import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class StoreService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async GetStoreDashBoard(storeId) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_DASHBOARD });
    let itemArray = [];
    let id = "?StoreID=" + storeId;

    await myApi.endpoints.GetStoreDashBoard.getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetStoreDashBoard(storeId);
      });
    }
    return Promise.resolve(itemArray);
  }

  async GetStoreDashBoardDeliveryRequestData(storeId) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.GET_STORE_DASHBOARD_DELIVERY_REQUEST_DATA,
    });
    let itemArray = [];
    let id = "?StoreID=" + storeId;
    await myApi.endpoints.GetStoreDashBoardDeliveryRequestData.getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetStoreDashBoardDeliveryRequestData(storeId);
      });
    }
    return Promise.resolve(itemArray);
  }

  async GetStoreDashBoardSplitOrderData(storeId) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.GET_STORE_DASHBOARD_SPLIT_ORDER_DATA,
    });
    let itemArray = [];
    let id = "?StoreID=" + storeId;
    await myApi.endpoints.GetStoreDashBoardSplitOrderData.getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetStoreDashBoardSplitOrderData(storeId);
      });
    }

    return Promise.resolve(itemArray);
  }

  async GetStoreDashBoardPickUpData(storeId) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_DASHBOARD_PICKUP_DATA });
    let itemArray = [];
    let id = "?StoreID=" + storeId;
    await myApi.endpoints.GetStoreDashBoardPickUpData.getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetStoreDashBoardPickUpData(storeId);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default StoreService;
