import React from "react";
import { withRouter } from "react-router-dom";
import CustomerService from "../../../../services/customerservice";
import { notification, Typography, Form, Input, Spin } from "antd";
import styles from "../../../Common/Styles/master.module.css";
import Button from "../../../Common/Button/button";

export default class Verification extends React.Component {
  customerService = new CustomerService();
  componentDidMount() {
    const url = new URL(window.location.href);
    const Emailtoken = url.searchParams.get("token");
    this.VerifyCutomerEmail(Emailtoken);
  }

  VerifyCutomerEmail = (Emailtoken) => {
    this.customerService.VerifyCutomerEmail(Emailtoken).then((response) => {     
      if (response.statusCode === 200) {
        notification["success"]({
          message: "Customer Master",
          description: response.message
            ? response.message
            : "Verification successful, you can now login",
          duration: 2,
          onClose: () => {
            window.location.href = "https://www.laundrokart.com/";
          },
        });
      } else {
        notification["error"]({
          message: "Customer Master",
          description: response.message
            ? response.message
            : "Something Went Wrong",
        });
      }
    });
  };

  render() {
    return <span></span>;
  }
}
