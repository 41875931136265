class UserRightsConfiguration {
  DASHBOARD = "Dashboard";
  //masters
  FACTORY = "Factory";
  CITY_MASTER = "City";
  STATE_MASTER = "State";
  DESIGNATION = "Designation";
  GARMENT_BRAND = "Garment Brand";
  GARMENT_SIZE = "Garment Size";
  KYC_DOC_TYPE = "KYC Document Type";
  SERVICE = "Service";
  GARMENT = "Garment";
  // GARMENT_COLOR = "Garment Color";
  REWASH_REASON = "Rewash Reason";
  OPER_INSTRUCTION = "Operation Instruction";
  EXPENSE_TYPE = "Expense Type";
  CORPORATE = "Corporate";
  STORE = "Store";
  CUSTOMER = "Manage Customers"; //updated
  SERVICE_ICONS = "Service Icons";

  //admin configurations
  SERVICE_TIME = "Service Time Card";
  SERVICE_RATE = "Service Rate Card";
  CASH_COUPON = "Cash Coupon";
  UPLOAD_CUSTOMER_TO_COUPON_MAPPING = "Upload Customer to Coupon Mapping";
  PACKAGING_CHARGES = "Packaging Charges";
  DELIVERY_CHARGES = "Delivery Charges";
  REWARDS_CONFIG = "Rewards Config";
  DISCOUNT_MASTER = "Discount Master";
  BANNER = "Banner";
  NOTICE_BOARD = "Notice Board";
  STANDARD_RATE_CARD = "Standard Rate Card";
  HOLIDAY_CONFIGURATION = "Holiday Configuration";
  TERMS_AND_CONDITIONS = "Terms And Conditions";
  //authentication
  USERS = "Users"; //added
  ROLES = "Roles";
  ROLE_OPERATION = "Role Operations"; //added
  CASH_COLLECTION = "Cash Collection";

  //store module
  // STORE_DASHBOARD = "Dashboard";
  STORE_TRANSFER_IN = "Transfer In";
  STORE_IN_PENDING = "Store In Pending";
  PICKUP = "Pickups"; //added
  ORDER_LIST = "Manage Orders"; //added
  // ORDERS = "Order";
  GATE_PASS_LIST = "Gate Pass List";
  READY_FOR_DELIVERY_ORDERS = "Ready For Delivery/Invoice"; //added
  EXPENSE_ENTRY = "User Expenses"; //added
  STORE_MANAGE_QC = "Pre-QC Response"; //added
  STORE_TRANSFER_OUT = "Transfer Out";
  STORE_INVENTORY = "Store Inventory";
  STORE_DELIVERY_LIST = "Deliveries"; //added
  PARTIAL_ORDER_RECEIVED = "Partial Order Received";
  STORE_ORDER_HISTORY = "Store Order History";
  VIEW_ON_MAP = "View On Map";
  // INVOICE = "Invoice";

  //factory module
  // FACTORY_DASHBOARD = "Dashboard";
  FACTORY_IN = "Factory In";
  FACTORY_PRE_QC = "Pre QC";
  FACTORY_POST_QC = "Post QC";
  FACTORY_OUT = "Factory Out";
  FACTORY_INVENTORY = "Factory Inventory";
  RESORT_PACK = "Resorting & Packaging";
  RESORTING_AND_PACKAGING_LIST = "Resorting And Packaging List";
  FACTORY_GATE_PASS = "Factory Gate Pass List";
  FACTORY_ORDER_HISTORY = "Factory Order History";
  FACTORY_IN_PENDING = "Factory In Pending";
  FACTORY_GARMENT_INVENTORY="Factory Garment Inventory";
  INVENTORY_SUMMARY="Inventory Summary";


  //LKWallet
  PACKAGE_CONFIG = "Package Configuration";
  REFERRAL_BONUS = "Refferal Bonus Configuration";
  COMPENSATION = "Compensation";
  EMPLOYEE_CREDITS = "Employee Credit";
  REFUND = "Refund";
  ADVANCE_PAYMENT = "Advance Payment";
  BUY_PACKAGE = "Buy Package";
  CUSTOMER_BULK_CREDIT = "Customer Bulk Credit";
  CUSTOMER_BULK_DEBIT = "Customer Bulk Debit";
  CUSTOM_BULK_NOTIFICATION_UPLOAD = "Custom Bulk Notification Upload";

  COLLECT_FINGERPRINTS = "Collect FingerPrints";
  ATTENDANCE = "Attendance";
  ATTENDANCE_REPORT = "Attendance Report";
  //REPORTS = "Reports";
  STORE_GATE_PASS_REPORT = "Store Gate Pass Report";
  GARMENT_SUMMARY_REPORT = "Garment Summary Report";
  QC_REPORT = "QC Report";
  FACTORY_IN_SUMMARY_REPORT = "Factory In Summary Report";
  FACTORY_GATE_PASS_REPORT = "Factory Gate Pass Report";
  STORE_GATE_PASS_SUMMARY_REPORT = "Store Gate Pass Summary Report";
  CUSTOMER_REPORT = "Customer Report";
  RAF_REPORT = "RAF Report";
  REVENUE_REPORT = "Revenue Report";
  ORDER_REPORT = "Order Report";
  WALLET_TRANSACTION_REPORT = "Wallet Transaction Report";
  PACKAGE_RECHARGE_REPORT = "Package Recharge Report";
  STORE_TRANSFER_IN_REPORT = "Store Transfer In Report";
  STORE_TRANSFER_OUT_REPORT = "Store Transfer Out Report";
  TRANSACTION_REPORT = "Transaction Report";
  ODM_REPORT = "ODM Report";
  PROMOCODE_REPORT = "Promo Code Report";
  CUSTOMER_VALUE_SUMMARY_REPORT = "Customer Value Summary Report";
  CASH_COUPON_REPORT = "Cash Coupon Report";
  PAYTM_EDC_MACHINE_CONFING = "Paytm EDC Machine Configuration";

  //Processing
  GARMENT_PROCESSING = "Garment Processing";
  PROCESSING_LIST = "Processing List";

  //Faq
  FAQ = "Faq";

  //Audit
  AUDIT = "Audit";
  PENDING_CASH_SUMMARY = "Pending Cash Summary";
  OUT_OF_COVERAGE="Out Of Coverage";
  PICKUP_DELIVERY_ASSIGNMENT="Pickup Delivery Assignment";
  DAILY_ORDER_SUMMARY="Daily Order Summary";
  MONTHLY_ORDER_SUMMARY="Monthly Order Summary";
  DAILY_PICKUP_SUMMARY="Daily Pickup Summary";
  MONTHLY_PICKUP_SUMMARY="Pickup Summary"

  //Orders Summary
  ORDERS_SUMMARY="Orders Summary";
  APPSFLYER="AppsFlyer";

  EXPRESS_URGENT="Express/Urgent";
  REWASH="Rewash";
  FIVE_STARE="5 Star";

  
}

export default UserRightsConfiguration;
