import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  // TimePicker,
  Input,
  // DatePicker,
  Space,
  notification,
  Modal,
  Descriptions,
  Upload,
  Table,
} from "antd";
import Button from "../../../Common/Button/button";
// import FactoryPreQC from "../../../../services/factorypre-qc";
import FactoryPostQC from "../../../../services/factorypost-qcservice";
import { PlusOutlined } from "@ant-design/icons";
import Common from "../../../../services/common";
import ROUTES from "../../../../routes";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import { BarcodeOutlined } from "@ant-design/icons";
import { labels } from "../../../../shared/language";

var jwt = require("jsonwebtoken");
const { Option } = Select;
let formReference = React.createRef();
/**
 * @author Payal Bharakhda
 * @created 25 Nov 2020
 */

const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

class GeneralForm extends React.Component {
  factoryPostQC = new FactoryPostQC();
  commonReq = new Common();

  state = {
    garmentLoaded: false,
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    tagNo: "",
    qcStatus: 2,
    remark: "",
    garMentData: [],
    statusDDL: [],
    isSaveDisable: true,
    lastQCDetails: {},
    fileList: [
      // {
      //   uid: "-1",
      //   name: "image.png",
      //   status: "done",
      //   url:
      //     "https://images.unsplash.com/photo-1549490349-8643362247b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80",
      // },
      // {
      //   uid: "-2",
      //   name: "image.png",
      //   status: "done",
      //   url:
      //     "https://images.unsplash.com/photo-1549490349-8643362247b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80",
      // },
      // {
      //   uid: "-5",
      //   name: "image.png",
      //   status: "error",
      // },
    ],

    garMentHistoryData: [],
    showAddPopup: false,
    // reasonDDL: [],
    // orderGarmentID: 0,
    mode: "add",
  };

  columns = [
    //{
    //     title: 'CityID',
    //     dataIndex: 'cityID',
    //     key: 'cityID',
    // },
    {
      title: labels.QC_REASON,
      dataIndex: "qcReason",
      key: "qcReason",
      // sorter: true,
    },
    {
      title: labels.REMARK,
      dataIndex: "remark",
      key: "remark",
      // sorter: true,
    },
    {
      title: labels.QC_PENDING_FROM,
      dataIndex: "qcPendingFrom",
      key: "qcPendingFrom",
      //sorter: true,
    },
    {
      title: labels.QC_STATUS,
      dataIndex: "qcStatus",
      key: "qcStatus",
      // sorter: true,
    },
  ];

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    this.GetQCStatus();
    this.getLastPostQCDetails();

    // this.GetQCResion();
    if (
      [ROUTES.VIEW_POST_QC_FACTORY, ":tagNo"].join("/") ===
      this.props.match.path
    ) {
      this.setState(
        { tagNo: this.props.location.state.tagNo, mode: "view" },
        () => this.GetGarmentInfo()
      );
    } else if (
      [ROUTES.EDIT_POST_QC_FACTORY, ":tagNo"].join("/") ===
      this.props.match.path
    ) {
      this.setState(
        { tagNo: this.props.location.state.tagNo, mode: "edit" },
        () => this.GetGarmentInfo()
      );
    }
  }
  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = (info) => {
    if (info.fileList.length > 0) {
      if (info.file !== undefined) {
        // this.setState({ fileList: info.fileList });
        const isJpgOrPng =
          info.file.type === "image/jpeg" ||
          info.file.type === "image/png" ||
          info.file.type === "image/jpg";
        if (!isJpgOrPng) {
          notification["error"]({
            message: labels.POST_QC,
            description: labels.YOU_CAN_ONLY_UPLOAD_JPG_JPEG_PNG_FILE,
          });
          info.file = undefined;
          // this.setState({ fileList: [] });
        }
        let isLt5M = false;
        if (info.file !== undefined) {
          isLt5M = info.file.size / 1024 / 1024 < 5;
          if (!isLt5M) {
            notification["error"]({
              message: labels.POST_QC,
              description: labels.IMAGE_MUST_BE_SMALLER_THAN_5_MB,
            });
            info.file = undefined;
            // this.setState({ fileList: [] });
          }
        }
        if (isJpgOrPng && isLt5M) {
          info.file.status = "done";
        }
      }
      var infoFileList = [];
      infoFileList.push(info.file);
      info.file = infoFileList.filter((x) => x != undefined);
      this.setState({ fileList: info.file });
    } else {
      this.setState({ fileList: [] });
    }
  };

  onChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChangeDDL = (name, e) => {
    this.setState({ [name]: e });
  };

  getLastPostQCDetails = () => {
    this.factoryPostQC
      .getLastQC(this.props.location.state.tagNo)
      .then((response) => {
        if (response.statusCode === 200) {
          if (response.data.length > 0) {
            this.setState({ lastQCDetails: response.data[0] });
            if (![null, undefined, ""].includes(formReference)) {
              formReference.current.setFieldsValue({
                orderGarmentID: response.data[0].orderGarmentID,
                qcStatus: response.data[0].postQCStatus,
                remark:
                  this.props.mode === "view"
                    ? response.data[0].postQCRemark
                    : "",
              });
            }
          } else {
            this.setState({ lastQCDetails: null });
          }
        } else {
          this.setState({ lastQCDetails: null });
        }
      });
  };

  handlePostQc = () => {
    var fileData = this.state.fileList;
    const formData = new FormData();

    formData.append(
      "PostQCImage",
      this.state.fileList.length > 0
        ? this.state.fileList[0].originFileObj
        : null
    );
    formData.append("TagNo", this.state.tagNo);
    formData.append("PostQCStatus", this.state.qcStatus.toString());
    formData.append("PostQCRemark", this.state.remark);
    formData.append(
      "UserID",
      parseInt(
        jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
          .unique_name
      )
    );

    this.factoryPostQC.ManagePostQCRequest(formData).then((response) => {
      if (response.statusCode === 200) {
        formReference.current.resetFields();
        this.setState({
          garmentLoaded: false,
          fileList: [],
        });
        notification["success"]({
          message: labels.POST_QC,
          description: labels.DATA_SUCCESSFULLY_SUBMITTED,
        });
        this.props.backFunction();
      } else {
        notification["error"]({
          message: labels.POST_QC,
          description: labels.UNABLE_TO_SUBMIT_TAG_DATA,
        });
      }
    });
  };

  GetGarmentInfo = () => {
    if (![null, undefined, ""].includes(this.state.tagNo)) {
      this.factoryPostQC
        .GetGarmentDetailByTagNo(this.state.tagNo)
        .then((items) => {
          if (items.statusCode === 200 && items.data.length > 0) {
            this.setState(
              {
                garMentData: items.data,
                // orderGarmentID: items.data.length > 0 ? items.data[0].orderGarmentID : 0
              },
              () => {
                if (this.state.garMentData.length > 0) {
                  this.setState({
                    garmentLoaded: true,
                  });
                } else {
                  notification["error"]({
                    message: labels.POST_QC,
                    description: labels.UNABLE_TO_LOAD_TAG_DATA,
                  });
                  this.setState({
                    garmentLoaded: false,
                  });
                }
              }
            );
          } else {
            notification["error"]({
              message: labels.POST_QC,
              description: labels.UNABLE_TO_GET_TAG_DATA,
            });
            this.setState({ garMentData: [], garmentLoaded: false });
          }
        });
    } else {
      notification["error"]({
        message: labels.POST_QC,
        description: labels.PLEASE_ENTER_TAG_NO,
      });
    }
  };

  GetQCHistoryByTagNo = () => {
    this.setState({ showAddPopup: true });
    this.factoryPreQC.GetQCHistoryByTagNo(this.state.tagNo).then((items) => {
      this.setState({
        garMentHistoryData: items.data,
      });
    });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    // this.resetFields();
  };
  GetQCStatus = () => {
    this.commonReq.GetSystemEnums("PostQCStatus").then((res) => {
      this.setState({ statusDDL: res.data });
    });
  };

  showNumberWithStar = () => {
    return ![undefined, null, ""].includes(this.state.tagNo) ? (
      <div>
        <span>{labels.TAG_NUMBER}</span>
        <span style={{ color: "red", marginTop: 5 }}> *</span>
      </div>
    ) : (
      labels.TAG_NUMBER
    );
  };

  render() {
    const disableAll = this.props.mode === "view";
    const { garMentData } = this.state;
    const uploadButton =
      this.state.fileList.length === 0 ? (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">{labels.UPLOAD}</div>
        </div>
      ) : null;
    return (
      <Form
        // key={this.props.values.tagNo}
        name="general"
        layout="vertical"
        // initialValues={this.props.values}
        initialValues={{
          qcStatus: this.state.qcStatus,
          remark: this.state.remark,
        }}
        // ref={this.props.instance}
        ref={formReference}
        style={{ display: !this.props.isActive ? "none" : "block" }}
        onFinish={this.handlePostQc}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={10}>
            <Form.Item
              label={
                this.props.mode === "edit"
                  ? this.showNumberWithStar()
                  : labels.TAG_NUMBER
              }
              name="tagNumber"
              rules={[
                {
                  required: [undefined, null, ""].includes(this.state.tagNo)
                    ? true
                    : this.props.mode === "add" || this.props.mode === "view"
                    ? true
                    : false,
                  message: labels.PLEASE_SCAN_ADD_TAG_NUMBER,
                },
              ]}
            >
              <div className="w-100" style={{ display: "flex" }}>
                <Input
                  placeholder={labels.ENTER_SCAN_TAG_NUMBER}
                  onChange={this.onChange.bind(this, "tagNo")}
                  value={this.state.tagNo || ""}
                  onPressEnter={this.GetGarmentInfo}
                  disabled={
                    disableAll ? true : this.props.mode === "add" ? false : true
                  }
                  // onBlur={this.GetGarmentInfo}
                  suffix={<BarcodeOutlined />}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>
        {this.state.garmentLoaded ? (
          <Descriptions
            title={labels.GARMENT_INFORMATION}
            bordered
            size="small"
            column={2}
          >
            <Descriptions.Item label={labels.CUSTOMER_NAME} span={2}>
              {garMentData[0].customerName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.CUSTOMER_TYPE}>
              {garMentData[0].customerType}
            </Descriptions.Item>
            <Descriptions.Item label={labels.CONTACT}>
              {garMentData[0].customerContactNo}
            </Descriptions.Item>
            <Descriptions.Item label={labels.ORDER_DATE}>
              {Moment(garMentData[0].orderDate).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label={labels.SPLIT_ORDER_NO}>
              {garMentData[0].splitOrderNumber}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GATE_PASS_NO}>
              {garMentData[0].gatePassNo}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GATE_PASS_DATE}>
              {Moment(garMentData[0].gatePassDate).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GARMENT_STATUS}>
              {garMentData[0].garmentStatusName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GARMENT_NAME}>
              {garMentData[0].garmentName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.DELIVERY_TYPE}>
              {garMentData[0].deliveryType}
            </Descriptions.Item>
            <Descriptions.Item label={labels.DELIVERY_DATE}>
              {Moment(garMentData[0].deliveryDate).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label={labels.BRAND}>
              {garMentData[0].garmentBrand}
            </Descriptions.Item>
            <Descriptions.Item label={labels.COLOR}>
              {garMentData[0].colorNames}
            </Descriptions.Item>
            <Descriptions.Item label={labels.SERVICE}>
              {garMentData[0].serviceName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.ADD_ON_SERVICE}>
              {garMentData[0].additionalServiceName}
            </Descriptions.Item>
          </Descriptions>
        ) : null}
        {this.state.lastQCDetails !== null &&
        this.state.lastQCDetails !== {} ? (
          <Descriptions
            title={labels.LATEST_POSTQC_DETAILS}
            bordered
            size="small"
            column={2}
          >
            <Descriptions.Item label={labels.POST_QC_STATUS}>
              {this.state.lastQCDetails.postQCStatusName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.REMARKS} span={2}>
              {this.state.lastQCDetails.postQCRemark}
            </Descriptions.Item>
            <Descriptions.Item label={labels.IMAGES} span={2}>
              {this.state.lastQCDetails.postQCImage !== "" &&
              this.state.lastQCDetails.postQCImage !== null ? (
                <a href={this.state.lastQCDetails.postQCImage} target="_blank">
                  Image 1
                </a>
              ) : null}
              <br />
            </Descriptions.Item>
          </Descriptions>
        ) : null}
        <Row gutter={30}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={labels.STATUS}
              name="qcStatus"
              rules={[{ required: true, message: labels.PLEASE_SELECT_STATUS }]}
              // initialValue={1}
            >
              <Select
                placeholder={labels.SELECT_STATUS}
                //    disabled={disableAll}
                // value={this.state.qcStatus || null}
                disabled={
                  disableAll
                    ? disableAll
                    : this.state.garmentLoaded
                    ? garMentData[0].garmentStatus == 4 ||
                      garMentData[0].garmentStatus == 6
                      ? false
                      : true
                    : true
                }
                onChange={this.onChangeDDL.bind(this, "qcStatus")}
              >
                {this.state.statusDDL.length !== 0
                  ? this.state.statusDDL.map((res) => {
                      return res.enumDetailID !== "-1" ? (
                        <Option
                          value={res.enumDetailID}
                          key={res.enumDetailID}
                          // disabled={parseInt(res.enumDetailID) === 1}
                        >
                          {res.enumDetailName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={18}>
            <Form.Item
              label={labels.REMARKS}
              name="remark"
              rules={[{ required: true, message: labels.PLEASE_ENTER_REMARKS }]}
            >
              <Input.TextArea
                placeholder={labels.ENTER_REMARKS}
                rows={1}
                onChange={this.onChange.bind(this, "remark")}
                // value={this.state.remark || ""}
                disabled={
                  disableAll
                    ? disableAll
                    : this.state.garmentLoaded
                    ? garMentData[0].garmentStatus == 4 ||
                      garMentData[0].garmentStatus == 6
                      ? false
                      : true
                    : true
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            {disableAll ? null : (
              <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={this.state.fileList}
                disabled={
                  this.state.garmentLoaded
                    ? garMentData[0].garmentStatus == 4 ||
                      garMentData[0].garmentStatus == 6
                      ? false
                      : this.state.isSaveDisable
                    : this.state.isSaveDisable
                }
                // onPreview={this.handlePreview}
                onChange={this.handleChange}
                customRequest={({ file, onSuccess, onError }) => {
                  onSuccess({ status: "done" }, file);
                }}
              >
                {uploadButton}
                {/* {this.state.fileList.length >= 8 ? null : uploadButton} */}
              </Upload>
            )}
          </Col>

          <Col xs={24} style={{ textAlign: "right" }}>
            <Space>
              <div className="w-100"></div>
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                {labels.CANCEL}
              </Button>
              {!disableAll ? (
                <Button
                  htmlType="submit"
                  variant="purple"
                  shape="round"
                  size="large"
                  disabled={
                    this.state.garmentLoaded
                      ? garMentData[0].garmentStatus == 4 ||
                        garMentData[0].garmentStatus == 6
                        ? false
                        : this.state.isSaveDisable
                      : this.state.isSaveDisable
                  }
                  // onClick={this.handlePostQc}
                >
                  {labels.SUBMIT}
                </Button>
              ) : null}
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
export default withRouter(GeneralForm);
