import React from "react";
import GatePassList from "../../../components/FactoryModule/GatePassList/factoryGatepassList.factory.component";

export default class FactoryGatePassListScreen extends React.Component {
  render() {
    return (
      <div>
        <GatePassList />
      </div>
    );
  }
}
