import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class RateCardService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async retrieveStore() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE });
    let itemArray = [];
    await myApi.endpoints.GetStoreList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStore();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveCorporate() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_CORPORATE });
    let itemArray = [];
    await myApi.endpoints.GetCorporateList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveCorporate();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreCardAssignment(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_RATECARD_ASSIGN_TO_STORE });
    let itemArray = [];
    await myApi.endpoints.GetRateCardAssginedToStore.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreCardAssignment(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveCorporateCardAssignment(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.GET_RATECARD_ASSIGN_TO_CORPORATE,
    });
    let itemArray = [];
    await myApi.endpoints.GetRateCardAssignedToCorporate.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveCorporateCardAssignment(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getRateCardDetails(rateCardID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_RATECARD_DETAILS });
    let itemArray = [];
    let id = "?RateCardID=" + rateCardID;

    await myApi.endpoints.GetRateCardInfoByRateCardID.getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getRateCardDetails(rateCardID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async uploadServiceRateCard(id, formData) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.UPLOAD_SERVICE_RATE_CARD });
    let itemArray = [];
    let Id = "?RateCardID=" + id;
    await myApi.endpoints.UploadServiceRateCard.uploadFile(Id, formData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.uploadServiceRateCard(id, formData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async saveUpdateRateCardMaster(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_RATECARD_MASTER });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateRateCardMaster.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.saveUpdateRateCardMaster(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async SaveUpdateAssignRateCardToStores(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.SAVE_UPDATE_ASSIGN_RATECARD_TO_STORE,
    });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateAssignRateCardToStores.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.SaveUpdateAssignRateCardToStores(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async SaveUpdateAssignRateCardToCorporate(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.SAVE_UPDATE_ASSIGN_RATECARD_TO_CORPORATE,
    });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateAssignRateCardToCorporate.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.SaveUpdateAssignRateCardToCorporate(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getRateCardDetailByRateCardID(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.GET_RATECARD_DETAIL_BY_RATECARDID,
    });
    let itemArray = [];
    let Id = "?RateCardID=" + id;
    await myApi.endpoints.GetRateCardDetailByRateCardID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getRateCardDetailByRateCardID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveRateCardMaster(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_RATECARD_MASTER });
    let itemArray = [];
    await myApi.endpoints.GetRateCardMaster.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveRateCardMaster(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default RateCardService;
