import React from "react";
import { withRouter, Link } from "react-router-dom";
import Button from "../../components/Common/Button/button";
import styles from "../LKWallet/EmployeeCredit/lkemployeecredit.module.css";
import TableActions from "../../components/Common/TableActions/tableactions";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Spin,
  // Popconfirm,
  Table,
  Modal,
  // Checkbox,
  notification,
  // DatePicker,
  Upload,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faInfo,
  faDownload,
  // faPencilAlt,
  // faTrash,
} from "@fortawesome/free-solid-svg-icons";
import BulkUploadCouponService from "../../services/bulkuploadcouponservice";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import { UploadOutlined } from "@ant-design/icons";
import ReactExport from "react-export-excel";
import { labels } from "../../shared/language";
import Common from "../../services/common";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Search } = Input;
const { Option } = Select;

class Master extends React.Component {
  constructor(props) {
    super(props);
    this.bulkuploadcouponService = new BulkUploadCouponService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();
    this.state = {
      modalLoading: false,
      excelFile: <React.Fragment></React.Fragment>,

      bulkuploadCouponItems: [],
      couponMapFileID: 0,
      fileName: "",
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      loading: true,
      showfilterBox: false,
      showAddPopup: false,

      uploading: false,
      fileList: [],
      binaryFile: "",
      currentURL: "",
      EmployeeCreditDetailsList: [],
    };
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.UPLOAD_CUSTOMER_TO_COUPON_MAPPING
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.FILE_NAME,
      dataIndex: "fileName",
      key: "fileName",
      sorter: true,
    },
    {
      title: labels.TOTAL_RECORDS,
      dataIndex: "totalCnt",
      key: "totalCnt",
      sorter: true,
    },
    {
      title: labels.UPLOADED_BY,
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: true,
    },
    {
      title: labels.UPLOADED_ON,
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: true,
      render: (text) => {
        return [null, undefined, ""].includes(text)
          ? ""
          : moment(text).format("DD-MM-YYYY hh:mm:ss a");
      },
    },
    {
      title: labels.SUCCEEDED,
      dataIndex: "succeeded",
      key: "succeeded",
      sorter: true,
      render: (text, record, index) => {
        return (
          <Space direction="horizontal">
            <span>{text}</span>
            <Tooltip title={labels.DOWNLOAD}>
              <Button
                onClick={() => this.getReport(record.couponMapFileID, true)}
                type="link"
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: labels.FAILED,
      dataIndex: "failed",
      key: "failed",
      sorter: true,
      render: (text, record, index) => {
        return (
          <Space direction="horizontal">
            <span>{text}</span>
            <Tooltip title={labels.DOWNLOAD}>
              <Button
                onClick={() => this.getReport(record.couponMapFileID, false)}
                type="link"
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      couponMapFileID: this.state.couponMapFileID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }

  handleSearch = (event) => {
    this.fetchItems({
      couponMapFileID: this.state.couponMapFileID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  getReport = (id, type) => {
    this.bulkuploadcouponService
      .getReport({
        couponMapFileID: parseInt(id),
        isSucceeded: type,
      })
      .then((response) => {
        this.setState(
          {
            excelFile: (
              <div>
                <ExcelFile
                  hideElement={true}
                  filename={type ? "succeeded" : "failed"}
                >
                  <ExcelSheet
                    data={response.data}
                    name={type ? "Succeeded Records" : "Failed Records"}
                    columns={[]}
                  >
                    <ExcelColumn label="Customer Name" value="customerName" />
                    <ExcelColumn label="MobileNo" value="mobileNo" />
                    <ExcelColumn label="CreatedBy" value="createdBy" />
                    <ExcelColumn label="CreatedBy Name" value="createdByName" />
                    <ExcelColumn label="Created On" value="createdOn" />
                    <ExcelColumn label="Amount" value="amount" />
                    <ExcelColumn label="CouponCode" value="couponCode" />
                    <ExcelColumn label="FromDate" value="fromDate" />
                    <ExcelColumn label="ToDate" value="toDate" />
                    <ExcelColumn
                      label="Customer Remark"
                      value="customerRemark"
                    />
                    <ExcelColumn label="Title" value="title" />
                    <ExcelColumn label="Remarks" value="remark" />
                  </ExcelSheet>
                </ExcelFile>
              </div>
            ),
          },
          () => this.setState({ excelFile: <React.Fragment></React.Fragment> })
        );
      });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      couponMapFileID: 0,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : sorter.order,
      // ...filters,
    });
  };

  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.bulkuploadcouponService
      .getbulkcouponcodesdata(params)
      .then((items) => {
        if (items.statusCode === 200) {
          if (
            items.length !== 0 &&
            items.data.length === 0 &&
            params.pagination.current !== 1
          ) {
            params.pagination.current = 1;
            this.fetchItems({
              couponMapFileID: 0,
              pageIndex: 1,
              pageSize: params.pageSize,
              pagination: params.pagination,
            });
          }
          this.setState({
            bulkuploadCouponItems: items.data,
            pagination: {
              ...params.pagination,
              total:
                items.length !== 0 && items.data.length !== 0
                  ? items.data[0].totalRecords
                  : 0,
            },
          });
        } else {
          notification["error"]({
            message: labels.UPLOAD_CUSTOMER_TO_COUPON_MAPPING,
            description: items.message
              ? this.common.Message(items.message)
              : labels.UNABLE_TO_GET_LIST_OF_BULK_UPLOAD_COUPON_CODES_DATA,
          });
        }
        this.setState({ loading: false });
      });
  };

  handleUpload = (values) => {
    this.setState({ modalLoading: true });
    let postData = new FormData();

    postData.append("CouponBulkUploadFile", values.file[0].originFileObj);

    this.bulkuploadcouponService.uploadFile(postData).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.UPLOAD_CUSTOMER_TO_COUPON_MAPPING,
          description: this.common.Message(response.message),
        });
        this.fetchItems({
          pageIndex: this.state.pagination.current,
          pageSize: this.state.pagination.pageSize,
          ...this.state.pagination,
        });
      } else {
        notification["error"]({
          message: labels.UPLOAD_CUSTOMER_TO_COUPON_MAPPING,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_UPLOAD_THE_FILE,
        });
      }
      this.setState({ modalLoading: false });
    });
    this.setState({ showAddPopup: false });
  };

  handleBonusAmount = (e) => {
    this.setState({ bonusAmount: e.target.value });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleRemark = (e) => {
    this.setState({ remark: e.target.value });
  };

  resetFields = () => {
    this.setState({
      couponMapFileID: 0,
      fileName: "",
      totalCnt: "",
      succeeded: "",
      failed: "",
      isEnabled: false,
      isVisible: false,
    });
  };

  render() {
    const {
      fileName,
      totalCnt,
      succeeded,
      failed,
      pagination,
      loading,
      remark,
    } = this.state;
    const bulkuploadCouponItems = this.state.bulkuploadCouponItems;
    let RegexInteger = /^[1-9]+[0-9]*$/g;
    const listItems = bulkuploadCouponItems.map((item, index) => ({
      key: item.couponMapFileID,
      couponMapFileID: item.couponMapFileID,
      fileName: item.fileName,
      totalCnt: item.totalCnt,
      succeeded: item.succeeded,
      failed: item.failed,
      totalRecords: item.totalRecords,
      createdBy: item.createdBy,
      createdByName: item.createdByName,
      createdOn: item.createdOn,
    }));
    return (
      <div>
        {this.state.excelFile}
        <PageHeader
          title={labels.UPLOAD_CUSTOMER_TO_COUPON_MAPPING}
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
              key="1"
            />,
            <a
              href={window.location.origin + "/files/CouponBulkUploadFile.xlsx"}
              key="4"
              style={{ alignSelf: "center" }}
            >
              {labels.DOWNLOAD_FORMAT}
            </a>,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
                key="2"
              >
                {labels.UPLOAD_FILE}
              </Button>
            ) : null,
          ]}
        />
        <Table
          size="small"
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}></TableActions>
        <Modal
          title={
            this.state.couponMapFileID === 0
              ? labels.ADD_BULK_UPLOAD_COUPON_CODES
              : this.state.isEnabled
              ? labels.VIEW_BULK_UPLOAD_COUPON_CODES
              : labels.EDIT_BULK_UPLOAD_COUPON_CODES
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handleUpload}
              initialValues={{
                fileName,
                totalCnt,
                succeeded,
                failed,
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    name="file"
                    label={
                      labels.UPLOAD_BULK_UPLOAD_COUPON_CODES_CONFIGURATION_FILE
                    }
                    rules={[
                      { required: true, message: labels.PLEASE_SELECT_FILE },
                    ]}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    }}
                  >
                    <Upload
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls"
                      customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                          onSuccess("ok");
                        }, 0);
                      }}
                    >
                      <Button variant="transparentGreyOutline">
                        <UploadOutlined /> {labels.CLICK_TO_UPLOAD}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.couponMapFileID === 0
                            ? labels.ADD
                            : labels.UPDATE}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Master);
