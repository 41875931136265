import React from "react";
import styles from "./discountmaster.module.css";
import Button from "../Common/Button/button";
import Filters from "../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  notification,
  Tooltip,
  Radio,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faPencilAlt,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../routes";
import DiscountMasterService from "../../services/discountmasterservice";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import Common from "../../services/common";
const { Search } = Input;
const { Option } = Select;
/**
 * A factory master screen which holds grid view for master screens and form with steps
 *
 * @author Maulik  Suthar
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
class Master extends React.Component {
  discountmasterservice = new DiscountMasterService();
  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    discountID: 0,
    discountName: "",
    statusID: 0,
    showfilterBox: false,
    discountList: [],
    tableLoading: true,
    pagination: {
      showSizeChanger: true,
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
    validtilltoday: 0,
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.DISCOUNT_MASTER
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }
  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ tableLoading: false });
    this.getDiscountMasterList({
      validtilltoday:this.state.validtilltoday,
      discountID: this.state.discountID,
      discountName: this.state.discountName,
      statusID: this.state.statusID,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination,
    });
  }
  handleSearch = (event) => {
    this.setState({ search: event.target.value });
    this.getDiscountMasterList({
      validtilltoday:this.state.validtilltoday,
      discountID: this.state.discountID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
    });
  };
  getDiscountMasterList(params = {}) {
    this.setState({ tableLoading: true });
    params = { ...params, commonSearch: this.state.search };
    this.discountmasterservice
      .retrieveDiscountMaster(params)
      .then((discountList) => {
        if (discountList.statusCode === 200) {

          if (
            discountList.length !== 0 &&
            discountList.data.length === 0 &&
            params.pagination.current !== 1
          ) {
            params.pagination.current = 1;

            this.getDiscountMasterList({
              validtilltoday:this.state.validtilltoday,
              discountID: this.state.discountID,
              discountName: this.state.discountName,
              statusID: this.state.statusID,
              pageIndex: 1,
              pageSize: params.pageSize,
              pagination:params.pagination
            });
          }


          this.setState({
            discountList: discountList.data,
            pagination: {
              ...params.pagination,
              total:
                discountList.data.length > 0
                  ? discountList.data[0].totalRecords
                  : 0,
            },
          });
        } else {
          notification["error"]({
            message: "Discount",
            description: discountList.message
              ? this.common.Message(discountList.message)
              : "Unable to get list of discount data",
          });
        }
        this.setState({ tableLoading: false });
      });
  }
  columns = [
    {
      title: "Discount",
      dataIndex: "discountName",
      key: "discountName",
      sorter: true,
    },
    {
      title: "Discount Type",
      dataIndex: "discountTypeName",
      key: "discountTypeName",
      sorter: true,
    },
    {
      title: "Discount On Value",
      dataIndex: "discountOnValue",
      key: "discountOnValue",
      sorter: true,
    },
    {
      title: "Mode Name",
      dataIndex: "modeName",
      key: "modeName",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "statusName",
      key: "statusName",
      sorter: true,
    },
    {
      title: "Week Days",
      dataIndex: "weekDayNames",
      key: "weekDayNames",
      sorter: true,
    },
    {
      title: "Valid From",
      dataIndex: "validFrom",
      key: "validFrom",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Valid To",
      dataIndex: "validTo",
      key: "validTo",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Link
                to={{
                  pathname: [
                    ROUTES.VIEW_DISCOUNT_MASTER,
                    record.discountID,
                  ].join("/"),
                  state: { discountID: record.discountID },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Link
                to={{
                  pathname: [
                    ROUTES.EDIT_DISCOUNT_MASTER,
                    record.discountID,
                  ].join("/"),
                  state: { discountID: record.discountID },
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  handleRadioChange = (e) => {
    // console.log("radio checked", e.target.value);
    const validtilltoday = e.target.value === 2 ? 1 : 0;

    this.setState(
      {
        validtilltoday: validtilltoday,
        radioSelectedData: e.target.value,
      },
      () => {
        this.getDiscountMasterList({
          validtilltoday:this.state.validtilltoday,
          discountID: this.state.discountID,
          discountName: this.state.discountName,
          statusID: this.state.statusID,
          pageIndex: this.state.pagination.current,
          pageSize: this.state.pagination.pageSize,
          pagination: this.state.pagination,
        });
      }
    );
  };

  render() {
    return (
      <div>
        <PageHeader
          title="Discount Master"
          extra={[
            <Radio.Group
              onChange={this.handleRadioChange}
              value={this.state.radioSelectedData}
            >
              <Radio value={1}>All</Radio>
              <Radio value={2}>Valid as on Today</Radio>
            </Radio.Group>,

            <Search
              placeholder="Search"
              className={styles.SearchInput}
              key="1"
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.props.history.push({
                    pathname: ROUTES.ADD_DISCOUNT_MASTER,
                    state: { discountID: this.state.discountID },
                  })
                }
                key="2"
              >
                Add Discount Master
              </Button>
            ) : null,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https:
                   * https:
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>
        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.discountList}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.getDiscountMasterList({
              validtilltoday:this.state.validtilltoday,
              discountID: this.state.discountID,
              pageIndex: pagination.current,
              pageSize: pagination.pageSize,
              pagination,
              sortColumn: sorter.field,
              sortOrder:
                sorter.order === undefined
                  ? null
                  : sorter.order === "descend"
                  ? "desc"
                  : "asc",
            });
          }}
        />
      </div>
    );
  }
}
export default withRouter(Master);
