import React from 'react';
import Holiday from '../../components/HoliDay/holidayconfiguration.component';

export default class holidayconfigurationscreen extends React.Component {
    render() {
        return (
            <div>
                <Holiday></Holiday>
            </div>
        )
    }
}
