import React from "react";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Form,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faInfo,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Common/Button/button";
import Filters from "../../components/Common/Filters/filters";
import TableActions from "../../components/Common/TableActions/tableactions";
import styles from "../RateCard/ratecardlistscreen.module.css";
import CreateRateCard from "./createratecard.screen";
import RateCardService from "../../services/ratecardservice";
//import  Moment from 'moment'
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../routes";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import Common from "../../services/common";

const { Search } = Input;
// const { Option } = Select;
/**
 * Rate Card listing screen.
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 1 June 2020
 *
 */
class RateCardList extends React.Component {
  /**
   * State Varible to Hold Active Step Index starting from 0
   */
  constructor(props) {
    super(props);
    this.ratecardservice = new RateCardService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();
    this.state = {
      commonSearch: null,
      tableLoading: true,
      isEditmode: false,
      showfilterBox: false,
      isViewmode: false,
      disabled: false,
      showAddPopup: false,
      isAdd: false,
      rateCardList: [],
      rateCardMasterData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      loading: false,
      shouldRefresh: false,
    };
    this.getRateCardMasterByfilter = this.getRateCardMasterByfilter.bind(this);
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ tableLoading: false });
    this.getRateCardMasterDetails({
      commonSearch: this.state.commonSearch,
      rateCardName: "",
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      // ...filters,
    });
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.SERVICE_RATE
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.getRateCardMasterDetails({
      rateCardName: "",
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  getRateCardMasterDetails(params = {}) {
    this.setState({ tableLoading: true });
    params.CommonSearch = this.state.commonSearch;
    params.rateCardName =
      params.rateCardName === undefined ? "" : params.rateCardName;
    params.sortColumn =
      params.sortColumn === undefined ? "" : params.sortColumn;
    params.sortOrder = params.sortOrder === undefined ? "" : params.sortOrder;
    this.ratecardservice.retrieveRateCardMaster(params).then((rateCardList) => {
      if (rateCardList.statusCode === 200) {
        this.setState({
          rateCardList: rateCardList.data,
          pagination: {
            ...params.pagination,
            total:
              rateCardList.data.length !== 0
                ? rateCardList.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Rate Card Master",
          description: rateCardList.message
            ? this.common.Message(rateCardList.message)
            : "Unable to get list of rate card data",
        });
      }
      this.setState({ tableLoading: false });
    });
  }

  getRateCardMasterByfilter(pagination) {
    const params = {
      rateCardName: document.getElementById("rateCardName").value,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    };

    this.ratecardservice.retrieveRateCardMaster(params).then((rateCardList) => {
      this.setState({
        rateCardList: rateCardList.data,
        pagination: {
          ...params.pagination,
          total: rateCardList.data[0].totalRecords,
        },
      });
    });
  }

  // deleteUserByUserID(id){
  //   this.userservice.deleteUserByUserID(id).then(response => {
  //       console.log(response);
  //       alert(response.message);
  //       this.getUserMasterDetails();
  //   });
  // }
  handleSearch = (event) => {
    this.setState({ commonSearch: event.target.value }, () => {
      this.getRateCardMasterDetails({
        // CommonSearch: event.target.value,
        pageIndex: this.state.pagination.current,
        pageSize: this.state.pagination.pageSize,
        pagination: this.state.pagination,
        // ...filters,
      });
    });
  };

  bringback = () => {
    this.setState({ isAdd: false });
  };

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: "Rate Card Name",
      dataIndex: "rateCardName",
      key: "rateCardName",
      sorter: true,
    },
    {
      title: "Is Corporate",
      dataIndex: "isCorporate",
      key: "isCorporate",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Link
                to={{
                  pathname: [ROUTES.VIEW_RATECARD, record.rateCardID].join("/"),
                  state: { rateCardID: record.rateCardID },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Link
                to={{
                  pathname: [ROUTES.EDIT_RATECARD, record.rateCardID].join("/"),
                  state: { rateCardID: record.rateCardID },
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  /**
   * Variables to be used in table for selection property
   * kindly go through
   * https://ant.design/components/table/
   * https://ant.design/components/table/#components-table-demo-row-selection-and-operation
   * https://ant.design/components/table/#components-table-demo-row-selection
   */
  // selectionType = "checkbox";
  // rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(
  //       `selectedRowKeys: ${selectedRowKeys}`,
  //       "selectedRows: ",
  //       selectedRows
  //     );
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.firstName === "Disabled User", // Column configuration not to be checked
  //     name: record.firstName,
  //   }),
  // };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  render() {
    const { pagination } = this.state;
    const rateCardList = this.state.rateCardList;
    const rateCardLists = rateCardList.map((rateCardList) => ({
      rateCardID: rateCardList.rateCardID,
      rateCardName: rateCardList.rateCardName,
      isCorporate: rateCardList.isCorporate === true ? "Yes" : "No",
    }));
    return this.state.isAdd ||
      this.state.isEditmode ||
      this.state.isViewmode ? (
      <div key={this.state.shouldRefresh}>
        {/* <CreateRateCard backFunction={this.bringback} rateCardMasterData={rateCardMasterData} isEditmode={isEditmode} isViewmode={isViewmode} disabled={disabled}/> */}
        <CreateRateCard backFunction={this.bringback} />
      </div>
    ) : (
      <React.Fragment>
        <PageHeader
          title="Rate Card Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
              key="1"
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                onClick={() =>
                  this.props.history.push({
                    pathname: ROUTES.ADD_RATECARD,
                    state: { rateCardID: this.state.rateCardID },
                  })
                }
                key="2"
                //onClick={() => this.setState({ showAddPopup: true,isAdd:true })}
              >
                Add Rate Card
              </Button>
            ) : null,
          ]}
        />

        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={6}>
                <Form.Item>
                  <Input placeholder="Enter Rate Card Name" id="rateCardName" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  variant="whitePink"
                  shape="round"
                  size="large"
                  onClick={this.getRateCardMasterByfilter}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>
        {/**
         * Please go through below documention for <Table> Component
         * https://ant.design/components/table/
         * https://ant.design/components/table/#API
         *
         */}
        <Table
          size="small"
          // rowSelection={{
          //   type: this.selectionType,
          //   ...this.rowSelection,
          // }}
          columns={this.columns}
          dataSource={rateCardLists}
          pagination={pagination}
          loading={this.state.tableLoading}
          onChange={this.handleTableChange}
        />

        <TableActions isPagination={true}></TableActions>
      </React.Fragment>
    );
  }
}
export default withRouter(RateCardList);
