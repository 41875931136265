import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";


export default class Header {
    constructor() {
        this.config = new Configuration();
        this.endpoint = new EndPointEntity();
        this.common = new Common();
    }

    async AddScanGarmentRecords(postData) {
        const myApi = new API({ url: this.config.ITEM_STORE_URL });
        myApi.createEntity({ name: this.endpoint.UPDATE_SCANNED_TAGS_STATUS_FOR_READY_FOR_TRANS_OUT });
        let itemArray = [];
        let shouldWait = false;
        await myApi.endpoints.UpdateScannedTagsStatusForReadyForTransOut.create(postData)
            .then(({ data }) => {
                itemArray = data;
            })
            .catch((err) => {
                itemArray = this.common.handleError(err)
              });
            if (itemArray.statusCode == 401) {
              await this.common.refreshToken().then((response) => {
                this.common.setToken(response);
                itemArray = this.AddScanGarmentRecords(postData);
            });
        }
        return Promise.resolve(itemArray);
    }

    async getPreQcresponseCount(storeId) {
        const getFactoryAPI = new API({ url: this.config.ITEM_FACTORY_URL });
        getFactoryAPI.createEntity({ name: this.endpoint.GET_PRE_QC_PANDING_LIST });
        let itemArray = [];
        let idParams = "?storeId=" + storeId.storeID;
        let shouldWait = false;
        await getFactoryAPI.endpoints[this.endpoint.GET_PRE_QC_PANDING_LIST]
            .getById(idParams)
            .then((response) => {
                itemArray = response.data;
            })
            .catch((err) => {
                itemArray = this.common.handleError(err)
            });
          if (itemArray.statusCode == 401) {
            await this.common.refreshToken().then((response) => {
              this.common.setToken(response);
                itemArray = this.getPreQcresponseCount(storeId);
            });
        }
        return Promise.resolve(itemArray);
    }
}