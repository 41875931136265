import React, { createRef } from "react";
import {
  PageHeader,
  Input,
  notification,
  Popconfirm,
  Form,
  Modal,
  Row,
  Col,
  Select,
  Space,
  Table,
  Tooltip,
  Checkbox,
  Spin,
} from "antd";
import Button from "../../../Common/Button/button";
import styles from "../../../Common/Styles/master.module.css";
import CustomerService from "../../../../services/customerservice";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  // faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
  RegexDecimalAndRestrictFirstZero,
  RegexInteger,
  MessageNumbers,
} from "../../../../shared/validator";
import { labels } from "../../../../shared/language";
// const { Search } = Input;
import Common from "../../../../services/common";
const { Option } = Select;
// const {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } = require("react-google-maps");

class Addresses extends React.Component {
  customerService = new CustomerService();
  common = new Common();
  state = {
    modalLoading: false,
    customerID: null,
    customerAddressID: 0,
    customerObj: {},
    addresses: [],
    showAddPopup: false,
    states: {
      selected: null,
      data: [],
      disabled: true,
    },
    cities: {
      selected: null,
      data: [],
      disabled: true,
    },
    addressTypeID: {
      selected: null,
      data: [],
      disabled: true,
    },
    tableLoading: true,
    currentAddress: {},
    popupMode: "add",
    dummyVar: "",
    pincodea: 121212,
    isMapInitialized: true,
    selectedAddressTypeValue: "",
  };
  mapHolder = React.createRef();
  searchInput = createRef();
  pinCodeRef = createRef();
  latInput = createRef();
  lonInput = createRef();
  form = React.createRef();
  componentDidMount() {
    if (this.props.customerID === 0) {
      this.props.history.goBack();
    } else {
      this.getCustomer(this.props.customerID);
    }

    this.getStateList();
    this.getAddresses(this.props.customerID);
    this.getAddressTypes();
  }

  handleModalLoad() {
    var isPopupRendered = this.mapHolder.current;
    if (this.state.isMapInitialized == false && isPopupRendered == null) {
      this.setState({ dummyVar: 1 });
    }
    if (this.state.isMapInitialized == false && isPopupRendered != null) {
      // this.InitGoogleMapSearch();
      this.InitGoogleMapAutocomplete();
      this.setState({ isMapInitialized: true });
    }
  }

  InitGoogleMapAutocomplete = () => {
    //load MAP
    let latval = this.form.current.getFieldValue("latitude");
    let myLatLng = { lat: 12.8795358, lng: 77.6345568 };
    if (latval !== undefined) {
      myLatLng = {
        lat: parseFloat(this.form.current.getFieldValue("latitude")),
        lng: parseFloat(this.form.current.getFieldValue("longitude")),
      };
    }
    const map = new window.google.maps.Map(this.mapHolder.current, {
      center: myLatLng,
      zoom: 15,
      mapTypeId: "roadmap",
    });

    // const southwest = { lat: 11.599208, lng: 77.832233 };
    // const northeast = { lat: 11.478537, lng: 78.594635 };
    // const newBounds = new window.google.maps.LatLngBounds(southwest, northeast);

    
    const options = {
      // fields: ["address", "geometry", "name"],
      // locationBias: {radius: 100, center: {lat: 37.402105, lng: -122.081974}},
      // LatLngBounds: newBounds,
      strictBounds: false,
      componentRestrictions: {
        country: "IN",
      },
    };

    const input = this.searchInput.current.input;

    const autocomplete = new window.google.maps.places.Autocomplete(
      input,
      options
    );

    autocomplete.bindTo("bounds", map);

    let markers = [];

    autocomplete.set("place", null);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      // console.log("place->", place);

      this.form.current.setFieldsValue({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        address: place.formatted_address,
      });

      var address_components = place.address_components;
      for (var j = 0; j < address_components.length; j++) {
        if (address_components[j]["types"].indexOf("postal_code") > -1) {
          this.form.current.setFieldsValue({
            pinCode: address_components[j]["long_name"],
          });
          // document.getElementById("pinCodeRef").value = address_components[j]["long_name"];
          // this.pinCodeRef.current.input.value = address_components[j]["long_name"];
          // this.setState({ pincode: address_components[j]["long_name"] });
        }
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      // markers = [];

      // For each place, get the icon, name and location.
      const bounds = new window.google.maps.LatLngBounds();
      if (place != null) {
        const icon = {
          // url: base_url + "images/account/map-pin.png",
          size: new window.google.maps.Size(71, 71),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
        };
        // Create a marker for each place.

        var marker = new window.google.maps.Marker({
          map,
          icon,
          title: place.name,
          draggable: true,
          animation: window.google.maps.Animation.DROP,
          position: place.geometry.location,
        });
        marker.geocodePosition = this.geocodePosition;
        window.google.maps.event.addListener(
          marker,
          "dragend",
          function (event) {
            marker.geocodePosition(
              marker.getPosition(),
              this.position.lat(),
              this.position.lng()
            );
          }
        );
        markers.push(marker);

        // console.log("marker->", marker);

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      }
      map.fitBounds(bounds);
    });

    //show on view
    // const myLatLng = { lat: parseFloat( this.form.current.getFieldValue('latitude')), lng: parseFloat( this.form.current.getFieldValue('longitude')) };
    // var myLatlng = new window.google.maps.LatLng(parseFloat( this.form.current.getFieldValue('latitude')),parseFloat( this.form.current.getFieldValue('longitude')));
    // console.log(myLatLng);
    new window.google.maps.Marker({
      position: myLatLng,
      map,
      center: myLatLng,
    });
  };

  InitGoogleMapSearch = () => {
    //load MAP
    let latval = this.form.current.getFieldValue("latitude");
    let myLatLng = { lat: 12.8795358, lng: 77.6345568 };
    if (latval !== undefined) {
      myLatLng = {
        lat: parseFloat(this.form.current.getFieldValue("latitude")),
        lng: parseFloat(this.form.current.getFieldValue("longitude")),
      };
    }
    const map = new window.google.maps.Map(this.mapHolder.current, {
      center: myLatLng,
      zoom: 15,
      mapTypeId: "roadmap",
    });
    const input = this.searchInput.current.input;
    const searchBox = new window.google.maps.places.SearchBox(input, {
      types: ["address"],
      componentRestrictions: {
        country: "IN",
      },
    });

    map.addListener("bounds_changed", () => {
      searchBox.setBounds(map.getBounds());
    });

    let markers = [];
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places.length == 0) {
        return;
      }

      this.form.current.setFieldsValue({
        latitude: places[0]["geometry"]["location"].lat(),
        longitude: places[0]["geometry"]["location"].lng(),
        address: places[0]["formatted_address"],
      });

      var address_components = places[0]["address_components"];
      for (var j = 0; j < address_components.length; j++) {
        if (address_components[j]["types"].indexOf("postal_code") > -1) {
          this.form.current.setFieldsValue({
            pinCode: address_components[j]["long_name"],
          });
          // document.getElementById("pinCodeRef").value = address_components[j]["long_name"];
          // this.pinCodeRef.current.input.value = address_components[j]["long_name"];
          // this.setState({ pincode: address_components[j]["long_name"] });
        }
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new window.google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        const icon = {
          // url: base_url + "images/account/map-pin.png",
          size: new window.google.maps.Size(71, 71),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
        };
        // Create a marker for each place.

        var marker = new window.google.maps.Marker({
          map,
          icon,
          title: place.name,
          draggable: true,
          animation: window.google.maps.Animation.DROP,
          position: place.geometry.location,
        });
        marker.geocodePosition = this.geocodePosition;
        window.google.maps.event.addListener(
          marker,
          "dragend",
          function (event) {
            marker.geocodePosition(
              marker.getPosition(),
              this.position.lat(),
              this.position.lng()
            );
          }
        );
        markers.push(marker);

        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });

    //show on view
    // const myLatLng = { lat: parseFloat( this.form.current.getFieldValue('latitude')), lng: parseFloat( this.form.current.getFieldValue('longitude')) };
    // var myLatlng = new window.google.maps.LatLng(parseFloat( this.form.current.getFieldValue('latitude')),parseFloat( this.form.current.getFieldValue('longitude')));
    // console.log(myLatLng);
    new window.google.maps.Marker({
      position: myLatLng,
      map,
      center: myLatLng,
    });
  };

  geocodePosition = (pos, lat, lan) => {
    this.form.current.setFieldsValue({
      latitude: lat,
      longitude: lan,
    });

    var geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      {
        latLng: pos,
      },
      this.geocodeResponseHandler
    );
  };
  geocodeResponseHandler = (results, status) => {
    if (status == window.google.maps.GeocoderStatus.OK) {
      var address_components = results[0]["address_components"];
      for (var j = 0; j < address_components.length; j++) {
        if (address_components[j]["types"].indexOf("postal_code") > -1) {
          this.form.current.setFieldsValue({
            address: results[0]["formatted_address"],
            pinCode: address_components[j]["long_name"],
          });
        }
      }

      // $("#mapErrorMsg").hide(100);
    } else {
      // $("#mapErrorMsg").html('Cannot determine address at this location.' + status).show(100);
    }
  };

  getAddressTypes = () => {
    this.customerService.getAddressTypes().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          addressTypeID: {
            ...this.state.addressTypeID,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Customer Master",
          description: this.common.Message(response.message)
            ? response.message
            : "Unable to get address types",
        });
      }
    });
  };
  getCustomer = (id) => {
    this.customerService.getCustomer(id).then((response) => {
      if (response.statusCode === 200) {
        this.setState({ customerObj: response.data });
      } else {
        notification["error"]({
          message: "Customer Master",
          description: response.message
            ? this.common.Message(response.message)
            : "Unable to get customer data",
          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
    });
  };

  getAddresses = (id) => {
    this.customerService.getCustomerAddresses(id).then((response) => {
      if (response.statusCode === 200) {
        let modifiedData = response.data.map((singleObj) => {
          singleObj.key = singleObj.customerAddressID;
          return singleObj;
        });
        this.setState({ addresses: modifiedData });
        this.setState({ tableLoading: false });
      } else {
        notification["error"]({
          message: "Customer Master",
          description: response.message
            ? this.common.Message(response.message)
            : "Unable to get customer addresses",
          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
    });
  };
  getStateList = () => {
    this.customerService.getStateList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          states: {
            ...this.state.states,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Customer Master",
          description: response.message
            ? this.common.Message(response.message)
            : "Unable to get list of states",
        });
      }
    });
  };

  getCityList = (cityID) => {
    this.customerService.getCityListByState(cityID).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          cities: {
            ...this.state.cities,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Customer Master",
          description: response.message
            ? this.common.Message(response.message)
            : "Unable to get list of states",
        });
      }
    });
  };

  addUpdateAddress(values) {
    const postData = {
      customerAddressID: this.state.currentAddress.customerAddressID
        ? this.state.currentAddress.customerAddressID
        : 0,
      customerID: this.state.customerObj.customerID,
      addressType:
        values.addressTypeID !== "Other"
          ? values.addressTypeID.toString()
          : values.otherAddressType,
      latitude:
        values.latitude !== undefined ? values.latitude.toString() : null,
      longitude:
        values.longitude !== undefined ? values.longitude.toString() : null,
      address: values.address,
      howToReach: values.howToReach !== undefined ? values.howToReach : null,
      houseNo: values.houseNo,
      landmark: values.howToReach !== undefined ? values.landmark : null,
      cityID: parseInt(values.cityID),
      stateID: parseInt(values.stateID),
      pinCode: values.pinCode,
      isDefault: [null, undefined, ""].includes(values.isDefault)
        ? false
        : values.isDefault,
    };
    this.setState({ modalLoading: true });
    this.customerService.addUpdateAddress(postData).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          description: response.message
            ? this.common.Message(response.message)
            : "Address Sucessfully Added",
          message: "Customer Master",
        });
      } else {
        notification["error"]({
          message: "Customer Master",
          description: response.message
            ? response.message
            : "Unable to add address",
        });
      }
      this.setState({ showAddPopup: false });
      this.setState({ modalLoading: false });
      this.getAddresses(this.props.customerID);
      this.setState({ isMapInitialized: false });
    });
  }
  handleViewEditAddress = (address, mode) => {
    this.getCityList(address.stateID.toString());
    const newAddress = {
      ...address,
      stateID: address.stateID.toString(),
      cityID: address.cityID.toString(),
      addressTypeID: address.addressType.toString(),
    };

    this.setState({ currentAddress: newAddress });
    this.setState({ popupMode: mode });
    this.setState({ showAddPopup: true });
    this.setState({ isMapInitialized: false });
  };
  handleDeleteAddress(addressID) {
    this.customerService
      .deleteCustomerAddress(parseInt(addressID))
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Customer Master",
            description: response.message
              ? response.message
              : "Address deleted successfully",
          });
          this.getAddresses(this.props.customerID);
        } else {
          notification["error"]({
            message: "Customer Master",
            description: response.message
              ? response.message
              : "Unable to delete address",
          });
        }
      });
  }
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.setState({ isMapInitialized: false });
  };
  columns = [
    {
      title: labels.ID,
      dataIndex: "customerAddressID",
      key: "customerAddressID",
      width: "3%",
    },
    {
      title: labels.TYPE,
      dataIndex: "addressType",
      key: "addressType",
    },
    {
      title: labels.ADDRESS,
      dataIndex: "address",
      key: "address",
    },
    {
      title: labels.CITY,
      dataIndex: "cityName",
      key: "cityName",
    },
    {
      title: labels.STATE,
      dataIndex: "stateName",
      key: "stateName",
    },
    {
      title: labels.PINCODE,
      dataIndex: "pinCode",
      key: "pinCode",
    },
    {
      title: labels.ACTIONS,
      key: "actions",
      width: "100px",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title={labels.VIEW}>
            <Button
              type="link"
              variant="link"
              onClick={() => this.handleViewEditAddress(record, "view")}
            >
              <FontAwesomeIcon icon={faInfo} />
            </Button>
          </Tooltip>
          {/* <Button
            type="link"
            variant="link"
            onClick={() => this.handleViewEditAddress(record, "edit")}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button> */}
          {this.props.mode === "view" ? null : (
            <Popconfirm
              title={labels.ARE_YOU_SURE}
              onConfirm={() =>
                this.handleDeleteAddress(record.customerAddressID)
              }
              onCancel={() => null}
              okText={labels.YES}
              cancelText={labels.NO}
            >
              <Tooltip title={labels.DELETE}>
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  render() {
    const disableAll = this.state.popupMode === "view";
    return (
      <div>
        <PageHeader
          className={styles.SubHeader}
          title={
            labels.ADRESSES_OF +
            (this.state.customerObj.firstName
              ? this.state.customerObj.firstName
              : "") +
            " " +
            (this.state.customerObj.lastName
              ? this.state.customerObj.lastName
              : labels.CUSTOMER)
          }
          extra={[
            this.props.mode === "view" ? null : (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => {
                  this.setState({
                    showAddPopup: true,
                    popupMode: "add",
                    currentAddress: {},
                    isMapInitialized: false,
                  });
                }}
                key="1"
              >
                {labels.ADD_ADDRESS}
              </Button>
            ),
          ]}
        />
        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.addresses}
          loading={this.state.tableLoading}
          pagination={false}
        />
        <Modal
          width={600}
          title={[
            this.state.popupMode === "add"
              ? labels.ADD_ADDRESS
              : this.state.popupMode === "view"
              ? labels.VIEW_ADDRESS
              : labels.EDIT_ADDRESS,
          ].join(" ")}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              ref={this.form}
              layout="vertical"
              preserve={false}
              onFinish={(values) => {
                this.addUpdateAddress(values);
              }}
              initialValues={this.state.currentAddress}
              destroyOnClose
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item label={"Search"} name="Search">
                    <Input
                      id="pac-input"
                      ref={this.searchInput}
                      placeholder={"Search"}
                      disabled={disableAll}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <div
                    id="mapHolderid"
                    style={{ width: "100%", height: 300 }}
                    ref={this.mapHolder}
                  ></div>
                </Col>
                {/* {this.InitGoogleMapSearch} */}
                {this.InitGoogleMapAutocomplete}
                <Col xs={8}>
                  <Form.Item
                    label={labels.TYPE}
                    name="addressTypeID"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_ADDRESS_TYPE,
                      },
                    ]}
                  >
                    <Select
                      disabled={this.state.addressTypeID.disabled || disableAll}
                      onChange={(event) => {
                        this.setState({ selectedAddressTypeValue: event });
                      }}
                      placeholder={labels.SELECT_TYPE}
                    >
                      <Option value="Home">{labels.HOME}</Option>
                      <Option value="Office">{labels.OFFICE}</Option>
                      <Option value="Other">{labels.OTHER}</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label={labels.LONGITUDE}
                    name="longitude"
                    rules={[
                      {
                        pattern: RegexDecimalAndRestrictFirstZero,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      id="lonInput"
                      disabled={disableAll}
                      placeholder={labels.ENTER_LONGITUDE}
                      ref={this.lonInput}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label={labels.LATITUDE}
                    name="latitude"
                    rules={[
                      {
                        pattern: RegexDecimalAndRestrictFirstZero,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      disabled={disableAll}
                      ref={this.latInput}
                      // ref={(ref) => this.latInput = ref}
                      id="latInput"
                      placeholder={labels.ENTER_LATITUDE}
                    />
                  </Form.Item>
                </Col>
                {this.state.selectedAddressTypeValue === "Other" &&
                this.state.popupMode != "view" ? (
                  <Col xs={24}>
                    <Form.Item
                      label={labels.OTHER_TYPE}
                      name="otherAddressType"
                      rules={[
                        {
                          required: true,
                          message: labels.PLEASE_ENTER_OTHER_TYPE,
                        },
                      ]}
                    >
                      <Input
                        disabled={disableAll}
                        maxLength={10}
                        placeholder={labels.ENTER_OTHER_TYPE}
                      />
                    </Form.Item>
                  </Col>
                ) : null}
                <Col xs={8}>
                  <Form.Item
                    label={labels.STATE}
                    name="stateID"
                    rules={[
                      { required: true, message: labels.PLEASE_SELECT_STATE },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={labels.SELECT_STATE}
                      onChange={(value) => {
                        this.setState(
                          {
                            states: { ...this.state.states, selected: value },
                          },
                          () => this.getCityList(value)
                        );
                      }}
                      disabled={this.state.states.disabled || disableAll}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.states.data.length !== 0
                        ? this.state.states.data.map((state) => {
                            return state.value !== "-1" ? (
                              <Option value={state.value} key={state.value}>
                                {state.text}
                              </Option>
                            ) : null;
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label={labels.CITY}
                    name="cityID"
                    rules={[
                      { required: true, message: labels.PLEASE_SELECT_CITY },
                    ]}
                    // dependencies={["state"]}
                    shouldUpdate={(prev, next) => prev.state !== next.state}
                  >
                    <Select
                      showSearch
                      placeholder={labels.SELECT_CITY}
                      /**
                       * Change the object in state and enable the dependant dropdown
                       */
                      onChange={(value) =>
                        this.setState({
                          cities: { ...this.state.cities, selected: value },
                          zones: { ...this.state.zones, disabled: false },
                        })
                      }
                      disabled={this.state.cities.disabled || disableAll}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.cities.data.length !== 0
                        ? this.state.cities.data.map((city) => {
                            return city.value !== "-1" ? (
                              <Option value={city.value} key={city.value}>
                                {city.text}
                              </Option>
                            ) : null;
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label={labels.PINCODE}
                    name="pinCode"
                    rules={[
                      { required: true, message: labels.PLEASE_ENTER_PINCODE },
                      {
                        pattern: RegexInteger,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      id="pinCodeRef"
                      // ref={this.pinCodeRef}
                      disabled={disableAll}
                      maxLength={6}
                      placeholder={labels.ENTER_PINCODE}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={labels.HOUSE_NO}
                    name="houseNo"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_HOUSE_NO,
                      },
                    ]}
                  >
                    <Input
                      disabled={disableAll}
                      maxLength={45}
                      placeholder={labels.ENTER_HOUSE_NO}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label={labels.LANDMARK} name="landmark">
                    <Input
                      disabled={disableAll}
                      placeholder={labels.ENTER_LANDMARK}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label={labels.HOW_TO_REACH} name="howToReach">
                    <Input
                      disabled={disableAll}
                      placeholder={labels.ENTER_HOW_TO_REACH}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label={labels.ADDRESS}
                    name="address"
                    rules={[
                      { required: true, message: labels.PLEASE_ENTER_ADDRESS },
                    ]}
                  >
                    <Input.TextArea
                      disabled={disableAll}
                      placeholder={labels.ENTER_ADDRESS}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item name="isDefault" valuePropName="checked">
                    <Checkbox disabled={disableAll}>
                      {labels.IS_DEFAULT}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.popupMode === "view" ? (
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        {labels.CANCEL}
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.popupMode === "add"
                            ? labels.ADD
                            : labels.UPDATE}
                        </Button>
                      </React.Fragment>
                    )}
                    {this.handleModalLoad()}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
export default withRouter(Addresses);
