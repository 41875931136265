import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class ReadyForDeliverySplitOrder {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async retrieveItems(
    index = 0,
    size = 0,
    sortColumn = null,
    sortOrder = null,
    search = null,
    storeIDs = null,
    customerID = null,
    splitOrderNumber = null,
    fromOrderDate = null,
    toOrderDate = null,
    invoiceNumber = null,
    invoiceDate = null,
    performaNumber = null,
    perfomaInvoiceDate = null,
    invoiceStatus = null,
    orderType = null,
    IsCheckNow=null,
    splitOrderStatus = null,
  ) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.SPILT_ORDER_READY_FOR_INVOICE,
    });
    let itemArray = [];
    let payload = {
      storeIDs: storeIDs,
      customerID: customerID,
      splitOrderNumber: splitOrderNumber,
      fromOrderDate: fromOrderDate,
      toOrderDate: toOrderDate,
      invoiceStatus: invoiceStatus,
      splitOrderStatus : splitOrderStatus,
      orderType: orderType,
      IsCheckNow:IsCheckNow,
      invoiceNumber: invoiceNumber,
      invoiceDate: invoiceDate,
      performaNumber: performaNumber,
      perfomaInvoiceDate: perfomaInvoiceDate,
      pageIndex: index,
      pageSize: size,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      commonSearch: search,
    };

    await myApi.endpoints[this.endpoint.SPILT_ORDER_READY_FOR_INVOICE]
      .getAllPost(payload)
      .then(({ data }) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(
          index,
          size,
          sortColumn,
          sortOrder,
          search,
          storeIDs,
          customerID,
          splitOrderNumber,
          fromOrderDate,
          toOrderDate,
          invoiceNumber,
          invoiceDate,
          performaNumber,
          perfomaInvoiceDate
        );
      });
    }
    return Promise.resolve(itemArray);
  }

  async SplitOrderMarkAsCompleted(splitOrderNumber) {
    const listApi = new API({ url: this.config.ITEM_STORE_URL });
    listApi.createEntity({
      name: this.endpoint.SPLIT_ORDER_MARK_DELIVERY_AS_DELIVERED,
    });
    let itemArray = [];

    let append = "?SplitOrderNo=" + splitOrderNumber;
    await listApi.endpoints[
      this.endpoint.SPLIT_ORDER_MARK_DELIVERY_AS_DELIVERED
    ]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.SplitOrderMarkAsCompleted(splitOrderNumber);
      });
    }
    return Promise.resolve(itemArray);
  }
  async searchCustomer(value) {
    const stateListAPI = new API({ url: this.config.ITEM_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_SEARCH_CUSTOMER });
    let serviceTypes = [];

    let append = "?SearchString=" + value;
    await stateListAPI.endpoints[this.endpoint.GET_SEARCH_CUSTOMER]
      .getOne(append)
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.searchCustomer(value);
      });
    }
    return Promise.resolve(serviceTypes);
  }

  async getInvoiceStatusList(invoiceStatus) {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({
      name: this.endpoint.GET_SYSTEME_NUMS,
    });

    let append = invoiceStatus === 1
    ? "?EnumNames=InvoiceStatus"
    : invoiceStatus === 2
      ? "?EnumNames=SplitOrderStatus"
      : "?EnumNames=StoreServiceType";

    // let append =
    //   invoiceStatus === 1
    //     ? "?EnumNames=InvoiceStatus"
    //     : "?EnumNames=StoreServiceType";
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_SYSTEME_NUMS]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getInvoiceStatusList(invoiceStatus);
      });
    }
    return itemArray;
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}
