import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  // TimePicker,
  Input,
  DatePicker,
  Space,
  notification,
  Radio,
} from "antd";
import Button from "../../../Common/Button/button";
import CorporateService from "../../../../services/corporateservice";
import { RegexLetters, MessageLetters } from "../../../../shared/validator";
const { Option } = Select;
/**
 * General form as a part of create factory screen
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 */
export default class GeneralForm extends React.Component {
  /**
   * Initalizing Factory Service
   */
  corporateService = new CorporateService();

  /**
   * Storing objects for dynamic controls
   *
   * @added 18 June 2020
   */
  state = {
    billingCycle: {
      disabled: true,
      data: [],
    },
  };
  /**
   * API Calls on component mounting to populate dropdowns
   * @added 18 June 2020
   */
  componentDidMount() {
    this.getBillingCycles();
  }
  getBillingCycles() {
    this.corporateService.getBillingCycle().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          billingCycle: {
            ...this.state.billingCycle,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Corporate Master",
          description: response.message
            ? response.message
            : "Unable to get data for billing cycle list",
        });
      }
    });
  }
  render() {
    const disableAll = this.props.mode === "view";
    return (
      <Form
        key={this.props.values.corporateName}
        name="general"
        layout="vertical"
        initialValues={this.props.values}
        ref={this.props.instance}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={24}>
            <Form.Item
              label="Corporate Name"
              name="corporateName"
              rules={[
                { required: true, message: "Please Enter Corporate Name" },
                // {
                //   pattern: RegexLetters,
                //   message: MessageLetters,
                // },
              ]}
            >
              <Input placeholder="Enter Corporate Name" disabled={disableAll} />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="GST Number"
              name="gstNo"
              validateFirst
              rules={[
                { required: true, message: "Please Enter GST Number" },
                {
                  len: 15,
                  message: "Enter valid GST Number",
                },
              ]}
            >
              <Input placeholder="Enter GST Number" disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="Purchase Order Number" name="poNo">
              <Input placeholder="Enter PO Number" disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="Purchase Order Validity" name="poValidity">
              <DatePicker
                placeholder="Select PO Validity"
                disabled={disableAll}
                format={"DD-MM-YYYY"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={16}>
            <Form.Item label="Special Economic Zone Information" name="sez">
              <Input placeholder="Enter Information" disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Billing Cycle"
              name="billingCycle"
              rules={[
                { required: true, message: "Please Select Billing Cycle" },
              ]}
              dependencies={["customerType"]}
            >
              <Select
                placeholder="Select Billing Cycle"
                onChange={() => null}
                disabled={this.state.billingCycle.disabled || disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.billingCycle.data.length !== 0
                  ? this.state.billingCycle.data.map((cycle) => {
                      return cycle.value !== "-1" ? (
                        <Option value={cycle.value} key={cycle.value}>
                          {cycle.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="Payment Terms" name="paymentTerms">
              <Input placeholder="Enter Payment Terms" disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Pickup Frequency"
              name="pickupFrequency"
              rules={[
                { required: true, message: "Please Select Pickup Frequency" },
              ]}
              dependencies={["customerType"]}
            >
              <Select
                placeholder="Select Pickup Frequency"
                onChange={() => null}
                disabled={this.state.billingCycle.disabled || disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.billingCycle.data.length !== 0
                  ? this.state.billingCycle.data.map((cycle) => {
                      return cycle.value !== "-1" ? (
                        <Option value={cycle.value} key={cycle.value}>
                          {cycle.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Delivery Charges"
              name="isDeliveryCharge"
              rules={[
                { required: true, message: "Please Select Delivery Charges" },
              ]}
            >
              <Radio.Group disabled={disableAll}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} style={{ textAlign: "right" }}>
            <Space>
              <div className="w-100"></div>
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                Cancel
              </Button>
              <Button
                onClick={this.props.nextFunction}
                variant="purple"
                shape="round"
                size="large"
              >
                Next
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
