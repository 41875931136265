import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Navigation from "./Navigation";
//import { LanguageProvider } from './shared/containers/Language';
//import LanguageSelector from './components/localizationcomponent/LanguageSelector';
//import Explore from './components/localizationcomponent/Explore';

function App() {
  return (
    <div className="App">
      <BrowserRouter forceRefresh={true}>
      {/* <BrowserRouter basename={'/lndkui'}  forceRefresh={true}> */}
        <Navigation />
      </BrowserRouter>
    </div>
  );
}

export default App;
