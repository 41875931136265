import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class CashCollection {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getCashCollectionEntries(
    index = 0,
    size = 0,
    sortColumn = null,
    sortOrder = null,
    search = null,
    storeID = null,
    userID = null,
    cashStatus = null,
    fromDate = null,
    toDate = null
  ) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_STORE_URL });
    allFactoriesAPI.createEntity({
      name: this.endpoint.GET_CASH_COLLECTION_DATA,
    });
    let factories = [];
    let payload = {
      storeIDs: storeID,
      executiveUserID: userID,
      status: cashStatus,
      fromDate: fromDate, // "2020-10-01",
      toDate: toDate, //"2020-10-26",
      pageIndex: index,
      pageSize: size,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      commonSearch: search,
    };
    await allFactoriesAPI.endpoints[this.endpoint.GET_CASH_COLLECTION_DATA]
      .getAllPost(payload)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getCashCollectionEntries(
          index,
          size,
          sortColumn,
          sortOrder,
          search,
          storeID,
          userID,
          cashStatus,
          fromDate,
          toDate
        );
      });
    }
    return Promise.resolve(factories);
  }

  async submitCashCollectionData(postData) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.SUBMIT_CASH_COLLECTION_DATA });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.SUBMIT_CASH_COLLECTION_DATA]
      .create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.submitCashCollectionData(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

      async getAccountExecutiveByStoreID(storeid) {
    const userAPI = new API({ url: this.config.DROPDOWN_URL });
    userAPI.createEntity({
      name: this.endpoint.GET_ACCOUNT_EXEC_LIST_BY_STOREID,
    });
    let users = [];
    const idParam = "?StoreID=" + storeid;
    await userAPI.endpoints[this.endpoint.GET_ACCOUNT_EXEC_LIST_BY_STOREID]
      .getOne(idParam)
      .then((response) => {
        users = response.data;
      })
      .catch((err) => {
        users = this.common.handleError(err);
      });
    if (users.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        users = this.getAccountExecutiveByStoreID(storeid);
      });
    }
    return Promise.resolve(users);
  }

  // handleError(error) {
  //   console.log(error.message);
  // }
}
