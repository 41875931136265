import React from "react";
import styles from "./box.module.css";
/**
 * A component to render a card-like box with shadow
 * Generally used to wrap a content of the screen/card
 *
 * @prop sub : if sub is applied, it won't take full viewport height
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 1 June 2020
 */

export default class Box extends React.Component {
  render() {
    return (
      <div
        className={[
          this.props.className,
          styles.Box,
          this.props.sub ? styles.SubBox : null,
        ].join(" ")}
      >
        {this.props.children}
      </div>
    );
  }
}
