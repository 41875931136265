import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  PageHeader,
  // TimePicker,
  Input,
  // DatePicker,
  Space,
  notification,
  // Radio,
  Descriptions,
  Upload,
  Collapse,
} from "antd";
import Button from "../../Common/Button/button";
// import styles from "../../Common/Styles/master.module.css";
import styles from "./transferincomponent.module.css";
import CorporateService from "../../../services/corporateservice";
import { BarcodeOutlined } from "@ant-design/icons";
import Common from "../../../services/common";
import TransferInStore from "../../../services/storetransferinservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
var jwt = require("jsonwebtoken");

const { Option } = Select;
const { Panel } = Collapse;

/**
 * General form as a part of create factory screen
 *
 * @author Payal
 * @created 07 August 2020
 */
export default class Master extends React.Component {
  /**
   * Initalizing Factory Service
   */
  transferInStore = new TransferInStore();
  UserRightsConfiguration = new UserRightsConfiguration();
  commonReq = new Common();

  state = {
    submitButtonLoading: false,
    stickerLoaded: false,
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    stickerID: "",
    garmentData: [],
    orderData: [],
    isSaveDisable: true,
    stickerStatus: "",
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.STORE_TRANSFER_IN
    );

    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  handleStoreTransferIn = () => {
    var stickerID = parseInt(this.state.stickerID);
    var storeID = parseInt(localStorage.getItem("branchID"));
    var userID = parseInt(
      jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
        .unique_name
    );
    var CustomerID = parseInt(this.state.orderData[0].customerID)
    console.log("customerID->",CustomerID);
    this.setState({ submitButtonLoading: true });
    this.transferInStore
      .requestStoreTransferin(stickerID, storeID, userID,CustomerID)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: labels.TRANSFER_IN,
            description: labels.DATA_SUCCESSFULLY_SUBMITTED,
          });
          this.setState({ stickerLoaded: false, stickerID: "" });
        } else {
          notification["error"]({
            message: labels.TRANSFER_IN,
            description: labels.UNABLE_TO_SUBMIT_STICKER_DATA,
          });
        }
        this.setState({ submitButtonLoading: false });
      });
  };

  GetStickerInfo = () => {
    if (this.state.stickerID != "") {
      var stickerID = parseInt(this.state.stickerID);
      var storeID = parseInt(localStorage.getItem("branchID"));

      this.transferInStore
        .getStickerByStickerID(stickerID, storeID)
        .then((items) => {
          if (items.statusCode === 200) {
            if (
              items.data.responseStatus === 1
              // items.data.lstGarmentData.length > 0 ||
              // items.data.lstOrderData.length > 0
            ) {
              this.setState({
                garmentData: items.data.lstGarmentData,
                orderData: items.data.lstOrderData,
                stickerLoaded: true,
              });
              this.setState({
                stickerStatus: items.data.lstOrderData[0].stickerStatus,
              });
            } else {
              notification["error"]({
                message: labels.TRANSFER_IN,
                description: this.commonReq.Message(items.data.responseMessage), //labels.UNABLE_TO_GET_STICKER_DATA,
              });
              this.setState({
                garmentData: [],
                orderData: [],
                stickerLoaded: false,
              });
            }
            // if (
            //   this.state.orderData.length > 0 &&
            //   this.state.garmentData.length > 0
            // ) {
            //   this.setState({
            //     stickerLoaded: !this.state.stickerLoaded,
            //   });
            // } else {
            //   notification["error"]({
            //     message: "Unable to load sticker data",
            //   });
            // }
          } else {
            notification["error"]({
              message: labels.TRANSFER_IN,
              description: labels.UNABLE_TO_GET_STICKER_DATA,
            });
          }
        });
    } else {
      notification["error"]({
        message: labels.TRANSFER_IN,
        description: labels.PLEASE_ENTER_STICKER_ID,
      });
    }
  };

  onChange = (e) => {
    this.setState({ stickerID: e.target.value });
  };

  render() {
    const { orderData, garmentData } = this.state;
    var packedInfo = this.state.garmentData;

    var countPackedInfo = packedInfo.reduce((items, item) => {
      items[item.packingMaterialName] = items[item.packingMaterialName] || "";
      items[item.packingMaterialName]++;
      return items;
    }, {});
    return (
      <div>
        <PageHeader title={labels.TRANSFER_IN} />
        <br />
        <Form
          name="general"
          layout="vertical"
          // onFinish={this.handleStoreTransferIn}
          initialValues={this.props.values}
        >
          <Row gutter={30}>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                label={labels.STIKER_NUMBER}
                name="stickerID"
                rules={[
                  { required: true, message: labels.PLEASESCAN_ADD_STICKER },
                ]}
              >
                <div className="w-100" style={{ display: "flex" }}>
                  <Input
                    placeholder={labels.ENTER_SCAN_STICKER} //"Enter/Scan Sticker"
                    onChange={this.onChange}
                    value={this.state.stickerID}
                    onPressEnter={this.GetStickerInfo}
                    // onBlur={this.GetStickerInfo}
                    suffix={<BarcodeOutlined />}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>

          {this.state.stickerLoaded ? (
            <>
              <Descriptions
                title={labels.STICKER_INFORMATION}
                bordered
                size="small"
                column={3}
              >
                {/* <Descriptions.Item label={labels.CUSTOMER_NAME} span={3}>
                   {orderData[0].customerName} 
                </Descriptions.Item>
                <Descriptions.Item label={labels.CUSTOMER_TYPE}>
                   {orderData[0].customerTypeName} 
                </Descriptions.Item>
                <Descriptions.Item label={labels.CUSTOMER_CONTACT}>
                   {orderData[0].customerContactNo} 
                </Descriptions.Item>
                <Descriptions.Item label={labels.DELIVERY_TYPE}>
                   {orderData[0].deliveryTypeName} 
                </Descriptions.Item>
                <Descriptions.Item label={labels.ORDER_DATE}>
                   {Moment(orderData[0].orderDate).format("DD-MM-YYYY")} 
                </Descriptions.Item> */}
                <Descriptions.Item label={labels.SPLIT_ORDER_NO}>
                  {orderData[0].splitOrderNumber}
                </Descriptions.Item>
                {/* <Descriptions.Item label={labels.GATE_PASS_NO}>
                  {orderData[0].gatePassNumber}
                </Descriptions.Item>
                <Descriptions.Item label={labels.DELIVERY_DATE}>
                  {Moment(orderData[0].deliveryDate).format("DD-MM-YYYY")}
                </Descriptions.Item>
                <Descriptions.Item label={labels.GARMENTS_IN_PROCESS}>
                  {orderData[0].numberOfGarmentInProcess}
                </Descriptions.Item>
                <Descriptions.Item label={labels.PACKED_GARMENTS}>
                  {orderData[0].packedGarments}
                </Descriptions.Item>
                <Descriptions.Item label={labels.PACKING_MATERIAL}>
                  {orderData[0].packingMaterialName}
                </Descriptions.Item>
                <Descriptions.Item label={labels.GATE_PASS_DATE}>
                  {Moment(orderData[0].gatePassDate).format("DD-MM-YYYY")}
                </Descriptions.Item> */}
                <Descriptions.Item label={labels.STICKER_STATUS}>
                  {orderData[0].stickerStatusName}
                </Descriptions.Item>
                {/* <Descriptions.Item label={labels.SPLIT_ORDER_STATUS}>
                  {orderData[0].splitOrderStatusName}
                </Descriptions.Item> */}
              </Descriptions>
            </>
          ) : null}

          <Row gutter={30} align="bottom">
            <Col xs={24} md={12} xl={6}>
              {/* {this.state.stickerLoaded ? (
                <Descriptions
                  title={labels.GARMENTS_PACKED_INFORMATION}
                  bordered
                  size="small"
                  column={1}
                >
                  {Object.keys(countPackedInfo).map((key) => {
                    return (
                      <Descriptions.Item label={key}>
                         {countPackedInfo[key]} 
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              ) : null} */}
            </Col>
            <Col xs={12}></Col>
            <Col xs={24} xl={6} style={{ textAlign: "right" }}>
              <Button
                // htmlType="submit"
                variant="purple"
                shape="round"
                size="large"
                onClick={this.handleStoreTransferIn}
                disabled={
                  this.state.stickerLoaded
                    ? this.state.stickerStatus === 2
                      ? false
                      : this.state.isSaveDisable
                    : this.state.isSaveDisable
                }
                loading={this.state.submitButtonLoading}
              >
                {labels.SUBMIT}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
