import React, { Component } from 'react'
import FivestarFactoryComponent from '../../../components/FactoryModule/DailySheet/fivestar.factory.component';


export default class fivestarfactoryscreen extends Component {
  render() {
    return (
      <FivestarFactoryComponent/>
    )
  }
}
