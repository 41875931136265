import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class PaytmEDCMachineConfigService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getActiveUserList() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_ACTIVE_USER_LIST });
    let users = [];
    await myApi.endpoints[this.endpoint.GET_ACTIVE_USER_LIST]
      .getAll()
      .then((response) => {
        users = response.data;
      })
      .catch((err) => {
        users = this.common.handleError(err);
      });
    if (users.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        users = this.getActiveUserList();
      });
    }
    return Promise.resolve(users);
  }

  async getStateDetailByStateId(StateID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_STATE_DETAIL_BY_ID });
    let itemArray = [];
    let ID = "?StateID=" + StateID;
    await myApi.endpoints.GetStateDetailByStateID.getById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getStateDetailByStateId(StateID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async removeItems(edcInfoId) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.DELETE_PAYTM_EDC_MACHINE_MASTER });
    let itemArray = [];
    let ID = "?edcInfoIds=" + edcInfoId;
    await myApi.endpoints[this.endpoint.DELETE_PAYTM_EDC_MACHINE_MASTER]
      .deleteById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.removeItems(edcInfoId);
      });
    }
    return Promise.resolve(itemArray);
  }

  async insertUpdateItems(postData) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.ADD_UPDATE_PAYTM_EDC_MACHINE_INFO,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.ADD_UPDATE_PAYTM_EDC_MACHINE_INFO]
      .create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.insertUpdateItems(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveItems(param) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_PAYTM_EDC_MASTER });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_PAYTM_EDC_MASTER]
      .getAllPost(param)
      .then(({ data }) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}

export default PaytmEDCMachineConfigService;
