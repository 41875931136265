import React from "react";
// import styles from "./dashboardscreen.module.css";
import { Row, Col, Spin } from "antd";
import Stats from "../../../components/FactoryModule/Dashboard/stats.component";
import Charts from "../../../components/FactoryModule/Dashboard/charts.component";
// import QCCheck from "../../../components/FactoryModule/Dashboard/qccheck.component";
// import QuickActions from "../../../components/FactoryModule/Dashboard/quickactions.component";
import NoticeBoard from "../../../components/StoreModule/Dashboard/noticeboard.component";
import Box from "../../../components/Common/Box/box";
/**
 * View of Dashboard screen
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 15 June 2020
 */
export default class Dashboard extends React.Component {
  state = {
    fullLoading: false,
  };
  toggleLoading = (value) => {
    this.setState({ fullLoading: value });
  };
  render() {
    return (
      <Spin spinning={this.state.fullLoading}>
        <Row gutter={30}>
          <Col xs={24}>
            <Stats toggleLoading={this.toggleLoading} />
          </Col>
          <Col xs={12}>
            <Charts toggleLoading={this.toggleLoading} />
          </Col>
          <Col xs={12}>
            <Box sub>
              <NoticeBoard toggleLoading={this.toggleLoading} />
            </Box>
          </Col>
        </Row>
        {/* <Row gutter={28}>
          <Col xs={24} lg={18}>
            <QCCheck />
          </Col>
          <Col xs={24} lg={6}>
            <QuickActions />
          </Col>
        </Row> */}
      </Spin>
    );
  }
}
