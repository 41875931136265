import React from "react";
import styles from "./transfercomponent.module.css";
import {
  PageHeader,
  notification,
  Tag,
  Table,
  Transfer as AntTransfer,
  Input,
  Popover,
  Select,
  Modal,
  Descriptions,
  Checkbox,
} from "antd";
import InfoIcon from "../../../images/Common/info.svg";
import TransferInService from "../../../services/factorytransferinservice";
import { IsAuthenticated } from "../../../services/auth-header";
import Button from "../../Common/Button/button";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import ColorPicker from "../../Common/ColorPicker/colorpicker";
import Common from "../../../services/common";
import Moment from "moment";
import { BarcodeOutlined } from "@ant-design/icons";
import { labels } from "../../../shared/language";

var jwt = require("jsonwebtoken");

const { Option } = Select;

//  var data=[]
//   for (let i = 0; i <10; i++) {
//       data.push({
//         key: i.toString(),
//         title:`ID${i + 1}`,
//       });
//     }
/**
 * This file holds the view of transfer in/out component
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 02 July 2020
 */
export default class TransferIn extends React.Component {
  TransferInService = new TransferInService();
  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();
  /**
   * The state variable holds the different variables for different purpose
   *
   * @var targetKeys : the key/id of the elements on the right side, id provided in data source
   * @var selectedKeys : if want to check a few items by default
   */
  state = {
    scanBtnLoading: false,
    targetKeys: [],
    // selectedKeys: [],
    storeKeys: [],
    factoryKeys: [],

    transferInData: [],
    missingTagData: [],
    loading: true,
    TagNos: "",
    storeListOptions: [],
    selectedStore: "",
    storeOutTags: [],
    filteredTagsData: [],

    showInfoPopup: false,
    btnMissedTagEnable: true,
    SplitOrderNumber: "",
    EnteredLotSize: null,
    OpenLotSizeModal: false,
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.FACTORY_IN
    );
    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
  }

  componentDidMount() {
    this.getStoreList();
    // var targetTags =
    //   localStorage.getItem("Tags") === null
    //     ? ""
    //     : localStorage.getItem("Tags").split(",");

    // this.setState({ targetKeys: targetTags });

    // var targetTags =
    //   localStorage.getItem("SplitOrderNumbers") === null
    //     ? ""
    //     : localStorage.getItem("SplitOrderNumbers").split(",");

    // // console.log("targetTags->", targetTags);
    // this.setState({ targetKeys: targetTags });
  }

  getStoreOutGarments = () => {
    if (this.state.selectedStore != "") {
      this.setState({ btnMissedTagEnable: false });
    }
    const params = {
      // storeID: parseInt(this.state.selectedStore),
      storeIDs: this.state.selectedStore,
      factoryID: parseInt(localStorage.getItem("branchID")),
    };
    this.TransferInService.getStoreOutGarmentsById(params).then((response) => {
      if (response.statusCode === 200 && response.data.length > 0) {
        this.setState({ storeOutTags: response.data });
      } else {
        if (this.state.selectedStore != "") {
          notification["error"]({
            message: labels.TRANSFER_IN,
            description: labels.TAGS_NOT_FOUND,
          });
        }
        this.setState({ storeOutTags: [], btnMissedTagEnable: true });
      }
    });
  };

  getStoreList = () => {
    this.TransferInService.retrieveStoreList().then((items) => {
      if (items.statusCode === 200) {
        this.setState({ storeListOptions: items.data });
      } else {
        notification["error"]({
          message: labels.TRANSFER_IN,
          description: items.message
            ? this.common.Message(items.message)
            : labels.UNABLE_TO_GET_LIST_OF_STORE_DATA,
        });
      }
    });
  };

  handlePopupCancel = () => {
    this.setState({ showInfoPopup: false });
  };

  /**
   * A function to handle change in the transfer component
   */
  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });
    if (direction === "right") {
      // localStorage.setItem("Tags", nextTargetKeys);
      localStorage.setItem("SplitOrderNumbers", nextTargetKeys);
    }
  };

  /**
   * A function to handle change occured on selecting a single element
   *
   */
  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({
      storeKeys: [...sourceSelectedKeys],
      factoryKeys: [...targetSelectedKeys],
    });
  };

  /**
   * A function to render single item in transfer block
   * a single garment entry here
   * pass any number of variables in storedata and you can get it in item object
   */
  renderItem = (item) => {
    return (
      <div className={styles.SingleItem}>
        <span>
          {item.title}
          {/* - {item.description} */}
        </span>
        <span>
          {/**
           * Please find the documentation for Popover
           * https://ant.design/components/popover
           * https://ant.design/components/popover/#API
           */}
          <Popover content={item.store} placement="topRight" arrowPointAtCenter>
            <img
              src={InfoIcon}
              alt="info"
              onClick={(e) => e.stopPropagation()}
            />
          </Popover>
        </span>
      </div>
    );
  };

  Missingcolumns = [
    {
      title: labels.STORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: labels.SPLIT_ORDER_NO,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
    },
    {
      title: labels.MISSING_TAG_NOS,
      dataIndex: "missingTagNos",
      key: "missingTagNos",
    },
  ];

  getMissingGarments = () => {
    var currentDate = Moment().format("YYYY-MM-DD");
    const params = {
      storeIDs: this.state.selectedStore,
      factoryID: parseInt(localStorage.getItem("branchID")),
      factoryInTime: currentDate,
    };
    this.TransferInService.getMissingGarments(params).then((items) => {
      if (items.statusCode === 200) {
        this.setState({
          missingTagData: items.data,
          showInfoPopup: items.data.length > 0 ? true : false,
          selectedStore: "",
          btnMissedTagEnable: true,
        });
        if (items.data.length == 0 && this.state.selectedStore != "") {
          notification["error"]({
            message: labels.TRANSFER_IN,
            description: labels.UNABLE_TO_GET_MISSING_TAG_DATA,
          });
        }
      } else {
        notification["error"]({
          message: labels.TRANSFER_IN,
          description: labels.UNABLE_TO_GET_MISSING_TAG_DATA,
        });
      }
      this.setState({ loading: false });
    });
  };

  transferredIntoFactory = () => {
    // console.log("EnteredLotSize->", this.state.EnteredLotSize);
    // console.log("targetKeys->", this.state.targetKeys);
    // console.log("filteredTagsData->", this.state.filteredTagsData);

    var tagNos = this.state.factoryKeys;
    var userId = jwt.decode(
      JSON.parse(localStorage.getItem("currentuser")).token
    ).unique_name;

    if (tagNos.length != 0) {
      const params = {
        tagNos: tagNos.toString(),
        userID: parseInt(userId),
      };
      this.setState({ scanBtnLoading: true });
      this.TransferInService.getFactoryTransferInData(params).then((items) => {
        if (items.statusCode === 200) {
          notification["success"]({
            message: labels.TRANSFER_IN,
            description: labels.TAGS_ARE_TRANSFERRED_IN_SUCCESSFULLY,
          });
          this.setState({
            transferInData: items.data,
          });
          if (this.state.transferInData.actionResult === "Valid") {
            this.setState({
              factoryKeys: [],
              targetKeys: [],
              // : "",
              btnMissedTagEnable: true,
              // TagNos: "",
              SplitOrderNumber: "",
              storeOutTags: [],
            });
            // localStorage.removeItem("Tags");
            localStorage.removeItem("SplitOrderNumbers");
            // this.getStoreOutGarments();
            this.getMissingGarments();
          }
        } else {
          notification["error"]({
            message: labels.TRANSFER_IN,
            description: labels.UNABLE_TO_SCAN_THE_TAG,
          });
        }
        this.setState({ scanBtnLoading: false });
        this.setState({ loading: false });
      });
    } else {
      notification["error"]({
        message: labels.TRANSFER_IN,
        description: labels.PLEASE_SELECT_FACTORY_TAG_NO_FOR_TRANSFERIN_PROCESS,
      });
    }
  };

  filterOption = (inputValue, option) => {
    return option.key.indexOf(inputValue) > -1;
  };

  searchStoreKeys = () => {
    // console.log("here", this.state.storeOutTags);
    // var searchingTag = this.state.TagNos;
    var searchingSplitNumber = this.state.SplitOrderNumber;
    if (this.state.selectedStore != "") {
      if (searchingSplitNumber != "") {
        var filtereddata = [];

        // var filtereddata = this.state.storeOutTags.filter(
        //           (items) => items.tagNo == searchingTag
        //         );

        var filtereddata = this.state.storeOutTags.filter(
          (items) => items.splitOrderNumber == searchingSplitNumber
        );

        // console.log("filtereddata->", filtereddata);

        if (filtereddata.length != 0) {
          this.setState({ filteredTagsData: filtereddata });
        } else {
          notification["error"]({
            message: labels.TRANSFER_IN,
            description: labels.ORDER_NOT_FOUND,
          });
        }
      } else {
        notification["error"]({
          message: labels.TRANSFER_IN,
          description: labels.PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_ORDER,
        });
      }
    } else {
      notification["error"]({
        message: labels.TRANSFER_IN,
        description: labels.PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_ORDER,
      });
    }
  };

  OnSubmitOrderLotSize = () => {
    var filtereddata = this.state.filteredTagsData;

    if (filtereddata[0].noOfGarment < parseInt(this.state.EnteredLotSize)) {
      notification["error"]({
        message: labels.TRANSFER_IN,
        description: "Please Enter valid Lot Size.",
      });
    } else if (
      filtereddata[0].noOfGarment > parseInt(this.state.EnteredLotSize)
    ) {
      this.setState({ OpenLotSizeModal: true });
    } else {
      this.handleOk();
    }
  };

  handleOk = () => {
    this.setState({ OpenLotSizeModal: false });

    var filtereddata = this.state.filteredTagsData;
    filtereddata[0].tempLotSize = this.state.EnteredLotSize;

    this.setState({ filteredTagsData: filtereddata });
    var searchingSplitNumber = this.state.SplitOrderNumber;
    var temparray = [];

    // for (let i = 0; i < filtereddata.length; i++) {
    //   console.log("tempdata->", tempdata);
    //   console.log("data123->", data1);
    //   var tempdata = filtereddata[i].tagNo;
    //   data1 = [...temparray, tempdata];
    //   temparray = data1;
    // }

    if (!this.state.targetKeys.includes(searchingSplitNumber)) {

      var editarray = [];
      var temparray = this.state.targetKeys;
      var templen=  this.state.EnteredLotSize;

      // console.log("templen",templen);

      for (let i = 0; i < templen; i++) {       
        var tempdata = filtereddata[i].tagNo;
        editarray = [...temparray, tempdata];
         temparray = editarray;
      }

      // console.log("editarray->", editarray);
      if (editarray.length > 150) {
        // console.log("in if");
        notification["error"]({
          message: labels.TRANSFER_IN,
          description:
            "You have reached maximum clothes limit, pls marks factory in before proceeding further.",
        });
      } else {
        // console.log("in else");
        this.setState({ targetKeys: editarray });
        localStorage.setItem("SplitOrderNumbers", editarray);
        // localStorage.setItem("Tags", editarray);
        // this.setState({ TagNos: "" });
        this.setState({ SplitOrderNumber: "" });
      }
      this.setState({
        EnteredLotSize: null,
      });
    } else {
      notification["error"]({
        message: labels.TRANSFER_IN,
        description: labels.ORDER_ALREADY_SCANNED,
      });
    }
  };

  handleCancel = () => {
    // console.log("Clicked cancel button");
    this.setState({ OpenLotSizeModal: false });
  };

  // searchStoreKeys1 = () => {
  //   var searchingTag = this.state.TagNos;
  //   if (this.state.selectedStore != "") {
  //     if (searchingTag != "") {
  //       var filtereddata = [];
  //       var filtereddata = this.state.storeOutTags.filter(
  //         (items) => items.tagNo == searchingTag
  //       );
  //       if (filtereddata.length != 0) {
  //         this.setState({ filteredTagsData: filtereddata });
  //         var data1 = filtereddata[0].tagNo;
  //         if (!this.state.targetKeys.includes(searchingTag)) {
  //           var editarray = [...this.state.targetKeys, data1];
  //           // console.log("editarray->", editarray);
  //           if (editarray.length > 150) {
  //             // console.log("in if");
  //             notification["error"]({
  //               message: labels.TRANSFER_IN,
  //               description:
  //                 "You have reached maximum clothes limit, pls marks factory in before proceeding further.",
  //             });
  //           } else {
  //             // console.log("in else");
  //             this.setState({ targetKeys: editarray });
  //             localStorage.setItem("Tags", editarray);
  //             this.setState({ TagNos: "" });
  //           }
  //         } else {
  //           notification["error"]({
  //             message: labels.TRANSFER_IN,
  //             description: labels.TAG_ALREADY_SCANNED,
  //           });
  //         }
  //       } else {
  //         notification["error"]({
  //           message: labels.TRANSFER_IN,
  //           description: labels.TAGS_NOT_FOUND,
  //         });
  //       }
  //     } else {
  //       notification["error"]({
  //         message: labels.TRANSFER_IN,
  //         description: labels.PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_TAG,
  //       });
  //     }
  //   } else {
  //     notification["error"]({
  //       message: labels.TRANSFER_IN,
  //       description: labels.PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_TAG,
  //     });
  //   }
  // };

  render() {
    const filteredTagsData = this.state.filteredTagsData;
    const { loading, TagNos } = this.state;

    const missingTagData = this.state.missingTagData;
    const listMissingData = missingTagData.map((item, index) => ({
      key: index,
      splitOrderNumber: item.splitOrderNumber,
      missingTagNos: item.missingTagNos,
      storeName: item.storeName,
    }));
    const storeOutTags = this.state.storeOutTags;
    // console.log("storeOutTags->", storeOutTags);
    const listItems = storeOutTags.map((item, index) => ({
      key: item.tagNo,
      tagNo: item.tagNo,
      gatePassNo: item.gatePassNo,
      orderGarmentID: item.orderGarmentID,
      splitOrderNumber: item.splitOrderNumber,
      garmentName: item.garmentName,
      length: item.length,
      width: item.width,
      colorNames: item.colorNames,
      brandName: item.brandName,
      sizeName: item.sizeName,
      specialInstruction: item.specialInstruction,
      factoryInTime: item.factoryInTime,
      garmentStatusName: item.garmentStatusName,
      dyeingColors: item.dyeingColors,
      sequenceNumber: item.sequenceNumber,
      serviceName: item.serviceName,
      addOnServiceNames: item.addOnServiceNames,
      storeName: item.storeName,
      deliveryTypeName: item.deliveryTypeName,
      gatePassDate: item.gatePassDate,
      noOfGarment: item.noOfGarment,
      tempLotSize: item.tempLotSize,
    }));

    var data = [];
    for (let i = 0; i < listItems.length; i++) {
      data.push({
        key: listItems[i].tagNo,
        title: listItems[i].tagNo,
        // key: listItems[i].splitOrderNumber,
        // title:
        //   listItems[i].tempLotSize != undefined
        //     ? listItems[i].splitOrderNumber + " -" + listItems[i].tempLotSize
        //     : listItems[i].splitOrderNumber,
        // description: `GatePassNo:${listItems[i].gatePassNo}`,
        store: (
          <div>
            <p>
              {labels.GATE_PASS_NO}:{listItems[i].gatePassNo}
            </p>
            <p>
              {labels.SPLIT_ORDER_NO}: {listItems[i].splitOrderNumber}
            </p>
            <p>
              {labels.GARMENT_NAME}: {listItems[i].garmentName}
            </p>
            <p>
              {labels.GARMENT_STATUS}: {listItems[i].garmentStatusName}
            </p>
            <p>
              {labels.BRAND} :{listItems[i].brandName}
            </p>
            <p style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: 5 }}>{labels.COLOR} :</span>
              {listItems[i].colorNames === "" ? null : (
                <ColorPicker
                  color={
                    listItems[i].colorNames !== null
                      ? listItems[i].colorNames.toString()
                      : null
                  }
                  disabled={true}
                  inline
                />
              )}
            </p>
            <p>
              {labels.STORE_NAME} :{listItems[i].storeName}
            </p>
            <p>
              {labels.STORE_OUT_DATE} :
              {Moment(listItems[i].gatePassDate).format("DD-MM-YYYY")}
            </p>
            <p>
              {labels.DELIVERY_TYPE} :{listItems[i].deliveryTypeName}
            </p>
          </div>
        ),
        //disabled : true
      });
    }
    const allStoreArray = this.state.storeListOptions
      .map((store) => {
        return store.value;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();

    return (
      <React.Fragment>
        {/* {JSON.stringify(this.state.targetKeys)} */}
        <PageHeader
          className={styles.TransferInHeader}
          title={labels.FACTORY_TRANSFER_IN}
          // subTitle={<Tag color="#FCC350">#5687899</Tag>}
          extra={[
            <Select
              showSearch
              style={{
                minWidth: 150,
                maxWidth: "50vw",
                // maxHeight: 100,
                // overflow: "auto",
              }}
              placeholder={labels.SELECT_STORE}
              allowClear
              key="1"
              mode="multiple"
              onChange={(event) => {
                this.setState(
                  {
                    selectedStore: [null, undefined, ""].includes(event)
                      ? ""
                      : event.join(","),
                  },
                  () => {
                    if (this.state.selectedStore !== "") {
                      this.getStoreOutGarments();
                    } else {
                      this.setState({
                        storeOutTags: [],
                        btnMissedTagEnable: true,
                      });
                    }
                  }
                );
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={
                this.state.selectedStore === ""
                  ? []
                  : this.state.selectedStore.split(",")
              }
            >
              {this.state.storeListOptions.length !== 0
                ? this.state.storeListOptions.map((store) => {
                    return store.value !== "-1" ? (
                      <Option value={store.value} key={store.value}>
                        {store.text}
                      </Option>
                    ) : null;
                  })
                : null}
            </Select>,
            <div key="2" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={
                  ![null, undefined, ""].includes(this.state.selectedStore) &&
                  this.state.selectedStore.split(",").length ===
                    allStoreArray.length &&
                  this.state.selectedStore
                    .split(",")
                    .slice()
                    .sort()
                    .every((value, index) => value === allStoreArray[index])
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    let allOpt = this.state.storeListOptions
                      .map((store) => {
                        return store.value;
                      })
                      .filter((x) => x !== "-1");
                    this.setState(
                      {
                        selectedStore: allOpt.join(","),
                        storeKeys: [],
                        factoryKeys: [],
                        // tagNos:""
                      },
                      () => {
                        if (this.state.selectedStore !== "") {
                          this.getStoreOutGarments();
                        } else {
                          this.setState({
                            storeOutTags: [],
                            btnMissedTagEnable: true,
                          });
                        }
                      }
                    );
                  } else {
                    this.setState(
                      {
                        selectedStore: "",
                        storeKeys: [],
                        factoryKeys: [],
                        // TagNos: "",
                        SplitOrderNumber: "",
                      },
                      () => {
                        if (this.state.selectedStore !== "") {
                          this.getStoreOutGarments();
                        } else {
                          this.setState({
                            storeOutTags: [],
                            btnMissedTagEnable: true,
                          });
                        }
                      }
                    );
                  }
                }}
              >
                {labels.ALL}
              </Checkbox>
            </div>,
            <div key="3" style={{ alignSelf: "center" }}>
              <Button
                variant="pink"
                htmlType="submit"
                disabled={this.state.btnMissedTagEnable}
                onClick={(event) => {
                  this.setState(() => this.getMissingGarments());
                }}
              >
                {labels.GET_MISSING_GARMENTS}
              </Button>
            </div>,
          ]}
        />

        <div className={styles.CustomTransferContainer}>
          {/**
           * Please go through the doc for transfer component from Ant Design
           * https://ant.design/components/transfer/
           * https://ant.design/components/transfer/#API
           */}
          <AntTransfer
            className={styles.CustomTransfer}
            titles={[labels.TAGS_FROM_STORES, labels.TAGS_TO_THE_FACTORY]}
            dataSource={data}
            targetKeys={this.state.targetKeys}
            render={this.renderItem}
            showSearch
            filterOption={this.filterOption}
            selectedKeys={[...this.state.storeKeys, ...this.state.factoryKeys]}
            onChange={this.handleChange}
            loading={loading}
            onSelectChange={this.handleSelectChange}
            {...this.props}
          ></AntTransfer>

          <Modal
            title={labels.MISSING_GARMENTS}
            visible={this.state.showInfoPopup}
            footer={null}
            onCancel={this.handlePopupCancel}
            className="modal-form"
            destroyOnClose
          >
            <Table
              columns={this.Missingcolumns}
              dataSource={listMissingData}
              pagination={false}
              size="small"
            />
          </Modal>
          {/**
           * Customized middle column
           */}
          <div className={styles.MiddleColumn}>
            {/**
             * Search box in middle column
             * Please go through below documentation
             * https://ant.design/components/input/.
             * https://ant.design/components/input/#components-input-demo-search-input
             * https://ant.design/components/input/#components-input-demo-search-input-loading
             * https://ant.design/components/input/#API
             */}
            <Input.Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              value={this.state.SplitOrderNumber}
              // value={this.state.TagNos}
              onChange={(event) =>
                // this.setState({ TagNos: event.target.value })
                this.setState({ SplitOrderNumber: event.target.value })
              }
              onPressEnter={(event) => {
                this.setState(
                  {
                    // TagNos: event.target.defaultValue,
                    SplitOrderNumber: event.target.defaultValue,
                  },
                  () => this.searchStoreKeys()
                );
              }}
              // onBlur={(event) => {
              //   this.setState(
              //     {
              //       TagNos: event.target.defaultValue,
              //     },
              //     () => this.searchStoreKeys()
              //   );
              // }}
              onSearch={(event) => {
                this.setState(
                  {
                    // TagNos: event,
                    SplitOrderNumber: event,
                  },
                  () => this.searchStoreKeys()
                );
              }}
              suffix={<BarcodeOutlined />}
            />

            {/**
             * a box displaying the search/scan status
             *
             * @todo may be we can add "Ready to Scan", "Scan Finished" states
             */}
            <div className={styles.SearchStatus}>
              <span>{labels.SCANNING}...</span>
            </div>

            {/**
             * A blue box that displays the details of the scanned item
             */}
            {![null, undefined, ""].includes(this.state.selectedStore)
              ? filteredTagsData.length != 0 && (
                  // this.state.targetKeys.length != 0 ? (
                  <div
                    // className={styles.ScannedItem}
                    style={{
                      backgroundColor:
                        filteredTagsData[0].orderType == 1
                          ? "#6abeff"
                          : "#e35b5b",
                      borderRadius: 10,
                      padding: 15,
                      color: "#fff",
                    }}
                  >
                    <div className={styles.ScannedItemHeader}>
                      <span className={styles.ScannedItemLabel}>
                        {" "}
                        {labels.SCANNED_ITEM}
                      </span>
                    </div>

                    <div className={styles.ScannedItemOrder}>
                      <span>Store Name : {filteredTagsData[0].storeName}</span>
                    </div>

                    <div className={styles.ScannedItemOrder}>
                      <span>
                        Order Id : {filteredTagsData[0].splitOrderNumber}
                      </span>
                    </div>

                    <div className={styles.SingleItem}>
                      <div>Total Lot Size : </div>
                      <div>
                        <Input
                          placeholder="Enter Lot Size"
                          value={this.state.EnteredLotSize}
                          // disabled={this.state.isEnabled}
                          onChange={(event) =>
                            this.setState({
                              EnteredLotSize: event.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Button
                        variant="pink"
                        // htmlType="submit"
                        // disabled={this.state.EnteredLotSize <= 0}
                        onClick={(event) => {
                          this.OnSubmitOrderLotSize();
                        }}
                      >
                        SUBMIT
                      </Button>
                    </div>
                  </div>
                )
              : // ) : null
                null}
            {/* {![null, undefined, ""].includes(this.state.selectedStore) ? (
              filteredTagsData.length != 0 &&
              this.state.targetKeys.length != 0 ? (
                <div
                  // className={styles.ScannedItem}
                  style={{
                    backgroundColor:
                      filteredTagsData[0].orderType == 1
                        ? "#6abeff"
                        : "#e35b5b",
                    borderRadius: 10,
                    padding: 15,
                    color: "#fff",
                  }}
                >
                  <div className={styles.ScannedItemHeader}>
                    <span className={styles.ScannedItemLabel}>
                      {" "}
                      {labels.SCANNED_ITEM}
                    </span>
                    <span className={styles.ScannedItemTag}>
                      {filteredTagsData[0].tagNo}
                    </span>
                  </div>
                  <div className={styles.ScannedItemName}>
                    {filteredTagsData[0].garmentName}
                  </div>
                  <div className={styles.ScannedItemOrder}>
                    <span>
                      {labels.SPLIT_ORDER_NO} #
                      {filteredTagsData[0].splitOrderNumber}
                    </span>
                    <span>
                      <Popover
                        content={
                          <div>
                            <p>
                              {labels.GARMENT_STATUS}:{" "}
                              {filteredTagsData[0].garmentStatusName}
                            </p>
                            <p>
                              {labels.SERVICE}:{" "}
                              {filteredTagsData[0].serviceName}
                            </p>
                            <p>
                              {labels.ADDON_SERVICE}:{" "}
                              {filteredTagsData[0].addOnServiceNames}
                            </p>
                            <p>
                              {labels.DELIVERY_TYPE}:{" "}
                              {filteredTagsData[0].deliveryTypeName}
                            </p>
                            <p>
                              {labels.COLOR}: {filteredTagsData[0].colorNames}
                            </p>
                            <p>
                              {labels.BRAND}: {filteredTagsData[0].brandName}
                            </p>
                          </div>
                        }
                      >
                        <img
                          src={InfoIcon}
                          alt="info"
                          className={styles.InfoIcon}
                        />
                      </Popover>
                    </span>
                  </div>
                  <div className={styles.ScannedItemCustomer}>
                    <span> {filteredTagsData[0].customerName}</span>
                    <span>
                      <Popover
                        content={
                          <div>
                            <p>
                              {labels.CUSTOMER_TYPE}:{" "}
                              {filteredTagsData[0].customerTypeName}
                            </p>
                            <p>
                              {labels.CONTACT_NO}:{" "}
                              {filteredTagsData[0].customerContactNo}
                            </p>
                          </div>
                        }
                      >
                        <img
                          src={InfoIcon}
                          alt="info"
                          className={styles.InfoIcon}
                        />
                      </Popover>
                    </span>
                    <div>
                      <span> {filteredTagsData[0].rewashReason}</span>
                    </div>
                  </div>
                </div>
              ) : null
            ) : null} */}
          </div>

          {this.state.filteredTagsData.length != 0 ? (
            <Modal
              title="Title"
              visible={this.state.OpenLotSizeModal}
              onOk={() => this.handleOk()}
              // confirmLoading={()=>confirmLoading()}
              onCancel={() => this.handleCancel()}
              className="modal-form"
              destroyOnClose
            >
              <div>
                Actual size is {this.state.filteredTagsData[0].noOfGarment} and
                you are entering {this.state.EnteredLotSize} garments.
              </div>
            </Modal>
          ) : null}

          {/* {JSON.stringify(this.state.factoryKeys)} */}
          <div style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
            <Button
              variant="purple"
              htmlType="submit"
              onClick={this.transferredIntoFactory}
              key="2"
              loading={this.state.scanBtnLoading}
              disabled={this.state.factoryKeys.length != 0 ? false : true}
            >
              {labels.SCAN_COMPLETE}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
