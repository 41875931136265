import React from "react";
import KYCDocTypeMaster from "../../../components/Master/KYCDocType/kycdoctypemaster.component"
export default class KYCDocTypeScreen extends React.Component {
  render() {
    return (
      <div >
        <KYCDocTypeMaster></KYCDocTypeMaster>
      </div>
    );
  }
}