import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class TimeCardService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async retrieveStore() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE });
    let itemArray = [];
    await myApi.endpoints.GetStoreList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStore();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveCorporate() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_CORPORATE });
    let itemArray = [];
    await myApi.endpoints.GetCorporateList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveCorporate();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreCardAssignment(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_TIMECARD_ASSIGN_TO_STORE });
    let itemArray = [];
    await myApi.endpoints.GetTimeCardAssginedToStore.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreCardAssignment(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveCorporateCardAssignment(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.GET_TIMECARD_ASSIGN_TO_CORPORATE,
    });
    let itemArray = [];
    await myApi.endpoints.GetTimeCardAssignedToCorpoTime.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveCorporateCardAssignment(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async saveUpdateTimeCardMaster(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_TIMECARD_MASTER });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateTimeCardMaster.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.saveUpdateTimeCardMaster(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async SaveUpdateAssignTimeCardToStores(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.SAVE_UPDATE_ASSIGN_TIMECARD_TO_STORE,
    });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateAssignTimeCardToStores.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.SaveUpdateAssignTimeCardToStores(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async SaveUpdateAssignTimeCardToCorporate(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.SAVE_UPDATE_ASSIGN_TIMECARD_TO_CORPORATE,
    });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateAssignTimeCardToCorporate.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.SaveUpdateAssignTimeCardToCorporate(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getTimeCardDetailByTimeCardID(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.GET_TIMECARD_DETAIL_BY_TIMECARDID,
    });
    let itemArray = [];
    let Id = "?TimeCardID=" + id;
    await myApi.endpoints.GetTimeCardDetailByTimeCardID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getTimeCardDetailByTimeCardID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveTimeCardMaster(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_TIMECARD_MASTER });
    let itemArray = [];
    await myApi.endpoints.GetTimeCardMaster.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveTimeCardMaster(params);
      });
    }
    return Promise.resolve(itemArray);
  }
  async getTimeCardDetails(timeCardID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_TIMECARD_DETAILS });
    let itemArray = [];
    let id = "?TimeCardID=" + timeCardID;

    await myApi.endpoints.GetTimeCardInfoByTimeCardID.getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getTimeCardDetails(timeCardID);
      });
    }
    return Promise.resolve(itemArray);
  }
  async uploadServiceTimeCard(id, formData) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.UPLOAD_SERVICE_TIME_CARD });
    let itemArray = [];
    let Id = "?TimeCardID=" + id;
    await myApi.endpoints.UploadServiceTimeCard.uploadFile(Id, formData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.uploadServiceTimeCard(id, formData);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default TimeCardService;
