import React from "react";
import SlotForm from "./slotform.component";
import { Table, Space, PageHeader, Typography , notification,Popconfirm,Tooltip} from "antd";
import Button from "../../../Common/Button/button";
import styles from "./createstore.module.css";
import StoreService from "../../../../services/storeservice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faPencilAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import Moment from "moment";

const { Title } = Typography;

/**
 * A tabular view as a part of create store - Slot Mapping, Slot List
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 2 June 2020
 *
 * @module Store
 */
export default class SlotMapping extends React.Component {
  constructor(props) {
    super(props);
    this.storeservice = new StoreService();
    this.state = {
      isAdd: false,
      isEdit: false,
      isViewmode: false,
      disabled: false,
      slotID: 0,
      weekDayID: 0,
      slotMappingList: [],
      weekDayName: "",
      slotFrom: "",
      slotTo: "",
      slotformValues: {},
    };
  }

  componentDidMount() {
    this.setState({ isViewmode: this.props.isViewmode });
    this.getStoreSlotMappingDetails(this.props.storeID);
  }

  getStoreSlotMappingDetails = (storeID) => {
    this.storeservice.getStoreSlotDetails(storeID).then((response) => {
      this.setState({ slotMappingList: response.data });
    });
  };

  getStoreSlotDetailBySlotID(slotID) {
    this.storeservice.getStoreSlotDetailBySlotID(slotID).then((response) => {
      this.setState({
        isEdit: true,
        slotID: slotID,
        weekDayID: response.data.weekDayID,
        weekDayName: response.data.weekDayName,
        slotFrom: response.data.slotFrom,
        slotTo: response.data.slotTo,
        slotformValues: {
          weekDayID: response.data.weekDayID.toString(),
          slotFrom: Moment(response.data.slotFrom, "h:mm a"),
          slotTo: Moment(response.data.slotTo, "h:mm a"),
        },
      });
    });
  }

  getSlotDetailsViewbySlotID(slotID) {
    this.storeservice.getStoreSlotDetailBySlotID(slotID).then((response) => {
      this.setState({
        isView: true,
        disabled: true,
        slotID: slotID,
        weekDayID: response.data.weekDayID,
        weekDayName: response.data.weekDayName,
        slotFrom: response.data.slotFrom,
        slotTo: response.data.slotTo,
        slotformValues: {
          weekDayID: response.data.weekDayID.toString(),
          slotFrom: Moment(response.data.slotFrom, "h:mm a"),
          slotTo: Moment(response.data.slotTo, "h:mm a"),
        },
      });
    });
  }

  //function for deleting data from server call
  deleteStoreSlotBySlotID = (slotID) => {
    if (slotID === "" || slotID === null || slotID === undefined) {
        notification["error"]({
        message: "Store Mapping",
        description: "Oops, Something goes wrong while removing slot. Please try again.",
      });
    } else {
      this.setState({ modalLoading: true });
      this.storeservice.deleteStoreSlotBySlotID(slotID).then((items) => {
        if (items.result === "Success") {
          // openNotificationWithIcon("success", "Garment Size", items.message);
          notification["success"]({
            message: "Store Mapping",
            description: items.message,
          });
          this.getStoreSlotMappingDetails(this.props.storeID);
        } else {
          // openNotificationWithIcon("error", "Garment Size", items.message);
          notification["error"]({
            message: "Store Mapping",
            description: items.message,
          });
        }
        this.setState({ modalLoading: false });
      });
    }
  };

  columns = [
    {
      title: "Weekday",
      dataIndex: "weekDayName",
      key: "weekDayName",
    },
    {
      title: "From",
      dataIndex: "slotFrom",
      key: "slotFrom",
    },
    {
      title: "To",
      dataIndex: "slotTo",
      key: "slotTo",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => this.getSlotDetailsViewbySlotID(record.slotID)}
          >
            <FontAwesomeIcon icon={faInfo} />
          </Button>
          {!this.props.isViewmode ? (
            <Button
              type="link"
              onClick={(event) =>
                this.getStoreSlotDetailBySlotID(record.slotID)
              }
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          ) : null}
          {!this.props.isViewmode ? (
            <Popconfirm
            title="Are you sure ?"
            onConfirm={() => this.deleteStoreSlotBySlotID(record.slotID)}
            onCancel={() => null}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button type="link">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Tooltip>
          </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  /**
   * A function to modify state varibles from child
   */
  bringback = () => {
    this.setState({
      isAdd: false,
      isEdit: false,
      isView: false,
      disabled: false,
      slotID: 0,
      slotformValues: null,
    });
  };
  render() {
    //const{data,handleSaveUpdateSlotMapping,slotMappingList,storeID,isView,disabled,isViewmode}=this.props;
    return this.state.isAdd || this.state.isEdit || this.state.isView ? (
      <SlotForm
        backFunction={this.bringback}
        slotMappingList={this.props.data}
        storeID={this.props.storeID}
        slotID={this.state.slotID}
        weekDayID={this.state.weekDayID}
        weekDayName={this.state.weekDayName}
        slotFrom={this.state.slotFrom}
        slotTo={this.state.slotTo}
        isViewmode={this.props.isViewmode}
        values={this.state.slotformValues}
        isView={this.state.isView}
        disabled={this.state.disabled}
      />
    ) : (
      <React.Fragment>
        {/**
         * Page Header Component to display title and button
         * https://ant.design/components/page-header/
         * https://ant.design/components/page-header/#API
         */}
        <PageHeader
          title={
            <Title level={4} className={styles.SlotMappingTitle}>
              Slot Mapping
            </Title>
          }
          extra={[
            !this.props.isViewmode ? (
              <Button
                variant="purple"
                shape="round"
                size="large"
                onClick={() => {
                  this.setState({ isAdd: true });
                }}
                key="1"
              >
                Add Slot
              </Button>
            ) : null,
          ]}
          className={styles.SlotMappingHeader}
        />
        {/**
         * Please go through below documention for <Table> Component
         * https://ant.design/components/table/
         * https://ant.design/components/table/#API
         *
         */}
        <Table
          size="small"
          bordered
          columns={this.columns}
          dataSource={this.state.slotMappingList}
          pagination={false}
        />
      </React.Fragment>
    );
  }
}
