import React from 'react';
import Expressfactorycomponent from '../../../components/FactoryModule/DailySheet/express.factory.component';

export default class expressfactoryscreen extends React.Component {
  render() {
    return (
      <div><Expressfactorycomponent></Expressfactorycomponent></div>
    )
  }
}
