import React from 'react'
import OrderHistory from '../../../components/StoreModule/OrderHistory/orderhistory.component'

export default class StoreOrderhistoryscreen extends React.Component {
    render() {
        return (
            <div>
                <OrderHistory></OrderHistory>
            </div>
        )
    }
}
