import React from "react";
import DeliveryChargeMaster from "../../../components/Master/DeliveryCharges/deliverychargemaster.component"

export default class DeliveryChargeScreen extends React.Component {
  render() {
    return (
      <div >
        <DeliveryChargeMaster></DeliveryChargeMaster>
      </div>
    );
  }
}