import React from "react";
// import styles from "./dashboardscreen.module.css";
import { Row, Col, Spin } from "antd";
import Stats from "../../../components/StoreModule/Dashboard/stats.component";
import Charts from "../../../components/StoreModule/Dashboard/charts.component";
// import QCCheck from "../../../components/StoreModule/Dashboard/qccheck.component";
// import QuickActions from "../../../components/StoreModule/Dashboard/quickactions.component";
import PreQCListForDashboard from "../../../components/StoreModule/PreQCResponseList/preqclistfordashboard";
import Box from "../../../components/Common/Box/box";
import NoticeBoard from "../../../components/StoreModule/Dashboard/noticeboard.component";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
/**
 * View of Dashboard screen
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 15 June 2020
 */
export default class Dashboard extends React.Component {
  UserRightsConfiguration = new UserRightsConfiguration();
  state = {
    fullLoading: false,
    isCallPreQc: true,
    isCallNotice: true,
  };
  toggleLoading = (value) => {
    this.setState({ fullLoading: value });
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.STORE_MANAGE_QC
    );

    var res1 = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.NOTICE_BOARD
    );
    if (!res[0].isView) {
      this.setState({ isCallPreQc: false });
    }

    if (!res1[0].isView) {
      this.setState({ isCallNotice: false });
    }
  }
  render() {
    return (
      <Spin spinning={this.state.fullLoading}>
        <Stats toggleLoading={this.toggleLoading} />
        <Charts toggleLoading={this.toggleLoading} />
        {/* <Row gutter={28}>
          <Col xs={24} lg={18}>
            <QCCheck />
          </Col>
          <Col xs={24} lg={6}>
            <QuickActions />
          </Col>
        </Row> */}
        <div>
          <Row gutter={28}>
            <Col xs={24} lg={18}>
              <Box sub>
                {this.state.isCallPreQc === true ? (
                  <PreQCListForDashboard toggleLoading={this.toggleLoading} />
                ) : null}
              </Box>
            </Col>
            <Col xs={24} lg={6}>
              <Box sub>
                {this.state.isCallNotice === true ? (
                  <NoticeBoard toggleLoading={this.toggleLoading} />
                ) : null}
              </Box>
            </Col>
          </Row>
        </div>
      </Spin>
    );
  }
}
