import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class orderhistoryservice {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getOrderHistory(type, Id) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_ORDER_HISTORY,
    });
    let states = [];

    let append = "?IDType=" + type + "&OrderID=" + Id;
    await stateListAPI.endpoints[this.endpoint.GET_ORDER_HISTORY]
      .getById(append)
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getOrderHistory(type, Id);
      });
    }
    return Promise.resolve(states);
  }

  async getOrderListByMobileNo(MobileNo) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_ORDERLISTBYMOBILENO,
    });
    let states = [];

    let append = "?MobileNo=" + MobileNo 
    await stateListAPI.endpoints[this.endpoint.GET_ORDERLISTBYMOBILENO]
      .getById(append)
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getOrderHistory(MobileNo);
      });
    }
    return Promise.resolve(states);
  }

  async getfactorystatusdata(Id) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_ORDER_HISTORY_FROM_SPLITEORDERNUMBER,
    });
    let states = [];

    let append = "?SplitOrderNumber=" + Id;
    await stateListAPI.endpoints[
      this.endpoint.GET_ORDER_HISTORY_FROM_SPLITEORDERNUMBER
    ]
      .getById(append)
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getfactorystatusdata(Id);
      });
    }
    return Promise.resolve(states);
  }

  async getMoreData(Id) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_ORDER_HISTORY_LOADMOREDATA,
    });
    let states = [];

    let append = "?SplitOrderNumber=" + Id;
    await stateListAPI.endpoints[
      this.endpoint.GET_ORDER_HISTORY_LOADMOREDATA
    ]
      .getById(append)
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getfactorystatusdata(Id);
      });
    }
    return Promise.resolve(states);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}
