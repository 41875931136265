import React from "react";
import styles from "./createorder.module.css";
import { Input, Empty, Popconfirm, Spin, Tooltip } from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGift } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../Common/Button/button";
import infoIcon from "../../../../images/Order/info.svg";
import CreateOrderService from "../../../../services/createorderservice";
import { withRouter } from "react-router-dom";
import ROUTES from "../../../../routes";
import { labels } from "../../../../shared/language";

/**
 * Holds the view of order summary placed at the right of create order screen
 *
 * @prop isInvoice : boolean : to determine if the summary is displayed for order or invoice
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 9 June 2020
 */
class OrderSummary extends React.Component {
  createOrderService = new CreateOrderService();
  /**
   * Information to be displayed is placed in state object
   */
  state = {
    displayDiscountInfo: false,
    displayTaxInfo: false,
    orderNumber: "#256795",
    orderSummary: [],
    tableLoading: false,
  };

  componentDidUpdate() {
    // console.log(this.props);
  }
  // static getDerivedStateFromProps(props, state) {
  //   let summary = [];
  //   props.garmentList.map((singleCategory, index) => {
  //     if (props.order[singleCategory.serviceId] !== undefined) {
  //       let garmentsObj = [];
  //       let categoryRate = "0";
  //       let categoryQty = "0";
  //       if (props.packagingCharges.length > 0) {
  //         let filteredPackagingCharges = props.packagingCharges.filter(
  //           (x) => parseInt(x.serviceID) === parseInt(singleCategory.serviceId)
  //         );

  //         if (filteredPackagingCharges.length > 0) {
  //           let chargesObj = filteredPackagingCharges[0];
  //           let categorySubtotal = 0;

  //           if (singleCategory.garments !== undefined) {
  //             if (singleCategory.UOM === 1) {
  //               singleCategory.garments.map((garment, index) => {
  //                 let qty =
  //                   props.order[singleCategory.serviceId] !== undefined
  //                     ? props.order[singleCategory.serviceId][garment.id] !==
  //                       undefined
  //                       ? props.order[singleCategory.serviceId][garment.id]
  //                       : 0
  //                     : 0;
  //                 let rate =
  //                   props.rates[singleCategory.serviceId] !== undefined
  //                     ? props.rates[singleCategory.serviceId][garment.id] !==
  //                       undefined
  //                       ? props.rates[singleCategory.serviceId][garment.id]
  //                       : 0
  //                     : 0;
  //                 let add =
  //                   parseFloat(qty) * parseFloat(parseFloat(rate).toFixed(2));
  //                 categorySubtotal = categorySubtotal + add;
  //               });
  //             } else {
  //               let add =
  //                 parseFloat(singleCategory.categoryQty) *
  //                 parseFloat(singleCategory.categoryRate);
  //               categorySubtotal = categorySubtotal + add;
  //             }
  //           }
  //           let ratePackaging =
  //             (categorySubtotal * chargesObj.perCharges) / 100;
  //           state.packagingCharge = {
  //             ...state.packagingCharge,
  //             [singleCategory.serviceId]:
  //               ratePackaging > chargesObj.fixedCharges
  //                 ? ratePackaging
  //                 : chargesObj.fixedCharges,
  //           };
  //           state.packagingChargeTotal =
  //             state.packagingChargeTotal + ratePackaging >
  //             chargesObj.fixedCharges
  //               ? ratePackaging
  //               : chargesObj.fixedCharges;
  //         }
  //       }
  //       for (const key in props.order[singleCategory.serviceId]) {
  //         let orderObj = {
  //           id: null,
  //           name: null,
  //           qty: null,
  //           rate: 0,
  //         };
  //         if (parseInt(key) !== -1) {
  //           const garmentObj = singleCategory.garments.filter((x) => {
  //             return x.id === parseInt(key);
  //           });

  //           if (
  //             props.order[singleCategory.serviceId].hasOwnProperty(key) &&
  //             props.order[singleCategory.serviceId][parseInt(key)] !== undefined
  //           ) {
  //             orderObj.id = parseInt(key);
  //             orderObj.name = garmentObj[0].name;
  //             orderObj.qty =
  //               props.order[singleCategory.serviceId][parseInt(key)];
  //             let rateToCheck = singleCategory.serviceId;
  //             if (parseInt(singleCategory.seviceId) === 9) {
  //               rateToCheck = 11;
  //             } else if (parseInt(singleCategory.serviceId) === 8) {
  //               rateToCheck = 21;
  //             }
  //             if (props.rates[rateToCheck] !== undefined) {
  //               state.tableLoading = false;
  //               orderObj.rate = props.rates[rateToCheck][parseInt(key)];
  //               garmentsObj.push(orderObj);
  //             } else {
  //               state.tableLoading = true;
  //             }
  //           }
  //         } else {
  //           if (props.rates[singleCategory.serviceId] !== undefined) {
  //             categoryRate =
  //               props.rates[singleCategory.serviceId][parseInt(key)];
  //             state.tableLoading = false;
  //           } else {
  //             state.tableLoading = true;
  //           }
  //           categoryQty = props.order[singleCategory.serviceId][parseInt(key)];
  //         }
  //       }

  //       summary.push({
  //         id: singleCategory.serviceId,
  //         title: singleCategory.title,
  //         categoryQty: categoryQty,
  //         categoryRate: categoryRate,
  //         UOM: singleCategory.UOM,
  //         data: garmentsObj,
  //       });
  //     }
  //   });
  //   const calculateTax = (type) => {
  //     let total = 0;
  //     for (let service in props.order) {
  //       if (
  //         props.cgst[service] !== undefined &&
  //         props.order[service] !== undefined &&
  //         props.sgst[service] !== undefined &&
  //         props.igst[service] !== undefined
  //       ) {
  //         let filteredUOM = props.garmentList.filter(
  //           (x) => x.serviceId == service
  //         )[0].UOM;
  //         if (parseInt(filteredUOM) === 1) {
  //           for (let garment in props.order[service]) {
  //             if (type === "CGST") {
  //               total =
  //                 total +
  //                 parseFloat(props.cgst[service][garment]) *
  //                   parseInt(props.order[service][garment]);
  //             } else if (type === "SGST") {
  //               total =
  //                 total +
  //                 parseFloat(props.sgst[service][garment]) *
  //                   parseInt(props.order[service][garment]);
  //             } else if (type === "IGST") {
  //               total =
  //                 total +
  //                 parseFloat(props.sgst[service][garment]) *
  //                   parseInt(props.order[service][garment]);
  //             }
  //           }
  //         } else {
  //           if (type === "CGST") {
  //             total =
  //               total +
  //               parseFloat(props.cgst[service][-1]) *
  //                 parseInt(props.order[service][-1]);
  //           } else if (type === "SGST") {
  //             total =
  //               total +
  //               parseFloat(props.sgst[service][-1]) *
  //                 parseInt(props.order[service][-1]);
  //           } else if (type === "IGST") {
  //             total =
  //               total +
  //               parseFloat(props.sgst[service][-1]) *
  //                 parseInt(props.order[service][-1]);
  //           }
  //         }
  //       }
  //     }
  //     return total;
  //   };
  //   state.cgst = calculateTax("CGST");
  //   state.sgst = calculateTax("SGST");
  //   state.igst = calculateTax("IGST");
  //   return { ...state, orderSummary: summary };
  // }

  calculateCount = (serviceId = null) => {
    return this.props.orderArray.length;
  };
  calculateTotal = () => {
    let subtotal = this.calculateSubtotal();
    let total =
      parseFloat(subtotal.toFixed(2)) +
      parseFloat(this.calculateTaxes("CGST").toFixed(2)) +
      parseFloat(this.calculateTaxes("SGST").toFixed(2)) +
      parseFloat(this.calculateTaxes("IGST").toFixed(2)) +
      parseFloat(this.calculatePackagingCharges().toFixed(2)) +
      parseFloat(this.calculateDeliveryCharges().toFixed(2)) -
      parseFloat(parseFloat(this.props.discount).toFixed(2));
    return total.toFixed(2);
  };
  calculateSubtotal = (service = null, type = "whole") => {
    // console.log("service->",service);
    // console.log("type->",type);
    let total = 0;
    if (service === null) {
      this.props.orderArray.map((record) => {
        let addAmt =
          type === "whole"
            ? parseFloat(
                (
                  record.price +
                  record.darningPrice +
                  record.dyingPrice +
                  record.ucPrice
                ).toFixed(2)
              )
            : type === "service"
            ? parseFloat(record.price.toFixed(2))
            : type === "addon"
            ? parseFloat(
                (
                  record.darningPrice +
                  record.dyingPrice +
                  record.ucPrice
                ).toFixed(2)
              )
            : parseFloat(
                (
                  record.price +
                  record.darningPrice +
                  record.dyingPrice +
                  record.ucPrice
                ).toFixed(2)
              );
        total = total + addAmt;
      });
    } else {
      // console.log("order->",this.props.orderArray);
      let filtered = this.props.orderArray.filter(
        (x) => x.serviceId === service
      );
      if (filtered.length > 0) {
        filtered.map((record) => {
          total =
            total +
            parseFloat(
              (
                record.price +
                record.darningPrice +
                record.dyingPrice +
                record.ucPrice
              ).toFixed(2)
            );
        });
      }
    }
    return total;
  };
  calculateTaxes(type) {
    let tax = 0;
    let total = 0;
    if (type === "CGST") {
      this.props.orderArray.map((record) => {
        total =
          total +
          parseFloat(
            parseFloat(
              (
                record.price +
                record.darningPrice +
                record.dyingPrice +
                record.ucPrice
              ).toFixed(2)
            ).toFixed(2)
          );
      });
      total =
        total +
        this.calculatePackagingCharges() +
        this.calculateDeliveryCharges() -
        this.props.discount;
      tax =
        (total *
          (this.props.orderArray.length > 0
            ? this.props.orderArray[0].cgst
            : 0)) /
        100;
    }
    if (type === "SGST") {
      this.props.orderArray.map((record) => {
        total =
          total +
          parseFloat(
            parseFloat(
              (
                record.price +
                record.darningPrice +
                record.dyingPrice +
                record.ucPrice
              ).toFixed(2)
            ).toFixed(2)
          );
      });
      total =
        total +
        this.calculatePackagingCharges() +
        this.calculateDeliveryCharges() -
        this.props.discount;
      tax =
        (total *
          (this.props.orderArray.length > 0
            ? this.props.orderArray[0].sgst
            : 0)) /
        100;
    }
    if (type === "IGST") {
      this.props.orderArray.map((record) => {
        total =
          total +
          parseFloat(
            parseFloat(
              (
                record.price +
                record.darningPrice +
                record.dyingPrice +
                record.ucPrice
              ).toFixed(2)
            ).toFixed(2)
          );
      });
      total =
        total +
        this.calculatePackagingCharges() +
        this.calculateDeliveryCharges() -
        this.props.discount;
      tax =
        (total *
          (this.props.orderArray.length > 0
            ? this.props.orderArray[0].igst
            : 0)) /
        100;
    }
    return tax;
  }
  calculatePackagingCharges = (shouldReturnTax = false) => {
    let total = 0;
    let serviceIDs = [];
    this.props.orderArray.map((record) => {
      if (!serviceIDs.includes(record.serviceId)) {
        serviceIDs.push(record.serviceId);
      }
    });
    for (let serviceId in serviceIDs) {
      if (![8, 9].includes(parseInt(serviceId))) {
        let filtered = this.props.packagingCharges.filter(
          (x) => parseInt(x.serviceID) === parseInt(serviceIDs[serviceId])
        );
        if (filtered.length > 0) {
          let filteredObj = filtered[0];
          let categorySubtotal = this.calculateSubtotal(serviceIDs[serviceId]);
          let rateCharges = (categorySubtotal * filteredObj.perCharges) / 100;
          if (shouldReturnTax) {
            total = {
              cgst: filteredObj.cgst,
              sgst: filteredObj.sgst,
              igst: filteredObj.igst,
            };
          } else {
            if (rateCharges > filteredObj.fixedCharges) {
              total = total + rateCharges;
            } else {
              total = total + filteredObj.fixedCharges;
            }
          }
        }
      } else {
        if (shouldReturnTax) {
          total = {
            cgst: 0,
            sgst: 0,
            igst: 0,
          };
        } else {
          total = total;
        }
      }
    }
    return total;
  };
  calculateDeliveryCharges = (shouldReturnTax = false) => {
    let subtotal = this.calculateSubtotal();
    let finalCharges = 0;
    if (subtotal < this.props.deliveryCharges.minBillAmount) {
      finalCharges = this.props.deliveryCharges.fixedCharges;
    } else {
      let percentageCharges =
        (subtotal * this.props.deliveryCharges.perCharges) / 100;

      if (percentageCharges > this.props.deliveryCharges.fixedCharges) {
        finalCharges = percentageCharges;
      } else {
        finalCharges = this.props.deliveryCharges.fixedCharges;
      }
      if (finalCharges > this.props.deliveryCharges.maxCharges) {
        finalCharges = this.props.deliveryCharges.maxCharges;
      }
    }

    if (shouldReturnTax) {
      if (this.props.isFromPickup || this.props.isOnDemand) {
        return {
          cgst: this.props.deliveryCharges.cgst,
          sgst: this.props.deliveryCharges.sgst,
          igst: this.props.deliveryCharges.igst,
        };
      } else {
        return {
          cgst: 0,
          sgst: 0,
          igst: 0,
        };
      }
    } else {
      if (this.props.isFromPickup || this.props.isOnDemand) {
        return parseFloat(finalCharges.toFixed(2));
      } else {
        return 0;
      }
    }
  };
  render() {
    /**
     * An array of colors for the labels for different categories
     */
    const labelColors = [
      "#FF8EB1",
      "#FCC350",
      "#6ABEFF",
      "#7AE473",
      "#FF6A6A",
      "#8F56F3",
      "#E162E5",
      "#B2C12C",
      "#3C76EB",
    ];

    var Cancel_order_by_admin_show = JSON.parse(
      localStorage.getItem("buttonAccess")
    ).filter((item) => {
      return item.accessName == "Cancel Order By Admin";
    }); 

    return (
      <div
        className={[
          styles.OrderSummaryContainer,
          this.props.isInvoice ? styles.SummaryContainerInvoice : null,
        ].join(" ")}
      >
        {/* <pre>{JSON.stringify(this.state.orderSummary)}</pre> */}
        {/**
         * Added condition for isInvoice
         * if this summary is displayed in invoice then order number wont be displayed
         *
         * @author Niraj Gohel
         * @added 11 June 2016
         */}
        {!this.props.isInvoice ? (
          <div className={styles.OrderTitle}>
            <span>{labels.ORDER_SUMMARY}</span>
            {/* <span>{this.state.orderNumber}</span> */}
          </div>
        ) : null}

        {/**
         * additional class if the summary is displayed in invoice screen
         * styles.SummaryTableInvoice
         *
         * @author Niraj Gohel
         * @added 11 June 2016
         */}

        {this.props.orderArray.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <React.Fragment>
            <div
              className={[
                styles.SummaryTableWrapper,
                this.props.isInvoice ? styles.SummaryTableInvoice : null,
              ].join(" ")}
            >
              <Spin spinning={this.state.tableLoading}>
                <table className={styles.SummaryTable}>
                  <thead>
                    <tr>
                      {/**
                       * Do not display gear if summary is displayed in invoice
                       *
                       * @author Niraj Gohel
                       * @added 11 June 2016
                       */}
                      {!this.props.isInvoice ? <th></th> : null}
                      <th>{labels.DETAILS}</th>
                      <th className="text-right">{labels.QTY}</th>
                      <th className="text-right">{labels.RATES}</th>
                      <th className="text-right">{labels.FEES}</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: 12 }}>
                    {/* {this.state.orderSummary.map((category, index) => {
                      return (
                        <React.Fragment key={index}>
                          <tr valign="middle">
                            <td colSpan={this.props.isInvoice ? 4 : 2}>
                              <div
                                className={styles.CategoryLabel}
                                style={{
                                  backgroundColor:
                                    labelColors[index % labelColors.length],
                                }}
                              >
                                {category.title}
                              </div>
                            </td>
                            {category.categoryQty !== undefined &&
                            category.categoryQty !== "" ? (
                              <React.Fragment>
                                <td className="text-right">
                                  <div style={{ marginTop: 15 }}>
                                    {category.UOM !== 1 ? (
                                      <React.Fragment>
                                        {parseFloat(
                                          category.categoryQty
                                        ).toFixed(2)}
                                        {category.UOM === 2
                                          ? "Kg"
                                          : category.UOM === 3
                                          ? "Sqft"
                                          : null}
                                      </React.Fragment>
                                    ) : null}
                                  </div>
                                </td>
                                <td className="text-right text-nowrap">
                                  <div style={{ marginTop: 15 }}>
                                    {category.UOM !== 1 ? (
                                      <React.Fragment>
                                        ₹
                                        {parseFloat(
                                          category.categoryRate
                                        ).toFixed(2)}
                                      </React.Fragment>
                                    ) : null}
                                  </div>
                                </td>
                                <td className="text-right text-nowrap">
                                  <div style={{ marginTop: 15 }}>
                                    {category.UOM !== 1 ? (
                                      <React.Fragment>
                                        ₹
                                        {(
                                          category.categoryQty *
                                          category.categoryRate
                                        ).toFixed(2)}
                                      </React.Fragment>
                                    ) : null}
                                  </div>
                                </td>
                              </React.Fragment>
                            ) : (
                              <td colSpan="3"></td>
                            )}
                          </tr>

                          {category.data.map((item, index) => {
                            return (
                              <tr className={styles.ItemRow} key={index}>
                                {!this.props.isInvoice ? <td></td> : null}

                                <td>{item.name}</td>
                                <td className="text-right">{item.qty}</td>
                                <td className="text-right text-nowrap">
                                  {category.UOM === 1 ? (
                                    <React.Fragment>
                                      ₹{parseFloat(item.rate).toFixed(2)}
                                    </React.Fragment>
                                  ) : null}
                                </td>
                                <td className="text-right text-nowrap">
                                  {category.UOM === 1 ? (
                                    <React.Fragment>
                                      ₹{(item.qty * item.rate).toFixed(2)}
                                    </React.Fragment>
                                  ) : null}
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      );
                    })} */}
                    {this.props.garmentList.map((service, index) => {
                      let serviceId = parseInt(service.serviceId);
                      let title = service.title;
                      let uom = service.UOM;
                      let filteredRecords = this.props.orderArray.filter(
                        (record) =>
                          parseInt(record.serviceId) === parseInt(serviceId)
                      );
                      let returnVal = [];
                      if (filteredRecords.length > 0) {
                        returnVal.push(
                          <tr valign="middle">
                            <td colSpan={this.props.isInvoice ? 4 : 2}>
                              <div
                                className={styles.CategoryLabel}
                                style={{
                                  backgroundColor:
                                    labelColors[index % labelColors.length],
                                }}
                              >
                                {title}
                              </div>
                            </td>
                            {parseInt(uom) === 2 ? (
                              <React.Fragment>
                                <td className="text-right">
                                  <div
                                    style={{
                                      marginTop: 15,
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {this.props.order[serviceId] !== undefined
                                      ? this.props.order[serviceId]["-1"] !==
                                          undefined &&
                                        this.props.order[serviceId]["-1"] !==
                                          null &&
                                        this.props.order[serviceId]["-1"] !== ""
                                        ? parseFloat(
                                            this.props.order[serviceId]["-1"]
                                          ).toFixed(2)
                                        : parseFloat(0).toFixed(2)
                                      : parseFloat(0).toFixed(2)}
                                  </div>
                                </td>
                                <td className="text-right">
                                  <div
                                    style={{
                                      marginTop: 15,
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    ₹{" "}
                                    {parseFloat(
                                      filteredRecords[0].rate
                                    ).toFixed(2)}
                                  </div>
                                </td>
                                <td className="text-right">
                                  <div
                                    style={{
                                      marginTop: 15,
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    ₹{" "}
                                    {this.props.order[serviceId] !== undefined
                                      ? this.props.order[serviceId]["-1"] !==
                                          undefined &&
                                        this.props.order[serviceId]["-1"] !==
                                          null &&
                                        this.props.order[serviceId]["-1"] !== ""
                                        ? parseFloat(
                                            this.props.order[serviceId]["-1"] *
                                              filteredRecords[0].rate
                                          ).toFixed(2)
                                        : 0
                                      : 0}
                                  </div>
                                </td>
                              </React.Fragment>
                            ) : (
                              <td colSpan={3}></td>
                            )}
                          </tr>
                        );
                      }
                      service.garments.map((garment) => {                        
                        let garmentId = parseInt(garment.id);
                        let garmentFilteredRecords =
                          this.props.orderArray.filter(                          
                            (record) =>                            
                              parseInt(record.serviceId) === parseInt(serviceId) &&
                              parseInt(record.garmentId) === parseInt(garmentId) 
                          );
                        if (garmentFilteredRecords.length > 0) {
                          returnVal.push(
                            <tr className={styles.ItemRow}>
                              <td></td>
                              <td>
                                {garment.name}
                                {[null, undefined, ""].includes(
                                  this.props.customer
                                )
                                  ? null
                                  : this.props.customer.serviceType.id === 2
                                  ? " " + labels.SEMI_EXPRESS_RATE
                                  : this.props.customer.serviceType.id === 3
                                  ? " " + labels.EXPRESS_RATE
                                  : null}
                              </td>
                              <td
                                className="text-right"
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {parseInt(uom) !== 3
                                  ? garmentFilteredRecords.length
                                  : parseFloat(
                                      garmentFilteredRecords.reduce(
                                        (prev, obj) => {
                                          return (
                                            parseFloat(prev) +
                                            parseFloat(
                                              obj["width"] * obj["length"]
                                            )
                                          );
                                        },

                                        0
                                      )
                                    ).toFixed(2)}
                              </td>
                              {parseInt(uom) === 2 ? (
                                <td colSpan={2}></td>
                              ) : (
                                <React.Fragment>
                                  <td
                                    className="text-right"
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    ₹{" "}
                                    {parseFloat(
                                      garmentFilteredRecords[0].rate
                                    ).toFixed(2)}
                                  </td>
                                  <td
                                    className="text-right"
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Tooltip
                                      // visible={false}
                                      title={
                                        <React.Fragment>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>Service Charges</div>
                                            <div>
                                              ₹{" "}
                                              {parseFloat(
                                                garmentFilteredRecords.reduce(
                                                  (prev, obj) =>
                                                    prev + obj.price,
                                                  0
                                                )
                                              ).toFixed(2)}
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>Add-on Service Charges</div>
                                            <div style={{ marginLeft: 5 }}>
                                              ₹{" "}
                                              {parseFloat(
                                                garmentFilteredRecords.reduce(
                                                  (prev, obj) =>
                                                    prev +
                                                    obj.darningPrice +
                                                    obj.dyingPrice +
                                                    obj.ucPrice,
                                                  0
                                                )
                                              ).toFixed(2)}
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      }
                                    >
                                      ₹{" "}
                                      {parseInt(uom) !== 3
                                        ? parseFloat(
                                            garmentFilteredRecords.reduce(
                                              (prev, obj) =>
                                                prev +
                                                obj.price +
                                                obj.darningPrice +
                                                obj.dyingPrice +
                                                obj.ucPrice,
                                              0
                                            )
                                          ).toFixed(2)
                                        : parseFloat(
                                            parseFloat(
                                              parseFloat(
                                                garmentFilteredRecords.reduce(
                                                  (prev, obj) => {
                                                    return (
                                                      parseFloat(prev) +
                                                      parseFloat(
                                                        parseFloat(
                                                          obj["width"] *
                                                            obj["length"]
                                                        ) * obj.rate
                                                      ) +
                                                      obj.darningPrice +
                                                      obj.dyingPrice +
                                                      obj.ucPrice
                                                    );
                                                  },

                                                  0
                                                )
                                              ).toFixed(2)
                                            )
                                          ).toFixed(2)}{" "}
                                    </Tooltip>
                                  </td>
                                </React.Fragment>
                              )}
                            </tr>
                          );
                        }
                      });
                      return returnVal;
                    })}
                  </tbody>
                </table>
              </Spin>
            </div>

            <div className={styles.SummaryInfoWrap} style={{ paddingTop: 20 }}>
              <div className={styles.SummaryInfoLabel}>{labels.SUBTOTAL}</div>
              <div className={styles.SummaryInfoValue}>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Service Charges</div>
                        <div style={{ marginLeft: 5 }}>
                          ₹ {this.calculateSubtotal(null, "service").toFixed(2)}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Add-on Service Charges</div>
                        <div style={{ marginLeft: 5 }}>
                          ₹ {this.calculateSubtotal(null, "addon").toFixed(2)}
                        </div>
                      </div>
                    </React.Fragment>
                  }
                >
                  ₹ {this.calculateSubtotal().toFixed(2)}
                </Tooltip>
              </div>
            </div>
            <div className={styles.SummaryInfoWrap}>
              <div className={styles.SummaryInfoLabel}>
                {labels.DISCOUNT}
                <span
                  className={styles.InfoIcon}
                  onClick={() =>
                    this.setState({
                      displayDiscountInfo: !this.state.displayDiscountInfo,
                    })
                  }
                >
                  <img src={infoIcon} alt="i" />
                </span>
              </div>
              <div className={styles.SummaryInfoValue}>
                ₹ {parseFloat(this.props.discount).toFixed(2)}
              </div>
            </div>
            {this.state.displayDiscountInfo ? (
              <div className={styles.SummaryInfoSubPart}>
                <div className={styles.SummaryInfoWrap}>
                  <div className={styles.SummaryInfoLabel}>
                    {labels.PROMO_DISCOUNT}
                  </div>
                  <div className={styles.SummaryInfoValue}>
                    ₹ {parseFloat(this.props.discount).toFixed(2)}
                  </div>
                </div>
              </div>
            ) : null}
            <div className={styles.SummaryInfoWrap}>
              <div className={styles.SummaryInfoLabel}>
                {labels.TAXES_AND_CHARGES}
                <span
                  className={styles.InfoIcon}
                  onClick={() =>
                    this.setState({
                      displayTaxInfo: !this.state.displayTaxInfo,
                    })
                  }
                >
                  <img src={infoIcon} alt="i" />
                </span>
              </div>

              <div className={styles.SummaryInfoValue}>
                ₹{" "}
                {(
                  parseFloat(this.calculateTaxes("CGST").toFixed(2)) +
                  parseFloat(this.calculateTaxes("SGST").toFixed(2)) +
                  parseFloat(this.calculateTaxes("IGST").toFixed(2)) +
                  parseFloat(this.calculatePackagingCharges().toFixed(2)) +
                  parseFloat(this.calculateDeliveryCharges().toFixed(2))
                ).toFixed(2)}
              </div>
            </div>

            {this.state.displayTaxInfo ? (
              <div className={styles.SummaryInfoSubPart}>
                <div className={styles.SummaryInfoWrap}>
                  <div className={styles.SummaryInfoLabel}>
                    {labels.PACKAGING_CHARGES}
                  </div>
                  <div className={styles.SummaryInfoValue}>
                    ₹ {this.calculatePackagingCharges().toFixed(2)}
                  </div>
                </div>
                <div className={styles.SummaryInfoWrap}>
                  <div className={styles.SummaryInfoLabel}>
                    {labels.DELIVERY_CHARGES}
                  </div>
                  <div className={styles.SummaryInfoValue}>
                    ₹ {this.calculateDeliveryCharges().toFixed(2)}
                  </div>
                </div>
                <div className={styles.SummaryInfoWrap}>
                  <div className={styles.SummaryInfoLabel}>{labels.CGST}</div>
                  <div className={styles.SummaryInfoValue}>
                    ₹ {this.calculateTaxes("CGST").toFixed(2)}
                  </div>
                </div>
                <div className={styles.SummaryInfoWrap}>
                  <div className={styles.SummaryInfoLabel}>{labels.SGST}</div>
                  <div className={styles.SummaryInfoValue}>
                    ₹ {this.calculateTaxes("SGST").toFixed(2)}
                  </div>
                </div>
                <div className={styles.SummaryInfoWrap}>
                  <div className={styles.SummaryInfoLabel}>{labels.IGST}</div>
                  <div className={styles.SummaryInfoValue}>
                    ₹ {this.calculateTaxes("IGST").toFixed(2)}
                  </div>
                </div>
              </div>
            ) : null}
            <div className={styles.TotalBlock}>
              <span>{labels.TOTAL}</span>
              <span>{this.calculateCount()}</span>
              <span>₹ {this.calculateTotal()}</span>
            </div>
            {/**
             * Hide Notes and Review Button if displayed in invoice
             * @author Niraj Gohel
             * @added 12 June 2016
             */}
            {!this.props.isInvoice ? (
              <React.Fragment>
                <Input.TextArea
                  placeholder={labels.ORDER_REMARKS}
                  rows={3}
                  value={this.props.orderRemarks}
                  maxLength={500}
                  onChange={(e) =>
                    this.props.updateOrderRemarks(e.target.value)
                  }
                />
                <div style={{ paddingTop: 20 }}>
                  <Button
                    variant="purple"
                    size="large"
                    className={styles.ReviewOrderButton}
                    block
                    onClick={this.props.goToReview}
                    // disabled={
                    //   Object.keys(this.props.order).filter(
                    //     (x) => this.props.order[x] !== undefined
                    //   ).length ===
                    //   Object.keys(this.props.order)
                    //     .filter((x) => this.props.order[x] !== undefined)
                    //     .filter((x) => parseInt(x) === 8 || parseInt(x) === 9)
                    //     .length
                    // }
                    disabled={this.disableRevireOrder()}
                  >
                    <div>
                      <span>{labels.REVIEW_ORDER}</span>
                      <span>₹ {this.calculateTotal()}</span>
                    </div>
                  </Button>
                  {[ROUTES.UPDATE_ORDER, ":orderID"].join("/") ===
                    this.props.match.path &&
                  parseInt(this.props.orderStatus) < 2 ? (
                    <Popconfirm
                      title={labels.ARE_YOU_SURE}
                      onConfirm={this.props.cancelOrder}
                      onCancel={() => null}
                      okText={labels.YES}
                      cancelText={labels.NO}
                    >
                      <Button
                        variant="purpleOutline"
                        style={{ marginTop: 0 }}
                        size="large"
                        className={styles.ReviewOrderButton}
                        block
                      >
                        {labels.CANCEL_ORDER}
                      </Button>
                    </Popconfirm>
                  ) : null}

                    {/* localStorage
                    .getItem("roleNames")
                    .split(",")
                    .indexOf("Admin") > -1 && */}

                  {Cancel_order_by_admin_show.length > 0 &&
                  [ROUTES.UPDATE_ORDER, ":orderID"].join("/") ===
                    this.props.match.path &&
                  parseInt(this.props.orderStatus) < 4 ? (
                    <Popconfirm
                      title={labels.ARE_YOU_SURE}
                      onConfirm={this.props.cancelOrder}
                      onCancel={() => null}
                      okText={labels.YES}
                      cancelText={labels.NO}
                    >
                      <Button
                        variant="purpleOutline"
                        style={{ marginTop: 0 }}
                        size="large"
                        className={styles.ReviewOrderButton}
                        block
                      >
                        {labels.CANCEL_ORDER_BY_ADMIN}
                      </Button>
                    </Popconfirm>
                  ) : null}
                </div>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
      </div>
    );
  }
  disableRevireOrder = () => {
    // debugger;
    var orderArrayLength =
      this.props.orderArray.length > 0
        ? this.props.orderArray.filter(
            (x) =>
              !x.serviceIsAddOn ||
              (parseInt(x.serviceId) != 8 && parseInt(x.serviceId) != 9)
          ).length
        : 0;
    return orderArrayLength > 0 ? false : true;
  };
}
export default withRouter(OrderSummary);
