import React, { Component } from 'react';
import Monthlypickupsummarycomponent from '../../../components/StoreModule/Audit/monthlypickupsummary.component';

export default class monthlypickupsummaryscreen extends Component {
  render() {
    return (
      <div><Monthlypickupsummarycomponent></Monthlypickupsummarycomponent></div>
    )
  }
}
