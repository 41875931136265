import React from 'react'
import Ordersummarycomponent from '../../../components/StoreModule/Audit/ordersummary.component'

export default class ordersummaryscreen extends React.Component {
  render() {
    return (
      <Ordersummarycomponent></Ordersummarycomponent>
    )
  }
}
