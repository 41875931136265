import React from "react";
import RewashReasonMaster from "../../../components/Master/RewashReason/rewashreasonmaster.component"
export default class RewashReasonScreen extends React.Component {
  render() {
    return (
      <div >
        <RewashReasonMaster></RewashReasonMaster>
      </div>
    );
  }
}