import React from "react";
import styles from "../Common/Styles/master.module.css";
import Button from "../Common/Button/button";
import Filters from "../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  notification,
  Tooltip,
  Modal,
  Spin,
  Upload,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //   faChevronUp,
  faPencilAlt,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
// import ROUTES from "../../../routes";
import StandardRateCardService from "../../services/standardratecardservice";
import PackagingChargesService from "../../services/packagingchargeservice";
import { IsAuthenticated } from "../../services/auth-header";
import Configuration from "../../shared/configuration";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import { RegexInteger, MessageNumbers } from "../../shared/validator";
import { PlusOutlined } from "@ant-design/icons";

const { Search } = Input;
const { Option } = Select;

/**
 * A factory master screen which holds grid view for master screens and form with steps
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
class Master extends React.Component {
  standardRateService = new StandardRateCardService();
  UserRightsConfiguration = new UserRightsConfiguration();
  PackageChargeService = new PackagingChargesService();
  config = new Configuration();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    modalLoading: false,
    ID: 0,
    showfilterBox: false,
    factories: [],

    garmentImage: "",
    fileList: [],
    previewVisible: false,
    previewImage: "",
    previewTitle: "",

    serviceListOptions: [],
    serviceID: "",
    serviceName: [],

    garmentCategory: "",
    garmentName: "",
    servicePrefixUOM: "",
    rate: 0,

    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    showAddPopup: false,
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
    popupMode: "add",
    currentExpense: {},
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.STANDARD_RATE_CARD
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      title: "Garment Category",
      dataIndex: "garmentCategory",
      key: "garmentCategory",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (category) => (
        <Tooltip placement="topLeft" title={category}>
          {category}
        </Tooltip>
      ),
    },
    {
      title: "Garment Name",
      dataIndex: "garmentName",
      key: "garmentName",
      sorter: true,
    },
    {
      title: "Service",
      dataIndex: "serviceName",
      key: "serviceName",
      sorter: true,
      //   render: (text) => {
      //     return moment(text).format("YYYY-MM-DD");
      //   },
    },
    {
      title: "Service Prefix/UOM",
      dataIndex: "other",
      key: "other",
      sorter: true,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      sorter: true,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip
          placement="topLeft"
          title={address.split(
            this.config.STANDARD_RATE_CARD_AND_GARMENT_MASTER_IMAGE_URL
          )}
        >
          {address.split(
            this.config.STANDARD_RATE_CARD_AND_GARMENT_MASTER_IMAGE_URL
          )}
        </Tooltip>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState(
                    {
                      popupMode: "view",
                      ID: record.id,
                      serviceID: record.serviceID,
                      serviceName: record.serviceName,
                      garmentCategory: record.garmentCategory,
                      garmentName: record.garmentName,
                      servicePrefixUOM: record.other,
                      rate: record.rate,
                      garmentImage: record.image,
                    },
                    () => this.setState({ showAddPopup: true })
                  )
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() =>
                  this.setState(
                    {
                      popupMode: "edit",

                      ID: record.id,
                      serviceID: record.serviceID,
                      serviceName: record.serviceName,
                      garmentCategory: record.garmentCategory,
                      garmentName: record.garmentName,
                      servicePrefixUOM: record.other,
                      rate: record.rate,
                      garmentImage: record.image,
                    },
                    () => this.setState({ showAddPopup: true })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.handleDelete(record.id)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    this.getStandardRateCardList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
    this.DropDownServiceList();
  }
  handleDelete = (standardRateID) => {
    this.setState({ tableLoading: true });
    this.standardRateService
      .deleteStandardRateCard(standardRateID)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Standard Rate Card",
            description: response.message
              ? response.message
              : "Standard rate card deleted successfully",
          });
          this.getStandardRateCardList(
            this.state.pagination.current,
            this.state.pagination.pageSize,
            this.state.sorter.field,
            this.state.sorter.order,
            this.state.search
          );
        } else {
          notification["error"]({
            message: "Standard Rate Card",
            description: response.message
              ? response.message
              : "Unable to delete standard rate card",
          });
        }
        this.setState({ tableLoading: false });
      });
  };
  addUpdateStandardRateCard = (values) => {
    this.setState({ modalLoading: true });
    var fileData = this.state.fileList;
    const postFormData = new FormData();

    postFormData.append("ID", this.state.ID);
    postFormData.append("garmentCategory", values.garmentCategory);
    postFormData.append("garmentName", values.garmentName);
    postFormData.append("serviceID", this.state.serviceID);
    postFormData.append("serviceName", this.state.serviceName);
    postFormData.append("other", values.servicePrefixUOM);
    postFormData.append("rate", values.rate);
    postFormData.append(
      "image",
      this.state.fileList.length > 0
        ? this.state.fileList[0].originFileObj
        : null
    );
    postFormData.append("isNewImgUploaded", true);
    postFormData.append("oldGarmentImg", this.oldServiceImageFromUrl());

    for (var value of postFormData.values()) {
      // console.log(value);
    }
    this.standardRateService
      .addUpdateStandardRateCard(postFormData)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Standard Rate Card",
            description: response.message
              ? response.message
              : "Data saved successfully",
          });
          this.getStandardRateCardList(
            this.state.pagination.current,
            this.state.pagination.pageSize,
            this.state.sorter.field,
            this.state.sorter.order,
            this.state.search
          );
          this.setState({ showAddPopup: false });
          this.resetFields();
        } else {
          notification["error"]({
            message: "Standard Rate Card",
            description: response.message
              ? response.message
              : "Unable to submit data",
          });
        }
        this.setState({ modalLoading: false });
      });
  };

  resetFields = () => {
    this.setState({
      ID: 0,
      serviceID: "",
      serviceName: [],
      garmentCategory: "",
      garmentName: "",
      servicePrefixUOM: "",
      rate: "",
      fileList: [],
      garmentImage: "",
    });
  };

  oldServiceImageFromUrl = () => {
    let str1 = "";
    if (![undefined, null, ""].includes(this.state.garmentImage)) {
      let str = this.state.garmentImage.split("/");
      str1 = str[str.length - 1];
    }
    return str1;
  };
  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleChange = (info) => {
    if (info.fileList.length > 0) {
      if (info.file !== undefined) {
        const isJpgOrPng =
          info.file.type === "image/png" ||
          info.file.type === "image/jpeg" ||
          info.file.type === "image/jpg";
        if (!isJpgOrPng) {
          notification["error"]({
            message: "Standard Rate Card",
            description: "You can only upload JPG/PNG/JPEG file!",
          });
          // this.setState({ fileList: [] });
          info.file = undefined;
        }
        let isLt1M = false;
        if (info.file !== undefined) {
          isLt1M = info.file.size / 1024 / 1024 < 1;
          if (!isLt1M) {
            notification["error"]({
              message: "Standard Rate Card",
              description: "Image must be smaller than 1 MB!",
            });
            // this.setState({ fileList: [] });
            info.file = undefined;
          }
        }
        if (isJpgOrPng && isLt1M) {
          info.file.status = "done";
        }
      }
      var infoFileList = [];
      infoFileList.push(info.file);
      info.file = infoFileList.filter((x) => x != undefined);
      this.setState({ fileList: info.file });
      if (info.file.length > 0) {
        this.setState({ garmentImage: "" });
      }
    } else {
      this.setState({ fileList: [], garmentImage: "" });
    }
  };

  getStandardRateCardList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    this.setState({ tableLoading: true });
    this.standardRateService
      .getStandardRateCardData(index, size, sortColumn, sortOrder, search)
      .then((response) => {
        if (response.statusCode === 200) {
          let factoryData = [];
          response.data.map((singleData) => {
            singleData.key = singleData.id;
            factoryData.push(singleData);
            return null;
          });
          this.setState({ factories: factoryData });
          this.setState({
            pagination: {
              ...this.state.pagination,
              total: factoryData.length ? factoryData[0].totalRecords : 0,
            },
          });
        } else {
          notification["error"]({
            message: "Standard Rate Card",
            description: response.message
              ? response.message
              : "Unable to get list of standard rate card data",
          });
        }
        this.setState({ tableLoading: false });
      });
  };

  RestoreDDlValue = (e) => {
    var serviceArray = this.state.serviceListOptions.filter(
      (x) => x.value === e
    );
    if (serviceArray !== undefined) {
      this.setState({
        serviceID: serviceArray[0].value,
        serviceName: serviceArray[0].text,
      });
    }
  };

  DropDownServiceList = () => {
    this.PackageChargeService.serviceListItems().then((items) => {
      if (items.statusCode === 200) {
        this.setState({ serviceListOptions: items.data });
      } else {
        notification["error"]({
          message: "Standard Rate Card",
          description: items.message
            ? items.message
            : "Unable to get list of service data",
        });
      }
    });
  };

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const disableAll = this.state.popupMode === "view";
    const uploadButton =
      this.state.fileList.length === 0 ? (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">Upload</div>
        </div>
      ) : null;

    return (
      <div>
        <PageHeader
          title="Standard Rate Card"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              key="1"
              onChange={(event) => {
                this.setState({
                  search: event.target.value.toString().toLowerCase(),
                });
                this.getStandardRateCardList(
                  this.state.pagination.current,
                  this.state.pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  event.target.value.toString().toLowerCase()
                );
              }}
            />,
            /**
             * Add Factory Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.setState({
                    ID: 0,
                    showAddPopup: true,
                    popupMode: "add",
                    currentExpense: {},
                  })
                }
                key="2"
              >
                Add Standard Rate Card
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getStandardRateCardList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />

        <Modal
          width={600}
          title={[
            this.state.popupMode === "add"
              ? "Add"
              : this.state.popupMode === "view"
              ? "View"
              : "Edit",
            "Standard Rate Card",
          ].join(" ")}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={(values) => {
                this.addUpdateStandardRateCard(values);
              }}
              initialValues={{
                serviceName: this.state.serviceName,
                garmentCategory: this.state.garmentCategory,
                garmentName: this.state.garmentName,
                servicePrefixUOM: this.state.servicePrefixUOM,
                rate: this.state.rate,
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Service Name "
                    name="serviceName"
                    rules={[
                      { required: true, message: "Please select service name" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Service Name"
                      onChange={this.RestoreDDlValue}
                      disabled={disableAll}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.serviceListOptions.map((service) =>
                        service.value !== "-1" ? (
                          <option key={service.value} value={service.value}>
                            {service.text}
                          </option>
                        ) : null
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Garment Category"
                    name="garmentCategory"
                    rules={[
                      {
                        required: true,
                        message: "Please enter garment category",
                      },
                      // {
                      //   pattern: Regex,
                      //   message:
                      //     "You Can Enter Alphabets,Digits,Underscore, Hyphen Or Ampersand",
                      // },
                    ]}
                  >
                    <Input
                      placeholder="Enter Garment Category"
                      disabled={disableAll}
                      onChange={this.handleInputChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12}>
                  <Form.Item
                    label="Garment Name"
                    name="garmentName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter garment name",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Garment Name"
                      disabled={disableAll}
                      onChange={this.handleInputChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Service Prefix/UOM"
                    name="servicePrefixUOM"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Enter Service Prefix/UOM",
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder="Enter service prefix/UOM "
                      disabled={disableAll}
                      onChange={this.handleInputChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Rate"
                    name="rate"
                    rules={[
                      {
                        required: true,
                        message: "Please enter rate",
                      },
                      {
                        pattern: RegexInteger,
                        message: MessageNumbers,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Rate"
                      disabled={disableAll}
                      prefix="₹"
                      maxLength={20}
                      onChange={this.handleInputChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  {this.state.popupMode === "edit" ||
                  this.state.popupMode === "add" ? (
                    <>
                      <Space>
                        <Form.Item
                          label="Select File To Upload"
                          name="file"
                          valuePropName={"file"}
                          rules={[
                            {
                              required: this.state.ID === 0,
                              message: "Please select file to upload",
                            },
                          ]}
                        >
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={this.state.fileList}
                            // onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            customRequest={({ file, onSuccess, onError }) => {
                              onSuccess({ status: "done" }, file);
                            }}
                          >
                            {/* {this.state.fileList.length >= 0
                              ? null
                              : uploadButton} */}
                            {uploadButton}
                          </Upload>
                        </Form.Item>
                        {this.state.ID != 0 ? (
                          <Form.Item>
                            <img
                              src={this.state.garmentImage}
                              style={{ maxWidth: "100px", maxHeight: "100px" }}
                            />
                          </Form.Item>
                        ) : null}
                      </Space>
                    </>
                  ) : (
                    <Form.Item label="Image Url">
                      <img
                        src={this.state.garmentImage}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.popupMode === "view" ? (
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.popupMode === "add" ? "Add" : "Update"}
                        </Button>
                      </React.Fragment>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Master);
