import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../../../Common/Styles/master.module.css";
import { Steps, PageHeader, Form, notification, Spin } from "antd";
import Moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import GeneralForm from "./general.addpickup";
import RescheduleHistory from "./reschedulehistory.addpickup";
import PickupService from "../../../../../services/pickupservice";
import ROUTES from "../../../../../routes";
import { labels } from "../../../../../shared/language";
import Common from "../../../../../services/common";
const { Step } = Steps;

/**
 * Add Pickup Form
 *
 * @author Payal Bharakhda
 * @created 13  July 2020
 *
 * @module Store
 */
class AddPickup extends React.Component {
  pickupService = new PickupService();
  common = new Common();
  /**
   * State Variables
   *
   */
  state = {
    activeStep: 0,
    fullLoading: false,
    stepsDirection: "vertical",
    stepsSize: "default",
    generalFormValues: {},
    requestHistoryValues: {},
    mode: "add",
    deliveryDate: "",
    //general pickup info
    pickupID: 0,
    isValidCode: false,
    pickupStatusID: 0,
    sourceFromDelivery: 0,
    //reschedule history info
    submitbtnloading: false,

    storeID: 0,
    storeData: {
      data: [],
    },
    isGeneralFromCall: false,
    pickupTimeSlotName: "",
    pickupTimeSlotID: 0,
    deliveryTimeSlotID: 0,
    customerID: 0,
  };
  /**
   * A function to responsively set direction of the steps
   */
  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "small" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };

  /**
   * bind the setStepDirection to the resize event of windows
   */
  componentDidMount() {
    // console.log(this.props.location.state, this.props.operation);
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    this.setState({ isGeneralFromCall: false });
    if ([ROUTES.EDIT_PICKUP, ":pickupID"].join("/") === this.props.match.path) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });
      this.getPickupInfoByID(this.props.location.state.pickupID);
    } else if (
      [ROUTES.VIEW_PICKUP, ":pickupID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.getPickupInfoByID(this.props.location.state.pickupID);
    } else if (
      [ROUTES.ADD_PICKUP, ":customerID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      // console.log("customerID->", this.props.location.state.customerID);
      if (this.props.location.state.customerID != 0) {
        this.setState({
          generalFormValues: {
            customerName: this.props.location.state.customerID,
          },
        });
      }
      this.setgeneralFormvalue();
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
  }

  setgeneralFormvalue = () => {
    const timer = setTimeout(() => {
      this.setState({ fullLoading: false, isGeneralFromCall: true });
      this.setState({ activeStep: -1 });
      this.setState({ activeStep: 0 });
    }, 5000);
    return () => clearTimeout(timer);
  };

  getPickupInfoByID = (id) => {
    this.pickupService.getPickUpDetailsByID(id).then((response) => {
      // console.log("response->", response);
      if (
        response.statusCode === 200 &&
        response.data.pickupID === parseInt(id)
      ) {
        this.setState({
          pickupID: response.data.pickupID,
          pickupStatusID: response.data.status,
          sourceFromDelivery: response.data.source,
          storeID: response.data.storeID,
        });
        this.setState({
          generalFormValues: {
            customerName:
              this.state.mode === "view"
                ? response.data.customerName +
                  "(" +
                  response.data.contactNumber +
                  ")"
                : parseInt(response.data.customerID),
            address:
              this.state.mode === "view"
                ? response.data.address
                : parseInt(response.data.customerAddressID),
            pickupDate: Moment(response.data.pickupDate),
            timeslotID:
              this.state.mode === "view" || this.state.mode === "edit"
                ? response.data.pickupSlotName
                : parseInt(response.data.pickupSlotID),
            deliverySlot:
              this.state.mode === "view" || this.state.mode === "edit"
                ? response.data.deliverySlotName
                : //added
                response.data.deliverySlotID !== 0
                ? response.data.deliverySlotID
                : undefined,
            serviceIDs: [null, undefined, ""].includes(response.data.services)
              ? undefined
              : response.data.services.split(","),
            noOfGarments:
              response.data.noOfGarments === 0
                ? ""
                : response.data.noOfGarments,
            promocode: response.data.promocode,
            deliveryTypeID: response.data.deliveryType.toString(),
            opInstructID: ![null, undefined, ""].includes(
              response.data.operationInstID
            )
              ? response.data.operationInstID.split(",")
              : undefined,
            statusName: response.data.statusName,
            remarks: response.data.remark,
            // reason: response.data.canceledReason,

            reason: [null, undefined, ""].includes(response.data.canceledReason)
              ? undefined
              : response.data.canceledReason.split(","),
            originalpickupID: response.data.originalPickupID,
            // cancelRemarks: response.data.cancelReason,
            deliveryDate:
              //added
              response.data.deliveryDate === null
                ? undefined
                : Moment(response.data.deliveryDate),
          },
          isValidCode: [null, undefined, ""].includes(response.data.promocode)
            ? false
            : true,
          pickupTimeSlotName: response.data.pickupSlotName,
          pickupTimeSlotID: response.data.pickupSlotID,
          deliveryTimeSlotID: response.data.deliverySlotID,
        });
        this.setState({
          requestHistoryValues: {
            pickupDate: Moment(response.data.pickupDate),
            timeslotID: response.data.pickupSlotName,
            deliverySlot: response.data.deliverySlotName,
            deliveryDate: Moment(response.data.deliveryDate),
          },
        });
        this.setState({ fullLoading: false, isGeneralFromCall: true });
        this.setState({ activeStep: -1 });
        this.setState({ activeStep: 0 });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: labels.UNABLE_TO_GET_PICKUP_DATA,

          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
    });
  };

  addUpdatePickup = () => {
    const postData = {
      pickupID: this.state.pickupID,
      customerID: this.state.generalFormValues.customerName,
      customerAddressID: this.state.generalFormValues.address,
      pickupDate: this.state.generalFormValues.pickupDate.format("YYYY-MM-DD"),
      deliveryDate:
        this.state.generalFormValues.deliveryDate.format("YYYY-MM-DD"),
      pickupSlotID: ![null, undefined, "", NaN].includes(
        +this.state.generalFormValues.timeslotID
      )
        ? parseInt(this.state.generalFormValues.timeslotID)
        : this.state.pickupTimeSlotID,
      deliverySlotID: ![null, undefined, "", NaN].includes(
        +this.state.generalFormValues.deliverySlot
      )
        ? parseInt(this.state.generalFormValues.deliverySlot)
        : this.state.deliveryTimeSlotID,
      services: this.state.generalFormValues.serviceIDs.join(","),
      noOfGarments: ![null, undefined, ""].includes(
        this.state.generalFormValues.noOfGarments
      )
        ? parseInt(this.state.generalFormValues.noOfGarments)
        : null,
      uom: 0,
      deliveryType: parseInt(this.state.generalFormValues.deliveryTypeID),
      // storeID: parseInt(localStorage.getItem("branchID")),
      storeID:
        this.state.mode === "edit"
          ? this.state.storeID
          : this.state.storeData.data.storeID,
      promocode: this.state.generalFormValues.promocode
        ? this.state.generalFormValues.promocode.toString()
        : null,
      operationInstID: this.state.generalFormValues.opInstructID
        ? this.state.generalFormValues.opInstructID.join(",")
        : null,
      remark: this.state.generalFormValues.remarks
        ? this.state.generalFormValues.remarks.toString()
        : null,
      sourceFrom: 1,
    };
    this.setState({ fullLoading: true });
    this.pickupService.addUpdatePickupRequest(postData).then((response) => {
      if (response.statusCode === 200) {
        if (this.state.mode === "edit") {
          notification["success"]({
            message: labels.PICKUPS,
            description: response.message
              ? this.common.Message(response.message)
              : labels.PICKUP_IS_UPDATED,
            duration: 1.5,
            onClose: () => {
              this.props.history.goBack();
            },
          });
        } else {
          notification["success"]({
            message: labels.PICKUPS,
            description: response.message
              ? this.state.storeData.data.storeID ===
                parseInt(localStorage.getItem("branchID"))
                ? this.common.Message(response.message)
                : "Pickup ID: " +
                  response.data.pickupID +
                  " is scheduled and assigned to " +
                  this.state.storeData.data.storeName +
                  " store"
              : labels.PICKUP_IS_SCHEDULED,
            duration: 2,
            onClose: () => {
              this.props.history.goBack();
            },
          });
        }
      } else {
        response.length === 0
          ? notification["error"]({
              message: labels.PICKUPS,
              description: this.common.Message(response.message),
            })
          : notification["error"]({
              message: labels.PICKUPS,
              description: this.common.Message(response.message),
            });
      }
      this.setState({ fullLoading: false });
    });
  };

  reschedulePickup = () => {
    const postdata = {
      pickupID: this.state.pickupID,
      pickupDate: this.state.generalFormValues.pickupDate.format("YYYY-MM-DD"),
      pickupSlotID: ![null, undefined, ""].includes(
        this.state.generalFormValues.timeslotID
      )
        ? [null, undefined, "", NaN].includes(
            +this.state.generalFormValues.timeslotID
          )
          ? this.state.pickupTimeSlotID
          : parseInt(this.state.generalFormValues.timeslotID)
        : undefined,
      deliveryDate: ![null, undefined, ""].includes(
        this.state.generalFormValues.deliveryDate
      )
        ? this.state.generalFormValues.deliveryDate.format("YYYY-MM-DD")
        : undefined,
      deliverySlotID: ![null, undefined, ""].includes(
        this.state.generalFormValues.deliverySlot
      )
        ? [null, undefined, "", NaN].includes(
            +this.state.generalFormValues.deliverySlot
          )
          ? this.state.deliveryTimeSlotID
          : parseInt(this.state.generalFormValues.deliverySlot)
        : undefined,
      remark: this.state.generalFormValues.remarks,
      sourceFrom: 1,
    };
    this.setState({ fullLoading: true });
    this.pickupService.requestReschedulePickup(postdata).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.PICKUP_IS_RESCHEDULED_SUCCESSFULLY,
          duration: 1.5,
          onClose: () => {
            this.props.history.goBack();
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_RESCHEDULE_THE_PICKUP,
        });
      }
      this.setState({ fullLoading: false });
    });
  };

  cancelPickup = () => {
    if (this.state.generalFormValues.reason === "")
      return notification["error"]({
        message: labels.PICKUPS,
        description: labels.CANCEL_REASON_IS_REQUIRED,
      });

    const postdata = {
      pickupID: this.state.pickupID,
      cancelReason:
        this.state.generalFormValues.reason != "Others"
          ? this.state.generalFormValues.reason.toString()
          : this.state.generalFormValues.cancelRemarks,
      // CancelRequestedReason:null,
      originalpickupID: this.state.generalFormValues.originalpickupID
        ? parseInt(this.state.generalFormValues.originalpickupID)
        : 0,
      sourceFrom: 1,
    };
    // console.log("postdata->", postdata);
    this.setState({ fullLoading: true });
    this.pickupService.requestCancelPickup(postdata).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.PICKUP_IS_CANCELLED_SUCCESSFULLY,
          duration: 1.5,
          onClose: () => {
            this.props.history.goBack();
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_CANCEL_PICKUP,
        });
      }
      this.setState({ fullLoading: false });
    });
  };

  generalForm = React.createRef();
  handleSubmit = () => {
    // console.log("here");
    const generalValidator = this.generalForm.current.validateFields();
    Promise.allSettled([generalValidator]).then((responses) => {
      // console.log("responses->",responses);
      if (responses[0].status === "rejected") {
        this.setState({ activeStep: 0 });
      } else if (responses[0].status === "fulfilled") {
        this.setState(
          {
            generalFormValues: responses[0].value,
          },
          () => {
            let statusName = "";
            if (this.state.generalFormValues.statusName == undefined) {
              statusName = "1";
            } else if (this.state.generalFormValues.statusName == "Assigned") {
              statusName = "Assigned";
            }

            if (
              (this.state.pickupStatusID === 1 &&
                //added condition  for live issue : L15473
                this.state.generalFormValues.statusName !== "4" &&
                this.state.generalFormValues.statusName !== "5") ||
              statusName === "1" ||
              statusName === "Assigned" ||
              this.state.generalFormValues.statusName === "8" ||
              this.state.generalFormValues.statusName === "Reschedule Requested"
              ||  this.state.generalFormValues.statusName === "2" // added by hasti
            ) {
              this.addUpdatePickup();
            } else if (this.state.generalFormValues.statusName === "5") {
              this.reschedulePickup();
            } else if (this.state.generalFormValues.statusName === "4") {
              this.cancelPickup();
            }
          }
        );
      }
    });
  };

  getDeliveryDate = (dateObj) => {
    this.setState({ deliveryDate: dateObj });
  };

  getValidCode = (value) => {
    this.setState({ isValidCode: value });
  };

  getStoreIDAndNameByCustAddress = (value) => {
    this.setState({
      storeData: {
        data: value,
      },
    });
  };

  render() {
    return (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          {/* {JSON.stringify(this.state)} */}
          {/**
           * Page Header Component from Ant Design
           * Please check below link for more options
           * https://ant.design/components/page-header/
           * https://ant.design/components/page-header/#API
           *
           */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? labels.EDIT
                : this.state.mode === "view"
                ? labels.VIEW
                : labels.ADD,
              labels.PICKUP,
            ].join(" ")}
          />

          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              {/**
               * Steps Component from Ant Design
               * Please check below link for more options
               * https://ant.design/components/steps/
               * https://ant.design/components/steps/#API
               *
               */}
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => this.setState({ activeStep: current })}
              >
                <Step
                  title={labels.GENERAL}
                  description={labels.GENERAL_PICKUP_INFORMATION}
                />
                <Step
                  title={labels.HISTORY}
                  description={labels.HISTORY_OF_RESCHEDULED_PICKUPS}
                />
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider>
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading}>
                  <React.Fragment>
                    {this.state.isGeneralFromCall ? (
                      <GeneralForm
                        values={this.state.generalFormValues}
                        backFunction={() => this.props.history.goBack()}
                        nextFunction={this.handleSubmit}
                        pickupStatusID={this.state.pickupStatusID}
                        getDeliveryDateFunction={this.getDeliveryDate}
                        isActive={this.state.activeStep === 0}
                        instance={this.generalForm}
                        mode={this.state.mode}
                        isValidCodeMethod={this.getValidCode}
                        isValidCode={this.state.isValidCode}
                        sourceFromDelivery={this.state.sourceFromDelivery}
                        submitbtnloading={this.state.submitbtnloading}
                        getStoreIDStoreName={
                          this.getStoreIDAndNameByCustAddress
                        }
                        storeID={this.state.storeID}
                        pickupID={this.state.pickupID}
                        pickupTimeSlotName={this.state.pickupTimeSlotName}
                      />
                    ) : (
                      <GeneralForm
                        values={this.state.generalFormValues}
                        backFunction={() => this.props.history.goBack()}
                        nextFunction={this.handleSubmit}
                        pickupStatusID={this.state.pickupStatusID}
                        getDeliveryDateFunction={this.getDeliveryDate}
                        isActive={this.state.activeStep === 0}
                        instance={this.generalForm}
                        mode={this.state.mode}
                        isValidCodeMethod={this.getValidCode}
                        isValidCode={this.state.isValidCode}
                        sourceFromDelivery={this.state.sourceFromDelivery}
                        submitbtnloading={this.state.submitbtnloading}
                        getStoreIDStoreName={
                          this.getStoreIDAndNameByCustAddress
                        }
                        pickupID={this.state.pickupID}
                        pickupTimeSlotName={this.state.pickupTimeSlotName}
                      />
                    )}

                    <RescheduleHistory
                      values={this.state.requestHistoryValues}
                      backFunction={() =>
                        this.setState({ activeStep: this.state.activeStep - 1 })
                      }
                      // nextFunction={this.handleSubmit}
                      isActive={this.state.activeStep === 1}
                      instance={this.contactForm}
                      mode={this.state.mode}
                    />
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(AddPickup);
