import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Tooltip,
  Spin,
  DatePicker,
  Checkbox,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import pendingcashsummaryservice from "../../../services/pendingcashsummaryservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
import moment from "moment";
const { Option } = Select;
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);
const Temp_Date = new Date('2022-05-01');

export default class pendingcashsummary extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.pendingcashsummaryservice = new pendingcashsummaryservice();
    this.common = new Common();
    this.state = {
      selectedBranch: localStorage.getItem("branchID"),
      invoiceFromDate:
        moment(ONE_MONTHS_AGO) < moment("2022-04-30")
          ? moment(Temp_Date) //moment("2022-05-01")
          : moment(ONE_MONTHS_AGO),
      invoiceToDate: moment(),
      selectedDeliverytype: "1",
      modalLoading: false,
      dashboardItems: [],
      detailItems: [],
      deliveryTypes: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      paginationforDetailtable: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      rowselectedvalue: "",
      showfilterBox: true,
      showAddPopup: false,
      loading: true,
      userRights: [],
      isResponseErrorShow: false,
    };
  }

  //   componentWillMount() {
  //     if (!IsAuthenticated()) {
  //       window.location.href = "/";
  //     }

  //     var data = JSON.parse(localStorage.getItem("userrights"));
  //     var res = data.filter(
  //       (val) => val.screenName == this.UserRightsConfiguration.CITY_MASTER
  //     );

  //     const storeOrFactory = localStorage
  //       .getItem("Store/FactoryType")
  //       .toLowerCase();
  //     if (!res[0].isView) {
  //       if (storeOrFactory === "store") {
  //         window.location.href = "/store/dashboard";
  //       } else {
  //         window.location.href = "/factory/dashboard";
  //       }
  //     }
  //     this.setState({ userRights: res });
  //   }

  columns = [
    {
      title: "Store Name",
      dataIndex: "shortName",
      key: "shortName",
    },

    {
      title: "Normal Order",
      dataIndex: "normalOrder",
      key: "normalOrder",
    },
    {
      title: "Amount",
      dataIndex: "normalOrderAmount",
      key: "normalOrderAmount",
    },

    {
      title: "SemiExpress Order",
      dataIndex: "semiexpressOrder",
      key: "semiexpressOrder",
    },

    {
      title: "Amount",
      dataIndex: "semiexpressOrderAmount",
      key: "semiexpressOrderAmount",
    },

    {
      title: "Express Order",
      dataIndex: "expressOrder",
      key: "expressOrder",
    },

    {
      title: "Amount",
      dataIndex: "expressOrderAmount",
      key: "expressOrderAmount",
    },

    {
      title: "Total Order",
      dataIndex: "totalOrder",
      key: "totalOrder",
    },

    {
      title: "Total Amount",
      dataIndex: "totalOrderAmount",
      key: "totalOrderAmount",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => this.getStoreWiseData(record.storeID)}
          >
           <text style={{textDecorationLine:"underline"}}> {text}</text>
          </Button>
        </Space>
      ),
    },
  ];

  Detailcolumns = [
    {
      title: "Store Name",
      dataIndex: "shortName",
      key: "shortName",
    },
    {
      title: "SplitOrder Number",
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (text) => {
        return ![undefined, null, ""].includes(text)
          ? moment(text).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Amount",
      dataIndex: "finalInvoiceAmount",
      key: "finalInvoiceAmount",
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      key: "orderType",
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      FromInvoiceDate: this.state.invoiceFromDate,
      ToInvoiceDate: this.state.invoiceToDate,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      StoreID: this.state.selectedBranch,
      pagination,
    });
  }

  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.pendingcashsummaryservice.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            FromInvoiceDate: this.state.invoiceFromDate,
            ToInvoiceDate: this.state.invoiceToDate,
            StoreID: this.state.selectedBranch,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          dashboardItems: items.data,
          pagination: {
            ...params.pagination,
            total: items.data.length > 0 ? items.data[0].totalRecords : 0,
          },
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "Pending Cash Summary",
            description: items.message
              ? items.message
              : "Unable to get Pending Cash Summary data",
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
      }
      this.setState({ loading: false });
    });
  };

  getStoreWiseData = (storeId) => {
    // this.setState({detailItems:[]})
    let params = {
      StoreId: parseInt(storeId),
      FromInvoiceDate:this.state.invoiceFromDate,
      ToInvoiceDate: this.state.invoiceToDate,
    };

    // console.log("params->>>",params);

    this.setState({ modalLoading: true });
    this.pendingcashsummaryservice
      .getPendingCashSummaryfromStoreID(params)
      .then((items) => {
        if (items.statusCode === 200) {
          if (
            items.length !== 0 &&
            items.data.length === 0 &&
            params.pagination.current !== 1
          ) {
            params.pagination.current = 1;
            this.fetchItems({
              FromInvoiceDate: this.state.invoiceFromDate,
              ToInvoiceDate: this.state.invoiceToDate,
              StoreID: this.state.selectedBranch,
              pageIndex: 1,
              pageSize: params.pageSize,
              pagination: params.pagination,
            });
          }

          this.setState({
            detailItems: items.data,
            showAddPopup: true,
            paginationforDetailtable: {
              ...params.pagination,
              total: items.data.length > 0 ? items.data[0].totalRecords : 0,
            },
            // isRenderComponent: true,
          });
        } else {
          if (!this.state.isResponseErrorShow) {
            notification["error"]({
              message: "Pending Cash Summary",
              description: items.message
                ? items.message
                : "Unable to get Pending Cash Summary data",
            });
            this.setState({
              isResponseErrorShow: true,
            });
          }
        }
        this.setState({ modalLoading: false });
      });
  };

  handleTableChange = (pagination) => {
    this.fetchItems({
      FromInvoiceDate: this.state.invoiceFromDate,
      ToInvoiceDate: this.state.invoiceToDate,
      StoreID: this.state.selectedBranch,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  handleChangeBranch = () => {
    // console.log("selected->", this.state.selectedBranch);
  };

  render() {
    const storeBranchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo")).filter(
          (x) => x.branchType === "Store"
        )
      : [];

    const allStoreBranchArray = storeBranchTypes
      .map((branchType) => {
        return branchType.branchID;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();
    const { paginationforDetailtable, pagination, loading } = this.state;
    const dashboardItems = this.state.dashboardItems;
    const listItems = dashboardItems.map((item, index) => ({
      key: item.storeID,
      storeID: item.storeID,
      storeName: item.storeName,
      shortName: item.shortName,
      normalOrder: item.normalOrder,
      normalOrderAmount: item.normalOrderAmount,
      semiexpressOrder: item.semiexpressOrder,
      semiexpressOrderAmount: item.semiexpressOrderAmount,
      expressOrder: item.expressOrder,
      expressOrderAmount: item.expressOrderAmount,
      totalOrder: item.totalOrder,
      totalOrderAmount: item.totalOrderAmount,
      totalRecords: item.totalRecords,
    }));
    const detailItems = this.state.detailItems;
    const DetailListItem = detailItems.map((item) => ({
      // key: item.storeID,
      shortName: item.shortName,
      splitOrderNumber: item.splitOrderNumber,
      invoiceDate: item.invoiceDate,
      finalInvoiceAmount: item.finalInvoiceAmount,
      orderType: item.orderType,
    }));
    return (
      <div>
        <PageHeader
          title={labels.PENDING_CASH_SUMMARY}
          extra={[
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.fetchItems({
                FromInvoiceDate: this.state.invoiceFromDate,
                ToInvoiceDate: this.state.invoiceToDate,
                StoreID: this.state.selectedBranch,
                pageIndex: pagination.current,
                pageSize: pagination.pageSize,
                pagination,
              })
            }
            initialValues={{
              invoiceFromDate: this.state.invoiceFromDate,
              invoiceToDate: this.state.invoiceToDate,
              DeliveryType: this.state.selectedDeliverytype,
              storeId: this.state.selectedBranch,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"invoiceFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "invoiceFromDate")
                    }
                    value={this.state.invoiceFromDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(moment("2022-04-30"), "day")
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"invoiceToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "invoiceToDate")
                    }
                    value={this.state.invoiceToDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={6}>
                <Form.Item
                  name={"storeId"}
                  // rules={[{ required: true, message: "Please select store " }]}
                >
                 
                    <div>
                      <Select
                        showSearch
                        mode="multiple"
                        allowClear
                        onChange={(event) => {
                          this.setState(
                            {
                              selectedBranch: [null, undefined, ""].includes(
                                event
                              )
                                ? ""
                                : event.join(","),
                            },
                            () => {
                              this.handleChangeBranch();
                            }
                          );
                        }}
                        value={
                          [null, undefined, ""].includes(
                            this.state.selectedBranch
                          )
                            ? [localStorage.getItem("branchID")]
                            : this.state.selectedBranch.split(",")
                        }
                        placeholder={labels.SELECT_STORE}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {storeBranchTypes.length !== 0
                          ? storeBranchTypes.map((branchType) => {
                              return branchType.branchID !== "-1" ? (
                                <Option
                                  value={branchType.branchID.toString()}
                                  key={branchType.branchID.toString()}
                                >
                                  {branchType.branchName}
                                </Option>
                              ) : null;
                            })
                          : null}
                      </Select>
                    </div>
                  
                    <div>
                      <Checkbox
                        checked={
                          ![null, undefined, ""].includes(
                            this.state.selectedBranch
                          ) &&
                          this.state.selectedBranch.split(",").length ===
                            allStoreBranchArray.length &&
                          this.state.selectedBranch.split(",").slice().sort()
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            let allOpt = storeBranchTypes
                              .map((branchType) => {
                                return branchType.branchID;
                              })
                              .filter((x) => x !== "-1");
                            this.setState(
                              {
                                selectedBranch: allOpt.join(","),
                              },
                              () => {
                                if (this.state.selectedBranch !== "") {
                                  this.handleChangeBranch();
                                }
                              }
                            );
                          } else {
                            this.setState(
                              {
                                selectedBranch:
                                  localStorage.getItem("branchID"),
                              },
                              () => {
                                this.handleChangeBranch();
                              }
                            );
                          }
                        }}
                      >
                        {labels.ALL}
                      </Checkbox>
                    </div>
                 
                </Form.Item>
              </Col>

              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}></TableActions>

        <Modal
          title={"Pending Cash Summary"}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          width={"50%"}
        >
          <Spin spinning={this.state.modalLoading}>
            <Table
              size="small"
              columns={this.Detailcolumns}
              dataSource={DetailListItem}
              loading={loading}
              pagination={paginationforDetailtable}
              //   onChange={this.handleTableChange}
              // onChange={this.handleTableChangeforDetailData}
            />
            <TableActions isPagination={true}></TableActions>
            <Row>
              <Col xs={24} className="custom-modal-footer">
                <Space>
                  <>
                    <Button
                      variant="transparentGreyOutline"
                      onClick={this.handlePopupCancel}
                    >
                      Close
                    </Button>
                  </>
                </Space>
              </Col>
            </Row>
          </Spin>
        </Modal>
      </div>
    );
  }
}
