import React from "react";
import CorporateMaster from "../../../components/Master/Corporate/corporatemaster.component";
/**
 * Factory Master Screen
 *
 * @author Niraj Gohel
 * @created 30 June 2020
 */
export default class CorporateMasterScreen extends React.Component {
  render() {
    return (
      <div>
        <CorporateMaster></CorporateMaster>
      </div>
    );
  }
}
