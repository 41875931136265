import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  TimePicker,
  PageHeader,
  Typography,
  Space,
  notification,
  Spin,
} from "antd";
import Button from "../../../Common/Button/button";
import styles from "./createstore.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import FactoryMapping from "./factorymapping.component";
import StoreService from "../../../../services/storeservice";
import UserService from "../../../../services/userservice";
import Moment from "moment";

const { Option } = Select;
const { Title } = Typography;
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

/**
 * A form as a part of create store - Add Slot Form
 *
 * @props backFunction: a function to modify parent state to go back to tabular view
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 3 June 2020
 *
 * @module Store
 */
export default class FactoryMappingForm extends React.Component {
  constructor(props) {
    super(props);
    this.storeservice = new StoreService();
    this.userservice = new UserService();
    this.state = {
      fullLoading: false,
      isAdd: false,
      isView: false,
      disabled: false,
      factoryList: [],
      offerredServices: [],
      factoryMappinglist: [],
      storeFactoryMappingID: 0,
      factoryID: "",
      factoryName: "",
      offeredServiceIds: [],
      offeredServicesName: "",
      factoryFormValues: {},
      selectedofferedServiceIds: "",
      selectedFactory: 0,
      // slotID: 0,
      // weekDayID: 0,
      // weekDayName: "",
      // slotFrom: "",
      // slotTo: "",

      // slotMappingList: [],
      showComponent: false,
      currentComponent: true,
      // slotFormValues: {},
    };
    this.handleSaveUpdateStoreFactoryMapping = this.handleSaveUpdateStoreFactoryMapping.bind(
      this
    );
  }

  componentDidMount() {
    this.getFactory();
    if (this.props.isAdd !== "add") {
      this.setState(
        {
          storeFactoryMappingID: this.props.storeFactoryMappingID,
          factoryID: this.props.factoryID,
          // factoryName: this.props.factoryName,
          offeredServiceIds: this.props.offeredServiceIds.split(","),
          // offeredServicesName: this.props.offeredServicesName,
          isView: this.props.isView,
          disabled: this.props.disabled,
          // slotID: this.props.slotID,
          // weekDayName: this.props.weekDayName,
          // weekDayID: this.props.weekDayID,

          // slotFrom: Moment(this.props.slotFrom, "h:mm a"),
          // slotTo: Moment(this.props.slotTo, "h:mm a"),
        },
        () => {
          if (![null, undefined, "", 0].includes(this.state.factoryID))
            this.GetOfferedServicesByFactoryID(this.state.factoryID);
        }
      );
    } else {
      this.setState({
        storeFactoryMappingID: 0,
        factoryID: undefined,
        offeredServiceIds: undefined,
        // isView: this.props.isView,
        //  disabled: this.props.disabled,
      });
    }
    if (this.props.factoryMappinglist !== null) {
      this.setState({ factoryMappinglist: this.props.factoryMappinglist });
    }
  }

  getFactory = () => {
    this.userservice.retrieveFactory().then((factory) => {
      if (factory.statusCode === 200) {
        this.setState({ factoryList: factory.data });
      } else {
        notification["error"]({
          message: "Users",
          description: "Unable to get data for factory",
        });
      }
    });
  };

  GetOfferedServicesByFactoryID = (FactoryID) => {
    this.storeservice
      .GetOfferedServicesByFactoryID(parseInt(FactoryID))
      .then((services) => {
        if (services.statusCode === 200) {
          this.setState({
            offerredServices: services.data,
          });
        } else {
          notification["error"]({
            message: "Store Master",
            description: "Unable to get list for services offered data",
          });
        }
      });
  };

  handleSaveUpdateStoreFactoryMapping = () => {
    const postSave = {
      storeFactoryMappingID: this.state.storeFactoryMappingID,
      storeID: this.props.storeID,
      factoryID: parseInt(this.state.selectedFactory),
      offeredServiceIds: this.state.selectedofferedServiceIds,
    };
    this.setState({ fullLoading: true });
    this.storeservice
      .saveUpdateStoreFactoryMapping(postSave)
      .then((response) => {
        if (response.result === "Success") {
          openNotificationWithIcon(
            "success",
            "Factory Mapping",
            response.message
          );
          this.setState({ showComponent: true });
        } else {
          openNotificationWithIcon(
            "error",
            "Factory Mapping",
            response.message
          );
        }
        this.setState({ fullLoading: false });
      });
  };

  factoryForm = React.createRef();
  slotFormValidated = false;

  handleSubmit = () => {
    const slotValidator = this.factoryForm.validateFields();

    Promise.allSettled([slotValidator]).then((responses) => {
      if (responses[0].status === "rejected") {
      } else if (responses[0].status === "fulfilled") {
        this.setState(
          {
            factoryFormValues: responses[0].value,
          },
          () => {
            this.handleSaveUpdateStoreFactoryMapping();
          }
        );
      }
    });
  };

  onFinishFactoryForm = () => {
    return null;
  };

  render() {
    const { disabled, factoryID, offeredServiceIds } = this.state;
    return (
      <Spin spinning={this.state.fullLoading}>
        {this.state.showComponent ? (
          <FactoryMapping
            backFunction={this.bringback}
            storeID={this.props.storeID}
          />
        ) : (
          <Form
            layout="vertical"
            initialValues={{ factoryID, offeredServiceIds }}
            ref={(factoryForm) => (this.factoryForm = factoryForm)}
            onFinish={this.onFinishFactoryForm}
            className={styles.AddSlotForm}
            key={factoryID + offeredServiceIds}
          >
            {/**
             * Please go through below documentation befor modifying
             * https://ant.design/components/page-header/
             * https://ant.design/components/page-header/#API
             */}
            <PageHeader
              title={
                <Title level={4} className={styles.SlotMappingTitle}>
                  {this.state.storeFactoryMappingID === 0
                    ? "Add Factory Mapping"
                    : this.state.isView
                    ? "View Factory Mapping"
                    : "Edit Factory Mapping"}
                </Title>
              }
              backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
              onBack={this.props.backFunction}
              className={styles.AddSlotFormHeader}
            />
            <Row gutter={30}>
              <Col xs={8}>
                <Form.Item
                  label="Factory"
                  name="factoryID"
                  rules={[{ required: true, message: "Please select factory" }]}
                >
                  <Select
                    placeholder="Select Factory"
                    onChange={
                      (value) => {
                        this.setState(
                          {
                            selectedFactory: value,
                            factoryID: value,
                            offeredServiceIds: [],
                          },
                          () =>
                            ![null, undefined, ""].includes(value)
                              ? this.GetOfferedServicesByFactoryID(value)
                              : value
                        );
                      }
                      // this.GetOfferedServicesByFactoryID(value, 1)
                    }
                    disabled={disabled}
                  >
                    {this.state.factoryList.map((factoryList) => {
                      return factoryList.value !== "-1" ? (
                        <Option
                          value={factoryList.value.toString()}
                          key={factoryList.value.toString()}
                        >
                          {factoryList.text}
                        </Option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col xs={16}>
                <Form.Item
                  label="Services Offered"
                  name="offeredServiceIds"
                  dependencies={["factoryID"]}
                  rules={[
                    {
                      required: true,
                      message: "Please select services offered",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Services Offered"
                    disabled={disabled}
                    showSearch
                    mode="multiple"
                    // value={
                    //   [null, undefined, "", []].includes(
                    //     this.state.selectedofferedServiceIds
                    //   )
                    //     ? undefined
                    //     : this.state.selectedofferedServiceIds.split(",")
                    // }
                    // shouldUpdate={(prev, next) => prev.state !== next.state}
                    onChange={(value) =>
                      this.setState({
                        selectedofferedServiceIds: [
                          null,
                          undefined,
                          "",
                        ].includes(value)
                          ? undefined
                          : value.join(","),
                      })
                    }
                  >
                    {this.state.offerredServices.map((service) => {
                      return service.value !== "-1" ? (
                        <Option
                          value={service.value.toString()}
                          key={service.value.toString()}
                        >
                          {service.text}
                        </Option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className={styles.ActionsRow}>
              <Col xs={24} className={styles.ButtonContainer}>
                <Space size="middle">
                  <Button
                    variant="transparentGreyOutline"
                    className={styles.CancelButton}
                    shape="round"
                    size="large"
                    onClick={this.props.backFunction}
                  >
                    Cancel
                  </Button>
                  {!this.state.isView ? (
                    <Button
                      htmlType="submit"
                      variant="purple"
                      shape="round"
                      size="large"
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                  ) : null}
                  {this.state.showComponent ? (
                    <FactoryMapping data={this.state.factoryMappinglist} />
                  ) : null}
                </Space>
              </Col>
            </Row>
          </Form>
        )}
      </Spin>
    );
  }
}
