import React from "react";
// import styles from "./transferoutmasterscreen.module.css";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
// import Filters from "../../Common/Filters/filters";
// import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Select,
  Table,
  Input,
  // Modal,
  // Descriptions,
  notification,
} from "antd";
import TransferOutService from "../../../services/factorytransferoutservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import moment from "moment";
import Logo from "../../../images/Common/Logo.png";
import html2pdf from "html2pdf.js";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";
// import { LabelList } from "recharts";

var jwt = require("jsonwebtoken");
const { Option } = Select;
const { Search } = Input;

export default class Master extends React.Component {
  TransferOutService = new TransferOutService();
  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();
  state = {
    generateBtnLoading: false,
    tableLoading: false,
    showInfoPopup: false,
    storeListOptions: [],
    selectedStore: "",
    rowselectedvalue: "",
    storeOutStickerData: [],
    loading: true,
    isStickerDataLoaded: false,
    printInfoHeaderData: {},
    printInfoRowData: [],
    totalMaterial: {},
    serviceWiseData: [],
    isPDFDataLoaded: false,
    gatePassNum: "",
    isReprintDisable: true,
    selectedRowKeys: [],
    selectedSplitOrders: [],
    Msgcounter: 0,
    enableSearchBox: true,
    disableGatePassbtn: true,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  columns = [
    {
      title: labels.STICKER_NO,
      dataIndex: "stickerID",
      key: "stickerID",
      sorter: true,
    },
    {
      title: labels.SPLLIT_ORDER_NO,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },
    {
      title: labels.CUSTOMER_NAME,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: labels.CUSTOMER_TYPE,
      dataIndex: "customerTypeName",
      key: "customerTypeName",
      sorter: true,
    },
    {
      title: labels.PACKED_GARMENTS,
      dataIndex: "packedGarments",
      key: "packedGarments",
      sorter: true,
    },
    {
      title: labels.PACKING_MATERIAL_NAME,
      dataIndex: "packingMaterialName",
      key: "packingMaterialName",
      sorter: true,
    },
    {
      title: labels.DELIVERY_TYPE,
      dataIndex: "deliveryType",
      key: "deliveryType",
      sorter: true,
    },
    {
      title: labels.DELIVERY_DATE,
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    //  {
    //   title: "total",
    //   dataIndex: "total",
    //   key: "total",
    // },
    ,
  ];
  componentDidMount() {
    this.getStoreList();
  }

  resetData = () => {
    this.setState({
      selectedRowKeys: "",
      selectedStore: "",
      isStickerDataLoaded: false,
    });
  };
  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    // onChange: (selectedRowKeys, selectedRows) => {
    //   let selectedSplitOrders = selectedRows.map((x) => x.splitOrderNumber);
    //   let selectedStickers = this.state.storeOutStickerData
    //     .filter((x) => selectedSplitOrders.includes(x.splitOrderNumber))
    //     .map((x) => x.stickerID);
    //   console.log(selectedStickers);
    //   this.setState({ selectedRowKeys: selectedStickers });
    // },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      if (selected) {
        let selectedSplitOrders = [...this.state.selectedSplitOrders];
        selectedSplitOrders.push(record.splitOrderNumber);
        this.setState({ selectedSplitOrders: selectedSplitOrders });
        let selectedStickers = this.state.storeOutStickerData
          .filter((x) => selectedSplitOrders.includes(x.splitOrderNumber))
          .map((x) => x.stickerID);
        this.setState({
          selectedRowKeys: selectedStickers,
        });
      } else {
        let selectedSplitOrders = [...this.state.selectedSplitOrders];
        selectedSplitOrders = selectedSplitOrders.filter(
          (x) => x !== record.splitOrderNumber
        );
        this.setState({ selectedSplitOrders: selectedSplitOrders });
        let selectedStickers = this.state.storeOutStickerData
          .filter((x) => selectedSplitOrders.includes(x.splitOrderNumber))
          .map((x) => x.stickerID);
        this.setState({
          selectedRowKeys: selectedStickers,
        });
      }
    },
    onSelectAll: (selected) => {
      if (selected) {
        let selectedStickers = this.state.storeOutStickerData.map(
          (x) => x.stickerID
        );
        this.setState({ selectedRowKeys: selectedStickers });
      } else {
        this.setState({ selectedRowKeys: [] });
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };
  /**
   * Data to be displayed in table
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   *
   */

  /**
   * Generating dummy rows to implement pagination
   */
  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.FACTORY_OUT
    );

    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
  }

  generatePDFGatePass = () => {
    this.TransferOutService.getPDFGatePass(this.state.gatePassNum).then(
      (res) => {
        console.log("res->", res);
        this.setState(
          {
            printInfoHeaderData: res.data.objVMFactoryPrintGatePassHeaderData,
            printInfoRowData: res.data.lstVMFactoryPrintGatePassRowData,
            totalMaterial: res.data.totalMaterial,
            servicewisedata: res.data.servicewisedata,

            isPDFDataLoaded: true,
          },
          () => {
            var opt = {
              margin: 1,
              filename: "FactoryGatePass.pdf",
              jsPDF: { format: "a4", orientation: "portrait" },
              pagebreak: { mode: ["css", "legacy"], avoid: "tr" },
              html2canvas: {
                dpi: 192,
                scale: 4,
              },
            };
            html2pdf().set(opt).from(this.element.current).save();
          }
        );
        this.setState(
          {
            selectedStore: "",
            rowselectedvalue: "",
            storeOutStickerData: [],
            tableLoading: false,
            selectedRowKeys: [],
          }
          // ,
          // () => this.getStickersToTransferOut()
        );
      }
    );
  };

  element = React.createRef();
  generateGatePass = () => {
    var userID = jwt.decode(
      JSON.parse(localStorage.getItem("currentuser")).token
    ).unique_name;

    let stickerIdList = "";
    for (let i = 0; i < this.state.storeOutStickerData.length; i++) {
      stickerIdList += this.state.storeOutStickerData[i].stickerID + ",";
    }
    var stickerIDs = stickerIdList.substring(0, stickerIdList.length - 1);
    if (stickerIDs != "") {
      const params = {
        storeID: parseInt(this.state.selectedStore),
        factoryID: parseInt(localStorage.getItem("branchID")),
        UserID: parseInt(userID),
        stickerIds: this.state.selectedRowKeys.join(","),
      };
      this.setState({ generateBtnLoading: true });
      this.TransferOutService.generateGatePass(params).then((items) => {
        if (items.statusCode === 200) {
          this.setState({ selectedStore: null });
          notification["info"]({
            message: labels.TRANSFER_OUT,
            description: labels.GENERATING_GATE_PASS,
            duration: 1.0,
            onClose: () => {
              this.setState(
                { gatePassNum: items.data.gatePassNo, isReprintDisable: false },
                () => this.generatePDFGatePass()
              );
            },
          });
        }
        this.setState({ generateBtnLoading: false, disableGatePassbtn: true });
      });
    } else {
      notification["error"]({
        message: labels.TRANSFER_OUT,
        description: labels.PLEASE_SELECT_STICKERS,
      });
    }
  };

  getStoreList = () => {
    this.setState({ tableLoading: true });
    this.TransferOutService.retrieveStoreList().then((items) => {
      if (items.statusCode === 200) {
        this.setState({ storeListOptions: items.data });
      } else {
        notification["error"]({
          message: labels.TRANSFER_OUT,
          description: items.message
            ? this.common.Message(items.message)
            : labels.UNABLE_TO_GET_LIST_OF_STORE_DATA,
        });
      }
      this.setState({ tableLoading: false });
    });
  };

  getStickersToTransferOut = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    // const params = {
    //   storeID: parseInt(this.state.selectedStore),
    //   factoryID: parseInt(localStorage.getItem("branchID")),
    // };
    this.setState({ tableLoading: true });

    if ([undefined, null, ""].includes(this.state.selectedStore)) return false;

    this.TransferOutService.getStickersToTransferOut(
      index,
      size,
      sortColumn,
      sortOrder,
      search,
      parseInt(this.state.selectedStore),
      parseInt(localStorage.getItem("branchID"))
    ).then((response) => {
      // if (response.statusCode === 200 && response.data.length > 0) {
      if (response.statusCode === 200) {
        if (response.data.length === 0 && this.state.pagination.current !== 1) {
          this.setState(
            { pagination: { ...this.state.pagination, current: 1 } },
            () => {
              this.getStickersToTransferOut(
                1,
                size,
                sortColumn,
                sortOrder,
                search
              );
            }
          );
        }
        if (response.data.length > 0) {
          this.setState({
            storeOutStickerData: response.data,
            isStickerDataLoaded: true,
          });
          let selectedStickers = this.state.storeOutStickerData
            .filter((x) =>
              this.state.selectedSplitOrders.includes(x.splitOrderNumber)
            )
            .map((x) => x.stickerID);
          selectedStickers.push(...this.state.selectedRowKeys);
          this.setState({
            // ...this.state.selectedRowKeys,
            selectedRowKeys: selectedStickers,
          });
        } else {
          if (this.state.Msgcounter === 0) {
            this.setState({ Msgcounter: this.state.Msgcounter + 1 });
            notification["error"]({
              message: labels.TRANSFER_OUT,
              description: labels.UNABLE_TO_GET_STICKERS_TO_TRANSFER_OUT,
              onClose: () => {
                this.setState({ Msgcounter: this.state.Msgcounter - 1 });
              },
            });
          }
          // }
          this.setState({ storeOutStickerData: [] });
        }
        this.setState({
          pagination: {
            ...this.state.pagination,
            total: this.state.storeOutStickerData.length
              ? this.state.storeOutStickerData[0].totalRecords
              : 0,
            current: this.state.storeOutStickerData.length
              ? this.state.pagination.current
              : 1,
          },
        });
      }
      else {
        notification["error"]({
          message: labels.TRANSFER_OUT,
          description:  this.common.Message(response.message)
        });
      }
      this.setState({ tableLoading: false });
      this.setState({ loading: false });
    });
  };

  render() {
    const { loading } = this.state;
    const storeOutStickerData = this.state.storeOutStickerData;
    const listItems = storeOutStickerData.map((item, index) => ({
      key: item.stickerID,
      stickerID: item.stickerID,
      splitOrderNumber: item.splitOrderNumber,
      customerName: item.customerName,
      customerTypeName: item.customerTypeName,
      packedGarments: item.packedGarments,
      packingMaterialID: item.packingMaterialID,
      packingMaterialName: item.packingMaterialName,
      deliveryType: item.deliveryType,
      deliveryDate: item.deliveryDate,
      totalRecords: item.totalRecords,
    }));
    let data = <tr></tr>;
    if (this.state.isPDFDataLoaded) {
      data = this.state.printInfoRowData.map((item, index) => {
        return (
          <tr style={{ fontSize: 12 }} key={index}>
            <td>{item.id}</td>
            <td>{item.splitOrderNumber}</td>
            <td>{item.deliveryTypeName}</td>
            <td>{item.totalGarments}</td>
            <td>{item.previouslySentGarment}</td>
            <td>{item.sendingTodayGarments}</td>
            <td>{item.pendingGarments}</td>
            <td>{item.bag}</td>
            <td>{item.hanger}</td>
            <td>{item.blanket}</td>
            <td>{item.shoe}</td>
            <td>{item.carpet}</td>
            <td>{item.curtain}</td>
            <td>{item.roll}</td>
          </tr>
        );
      });
    }

    let BagDCSIData = 0;
    let BagRestData = 0;
    let HangerData = 0;
    let BlanketData = 0;
    let ShoeData = 0;
    let CarpetData = 0;
    let CurtainData = 0;
    let RollData = 0;

    let Item = this.state.servicewisedata;
    if (this.state.isPDFDataLoaded) {
      for (let i = 0; i < Item.length; i++) {
        console.log("i->", Item[i]);
        if (Item[i].packingMaterialID == 1) {
          if (Item[i].serviceID == 4 || Item[i].serviceID == 6) {
            BagDCSIData = BagDCSIData + Item[i].totalCounts;
          } else {
            BagRestData = BagRestData + Item[i].totalCounts;
          }
        } else if (Item[i].packingMaterialID == 2) {
          HangerData = HangerData + Item[i].totalCounts;
        } else if (Item[i].packingMaterialID == 3) {
          CurtainData = CurtainData + Item[i].totalCounts;
        } else if (Item[i].packingMaterialID == 4) {
          CarpetData = CarpetData + Item[i].totalCounts;
        } else if (Item[i].packingMaterialID == 5) {
          ShoeData = ShoeData + Item[i].totalCounts;
        } else if (Item[i].packingMaterialID == 6) {
          BlanketData = BlanketData + Item[i].totalCounts;
        } else if (Item[i].packingMaterialID == 7) {
          RollData = RollData + Item[i].totalCounts;
        }
      }
    }

    return (
      <div>
        <PageHeader
          title={labels.FACTORY_TRANSFER_OUT}
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              key="1"
              style={{ minWidth: 150 }}
              disabled={
                this.state.selectedStore !== ""
                  ? // && this.state.storeOutStickerData.length !== 0
                    false
                  : this.state.enableSearchBox
              }
              onChange={(event) => {
                this.setState({
                  search: event.target.value.toString().toLowerCase(),
                });
                this.getStickersToTransferOut(
                  this.state.pagination.current,
                  this.state.pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  event.target.value.toString().toLowerCase()
                );
              }}
            />,
            <Select
              style={{ minWidth: 150 }}
              showSearch
              placeholder={labels.SELECT_STORE}
              allowClear
              onChange={(event) => {
                this.setState(
                  {
                    selectedStore: event,
                  },
                  () =>
                    ![null, undefined, ""].includes(event)
                      ? this.getStickersToTransferOut(
                          this.state.pagination.current,
                          this.state.pagination.pageSize,
                          this.state.sorter.field,
                          this.state.sorter.order,
                          this.state.search
                        )
                      : this.setState({
                          storeOutStickerData: [],
                          selectedStore: "",
                        })
                );
              }}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.storeListOptions.length !== 0
                ? this.state.storeListOptions.map((store) => {
                    return store.value !== "-1" ? (
                      <Option value={store.value} key={store.value}>
                        {store.text}
                      </Option>
                    ) : null;
                  })
                : null}
            </Select>,
            <Button
              htmlType="submit"
              shape="rounded"
              disabled={
                this.state.selectedRowKeys.length > 0
                  ? false
                  : this.state.disableGatePassbtn
              }
              onClick={this.generateGatePass}
              loading={this.state.generateBtnLoading || this.state.tableLoading}
            >
              {labels.GENERATE_GATE_PASS}
            </Button>,
          ]}
        />

        <Table
          // className="components-table-demo-nested"
          size="small"
          columns={this.columns}
          dataSource={listItems}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          rowSelection={{
            type: this.selectionType,
            selectedRowKeys: this.state.selectedRowKeys,
            ...this.rowSelection,
          }}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getStickersToTransferOut(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />

        {this.state.isPDFDataLoaded ? (
          <div style={{ display: "none" }}>
            <div id="page" ref={this.element} style={{ padding: "10px" }}>
              <table
                align="center"
                style={{
                  width: "100%",
                }}
              >
                <tr
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    {labels.FACTORY_GATE_PASS_NUMBER}
                    {this.state.printInfoHeaderData.gatePassNumber}
                  </td>
                </tr>
                <tr
                  style={{ fontSize: 12, fontWeight: "bold" }}
                  // key={index}
                >
                  <td>
                    <img src={Logo} alt="Laundrokart" className="img-fluid" />
                  </td>

                  <td style={{ textAlign: "right" }}>
                    {labels.DATE}:
                    {moment(this.state.printInfoHeaderData.gatePassDate).format(
                      "DD/MM/YYYY"
                    )}
                    <br></br>
                    {labels.STORE_NAME}:
                    {this.state.printInfoHeaderData.storeName}
                    <br></br>
                    {labels.STORE_ADDRESS} :
                    {this.state.printInfoHeaderData.storeAddress}
                    <br></br>
                    {labels.FACTORY_NAME}:
                    {this.state.printInfoHeaderData.factoryName}
                    <br></br>
                    {labels.CREATED_BY}:{" "}
                    {this.state.printInfoHeaderData.userName}
                  </td>
                </tr>
              </table>
              <table
                border="1"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
                cellPadding={2}
              >
                <thead>
                  <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                    <th>{labels.S_NO}</th>
                    <th>{labels.SPLIT_ORDER_ID}</th>
                    <th>{labels.DELIVERY_TYPE}</th>
                    <th colspan="4">{labels.NUMBER_OF_GARMENTS}</th>
                    <th colspan="7">{labels.PACKED}</th>
                  </tr>
                  <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>{labels.ORDER_LOT_SIZE}</th>
                    <th>{labels.PREVIOUSLY_SENT}</th>
                    <th>{labels.SENDING_TODAY}</th>
                    <th>{labels.PENDING}</th>
                    <th>{labels.BAG}</th>
                    <th>{labels.HANGER}</th>
                    <th>{labels.BLANKET}</th>
                    <th>{labels.SHOE}</th>
                    <th>{labels.CARPET}</th>
                    <th>{labels.CURTAIN}</th>
                    <th>{labels.ROLL}</th>
                  </tr>
                </thead>
                <tbody>{data}</tbody>
                <tr style={{ fontSize: 12 }}>
                  <td colspan="7">Total</td>
                  <td>{this.state.totalMaterial.bag}</td>
                  <td>{this.state.totalMaterial.hanger}</td>
                  <td>{this.state.totalMaterial.blanket}</td>
                  <td>{this.state.totalMaterial.shoe}</td>
                  <td>{this.state.totalMaterial.carpet}</td>
                  <td>{this.state.totalMaterial.curtain}</td>
                  <td>{this.state.totalMaterial.roll}</td>
                </tr>
              </table>
              <br></br>

              <table
                border="1"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
                cellPadding={2}
              >
                <thead>
                  <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                    <th>Summary</th>
                    <th>Bag - DC/SI</th>
                    <th>Bag - Rest</th>
                    <th>Hanger</th>
                    <th>Blanket</th>
                    <th>Shoe</th>
                    <th>Carpet</th>
                    <th>Curtain</th>
                    <th>Roll</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ fontSize: 12 }}>
                    <td style={{ fontWeight: "bold" }}>Count</td>
                    <td>{BagDCSIData}</td>
                    <td>{BagRestData}</td>
                    <td>{HangerData}</td>
                    <td>{BlanketData}</td>
                    <td>{ShoeData}</td>
                    <td>{CarpetData}</td>
                    <td>{CurtainData}</td>
                    <td>{RollData}</td>
                  </tr>
                  <tr style={{ fontSize: 12 }}>
                    <td style={{ fontWeight: "bold" }}>Check</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <br></br>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
