import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class pendingcashsummaryservice {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async retrieveItems(param) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_PENDING_CASH_SUMMARY });
    let itemArray = [];
    await myApi.endpoints.GetPendingCashSummary.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getPendingCashSummaryfromStoreID(param) {
    // console.log("params123->",param);
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_PENDING_CASH_SUMMARY_DETAIL });
    let itemArray = [];
    await myApi.endpoints.GetPendingCashSummaryDetail.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getPendingCashSummaryfromStoreID(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getOrdersummarydata(param) {
    // console.log("param->",param);
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_ORDER_SUMMARY_REPORT });
    let itemArray = [];
    await myApi.endpoints.OrderSummaryReport.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getDailyOrderSummary(param) {
    // console.log("param->",param);
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.DAILY_ORDER_SUMMARY });
    let itemArray = [];
    await myApi.endpoints.DailyOrderSummary.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getDailyOrderSummary(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getDailyPickupSummary(param) {
    // console.log("param->",param);
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.DAILY_PICKUP_SUMMARY });
    let itemArray = [];
    await myApi.endpoints.DailyPickupSummary.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getDailyPickupSummary(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getMonthlyPickupSummary(param) {
    // console.log("param->",param);
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.MONTHLY_PICKUP_SUMMARY });
    let itemArray = [];
    await myApi.endpoints.MonthlyPickupSummary.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getMonthlyPickupSummary(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getMonthlyOrderSummary(param) {
    // console.log("param->",param);
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.MONTHLY_ORDER_SUMMARY });
    let itemArray = [];
    await myApi.endpoints.MonthlyOrderSummary.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getMonthlyOrderSummary(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}
