import React from "react";
import styles from "./logincomponent.module.css";
import LogoImg from "../../images/Common/Picture1logo.png";
// import LogoImg from "../../images/Common/Logo.png";
import { Form, Input, notification, Select } from "antd";
import Button from "../Common/Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import AuthService from "../../services/authservice";
import PickupService from "../../services/pickupservice";
import ROUTES from "../../routes";
import { labels } from "../../shared/language";
var jwt = require("jsonwebtoken");

const { Option } = Select;
/**
 * This file contains the Login Form that is displayed on right in Login Screen
 *
 * @author Niraj Gohel
 * @since 1.0.0
 * @module Login
 *
 * @created 29 May 2020
 *
 */

const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.authservice = new AuthService();
    this.pickupService = new PickupService();
    this.state = {
      userName: "",
      password: "",
      isShow: false,
      branchTypes: [],
      isNextVisible: true,
      isSelectVisible: false,
      isLoginVisible: false,
      selectedBranch: null,
      selectedBranchName: "",
      selectedBranchType: "",
      validation: 0,
      oldUserName: "",
      oldPassword: "",
      isValidate: false,
      validateStatus: null,
      validationMessage: null,
      isForgotShow: false,
      isPasswordUpdateShow: false,
      isOtpShow: false,
      isLoginShow: false,
      userId: 0,
      InputOtp: 0,
      Otp: 0,
      mobileNo: "",
      usernameValidateStatus: null,
      passwordValidateStatus: null,
      mobileNoValidateStatus: null,
      nextBtnLoading: false,
      isHideForgotLink: false,
      timeSlot: {
        data: [],
      },
    };
  }

  componentWillMount() {
    this.setState({
      isForgotShow: false,
      isLoginShow: true,
      isPasswordUpdateShow: false,
      isOtpShow: false,
      isHideForgotLink: false,
    });
  }

  componentDidMount() {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }

  handlePressEnterUserName = (e) => {
    this.setState({ validateStatus: null, userName: e.target.value });
    if ([null, undefined, ""].includes(e.target.value)) {
      this.setState({ usernameValidateStatus: "error" });
    } else {
      this.setState({ usernameValidateStatus: null });
    }
  };

  handlePressEnterPassword = (e) => {
    this.setState({ validateStatus: null, password: e.target.value });
    if ([null, undefined, ""].includes(e.target.value)) {
      this.setState({ passwordValidateStatus: "error" });
    } else {
      this.setState({ passwordValidateStatus: null });
    }
  };

  handlePressMobile = (e) => {
    this.setState({ mobileNoValidateStatus: null, mobileNo: e.target.value });
  };

  handleSelect = (event1) => {
    let event = parseInt(event1.slice(1));
    let type = event1.charAt(0);
    var branchArray = this.state.branchTypes.filter(
      (x) =>
        x.branchID === event &&
        ((x.branchType === "Store" && type === "s") ||
          (x.branchType === "Factory" && type === "f"))
    );

    if (branchArray !== undefined) {
      this.setState({
        selectedBranch: branchArray[0].branchID,
        selectedBranchName: branchArray[0].branchName,
        selectedBranchType: branchArray[0].branchType,
        validation: event,
      });
    }
  };

  getTimeSlot = (storeid) => {
    this.pickupService.getTimeSlotList(storeid).then((response) => {
      if (response.statusCode === 200) {
        this.setState(
          {
            timeSlot: {
              ...this.state.timeSlot,
              data: response.data,
            },
          },
          () => {
            localStorage.setItem(
              "StoreSlots",
              JSON.stringify(this.state.timeSlot.data)
            );
            // this.props.history.push(ROUTES.STORE_DASHBOARD);
            this.props.history.push(ROUTES.SPLASH);
          }
        );
      }
    });
  };

  handleClick = () => {
    if (
      this.state.userName == this.state.oldUserName &&
      this.state.password == this.state.oldPassword
    ) {
      if (this.state.validation == "") {
        // notification["error"]({
        //   message: "Select Store/Factory.",
        // });
        return false;
      }
      localStorage.setItem("branchID", this.state.selectedBranch);
      localStorage.setItem("Store/FactoryName", this.state.selectedBranchName);
      localStorage.setItem("Store/FactoryType", this.state.selectedBranchType);
      if (this.state.selectedBranchType.toLowerCase() === "store") {
        this.getTimeSlot(this.state.selectedBranch);
      } else if (this.state.selectedBranchType.toLowerCase() === "factory") {
        localStorage.removeItem("StoreSlots");
        // this.props.history.push(ROUTES.FACTORY_DASHBOARD);
        this.props.history.push(ROUTES.SPLASH);
      }
    } else {
      this.setState({ validateStatus: "error" }, () =>
        console.log(this.state.validateStatus)
      );
      openNotificationWithIcon("error", "Wrong credentials", "");
      this.setState({ validateStatus: "error" });
      // this.setState({ usernameValidateStatus: "error" });
      // this.setState({ passwordValidateStatus: "error" });
    }
  };

  getAssignStoreFactory = () => {
    const params = {
      username: this.state.userName,
      password: this.state.password,
    };
    this.authservice.getAssignStoreFactoryByUserId(params).then((response) => {
      if (response.status === 200) {
        this.setState({
          branchTypes: response.data.data,
        });
      } else {
        notification["error"]({
          message: "Login",
          description: "Unable to get data for Store/Factory",
        });
      }
    });
  };

  RemoteRequest = () => {
    if (
      ![undefined, null, ""].includes(this.state.userName) &&
      ![undefined, null, ""].includes(this.state.password)
    ) {
      this.setState({ nextBtnLoading: true });
      this.authservice
        .MakeRemoteAuthRequest(this.state.userName, this.state.password)
        .then((items) => {
          if (items != undefined && items.data.result == "Success") {
            var userId = jwt.decode(
              JSON.parse(localStorage.getItem("currentuser")).token
            ).unique_name;
            this.authservice.GetScreenRightsByUser(userId).then((rights) => {
            //  console.log("rights->",rights);
              localStorage.setItem("userrights", JSON.stringify(rights.data.vmUserScreenRights)); 
              localStorage.setItem("buttonAccess", JSON.stringify(rights.data.vmUserAccessRes));
              this.setState({ isShow: false });
              if (items.data.result === "Success") {
                this.setState({ isSelectVisible: true, isNextVisible: false });
                this.getAssignStoreFactory();
              }
            });
            this.setState({
              isShow: true,
              oldUserName: this.state.userName,
              oldPassword: this.state.password,
              isValidate: true,
              isHideForgotLink: true,

              usernameValidateStatus: null,
              passwordValidateStatus: null,
            });
            localStorage.setItem("roleNames", items.data.data.roleNames);
          } else {
            if (items == undefined) {
              notification["error"]({
                message: "Login",
                description: "Please contact technical team.",
              });
              return false;
            }
            if (this.state.userName !== "" && this.state.password !== "") {
              notification["error"]({
                message: "Login",
                description: "Wrong credentials",
              });
              this.setState({ validateStatus: "error" });
              this.setState({ usernameValidateStatus: "error" });
              this.setState({ passwordValidateStatus: "error" });
            }
          }
          this.setState({ nextBtnLoading: false });
        });
    } else {
      this.setState({
        usernameValidateStatus: "error",
        passwordValidateStatus: "error",
      });
    }
    // this.props.history.push(ROUTES.DASHBOARD);
  };

  handleBack = (e) => {
    // if (this.state.validateStatus === "") {
    this.setState({
      isSelectVisible: false,
      isValidate: false,
      isNextVisible: true,
      branchTypes: [],
      isHideForgotLink: false,
    });
    // }
  };

  handleChange = (e) => {
    this.setState({ validation: e });
  };
  handleOTPBack = () => {
    this.setState({ isOtpShow: false, isForgotShow: true });
  };
  ForgotPassword = () => {
    this.setState({
      isForgotShow: true,
      isLoginShow: false,
      isNextVisible: true,
      userName: undefined,
      password: undefined,
    });
  };
  formRef = React.createRef();
  render() {
    const onFinish = (values) => {
      // console.log("Received values of form: ", values);
    };

    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 24 },
    };

    return (
      <div className={styles.LoginFormWrapper}>
        <div className={styles.WrapLogo}>
          <img className="img-fluid" src={LogoImg} alt="Laundrokart Logo" />
        </div>
        <div className={styles.BackButton}>
          {this.state.isNextVisible ? (
            this.state.isOtpShow ? (
              <Button
                variant="link"
                type="link"
                block
                onClick={this.handleOTPBack}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
            ) : null
          ) : (
            <Button
              variant="link"
              type="link"
              block
              onClick={this.handleBack}
              style={{ color: "#352a54" }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          )}
        </div>
        {this.state.isLoginShow == true ? (
          <Form
            name="login"
            className={styles.LoginFromEl}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            ref={this.formRef}
          >
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Please Enter The Username!" },
              ]}
              validateStatus={this.state.usernameValidateStatus}
            >
              <Input
                // prefix={
                //   <FontAwesomeIcon
                //     icon={faUser}
                //     className="site-form-item-icon"
                //   />
                // }
                value={this.state.userName}
                // placeholder="Username"
                onChange={this.handlePressEnterUserName}
                disabled={this.state.isValidate}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please Enter The Password!" },
              ]}
              validateStatus={this.state.passwordValidateStatus}
            >
              <Input
                // prefix={
                //   <FontAwesomeIcon
                //     icon={faLock}
                //     className="site-form-item-icon"
                //   />
                // }
                disabled={this.state.isValidate}
                value={this.state.password}
                type="password"
                // placeholder="Password"
                onChange={this.handlePressEnterPassword}
              />
            </Form.Item>
            {this.state.isSelectVisible ? (
              <Form.Item
                name="branchTypes"
                rules={[
                  { required: true, message: "Please Select Store/Factory" },
                ]}
              >
                <Select
                  showSearch
                  onChange={this.handleSelect}
                  value={this.state.selectedBranch}
                  placeholder="Select Store/Factory"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.branchTypes.length !== 0
                    ? this.state.branchTypes.map((branchType) => {
                      return branchType.branchID !== "-1" ? (
                        <Option
                          value={
                            branchType.branchType === "Store"
                              ? "s" + branchType.branchID
                              : "f" + branchType.branchID
                          }
                          key={
                            branchType.branchType === "Store"
                              ? "s" + branchType.branchID
                              : "f" + branchType.branchID
                          }
                        >
                          {branchType.branchName}
                        </Option>
                      ) : null;
                    })
                    : null}
                </Select>
              </Form.Item>
            ) : null}
            <Form.Item>
              {this.state.isNextVisible ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  className={styles.LoginButton}
                  shape="round"
                  variant="darkpurple"
                  block
                  onClick={this.RemoteRequest}
                  loading={this.state.nextBtnLoading}
                  disabled={this.state.validateStatus !== null}
                >
                  Next
                </Button>
              ) : (
                <>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles.LoginButton}
                    shape="round"
                    variant="darkpurple"
                    block
                    onClick={this.handleClick}
                  >
                    Log in
                  </Button>
                </>
              )}
            </Form.Item>
            <Form.Item>
              {/* {this.state.isShow === true ? (
                <span>Credentials are invalid</span>
              ) : null} */}
              {!this.state.isHideForgotLink ? (
                <div className={styles.ForgetPassswordLink}>
                  <Button
                    style={{ color: "#352a54", fontWeight: 600 }}
                    type="link"
                    variant="link"
                    onClick={this.ForgotPassword}
                  >
                    forgot password ?
                  </Button>
                </div>
              ) : null}
            </Form.Item>
          </Form>
        ) : null}

        {this.state.isForgotShow == true ? (
          <Form
            name="forgotpassword"
            className={styles.LoginFromEl}
            initialValues={{ remember: true }}
            onFinish={this.SendOtp}
          >
            <Form.Item
              name="mobileno"
              rules={[
                { required: true, message: labels.PLEASE_ENTER_PHONE_NUMBER },
                {
                  pattern: "[6789][0-9]{9}",
                  message: labels.PLEASE_ENTER_VALID_PHONE_NUMBER,
                },
              ]}

            // validateStatus={this.state.mobileNoValidateStatus}
            >
              <Input
                prefix={
                  <FontAwesomeIcon
                    icon={faUser}
                    className="site-form-item-icon"
                  />
                }
                maxLength={10}
                placeholder="Mobile No"
                onChange={this.handlePressMobile}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.LoginButton}
                shape="round"
                variant="darkpurple"
                block
                loading={this.state.nextBtnLoading}
              // onClick={this.handleClick}
              >
                Send OTP
              </Button>
            </Form.Item>
          </Form>
        ) : null}
        {this.state.isOtpShow == true ? (
          <Form
            name="otpverify"
            className={styles.LoginFromEl}
            initialValues={{ remember: true }}
            onFinish={this.OtpVerify}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please enter OTP" }]}
            // validateStatus={this.state.validateStatus}
            >
              <Input
                prefix={
                  <FontAwesomeIcon
                    icon={faUser}
                    className="site-form-item-icon"
                  />
                }
                placeholder="OTP"
                onChange={this.handlePressEnterOtp}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.LoginButton}
                shape="round"
                variant="darkpurple"
                block
              >
                Verify OTP
              </Button>
            </Form.Item>
          </Form>
        ) : null}

        {this.state.isPasswordUpdateShow == true ? (
          <Form
            name="submitPass"
            className={styles.LoginFromEl}
            initialValues={{ remember: true }}
            onFinish={this.SubmitPassword}
            {...layout}
          >
            <Form.Item
              name="password"
              validateFirst
              // label="Password"
              rules={[
                {
                  required: true,
                  message: "Please enter new password",
                },
                {
                  required: true,

                  pattern:
                    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-]).{8,}$",
                  message:
                    "Mininum password length should be 8 including minimum 1 upper case,1 lower case,1 numeric,1 special character except *.",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                onChange={this.handleNewPassword}
                placeholder="New Password"
              />
            </Form.Item>

            <Form.Item
              name="confirm"
              // label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              validateFirst
              rules={[
                {
                  required: true,
                  message: "Please confirm password",
                },
                {
                  required: true,
                  pattern:
                    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-]).{8,}$",
                  message:
                    "Mininum password length should be 8 including minimum 1 upper case,1 lower case,1 numeric,1 special character except *.",
                },

                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "New password & confirm password do not match"
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.LoginButton}
              shape="round"
              variant="darkpurple"
              block
            //  onClick={this.handleClick}
            >
              Update Password
            </Button>
          </Form>
        ) : null}
        {this.state.isForgotShow === true ||
          this.state.OtpVerify == true ||
          this.state.isPasswordUpdateShow == true ? (
          <Form.Item>
            <div className={styles.ForgetPassswordLink}>
              <Button
                type="link"
                variant="link"
                onClick={this.CallLogin}
                style={{ color: "#352a54", fontWeight: 600 }}
              >
                Login ?
              </Button>
            </div>
          </Form.Item>
        ) : null}
      </div>
    );
  }

  CallLogin = () => {
    this.setState({
      isForgotShow: false,
      isPasswordUpdateShow: false,
      isOtpShow: false,
      isLoginShow: true,
      isNextVisible: true,
      isSelectVisible: false,
      isValidate: false,
      isHideForgotLink: false,
    });
  };

  SendOtp = () => {
    this.setState({ nextBtnLoading: true });
    this.authservice.SendOTP(this.state.mobileNo).then((response) => {
      if (response.result == "Success") {
        this.setState({
          Otp: response.data.otp,
          userId: response.data.userId,
          isForgotShow: false,
          isOtpShow: true,
        });
      } else {
        notification["error"]({
          message: "Login",
          description: response.message,
        });
        this.setState({ mobileNoValidateStatus: "error" });
      }
      this.setState({ nextBtnLoading: false });
    });
  };
  OtpVerify = () => {
    if (this.state.Otp == this.state.InputOtp) {
      this.setState({
        isForgotShow: false,
        isOtpShow: false,
        isPasswordUpdateShow: true,
      });
    } else {
      notification["error"]({
        message: "Login",
        description: "Incorrect OTP",
      });
      return false;
    }
  };

  handlePressEnterOtp = (e) => {
    this.setState({ InputOtp: e.target.value });
  };

  handleNewPassword = (e) => {
    this.setState({
      newPassword: e.target.value,
    });
  };

  SubmitPassword = () => {
    this.authservice
      .SubmitPassword(this.state.userId, this.state.newPassword)
      .then((response) => {
        if ((response.data.result = "Success")) {
          notification["success"]({
            message: "Login",
            description: "Password has been changed.",
          });

          this.setState({
            isForgotShow: false,
            isOtpShow: false,
            isPasswordUpdateShow: false,
            isLoginShow: true,
          });
        } else {
          notification["error"]({
            message: "Login",
            description: "Something went wrong. Please contact technical team",
          });
        }
      });
  };
}
export default withRouter(Login);
