import React from "react";
import styles from "./loginscreen.module.css";
import { Row, Col, Typography } from "antd";
import LoginHero from "../../images/Login/LoginHero.png";
import LoginForm from "../../components/Login/login.component";
const { Title } = Typography;

/**
 * This file contains the view of Login Screen
 *
 * @author Niraj Gohel
 * @since 1.0.0
 * @module Login
 *
 * @created 29 May 2020
 *
 */

export default class Login extends React.Component {
  /**
   * The left-hand side title and image are stored in state variable
   * which then will be used to render the component
   *
   * the reason for doing this is you can fetch the data from some api and make dynamic if needed.
   *
   */
  state = {
    title: "Safety, Hygiene and Fresh Laundry is our Priority",
    heroImage: LoginHero,
    heroImageAlt: "An image showing laundry process",
  };

  render() {
    return (
      <div className={styles.LoginContainer}>
        <Row align="middle" className={styles.LoginWrapper}>
          <Col xs={0} sm={0} lg={12}>
            <Title level={1} className={styles.loginHeader}>
              {this.state.title}
            </Title>
            <img
              src={this.state.heroImage}
              className="img-fluid"
              alt={this.state.heroImageAlt}
            />
          </Col>
          <Col xs={24} sm={24} lg={12}>
            <div className={styles.LoginFormContainer}>
              <LoginForm {...this.props}/>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
