import React from "react";
import { Table, PageHeader, notification } from "antd";
import styles from "../../../../Common/Styles/master.module.css";
import { withRouter } from "react-router-dom";
import PickupService from "../../../../../services/pickupservice";
import Moment from "moment";
import { labels } from "../../../../../shared/language";
import Common from "../../../../../services/common";
/**
 * Reschedule history Form as a part of Pickup Creation
 *
 * @author Payal Bharakhda
 * @created 13 July 2020
 */
class RescheduleHistory extends React.Component {
  pickupService = new PickupService();
  common = new Common();
  /**
   * State Variables
   *
   */
  state = {
    historyData: [],
  };

  columns = [
    {
      title: labels.PICKUP_DATE,
      dataIndex: "pickupDate",
      key: "pickupDate",
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.PICKUP_TIMESLOT,
      dataIndex: "pickupSlot",
      key: "pickupSlot",
    },
    {
      title: labels.DELIVERY_DATE,
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      // render: (text) => {
      //   return Moment(text).format("DD-MM-YYYY");
      // },
    },
    {
      title: labels.DELIVERY_TIMESLOT,
      dataIndex: "deliverySlot",
      key: "deliverySlot",
    },
  ];

  componentDidMount() {
    var pickupID = this.props.match.params.pickupID;
    this.requestHistory(pickupID);
  }

  requestHistory(pickupID) {
    if (pickupID !== undefined) {
      this.pickupService
        .getPickupRequestHistory(parseInt(pickupID))
        .then((response) => {
          if (response.statusCode === 200) {
            this.setState({
              historyData: response.data,
            });
          } else {
            notification["error"]({
              message: labels.PICKUPS,
              description: response.message
                ? this.common.Message(response.message)
                : labels.UNABLE_TO_GET_DATA_FOR_HISTORY,
            });
          }
        });
    }
  }

  render() {
    // const disableAll = this.props.mode === "view";
    const historyData = this.state.historyData;
    const listItems = historyData.map((item) => ({
      pickupDate: item.pickupDate,
      // Moment(item.pickupDate).format("DD/MM/YYYY"),
      pickupSlot: ![null, undefined, ""].includes(item.pickupSlot)
        ? item.pickupSlot
        : null,
      //item.deliveryDate,
      deliveryDate: ![null, undefined, ""].includes(item.deliveryDate)
        ? Moment(item.deliveryDate).format("DD-MM-YYYY")
        : null,
      deliverySlot: ![null, undefined, ""].includes(item.deliverySlot)
        ? item.deliverySlot
        : null,
    }));
    return (
      <div
        key={this.props.values.state}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <PageHeader
          className={styles.SubHeader}
          title={labels.RESCHEDULED_PICKUP_HISTORY}
        />
        <Table
          size="small"
          columns={this.columns}
          dataSource={listItems}
          loading={this.state.tableLoading}
          pagination={false}
        />
      </div>
    );
  }
}

export default withRouter(RescheduleHistory);
