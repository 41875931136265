import React from 'react';
import FactoryInPending from "../../../components/FactoryModule/FactoryInPending/factoryinpending.factory.component"
export default class Factoryinpendingscreen extends  React.Component {
    render() {
        return (
            <div>
                <FactoryInPending></FactoryInPending>
            </div>
        )
    }
}
