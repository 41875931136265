import React from "react";
import {
  Table,
  Space,
  Popconfirm,
  PageHeader,
  Typography,
  notification,
} from "antd";
import Button from "../../../Common/Button/button";
import styles from "./createuser.module.css";
import UserService from "../../../../services/userservice";
import RoleAssignment from "./roleassignment.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};
/**
 * A tabular view as a part of create Role - Role Mapping, Role List
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 2 June 2020
 *
 * @module Role
 */
export default class UserRole extends React.Component {
  /**
   * State variable to determine whether to display form or table
   */
  constructor(props) {
    super(props);
    this.userservice = new UserService();
    this.state = {
      assignedList: [],
      isAdd: false,
      isEdit: false,
      isView: false,
      disabled: false,
      userrolemappingID: 0,
      roleID: 0,
      roleList: [],
      roleName: "",
      roleAssignmentFormValues: {},
    };
  }

  componentDidMount() {
    this.getUserRoleDetails(this.props.userID);
  }

  getUserRoleDetails = (userID) => {
    this.setState({ tableLoading: true });
    this.userservice.getUserRoleDetails(userID).then((response) => {
      let assignedList = response.data.map((x) => parseInt(x.roleID));
      this.setState({ roleList: response.data, assignedList: assignedList });
      this.setState({ tableLoading: false });
    });
  };

  deleteRoleByRoleID(userrolemappingID) {
    this.userservice.deleteRoleByRoleID(userrolemappingID).then((response) => {
      if (response.result === "Success") {
        openNotificationWithIcon("success", "Users", response.message);
      } else {
        openNotificationWithIcon("error", "Users", response.message);
      }
      this.getUserRoleDetails(this.props.userID);
    });
  }

  getUserRoleByID(userrolemappingID) {
    this.userservice.getUserRoleByID(userrolemappingID).then((response) => {
      this.setState({
        isEdit: true,
        userrolemappingID: userrolemappingID,
        roleID: response.data.roleID,
        roleName: response.data.roleName,
        roleAssignmentFormValues: {
          roleID: response.data.roleID.toString(),
        },
      });
    });
  }

  getViewUserRoleByID(userrolemappingID) {
    this.userservice.getUserRoleByID(userrolemappingID).then((response) => {
      this.setState({
        isView: true,
        disabled: true,
        userrolemappingID: userrolemappingID,
        roleID: response.data.roleID,
        roleName: response.data.roleName,
        roleAssignmentFormValues: {
          roleID: response.data.roleID.toString(),
        },
      });
    });
  }

  columns = [
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleNamr",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={(event) =>
              this.getViewUserRoleByID(record.userRoleMappingID)
            }
          >
            <FontAwesomeIcon icon={faInfo} />
          </Button>
          {!this.props.isViewmode ? (
            <Button
              type="link"
              onClick={(event) =>
                this.getUserRoleByID(record.userRoleMappingID)
              }
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          ) : null}
          {!this.props.isViewmode ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={(event) =>
                this.deleteRoleByRoleID(record.userRoleMappingID)
              }
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  /**
   * Data to be displayed in table
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   *
   */
  /**
   * A function to modify state varibles from child
   */
  bringback = () => {
    this.setState({
      isAdd: false,
      isEdit: false,
      isView: false,
      disabled: false,
      userrolemappingID: 0,
      roleAssignmentFormValues: null,
    });
  };
  render() {
    return this.state.isAdd || this.state.isEdit || this.state.isView ? (
      <RoleAssignment
        backFunction={this.bringback}
        roleList={this.props.data}
        userID={this.props.userID}
        userrolemappingID={this.state.userrolemappingID}
        roleID={this.state.roleID}
        roleName={this.state.roleName}
        isView={this.state.isView}
        disabled={this.state.disabled}
        isViewmode={this.props.isViewmode}
        values={this.state.roleAssignmentFormValues}
        assignedList={this.state.assignedList}
      />
    ) : (
      <React.Fragment>
        {/**
         * Page Header Component to display title and button
         * https://ant.design/components/page-header/
         * https://ant.design/components/page-header/#API
         */}
        <PageHeader
          title={
            <Title level={4} className={styles.UserRoleTitle}>
              User Role
            </Title>
          }
          extra={[
            !this.props.isViewmode ? (
              <Button
                variant="purple"
                shape="round"
                size="large"
                onClick={() => {
                  this.setState({ isAdd: true });
                }}
              >
                Add Role
              </Button>
            ) : null,
          ]}
          className={styles.UserRoleHeader}
        />
        {/**
         * Please go through below documention for <Table> Component
         * https://ant.design/components/table/
         * https://ant.design/components/table/#API
         *
         */}
        <Table
          size="small"
          bordered
          columns={this.columns}
          dataSource={this.state.roleList}
          pagination={false}
          loading={this.state.tableLoading}
        />
      </React.Fragment>
    );
  }
}
