import React from "react";
import StoreService from "../../../../services/storeservice";
import { Row, Col, Form, Input, Select, notification, Checkbox } from "antd";
import styles from "./createstore.module.css";
import Button from "../../../Common/Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  RegexDecimalAndRestrictFirstZero,
  RegexInteger,
  MessageNumbers,
  RegexLetters,
  MessageLetters,
} from "../../../../shared/validator";

const { Option } = Select;
let map;
// let bounds = new window.google.maps.LatLngBounds();
let coordinates = [];
var drawingManager;
var selectedShape;
var allPolygonNew = [];
var allPolygonOld = [];
var newShape = "";
var selectedCoordinates = "";
var geoCoordinate = "";
/**
 * A form as a part of create user - General Section/Step
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 2 June 2020
 *
 * @module User
 */
export default class StoreContact extends React.Component {
  constructor(props) {
    super(props);
    this.storeservice = new StoreService();
    this.state = {
      isEditmode: false,
      isViewmode: false,
      zone: [],
      state: [],
      city: [],
      geoFencing: "",
    };
    this.renderCoordinate = this.renderCoordinate.bind(this);
    this.initMap = this.initMap.bind(this);
    this.deleteSelectedShape = this.deleteSelectedShape.bind(this);
  }
  componentDidMount() {
   
    this.initMap();
    this.setState({
      isEditmode: this.props.isEditmode,
      isViewmode: this.props.isViewmode,
    });
    this.getZone();
    this.getState();
    if (this.props.stateID !== 0) {
      this.getCity(this.props.stateID);
    }
  }

  initMap = () => {
    if (
      localStorage.getItem("geoFencing") !== "" &&
      localStorage.getItem("geoFencing") !== undefined &&
      localStorage.getItem("geoFencing") !== null
    ) {
      selectedCoordinates = localStorage.getItem("geoFencing");
    }
    if (this.props.mode === "add") {
      selectedCoordinates = "";
    }
    map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 12.9716, lng: 77.5946 },
      zoom: 10,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER,
      },
      scrollwheel: false,
      streetViewControl: false,
      mapTypeControl: false,
      mapTypeId: "roadmap",
    });
    var myPolygon = new window.google.maps.Polygon({
      //paths: this.buildCoordinates("20.385816,77.281992 19.694305,77.682993 20.576216,78.446543"),
      paths: this.buildCoordinates(selectedCoordinates),
      description: ["1", "0", "", ""],
      IsChanged: "0",
      draggable: true,
      editable: true,
      strokeColor: "#1E90FF",
      strokeOpacity: 0.8,
      strokeWeight: 0,
      fillColor: "#66ccff",
      fillOpacity: 0.45,
    });

    myPolygon.setMap(map);
    allPolygonOld.push(myPolygon);
    window.google.maps.event.addListener(myPolygon, "click", function () {
      selectedShape = myPolygon;
      window.google.maps.event.addListener(
        myPolygon.getPath(),
        "insert_at",
        this.mapEditing
      );
      window.google.maps.event.addListener(
        myPolygon.getPath(),
        "set_at",
        this.mapEditing
      );
    });

    drawingManager = new window.google.maps.drawing.DrawingManager({
      markerOptions: {
        draggable: true,
      },
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        strokeWeight: 0,
        fillOpacity: 0.45,
        editable: true,
        description: [],
        strokeColor: "#1E90FF",
        fillColor: "#1E90FF",
        IsChanged: "0",
        draggable: true,
      },

      map: map,
    });

    window.google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      function (e) {
        selectedCoordinates = "";
        var firstPointCoordinates = "";
        newShape = e.overlay;
        newShape.type = e.type;
        newShape.description = ["1", "0", "", ""];
        if (e.type !== window.google.maps.drawing.OverlayType.MARKER) {
          for (var i = 0; i < newShape.getPath().getLength(); i++) {
            selectedCoordinates +=
              newShape.getPath().getAt(i).toUrlValue(14) + " ";
            geoCoordinate = selectedCoordinates;
            if (i === 0) {
              firstPointCoordinates = selectedCoordinates;
            }
            if (i === newShape.getPath().getLength() - 1) {
              selectedCoordinates += firstPointCoordinates;
            }
          }
          localStorage.setItem("geoFencing", selectedCoordinates);
          selectedShape = newShape;
          allPolygonNew.push(newShape);
        }
      }
    );

    window.google.maps.event.addListener(
      drawingManager,
      "drawingmode_changed",
      this.clearSelection()
    );

    myPolygon.addListener("mouseup", function (event) {
      // debugger;
      const polygon = this;
      selectedCoordinates = "";
      var firstPointCoordinates = "";
      for (var i = 0; i < polygon.getPath().getLength(); i++) {
        selectedCoordinates += polygon.getPath().getAt(i).toUrlValue(14) + " ";
        geoCoordinate = selectedCoordinates;
        if (i === 0) {
          firstPointCoordinates = selectedCoordinates;
        }
        if (i === polygon.getPath().getLength() - 1) {
          selectedCoordinates += firstPointCoordinates;
        }
      }
      localStorage.setItem("geoFencing", selectedCoordinates);
    });

    window.google.maps.event.addListener(map, "click", this.clearSelection());
  };

  deleteSelectedShape = () => {
    var shape = selectedShape;
    if (shape !== undefined && shape.type !== "marker") {
      window.google.maps.event.clearListeners(
        selectedShape.getPath(),
        "insert_at"
      );
      window.google.maps.event.clearListeners(
        selectedShape.getPath(),
        "set_at"
      );
      selectedShape.setEditable(false);
      selectedShape = null;
      shape.setEditable(true);
    }
    selectedShape = shape;

    if (selectedShape) {
      if (selectedShape.description[0] !== "1") {
        for (var i = 0; i < allPolygonNew.length; i++) {
          if (
            allPolygonNew[i].description[1] === selectedShape.description[1]
          ) {
            allPolygonNew.splice(i, 1);
            selectedShape.setMap(null);
          }
        }
      } else {
        selectedShape.description[2] = "D";
        selectedShape.setMap(null);
      }
    }
    localStorage.setItem("geoFencing", "");
  };

  buildCoordinates(cordinates) {
    var finalData = [];
    var grouped = cordinates.split(" ");
    grouped.forEach(function (item, i) {
      let a = item.trim().split(",");
      finalData.push(new window.google.maps.LatLng(a[0], a[1]));
    });

    return finalData;
  }

  clearSelection() {
    if (selectedShape) {
      window.google.maps.event.clearListeners(
        selectedShape.getPath(),
        "insert_at"
      );
      window.google.maps.event.clearListeners(
        selectedShape.getPath(),
        "set_at"
      );
      selectedShape.setEditable(false);
      selectedShape = null;
    }
  }

  mapEditing() {
    if (selectedShape && selectedShape.IsChanged === "0") {
      selectedShape.IsChanged = "1";
    }
  }

  renderCoordinate(paths) {
    coordinates = [];
    let position = 0;
    paths.map((location) => {
      if (position % 10 === 0) {
        coordinates.push({ lat: location[1], lng: location[0] });
      }
      position++;
      return true;
    });
  }

  getZone = () => {
    this.storeservice.retrieveZone().then((zone) => {
      if (zone.statusCode === 200) {
        this.setState({ zone: zone.data });
      } else {
        notification["error"]({
          message: "Store Master",
          description: "Unable to get data for zone",
        });
      }
    });
  };

  getState = () => {
    this.storeservice.retrieveState().then((state) => {
      if (state.statusCode === 200) {
        this.setState({ state: state.data });
      } else {
        notification["error"]({
          message: "Store Master",
          description: "Unable to get data for state",
        });
      }
    });
  };

  getCity = (stateID) => {
    this.storeservice.retrieveCity(parseInt(stateID)).then((city) => {
      if (city.statusCode === 200) {
        this.setState({ city: city.data });
      } else {
        notification["error"]({
          message: "Store Master",
          description: "Unable to get data for city",
        });
      }
    });
  };

  // handleWebCheckbox = (event) => {
  //   if (event.target.checked == true) {
  //     this.setState({ showOnWebsite: "Yes" });
  //   } else {
  //     this.setState({ showOnWebsite: "No" });
  //   }
  //   // this.setState({ showOnWebsite: event.target.checked });
  //   console.log("event->", event);
  // };

  render() {
    const disabled = this.props.mode === "view";
    return (
      <Form
        layout="vertical"
        name="Contact"
        initialValues={this.props.values}
        ref={this.props.instance}
        onFinish={this.onFinishGeneralForm}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={24}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Please Enter Address" }]}
            >
              <Input.TextArea
                rows={2}
                placeholder="Enter Address"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24} md={12} xl={12}>
            <Form.Item label="Geo Co-ordinates" name="geoCoOrdinates">
              <Input placeholder="Enter Geo Co-ordinates" disabled={disabled}/>
            </Form.Item>
          </Col> */}
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Zone"
              name="zone"
              rules={[{ required: true, message: "Please Select Zone" }]}
            >
              <Select placeholder="Select Zone" disabled={disabled}>
                {/* {this.state.zone.map((zone) => <Select.Option key={zone.value} value={zone.value}>{zone.text}</Select.Option>)}   */}
                {this.state.zone.map((zone) => {
                  return zone.value !== "-1" ? (
                    <Option value={zone.value} key={zone.value}>
                      {zone.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  required: true,
                  pattern: "[6789][0-9]{9}",
                  message: "Please Enter valid Phone",
                },
              ]}
            >
              <Input placeholder="Enter Phone" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Store Executive"
              name="storeExecutive"
              rules={[
                {
                  pattern: RegexLetters,
                  message: MessageLetters,
                },
              ]}
            >
              <Input placeholder="Enter Store Executive" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Store Manager"
              name="storeManager"
              rules={[
                {
                  pattern: RegexLetters,
                  message: MessageLetters,
                },
              ]}
            >
              <Input placeholder="Enter Store Manager" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Area Manager"
              name="areaManager"
              rules={[
                {
                  pattern: RegexLetters,
                  message: MessageLetters,
                },
              ]}
            >
              <Input placeholder="Enter Area Manager" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="State"
              name="stateID"
              rules={[{ required: true, message: "Please Select State" }]}
            >
              <Select
                placeholder="Select State"
                onChange={(event) => {
                  this.getCity(event);
                }}
                disabled={disabled}
              >
                {/* {this.state.state.map((state) => <Select.Option key={state.value} value={state.value}>{state.text}</Select.Option>)}   */}
                {this.state.state.map((state) => {
                  return state.value !== "-1" ? (
                    <Option value={state.value} key={state.value}>
                      {state.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="City"
              name="cityID"
              dependencies={["state"]}
              shouldUpdate={(prev, next) => prev.state !== next.state}
              rules={[{ required: true, message: "Please Select City" }]}
            >
              <Select placeholder="Select City" disabled={disabled}>
                {/* { this.state.city!=null || this.state.city!='' ? 
                    this.state.city.map((city) => <Select.Option key={city.value} value={city.value}>{city.text}</Select.Option>)  
                : null } */}
                {this.state.city !== null || this.state.city !== ""
                  ? this.state.city.map((city) => {
                      return city.value !== "-1" ? (
                        <Option value={city.value} key={city.value}>
                          {city.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Latitude"
              name="latitude"
              rules={[
                {
                  pattern: RegexDecimalAndRestrictFirstZero,
                  message: MessageNumbers,
                },
              ]}
            >
              <Input placeholder="Enter Latitude" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Longitude"
              name="longitude"
              rules={[
                {
                  pattern: RegexDecimalAndRestrictFirstZero,
                  message: MessageNumbers,
                },
              ]}
            >
              <Input placeholder="Enter Longitude" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Pin Code"
              name="pinCode"
              rules={[
                {
                  pattern: RegexInteger,
                  message: MessageNumbers,
                },
              ]}
            >
              <Input
                placeholder="Enter Pin Code"
                disabled={disabled}
                maxLength={6}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label=" " name="showOnWebsite" valuePropName="checked">
              <Checkbox
                // onChange={this.handleWebCheckbox}
                disabled={disabled}
              >
                Show on Website
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label=" " name="showMarkerOnApp" valuePropName="checked">
              <Checkbox
                disabled={disabled}
              >
                Show marker on app
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={24}>
            Geo-CoOrdinates
            <Button
              type="link"
              id="delete-button"
              onClick={this.deleteSelectedShape}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            {/* <Form.Item label=" " name="geoFencing" getValueFromEvent= { e => e.target.value.toUpperCase().trim()}>
              
            </Form.Item > */}
            <div className={styles.maps} id="map" disabled={disabled}></div>
          </Col>
          <Col
            xs={24}
            className={styles.ButtonContainer}
            style={{ marginTop: 15 }}
          >
            <Button
              variant="transparentGreyOutline"
              className={styles.CancelButton}
              shape="round"
              size="large"
              onClick={this.props.backFunction}
            >
              Previuos
            </Button>
            {!disabled ? (
              <Button
                //htmlType="submit"
                variant="purple"
                shape="round"
                size="large"
                onClick={this.props.nextFunction}
              >
                Submit
              </Button>
            ) : null}
          </Col>
        </Row>
      </Form>
    );
  }
}
