import React from 'react';
import TermsAndConditions from '../../components/TermsAndConditions/termsAndConditions.component';

export default class termsAndConditions extends React.Component {
    render() {
        return (
            <div>
                <TermsAndConditions></TermsAndConditions>
            </div>
        )
    }
}
