import React from 'react';
import PickupdeliveryassignmentComponent from '../../../components/StoreModule/Audit/pickupdeliveryassignment.Component';

export default class pickupdeliveryassignmentscreen extends React.Component {
  render() {
    return (
      <div><PickupdeliveryassignmentComponent></PickupdeliveryassignmentComponent></div>
    )
  }
}
