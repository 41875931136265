import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class TransferInStore {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getStickerByStickerID(stickerID, storeID) {
    const garmentListAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    garmentListAPI.createEntity({
      name: this.endpoint.GET_STICKERINFO_BY_STICKER_NO,
    });
    let garments = [];
    let param = "?StickerID=" + stickerID + "&StoreID=" + storeID;
    await garmentListAPI.endpoints[this.endpoint.GET_STICKERINFO_BY_STICKER_NO]
      .deleteById(param)
      .then((response) => {
        garments = response.data;
      })
      .catch((err) => {
        garments = this.common.handleError(err);
      });
    if (garments.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        garments = this.getStickerByStickerID(stickerID, storeID);
      });
    }
    return Promise.resolve(garments);
  }

  async requestStoreTransferin(stickerID, storeID, userID, CustomerID) {
    const transferInAPI = new API({ url: this.config.ITEM_STORE_URL });
    transferInAPI.createEntity({ name: this.endpoint.TRANSFER_INTO_STORE });
    let garments = [];
    let param =
      "?StickerID=" +
      stickerID +
      "&StoreID=" +
      storeID +
      "&UserID=" +
      userID +
      "&CustomerID=" +
      CustomerID;
    await transferInAPI.endpoints[this.endpoint.TRANSFER_INTO_STORE]
      .deleteById(param)
      .then((response) => {
        garments = response.data;
      })
      .catch((err) => {
        garments = this.common.handleError(err);
      });
    if (garments.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        garments = this.requestStoreTransferin(stickerID, storeID, userID,CustomerID);
      });
    }
    return Promise.resolve(garments);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default TransferInStore;
