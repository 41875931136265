import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import moment from "moment";
import Common from "./common";
var jwt = require("jsonwebtoken");
export default class FactoryService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
    // this.handleError = (error) => {
    //   console.error(error);
    // };
  }

  async getOrderRequestStatusList() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({
      name: this.endpoint.GET_SYSTEME_NUMS,
    });
    let append = "?EnumNames=RequestStatus";
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_SYSTEME_NUMS]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getOrderRequestStatusList();
      });
    }
    return itemArray;
  }
  async getAddOnServicesList() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_ADDON_SERVICES });
    let states = [];
    await stateListAPI.endpoints[this.endpoint.GET_ADDON_SERVICES]
      .getAll()
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getAddOnServicesList();
      });
    }
    return Promise.resolve(states);
  }
  async getRewashReason() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_REWASH_REASON_LIST,
    });
   
    let states = [];
    await stateListAPI.endpoints[this.endpoint.GET_REWASH_REASON_LIST]
      .getAll()
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getRewashReason();
      });
    }
    return Promise.resolve(states);
  }
  async getRewashRequestedBy() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_SYSTEME_NUMS });
    let states = [];
    await stateListAPI.endpoints[this.endpoint.GET_SYSTEME_NUMS]
      .getById("?EnumNames=RewashRequestBy")
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getRewashRequestedBy();
      });
    }
    return Promise.resolve(states);
  }
  async getServiceTypeList() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_DELIVERY_TYPE });
    let serviceTypes = [];
    await stateListAPI.endpoints[this.endpoint.GET_DELIVERY_TYPE]
      .getAll()
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.getServiceTypeList();
      });
    }
    return Promise.resolve(serviceTypes);
  }

  async searchCustomer(value) {
    const stateListAPI = new API({ url: this.config.ITEM_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_SEARCH_CUSTOMER });
    let serviceTypes = [];
    let append = "?SearchString=" + value;
    await stateListAPI.endpoints[this.endpoint.GET_SEARCH_CUSTOMER]
      .getOne(append)
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.searchCustomer(value);
      });
    }
    return Promise.resolve(serviceTypes);
  }

  async getCustomerAddresses(id) {
    const stateListAPI = new API({ url: this.config.ITEM_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_CUSTOMER_ADDRESSES });
    let addresses = [];
    let append = "?CustomerId=" + id;
    await stateListAPI.endpoints[this.endpoint.GET_CUSTOMER_ADDRESSES]
      .getOne(append)
      .then((response) => {
        addresses = response.data;
      })
      .catch((err) => {
        addresses = this.common.handleError(err);
      });
    if (addresses.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        addresses = this.getCustomerAddresses(id);
      });
    }
    return Promise.resolve(addresses);
  }

  async getGarmentsList(service = null, uom = null,storeID) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_GARMENTS_WITH_SERVICES,
    });
    let returnVal = [];
    let params = {
      garmentID: null,
      serviceID: parseInt(service),
      uom: parseInt(uom),
      storeID:storeID
    };
    await stateListAPI.endpoints[this.endpoint.GET_GARMENTS_WITH_SERVICES]
      .getAllPost(params)
      .then((response) => {
        if (response.status === 200) {
          let garments = response.data;
          let services = [];

          garments.data.map((garment, index) => {
            if (
              services.filter(
                (x) =>
                  x.serviceID === garment.serviceID &&
                  x.serviceName === garment.serviceName
              ).length === 0
            ) {
              services.push({
                serviceID: garment.serviceID,
                serviceName: garment.serviceName,
                serviceUOM: garment.serviceUOM,
                serviceIsAddOn: garment.isAddOn,
              });
            }
            return true;
          });
          services.map((service, index) => {
            let serviceWiseGarments = garments.data
              .filter(
                (x) =>
                  x.serviceID === service.serviceID &&
                  x.serviceName === service.serviceName
              )
              .map((garment, index) => {
                return {
                  id: garment.garmentID,
                  name: garment.garmentName,
                  icon: garment.garmentImage,
                  garmentDeliveryType:garment.garmentDeliveryType //Added by rashmi for L177728 - 20/02/23
                };
              });
            returnVal.push({
              serviceId: service.serviceID.toString(),
              title: service.serviceName,
              UOM: service.serviceUOM,
              isAddOn: service.serviceIsAddOn,
              garments: serviceWiseGarments,
            });
            return true;
          });
        } else {
          returnVal = response;
        }
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getGarmentsList(service, uom);
      });
    }
    return Promise.resolve(returnVal);
  }

  async getRateCardForStore(
    store,
    uom,
    delivery,
    service,
    garment,
    date = moment().format("YYYY-MM-DD"),
    isCorporate = false
  ) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    let callObj = null;
    if (isCorporate === true) {
      stateListAPI.createEntity({
        name: this.endpoint.GET_RATE_CARD_FOR_CORPORATE,
      });
      callObj =
        stateListAPI.endpoints[this.endpoint.GET_RATE_CARD_FOR_CORPORATE];
    } else {
      stateListAPI.createEntity({
        name: this.endpoint.GET_RATE_CARD_FOR_STORE,
      });
      callObj = stateListAPI.endpoints[this.endpoint.GET_RATE_CARD_FOR_STORE];
    }

    let returnVal = [];
    let params = {
      corporateID: parseInt(store),
      storeID: parseInt(store),
      uom: parseInt(uom),
      effectiveDate: date,
      deliveryType: parseInt(delivery),
      serviceID: parseInt(service),
      garmentID: parseInt(garment),
    };
    await callObj
      .getAllPost(params)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getRateCardForStore(
          store,
          uom,
          delivery,
          service,
          garment,
          date,
          isCorporate
        );
      });
    }
    return Promise.resolve(returnVal);
  }

  async getRateCardForStore1(
    store,
    uom,
    delivery,
    service,
    garment,
    date = moment().format("YYYY-MM-DD"),
    isCorporate = false
  ) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    let callObj = null;
    if (isCorporate === true) {
      stateListAPI.createEntity({
        name: this.endpoint.GET_RATE_CARD_FOR_CORPORATE,
      });
      callObj =
        stateListAPI.endpoints[this.endpoint.GET_RATE_CARD_FOR_CORPORATE];
    } else {
      stateListAPI.createEntity({
        name: this.endpoint.GET_RATE_CARD_FOR_STORE,
      });
      callObj = stateListAPI.endpoints[this.endpoint.GET_RATE_CARD_FOR_STORE];
    }

    let returnVal = [];
    let params = {
      corporateID: parseInt(store),
      storeID: parseInt(store),
      uom: parseInt(uom),
      effectiveDate: date,
      deliveryType: parseInt(delivery),
      serviceID: parseInt(service),
      garmentID: parseInt(garment),
    };
    await callObj
      .getAllPost(params)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getRateCardForStore(
          store,
          uom,
          delivery,
          service,
          garment,
          date,
          isCorporate
        );
      });
    }
    return Promise.resolve(returnVal);
  }

  async getUOMList() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_GARMENTUOM_LIST });
    let serviceTypes = [];
    await stateListAPI.endpoints[this.endpoint.GET_GARMENTUOM_LIST]
      .getAll()
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.getUOMList();
      });
    }
    return Promise.resolve(serviceTypes);
  }

  async getBrandsList() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_BRAND_DROPDOWN });
    let serviceTypes = [];
    await stateListAPI.endpoints[this.endpoint.GET_BRAND_DROPDOWN]
      .getAll()
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.getBrandsList();
      });
    }
    return Promise.resolve(serviceTypes);
  }

  async getSizeList() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_SIZE_DROPDOWN });
    let serviceTypes = [];
    await stateListAPI.endpoints[this.endpoint.GET_SIZE_DROPDOWN]
      .getAll()
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.getSizeList();
      });
    }
    return Promise.resolve(serviceTypes);
  }
  async getDyeingColors() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_SYSTEME_NUMS });
    let serviceTypes = [];
    await stateListAPI.endpoints[this.endpoint.GET_SYSTEME_NUMS]
      .getById("?EnumNames=DyeingColors")
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.getDyeingColors();
      });
    }
    return Promise.resolve(serviceTypes);
  }

  async getOpeartionInstructions() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_OPERATION_INSTRUCT_LIST,
    });
    let serviceTypes = [];
    await stateListAPI.endpoints[this.endpoint.GET_OPERATION_INSTRUCT_LIST]
      .getAll()
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.getOpeartionInstructions();
      });
    }
    return Promise.resolve(serviceTypes);
  }

  async getTimeCard(store, date, isCorporate = false, deliveryType = null) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    let callObj = null;
    if (isCorporate !== true) {
      stateListAPI.createEntity({
        name: this.endpoint.GET_TIME_CARD_FOR_STORE,
      });
      callObj = stateListAPI.endpoints[this.endpoint.GET_TIME_CARD_FOR_STORE];
    } else {
      stateListAPI.createEntity({
        name: this.endpoint.GET_TIME_CARD_FOR_CORPORATE,
      });
      callObj =
        stateListAPI.endpoints[this.endpoint.GET_TIME_CARD_FOR_CORPORATE];
    }
    let serviceTypes = [];
    let params = {
      corporateID: parseInt(store),
      storeID: store,
      effectiveDate: date.format("YYYY-MM-DD"),
      deliveryType: deliveryType,
      serviceID: null,
      garmentID: null,
    };
    await callObj
      .getAllPost(params)
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.getTimeCard(store, date, isCorporate, deliveryType);
      });
    }
    return Promise.resolve(serviceTypes);
  }

  async getPackagingCharges(service = null, date = moment()) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_EFFECTIVE_PACKAGING_CHARGES,
    });
    let returnVal = [];
    let params = {
      serviceID: service,
      effectiveDate: date.format("YYYY-MM-DD"),
    };
    await stateListAPI.endpoints[this.endpoint.GET_EFFECTIVE_PACKAGING_CHARGES]
      .getAllPost(params)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getPackagingCharges(service, date);
      });
    }
    return Promise.resolve(returnVal);
  }

  async getDeliveryCharges(company = 1, date = moment()) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_EFFECTIVE_DELIVERY_CHARGES,
    });
    let returnVal = [];
    let params = {
      companyID: company,
      effectiveDate: date.format("YYYY-MM-DD"),
    };
    await stateListAPI.endpoints[this.endpoint.GET_EFFECTIVE_DELIVERY_CHARGES]
      .getAllPost(params)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getDeliveryCharges(company, date);
      });
    }
    return Promise.resolve(returnVal);
  }

  async createOrder(postData) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.CREATE_ORDER,
    });
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.CREATE_ORDER]
      .getAllPost(postData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.createOrder(postData);
      });
    }
    return Promise.resolve(returnVal);
  }

  async updateOrder(postData) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.UPDATE_ORDER,
    });
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.UPDATE_ORDER]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.updateOrder(postData);
      });
    }
    return Promise.resolve(returnVal);
  }

  async getOrderList(
    index = 0,
    size = 0,
    sortColumn = null,
    sortOrder = null,
    search = null,
    selectedBranch = null,
    selectedOrderRequestStatusFilter = null,
    orderFromDate = null,
    orderToDate = null
  ) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_STORE_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.GET_ORDER_LIST });
    let factories = [];
    let payload = {
      storeIDs: [null, undefined, ""].includes(selectedBranch)
        ? localStorage.getItem("branchID")
        : selectedBranch,
      // storeIDs: localStorage.getItem("branchID").toString(),
      orderID: 0,
      customerID: 0,
      orderFromDate: orderFromDate,
      orderToDate: orderToDate,
      orderStatus: selectedOrderRequestStatusFilter,
      assignedToUserID: null,

      pageIndex: index,
      pageSize: size,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      commonSearch: search,
    };
    await allFactoriesAPI.endpoints[this.endpoint.GET_ORDER_LIST]
      .getAllPost(payload)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getOrderList(
          index,
          size,
          sortColumn,
          sortOrder,
          search
        );
      });
    }
    return Promise.resolve(factories);
  }

  async getOrderByID(id) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_STORE_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.GET_ORDER_BY_ID });
    let factories = [];
    let payload = "?OrderID=" + parseInt(id);
    await allFactoriesAPI.endpoints[this.endpoint.GET_ORDER_BY_ID]
      .getById(payload)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getOrderByID(id);
      });
    }
    return Promise.resolve(factories);
  }

  async cancelOrder(orderID, reason) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.CANCEL_ORDER,
    });
    let postData = {
      orderID: parseInt(orderID),
      cancelOrderRemark: reason,
    };
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.CANCEL_ORDER]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.cancelOrder(orderID, reason);
      });
    }
    return Promise.resolve(returnVal);
  }

  async generateTags(orderID) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GENERATE_TAGS,
    });
    let postData = {
      orderID: parseInt(orderID),
      userID: parseInt(
        jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
          .unique_name
      ),
    };
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.GENERATE_TAGS]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.generateTags(orderID);
      });
    }
    return Promise.resolve(returnVal);
  }

  async validatePromocode(postData) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.VALIDATE_PROMOCODE,
    });
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.VALIDATE_PROMOCODE]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.validatePromocode(postData);
      });
    }
    return Promise.resolve(returnVal);
  }

  async getPickupInformation(value) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_PICKUP_REQUEST_BY_PICKUPID,
    });
    let serviceTypes = [];
    let append = "?PickupId=" + value;
    await stateListAPI.endpoints[this.endpoint.GET_PICKUP_REQUEST_BY_PICKUPID]
      .getOne(append)
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.getPickupInformation(value);
      });
    }
    return Promise.resolve(serviceTypes);
  }

  async GetTagToPrint(OrderID) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.GET_TAG_TO_PRINT });
    let itemArray = [];
    var payload = "?OrderID=" + OrderID;
    await myApi.endpoints[this.endpoint.GET_TAG_TO_PRINT]
      .getById(payload)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetTagToPrint(OrderID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async GetRewashTagToPrint(OrderID) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.GET_REWASh_TAG_TO_PRINT });
    let itemArray = [];
    var payload = "?OrderID=" + OrderID;
    await myApi.endpoints[this.endpoint.GET_REWASh_TAG_TO_PRINT]
      .getById(payload)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetTagToPrint(OrderID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getPDFStoreEstimatedOrder(OrderID) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_ESTIMATED_ORDER });
    let itemArray = [];
    var param = "?OrderID=" + OrderID;
    await myApi.endpoints[this.endpoint.GET_STORE_ESTIMATED_ORDER]
      .getOne(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getPDFStoreEstimatedOrder(OrderID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getEstimatedOrderPrintByOrderID(id) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_ESTIMATED_PDF_BY_ORDER_ID,
    });
    let states = [];
    let append = "?OrderID=" + parseInt(id);
    await stateListAPI.endpoints[this.endpoint.GET_ESTIMATED_PDF_BY_ORDER_ID]
      .getById(append)
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getEstimatedOrderPrintByOrderID(id);
      });
    }
    return Promise.resolve(states);
  }

  //partial order received screen method
  async getPartialOrderReceivedList(
    index,
    size,
    sortColumn,
    sortOrder,
    search,
    selectedBranch
  ) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_PARTIAL_ORDER_RECEIVED_LIST });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.GET_PARTIAL_ORDER_RECEIVED_LIST]
      .getAllPost({
        storeIDs: [null, undefined, ""].includes(selectedBranch)
          ? localStorage.getItem("branchID")
          : selectedBranch,
        pageIndex: index,
        pageSize: size,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        commonSearch: search,
      })
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getPartialOrderReceivedList(
          index,
          size,
          sortColumn,
          sortOrder,
          search
        );
      });
    }
    return Promise.resolve(itemArray);
  }

  async ADDAltNumberForCustomer(postData) {
    const stateListAPI = new API({ url: this.config.CDA_URL });
    stateListAPI.createEntity({
      name: this.endpoint.CDA_ADD_ALT_NUMBER_FOR_CUSTOMER,
    });
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.CDA_ADD_ALT_NUMBER_FOR_CUSTOMER]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.ADDAltNumberForCustomer(postData);
      });
    }
    return Promise.resolve(returnVal);
  }

  async SendCustomerOTPForCreateOrder(postData) {
    const stateListAPI = new API({ url: this.config.CDA_URL });
    stateListAPI.createEntity({
      name: this.endpoint.CDA_SEND_RESEND_CUSTOMEROTP,
    });
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.CDA_SEND_RESEND_CUSTOMEROTP]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.SendCustomerOTPForCreateOrder();
      });
    }
    return Promise.resolve(returnVal);
  }



  async UpdateVerifiedOTPDateTime(postData) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.UPDATE_VERIFIED_OTP_DATETIME,
    });
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.UPDATE_VERIFIED_OTP_DATETIME]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.UpdateVerifiedOTPDateTime();
      });
    }
    return Promise.resolve(returnVal);
  }

  async getGarmentDefect() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_SYSTEME_NUMS });
    let serviceTypes = [];
    await stateListAPI.endpoints[this.endpoint.GET_SYSTEME_NUMS]
      .getById("?EnumNames=GarmentDefect")
      .then((response) => {
        serviceTypes = response.data;
      })
      .catch((err) => {
        serviceTypes = this.common.handleError(err);
      });
    if (serviceTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        serviceTypes = this.getGarmentDefect();
      });
    }
    return Promise.resolve(serviceTypes);
  }
}
