import React from "react";
import EmployeeCredit from "../../../components/LKWallet/EmployeeCredit/lkemployeecredit.component";

export default class EmployeeCreditsScreen extends React.Component {
                 render() {
                   return (
                     <div>
                       <EmployeeCredit></EmployeeCredit>
                     </div>
                   );
                 }
               }
