import React from "react";
import DesignationMaster from "../../../components/Master/Designation/designationmaster.component"

export default class DesignationScreen extends React.Component {
  render() {
    return (
      <div >
        <DesignationMaster></DesignationMaster>
      </div>
    );
  }
}