import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class outofcoverageservice {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async retrieveItems(param) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_OUT_OF_COVERAGE });
    let itemArray = [];
    await myApi.endpoints.OutOfCoverage.getAllPost(param)
      .then(({ data }) => {
        // console.log("data->",data);
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  
  async getpickupdeliveryassignmentlist(param) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_PICKUP_DELIVERY_ASSIGNMENT_LIST });
    let itemArray = [];
    await myApi.endpoints.GetPickupAndDeliveryExecutive.getAllPost(param)
      .then(({ data }) => {
        console.log("data->",data);
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getStoreStatusList() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({
      name: this.endpoint.GET_SYSTEME_NUMS,
    });
    let append = "?EnumNames=StoreStatus";
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_SYSTEME_NUMS]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getStoreStatusList();
      });
    }
    return itemArray;
  }




  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}
