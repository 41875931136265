import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Row,
  Col,
  Form,
  Table,
  notification,
  DatePicker,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import pendingcashsummaryservice from "../../../services/pendingcashsummaryservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
import moment from "moment";
const CURRENT_DATE = new Date();
const monthFormat = "YYYY/MM";
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);
const Temp_Date = new Date("2022-05-01");

export default class ordersummarycomponent extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.pendingcashsummaryservice = new pendingcashsummaryservice();
    this.common = new Common();
    this.state = {
      currentMonth: moment(CURRENT_DATE),
      selectedmonth: moment(CURRENT_DATE).format("M"),
      selectedyear: moment(CURRENT_DATE).format("Y"),
      modalLoading: false,
      dashboardItems: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 200,
        total: 0,
        showSizeChanger: true,
      },
      showfilterBox: true,
      loading: true,
      userRights: [],
      isResponseErrorShow: false,
      // totalOrder: "",
      // totalOrderAmount: "",
      // totalInvoice: "",
      // totalInvoiceAmount: "",
      // totalLoss: "",
      // totalPenOrders: "",
      // totalPenAmount: "",
      sorter: {
        field: null,
        order: null,
      },
    };
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }

    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.ORDERS_SUMMARY
    );

    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.SR_NO,
      dataIndex: "srNo",
      key: "srNo",
      align: "center",
      
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
      align: "center",
    },

    {
      title: "MIS",
      children: [
        {
          title: "NO of Orders",
          dataIndex: "misOrders",
          key: "misOrders",
          align: "center",
          sorter: true,
        },
        {
          title: "Amount",
          dataIndex: "misAmount",
          key: "misAmount",
          align: "center",
          sorter: true,
        },

        {
          title: "Revenue %",
          dataIndex: "misRevenue",
          key: "misRevenue",
          align: "center",
          sorter: true,
        },
      ],
    },

    {
      title: "Invoices",
      children: [
        {
          title: "NO of Invoice",
          dataIndex: "invOrders",
          key: "invOrders",
          align: "center",
          sorter: true,
        },
        {
          title: "Amount",
          dataIndex: "invAmount",
          key: "invAmount",
          align: "center",
          sorter: true,
        },

        {
          title: "Loss of Revenue",
          dataIndex: "loss",
          key: "loss",
          align: "center",
          sorter: true,
        },
      ],
    },

    {
      title: "Pending",
      children: [
        {
          title: "NO of Orders",
          dataIndex: "penOrders",
          key: "penOrders",
          align: "center",
          sorter: true,
        },
        {
          title: "Amount",
          dataIndex: "penAmount",
          key: "penAmount",
          align: "center",
          sorter: true,
        },

        {
          title: "Pending %",
          dataIndex: "penPerce",
          key: "penPerce",
          align: "center",
          sorter: true,
        },
      ],
    },

    {
      title: "Consolidated Pending",
      children: [
        {
          title: "NO of Orders",
          dataIndex: "conOrders",
          key: "conOrders",
          align: "center",
          sorter: true,
        },
        {
          title: "Amount",
          dataIndex: "conAmount",
          key: "conAmount",
          align: "center",
          sorter: true,
        },

        {
          title: "Pending %",
          dataIndex: "conPerce",
          key: "conPerce",
          align: "center",
          sorter: true,
        },
      ],
    },
  ];

  // totalcolumns = [
  //   {
  //     title: "",
  //     dataIndex: "grandTotal",
  //     key: "grandTotal",
  //     align: "center",
  //   },

  //   {
  //     title: "MIS",
  //     children: [
  //       {
  //         title: " ",
  //         dataIndex: "totalOrder",
  //         key: "totalOrder",
  //         align: "center",

  //       },
  //       {
  //         title: "",
  //         dataIndex: "totalOrderAmount",
  //         key: "totalOrderAmount",
  //         align: "center",
  //       },

  //       {
  //         title: "",
  //         dataIndex: "totalOrderRevenue",
  //         key: "totalOrderRevenue",
  //         align: "center",
  //       },
  //     ],
  //   },

  //   {
  //     title: "Invoices",
  //     width:"30%",
  //     children: [
  //       {
  //         title: "NO of Invoice",
  //         dataIndex: "totalInvoice",
  //         key: "totalInvoice",
  //         align: "center",
  //       },
  //       {
  //         title: "Amount",
  //         dataIndex: "totalInvoiceAmount",
  //         key: "totalInvoiceAmount",
  //         align: "center",
  //       },

  //       {
  //         title: "Loss of Revenue",
  //         dataIndex: "totalLoss",
  //         key: "totalLoss",
  //         align: "center",
  //       },
  //     ],
  //   },

  //   {
  //     title: "Pending",
  //     width:"30%",
  //     children: [
  //       {
  //         title: "NO of Orders",
  //         dataIndex: "totalPenOrders",
  //         key: "totalPenOrders",
  //         align: "center",
  //       },
  //       {
  //         title: "Amount",
  //         dataIndex: "totalPenAmount",
  //         key: "totalPenAmount",
  //         align: "center",
  //       },

  //       {
  //         title: "Pending %",
  //         dataIndex: "totalPending",
  //         key: "totalPending",
  //         align: "center",
  //       },
  //     ],
  //   },
  // ];

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      fMonth: this.state.selectedmonth,
      fYear: this.state.selectedyear,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      sortColumn: null,
      sortOrder: null,
      commonSearch: null,
    });
  }

  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.pendingcashsummaryservice.getOrdersummarydata(params).then((items) => {
      // console.log("items->", items);
      this.setState({
        dashboardItems: [],
      });
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            fMonth: this.state.selectedmonth,
            fYear: this.state.selectedyear,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
            sortColumn: null,
            sortOrder: null,
            commonSearch: null,
          });
        }
        this.setState({
          // dashboardItems: items.data.vmOrderSummaryList,
          dashboardItems: items.data,
          // pagination: {
          //   ...params.pagination,
          //   total: items.data.length > 0 ? items.data[0].totalRecords : 0,
          //   //total: items.data.vmOrderSummaryList.length  > 0 ? items.data.vmOrderSummaryList[0].totalRecords : 0,
          // },
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "Order Summary",
            description: items.message
              ? items.message
              : "Unable to get Order Summary data",
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
      }
      this.setState({ loading: false });
    });
  };

  handleTableChange = (pagination, filters, sorter, extra) => {
    // console.log("pagination->", pagination);
    this.setState(
      {
        sorter: {
          ...sorter,
          order:
            sorter.order === "ascend"
              ? "asc"
              : sorter.order === "descend"
              ? "desc"
              : sorter.order,
        },
      },
      () => {
        this.fetchItems({
          fMonth: this.state.selectedmonth,
          fYear: this.state.selectedyear,
          pageIndex: pagination.current,
          pageSize: 200,
          sortColumn: this.state.sorter.field,
          sortOrder: this.state.sorter.order,
          commonSearch: null,
          pagination,
        });
      }
    );
  };

  handleDateChange = (date, dateString) => {
    //console.log("dateString->", dateString);
    let month = dateString.split("/")[1];
    let year = dateString.split("/")[0];
    // console.log("month->", month, year);
    this.setState({ selectedmonth: month, selectedyear: year });
  };

  render() {
    const { pagination, loading } = this.state;
    const dashboardItems = this.state.dashboardItems;
    // console.log("dashboardItems->", dashboardItems);
    const listItems = dashboardItems.map((item, index) => ({
      key: item.storeName,
      srNo:item.rowNumber,
      storeName: item.storeName,
      misOrders: item.misOrders,
      misAmount: item.misAmount,
      misRevenue: item.misRevenue,
      invOrders: item.invOrders,
      invAmount: item.invAmount,
      loss: item.loss,
      penOrders: item.penOrders,
      penAmount: item.penAmount,
      penPerce: item.penPerce,
      conOrders: item.conOrders,
      conAmount: item.conAmount,
      conPerce: item.conPerce,
      totalRecords: item.totalRecords,
    }));

   

    return (
      <div>
        <PageHeader
          title={labels.ORDERS_SUMMARY}
          extra={[
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.fetchItems({
                fMonth: this.state.selectedmonth,
                fYear: this.state.selectedyear,
                pageIndex: pagination.current,
                // pageSize: pagination.pageSize,
                pageSize: 200,
                sortColumn: null,
                sortOrder: null,
                commonSearch: null,
                // pagination,
              })
            }
            initialValues={{
              currentMonth: this.state.currentMonth,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"Month"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    // defaultValue={moment("2015/01", monthFormat)}
                    defaultValue={moment(CURRENT_DATE, monthFormat)}
                    value={this.state.currentMonth}
                    format={monthFormat}
                    picker="month"
                    onChange={(date, dateString) =>
                      this.handleDateChange(date, dateString)
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          onRow={(record, rowIndex) => {
            return {
              style: {fontWeight :record.key == 'Grand Total' ? "bold" :null ,
               color:record.key == 'Grand Total' ? "black" :null 
                    }
            };
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          bordered
        />
        <TableActions isPagination={false}></TableActions>
      </div>
    );
  }
}
