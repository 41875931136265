import React from "react";
import styles from "./factoryinventory.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
// import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Select,
  Table,
  Row,
  Col,
  Form,
  notification,
  Checkbox,
  Space,
  Tooltip,
  Modal,
  Spin,
  DatePicker,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ROUTES from "../../../routes";
import { Link, useLocation, withRouter } from "react-router-dom";
import {
  faChevronUp,
  faFileExcel,
  faInfo,
  // faPencilAlt,
  // faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { IsAuthenticated } from "../../../services/auth-header";
import FactoryInventory from "../../../services/factoryinventoryservice";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Moment from "moment";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Option } = Select;
const { Search } = Input;
const todate = new Date();
class Master extends React.Component {
  UserRightsConfiguration = new UserRightsConfiguration();
  inventoryService = new FactoryInventory();
  common = new Common();
  state = {
    IsPackagingDue: 0,
    showInfoPopup: false,
    inventoryData: [],
    loading: true,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    SplitOrderNumber: "",
    OldDeliveryDate: Moment(),
    deliverydate: Moment(),
    modalLoading: false,
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
    excelFile: <React.Fragment></React.Fragment>,
    excelButtonLoading: false,
    deliveryFromDate: Moment(),
    deliveryToDate: Moment(),
    serviceID: null,
  };

  columns = [
    {
      title: labels.SPLIT_ORDER_NUMBER,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },
    {
      title: labels.DELIVERY_TYPE,
      dataIndex: "deliveryTypeName",
      key: "deliveryTypeName",
      sorter: true,
    },

    {
      title: labels.ORDER_DATE,
      dataIndex: "orderDate",
      key: "orderDate",
      sorter: true,
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.FACTORY_IN_DATE,
      dataIndex: "factoryInTime",
      key: "factoryInTime",
      sorter: true,
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.LOT_SIZE,
      dataIndex: "noOfGarment",
      key: "noOfGarment",
      sorter: true,
    },
    {
      title: labels.GARMENTS_FACTORY_IN,
      dataIndex: "noofgarmentsFactoryIn",
      key: "noofgarmentsFactoryIn",
      sorter: true,
    },
    {
      title: labels.GARMENTS_MISSING,
      dataIndex: "noofgarmentsMissing",
      key: "noofgarmentsMissing",
      sorter: true,
    },
    {
      title: labels.SERVICE,
      dataIndex: "serviceName",
      key: "serviceName",
      sorter: true,
    },
    {
      title: labels.DELIVERY_DATE,
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      sorter: true,
      render: (text, record) => {
        // console.log("record->",record)
        return [null, undefined, ""].includes(text) ? undefined : Moment(
            text
          ).format("YYYY-MM-DD") <= Moment(todate).format("YYYY-MM-DD") ? (
          <Space size="middle">
           
            <Tooltip title="Change Delivery Date">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showInfoPopup: true,
                    OldDeliveryDate: Moment(text).format("YYYY-MM-DD"),
                    SplitOrderNumber: record.splitOrderNumber,
                  })
                }
              >
                {Moment(text).format("DD-MM-YYYY")}
              </Button>
            </Tooltip>
          </Space>
        ) : (
          // console.log("date->", Moment(text).format("YYYY-MM-DD"), Moment(todate))
          Moment(text).format("DD-MM-YYYY")
        );
      },
    },

    {
      title: labels.GARMENTS_AT_QC,
      dataIndex: "noofgarmentsatQC",
      key: "noofgarmentsatQC",
      sorter: true,
    },
    {
      title: labels.MISSING,
      dataIndex: "missing",
      key: "missing",
      sorter: true,
    },
    {
      title: labels.DAMAGE,
      dataIndex: "damage",
      key: "damage",
      sorter: true,
    },

    {
      title: labels.FACTORY_OUT,
      dataIndex: "factoryOutGarments",
      key: "factoryOutGarments",
      sorter: true,
    },

    {
      title: labels.PACKED,
      dataIndex: "noofPackedgarments",
      key: "noofPackedgarments",
      sorter: true,
    },

    {
      title: labels.PACKING_DUE,
      dataIndex: "balancedGarments",
      key: "balancedGarments",
      sorter: true,
    },
    {
      title: labels.STORECODE,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
  ];

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.FACTORY_GARMENT_INVENTORY
    );
    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
  }

  handlePopupCancel = () => {
    this.setState({ showInfoPopup: false });
  };

  handlePopupOk = () => {
    this.setState({ showInfoPopup: false });
    var postData = {
      OldDeliveryDate: this.state.OldDeliveryDate,
      NewDeliveryDate: Moment(this.state.deliverydate).format("yyyy-MM-DD"),
      SplitOrderNumber: this.state.SplitOrderNumber,
    };

    this.inventoryService.UpdateDeliveryDate(postData).then((items) => {
      if (items.result === "Success") {
        notification["success"]({
          message: "Factory Inventory",
          description: items.message,
        });
        this.setState({ showInfoPopup: false });
        this.getFactoryGarmentInventory(
          this.state.pagination.current,
          this.state.pagination.pageSize,
          this.state.sorter.field,
          this.state.sorter.order,
          this.state.search,
          this.state.IsPackagingDue
        );
      } else {
        notification["error"]({
          message: "Factory Inventory",
          description: items.message,
        });
      }
      this.setState({ deliverydate: Moment() });
    });
  };

  componentDidMount() {
    // if (this.props.location.state === undefined) {
    //   console.log("in 1")
    //   this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    // }
    // if (
    //   this.props.match.path ===
    //   [ROUTES.FACTORY_GARMENT_INVENTORY, ":serviceID"].join("/")
    // ) {
    //   console.log("here")
    //   // this.getInvoiceDetailFromSplitOrder();
    //   // if (this.props.match.params.splitOrderID.split(",").length > 1) {
    //   //   this.setState({ mode: "multiple" });
    //   // }
    // }
    console.log("test1->", this.props.match);
    // if (this.props.match != undefined) {
    //   this.setState({
    //     serviceID: this.props.match.params.serviceID,
    //     deliveryFromDate: this.props.match.params.deliveryFromDate,
    //     deliveryToDate: this.props.match.params.deliveryToDate,
    //   });
    // }
    // else{
    //   this.setState({
    //     serviceID: this.props.match.params.serviceID,
    //     deliveryFromDate: this.props.match.params.deliveryFromDate,
    //     deliveryToDate: this.props.match.params.deliveryToDate,
    //   });
    // }
    // console.log(
    //   "param->",
    //   this.props.match.params.serviceID,
    //   this.props.match.params.deliveryFromDate,
    //   this.props.match.params.deliveryToDate
    // );
    this.getFactoryGarmentInventory(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search,
      this.state.IsPackagingDue,
      // this.props.match.params.serviceID,
      // this.props.match.params.deliveryFromDate,
      // this.props.match.params.deliveryToDate
    );
  }

  getFactoryGarmentInventory = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search,
    IsPackagingDue
  ) => {
    this.setState({ loading: true });

    this.inventoryService
      .getFactoryGarmentInventoryById(
        index,
        size,
        sortColumn,
        sortOrder,
        search,
        parseInt(localStorage.getItem("branchID")),
        IsPackagingDue,
        this.props.match.params.serviceID,
        this.props.match.params.deliveryFromDate,
        this.props.match.params.deliveryToDate
      )
      .then((response) => {
        if (response.statusCode === 200) {
          if (
            response.data.length === 0 &&
            this.state.pagination.current !== 1
          ) {
            this.setState(
              { pagination: { ...this.state.pagination, current: 1 } },
              () => {
                this.getFactoryGarmentInventory(
                  1,
                  size,
                  sortColumn,
                  sortOrder,
                  search,
                  IsPackagingDue
                );
              }
            );
          }
          this.setState({ inventoryData: response.data });
          this.setState({
            pagination: {
              ...this.state.pagination,
              total: this.state.inventoryData.length
                ? this.state.inventoryData[0].totalRecords
                : 0,
              current: this.state.inventoryData.length
                ? this.state.pagination.current
                : 1,
            },
          });
        } else {
          notification["error"]({
            message: labels.INVENTORY,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_FACTORY_GARMENTS_INVENTORY_DATA,
          });
        }
        this.setState({ loading: false });
      });
  };

  generateExcel = () => {
    if (this.state.inventoryData.length > 0) {
      this.setState({ excelButtonLoading: true });
      let totalRecords = this.state.inventoryData[0].totalRecords;
      this.inventoryService
        .getFactoryGarmentInventoryById(
          1,
          totalRecords,
          null,
          null,
          null,
          parseInt(localStorage.getItem("branchID")),
          parseInt(this.state.IsPackagingDue)
          // this.ServiceItem.retrieveItems({
          //   serviceID: null,
          //   pageIndex: 1,
          //   pageSize: totalRecords,
        )
        .then((response) => {
          if (response.statusCode === 200) {
            let factoryData = [];
            response.data.map((singleData) => {
              singleData.key = singleData.splitOrderNumber;
              singleData.orderDate = [null, undefined, ""].includes(
                singleData.orderDate
              )
                ? undefined
                : Moment(singleData.orderDate).format("DD-MM-YYYY");
              singleData.factoryInTime = Moment(
                singleData.factoryInTime
              ).format("DD-MM-YYYY");
              singleData.deliveryDate = [null, undefined, ""].includes(
                singleData.deliveryDate
              )
                ? undefined
                : Moment(singleData.deliveryDate).format("DD-MM-YYYY");
              factoryData.push(singleData);
              return null;
            });
            this.setState(
              {
                excelFile: (
                  <div>
                    <ExcelFile
                      hideElement={true}
                      filename="Factory Garment Inventory"
                    >
                      <ExcelSheet
                        data={factoryData}
                        name={"FactoryGarmentInventory"}
                        columns={[]}
                      >
                        <ExcelColumn
                          label="SplitOrderNumber"
                          value="splitOrderNumber"
                        />
                        <ExcelColumn
                          label="deliveryType"
                          value="deliveryTypeName"
                        />
                        <ExcelColumn label="OrderDate" value="orderDate" />
                        <ExcelColumn
                          label="factoryInDate"
                          value="factoryInTime"
                        />
                        <ExcelColumn label="LotSize" value="noOfGarment" />
                        <ExcelColumn
                          label="GarmentsFactoryIn"
                          value="noofgarmentsFactoryIn"
                        />
                        <ExcelColumn
                          label="GarmentsMissing"
                          value="noofgarmentsMissing"
                        />
                        <ExcelColumn label="Service" value="serviceName" />
                        <ExcelColumn
                          label="DeliveryDate"
                          value="deliveryDate"
                        />
                        <ExcelColumn
                          label="GarmentsAtQC"
                          value="noofgarmentsatQC"
                        />
                        <ExcelColumn label="Missing" value="missing" />
                        <ExcelColumn label="Damage" value="damage" />
                        <ExcelColumn
                          label="FactoryOut"
                          value="factoryOutGarments"
                        />
                        <ExcelColumn
                          label="Packed"
                          value="noofPackedgarments"
                        />
                        <ExcelColumn
                          label="Packing Due"
                          value="balancedGarments"
                        />
                        <ExcelColumn label="StoreCode" value="storeName" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                ),
              },
              () =>
                this.setState({
                  excelFile: <React.Fragment></React.Fragment>,
                  excelButtonLoading: false,
                })
            );
          }
        });
    }
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  render() {
    const { loading } = this.state;
    const inventoryData = this.state.inventoryData;

    const listItems = inventoryData.map((item) => ({
      splitOrderNumber: item.splitOrderNumber,
      orderDate: Moment(item.orderDate).format("YYYY/MM/DD"),
      noOfGarment: item.noOfGarment,
      serviceName: item.serviceName,
      deliveryDate: item.deliveryDate
        ? Moment(item.deliveryDate).format("YYYY/MM/DD")
        : null,
      deliveryTypeName: item.deliveryTypeName,
      noofgarmentsatQC: item.noofgarmentsatQC,
      missing: item.missing,
      damage: item.damage,
      noofPackedgarments: item.noofPackedgarments,
      factoryInTime: item.factoryInTime
        ? Moment(item.factoryInTime).format("YYYY/MM/DD")
        : null,
      noofgarmentsFactoryIn: item.noofgarmentsFactoryIn,
      noofgarmentsMissing: item.noofgarmentsMissing,
      balancedGarments: item.balancedGarments,
      storeID: item.storeID,
      storeName: item.storeName,
      factoryOutGarments: item.factoryOutGarments,
      bgColor: item.bgColor,
    }));
    return (
      <div>
        {this.state.excelFile}
        <PageHeader
          title={labels.GARMENT_INVENTORY_AT_FACTORY}
          extra={[
            <Checkbox
              style={{ marginTop: 7 }}
              onChange={(e) => {
                if (e.target.checked) {
                  this.setState({ IsPackagingDue: 1 });
                  this.getFactoryGarmentInventory(
                    this.state.pagination.current,
                    this.state.pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    this.state.search,
                    1
                  );
                } else {
                  this.setState({ IsPackagingDue: 0 });
                  this.getFactoryGarmentInventory(
                    this.state.pagination.current,
                    this.state.pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    this.state.search,
                    0
                  );
                }
              }}
            >
              Completely Packed Orders{" "}
            </Checkbox>,

            <Search
              placeholder="Search"
              className={styles.SearchInput}
              key="1"
              style={{ minWidth: 150 }}
              onChange={(event) => {
                this.setState({
                  search: event.target.value.toString().toLowerCase(),
                });
                this.getFactoryGarmentInventory(
                  this.state.pagination.current,
                  this.state.pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  event.target.value.toString().toLowerCase(),
                  this.state.IsPackagingDue
                );
              }}
            />,
            <Button
              variant="transparentGreyOutline"
              style={{
                display: "flex",
                alignItems: "center",
                lineHeight: "19px",
              }}
              onClick={() => {
                this.generateExcel();
              }}
              loading={this.state.excelButtonLoading}
            >
              <FontAwesomeIcon
                icon={faFileExcel}
                style={{
                  fontSize: 18,
                  marginRight: 5,
                }}
              />{" "}
              Download Excel
            </Button>,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          onRow={(record, rowIndex) => {
            return {
              style: { background: record.bgColor },
            };
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={this.state.pagination}
          loading={loading}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getFactoryGarmentInventory(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search,
                  this.state.IsPackagingDue
                );
              }
            );
          }}
        />
        <Modal
          title={"Change Delivery Date"}
          visible={this.state.showInfoPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          // width={"50%"}
        >
          <Spin spinning={this.state.modalLoading}>
            <Row>
              <Col xs={24} lg={24}>
                <div style={{ margin: 10 }}>
                  <DatePicker
                    placeholder={"Delivery Date"}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "deliverydate")
                    }
                    value={this.state.deliverydate}
                    disabledDate={(currentDate) =>
                      currentDate.isBefore(Moment(), "day") ? true : false
                    }
                  />
                </div>
              </Col>

              <Col xs={24} className="custom-modal-footer">
                <Space>
                  <>
                    <Button
                      variant="transparentGreyOutline"
                      onClick={this.handlePopupCancel}
                    >
                      Cancel
                    </Button>
                    <Button variant="purple" onClick={this.handlePopupOk}>
                      OK
                    </Button>
                  </>
                </Space>
              </Col>
            </Row>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Master);
