import React from "react";
import { Row, Col, Form, Input, Space } from "antd";
import Button from "../../../Common/Button/button";
import CorporateService from "../../../../services/corporateservice";
import { RegexLetters, MessageLetters } from "../../../../shared/validator";
// const { Option } = Select;

/**
 * Contact Form as a part of Factory Creation
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 */
export default class ContactForm extends React.Component {
  corporateService = new CorporateService();
  /**
   * State Varibales
   *
   */
  state = {};
  /**
   * API calls to fetch data and populate the dropdowns
   */
  componentDidMount() {}

  render() {
    const disableAll = this.props.mode === "view";
    return (
      <Form
        key={this.props.values.state}
        name="contact"
        layout="vertical"
        initialValues={this.props.values}
        ref={this.props.instance}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={12}>
            <Form.Item
              label="Billing Address"
              name="billingAddress"
              rules={[
                { required: true, message: "Please Enter Billing Address" },
              ]}
            >
              <Input.TextArea
                placeholder="Enter Billing Address"
                disabled={disableAll}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={12}>
            <Form.Item
              label="Service Address"
              name="serviceAddress"
              rules={[
                { required: true, message: "Please Enter Service Address" },
              ]}
            >
              <Input.TextArea
                placeholder="Enter Service Address"
                disabled={disableAll}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Email Address"
              name="emailID"
              rules={[
                { required: true, message: "Please Enter Email Address" },
                { type: "email", message: "Please Enter Valid Email Address" },
              ]}
            >
              <Input placeholder="Enter Email Address" disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Account Manager Name"
              name="accManager"
              rules={[
                {
                  required: true,
                  message: "Please Enter Account Manager Name",
                },
                {
                  pattern: RegexLetters,
                  message: MessageLetters,
                },
              ]}
            >
              <Input
                placeholder="Enter Account Manager Name"
                disabled={disableAll}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Account Manager Contact No"
              name="accManagerContact"
              rules={[
                {
                  pattern: "[6789][0-9]{9}",
                  message: "Please Enter valid Contact No",
                },
              ]}
            >
              <Input placeholder="Enter Contact Number" disabled={disableAll} />
            </Form.Item>
          </Col>

          <Col xs={24} style={{ textAlign: "right" }}>
            <Space>
              <div className="w-100"></div>
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                Back
              </Button>
              {!disableAll ? (
                <Button
                  // htmlType="submit"
                  variant="purple"
                  shape="round"
                  size="large"
                  onClick={this.props.nextFunction}
                >
                  Submit
                </Button>
              ) : null}
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
