import React from "react";
import ServiceIcons from "../../../components/Master/ServiceIcons/serviceIconsmaster.component"

export default class ServiceIconsScreen extends React.Component {
  render() {
    return (
      <div >
        <ServiceIcons></ServiceIcons>
      </div>
    );
  }
}