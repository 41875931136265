import React from "react";
import styles from "./createorder.module.css";
import { Row, Col, Input, Radio, Select, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../Common/Button/button";

/**
 * This file contains the add customer view/form from select customer panel of create order
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 10 June 2020
 *
 * @module order
 */
export default class AddCustomer extends React.Component {
  /**
   * The state varibale holds the information to be displayed on add customer form
   *
   * Please verify the details with the designs.
   */
  state = {
    requestID: "$XXXXXX",
    customerName: "Arjun Makam",
    addresses: [
      {
        addressType: "Home",
        addressText:
          "B1, Shantiniketan Apartment, EEC Road, Bengaluru - 560066",
      },
      {
        addressType: "Office",
        addressText:
          "B1, Shantiniketan Apartment, EEC Road, Bengaluru - 560066",
      },
      {
        addressType: "Custom",
        addressText:
          "B1, Shantiniketan Apartment, EEC Road, Bengaluru - 560066",
      },
    ],
    serviceTypes: [
      {
        title: "Normal",
        subtitle: "Delivery in xx hours",
      },
      {
        title: "Express",
        subtitle: "Delivery in xx hours at 2 x Normal Price",
      },
      {
        title: "Semi Express",
        subtitle: "",
      },
    ],
  };
  render() {
    /**
     * an array of colors to be displayed for address type chips/labels
     */
    const addressTypeColors = ["#ffb9d6", "#FDDB97", "#A1D1F5"];
    return (
      <div className={styles.AddCustomerContainer}>
        <div className={styles.AddCustomerWrapper}>
          <Row gutter={30}>
            <Col xs={24} lg={4} className={styles.LabelColumn}>
              Request ID
            </Col>
            <Col xs={24} lg={20} className={styles.ValueColumn}>
              {this.state.requestID}
            </Col>
          </Row>
          <Row gutter={30}>
            <Col xs={24} lg={4} className={styles.LabelColumn}>
              Customer Name
            </Col>
            <Col xs={24} lg={20} className={styles.ValueColumn}>
              <Input
                placeholder="Customer Name"
                className={styles.customerNameInput}
                value={this.state.customerName}
              />
            </Col>
          </Row>
          <Row gutter={30}>
            <Col xs={24} lg={4} className={styles.LabelColumn}>
              <div>Customer Address</div>
              <small>(Select any one Option)</small>
            </Col>
            <Col xs={24} lg={20} className={styles.ValueColumn}>
              {/**
               * Please go through this documentation regarding radio groups
               * https://ant.design/components/radio/
               * https://ant.design/components/radio/#API
               * https://ant.design/components/radio/#components-radio-demo-radiobutton-solid
               */}
              <Radio.Group
                defaultValue="Home"
                buttonStyle="solid"
                style={{ width: "100%" }}
              >
                <Row gutter={10}>
                  {/**
                   * Looping through array of addresses.
                   */}
                  {this.state.addresses.map((singleAddress, index) => {
                    return (
                      <Col xs={24} lg={6} key={index}>
                        <Radio.Button value={singleAddress.addressType}>
                          <div className={styles.AddressWrapper}>
                            <div
                              className={styles.AddressType}
                              style={{
                                backgroundColor:
                                  addressTypeColors[
                                  index % addressTypeColors.length
                                  ],
                              }}
                            >
                              {singleAddress.addressType}
                            </div>
                            <div className={styles.AddressText}>
                              {singleAddress.addressText}
                            </div>
                          </div>
                        </Radio.Button>
                      </Col>
                    );
                  })}
                  {/**
                   * Add Address option in the address panel
                   */}
                  <Col xs={24} lg={6}>
                    <div
                      className={styles.AddAddress}
                      onClick={this.props.openAddressModal}
                    >
                      <FontAwesomeIcon icon={faPlus} /> <span>Add Address</span>
                    </div>
                  </Col>
                </Row>
              </Radio.Group>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col xs={24} lg={4} className={styles.LabelColumn}>
              <div>Type of Service</div>
              <small>(Select any one Option)</small>
            </Col>
            <Col xs={24} lg={20} className={styles.ValueColumn}>
              <Radio.Group
                defaultValue="Normal"
                buttonStyle="solid"
                style={{ width: "100%" }}
              >
                <Row gutter={10}>
                  {/**
                   * Looping through service types available.
                   */}
                  {this.state.serviceTypes.map((singleService, index) => {
                    return (
                      <Col xs={24} lg={6} key={index}>
                        <Radio.Button value={singleService.title}>
                          <div className={styles.AddressWrapper}>
                            <div className={styles.ServiceTitle}>
                              {singleService.title}
                            </div>
                            <div className={styles.AddressText}>
                              {singleService.subtitle}
                            </div>
                          </div>
                        </Radio.Button>
                      </Col>
                    );
                  })}
                </Row>
              </Radio.Group>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col xs={24} lg={4} className={styles.LabelColumn}>
              B2B Customer
            </Col>
            <Col xs={24} lg={5} className={styles.ValueColumn}>
              {/**
               * Please go through below documentation for Select Component
               * https://ant.design/components/select/
               * https://ant.design/components/select/#API
               *
               */}
              <Select defaultValue="SAP" style={{ width: "100%" }}>
                <Select.Option value="SAP">SAP</Select.Option>
                <Select.Option value="SAP2">SAP 2</Select.Option>
                <Select.Option value="SAP3">SAP 3</Select.Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={30}>
            <Col xs={24} lg={4} className={styles.LabelColumn}></Col>
            <Col
              xs={24}
              lg={20}
              className={[styles.ValueColumn, styles.ButtonContainer].join(" ")}
            >
              <Space size="middle">
                <Button
                  variant="transparentGreyOutline"
                  size="large"
                  onClick={this.props.bringBack}
                >
                  Cancel
                </Button>
                <Button size="large" onClick={this.props.bringBack}>
                  Submit
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
