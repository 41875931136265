import React from "react";
import styles from "../Common/Styles/master.module.css";
import Button from "../Common/Button/button";
import Filters from "../Common/Filters/filters";
import TableActions from "../Common/TableActions/tableactions";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  // Popconfirm,
  Table,
  notification,
  Tooltip,
  DatePicker,
  Modal,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faPencilAlt,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../routes";
import CashCollection from "../../services/cashcollectionservice";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import PickupService from "../../services/pickupservice";
import Moment from "moment";
import Common from "../../services/common";
var jwt = require("jsonwebtoken");

const { Search } = Input;
const { Option } = Select;

/**
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 26 October 2020
 */
class Master extends React.Component {
  cashCollectionService = new CashCollection();
  UserRightsConfiguration = new UserRightsConfiguration();
  pickupService = new PickupService();
  commonReq = new Common();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    cashCollections: [],
    branchTypes: [],
    selectedBranch: null,
    userListOptions: [],
    userID: "",
    userName: [],
    selectedRowKeys: [],
    // selectedIds: null,
    showSubmitModal: false,
    modalLoading: false,
    collectionType: "",
    invoiceIds: "",
    walletIds: "",
    fromDate: Moment().subtract(30, "days"),
    toDate: Moment(),
    statusDDL: [],
    cashStatus: 1,
    accountExecutive: "",
    AccountExecList: [],
    showfilterBox: false,
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.CASH_COLLECTION
    );

    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
    var storeData = JSON.parse(localStorage.getItem("BranchInfo"));
    for (var i = 0; i < storeData.length; i++) {
      var res = storeData.filter((store) => store.branchType === "Store");
    }
    this.setState({ branchTypes: res });
  }

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    //   width: "3%",
    // },
    {
      title: "Order Number",
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: true,
    },
    {
      title: "Customer Paid Date",
      dataIndex: "customerPaidDate",
      key: "customerPaidDate",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Cash Submission Date",
      dataIndex: "cashSubmissionDate",
      key: "cashSubmissionDate",
      sorter: true,
      render: (text) => {
        return [null, undefined, ""].includes(text)
          ? undefined
          : moment(text).format("DD-MM-YYYY");
      },
    },
    // {
    //   title: "Collection Type",
    //   dataIndex: "collectionType",
    //   key: "collectionType",
    //   sorter: true,
    // },
    {
      title: "Cash Submission Status",
      dataIndex: "cashSubmissionStatusName",
      key: "cashSubmissionStatusName",
      sorter: true,
    },

    // {
    //   title: "Cash Submitted To User",
    //   dataIndex: "cashSubmittedToUser",
    //   key: "cashSubmittedToUser",
    //   sorter: true,
    // },
    // {
    //   title: "Cash Submission Remark",
    //   dataIndex: "cashSubmissionRemark",
    //   key: "cashSubmissionRemark",
    //   sorter: true,
    // },

    {
      title: "Executive Name",
      dataIndex: "executiveName",
      key: "executiveName",
      sorter: true,
    },
    {
      title: "Account Executive Name",
      dataIndex: "accountExecutiveName",
      key: "accountExecutiveName",
      sorter: true,
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {this.state.userRights.length > 0 &&
    //       this.state.userRights[0].isView == true ? (
    //         <Tooltip title="View">
    //           <Link to={[ROUTES.VIEW_FACTORY, record.factoryID].join("/")}>
    //             <FontAwesomeIcon icon={faInfo} />
    //           </Link>
    //         </Tooltip>
    //       ) : null}
    //       {this.state.userRights.length > 0 &&
    //       this.state.userRights[0].isUpdate == true ? (
    //         <Tooltip title="Edit">
    //           <Link to={[ROUTES.EDIT_FACTORY, record.factoryID].join("/")}>
    //             <FontAwesomeIcon icon={faPencilAlt} />
    //           </Link>
    //         </Tooltip>
    //       ) : null}
    //     </Space>
    //   ),
    // },
  ];

  componentDidMount() {
    this.getCashCollectionList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
    this.getCashCollectionStatus();
    // this.getUserList(parseInt(localStorage.getItem("branchID")));
  }

  getCashCollectionList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    if (!this.state.tableLoading) {
      this.setState({ tableLoading: true });
      this.cashCollectionService
        .getCashCollectionEntries(
          index,
          size,
          sortColumn,
          sortOrder,
          search,
          this.state.selectedBranch
            ? this.state.selectedBranch.toString()
            : null,
          this.state.userID ? this.state.userID : null,
          this.state.cashStatus,
          Moment(this.state.fromDate).format("YYYY-MM-DD"),
          Moment(this.state.toDate).format("YYYY-MM-DD")
        )
        .then((response) => {
          if (response.statusCode === 200) {
            let cashCollectionData = [];
            response.data.map((singleData) => {
              singleData.key = singleData.id;
              singleData.customerPaidDate = singleData.customerPaidDate
                ? moment(singleData.customerPaidDate).format("YYYY-MM-DD")
                : undefined;
              singleData.cashSubmissionDate = singleData.cashSubmissionDate
                ? moment(singleData.cashSubmissionDate).format("YYYY-MM-DD")
                : undefined;
              cashCollectionData.push(singleData);
              return null;
            });
            this.setState({ cashCollections: cashCollectionData });
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: cashCollectionData.length
                  ? cashCollectionData[0].totalRecords
                  : 0,
              },
            });
          } else {
            notification["error"]({
              message: "Cash Collection",
              description: response.message
                ? response.message
                : "Unable to get list of cash collection data",
            });
          }
          this.setState({ tableLoading: false });
        });
    }
  };
  getUserList = (storeId) => {
    this.pickupService.getUserListBasedOnStore(storeId).then((items) => {
      if (items.statusCode === 200) {
        this.setState({ userListOptions: items.data });
      } else {
        notification["error"]({
          message: "Cash Collection",
          description: items.message
            ? items.message
            : "Unable to get list of user data",
        });
      }
    });
  };

  RestoreDDlValue = (e) => {
    this.setState({ userID: e });
    // accountExecutive: e
    // var userArray = this.state.userListOptions.filter((x) => x.value === e);
    // if (userArray !== undefined) {
    //   this.setState({
    //     userID: userArray[0].value,
    //     userName: userArray[0].text,
    //   });
    // }
  };
  RestoreDDlValueAccountExec = (e) => {
    this.setState({ accountExecutive: e });
  };

  getAccountExecByStoreID = (storeId) => {
    this.cashCollectionService
      .getAccountExecutiveByStoreID(storeId)
      .then((items) => {
        if (items.statusCode === 200) {
          this.setState({ AccountExecList: items.data });
        } else {
          notification["error"]({
            message: "Cash Collection",
            description: items.message
              ? items.message
              : "Unable to get list of account executive data",
          });
        }
      });
  };

  handleSelect = (event) => {
    this.setState({ selectedBranch: event }, () => {
      if (![null, undefined, ""].includes(this.state.selectedBranch)) {
        this.getUserList(this.state.selectedBranch.toString());
      } else {
        this.setState({ userListOptions: [] });
      }
    });
  };

  handlePopupCancel = () => {
    this.setState({ showSubmitModal: false });
  };

  submitCashCollection = (values) => {
    this.setState({ modalLoading: true });
    let invoiceId = [];
    let walletId = [];
    let InvoiceCollectionType = this.state.selectedRows.map((type) => {
      if (type.collectionType === "Invoice") {
        invoiceId.push(type.id);
      }
      if (type.collectionType === "Wallet") {
        walletId.push(type.id);
      }
    });

    let postData = {
      invoicePaymentIDs: invoiceId.toString(),
      lkWalletIDs: walletId.toString(),
      userID: jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
        .unique_name,
      accountUserID: this.state.accountExecutive,
      remark: values.remarks,
    };
    this.cashCollectionService
      .submitCashCollectionData(postData)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Cash Collection",
            description: response.message
              ? response.message
              : "Data is successfully saved",
          });
          this.setState({ showSubmitModal: false });
        } else {
          notification["error"]({
            message: "Cash Collection",
            description: response.message
              ? response.message
              : "Unable to submit data",
            duration: 1.5,
          });
        }
        this.setState({ modalLoading: false });
        window.location.reload();
      });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  getCashCollectionStatus = () => {
    this.commonReq.GetSystemEnums("CashCollectionStatus").then((response) => {
      if (response.statusCode === 200) {
        this.setState({ statusDDL: response.data });
      } else {
        notification["error"]({
          message: "Cash Collection",
          description: response.message
            ? response.message
            : "Unable to get status data",
        });
      }
    });
  };

  handleSelectStatus = (event) => {
    this.setState({ cashStatus: event });
  };

  render() {
    return (
      <div>
        <PageHeader
          title="Cash Collection"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              key="1"
              style={{ minWidth: 150 }}
              onChange={(event) => {
                this.setState({
                  search: event.target.value.toString().toLowerCase(),
                });
                this.getCashCollectionList(
                  this.state.pagination.current,
                  this.state.pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  event.target.value.toString().toLowerCase()
                );
              }}
            />,

            /**
             * Add Factory Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                disabled={this.state.selectedRowKeys.length === 0}
                onClick={() => {
                  this.setState({
                    showSubmitModal: true,
                    selectedRows: this.state.selectedRows,
                  });
                  this.getAccountExecByStoreID(this.state.selectedBranch);
                }}
                key="2"
              >
                Submit
              </Button>
            ) : null,
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
              key="3"
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.getCashCollectionList(
                this.state.pagination.current,
                this.state.pagination.pageSize,
                this.state.sorter.field,
                this.state.sorter.order,
                this.state.search
              )
            }
            initialValues={{
              store: this.state.selectedBranch
                ? this.state.selectedBranch
                : undefined,
              user: this.state.userID ? this.state.userID : undefined,
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="store">
                  <Select
                    showSearch
                    onChange={this.handleSelect}
                    style={{ minWidth: 150 }}
                    allowClear
                    value={this.state.selectedBranch}
                    placeholder="Select Store"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.branchTypes.length !== 0
                      ? this.state.branchTypes.map((branchType) => {
                          return branchType.branchID !== "-1" ? (
                            <Option
                              value={
                                branchType.branchType === "Store"
                                  ? branchType.branchID
                                  : "f" + branchType.branchID
                              }
                              key={
                                branchType.branchType === "Store"
                                  ? branchType.branchID
                                  : "f" + branchType.branchID
                              }
                            >
                              {branchType.branchName}
                            </Option>
                          ) : null;
                        })
                      : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="user">
                  <Select
                    showSearch
                    placeholder="Select User"
                    allowClear
                    style={{ minWidth: 150 }}
                    onChange={this.RestoreDDlValue}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {/* {this.state.stateListOptions.length !== 0? */}
                    {
                      this.state.userListOptions.map((user) => {
                        return user.value !== "-1" ? (
                          <option key={user.value} value={user.value}>
                            {user.text}
                          </option>
                        ) : null;
                      })
                      // :null
                      // }
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={6}>
                <Form.Item
                  name={"fromDate"}
                  rules={[
                    { required: true, message: "Please Select From Date" },
                  ]}
                >
                  <DatePicker.RangePicker
                    // placeholder="From Date"

                    format={"DD-MM-YYYY"}
                    onChange={(dates) => {
                      this.handleDateChange(dates[0], "fromDate");
                      this.handleDateChange(dates[1], "toDate");
                    }}
                    value={[this.state.fromDate, this.state.toDate]}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                    defaultValue={[
                      this.state.fromDate.toString(),
                      this.state.toDate.toString(),
                    ]}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              {/* <Col xs={24} lg={4}>
                <Form.Item
                  name={"toDate"}
                  rules={[{ required: true, message: "please select to date" }]}
                >
                  <DatePicker
                    placeholder="To Date"
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "toDate")}
                    value={this.state.toDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col> */}
              <Col xs={24} lg={4}>
                <Form.Item name="status" initialValue={1}>
                  <Select
                    showSearch
                    placeholder="Select Status"
                    style={{ minWidth: 150 }}
                    onChange={this.handleSelectStatus}
                    value={this.state.cashStatus || ""}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.statusDDL.length !== 0
                      ? this.state.statusDDL.map((user) => {
                          return user.enumDetailID !== "-1" ? (
                            <option
                              key={user.enumDetailID}
                              value={user.enumDetailID}
                            >
                              {user.enumDetailName}
                            </option>
                          ) : null;
                        })
                      : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  variant="whitePink"
                  shape="round"
                  size="large"
                  htmlType="submit"
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.cashCollections}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          rowSelection={{
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                selectedRowKeys: selectedRowKeys,
                selectedRows: selectedRows,
              });

              //   //for multiple ids
              //   this.setState({
              //     selectedIds:
              //       selectedRows.length > 0 ? selectedRows[0].id : null,
              //   });
            },
            getCheckboxProps: (record) => {
              let shouldDisable = true; //default is disabled
              if (record.cashSubmissionStatus === 1) {
                shouldDisable = false;
              } else {
                shouldDisable = true;
              }
              return { disabled: shouldDisable };
            },
          }}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getCashCollectionList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />

        <TableActions isPagination={true}></TableActions>
        <Modal
          width={600}
          title="Cash Collection"
          visible={this.state.showSubmitModal}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={(values) => {
                this.submitCashCollection(values);
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    name="accountExecutive"
                    label="Account Executive"
                    rules={[
                      {
                        required: true,
                        message: "Please select account executive",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Account Executive"
                      allowClear
                      style={{ minWidth: 150 }}
                      onChange={this.RestoreDDlValueAccountExec}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {/* {this.state.stateListOptions.length !== 0? */}
                      {
                        this.state.AccountExecList.map((user) => {
                          return user.value !== "-1" ? (
                            <option key={user.value} value={user.value}>
                              {user.text}
                            </option>
                          ) : null;
                        })
                        // :null
                        // }
                      }
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Remarks"
                    name="remarks"
                    rules={[
                      { required: true, message: "Please enter remarks" },
                    ]}
                  >
                    <Input.TextArea
                      //   disabled={disableAll}
                      placeholder="Remarks"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    <React.Fragment>
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        Cancel
                      </Button>
                      <Button htmlType="submit" variant="purple">
                        Submit
                      </Button>
                    </React.Fragment>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Master);
