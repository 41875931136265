import React from "react";
import styles from "./createtimecardscreen.module.css";
import TimeCardMaster from "../../components/TimeCard/CreateTimeCard/timecardmaster.component";
import TimeCardDetail from "../../components/TimeCard/CreateTimeCard/timecarddetail.component";
import StoreAssignment from "../../components/TimeCard/CreateTimeCard/storeassignment.component";
import CorporateAssignment from "../../components/TimeCard/CreateTimeCard/corporateassignment.component";
import TimeCardList from "./timecardlist.screen";
import { PageHeader, Steps, notification, Form, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import TimeCardService from "../../services/timecardservice";
//import Moment from 'moment'
import { withRouter } from "react-router-dom";
import ROUTES from "../../routes";

const { Step } = Steps;

// const openNotificationWithIcon = (type, title, message) => {
//   notification[type]({
//       message: title,
//       description:
//           message
//   });
// };
/**
 * User Creation Screen with steps and form (Master - Form).
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 1 June 2020
 *
 */
class CreateTimeCard extends React.Component {
  /**
   * State Varible to Hold Active Step Index starting from 0
   */
  constructor(props) {
    super(props);
    this.timecardservice = new TimeCardService();
    this.state = {
      detailsLoading: false,
      isAddTimecard: false,
      mode: "add",
      isViewmode: false,
      fullLoading: false,
      activeStep: 0,
      stepsDirection: "vertical",
      stepsSize: "default",
      timeCardID: 0,
      timeAssignStoreID: 0,
      timeAssignCorporateID: 0,
      timeCardMasterFormValues: {},
      storeTimeCardAssignmentFormValues: {},
      corporateTimeCardAssignmentFormValues: {},
      isCorporate: "",
    };
  }

  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "mini" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };
  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    if (
      [ROUTES.EDIT_TIMECARD, ":timeCardID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });
      this.getTimeCard(this.props.location.state.timeCardID);
    } else if (
      [ROUTES.VIEW_TIMECARD, ":timeCardID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.setState({ isViewmode: true });
      this.getTimeCard(this.props.location.state.timeCardID);
    } else if (
      [ROUTES.VIEW_TIMESTOREASSIGNMENT, ":timeCardID"].join("/") ===
      this.props.match.path
    ) {
      this.getTimeCard(this.props.location.state.timeCardID);
      this.setState({ fullLoading: true });
      this.setState({ mode: "storeassignment" });
      this.setState({ activeStep: 2 });
    } else if (
      [ROUTES.VIEW_TIMECORPORATEASSIGNMENT, ":timeCardID"].join("/") ===
      this.props.match.path
    ) {
      this.getTimeCard(this.props.location.state.timeCardID);
      this.setState({ fullLoading: true });
      this.setState({ mode: "corporateassignment" });
      this.setState({ activeStep: 2 });
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
  }

  getTimeCard = (id) => {
    this.timecardservice.getTimeCardDetailByTimeCardID(id).then((response) => {
      if (
        response.statusCode === 200 &&
        response.data.timeCardID === parseInt(id)
      ) {
        this.setState({ timeCardID: response.data.timeCardID });
        this.setState({
          timeCardMasterFormValues: {
            timeCardName: response.data.timeCardName,
            isCorporate: response.data.isCorporate,
          },
        });
        if (response.data.isCorporate) {
          this.setState({ isCorporate: "Yes" });
        } else {
          this.setState({ isCorporate: "No" });
        }

        this.setState({ fullLoading: false });
        const prevStep = this.state.activeStep;
        this.setState({ activeStep: -1 });
        this.setState({ activeStep: prevStep });
      } else {
        notification["error"]({
          message: "Time Card",
          description: "Unable to get time card data",
          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
    });
  };

  /**
   * General Tab Form Submit Handler
   * Please go through https://ant.design/components/form/#API for more information
   *
   */

  timeCardMasterForm = React.createRef();
  storeTimeCardAssignmentForm = React.createRef();
  corporateTimeCardAssignmentForm = React.createRef();

  timeCardMasterFormValidated = false;
  storeTimeCardAssignmentFormValidated = false;
  corporateTimeCardAssignmentFormValidated = false;

  handleTimeCardMasterSubmit = () => {
    const timeCardMasterValidator = this.timeCardMasterForm.current.validateFields();

    Promise.allSettled([timeCardMasterValidator]).then((responses) => {
      if (responses[0].status === "rejected") {
        this.setState({ activeStep: 0 });
      } else if (responses[0].status === "fulfilled") {
        this.setState(
          {
            timeCardMasterFormValues: responses[0].value,
          },
          () => {
            this.handleSaveUpdatTimeCardMaster();
          }
        );
      }
    });
  };

  handleSaveUpdatTimeCardMaster = () => {
    const postSave = {
      timeCardID: this.state.timeCardID,
      timeCardName: this.state.timeCardMasterFormValues.timeCardName,
      isCorporate: this.state.timeCardMasterFormValues.isCorporate,
    };
    this.setState({ fullLoading: true });
    this.timecardservice.saveUpdateTimeCardMaster(postSave).then((response) => {
      if (response.statusCode === 200) {
        this.setState({ timeCardID: response.data.timeCardID });
        notification["success"]({
          message: "Time Card",
          description: response.message,
          duration: 1.5,
          onClose: () => {
            if (!this.state.timeCardMasterFormValues.isCorporate) {
              this.setState({ isCorporate: "No" });
              this.setState({ activeStep: 1 });
              // this.props.history.push(
              //   [ROUTES.VIEW_TIMESTOREASSIGNMENT, this.state.timeCardID].join("/")
              // );
            } else {
              this.setState({ isCorporate: "Yes" });
              this.setState({ activeStep: 1 });
              // this.props.history.push(
              //   [ROUTES.VIEW_TIMECORPORATEASSIGNMENT, this.state.timeCardID].join("/")
              // );
            }
          },
        });
        //this.setState({activeStep: 2 })
      } else {
        notification["error"]({
          message: "Time Card",
          message: response.message,
        });
      }
      this.setState({ fullLoading: false });
    });
  };

  handleStoreTimeCardAssignmentSubmit = () => {
    const storeTimeCardAssignmentValidator = this.storeTimeCardAssignmentForm.current.validateFields();

    Promise.allSettled([storeTimeCardAssignmentValidator]).then((responses) => {
      if (responses[0].status === "rejected") {
        this.setState({ activeStep: 2 });
      } else if (responses[0].status === "fulfilled") {
        this.setState(
          {
            storeTimeCardAssignmentFormValues: responses[0].value,
          },
          () => {
            //this.handleSaveUpdatStoreTimeCardAssignmentMaster();
          }
        );
      }
    });
  };

  handleCorporateTimeCardAssignmentSubmit = () => {
    const corporateTimeCardAssignmentValidator = this.corporateTimeCardAssignmentForm.current.validateFields();

    Promise.allSettled([corporateTimeCardAssignmentValidator]).then(
      (responses) => {
        if (responses[0].status === "rejected") {
          this.setState({ activeStep: 3 });
        } else if (responses[0].status === "fulfilled") {
          this.setState(
            {
              corporateTimeCardAssignmentFormValues: responses[0].value,
            },
            () => {
              //this.handleSaveUpdatCorporateTimeCardAssignmentMaster();
            }
          );
        }
      }
    );
  };

  render() {
    //const{data}=this.state;

    //const{storeMasterData,isEditmode,isViewmode,disabled}=this.props;
    return this.state.isAddTimecard ? (
      <TimeCardList backFunction={this.bringback} />
    ) : (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading || this.state.detailsLoading}>
          {/**
           * Page Header Component from Ant Design
           * Please check below link for more options
           * https://ant.design/components/page-header/
           * https://ant.design/components/page-header/#API
           *
           */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? "Edit"
                : this.state.mode === "view"
                ? "View"
                : "Add",
              "Time Card",
            ].join(" ")}
          />

          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              {/**
               * Steps Component from Ant Design
               * Please check below link for more options
               * https://ant.design/components/steps/
               * https://ant.design/components/steps/#API
               *
               */}
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => this.setState({ activeStep: current })}
              >
                <Step title="Time Card" description="Time Card Information" />
                <Step
                  title="Time Card Detail"
                  description="Detail Information"
                />
                {this.state.isCorporate === "No" ? (
                  <Step
                    title="Store Assignment"
                    description="Time Card Assignment"
                  />
                ) : null}
                {this.state.isCorporate === "Yes" ? (
                  <Step
                    title="Corporate Assignment"
                    description="Time Card Assignment"
                  />
                ) : null}
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider
                onFormFinish={(name, info) => {
                  if (name === "TimeCardMaster") {
                    this.handleTimeCardMasterSubmit(info.values);
                  }
                }}
              >
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading}>
                  <React.Fragment>
                    <TimeCardMaster
                      values={this.state.timeCardMasterFormValues}
                      isActive={this.state.activeStep === 0}
                      instance={this.timeCardMasterForm}
                      backFunction={() => this.props.history.goBack()}
                      mode={this.state.mode}
                    />

                    <TimeCardDetail
                      isActive={this.state.activeStep === 1}
                      timeCardID={this.state.timeCardID}
                      backFunction={() => this.props.history.goBack()}
                      toggleLoading={(value) =>
                        this.setState({ detailsLoading: value })
                      }
                      //   values={this.state.contactFormValues}
                      //   backFunction={() =>
                      //     this.setState({ activeStep: this.state.activeStep - 1 })
                      //   }
                      //   nextFunction={this.handleSubmit}
                      //   isActive={this.state.activeStep === 1}
                      //   instance={this.contactForm}
                      //   mode={this.state.mode}
                    />
                    {this.state.activeStep === 2 &&
                    this.state.isCorporate === "No" ? (
                      <StoreAssignment
                        mode={this.state.mode}
                        timeCardID={this.state.timeCardID}
                      />
                    ) : null}
                    {this.state.activeStep === 2 &&
                    this.state.isCorporate === "Yes" ? (
                      <CorporateAssignment
                        mode={this.state.mode}
                        timeCardID={this.state.timeCardID}
                      />
                    ) : null}
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(CreateTimeCard);
