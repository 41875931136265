import React from "react";
import styles from "../../Common/Styles/master.module.css";
import StickerStyles from "../../Common/Styles/ResortingAndPackagingSticker.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  // Popconfirm,
  Table,
  notification,
  Tooltip,
} from "antd";
import ReactToPrint from "react-to-print";
import Box from "../../Common/Box/box";
import Logo from "../../../images/Common/Logo.png";
var Barcode = require("react-barcode");

export default class StikerPrint extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const hh = this.props.printInfo;
    return (
      <div>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <a href="#">Print this out!</a>;
          }}
          content={() => this.componentRef}
          pnintInfo={this.props}
        />
        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          printInfo={this.props.printInfo}
          stickerID={this.props.stickerID}
          customerID={this.props.customerID}
        />
      </div>
    );
  }
}

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var rows = [];
    var numrows = this.props.printInfo.lstStickerInfoToPrintGarmentData;
    numrows = numrows.slice(Math.max(numrows.length - 4, 0)); // To get last 4 records from the array
    // const hh = this.props
    const data = this.props.printInfo.lstStickerInfoToPrintStickerData.map(
      (element, index) => {
        return (
          // <div style={{ width: '188px', height: '302px', 'border-style': 'dotted', 'margin-top': '0mm', 'margin-bottom': '0px', 'margin-right': '5mm', 'margin-left': '5mm' }}>
          <div style={{ maxHeight: '217px', minHeight: '188px', width: '302px' }}>
            <div style={{
              backgroundc: "#fff",
              minHeight: "200px",
              maxHeight: "217px",
              padding:"5px"
              
            }}>
              <div style={{position:"inherit",top:"0.19in",left:"0.74in",textAlign:"center",
              // lineHeight:"0.6in"
              // ,marginBottom:"5px"
              }} >
                <span style={{fontSize:"21pt", color:"#000000",fontWeight:"600"}} className="ff2">{element.storeName}</span>
                <br/><br/>
              </div>
              <div style={{float:"left",width:"65%"}}>
                <div style={{position:"inherit",top:"0.60in",left:"0.41in",width:"1.70in",textAlign:"center"}}>
                  <span style={{fontSize:"15pt", color:"#000000"}} className="ff1">{element.customerName}</span>
                  <br/>
                </div>
                <div style={{position:"inherit",top:"0.70in",left:"0.41in",maxWidth:"1.70in",width:"1.70in"}}>
                    <Barcode format={"CODE128"} value={element.stickerID} width={2.0} height={30} />
                </div>
                <div style={{position:"inherit",top:"1.44in",left:"0.41in",width:"1.70in",textAlign:"center"}}>
                  <span style={{fontSize:"15pt", color:"#000000"}} className="ff1">Order Size: {element.noOfGarment}</span>
                </div>
              </div>
              <div style={{float:"left",width:"30%"}}>
                <div style={{position:"inherit",top:"0.62in",left:"2.26in",width:"0.91in",lineHeight:"0.27in",marginBottom:"10px"}}>
                  <span style={{fontSize:"15pt", color:"#000000"}} className="ff2">{element.splitOrderNumber}</span>
                  <br/>
                </div>
                  <div style={{position:"inherit",top:"0.93in",left:"2.25in",lineHeight:"0.20in"}}>
                  {numrows.map(x => {
                    return (
                      <React.Fragment>
                      <span style={{fontSize:"10pt", color:"#000000",float:"left"}} className="ff1">{x.packingMaterialName}</span>
                      <span style={{fontSize:"10pt", color:"#000000",float:"right"}} className="ff1">
                        {x.count ? x.count : 0} / {x.previousCount ? x.previousCount : 0}
                      </span>
                      <br/>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      }
    );

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {/* //repeat this in case of multiple stickers */}
        {data}
      </div>
    );
  }
}

class Example extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const hh = this.props;
    return (
      <div>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return <a href="#">Print this out!</a>;
          }}
          content={() => this.componentRef}
          pnintInfo={this.props}
          pageStyle="@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }"
        />
        <ComponentToPrint ref={(el) => (this.componentRef = el)} />
      </div>
    );
  }
}
