import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../../Common/Styles/master.module.css";
import { Steps, PageHeader, Form, notification, Spin } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import GeneralForm from "./general.addcorporate";
import ContactForm from "./contact.addcorporate";
import FactoryService from "../../../../services/factoryservice";
import ROUTES from "../../../../routes";
import CorporateService from "../../../../services/corporateservice";
const { Step } = Steps;

/**
 * Add Factory Form as a part of Factory Master Module
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 *
 * @module Master
 */
class AddFactory extends React.Component {
  factoryService = new FactoryService();
  corporateService = new CorporateService();
  /**
   * State Variables
   * @var factoryID : ID of factory 0 if this is to add factory
   * @var activeStep : holds active form step
   * @var stepDirection : holds direction of steps horizontal/vertical
   * @var stepSize : size of steps
   * @var generalFormValues : values submitted through generalForm
   * @var contactFormValues : values submitted through contactForm
   */
  state = {
    factoryID: 0,
    activeStep: 0,
    fullLoading: false,
    stepsDirection: "vertical",
    stepsSize: "default",
    generalFormValues: {},
    contactFormValues: {},
    mode: "add",
  };
  /**
   * A function to responsively set direction of the steps
   */
  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "small" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };

  /**
   * bind the setStepDirection to the resize event of windows
   */
  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    if (
      [ROUTES.EDIT_CORPORATE, ":corporateID"].join("/") ===
      this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });
      this.getCorporate(this.props.location.state.corporateID);
    } else if (
      [ROUTES.VIEW_CORPORATE, ":corporateID"].join("/") ===
      this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.getCorporate(this.props.location.state.corporateID);
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
  }

  getCorporate = (id) => {
    this.corporateService.getCorporate(id).then((response) => {
      if (
        response.statusCode === 200 &&
        response.data.corporateID === parseInt(id)
      ) {
        this.setState({ corporateID: response.data.corporateID });
        this.setState({
          generalFormValues: {
            corporateID: response.data.corporateID,
            corporateName: response.data.corporateName,
            gstNo: response.data.gstNo,
            poNo: response.data.poNo,
            poValidity: response.data.poValidity
              ? moment(response.data.poValidity, "M/DD/YYYY hh:mm:ss A")
              : "",
            sez: response.data.sez,
            billingCycle: response.data.billingCycleID.toString(),
            paymentTerms: response.data.paymentTerms,
            isDeliveryCharge: response.data.isDeliveryCharge,
            pickupFrequency: response.data.pickupFrequencyID.toString(),
          },
        });
        this.setState({
          contactFormValues: {
            billingAddress: response.data.billingAddress,
            serviceAddress: response.data.serviceAddress,
            emailID: response.data.emailID,
            accManager: response.data.accManager,
            accManagerContact: response.data.accManagerContact,
          },
        });
        this.setState({ fullLoading: false });
        this.setState({ activeStep: -1 });
        this.setState({ activeStep: 0 });
      } else {
        notification["error"]({
          message: "Corporate Master",
          description: response.message
            ? response.message
            : "Unable to get corporate data",

          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
    });
  };
  addUpdateCorporate = () => {
    this.setState({ fullLoading: true });
    const postData = {
      corporateID: this.state.corporateID ? this.state.corporateID : 0,
      corporateName: this.state.generalFormValues.corporateName
        ? this.state.generalFormValues.corporateName
        : null,
      billingAddress: this.state.contactFormValues.billingAddress
        ? this.state.contactFormValues.billingAddress
        : null,
      serviceAddress: this.state.contactFormValues.serviceAddress
        ? this.state.contactFormValues.serviceAddress
        : null,
      gstNo: this.state.generalFormValues.gstNo
        ? this.state.generalFormValues.gstNo
        : null,
      poNo: this.state.generalFormValues.poNo
        ? this.state.generalFormValues.poNo
        : null,
      poValidity: this.state.generalFormValues.poValidity
        ? this.state.generalFormValues.poValidity.format("YYYY-MM-DD")
        : null,
      sez: this.state.generalFormValues.sez
        ? this.state.generalFormValues.sez
        : null,
      billingCycleID: this.state.generalFormValues.billingCycle
        ? parseInt(this.state.generalFormValues.billingCycle)
        : null,
      paymentTerms: this.state.generalFormValues.paymentTerms
        ? this.state.generalFormValues.paymentTerms
        : null,
      emailID: this.state.contactFormValues.emailID
        ? this.state.contactFormValues.emailID
        : null,
      accManager: this.state.contactFormValues.accManager
        ? this.state.contactFormValues.accManager
        : null,
      accManagerContact: this.state.contactFormValues.accManagerContact
        ? this.state.contactFormValues.accManagerContact
        : null,
      isDeliveryCharge: ![null, undefined, ""].includes(
        this.state.generalFormValues.isDeliveryCharge
      )
        ? this.state.generalFormValues.isDeliveryCharge
        : null,
      pickupFrequency: this.state.generalFormValues.pickupFrequency
        ? parseInt(this.state.generalFormValues.pickupFrequency)
        : null,
    };
    this.corporateService.addUpdateCorporate(postData).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: "Corporate Master",
          description: response.message,
          duration: 1.5,
          onClose: () => {
            this.props.history.goBack();
          },
        });
      } else {
        notification["error"]({
          message: "Corporate Master",
          description: response.message
            ? response.message
            : "Unable to submit the form",
        });
      }
      this.setState({ fullLoading: false });
    });
  };
  generalForm = React.createRef();
  contactForm = React.createRef();
  handleSubmit = () => {
    const generalValidator = this.generalForm.current.validateFields();
    const contactValidator = this.contactForm.current.validateFields();
    Promise.allSettled([generalValidator, contactValidator]).then(
      (responses) => {
        if (responses[0].status === "rejected") {
          this.setState({ activeStep: 0 });
        } else if (responses[1].status === "rejected") {
          this.setState({ activeStep: 1 });
        } else if (
          responses[0].status === "fulfilled" &&
          responses[1].status === "fulfilled"
        ) {
          this.setState(
            {
              generalFormValues: responses[0].value,
              contactFormValues: responses[1].value,
            },
            () => {
              this.addUpdateCorporate();
            }
          );
        }
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          {/* {JSON.stringify(this.state)} */}
          {/**
           * Page Header Component from Ant Design
           * Please check below link for more options
           * https://ant.design/components/page-header/
           * https://ant.design/components/page-header/#API
           *
           */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? "Edit"
                : this.state.mode === "view"
                ? "View"
                : "Add",
              "Corporate",
            ].join(" ")}
          />

          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              {/**
               * Steps Component from Ant Design
               * Please check below link for more options
               * https://ant.design/components/steps/
               * https://ant.design/components/steps/#API
               *
               */}
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => this.setState({ activeStep: current })}
              >
                <Step
                  title="General"
                  description="General Corporate Information"
                />
                <Step
                  title="Contact"
                  description="Corporate Contact Information"
                />
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider>
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading}>
                  <React.Fragment>
                    <GeneralForm
                      values={this.state.generalFormValues}
                      backFunction={() => this.props.history.goBack()}
                      nextFunction={() => this.setState({ activeStep: 1 })}
                      isActive={this.state.activeStep === 0}
                      instance={this.generalForm}
                      mode={this.state.mode}
                    />
                    <ContactForm
                      values={this.state.contactFormValues}
                      backFunction={() =>
                        this.setState({ activeStep: this.state.activeStep - 1 })
                      }
                      nextFunction={this.handleSubmit}
                      isActive={this.state.activeStep === 1}
                      instance={this.contactForm}
                      mode={this.state.mode}
                    />
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(AddFactory);
