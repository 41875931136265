import {
  notification,
  Row,
  Col,
  Typography,
  Modal,
  Form,
  Select,
  Input,
  Space,
  Spin,
} from "antd";
import React from "react";
import PackageConfigService from "../../../services/packageconfigurationservice";
import InvoiceService from "../../../services/invoiceservice";
import ReadyForDeliveryOrderService from "../../../services/storereadyfordeliverysplitorderservice";
import styles from "./buypackage.module.css";
import Button from "../../../components/Common/Button/button";
import PaytmTransService from "../../../services/PaytmTransService";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";

const { Option } = Select;
const confirm = Modal.confirm;

export default class BuyPackage extends React.Component {
  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();
  state = {
    modalLoading: false,
    loading: false,
    packages: [],
    selectedPackage: null,
    selectedPackageAmount: 0,
    selectedPackageName: null,
    paymentMethods: {
      data: [],
      disabled: true,
      selected: 1,
    },
    showBuyModal: false,
    customerName: {
      //  disabled: true,
      data: [],
      selected: null,
    },
    isTransFail: false,
    showAddPopup: false,
    isConfirmPayment: false,
    isResponseErrorShow: false,
    isShowQRCode: false,
    base64Image: "",
    isQRCodeShow: false
  };
  service = new PackageConfigService();
  invoiceService = new InvoiceService();
  readyForDeliveryOrderService = new ReadyForDeliveryOrderService();
  paytmTransService = new PaytmTransService();
  componentDidMount() {
    this.setState({ isResponseErrorShow: false });
    this.getPackages();
    this.getPaymentMethods();
  }
  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.BUY_PACKAGE
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({
      userRights: res,
      isConfirmPayment: false,
      showAddPopup: false,
    });
  }
  getPaymentMethods = () => {
    this.invoiceService.getPaymentMethods().then((response) => {
      if (response.statusCode === 200) {
        let data = response.data.map((single) => {
          return {
            value: parseInt(single.enumDetailID),
            label: single.enumDetailName,
          };
        });
        this.setState({
          paymentMethods: {
            ...this.state.paymentMethods,
            data: data,
            disabled: false,
          },
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: labels.BUY_PACKAGE,
            description: response.message
              ? response.message
              : labels.UNABLE_TO_GET_PAYMENT_METHOD_DATA,
          });
        }
      }
    });
  };
  getPackages = () => {
    this.setState({ loading: true });
    this.service
      .retrievePackageConfiguration({
        packageName: null,
        pageIndex: 1,
        pageSize: 10,
        sortColumn: null,
        sortOrder: null,
        commonSearch: null,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({ packages: response.data });
        } else {
          if (!this.state.isResponseErrorShow) {
            notification["error"]({
              message: labels.BUY_PACKAGE,
              description: response.message
                ? this.common.Message(response.message)
                : labels.UNABLE_TO_LOAD_PACKAGES,
            });
            this.setState({
              isResponseErrorShow: true,
            });
          }
        }
        this.setState({ loading: false });
      });
  };
  searchCustomer = (value) => {
    if (value !== "" && value !== null && value !== undefined) {
      this.readyForDeliveryOrderService
        .searchCustomer(value)
        .then((response) => {
          if (response.statusCode === 200) {
            this.setState({
              customerName: {
                ...this.state.customerName,
                data: response.data,
              },
            });
          } else {
            notification["error"]({
              message: labels.BUY_PACKAGE,
              description: response.message
                ? response.message
                : labels.UNABLE_TO_GET_LIST_OF_CUSTOMERS,
            });
          }
        });
    }
  };
  buyPackageRemoreRequest = (values) => {
    // console.log("values->",values);
    if (this.state.paymentMethods.selected == 3) {
      this.EDCRequest(values);
    }

    else if ((this.state.paymentMethods.selected == 4)) {
      this.QRCodeRequest(values);
      // this.DynamicQRCodeRequest();
    }
    else {
      this.buyPackage(values);
    }
  };

  EDCRequest = (values) => {
    this.setState({
      isTransFail: false,
    });
    let transID = this.common.PaytmTransNumber(
      this.state.customerName.selected.toString(),
      "Package Payment"
    );
    this.setState({ modalLoading: true, transID: transID });
    this.paytmTransService.getEDCMachineInfo().then((response) => {
      if (response.statusCode == 200) {
        if ([undefined, "", null].includes(response.data.machineKey)) {
          notification["error"]({
            message: labels.BUY_PACKAGE,
            description: labels.EDC_CONFIGURATION,
          });
          this.setState({ modalLoading: false });
          return false;
        }

        this.setState({ showAddPopup: true, EDCmachineInfo: response.data });
        var postdata = {
          paytmMid: response.data.paytmMid,
          paytmTid: response.data.paytmTid,
          transactionDateTime: "",
          merchantTransactionId: "",
          merchantReferenceNo: "",
          transactionAmount: (
            this.state.selectedPackageAmount * 100
          ).toString(),
          callbackUrl: "",
          machineKey: response.data.machineKey,
          json: 0,
          response: "",
          transactionType: 0,
          requestParams: "",
          createdBy: 0,
          status: "",
          id: this.state.customerName.selected.toString(), //this.state.invoiceData.invoiceID,
          callFrom: "buy-package",
          platform: "web",
          transctionId: transID,
          versionNumber: response.data.versionNumber,
        };
        this.setState({ modalLoading: true });
        this.paytmTransService
          .EDCMachineRequest(postdata)
          .then((response) => {
            if (response.statusCode == 200) {
              var res = JSON.parse(response.data.response);
              if (res.body.resultInfo.resultStatus == "ACCEPTED_SUCCESS") {
                postdata.merchantTransactionId = this.state.transID;
                postdata.transactionDateTime =
                  response.data.transactionDateTime;
                postdata.transactionType = 1;
                const intervalID = setInterval(() => {
                  this.setState({ modalLoading: true });
                  this.paytmTransService
                    .EDCMachineStatus(postdata)
                    .then((response) => {
                      if (response.statusCode == 200) {
                        var res = JSON.parse(response.data.response);
                        if (res.body.resultInfo.resultStatus == "SUCCESS") {
                          notification["success"]({
                            message: labels.BUY_PACKAGE,
                            description:
                              labels.TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED,
                          });
                          setTimeout(() => {
                            this.buyPackage(values);
                            this.setState({
                              // transID: postdata.merchantTransactionId,
                              showAddPopup: false,
                              modalLoading: false,
                            });
                          }, 1000);
                          clearInterval(intervalID);
                          this.setState({ modalLoading: false });
                        } else {
                          if (res.body.resultInfo.resultStatus === "FAIL") {
                            clearInterval(intervalID);
                            this.setState({
                              showAddPopup: false,
                              isTransFail: true,
                              modalLoading: false,
                            });
                            notification["error"]({
                              message: labels.BUY_PACKAGE,
                              description:
                                labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                            });
                          } else {
                            setTimeout(() => {
                              clearInterval(intervalID);
                              this.setState({
                                showAddPopup: false,
                                modalLoading: false,
                              });
                              // if (
                              //   (res.body.resultInfo.resultStatus == "FAIL" ||
                              //     res.body.resultInfo.resultStatus ==
                              //       "PANDING") &&
                              //   this.state.isTransFail == false
                              // ) {
                              notification["error"]({
                                message: labels.BUY_PACKAGE,
                                description:
                                  labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                              });
                              // }
                            }, 360000);
                          }
                        }
                      }
                    });
                }, 10000);
              } else {
                this.setState({ showAddPopup: false, modalLoading: false });
                notification["error"]({
                  message: labels.BUY_PACKAGE,
                  description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                });
              }
            } else {
              this.setState({ showAddPopup: false, modalLoading: false });
              notification["error"]({
                message: labels.BUY_PACKAGE,
                description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
              });
            }
            this.setState({ modalLoading: false });
          });
      } else {
        this.setState({ showAddPopup: false, modalLoading: false });
        notification["error"]({
          message: labels.BUY_PACKAGE,
          description: labels.UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM,
        });
      }
      this.setState({ modalLoading: true });
    });
  }

  QRCodeRequest = (values) => {

    this.setState({
      isTransFail: false,
    });
    let transID = this.common.PaytmTransNumber(
      this.state.customerName.selected.toString(),
      "Package Payment"
    );
    this.setState({ modalLoading: true, transID: transID });
    // this.paytmTransService.getEDCMachineInfo().then((response) => {
    //   if (response.statusCode == 200) {
    // if ([undefined, "", null].includes(response.data.machineKey)) {
    //   notification["error"]({
    //     message: labels.BUY_PACKAGE,
    //     description: labels.EDC_CONFIGURATION,
    //   });
    //   this.setState({ modalLoading: false });
    //   return false;
    // }

    // this.setState({ showAddPopup: true, EDCmachineInfo:  response.data });
    // this.setState({ showAddPopup: true });
    var postdata = {
      mid: "",
      orderId: transID,
      amount: (
        this.state.selectedPackageAmount
      ).toString(),
      posId: localStorage.getItem("branchID"),
      clientId: "",
      channelId: "",
      version: "",
      id: this.state.customerName.selected.toString(), //this.state.invoiceData.invoiceID,
      callFrom: "buy-package",
      platform: "web-QR",
      versionNumber: "",
      transactionType: 0,
      response: "",
      createdBy: 0,
    };
    this.setState({ modalLoading: true });
    this.paytmTransService
      .QRCodeRequest(postdata)
      .then((response) => {
        if (response.statusCode == 200) {
          var res = JSON.parse(response.data.qrCodeResponse);
          if (res.body.resultInfo.resultStatus == "SUCCESS") {
            this.setState({ isShowQRCode: true, base64Image: res.body.image, isQRCodeShow: true })
            postdata.transactionType = 1;
            const intervalID = setInterval(() => {
              this.paytmTransService
                .QRCodeStatusCheck(postdata)
                .then((response) => {
                  if (response.statusCode == 200) {
                    var res = JSON.parse(response.data.qrCodeResponse);
                    if (res.body.resultInfo.resultStatus == "TXN_SUCCESS") {
                      notification["success"]({
                        message: labels.BUY_PACKAGE,
                        description:
                          labels.TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED,
                      });
                      setTimeout(() => {
                        this.buyPackage(values);
                        this.setState({
                          // transID: postdata.merchantTransactionId,
                          showAddPopup: false,
                          modalLoading: false,
                          isQRCodeShow: false
                        });
                      }, 1000);
                      clearInterval(intervalID);
                      this.setState({ modalLoading: false });
                    } else {
                      if (res.body.resultInfo.resultStatus === "TXN_FAILURE") {
                        clearInterval(intervalID);
                        this.setState({
                          showAddPopup: false,
                          isTransFail: true,
                          modalLoading: false,
                          isQRCodeShow: false
                        });
                        notification["error"]({
                          message: labels.BUY_PACKAGE,
                          description:
                            labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                        });
                      } else {
                        // debugger;

                      }
                    }
                  }
                });
            }, 15000);
            setTimeout(() => {
              clearInterval(intervalID);
              this.setState({
                showAddPopup: false,
                modalLoading: false,
                isQRCodeShow: false
              });
              // if (
              //   (res.body.resultInfo.resultStatus == "FAIL" ||
              //     res.body.resultInfo.resultStatus ==
              //       "PANDING") &&
              //   this.state.isTransFail == false
              // ) {
              notification["error"]({
                message: labels.BUY_PACKAGE,
                description:
                  labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
              });
              // }
            }, 360000);
          } else {
            this.setState({ showAddPopup: false, modalLoading: false });
            notification["error"]({
              message: labels.BUY_PACKAGE,
              description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
            });
          }
        } else {
          this.setState({ showAddPopup: false, modalLoading: false });
          notification["error"]({
            message: labels.BUY_PACKAGE,
            description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
          });
        }
        this.setState({ modalLoading: false });
      });

    // } else {
    //   this.setState({ showAddPopup: false, modalLoading: false });
    //   notification["error"]({
    //     message: labels.BUY_PACKAGE,
    //     description: labels.UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM,
    //   });
    // }
    this.setState({ modalLoading: true });
    //});
  }

  DynamicQRCodeRequest=()=>{
    // this.setState({ showAddPopup: true });
    let transID = this.common.PaytmTransNumber(
      this.state.customerName.selected.toString(),
      "Package Payment"
    );
    var postdata = {
      mid: "",
      orderId: transID,
      amount: (
        this.state.selectedPackageAmount
      ).toString(),
      posId: localStorage.getItem("branchID"),
      clientId: "",
      channelId: "",
      version: "",
      id: this.state.customerName.selected.toString(), //this.state.invoiceData.invoiceID,
      callFrom: "buy-package",
      platform: "web-QR",
      versionNumber: "",
      transactionType: 0,
      response: "",
      createdBy: 0,
    };
    this.paytmTransService
    .DynamicQRCodeRequest(postdata)
    .then((response) => {
      if (response.statusCode == 200) {
        // console.log("response->",response);
         var res = JSON.parse(response.data.dynamicQRCodeResponse);
        if (res.body.resultInfo.resultStatus == "SUCCESS") {
          this.setState({ isShowQRCode: true, base64Image: res.body.image, isQRCodeShow: true })
          postdata.transactionType = 1;
          const intervalID = setInterval(() => {
            this.paytmTransService
              .QRCodeStatusCheck(postdata)
              .then((response) => {
                if (response.statusCode == 200) {
                  var res = JSON.parse(response.data.qrCodeResponse);
                  if (res.body.resultInfo.resultStatus == "TXN_SUCCESS") {
                    notification["success"]({
                      message: labels.BUY_PACKAGE,
                      description:
                        labels.TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED,
                    });
                    setTimeout(() => {
                      // this.buyPackage(values);
                      this.setState({
                        // transID: postdata.merchantTransactionId,
                        showAddPopup: false,
                        modalLoading: false,
                        isQRCodeShow: false
                      });
                    }, 1000);
                    clearInterval(intervalID);
                    this.setState({ modalLoading: false });
                  } else {
                    if (res.body.resultInfo.resultStatus === "TXN_FAILURE") {
                      clearInterval(intervalID);
                      this.setState({
                        showAddPopup: false,
                        isTransFail: true,
                        modalLoading: false,
                        isQRCodeShow: false
                      });
                      notification["error"]({
                        message: labels.BUY_PACKAGE,
                        description:
                          labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                      });
                    } else {
                      // debugger;

                    }
                  }
                }
              });
          }, 15000);
          setTimeout(() => {
            clearInterval(intervalID);
            this.setState({
              showAddPopup: false,
              modalLoading: false,
              isQRCodeShow: false
            });
            
            notification["error"]({
              message: labels.BUY_PACKAGE,
              description:
                labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
            });
            // }
          }, 360000);
        } else {
          this.setState({ showAddPopup: false, modalLoading: false });
          notification["error"]({
            message: labels.BUY_PACKAGE,
            description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
          });
        }
      } else {
        this.setState({ showAddPopup: false, modalLoading: false });
        notification["error"]({
          message: labels.BUY_PACKAGE,
          description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
        });
      }
      this.setState({ modalLoading: false });
    });

  }

  buyPackage = (values) => {
    this.setState({ modalLoading: true });
    this.service
      .buyPackage({
        customerID: parseInt(this.state.customerName.selected),
        lkPackageID: parseInt(this.state.selectedPackage),
        paymentMode: parseInt(this.state.paymentMethods.selected),
        chequeNo: null,
        chequeDate: null,
        cardNo: null,
        transactionNo: this.state.transID,
        remark: ![null, undefined, ""].includes(values.remarks)
          ? values.remarks
          : "Buy Package From Web Portal",
        storeID: localStorage.getItem("branchID"),
        sourceFrom: 1,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: labels.BUY_PACKAGE,
            description: response.message
              ? this.common.Message(response.message)
              : labels.PACKAGE_PURCHASED_SUCCESSFULLY,
          });
          this.setState({ showBuyModal: false });
        } else {
          notification["error"]({
            message: labels.BUY_PACKAGE,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_BUY_PACKAGE,
          });
        }
        this.setState({ modalLoading: false });
      });
    this.setState({
      paymentMethods: { ...this.state.paymentMethods, selected: 1 },
    });
  };

  function = (showConfirm) => {
    confirm({
      title: "Do you want to delete these items?",
      content:
        "When clicked the OK button, this dialog will be closed after 1 second",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() { },
    });
  };

  handlePopupCancel = () => {
    confirm({
      title: "Do you want to kill these payment process?",
      content: "When clicked the OK button, Stop the payment process",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 3000);
          setTimeout(function () {
            window.location.reload();
          }, 4000);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() { },
    });
  };

  render() {
    const colors = [
      "#FF8EB1",
      "#FCC350",
      "#6ABEFF",
      "#7AE473",
      "#FF6A6A",
      "#8F56F3",
      "#E162E5",
      "#B2C12C",
      "#3C76EB",
    ];

    const { isShowQRCode, base64Image, isQRCodeShow } = this.state
    return (
      <Spin spinning={this.state.loading}>

        {isShowQRCode ?
          <Modal
            title={"QR Code"}
            visible={isQRCodeShow}
            footer={null}
            onCancel={this.handlePopupCancel}
            className="modal-form"
            destroyOnClose
          >
            <Spin spinning={false}>
              <Row gutter={24}>
                <Col xs={24}>
                  <div style= {{marginLeft : "7%"}}>
                    <img src={"data:image/jpeg;base64," + base64Image} alt  style = {{width : 400}}  />
                  </div>
                </Col>
              </Row>
            </Spin>
          </Modal>

          : null}

        <Row gutter={[30, 30]}>
          {this.state.packages.map((single, index) => {
            if (single.isActive) {
              return (
                <Col xs={8} key={single.lkPackageID}>
                  <div
                    style={{ backgroundColor: colors[index % colors.length] }}
                    className={styles.packageWrapper}
                  >
                    <div>
                      <Typography.Title level={3} style={{ margin: 0 }}>
                        {single.packageName}
                      </Typography.Title>
                      <Typography>{single.descriptionTitle}</Typography>
                      <Typography.Title level={2}>
                        ₹{single.packageAmount}
                        <br />
                        <div className={styles.bonus}>
                          {" "}
                          + ₹{single.bonusAmount} (Bonus : Valid for{" "}
                          {single.bonusAmountValidityInDays} days)
                        </div>
                      </Typography.Title>
                      <div className={styles.desc}>{single.description}</div>
                    </div>
                    <div>
                      <Button
                        variant="whitePink"
                        size="large"
                        block
                        className={styles.buyButton}
                        onClick={() => {
                          this.setState({
                            showBuyModal: true,
                            selectedPackage: single.lkPackageID,
                            selectedPackageAmount: single.packageAmount,
                            selectedPackageName: single.packageName,
                          });
                        }}
                      >
                        {labels.BUY}
                      </Button>
                    </div>
                  </div>
                </Col>
              );
            } else {
              return null;
            }
          })}
        </Row>
        <Modal
          title={labels.BUY_PACKAGE}
          visible={this.state.showBuyModal}
          footer={null}
          onCancel={() => this.setState({ showBuyModal: false })}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.buyPackageRemoreRequest}
              initialValues={{ paymentMode: 1, amount: 0 }}
            >
              <Row gutter={21}>
                <Col xs={8}>
                  <div style={{ marginBottom: 20 }}>
                    <h2 style={{ margin: 0 }}>
                      {this.state.selectedPackageName}
                    </h2>
                    ₹ {this.state.selectedPackageAmount}
                  </div>
                </Col>
                <Col xs={16}>
                  <Form.Item
                    name="customer"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_CUSTOMER,
                      },
                    ]}
                  >
                    <Select
                      onSearch={this.searchCustomer}
                      placeholder={labels.SEARCH_CUSTOMER}
                      showSearch
                      allowClear
                      onChange={(value) => {
                        this.setState({
                          customerName: {
                            ...this.state.customerName,
                            selected: value,
                          },
                        });
                      }}
                      filterOption={false}
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    //   0
                    // }
                    >
                      {this.state.customerName.data.length !== 0
                        ? this.state.customerName.data.map(
                          (singleCustomerName) => {
                            return singleCustomerName.customerID !== "-1" ? (
                              <Option
                                value={singleCustomerName.customerID}
                                key={singleCustomerName.customerID}
                              >
                                {singleCustomerName.customerName +
                                  " (" +
                                  singleCustomerName.mobileNo +
                                  ")"}
                              </Option>
                            ) : null;
                          }
                        )
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item name="paymentMode" label={labels.PAYMENT_METHOD}>
                    <Select
                      placeholder={labels.PAYMENT_METHOD}
                      disabled={this.state.paymentMethods.disabled}
                      options={this.state.paymentMethods.data.filter(
                        (x) => x.label.toLowerCase() !== "lk wallet"
                      )}
                      value={this.state.paymentMethods.selected}
                      onChange={(value) =>
                        this.setState({
                          paymentMethods: {
                            ...this.state.paymentMethods,
                            selected: value,
                          },
                        })
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={16}>
                  <Form.Item name="remarks" label={labels.REMARKS}>
                    <Input.TextArea
                      placeholder={labels.REMARKS}
                      style={{ height: 115 }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    <React.Fragment>
                      <Button
                        variant="transparentGreyOutline"
                        onClick={() => this.setState({ showBuyModal: false })}
                      >
                        {labels.CANCEL}
                      </Button>
                      <Button htmlType="submit" variant="purple">
                        {labels.COLLECT}
                      </Button>
                    </React.Fragment>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        <Modal
          title={labels.PROCESSING}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          maskClosable={false}
        >
          <Form layout="vertical" onFinish={this.handlePopupAdd}>
            <Row gutter={24}>
              <Col xs={24}>{labels.PROCESSING}.....</Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title="Modal"
          visible={this.state.isConfirmPayment}
          onOk={this.hideModal}
          onCancel={this.hideModal}
          okText="Ok"
          cancelText="Cancle"
        >
          <p>Are you sure close this? Because of payment is in progress</p>
        </Modal>

      </Spin>
    );
  }

  // handlePopupCancel = () => {
  //   this.setState({ isConfirmPayment: false, showAddPopup: false });
  // };
}
