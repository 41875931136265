import React from "react";
import styles from "../../../components/Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
// import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  // Popconfirm,
  Table,
  // Modal,
  // notification,
  DatePicker,
  Tooltip,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  // faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link, withRouter } from "react-router-dom";
import ROUTES from "../../../routes";
// import PreQCResponseList from "../../../services/storepreqcresponselistservice";
import FactoryPostQC from "../../../services/factorypost-qcservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
import Moment from "moment";
import Common from "../../../services/common";
const { Search } = Input;
const { Option } = Select;
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);

class Master extends React.Component {
  constructor(props) {
    super(props);
    // this.PreQCResponseListService = new PreQCResponseList();
    this.factoryPostQC = new FactoryPostQC();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();

    this.state = {
      orderFromDate: Moment(ONE_MONTHS_AGO),
      orderToDate: Moment(),
      preQCResponseItems: [],
      ID: 0,
      listData: [],
      QCStatusOptions: [],
      data: [],
      qcStatusFilter: 2,
      sorter: {
        field: null,
        order: null,
      },
      pagination: {
        showSizeChanger: true,
        // pageSizeOptions: [1, 2, 3, 4],
        current: 1,
        pageSize: 10,
        onChange: (page, pageSize) => {
          this.setState({
            // tableLoading: true,
            pagination: {
              ...this.state.pagination,
              current: page,
              pageSize: pageSize,
            },
          });
        },
        onShowSizeChange: (current, size) => {
          this.setState({
            // tableLoading: true,
            pagination: {
              ...this.state.pagination,
              current: current,
              pageSize: size,
            },
          });
        },
      },
      showfilterBox: true,
      loading: false,
    };
  }

  columns = [
    {
      title: labels.TAG,
      dataIndex: "tagNo",
      key: "tagNo",
      sorter: true,
    },
    {
      title: labels.CUSTOMER,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: labels.CONTACT,
      dataIndex: "mobileNo",
      key: "mobileNo",
      sorter: true,
    },
    {
      title: labels.SRORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
    // {
    //   title: "Gate Pass No",
    //   dataIndex: "gatePassNo",
    //   key: "gatePassNo",
    //   sorter: true,
    // },
    {
      title: labels.POST_QC_STATUS,
      dataIndex: "postQCStatusName",
      key: "postQCStatusName",
      sorter: true,
    },
    {
      title: labels.POST_QC_REMARK,
      dataIndex: "postQCRemark",
      key: "postQCRemark",
      sorter: true,
      //   width: "10%",
      //   ellipsis: {
      //     showTitle: false,
      //   },
      //   render: (address) => (
      //     <Tooltip placement="topLeft" title={address}>
      //       {address}
      //     </Tooltip>
      //   ),
    },
    {
      title: labels.POST_QC_DATETIME,
      dataIndex: "postQCDateTime",
      key: "postQCDateTime",
      render: (text) =>
        text !== null ? Moment(text).format("DD-MM-YYYY HH:mm:ss") : null,
      sorter: true,
    },
    {
      title: labels.POSTED_BY,
      dataIndex: "postQCByName",
      key: "postQCByName",
      sorter: true,
    },
    {
      title: labels.GARMENT_STATUS,
      dataIndex: "garmentStatusName",
      key: "garmentStatusName",
      sorter: true,
    },
    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title={labels.VIEW}>
              <Link
                to={{
                  pathname: [ROUTES.VIEW_POST_QC_FACTORY, record.tagNo].join(
                    "/"
                  ),
                  state: { tagNo: record.tagNo },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {record.postQCStatus === 1 &&
          [4, 6].includes(record.garmentStatus) ? (
            <React.Fragment>
              {this.state.userRights.length > 0 &&
              this.state.userRights[0].isUpdate == true ? (
                <Tooltip title={labels.EDIT}>
                  <Link
                    to={{
                      pathname: [
                        ROUTES.EDIT_POST_QC_FACTORY,
                        record.tagNo,
                      ].join("/"),
                      state: { tagNo: record.tagNo },
                    }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Link>
                </Tooltip>
              ) : null}
            </React.Fragment>
          ) : null}
        </Space>
      ),
    },
  ];

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.FACTORY_POST_QC
    );
    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
  }

  componentDidMount() {
    this.getResponseList();
  }
  getResponseList = () => {
    this.setState({ loading: true });
    if (this.props.toggleLoading !== undefined) {
      this.props.toggleLoading(true);
    }
    this.factoryPostQC
      .getResponseList({
        storeID: 0,
        factoryID: parseInt(localStorage.getItem("branchID")),
        FromOrderDate: Moment(this.state.orderFromDate).format("YYYY-MM-DD"),
        ToOrderDate: Moment(this.state.orderToDate).format("YYYY-MM-DD"),
        postQCStatus: this.state.qcStatusFilter,
        // tagNo: null,
        pageIndex: this.state.pagination.current,
        pageSize: this.state.pagination.pageSize,
        sortColumn: this.state.sorter.field,
        sortOrder: this.state.sorter.order,
        commonSearch: this.state.search,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            listData: response.data,
            pagination: {
              ...this.state.pagination,
              total:
                response.data.length !== 0 ? response.data[0].totalRecords : 0,
            },
            loading: false,
          });
        } else {
          notification["error"]({
            message: labels.POST_QC,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_POST_QC_DATA,
          });
        }
        if (this.props.toggleLoading !== undefined) {
          this.props.toggleLoading(false);
        }
      });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  //function for handling search change event
  handleSearch = (event) => {
    this.setState({ search: event.target.value }, () => this.getResponseList());
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        sorter: {
          ...sorter,
          order:
            sorter.order === "ascend"
              ? "asc"
              : sorter.order === "descend"
              ? "desc"
              : sorter.order,
        },
      },
      () => this.getResponseList()
    );
  };

  render() {
    const { pagination, loading } = this.state;
    const preQCResponseItems = this.state.preQCResponseItems;
    const listItems = preQCResponseItems;
    return (
      <div>
        <PageHeader
          title={labels.POST_QC}
          extra={[
            
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.props.history.push({
                    pathname: ROUTES.ADD_POST_QC_FACTORY,
                    state: { tagNo: "" },
                  })
                }
                key="2"
              >
                {labels.ADD_GARMENT_FOR_POST_QC}
              </Button>
            ) : null,

            <Button
                type="link"
                className={styles.FilterToggle}
                onClick={() => {
                    this.setState({ showfilterBox: !this.state.showfilterBox });
                }}
            >
                {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
                <FontAwesomeIcon
                    icon={faChevronUp}
                    className={
                        this.state.showfilterBox
                            ? styles.FilterToggleIcon
                            : styles.FilterToggleIconRotated
                    }
                />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
        <Form
            onFinish={() => this.getResponseList()}
            initialValues={{
              orderFromDate: this.state.orderFromDate,
              orderToDate: this.state.orderToDate,
              qcStatusFilter:this.state.qcStatusFilter
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderFromDate")
                    }
                    value={this.state.orderFromDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderToDate")
                    }
                    value={this.state.orderToDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
              <Form.Item
                  name={"qcStatusFilter"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_QC_STATUS },
                  ]}
                >
             <Select
              showSearch
              placeholder={labels.SELECT_QC_STATUS}
              defaultValue={this.state.qcStatusFilter}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) =>
                this.setState(
                  {
                    qcStatusFilter: value,
                    pagination: { ...this.state.pagination, current: 1 },
                  },
                  // () => this.getResponseList()
                )
              }
            >
              {/* <Option value={1}>Pending</Option> */}
              <Option value={2}>Damage</Option>
              {/* <Option value={3}>Washed</Option> */}
              <Option value={4}>Lost</Option>

              {/* {this.state.QCStatusOptions.length !== 0
                            ? this.state.QCStatusOptions.map((status) => {
                                return status.value !== "-1" ? (
                                    <Option value={status.value} key={status.value}>
                                    {status.text}
                                    </Option>
                                ) : null;
                                })
                            : null} */}
            </Select>,
            </Form.Item>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.listData}
          // rowKey={(record) => record.tagNo}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}
export default withRouter(Master);
