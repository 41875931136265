import React from "react";
import Compensation from "../../../components/LKWallet/Compensation/lkcompensation.component"

export default class CompensationScreen extends React.Component {
  render() {
    return (
      <div >
        <Compensation></Compensation>
      </div>
    );
  }
}