import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class RefferalBonusConfigurationService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async removeItems(lkRefferalProgramID) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.DELETE_LKREFERRAL_PROGRAM_BY_ID });
    let itemArray = [];
    let shouldWait = false;
    let ID = "?LKReferralProgramIDs=" + lkRefferalProgramID;
    await myApi.endpoints[this.endpoint.DELETE_LKREFERRAL_PROGRAM_BY_ID]
      .deleteById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.removeItems(lkRefferalProgramID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async insertUpdateItems(postData) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_LKREFERRAL_PROGRAM });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.SAVE_UPDATE_LKREFERRAL_PROGRAM]
      .create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.insertUpdateItems(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveReferralBonusConfiguration(param) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.GET_LKREFERRAL_PROGRAM_MASTER });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.GET_LKREFERRAL_PROGRAM_MASTER]
      .getAllPost(param)
      .then(({ data }) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveReferralBonusConfiguration(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}

export default RefferalBonusConfigurationService;
