import React, { Component } from 'react';
import Dailyordersummarycomponent from '../../../components/StoreModule/Audit/dailyordersummary.component';

export default class dailyordersummaryscreen extends Component {
  render() {
    return (
      <div><Dailyordersummarycomponent></Dailyordersummarycomponent></div>
    )
  }
}
