import React from "react";
import styles from "./tableactions.module.css";
/**
 * Container file to hold buttons for actions in master grid screen tables
 * @porps isPagination : if it is in a table with pagination or not, it will apply styles accordingly
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 4 June 2020
 */
export default class TableActions extends React.Component {
  render() {
    const { children, isPagination, ...rest } = this.props;
    return (
      <div
        className={[
          styles.TableActionWrapper,
          isPagination
            ? styles.TableActionWrapperWPagination
            : styles.TableActionWrapperWoPagination,
        ].join(" ")}
        {...rest}
      >
        {children}
      </div>
    );
  }
}
