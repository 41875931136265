import React from "react";
import styles from "./invoicecomponent.module.css";
import {
  PageHeader,
  Tag,
  Row,
  Col,
  Input,
  Space,
  notification,
  InputNumber,
  Spin,
  Checkbox,
  Select,
  Popconfirm,
  Modal,
  Form,
  Typography,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faGift,
  faPen,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import PrintIcon from "../../../images/Billing/print.svg";
import WalletIcon from "../../../images/Billing/wallet.svg";
import Button from "../../Common/Button/button";
// import CashIcon from "../../../images/Billing/cash.png";
// import PayTMIcon from "../../../images/Billing/paytm.png";
import { withRouter } from "react-router-dom";
import ROUTES from "../../../routes";
import InvoiceService from "../../../services/invoiceservice";
import moment from "moment";
import infoIcon from "../../../images/Order/info.svg";
import { getPDFProformaInvoice } from "./storeinvoicepdf.component";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";
import InvalidRequestComp from "../../../screens/RedirectInvalidReqestMessage";
import PaytmTransService from "../../../services/PaytmTransService";
var jwt = require("jsonwebtoken");
const confirm = Modal.confirm;

const { Text } = Typography;
var OTParr = [];

/**
 * This file holds the view of invoice detail and payment options
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 11 June 2020
 */

class Invoice extends React.Component {
  invoiceService = new InvoiceService();
  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();
  paytmTransService = new PaytmTransService();
  state = {
    disablegenerateOTP: false,
    TimerValue: 0,
    isResendOtp: false,
    ALTmobileNo: "",
    InputOTP: "",
    ReceivedOTP: "",
    ReceivedOTPForMultiple: [],
    reSendOTP: "",
    ISVerified: false,
    invoiceData: {
      adjustedLKBonusAmt: 0,
      adhocCharges: 0,
      paidLess: 0,
      lstVMInvoiceDataInvoiceInfo: [],
      lstVMVMInvoiceDataInvoicePaymentInfo: [],
      customerName: null,
      storeID: null,
      deliveryType: null,
      customerID: null,
      corporateID: null,
      corporateName: null,
      customeraddressID: null,
      customeraddressName: null,
      customerType: null,
      customerTypeName: null,
      orderID: null,
      orderDate: "",
      ordertype: null,
      ordertypename: null,
      pickupDate: "",
      assignedToUserID: null,
      pickupBy: null,
      cgstPer: 0,
      sgstPer: 0,
      igstPer: 0,
      splitOrderNumber: null,
      uOM: null,
      serviceid: null,
      servicename: null,
      perfomaInvoiceNumber: null,
      invoiceID: 0,
      invoiceDate: null,
      invoiceStatus: null,
      invoiceStatusId: null,
      walletBalance: null,
      serviceAmount: null,
      addonserviceamount: null,
      deliverycharges: null,
      packagingcharges: null,
      discountamount: null,
      discountname: null,
      cgst: null,
      sgst: null,
      igst: null,
      weight: null,
      noofgarments: null,
      notServedcGST: null,
      notServedsGST: null,
      notServediGST: null,
      notServedserviceamount: null,
      notServedaddOnServiceAmount: null,
      vMInvoiceGarmentDetails: [],
      isShowQRCode: false,
      base64Image: "",
      isQRCodeShow: false,
    },
    displayDiscountInfo: false,
    adhocAmount: 0,
    remarks: null,
    invoiceremark: null,
    adjustmentAmount: 0,
    newAdjustmentAmount: 0,
    isAdjustmentUpdated: false,
    fullLoading: false,
    restAmount: 0,
    useLKWallet: false,
    paymentMethods: {
      data: [],
      disabled: true,
      selected: null,
    },
    mode: "single",
    showAddPopup: false,
    EDCmachineInfo: [],
    transID: "",
    isTransFail: false,
    isShowQRCode: false,
    base64Image: "",
    isQRCodeShow: false,
    showPaymentDetailModal: false,
    paymentDetail: {
      ORDERID: "",
      TXNAMOUNT: "",
      TXNDATE: "",
      STATUS: "",
      RESPMSG: "",
    },
    IsDetailShow: false,
  };
  componentDidMount() {
    // let post = {
    //   splitOrderNumber: "5s2",
    // };
    // getPDFProformaInvoice(post);
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    if (
      this.props.match.path ===
      [ROUTES.PROFORMA_INVOICE, ":splitOrderID"].join("/")
    ) {
      this.getInvoiceDetailFromSplitOrder();
      if (this.props.match.params.splitOrderID.split(",").length > 1) {
        this.setState({ mode: "multiple" });
      }
    }

    this.getPaymentMethods();

    var Settle_Invoice_Without_OTP = JSON.parse(
      localStorage.getItem("buttonAccess")
    ).filter((item) => {
      return item.accessName == "Settle Invoice Without OTP";
    });

    if (Settle_Invoice_Without_OTP.length > 0) {
      this.setState({ ISVerified: true });
    }
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.INVOICE
    );
    this.setState({ userRights: res, showAddPopup: false });
  }
  getPaymentMethods = () => {
    this.invoiceService.getPaymentMethods().then((response) => {
      if (response.statusCode === 200) {
        let data = response.data.map((single) => {
          return {
            value: parseInt(single.enumDetailID),
            label: single.enumDetailName,
          };
        });
        this.setState({
          paymentMethods: {
            ...this.state.paymentMethods,
            data: data,
            disabled: false,
          },
        });
      }
    });
  };
  getInvoiceDetailFromSplitOrder = () => {
    this.setState({ fullLoading: true });
    // const splitOrderID = this.props.match.params.splitOrderID;
    const splitOrderID = this.props.location.state.splitOrderNumber;
    this.invoiceService
      .getInvoiceDetailFromSplitOrder(splitOrderID)
      .then((response) => {
        // console.log("response->", response)
        if (response.statusCode === 200) {
          let updated = response.data;
          // updated.cgstPer = 9;
          // updated.sgstPer = 9;
          // updated.igstPer = 9;
          // updated.walletBalance = 80;
          // console.log("updated->", updated);
          // updated.length > 0 && (
          this.setState(
            {
              // ReceivedOTP:
              //   updated.otp !== null &&
              //   updated.otp !== undefined &&
              //   updated.otp !== ""
              //     ? updated.otp
              //     : "",
              invoiceData: updated,
              adhocAmount: updated.adhocCharges,
              adjustmentAmount: updated.adjustmentAmount,
              newAdjustmentAmount: updated.adjustmentAmount,
              //condition added by PB for displaying remarks correctly even after generating tax invoice
              remarks:
                response.data.invoiceStatusId === 1
                  ? response.data.remark
                  : response.data.lstVMVMInvoiceDataInvoicePaymentInfo.length >
                    0
                  ? response.data.lstVMVMInvoiceDataInvoicePaymentInfo[0].remark
                  : "",
              //ended by PB
              invoiceremark:
                response.data.invoiceStatusId === 3
                  ? response.data.invoiceRemark
                  : null,
            },
            () =>
              this.setState({
                //restAmount: Math.round(this.calculateTotal() - 0.01), //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                restAmount: Math.round(this.calculateTotal()), //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
              })
          );

          //Added by Rashmi 16-Feb-23 /Comment By Hasti - L16814
          // if (this.state.mode !== "single") {
          //   let tmparr = [];
          //   updated.lstVMInvoiceDataInvoiceInfo.forEach((data) => {
          //     // console.log("data.otp->>", data.otp)
          //     if (
          //       data.otp !== "" &&
          //       data.otp !== undefined &&
          //       data.otp !== null
          //     ) {
          //       tmparr.push(data.otp);
          //     }
          //   });

          //   this.setState({
          //     ReceivedOTPForMultiple: tmparr,
          //   });
          // }
          // );
        } else {
          notification["error"]({
            message: labels.INVOICE,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_SPLIT_ORDER_DETAILS,
          });
        }
        this.setState({ fullLoading: false });
      });
  };

  InsertPaytmprocesslog = (req, res, apiurl, query) => {
    let param = {
      SplitOrderID: this.props.location.state.splitOrderNumber,
      Request: JSON.stringify(req),
      Response: JSON.stringify(res),
      APIName: apiurl,
      QueryName: query,
    };
    this.invoiceService.InsertPaytmprocesslog(param).then((response) => {});
  };

  // CheckPaytmEDCMachineTransData = () => {
  //   if (this.state.mode === "single") {
  //     if (this.state.paymentMethods.selected == 3) {
  //       this.invoiceService
  //         .PaytmEDCTransInfo(this.state.invoiceData.invoiceID)
  //         .then((res) => {
  //           if (
  //             res != null &&
  //             res.data.id == 0 &&
  //             res.data.merchantTransactionId == null
  //           ) {
  //             this.generateTaxInvoice();
  //           } else {
  //             var res = JSON.parse(res.data.response);
  //             if (
  //               res != null &&
  //               res.body.resultInfo.resultStatus == "SUCCESS"
  //             ) {
  //               notification["success"]({
  //                 message: "Transaction has been successfully processed.",
  //               });
  //               this.GenerateInvoiceTax();
  //               return false;
  //             }
  //             this.generateTaxInvoice();
  //           }
  //         });
  //     } else {
  //       this.generateTaxInvoice();
  //     }
  //   } else {
  //     this.generateTaxInvoice();
  //   }
  // };

  CheckPaytmEDCMachineTransData = () => {
    // if (this.state.paymentMethods.selected == 3 && this.state.restAmount > 0) {
    if (this.state.paymentMethods.selected == 3) {
      this.EDCRequest();
    } else if (this.state.paymentMethods.selected == 4) {
      this.QRCodeRequest();
    } else {
      this.GenerateInvoiceTax();
    }
  };

  QRCodeRequest = () => {
    this.setState({
      isTransFail: false,
    });
    let transID = this.common.PaytmTransNumber(
      this.props.location.state.splitId === undefined
        ? this.props.location.state.splitOrderNumber.toUpperCase()
        : this.props.location.state.splitId.toUpperCase(),
      // this.props.location.state.splitId.toUpperCase(),
      // this.state.mode === "single"
      //   ? this.state.invoiceData.invoiceID.toString()
      //   : // : this.props.match.params.splitOrderID,
      //     this.props.location.state.splitOrderNumber,
      "Order Payment"
    );

    // console.log("1",this.props.location.state.splitOrderNumber);
    // console.log("2",this.props.location.state.splitId);
    // console.log("3", this.state.invoiceData.invoiceID.toString());

    this.setState({ modalLoading: true, transID: transID });
    this.setState({ showAddPopup: true });

    let excessAmount = this.state.useLKWallet
      ? this.state.restAmount +
        this.state.invoiceData.walletBalance -
        this.calculateTotal()
      : this.state.restAmount - this.calculateTotal();
    let amountToBeSent =
      excessAmount > 0
        ? this.state.restAmount - excessAmount
        : this.state.restAmount;

    var postdata = {
      mid: "LAUNDR39826025976613",
      orderId: transID,
      amount: parseInt(Math.round(amountToBeSent)).toString(),
      businessType: "UPI_QR_CODE",
      posId: localStorage.getItem("branchID"),
      clientId: "Mr9HS8Zd1lClTaJO",
      channelId: "WEB",
      version: "V1",
      versionNumber: "",
      transactionType: 0,
      response: "",
      createdBy: 0,
      id:
        this.state.mode === "single"
          ? this.state.invoiceData.invoiceID.toString()
          : this.props.location.state.splitOrderNumber,
      callFrom: "invoice",
      platform: "web-QR",
      invoiceID:
        this.state.mode === "single"
          ? this.state.invoiceData.invoiceID.toString()
          : this.props.location.state.splitOrderNumber,
    };

    this.setState({ modalLoading: true });
    this.paytmTransService.QRCodeRequest(postdata).then((response) => {
      this.InsertPaytmprocesslog(
        postdata,
        response,
        "QRCodeRequest",
        "WebPortal-QRCode"
      );

      if (response.statusCode == 200) {
        var res = JSON.parse(response.data.qrCodeResponse);
        if (res.body.resultInfo.resultStatus == "SUCCESS") {
          this.setState({
            isShowQRCode: true,
            base64Image: res.body.image,
            isQRCodeShow: true,
          });
          postdata.transactionType = 1;
          postdata.merchantTransactionId = this.state.transID;
          const intervalID = setInterval(() => {
            this.paytmTransService
              .QRCodeStatusCheck(postdata)
              .then((response) => {
                this.InsertPaytmprocesslog(
                  postdata,
                  response,
                  "QRCodeStatusCheck",
                  "WebPortal-QRCode"
                );
                if (response.statusCode == 200) {
                  var res = JSON.parse(response.data.qrCodeResponse);
                  if (res.body.resultInfo.resultStatus == "TXN_SUCCESS") {
                    notification["success"]({
                      message: labels.INVOICE,
                      description:
                        labels.TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED,
                    });
                    setTimeout(() => {
                      this.GenerateInvoiceTax();
                      this.setState({
                        // transID: postdata.merchantTransactionId,
                        showAddPopup: false,
                        modalLoading: false,
                        isQRCodeShow: false,
                      });
                    }, 1000);
                    clearInterval(intervalID);
                    this.setState({ modalLoading: false });
                  } else {
                    if (res.body.resultInfo.resultStatus === "TXN_FAILURE") {
                      clearInterval(intervalID);
                      this.setState({
                        showAddPopup: false,
                        isTransFail: true,
                        modalLoading: false,
                        isQRCodeShow: false,
                      });
                      notification["error"]({
                        message: labels.INVOICE,
                        description:
                          labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                      });
                    } else {
                      // debugger;
                    }
                  }
                }
              });
          }, 15000);
          setTimeout(() => {
            clearInterval(intervalID);
            this.setState({
              showAddPopup: false,
              modalLoading: false,
              isQRCodeShow: false,
            });
            // if (
            //   (res.body.resultInfo.resultStatus == "FAIL" ||
            //     res.body.resultInfo.resultStatus ==
            //       "PANDING") &&
            //   this.state.isTransFail == false
            // ) {
            notification["error"]({
              message: labels.INVOICE,
              description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
            });
            // }
          }, 360000);
        } else {
          this.setState({
            showAddPopup: false,
            modalLoading: false,
            isQRCodeShow: false,
          });
          notification["error"]({
            message: labels.INVOICE,
            description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
          });
        }
      } else {
        this.setState({
          showAddPopup: false,
          modalLoading: false,
          isQRCodeShow: false,
        });
        notification["error"]({
          message: labels.INVOICE,
          description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
        });
      }
      this.setState({ modalLoading: false });
    });

    // } else {
    //   this.setState({ showAddPopup: false, modalLoading: false });
    //   notification["error"]({
    //     message: labels.BUY_PACKAGE,
    //     description: labels.UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM,
    //   });
    // }
    this.setState({ modalLoading: true });
    //});
  };

  EDCRequest = () => {
    this.setState({ isTransFail: false });
    this.invoiceService.getEDCMachineInfo().then((response) => {
      //  this.InsertPaytmprocesslog(parseInt(localStorage.getItem("branchID")),response,"getEDCMachineInfo","WebPortal-EDC")
      if (response.statusCode == 200) {
        if ([undefined, "", null].includes(response.data.machineKey)) {
          notification["error"]({
            message: labels.INVOICE,
            description: labels.EDC_CONFIGURATION,
          });
          this.setState({ modalLoading: false });
          return false;
        }
        let transID = this.common.PaytmTransNumber(
          // this.props.location.state.splitId.toUpperCase(),
          this.props.location.state.splitId === undefined
            ? this.props.location.state.splitOrderNumber.toUpperCase()
            : this.props.location.state.splitId.toUpperCase(),
          // this.state.mode === "single"
          //   ? this.state.invoiceData.invoiceID.toString()
          //   : // : this.props.match.params.splitOrderID,
          //     this.props.location.state.splitOrderNumber,
          "Order Payment"
        );
        this.setState({
          showAddPopup: true,
          EDCmachineInfo: response.data,
          transID: transID,
        });
        let excessAmount = this.state.useLKWallet
          ? this.state.restAmount +
            this.state.invoiceData.walletBalance -
            this.calculateTotal()
          : this.state.restAmount - this.calculateTotal();
        let amountToBeSent =
          excessAmount > 0
            ? this.state.restAmount - excessAmount
            : this.state.restAmount;
        var postdata = {
          paytmMid: response.data.paytmMid,
          paytmTid: response.data.paytmTid,
          transactionDateTime: "",
          merchantTransactionId: "",
          merchantReferenceNo: "",
          transactionAmount: parseInt(
            Math.round(amountToBeSent) * 100
          ).toString(),
          callbackUrl: "",
          machineKey: response.data.machineKey,
          json: 0,
          response: "",
          transactionType: 0,
          requestParams: "",
          createdBy: 0,
          status: "",
          id:
            this.state.mode === "single"
              ? this.state.invoiceData.invoiceID.toString()
              : this.props.location.state.splitOrderNumber,
          // this.props.match.params.splitOrderID,
          callFrom: "invoice",
          platform: "web",
          transctionId: transID,
          versionNumber: response.data.versionNumber,
        };
        this.invoiceService.EDCMachineRequest(postdata).then((response) => {
          // this.InsertPaytmprocesslog(postdata,response,"EDCMachineRequest","WebPortal-EDC")

          if (response.statusCode == 200) {
            var res = JSON.parse(response.data.response);
            if (res.body.resultInfo.resultStatus == "ACCEPTED_SUCCESS") {
              postdata.merchantTransactionId = this.state.transID;
              postdata.transactionDateTime = response.data.transactionDateTime;
              postdata.transactionType = 1;
              const intervalID = setInterval(
                () =>
                  this.invoiceService
                    .EDCMachineStatus(postdata)
                    .then((response) => {
                      // this.InsertPaytmprocesslog(postdata,response,"EDCMachineStatus","WebPortal-EDC")
                      if (response.statusCode == 200) {
                        var res = JSON.parse(response.data.response);
                        if (res.body.resultInfo.resultStatus == "SUCCESS") {
                          notification["success"]({
                            message: labels.INVOICE,
                            description:
                              labels.TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED,
                          });
                          setTimeout(() => {
                            this.GenerateInvoiceTax();
                            this.setState({
                              //  transID: postdata.merchantTransactionId,
                              showAddPopup: false,
                              fullLoading: true,
                            });
                          }, 1000);
                          clearInterval(intervalID);
                        } else {
                          if (res.body.resultInfo.resultStatus === "FAIL") {
                            clearInterval(intervalID);
                            this.setState({
                              showAddPopup: false,
                              fullLoading: false,
                              isTransFail: true,
                            });
                            notification["error"]({
                              message: labels.INVOICE,
                              description:
                                labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                            });
                          } else {
                            setTimeout(() => {
                              clearInterval(intervalID);
                              this.setState({
                                showAddPopup: false,
                                fullLoading: false,
                              });
                              notification["error"]({
                                message: labels.INVOICE,
                                description:
                                  labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                              });
                            }, 360000);
                          }
                        }
                      }
                    }),
                10000
              );
            } else {
              this.setState({ showAddPopup: false, fullLoading: false });
              notification["error"]({
                message: labels.INVOICE,
                description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
              });
            }
          } else {
            this.setState({ showAddPopup: false, fullLoading: false });
            notification["error"]({
              message: labels.INVOICE,
              description: labels.SOMETHING_WENT_WRONG,
              // description:  this.common.Message(response.message)
            });
          }
        });
      } else {
        this.setState({ showAddPopup: false, fullLoading: false });
        notification["error"]({
          message: labels.INVOICE,
          description: labels.UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM,
        });
      }
    });
  };
  GenerateInvoiceTax = () => {
    if (
      [null, "", undefined].includes(this.state.paymentMethods.selected) &&
      [false].includes(this.state.useLKWallet)
    )
      return notification["error"]({
        message: labels.INVOICE,
        description: labels.PLEASE_SELECT_PAYMENT_MODE,
      });

    this.setState({ fullLoading: true });
    let request = [];
    let excessAmount = this.state.useLKWallet
      ? this.state.restAmount +
        this.state.invoiceData.walletBalance -
        //Math.round(this.calculateTotal() - 0.01) //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
        Math.round(this.calculateTotal()) //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
      : // : this.state.restAmount - Math.round(this.calculateTotal() - 0.01); //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
        this.state.restAmount - Math.round(this.calculateTotal()); //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14

    let amountToBeSent =
      excessAmount > 0
        ? this.state.restAmount - excessAmount
        : this.state.restAmount;

    if (this.state.useLKWallet === true) {
      request.push({
        invoiceIDs:
          this.state.mode === "single"
            ? this.state.invoiceData.invoiceID.toString()
            : this.props.location.state.splitOrderNumber,
        //  this.props.match.params.splitOrderID,
        paymentMode: this.state.paymentMethods.data.filter(
          (x) => x.label.toLowerCase() === "lk wallet"
        )[0].value,
        amount:
          //Math.round(this.calculateTotal() - 0.01) >= //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
          Math.round(this.calculateTotal()) >= //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
          this.state.invoiceData.walletBalance
            ? this.state.invoiceData.walletBalance
            : //: Math.round(this.calculateTotal() - 0.01), //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
              Math.round(this.calculateTotal()), //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
        chequeNo: null,
        chequeDate: moment().format("YYYY-MM-DD"),
        cardNo: null,
        transactionNo: this.state.transID,
        remark: this.state.remarks,
        source: 1,
      });
    }
    if (amountToBeSent > 0) {
      //amountToBeSent >= 0 change in condition By Hasti Tilva for isuue #L16335

      request.push({
        invoiceIDs:
          this.state.mode === "single"
            ? this.state.invoiceData.invoiceID.toString()
            : this.props.location.state.splitOrderNumber,
        // this.props.match.params.splitOrderID,
        paymentMode: this.state.paymentMethods.selected,
        amount: amountToBeSent,
        chequeNo: null,
        chequeDate: moment().format("YYYY-MM-DD"),
        cardNo: null,
        transactionNo: this.state.transID,
        remark: this.state.remarks,
        source: 1,
      });
    }

    this.invoiceService.generateTaxInvoice(request).then((response) => {
      this.InsertPaytmprocesslog(
        request,
        response,
        "generateTaxInvoice",
        "WebPortal-QRCode"
      );
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.INVOICE,
          description: labels.INVOICE_GENERATED_SUCCESSFULLY,
          onClose: () => {
            this.props.history.push(ROUTES.STORE_READY_FOR_DELIVERY_ORDERS);
          },
        });

        // let post = {
        //   splitOrderNumber: "5s2",
        //    splitOrderNumber:this.props.match.params.splitOrderID;
        // };
        // getPDFProformaInvoice(post);
      } else {
        notification["error"]({
          message: labels.INVOICE,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GENERATE_INVOICE,
        });
      }
      this.setState({ fullLoading: false });
      this.getInvoiceDetailFromSplitOrder();
    });
  };
  getAdjustmentAmount = (AdjustmentAmount) => {
    return parseFloat(
      parseFloat(
        AdjustmentAmount +
          (AdjustmentAmount *
            parseFloat(
              this.state.invoiceData.cgstPer +
                this.state.invoiceData.cgstPer +
                this.state.invoiceData.igstPer
            )) /
            100
      ).toFixed(2)
    );
  };
  saveUpdateProformaInvoice = () => {
    this.setState({ fullLoading: true });
    const splitOrderID = this.props.location.state.splitOrderNumber;
    // this.props.match.params.splitOrderID;
    this.invoiceService
      .saveUpdateProformaInvoice({
        splitOrderNumber: splitOrderID,
        adhocCharges:
          this.state.adhocAmount === null ? 0 : this.state.adhocAmount,
        adjustmentAmount:
          this.state.newAdjustmentAmount === null
            ? 0
            : this.state.isAdjustmentUpdated === true
            ? this.state.newAdjustmentAmount
            : this.getAdjustmentAmount(this.state.newAdjustmentAmount),
        remark: this.state.remarks,
        source: 1,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            fullLoading: true,
            useLKWallet: false,
            restAmount: 0.0,
            isAdjustmentUpdated: false,
          });
          notification["success"]({
            message: labels.INVOICE,
            description: response.message
              ? this.common.Message(response.message)
              : labels.INVOICE_DATA_SUBMITTED_SUCCESSFULLY,
          });
          this.getInvoiceDetailFromSplitOrder();
        } else {
          notification["error"]({
            message: labels.INVOICE,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_SUBMIT_INVOICE_DATA,
          });
        }
        this.setState({ fullLoading: false });
      });
  };

  // CheckPaymentDetailLog = () => {
  //   this.setState({ fullLoading: true });
  //   const splitOrderID = this.props.location.state.splitOrderNumber;
  //   this.invoiceService
  //     .CheckPaymentDetailLog({
  //       SplitOrderID: splitOrderID,
  //     })
  //     .then((response) => {
  //       if (response.statusCode === 200) {
  //         // console.log("res->", response);
  //         if(response.data.length > 0){
  //         var SourceFrom = response.data[0].sourceFrom;
  //         if (SourceFrom == "WebPortal-QRCode") {
  //           var tempdata1 = JSON.parse(response.data[0].response);
  //           var webdata =  JSON.parse(tempdata1.data.qrCodeResponse).body;
  //           this.setState({
  //             paymentDetail: {
  //               ORDERID: webdata.orderId,
  //               TXNAMOUNT: webdata.txnAmount,
  //               TXNDATE: webdata.txnDate,
  //               STATUS: webdata.resultInfo.resultStatus,
  //               RESPMSG: webdata.resultInfo.resultMsg,
  //             },
  //             IsDetailShow:true,
  //             showPaymentDetailModal: true,
  //           });
  //         } else if (SourceFrom == "UI-Android" || SourceFrom == "UI-IOS") {
  //           var tempdata2 = JSON.parse(response.data[0].response);
  //           // console.log("tempdata2->", tempdata2);
  //           this.setState({
  //             paymentDetail: {
  //               ORDERID: tempdata2.ORDERID,
  //               TXNAMOUNT: tempdata2.TXNAMOUNT,
  //               TXNDATE: tempdata2.TXNDATE,
  //               STATUS: tempdata2.STATUS,
  //               RESPMSG: tempdata2.RESPMSG,
  //             },
  //             IsDetailShow:true,
  //             showPaymentDetailModal: true,
  //           });
  //         }}
  //         else{
  //           this.setState({
  //             IsDetailShow:false,
  //             showPaymentDetailModal: true,
  //           });
  //         }
  //       } else {
  //         notification["error"]({
  //           message: labels.INVOICE,
  //           description: "Unable to get Payment Detail",
  //         });
  //       }
  //       this.setState({ fullLoading: false });
  //     });
  // };

  CheckPaymentStatus = () => {
    // console.log("invoicedata->", this.state.invoiceData);
    var postdata = {
      mid:
        this.state.mode === "single"
          ? this.state.invoiceData.mid
          : this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[0].mid,
      clientId:
        this.state.mode === "single"
          ? this.state.invoiceData.clientID
          : this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[0].clientID,
      orderId:
        this.state.mode === "single"
          ? this.state.invoiceData.paytmtranID.toString()
          : this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[0].paytmtranID,

      //QR Code
      // mid:"KLEENC97448479615017",
      // clientId:"FxU%S%zqMPMUk9Ee",
      // orderId:"061913CDA008ODC105567"

      //PG
      // mid:"laundr11769834761468",
      // clientId:"398Q0RWW1Nsm77Gp",
      // orderId:"441859CAP001OWI105247"
    };

    // console.log("postdata->", postdata);
    this.paytmTransService
      .CheckPaymentTransactionStatus(postdata)
      .then((response) => {
        if (response.statusCode == 200) {
          // console.log("res->", response.data);
          var res = JSON.parse(response.data.transactionStatus);
          // console.log("res123->", res);

          // var tempdata1 = JSON.parse(response.data[0].response);
          var webdata = res.body;
          this.setState({
            paymentDetail: {
              ORDERID: webdata.orderId,
              TXNAMOUNT: webdata.txnAmount,
              TXNDATE: webdata.txnDate,
              STATUS: webdata.resultInfo.resultStatus,
              RESPMSG: webdata.resultInfo.resultMsg,
            },
            IsDetailShow: true,
            showPaymentDetailModal: true,
          });

          let params = {
            invoiceIds:
              this.state.mode === "single"
                ? this.state.invoiceData.invoiceID.toString()
                : this.props.location.state.splitOrderNumber,
            qrCodeStatus: webdata.resultInfo.resultStatus,
          };
          this.invoiceService.QRCodeStatusUpdate(params).then((response) => {
            if (response.statusCode == 200) {
              // console.log("res->", response);
              if (response.data == 1) {
                notification["success"]({
                  message: labels.INVOICE,
                  description: "Status Save successfully",
                });
              } else {
                notification["success"]({
                  message: labels.INVOICE,
                  description: response.message,
                });
              }
            }
          });
        }
      });
  };

  CheckPhonePeStatus = () => {
    // console.log("invoicedata->", this.state.invoiceData);
    var postdata = {
      merchantTransactionId:
        this.state.mode === "single"
          ? this.state.invoiceData.phonePetranID.toString()
          : this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[0].phonePetranID,
      SplitOrderNo: "",
    };

    // console.log("postdata->", postdata);
    this.paytmTransService.CheckPhonePeStatus(postdata).then((response) => {
      if (response.statusCode == 200) {
        // console.log("res->", response.data);
        var res = response.data;
        // console.log("res123->", res);

        // var tempdata1 = JSON.parse(response.data[0].response);
        var webdata = res.data;
        this.setState({
          paymentDetail: {
            ORDERID: webdata.merchantTransactionId,
            TXNAMOUNT: (webdata.amount)/100,
            // TXNDATE: webdata.txnDate,
            STATUS: res.code,
            RESPMSG: res.message,
          },
          IsDetailShow: true,
          showPaymentDetailModal: true,
        });

        let params = {
          invoiceIds:
            this.state.mode === "single"
              ? this.state.invoiceData.invoiceID.toString()
              : this.props.location.state.splitOrderNumber,
          PhonePeStatus: res.code,
        };
        this.invoiceService.PhonePeStatusUpdate(params).then((response) => {
          if (response.statusCode == 200) {
            // console.log("res->", response);
            if (response.data == 1) {
              notification["success"]({
                message: labels.INVOICE,
                description: "Status Save successfully",
              });
            } else {
              notification["success"]({
                message: labels.INVOICE,
                description: response.message,
              });
            }
          }
        });
      }
    });
  };

  GenerateTaxInvoiceFromCheckNow = (tranID) => {
    let amount = Math.round(this.calculateTotal() - this.getPaidTotal());
    this.setState({ showPaymentDetailModal: false, fullLoading: true });
    let request = [];
    request.push({
      invoiceIDs:
        this.state.mode === "single"
          ? this.state.invoiceData.invoiceID.toString()
          : this.props.location.state.splitOrderNumber,
      paymentMode: 4,
      amount: parseFloat(amount),
      chequeNo: null,
      chequeDate: moment().format("YYYY-MM-DD"),
      cardNo: null,
      transactionNo: tranID,
      remark: "SettledWithCheckNow",
      source: 1,
    });

    // console.log("request->", request);

    this.invoiceService.generateTaxInvoice(request).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.INVOICE,
          description: labels.INVOICE_GENERATED_SUCCESSFULLY,
          onClose: () => {
            this.props.history.push(ROUTES.STORE_READY_FOR_DELIVERY_ORDERS);
          },
        });
      } else {
        notification["error"]({
          message: labels.INVOICE,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GENERATE_INVOICE,
        });
      }
      this.setState({ fullLoading: false });
      this.getInvoiceDetailFromSplitOrder();
    });
  };

  UpdateInvoiceRemark = () => {
    this.setState({ fullLoading: true });
    const splitOrderID = this.props.location.state.splitOrderNumber;
    // this.props.match.params.splitOrderID;
    this.invoiceService
      .UpdateInvoiceRemark({
        SplitOrderNumber: splitOrderID,
        InvoiceRemark: this.state.invoiceremark,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            fullLoading: true,
          });
          notification["success"]({
            message: labels.INVOICE,
            description: response.message
              ? this.common.Message(response.message)
              : labels.INVOICE_REMARK_UPDATED_SUCCESSFULLY,
          });
          this.getInvoiceDetailFromSplitOrder();
        } else {
          notification["error"]({
            message: labels.INVOICE,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_UPDATE_INVOICE_REMARK,
          });
        }
        this.setState({ fullLoading: false });
      });
  };

  getPaidTotal = () => {
    let sum = 0;
    if (
      this.state.invoiceData.lstVMVMInvoiceDataInvoicePaymentInfo !== undefined
    ) {
      this.state.invoiceData.lstVMVMInvoiceDataInvoicePaymentInfo.forEach(
        function (obj) {
          sum += obj.amount;
        }
      );
    }
    return sum;
  };
  calculateTotal = (taxType = false) => {
    if (this.state.mode === "single") {
      return parseFloat(
        (
          (parseFloat(this.state.invoiceData.serviceAmount) +
            parseFloat(this.state.invoiceData.addonserviceamount) +
            parseFloat(this.state.invoiceData.deliverycharges) +
            parseFloat(this.state.invoiceData.packagingcharges) -
            parseFloat(this.state.invoiceData.discountamount) +
            // parseFloat(this.state.invoiceData.adhocCharges) +
            parseFloat(this.state.adhocAmount) -
            parseFloat(this.state.invoiceData.adjustedLKBonusAmt) -
            parseFloat(this.state.invoiceData.adjustmentAmount) -
            parseFloat(this.state.invoiceData.paidLess)) *
          parseFloat(
            (
              (taxType !== false ? 0 : 1) +
              parseFloat(
                (
                  (taxType === "CGST"
                    ? this.state.invoiceData.cgstPer
                    : taxType === "SGST"
                    ? this.state.invoiceData.sgstPer
                    : taxType === "IGST"
                    ? this.state.invoiceData.igstPer
                    : this.state.invoiceData.cgstPer +
                      this.state.invoiceData.sgstPer +
                      this.state.invoiceData.igstPer) / 100
                ).toFixed(2)
              )
            ).toFixed(2)
          )
        ).toFixed(2)
      );
    } else {
      return parseFloat(this.state.invoiceData.totalFinalInvoiceAmount);
    }
    // if (this.state.mode === "single") {
    //   let adjustedandPaidlessAmt =
    //     parseFloat(this.state.invoiceData.paidLess) +
    //     parseFloat(this.state.invoiceData.adjustmentAmount);
    //   let taxPerc = parseFloat(
    //     (
    //       (taxType !== false ? 0 : 1) +
    //       parseFloat(
    //         (
    //           (taxType === "CGST"
    //             ? this.state.invoiceData.cgstPer
    //             : taxType === "SGST"
    //               ? this.state.invoiceData.sgstPer
    //               : taxType === "IGST"
    //                 ? this.state.invoiceData.igstPer
    //                 : this.state.invoiceData.cgstPer +
    //                 this.state.invoiceData.sgstPer +
    //                 this.state.invoiceData.igstPer) / 100
    //         ).toFixed(2)
    //       )
    //     ).toFixed(2)
    //   );

    //   return parseFloat(
    //     (
    //       (parseFloat(this.state.invoiceData.serviceAmount) +
    //         parseFloat(this.state.invoiceData.addonserviceamount) +
    //         parseFloat(this.state.invoiceData.deliverycharges) +
    //         parseFloat(this.state.invoiceData.packagingcharges) -
    //         parseFloat(this.state.invoiceData.discountamount) +
    //         // parseFloat(this.state.invoiceData.adhocCharges) +
    //         parseFloat(this.state.adhocAmount) -
    //         parseFloat(this.state.invoiceData.adjustedLKBonusAmt)) *
    //       // parseFloat(
    //       //   (
    //       //     (taxType !== false ? 0 : 1) +
    //       //     parseFloat(
    //       //       (
    //       //         (taxType === "CGST"
    //       //           ? this.state.invoiceData.cgstPer
    //       //           : taxType === "SGST"
    //       //             ? this.state.invoiceData.sgstPer
    //       //             : taxType === "IGST"
    //       //               ? this.state.invoiceData.igstPer
    //       //               : this.state.invoiceData.cgstPer +
    //       //               this.state.invoiceData.sgstPer +
    //       //               this.state.invoiceData.igstPer) / 100
    //       //       ).toFixed(2)
    //       //     )
    //       //   ).toFixed(2)
    //       // )
    //       taxPerc -
    //       (taxType !== false ? 0 : adjustedandPaidlessAmt) -
    //       (taxType === false
    //         ? 0
    //         : adjustedandPaidlessAmt -
    //         adjustedandPaidlessAmt * (100 / (100 + taxPerc * 100)))
    //     ).toFixed(2)
    //   );
    // } else {
    //   return parseFloat(this.state.invoiceData.totalFinalInvoiceAmount);
    // }
  };
  markSettled = () => {
    this.setState({ fullLoading: true });
    this.invoiceService
      .markSettled(this.state.invoiceData.invoiceID)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: labels.INVOICE,
            description: response.message
              ? this.common.Message(response.message)
              : labels.INVOICE_SETTLED_SUCCESSFULLY,
          });
          this.getInvoiceDetailFromSplitOrder();
        } else {
          notification["error"]({
            message: labels.INVOICE,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_SETTLE_INVOICE,
          });
        }
        this.setState({ fullLoading: false });
      });
  };
  addExcessAmountToWallet = () => {
    let excessAmount = this.state.useLKWallet
      ? this.state.restAmount +
        this.state.invoiceData.walletBalance -
        //Math.round(this.calculateTotal() - 0.01) //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
        Math.round(this.calculateTotal()) //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
      : //: this.state.restAmount - Math.round(this.calculateTotal() - 0.01); //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
        this.state.restAmount - Math.round(this.calculateTotal()); //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
    let invoiceIDs = "";
    let proformaNumbers = "";
    if (this.state.mode !== "single") {
      invoiceIDs = this.state.invoiceData.lstVMInvoiceDataInvoiceInfo.map(
        (x) => x.invoiceID
      );
      proformaNumbers = this.state.invoiceData.lstVMInvoiceDataInvoiceInfo.map(
        (x) => x.performaNumber
      );
      invoiceIDs = invoiceIDs.join(",");
      proformaNumbers = proformaNumbers.join(",");
    }
    const postData = {
      customerID: parseInt(this.state.invoiceData.customerID),
      orderID: null,
      invoiceID:
        this.state.mode === "single"
          ? this.state.invoiceData.invoiceID.toString()
          : invoiceIDs,
      storeID: localStorage.getItem("branchID"),
      advanceAmount: excessAmount,
      isExcessAmount: true,
      paymentMode: parseInt(this.state.paymentMethods.selected),
      chequeNo: null,
      chequeDate: null,
      cardNo: null,
      transactionNo: null,
      remark:
        "Excess Amount From Invoice " + this.state.mode === "single"
          ? this.state.invoiceData.perfomaInvoiceNumber.toString()
          : proformaNumbers,
      sourceFrom: 1,
    };
    this.setState({ fullLoading: true });
    this.invoiceService.addExcessAmountToWallet(postData).then((response) => {
      if (response.statusCode === 200) {
        this.CheckPaytmEDCMachineTransData();
      } else {
        notification["error"]({
          message: labels.INVOICE,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_ADD_AMOUNT_TO_LKWALLET,
        });
      }
      this.setState({ fullLoading: false });
    });
  };

  settleWithoutPayment = () => {
    this.setState({ fullLoading: true });
    let param = {
      UserID: parseInt(
        jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
          .unique_name
      ),
      SplitOrderNumber: this.props.location.state.splitOrderNumber,
      InvoiceId: this.state.invoiceData.invoiceID,
    };

    this.invoiceService.settleinvoicewithoutpayment(param).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.INVOICE,
          description: response.message
            ? this.common.Message(response.message)
            : labels.INVOICE_SETTLED_WITH_OUT_PAYMEN_SUCCESSFULLY,
        });
        this.getInvoiceDetailFromSplitOrder();
      } else {
        notification["error"]({
          message: labels.INVOICE,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_SETTLE_INVOICE_WITH_OUT_PAYMENT,
        });
      }
      this.setState({ fullLoading: false });
    });
  };

  // AddAltNumberForCustomer = () => {
  //   if (this.state.ALTmobileNo.length > 0) {
  //     const postData = {
  //       CustomerID: parseInt(this.state.invoiceData.customerID),
  //       AlterNativeMobileNo: this.state.ALTmobileNo.toString(),
  //     };
  //     this.setState({ fullLoading: true });
  //     this.invoiceService.ADDAltNumberForCustomer(postData).then((response) => {
  //       if (response.statusCode === 200) {
  //         notification["success"]({
  //           message: labels.INVOICE,
  //           description: labels.ALT_NUMBER_ADDED_SUCCESSFULLY,
  //         });
  //         this.setState({ fullLoading: false, ALTmobileNo: "" });
  //       } else {
  //         notification["error"]({
  //           message: labels.INVOICE,
  //           description: labels.UNABLE_TO_ADD_ALT_NUMBER,
  //         });
  //       }
  //       this.setState({ fullLoading: false, ALTmobileNo: "" });
  //     });
  //   } else {
  //     notification["error"]({
  //       message: labels.ORDERS,
  //       description: "Please Enter Alternative Mobile Number",
  //     });
  //   }
  // };

  // DeleteAltNumberForCustomer = () => {
  //   const postData = {
  //     CustomerID: parseInt(this.state.invoiceData.customerID),
  //     AlterNativeMobileNo: "",
  //   };
  //   this.setState({ fullLoading: true });
  //   this.invoiceService.ADDAltNumberForCustomer(postData).then((response) => {
  //     if (response.statusCode === 200) {
  //       notification["success"]({
  //         message: labels.ORDERS,
  //         description: labels.ALT_NUMBER_DELETED_SUCCESSFULLY,
  //       });
  //       this.setState({ fullLoading: false });
  //     } else {
  //       notification["error"]({
  //         message: labels.ORDERS,
  //         description: labels.UNABLE_TO_DELETE_ALT_NUMBER,
  //       });
  //     }
  //     this.setState({ fullLoading: false, ALTmobileNo: "" });
  //     this.formInstanceforALTNO.current.setFieldsValue({
  //       altmobileNo: "",
  //     });
  //   });
  // };

  formInstanceforOTP = React.createRef();

  SendCustomerOTPForCreateOrder = () => {
    let Garment_Count = this.state.invoiceData.noofgarments;
    let Estimated_Amount = null;
    let tempstr = "";
    let SplitNum = "";
    if (this.state.mode === "single") {
      tempstr = this.state.invoiceData.splitOrderNumber + "/" + Garment_Count;
      SplitNum = this.state.invoiceData.splitOrderNumber;
    } else {
      for (
        let i = 0;
        i < this.state.invoiceData.lstVMInvoiceDataInvoiceInfo.length;
        i++
      ) {
        tempstr =
          tempstr +
          "," +
          this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[i]
            .splitOrderNumber +
          "/" +
          this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[i].noOfGarment;
        SplitNum =
          SplitNum +
          "," +
          this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[i]
            .splitOrderNumber;
      }

      tempstr = tempstr.slice(1, tempstr.length);
      SplitNum = SplitNum.slice(1, SplitNum.length);
    }

    const postData = {
      CustomerID: parseInt(this.state.invoiceData.customerID),
      MobileNo: this.state.invoiceData.mobileNo.toString(),
      AlterNativeMobileNo:
        this.state.invoiceData.alterNativeMobileNo.toString(),
      ISPickup: false,
      GarmentCount: Garment_Count,
      SplitOrderIDGarmentCount: tempstr,
      Amount: Estimated_Amount,
      Type: "Invoice",
      SplitOrderNumber: SplitNum,
    };

    // console.log("postData->", postData);
    this.setState({ fullLoading: true });
    this.invoiceService
      .SendCustomerOTPForCreateOrder(postData)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: labels.ORDERS,
            description: labels.OTP_SENT_SUCCESSFULLY,
          });
          this.setState({ fullLoading: false, disablegenerateOTP: true });
          this.setState({
            ReceivedOTP: response.data.otp,
          });
          var timeleft = 0;
          var downloadTimer = setInterval(() => {
            if (timeleft >= 30) {
              clearInterval(downloadTimer);
            }
            var time = 30 - timeleft;
            this.setState({
              TimerValue: time,
            });

            timeleft += 1;
            this.setState({
              isResendOtp: true,
            });

            if (timeleft >= 31) {
              this.setState({
                isResendOtp: false,
              });
            }
          }, 1000);
        } else {
          notification["error"]({
            message: labels.ORDERS,
            description: labels.UNABLE_TO_SENT_OTP,
          });
        }
        this.setState({ fullLoading: false });
      });
  };

  // ResendOTP = () => {
  //   if (this.state.mode === "single") {
  //     if (
  //       this.state.ReceivedOTP !== null &&
  //       this.state.ReceivedOTP !== "" &&
  //       this.state.ReceivedOTP !== undefined
  //     ) {
  //       this.ResendDeliveryOTP();
  //     } else {
  //       this.SendCustomerOTP();
  //     }
  //   } else {
  //     if (this.state.ReceivedOTPForMultiple.length > 0) {
  //       this.ResendDeliveryOTP();
  //     } else {
  //       OTParr = [];
  //       let sendOtpCount =
  //         this.state.invoiceData.lstVMInvoiceDataInvoiceInfo.length;
  //       console.log("sendOtpCount", sendOtpCount);
  //       this.state.invoiceData.lstVMInvoiceDataInvoiceInfo.forEach((data) => {
  //         console.log("Data", data);
  //         this.SendCustomerOTP(data.splitOrderNumber);
  //       });
  //       // this.SendCustomerOTP();
  //     }
  //   }
  // };

  // ResendDeliveryOTP = () => {
  //   let Garment_Count = this.state.invoiceData.noofgarments;
  //   let tempstr = "";
  //   let RecOTP = "";
  //   if (this.state.mode === "single") {
  //     tempstr = this.state.invoiceData.splitOrderNumber + "/" + Garment_Count;
  //     RecOTP = this.state.ReceivedOTP;
  //   } else {
  //     for (
  //       let i = 0;
  //       i < this.state.invoiceData.lstVMInvoiceDataInvoiceInfo.length;
  //       i++
  //     ) {
  //       tempstr =
  //         tempstr +
  //         "," +
  //         this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[i]
  //           .splitOrderNumber +
  //         "/" +
  //         this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[i].noOfGarment;
  //     }

  //     tempstr = tempstr.slice(1, tempstr.length);
  //     // console.log("this.state.ReceivedOTPForMultiple", this.state.ReceivedOTPForMultiple)
  //     RecOTP = this.state.ReceivedOTPForMultiple[0];
  //   }

  //   const postData = {
  //     CustomerID: parseInt(this.state.invoiceData.customerID),
  //     MobileNo: this.state.invoiceData.mobileNo.toString(),
  //     AlterNativeMobileNo:
  //       this.state.invoiceData.alterNativeMobileNo.toString(),
  //     SplitOrderIDGarmentCount: tempstr,
  //     OTP: RecOTP,
  //     FullName: this.state.invoiceData.customerName,
  //   };

  //   // console.log("postData->", postData);
  //   this.setState({ fullLoading: true });
  //   this.invoiceService.ResendDeliveryOTP(postData).then((response) => {
  //     // console.log("response->", response);
  //     if (response.statusCode === 200) {
  //       notification["success"]({
  //         message: labels.ORDERS,
  //         description: labels.OTP_SENT_SUCCESSFULLY,
  //       });
  //       this.setState({ fullLoading: false, disablegenerateOTP: false });
  //       var timeleft = 0;
  //       var downloadTimer = setInterval(() => {
  //         if (timeleft >= 30) {
  //           clearInterval(downloadTimer);
  //         }
  //         var time = 30 - timeleft;
  //         this.setState({
  //           TimerValue: time,
  //         });

  //         timeleft += 1;
  //         this.setState({
  //           isResendOtp: true,
  //         });

  //         if (timeleft >= 31) {
  //           this.setState({
  //             isResendOtp: false,
  //           });
  //         }
  //       }, 1000);
  //     } else {
  //       notification["error"]({
  //         message: labels.ORDERS,
  //         description: labels.UNABLE_TO_SENT_OTP,
  //       });
  //     }
  //     this.setState({ fullLoading: false });
  //   });
  // };

  // SendCustomerOTP = (splitdata) => {
  //   // console.log("splitdata", splitdata)
  //   let Garment_Count = this.state.invoiceData.noofgarments;
  //   let Estimated_Amount = null;
  //   let tempstr = "";
  //   let SplitNum = "";
  //   if (this.state.mode === "single") {
  //     tempstr = this.state.invoiceData.splitOrderNumber + "/" + Garment_Count;
  //     SplitNum = this.state.invoiceData.splitOrderNumber;
  //   } else {
  //     for (
  //       let i = 0;
  //       i < this.state.invoiceData.lstVMInvoiceDataInvoiceInfo.length;
  //       i++
  //     ) {
  //       tempstr =
  //         tempstr +
  //         "," +
  //         this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[i]
  //           .splitOrderNumber +
  //         "/" +
  //         this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[i].noOfGarment;
  //     }

  //     tempstr = tempstr.slice(1, tempstr.length);
  //     SplitNum = splitdata;
  //   }

  //   const postData = {
  //     CustomerID: parseInt(this.state.invoiceData.customerID),
  //     MobileNo: this.state.invoiceData.mobileNo.toString(),
  //     AlterNativeMobileNo:
  //       this.state.invoiceData.alterNativeMobileNo.toString(),
  //     GarmentCount: Garment_Count,
  //     Amount: Estimated_Amount,
  //     SplitOrderIDGarmentCount: tempstr,
  //     ISPickup: false,
  //     SplitOrderNumber: SplitNum,
  //     type: "Invoice",
  //   };

  //   // console.log("postData->", postData);
  //   this.setState({ fullLoading: true });
  //   this.invoiceService
  //     .SendCustomerOTPForCreateOrder(postData)
  //     .then((response) => {
  //       // console.log("response->", response);

  //       if (response.statusCode === 200) {
  //         notification["success"]({
  //           message: labels.ORDERS,
  //           description: labels.OTP_SENT_SUCCESSFULLY,
  //         });
  //         this.setState({ fullLoading: false, disablegenerateOTP: false });
  //         if (this.state.mode === "single") {
  //           this.setState({
  //             reSendOTP: response.data.otp,
  //           });
  //         } else {
  //           OTParr.push(response.data.otp);
  //         }
  //         var timeleft = 0;
  //         var downloadTimer = setInterval(() => {
  //           if (timeleft >= 30) {
  //             clearInterval(downloadTimer);
  //           }
  //           var time = 30 - timeleft;
  //           this.setState({
  //             TimerValue: time,
  //           });

  //           timeleft += 1;
  //           this.setState({
  //             isResendOtp: true,
  //           });

  //           if (timeleft >= 31) {
  //             this.setState({
  //               isResendOtp: false,
  //             });
  //           }
  //         }, 1000);
  //       } else {
  //         notification["error"]({
  //           message: labels.ORDERS,
  //           description: labels.UNABLE_TO_SENT_OTP,
  //         });
  //       }
  //       this.setState({ fullLoading: false });
  //     });
  // };

  ValidateOTP = () => {
    // if (this.state.mode === "single") {
    if (
      this.state.ReceivedOTP !== null &&
      this.state.ReceivedOTP !== "" &&
      this.state.ReceivedOTP !== undefined
    ) {
      if (this.state.ReceivedOTP != this.state.InputOTP) {
        notification["error"]({
          message: labels.ORDERS,
          description: "Please Enter Valid OTP",
        });
        this.setState({ ISVerified: false, InputOTP: "" });
        this.formInstanceforOTP.current.setFieldsValue({
          OTP: "",
        });
      } else {
        notification["success"]({
          message: labels.ORDERS,
          description: "OTP Varified Successfully",
        });
        this.UpdateVerifiedOTPDateTime(this.state.InputOTP);
        this.setState({ ISVerified: true, InputOTP: "" });
        this.formInstanceforOTP.current.setFieldsValue({
          OTP: "",
        });
      }
    }
    // else {
    //   if (this.state.reSendOTP != this.state.InputOTP) {
    //     notification["error"]({
    //       message: labels.ORDERS,
    //       description: "Please Enter Valid OTP",
    //     });
    //     this.setState({ ISVerified: false, InputOTP: "" });
    //     this.formInstanceforOTP.current.setFieldsValue({
    //       OTP: "",
    //     });
    //   } else {
    //     notification["success"]({
    //       message: labels.ORDERS,
    //       description: "OTP Varified Successfully",
    //     });
    //     this.UpdateVerifiedOTPDateTime(this.state.InputOTP);
    //     this.setState({ ISVerified: true, InputOTP: "" });
    //     this.formInstanceforOTP.current.setFieldsValue({
    //       OTP: "",
    //     });
    //   }
    // }
    // }
  };
  formInstanceforALTNO = React.createRef();

  UpdateVerifiedOTPDateTime = (OTP) => {
    const postData = {
      OTP: OTP,
    };
    this.setState({ modalLoading: true });
    this.invoiceService.UpdateVerifiedOTPDateTime(postData).then((response) => {
      if (response.statusCode === 200) {
        this.setState({ modalLoading: false });
      } else {
        this.setState({ modalLoading: false });
      }
      this.setState({ modalLoading: false });
    });
  };

  render() {
    let post = {
      splitOrderNumber:
        this.state.mode === "single"
          ? this.state.invoiceData.splitOrderNumber
          : this.props.location.state.splitOrderNumber,
      // this.props.match.params.splitOrderID,
    };
    var Settle_Less_Paid_show = JSON.parse(
      localStorage.getItem("buttonAccess")
    ).filter((item) => {
      return item.accessName == "Settle Less Paid";
    });

    var Close_ODM_Order_show = JSON.parse(
      localStorage.getItem("buttonAccess")
    ).filter((item) => {
      return item.accessName == "Close ODM Order";
    });

    const labelColors = [
      "#FF8EB1",
      "#FCC350",
      "#6ABEFF",
      "#7AE473",
      "#FF6A6A",
      "#8F56F3",
      "#E162E5",
      "#B2C12C",
      "#3C76EB",
    ];

    // console.log("data->", this.state.invoiceData.alterNativeMobileNo);

    const { isShowQRCode, base64Image, isQRCodeShow } = this.state;
    var altmobileNo = this.state.invoiceData.alterNativeMobileNo;
    // if (this.state.invoiceData.alterNativeMobileNo != undefined) {
    //   altmobileNo = this.state.invoiceData.alterNativeMobileNo;
    // }
    // console.log("OTPArr", OTParr)
    // console.log("paymentmethod", this.state.paymentMethods);

    return (
      <Spin spinning={this.state.fullLoading}>
        {/* <pre>{JSON.stringify(this.state.invoiceData, null, 2)}</pre> */}

        {isShowQRCode ? (
          <Modal
            title={"QR Code"}
            visible={isQRCodeShow}
            footer={null}
            onCancel={this.handlePopupCancel}
            className="modal-form"
            destroyOnClose
          >
            <Spin spinning={false}>
              <Row gutter={24}>
                <Col xs={24}>
                  <div style={{ marginLeft: "7%" }}>
                    <img
                      src={"data:image/jpeg;base64," + base64Image}
                      alt
                      style={{ width: 400 }}
                    />
                  </div>
                </Col>
              </Row>
            </Spin>
          </Modal>
        ) : null}

        <Row gutter={30}>
          <Col xs={24} lg={15}>
            {/**
             * Page Header Component from Ant Design
             * Please check below link for more options
             * https://ant.design/components/page-header/
             * https://ant.design/components/page-header/#API
             *
             */}
            <PageHeader
              onBack={() => this.props.history.goBack()}
              backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
              title={labels.INVOICE}
              subTitle={
                this.state.mode === "single" &&
                this.state.invoiceData.perfomaInvoiceNumber !== null &&
                this.state.invoiceData.perfomaInvoiceNumber !== "" ? (
                  <Tag color="#FCC350">
                    #{this.state.invoiceData.perfomaInvoiceNumber}
                  </Tag>
                ) : null
              }
              extra={
                this.state.mode === "single" ? (
                  <Button
                    variant="link"
                    type="link"
                    onClick={() => {
                      getPDFProformaInvoice(
                        this.state.invoiceData.orderID,
                        this.state.invoiceData.splitOrderNumber
                      );
                    }}
                  >
                    <img src={PrintIcon} alt="print" />
                  </Button>
                ) : null
              }
            />
            <div className={styles.InvoiceWrapper}>
              <Row gutter={24} align="middle">
                <Col xs={24} lg={12}>
                  <div className={styles.CustomerName}>
                    {this.state.invoiceData.customerName}
                  </div>
                  <div>
                    <small>
                      {this.state.invoiceData.customerTypeName}
                      {this.state.invoiceData.corporateName !== null &&
                      this.state.invoiceData.corporateName !== ""
                        ? " - " + this.state.invoiceData.corporateName
                        : ""}
                    </small>
                  </div>
                  <div className={styles.WalletBalance}>
                    <img src={WalletIcon} alt="wallet" />
                    <span>₹ {this.state.invoiceData.walletBalance}</span>
                  </div>
                  <div className={styles.CustomerAddress}>
                    {this.state.invoiceData.customeraddressName}
                  </div>
                </Col>
                {this.state.mode === "single" ? (
                  <Col xs={24} lg={12} className={styles.TextRightCol}>
                    <div className={styles.OrderNumber}>
                      {labels.ORDER} #{this.state.invoiceData.orderID}
                    </div>
                    <div>
                      {labels.ORDER_TYPE} :{" "}
                      {this.state.invoiceData.ordertypename}
                    </div>
                    <div>
                      {labels.ORDER_DATE} :{" "}
                      {moment(this.state.invoiceData.orderDate).format(
                        "DD MMM YYYY"
                      )}
                    </div>
                    {(this.state.invoiceData.pickupBy !== null) &
                    (this.state.invoiceData.pickupBy !== "") ? (
                      <div>
                        {labels.PICKUP_BY} : {this.state.invoiceData.pickupBy}
                      </div>
                    ) : null}
                    {this.state.invoiceData.pickupDate !== "" ? (
                      <div>
                        {labels.PICKUP_DATE} :{" "}
                        {moment(this.state.invoiceData.pickupDate).format(
                          "DD MMM YYYY"
                        )}
                      </div>
                    ) : null}
                  </Col>
                ) : (
                  <Col lg={12}></Col>
                )}
              </Row>
              <div className={styles.SummaryTableInvoice}>
                <table className={styles.SummaryTable}>
                  <thead>
                    {this.state.mode === "single" ? (
                      <tr>
                        <th>{labels.DETAILS}</th>
                        <th>{labels.ADD_ON_SERVICES}</th>
                        <th className="text-right">{labels.AMOUNT}</th>
                      </tr>
                    ) : (
                      <tr>
                        <th>{labels.PROFORMA_INVOICE}</th>
                        <th style={{ textAlign: "right" }}>
                          {labels.ADJUSTED_LK_BONUS_AMOUNT}
                        </th>
                        <th style={{ textAlign: "right" }}>{labels.AMOUNT}</th>
                      </tr>
                    )}
                  </thead>
                  <tbody>
                    {this.state.mode === "single" ? (
                      <React.Fragment>
                        <tr>
                          <td colSpan={3}>
                            <div
                              className={styles.CategoryLabel}
                              style={{
                                backgroundColor:
                                  labelColors[1 % labelColors.length],
                              }}
                            >
                              {this.state.invoiceData.servicename}
                            </div>
                          </td>
                        </tr>
                        {this.state.invoiceData.vMInvoiceGarmentDetails.map(
                          (item, index) => {
                            return (
                              <tr className={styles.ItemRow} key={index}>
                                <td>
                                  {item.tagNumber} - {item.garmentName}
                                </td>
                                <td>{item.addonservicename}</td>
                                <td className="text-right">
                                  ₹{" "}
                                  {item.serviceamount + item.addOnServiceAmount}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {this.state.invoiceData.lstVMInvoiceDataInvoiceInfo.map(
                          (single) => {
                            return (
                              <tr>
                                <td>{single.performaNumber}</td>
                                <td style={{ textAlign: "right" }}>
                                  ₹ {single.adjustedLKBonusAmt}
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  ₹ {single.finalInvoiceAmount}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </React.Fragment>
                    )}
                  </tbody>
                </table>
                {this.state.mode === "single" ? (
                  <React.Fragment>
                    <div className={styles.SummaryInfoWrap}>
                      <div className={styles.SummaryInfoLabel}>
                        {labels.SUBTOTAL}
                      </div>
                      <div className={styles.SummaryInfoValue}>
                        ₹ {this.state.invoiceData.serviceAmount}
                      </div>
                    </div>

                    <div className={styles.SummaryInfoWrap}>
                      <div className={styles.SummaryInfoLabel}>
                        {labels.ADD_ON_SERVICE_CHARGES}
                      </div>
                      <div className={styles.SummaryInfoValue}>
                        ₹ {this.state.invoiceData.addonserviceamount}
                      </div>
                    </div>

                    <div className={styles.SummaryInfoWrap}>
                      <div className={styles.SummaryInfoLabel}>
                        {labels.ADHOC_CHARGES}
                      </div>
                      <div className={styles.SummaryInfoValue}>
                        ₹ {this.state.adhocAmount}
                      </div>
                    </div>
                    {this.state.mode === "single" ? (
                      <div className={styles.SummaryInfoWrap}>
                        <div className={styles.SummaryInfoLabel}>
                          {labels.ADJUSTED_LK_BONUS_AMOUNT}
                        </div>
                        <div className={styles.SummaryInfoValue}>
                          ₹ {this.state.invoiceData.adjustedLKBonusAmt}
                        </div>
                      </div>
                    ) : null}
                    <div className={styles.SummaryInfoWrap}>
                      <div className={styles.SummaryInfoLabel}>
                        {labels.ADJUSTED_AMOUNT}
                      </div>
                      <div className={styles.SummaryInamountfoValue}>
                        ₹ {this.state.adjustmentAmount}
                      </div>
                    </div>

                    <div className={styles.SummaryInfoWrap}>
                      <div className={styles.SummaryInfoLabel}>
                        {labels.PAIDLESS_AMOUNT}
                      </div>
                      <div className={styles.SummaryInfoValue}>
                        ₹ {this.state.invoiceData.paidLess}
                      </div>
                    </div>
                    {this.state.invoiceData.discountamount !== 0 ? (
                      <React.Fragment>
                        <div className={styles.SummaryInfoWrap}>
                          <div className={styles.SummaryInfoLabel}>
                            {labels.DISCOUNT}
                            <span
                              className={styles.InfoIcon}
                              onClick={() =>
                                this.setState({
                                  displayDiscountInfo:
                                    !this.state.displayDiscountInfo,
                                })
                              }
                            >
                              <img src={infoIcon} alt="i" />
                            </span>
                          </div>
                          <div className={styles.SummaryInfoValue}>
                            ₹ {this.state.invoiceData.discountamount}
                          </div>
                        </div>
                        {this.state.displayDiscountInfo ? (
                          <div className={styles.SummaryInfoSubPart}>
                            <div className={styles.SummaryInfoWrap}>
                              <div className={styles.SummaryInfoLabel}>
                                {this.state.invoiceData.discountname}
                              </div>
                              <div className={styles.SummaryInfoValue}>
                                ₹ {this.state.invoiceData.discountamount}
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                ) : null}

                <div className={styles.SummaryInfoWrap}>
                  <div className={styles.SummaryInfoLabel}>
                    {labels.TAXES_AND_CHARGES}
                    <span
                      className={styles.InfoIcon}
                      onClick={() =>
                        this.setState({
                          displayTaxInfo: !this.state.displayTaxInfo,
                        })
                      }
                    >
                      <img src={infoIcon} alt="i" />
                    </span>
                  </div>
                  <div className={styles.SummaryInfoValue}>
                    ₹{" "}
                    {this.state.mode === "single"
                      ? parseFloat(
                          (
                            parseFloat(this.state.invoiceData.deliverycharges) +
                            parseFloat(
                              this.state.invoiceData.packagingcharges
                            ) +
                            parseFloat(this.calculateTotal("CGST")) +
                            parseFloat(this.calculateTotal("SGST")) +
                            parseFloat(this.calculateTotal("IGST"))
                          ).toFixed(2)
                        )
                      : parseFloat(
                          this.state.invoiceData.totalCGST +
                            this.state.invoiceData.totalSGST +
                            this.state.invoiceData.totalIGST
                        ).toFixed(2)}
                  </div>
                </div>
                {this.state.displayTaxInfo ? (
                  <div className={styles.SummaryInfoSubPart}>
                    {this.state.mode === "single" ? (
                      <React.Fragment>
                        <div className={styles.SummaryInfoWrap}>
                          <div className={styles.SummaryInfoLabel}>
                            {labels.PACKAGING_CHARGES}
                          </div>
                          <div className={styles.SummaryInfoValue}>
                            ₹ {this.state.invoiceData.packagingcharges}
                          </div>
                        </div>
                        <div className={styles.SummaryInfoWrap}>
                          <div className={styles.SummaryInfoLabel}>
                            {labels.DELIVERY_CHARGES}
                          </div>
                          <div className={styles.SummaryInfoValue}>
                            ₹ {this.state.invoiceData.deliverycharges}
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                    <div className={styles.SummaryInfoWrap}>
                      <div className={styles.SummaryInfoLabel}>
                        {labels.CGST}{" "}
                        {this.state.invoiceData.cgstPer !== undefined
                          ? "–" + this.state.invoiceData.cgstPer + "%"
                          : null}
                      </div>
                      <div className={styles.SummaryInfoValue}>
                        ₹{" "}
                        {this.state.mode === "single"
                          ? // ? parseFloat(
                            //     parseFloat(this.state.invoiceData.cgst) -
                            //       parseFloat(
                            //         this.state.invoiceData.adhocCharges
                            //       ) *
                            //         parseFloat(
                            //           (
                            //             this.state.invoiceData.cgstPer / 100
                            //           ).toFixed(2)
                            //         ) +
                            //       this.state.adhocAmount *
                            //         parseFloat(
                            //           (
                            //             this.state.invoiceData.cgstPer / 100
                            //           ).toFixed(2)
                            //         ).toFixed(2)
                            //   ).toFixed(2)
                            this.calculateTotal("CGST")
                          : parseFloat(
                              this.state.invoiceData.totalCGST
                            ).toFixed(2)}
                      </div>
                    </div>
                    <div className={styles.SummaryInfoWrap}>
                      <div className={styles.SummaryInfoLabel}>
                        {labels.SGST}{" "}
                        {this.state.invoiceData.sgstPer !== undefined
                          ? "–" + this.state.invoiceData.sgstPer + "%"
                          : null}
                      </div>
                      <div className={styles.SummaryInfoValue}>
                        ₹{" "}
                        {this.state.mode === "single"
                          ? this.calculateTotal("SGST")
                          : parseFloat(
                              this.state.invoiceData.totalSGST
                            ).toFixed(2)}
                      </div>
                    </div>
                    <div className={styles.SummaryInfoWrap}>
                      <div className={styles.SummaryInfoLabel}>
                        {labels.IGST}{" "}
                        {this.state.invoiceData.igstPer !== undefined
                          ? "–" + this.state.invoiceData.igstPer + "%"
                          : null}
                      </div>
                      <div className={styles.SummaryInfoValue}>
                        ₹{" "}
                        {this.state.mode === "single"
                          ? this.calculateTotal("IGST")
                          : parseFloat(
                              this.state.invoiceData.totalIGST
                            ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className={styles.TotalBlock}>
                  <span>{labels.TOTAL}</span>
                  <span>{this.state.invoiceData.noofgarments}</span>
                  <span>
                    ₹{" "}
                    {this.state.mode === "single"
                      ? //? Math.round(this.calculateTotal().toFixed(2) - 0.01) //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                        Math.round(this.calculateTotal().toFixed(2)) //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                      : parseFloat(
                          this.state.invoiceData.totalFinalInvoiceAmount
                        ).toFixed(2)}
                  </span>
                </div>
                {/* <OrderSummary isInvoice /> */}
              </div>
            </div>
          </Col>
          <Col xs={24} lg={9}>
            <div className={styles.RightSideContainer}>
              <div className={styles.StickyWrapper} id="stickyWrapper">
                <Space direction="vertical" size={10} className="w-100">
                  {this.state.mode === "single" ? (
                    <React.Fragment>
                      <div className={styles.AdhocAmountWrap}>
                        <div>{labels.PENDING_PAYMENT}</div>
                        <div>₹ {this.state.invoiceData.pendigngPayement}</div>
                      </div>
                      <div className={styles.AdhocAmountWrap}>
                        <label>{labels.ADHOC_CHARGES}</label>
                        <InputNumber
                          disabled={this.state.invoiceData.invoiceStatusId >= 2}
                          value={this.state.adhocAmount}
                          step={0.01}
                          onChange={(value) => {
                            if (value === "" || value === null) {
                              value = 0;
                            }
                            this.setState({ adhocAmount: value });
                          }}
                          min={0}
                        />
                      </div>
                      <div className={styles.TotalWrap}>
                        <div className={styles.TotalLabel}>
                          {labels.TOTAL_AMOUNT} :
                        </div>
                        <div className={styles.TotalValue}>
                          ₹{" "}
                          {
                            //Math.round(this.calculateTotal().toFixed(2) - 0.01) //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                            Math.round(this.calculateTotal().toFixed(2)) //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                          }
                        </div>
                      </div>
                      {/**
                       * Please go through below documentation
                       * https://ant.design/components/input/
                       * https://ant.design/components/input/#API
                       *
                       */}
                      {/* <Input
                    prefix={<FontAwesomeIcon icon={faGift} />}
                    suffix={<Button variant="pink">Add</Button>}
                    className={styles.PromocodeInput}
                    placeholder="Promocode"
                  /> */}
                    </React.Fragment>
                  ) : null}
                  {this.state.mode === "single" &&
                  this.state.invoiceData.perfomaInvoiceNumber !== null &&
                  this.state.invoiceData.perfomaInvoiceNumber !== "" ? (
                    <div className={styles.AdhocAmountWrap}>
                      <label>{labels.AMOUNT_TO_COLLECT}</label>
                      <InputNumber
                        disabled={this.state.invoiceData.invoiceStatusId >= 2}
                        value={
                          //Math.round(this.calculateTotal() - this.getPaidTotal() - 0.01) //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                          Math.round(
                            this.calculateTotal() - this.getPaidTotal()
                          ) //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                        }
                        onChange={(value) => {
                          this.setState({
                            newAdjustmentAmount:
                              //Math.round(this.calculateTotal() - 0.01) + //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                              Math.round(this.calculateTotal()) + //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                              this.getAdjustmentAmount(
                                this.state.adjustmentAmount
                              ) -
                              value,
                            isAdjustmentUpdated: true,
                          });
                        }}
                        // // min={
                        // //   Math.round(this.calculateTotal()) +
                        // //   this.state.adjustmentAmount -
                        // //   9
                        // // }
                        // max={9.99}
                        precision={2}
                      />
                    </div>
                  ) : null}
                  <div className={styles.AdhocAmountWrap}>
                    {![undefined, null, []].includes(this.state.invoiceData) &&
                    [2, 3].includes(this.state.invoiceData.deliveryType)
                      ? "Note : " +
                        this.state.invoiceData.deliveryTypeName +
                        " - You have been charged " +
                        this.state.invoiceData.rateRatio +
                        "x  of Standard price."
                      : null}
                  </div>

                  <Input.TextArea
                    placeholder={labels.ADD_REMARKS}
                    disabled={this.state.invoiceData.invoiceStatusId >= 2}
                    value={this.state.remarks}
                    onChange={(e) => this.setState({ remarks: e.target.value })}
                    rows={3}
                  />
                  {this.state.invoiceData.remarkUpdatedBy != "" ? (
                    <div
                      className={styles.AdhocAmountWrap}
                      //style={{ fontStyle: "italic" }}
                    >
                      <div> PI Remarked Updated by</div>
                      <div>{this.state.invoiceData.remarkUpdatedBy}</div>
                    </div>
                  ) : null}
                  <Space
                    direction="vertical"
                    size={8}
                    className={styles.ButtonsWrap}
                  >
                    {this.state.invoiceData.lstVMVMInvoiceDataInvoicePaymentInfo.map(
                      (single) => {
                        return (
                          <>
                            <div className={styles.AdhocAmountWrap}>
                              <div>{single.paymentModeName}</div>
                              {/* <div>₹ {single.amount}</div> */}
                              <div>
                                ₹ {Math.round(single.amount.toFixed(2))}
                              </div>
                              {/* //add here */}
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                              Transaction Details
                            </div>
                            {[3, 4].includes(single.paymentMode) ? (
                              <>
                                {single.sourceFromID === 1 ? (
                                  <>
                                    <div
                                      className={styles.AdhocAmountWrap}
                                      style={{ fontStyle: "italic" }}
                                    >
                                      <div> Transaction ID </div>
                                      <div>{single.transactionNo}</div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className={styles.AdhocAmountWrap}
                                      style={{ fontStyle: "italic" }}
                                    >
                                      <div> Transaction ID </div>
                                    </div>
                                    <div
                                      className={styles.AdhocAmountWrap}
                                      style={{ fontStyle: "italic" }}
                                    >
                                      <div>{single.transactionNo}</div>
                                    </div>
                                  </>
                                )}

                                <div
                                  className={styles.AdhocAmountWrap}
                                  style={{ fontStyle: "italic" }}
                                >
                                  <div> Mode Of Transaction</div>
                                  <div>
                                    {single.sourceFromID === 1 ||
                                    single.sourceFromID === 3
                                      ? single.paymentMode === 4
                                        ? "Paytm QR Code"
                                        : "EDC"
                                      : "PG"}
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div
                              className={styles.AdhocAmountWrap}
                              style={{ fontStyle: "italic" }}
                            >
                              <div> Transaction DateTime</div>
                              <div>
                                {moment(single.paymentDate).format(
                                  "DD-MM-YYYY HH:mm:ss"
                                )}
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}

                    {this.state.invoiceData.invoiceStatusId <= 1 ? (
                      <Button
                        block
                        size="large"
                        variant="purpleOutline"
                        onClick={this.saveUpdateProformaInvoice}
                        style={{ fontSize: 20, fontWeight: "bold" }}
                      >
                        {this.state.invoiceData.perfomaInvoiceNumber !== null &&
                        this.state.invoiceData.perfomaInvoiceNumber !== ""
                          ? labels.UPDATE_PROFORMA_INVOICE
                          : labels.CREATE_PROFORMA_INVOICE}{" "}
                      </Button>
                    ) : null}
                  </Space>

                  {/* Check Now for Paytm */}
                  {/* {(this.state.invoiceData.invoiceStatusId != undefined &&
                    this.state.invoiceData.paytmtranID.length > 0) ||
                  (this.state.invoiceData.lstVMInvoiceDataInvoiceInfo !=
                    undefined &&
                    this.state.invoiceData.lstVMInvoiceDataInvoiceInfo.length >
                      0 &&
                    this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[0]
                      .invoiceStatus != undefined &&
                    this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[0]
                      .paytmtranID.length > 0) ? (
                    <Button
                      block
                      size="large"
                      variant="purpleOutline"
                      onClick={this.CheckPaymentStatus}
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Check Now
                    </Button>
                  ) : null} */}
                  

                  {/* Check Now for PhonePe */}
                  {(this.state.invoiceData.invoiceStatusId != undefined &&
                    this.state.invoiceData.phonePetranID.length > 0) ||
                  (this.state.invoiceData.lstVMInvoiceDataInvoiceInfo !=
                    undefined &&
                    this.state.invoiceData.lstVMInvoiceDataInvoiceInfo.length >
                      0 &&
                    this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[0]
                      .invoiceStatus != undefined &&
                    this.state.invoiceData.lstVMInvoiceDataInvoiceInfo[0]
                      .phonePetranID.length > 0) ? (
                    <Button
                      block
                      size="large"
                      variant="purpleOutline"
                      onClick={this.CheckPhonePeStatus}
                      style={{ fontSize: 20, fontWeight: "bold" }}
                    >
                      Check Now
                    </Button>
                  ) : null}

                  {/* Added By Hasti - Delivery OTP Conformation */}
                  <Space direction="vertical" size={8}>
                    {!this.state.ISVerified &&
                    this.state.invoiceData.invoiceStatusId != 3 ? (
                      <div>
                        <div>
                          <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                            OTP is sent to Registered mobile number & App
                          </Text>
                        </div>
                        {/* UnComment By Hasti - L16814 */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 7,
                          }}
                        >
                          <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                            Registered number :{" "}
                          </Text>
                          <Text>{this.state.invoiceData.mobileNo}</Text>
                        </div>
                        {/* Comment By Hasti - L16815 */}
                        {/* {altmobileNo != undefined ? (
                          <div>
                            <Form
                              ref={this.formInstanceforALTNO}
                              initialValues={{ altmobileNo }}
                            >
                              <Form.Item
                                name="altmobileNo"
                                validateFirst={true}
                                rules={[
                                  {
                                    pattern: "[6789][0-9]{9}",
                                    message:
                                      labels.PLEASE_ENTER_VALID_PHONE_NUMBER,
                                  },
                                ]}
                              >
                                <Input
                                  suffix={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexdirection: "row",
                                      }}
                                    >
                                      <div
                                        style={{ marginRight: 5, marginTop: 7 }}
                                      >
                                        <FontAwesomeIcon icon={faPencilAlt} />
                                        <Popconfirm
                                          title="Are you sure want to delete Alternative number ?"
                                          onConfirm={
                                            this.DeleteAltNumberForCustomer
                                          }
                                          onCancel={() => null}
                                          okText="Yes"
                                          cancelText="No"
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            style={{ marginLeft: 10 }}
                                          />
                                        </Popconfirm>
                                      </div>
                                      <div>
                                        <Button
                                          type="small"
                                          variant="pink"
                                          onClick={this.AddAltNumberForCustomer}
                                          disabled={
                                            !this.state.ALTmobileNo.length > 0
                                          }
                                        >
                                          Submit
                                        </Button>
                                      </div>
                                    </div>
                                  }
                                  className={styles.PromocodeInput}
                                  // placeholder="Alternative Number"
                                  onChange={(e) =>
                                    this.setState({
                                      ALTmobileNo: e.target.value,
                                    })
                                  }
                                />
                              </Form.Item>
                            </Form>
                          </div>
                        ) : null} */}
                        {/* UnComment By Hasti - L16814 */}
                        <div>
                          <Button
                            onClick={this.SendCustomerOTPForCreateOrder}
                            block
                            size="large"
                            disabled={this.state.disablegenerateOTP}
                          >
                            Generate OTP
                          </Button>
                        </div>

                        <div style={{ marginTop: 10 }}>
                          <Text style={{ fontSize: 13 }}>
                            OTP will be sent to Regesterd mobile number
                          </Text>
                        </div>
                        <div>
                          <Form
                            style={{ display: "block" }}
                            ref={this.formInstanceforOTP}
                          >
                            <Form.Item name="OTP" validateFirst={true}>
                              <Input
                                suffix={
                                  <Button
                                    type="small"
                                    variant="pink"
                                    onClick={this.ValidateOTP}
                                    disabled={!this.state.InputOTP.length > 0}
                                  >
                                    Submit
                                  </Button>
                                }
                                // onPressEnter={this.ValidateOTP}
                                className={styles.PromocodeInput}
                                placeholder="Enter OTP"
                                value={
                                  this.state.InputOTP === ""
                                    ? null
                                    : this.state.InputOTP
                                }
                                onChange={(e) =>
                                  this.setState({
                                    InputOTP: e.target.value,
                                  })
                                }
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Button
                                  type="link"
                                  onClick={this.SendCustomerOTPForCreateOrder}
                                  // onClick={this.ResendDeliveryOTP}
                                  // onClick={this.ResendOTP} Comment by Hasti for Revert L16814
                                  disabled={this.state.isResendOtp}
                                >
                                  <Text
                                    style={{
                                      textDecoration: "underline",
                                      fontSize: 13,
                                    }}
                                  >
                                    Resend OTP
                                  </Text>
                                </Button>
                                <Text
                                  style={{
                                    marginTop: 4,
                                    fontWeight: "bold",
                                    fontSize: 15,
                                    marginRight: 4,
                                  }}
                                >
                                  0:{this.state.TimerValue}
                                </Text>
                              </div>
                            </Form.Item>
                          </Form>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            style={{ width: "40%", marginLeft: 20, height: 35 }}
                            variant="pink"
                            size="small"
                            block
                            disabled={!this.state.ISVerified}
                          >
                            {labels.SUBMIT}
                          </Button>

                          <Button
                            onClick={() => {
                              // this.formInstanceforOTP.current.setFieldsValue({
                              //   OTP: "",
                              // });
                              this.setState({
                                InputOTP: "",
                              });
                            }}
                            style={{ width: "40%", marginRight: 20 }}
                            variant="pink"
                            size="small"
                            block
                          >
                            {labels.CANCEL}
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </Space>
                  {/* End */}

                  <Space
                    direction="vertical"
                    size={8}
                    className={styles.ButtonsWrap}
                  >
                    {(this.state.invoiceData.invoiceStatusId === 1 ||
                      this.state.mode !== "single") &&
                    this.state.ISVerified ? (
                      <React.Fragment>
                        {
                          //Math.round(this.calculateTotal() - 0.01) > 0 ? (  //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                          Math.round(this.calculateTotal()) > 0 ? ( //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                            <React.Fragment>
                              <div
                                className={styles.AdhocAmountWrap}
                                style={{ marginTop: 50 }}
                              >
                                <div>
                                  <Checkbox
                                    checked={this.state.useLKWallet}
                                    onChange={(event) =>
                                      this.setState(
                                        {
                                          useLKWallet: event.target.checked,
                                        },
                                        () => this.GenerateInvoiceBalance()
                                      )
                                    }
                                  >
                                    {labels.USE_LK_WALLET}
                                  </Checkbox>
                                </div>
                                <div>
                                  ₹{" "}
                                  {
                                    //Math.round(this.calculateTotal() - 0.01) >= //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                    Math.round(this.calculateTotal()) >= //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                    this.state.invoiceData.walletBalance
                                      ? this.state.invoiceData.walletBalance.toFixed(
                                          2
                                        )
                                      : //: Math.round(this.calculateTotal() - 0.01)} //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                        Math.round(this.calculateTotal()) //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                  }
                                  {/* {this.state.useLKWallet
                              ? this.calculateTotal() >=
                                this.state.invoiceData.walletBalance
                                ? this.state.invoiceData.walletBalance.toFixed(
                                    2
                                  )
                                : this.calculateTotal()
                              : 0} */}
                                </div>
                              </div>

                              <div className={styles.AdhocAmountWrap}>
                                <div>{labels.WALLET_BALANCE}</div>
                                <div>
                                  ₹{" "}
                                  {this.state.useLKWallet
                                    ? //? Math.round(this.calculateTotal() - 0.01) >= //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                      Math.round(this.calculateTotal()) >= //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                      this.state.invoiceData.walletBalance
                                      ? 0
                                      : this.state.invoiceData.walletBalance -
                                        // Math.round(this.calculateTotal() - 0.01) //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                        Math.round(this.calculateTotal()) //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                    : this.state.invoiceData.walletBalance}
                                  {/* {this.state.useLKWallet
                              ? this.calculateTotal() >=
                                this.state.invoiceData.walletBalance
                                ? this.state.invoiceData.walletBalance.toFixed(
                                    2
                                  )
                                : this.calculateTotal()
                              : 0} */}
                                </div>
                              </div>
                            </React.Fragment>
                          ) : null
                        }

                        {
                          //Math.round(this.calculateTotal() - 0.01) > 0 ? (   //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                          Math.round(this.calculateTotal()) > 0 ? ( //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                            <React.Fragment>
                              <div className={styles.AdhocAmountWrap}>
                                {/* <Select
                                  placeholder={labels.PAYMENT_METHOD}
                                  disabled={this.state.paymentMethods.disabled}
                                  options={this.state.paymentMethods.data.filter(
                                    (x) => x.label.toLowerCase() !== "lk wallet"
                                  )}
                                  value={this.state.paymentMethods.selected}
                                  onChange={(value) =>
                                    this.setState(
                                      {
                                        paymentMethods: {
                                          ...this.state.paymentMethods,
                                          selected: value,
                                        },
                                      },
                                      () =>
                                        console.log(
                                          this.state.paymentMethods.selected
                                        )
                                    )
                                  }
                                /> */}
                                <Select
                                  showSearch
                                  placeholder={labels.PAYMENT_METHOD}
                                  className={styles.test1}
                                  disabled={this.state.paymentMethods.disabled}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  onChange={(value) =>
                                    this.setState(
                                      {
                                        paymentMethods: {
                                          ...this.state.paymentMethods,
                                          selected: value,
                                        },
                                      },
                                      () =>
                                        console.log(
                                          this.state.paymentMethods.selected
                                        )
                                    )
                                  }
                                >
                                  {/* {this.state.stateListOptions.length !== 0? */}
                                  {
                                    this.state.paymentMethods.data.map((x) => {
                                      return x.value != -1 && x.value != 2 ? (
                                        <option key={x.value} value={x.value}>
                                          {x.label}
                                        </option>
                                      ) : null;
                                    })
                                    // :null
                                    // }
                                  }
                                </Select>

                                <InputNumber
                                  value={this.state.restAmount}
                                  step={0.01}
                                  onChange={(value) => {
                                    if (value === "" || value === null) {
                                      value = 0;
                                    }
                                    this.setState({ restAmount: value });
                                  }}
                                  min={0}
                                  // max={
                                  //   this.state.useLKWallet
                                  //     ? parseInt((Math.round(this.calculateTotal()) -
                                  //         this.state.invoiceData.walletBalance)) <
                                  //       0
                                  //       ? 100
                                  //       : Math.round(this.calculateTotal()) -
                                  //         this.state.invoiceData.walletBalance
                                  //     : Math.round(this.calculateTotal()) + 100
                                  // }
                                />
                              </div>
                            </React.Fragment>
                          ) : null
                        }              
                        <Popconfirm
                          visible={this.state.visible}
                          onVisibleChange={(visible) => {
                            if (!visible) {
                              this.setState({ visible: visible });
                              return;
                            }
                            if (
                              this.state.useLKWallet
                                ? this.state.invoiceData.walletBalance >
                                  // Math.round(this.calculateTotal() - 0.01) //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                  Math.round(this.calculateTotal()) //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                  ? false
                                  : //: Math.round(this.calculateTotal() - 0.01) < //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                  Math.round(this.calculateTotal()) < //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                    this.state.restAmount +
                                      this.state.invoiceData.walletBalance
                                  ? true
                                  : false
                                : this.state.restAmount >
                                  //Math.round(this.calculateTotal() - 0.01) //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                  Math.round(this.calculateTotal()) //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                ? true
                                : false
                            ) {
                              this.setState({ visible: visible }); // show the popconfirm
                            } else {
                              this.CheckPaytmEDCMachineTransData();
                            }
                          }}
                          title={
                            labels.DO_YOU_WANT_TO_ADD_THE_EXCESS_AMOUNT_TO_THE_LKWALLET +
                            " ?"
                          }
                          placement="bottom"
                          okText={labels.YES}
                          cancelText={labels.NO}
                          onConfirm={() => {
                            this.addExcessAmountToWallet();
                          }}
                          onCancel={() => this.CheckPaytmEDCMachineTransData()}
                        >
                          {(localStorage
                            .getItem("roleNames")
                            .split(",")
                            .indexOf("Admin") > -1 &&
                            this.state.invoiceData.pickupBy == "" &&
                           (this.state.invoiceData.pickupDate == "" ||  this.state.invoiceData.assignedToUserID ==0 )) ||
                          (this.state.invoiceData.lstVMInvoiceDataInvoiceInfo &&
                          this.state.invoiceData.lstVMInvoiceDataInvoiceInfo
                            .length > 0
                            ? this.state.invoiceData
                                .lstVMInvoiceDataInvoiceInfo[0].invoiceStatus ==
                              1
                            : this.state.invoiceData.invoiceStatusId == 1 &&
                              this.state.invoiceData.pickupBy == "" &&
                             (this.state.invoiceData.pickupDate == "" || this.state.invoiceData.assignedToUserID ==0)) ? (
                            <Button
                              style={{ fontSize: 20, fontWeight: "bold" }}
                              block
                              size="large"
                              variant="purple"
                              disabled={
                                //Math.round(this.calculateTotal() - 0.01) > 0  //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                Math.round(this.calculateTotal()) > 0 //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                  ? this.state.useLKWallet
                                    ? //? Math.round(this.calculateTotal() - 0.01) >    //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                      Math.round(this.calculateTotal()) > //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
                                      this.state.invoiceData.walletBalance
                                      ? this.state.restAmount === 0
                                        ? true
                                        : false
                                      : false
                                    : this.state.restAmount === 0
                                    ? true
                                    : false
                                  : false
                              }
                              // onClick={() => console.log("Create Tax Invoice")}
                              // onClick={this.generateTaxInvoice}
                            >
                              {labels.GENERATE_TAX_INVOICE}
                            </Button>
                          ) : this.state.invoiceData
                              .lstVMInvoiceDataInvoiceInfo &&
                            this.state.invoiceData.lstVMInvoiceDataInvoiceInfo
                              .length > 0 ? (
                            this.state.invoiceData
                              .lstVMInvoiceDataInvoiceInfo[0].invoiceStatus == 1
                          ) : this.state.invoiceData.invoiceStatusId == 1 &&
                            this.state.invoiceData.pickupBy !== "" &&
                            this.state.invoiceData.pickupDate !== "" &&
                            Close_ODM_Order_show.length ? (
                            <Button block size="large" variant="purple">
                              {labels.GENERATE_TAX_INVOICE}
                            </Button>
                          ) : null}
                        </Popconfirm>
                      </React.Fragment>
                    ) : null}
                    {/* localStorage
                      .getItem("roleNames")
                      .split(",")
                      .indexOf("Admin") > -1 */}
                    {/* {JSON.stringify(this.state.invoiceData)} */}
                    {parseInt(this.state.invoiceData.invoiceStatusId) !== 3 &&
                    Settle_Less_Paid_show.length > 0 &&
                    this.state.mode === "single" &&
                    this.state.ISVerified ? (
                      <Button
                        style={{ fontSize: 20, fontWeight: "bold" }}
                        block
                        size="large"
                        variant="purple"
                        onClick={this.markSettled}
                      >
                        {labels.SETTLE_LESS_PAID_INVOICE}
                      </Button>
                    ) : null}

                    {this.state.invoiceData
                      .lstVMVMInvoiceDataInvoicePaymentInfo != undefined &&
                    this.state.invoiceData.lstVMVMInvoiceDataInvoicePaymentInfo
                      .length > 0 &&
                    parseInt(this.state.invoiceData.invoiceStatusId) == 1 ? (
                      <Button
                        block
                        size="large"
                        variant="purple"
                        onClick={this.settleWithoutPayment}
                      >
                        {labels.SETTLE_WITHOUT_PAYMENT}
                      </Button>
                    ) : null}
                  </Space>
                  {this.state.invoiceData.invoiceStatusId == 3 ? (
                    <div>
                      <Input.TextArea
                        placeholder={labels.UPDATE_INVOICE_REMARK}
                        disabled={
                          this.state.invoiceData.invoiceRemarkUpdatedBy != ""
                        }
                        value={this.state.invoiceremark}
                        onChange={(e) =>
                          this.setState({ invoiceremark: e.target.value })
                        }
                        // rows={3}
                      />
                      {this.state.invoiceData.invoiceRemarkUpdatedBy == "" ? (
                        <Button
                          block
                          size="large"
                          variant="purple"
                          onClick={this.UpdateInvoiceRemark}
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          {labels.UPDATE_INVOICE_REMARK}
                        </Button>
                      ) : null}

                      {this.state.invoiceData.invoiceRemarkUpdatedBy != "" ? (
                        <div className={styles.AdhocAmountWrap}>
                          <div> Invoice Remark Updated by</div>
                          <div>
                            {this.state.invoiceData.invoiceRemarkUpdatedBy}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </Space>
              </div>
            </div>
          </Col>
        </Row>

        <Modal
          title={labels.PROCESSING}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          maskClosable={false}
        >
          <Form
            layout="vertical"
            onFinish={this.handlePopupAdd}
            //initialValues={{ cityName, stateName }}
          >
            <Row gutter={24}>
              <Col xs={24}>
                {labels.PROCESSING}.....
                {/* <Button htmlType="submit" variant="purple">
                  Search
                      </Button> */}
              </Col>

              {/* <Col xs={24} className="custom-modal-footer">
                <Space>
                  <Button htmlType="submit" variant="purple">
                    </Button>
                </Space>
              </Col> */}
            </Row>
          </Form>
        </Modal>

        <Modal
          title={"Payment Detail"}
          visible={this.state.showPaymentDetailModal}
          footer={null}
          onCancel={() => {
            this.setState({
              showPaymentDetailModal: false,
            });
          }}
          className="modal-form"
          destroyOnClose
          maskClosable={false}
        >
          <div>
            {this.state.IsDetailShow ? (
              <>
                <div style={{ marginBottom: 30 }}>
                  <div>
                    <b>Payment ID : </b>
                    {this.state.paymentDetail.ORDERID}
                  </div>
                  <div>
                    <b>Amount : </b> {this.state.paymentDetail.TXNAMOUNT}
                  </div>
                  {/* <div>
                    <b>Transaction Date : </b>
                    {this.state.paymentDetail.TXNDATE}
                  </div> */}
                  <div>
                    <b>Transaction Status : </b>
                    {this.state.paymentDetail.STATUS}
                  </div>
                  <div>
                    <b>Transaction Message : </b>
                    {this.state.paymentDetail.RESPMSG}
                  </div>
                </div>
                {this.state.paymentDetail.STATUS == "PAYMENT_SUCCESS" ? (
                  <div>
                    <Popconfirm
                      title="Are you sure want to Settle Invoice ?"
                      onConfirm={() =>
                        this.GenerateTaxInvoiceFromCheckNow(
                          this.state.paymentDetail.ORDERID
                        )
                      }
                      onCancel={() => null}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button style={{ float: "right", marginTop: -30 }}>
                        Settle Invoice
                      </Button>
                    </Popconfirm>
                  </div>
                ) : null}
              </>
            ) : (
              <div>Transaction details not found</div>
            )}
          </div>
        </Modal>
      </Spin>
    );
  }

  GenerateInvoiceBalance = () => {
    if (
      this.state.invoiceData.walletBalance >=
      //Math.round(this.calculateTotal() - 0.01)    //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
      Math.round(this.calculateTotal()) //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
    ) {
      this.setState({
        restAmount: 0.0,
      });
    } else {
      this.setState({
        // restAmount: Math.round(this.calculateTotal() - this.state.invoiceData.walletBalance - 0.01), //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
        restAmount: Math.round(
          this.calculateTotal() - this.state.invoiceData.walletBalance
        ), //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
      });
    }
    if (!this.state.useLKWallet) {
      this.setState({
        //restAmount: Math.round(this.calculateTotal() - 0.01), //Commented by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
        restAmount: Math.round(this.calculateTotal()), //Removed "- 0.01" by Parth Jotangiya for RED ticket no: #L15797 on 2021-07-14
      });
    }
  };

  handlePopupCancel = () => {
    confirm({
      title: "Do you want to kill these payment process?",
      content: "When clicked the OK button, Stop the payment process",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 3000);
          setTimeout(function () {
            window.location.reload();
          }, 4000);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
}

export default withRouter(Invoice);
