import {
  PageHeader,
  Table,
  Checkbox,
  Select,
  notification,
  Spin,
  Space,
} from "antd";
import Button from "../../../components/Common/Button/button";
import React from "react";
// import TableActions from "../../components/Common/TableActions/tableactions";
import RoleService from "../../../services/roleservice";
import AuthService from "../../../services/authservice";
import jwt from "jsonwebtoken";

const { Option } = Select;
class Roles extends React.Component {
  roleService = new RoleService();
  authService = new AuthService();
  state = {
    table: {
      loading: false,
      data: [],
    },
    roles: {
      disabled: true,
      data: [],
    },
    roleAccess: {
      disabled: true,
      data: [],
    },
    selectedRoleAccess: "",
    change: {},
    roleId: 0,
    roleAccessbyRole: {},
    showRoleAccessMenu: false,
  };
  updateChange = (screen, key, value) => {
    this.setState(
      {
        change: {
          ...this.state.change,
          [screen.screenID]: {
            view: key === "view" && value === false ? false : screen.isView,
            update: key === "view" && value === false ? false : screen.isUpdate,
            add: key === "view" && value === false ? false : screen.isAdd,
            delete: key === "view" && value === false ? false : screen.isDelete,
            [key]: key === "view" && value === false ? false : value,
          },
        },
      },
      () => {
        if (key !== "view") {
          if (this.state.change[screen.screenID] !== undefined) {
            let rights = this.state.change[screen.screenID];
            if (rights.view || rights.add || rights.update || rights.delete) {
              this.updateChange(screen, "view", true);
            } else {
              this.updateChange(screen, "view", false);
            }
          }
        }
      }
    );
    let originalData = [...this.state.table.data];
    let updatedData = originalData.map((single) => {
      if (single.parentID === screen.parentID) {
        single.rights = single.rights.map((right) => {
          if (right.screenID === screen.screenID) {
            if (key === "view") {
              right.isView = value;
            } else if (key === "add") {
              right.isAdd = value;
            } else if (key === "update") {
              right.isUpdate = value;
            } else if (key === "delete") {
              right.isDelete = value;
            }
          }
          return right;
        });
        return single;
      }
    });
  };
  expandedRowRender = (record1) => {
    const columns = [
      { title: "Screen", dataIndex: "screenName", key: "screenName" },
      {
        title: "View",
        dataIndex: "isView",
        key: "isView",
        width: "100px",
        align: "center",
        render: (text, record, index) => (
          <Checkbox
            checked={
              this.state.change[record.screenID] !== undefined
                ? this.state.change[record.screenID].view
                : record.isView
            }
            defaultChecked={record.isView}
            onChange={(event) => {
              this.updateChange(record, "view", event.target.checked);
            }}
          ></Checkbox>
        ),
      },
      {
        title: "Create",
        dataIndex: "create",
        key: "create",
        width: "100px",
        align: "center",
        render: (text, record, index) => (
          <Checkbox
            checked={
              this.state.change[record.screenID] !== undefined
                ? this.state.change[record.screenID].add
                : record.isAdd
            }
            defaultChecked={record.isAdd}
            disabled={record1.parentName === "Reports" ? true : false}
            onChange={(event) => {
              this.updateChange(record, "add", event.target.checked);
            }}
          ></Checkbox>
        ),
      },
      {
        title: "Edit",
        dataIndex: "edit",
        key: "edit",
        width: "100px",
        align: "center",
        render: (text, record, index) => (
          <Checkbox
            checked={
              this.state.change[record.screenID] !== undefined
                ? this.state.change[record.screenID].update
                : record.isUpdate
            }
            defaultChecked={record.isUpdate}
            disabled={record1.parentName === "Reports" ? true : false}
            onChange={(event) => {
              this.updateChange(record, "update", event.target.checked);
            }}
          ></Checkbox>
        ),
      },
      {
        title: "Delete",
        dataIndex: "delete",
        key: "delete",
        width: "100px",
        align: "center",
        render: (text, record, index) => (
          <Checkbox
            checked={
              this.state.change[record.screenID] !== undefined
                ? this.state.change[record.screenID].delete
                : record.isDelete
            }
            defaultChecked={record.isDelete}
            disabled={record1.parentName === "Reports" ? true : false}
            onChange={(event) => {
              this.updateChange(record, "delete", event.target.checked);
            }}
          ></Checkbox>
        ),
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={record1.rights}
        pagination={false}
        size="small"
      />
    );
  };
  columns = [{ title: "Screen", dataIndex: "parentName", key: "parentName" }];
  componentDidMount() {
    this.getRoleList();
    this.getRoleAccessList();
  }
  getRoleList() {
    this.roleService.getRolesList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          roles: {
            ...this.state.roles,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Role Operations",
          description: response.message
            ? response.message
            : "Unable to get data for roles",
        });
      }
    });
  }

  getRoleAccessList() {
    this.roleService.getRolesAccessList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          roleAccess: {
            ...this.state.roleAccess,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Role Operations",
          description: response.message
            ? response.message
            : "Unable to get data for role access",
        });
      }
    });
  }

  // getTableData(id) {
  //   this.setState({
  //     table: { ...this.state.table, loading: true },
  //     roleId: id,
  //     change: {},
  //   });
  //   this.roleService.getRolesRights(id).then((response) => {
  //     console.log("response->",response);
  //     if (response.statusCode === 200) {
  //       let screenRignt= response.data.vmScreenRights;
  //       console.log("screenRignt->",screenRignt);
  //       var flags = [],
  //         output = [],
  //         l = response.data.length,
  //         i;
  //       for (i = 0; i < l; i++) {
  //         if (flags[response.data[i].parentID]) continue;
  //         flags[response.data[i].parentID] = true;
  //         let matchParent = response.data[i].parentID;
  //         const filteredData = response.data.filter((value) => {
  //           return matchParent === value.parentID;
  //         });
  //         let prepare = {
  //           key: response.data[i].parentID,
  //           parentID: response.data[i].parentID,
  //           parentName: response.data[i].parentName,
  //           rights: filteredData,
  //         };
  //         output.push(prepare);
  //       }
  //       this.setState({
  //         table: {
  //           ...this.state.table,
  //           data: output,
  //           loading: false,
  //         },
  //       });
  //     } else {
  //       notification["error"]({
  //         message: "Role Operations",
  //         description: "Unable to get data for screen rights",
  //       });
  //     }
  //   });
  // }

  getTableData(id) {
    this.setState({
      table: { ...this.state.table, loading: true },
      roleId: id,
      change: {},
    });
    this.roleService.getRolesRights(id).then((response) => {
      // this.setState({
      //   roleAccessbyRole: []
      // });
      if (response.statusCode === 200) {
        let screenRignt = response.data.vmScreenRights;
        var flags = [],
          output = [],
          l = screenRignt.length,
          i;
        for (i = 0; i < l; i++) {
          if (flags[screenRignt[i].parentID]) continue;
          flags[screenRignt[i].parentID] = true;
          let matchParent = screenRignt[i].parentID;
          const filteredData = screenRignt.filter((value) => {
            return matchParent === value.parentID;
          });
          let prepare = {
            key: screenRignt[i].parentID,
            parentID: screenRignt[i].parentID,
            parentName: screenRignt[i].parentName,
            rights: filteredData,
          };
          output.push(prepare);
        }
        this.setState({
          table: {
            ...this.state.table,
            data: output,
            loading: false,
          },
        });
        this.setState({
          roleAccessbyRole: response.data.vmRoleAccessRes,
        });
        this.showRole(response.data.vmRoleAccessRes);
      } else {
        notification["error"]({
          message: "Role Operations",
          description: "Unable to get data for screen rights",
        });
      }
    });
  }

  showRole = (data) => {
   // console.log("data->", data);
    let str = "";
    for (let i = 0; i < data.length; i++) {
      str = str + data[i].accessID + ",";
    }
    str = str.substring(0, str.length - 1);
    this.setState({
      selectedRoleAccess: str,
      showRoleAccessMenu: true,
    });
   
  };

  UpdateRoles = () => {
    let array = [];
    for (let key in this.state.change) {
      let value = this.state.change[key];
      let obj = {
        roleID: this.state.roleId,
        screenID: parseInt(key),
        isAdd: value.add,
        isUpdate: value.update,
        isDelete: value.delete,
        isView: value.view,
      };
      array.push(obj);
    }

    let tempobj = {
      roleID: this.state.roleId,
      roleAccess: this.state.selectedRoleAccess,
    };

    let PostData = {
      vMUpdateRoleOperationLink: array,
      vMUpdateRoleAccess: tempobj,
    };

    // console.log("PostData->",PostData);
    this.setState({ table: { ...this.state.table, loading: true } });
    this.roleService.UpdateRoles(PostData).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          table: {
            ...this.state.table,
          },
        });
        this.getTableData(this.state.roleId);
        notification["success"]({
          message: "Role Operations",
          description: "Data is submitted successfully",
        });
        this.setState({ table: { ...this.state.table, loading: true } });
        this.authService
          .GetScreenRightsByUser(
            parseInt(
              jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
                .unique_name
            )
          )
          .then((rights) => {
            localStorage.setItem("userrights", JSON.stringify(rights.data.vmUserScreenRights));
            this.setState({ table: { ...this.state.table, loading: false } });
            window.location.reload();
          });
      }
      this.setState({ table: { ...this.state.table, loading: false } });
    });
  };
  render() {
    return (
      <React.Fragment>
        <PageHeader
          title={
            <Space size="large">
              <span>Role Screen Mapping</span>
              <Select
                style={{ minWidth: 200 }}
                placeholder="Select Role"
                key="1"
                disabled={this.state.roles.disabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value) => {
                  this.getTableData(parseInt(value));
                }}
              >
                {this.state.roles.data.length !== 0
                  ? this.state.roles.data.map((role) => {
                      return role.value !== "-1" ? (
                        <Option value={role.value} key={role.value}>
                          {role.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
              {this.state.showRoleAccessMenu ? (
                <div>
                  <Select
                    showSearch
                    allowClear
                    style={{ minWidth: 200, marginRight: 20 }}
                    mode="multiple"
                    placeholder="Select Role Access"
                    key="2"
                    disabled={this.state.roleAccess.disabled}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={
                      this.state.selectedRoleAccess === ""
                        ? []
                        : this.state.selectedRoleAccess.split(",")
                    }
                    // value={
                    //   [null, undefined, ""].includes(
                    //     this.state.selectedRoleAccess
                    //   )
                    //     ? null
                    //     : this.state.selectedRoleAccess.split(",")
                    // }
                    onChange={(event) => {
                      this.setState(
                        {
                          selectedRoleAccess: [null, undefined, ""].includes(
                            event
                          )
                            ? ""
                            : event.join(","),
                        },
                        () => {
                          // this.handleChangeBranch();
                        }
                      );
                    }}
                  >
                    {this.state.roleAccess.data.length !== 0
                      ? this.state.roleAccess.data.map((e) => {
                          return  e.value !== "-1" ? (
                            <Option value={e.value} key={e.value}>
                              {e.text}
                            </Option>
                          ):null;
                        })
                      : null}
                  </Select>

                  {/* <Checkbox
                    checked={
                      ![null, undefined, ""].includes(
                        this.state.selectedRoleAccess
                      ) &&
                      this.state.selectedRoleAccess.split(",").length ===
                        this.state.roleAccess.data.length &&
                      this.state.selectedRoleAccess.split(",").slice().sort()
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        let allOpt = this.state.roleAccess.data
                          .map((Item) => {
                            return Item.value;
                          })
                          .filter((x) => x !== "-1");
                        this.setState({
                          selectedRoleAccess: allOpt.join(","),
                        });
                      } else {
                        this.setState({
                          selectedRoleAccess: "1",
                        });
                      }
                    }}
                  >
                    ALL
                  </Checkbox> */}
                </div>
              ) : null}
            </Space>
          }
          extra={[
            <Button
              htmlType="submit"
              shape="rounded"
              size="large"
              onClick={this.UpdateRoles}
              loading={this.state.table.loading}
            >
              Submit
            </Button>,
          ]}
        />
        <Spin
          spinning={this.state.table.loading}
          key={this.state.table.loading}
        >
          <Table
            className="components-table-demo-nested"
            columns={this.columns}
            expandRowByClick={true}
            expandable={{ expandedRowRender: this.expandedRowRender }}
            dataSource={this.state.table.data}
            pagination={false}
            size="small"
          />
        </Spin>
      </React.Fragment>
    );
  }
}

export default Roles;
