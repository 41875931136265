import React from "react";
import styles from "./statscomponent.module.css";
import { Row, Col, notification } from "antd";
import TruckIcon from "../../../images/Dashboard/truck.svg";
import TshirtIcon from "../../../images/Dashboard/tshirt.svg";
import BagIcon from "../../../images/Dashboard/bag.svg";
import WalletIcon from "../../../images/Dashboard/wallet.svg";
import FactoryDashboard from "../../../services/factorydashboardservice";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
/**
 * This file holds the view of statastics cards placed on the top row of dashboard
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
export default class Stats extends React.Component {
  factoryDashboard = new FactoryDashboard();
  common = new Common();

  /**
   * Array of objects to display the stats
   */

  state = {
    dashboardData: [],
  };

  statsData = [
    {
      title: "Pending Deliveries",
      count: 10,
      icon: TruckIcon,
    },
    {
      title: "Pending Take Aways",
      count: 25,
      icon: TshirtIcon,
    },
    {
      title: "Pending Pickups",
      count: 12,
      icon: BagIcon,
    },
    {
      title: "Collection Amount Due",
      count: "₹1200",
      icon: WalletIcon,
    },
  ];

  componentWillMount() {
    this.GetFactoryDashboard();
  }

  GetFactoryDashboard() {
    this.props.toggleLoading(true);
    this.factoryDashboard
      .GetFactoryDashboard(parseInt(localStorage.getItem("branchID")))
      .then((response) => {
        if (response.statusCode === 200) {
          
          this.setState({
            dashboardData: response.data,
          });
        } else {
          notification["error"]({
            message: labels.DASHBOARD,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_DATA_FOR_DASHBOARD,
          });
        }
        this.props.toggleLoading(false);
      });
  }

  render() {
    const StatsBox = (props) => {
      /**
       * Array of background colors
       */
      // const colors = ["#FE1D7F", "#FCC350", "#6ABEFF", "#7E13D5"];
      const colors = [
        "#FE1D7F",
        "#FCC350",
        "#6ABEFF",
        "#53da4a",
        "#7E13D5",
        "#FF3333",
        "#E162E5",
        "#B2C12C",
        "#3C76EB",
      ];
      StatsBox.count++;
      return (
        <div
          className={styles.StatsWrapper}
          style={{ backgroundColor: colors[StatsBox.count % colors.length] }}
        >
          <div>
            <div className={styles.StatsTitle}>{props.title}</div>
            <div className={styles.StatsCount}>{props.count}</div>
          </div>
          <div className={styles.StatsIcon}>
            <img src={props.icon} alt="icon" />
          </div>
        </div>
      );
    };
    /**
     * Static property
     */
    StatsBox.count = -1;
    return (
      <Row gutter={28}>
        <Col flex={1}>
          <StatsBox
            title={labels.FACTORY_INVENTORY}
            count={
              this.state.dashboardData.distinctSplitOrderCount +
              "/" +
              this.state.dashboardData.splitOrderCount
            }
            icon={BagIcon}
          ></StatsBox>
        </Col>

        <Col flex={1}>
          <StatsBox
            title={labels.PACKED_GATEPASS_NOT_GENERATED}
            count={
              this.state.dashboardData.garmentsToTransferOut +
              "/" +
              this.state.dashboardData.orderTOTransferOut
            }
            icon={WalletIcon}
          ></StatsBox>
        </Col>
        <Col flex={1}>
          <StatsBox
            title={labels.TODAYS_DUE_GARMENT_ORDER}
            count={
              this.state.dashboardData.todaysOrderProcessCount +
              "/" +
              this.state.dashboardData.todaysDueOrders
            }
            icon={BagIcon}
          ></StatsBox>
        </Col>
        <Col flex={1}>
          <StatsBox
            title={labels.PREVIOUS_DUE}
            count={
              this.state.dashboardData.previousDueGarments +
              "/" +
              this.state.dashboardData.previousDueOrders
            }
            icon={TshirtIcon}
          ></StatsBox>
        </Col>
        {/* <Col xs={24} lg={12}>
          <StatsBox
            title="Overdue Garments"
            count={this.state.dashboardData.orderTOTransferOut}
            icon={TshirtIcon}
          ></StatsBox>
        </Col> */}
        <Col flex={1}>
          <StatsBox
            title={labels.MISSING_DAMAGE}
            count={
              "(" +
              this.state.dashboardData.missingGarmentCount +
              "/" +
              this.state.dashboardData.missingOrderCount +
              ")" +
              " / " +
              "(" +
              this.state.dashboardData.damageGarmentCount + "/"+ this.state.dashboardData.damageOrderCount +")"
            }
            icon={TshirtIcon}
          ></StatsBox>
        </Col>

        <Col flex={1}>
          <StatsBox
            title={labels.TICKETS_COUNTS}
            count={this.state.dashboardData.qcGarmentCounts}
            icon={WalletIcon}
          ></StatsBox>
        </Col>
      </Row>
    );
  }
}
