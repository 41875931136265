import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  TimePicker,
  Input,
  DatePicker,
  Space,
  notification,
  Radio,
} from "antd";
import Button from "../../Common/Button/button";
import DiscountMasterService from "../../../services/discountmasterservice";
import {
  RegexDecimalAndRestrictFirstZero,
  RegexInteger,
  MessageNumbers,
} from "../../../shared/validator";
import Moment from "moment";
const { Option } = Select;

export default class GeneralForm extends React.Component {
  discountmasterservice = new DiscountMasterService();

  state = {
    discountTypeID: 0,
    usageTypeID:0,
    discountID: this.props.discountID ? this.props.discountID : 0,
    discountTypeEditID: parseInt(this.props.discountTypeEditID),
    deliveryTypes: {
      disabled: true,
      data: [],
    },
    weekDays: {
      disabled: true,
      data: [],
    },
    status: {
      disabled: true,
      data: [],
    },
    applicableServiceIds: {
      disabled: true,
      data: [],
    },
    applicableStoreIds: {
      disabled: true,
      data: [],
    },

    mode: {
      disabled: true,
      data: [],
    },
    usageType: {
      disabled: true,
      data: [],
    },
    discountType: {
      disabled: true,
      data: [],
    },
    promocodeType: {
      disabled: true,
      data: [],
    },
    cities: {
      disabled: true,
      data: [],
    },
  };

  componentDidMount() {
    this.getWeekDays();
    this.getServices();
    this.getStores();
    this.getMode();
    this.getUsageType();
    this.getDiscountType();
    this.getPromocodeType();
    this.getCities();
    this.setState({ discountTypeID: this.props.values.discountType });
    this.setState({ usageTypeID: this.props.values.usageType });
  }

  authorizeDiscount = () => {
    this.discountmasterservice
      .requestAuthorizeDiscount(this.state.discountID)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Discount",
            description: response.message,
          });
          this.props.getDiscountMaster(this.state.discountID);
        } else {
          notification["error"]({
            message: "Discount",
            description: response.message
              ? response.message
              : "Unable to Authorize Discount",
          });
        }
      });
  };

  blockDiscount = () => {
    this.discountmasterservice
      .requestBlockDiscount(this.state.discountID)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Discount",
            description: response.message,
          });
          this.props.getDiscountMaster(this.state.discountID);
        } else {
          notification["error"]({
            message: "Discount",
            description: response.message
              ? response.message
              : "Unable to Block Discount",
          });
        }
      });
  };

  getWeekDays = () => {
    this.discountmasterservice.getWeekDaysList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          weekDays: {
            ...this.state.weekDays,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Discount",
          description: "Unable to get data for week days",
        });
      }
    });
  };

  getServices = () => {
    this.discountmasterservice.getServices().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          applicableServiceIds: {
            ...this.state.applicableServiceIds,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Discount",
          description: "Unable to get applicable services",
        });
      }
    });
  };

  getStores = () => {
    this.discountmasterservice.getStoresList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          applicableStoreIds: {
            ...this.state.applicableStoreIds,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Discount",
          description: "Unable to get data for applicable stores",
        });
      }
    });
  };

  getMode = () => {
    this.discountmasterservice.getMode().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          mode: {
            ...this.state.mode,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Discount",
          description: "Unable to get data for mode",
        });
      }
    });
  };

  getUsageType = () => {
    this.discountmasterservice.getUsageType().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          usageType: {
            ...this.state.usageType,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Discount",
          description: "Unable to get data for usage type",
        });
      }
    });
  };

  getDiscountType = () => {
    this.discountmasterservice.getDiscountType().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          discountType: {
            ...this.state.discountType,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Discount",
          description: "Unable to get data for usage type",
        });
      }
    });
  };

  getPromocodeType = () => {
    this.discountmasterservice.getPromocodeType().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          promocodeType: {
            ...this.state.promocodeType,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Discount",
          description: "Unable to get data for promocode type",
        });
      }
    });
  };

  getCities = () => {
    const params = {
      cityID: 0,
      cityName: "",
      pageIndex: 1,
      pageSize: 1000,
      sortColumn: "",
      sortOrder: "",
      commonSearch: "",
    };
    this.discountmasterservice.getCities(params).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          cities: {
            ...this.state.cities,
            data: response.data,

            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Discount",
          description: "Unable to get data for cities",
        });
      }
    });
  };

  render() {
    const disableAll = this.props.mode === "view";
    const editmode = this.props.mode === "edit";
    const addmode = this.props.mode === "add";

    return (
      <Form
        key={this.props.values.discountName}
        name="general"
        layout="vertical"
        initialValues={this.props.values}
        ref={this.props.instance}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={12}>
            <Form.Item
              label="Discount Name"
              name="discountName"
              rules={[
                { required: true, message: "Please Enter Discount Name" },
              ]}
            >
              <Input
                placeholder="Enter Discount Name"
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={12}>
            {/**
             * For More Clarity over what <DatePicker> Component can do
             * Please go through https://ant.design/components/date-picker/
             * https://ant.design/components/date-picker/#API
             */}
            <Form.Item
              label="Validity"
              name="validFrom"
              rules={[
                {
                  required: true,
                  message: "Please Select Validity",
                },
              ]}
            >
              <DatePicker.RangePicker
                onChange={() => null}
                disabledDate={(currentDate) =>
                  currentDate.isBefore(Moment(), "day") ? true : false
                }
                format={"DD-MM-YYYY"}
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              />
            </Form.Item>
          </Col>

          {/* Added By hasti */}

          <Col xs={24} md={12} xl={24}>
            <Form.Item
              label="Discount Title"
              name="discountTitle"
              rules={[
                { required: true, message: "Please enter Discount Title" },
              ]}
            >
              <Input
                placeholder="Enter Discount Title"
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              />
            </Form.Item>
          </Col>

          {/* Added By hasti */}

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Mode"
              name="mode"
              rules={[{ required: true, message: "Please Select Mode" }]}
            >
              <Select
                placeholder="Select Mode"
                onChange={() => null}
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
                //disabled={this.state.status.disabled || disableAll}
              >
                {this.state.mode.data.length !== 0
                  ? this.state.mode.data.map((mode) => {
                      return mode.value !== "-1" ? (
                        <Option value={mode.value} key={mode.value}>
                          {mode.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Usage Type"
              name="usageType"
              rules={[{ required: true, message: "Please Select Usage Type" }]}
            >
              <Select
                placeholder="Select Usage Type"
                onChange={(e) => this.setState({ usageTypeID: e })}
                // onChange={() => null}
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
                //disabled={this.state.usageType.disabled || disableAll}
              >
                {this.state.usageType.data.length !== 0
                  ? this.state.usageType.data.map((usageType) => {
                      return usageType.value !== "-1" ? (
                        <Option value={usageType.value} key={usageType.value}>
                          {usageType.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="No Of Time"
              name="nos_Times"
              rules={[
                { required: true, message: "Please Enter No Of Time" },
                {
                  pattern: RegexInteger,
                  message: MessageNumbers,
                },
              ]}
            >
              <Input
                placeholder="Enter No Of Time"
                maxLength={6}
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              />
            </Form.Item>
          </Col>
          {/* Added By Hasti -Start */}
          {parseInt(this.state.usageTypeID) === 2 ? (
            <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="No of Times Redeemable"
              name="nos_Times_Redeemable"
              rules={[
                { required: true, message: "Please Enter No of Times Redeemable" },
                {
                  pattern: RegexInteger,
                  message: MessageNumbers,
                },
              ]}
            >
              <Input
                placeholder="Enter No of Times Redeemable"
                maxLength={6}
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              />
            </Form.Item>
          </Col>
          ):null}
          

          {/* End */}
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Promo Code Type"
              name="promoCodeType"
              rules={[
                {
                  required: true,
                  message: "Please Select Promo Code Type",
                },
              ]}
            >
              <Select
                onChange={() => null}
                value={1}
                mode="multiple"
                placeholder="Select Promo code Types"
                //disabled={this.state.deliveryTypes.disabled || disableAll}
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.promocodeType.data.length !== 0
                  ? this.state.promocodeType.data.map((promocodeType) => {
                      return promocodeType.value !== "-1" ? (
                        <Option
                          value={promocodeType.value}
                          key={promocodeType.value}
                        >
                          {promocodeType.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Discount Type"
              name="discountType"
              rules={[
                { required: true, message: "Please Select Discount Type" },
              ]}
            >
              <Select
                placeholder="Select Discount Type"
                onChange={(e) => this.setState({ discountTypeID: e })}
                //disabled={this.state.discountType.disabled || disableAll}
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              >
                {this.state.discountType.data.length !== 0
                  ? this.state.discountType.data.map((discountType) => {
                      return discountType.value !== "-1" ? (
                        <Option
                          value={discountType.value}
                          key={discountType.value}
                        >
                          {discountType.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={
                addmode
                  ? parseInt(this.state.discountTypeID) === 2
                    ? "Discount On Percentage"
                    : "Discount On Fixed Amount"
                  : parseInt(this.state.discountTypeID) !== 0
                  ? this.props.discountTypeEditID !== 2 ||
                    parseInt(this.state.discountTypeID) !== 2
                    ? "Discount On Fixed Amount"
                    : "Discount On Percentage"
                  : this.props.discountTypeEditID !== 2
                  ? "Discount On Fixed Amount"
                  : "Discount On Percentage"
              }
              name="discountValue"
              rules={[
                {
                  required: true,
                  message:
                    "Please Enter Discount On " +
                    (addmode
                      ? parseInt(this.state.discountTypeID) === 2
                        ? "Percentage"
                        : "Fixed Amount"
                      : parseInt(this.state.discountTypeID) !== 0
                      ? this.props.discountTypeEditID !== 2 ||
                        parseInt(this.state.discountTypeID) !== 2
                        ? "Fixed Amount"
                        : "Percentage"
                      : this.props.discountTypeEditID !== 2
                      ? "Fixed Amount"
                      : "Percentage"),
                },
                {
                  pattern: RegexDecimalAndRestrictFirstZero,
                  message: MessageNumbers,
                },
              ]}
            >
              <Input
                placeholder={
                  parseInt(this.state.discountTypeID) === 2
                    ? "Enter Discount On Percentage"
                    : "Enter Discount On Fixed Amount"
                }
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              />
            </Form.Item>
          </Col>

          {parseInt(this.state.discountTypeID) === 2 ? (
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                label="Max Discount Amount"
                name="maxAmount"
                rules={[
                  { required: true, message: "Please Enter Max Amount" },
                  {
                    pattern: RegexInteger,
                    message: MessageNumbers,
                  },
                ]}
              >
                <Input
                  placeholder="Enter Max Amount"
                  disabled={
                    editmode
                      ? this.props.DiscountStatusName === 2 ||
                        this.props.DiscountStatusName === 3
                        ? true
                        : false
                      : disableAll
                  }
                />
              </Form.Item>
            </Col>
          ) : null}

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Min Order Amount"
              name="minAmount"
              rules={[
                { required: true, message: "Please Enter Min Order Amount" },
                {
                  pattern: RegexInteger,
                  message: MessageNumbers,
                },
              ]}
            >
              <Input
                placeholder="Enter Min Order Amount"
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Is First Order Only"
              name="isFirstOrderOnly"
              rules={[
                { required: true, message: "Please Is First Order Only" },
              ]}
            >
              <Radio.Group
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
                {/* <Radio value={3}>All Orders</Radio> */}
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={16}>
            <Form.Item
              label="Week Days"
              name="weekDays"
              rules={[{ required: true, message: "Please Select Week Days" }]}
            >
              <Select
                mode="multiple"
                placeholder="Select Week Days"
                onChange={() => null}
                //disabled={this.state.weekDays.disabled || disableAll}
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.weekDays.data.length !== 0
                  ? this.state.weekDays.data.map((weekDays) => {
                      return weekDays.value !== "-1" ? (
                        <Option value={weekDays.value} key={weekDays.value}>
                          {weekDays.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Include Delivery Charge"
              name="includeDeliveryCharge"
              rules={[
                {
                  required: true,
                  message: "Please Include Delivery Charge",
                },
              ]}
            >
              <Radio.Group
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Applicable Services"
              name="applicableServiceIds"
              rules={[
                {
                  required: true,
                  message: "Please Select Applicable Services",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Applicable Services"
                onChange={() => null}
                //disabled={this.state.services.disabled || disableAll}
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.applicableServiceIds.data.length !== 0
                  ? this.state.applicableServiceIds.data.map(
                      (applicableServiceIds) => {
                        return applicableServiceIds.value !== "-1" ? (
                          <Option
                            value={applicableServiceIds.value}
                            key={applicableServiceIds.value}
                          >
                            {applicableServiceIds.text}
                          </Option>
                        ) : null;
                      }
                    )
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Applicable Stores"
              name="applicableStoreIds"
              rules={[
                {
                  required: true,
                  message: "Please Select Applicable Stores",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Applicable Store"
                onChange={() => null}
                //disabled={this.state.applicableStoreIds.disabled || disableAll}
                disabled={
                  editmode
                    ? //  removed condition as to solve L15563 : this ticket by PB
                      //this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.applicableStoreIds.data.length !== 0
                  ? this.state.applicableStoreIds.data.map(
                      (applicableStoreIds) => {
                        return applicableStoreIds.value !== "-1" ? (
                          <Option
                            value={applicableStoreIds.value}
                            key={applicableStoreIds.value}
                          >
                            {applicableStoreIds.text}
                          </Option>
                        ) : null;
                      }
                    )
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Applicable Cities"
              name="applicableCityIds"
              rules={[
                {
                  required: true,
                  message: "Please Select Applicable Cities",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Applicable Cities"
                onChange={() => null}
                //disabled={this.state.applicableCityIds.disabled || disableAll}
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.cities.data.length !== 0
                  ? this.state.cities.data.map((cities) => {
                      return cities.value !== "-1" ? (
                        <Option value={cities.cityID} key={cities.cityID}>
                          {cities.cityName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={24}>
            <Form.Item label="Comment" name="comment">
              <Input.TextArea
                rows={2}
                maxLength={2000}
                placeholder="Enter Comment"
                disabled={
                  editmode
                    ? this.props.DiscountStatusName === 2 ||
                      this.props.DiscountStatusName === 3
                      ? true
                      : false
                    : disableAll
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} style={{ textAlign: "right" }}>
            <Space>
              <div className="w-100"></div>
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                Cancel
              </Button>
              {addmode ? (
                <Button
                  htmlType="submit"
                  onClick={this.props.nextFunction}
                  variant="purple"
                  shape="round"
                  size="large"
                >
                  Submit
                </Button>
              ) : editmode ? (
                this.props.DiscountStatusName === 1 ||
                this.props.DiscountStatusName === 2 ? ( // added condition by PB to solve L15563 ticket
                  <>
                    <Button
                      htmlType="submit"
                      onClick={this.props.nextFunction}
                      variant="purple"
                      shape="round"
                      size="large"
                    >
                      {this.props.DiscountStatusName === 1 ||
                      this.props.DiscountStatusName === 2
                        ? "Update"
                        : "Submit"}
                    </Button>
                    {/* // added condition to hide the authorize button once it is
                    authorized */}
                    {this.props.DiscountStatusName === 2 ? (
                      <Button
                        onClick={this.blockDiscount}
                        variant="purple"
                        shape="round"
                        size="large"
                      >
                        Block
                      </Button>
                    ) : (
                      <Button
                        onClick={this.authorizeDiscount}
                        variant="purple"
                        shape="round"
                        size="large"
                      >
                        Authorize
                      </Button>
                    )}
                  </>
                ) : (
                  disableAll
                )
              ) : (
                // this.props.DiscountStatusName === 2 ? (
                //   <Button
                //     onClick={this.blockDiscount}
                //     variant="purple"
                //     shape="round"
                //     size="large"
                //   >
                //     Block
                //   </Button>
                // ) : null
                disableAll
              )}
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
