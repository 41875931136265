import React from "react";
import styles from "../Common/Styles/master.module.css";
import Button from "../../components/Common/Button/button";
import TableActions from "../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Tooltip,
  DatePicker,
  Spin,
  Checkbox,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Holidayservice from "../../services/holidayservice";

import Moment from "moment";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import {
  RegexLettersDigitsUnderScoreHyphen,
  MessageLettersDigitsUnderScoreHyphen,
} from "../../shared/validator";
import { View } from "@react-pdf/renderer";

const { Search } = Input;
const { Option } = Select;

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class holidayconfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.holidayservice = new Holidayservice();

    this.state = {
      modalLoading: false,
      storelist: [],
      holidayConfigItems: [],
      holidayID: 0,
      storeNames: "",
      storeIDs: "",
      holidayDate: "",
      remark: "",
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showAddPopup: false,
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.HOLIDAY_CONFIGURATION
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }

    this.setState({ userRights: res });
  }

  columns = [
    {
      title: "Holiday Date",
      dataIndex: "holidayDate",
      key: "holidayDate",
      sorter: true,
    },
    {
      title: "Store Name",
      dataIndex: "storeNames",
      key: "storeNames",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (storeNames) => (
        <Tooltip
          overlayStyle={{ whiteSpace: "pre-line" }}
          placement="topLeft"
          title={storeNames}
        >
          {storeNames}
        </Tooltip>
      ),
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      sorter: true,
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: true,
    },

    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: true,
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
         
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    holidayID: record.holidayID,
                    storeIDs: record.storeIDs,
                    holidayDate: Moment(record.holidayDate, "DD/MM/YYYY"),
                    remark: record.remark,
                    isEnabled: true,
                    isVisible: true,
                    showAddPopup: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}

          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() => {
                  this.setState({
                    holidayID: record.holidayID,
                    storeIDs: record.storeIDs,
                    holidayDate: Moment(record.holidayDate, "DD/MM/YYYY"),
                    remark: record.remark,
                    showAddPopup: true,
                  });
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.holidayID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
    this.getStore();
  }

  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table pagination when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  getStore = () => {
    this.holidayservice.retrieveStore().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          storelist: response.data,
        });
      } else {
        notification["error"]({
          message: "Holiday Configuration",
          description: "Unable to get list of store",
        });
      }
    });
  };

  fetchItems = (params = {}) => {
    this.holidayservice.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          holidayConfigItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Holiday Configuration",
          description: items.message
            ? items.message
            : "Unable to get list of Holiday data",
        });
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (holidayID) => {
    if (holidayID === "" || holidayID === null || holidayID === undefined) {
      notification["error"]({
        message: "Holiday Configuration",
        description: "Please Select Atleast One Row",
      });
    } else {
      this.holidayservice.removeItems(holidayID).then((items) => {
        if (items.result === "Success") {
          notification["success"]({
            message: "Holiday Configuration",
            description: items.message,
          });
          const { pagination } = this.state;
          this.fetchItems({
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
          });
        } else {
          notification["error"]({
            message: "Holiday Configuration",
            description: items.message,
          });
        }
      });
    }
  };

  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    var postData = {
      holidayID: this.state.holidayID,
      storeIDs: this.state.storeIDs,
      holidayDate: Moment(this.state.holidayDate).format("YYYY/MM/DD"),
      remark: this.state.remark,
    };
    this.setState({ modalLoading: true });
    this.holidayservice.insertUpdateItems(postData).then((items) => {
      if (items.result === "Success") {
        // openNotificationWithIcon("success", "State Master", items.message);
        notification["success"]({
          message: "Holiday Configuration",
          description: items.message,
        });
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        // openNotificationWithIcon("error", "State Master", items.message);
        notification["error"]({
          message: "Holiday Configuration",
          description: items.message,
        });
      }
      this.setState({ modalLoading: false });
    });
  };

  //   RestoreDDlValue = (e) => {
  //     this.setState({
  //       selectedstore: [null, undefined, ""].includes(e) ? "" : e.join(","),
  //     });
  //   };

  handleInputOnChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  //function for clearing out the states
  resetFields = () => {
    this.setState({
      holidayID: 0,
      storeIDs: "",
      holidayDate: "",
      remark: "",
      isEnabled: false,
      isVisible: false,
    });
  };

  handleChangee = (id) => {
    // console.log("id->", id);
  };

  render() {
   
    const { storeIDs, holidayDate, remark, pagination, loading } = this.state;
    const holidayConfigItems = this.state.holidayConfigItems;
    const listItems = holidayConfigItems.map((item, index) => ({
      key: item.holidayID,
      holidayID: item.holidayID,
      holidayDate: Moment(item.holidayDate).format("DD-MM-YYYY"),
      storeNames: item.storeNames,
      createdBy: item.createdBy,
      remark: item.remark,
      storeIDs: item.storeIDs,
      isActive: item.isActive.toString(),
      createdByName: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD-MM-YYYY hh:mm:ss a"),
      totalRecords: item.totalRecords,
    }));

    return (
      <div>
        <PageHeader
          title="Holiday Configuration"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                Add Holiday Configuration
              </Button>
            ) : null,
          ]}
        />

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            holidayConfigItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>

        <Modal
          title={
            this.state.holidayID === 0
              ? "Add Holiday Configuration"
              : this.state.isEnabled
              ? "View Holiday Configuration"
              : "Edit Holiday Configuration"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          destroyOnClose
          className="modal-form"
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                storeIDs,
                holidayDate,
                remark,
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Holiday Date"
                    name={"holidayDate"}
                    rules={[
                      { required: true, message: "Please Select Holiday Date" },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      format={"DD-MM-YYYY"}
                      onChange={(date) =>
                        this.handleDateChange(date, "holidayDate")
                      }
                      disabled={this.state.isEnabled}
                      disabledDate={(currentDate) =>
                        currentDate < Moment().endOf("day") ? true : false
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} >
                  <Form.Item
                    label="Store"
                    name="storename"
                    rules={[
                      { required: true, message: "Please Select Any Store!" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Store"
                      mode="multiple"
                      //   onChange={this.RestoreDDlValue}
                      onChange={(event) => {
                        this.setState(
                          {
                            storeIDs: [null, undefined, ""].includes(event)
                              ? ""
                              : event.join(","),
                          },
                          () => {
                           
                            this.handleChangee(event);
                          }
                        );
                      }}
                      disabled={this.state.isEnabled}
                      defaultValue={
                        this.state.storeIDs === ""
                          ? []
                          : this.state.storeIDs.split(",")
                      }
                      // value={
                      //   this.state.storeIDs === ""
                      //     ? []
                      //     : this.state.storeIDs.split(",")
                      // }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.storelist.map((item) => {
                        return item.value !== "-1" ? (
                          <option key={item.value} value={item.value}>
                            {item.text}
                          </option>
                        ) : null;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col xs={24} lg={6}>
                  <Form.Item label="Select All">
                    <Checkbox
                      // checked={this.state.storeIDs.length == this.state.storelist.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                         
                          let allOpt = this.state.storelist
                            .map((store) => {
                              return (store.value);
                            })
                            .filter((x) => x !== "-1");
                          
                         
                         

                          this.setState(
                            {
                              storeIDs: [null, undefined, ""].includes(allOpt)
                                ? ""
                                : allOpt.join(","),
                            },
                            () => {
                              this.handleChangee(e);
                            }
                          );
                        } else {
                          this.setState(
                            {
                              storeIDs: "",
                            },
                            () => this.handleChangee(e)
                          );
                        }
                      }}
                    ></Checkbox>
                  </Form.Item>
                </Col> */}

                <Col xs={24}>
                  <Form.Item
                    label="Rearks"
                    name="remark"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Remark",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Remark"
                      name="remark"
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.holidayID === 0 ? "Add" : "Update"}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
