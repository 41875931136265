import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Tooltip,
  Spin,
  DatePicker,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CityService from "../../../services/cityservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import {
  RegexLettersDigitsUnderScoreHyphen,
  MessageLettersDigitsUnderScoreHyphen,
} from "../../../shared/validator";
import Common from "../../../services/common";
import Resortingandpackagingservice from "../../../services/resortingandpackagingservice";
import { labels } from "../../../shared/language";
import GeneratePrintInfo from "../ResortingAndPackaging/printstikerprint";
// let cityname = "";

const { Search } = Input;
const { Option } = Select;
const TWO_MONTHS_AGO = new Date();
TWO_MONTHS_AGO.setMonth(TWO_MONTHS_AGO.getMonth() - 1);

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.resortingandpackagingservice = new Resortingandpackagingservice();
    this.common = new Common();
    this.state = {
      modalLoading: false,
      resortingAndPackagingItem: [],
      orderFromDate: Moment(TWO_MONTHS_AGO).startOf(1, "months").date(1),
      orderToDate: Moment(),
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      rowselectedvalue: "",
      showfilterBox: false,
      showAddPopup: false,
      loading: true,
      userRights: [],
      printInfo: [],
      stickerID: 0,
      showAddPopup: false,
    };
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName ==
        this.UserRightsConfiguration.RESORTING_AND_PACKAGING_LIST
    );
    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.STICKER_ID,
      dataIndex: "stickerID",
      key: "stickerID",
      sorter: true,
      render: (text) => {
        return (
          <Button
            variant="link"
            type="link"
            onClick={() => this.GenerateStickers(text)}
          >
            {text}
            <FontAwesomeIcon icon={faInfo} style={{ marginLeft: 5 }} />
          </Button>
        );
      },
    },
    {
      title: labels.SPLIT_ORDER_NUMBER,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },
    {
      title: labels.PACKING_IDS,
      dataIndex: "packingIds",
      key: "packingIds",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),

      // render: (text, record) => (
      //   <Space size="middle">
      //     {text.length > 150 ? (
      //       <div
      //         style={{
      //           width: 350,
      //           height: 200,
      //           margin: 0,
      //           padding: 0,
      //           overflow: "auto",
      //         }}
      //       >
      //         {text}
      //       </div>
      //     ) : (
      //       text
      //     )}
      //   </Space>
      // ),
    },
    {
      title: labels.PACKED_On,
      dataIndex: "packedOn",
      key: "packedOn",
      sorter: true,
      render: (text) =>
        ![null, "", undefined].includes(text)
          ? Moment(text).format("DD-MM-YYYY HH:mm:ss")
          : null,
    },

    {
      title: labels.CUSTOMER_NAME,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: labels.STORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
    {
      title: labels.NO_OF_GARMENTS,
      dataIndex: "noOfGarment",
      key: "noOfGarment",
      sorter: true,
    },
    {
      title: labels.PACKED_GARMENTS,
      dataIndex: "packedGarments",
      key: "packedGarments",
      sorter: true,
    },
    {
      title: labels.DELIVERY_TYPE,
      dataIndex: "deliveryType",
      key: "deliveryType",
      sorter: true,
    },
  ];

  GenerateStickers = (text) => {
    if (text != "") {
      // this.setState({ generateBtnLoading: true });
      this.resortingandpackagingservice
        .GetStickerInfoToPrint(parseInt(text))
        .then((res) => {
          // if (res.data.stickerID != "") {

          this.setState(
            {
              showAddPopup: true,
              printInfo: res.data,
              stickerID: res.data.stickerID,
            },
            () => {
              this.setState({
                listItemsRes: [],
                resortingAndPackagingServices: {
                  vMPackingMaterial: [],
                },
              });
              // formReference.current.resetFields();
            }
          );
          this.setState({ generateBtnLoading: false });
          // } else {
          //   openNotificationWithIcon(
          //     "error",
          //     "Ganerate Sticker",
          //     "Sticker Id Not Generated."
          //   );
          // }
        });
    } else {
      notification["error"]({
        message: labels.RESORTING_AND_PACKAGING,
        description: labels.UNABLE_TO_GET_STICKERID,
      });
    }
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      factoryID: localStorage.getItem("branchID"),
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }

  handleSearch = (event) => {
    this.fetchItems({
      factoryID: localStorage.getItem("branchID"),
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table pagination when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      factoryID: localStorage.getItem("branchID"),
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
    });
  };

  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    var postdata = {
      stickerID: 0,
      splitOrderNumber: "string",
      packedOn: "2020-11-06T07:10:50.725Z",
      packingIds: "string",
      customerName: "string",
      storeName: "string",
      noOfGarment: 0,
      packedGarments: 0,
      factoryID: parseInt(params.factoryID),
      totalRecords: 0,
      pageIndex: params.pageIndex,
      pageSize: params.pageSize,
      sortColumn: params.sortColumn,
      sortOrder: params.sortOrder,
      commonSearch: params.commonSearch != undefined ? params.commonSearch : "",
      // orderFromDate: Moment(this.state.orderFromDate).format("YYYY-MM-DD"),
      // orderToDate: Moment(this.state.orderToDate).format("YYYY-MM-DD"),
    };
    this.resortingandpackagingservice
      .ResortingAndPackagingList(postdata)
      .then((items) => {
        if (items.statusCode === 200) {
          if (
            items.length !== 0 &&
            items.data.length === 0 &&
            params.pagination.current !== 1
          ) {
            params.pagination.current = 1;
            this.fetchItems({
              cityID: 0,
              pageIndex: 1,
              pageSize: params.pageSize,
              pagination: params.pagination,
            });
          }
          this.setState({
            resortingAndPackagingItem: items.data,
            pagination: {
              ...params.pagination,
              total: items.data.length > 0 ? items.data[0].totalRecords : 0,
            },
          });
        } else {
          notification["error"]({
            message: labels.RESORTING_AND_PACKAGING,
            description: this.common.Message(items.message),
          });
        }
        this.setState({ loading: false });
      });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    // this.resetFields();
    //window.location.reload();
  };

  // handleDateChange = (date, name) => {
  //   this.setState({ [name]: date });
  // };

  render() {
    const { pagination, loading } = this.state;
    const resortingAndPackagingItem = this.state.resortingAndPackagingItem;
    const listItems = resortingAndPackagingItem.map((item, index) => ({
      key: index,
      stickerID: item.stickerID,
      splitOrderNumber: item.splitOrderNumber,
      packedOn: item.packedOn,
      // packedOn: ![null, undefined, ""].includes(item.packedOn)
      //   ? item.packedOn.split("T")[0]
      //   : "",
      packingIds: item.packingIds,
      customerName: item.customerName,
      storeName: item.storeName,
      noOfGarment: item.noOfGarment,
      packedGarments: item.packedGarments,
      deliveryType: item.deliveryType,
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title={labels.RESORTING_AND_PACKAGING_List}
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            // <Button
            //   key="1"
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        {/* <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.fetchItems({
                factoryID: localStorage.getItem("branchID"),
                pageIndex: pagination.current,
                pageSize: pagination.pageSize,
                pagination,
              })
            }
            initialValues={{
              orderFromDate: this.state.orderFromDate,
              orderToDate: this.state.orderToDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderFromDate")
                    }
                    value={this.state.orderFromDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderToDate")
                    }
                    value={this.state.orderToDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters> */}
        <Table
          size="small"
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}></TableActions>
        <Modal
          title={labels.PRINT_GENERATE_STICKER}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <div
            style={{
              overflowY: "scroll",
              justifyContent: "flex",
              justifyContent: "center",
            }}
          >
            <GeneratePrintInfo
              printInfo={this.state.printInfo}
              stickerID={this.state.stickerID}
              // customerID={
              //     this.state.resortingAndPackagingServices != null
              //         ? this.state.resortingAndPackagingServices.customerID
              //         : 0
              // }
              customerID={""}
            ></GeneratePrintInfo>
          </div>
        </Modal>
      </div>
    );
  }
}
