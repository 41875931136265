import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class FactoryService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }
  async getStateList() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GETSTATELIST });
    let states = [];
    await stateListAPI.endpoints[this.endpoint.GETSTATELIST]
      .getAll()
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getStateList();
      });
    }
    return Promise.resolve(states);
  }

  async getCityListByState(id) {
    const getCitiesAPI = new API({ url: this.config.DROPDOWN_URL });
    getCitiesAPI.createEntity({ name: this.endpoint.GET_CITIES_BY_STATE });
    let cities = [];
    let idParams = {
      params: {
        StateID: id,
      },
    };
    await getCitiesAPI.endpoints[this.endpoint.GET_CITIES_BY_STATE]
      .getAll(idParams)
      .then((response) => {
        cities = response.data;
      })
      .catch((err) => {
        cities = this.common.handleError(err);
      });
    if (cities.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        cities = this.getCityListByState(id);
      });
    }
    return Promise.resolve(cities);
  }

  async getDeliveryTypeList() {
    const deliveryTypeAPI = new API({ url: this.config.DROPDOWN_URL });
    deliveryTypeAPI.createEntity({ name: this.endpoint.GET_DELIVERY_TYPES });
    let deliveryTypes = [];
    await deliveryTypeAPI.endpoints[this.endpoint.GET_DELIVERY_TYPES]
      .getAll()
      .then((response) => {
        deliveryTypes = response.data;
      })
      .catch((err) => {
        deliveryTypes = this.common.handleError(err);
      });
    if (deliveryTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        deliveryTypes = this.getDeliveryTypeList();
      });
    }
    return Promise.resolve(deliveryTypes);
  }

  async getOperatingDaysList() {
    const daysListAPI = new API({ url: this.config.DROPDOWN_URL });
    daysListAPI.createEntity({ name: this.endpoint.GET_WEEKDAYS });
    let days = [];
    await daysListAPI.endpoints[this.endpoint.GET_WEEKDAYS]
      .getAll()
      .then((response) => {
        days = response.data;
      })
      .catch((err) => {
        days = this.common.handleError(err);
      });
    if (days.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        days = this.getOperatingDaysList();
      });
    }
    return Promise.resolve(days);
  }

  async getFactoryStatusList() {
    const statusListAPI = new API({ url: this.config.DROPDOWN_URL });
    statusListAPI.createEntity({ name: this.endpoint.GET_FACTORY_STATUS_LIST });
    let statusList = [];
    await statusListAPI.endpoints[this.endpoint.GET_FACTORY_STATUS_LIST]
      .getAll()
      .then((response) => {
        statusList = response.data;
      })
      .catch((err) => {
        statusList = this.common.handleError(err);
      });
    if (statusList.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        statusList = this.getFactoryStatusList();
      });
    }
    return Promise.resolve(statusList);
  }

  async getZoneList() {
    const zoneListAPI = new API({ url: this.config.DROPDOWN_URL });
    zoneListAPI.createEntity({ name: this.endpoint.GET_ZONES });
    let zones = [];
    await zoneListAPI.endpoints[this.endpoint.GET_ZONES]
      .getAll()
      .then((response) => {
        zones = response.data;
      })
      .catch((err) => {
        zones = this.common.handleError(err);
      });
    if (zones.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        zones = this.getZoneList();
      });
    }
    return Promise.resolve(zones);
  }
  async addUpdateFactory(factory) {
    const addUpdateAPI = new API({ url: this.config.ITEM_URL });
    addUpdateAPI.createEntity({ name: this.endpoint.ADD_UPDATE_FACTORY });
    let returnVal = [];
    await addUpdateAPI.endpoints[this.endpoint.ADD_UPDATE_FACTORY]
      .create(factory)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.addUpdateFactory(factory);
      });
    }
    return Promise.resolve(returnVal);
  }
  async getAllFactories(
    index = 0,
    size = 0,
    sortColumn = null,
    sortOrder = null,
    search = null
  ) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.GET_ALL_FACTORIES });
    let factories = [];
    let payload = {
      factoryName: "",
      shortName: "",
      pageIndex: index,
      pageSize: size,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      commonSearch: search,
    };
    await allFactoriesAPI.endpoints[this.endpoint.GET_ALL_FACTORIES]
      .getAllPost(payload)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getAllFactories(
          index,
          size,
          sortColumn,
          sortOrder,
          search
        );
      });
    }
    return Promise.resolve(factories);
  }

  async getFactory(id) {
    const getFactoryAPI = new API({ url: this.config.ITEM_URL });
    getFactoryAPI.createEntity({ name: this.endpoint.GET_FACTORY });
    let factory = [];
    let idParams = {
      params: {
        FactoryID: id,
      },
    };
    await getFactoryAPI.endpoints[this.endpoint.GET_FACTORY]
      .getAll(idParams)
      .then((response) => {
        factory = response.data;
      })
      .catch((err) => {
        factory = this.common.handleError(err);
      });
    if (factory.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factory = this.getFactory(id);
      });
    }
    return Promise.resolve(factory);
  }

  async getServices() {
    const servicesAPI = new API({ url: this.config.DROPDOWN_URL });
    servicesAPI.createEntity({ name: this.endpoint.GET_SERVICES_OFFERED });
    let services = [];
    await servicesAPI.endpoints[this.endpoint.GET_SERVICES_OFFERED]
      .getAll()
      .then((response) => {
        services = response.data;
      })
      .catch((err) => {
        services = this.common.handleError(err);
      });
    if (services.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        services = this.getServices();
      });
    }
    return Promise.resolve(services);
  }
}
