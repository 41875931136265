import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import jwt from "jsonwebtoken";
import Common from "./common";

class ExpenseEntryService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }
  handleError = () => {
    return null;
  };
  async getExpenseTypes() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_EXPENSE_TYPE });

    let itemArray = [];
    await myApi.endpoints[this.endpoint.GET_EXPENSE_TYPE]
      .getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getExpenseTypes();
      });
    }
    return Promise.resolve(itemArray);
  }

  async deleteExpense(expenseID) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.DELETE_EXPENSE });
    let itemArray = [];
    let ID = "?ExpenseID=" + parseInt(expenseID);

    await myApi.endpoints[this.endpoint.DELETE_EXPENSE]
      .getOne(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.deleteExpense(expenseID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async addUpdateExpense(postData) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.ADD_UPDATE_EXPENSE });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.ADD_UPDATE_EXPENSE]
      .create({
        ...postData,
        userID: parseInt(
          jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
            .unique_name
        ),
      })
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.addUpdateExpense(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getAllExpenseList(index, size, sortColumn, sortOrder, search) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.EXPENSE_ENTRY_LIST });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.EXPENSE_ENTRY_LIST]
      .getAllPost({
        expenseID: null,
        userID: parseInt(
          jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
            .unique_name
        ),
        expenseTypeID: null,
        expenseDate: null,
        pageIndex: index,
        pageSize: size,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        commonSearch: search,
      })
      .then(({ data }) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getAllExpenseList(
          index,
          size,
          sortColumn,
          sortOrder,
          search
        );
      });
    }
    return Promise.resolve(itemArray);
  }
}
export default ExpenseEntryService;
