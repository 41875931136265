import React, { Component } from "react";
import styles from "../Common/Styles/master.module.css";
import Button from "../Common/Button/button";
import Filters from "../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  notification,
  Tooltip,
  Modal,
  Spin,
  DatePicker,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faPencilAlt,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
// import ROUTES from "../../../routes";
import NoticeBoardService from "../../services/noticeboardservice";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import { labels } from "../../shared/language";

const { Search } = Input;
const { Option } = Select;
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);

export default class appsflyercomponent extends Component {
  noticeBoardService = new NoticeBoardService();
  UserRightsConfiguration = new UserRightsConfiguration();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    FromDate: moment(ONE_MONTHS_AGO),
    ToDate: moment(),
    modalLoading: false,
    noticeID: 0,
    showfilterBox: true,
    factories: [],
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    showAddPopup: false,
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
    popupMode: "add",
    JSONData: {},
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.APPSFLYER
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  columns = [
    {
      title: "Customer ID",
      dataIndex: "customerID",
      key: "customerID",
      sorter: true,
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      key: "mobileNo",
      sorter: true,
    },
    {
      title: "EmailID",
      dataIndex: "emailID",
      key: "emailID",
      sorter: true,
    },
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      sorter: true,
    },
    {
      title: "Created Date",
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {console.log("record->", record)}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState(
                    {
                      popupMode: "view",
                      JSONData: {
                        json: record.json,
                      },
                    },
                    () => this.setState({ showAddPopup: true })
                  )
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    this.getAppsFlyerData({
      FromDate: this.state.FromDate,
      ToDate: this.state.ToDate,
      PageIndex: this.state.pagination.current,
      PageSize: this.state.pagination.pageSize,
      SortColumn: this.state.sorter.field,
      SortOrder: this.state.sorter.order,
      CommonSearch: this.state.search,
    });
  }

  getAppsFlyerData = (params = {}) => {
    if (!this.state.tableLoading) {
      this.setState({ tableLoading: true });
      this.noticeBoardService.retrieveItems(params).then((response) => {
        if (response.statusCode === 200) {
          this.setState({ factories: response.data });
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: response.data.length ? response.data[0].totalRecords : 0,
              },
            });
        } else {
          notification["error"]({
            message: "AppsFlyer",
            description: response.message
              ? response.message
              : "Unable to get AppsFlyer data.",
          });
        }
        this.setState({ tableLoading: false });
      });
    }
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  render() {
    const disableAll = this.state.popupMode === "view";
    return (
      <div>
        <PageHeader
          title="AppsFlyer"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              key="1"
              onChange={(event) => {
                this.setState({
                  search: event.target.value.toString().toLowerCase(),
                });
                this.getAppsFlyerData({
                  FromDate: this.state.FromDate,
                  ToDate: this.state.ToDate,
                  PageIndex: this.state.pagination.current,
                  PageSize: this.state.pagination.pageSize,
                  SortColumn: this.state.sorter.field,
                  SortOrder: this.state.sorter.order,
                  CommonSearch: event.target.value.toString().toLowerCase(),
                });
              }}
            />,

            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
              key="3"
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.getAppsFlyerData({
                FromDate: this.state.FromDate,
                ToDate: this.state.ToDate,
                PageIndex: this.state.pagination.current,
                PageSize: this.state.pagination.pageSize,
                SortColumn: this.state.sorter.field,
                SortOrder: this.state.sorter.order,
                CommonSearch: this.state.search,
              })
            }
            initialValues={{
              FromDate: this.state.FromDate,
              ToDate: this.state.ToDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"FromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "FromDate")}
                    value={this.state.orderFromDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(moment(), "day") ? true : false
                    }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"ToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "ToDate")}
                    value={this.state.orderToDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getAppsFlyerData({
                  FromDate: this.state.FromDate,
                  ToDate: this.state.ToDate,
                  PageIndex: pagination.current,
                  PageSize: pagination.pageSize,
                  SortColumn: this.state.sorter.field,
                  SortOrder: this.state.sorter.order,
                  CommonSearch: this.state.search,
                });
              }
            );
          }}
        />

        <Modal
          width={600}
          title={"View AppsFlyer Data"}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.modalLoading}>
            <Form layout="vertical" initialValues={this.state.JSONData}>
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item label="Data" name="json">
                    <Input.TextArea
                      style={{ height: 200 }}
                      // disabled={disableAll}
                      placeholder="Data"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.popupMode === "view" ? (
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        Cancel
                      </Button>
                    ) : null}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
