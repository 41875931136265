import React from 'react';
import Monthlyordersummarycomponent from '../../../components/StoreModule/Audit/monthlyordersummary.component';

export default class monthlyordersummaryscreen extends React.Component {
  render() {
    return (
      <div><Monthlyordersummarycomponent></Monthlyordersummarycomponent></div>
    )
  }
}
