import React from "react";
import GatePassList from "../../../components/StoreModule/GatePassList/gatepasslist.store.component";

export default class GatePassListScreen extends React.Component {
  render() {
    return (
      <div>
        <GatePassList />
      </div>
    );
  }
}
