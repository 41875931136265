import React from "react";
import ExpenseEntry from "../../../components/StoreModule/ExpenseEntry/expenseentry.store.component";

export default class ExpenseEntryScreen extends React.Component {
  render() {
    return (
      <div>
        <ExpenseEntry />
      </div>
    );
  }
}
