import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Tooltip,
  Spin,
  DatePicker,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DeliveryDashboardService from "../../../services/deliverydashboard.service";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
import moment from "moment";
const { Option } = Select;
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);

export default class deliverydashboard extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.DeliveryDashboardService = new DeliveryDashboardService();
    this.common = new Common();
    this.state = {
      selectedBranch: localStorage.getItem("branchID"),
      orderFromDate: moment(ONE_MONTHS_AGO),
      orderToDate: moment(),
      selectedDeliverytype: "1",
      modalLoading: false,
      dashboardItems: [],
      detailItems: [],
      deliveryTypes: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      paginationforDetailtable: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      rowselectedvalue: "",
      showfilterBox: true,
      showAddPopup: false,
      loading: true,
      userRights: [],
      isResponseErrorShow: false,
    };
  }

  //   componentWillMount() {
  //     if (!IsAuthenticated()) {
  //       window.location.href = "/";
  //     }

  //     var data = JSON.parse(localStorage.getItem("userrights"));
  //     var res = data.filter(
  //       (val) => val.screenName == this.UserRightsConfiguration.CITY_MASTER
  //     );

  //     const storeOrFactory = localStorage
  //       .getItem("Store/FactoryType")
  //       .toLowerCase();
  //     if (!res[0].isView) {
  //       if (storeOrFactory === "store") {
  //         window.location.href = "/store/dashboard";
  //       } else {
  //         window.location.href = "/factory/dashboard";
  //       }
  //     }
  //     this.setState({ userRights: res });
  //   }

  columns = [
    {
      title: "Store Name",
      dataIndex: "shortName",
      key: "shortName",
    },

    {
      title: "Factory In",
      children: [
        {
          title: "Walk In",
          dataIndex: "walkInFactoryIn",
          key: "walkInFactoryIn",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "6",
                    "WalkIn",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
        {
          title: "On Demand",
          dataIndex: "onDemandFactoryIn",
          key: "onDemandFactoryIn",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "6",
                    "OnDemand",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
      ],
    },

    {
      title: "Store In Pending",
      children: [
        {
          title: "Walk In",
          dataIndex: "walkInStoreInPending",
          key: "walkInStoreInPending",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "7",
                    "WalkIn",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
        {
          title: "On Demand",
          dataIndex: "onDemandStoreInPending",
          key: "onDemandStoreInPending",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "7",
                    "OnDemand",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
      ],
    },

    {
      title: "Partial Order",
      children: [
        {
          title: "Walk In",
          dataIndex: "walkInPartialOrder",
          key: "walkInPartialOrder",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "8",
                    "WalkIn",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
        {
          title: "On Demand",
          dataIndex: "onDemandPartialOrder",
          key: "onDemandPartialOrder",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "8",
                    "OnDemand",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
      ],
    },

    {
      title: "Ready to Deliver",
      children: [
        {
          title: "Walk In",
          dataIndex: "walkInReadytoDeliver",
          key: "walkInReadytoDeliver",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "9,10,11",
                    "WalkIn",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
        {
          title: "On Demand",
          dataIndex: "onDemandReadytoDeliver",
          key: "onDemandReadytoDeliver",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "9,10,11",
                    "OnDemand",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
      ],
    },

    {
      title: "Paid and Undeliverd",
      children: [
        {
          title: "Walk In",
          dataIndex: "walkInPaidandUndeliverd",
          key: "walkInPaidandUndeliverd",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "15",
                    "WalkIn",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
        {
          title: "On Demand",
          dataIndex: "onDemandPaidandUndeliverd",
          key: "onDemandPaidandUndeliverd",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "15",
                    "OnDemand",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
      ],
    },

    {
      title: "Total",
      children: [
        {
          title: "Walk In",
          dataIndex: "walkInTotal",
          key: "walkInTotal",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "6,7,8,9,10,11,15",
                    "WalkIn",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
        {
          title: "On Demand",
          dataIndex: "onDemandTotal",
          key: "onDemandTotal",
          render: (text, record) => (
            <Space size="middle">
              <Button
                type="link"
                onClick={() =>
                  this.getSplitOrderStatusWiseData(
                    "6,7,8,9,10,11,15",
                    "OnDemand",
                    record.storeID
                  )
                }
              >
                {text}
              </Button>
            </Space>
          ),
        },
      ],
    },
  ];

  Detailcolumns = [
    {
      title: "Store Name",
      dataIndex: "shortName",
      key: "shortName",
      //   width: '40%',
    },
    {
      title: "SplitOrder Number",
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      //   width: '40%',
    },
    {
      title: "SplitOrder Status",
      dataIndex: "splitOrderStatusName",
      key: "splitOrderStatusName",
      //   width: '40%',
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      //   width: '40%',
      render: (text) => {
        return ![undefined, null, ""].includes(text)
          ? moment(text).format("DD/MM/YYYY")
          : "";
      },
    },
    {
      title: "Delivery Type",
      dataIndex: "deliveryType",
      key: "deliveryType",
      //   width: '40%',
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      key: "orderType",
      //   width: '40%',
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      DeliveryType: this.state.selectedDeliverytype,
      FromOrderDate: this.state.orderFromDate,
      ToOrderDate: this.state.orderToDate,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      // StoreID:parseInt(this.state.selectedBranch),
      StoreID:this.state.selectedBranch,
      pagination,
    });
    this.getDeliveryTypes();
  }

  getDeliveryTypes = () => {
    this.DeliveryDashboardService.getDeliveryTypeList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          deliveryTypes: response.data,
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: labels.UNABLE_TO_GET_DATA_FOR_DELIVERY_TYPES,
        });
      }
    });
  };

  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.DeliveryDashboardService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            DeliveryType: this.state.selectedDeliverytype,
            FromOrderDate: this.state.orderFromDate,
            ToOrderDate: this.state.orderToDate,
            // StoreID:parseInt(this.state.selectedBranch),
            StoreID:this.state.selectedBranch,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          dashboardItems: items.data,
          pagination: {
            ...params.pagination,
            total: items.data.length > 0 ? items.data[0].totalRecords : 0,
          },
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "Delivery Dashboard",
            description: items.message
              ? items.message
              : "Unable to get Delivery Dashboard data",
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
      }
      this.setState({ loading: false });
    });
  };

  getSplitOrderStatusWiseData = (status, type, storeId) => {
    // this.setState({detailItems:[]})
    let params = {
      SplitOrderStatus: status,
      OrderType: type,
      DeliveryType: this.state.selectedDeliverytype,
      StoreID: storeId,
      FromOrderDate: this.state.orderFromDate,
      ToOrderDate: this.state.orderToDate,
    };
    this.setState({ modalLoading: true });
    this.DeliveryDashboardService.getDeliveryDatafromSplitOrderStatus(
      params
    ).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            DeliveryType: this.state.selectedDeliverytype,
            FromOrderDate: this.state.orderFromDate,
            ToOrderDate: this.state.orderToDate,
            StoreID:this.state.selectedBranch,
            // StoreID:parseInt(this.state.selectedBranch),
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }

        this.setState({
          detailItems: items.data,
          showAddPopup: true,
          paginationforDetailtable: {
            ...params.pagination,
            total: items.data.length > 0 ? items.data[0].totalRecords : 0,
          },
          // isRenderComponent: true,
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "Delivery Dashboard",
            description: items.message
              ? items.message
              : "Unable to get Delivery Dashboard data",
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
      }
      this.setState({ modalLoading: false });
    });
  };

  handleTableChange = (pagination) => {
    this.fetchItems({
      DeliveryType: this.state.selectedDeliverytype,
      FromOrderDate: this.state.orderFromDate,
      ToOrderDate: this.state.orderToDate,
      // StoreID:parseInt(this.state.selectedBranch),
      StoreID:this.state.selectedBranch,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  };

  // handleTableChangeforDetailData = (pagination) => {
  //   this.fetchItems({
  //     DeliveryType: this.state.selectedDeliverytype,
  //     FromOrderDate: this.state.orderFromDate,
  //     ToOrderDate: this.state.orderToDate,
  //     pageIndex: pagination.current,
  //     pageSize: pagination.pageSize,
  //     pagination,
  //   });
  // };

  // handleChangeBranch = () => {
  //   this.fetchItems({
  //     DeliveryType: this.state.selectedDeliverytype,
  //     FromOrderDate: this.state.orderFromDate,
  //     ToOrderDate: this.state.orderToDate,
  //     StoreID:parseInt(this.state.selectedBranch),
  //     pageIndex:this.state.pagination.current,
  //     pageSize:this.state.pagination.pageSize,
  //     // this.state.pagination,
  //   });
  // };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  render() {
    const storeBranchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo")).filter(
          (x) => x.branchType === "Store"
        )
      : [];

    const allStoreBranchArray = storeBranchTypes
      .map((branchType) => {
        return branchType.branchID;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();
    const { paginationforDetailtable, pagination, loading } = this.state;
    const dashboardItems = this.state.dashboardItems;
    const listItems = dashboardItems.map((item, index) => ({
      key: item.storeID,
      storeID: item.storeID,
      storeName: item.storeName,
      shortName: item.shortName,
      walkInFactoryIn: item.walkInFactoryIn,
      onDemandFactoryIn: item.onDemandFactoryIn,
      walkInStoreInPending: item.walkInStoreInPending,
      onDemandStoreInPending: item.onDemandStoreInPending,
      walkInPartialOrder: item.walkInPartialOrder,
      onDemandPartialOrder: item.onDemandPartialOrder,
      walkInReadytoDeliver: item.walkInReadytoDeliver,
      onDemandReadytoDeliver: item.onDemandReadytoDeliver,
      walkInPaidandUndeliverd: item.walkInPaidandUndeliverd,
      onDemandPaidandUndeliverd: item.onDemandPaidandUndeliverd,
      walkInTotal: item.walkInTotal,
      onDemandTotal: item.onDemandTotal,
      totalRecords: item.totalRecords,
    }));
    const detailItems = this.state.detailItems;
    const DetailListItem = detailItems.map((item) => ({
      // key: item.storeID,
      storeName: item.storeName,
      shortName: item.shortName,
      splitOrderNumber: item.splitOrderNumber,
      splitOrderStatusName: item.splitOrderStatusName,
      deliveryDate: item.deliveryDate,
      deliveryType: item.deliveryType,
      orderType: item.orderType,
    }));
    return (
      <div>
        <PageHeader
          title="Delivery Dashboard"
          extra={[
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.fetchItems({
                DeliveryType: this.state.selectedDeliverytype,
                FromOrderDate: this.state.orderFromDate,
                ToOrderDate: this.state.orderToDate,
                // StoreID:parseInt(this.state.selectedBranch),
                StoreID:this.state.selectedBranch,
                pageIndex: pagination.current,
                pageSize: pagination.pageSize,
                pagination,
              })
            }
            initialValues={{
              orderFromDate: this.state.orderFromDate,
              orderToDate: this.state.orderToDate,
              DeliveryType: this.state.selectedDeliverytype,
              storeId:this.state.selectedBranch
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={6}>
                <Form.Item name="DeliveryType">
                  <Select
                    allowClear
                    placeholder={labels.DELIVERY_TYPE}
                    style={{ minWidth: 150, maxWidth: 250 }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      this.setState(
                        {
                          selectedDeliverytype: [null, undefined, ""].includes(
                            value
                          )
                            ? undefined
                            : value,
                        },
                        () => {
                          // console.log(this.state.deliveryTypes.selectedDeliverytype);
                        }
                      )
                    }
                  >
                    {this.state.deliveryTypes.map((x) => {
                      return x.value != "-1" ? (
                        <option
                          key={x.value.toString()}
                          value={x.value.toString()}
                        >
                          {x.text}
                        </option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderFromDate")
                    }
                    value={this.state.orderFromDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderToDate")
                    }
                    value={this.state.orderToDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={6}>
                <Form.Item
                  name={"storeId"}
                  rules={[{ required: true, message: "Please select store " }]}
                >
                  <Select
                    showSearch 
                    mode="multiple"
                    allowClear
                    onChange={(event) => {
                      this.setState(
                        {
                          selectedBranch: [null, undefined, ""].includes(event)
                            ? ""
                            : event.join(","),
                        },
                        () => {
                          // this.handleChangeBranch();
                        }
                      );
                    }}
                    value={
                      [null, undefined, ""].includes(this.state.selectedBranch)
                        ? [localStorage.getItem("branchID")]
                        : this.state.selectedBranch.split(",")
                    }
                    // value={this.state.selectedBranch}
                    placeholder={labels.SELECT_STORE}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {storeBranchTypes.length !== 0
                      ? storeBranchTypes.map((branchType) => {
                          return branchType.branchID !== "-1" ? (
                            <Option
                              value={branchType.branchID.toString()}
                              key={branchType.branchID.toString()}
                            >
                              {branchType.branchName}
                            </Option>
                          ) : null;
                        })
                      : null}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}></TableActions>

        <Modal
          title={"Delivery Dashboard"}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          width={"50%"}
        >
          <Spin spinning={this.state.modalLoading}>
            <Table
              size="small"
              columns={this.Detailcolumns}
              dataSource={DetailListItem}
              loading={loading}
              pagination={paginationforDetailtable}
              //   onChange={this.handleTableChange}
              // onChange={this.handleTableChangeforDetailData}
            />
            <TableActions isPagination={true}></TableActions>
            <Row>
              <Col xs={24} className="custom-modal-footer">
                <Space>
                  <>
                    <Button
                      variant="transparentGreyOutline"
                      onClick={this.handlePopupCancel}
                    >
                      Close
                    </Button>
                  </>
                </Space>
              </Col>
            </Row>
          </Spin>
        </Modal>
      </div>
    );
  }
}
