import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../../Common/Styles/master.module.css";
import { Steps, PageHeader, Form, notification, Spin } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import GeneralForm from "./general.addfactory";
import ContactForm from "./contact.addfactory";
import FactoryService from "../../../../services/factoryservice";
import ROUTES from "../../../../routes";
const { Step } = Steps;

/**
 * Add Factory Form as a part of Factory Master Module
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 *
 * @module Master
 */
class AddFactory extends React.Component {
  factoryService = new FactoryService();
  /**
   * State Variables
   * @var factoryID : ID of factory 0 if this is to add factory
   * @var activeStep : holds active form step
   * @var stepDirection : holds direction of steps horizontal/vertical
   * @var stepSize : size of steps
   * @var generalFormValues : values submitted through generalForm
   * @var contactFormValues : values submitted through contactForm
   */
  state = {
    factoryID: 0,
    activeStep: 0,
    fullLoading: false,
    stepsDirection: "vertical",
    stepsSize: "default",
    generalFormValues: {},
    contactFormValues: {},
    mode: "add",
  };
  /**
   * A function to responsively set direction of the steps
   */
  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "small" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };

  /**
   * bind the setStepDirection to the resize event of windows
   */
  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    if (
      [ROUTES.EDIT_FACTORY, ":factoryID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });
      this.getFactory(this.props.location.state.factoryID);
    } else if (
      [ROUTES.VIEW_FACTORY, ":factoryID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.getFactory(this.props.location.state.factoryID);
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
  }

  getFactory = (id) => {
    this.setState({ fullLoading: true });
    this.factoryService.getFactory(id).then((response) => {
      if (
        response.statusCode === 200 &&
        response.data.factoryID === parseInt(id)
      ) {
        this.setState({ factoryID: response.data.factoryID });
        this.setState({
          generalFormValues: {
            factoryName: response.data.factoryName,
            shortName: response.data.shortName,
            openingDate: [null, undefined, ""].includes(
              response.data.openingDate
            )
              ? undefined
              : moment(response.data.openingDate, "M/D/YYYY"),
            deliveryType: [null, undefined, ""].includes(
              response.data.deliveryType
            )
              ? undefined
              : response.data.deliveryType.split(","),
            operatingDays: response.data.operatingDays.split(","),
            operatingTime: [
              moment(response.data.operatingTimeFrom, "h:mm a"),
              moment(response.data.operatingTimeTo, "h:mm a"),
            ],
            status: [null, undefined, ""].includes(response.data.status)
              ? undefined
              : response.data.status.toString(),
            serviceIDs: [null, undefined, ""].includes(response.data.serviceIDs)
              ? undefined
              : response.data.serviceIDs.split(","),
          },
        });
        this.setState({
          contactFormValues: {
            state: [null, undefined, ""].includes(response.data.stateID)
              ? undefined
              : response.data.stateID.toString(),
            city: [null, undefined, ""].includes(response.data.cityID)
              ? undefined
              : response.data.cityID.toString(),
            address: response.data.address,
            zone: [null, undefined, ""].includes(response.data.zone)
              ? undefined
              : response.data.zone.toString(),
            phoneNumber: response.data.phone,
            factoryExecutive: response.data.factoryExecutiveName,
            factoryManager: response.data.factoryManagerName,
          },
        });
        this.setState({ fullLoading: false });
        this.setState({ activeStep: -1 });
        this.setState({ activeStep: 0 });
      } else {
        notification["error"]({
          message: "Factory Master",
          description: "Unable to get factory data",
          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
      this.setState({ fullLoading: false });
    });
  };
  addUpdateFactory = () => {
    this.setState({ fullLoading: true });
    const postData = {
      factoryID: this.state.factoryID,
      factoryName: this.state.generalFormValues.factoryName,
      shortName: this.state.generalFormValues.shortName,
      openingDate: this.state.generalFormValues.openingDate
        ? this.state.generalFormValues.openingDate.format("YYYY-MM-DD")
        : null,
      deliveryType: this.state.generalFormValues.deliveryType.join(","),
      operatingDays: this.state.generalFormValues.operatingDays.join(","),
      operatingTimeFrom: this.state.generalFormValues.operatingTime[0].format(
        "HH:mm"
      ),
      operatingTimeTo: this.state.generalFormValues.operatingTime[1].format(
        "HH:mm"
      ),
      status: parseInt(this.state.generalFormValues.status),
      stateID: parseInt(this.state.contactFormValues.state),
      cityID: parseInt(this.state.contactFormValues.city),
      address: this.state.contactFormValues.address,
      zone: parseInt(this.state.contactFormValues.zone),
      phone: this.state.contactFormValues.phoneNumber,
      factoryExecutiveName: this.state.contactFormValues.factoryExecutive,
      factoryManagerName: this.state.contactFormValues.factoryManager,
      serviceIDs: this.state.generalFormValues.serviceIDs.join(","),
    };
    this.factoryService.addUpdateFactory(postData).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: "Factory Master",
          description: response.message ? response.message : "Form Submitted",
          onClose: () => {
            this.props.history.goBack();
          },
        });
      } else {
        notification["error"]({
          message: "Factory Master",
          description: response.message
            ? response.message
            : "Unable to submit the form",
        });
      }
      this.setState({ fullLoading: false });
    });
  };
  generalForm = React.createRef();
  contactForm = React.createRef();
  handleSubmit = () => {
    const generalValidator = this.generalForm.current.validateFields();
    const contactValidator = this.contactForm.current.validateFields();
    Promise.allSettled([generalValidator, contactValidator]).then(
      (responses) => {
        if (responses[0].status === "rejected") {
          this.setState({ activeStep: 0 });
        } else if (responses[1].status === "rejected") {
          this.setState({ activeStep: 1 });
        } else if (
          responses[0].status === "fulfilled" &&
          responses[1].status === "fulfilled"
        ) {
          this.setState(
            {
              generalFormValues: responses[0].value,
              contactFormValues: responses[1].value,
            },
            () => {
              this.addUpdateFactory();
            }
          );
        }
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          {/* {JSON.stringify(this.state)} */}
          {/**
           * Page Header Component from Ant Design
           * Please check below link for more options
           * https://ant.design/components/page-header/
           * https://ant.design/components/page-header/#API
           *
           */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? "Edit"
                : this.state.mode === "view"
                ? "View"
                : "Add",
              "Factory",
            ].join(" ")}
          />

          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              {/**
               * Steps Component from Ant Design
               * Please check below link for more options
               * https://ant.design/components/steps/
               * https://ant.design/components/steps/#API
               *
               */}
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => this.setState({ activeStep: current })}
              >
                <Step
                  title="General"
                  description="General Factory Information"
                />
                <Step
                  title="Contact"
                  description="Factory Contact Information"
                />
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider>
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading}>
                  <React.Fragment>
                    <GeneralForm
                      values={this.state.generalFormValues}
                      backFunction={() => this.props.history.goBack()}
                      nextFunction={() => this.setState({ activeStep: 1 })}
                      isActive={this.state.activeStep === 0}
                      instance={this.generalForm}
                      mode={this.state.mode}
                    />
                    <ContactForm
                      values={this.state.contactFormValues}
                      backFunction={() =>
                        this.setState({ activeStep: this.state.activeStep - 1 })
                      }
                      nextFunction={this.handleSubmit}
                      isActive={this.state.activeStep === 1}
                      instance={this.contactForm}
                      mode={this.state.mode}
                    />
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(AddFactory);
