import React from "react";
import styles from "./storeinventorycomponent.module.css";
import { PageHeader, Row, Col, notification, Spin } from "antd";
import { Link } from "react-router-dom";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import StoreInventoryService from "../../../services/storeinventoryservice";
import { labels } from "../../../shared/language";
import ROUTES from "../../../routes";

export default class StoreInventory extends React.Component {
  StoreInventoryService = new StoreInventoryService();
  UserRightsConfiguration = new UserRightsConfiguration();
  state = {
    fullLoading: false,
    storeName: localStorage.getItem("Store/FactoryName"),
    storeInventoryData: [],
    garmentPendingToSendToFactory: 0,
    ordersPendingtosendtoFactory: 0,
    pendingForNoOfDays: 0,
    garmentsReceivedFromFactory: 0,
    splitOrderReceivedFromFactory: 0,
    isInventoryDataLoaded: false,
    statsData: [], //Array of objects to display the stats
    userRights: [],
  };

  componentDidMount() {
    this.fetchItems();
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.STORE_INVENTORY
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  fetchItems() {
    this.setState({ fullLoading: true });
    let storeID = parseInt(localStorage.getItem("branchID"));
    this.StoreInventoryService.retrieveItems(storeID).then((items) => {
      if (items.statusCode === 200) {
        this.setState({
          storeInventoryData: items.data,
          isInventoryDataLoaded: true,
        });
        if (this.state.isInventoryDataLoaded === true) {
          this.setState({
            statsData: [
              {
                title: labels.GARMENTS_PENDING_TO_SEND_TO_FACTORY,
                count: this.state.storeInventoryData
                  .garmentPendingToSendToFactory,
                search: "Tagged In",
              },
              {
                title: labels.GARMENTS_RECEIVED_FROM_FACTORY,
                count: this.state.storeInventoryData
                  .garmentsReceivedFromFactory,
                search: "Performa Generated",
              },
              {
                title: labels.ORDERS_PENDING_TO_SEND_TO_FACTORY,
                count: this.state.storeInventoryData
                  .ordersPendingtosendtoFactory,
                search: "Tagged In",
              },
              {
                title: labels.SPILT_ORDERS_RECEIVED_FROM_FACTORY,
                count: this.state.storeInventoryData
                  .splitOrderReceivedFromFactory,
                search: "Performa Generated",
              },
              {
                title: labels.PENDING_FOR_NO_OF_DAYS,
                count: this.state.storeInventoryData.pendingForNoOfDays,
              },
            ],
          });
        }
      } else {
        notification["error"]({
          message: labels.INVENTORY,
          description: items.message
            ? items.message
            : labels.UNABLE_TO_GET_STORE_GARMENT_INVENTORY_DATA,
        });
      }
      this.setState({ fullLoading: false });
    });
  }

  render() {
    const StatsBox = (props) => {
      /**
       * Array of background colors
       */
      // const colors = ["#FE1D7F", "#FCC350", "#6ABEFF", "#7E13D5"];
      // StatsBox.count++;
      return (
        <div
          className={styles.StatsWrapper}
          // style={{ backgroundColor: "#ffffff" }}
        >
          <div>
            <div className={styles.StatsTitle}>{props.title}</div>
          </div>
          <div className={styles.StatsIcon}>
            <div className={styles.StatsCount}>{props.count}</div>
          </div>
        </div>
      );
    };
    /**
     * Static property
     */
    StatsBox.count = -1;
    return (
      <PageHeader
        className={styles.TransferInHeader}
        title={labels.STORE_GARMENT_INVENTORY}
        extra={this.state.storeName}
      >
        <Spin spinning={this.state.fullLoading}>
          <Row gutter={28}>
            {this.state.statsData.map((singleData, index) => {
              return singleData.search === "Tagged In" ? (
                <Col xs={24} lg={8} key={index}>
                  <Link
                    to={{
                      pathname: ROUTES.ORDER_LISTING,
                      state: { search: singleData.search },
                    }}
                    style={{ display: "inline-block", width: "100%" }}
                  >
                    <StatsBox
                      title={singleData.title}
                      count={singleData.count}
                      // icon={singleData.icon}
                    ></StatsBox>
                  </Link>
                </Col>
              ) : singleData.search === "Performa Generated" ? (
                <Col xs={24} lg={8} key={index}>
                  <Link
                    to={{
                      pathname: ROUTES.STORE_READY_FOR_DELIVERY_ORDERS,
                      state: { search: singleData.search },
                    }}
                    style={{ display: "inline-block", width: "100%" }}
                  >
                    <StatsBox
                      title={singleData.title}
                      count={singleData.count}
                      // icon={singleData.icon}
                    ></StatsBox>
                  </Link>
                </Col>
              ) : (
                <Col xs={24} lg={8} key={index}>
                  <StatsBox
                    title={singleData.title}
                    count={singleData.count}
                    // icon={singleData.icon}
                  ></StatsBox>
                </Col>
              );
            })}
          </Row>
        </Spin>
      </PageHeader>
    );
  }
}
