import React from "react";
import TransferOut from "../../../components/FactoryModule/TransferOut/transferout.factory.component";

/**
 * This file holds the view of Transfer In screen
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 11 June 2020
 */
export default class TransferOutScreen extends React.Component {
  render() {
    return (
      <div>
        <TransferOut/>
      </div>
    )
  }
}
