import React from "react";
import FactoryMappingForm from "./factorymappingform.component";
import {
  Table,
  Space,
  PageHeader,
  Typography,
  Popconfirm,
  notification,
} from "antd";
import Button from "../../../Common/Button/button";
import styles from "./createstore.module.css";
import StoreService from "../../../../services/storeservice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Moment from "moment";

const { Title } = Typography;
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

/**
 * A tabular view as a part of create store - Slot Mapping, Slot List
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 2 June 2020
 *
 * @module Store
 */
export default class FactoryMapping extends React.Component {
  constructor(props) {
    super(props);
    this.storeservice = new StoreService();
    this.state = {
      isAdd: false,
      isEdit: false,
      isViewmode: false,
      disabled: false,
      factoryMappinglist: [],
      storeFactoryMappingID: 0,
      factoryID: 0,
      factoryName: "",
      offeredServiceIds: "",
      offeredServicesName: "",
      factoryFormValues: {},
    };
  }

  componentDidMount() {
    this.setState({ isViewmode: this.props.isViewmode });
    this.getFactoryMappingList(this.props.storeID);
  }

  getFactoryMappingList = (storeID) => {
    this.storeservice.getFactoryMappingList(storeID).then((response) => {
      this.setState({ factoryMappinglist: response.data });
    });
  };

  getStoreFactoryMappingByID(storeFactoryMappingID) {
    this.storeservice
      .getStoreFactoryMappingByID(storeFactoryMappingID)
      .then((response) => {
        const res = response.data.offeredServiceIds.split(",");
        //   .map((x) => parseInt(x));
        this.setState({
          isEdit: true,
          storeFactoryMappingID: storeFactoryMappingID,
          factoryID: response.data.factoryID.toString(),
          factoryName: response.data.factoryName,
          offeredServiceIds: response.data.offeredServiceIds,
          offeredServicesName: response.data.offeredServices,
          //   factoryFormValues: {
          //     factoryID: response.data.factoryID.toString(),
          //     factoryName: response.data.factoryName,
          //     offeredServiceIds: res,
          //     // offeredServiceIds: response.data.offeredServiceIds
          //     //   .split(",")
          //     //   .map((x) => {
          //     //     x;
          //     //   }),
          //     offeredServicesName: response.data.offeredServices,
          //   },
        });
      });
  }

  getStoreFactoryMappingViewByID(storeFactoryMappingID) {
    this.storeservice
      .getStoreFactoryMappingByID(storeFactoryMappingID)
      .then((response) => {
        this.setState({
          isView: true,
          disabled: true,
          storeFactoryMappingID: storeFactoryMappingID,
          factoryID: response.data.factoryID.toString(),
          factoryName: response.data.factoryName,
          offeredServiceIds: response.data.offeredServiceIds,
          offeredServicesName: response.data.offeredServices,
          factoryFormValues: {
            factoryID: response.data.factoryID.toString(),
            factoryName: response.data.factoryName,
            offeredServiceIds: response.data.offeredServices, //response.data.offeredServiceIds.split(","),
            offeredServicesName: response.data.offeredServices,
          },
        });
      });
  }

  deleteStoreFactoryMappingByID(storeFactoryMappingID) {
    this.storeservice
      .deleteStoreFactoryMappingByID(storeFactoryMappingID)
      .then((response) => {
        if (response.result === "Success") {
          openNotificationWithIcon(
            "success",
            "Factory Mapping",
            response.message
          );
        } else {
          openNotificationWithIcon(
            "error",
            "Factory Mapping",
            response.message
          );
        }
        this.getFactoryMappingList(this.props.storeID);
      });
  }

  columns = [
    {
      title: "Factory Name",
      dataIndex: "factoryName",
      key: "factoryName",
    },
    {
      title: "Offered Services",
      dataIndex: "offeredServices",
      key: "offeredServices",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={(event) =>
              this.getStoreFactoryMappingViewByID(record.storeFactoryMappingID)
            }
          >
            <FontAwesomeIcon icon={faInfo} />
          </Button>
          {!this.props.isViewmode ? (
            <Button
              type="link"
              onClick={(event) =>
                this.getStoreFactoryMappingByID(record.storeFactoryMappingID)
              }
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          ) : null}
          {!this.props.isViewmode ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={(event) =>
                this.deleteStoreFactoryMappingByID(record.storeFactoryMappingID)
              }
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  /**
   * A function to modify state varibles from child
   */
  bringback = () => {
    this.setState({
      isAdd: false,
      isEdit: false,
      isView: false,
      disabled: false,
      storeFactoryMappingID: 0,
      factoryFormValues: null,
    });
  };
  render() {
    //const{data,handleSaveUpdateSlotMapping,slotMappingList,storeID,isView,disabled,isViewmode}=this.props;
    return this.state.isAdd || this.state.isEdit || this.state.isView ? (
      <FactoryMappingForm
        backFunction={this.bringback}
        factoryMappinglist={this.props.data}
        storeID={this.props.storeID}
        storeFactoryMappingID={this.state.storeFactoryMappingID}
        factoryID={this.state.factoryID}
        factoryName={this.state.factoryName}
        offeredServiceIds={this.state.offeredServiceIds}
        offeredServicesName={this.state.offeredServicesName}
        isViewmode={this.props.isViewmode}
        // values={this.state.factoryFormValues}
        isView={this.state.isView}
        disabled={this.state.disabled}
        isAdd={this.state.isAdd === true ? "add" : ""}
      />
    ) : (
      <React.Fragment>
        {/**
         * Page Header Component to display title and button
         * https://ant.design/components/page-header/
         * https://ant.design/components/page-header/#API
         */}
        <PageHeader
          title={
            <Title level={4} className={styles.SlotMappingTitle}>
              Factory Mapping
            </Title>
          }
          extra={[
            !this.props.isViewmode ? (
              <Button
                variant="purple"
                shape="round"
                size="large"
                onClick={() => {
                  this.setState({ isAdd: true });
                }}
                key="1"
              >
                Add Factory Mapping
              </Button>
            ) : null,
          ]}
          className={styles.SlotMappingHeader}
        />
        {/**
         * Please go through below documention for <Table> Component
         * https://ant.design/components/table/
         * https://ant.design/components/table/#API
         *
         */}
        <Table
          size="small"
          bordered
          columns={this.columns}
          dataSource={this.state.factoryMappinglist}
          pagination={false}
        />
      </React.Fragment>
    );
  }
}
