import React from "react";
import styles from "./factoryinventory.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import {
  PageHeader,
  Input,
  Select,
  Table,
  Row,
  Col,
  Form,
  notification,
  Checkbox,
  Space,
  Tooltip,
  Modal,
  Spin,
  DatePicker,
} from "antd";
import { Link, withRouter } from "react-router-dom";
import ROUTES from "../../../routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faFileExcel,
  faInfo,
  // faPencilAlt,
  // faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { IsAuthenticated } from "../../../services/auth-header";
import FactoryInventory from "../../../services/factoryinventoryservice";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Moment from "moment";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
const { Option } = Select;
const { Search } = Input;
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);

export default class inventorysummarycomponent extends React.Component {
  UserRightsConfiguration = new UserRightsConfiguration();
  inventoryService = new FactoryInventory();
  common = new Common();

  state = {
    showfilterBox: true,
    deliveryFromDate: Moment(ONE_MONTHS_AGO),
    deliveryToDate: Moment(),
    factoryID: parseInt(localStorage.getItem("branchID")),
    showInfoPopup: false,
    inventoryData: [],
    loading: true,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    SplitOrderNumber: "",
    OldDeliveryDate: Moment(),
    deliverydate: Moment(),
    modalLoading: false,
    excelFile: <React.Fragment></React.Fragment>,
    excelButtonLoading: false,
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.FACTORY_GARMENT_INVENTORY
    );
    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.SERVICE_TYPE,
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: labels.ORDER_COUNT,
      dataIndex: "ordercount",
      key: "ordercount",
      render: (text, record) => (
        <React.Fragment>
          <Space>
            <Link
              to={{
                pathname: [
                  ROUTES.FACTORY_GARMENT_INVENTORY,
                  record.serviceID,
                  Moment(this.state.deliveryFromDate).format("YYYY-MM-DD"),
                  Moment(this.state.deliveryToDate).format("YYYY-MM-DD"),
                ].join("/"),
                state: {
                  serviceID: record.serviceID,
                  deliveryFromDate: Moment(this.state.deliveryFromDate).format("YYYY-MM-DD"),
                  deliveryToDate: Moment(this.state.deliveryToDate).format("YYYY-MM-DD")
                },
              }}
            >
              {record.ordercount}
            </Link>
          </Space>
        </React.Fragment>
      ),
    },

    {
      title: labels.NO_OF_GARMENT_DUE_FOR_PACKAGING,
      dataIndex: "packingDueGarmentCount",
      key: "packingDueGarmentCount",
    },
  ];

  componentDidMount() {
    this.getFactoryInventorySummary();
  }

  getFactoryInventorySummary = () => {
    let Param = {};
    Param = {
      FactoryID: this.state.factoryID,
      DeliveryFromDate: this.state.deliveryFromDate,
      DeliveryToDate: this.state.deliveryToDate,
    };
    this.setState({ loading: true });

    this.inventoryService.getFactoryInventorySummary(Param).then((response) => {
      if (response.statusCode === 200) {
        this.setState({ inventoryData: response.data });
      } else {
        notification["error"]({
          message: labels.INVENTORY,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_FACTORY_GARMENTS_INVENTORY_DATA,
        });
      }
      this.setState({ loading: false });
    });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  render() {
    const { loading } = this.state;
    const inventoryData = this.state.inventoryData;
    console.log("inventoryData->", inventoryData);
    const listItems = inventoryData.map((item) => ({
      serviceName: item.serviceName,
      serviceID: item.serviceID,
      ordercount: item.ordercount,
      packingDueGarmentCount: item.packingDueGarmentCount,
    }));
    return (
      <div>
        {this.state.excelFile}
        <PageHeader
          title={labels.INVENTORY_SUMMARY}
          extra={[
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
              key="3"
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() => this.getFactoryInventorySummary()}
            initialValues={{
              deliveryFromDate: this.state.deliveryFromDate,
              deliveryToDate: this.state.deliveryToDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"deliveryFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "deliveryFromDate")
                    }
                    value={this.state.deliveryFromDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(Moment(), "day") ? true : false
                    // }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"deliveryToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "deliveryToDate")
                    }
                    value={this.state.deliveryToDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(Moment(), "day") ? true : false
                    // }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          onRow={(record, rowIndex) => {
            return {
              style: { background: record.bgColor },
            };
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={false}
          loading={loading}
        />
      </div>
    );
  }
}
