import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  TimePicker,
  Input,
  DatePicker,
  Space,
  notification,
  AutoComplete,
} from "antd";
import Button from "../../../../Common/Button/button";
import PickupService from "../../../../../services/pickupservice";
import Moment from "moment";
import {
  RegexDecimalAndRestrictFirstZero,
  RegexInteger,
  MessageNumbers,
} from "../../../../../shared/validator";
import { labels } from "../../../../../shared/language";
import Common from "../../../../../services/common";
const { Option } = Select;

/**
 * General form as a part of create pickup screen
 *
 * @author Payal Bharakhda
 * @created 13 July 2020
 */
export default class GeneralForm extends React.Component {
  constructor(props) {
    super(props);
  }
  /**
   * Initalizing Pickup Service
   */
  pickupService = new PickupService();
  common = new Common();
  /**
   * Storing objects for dynamic controls
   *
   * @added 13 July 2020
   */
  state = {
    selectedPickupStatus: "1",
    selectedTimeSlot: " ",
    selectedDeliverySlot: " ",
    selectedCancelReason: "",
    deliveryTypes: [],
    addDisable: true,

    pickupSlotView: "",
    deliverySlotView: "",
    isDeliverySlotViewEnable: true,
    PickupDateDisabled: true,
    responseDaysData: [],
    daysDeliveryType: 0,
    servicesSelection: [],
    pickupdate: "",
    promocodeVal: "",
    deliveryDate:
      this.props.values.deliveryDate !== undefined
        ? this.props.values.deliveryDate
        : Moment(),
    promocodeResponse: [],
    isValidCode: "",
    isCancelRemarkVisible: false,
    isResceduleRemarkVisible: false,
    pickupStatus: {
      disabled: true,
      data: [],
    },
    opInstruct: {
      disabled: true,
      data: [],
    },
    services: {
      disabled: true,
      data: [],
    },
    reason: {
      // disabled: true,
      data: [],
    },
    pickupExecutive: {
      disabled: true,
      data: [],
    },

    timeSlot: {
      disabled: true,
      data: [],
    },

    customerName: {
      //  disabled: true,
      data: [],
      selected: null,
    },
    address: {
      // disabled:true,
      data: [],
      selected: null,
    },
    storeNameByCustAddress: {
      data: [],
      storeID: 0,
      storeName: "",
    },
    HolidayList: [],
    dDate: "",
    selectedPickupSlotValue: "",
  };
  /**
   * API Calls on component mounting to populate dropdowns
   * @added 13 July 2020
   */
  componentDidMount() {
    this.getDeliveryTypes();
    this.getPickupStatus();
    this.getOpInstruct();
    this.getServices();
    this.getCancelReasons();
    this.getHolidayList();
    // if(this.state.storeNameByCustAddress.storeID===parseInt(localStorage.getItem("branchID"))){
    // this.getTimeSlot();
    // }
    this.daysBasedOnServices();

    if (this.props.values.customerName !== undefined) {
      if (this.props.mode !== "view" && this.props.values.customerName != 0) {
        // console.log("here",this.props.values.customerName);
        this.onSearch(this.props.values.customerName);
        this.getAddressesByCustomerID(this.props.values.customerName);
      }
    }

    if (this.props.mode == "add") {
      if (
        this.props.values.customerName != undefined &&
        this.props.values.customerName != 0
      ) {
        this.getAddressesByCustomerID(this.props.values.customerName);
      }
    }

    if (this.props.mode !== "add") {
      this.setState(
        {
          daysDeliveryType: this.props.values.deliveryTypeID,
          pickupdate: this.props.values.pickupDate,
          servicesSelection: this.props.values.serviceIDs,
        },
        () =>
          this.setState({
            pickupSlotView: Moment(this.state.pickupdate).format("ddd"),
          })
      );
      if (this.props.storeID > 0) {
        if (this.props.mode !== "view") {
          this.getTimeSlot(this.props.storeID);
        }
      }
    } else {
      // this.setState({ pickupdate: Moment() }, () =>
      //   this.setState({ pickupSlotView: this.state.pickupdate.format("ddd") })
      // );
    }
    this.setState({
      deliverySlotView: ![null, undefined, ""].includes(
        this.props.values.deliveryDate
      )
        ? this.props.values.deliveryDate.format("ddd")
        : null,
    });
  }
  /**
   * Populating  Dropdowns
   * @added 13 July 2020
   */

  getDeliveryTypes = () => {
    this.pickupService.getDeliveryTypeList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          deliveryTypes: response.data,
          // {
          //   ...this.state.deliveryTypes,
          //   data: response.data,
          //   disabled: false,
          // },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: labels.UNABLE_TO_GET_DATA_FOR_DELIVERY_TYPES,
        });
      }
    });
  };

  getPickupStatus = () => {
    this.pickupService.getPickupStatusList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          pickupStatus: {
            ...this.state.pickupStatus,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_DATA_FOR_PICKUP_STATUS,
        });
      }
    });
  };

  getOpInstruct = () => {
    this.pickupService.getOpInstructList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          opInstruct: {
            ...this.state.opInstruct,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_LIST_OF_OPERATION_INSTRUCTION_DATA,
        });
      }
    });
  };

  getServices = () => {
    this.pickupService.getServices().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          services: {
            ...this.state.services,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_SERVICES_DATA,
        });
      }
    });
  };

  getCancelReasons = () => {
    this.pickupService.getCancelReasonsList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          reason: {
            ...this.state.reason,
            data: response.data,
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_LIST_OF_REASONS_DATA,
        });
      }
    });
  };

  getTimeSlot = (id) => {
    if (id === parseInt(localStorage.getItem("branchID"))) {
      var storeid = parseInt(localStorage.getItem("branchID"));
    } else {
      var storeid = id;
    }
    this.pickupService.getTimeSlotList(storeid).then((response) => {
      console.log("response->", response);
      if (response.statusCode === 200) {
        this.setState({
          timeSlot: {
            ...this.state.timeSlot,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_DATA_FOR_TIMESLOT,
        });
      }
    });
  };

  onSearch = (searchText) => {
    this.pickupService.getCustomerData(searchText).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          customerName: {
            ...this.state.customerName,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_DATA_FOR_CUSTOMER,
        });
      }
    });
  };

  getAddressesByCustomerID = (id) => {
    // console.log("customerID->", id);
    this.pickupService.getCustomerAddresses(parseInt(id)).then((response) => {
      if (response.statusCode === 200) {
        let modifiedData = response.data.map((singleObj) => {
          singleObj.key = singleObj.customerAddressID;
          return singleObj;
        });
        this.setState({
          address: {
            ...this.state.address,
            data: response.data,
            // disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_CUSTOMER_ADDRESSES,
        });
      }
    });
  };

  getHolidayList = () => {
    var postData =
      parseInt(this.state.storeNameByCustAddress.storeID) === 0
        ? parseInt(localStorage.getItem("branchID"))
        : parseInt(this.state.storeNameByCustAddress.storeID);

    this.pickupService.retriveHolidaylist(postData).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          HolidayList: response.data,
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_HOLIDAYLIST,
        });
      }
    });
  };

  getStoreBycustomerAddressID = (id) => {
    this.pickupService
      .getStoreByCustomerAddressID(parseInt(id))
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState(
            {
              storeNameByCustAddress: {
                ...this.state.storeNameByCustAddress,
                data: response.data,
                storeID: response.data.storeID,
                storeName: response.data.storeName,
                // disabled: false,
              },
            },
            () =>
              this.props.getStoreIDStoreName(
                this.state.storeNameByCustAddress.data
              ),
            this.getTimeSlot(response.data.storeID)
          );
          this.setState({
            PickupDateDisabled: false,
          });
          this.getHolidayList();
        } else {
          notification["error"]({
            message: labels.PICKUPS,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_STORE_NAME,
          });
        }
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.deliveryDate != this.state.deliveryDate) {
      this.props.getDeliveryDateFunction(this.state.deliveryDate);
    }
  }

  daysBasedOnServices() {
    this.pickupService.getDaysBasedOnServices().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          responseDaysData: response.data,
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_DATA,
        });
      }
    });
  }

  skipHoliday = (deliveryDate) => {
    let temparr = this.state.HolidayList;
    var delivery_date = Moment(deliveryDate).format("YYYY-MM-DD");
    var pickup_date = Moment(this.state.pickupdate).format("YYYY-MM-DD");
    // console.log("pickup_date->>>>",pickup_date)

    var HolidayArr = [];

    HolidayArr = temparr.filter((a) => {
      var holiday_date = Moment(a.holidayDate).format("YYYY-MM-DD");
      return pickup_date <= holiday_date && delivery_date >= holiday_date;
    });
    let len = HolidayArr.length;
    if (len > 0) {
      deliveryDate.add(len, "d");
    }
    this.skip2(deliveryDate);
    // return deliveryDate;
  };

  skip2 = (deliveryDate) => {
    let temparr = this.state.HolidayList;
    var HolidayArr = [];
    var delivery_date = Moment(deliveryDate).format("YYYY-MM-DD");
    HolidayArr = temparr.filter((a) => {
      var holiday_date = Moment(a.holidayDate).format("YYYY-MM-DD");
      return delivery_date == holiday_date;
    });
    let len = HolidayArr.length;

    var new_deliveryDate;
    if (len > 0) {
      new_deliveryDate = deliveryDate.add(len, "d");
      this.skip2(new_deliveryDate);
    }
  };

  calculateDeliveryDate = () => {
    // if (this.props.mode != "edit")
    //   this.setState({ selectedPickupSlotValue: "" });
    // check for selected services
    //delivery type=time/express/semi express
    // selectmax days out of selected services
    // add max days on pickupdate and make it as delivery date
    let service = this.state.servicesSelection;
    let pickupDate = this.state.pickupdate;

    let deliveryType = parseInt(this.state.daysDeliveryType);
    let max = 0;
    if (![null, undefined, ""].includes(service)) {
      // condition added for rescheduling the pickup in case request comes from delivery app
      this.state.responseDaysData
        .filter((object) => service.includes(object.id.toString()))
        .map((filteredObj) => {
          if (deliveryType === 1) {
            max = max < filteredObj.time ? filteredObj.time : max;
          } else if (deliveryType === 2) {
            max = max < filteredObj.semiExpress ? filteredObj.semiExpress : max;
          } else if (deliveryType === 3) {
            max = max < filteredObj.express ? filteredObj.express : max;
          }
        });
      let deliveryDate = Moment(pickupDate);
      deliveryDate.add(max, "d");
      // console.log("max->",max);
      this.skipHoliday(deliveryDate);
      // console.log("deliveryDate->",deliveryDate);

      this.setState({ deliveryDate: deliveryDate, dDate: deliveryDate });
      this.props.instance.current.setFieldsValue({
        deliveryDate: deliveryDate,
        deliverySlot: undefined,
      });
      //getting weekday from date
      this.setState({ deliverySlotView: Moment(deliveryDate).format("ddd") });
      this.setState({ isDeliverySlotViewEnable: false });
    }
    //getting weekday from date [in case of edit[reschedule]]
    this.setState({
      pickupSlotView: Moment(this.state.pickupdate).format("ddd"),
    });
  };

  validatePromocode = () => {
    if (
      this.props.mode !== "edit"
        ? !["", null].includes(this.state.customerName.selected)
        : !["", null].includes(this.props.values.customerName !== null) &&
          // this.state.customerName.selected !== "" &&
          // this.state.customerName.selected !== null
          //   // this.state.customerName.selected != undefined &&
          this.props.mode !== "edit"
        ? !["", null].includes(this.state.address.selected)
        : !["", null].includes(this.props.values.address) &&
          // this.state.address.selected !== "" &&
          // this.state.address.selected !== null &&
          // // this.state.address.selected != undefined &&
          this.state.servicesSelection !== "" &&
          this.state.servicesSelection !== null &&
          // this.state.servicesSelection != undefined &&
          this.state.promocodeVal !== "" &&
          this.state.promocodeVal !== null &&
          this.state.promocodeVal !== undefined
    ) {
      const params = {
        customerID:
          this.props.mode === "edit"
            ? this.props.values.customerName
            : this.state.customerName.selected,
        customerAddressID:
          this.props.mode === "edit"
            ? this.props.values.address
            : this.state.address.selected,
        serviceIDs: this.state.servicesSelection.toString(),
        pickupDate: this.state.pickupdate.format("YYYY-MM-DD"),
        promocode: this.state.promocodeVal,
        pickupID: this.props.pickupID,
      };
      //  debugger
      // if (this.state.promocodeVal != "") {
      this.pickupService.validateDisPromocode(params).then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            promocodeResponse: response.data,
          });
          if (this.state.promocodeResponse.responseMessage === "InValid") {
            notification["error"]({
              message: labels.PICKUPS,
              description: labels.PROMOCODE_IS_INVALID,
            });
            this.props.isValidCodeMethod(false);
            this.props.instance.current.setFieldsValue({ promocode: null });
            this.setState({ promocodeVal: "" });
          } else {
            this.props.isValidCodeMethod(true);
            // this.props.instance.current.setFieldsValue({ promocode: null });
            // this.setState({ promocodeVal: "" });
            notification["success"]({
              message: labels.PICKUPS,
              description: response.message
                ? this.common.Message(response.message)
                : labels.PROMOCODE_APPLIED_SUCCESSFULLY,
            });
          }
        } else {
          notification["error"]({
            message: labels.PICKUPS,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_VALIDATE_PROMOCODE,
          });
        }
      });
      //}
    } else {
      notification["error"]({
        message: labels.PICKUPS,
        description:
          labels.PLEASE_INPUT_CUSTOMERNAME_ADDRESS_SERVICES_PROMOCODE,
      });
    }
  };

  onChangeCode = (event, callback = () => null) => {
    this.setState({ promocodeVal: event.target.value }, callback());
  };

  handleSelectStatusChange = (event) => {
    this.setState({ selectedPickupStatus: event });
  };

  handlePickupSlotChange = (event) => {
    var slots = this.state.timeSlot.data.filter((x) => x.slotID == event);
    if (slots.length > 0) {
      this.setState({
        selectedTimeSlot: slots[0].slotID,
        selectedPickupSlotValue: slots[0].slotFrom,
      });
    }
    this.props.instance.current.setFieldsValue({
      deliverySlot: undefined,
    });
  };

  handleDeliverySlotChange = (event) => {
    this.setState({ selectedDeliverySlot: event });
  };

  handleCancelResonChange = (event) => {
    console.log("event->", event);
    // console.log("selectedCancelReason->",this.state.selectedCancelReason);
    this.setState({ selectedCancelReason: event });
  };

  render() {
    const disableAll = this.props.mode === "view";
    var editDisable = this.props.mode === "edit";
    var addDisableMode = this.props.mode === "add";
    var statusDisable = this.props.pickupStatusID;
    var sourceFromDelivery = this.props.sourceFromDelivery;
    var submitbtnloading = this.props.submitbtnloading;
    // console.log(this.state.selectedPickupStatus, statusDisable);
    // var nextDeliveryDate = Moment(this.state.deliveryDate).add(2, "days");
    var current = !["", undefined, null].includes(this.state.pickupdate)
      ? new Date(this.state.pickupdate.format("MM/DD/YYYY"))
      : new Date();
    var followingDay = new Date(current.getTime() + 86400000); // + 1 day in ms
    followingDay.toLocaleDateString();

    var pickupcurrentTime = Moment().format("HH:mm:00").toString();
    var currentTime = ![undefined, null, ""].includes(
      this.state.selectedPickupSlotValue
    )
      ? this.state.selectedPickupSlotValue
      : this.props.mode === "add"
      ? Moment().format("HH:mm:00").toString()
      : ![null, undefined, ""].includes(this.props.pickupTimeSlotName)
      ? this.props.pickupTimeSlotName.split("-").toString()
      : null;

    var Cancelled_show = JSON.parse(
      localStorage.getItem("buttonAccess")
    ).filter((item) => {
      return item.accessName == "Cancelled";
    });

    var RESCHEDULE_PICKUP_BY_ADMIN = JSON.parse(
      localStorage.getItem("buttonAccess")
    ).filter((item) => {
      return item.accessName == "Reschedule Pickup By Admin";
    });

    console.log("RESCHEDULE_PICKUP_BY_ADMIN->", RESCHEDULE_PICKUP_BY_ADMIN);

    return (
      <Form
        // key={this.props.values.factoryName}
        name="general"
        layout="vertical"
        initialValues={{
          ...this.props.values,
          pickupDate:
            this.props.mode === "add" ? "" : this.props.values.pickupDate,
        }}
        ref={this.props.instance}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label={labels.PICKUP_STATUS} name="statusName">
              <Select
                placeholder={labels.SELECT_PICKUP_STATUS}
                onChange={(event) => {
                  this.setState({ selectedPickupStatus: event });
                }}
                defaultValue={this.state.selectedPickupStatus}
                // disabled={
                //   addDisableMode === true
                //     ? true
                //     : statusDisable == "Cancelled"
                //     ? editDisable
                //     : false || disableAll
                // }
                disabled={addDisableMode ? true : disableAll}
                name="statusName"
              >
                {console.log("statusDisable->", statusDisable)}

                {this.state.pickupStatus.data.length !== 0
                  ? this.state.pickupStatus.data.map((pickupStatus) => {
                      return pickupStatus.value !== "-1" ? (
                        <Option
                          disabled={
                            statusDisable == 3
                              ? (pickupStatus.value.includes("4") &&
                                  Cancelled_show.length > 0) ||
                                pickupStatus.value.includes("5")
                                ? false
                                : true
                              : pickupStatus.value.includes("5") ||
                                (pickupStatus.value.includes("4") &&
                                  Cancelled_show.length > 0)
                              ? false
                              : true
                          }
                          value={pickupStatus.value}
                          key={pickupStatus.value}
                        >
                          {pickupStatus.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.PICKUP_DATE}
              name="pickupDate"
              rules={[
                { required: true, message: labels.PLEASE_SELECT_PICKUP_DATE },
              ]}
            >
              <DatePicker
                placeholder={labels.SELECT_DATE}
                onChange={(date) => {
                  let temparr = this.state.HolidayList;

                  var selected_date = Moment(date).format("YYYY-MM-DD");
                  var HolidayArr = temparr.filter((a) => {
                    var holiday_date = Moment(a.holidayDate).format(
                      "YYYY-MM-DD"
                    );

                    return selected_date == holiday_date;
                  });

                  if (HolidayArr.length > 0) {
                    notification["error"]({
                      message: HolidayArr[0].remark,
                      description: labels.SELECTED_DATE_IS_HOLIDAY,
                    });

                    // if (this.state.selectedPickupStatus == "5") {
                    this.props.instance.current.setFieldsValue({
                      timeslotID: undefined,
                    });
                    // }
                    this.setState({ pickupSlotView: "" });
                  } else {
                    this.setState(
                      {
                        pickupdate: date,
                      },
                      () => {
                        this.calculateDeliveryDate();
                      }
                    );
                    this.props.instance.current.setFieldsValue({
                      timeslotID: undefined,
                    });
                  }
                }}
                disabled={
                  this.state.selectedPickupStatus == "5"
                    ? false
                    : this.state.PickupDateDisabled || editDisable || disableAll
                }
                disabledDate={(currentDate) =>
                  currentDate.isBefore(Moment(), "day") ? true : false
                }
                format={"DD-MM-YYYY"}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.PICKUP_TIMESLOT}
              name="timeslotID"
              rules={[
                { required: true, message: labels.PLEASE_SELECT_TIMESLOT },
              ]}
            >
              <Select
                onChange={this.handlePickupSlotChange}
                placeholder={labels.SELECT_TIMESLOT}
                value={this.state.selectedTimeSlot}
                disabled={
                  this.state.timeSlot.disabled ||
                  this.state.selectedPickupStatus == "5"
                    ? this.props.mode === "view"
                      ? true
                      : false
                    : editDisable || disableAll
                } // || editDisable
              >
                {this.state.pickupdate != undefined &&
                this.state.timeSlot.data.length !== 0
                  ? this.state.timeSlot.data.map((timeSlot) => {
                      return timeSlot.slotID !== "-1" ? (
                        this.state.pickupSlotView === timeSlot.weekDayName ? (
                          <Option
                            value={timeSlot.slotID}
                            // value={timeSlot.slotID + "-" + timeSlot.slotFrom}
                            key={timeSlot.slotID}
                            disabled={
                              this.state.pickupdate
                                ? this.state.pickupdate.format("YYYY-MM-DD")
                                  ? this.state.pickupdate.format(
                                      "YYYY-MM-DD"
                                    ) === Moment().format("YYYY-MM-DD")
                                    ? timeSlot.slotFrom < pickupcurrentTime
                                      ? true
                                      : false
                                    : false
                                  : null
                                : null
                            }
                          >
                            {timeSlot.slotFrom + "-" + timeSlot.slotTo}
                            {/* {timeSlot.value + timeSlot.text} */}
                          </Option>
                        ) : null
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={labels.CUSTOMER_NAME}
              name="customerName"
              rules={[
                { required: true, message: labels.PLEASE_SELECT_CUSTOMER },
              ]}
            >
              <Select
                onSearch={this.onSearch}
                placeholder={labels.SEARCH_CUSTOMER}
                showSearch
                allowClear
                disabled={editDisable || disableAll}
                onChange={(value) => {
                  this.setState(
                    {
                      customerName: {
                        ...this.state.customerName,
                        selected: value,
                      },
                    },
                    () =>
                      ![null, undefined, ""].includes(value)
                        ? this.getAddressesByCustomerID(value)
                        : value
                  );
                }}
                filterOption={false}
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                //   0
                // }
              >
                {this.state.customerName.data.length !== 0
                  ? this.state.customerName.data.map((singleCustomerName) => {
                      return singleCustomerName.customerID !== "-1" ? (
                        <Option
                          value={singleCustomerName.customerID}
                          key={singleCustomerName.customerID}
                        >
                          {singleCustomerName.customerName +
                            " (" +
                            singleCustomerName.mobileNo +
                            ")"}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={labels.ADDRESS}
              name="address"
              rules={[{ required: true, message: labels.PLEASE_ENTER_ADDRESS }]}
            >
              <Select
                onChange={(value) =>
                  this.setState(
                    {
                      address: { ...this.state.address, selected: value },
                    },
                    () =>
                      [null, undefined, "", NaN].includes(value)
                        ? null
                        : this.getStoreBycustomerAddressID(value)
                  )
                }
                placeholder={labels.SELECT_ADDRESS}
                dependencies={["customerName"]}
                shouldUpdate={(prev, next) => prev.state !== next.state}
                disabled={
                  this.state.address.disabled || editDisable || disableAll
                } //|| editDisable
                allowClear
              >
                {this.state.address.data.length !== 0
                  ? this.state.address.data.map((address) => {
                      return address.value !== "-1" ? (
                        <Option
                          value={address.customerAddressID}
                          key={address.customerAddressID}
                        >
                          {address.fullAddress}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={labels.SERVICES}
              name="serviceIDs"
              rules={[
                {
                  required: !(
                    [8, 1, 2, 3].includes(statusDisable) &&
                    sourceFromDelivery === 3
                  ),
                  message: labels.PLEASE_SELECT_SERVICES,
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder={labels.SELECT_SERVICES}
                onChange={(event) => {
                  this.setState({ servicesSelection: event }, () =>
                    this.calculateDeliveryDate()
                  );
                }}
                allowClear
                disabled={
                  this.props.mode === "edit"
                    ? this.state.selectedPickupStatus == "5" ||
                      this.state.selectedPickupStatus == "4" ||
                      statusDisable == 8 ||
                      statusDisable == 3
                      ? editDisable
                      : false || disableAll
                    : disableAll
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.services.data.length !== 0
                  ? this.state.services.data.map((service) => {
                      return service.value !== "-1" ? (
                        <Option value={service.value} key={service.value}>
                          {service.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} ml={12} xl={8}>
            <Form.Item
              label={labels.DELIVERY_TYPE}
              name="deliveryTypeID"
              rules={[
                { required: true, message: labels.PLEASE_SELECT_DELIVERY_TYPE },
              ]}
            >
              <Select
                onChange={(value) => {
                  this.setState({ daysDeliveryType: value }, () =>
                    this.calculateDeliveryDate()
                  );
                }}
                placeholder={labels.SELECT_DELIVERY_TYPE}
                disabled={editDisable || disableAll}
              >
                {/* {this.state.deliveryTypes.data.length !== 0 */}
                {/* ?  */}
                {this.state.deliveryTypes.map((deliveryType) => {
                  return deliveryType.value !== "-1" ? (
                    <Option value={deliveryType.value} key={deliveryType.value}>
                      {deliveryType.text}
                    </Option>
                  ) : null;
                })}
                {/* : null}                  */}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              label={labels.NO_OF_GARMENTS}
              name="noOfGarments"
              rules={[
                // { required: true, message: "Please Enter No Of Garments" },
                {
                  pattern: RegexInteger,
                  message: labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                },
              ]}
            >
              <Input
                disabled={
                  this.props.mode === "edit"
                    ? this.state.selectedPickupStatus == "5" ||
                      this.state.selectedPickupStatus == "4" ||
                      statusDisable == "8" ||
                      statusDisable == 3
                      ? editDisable
                      : false || disableAll
                    : disableAll
                }
                placeholder={labels.ENTER_NO_OF_GARMENTS}
              />
            </Form.Item>
          </Col>

          <Col xs={8}>
            <Form.Item
              label={labels.PROMOCODE}
              name="promocode"
              validateStatus={
                this.props.isValidCode === true ? "success" : undefined
              }
              rules={[
                {
                  validator: ({}, value) => {
                    if (value !== null && value !== "" && value !== undefined) {
                      if (this.props.isValidCode === true) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("");
                        this.setState({ promocodeVal: "" });
                      }
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input
                disabled={
                  this.props.mode === "edit"
                    ? this.state.selectedPickupStatus == "5" ||
                      this.state.selectedPickupStatus == "4" ||
                      statusDisable == "8" ||
                      statusDisable == 3
                      ? editDisable
                      : false || disableAll
                    : disableAll
                }
                onBlur={(event) => {
                  this.onChangeCode(event, () => {
                    return ![null, undefined, ""].includes(
                      this.state.promocodeVal
                    )
                      ? this.validatePromocode()
                      : null;
                  });
                }}
                onChange={this.onChangeCode}
                placeholder={labels.ENTER_PROMOCODE}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.DELIVERY_DATE}
              name="deliveryDate"
              rules={[
                {
                  // required: true,
                  required: !(
                    [8, 1, 2, 3].includes(statusDisable) &&
                    sourceFromDelivery === 3
                  ),
                  message: labels.PLEASE_SELECT_DELIVERY_DATE,
                },
              ]}
            >
              <DatePicker
                placeholder={labels.SELECT_DATE}
                onChange={(value) => {
                  let temparr = this.state.HolidayList;

                  var selected_date = Moment(value).format("YYYY-MM-DD");
                  var HolidayArr = temparr.filter((a) => {
                    var holiday_date = Moment(a.holidayDate).format(
                      "YYYY-MM-DD"
                    );
                    // console.log("val->", selected_date, holiday_date);
                    return selected_date == holiday_date;
                  });
                  if (HolidayArr.length > 0) {
                    notification["error"]({
                      message: HolidayArr[0].remark,
                      description: labels.SELECTED_DATE_IS_HOLIDAY,
                    });
                    // if (this.state.selectedPickupStatus == "5") {
                    this.props.instance.current.setFieldsValue({
                      deliverySlot: undefined,
                    });
                    // }
                    this.setState({ deliverySlotView: "" });
                  } else {
                    this.props.instance.current.setFieldsValue({
                      deliverySlot: undefined,
                    });
                    this.props.mode === "edit"
                      ? this.getTimeSlot(this.props.storeID)
                      : this.getTimeSlot(
                          this.state.storeNameByCustAddress.storeID
                        );
                    this.setState({
                      deliverySlotView: Moment(value).format("ddd"),
                      dDate: value,
                    });
                  }
                }}
                allowClear={false}
                format={"DD-MM-YYYY"}
                value={this.state.deliveryDate}
                
                disabled={
                  this.props.mode === "edit"
                    ? (["1"].includes(this.state.selectedPickupStatus) &&
                        [8].includes(statusDisable)) ||
                      ([3].includes(statusDisable) &&
                        ["4"].includes(this.state.selectedPickupStatus)) ||
                       (statusDisable == 3 && this.state.selectedPickupStatus != 5)||
                       this.state.selectedPickupStatus == 4
                      ? true
                      : false
                    : false

                  // ["1", "2", "5"].includes(this.state.selectedPickupStatus) &&
                  // [2].includes(statusDisable)
                  //   ? //   ||
                  //     // (["1", "2", "5"].includes(this.state.selectedPickupStatus) &&
                  //     //   [2, 8].includes(statusDisable))
                  //     false
                  //   : ["2", "5"].includes(this.state.selectedPickupStatus) &&
                  //     [2, 8].includes(statusDisable)
                  //   ? false
                  //   : editDisable ||disableAll
                }

                disabledDate={(currentDate) => {
                  return currentDate &&
                    currentDate < Moment(this.state.deliveryDate, "YYYY-MM-DD")
                    ? true
                    : false;
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.DELIVERY_TIMESLOT}
              name="deliverySlot"
              rules={[
                {
                  required: !(
                    [8, 1, 2, 3].includes(statusDisable) &&
                    sourceFromDelivery === 3
                  ),
                  message: labels.PLEASE_SELECT_TIMESLOT,
                },
              ]}
            >
              <Select
                onChange={this.handleDeliverySlotChange}
                placeholder={labels.SELECT_TIMESLOT}
                disabled={
                  this.props.mode == "add" &&
                  [null, undefined, ""].includes(
                    this.state.selectedPickupSlotValue
                  )
                    ? true
                    : this.props.mode === "edit" &&
                      this.state.selectedTimeSlot !== ""
                    ? (["1"].includes(this.state.selectedPickupStatus) &&
                        [8].includes(statusDisable)) ||
                      ([3].includes(statusDisable) &&
                        ["4,"].includes(this.state.selectedPickupStatus)) ||
                      (statusDisable == 3 && this.state.selectedPickupStatus != 5)||
                      this.state.selectedPickupStatus == 4
                      ? editDisable
                      : disableAll
                    : disableAll

                  // ["4"].includes(this.state.selectedPickupStatus)
                  //   ? editDisable
                  //   : disableAll
                  // ["1", "2"].includes(this.state.selectedPickupStatus) &&
                  // this.state.isDeliverySlotViewEnable
                  //   ? editDisable
                  //   : this.state.timeSlot.disabled ||
                  //     this.state.selectedPickupStatus === "5"
                  //   ? false
                  //   : this.state.selectedPickupStatus === "4"
                  //   ? editDisable
                  //   : disableAll
                }
              >
                {this.state.timeSlot.data.length !== 0
                  ? this.state.timeSlot.data.map((timeSlot) => {
                      return timeSlot.slotID !== "-1" ? (
                        this.state.deliverySlotView === timeSlot.weekDayName ? (
                          <Option
                            value={timeSlot.slotID}
                            key={timeSlot.slotID}
                            disabled={
                              this.state.daysDeliveryType === "3"
                                ? this.state.deliveryDate
                                  ? timeSlot.slotFrom < currentTime &&
                                    new Date(
                                      (this.state.dDate === ""
                                        ? this.state.deliveryDate
                                        : this.state.dDate
                                      ).format("MM/DD/YYYY")
                                    ) <= followingDay
                                    ? true
                                    : false
                                  : null
                                : null
                            }
                          >
                            {timeSlot.slotFrom + "-" + timeSlot.slotTo}
                          </Option>
                        ) : null
                      ) : // (
                      //   notification["info"]({
                      //     message: "There is no slots available",
                      //   })
                      // )
                      null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.CANCELLATION_REASON}
              name="reason"
              rules={[
                {
                  required:
                    this.state.selectedPickupStatus == "4" ? true : false,
                  message:
                    this.state.selectedPickupStatus == "4"
                      ? "Please Select Cancellation Reason"
                      : null,
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder={labels.SELECT_REASON}
                onChange={this.handleCancelResonChange}
                disabled={
                  addDisableMode
                    ? this.state.addDisable
                    : this.state.selectedPickupStatus == "4"
                    ? false
                    : editDisable || disableAll
                }
              >
                {this.state.reason.data.length !== 0
                  ? this.state.reason.data.map((reason) => {
                      return reason.value !== "-1" ? (
                        <Option value={reason.text} key={reason.value}>
                          {reason.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item
              label={labels.OPERATION_INSTRUCTION}
              name="opInstructID"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please Select Operation Instruction",
              //   },
              // ]}
            >
              <Select
                placeholder={labels.SELECT_OPERATION_INSTRUCTION}
                onChange={() => null}
                mode="multiple"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={
                  this.props.mode === "edit"
                    ? this.state.selectedPickupStatus == "5" ||
                      this.state.selectedPickupStatus == "4" ||
                      statusDisable == 8 ||
                      statusDisable == 3
                      ? editDisable
                      : false || disableAll
                    : disableAll
                }
              >
                {this.state.opInstruct.data.length !== 0
                  ? this.state.opInstruct.data.map((opInstruct) => {
                      return opInstruct.value !== "-1" ? (
                        <Option value={opInstruct.value} key={opInstruct.value}>
                          {opInstruct.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          {this.state.selectedPickupStatus == "5" ? (
            <Col xs={24}>
              <Form.Item
                label={labels.REMARKS}
                name="remarks"
                rules={[
                  { required: true, message: labels.PLEASE_ENTER_REMARKS },
                ]}
              >
                <Input.TextArea
                  defaultValue={" "}
                  // disabled={
                  //   addDisableMode
                  //     ? this.state.addDisable
                  //     : this.state.selectedPickupStatus == "5"
                  //     ? false
                  //     : editDisable || this.state.selectedPickupStatus == "4"
                  //     ? this.state.selectedCancelReason == "Others"
                  //       ? false
                  //       : editDisable
                  //     : editDisable || disableAll
                  // }
                  placeholder={labels.ENTER_REMARKS}
                />
              </Form.Item>
            </Col>
          ) : null}
          {this.state.selectedPickupStatus == "4" ? (
            this.state.selectedCancelReason == "Others" ? (
              <Col xs={24}>
                <Form.Item
                  label={labels.REMARKS}
                  name="cancelRemarks"
                  rules={[
                    { required: true, message: labels.PLEASE_ENTER_REMARKS },
                  ]}
                >
                  <Input.TextArea
                    defaultValue={" "}
                    // disabled={
                    //   addDisableMode
                    //     ? this.state.addDisable
                    //     : this.state.selectedPickupStatus == "5"
                    //     ? editDisable
                    //     : // : editDisable || this.state.selectedPickupStatus == "4"
                    //     this.state.selectedCancelReason == "Others"
                    //     ? false
                    //     : editDisable || disableAll
                    //   // : editDisable || disableAll
                    // }
                    placeholder={labels.ENTER_REMARKS}
                  />
                </Form.Item>
              </Col>
            ) : null
          ) : null}
          {/* {console.log("reason->", this.state.selectedCancelReason)} */}
          {this.props.mode == "view" ||
          (this.state.selectedCancelReason.includes("Duplicate Pickup") &&
            this.props.mode == "edit") ? (
            <Col xs={24}>
              <Form.Item
                label={labels.ORIGINAL_PICKUPID}
                name="originalpickupID"
                rules={[
                  {
                    required: true,
                    message: labels.PLEASE_ENTER_ORIGINAL_PICKUP,
                  },
                ]}
              >
                <Input.TextArea
                  defaultValue={" "}
                  placeholder={"Enter Original Pickup"}
                  disabled={addDisableMode ? true : disableAll}
                />
              </Form.Item>
            </Col>
          ) : null}

          <Col xs={24} style={{ textAlign: "right" }}>
            {!disableAll ? (
              <Space>
                <div className="w-100"></div>
                <Button
                  variant="transparentGreyOutline"
                  onClick={this.props.backFunction}
                  shape="round"
                  size="large"
                >
                  {labels.CANCEL}
                </Button>
                {console.log("statusDisable->", statusDisable)}
                {(["1", "4", "5"].includes(this.state.selectedPickupStatus) &&
                  [0, 1, 2].includes(statusDisable)) ||
                ([8].includes(statusDisable) &&
                  ["4", "5", "2"].includes(this.state.selectedPickupStatus)) ||
                ([3].includes(statusDisable) &&
                  ["4", "5"].includes(this.state.selectedPickupStatus)) ? (
                  <Button
                    onClick={this.props.nextFunction}
                    variant="purple"
                    shape="round"
                    size="large"
                    loading={submitbtnloading}
                    disabled={
                      statusDisable === 4 ? editDisable : false
                      //   this.state.promocodeVal!=""
                      // ? this.state.promocodeResponse.responseMessage!=="Invalid"
                      //   ? false:editDisable
                      // :false
                    }
                  >
                    {labels.SUBMIT}
                  </Button>
                ) : null}
              </Space>
            ) : (
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                {labels.CANCEL}
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    );
  }
}
