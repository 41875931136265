import React from 'react'
import StoreInPending from "../../../components/StoreModule/StorInPending/storeinpending.store.component"
export default class StoreInPendingscreen extends React.Component {
    render() {
        return (
            <div>
                <StoreInPending></StoreInPending>
            </div>
        )
    }
}
