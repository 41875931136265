import React from "react";
import PreQCResponseList from "../../../components/StoreModule/PreQCResponseList/preqcresponselist.store.component";

export default class PreQCResponseListScreen extends React.Component {
  render() {
    return (
      <div>
        <PreQCResponseList />
      </div>
    );
  }
}
