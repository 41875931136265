import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  // TimePicker,
  Input,
  // DatePicker,
  Space,
  // notification,
  // Radio,
  Modal,
  Table,
  Descriptions,
  Upload,
  notification,
} from "antd";
import Button from "../../../Common/Button/button";
import PreQCResponseList from "../../../../services/storepreqcresponselistservice";
import Common from "../../../../services/common";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import { labels, lables } from "../../../../shared/language";
import { LabelList } from "recharts";
const { Option } = Select;
/**
 * General form as a part of create factory screen
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 */
class GeneralForm extends React.Component {
  /**
   * Initalizing Factory Service
   */
  preQcService = new PreQCResponseList();
  commonService = new Common();

  /**
   * Storing objects for dynamic controls
   *
   * @added 18 June 2020
   */
  state = {
    tagDetails: {},
    lastQCDetails: {},
    showAddPopup: false,
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url:
          "https://images.unsplash.com/photo-1549490349-8643362247b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80",
      },
      {
        uid: "-2",
        name: "image.png",
        status: "done",
        url:
          "https://images.unsplash.com/photo-1549490349-8643362247b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80",
      },
    ],
    qcPendingFrom: 2,
    reasonDDL: [],
    statusDDL: [],
  };
  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleChange = ({ fileList }) => this.setState({ fileList });

  /**
   * API Calls on component mounting to populate dropdowns
   * @added 18 June 2020
   */
  componentDidMount() {
    this.getTagDetail();
    // this.getResponseList();
    this.GetQCStatus();
    this.GetQCReason();
    this.getLastQCDetails();
  }
  GetQCStatus = () => {
    this.commonService.GetSystemEnums("FinalQCStatus").then((res) => {
      this.setState({ statusDDL: res.data });
    });
  };
  GetQCReason = () => {
    this.commonService.GetSystemEnums("PreQCReason").then((res) => {
      this.setState({ reasonDDL: res.data });
    });
  };
  componentDidUpdate() {
    if (![null, undefined, ""].includes(this.props.instance)) {
      this.props.instance.current.setFieldsValue({
        tagNumber: this.props.match.params.qcId,
      });
    }
    // if (this.state.lastQCDetails !== null) {
    //   if (
    //     this.state.qcPendingFrom !== null &&
    //     Object.keys(this.state.lastQCDetails).length === 0
    //   ) {
    //     this.getLastQCDetails();
    //   }
    // }
  }
  getLastQCDetails = () => {
    this.preQcService
      .getLastQC(this.props.match.params.qcId, this.state.qcPendingFrom)
      .then((response) => {
        if (response.statusCode === 200) {
          if (response.data.length > 0) {
            this.setState({ lastQCDetails: response.data[0] });
            if (![null, undefined, ""].includes(this.props.instance)) {
              this.props.instance.current.setFieldsValue({
                orderGarmentID: response.data[0].orderGarmentID,
                // qcStatus: response.data[0].qcStatus,
                qcReason: [null, undefined, ""].includes(
                  response.data[0].qcReason
                )
                  ? undefined
                  : response.data[0].qcReason.split(","),
                // qcRemarks: response.data[0].remark,
              });
            }
          } else {
            this.setState({ lastQCDetails: null });
          }
        } else {
          this.setState({ lastQCDetails: null });
        }
      });
  };
  getResponseList = () => {
    this.preQcService
      .getResponseList({
        storeID: parseInt(localStorage.getItem("branchID")),
        factoryID: 0,
        qcStatus: 0,
        tagNo: this.props.match.params.qcId,
      })
      .then((response) => {
        if (response.statusCode === 200) {
          if (response.data.length > 0) {
            // this.setState({ qcPendingFrom: response.data[0].qcPendingFrom });
            this.setState({ qcPendingFrom: 1 });
          }
        }
      });
  };

  getTagDetail = () => {
    this.preQcService
      .getGarmentDetailByTagNo(this.props.match.params.qcId)
      .then((response) => {
        if (response.statusCode === 200) {
          if (response.data[0] !== undefined) {
            this.setState({ tagDetails: response.data[0] });
          } else {
            notification["error"]({
              message: labels.PRE_QC_RESPONSES,
              description: labels.UNABLE_TO_GET_GARMENT_DETAILS,
            });
          }
        } else {
          notification["error"]({
            message: labels.PRE_QC_RESPONSES,
            description: labels.UNABLE_TO_GET_GARMENT_DETAILS,
          });
        }
      });
  };

  GetQCHistoryByTagNo = () => {
    this.setState({ showAddPopup: true });
    this.preQcService
      .GetQCHistoryByTagNo(this.props.match.params.qcId)
      .then((items) => {
        let HistoryData = [];
        items.data.map((singleData) => {
          singleData.key = singleData.orderGarmentID;
          singleData.qcPendingFrom = ["Approved", "Rejected"].includes(
            singleData.qcStatus
          )
            ? ""
            : singleData.qcPendingFrom;

          HistoryData.push(singleData);
          return null;
        });
        this.setState({
          garMentHistoryData: HistoryData,
        });
      });
  };
  columns = [
    {
      title: labels.TIME,
      dataIndex: "createdOn",
      key: "createdOn",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm a");
      },
    },
    {
      title: labels.QC_REASON,
      dataIndex: "qcReason",
      key: "qcReason",
      // sorter: true,
    },
    {
      title: labels.REMARK,
      dataIndex: "remark",
      key: "remark",
      // sorter: true,
    },
    {
      title: labels.QC_PENDING_FROM,
      dataIndex: "qcPendingFrom",
      key: "qcPendingFrom",
      //sorter: true,
    },
    {
      title: labels.QC_STATUS,
      dataIndex: "qcStatus",
      key: "qcStatus",
      // sorter: true,
    },
  ];
  render() {
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const disableAll = this.props.mode === "view";
    return (
      <Form
        key={this.props.values.qcTagNo}
        name="general"
        layout="vertical"
        initialValues={{
          ...this.props.values,
          tagNumber: this.props.match.params.qcId,
        }}
        ref={this.props.instance}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={16}>
            <Form.Item
              label={labels.TAG_NUMBER}
              name="orderGarmentID"
              rules={[
                { required: true, message: "Please scan/add tag number" },
              ]}
              hidden
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={labels.TAG_NUMBER}
              name="tagNumber"
              rules={[
                { required: true, message: "Please scan/add tag number" },
              ]}
            >
              <div className="w-100" style={{ display: "flex" }}>
                <Input
                  placeholder={labels.ENTER_SCAN_TAG_NUMBER}
                  value={this.props.match.params.qcId}
                  disabled
                />
                <Button
                  variant="transparentGreyOutline"
                  style={{ marginLeft: 10 }}
                  onClick={this.GetQCHistoryByTagNo}
                >
                  {labels.GARMENT_HISTORY}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        {this.state.tagDetails !== {} ? (
          <Descriptions
            title={labels.GARMENT_INFORMATION}
            bordered
            size="small"
            column={2}
          >
            <Descriptions.Item label={labels.CUSTOMER_NAME} span={2}>
              {this.state.tagDetails.customerName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.CUSTOMER_TYPE}>
              {this.state.tagDetails.customerType}
            </Descriptions.Item>
            <Descriptions.Item label={labels.CONTACT}>
              {this.state.tagDetails.customerContactNo}
            </Descriptions.Item>
            <Descriptions.Item label={labels.ORDER_DATE}>
              {moment(this.state.tagDetails.orderDate).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label={labels.SPLIT_ORDER}>
              {this.state.tagDetails.splitOrderNumber}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GATE_PASS}>
              {this.state.tagDetails.gatePassNo}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GATE_PASS_DATE}>
              {moment(this.state.tagDetails.gatePassDate).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GARMENT}>
              {this.state.tagDetails.garmentName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GARMENT_STATUS}>
              {this.state.tagDetails.garmentStatusName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.DELIVERY_DATE}>
              {moment(this.state.tagDetails.deliveryDate).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label={labels.BRAND}>
              {this.state.tagDetails.garmentBrand}
            </Descriptions.Item>
            <Descriptions.Item label={labels.COLOR}>
              {this.state.tagDetails.colorNames}
            </Descriptions.Item>
            <Descriptions.Item label={labels.SERVICE}>
              {this.state.tagDetails.serviceName}
            </Descriptions.Item>
            {/* <Descriptions.Item label="UOM">
              {this.state.tagDetails.uom}
            </Descriptions.Item> */}
            <Descriptions.Item label={labels.ADD_ON_SERVICE}>
              {this.state.tagDetails.additionalServiceName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.DELIVERY_TYPE}>
              {this.state.tagDetails.deliveryType}
            </Descriptions.Item>
            <Descriptions.Item label={labels.SPECIAL_INSTRUCTION}>
              {this.state.tagDetails.specialInstruction}
            </Descriptions.Item>
          </Descriptions>
        ) : null}
        {this.state.lastQCDetails !== null &&
        this.state.lastQCDetails !== {} ? (
          <Descriptions
            title={labels.LATEST_QC_DETAILS}
            bordered
            size="small"
            column={2}
          >
            <Descriptions.Item label={labels.QC_STATUS}>
              {this.state.lastQCDetails.qcStatusName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.QC_PENDING_FROM}>
              {this.state.lastQCDetails.qcPendingFromName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.REMARKS} span={2}>
              {this.state.lastQCDetails.remark}
            </Descriptions.Item>
            <Descriptions.Item label={labels.IMAGES} span={2}>
              {this.state.lastQCDetails.qcImage1 !== "" &&
              this.state.lastQCDetails.qcImage1 !== null ? (
                <a href={this.state.lastQCDetails.qcImage1} target="_blank">
                  Image 1
                </a>
              ) : null}
              <br />
              {this.state.lastQCDetails.qcImage2 !== "" &&
              this.state.lastQCDetails.qcImage2 !== null ? (
                <a href={this.state.lastQCDetails.qcImage2} target="_blank">
                  Image 2
                </a>
              ) : null}
              <br />
              {this.state.lastQCDetails.qcImage3 !== "" &&
              this.state.lastQCDetails.qcImage3 !== null ? (
                <a href={this.state.lastQCDetails.qcImage3} target="_blank">
                  Image 3
                </a>
              ) : null}
            </Descriptions.Item>
          </Descriptions>
        ) : null}
        <Row gutter={30}>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.STATUS}
              name="qcStatus"
              rules={[{ required: true, message: labels.PLEASE_SELECT_STATUS }]}
            >
              <Select placeholder={labels.SELECT_STATUS} disabled={disableAll}>
                {this.state.statusDDL.length !== 0
                  ? this.state.statusDDL.map((res) => {
                      return res.enumDetailID !== "-1" ? (
                        <Option
                          value={res.enumDetailID}
                          key={res.enumDetailID}
                          disabled={parseInt(res.enumDetailID) === 1}
                        >
                          {res.enumDetailName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={16}>
            <Form.Item
              label={labels.REASON}
              name="qcReason"
              rules={[{ required: true, message: labels.PLEASE_SELECT_REASON }]}
            >
              <Select
                placeholder={labels.SELECT_REASON}
                disabled={disableAll}
                mode="multiple"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.reasonDDL.length !== 0
                  ? this.state.reasonDDL.map((res) => {
                      return res.enumDetailID !== "-1" ? (
                        <Option
                          value={res.enumDetailID.toString()}
                          key={res.enumDetailID.toString()}
                        >
                          {res.enumDetailName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={24}>
            <Form.Item
              label={labels.REMARKS}
              name="qcRemarks"
              rules={[{ required: true, message: labels.PLEASE_ENTER_REMARKS }]}
            >
              <Input.TextArea
                placeholder={labels.ENTER_REMARKS}
                disabled={disableAll}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={24}>
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={this.state.fileList}
              // onPreview={this.handlePreview}

              onChange={this.handleChange}
            >
              {this.state.fileList.length >= 8 ? null : uploadButton}
            </Upload>
          </Col> */}

          <Col xs={24} style={{ textAlign: "right" }}>
            <Space>
              <div className="w-100"></div>
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                {labels.CANCEL}
              </Button>
              {!disableAll ? (
                <Button
                  // htmlType="submit"
                  variant="purple"
                  shape="round"
                  size="large"
                  onClick={this.props.nextFunction}
                >
                  {labels.SUBMIT}
                </Button>
              ) : null}
            </Space>
          </Col>
        </Row>
        <Modal
          title={labels.COMMUNICATION_HISTORY}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={() => this.setState({ showAddPopup: false })}
          className="modal-form"
          destroyOnClose
        >
          <Table
            size="small"
            columns={this.columns}
            dataSource={this.state.garMentHistoryData}
            loading={this.state.tableLoading}
            pagination={false}
          />
        </Modal>
      </Form>
    );
  }
}

export default withRouter(GeneralForm);
