import React from "react";
import { SketchPicker } from "react-color";

class SketchExample extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: "",
      g: "",
      b: "",
      a: "",
    },
  };
  componentDidMount() {
    if (this.props.color !== null) {
      this.setState({ color: this.props.color });
    }
  }
  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
    if (this.props.onChange !== undefined) {
      this.props.onChange(color);
    }
  };

  render() {
    return (
      <div
        style={{
          display: this.props.inline === true ? "inline-flex" : "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: "3px",
            background: "#fff",
            borderRadius: "100%",
            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            display: "inline-block",
            cursor: this.props.disabled ? "not-allowed" : "pointer",
          }}
          onClick={this.handleClick}
        >
          <div
            style={{
              width: "22px",
              height: "22px",
              borderRadius: "100%",
              background: this.state.color,
            }}
          />
        </div>
        {this.state.displayColorPicker ? (
          <div
            style={{
              position: "absolute",
              zIndex: "2",
            }}
          >
            <div
              style={{
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
              }}
              onClick={this.handleClose}
            />
            {this.props.disabled ? null : (
              <SketchPicker
                color={this.state.color}
                onChange={this.handleChange}
              />
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default SketchExample;
