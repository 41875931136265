import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class CorporateService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getBillingCycle() {
    const billingCycleAPI = new API({ url: this.config.DROPDOWN_URL });
    billingCycleAPI.createEntity({ name: this.endpoint.GET_BILLING_CYCLES });
    let returnVal = [];
    await billingCycleAPI.endpoints[this.endpoint.GET_BILLING_CYCLES]
      .getAll()
      .then((response) => (returnVal = response.data))
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getBillingCycle();
      });
    }
    return Promise.resolve(returnVal);
  }

  async addUpdateCorporate(postData) {
    const addUpdateAPI = new API({ url: this.config.ITEM_URL });
    addUpdateAPI.createEntity({ name: this.endpoint.ADD_UPDATE_CORPORATE });
    let returnVal = [];
    await addUpdateAPI.endpoints[this.endpoint.ADD_UPDATE_CORPORATE]
      .create(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.addUpdateCorporate(postData);
      });
    }
    return Promise.resolve(returnVal);
  }

  async getAllCorporates(
    index = 0,
    size = 0,
    column = null,
    order = null,
    search = null
  ) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.GET_ALL_CORPORATES });
    let factories = [];
    let payload = {
      corporateName: null,
      billingCycleID: 0,
      pageIndex: index,
      pageSize: size,
      sortColumn: column,
      sortOrder: order,
      commonSearch: search,
    };
    await allFactoriesAPI.endpoints[this.endpoint.GET_ALL_CORPORATES]
      .getAllPost(payload)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getAllCorporates(index, size, column, order, search);
      });
    }
    return Promise.resolve(factories);
  }

  async getCorporate(id) {
    const getFactoryAPI = new API({ url: this.config.ITEM_URL });
    getFactoryAPI.createEntity({ name: this.endpoint.GET_CORPORATE_FROM_ID });
    let factory = [];
    let idParams = {
      params: {
        CorporateID: id,
      },
    };
    await getFactoryAPI.endpoints[this.endpoint.GET_CORPORATE_FROM_ID]
      .getAll(idParams)
      .then((response) => {
        factory = response.data;
      })
      .catch((err) => {
        factory = this.common.handleError(err);
      });
    if (factory.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factory = this.getCorporate(id);
      });
    }
    return Promise.resolve(factory);
  }

  async deleteCorporate(id) {
    const getAddressAPI = new API({ url: this.config.ITEM_URL });
    getAddressAPI.createEntity({ name: this.endpoint.DELETE_CORPORATE });
    let addresses = [];
    const idParam = "?CorporateIDs=" + id;
    await getAddressAPI.endpoints[this.endpoint.DELETE_CORPORATE]
      .deleteById(idParam)
      .then((response) => (addresses = response.data))
      .catch((err) => {
        addresses = this.common.handleError(err);
      });
    if (addresses.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        addresses = this.deleteCorporate(id);
      });
    }
    return Promise.resolve(addresses);
  }
}
