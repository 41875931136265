import React from "react";
// import styles from "../../Common/Styles/master.module.css";
import styles from "./resortingandpackaging.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import Common from "../../../services/common";
import { RegexInteger, MessageNumbers } from "../../../shared/validator";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Collapse,
  Table,
  AutoComplete,
  Tooltip,
  Popconfirm,
  Divider,
  notification,
  Modal,
  Empty,
  InputNumber,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResortingAndPackagingServices from "../../../services/resortingandpackagingservice";
import FactoryService from "../../../services/factoryservice";
import Item from "antd/lib/list/Item";
import GeneratePrintInfo from "./printstikerprint";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { from } from "rxjs";
import { labels } from "../../../shared/language";
import Moment from "moment";
import { BarcodeOutlined } from "@ant-design/icons";
var jwt = require("jsonwebtoken");

const { Panel } = Collapse;
const { Option } = Select;

const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};
let formReference = React.createRef();
export default class ResortingAndPackagingInfo extends React.Component {
  resortingAndPackaging = new ResortingAndPackagingServices();
  UserRightsConfiguration = new UserRightsConfiguration();
  commonReq = new Common();
  state = {
    factoryData: {
      status: 1,
    },
    fullLoading: false,
    listItemTableLoading: false,
    generateBtnLoading: false,
    resortingAndPackagingServices: null,
    rowselectedvalue: 0,
    selectedRowKeys: [],
    getNoOfGarmentPackingRes: [],
    listItemsRes: [],
    postData: [],
    searchGarmentNo: "",
    finalNoOfgarmentPackings: [],
    packedGarments: [],
    showAddPopup: false,
    printInfo: [],
    stickerID: 0,
    isStikerGenerated: true,
    iscallGraments: false,
    lastPackedDate: "",
  };
  formRef = React.createRef();
  columns = [
    {
      title: labels.SR_NO,
      dataIndex: "garmentID",
      key: "garmentID",
    },
    {
      title: labels.GARMENT_NAME,
      dataIndex: "garmentName",
      key: "garmentName",
    },
    {
      title: labels.NO_OF_GARMENTS,
      dataIndex: "totalGarments",
      key: "totalGarments",
    },
    {
      title: labels.GARMENTS_PACKED,
      dataIndex: "packedGarments",
      key: "packedGarments",
    },
    {
      title: labels.PENDING_GARMENTS,
      dataIndex: "notPackedGarments",
      key: "notPackedGarments",
    },
    {
      title: labels.PACKING_GARMENTS,
      key: "packingGarments",
      render: (text, record, index) => (
        <Space size="middle">
          {record.notPackedGarments > 0 ? (
            <Form.Item
              name={index}
              rules={[
                {
                  min: 1,
                  max: parseInt(record.notPackedGarments),
                  type: "integer",
                  message: " ",
                },
              ]}
              initialValue={record.notPackedGarments}
            >
              <InputNumber
                style={{ width: 60, height: 30 }}
                min={1}
                onChange={(e) => {
                  let updated = [...this.state.listItemsRes];
                  let oldvalue = parseInt(updated[index].notPackedGarments);
                  let newValue = ![null, undefined, ""].includes(e)
                    ? parseInt(e)
                    : 0;
                  if (oldvalue >= newValue && newValue != 0) {
                    updated[index].packingGarments = ![
                      null,
                      undefined,
                      "",
                    ].includes(e)
                      ? parseInt(e)
                      : 0;
                  } else if (newValue == 0) {
                    updated[index].packingGarments = "";
                  } else {
                    updated[index].packingGarments = newValue;
                    if (![null, undefined, ""].includes(e))
                      openNotificationWithIcon(
                        "info",
                        labels.RESORTING_AND_PACKAGING,
                        labels.ENTERED_NUMBER_SHOULD_BE_SMALLER_THEN_NO_OF_PENDING_GARMENTS
                      );
                  }
                  this.setState({ listItemsRe: updated });
                  if (this.state.postData.stickerWiseGarments != undefined) {
                    var isEnable = false;
                    this.state.postData.stickerWiseGarments.forEach(
                      (element, index) => {
                        updated.forEach((element1) => {
                          if (element1.key == element.garmentID) {
                            let newArray = { ...this.state.postData };
                            newArray.stickerWiseGarments[index].qty =
                              element1.packingGarments;
                            this.setState({ postData: newArray });

                            if (
                              [null, undefined, ""].includes(
                                element1.packingGarments
                              ) ||
                              element1.notPackedGarments <
                                element1.packingGarments
                            ) {
                              this.setState({ isStikerGenerated: true });
                              isEnable = true;
                            } else {
                              if (!isEnable) {
                                this.setState({ isStikerGenerated: false });
                              }
                            }
                          }
                        });
                      }
                    );
                  }
                }}
              />
            </Form.Item>
          ) : null}
        </Space>
      ),
    },
  ];

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    this.setState({ isStikerGenerated: true, iscallGraments: false });
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.RESORT_PACK
    );

    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
    this.GetPackingDetailsBySplitOrder();
    this.getNoOfGarmentPackingList();
  }

  GetPackingDetailsBySplitOrder = () => {
    if (this.state.searchGarmentNo == "") {
      return false;
    }
    this.setState({ listItemTableLoading: true });
    this.resortingAndPackaging
      .GetPackingDetailsBySplitOrder(this.state.searchGarmentNo)
      .then((res) => {
        console.log("res->", res);
        if (res.data != undefined) {
          if (res.data.orderType == 2 && res.data.rewashReason != undefined) {
            notification["info"]({
              message: "Rewash Order – Pls check carefully",
              description: res.data.rewashReason,
            });
          }
        }
        const listItems =
          res != undefined && res.result == "Success"
            ? res.data.vMPackingGarmentDetails.map((item, index) => ({
                key: item.garmentID,
                garmentID: index + 1,
                garmentName: item.garmentName,
                packedGarments: item.packedGarments,
                totalGarments: item.totalGarments,
                notPackedGarments: item.notPackedGarments,
                packingGarments: item.notPackedGarments,
              }))
            : [];

        this.setState({
          listItemsRes: listItems,
          resortingAndPackagingServices: res.data,
          lastPackedDate:
            res.data.lastPackedDate !== "0001-01-01T00:00:00"
              ? Moment(res.data.lastPackedDate).format("Do MMMM, YYYY HH:mm")
              : "",
        });
        this.setState({ listItemTableLoading: false });
        if (listItems.length == 0) {
          notification["error"]({
            message: labels.RESORTING_AND_PACKAGING,
            description: labels.TAG_NUMBER_NOT_FOUND,
          });
        }
      });
  };

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length > 0) {
        let updated = [];
        let updated1 = [];
        let res = this.setState({ postData: { stickerWiseGarments: [] } });
        selectedRows.forEach((element) => {
          updated.push({
            garmentID: element.key,
            qty: element.packingGarments,
          });
          updated1.push({
            garmentID: element.key,
            qty: element.packingGarments,
            notPackedGarments: element.notPackedGarments,
          });
        });
        this.setState(
          {
            postData: {
              ...this.state.postData,
              stickerWiseGarments: updated,
            },
          },
          () => {
            if (this.state.postData.stickerWiseGarments != undefined) {
              this.setState({ isStikerGenerated: false });
            } else {
              this.setState({ isStikerGenerated: true });
            }

            if (this.state.postData.stickerWiseGarments != undefined) {
              var isEnable = false;
              updated1.forEach((element) => {
                if (element.qty == "") {
                  this.setState({ isStikerGenerated: true }, () => {
                    isEnable = true;
                  });
                } else {
                  if (!isEnable) {
                    this.setState({ isStikerGenerated: false });
                  }
                }
              });
            } else {
              this.setState({ isStikerGenerated: true });
            }
            selectedRows.forEach((element) => {
              if (element.notPackedGarments < element.packingGarments) {
                this.setState({ isStikerGenerated: true });
              }
              // else {
              //   this.setState({ isStikerGenerated: false });
              // }
            });
          }
        );
      } else {
        this.setState({
          isStikerGenerated: true,
          postData: { stickerWiseGarments: [] },
        });
      }

      this.setState({ selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.notPackedGarments == 0, // Column configuration not to be checked
      name: record.notPackedGarments,
    }),
  };

  handleAddpackingList = (e) => {
    var pmid = e.target.attributes.getNamedItem("data-enumid").value;
    var update = [...this.state.getNoOfGarmentPackingRes];
    var key = update.findIndex(
      (item) => item.packingGarmentId == parseInt(pmid)
    );
    update[key].packingQty =
      e.target.value != "" ? parseInt(e.target.value) : 0;
    this.setState(
      {
        getNoOfGarmentPackingRes: update,
      },
      () => {
        var updated = [];
        this.setState({ finalNoOfgarmentPackings: [] });
        let mapArray = [...this.state.getNoOfGarmentPackingRes];
        mapArray.forEach((element) => {
          if (element.packingQty !== 0) {
            updated.push({
              pmid: element.packingGarmentId,
              qty: element.packingQty,
            });
          }
        });
        this.setState({ finalNoOfgarmentPackings: updated });
      }
    );
  };

  getNoOfGarmentPackingList = () => {
    this.commonReq.GetSystemEnums("PackingMaterial").then((res) => {
      if (res.statusCode === 200) {
        let data = [];
        res.data.forEach((element) => {
          data.push({
            packingGarmentId: element.enumDetailID,
            packingGarmentName: element.enumDetailName,
            packingQty: 0,
          });
        });
        this.setState({
          getNoOfGarmentPackingRes: data,
        });
      } else {
        notification["error"]({
          message: labels.RESORTING_AND_PACKAGING,
          description: res.message ? this.commonReq.Message(res.message) : null,
        });
      }
    });
  };

  onChangeText = (e) => {
    this.setState({ searchGarmentNo: e.target.value });
  };

  fromInstance = React.createRef;

  componentDidMount() {
    // this.getFactoryData(parseInt(localStorage.getItem("branchID")));
  }
  factoryService = new FactoryService();
  // getFactoryData = (id) => {
  //   this.setState({ fullLoading: true });
  //   this.factoryService.getFactory(id).then((response) => {
  //     if (response.statusCode === 200) {

  //       this.setState({
  //         factoryData: {
  //           ...this.state.factoryData,
  //           status: response.data.status,
  //         },
  //         fullLoading: false,
  //       });
  //     }
  //   });
  // };
  render() {
    //const data = gRes

    const data = this.state.resortingAndPackagingServices;
    const garmentPackedIn =
      data != null ? (
        data.vMPackingMaterial.map((item, index) => {
          return (
            <div>
              <Row>
                <Col className="gutter-row" span={2}>
                  {item.packingMaterial}
                </Col>{" "}
                {item.count}
              </Row>
            </div>
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      );
    const getNoOfGarmentPackingList = this.state.getNoOfGarmentPackingRes.map(
      (item, index) => {
        return (
          <Row style={{ color: "black" }} className="w-100">
            <Col
              className="gutter-row"
              xs={1}
              style={{ marginRight: 50, marginBottom: 20, textAlign: "left" }}
            >
              {item.packingGarmentName}
            </Col>
            <Col xs={10}>
              <Form.Item
                name={index}
                rules={[
                  {
                    pattern: RegexInteger,
                    message:
                      labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                  },
                ]}
              >
                <Input
                  data-enumid={item.packingGarmentId}
                  style={{ width: 60, height: 30 }}
                  onChange={this.handleAddpackingList}
                />
              </Form.Item>
            </Col>
          </Row>
        );
      }
    );

    return (
      <Spin spinning={this.state.fullLoading}>
        <pre>
          {/* {JSON.stringify(this.state.finalNoOfgarmentPackings, null, 2)} */}
        </pre>
        <PageHeader title={labels.RESORTING_AND_PACKAGING} />
        <br />
        <div className={styles.CustomerSelectionWrapper}>
          <Row gutter={15} align="middle">
            <Col xs={24} lg={6}>
              <Space>
                <Input.Search
                  placeholder={labels.SPLIT_ORDER_NUMBER}
                  onChange={this.onChangeText}
                  onPressEnter={this.GetPackingDetailsBySplitOrder}
                  suffix={<BarcodeOutlined />}
                />
                <Button
                  // icon={<FontAwesomeIcon icon={faPlus} />}
                  className={styles.AddButton}
                  variant="whitePink"
                  valaue={this.state.searchGarmentNo || ""}
                  onClick={this.GetPackingDetailsBySplitOrder}
                  style={{ width: 80 }}
                >
                  {labels.SEARCH}
                </Button>
              </Space>
            </Col>

            <Col xs={24} lg={17}>
              <Row gutter={10} align="top">
                <Col xs={24} md={6}>
                  {this.state.resortingAndPackagingServices != null ? (
                    <React.Fragment>
                      <div className={styles.CustomerInfoLabel}>
                        {labels.CUSTOMER_NAME}
                      </div>
                      <div className={styles.CustomerInfoValue}>
                        {this.state.resortingAndPackagingServices.customerName}
                      </div>
                    </React.Fragment>
                  ) : null}
                </Col>
                <Col xs={24} md={6}>
                  {this.state.resortingAndPackagingServices != null ? (
                    <React.Fragment>
                      <div className={styles.CustomerInfoLabel}>
                        {labels.SRORE_NAME}
                      </div>
                      <div className={styles.CustomerInfoValue}>
                        {this.state.resortingAndPackagingServices.storeName}
                      </div>
                    </React.Fragment>
                  ) : null}
                </Col>
                <Col xs={24} md={6}>
                  {this.state.resortingAndPackagingServices != null ? (
                    <React.Fragment>
                      <div className={styles.CustomerInfoLabel}>
                        {" "}
                        {labels.NO_OF_GARMENTS}
                      </div>
                      <div className={styles.CustomerInfoValue}>
                        {this.state.resortingAndPackagingServices.noofGarment}
                      </div>
                    </React.Fragment>
                  ) : null}
                </Col>
                <Col xs={24} md={6}>
                  {this.state.resortingAndPackagingServices != null ? (
                    <React.Fragment>
                      <div className={styles.CustomerInfoLabel}>
                        {" "}
                        {labels.SPLIT_ORDER_NUMBER}
                      </div>
                      <div className={styles.CustomerInfoValue}>
                        {
                          this.state.resortingAndPackagingServices
                            .splitOrderNumber
                        }
                      </div>
                    </React.Fragment>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Collapse
          bordered={false}
          defaultActiveKey={["1"]}
          expandIcon={() => null}
          className={styles.GarmentSelectionCollapse}
        >
          <Panel
            header={labels.GARMENT_LEVEL_INFO}
            key={1}
            disabled
            extra={
              labels.SELECTED_PENDING_GARMENT +
              ": " +
              this.state.selectedRowKeys.length
            }
            style={{
              backgroundColor:
                this.state.resortingAndPackagingServices != undefined &&
                this.state.resortingAndPackagingServices.orderType == 2
                  ? "#e35b5b"
                  : null,
            }}
          >
            <div className={styles.hr}></div>
            <div style={{ marginTop: 10 }}>
              <Form ref={this.fromInstance}>
                <div className={styles.TableFields}>
                  <Table
                    size="small"
                    rowSelection={{
                      type: this.selectionType,
                      ...this.rowSelection,
                    }}
                    columns={this.columns}
                    dataSource={this.state.listItemsRes}
                    pagination={false}
                    loading={this.state.listItemTableLoading}
                  />
                </div>
              </Form>
            </div>
          </Panel>
        </Collapse>
        <Collapse
          bordered={false}
          defaultActiveKey={["2"]}
          expandIcon={() => null}
          className={styles.GarmentSelectionCollapse}
        >
          <Panel
            header={labels.PREVIOUSLY_GARMENTS_PACKED_IN}
            key={2}
            disabled
            extra={
              labels.PREVIOUSLY_PACKED_ON + " : " + this.state.lastPackedDate
              // "Packed On : " + this.state.resortingAndPackagingServices.length >
              // 0
              //   ? this.state.resortingAndPackagingServices.lastPackedDate
              //   : null
            }
          >
            <div className={styles.hr}></div>
            <div style={{ paddingLeft: "3%", fontWeight: "bold" }}>
              {garmentPackedIn}
            </div>
          </Panel>
        </Collapse>
        <Collapse
          bordered={false}
          defaultActiveKey={["3"]}
          expandIcon={() => null}
          className={styles.GarmentSelectionCollapse}
        >
          <Panel
            header={labels.PENDING_GARMENTS_TO_BE_PACKED_IN}
            key={3}
            disabled
          >
            <div className={styles.hr}></div>
            <div style={{ marginTop: 10 }} className="w-100">
              <Col xs={24} style={{ color: "white" }}>
                <Form ref={formReference}>{getNoOfGarmentPackingList}</Form>
              </Col>
            </div>
            <div className={styles.hr}></div>
            <div style={{ marginTop: 10 }}>
              <Button
                variant="blue"
                htmlType="submit"
                onClick={this.generateStiker}
                disabled={this.state.isStikerGenerated}
                loading={this.state.generateBtnLoading}
              >
                {labels.GENERATE_STICKER}
              </Button>
            </div>
          </Panel>
        </Collapse>

        <Modal
          title={labels.PRINT_GENERATE_STICKER}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <div
            style={{
              overflowY: "scroll",
              justifyContent: "flex",
              justifyContent: "center",
            }}
          >
            <GeneratePrintInfo
              printInfo={this.state.printInfo}
              stickerID={this.state.stickerID}
              customerID={
                this.state.resortingAndPackagingServices != null
                  ? this.state.resortingAndPackagingServices.customerID
                  : 0
              }
            ></GeneratePrintInfo>
          </div>
        </Modal>
      </Spin>
      // : (
      //   <div style={{ textAlign: "center" }}>
      //     {labels.THIS_ACTION_CAN_NOT_BE_PERFORMED_FROM_A_CLOSED_FACTORY}
      //   </div>
      // )
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
      /*: (*/
    );
  }

  generateStiker = () => {
    //   if (this.fromInstance.current.getFieldsError().length > 0) {
    //     notification["error"]({
    //       message: "Packing garments value must have less then no of packed garments !",
    //     });
    //     return false;
    //   }
    if (this.state.finalNoOfgarmentPackings.length == 0) {
      notification["error"]({
        message: labels.RESORTING_AND_PACKAGING,
        description:
          labels.PLEASE_SELECT_ATLEAST_ONE_PENDING_GARMENTS_TO_BE_PACKED_IN,
      });
      return false;
    }
    if (
      ![undefined, null, []].includes(this.state.finalNoOfgarmentPackings) &&
      this.state.finalNoOfgarmentPackings.length > 0
    ) {
      let count = 0;
      this.state.finalNoOfgarmentPackings.forEach((element) => {
        count = count + parseInt(element.qty);
      });

      if (count > 99) {
        notification["error"]({
          message: labels.RESORTING_AND_PACKAGING,
          description: labels.SELECT_PACKING_MATERIAL,
        });
        return false;
      }
    }

    // return false;
    var userId = jwt.decode(
      JSON.parse(localStorage.getItem("currentuser")).token
    ).unique_name;
    let packedGarments = 0;
    // if (this.state.postData.length == 0) return;
    this.state.postData.stickerWiseGarments.forEach((element) => {
      packedGarments = element.qty + packedGarments;
    });

    console.log("packedGarments :::" + packedGarments);
    console.log("factID", parseInt(localStorage.getItem("branchID")));
    this.setState(
      {
        postData: {
          ...this.state.postData,
          packedMaterials: this.state.finalNoOfgarmentPackings,
          splitOrderNumber:
            this.state.resortingAndPackagingServices.splitOrderNumber, //this.state.searchGarmentNo,
          customerID: this.state.resortingAndPackagingServices.customerID,
          storeID: this.state.resortingAndPackagingServices.storeID,
          factoryID: parseInt(localStorage.getItem("branchID")),
          packedGarments: packedGarments,
          userID: parseInt(userId),
        },
      },
      () => {
        this.setState({ generateBtnLoading: true });
        this.resortingAndPackaging
          .GenerateStickers(this.state.postData)
          .then((res) => {
            this.setState({ generateBtnLoading: false });
            this.setState({ iscallGraments: false });
            if (
              res.statusCode == 200 &&
              res.result.toLowerCase() === "success"
            ) {
              openNotificationWithIcon(
                "success",
                labels.RESORTING_AND_PACKAGING,
                labels.STICKER_SUCCESSFULLY_GENERATED
              );
              this.setState({ printInfo: [], postData: [] });
              if (res.data.stickerID != "") {
                this.setState({ generateBtnLoading: true });
                this.resortingAndPackaging
                  .GetStickerInfoToPrint(parseInt(res.data.stickerID))
                  .then((res) => {
                    this.setState(
                      {
                        showAddPopup: true,
                        printInfo: res.data,
                        stickerID: res.data.stickerID,
                      },
                      () => {
                        this.setState({
                          listItemsRes: [],
                          resortingAndPackagingServices: {
                            vMPackingMaterial: [],
                          },
                        });
                        formReference.current.resetFields();
                      }
                    );
                    this.setState({ generateBtnLoading: false });
                  });
              } else {
                notification["error"]({
                  message: labels.RESORTING_AND_PACKAGING,
                  description: labels.UNABLE_TO_GET_STICKERID,
                });
              }
            } else {
              //Umesh - added for throw error when try to pack already packed garment.
              notification["error"]({
                message: labels.RESORTING_AND_PACKAGING,
                description: labels.GARMENT_ALREADY_PACKED,
              });
            }
          });
      }
    );
  };

  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
    window.location.reload();
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    // this.resetFields();
    window.location.reload();
  };
}
