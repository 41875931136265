const axios = require("axios");

export default class MantraHelper {
    constructor() {
    }
    
   async CaptureFinger(quality, timeout) {
    let  resInfo;
    await PrepareScanner().then((res) => {
            if (!res) {
                return getFalseRes();
            }
            var MFS100Request = {
                "Quality": quality,
                "TimeOut": timeout
            };

            
            var jsondata = JSON.stringify(MFS100Request);
           return  PostMFS100Client("capture", jsondata)
           .then((resp) => {
             
                resInfo=resp;
                 return resp;
            });
            
        });

        return Promise.resolve(resInfo);
        // if (!  PrepareScanner()) {
        //     return getFalseRes();
        // }
        // var MFS100Request = {
        //     "Quality": quality,
        //     "TimeOut": timeout
        // };
        // let  resInfo= {};
        // var jsondata = JSON.stringify(MFS100Request);
        // return PostMFS100Client("capture", jsondata);
    }

    async VerifyFinger(ProbFMR, GalleryFMR) {
        let  resInfo;
        await PrepareScanner().then((res) => {
               
                if (!res) {
                    return getFalseRes();
                }
                var MFS100Request = {
                    ProbTemplate: ProbFMR,
                    GalleryTemplate: GalleryFMR,
                    BioType: "FMR" // you can paas here BioType as "ANSI" if you are using ANSI Template
                };
    
                
                var jsondata = MFS100Request;//JSON.stringify(MFS100Request);
               return  PostMFS100Client("verify", jsondata)
               .then((resp) => {
               
                    resInfo=resp;
                     return resp;
                });
                
            });
    
            return Promise.resolve(resInfo);

        // if (!PrepareScanner()) {
        //     return getFalseRes();
        // }
        // var MFS100Request = {
        //     "ProbTemplate": ProbFMR,
        //     "GalleryTemplate": GalleryFMR,
        //     "BioType": "FMR" // you can paas here BioType as "ANSI" if you are using ANSI Template
        // };
        // var jsondata = JSON.stringify(MFS100Request);
        // return PostMFS100Client("verify", jsondata);
    }

}

var KeyFlag = "";
var isGetSuccess = false;


async function PrepareScanner() {
    var  localisGetSuccess = false;
        if (!isGetSuccess) {

           await GetMFS100Client("info").then((resInfo) => {
                if (!resInfo.httpStaus) {
                    //alert(resInfo.err);
                    localisGetSuccess =  false;
                }
                else {
                    localisGetSuccess =  true;
                    isGetSuccess = true;
                }
                // if (KeyFlag !=  null && KeyFlag != 'undefined' && KeyFlag.length > 0) {
                //     var MFS100Request = {
                //         "Key": KeyFlag,
                //     };
                //     var jsondata = JSON.stringify(MFS100Request);
                //     PostMFS100Client("keyinfo", jsondata);
                // }

            });
        }else{
            localisGetSuccess= true;
        }
       
       // return true;
        return Promise.resolve(localisGetSuccess);
   

}

async function PostMFS100Client(method, jsonData) {
   
    let res;
    // $.support.cors = true;
    // var httpStaus = false;
    // $.ajax({
    //     type: "POST",
    //     async: false,
    //     crossDomain: true,
    //     url: getURL() + method,
    //     contentType: "application/json; charset=utf-8",
    //     data: jsonData,
    //     dataType: "json",
    //     processData: false,
    //     success: function (data) {
    //         httpStaus = true;
    //         res = { httpStaus: httpStaus, data: data };
    //     },
    //     error: function (jqXHR, ajaxOptions, thrownError) {
    //         res = { httpStaus: httpStaus, err: getHttpError(jqXHR) };
    //     },
    // });
    let axiosConfig = {
        headers: {
            crossDomain: true,
            contentType: "application/json; charset=utf-8",
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Accept":"application/json, text/plain, */*",
            "Sec-Fetch-Dest":"empty",
            "Sec-Fetch-Mode":"cors",
            "Sec-Fetch-Site":"cross-site",
            "Accept-Encoding":"gzip, deflate, br",
            "Accept-Language":"en-IN,en-GB;q=0.9,en-US;q=0.8,en;q=0.7,hi;q=0.6,gu;q=0.5",
            "Access-Control-Allow-Credentials": true,
            dataType: "json",
        }
      };
      

    return     axios.post(getURL() + method, jsonData, { crossdomain: true }, axiosConfig)
        .then((data) => {
            return { httpStaus: true, data: data };
          })
          .catch((error) => {
            // console.log(error);
            return { httpStaus: false, err: getHttpError(error) };
            
          });
        
    //return Promise.resolve(res);

}

async function GetMFS100Client(method) {
	
    var res;
    // $.support.cors = true;
    // var httpStaus = false;
    // $.ajax({
    //     type: "GET",
    //     async: false,
    //     crossDomain: true,
    //     url: getURL() + method,
    //     contentType: "application/json; charset=utf-8",
    //     processData: false,
    //     success: function (data) {
    //         httpStaus = true;
    //         res = { httpStaus: httpStaus, data: data };
    //     },
    //     error: function (jqXHR, ajaxOptions, thrownError) {
    //         res = { httpStaus: httpStaus, err: getHttpError(jqXHR) };
    //     },
    // });
    return await axios.get(getURL() + method)
    .then((data) => {
        return { httpStaus: true, data: data };
      })
      .catch((error) => {
        // console.log(error);
        return { httpStaus: false, err: getHttpError(error) };
        
      });
    // return Promise.resolve(res);
}

function GetMFS100Info() {
    KeyFlag = "";
    return GetMFS100Client("info");
}

function GetMFS100KeyInfo(key) {
    KeyFlag = key;
    if (!PrepareScanner())
    {
        return getFalseRes();
    }
    var MFS100Request = {
        "Key": key,
    };
    var jsondata = JSON.stringify(MFS100Request);
    return PostMFS100Client("keyinfo", jsondata);
}
function CaptureFinger(quality, timeout) {

    if (!PrepareScanner()) {
        return getFalseRes();
    }
    var MFS100Request = {
        "Quality": quality,
        "TimeOut": timeout
    };
    var jsondata = JSON.stringify(MFS100Request);
    return PostMFS100Client("capture", jsondata);
}

function MatchFinger(quality, timeout, GalleryFMR) {
    if (!PrepareScanner()) {
        return getFalseRes();
    }
    var MFS100Request = {
        "Quality": quality,
        "TimeOut": timeout,
        "GalleryTemplate": GalleryFMR,
        "BioType": "FMR" // you can paas here BioType as "ANSI" if you are using ANSI Template
    };
    var jsondata = JSON.stringify(MFS100Request);
    return PostMFS100Client("match", jsondata);
}
function GetPidData(BiometricArray) {
    if (!PrepareScanner()) {
        return getFalseRes();
    }
    var req = new MFS100Request(BiometricArray);
    var jsondata = JSON.stringify(req);
    return PostMFS100Client("getpiddata", jsondata);
}
function GetProtoPidData(BiometricArray) {
    if (!PrepareScanner()) {
        return getFalseRes();
    }
    var req = new MFS100Request(BiometricArray);
    var jsondata = JSON.stringify(req);
    return PostMFS100Client("getppiddata", jsondata);
}
function GetRbdData(BiometricArray) {
    if (!PrepareScanner()) {
        return getFalseRes();
    }
    var req = new MFS100Request(BiometricArray);
    var jsondata = JSON.stringify(req);
    return PostMFS100Client("getrbddata", jsondata);
}
function GetProtoRbdData(BiometricArray) {
    if (!PrepareScanner()) {
        return getFalseRes();
    }
    var req = new MFS100Request(BiometricArray);
    var jsondata = JSON.stringify(req);
    return PostMFS100Client("getprbddata", jsondata);
}



function getHttpError(jqXHR) {
    var err = "Unhandled Exception";
    if (jqXHR.status === 0) {
        err = 'Service Unavailable';
    } else if (jqXHR.status == 404) {
        err = 'Requested page not found';
    } else if (jqXHR.status == 500) {
        err = 'Internal Server Error';
    }
    //  else if (thrownError === 'parsererror') {
    //     err = 'Requested JSON parse failed';
    // } else if (thrownError === 'timeout') {
    //     err = 'Time out error';
    // } else if (thrownError === 'abort') {
    //     err = 'Ajax request aborted';
    // } 
    else {
        err = 'Unhandled Error';
    }
    return err;
}


// Devyang
function getURL() {
 var strURL = "http://localhost:8004/mfs100/";
	  try {
		 
		  var protocol = window.location.href;
		  //alert(protocol);
		  if (protocol.indexOf("https") >= 0) {
				 strURL = "https://localhost:8003/mfs100/";
		 }
		}
		 catch (e)
		 {
			strURL = "https://localhost:8003/mfs100/";
		 }
	return strURL;
}


//

/////////// Classes

function Biometric(BioType, BiometricData, Pos, Nfiq, Na) {
    this.BioType = BioType;
    this.BiometricData = BiometricData;
    this.Pos = Pos;
    this.Nfiq = Nfiq;
    this.Na = Na;
}

function MFS100Request(BiometricArray) {
    this.Biometrics = BiometricArray;
}

function getFalseRes()
{
    var res;
    res = { httpStaus: false, err: "Error while calling service" };
    return res;
}