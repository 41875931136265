import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./addfdiscountcomponent.module.css";
import { Steps, PageHeader, Form, notification, Spin } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ROUTES from "../../../routes";
import DiscountMaster from "../AddDiscountMaster/discountmaster.component";
import { from } from "rxjs";
import DiscountMasterService from "../../../services/discountmasterservice";
import DiscountCode from "../AddDiscountMaster/discountcode.component";

const { Step } = Steps;

class AddDiscount extends React.Component {
  discountmasterservice = new DiscountMasterService();
  state = {
    activeStep: 0,
    discountID: 0,
    fullLoading: false,
    generalFormValues: {},
    mode: "add",
    stepsDirection: "vertical",
    stepsSize: "default",
    discountStatus: 1,
    usageTypeID: 0,
    discountTypeEditID: 0,
  };

  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "small" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    if (
      [ROUTES.EDIT_DISCOUNT_MASTER, ":discountID"].join("/") ===
      this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });
      this.getDiscountMaster(this.props.location.state.discountID);
    } else if (
      [ROUTES.VIEW_DISCOUNT_MASTER, ":discountID"].join("/") ===
      this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.getDiscountMaster(this.props.location.state.discountID);
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
  }

  getDiscountMaster = (id) => {
    this.discountmasterservice
      .getDiscountDetailByDiscountID(id)
      .then((response) => {
        if (
          response.statusCode === 200 &&
          parseInt(response.data.discountID) === parseInt(id)
        ) {
          this.setState({
            discountID: response.data.discountID,
            discountStatus: response.data.status,
            usageTypeID: response.data.usageType,
            discountTypeEditID: response.data.discountType,
          });
          this.setState({
            generalFormValues: {
              discountName: response.data.discountName.toString(),
              discountTitle:response.data.discountTitle.toString(),
              validFrom: [
                response.data.validFrom
                  ? moment(response.data.validFrom)
                  : null,
                response.data.validTo ? moment(response.data.validTo) : null,
              ],
              mode: response.data.mode.toString(),
              promoCodeType: response.data.promoCodeType.split(","),
              usageType: response.data.usageType.toString(),
              nos_Times: response.data.nos_Times.toString(),
              nos_Times_Redeemable:response.data.nos_Times_Redeemable.toString(),
              isFirstOrderOnly: response.data.isFirstOrderOnly,
              weekDays: response.data.weekDays.split(","),
              discountType: response.data.discountType.toString(),
              maxAmount: response.data.maxAmount.toString(),
              minAmount: response.data.minAmount.toString(),
              includeDeliveryCharge: response.data.includeDeliveryCharge,
              comment: response.data.comment,
              applicableStoreIds: response.data.applicableStoreIds.split(","),
              applicableCityIds: response.data.applicableCityIds
                .split(",")
                .map(function (item) {
                  return parseInt(item, 10);
                }),
              applicableServiceIds: response.data.applicableServiceIds.split(
                ","
              ),
              discountValue: response.data.discountOnValue.toString(),
            },
          });
          this.setState({ fullLoading: false });
          const prevStep = this.state.activeStep;
          this.setState({ activeStep: 1 });
          this.setState({ activeStep: prevStep });
        } else {
          notification["error"]({
            message: "Discount",
            description: "Unable to get discount data",
            // description: "Redirecting....",
            onClose: () => {
              this.props.history.goBack();
            },
          });
        }
      });
  };

  addUpdateDiscount = () => {
    this.setState({ fullLoading: true });
    const postData = {
      discountID: this.state.discountID,
      discountName: this.state.generalFormValues.discountName.toString(),
      discountTitle:this.state.generalFormValues.discountTitle.toString(),
      validFrom: this.state.generalFormValues.validFrom
        ? this.state.generalFormValues.validFrom[0].format("YYYY-MM-DD")
        : null,
      validTo: this.state.generalFormValues.validFrom
        ? this.state.generalFormValues.validFrom[1].format("YYYY-MM-DD")
        : null,
      mode: parseInt(this.state.generalFormValues.mode),
      promoCodeType: this.state.generalFormValues.promoCodeType.join(","),
      usageType: parseInt(this.state.generalFormValues.usageType),
      nos_Times: parseInt(this.state.generalFormValues.nos_Times),
      nos_Times_Redeemable:(this.state.generalFormValues.nos_Times_Redeemable) != null ?
      parseInt(this.state.generalFormValues.nos_Times_Redeemable):0,
      // nos_Times_Redeemable:parseInt(this.state.generalFormValues.nos_Times_Redeemable),
      isFirstOrderOnly: this.state.generalFormValues.isFirstOrderOnly
        ? this.state.generalFormValues.isFirstOrderOnly
        : false,
      weekDays: this.state.generalFormValues.weekDays.join(","),
      discountType: parseInt(this.state.generalFormValues.discountType),
      maxAmount:
        this.state.generalFormValues.maxAmount !== undefined
          ? parseFloat(this.state.generalFormValues.maxAmount)
          : 0,
      minAmount: parseFloat(this.state.generalFormValues.minAmount),
      includeDeliveryCharge: this.state.generalFormValues.includeDeliveryCharge
        ? this.state.generalFormValues.includeDeliveryCharge
        : false,
      comment: this.state.generalFormValues.comment
        ? this.state.generalFormValues.comment
        : "",
      applicableStoreIds: this.state.generalFormValues.applicableStoreIds.join(
        ","
      ),
      applicableCityIds: this.state.generalFormValues.applicableCityIds.join(
        ","
      ),
      applicableServiceIds: this.state.generalFormValues.applicableServiceIds.join(
        ","
      ),
      discountOnValue: parseFloat(this.state.generalFormValues.discountValue),
    };
    this.discountmasterservice
      .saveUpdateDiscountMaster(postData)
      .then((response) => {
        if (response.statusCode === 200) {
          // this.setState({ discountID: response.data });
          notification["success"]({
            message: "Discount",
            description: response.message,
            duration: 1,
            onClose: () => {
              this.setState({ activeStep: 1 });
              this.setState({ fullLoading: false });
            },
          });
          if (this.state.mode === "edit" || this.state.mode === "view") {
            this.getDiscountMaster(this.props.match.params.discountID);
          }
        } else {
          notification["error"]({
            message: "Discount",
            description: response.message
              ? response.message
              : "Unable to submit the form",
          });
        }
        this.setState({ fullLoading: false });
      });
  };
  generalForm = React.createRef();
  handleSubmit = (values) => {
    this.setState(
      {
        generalFormValues: values,
      },
      () => {
        this.addUpdateDiscount();
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? "Edit"
                : this.state.mode === "view"
                ? "View"
                : "Add",
              "Discount Master",
            ].join(" ")}
          />
          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                //onChange={(current) => this.setState({ activeStep: current })}
                onChange={(current) => {
                  if (this.state.discountID === 0) {
                    return false;
                  } else {
                    this.setState({ activeStep: current });
                  }
                }}
              >
                <Step
                  title="Discount Master"
                  description="Discount Master information"
                />
                <Step
                  title="Code Master"
                  description="Code Master Information"
                />
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider
                onFormFinish={(name, info) => {
                  if (name === "general") {
                    this.handleSubmit(info.values);
                  }
                }}
              >
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading} style={{ height: "100%" }}>
                  <React.Fragment>
                    <DiscountMaster
                      values={this.state.generalFormValues}
                      backFunction={() => this.props.history.goBack()}
                      isActive={this.state.activeStep === 0}
                      instance={this.generalForm}
                      mode={this.state.mode}
                      DiscountStatusName={this.state.discountStatus}
                      discountID={this.state.discountID}
                      getDiscountMaster={this.getDiscountMaster}
                      discountTypeEditID={this.state.discountTypeEditID}
                    />

                    {this.state.activeStep === 1 ? (
                      <DiscountCode
                        discountName={this.state.generalFormValues.discountName.toString()}
                        usageTypeID={this.state.usageTypeID}
                        DiscountStatusName={this.state.discountStatus}
                      />
                    ) : null}
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(AddDiscount);
