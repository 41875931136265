import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  DatePicker,
  Space,
  notification,
  Radio,
} from "antd";
import Button from "../../../Common/Button/button";
import CustomerService from "../../../../services/customerservice";
import { RegexLetters, MessageLetters } from "../../../../shared/validator";
import moment from "moment";
import { labels } from "../../../../shared/language";
const { Option } = Select;
/**
 * General form as a part of add customer screen
 *
 * @author Niraj Gohel
 * @created 25 June 2020
 */
export default class GeneralForm extends React.Component {
  /**
   * Initalizing Factory Service
   */
  customerService = new CustomerService();

  /**
   * Storing objects for dynamic controls
   *
   * @added 18 June 2020
   */
  state = {
    customerType: {
      selected: null,
      disabled: true,
      data: [],
    },
    billingCycle: {
      disabled: true,
      data: [],
    },
    gender: {
      disabled: true,
      data: [],
    },
    corporate: {
      disabled: true,
      data: [],
    },
  };
  /**
   * API Calls on component mounting to populate dropdowns
   * @added 18 June 2020
   */
  componentDidMount() {
    this.getCustomerTypes();
    this.getBillingCycles();
    this.getGenders();
    this.getCorporate();
  }
  /**
   * Populating Delivery Type Dropdown
   * @added 18 June 2020
   */
  getCustomerTypes = () => {
    this.customerService.getCustomerTypes().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          customerType: {
            ...this.state.customerType,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Manage Customers",
          description: "Unable to get data for customer types",
        });
      }
    });
  };

  getBillingCycles() {
    this.customerService.getBillingCycle().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          billingCycle: {
            ...this.state.billingCycle,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Manage Customers",
          description: "Unable to get data for billing cycle list",
        });
      }
    });
  }

  getGenders() {
    this.customerService.getGenders().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          gender: {
            ...this.state.gender,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Manage Customers",
          description: "Unable to get data for gender",
        });
      }
    });
  }
  getCorporate() {
    this.customerService.getCorporate().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          corporate: {
            ...this.state.corporate,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Manage Customers",
          description: "Unable to get data for gender",
        });
      }
    });
  }
  render() {
    const disableAll = this.props.mode === "view";
    return (
      <Form
        key={this.props.values.factoryName}
        name="general"
        layout="vertical"
        initialValues={this.props.values}
        ref={this.props.instance}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.FIRST_NAME}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: labels.PLEASE_ENTER_FIRST_NAME,
                },
                {
                  pattern: RegexLetters,
                  message: labels.PLEASE_ENTER_ONLY_ALPHABETS,
                },
              ]}
            >
              <Input
                placeholder={labels.ENTER_FIRST_NAME}
                disabled={disableAll}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.LAST_NAME}
              name="lastName"
              rules={[
                {
                  required: false,
                  message: labels.PLEASE_ENTER_LAST_NAME,
                },
                {
                  pattern: RegexLetters,
                  message: labels.PLEASE_ENTER_ONLY_ALPHABETS,
                },
              ]}
            >
              <Input
                placeholder={labels.ENTER_LAST_NAME}
                disabled={disableAll}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.EMAIL}
              name="emailID"
              rules={[
                {
                  required: false,
                  message: labels.PLEASE_ENTER_EMAIL,
                },
                {
                  type: "email",
                  message: labels.PLEASE_ENTER_VALID_EMAIL,
                },
              ]}
            >
              <Input placeholder={labels.ENTER_EMAIL} disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.PHONE_NUMBER}
              name="mobileNo"
              validateFirst={true}
              rules={[
                { required: true, message: labels.PLEASE_ENTER_PHONE_NUMBER },
                {
                  pattern: "[6789][0-9]{9}",
                  message: labels.PLEASE_ENTER_VALID_PHONE_NUMBER,
                },
              ]}
            >
              <Input
                placeholder={labels.ENTER_PHONE_NUMBER}
                disabled={disableAll}
                maxLength={10}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.ALTER_PHONE_NUMBER}
              name="alterNativeMobileNo"
              validateFirst={true}
              rules={[
                // { required: true, message: labels.PLEASE_ENTER_PHONE_NUMBER },
                {
                  pattern: "[6789][0-9]{9}",
                  message: labels.PLEASE_ENTER_VALID_PHONE_NUMBER,
                },
              ]}
            >
              <Input
                placeholder={labels.ALTER_PHONE_NUMBER}
                disabled={true}
                maxLength={10}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              name="gender"
              label={labels.GENDER}
              rules={[
                {
                  required: false,
                  message: labels.PLEASE_SELECT_GENDER,
                },
              ]}
            >
              <Radio.Group disabled={disableAll}>
                {this.state.gender.data.length !== 0
                  ? this.state.gender.data.map((type) => {
                      return type.value !== "-1" ? (
                        <Radio value={type.value} key={type.value}>
                          {type.text}
                        </Radio>
                      ) : null;
                    })
                  : null}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label={labels.DATE_OF_BIRTH} name="dob">
              <DatePicker
                placeholder={labels.SELECT_DATE}
                disabled={disableAll}
                format={"DD-MM-YYYY"}
                disabledDate={(current) => current.isSameOrAfter(moment())}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label={labels.ANNIVERSARY_DATE} name="annivesaryDate">
              <DatePicker
                placeholder={labels.SELECT_DATE}
                disabled={disableAll}
                format={"DD-MM-YYYY"}
                disabledDate={(current) => current.isSameOrAfter(moment())}
              />
            </Form.Item>
          </Col>
          <Col
            xs={this.props.mode === "add" ? 0 : 24}
            md={this.props.mode === "add" ? 0 : 12}
            xl={this.props.mode === "add" ? 0 : 8}
          >
            <Form.Item label={labels.REFERRAL_CODE} name="referralCode">
              <Input placeholder={labels.ENTER_REFERRAL_CODE} disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.CUSTOMER_TYPE}
              name="customerType"
              rules={[
                {
                  required: true,
                  message: labels.PLEASE_SELECT_CUSTOMER_TYPE,
                },
              ]}
            >
              <Select
                placeholder={labels.SELECT_CUSTOMER_TYPE}
                onChange={(value) => {
                  this.setState({
                    customerType: {
                      ...this.state.customerType,
                      selected: value,
                    },
                  });
                }}
                disabled={this.state.customerType.disabled || disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.customerType.data.length !== 0
                  ? this.state.customerType.data.map((type) => {
                      return type.value !== "-1" ? (
                        <Option value={type.value} key={type.value}>
                          {type.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          {this.props.mode !== "add" ? (
            this.props.values.customerType !== undefined ? (
              this.props.values.customerType.toString() === "2" ? (
                <Col xs={24} md={12} xl={8}>
                  <Form.Item label={labels.CORPORATE} name="corporateID">
                    <Select
                      placeholder={labels.SELECT_CORPORATE}
                      onChange={() => null}
                      disabled={this.state.corporate.disabled || disableAll}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                    >
                      {this.state.corporate.data.length !== 0
                        ? this.state.corporate.data.map((single) => {
                            return single.value !== "-1" ? (
                              <Option value={single.value} key={single.value}>
                                {single.text}
                              </Option>
                            ) : null;
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null
            ) : null
          ) : this.state.customerType.selected === "2" ? (
            <Col xs={24} md={12} xl={8}>
              <Form.Item label={labels.CORPORATE} name="corporateID">
                <Select
                  placeholder={labels.SELECT_CORPORATE}
                  onChange={() => null}
                  disabled={this.state.corporate.disabled || disableAll}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.corporate.data.length !== 0
                    ? this.state.corporate.data.map((single) => {
                        return single.value !== "-1" ? (
                          <Option value={single.value} key={single.value}>
                            {single.text}
                          </Option>
                        ) : null;
                      })
                    : null}
                </Select>
              </Form.Item>
            </Col>
          ) : null}

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.BILLING_CYCLE}
              name="billingCycle"
              rules={[
                {
                  required: true,
                  message: labels.PLEASE_SELECT_BILLING_CYCLE,
                },
              ]}
              dependencies={["customerType"]}
              shouldUpdate={(prev, next) =>
                next.customerType === 3 ||
                next.customerType !== prev.customerType
                  ? true
                  : false
              }
            >
              <Select
                placeholder={labels.SELECT_BILLING_CYCLE}
                onChange={() => null}
                disabled={this.state.billingCycle.disabled || disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.billingCycle.data.length !== 0
                  ? this.state.billingCycle.data.map((cycle) => {
                      if (this.state.customerType.selected !== "3") {
                        return cycle.value !== "-1" ? (
                          <Option value={cycle.value} key={cycle.value}>
                            {cycle.text}
                          </Option>
                        ) : null;
                      } else {
                        return cycle.value === "4" ? (
                          <Option value={cycle.value} key={cycle.value}>
                            {cycle.text}
                          </Option>
                        ) : null;
                      }
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.GSTIN}
              name="gstin"
              rules={[
                {
                  len: 15,
                  message: labels.ENTER_VALID_GSTIN,
                },
              ]}
            >
              <Input placeholder={labels.ENTER_GSTIN} disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.ACTIVE}
              name="isActive"
              rules={[
                {
                  required: true,
                  message: labels.PLEASE_SELECT_STATUS,
                },
              ]}
            >
              <Radio.Group disabled={disableAll}>
                <Radio value={true}>{labels.YES}</Radio>
                <Radio value={false}>{labels.NO}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.IS_LAUNDROKART_EMPLOYEE + " ?"}
              name="isLaundrokartEmployee"
              rules={[
                {
                  required: true,
                  message: labels.PLEASE_SELECT_STATUS,
                },
              ]}
            >
              <Radio.Group disabled={disableAll}>
                <Radio value={true}>{labels.YES}</Radio>
                <Radio value={false}>{labels.NO}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} style={{ textAlign: "right" }}>
            <Space>
              <div className="w-100"></div>
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                {labels.CANCEL}
              </Button>
              {disableAll ? null : (
                <Button
                  htmlType="submit"
                  onClick={this.props.nextFunction}
                  variant="purple"
                  shape="round"
                  size="large"
                >
                  {labels.SUBMIT}
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
