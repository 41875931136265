import React from "react";
import { notification, Typography, List } from "antd";
import styles from "./chartscomponent.module.css";
import { labels } from "../../../shared/language";
import NoticeBoardService from "../../../services/noticeboardservice";
import Common from "../../../services/common";
import { InfoCircleTwoTone } from "@ant-design/icons";

export default class NoticeBoard extends React.Component {
  state = {
    notices: [],
  };
  service = new NoticeBoardService();
  common = new Common();
  componentDidMount() {
    this.getNotices();
  }
  getNotices = () => {
    this.props.toggleLoading(true);
    this.service.getAllNoticeList(1, 10, null, null, null).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          notices: response.data,
        });
      } else {
        this.setState({ notices: [] });
        notification["error"]({
          message: labels.DASHBOARD,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_DATA_FOR_DASHBOARD,
        });
      }
      this.props.toggleLoading(false);
    });
  };
  render() {
    return (
      <div className={styles.ChartsWrapper}>
        <div className={styles.ChartsTitleWrap}>
          <Typography.Title level={4}>{labels.NOTICE_BOARD}</Typography.Title>
        </div>
        <List
          dataSource={this.state.notices}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div style={{ fontSize: 16 }}>
                    <InfoCircleTwoTone twoToneColor={"#0095da"} />
                  </div>
                }
                title={item.notice}
              />
            </List.Item>
          )}
        />
      </div>
    );
  }
}
