import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../../Common/Styles/master.module.css";
import {
  Steps,
  PageHeader,
  Spin,
  Row,
  Col,
  Form,
  Select,
  // TimePicker,
  Input,
  // DatePicker,
  Space,
  notification,
  // Radio,
  Descriptions,
  Upload,
} from "antd";
import Button from "../../../Common/Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import GeneralForm from "./garment.addpreqcfactory";
import FactoryPreQC from "../../../../services/factorypre-qc";
import ROUTES from "../../../../routes";
import { labels } from "../../../../shared/language";
import Common from "../../../../services/common";

const { Step } = Steps;

/**
 * Add Factory Form as a part of Factory Master Module
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 *
 * @module Master
 */
class AddFactory extends React.Component {
  factoryPreQCService = new FactoryPreQC();
  common = new Common();
  // corporateService = new CorporateService();
  /**
   * State Variables
   * @var factoryID : ID of factory 0 if this is to add factory
   * @var activeStep : holds active form step
   * @var stepDirection : holds direction of steps horizontal/vertical
   * @var stepSize : size of steps
   * @var generalFormValues : values submitted through generalForm
   * @var contactFormValues : values submitted through contactForm
   */
  state = {
    factoryID: 0,
    activeStep: 0,
    fullLoading: false,
    stepsDirection: "vertical",
    stepsSize: "default",
    generalFormValues: {},
    contactFormValues: {},
    mode: "add",
  };
  /**
   * A function to responsively set direction of the steps
   */
  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "small" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };

  /**
   * bind the setStepDirection to the resize event of windows
   */
  componentDidMount() {
    // if (
    //   [ROUTES.EDIT_PRE_QC_FACTORY, ":tagNo"].join("/") === this.props.match.path
    // ) {
    //   this.setState({ fullLoading: true });
    //   this.setState({ mode: "edit" });
    //   this.getQCInfoByTagNumber(this.props.match.params.tagNo);
    // } else
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    if (
      [ROUTES.VIEW_PRE_QC_FACTORY, ":tagNo"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.getQCInfoByTagNumber(this.props.location.state.tagNo);
      // this.getQCInfoByTagNumber("5s45002");
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
    setTimeout(() => {
      this.setState({ fullLoading: false });
    }, 1500);
  }

  getQCInfoByTagNumber = (tagNo) => {
    var QCPendingFrom = 0;
    this.factoryPreQCService
      .getQCInfoByTagNumber(tagNo, QCPendingFrom)
      .then((response) => {
        if (response.statusCode === 200) {
          // this.setState({ tagNo: response.data.tagNo });
          this.setState({
            generalFormValues: {
              qcStatus: response.data[0].qcStatusName,
              remark: response.data[0].remark,
              qcReason: parseInt(response.data[0].qcReason),
              qcImage1: response.data[0].qcImage1,
              qcImage2: response.data[0].qcImage2,
              qcImage3: response.data[0].qcImage3,
              //  response.data.qcid,
              //  response.data.qcReasonName,
              //  response.data.orderGarmentID,
              //  response.data.qcImage1,
              //  response.data.qcImage2,
              //  response.data.qcImage3,
            },
          });
          this.setState({ fullLoading: false });
          this.setState({ activeStep: -1 });
          this.setState({ activeStep: 0 });
        } else {
          notification["error"]({
            message: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_CORPORATE_DATA,
            description: labels.REDIRECTING,
            onClose: () => {
              this.props.history.goBack();
            },
          });
        }
      });
  };

  addUpdateCorporate = () => {
    this.setState({ fullLoading: true });

    // this.corporateService.addUpdateCorporate(postData).then((response) => {
    //   if (response.statusCode === 200) {
    //     notification["success"]({
    //       message: "Form Submitted",
    //       description: "Redirecting....",
    //       duration: 1.5,
    //       onClose: () => {
    //         this.props.history.goBack();
    //       },
    //     });
    //   } else {
    //     notification["error"]({
    //       message: "Unable to submit the form",
    //     });
    //   }
    // });
  };
  generalForm = React.createRef();
  contactForm = React.createRef();
  handleSubmit = () => {
    const generalValidator = this.generalForm.current.validateFields();
    const contactValidator = this.contactForm.current.validateFields();
    Promise.allSettled([generalValidator, contactValidator]).then(
      (responses) => {
        if (responses[0].status === "rejected") {
          this.setState({ activeStep: 0 });
        } else if (responses[1].status === "rejected") {
          this.setState({ activeStep: 1 });
        } else if (
          responses[0].status === "fulfilled" &&
          responses[1].status === "fulfilled"
        ) {
          this.setState(
            {
              generalFormValues: responses[0].value,
              contactFormValues: responses[1].value,
            },
            () => {
              this.addUpdateCorporate();
            }
          );
        }
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          {/* {JSON.stringify(this.state)} */}
          {/**
           * Page Header Component from Ant Design
           * Please check below link for more options
           * https://ant.design/components/page-header/
           * https://ant.design/components/page-header/#API
           *
           */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? labels.EDIT
                : this.state.mode === "view"
                ? labels.VIEW
                : labels.ADD,
              labels.GARMENT_FOR_QC,
            ].join(" ")}
          />

          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              {/**
               * Steps Component from Ant Design
               * Please check below link for more options
               * https://ant.design/components/steps/
               * https://ant.design/components/steps/#API
               *
               */}
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => this.setState({ activeStep: current })}
              >
                <Step
                  title={labels.GARMENT}
                  description={labels.SELECT_SCAN_GARMENT}
                />

                {/* <Step
                  title="Communication History"
                  description="History of this garment"
                /> */}
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider>
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading}>
                  <React.Fragment>
                    {/* <Form layout="vertical">
                      <Row gutter={30}>
                        <Col xs={24} md={12} xl={16}>
                          <Form.Item
                            label="Tag Number"
                            name="tagNumber"
                            rules={[
                              { required: true, message: "Please scan/add tag number" },
                            ]}
                          >
                            <div className="w-100" style={{ display: "flex" }}>
                              <Input
                                placeholder="Enter/Scan Tag Number"
                                // disabled={disableAll}
                                value={this.state.tagNo || ''}
                              // onChange={this.onChange.bind(this, 'tagNo')}
                              />
                              <Button
                                variant="transparentGreyOutline"
                                style={{ marginLeft: 10 }}
                                onClick={this.GetGarmentInfo}
                              >
                                Get Garment Info
                </Button>
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form> */}
                    <GeneralForm
                      values={this.state.generalFormValues}
                      backFunction={() => this.props.history.goBack()}
                      nextFunction={() => this.setState({ activeStep: 1 })}
                      isActive={this.state.activeStep === 0}
                      instance={this.generalForm}
                      mode={this.state.mode}
                    />
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(AddFactory);
