import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class InvoiceService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }
  async getInvoiceDetailFromSplitOrder(splitOrderID) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    let itemArray = [];
    if (splitOrderID.split(",").length === 1) {
      myApi.createEntity({
        name: this.endpoint.GET_INVOICE_DATA_FROM_SPLITORDER,
      });
      let append = "?SplitOrderID=" + splitOrderID;
      await myApi.endpoints[this.endpoint.GET_INVOICE_DATA_FROM_SPLITORDER]
        .getById(append)
        .then((response) => {
          itemArray = response.data;
        })
        .catch((err) => {
          itemArray = this.common.handleError(err);
        });
    } else {
      myApi.createEntity({
        name: this.endpoint.GET_INVOICE_DATA,
      });
      let params = {
        invoiceIds: splitOrderID,
        customerIds: null,
        invoiceStatus: 1,
      };
      await myApi.endpoints[this.endpoint.GET_INVOICE_DATA]
        .getAllPost(params)
        .then((response) => {
          itemArray = response.data;
        })
        .catch((err) => {
          itemArray = this.common.handleError(err);
        });
    }
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getInvoiceDetailFromSplitOrder(splitOrderID);
      });
    }
    return itemArray;
  }
  async saveUpdateProformaInvoice(params) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.SAVE_UPDATE_INVOICE_INFO,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.SAVE_UPDATE_INVOICE_INFO]
      .create(params)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.saveUpdateProformaInvoice(params);
      });
    }
    return itemArray;
  }
  async getPaymentMethods() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({
      name: this.endpoint.GET_SYSTEME_NUMS,
    });
    let append = "?EnumNames=PaymentMode";
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_SYSTEME_NUMS]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getPaymentMethods();
      });
    }
    return itemArray;
  }
  async generateTaxInvoice(params) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.SAVE_UPDATE_INVOICE_PAYMENT_INFO,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.SAVE_UPDATE_INVOICE_PAYMENT_INFO]
      .create(params)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.generateTaxInvoice(params);
      });
    }
    return itemArray;
  }
  async markSettled(invoiceID) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.SETTLE_LESS_PAID,
    });
    let append = "?InvoiceID=" + invoiceID;
    let itemArray = [];

    await myApi.endpoints[this.endpoint.SETTLE_LESS_PAID]
      .deleteById(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.markSettled(invoiceID);
      });
    }
    return itemArray;
  }
  async getPDFStoreInvoicePrint(splitOrderNo) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_INVOICE_PRINT });
    let itemArray = [];

    // var param = "?OrderID=" + splitOrderNo;
    await myApi.endpoints[this.endpoint.GET_STORE_INVOICE_PRINT]
      .create(splitOrderNo)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getPDFStoreInvoicePrint(splitOrderNo);
      });
    }
    return Promise.resolve(itemArray);
  }

  async addExcessAmountToWallet(params) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.ADD_ADVANCE_PAYMENT });
    let itemArray = [];

    // var param = "?OrderID=" + splitOrderNo;
    await myApi.endpoints[this.endpoint.ADD_ADVANCE_PAYMENT]
      .create(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.addExcessAmountToWallet(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getEDCMachineInfo() {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({
      name: this.endpoint.PAYTM_EDC_MACHINE_INFO,
    });
    let append =
      "?id=" + parseInt(localStorage.getItem("branchID")) + "&platform=WEB";
    let itemArray = [];

    await myApi.endpoints[this.endpoint.PAYTM_EDC_MACHINE_INFO]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getEDCMachineInfo();
      });
    }
    return Promise.resolve(itemArray);
  }

  async EDCMachineRequest(postData) {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({ name: this.endpoint.PAYTM_EDC_TRANS_REQUEST });
    let itemArray = [];

    await myApi.endpoints.PaytmEDCTransRequest.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.EDCMachineRequest(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async EDCMachineStatus(postData) {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({ name: this.endpoint.PAYTM_EDC_TRANS_STATUS });
    let itemArray = [];

    await myApi.endpoints.PaytmEDCTransStatus.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.EDCMachineStatus(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async PaytmEDCTransInfo(invoiceId) {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({
      name: this.endpoint.PAYTM_EDC_TRANS_INFO,
    });
    let append = "?id=" + invoiceId + "&platfrom=WEB";
    let itemArray = [];

    await myApi.endpoints[this.endpoint.PAYTM_EDC_TRANS_INFO]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.PaytmEDCTransInfo(invoiceId);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getWalletBalance(params) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({
      name: this.endpoint.GET_WALLET_BALANCE,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_WALLET_BALANCE]
      .create(params)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getWalletBalance(params);
      });
    }
    return itemArray;
  }

  async getPDFLinks(orderID) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.GET_PROFORMA_INVOICE_BY_ORDER_ID,
    });
    let append = "?OrderID=" + orderID;
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_PROFORMA_INVOICE_BY_ORDER_ID]
      .getById(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getPDFLinks(orderID);
      });
    }
    return itemArray;
  }

  async settleinvoicewithoutpayment(params) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.SETTLE_INVOICE_WITHOUT_PAYMENT,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.SETTLE_INVOICE_WITHOUT_PAYMENT]
      .create(params)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.settleinvoicewithoutpayment(params);
      });
    }
    return itemArray;
  }

  async UpdateInvoiceRemark(params) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.UPDATE_INVOICE_REMARK,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.UPDATE_INVOICE_REMARK]
      .create(params)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.UpdateInvoiceRemark(params);
      });
    }
    return itemArray;
  }

  async ADDAltNumberForCustomer(postData) {
    const stateListAPI = new API({ url: this.config.CDA_URL });
    stateListAPI.createEntity({
      name: this.endpoint.CDA_ADD_ALT_NUMBER_FOR_CUSTOMER,
    });
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.CDA_ADD_ALT_NUMBER_FOR_CUSTOMER]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.ADDAltNumberForCustomer(postData);
      });
    }
    return Promise.resolve(returnVal);
  }

  async SendCustomerOTPForCreateOrder(postData) {
    const stateListAPI = new API({ url: this.config.CDA_URL });
    stateListAPI.createEntity({
      name: this.endpoint.CDA_SEND_RESEND_CUSTOMEROTP,
    });
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.CDA_SEND_RESEND_CUSTOMEROTP]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.SendCustomerOTPForCreateOrder();
      });
    }
    return Promise.resolve(returnVal);
  }

  async CheckPaymentDetailLog(params) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.GET_PAYMENT_LOG_DATA,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_PAYMENT_LOG_DATA]
      .getAllPost(params)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.CheckPaymentDetailLog(params);
      });
    }
    return itemArray;
  }


  async InsertPaytmprocesslog(params) {
    const myApi = new API({ url: this.config.CP_URL });
    myApi.createEntity({
      name: this.endpoint.INSERT_PAYMENT_PROCESSLOG,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.INSERT_PAYMENT_PROCESSLOG]
      .getAllPost(params)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.InsertPaytmprocesslog(params);
      });
    }
    return itemArray;
  }

  async ResendDeliveryOTP(postData) {
    const stateListAPI = new API({ url: this.config.CDA_URL });
    stateListAPI.createEntity({
      name: this.endpoint.RESEND_DELIVERY_OTP,
    });
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.RESEND_DELIVERY_OTP]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.ResendDeliveryOTP();
      });
    }
    return Promise.resolve(returnVal);
  }
  
  async QRCodeStatusUpdate(params) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.QR_CODE_STATUS_UPDATE,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.QR_CODE_STATUS_UPDATE]
      .getAllPost(params)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.QRCodeStatusUpdate(params);
      });
    }
    return itemArray;
  }

  async UpdateVerifiedOTPDateTime(postData) {
    const stateListAPI = new API({ url: this.config.ITEM_STORE_URL });
    stateListAPI.createEntity({
      name: this.endpoint.UPDATE_VERIFIED_OTP_DATETIME,
    });
    let returnVal = [];
    await stateListAPI.endpoints[this.endpoint.UPDATE_VERIFIED_OTP_DATETIME]
      .getAllPost(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.UpdateVerifiedOTPDateTime();
      });
    }
    return Promise.resolve(returnVal);
  }

  async PhonePeStatusUpdate(params) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.SAVE_UPDATE_PHONEPE_STATUS,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.SAVE_UPDATE_PHONEPE_STATUS]
      .getAllPost(params)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.PhonePeStatusUpdate(params);
      });
    }
    return itemArray;
  }
}
