import React from "react";
import CashCollection from "../../components/CashCollection/cashcollection.component";

export default class CashCollectionScreen extends React.Component {
  render() {
    return (
      <div>
        <CashCollection />
      </div>
    );
  }
}
