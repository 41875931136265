import React from 'react';
import GarmentProcessing from '../../../components/FactoryModule/Processing/garmentprocessing.factory.component';

export default class GarmentProcessingscreen extends React.Component {
    render() {
        return (
            <div>
                <GarmentProcessing></GarmentProcessing>
            </div>
        )
    }
}
