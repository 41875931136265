import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class StoreService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async GetFactoryDashboardOrderProcessCount(storeId) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({
      name: this.endpoint.GET_FACTORY_DASHBOARD_ORDER_PROCESS,
    });
    let itemArray = [];
    let id = "?FactoryID=" + storeId;
    await myApi.endpoints[this.endpoint.GET_FACTORY_DASHBOARD_ORDER_PROCESS]
      .getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetFactoryDashboardOrderProcessCount(storeId);
      });
    }
    return Promise.resolve(itemArray);
  }

  async GetFactoryDashboard(storeId) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.GET_FACTORY_DASHBOARD });
    let itemArray = [];
    let id = "?FactoryID=" + storeId;
    await myApi.endpoints.getFactoryDashboard
      .getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetFactoryDashboard(storeId);
      });
    }
    return Promise.resolve(itemArray);
  }
  handleError(error) {
    console.log(error.message);
  }
}

export default StoreService;
