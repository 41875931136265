import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class PackageConfiguration {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async removeItems(lkPackageID) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.DELETE_LKPACKAGE_BY_ID });
    let itemArray = [];
    let ID = "?LKPackageIDs=" + lkPackageID;
    await myApi.endpoints[this.endpoint.DELETE_LKPACKAGE_BY_ID]
      .deleteById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.removeItems(lkPackageID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async insertUpdateItems(postData) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_LKPACKAGE_MASTER });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.SAVE_UPDATE_LKPACKAGE_MASTER]
      .create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.insertUpdateItems(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async buyPackage(postData) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.BUY_PACKAGE });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.BUY_PACKAGE]
      .create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.buyPackage(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrievePackageConfiguration(param) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.GET_LKPACKAGE_MASTER });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.GET_LKPACKAGE_MASTER]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrievePackageConfiguration(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}

export default PackageConfiguration;
