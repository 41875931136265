import React from "react";
import CashCouponMaster from "../../../../src/components/Master/CashCoupon/cashcouponmaster.component";

export default class Master extends React.Component {

    render() {
        const listItems = null;
        return (
            <div>
                <CashCouponMaster></CashCouponMaster>
            </div>
        );
    }
}