import React, { Component } from "react";
import Button from "../../Common/Button/button";
import {
  Checkbox,
  Col,
  PageHeader,
  Row,
  notification,
  Affix,
  Typography,
} from "antd";
import moment from "moment";
import storemarkerimg from "../../../images/MapMarker/shoppurple.svg";
import { labels } from "../../../shared/language";
import PickupService from "../../../services/pickupservice";
import Common from "../../../services/common";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import DeliveryList from "../../../services/storedeliverylistservice";
const { Text } = Typography;

export default class ViewOnMapComponent extends Component {
  pickupService = new PickupService();
  deliveryListService = new DeliveryList();

  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();

  state = {
    TodayPickupList: [],
    TodayDeliveryList: [],
    ShowMapView: false,
    SelectedData: {},
    TodayPickupSlotList: [],
    TodayDeliverySlotList: [],
    MarkerList: [],
    checkBoxPickupValue: [],
    checkBoxDeliveryValue: [],
  };

  componentDidMount() {
    this.getTodayPickuplist();
    this.getTodayDeliverylist();
  }

  getTodayPickuplist = () => {
    let params = {
      storeID: parseInt(localStorage.getItem("branchID")),
      sourceFrom: 1,
      userID: 0,
    };
    this.pickupService.GetTodayPickipList(params).then((response) => {
      if (response.statusCode === 200) {
        // console.log("response->", response);
        this.setState({
          TodayPickupList: response.data.pickupListRes,
          TodayPickupSlotList: response.data.slotList,
          ShowMapView: true,
        });
        if (
          response.data.pickupListRes != null &&
          response.data.pickupListRes != undefined &&
          response.data.pickupListRes.length > 0
        ) {
          this.initMap();
        }
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_PICKUP_DATA,
        });
      }
    });
  };

  getTodayDeliverylist = () => {
    let params = {
      storeID: parseInt(localStorage.getItem("branchID")),
      sourceFrom: 1,
      userID: 0,
    };
    this.deliveryListService.getTodayDeliverylist(params).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          TodayDeliveryList: response.data.deliveryList,
          TodayDeliverySlotList: response.data.slotList,
          ShowMapView: true,
        });

        if (
          response.data.deliveryList !== null &&
          response.data.deliveryList != undefined &&
          response.data.deliveryList.length > 0
        ) {
          this.initMap();
        }
      } else {
        notification["error"]({
          message: labels.DELIVERIES,
          description: response.message
            ? response.message
            : labels.UNABLE_TO_GET_LIST_OF_DELIVERY_LIST_DATA,
        });
      }
    });
  };

  initMap = () => {
    var map;
    var bounds = new window.google.maps.LatLngBounds();
    var mapOptions = {
      mapTypeId: "roadmap",
    };

    this.setState({
      SelectedData: {},
    });

    const icon = {
      url: storemarkerimg,
      size: new window.google.maps.Size(50, 50),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(17, 34),
    };
    // Display a map on the web page
    map = new window.google.maps.Map(
      document.getElementById("map"),
      mapOptions
    );
    map.setTilt(50);

    //for pickup marker
    let selectedmarker = [];
    let PickupMarker = [];
    let DeliveryMarker = [];

    if (
      this.state.TodayPickupList != undefined &&
      this.state.TodayPickupList != null &&
      this.state.TodayPickupList.length > 0 &&
      this.state.checkBoxPickupValue != undefined &&
      this.state.checkBoxPickupValue != null &&
      this.state.checkBoxPickupValue.length > 0
    ) {
      PickupMarker = this.state.checkBoxPickupValue.map((slot) => {
        return this.state.TodayPickupList.filter((item) => item.slotID == slot);
      });
    }

    selectedmarker = PickupMarker;

    if (
      this.state.TodayDeliveryList != undefined &&
      this.state.TodayDeliveryList != null &&
      this.state.TodayDeliveryList.length > 0 &&
      this.state.checkBoxDeliveryValue != undefined &&
      this.state.checkBoxDeliveryValue != null &&
      this.state.checkBoxDeliveryValue.length > 0
    ) {
      DeliveryMarker = this.state.checkBoxDeliveryValue.map((slot) => {
        return this.state.TodayDeliveryList.filter(
          (item) => item.slotID == slot
        );
      });
    }

    // Merge PickupMarker with DeliveryMarker
    selectedmarker = selectedmarker.concat(DeliveryMarker);

    // Add multiple markers to map
    var infoWindow = new window.google.maps.InfoWindow(),
      marker,
      i;

    // Place pickup marker on the map
    if (
      selectedmarker != null &&
      selectedmarker != undefined &&
      selectedmarker.length > 0
    ) {
      selectedmarker.map((markers, index) => {
        for (i = 0; i < markers.length; i++) {
          var position = new window.google.maps.LatLng(
            markers[i].latitude,
            markers[i].longitude
          );
          // map.setCenter(position);
          // bounds.extend(position);

          marker = new window.google.maps.Marker({
            position: position,
            map: map,
            title: markers[i].pickupID
              ? markers[i].location
              : markers[i].address,
            label: {
              text: markers[i].pickupID
                ? `${markers[i].pickupID}`
                : markers[i].splitOrderNumber,
              color: this.state.TodayPickupSlotList.filter((e) => {
                return e.slotID == markers[i].slotID;
              })[0].colorName,
              // fontSize: "20px",
              // fontWeight: "bold"
            },
            icon: {
              path: markers[i].pickupID
                ? "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z"
                : "M8,0 L10,6 L16,6 L11,10 L13,16 L8,12 L3,16 L5,10 L0,6 L6,6 Z",
              // path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
              fillColor: this.state.TodayPickupSlotList.filter((e) => {
                return e.slotID == markers[i].slotID;
              })[0].colorName,
              fillOpacity: 1,
              strokeWeight: 1,
              // rotation: 0,
              scale: 1.2,
              anchor: new window.google.maps.Point(0, 20),
              labelOrigin: new window.google.maps.Point(0, 30),
            },
          });

          // this.setState({
          //   MarkerList: marker,
          // });

          // Add info window to marker
          var self = this;
          window.google.maps.event.addListener(
            marker,
            "click",
            (function (marker, i) {
              var tempobj = {
                iD: markers[i].pickupID
                  ? markers[i].pickupID
                  : markers[i].splitOrderNumber,
                customerName: markers[i].customerName,
                mobileNo: markers[i].mobileNo,
                address: markers[i].pickupID
                  ? markers[i].location
                  : markers[i].address,
              };

              return function () {
                self.setState({
                  SelectedData: tempobj,
                });
                infoWindow.setContent(markers[i].location);
                infoWindow.open(map, marker);
                navigator.geolocation.getCurrentPosition(function (position) {
                  window.open(
                    "https://www.google.com/maps/dir/?api=1&origin=" +
                      position.coords.latitude +
                      "," +
                      position.coords.longitude +
                      "&destination=" +
                      markers[i].latitude +
                      "," +
                      markers[i].longitude,
                    "_blank",
                    "noreferrer"
                  );
                });
              };
            })(marker, i)
          );

          // Center the map to fit all markers on the screen
          map.fitBounds(bounds);
        }
      });
    }

    //for Store Marker -- Start
    var infoWindow = new window.google.maps.InfoWindow(),
      storemarker,
      i;

    // console.log("TodayPickupList->", this.state.TodayPickupList);
    // console.log("TodayDeliveryList->", this.state.TodayDeliveryList);

    let Storemarkers =
      this.state.TodayPickupList != null &&
      this.state.TodayPickupList != undefined &&
      this.state.TodayPickupList.length > 0
        ? this.state.TodayPickupList
        : this.state.TodayDeliveryList;
    var Storeposition = new window.google.maps.LatLng(
      Storemarkers[0].storeLat,
      Storemarkers[0].storeLong
    );

    map.setCenter(Storeposition);
    bounds.extend(Storeposition);
    storemarker = new window.google.maps.Marker({
      position: Storeposition,
      map: map,
      icon,
      title: Storemarkers[0].storeAddress,
    });

    window.google.maps.event.addListener(
      storemarker,
      "click",
      (function (storemarker, i) {
        return function () {
          infoWindow.setContent(Storemarkers[0].storeAddress);
          infoWindow.open(map, storemarker);
        };
      })(storemarker, i)
    );

    map.fitBounds(bounds);
    // End for Store

    // Set zoom level
    var boundsListener = window.google.maps.event.addListener(
      map,
      "bounds_changed",
      function (event) {
        this.setZoom(13);
        window.google.maps.event.removeListener(boundsListener);
      }
    );
  };

  render() {
    return (
      <div>
        <PageHeader title={labels.VIEW_ON_MAP} />

        {(this.state.TodayPickupList != undefined &&
          this.state.TodayPickupList.length > 0) ||
        (this.state.TodayDeliveryList != undefined &&
          this.state.TodayDeliveryList.length > 0) ? (
          <div style={{ border: "2px solid black" }}>
            <Row gutter={24} style={{ margin: 0 }}>
              <Col
                span={24}
                style={{
                  borderBottom: "1px solid black",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    fontSize: 20,
                    fontStyle: "bold",
                  }}
                >
                  Pickup & Delivery list
                </div>
              </Col>
            </Row>
            <Row
              gutter={24}
              style={{ margin: 0, marginTop: 1, marginBottom: 1 }}
            >
              <Col span={5} style={{ borderRight: "1px solid black" }}>
                <Row>
                  <b> Store Name:</b>&nbsp;
                  {localStorage.getItem("Store/FactoryName")}
                </Row>
                <Row>
                  <b> Date: </b>&nbsp;
                  {moment().format("DD-MM-YYYY")}
                </Row>
                <Row>
                  <b> Total Pickups: </b>&nbsp;
                  {this.state.TodayPickupList != null &&
                  this.state.TodayPickupList != undefined
                    ? this.state.TodayPickupList.length
                    : 0}
                </Row>
                <Row>
                  <b> Total Deliveries: </b>&nbsp;
                  {this.state.TodayDeliveryList != null &&
                  this.state.TodayDeliveryList != undefined
                    ? this.state.TodayDeliveryList.length
                    : 0}
                </Row>
              </Col>
              <Col
                span={4}
                style={{ borderRight: "1px solid black", padding: 0 }}
              >
                <Row
                  style={{
                    height: "50%",
                    borderBottom: "1px solid black",
                  }}
                >
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Pickups
                  </Col>
                </Row>
                <Row style={{ height: "50%" }}>
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <b>Deliveries</b>
                  </Col>
                </Row>
              </Col>
              <Col span={15} style={{ padding: 0 }}>
              <Row
                  style={{
                    height: "50%",
                    paddingLeft: 10,
                  }}
                >
                  <Checkbox.Group
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                    onChange={(e) => {
                      this.setState({ checkBoxPickupValue: e }, () => {
                        this.initMap();
                      });
                    }}
                  >
                    {this.state.TodayPickupSlotList.map((item, index) => {
                      return (
                        <Checkbox
                          key={item.slotID}
                          value={item.slotID}
                          style={{
                            color: item.colorName,
                          }}
                        >
                          {item.slotName}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Row>
                <Row
                  style={{
                    height: "50%",
                    borderBottom: "1px solid black",
                    paddingLeft: 10,
                  }}
                >
                  <Checkbox.Group
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                    onChange={(e) => {
                      this.setState({ checkBoxDeliveryValue: e }, () => {
                        this.initMap();
                      });
                    }}
                  >
                    {this.state.TodayDeliverySlotList.map((item, index) => {
                      return (
                        <Checkbox
                          key={item.slotID}
                          value={item.slotID}
                          style={{
                            color: item.colorName,
                          }}
                        >
                          {item.slotName}
                        </Checkbox>
                      );
                    })}
                  </Checkbox.Group>
                </Row>
                
              </Col>
            </Row>
            <Row
              // className="mapview"
              gutter={24}
              style={{
                borderBottom: "1px solid black",
                borderTop: "1px solid black",
                margin: 0,
              }}
            >
              <Col span={2} style={{ borderRight: "1px solid black" }}>
                <b>PickupID</b>
                {this.state.TodayPickupList != null &&
                  this.state.TodayPickupList != undefined &&
                  this.state.TodayPickupList.length > 0 &&
                  this.state.TodayPickupList.map((e, index) => {
                    return (
                      <Row>
                        <Button
                          type="link"
                          onClick={() => {
                            this.setState(
                              {
                                SelectedData: {
                                  iD: e.pickupID,
                                  customerName: e.customerName,
                                  mobileNo: e.mobileNo,
                                  address: e.location,
                                },
                              }
                              // () => this.showme(index)
                            );
                          }}
                        >
                          <Text
                            style={{
                              color: this.state.TodayPickupSlotList.filter(
                                (item) => {
                                  return item.slotID == e.slotID;
                                }
                              )[0].colorName,
                            }}
                          >
                            {e.pickupID}
                          </Text>
                        </Button>
                        {/* <br /> */}
                      </Row>
                    );
                  })}
              </Col>
              <Col span={2} style={{ borderRight: "1px solid black" }}>
                <b>DeleveryID</b>
                {this.state.TodayDeliveryList != null &&
                  this.state.TodayDeliveryList != undefined &&
                  this.state.TodayDeliveryList.length > 0 &&
                  this.state.TodayDeliveryList.map((e, index) => {
                    return (
                      <div>
                        <Button
                          type="link"
                          onClick={() => {
                            this.setState(
                              {
                                SelectedData: {
                                  iD: e.splitOrderNumber,
                                  customerName: e.customerName,
                                  mobileNo: e.mobileNo,
                                  address: e.address,
                                },
                              }
                              // () => this.showme(index)
                            );
                          }}
                        >
                          <Text
                            style={{
                              color: this.state.TodayDeliverySlotList.filter(
                                (item) => {
                                  return item.slotID == e.slotID;
                                }
                              )[0].colorName,
                            }}
                          >
                            {e.splitOrderNumber}
                          </Text>
                        </Button>
                        <br />
                      </div>
                    );
                  })}
              </Col>
              <Col span={20} style={{ padding: 0 }}>
                <div style={{ width: "100%", height: 500 }} id="map"></div>
              </Col>
            </Row>
            <Row gutter={24} style={{ margin: 0 }}>
              <Col span={24}>
                <Row>
                  <b>Pickup ID/Order ID:</b>&nbsp;
                  {this.state.SelectedData.iD}
                </Row>
                <div>
                  <b> Customer Name:</b>&nbsp;
                  {this.state.SelectedData.customerName}
                </div>
                <div>
                  <b>Mobile No.:</b>&nbsp;
                  {this.state.SelectedData.mobileNo}
                </div>
                <div>
                  <b>Address:</b>&nbsp;
                  {this.state.SelectedData.address}
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: 15,
              fontSize: 15,
            }}
          >
            No Pickup/Delivery Available
          </div>
        )}
      </div>
    );
  }
}
