import { } from "./authservice";

export function authHeader() {
  // return authorization header with jwt token
  const currentUser = JSON.parse(localStorage.getItem("currentuser"));
  if (currentUser && currentUser.token) {
    return { Authorization: `Bearer ${currentUser.token}` };
  } else {
    return {};
  }
}

export function IsAuthenticated() {
  const currentUser = JSON.parse(localStorage.getItem("currentuser"));
  if (currentUser && currentUser.token) {
    return true;
  } else {
    return false;
  }
}
