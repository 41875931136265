import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  DatePicker,
  Tooltip,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import DeliveryChargeService from "../../../services/deliverychargeservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import {
  RegexDecimalAndRestrictFirstZero,
  RegexInteger,
  MessageNumbers,
} from "../../../shared/validator";

const { Search } = Input;
const { Option } = Select;
let todaysDate = Moment(new Date());

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.DeliveryChargeService = new DeliveryChargeService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.state = {
      modalLoading: false,
      deliveryChargeItems: [],
      deliveryChargesID: 0,
      minBillAmount: null,
      fixedCharges: null,
      perCharges: null,
      maxCharges: null,
      effectiveFrom: "",
      cgst: 0,
      sgst: 0,
      igst: 0,
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
      disableUpdateBtn: false,
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.DELIVERY_CHARGES
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    // {
    //   title: "DeliveryChargesID",
    //   dataIndex: "deliveryChargesID",
    //   key: "deliveryChargesID",
    // },
    {
      title: "Min Bill Amount",
      dataIndex: "minBillAmount",
      key: "minBillAmount",
      sorter: true,
    },
    {
      title: "Fixed Charges",
      dataIndex: "fixedCharges",
      key: "fixedCharges",
      sorter: true,
    },
    {
      title: "Percentage Charges",
      dataIndex: "perCharges",
      key: "perCharges",
      sorter: true,
    },
    {
      title: "Max Charges",
      dataIndex: "maxCharges",
      key: "maxCharges",
      sorter: true,
    },
    {
      title: "Effective From",
      dataIndex: "effectiveFrom",
      key: "effectiveFrom",
      sorter: true,
      render: (data) => Moment(data, "DD-MM-YYYY").format("DD-MM-YYYY"),
    },
    // {
    //   title: "CGST",
    //   dataIndex: "cgst",
    //   key: "cgst",
    //   sorter: true,
    // },
    // {
    //   title: "SGST",
    //   dataIndex: "sgst",
    //   key: "sgst",
    //   sorter: true,
    // },
    // {
    //   title: "IGST",
    //   dataIndex: "igst",
    //   key: "igst",
    //   sorter: true,
    // },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    deliveryChargesID: record.deliveryChargesID,
                    minBillAmount: record.minBillAmount,
                    fixedCharges: record.fixedCharges,
                    perCharges: record.perCharges,
                    maxCharges: record.maxCharges,
                    // cgst: record.cgst,
                    // sgst: record.sgst,
                    // igst: record.igst,
                    effectiveFrom: Moment(record.effectiveFrom, "DD/MM/YYYY"),
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true &&
          !Moment(record.effectiveFrom, "DD/MM/YYYY").isBefore(
            todaysDate,
            "date"
          ) ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() => {
                  this.setState({
                    showAddPopup: true,
                    deliveryChargesID: record.deliveryChargesID,
                    minBillAmount: record.minBillAmount,
                    fixedCharges: record.fixedCharges,
                    perCharges: record.perCharges,
                    maxCharges: record.maxCharges,
                    // cgst: record.cgst,
                    // sgst: record.sgst,
                    // igst: record.igst,
                    effectiveFrom: Moment(record.effectiveFrom, "DD/MM/YYYY"),
                  });
                  let effectiveDate = Moment(
                    record.effectiveFrom,
                    "DD/MM/YYYY"
                  );
                  if (effectiveDate.isBefore(todaysDate, "date")) {
                    this.setState({ disableUpdateBtn: true });
                    notification["error"]({
                      message: "Delivery Charges",
                      description: "Previous rates can't be updated !",
                    });
                  } else {
                    this.setState({ disableUpdateBtn: false });
                  }
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.deliveryChargesID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      deliveryChargesID: this.state.deliveryChargesID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      deliveryChargesID: this.state.deliveryChargesID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      deliveryChargesID: this.state.deliveryChargesID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.DeliveryChargeService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            deliveryChargesID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          deliveryChargeItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Delivery Charges",
          description: items.message
            ? items.message
            : "Unable to get list of Delivery Charges data",
        });
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (deliveryChargesID) => {
    if (
      deliveryChargesID === "" ||
      deliveryChargesID === null ||
      deliveryChargesID === undefined
    ) {
      openNotificationWithIcon(
        "error",
        "Delivery Charges",
        "Please Select Atleast One Row"
      );
    } else {
      this.setState({ loading: true });
      this.DeliveryChargeService.removeItems(deliveryChargesID).then(
        (items) => {
          if (items.result === "Success") {
            openNotificationWithIcon(
              "success",
              "Delivery Charges",
              items.message
            );
            const { pagination } = this.state;
            this.fetchItems({
              deliveryChargesID: parseInt(this.state.deliveryChargesID),
              pageIndex: pagination.current,
              pageSize: pagination.pageSize,
              pagination,
            });
          } else {
            openNotificationWithIcon(
              "error",
              "Delivery Charges",
              items.message
            );
          }
          this.setState({ loading: false });
        }
      );
    }
  };

  //function for adding data to back-end by server cal
  handlePopupAdd = (event) => {
    var postData = {
      deliveryChargesID: this.state.deliveryChargesID,
      minBillAmount: parseInt(this.state.minBillAmount),
      fixedCharges: parseFloat(this.state.fixedCharges),
      perCharges: parseFloat(this.state.perCharges),
      maxCharges: parseInt(this.state.maxCharges),
      cgst: 0, //parseFloat(this.state.cgst),
      sgst: 0, //parseFloat(this.state.sgst),
      igst: 0, //parseFloat(this.state.igst),
      effectiveFrom: Moment(this.state.effectiveFrom).format("YYYY/MM/DD"),
    };
    this.setState({ modalLoading: true });
    this.DeliveryChargeService.insertUpdateItems(postData).then((items) => {
      if (items.result === "Success") {
        openNotificationWithIcon("success", "Delivery Charges", items.message);
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          deliveryChargesID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        openNotificationWithIcon("error", "Delivery Charges", items.message);
      }
      this.setState({ modalLoading: false });
    });
  };

  handleInputOnChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      deliveryChargesID: 0,
      minBillAmount: null,
      fixedCharges: null,
      perCharges: null,
      maxCharges: null,
      cgst: 0,
      sgst: 0,
      igst: 0,
      effectiveFrom: "",
      isEnabled: false,
      isVisible: false,
      isEditbtndisable: false,
      disableUpdateBtn: false,
    });
  };

  render() {
    const {
      minBillAmount,
      fixedCharges,
      perCharges,
      maxCharges,
      cgst,
      sgst,
      igst,
      effectiveFrom,
      pagination,
      loading,
    } = this.state;
    // let RegexDecimal= /^[0-9]+([.][0-9]+)?$/g;
    const RegexDecimalAndRestrict = /^[0-9]\d*(.\d*)?$/;
    const deliveryChargeItems = this.state.deliveryChargeItems;
    const listItems = deliveryChargeItems.map((item, index) => ({
      key: item.deliveryChargesID,
      deliveryChargesID: item.deliveryChargesID,
      minBillAmount: item.minBillAmount,
      fixedCharges: item.fixedCharges,
      perCharges: item.perCharges,
      maxCharges: item.maxCharges,
      cgst: item.cgst,
      sgst: item.sgst,
      igst: item.igst,
      effectiveFrom: Moment(item.effectiveFrom.split(" ")[0]).format(
        "DD-MM-YYYY"
      ),
      isActive: item.isActive.toString(),
      createdByName: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD/MM/YYYY  hh:mm:ss a"),
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title="Delivery Charges"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                Add Charges
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            deliveryChargeItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>
        <Modal
          title={
            this.state.deliveryChargesID === 0
              ? "Add Delivery Charges"
              : this.state.isEnabled
              ? "View Delivery Charges"
              : "Edit Delivery Charges"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                minBillAmount,
                fixedCharges,
                perCharges,
                maxCharges,
                cgst,
                sgst,
                igst,
                effectiveFrom,
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Min Bill Amount "
                    name={"minBillAmount"}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Min Bill Amount",
                      },
                      {
                        pattern: RegexDecimalAndRestrict,
                        message: "Please Enter Digits Only",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Min Bill Amount "
                      name="minBillAmount"
                      maxLength={21}
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Fixed Charges "
                    name={"fixedCharges"}
                    validateFirst
                    dependencies={["perCharges"]}
                    rules={[
                      { required: true, message: "Please Enter Fixed Charges" },
                      {
                        pattern: RegexDecimalAndRestrict,
                        message: "Please Enter Digits Only",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            parseFloat(value) > 0 ||
                            parseFloat(getFieldValue("perCharges")) > 0
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Either Fixed Charges or Percentage Charges must be greater than 0"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter Fix Charges "
                      name="fixedCharges"
                      maxLength={21}
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Percentage Charges "
                    name={"perCharges"}
                    validateFirst
                    dependencies={["fixedCharges"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Percentage Charges",
                      },
                      {
                        pattern: RegexDecimalAndRestrict,
                        message: "Please Enter Digits Only",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            parseFloat(value) > 0 ||
                            parseFloat(getFieldValue("fixedCharges")) > 0
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Either Fixed Charges or Percentage Charges must be greater than 0"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter Percentage Charges "
                      name="perCharges"
                      maxLength={21}
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Max Charges "
                    name={"maxCharges"}
                    rules={[
                      { required: true, message: "Please Enter Max Charges" },
                      {
                        pattern: RegexDecimalAndRestrictFirstZero,
                        message: MessageNumbers,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Max Charges "
                      name="maxCharges"
                      maxLength={21}
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={8}>
                <Form.Item
                  label="CGST"
                  name={"cgst"}
                  rules={[
                    { required: true, message: "Please Enter CGST " },
                    {
                      pattern: RegexDecimal,
                      message: "Please Enter Valid Number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter CGST "
                    name="cgst"
                    disabled={this.state.isEnabled}
                    onChange={this.handleInputOnChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  label="SGST"
                  name={"sgst"}
                  rules={[
                    { required: true, message: "Please Enter SGST" },
                    {
                      pattern: RegexDecimal,
                      message: "Please Enter Valid Number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter SGST "
                    name="sgst"
                    disabled={this.state.isEnabled}
                    onChange={this.handleInputOnChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  label="IGST"
                  name={"igst"}
                  rules={[
                    { required: true, message: "Please Enter IGST " },
                    {
                      pattern: RegexDecimal,
                      message: "Please Enter Valid Number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter IGST "
                    name="igst"
                    disabled={this.state.isEnabled}
                    onChange={this.handleInputOnChange}
                  />
                </Form.Item>
              </Col> */}
                <Col xs={24}>
                  <Form.Item
                    label="Effective From"
                    name={"effectiveFrom"}
                    rules={[{ required: true, message: "Please Select Date " }]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      format={"DD-MM-YYYY"}
                      onChange={(date) =>
                        this.handleDateChange(date, "effectiveFrom")
                      }
                      disabled={this.state.isEnabled}
                      disabledDate={(currentDate) =>
                        currentDate.isBefore(Moment(), "day") ? true : false
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          htmlType="submit"
                          variant="purple"
                          disabled={this.state.disableUpdateBtn}
                        >
                          {this.state.deliveryChargesID === 0
                            ? "Add"
                            : "Update"}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
