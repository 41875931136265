import React from "react";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Form,
  Space,
  Table,
  Tooltip,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../components/Common/Button/button";
import Filters from "../../components/Common/Filters/filters";
import TableActions from "../../components/Common/TableActions/tableactions";
import styles from "../TimeCard/timecardlistscreen.module.css";
import CreateTimeCard from "./createtimecard.screen";
import TimeCardService from "../../services/timecardservice";
//import  Moment from 'moment'
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../routes";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import Common from "../../services/common";

const { Search } = Input;
// const { Option } = Select;
/**
 * Time Card listing screen.
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 1 June 2020
 *
 */
class TimeCardList extends React.Component {
  /**
   * State Varible to Hold Active Step Index starting from 0
   */
  constructor(props) {
    super(props);
    this.timecardservice = new TimeCardService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();
    this.state = {
      timeCardID: 0,
      tableLoading: true,
      isEditmode: false,
      showfilterBox: false,
      isViewmode: false,
      disabled: false,
      showAddPopup: false,
      isAdd: false,
      timeCardList: [],
      timeCardMasterData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      loading: false,
      shouldRefresh: false,
      search: null,
    };
    this.getTimeCardMasterByfilter = this.getTimeCardMasterByfilter.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.SERVICE_TIME
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ tableLoading: false });
    this.getTimeCardMasterDetails({
      timeCardID: this.state.timeCardID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      // ...filters,
    });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.getTimeCardMasterDetails({
      timeCardName: "",
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? null
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  getTimeCardMasterDetails(params = {}) {
    this.setState({ tableLoading: true });
    params = { ...params, CommonSearch: this.state.search };
    this.timecardservice.retrieveTimeCardMaster(params).then((timeCardList) => {
      if (timeCardList.statusCode === 200) {
        // if (timeCardList.length !== 0) {
        this.setState({
          timeCardList: timeCardList.data,
          pagination: {
            ...params.pagination,
            total:
              timeCardList.data.length !== 0
                ? timeCardList.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Time Card Master",
          description: timeCardList.message
            ? this.common.Message(timeCardList.message)
            : "Unable to get list of time card data",
        });
      }
      this.setState({ tableLoading: false });
    });
  }

  getTimeCardMasterByfilter(pagination) {
    const params = {
      timeCardName: document.getElementById("timeCardName").value,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    };

    this.ratecardservice.retrieveTimeCardMaster(params).then((timeCardList) => {
      this.setState({
        timeCardList: timeCardList.data,
      });
      if (timeCardList.statusCode === 200) {
        // if (timeCardList.data.length > 0) {
        this.setState({
          pagination: {
            ...params.pagination,
            total: timeCardList.data[0].totalRecords,
          },
        });
      } else {
        this.setState({
          pagination: {
            ...params.pagination,
            total: 0,
          },
        });
      }
    });
  }

  handleSearch = (event) => {
    this.setState({ search: event.target.value });
    this.getTimeCardMasterDetails({
      CommonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  bringback = () => {
    this.setState({ isAdd: false });
  };

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: "Time Card Name",
      dataIndex: "timeCardName",
      key: "timeCardName",
      sorter: true,
    },
    {
      title: "Express TAT",
      dataIndex: "expressTAT",
      key: "expressTAT",
      sorter: true,
    },
    {
      title: "Semi Express TAT",
      dataIndex: "semiExpressTAT",
      key: "semiExpressTAT",
      sorter: true,
    },
    {
      title: "Is Corporate",
      dataIndex: "isCorporate",
      key: "isCorporate",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Link
                to={{
                  pathname: [ROUTES.VIEW_TIMECARD, record.timeCardID].join("/"),
                  state: { timeCardID: record.timeCardID },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Link
                to={{
                  pathname: [ROUTES.EDIT_TIMECARD, record.timeCardID].join("/"),
                  state: { timeCardID: record.timeCardID },
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  /**
   * Variables to be used in table for selection property
   * kindly go through
   * https://ant.design/components/table/
   * https://ant.design/components/table/#components-table-demo-row-selection-and-operation
   * https://ant.design/components/table/#components-table-demo-row-selection
   */
  // selectionType = "checkbox";
  // rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(
  //       `selectedRowKeys: ${selectedRowKeys}`,
  //       "selectedRows: ",
  //       selectedRows
  //     );
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.firstName === "Disabled User", // Column configuration not to be checked
  //     name: record.firstName,
  //   }),
  // };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  render() {
    const { pagination } = this.state;
    const timeCardList = this.state.timeCardList;
    const timeCardLists = timeCardList.map((timeCardList) => ({
      timeCardID: timeCardList.timeCardID,
      timeCardName: timeCardList.timeCardName,
      expressTAT: timeCardList.expressTAT,
      semiExpressTAT: timeCardList.semiExpressTAT,
      isCorporate: timeCardList.isCorporate === true ? "Yes" : "No",
    }));
    return this.state.isAdd ||
      this.state.isEditmode ||
      this.state.isViewmode ? (
      <div key={this.state.shouldRefresh}>
        {/* <CreateStore backFunction={this.bringback} storeMasterData={storeMasterData} isEditmode={isEditmode} isViewmode={isViewmode} disabled={disabled}/> */}
        <CreateTimeCard backFunction={this.bringback} />
      </div>
    ) : (
      <React.Fragment>
        <PageHeader
          title="Time Card Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
              key="1"
            />,
            // this.state.userRights.length > 0 && this.state.userRights[0].isAdd == true ?
            // <Button
            //   variant="purple"
            //   onClick={() => this.props.history.push(ROUTES.ADD_TIMECARD)}
            //   key="2"
            //   //onClick={() => this.setState({ showAddPopup: true,isAdd:true })}
            // >
            //   Add Time Card
            // </Button>
            // :null,
            <Button
              variant="purple"
              onClick={() =>
                this.props.history.push({
                  pathname: ROUTES.ADD_TIMECARD,
                  state: { timeCardID: this.state.timeCardID },
                })
              }
              key="2"
              //onClick={() => this.setState({ showAddPopup: true,isAdd:true })}
            >
              Add Time Card
            </Button>,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />

        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item>
                  <Input placeholder="Enter Time Card Name" id="timeCardName" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  variant="whitePink"
                  shape="round"
                  size="large"
                  onClick={this.getTimeCardMasterByfilter}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>
        {/**
         * Please go through below documention for <Table> Component
         * https://ant.design/components/table/
         * https://ant.design/components/table/#API
         *
         */}
        <Table
          size="small"
          // rowSelection={{
          //   type: this.selectionType,
          //   ...this.rowSelection,
          // }}
          columns={this.columns}
          dataSource={timeCardLists}
          pagination={pagination}
          loading={this.state.tableLoading}
          onChange={this.handleTableChange}
        />

        <TableActions isPagination={true}></TableActions>
      </React.Fragment>
    );
  }
}
export default withRouter(TimeCardList);
