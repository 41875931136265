import React from "react";
import ReadyForDeliveryOrders from "../../../components/StoreModule/ReadyForDeliveryOrders/readyfordeliverysplitorders.store.component";

export default class ReadyForDeliveryOrdersScreen extends React.Component {
  render() {
    return (
      <div>
        <ReadyForDeliveryOrders />
      </div>
    );
  }
}
