import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  PageHeader,
  Typography,
  Space,
  notification,
  Spin,
} from "antd";
import Button from "../../../Common/Button/button";
import styles from "./createuser.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import UserService from "../../../../services/userservice";
import UserRole from "./userrole.component";

const { Option } = Select;
const { Title } = Typography;
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

/**
 * A form as a part of create Role - Add User Role
 *
 * @props backFunction: a function to modify parent state to go back to tabular view
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 3 June 2020
 *
 * @module User
 */
export default class RoleAassignment extends React.Component {
  /**
   * A function to handle form submit
   * please check below link for more details
   * https://ant.design/components/form/#API
   *
   */
  constructor(props) {
    super(props);
    this.userservice = new UserService();
    this.state = {
      fullLoading: false,
      assignedRoles: [],
      userrolemappingID: 0,
      isAdd: false,
      isView: false,
      disabled: false,
      roleID: "",
      roleName: "",
      role: [],
      roleList: [],
      showComponent: false,
      currentComponent: true,
      roleAssignmentFormValues: {},
    };
    this.handleSaveUpdateUserRole = this.handleSaveUpdateUserRole.bind(this);
  }
  componentDidMount() {
    this.getRole();
    this.setState({
      userrolemappingID: this.props.userrolemappingID,
      roleID: this.props.roleID,
      roleName: this.props.roleName,
      isView: this.props.isView,
      disabled: this.props.disabled,
    });
    if (this.props.roleList !== null) {
      this.setState({ roleList: this.props.roleList });
    }
  }

  getRole = () => {
    this.userservice.retrieveRole().then((role) => {
      this.setState({ role: role.data });
    });
  };

  handleChange = (event) => {
    this.setState({ roleID: parseInt(event) });
    this.setState({
      roleName: this.state.role.filter((x) => x.value === event)[0].text,
    });
  };

  handleSaveUpdateUserRole = () => {
    const postSave = {
      userRoleMappingID: this.state.userrolemappingID,
      userID: this.props.userID,
      roleID: parseInt(this.state.roleAssignmentFormValues.roleID),
    };
    this.setState({ fullLoading: true });
    this.userservice.saveUpdateUserRoleDetails(postSave).then((response) => {
      if (response.result === "Success") {
        openNotificationWithIcon("success", "Users", response.message);
        this.setState({ showComponent: true });
      } else {
        openNotificationWithIcon("error", "Users", response.message);
      }
      this.setState({ fullLoading: false });
    });
    // if(this.state.roleID!=0){
    //   const postSave={
    //     "userRoleMappingID": this.state.userrolemappingID,
    //     "userID": this.props.userID,
    //     "roleID": this.state.roleID
    //   }
    //   this.userservice.saveUpdateUserRoleDetails(postSave).then(response => {
    //     console.log(response);
    //     if(response.result=="Success")
    //     {
    //       openNotificationWithIcon('success', 'Role Assignment', response.message)
    //       this.setState({showComponent:true});
    //     }
    //     else {
    //       openNotificationWithIcon('error', 'Role Assignment', response.message)
    //     }
    //   });
    // }else{
    //   openNotificationWithIcon('error', 'Role Assignment', "Please select Role")
    // }
  };

  roleAssignmentForm = React.createRef();
  roleAssignmentFormValidated = false;

  handleSubmit = () => {
    const roleAssignmentValidator = this.roleAssignmentForm.validateFields();

    Promise.allSettled([roleAssignmentValidator]).then((responses) => {
      if (responses[0].status === "rejected") {
      } else if (responses[0].status === "fulfilled") {
        this.setState(
          {
            roleAssignmentFormValues: responses[0].value,
          },
          () => {
            this.handleSaveUpdateUserRole();
          }
        );
      }
    });
  };

  render() {
    const { disabled } = this.state;
    return (
      <Spin spinning={this.state.fullLoading}>
        {this.state.showComponent ? (
          <UserRole
            backFunction={this.bringback}
            userID={this.props.userID}
            setAssignedRoles={(roles) => {
              this.setState({ assignedRoles: roles });
            }}
          />
        ) : (
          <Form
            layout="vertical"
            initialValues={this.props.values}
            ref={(roleAssignmentForm) =>
              (this.roleAssignmentForm = roleAssignmentForm)
            }
            className={styles.AddKYCForm}
          >
            {/**
             * Please go through below documentation befor modifying
             * https://ant.design/components/page-header/
             * https://ant.design/components/page-header/#API
             */}
            <PageHeader
              title={
                <Title level={4} className={styles.UserKYCTitle}>
                  {this.state.userrolemappingID === 0
                    ? "Add Role"
                    : this.state.isView
                    ? "View Role"
                    : "Edit Role"}
                </Title>
              }
              backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
              onBack={this.props.backFunction}
              className={styles.AddKYCFormHeader}
            />
            <Row gutter={30}>
              <Col xs={8}>
                <Form.Item
                  label="Role"
                  name="roleID"
                  rules={[{ required: true, message: "Please select Role" }]}
                >
                  <Select placeholder="Select Role" disabled={disabled}>
                    {/* {this.state.role.map((role) => <Option key={role.value} value={role.value}>{role.text}</Option>)} */}
                    {this.state.role.map((role) => {
                      return role.value !== "-1" &&
                        !this.props.assignedList.includes(
                          parseInt(role.value)
                        ) ? (
                        <Option value={role.value} key={role.value}>
                          {role.text}
                        </Option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className={styles.ActionsRow}>
              <Col xs={24} className={styles.ButtonContainer}>
                <Space size="middle">
                  <Button
                    variant="transparentGreyOutline"
                    className={styles.CancelButton}
                    shape="round"
                    size="large"
                    onClick={this.props.backFunction}
                  >
                    Cancel
                  </Button>
                  {!this.state.isView ? (
                    <Button
                      htmlType="submit"
                      variant="purple"
                      shape="round"
                      size="large"
                      //onClick={(event) => this.handleSaveUpdateUserRole(event,this.state.roleList)}
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                  ) : null}
                  {this.state.showComponent ? (
                    <UserRole data={this.state.roleList} />
                  ) : null}
                </Space>
              </Col>
            </Row>
          </Form>
        )}
      </Spin>
    );
  }
}
