import React from "react";
import styles from "./createratecardscreen.module.css";
import RateCardMaster from "../../components/RateCard/CreateRateCard/ratecardmaster.component";
import RateCardDetail from "../../components/RateCard/CreateRateCard/ratecarddetail.component";
//import StoreRateCardAssignment from "../../components/RateCard/CreateRateCard/storeratecardassignment.component";
import StoreAssignment from "../../components/RateCard/CreateRateCard/storeassignment.component";
//import CorporateRateCardAssignment from "../../components/RateCard/CreateRateCard/corporateratecardassignment.component";
import CorporateAssignment from "../../components/RateCard/CreateRateCard/corporateassignment.component";
import RateCardList from "./ratecardlist.screen";
import { PageHeader, Steps, notification, Form, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import RateCardService from "../../services/ratecardservice";
//import Moment from 'moment'
import { withRouter } from "react-router-dom";
import ROUTES from "../../routes";

const { Step } = Steps;

// const openNotificationWithIcon = (type, title, message) => {
//   notification[type]({
//       message: title,
//       description:
//           message
//   });
// };
/**
 * User Creation Screen with steps and form (Master - Form).
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 1 June 2020
 *
 */
class CreateRateCard extends React.Component {
  /**
   * State Varible to Hold Active Step Index starting from 0
   */
  constructor(props) {
    super(props);
    this.ratecardservice = new RateCardService();
    this.state = {
      detailsLoading: false,
      isAddRatecard: false,
      mode: "add",
      isViewmode: false,
      fullLoading: false,
      activeStep: 0,
      stepsDirection: "vertical",
      stepsSize: "default",
      rateCardID: 0,
      rateAssignStoreID: 0,
      rateAssignCorporateID: 0,
      rateCardMasterFormValues: {},
      storeRateCardAssignmentFormValues: {},
      corporateRateCardAssignmentFormValues: {},
      isCorporate: "",
    };
  }

  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "mini" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };
  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    if (
      [ROUTES.EDIT_RATECARD, ":rateCardID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });
      this.getRateCard(this.props.location.state.rateCardID);
    } else if (
      [ROUTES.VIEW_RATECARD, ":rateCardID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.setState({ isViewmode: true });
      this.getRateCard(this.props.location.state.rateCardID);
    } else if (
      [ROUTES.VIEW_RATESTOREASSIGNMENT, ":rateCardID"].join("/") ===
      this.props.match.path
    ) {
      this.getRateCard(this.props.location.state.rateCardID);
      this.setState({ fullLoading: true });
      this.setState({ mode: "storeassignment" });
      this.setState({ activeStep: 2 });
    } else if (
      [ROUTES.VIEW_RATECORPORATEASSIGNMENT, ":rateCardID"].join("/") ===
      this.props.match.path
    ) {
      this.getRateCard(this.props.location.state.rateCardID);
      this.setState({ fullLoading: true });
      this.setState({ mode: "corporateassignment" });
      this.setState({ activeStep: 2 });
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
  }

  getRateCard = (id) => {
    this.setState({ fullLoading: true });
    this.ratecardservice.getRateCardDetailByRateCardID(id).then((response) => {
      if (
        response.statusCode === 200 &&
        response.data.rateCardID === parseInt(id)
      ) {
        this.setState({ rateCardID: response.data.rateCardID });
        this.setState({
          rateCardMasterFormValues: {
            rateCardName: response.data.rateCardName,
            isCorporate: response.data.isCorporate,
          },
        });
        if (response.data.isCorporate) {
          this.setState({ isCorporate: "Yes" });
        } else {
          this.setState({ isCorporate: "No" });
        }

        this.setState({ fullLoading: false });
        const prevStep = this.state.activeStep;
        this.setState({ activeStep: -1 });
        this.setState({ activeStep: prevStep });
      } else {
        notification["error"]({
          message: "Rate Card",
          description: "Unable to get rate card data",
          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
    });
  };

  /**
   * General Tab Form Submit Handler
   * Please go through https://ant.design/components/form/#API for more information
   *
   */

  rateCardMasterForm = React.createRef();
  storeRateCardAssignmentForm = React.createRef();
  corporateRateCardAssignmentForm = React.createRef();

  rateCardMasterFormValidated = false;
  storeRateCardAssignmentFormValidated = false;
  corporateRateCardAssignmentFormValidated = false;

  handleRateCardMasterSubmit = () => {
    const rateCardMasterValidator = this.rateCardMasterForm.current.validateFields();

    Promise.allSettled([rateCardMasterValidator]).then((responses) => {
      if (responses[0].status === "rejected") {
        this.setState({ activeStep: 0 });
      } else if (responses[0].status === "fulfilled") {
        this.setState(
          {
            rateCardMasterFormValues: responses[0].value,
          },
          () => {
            this.handleSaveUpdatRateCardMaster();
          }
        );
      }
    });
  };

  handleSaveUpdatRateCardMaster = () => {
    const postSave = {
      rateCardID: this.state.rateCardID,
      rateCardName: this.state.rateCardMasterFormValues.rateCardName,
      isCorporate: this.state.rateCardMasterFormValues.isCorporate,
    };
    this.setState({ fullLoading: true });
    this.ratecardservice.saveUpdateRateCardMaster(postSave).then((response) => {
      if (response.statusCode === 200) {
        this.setState({ rateCardID: response.data.rateCardID });
        notification["success"]({
          message: "Rate Card",
          description: response.message,
          duration: 1.5,
          onClose: () => {
            if (!this.state.rateCardMasterFormValues.isCorporate) {
              this.setState({ isCorporate: "No" });
              this.setState({ activeStep: 1 });
              // this.props.history.push(
              //   [ROUTES.VIEW_RATESTOREASSIGNMENT, this.state.rateCardID].join("/")
              // );
            } else {
              this.setState({ isCorporate: "Yes" });
              this.setState({ activeStep: 1 });
              // this.props.history.push(
              //   [ROUTES.VIEW_RATECORPORATEASSIGNMENT, this.state.rateCardID].join("/")
              // );
            }
          },
        });
      } else {
        notification["error"]({
          message: "Rate Card",
          description: response.message,
        });
      }
      this.setState({ fullLoading: false });
    });
  };

  handleStoreRateCardAssignmentSubmit = () => {
    const storeRateCardAssignmentValidator = this.storeRateCardAssignmentForm.current.validateFields();

    Promise.allSettled([storeRateCardAssignmentValidator]).then((responses) => {
      if (responses[0].status === "rejected") {
        this.setState({ activeStep: 2 });
      } else if (responses[0].status === "fulfilled") {
        this.setState(
          {
            storeRateCardAssignmentFormValues: responses[0].value,
          },
          () => {
            this.handleSaveUpdatStoreRateCardAssignmentMaster();
          }
        );
      }
    });
  };

  handleSaveUpdatStoreRateCardAssignmentMaster = () => {
    const postSave = {
      rateCardID: this.state.rateCardID,
      storeID: parseInt(this.state.storeRateCardAssignmentFormValues.storeID),
      effectiveFrom: this.state.storeRateCardAssignmentFormValues.effectiveFrom.format(
        "yyyy-MM-DD"
      ),
      effectiveTo: this.state.storeRateCardAssignmentFormValues.effectiveTo.format(
        "yyyy-MM-DD"
      ),
    };
    this.ratecardservice
      .SaveUpdateAssignRateCardToStores(postSave)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Rate Card",
            description: response.message,
            duration: 1.5,
            // onClose: () => {
            //   this.props.history.goBack();
            // },
          });
          this.setState({ activeStep: 3 });
        } else {
          notification["error"]({
            message: "Rate Card",
            description: response.message,
          });
        }
      });
  };

  handleCorporateRateCardAssignmentSubmit = () => {
    const corporateRateCardAssignmentValidator = this.corporateRateCardAssignmentForm.current.validateFields();

    Promise.allSettled([corporateRateCardAssignmentValidator]).then(
      (responses) => {
        if (responses[0].status === "rejected") {
          this.setState({ activeStep: 3 });
        } else if (responses[0].status === "fulfilled") {
          this.setState(
            {
              corporateRateCardAssignmentFormValues: responses[0].value,
            },
            () => {
              this.handleSaveUpdatCorporateRateCardAssignmentMaster();
            }
          );
        }
      }
    );
  };

  handleSaveUpdatCorporateRateCardAssignmentMaster = () => {
    const postSave = {
      rateCardID: this.state.rateCardID,
      corporateID: parseInt(
        this.state.corporateRateCardAssignmentFormValues.corporateID
      ),
      effectiveFrom: this.state.corporateRateCardAssignmentFormValues.effectiveFrom.format(
        "yyyy-MM-DD"
      ),
      effectiveTo: this.state.corporateRateCardAssignmentFormValues.effectiveTo.format(
        "yyyy-MM-DD"
      ),
    };
    this.ratecardservice
      .SaveUpdateAssignRateCardToCorporate(postSave)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Rate Card",
            description: response.message,
            duration: 1.5,
            // onClose: () => {
            //   this.props.history.goBack();
            // },
          });
        } else {
          notification["error"]({
            message: "Rate Card",
            description: response.message,
          });
        }
      });
  };

  render() {
    //const{data}=this.state;

    //const{storeMasterData,isEditmode,isViewmode,disabled}=this.props;
    return this.state.isAddRatecard ? (
      <RateCardList backFunction={this.bringback} />
    ) : (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading || this.state.detailsLoading}>
          {/**
           * Page Header Component from Ant Design
           * Please check below link for more options
           * https://ant.design/components/page-header/
           * https://ant.design/components/page-header/#API
           *
           */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? "Edit"
                : this.state.mode === "view"
                ? "View"
                : "Add",
              "Rate Card",
            ].join(" ")}
          />

          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              {/**
               * Steps Component from Ant Design
               * Please check below link for more options
               * https://ant.design/components/steps/
               * https://ant.design/components/steps/#API
               *
               */}
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => this.setState({ activeStep: current })}
              >
                <Step title="Rate Card" description="Rate Card Information" />
                <Step
                  title="Rate Card Detail"
                  description="Detail Information"
                />
                {this.state.isCorporate === "No" ? (
                  <Step
                    title="Store Assignment"
                    description="Rate Card Assignment"
                  />
                ) : null}
                {this.state.isCorporate === "Yes" ? (
                  <Step
                    title="Corporate Assignment"
                    description="Rate Card Assignment"
                  />
                ) : null}
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider
                onFormFinish={(name, info) => {
                  if (name === "RateCardMaster") {
                    this.handleRateCardMasterSubmit(info.values);
                  }
                }}
              >
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading}>
                  <React.Fragment>
                    <RateCardMaster
                      values={this.state.rateCardMasterFormValues}
                      isActive={this.state.activeStep === 0}
                      instance={this.rateCardMasterForm}
                      backFunction={() => this.props.history.goBack()}
                      mode={this.state.mode}
                    />

                    <RateCardDetail
                      isActive={this.state.activeStep === 1}
                      key={this.state.rateCardID}
                      rateCardID={this.state.rateCardID}
                      backFunction={() => this.props.history.goBack()}
                      toggleLoading={(value) =>
                        this.setState({ detailsLoading: value })
                      }
                      //   values={this.state.contactFormValues}
                      //   backFunction={() =>
                      //     this.setState({ activeStep: this.state.activeStep - 1 })
                      //   }
                      //   nextFunction={this.handleSubmit}
                      //   isActive={this.state.activeStep === 1}
                      //   instance={this.contactForm}
                      //   mode={this.state.mode}
                    />
                    {this.state.activeStep === 2 &&
                    this.state.isCorporate === "No" &&
                    this.state.rateCardID !== 0 ? (
                      <StoreAssignment
                        key={this.state.rateCardID}
                        mode={this.state.mode}
                        rateCardID={this.state.rateCardID}
                      />
                    ) : null}
                    {this.state.activeStep === 2 &&
                    this.state.isCorporate === "Yes" &&
                    this.state.rateCardID !== 0 ? (
                      <CorporateAssignment
                        mode={this.state.mode}
                        key={this.state.rateCardID}
                        rateCardID={this.state.rateCardID}
                      />
                    ) : null}
                    {/* {this.state.activeStep === 2 ?  
                     <StoreRateCardAssignment 
                      isActive={this.state.activeStep === 2}
                      nextFunction={this.handleStoreRateCardAssignmentSubmit}
                      instance={this.storeRateCardAssignmentForm}
                      backFunction={() => this.props.history.goBack()}
                      />  : null}  */}

                    {/* {this.state.activeStep === 3 ? 
                    <CorporateRateCardAssignment 
                        isActive={this.state.activeStep === 3}
                        nextFunction={this.handleCorporateRateCardAssignmentSubmit}
                        instance={this.corporateRateCardAssignmentForm}
                        backFunction={() => this.props.history.goBack()}
                        />  : null}  */}
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(CreateRateCard);
