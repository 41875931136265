class Configuration {
  //Local Server
  
  // BASE_WEBAPI_URL="https://120.138.9.40/";
  // BASE_WEBAPI_URL = "https://localhost:44364/"; //new API 
  // BASE_FILES_URL = "http://10.37.57.173:9000/LaundrokartFiles/";
  // BASE_REPORTBUILDER_URL = "http://10.37.57.173:9000/rbui/";



  // BASE_WEBAPI_URL = "https://localhost:44378/";
  // // BASE_WEBAPI_URL = "http://10.37.55.123/LaundrokartApi/";
  // // http://10.37.55.123/LaundrokartApi/swagger/index.html

  // BASE_REPORTBUILDER_URL = "http://localhost:9000/rbui/";

  // BASE_WEBAPI_URL ="http://10.37.55.43/LaundrokartApi/api/";
                  

  // Dev Server (Hosted in Parth Jotangiya's machine)
  // BASE_WEBAPI_URL = "http://10.37.57.173:9000/LaundrokartAPI/";
  // BASE_FILES_URL = "http://10.37.57.173:9000/LaundrokartFiles/";
  // BASE_REPORTBUILDER_URL = "http://localhost:9000/rbui/";


  // Dev Server (Hosted in Parth Jotangiya's machine)
  // BASE_WEBAPI_URL = "http://40.117.61.180/LKDevApi/";
  // BASE_FILES_URL = "http://40.117.61.180/LKDevFiles/";
  // BASE_REPORTBUILDER_URL = "http://localhost:9000/rbui/";

  //local lndk_live
  // BASE_WEBAPI_URL = "http://10.37.57.173/lndkLiveLKApi/";
  // BASE_FILES_URL = "http://120.138.9.40/LaundroKartFiles/";
  // BASE_REPORTBUILDER_URL = "http://120.138.9.40:5003/";

  // Staging Server (UAT)
  //  BASE_WEBAPI_URL = "http://120.138.9.40/LaundrokartApi/";
  //  BASE_FILES_URL = "http://120.138.9.40/LaundroKartFiles/";
  //  BASE_REPORTBUILDER_URL = "http://120.138.9.40:5003/";
  

  //Staging server Other - Working
  // BASE_WEBAPI_URL = "http://120.138.9.40:9000/LaundrokartAPI/";
  // BASE_FILES_URL = "http://120.138.9.40:9000/LaundroKartFiles/";
  // BASE_REPORTBUILDER_URL = "http://120.138.9.40:9000/rbui/";

  // Production server
  BASE_WEBAPI_URL = "https://karelabel.com/LaundrokartAPI/";
  BASE_FILES_URL = "https://karelabel.com/LaundrokartFiles/";
  BASE_REPORTBUILDER_URL = "https://karelabel.com/rbui/";

  //iSTART : API, Files, Report bulder & Static page configuration Urls

  // Api controller Urls
  DROPDOWN_URL = this.BASE_WEBAPI_URL + "api/DropDownList";
  LAUNDRETKART_AUTH = this.BASE_WEBAPI_URL + "api/Auth";
  ITEM_URL = this.BASE_WEBAPI_URL + "api/Admin";
  ITEM_STORE_URL = this.BASE_WEBAPI_URL + "api/Store";
  ITEM_FACTORY_URL = this.BASE_WEBAPI_URL + "api/Factory";
  ITEM_LKWALLET_URL = this.BASE_WEBAPI_URL + "api/LKWallet";
  PAYTM_URL = this.BASE_WEBAPI_URL + "api/Paytm";
  FINGERPRINT_URL = this.BASE_WEBAPI_URL + "api/Mantra";
  CDA_URL = this.BASE_WEBAPI_URL + "api/DeliveryApp";
  CP_URL=this.BASE_WEBAPI_URL + "api/CustomerApp";
  PHONEPE_URL = this.BASE_WEBAPI_URL + "api/PhonePe";

  // File folders Urls
  BANNER_MASTER_IMAGE_URL = this.BASE_FILES_URL + "CustomerAppBanner/";
  STANDARD_RATE_CARD_AND_GARMENT_MASTER_IMAGE_URL =
    this.BASE_FILES_URL + "GarmentIcons/";
  SERVICE_MASTER_IMAGE_URL = this.BASE_FILES_URL + "Services/";
  SERVICE_ICONS_MASTER_IMAGE_URL = this.BASE_FILES_URL + "ServiceIcons/";

  // Static pages Urls
  FAQ_URL = "https://www.laundrokart.com/faq";
  ELEARNING_URL = this.BASE_FILES_URL + "MobileStaticPages/traning.html ";
  RETURN_REFUND_URL = this.BASE_FILES_URL + "MobileStaticPages/returns_and_refunds.html ";
  TERMS_CONDITIONS_URL = this.BASE_FILES_URL + "MobileStaticPages/Terms_and_conditions.html ";

  // Report builder Urls
  sss = localStorage.getItem("currentuser");
  REPORTS_URL = ![null, undefined, "", "null"].includes(
    localStorage.getItem("currentuser")
  )
    ? this.BASE_REPORTBUILDER_URL +
      "ReportbuilderHome?Token=" +
      JSON.parse(localStorage.getItem("currentuser")).token
    : "";

  //iEND : API, Files, Report bulder & Static page configuration Urls
}

export default Configuration;
