import React from "react";
import PackagingChargesMaster from "../../../components/Master/PackagingCharges/packagechargemaster.component"

export default class PackagingChargesScreen extends React.Component {
  render() {
    return (
      <div >
        <PackagingChargesMaster></PackagingChargesMaster>
      </div>
    );
  }
}