import React from "react";
import styles from "./cashcouponmaster.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  notification,
  Tooltip,
  Radio,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faPencilAlt,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../routes";
import CashCouponMaster from "../../../services/cashcouponmasterservice";
import CashCouponMasterService from "../../../services/cashcouponmasterservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Common from "../../../services/common";

const { Search } = Input;
const { Option } = Select;

/**
 * A factory master screen which holds grid view for master screens and form with steps
 *
 * @author Maulik  Suthar
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
class Master extends React.Component {
  constructor(props) {
    super(props);
    this.cashcouponmasterservice = new CashCouponMasterService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();
    this.getCashCouponByfilter = this.getCashCouponByfilter.bind(this);
    /**
     * State Varibales to determine/toggle multiple components on screen
     *
     * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
     */
    this.state = {
      couponID: 0,
      couponName: "",
      cashCouponcode: "",
      statusID: 0,
      showfilterBox: false,
      couponList: [],
      tableLoading: true,
      pagination: {
        showSizeChanger: true,
        // pageSizeOptions: [1, 2, 3, 4],
        current: 1,
        pageSize: 10,
        onChange: (page, pageSize) => {
          this.setState({
            tableLoading: true,
            pagination: {
              ...this.state.pagination,
              current: page,
              pageSize: pageSize,
            },
          });
        },
        onShowSizeChange: (current, size) => {
          this.setState({
            tableLoading: true,
            pagination: {
              ...this.state.pagination,
              current: current,
              pageSize: size,
            },
          });
        },
        validtilltoday: 0,
      },
    };
  }
  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.CASH_COUPON
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ tableLoading: false });
    this.getCashCouponMasterList({
      couponID: this.state.couponID,
      validtilltoday: this.state.validtilltoday,
      cashCouponcode: this.state.cashCouponcode,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination,
    });
  }

  handleSearch = (event) => {
    this.getCashCouponMasterList({
      couponID: this.state.couponID,
      validtilltoday: this.state.validtilltoday,
      CommonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  getCashCouponByfilter = () => {
    this.getCashCouponMasterList({
      couponID: 0,
      validtilltoday: this.state.validtilltoday,
      CommonSearch: document.getElementById("cashCouponCode").value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  getCashCouponMasterList(params = {}) {
    this.setState({ tableLoading: true });
    this.cashcouponmasterservice
      .retrieveCashCouponMaster(params)
      .then((cashCouponList) => {
        if (cashCouponList.statusCode === 200) {
          // if (cashCouponList.length !== 0) {

          if (
            cashCouponList.length !== 0 &&
            cashCouponList.data.length === 0 &&
            params.pagination.current !== 1
          ) {
            params.pagination.current = 1;

            this.getCashCouponMasterList({
              couponID: this.state.couponID,
              validtilltoday: this.state.validtilltoday,
              cashCouponcode: this.state.cashCouponcode,
              pageIndex: 1,
              pageSize: params.pageSize,
              pagination:params.pagination
            });

          }


          this.setState({
            cashCouponList: cashCouponList.data,
            pagination: {
              ...params.pagination,
              total: cashCouponList.data.length
                ? cashCouponList.data[0].totalRecords
                : null,
            },
          });
        } else {
          notification["error"]({
            message: "Cash Coupon",
            description: cashCouponList.message
              ? this.common.Message(cashCouponList.message)
              : "Unable to get list of cash coupon data",
          });
        }
        this.setState({ tableLoading: false });
      });
  }

  columns = [
    {
      title: "Cash Coupon",
      dataIndex: "cashCouponcode",
      key: "cashCouponcode",
      sorter: true,
      // defaultSortOrder:'ascend',
      // sorter:(a,b)=>{return a.cityName.localeCompare(b.cityName)},
    },
    {
      title: "Coupon Type",
      dataIndex: "couponTypeName",
      key: "couponTypeName",
      sorter: true,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      sorter: true,
    },
    {
      title: "Valid From",
      dataIndex: "validFrom",
      key: "validFrom",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Valid To",
      dataIndex: "validTo",
      key: "validTo",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Link
                to={{
                  pathname: [ROUTES.VIEW_CASH_COUPON, record.couponID].join(
                    "/"
                  ),
                  state: { couponID: record.couponID },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true &&
          moment().isBefore(moment(record.validFrom)) ? (
            <Tooltip title="Edit">
              <Link
                to={{
                  pathname: [ROUTES.EDIT_CASH_COUPON, record.couponID].join(
                    "/"
                  ),
                  state: { couponID: record.couponID },
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </Tooltip>
          ) : null}{" "}
        </Space>
      ),
    },
  ];

  handleRadioChange = (e) => {
    // console.log("radio checked", e.target.value);
    const validtilltoday = e.target.value === 2 ? 1 : 0;

    this.setState(
      {
        validtilltoday: validtilltoday,
        radioSelectedData: e.target.value,
      },
      () => {

        this.getCashCouponMasterList({
          validtilltoday: this.state.validtilltoday,
          couponID: this.state.couponID,
          cashCouponcode: this.state.cashCouponcode,
          pageIndex: this.state.pagination.current,
          pageSize: this.state.pagination.pageSize,
          pagination:this.state.pagination
        });  
      }
    );
  };

  render() {
    return (
      <div>
        <PageHeader
          title="Cash Coupon"
          extra={[
            <Radio.Group
              onChange={this.handleRadioChange}
              value={this.state.radioSelectedData}
            >
              <Radio value={1}>All</Radio>
              <Radio value={2}>Valid as on Today</Radio>
            </Radio.Group>,

            <Search
              placeholder="Search"
              className={styles.SearchInput}
              key="1"
              onChange={this.handleSearch}
            />,
            /**
             * Add Factory Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.props.history.push({
                    pathname: ROUTES.ADD_CASH_COUPON,
                    state: { couponID: this.state.couponID },
                  })
                }
                key="2"
              >
                Add Cash Coupon
              </Button>
            ) : null,
            // <Button
            //     type="link"
            //     className={styles.FilterToggle}
            //     onClick={() => {
            //         this.setState({ showfilterBox: !this.state.showfilterBox });
            //     }}
            //     key="3"
            // >
            //     {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //     <FontAwesomeIcon
            //         icon={faChevronUp}
            //         className={
            //             this.state.showfilterBox
            //                 ? styles.FilterToggleIcon
            //                 : styles.FilterToggleIconRotated
            //         }
            //     />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item>
                  <Input
                    placeholder="Enter Cash Coupon Code"
                    id="cashCouponCode"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  variant="whitePink"
                  shape="round"
                  size="large"
                  onClick={this.getCashCouponByfilter}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.cashCouponList}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.getCashCouponMasterList({
              validtilltoday: this.state.validtilltoday,
              couponID: this.state.couponID,
              cashCouponcode: this.state.cashCouponcode,
              pageIndex: pagination.current,
              pageSize: pagination.pageSize,
              pagination,
              sortColumn: sorter.field,
              sortOrder:
                sorter.order === undefined
                  ? null
                  : sorter.order === "descend"
                  ? "desc"
                  : "asc",
            });
          }}
        />
      </div>
    );
  }
}

export default withRouter(Master);
