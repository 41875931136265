import React from "react";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Form,
  Space,
  Table,
  notification,
  Tooltip,
  Radio
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import styles from "./userlistscreen.module.css";
import CreateUser from "./createuser.screen";
import UserService from "../../../services/userservice";
import Moment from "moment";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../routes";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";

const { Search } = Input;
// const { Option } = Select;
/**
 * User listing screen.
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 1 June 2020
 *
 */
class UserList extends React.Component {
  /**
   * State Varible to Hold Active Step Index starting from 0
   */
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.userservice = new UserService();
    this.state = {
      tableLoading: true,
      isEditmode: false,
      isViewmode: false,
      disabled: false,
      showfilterBox: false,
      showAddPopup: false,
      isAdd: false,
      userList: [],
      userMasterData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      loading: false,
      search: null,
      roleAssigned: 0,
      roleNotAssigned: 0
    };
    this.getUserMasterByfilter = this.getUserMasterByfilter.bind(this);
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.getUserMasterDetails({
      roleNotAssigned: this.state.roleNotAssigned,
      roleAssigned: this.state.roleAssigned,
      userID: this.state.userID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.USERS
    );

    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.getUserMasterDetails({
      roleNotAssigned: this.state.roleNotAssigned,
      roleAssigned: this.state.roleAssigned,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  handleRadioChange = (e) => {
    // console.log('radio checked', e.target.value);
    const roleNotAssignedValue = e.target.value === 1 ? 1 : 0;
    const roleAssignedValue = e.target.value === 2 ? 1 : 0;

    this.setState({
      roleAssigned: roleAssignedValue,
      roleNotAssigned: roleNotAssignedValue,
      roleRadio: e.target.value
    }, () => {
      this.getUserMasterDetails({
        roleNotAssigned: this.state.roleNotAssigned,
        roleAssigned: this.state.roleAssigned,
        userID: this.state.userID,
        pageIndex: this.state.pagination.current,
        pageSize: this.state.pagination.pageSize,
        pagination: this.state.pagination,
        // ...filters,
      });
    });
  };

  getUserMasterDetails(params = {}) {
    this.setState({ tableLoading: true });
    params = { ...params, CommonSearch: this.state.search };
    this.userservice.retrieveUserMaster(params).then((userList) => {
      localStorage.setItem("geoFencing", "");
      if (userList.statusCode === 200) {
        if (
          userList.length !== 0 &&
          userList.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.getUserMasterDetails({
            roleAssigned:this.state.roleAssigned,
            roleNotAssigned : this.state.roleNotAssigned,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination
          });
        }
        this.setState({
          userList: userList.data,
          pagination: {
            ...params.pagination,
            total: userList.data.length > 0 ? userList.data[0].totalRecords : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Users",
          description: userList.message
            ? userList.message
            : "Unable to get list of users",
        });
      }
      this.setState({ tableLoading: false });
    });
  }

  getUserMasterByfilter(pagination) {
    const params = {
      firstName: document.getElementById("firstName").value,
      middleName: document.getElementById("middleName").value,
      lastName: document.getElementById("lastName").value,
      mobileNo: document.getElementById("mobileNo").value,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    };

    this.userservice.retrieveUserMasterByfilter(params).then((userList) => {
      this.setState({
        userList: userList.data,
        pagination: {
          ...params.pagination,
          total: userList.data[0].totalRecords,
        },
      });
    });
  }

  // deleteUserByUserID(id){
  //   this.userservice.deleteUserByUserID(id).then(response => {
  //       console.log(response);
  //       alert(response.message);
  //       this.getUserMasterDetails();
  //   });
  // }

  getUserDetailByUserID(id) {
    this.userservice.getUserDetailByUserID(id).then((userMasterData) => {
      if (userMasterData.result === "Success") {
        this.setState({ userMasterData: userMasterData.data });
        this.setState({ isEditmode: true });
      } else {
        alert(userMasterData.message);
      }
    });
  }

  getUserViewDetailByUserID(id) {
    this.userservice.getUserDetailByUserID(id).then((userMasterData) => {
      if (userMasterData.result === "Success") {
        this.setState({
          userMasterData: userMasterData.data,
          isViewmode: true,
          disabled: true,
        });
      } else {
        alert(userMasterData.message);
      }
    });
  }

  handleSearch = (event) => {
    this.setState({ search: event.target.value });
    this.getUserMasterDetails({
      userID: this.state.userID,
      CommonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  bringback = () => {
    this.setState({ isAdd: false });
  };

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: true,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: true,
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      key: "mobileNo",
      sorter: true,
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
      sorter: true,
    },
    {
      title: "Employee Type",
      dataIndex: "employeeTypeName",
      key: "employeeTypeName",
      sorter: true,
    },
    {
      title: "Designation",
      dataIndex: "designationName",
      key: "designationName",
      sorter: true,
    },
    {
      title: "Reporting To",
      dataIndex: "reportingToName",
      key: "reportingToName",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Link to={[ROUTES.VIEW_USER, record.userID].join("/")}>
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Link to={[ROUTES.EDIT_USER, record.userID].join("/")}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  /**
   * Variables to be used in table for selection property
   * kindly go through
   * https://ant.design/components/table/
   * https://ant.design/components/table/#components-table-demo-row-selection-and-operation
   * https://ant.design/components/table/#components-table-demo-row-selection
   */
  // selectionType = "checkbox";
  // rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(
  //       `selectedRowKeys: ${selectedRowKeys}`,
  //       "selectedRows: ",
  //       selectedRows
  //     );
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.firstName === "Disabled User", // Column configuration not to be checked
  //     name: record.firstName,
  //   }),
  // };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  render() {
    const { userMasterData, isEditmode, pagination, isViewmode, disabled } =
      this.state;
    const userList = this.state.userList;
    const userLists = userList.map((userList) => ({
      userID: userList.userID,
      firstName: userList.firstName,
      lastName: userList.lastName,
      mobileNo: userList.mobileNo,
      employeeTypeName: userList.employeeTypeName,
      designationName: userList.designationName,
      reportingToName: userList.reportingToName,
      dob: [null, "", undefined].includes(userList.dob)
        ? ""
        : Moment(userList.dob).format("DD-MM-YYYY"),
    }));
    return this.state.isAdd ||
      this.state.isEditmode ||
      this.state.isViewmode ? (
      <CreateUser
        backFunction={this.bringback}
        userMasterData={userMasterData}
        isEditmode={isEditmode}
        isViewmode={isViewmode}
        disabled={disabled}
      />
    ) : (
      <React.Fragment>
        <PageHeader
          title="Users"
          extra={[
            <Radio.Group
              onChange={this.handleRadioChange}
              value={this.state.roleRadio}
            >
              <Radio value={1}>Role Not Assigned</Radio>
              <Radio value={2}>Role Assigned</Radio>
            </Radio.Group>,

            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
              key="1"
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                onClick={() => this.props.history.push(ROUTES.ADD_USER)}
                key="2"
                //onClick={() => this.setState({ showAddPopup: true,isAdd:true })}
              >
                Add User
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />

        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item>
                  <Input placeholder="Enter First Name" id="firstName" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item>
                  <Input placeholder="Enter Middle Name" id="middleName" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item>
                  <Input placeholder="Enter Last Name" id="lastName" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item>
                  <Input placeholder="Enter Mobile No" id="mobileNo" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  variant="whitePink"
                  shape="round"
                  size="large"
                  onClick={this.getUserMasterByfilter}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>
        {/**
         * Please go through below documention for <Table> Component
         * https://ant.design/components/table/
         * https://ant.design/components/table/#API
         *
         */}
        <Table
          size="small"
          // rowSelection={{
          //   type: this.selectionType,
          //   ...this.rowSelection,
          // }}
          columns={this.columns}
          dataSource={userLists}
          pagination={pagination}
          loading={this.state.tableLoading}
          onChange={this.handleTableChange}
        />

        <TableActions isPagination={true}></TableActions>
      </React.Fragment>
    );
  }
}
export default withRouter(UserList);
