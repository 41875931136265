import React from "react";
import OperationInstructionMaster from "../../../components/Master/OperationInstruction/operationinstructmaster.component"
export default class OperationInstructionScreen extends React.Component {
  render() {
    return (
      <div >
        <OperationInstructionMaster></OperationInstructionMaster>
      </div>
    );
  }
}