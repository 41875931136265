import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../../Common/Styles/master.module.css";
import {
  Steps,
  PageHeader,
  Spin,
  Row,
  Col,
  Form,
  Select,
  // TimePicker,
  Input,
  // DatePicker,
  Space,
  notification,
  // Radio,
  Descriptions,
  Upload,
} from "antd";
import Button from "../../../Common/Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import GeneralForm from "./garment.addpostqc.factory.component";
// import FactoryPreQC from "../../../../services/factorypre-qc";
import ROUTES from "../../../../routes";
import { labels } from "../../../../shared/language";
import Common from "../../../../services/common";

const { Step } = Steps;

/**
 
 * @author Payal Bharakhda
 * @created 25 Nov 2020
 *
 * @module Factory
 */
class AddFactory extends React.Component {
  //   factoryPreQCService = new FactoryPreQC();
  common = new Common();
  // corporateService = new CorporateService();

  state = {
    factoryID: 0,
    activeStep: 0,
    fullLoading: false,
    stepsDirection: "vertical",
    stepsSize: "default",
    generalFormValues: {},
    contactFormValues: {},
    mode: "add",
  };
 
  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "small" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };

  componentDidMount() {
    if (
      [ROUTES.EDIT_POST_QC_FACTORY, ":tagNo"].join("/") ===
      this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });

      // this.getQCInfoByTagNumber(this.props.match.params.tagNo);
    } else if (
      [ROUTES.VIEW_POST_QC_FACTORY, ":tagNo"].join("/") ===
      this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      //   this.getQCInfoByTagNumber(this.props.match.params.tagNo);
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
    setTimeout(() => {
      this.setState({ fullLoading: false });
    }, 1500);
  }

  addUpdateCorporate = () => {
    this.setState({ fullLoading: true });
  };
  generalForm = React.createRef();

  handleSubmit = () => {
    const generalValidator = this.generalForm.current.validateFields();
    Promise.allSettled([generalValidator]).then((responses) => {
      if (responses[0].status === "rejected") {
        this.setState({ activeStep: 0 });
      } else if (responses[1].status === "rejected") {
        this.setState({ activeStep: 1 });
      } else if (
        responses[0].status === "fulfilled" &&
        responses[1].status === "fulfilled"
      ) {
        this.setState(
          {
            generalFormValues: responses[0].value,
          },
          () => {
            this.addUpdateCorporate();
          }
        );
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? labels.EDIT
                : this.state.mode === "view"
                ? labels.VIEW
                : labels.ADD,
              labels.GARMENT_FOR_QC,
            ].join(" ")}
          />
          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => this.setState({ activeStep: current })}
              >
                <Step
                  title={labels.GARMENT}
                  description={labels.SELECT_SCAN_GARMENT}
                />
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider>
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading}>
                  <React.Fragment>
                    <GeneralForm
                      values={this.state.generalFormValues}
                      backFunction={() => this.props.history.goBack()}
                      nextFunction={() => this.setState({ activeStep: 1 })}
                      isActive={this.state.activeStep === 0}
                      instance={this.generalForm}
                      mode={this.state.mode}
                    />
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(AddFactory);
