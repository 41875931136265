import React from "react";
import styles from "../Common/Styles/master.module.css";
import Button from "../Common/Button/button";
import Filters from "../Common/Filters/filters";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Table,
  notification,
  DatePicker,
  Descriptions,
} from "antd";

import Moment from "moment";
import AttendanceService from "../../services/attendanceservice";
import UserService from "../../services/userservice";
import ReactExport from "react-export-excel";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Search } = Input;
const { Option } = Select;

export default class AttendanceReport extends React.Component {
  userservice = new UserService();
  attendanceService = new AttendanceService();
  UserRightsConfiguration = new UserRightsConfiguration();
  constructor(props) {
    super(props);

    this.state = {
      excelFile: <React.Fragment></React.Fragment>,
      deliveryOrderItems: [],
      UserOptions: [],
      UserSelection: [],
      reportData: [],
      ID: 0,
      data: [],
      selectedRowKeys: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      showfilterBox: true,
      loading: false,
      fromDate: Moment().startOf(1, "months").date(1),
      toDate: Moment(),
      invoiceDate: null,
      performaDate: null,
      customerName: {
        //  disabled: true,
        data: [],
        selected: null,
      },
      selectedOrdersOfCustomer: null,
    };
  }

  columns = [
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      //   sorter: true,
    },
    {
      title: "Store/Factory Name",
      dataIndex: "storeName",
      key: "storeName",
      //   sorter: true,
    },
    {
      title: "In Time",
      dataIndex: "inTime",
      key: "inTime",
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY HH:mm");
      },
      //   sorter: true,
    },
    {
      title: "Out Time",
      dataIndex: "outTime",
      key: "outTime",
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY HH:mm");
      },
      //   sorter: true,
    },

    {
      title: "Normal / OT",
      dataIndex: "isNormal",
      key: "isNormal",
      //   sorter: true,
      render: (isNormal) => (isNormal !== 1 ? "OT" : "Normal"),
    },
  ];

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.ATTENDANCE_REPORT
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  componentDidMount() {
    this.getUsers({
      userID: null,
      pageIndex: 1,
      pageSize: 1000,
    });
    this.fetchItems();
  }

  getUsers(params = {}) {
    this.setState({ tableLoading: true });
    params = { ...params, CommonSearch: this.state.search };
    this.userservice.retrieveUserMaster(params).then((userList) => {
      if (userList.statusCode === 200) {
        this.setState({
          ...this.state.UserOptions,
          UserOptions: userList.data,
        });
      } else {
        notification["error"]({
          message: userList.message
            ? userList.message
            : "Unable to get list of users",
        });
      }
      this.setState({ tableLoading: false });
    });
  }

  handleUserChange = (value) => {
    this.setState({
      ...this.state.UserSelection,
      UserSelection: value,
    });
  };
  //function for get data from server call
  //   fetchItems = (params = {}) => {
  fetchItems = () => {
    this.setState({ loading: true });
    let userParams = null;
    if (
      this.state.UserSelection !== undefined &&
      this.state.UserSelection.length > 0
    ) {
      userParams = "";
      for (var j = 0; j < this.state.UserSelection.length; j++) {
        userParams += this.state.UserSelection[j] + ",";
      }
    }
    const params = {
      fromDate: Moment(this.state.fromDate).format("YYYY-MM-DD"),
      toDate: Moment(this.state.toDate).format("YYYY-MM-DD"),
      Users: userParams,
    };

    this.attendanceService.getAttendanceReport(params).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          reportData: response.data,
        });
      } else {
        notification["error"]({
          message: response.message
            ? response.message
            : "Failed to get report data",
        });
      }
    });

    this.setState({ loading: false });
  };

  downloadReport = () => {
    this.setState({ loading: true });
    let userParams = null;
    if (
      this.state.UserSelection !== undefined &&
      this.state.UserSelection.length > 0
    ) {
      userParams = "";
      for (var j = 0; j < this.state.UserSelection.length; j++) {
        userParams += this.state.UserSelection[j] + ",";
      }
    }
    const params = {
      fromDate: Moment(this.state.fromDate).format("YYYY-MM-DD"),
      toDate: Moment(this.state.toDate).format("YYYY-MM-DD"),
      Users: userParams,
    };

    this.attendanceService.getAttendanceReport(params).then((response) => {
      if (response.statusCode === 200) {
        this.setState(
          {
            excelFile: (
              <div>
                <ExcelFile hideElement={true} filename={"Attendance report"}>
                  <ExcelSheet
                    data={response.data}
                    name={"Attendance"}
                    columns={[]}
                  >
                    <ExcelColumn label="User Name" value="userName" />
                    <ExcelColumn label="Store/Factory Name" value="storeName" />
                    <ExcelColumn label="In Time" value="inTime" />
                    <ExcelColumn label="Out Time" value="outTime" />
                    <ExcelColumn label="Normal / OT" value={"isNormal"} />
                  </ExcelSheet>
                </ExcelFile>
              </div>
            ),
          },
          () => this.setState({ excelFile: <React.Fragment></React.Fragment> })
        );
      }
    });
    this.setState({ loading: false });
  };

  

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  render() {
    const { pagination, loading } = this.state;
    const deliveryOrderItems = this.state.deliveryOrderItems;

    return (
      <div>
        {this.state.excelFile}
        <PageHeader title="Attendance Report" extra={[]} />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={this.fetchItems}
            initialValues={{
              fromDate: this.state.fromDate,
              toDate: this.state.toDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"fromDate"}
                  rules={[
                    { required: true, message: "Please Select From Date !" },
                  ]}
                >
                  <DatePicker
                    placeholder="From Date"
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "fromDate")}
                    value={this.state.fromDate}
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"toDate"}
                  rules={[
                    { required: true, message: "Please Select To Date !" },
                  ]}
                >
                  <DatePicker
                    placeholder="To Date"
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "toDate")}
                    value={this.state.toDate}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item name="UserID">
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder="Select Users"
                    onChange={(value) => {
                      this.handleUserChange(value);
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.UserOptions.map((users) => {
                      return (
                        <Option value={users.userID} key={users.userID}>
                          {users.firstName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                >
                  View
                </Button>
                <Button
                  variant="transparentGreyOutline"
                  onClick={() => this.downloadReport()}
                >
                  Download
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>
        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.reportData}
          // pagination={pagination}
          loading={loading}
        />
      </div>
    );
  }
}
