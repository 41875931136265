import React from "react";
import {
  Tabs,
  Select,
  Input,
  Col,
  Row,
  Form,
  Space,
  List,
  Card,
  Divider,
  notification,
  Typography,
  Spin,
  Modal,
} from "antd";
import Button from "../../Common/Button/button";
import { labels } from "../../../shared/language";
import OrderHistoryservice from "../../../services/orderhistoryservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Styles from "./orderhistory.module.css";
import { getPDFProformaInvoice } from "../Invoice/storeinvoicepdf.component";
import CreateOrderService from "../../../services/createorderservice";
import moment from "moment";
const { TabPane } = Tabs;
const { Text } = Typography;

export default class orderhistory extends React.Component {
  formRef = React.createRef();
  OrderHistoryservice = new OrderHistoryservice();
  OrderService = new CreateOrderService();
  UserRightsConfiguration = new UserRightsConfiguration();

  state = {
    ShowPickupList: false,
    ShowOrderList: false,
    modalLoading: false,
    isResponseErrorShow: false,
    pickerData: [
      { label: "Order", value: "1" },
      { label: "Pickup", value: "2" },
      { label: "Mobile No", value: "3" },
    ],
    panes: 2,
    SearchType: "",
    userRights: [],
    orderId: "",
    OrderHistoryData: {},
    CustomerDetail: {},
    showDetail: false,
    spliteOrderwisedata: [],
    defultActivactionkey: "",
    factoryData: null,
    orderHistoryPackingDetail: [],
    orderHistoryPackingMaterial: [],
    postProcessData: [],
    deliveryData: [],
    paymentData: [],
    remarksData: [],
    processData: [],
    pickupcallback: [],
    deliverycallback: [],
    deliveryEscalationData: [],
    header: [],
    OrderList: [],
    PickupList: [],
    IsLoadMoreData: false,
    QcStatusData: null,
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.STORE_ORDER_HISTORY
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  handlePopupAdd = (SearchType, OrderID) => {
    this.setState({ modalLoading: true });

    this.setState({
      factoryData: null,
      orderHistoryPackingDetail: [],
      orderHistoryPackingMaterial: [],
      postProcessData: [],
      deliveryData: [],
      paymentData: [],
      remarksData: [],
      processData: [],
      pickupcallback: [],
      deliverycallback: [],
      deliveryEscalationData: [],
      QcStatusData: null,
    });
    if (SearchType == 1 || SearchType == 2) {
      this.OrderHistoryservice.getOrderHistory(
        SearchType,
        parseInt(OrderID)
        // parseInt(this.state.orderId)
      ).then((items) => {
        if (items.result === "Success") {
          // notification["success"]({
          //   message: "Order History",
          //   description: items.message,
          // });

          this.setState({
            //ShowOrderList: false,
            OrderHistoryData: items.data,
            showAddPopup: false,
            showDetail: true,
            panes:
              items.data.splitOrderNumber != null
                ? items.data.splitOrderNumber.length
                : 0,
            spliteOrderwisedata:
              items.data.splitOrderNumber != null
                ? items.data.splitOrderNumber
                : [],
            defultActivactionkey:
              items.data.splitOrderNumber != null
                ? items.data.splitOrderNumber[0].splitOrderNumber
                : 0,
          });
          if (items.data.splitOrderNumber != null) {
            this.getfactorystatusdata(
              items.data.splitOrderNumber[0].splitOrderNumber
            );
          }
        } else {
          notification["error"]({
            message: "Order History",
            description: items.message,
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
        this.setState({ modalLoading: false });
      });
    } else {
      this.setState({ modalLoading: true });
      this.OrderHistoryservice.getOrderListByMobileNo(this.state.orderId).then(
        (items) => {
          if (items.result === "Success") {
            this.setState({
              OrderList: items.data.orderList,
              PickupList: items.data.pickupList,
              ShowOrderList: true,
              ShowPickupList: true,
              showDetail: false,
              OrderHistoryData: [],
              spliteOrderwisedata: [],
            });
            // console.log("orderlisy::", items.data);
          } else {
            notification["error"]({
              message: "Order History",
              description: items.message,
            });
            this.setState({
              isResponseErrorShow: true,
            });
          }
          this.setState({ modalLoading: false });
        }
      );
    }
  };

  getfactorystatusdata = (spliteOrderId) => {
    this.setState({ modalLoading: true });
    this.OrderHistoryservice.getfactorystatusdata(spliteOrderId).then(
      (items) => {
        console.log("items->", items);
        if (items.result === "Success") {
          this.setState({
            factoryData: items.data,
            orderHistoryPackingDetail: items.data.orderHistoryPackingDetail,
            orderHistoryPackingMaterial: items.data.orderHistoryPackingMaterial,
            postProcessData: items.data.postProcessData,
            deliveryData: items.data.deliveryData,
            paymentData: items.data.paymentData,
            remarksData: items.data.remarksData,
            processData: items.data.processData,
            pickupcallback: items.data.pickupCallBack,
            deliverycallback: items.data.deliveryCallBack,
            deliveryEscalationData: items.data.deliveryEscalationData,
          });
          this.setState({ modalLoading: false });
        } else {
          notification["error"]({
            message: "Order History",
            description: items.message,
          });
          this.setState({
            isResponseErrorShow: true,
          });
          this.setState({ modalLoading: false });
        }
      }
    );
  };

  callInvoicePrint = () => {
    var OrderID = this.state.factoryData.orderID;
    var SpliteOrderID = this.state.defultActivactionkey;
    getPDFProformaInvoice(OrderID, SpliteOrderID);
  };

  callEstimatedIncoiceprint = () => {
    var OrderID = this.state.factoryData.orderID;
    this.getPDFEstimatedOrder(OrderID);
  };

  getPDFEstimatedOrder = (orderID) => {
    this.OrderService.getEstimatedOrderPrintByOrderID(orderID).then(
      (response) => {
        if (response.statusCode === 200) {
          if (response.data.length > 0) {
            window.open(response.data[0].location, "_blank");
            // window.location.reload();
          } else {
            notification["error"]({
              message: labels.ORDERS,
              description: response.message
                ? this.common.Message(response.message)
                : labels.UNABLE_TO_GENERATE_ESTIMATED_ORDER,
            });
          }
        } else {
          notification["error"]({
            message: labels.ORDERS,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GENERATE_ESTIMATED_ORDER,
          });
        }
        // window.location.reload();
      }
    );
  };

  LoadMoredata = (spliteOrderId) => {
    // console.log("spliteOrderId->",spliteOrderId);
    this.setState({ modalLoading: true });
    this.OrderHistoryservice.getMoreData(spliteOrderId).then((items) => {
      console.log("items->", items);
      if (items.result === "Success") {
        this.setState({
          QcStatusData: items.data,
          IsLoadMoreData: true,
        });
        this.setState({ modalLoading: false });
      } else {
        notification["error"]({
          message: "Order History",
          description: items.message,
        });
        this.setState({
          isResponseErrorShow: true,
        });
        this.setState({ modalLoading: false });
      }
    });
  };

  renderItemForFactoryStatus = (splitOrderNumber) => {
    return (
      <div>
        <div className={Styles.Title}>
          <Text underline>Factory Status</Text>
        </div>
        {this.state.factoryData != null ? (
          <div>
            <div>
              <Text> Store Out Date & Time : </Text>
              {this.state.factoryData.storeOutDatetime != null ||
              this.state.factoryData.storeOutDatetime != undefined ? (
                <Text>
                  {" "}
                  {moment(this.state.factoryData.storeOutDatetime).format(
                    "DD-MM-YYYY HH:mm:ss"
                  )}
                </Text>
              ) : null}
              {/* <Text type="secondary">
                {this.state.factoryData.storeOutDatetime}
              </Text> */}
            </div>

            <div>
              <Text>
                Total Garments : {this.state.factoryData.totalGarment}
              </Text>
              {/* <Text type="secondary">
                {this.state.factoryData.totalGarment}
              </Text> */}
            </div>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Text
              strong
              style={{
                color:
                  this.state.factoryData.totalGarment ==
                  this.state.factoryData.noOfFactoryInGarments
                    ? "#87ab69"
                    : "#FF0000",
              }}
            >
              Factory-In status
            </Text>

            <div>
              <Text> First In Date & Time : </Text>
              {this.state.factoryData.firstFactoryInDatetime != null ||
              this.state.factoryData.firstFactoryInDatetime != undefined ? (
                <Text>
                  {" "}
                  {moment(this.state.factoryData.firstFactoryInDatetime).format(
                    "DD-MM-YYYY HH:mm:ss"
                  )}
                </Text>
              ) : null}
              {this.state.factoryData.firstFactoryInDatetime !== null ? (
                <Text>
                  {"(" + this.state.factoryData.factoryInByName + ")"}
                </Text>
              ) : null}
              {/* <Text type="secondary">
                {this.state.factoryData.firstFactoryInDatetime}
              </Text> */}
            </div>
            <div>
              <Text> Last In Date & Time : </Text>
              {this.state.factoryData.lastFactoryInDatetime != null ||
              this.state.factoryData.lastFactoryInDatetime != undefined ? (
                <Text>
                  {" "}
                  {moment(this.state.factoryData.lastFactoryInDatetime).format(
                    "DD-MM-YYYY HH:mm:ss"
                  )}
                </Text>
              ) : null}
              {this.state.factoryData.lastFactoryInDatetime !== null ? (
                <Text>
                  {"(" + this.state.factoryData.factoryInByName + ")"}
                </Text>
              ) : null}
              {/* <Text type="secondary">
                {this.state.factoryData.lastFactoryInDatetime}
              </Text> */}
            </div>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Text strong>Processing Details</Text>
            {this.state.processData != null &&
              this.state.processData.map((e) => (
                <div>
                  {e.processGarments +
                    " Garments - " +
                    moment(e.processTime).format("DD-MM-YYYY HH:mm:ss") +
                    " - " +
                    e.processBy}
                </div>
              ))}
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <Text strong>Packaging Status</Text>
            <br></br>
          </div>
        ) : null}
        {this.state.orderHistoryPackingDetail != null &&
          this.state.orderHistoryPackingDetail.map((item) => (
            <div>
              <div>
                <Text>Sticker ID : {item.stickerID} </Text>
                {item.stickerID !== "" ? (
                  <Text>{"(" + item.stickerInByName + ")"}</Text>
                ) : null}
                {/* <Text type="secondary">{item.stickerID}</Text> */}
              </div>
              <div>
                <Text>Packaging ID : {item.packingID}</Text>
                {/* <Text type="secondary">{item.packingID}</Text> */}
              </div>
              <div>
                <Text>Packaged On :</Text>
                {/* <Text type="secondary">{item.packedOn}</Text> */}

                {item.packedOn != null || item.packedOn != undefined ? (
                  <Text>
                    {" "}
                    {moment(item.packedOn).format("DD-MM-YYYY HH:mm:ss")}
                  </Text>
                ) : null}
              </div>
              <div>
                <Text>No. of Garments : {item.noOfGarment}</Text>
                {/* <Text type="secondary">{item.noOfGarment}</Text> */}
              </div>
              <div>
                <Text>Packaged Garments : {item.packedGarments}</Text>
                {/* <Text type="secondary">{item.packedGarments}</Text> */}
              </div>
              <div>
                <Text>Factory Out Date & Time :</Text>
                {item.gatePassCreatedDateTime != null ||
                item.gatePassCreatedDateTime != undefined ? (
                  <Text>
                    {" "}
                    {moment(item.gatePassCreatedDateTime).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                  </Text>
                ) : null}
                {/* <Text type="secondary">{item.gatePassCreatedDateTime}</Text> */}
              </div>
              <div>
                <Text>Gate Pass Number : {item.factoryGatePassNumber} </Text>
                {item.factoryGatePassNumber !== "" ? (
                  <Text>{"(" + item.factoryGatepassByName + ")"}</Text>
                ) : null}
                {/* <Text type="secondary">{item.factoryGatePassNumber}</Text> */}
              </div>
              <div>
                <Text>Order Lot Size : {item.noOfGarment}</Text>
                {/* <Text type="secondary">{item.noOfGarment}</Text> */}
              </div>
              <div>
                <Text>Previously Sent : {item.previouslySent}</Text>
                {/* <Text type="secondary">{item.previouslySent}</Text> */}
              </div>
              <div>
                <Text>Sending today : {item.garmentsSent}</Text>
                {/* <Text type="secondary">{item.garmentsSent}</Text> */}
              </div>
              <div>
                <Text>Pending : {item.pending}</Text>
                {/* <Text type="secondary">{item.pending}</Text> */}
              </div>
              {this.state.orderHistoryPackingMaterial.map((e) => {
                if (item.stickerID == e.stickerID) {
                  return (
                    <div>
                      <Text>
                        {e.packingMaterialName} : {e.totalCounts}
                      </Text>
                      {/* <Text type="secondary">{e.totalCounts}</Text> */}
                    </div>
                  );
                }
              })}
              <Divider style={{ marginTop: 3, marginBottom: 3 }} />
            </div>
          ))}
        {this.state.factoryData != null ? (
          <div>
            <div>
              <Text>
                Balance Garments : {this.state.factoryData.balancedGarments}
              </Text>
            </div>
            <div style={{ marginTop: 20 }}>
              <Button onClick={() => this.LoadMoredata(splitOrderNumber)}>
                Load More
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  renderItemForPostProcess = () => {
    return (
      <div>
        <div className={Styles.Title}>
          <Text underline>Post Process</Text>
        </div>
        {this.state.orderHistoryPackingDetail != null &&
          this.state.orderHistoryPackingDetail.map((data) => {
            return (
              <div>
                <div>
                  <Text>Store In Date & Time : {data.stickerID}</Text>
                  {/* <Text type="secondary">{data.stickerID}</Text> */}
                </div>

                {this.state.postProcessData.map((e) =>
                  data.stickerID == e.stickerID ? (
                    <div>
                      <Text>Packaging ID : {e.packingID}</Text>

                      {e.storeInDateTime != null ||
                      e.storeInDateTime != undefined ? (
                        <Text>
                          {" "}
                          {moment(e.storeInDateTime).format(
                            "DD-MM-YYYY HH:mm:ss"
                          )}
                        </Text>
                      ) : null}
                      {e.storeInDateTime !== "" ? (
                        <Text>{"(" + e.storeInByName + ")"}</Text>
                      ) : null}

                      {/* <Text type="secondary">
                        {e.packingID} {e.storeInDateTime}
                      </Text> */}
                    </div>
                  ) : null
                )}
                <Divider style={{ marginTop: 3, marginBottom: 3 }} />
              </div>
            );
          })}
        {this.state.factoryData != null ? (
          <div>
            <Text>Partial Order : {this.state.factoryData.isPartialOrder}</Text>
            {/* <Text type="secondary">
              {this.state.factoryData.isPartialOrder}
            </Text> */}
          </div>
        ) : null}
        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        {this.state.deliveryData.map((e) => (
          <div>
            <div>
              <Text strong>Delivery Details : {e.SearchType}</Text>
              {/* <Text type="secondary">{e.SearchType}</Text> */}
            </div>
            <div>
              <Text>Delivery Type : {e.deliveryType}</Text>
              {/* <Text type="secondary">{e.deliveryType}</Text> */}
            </div>
            <div>
              <Text>Delivery Boy : {e.deliveryBoy}</Text>
              {/* <Text type="secondary">{e.deliveryBoy}</Text> */}
            </div>
            <div>
              <Text>Delivery Date : </Text>
              {/* <Text type="secondary">{e.deliveryDate}</Text> */}
              {e.deliveryDate != null || e.deliveryDate != undefined ? (
                <Text> {moment(e.deliveryDate).format("DD-MM-YYYY")}</Text>
              ) : null}
            </div>
            <div>
              <Text>No. of time Rescheduled : {e.rescheduledCount}</Text>
            </div>
            <div>
              <Text>Old Delivery Date : </Text>
              {e.oldDeliveryDate != null || e.oldDeliveryDate != undefined ? (
                <Text> {moment(e.oldDeliveryDate).format("DD-MM-YYYY")}</Text>
              ) : null}
            </div>
            <div>
              <Text>Delivered Date : </Text>
              {e.deliveredDate != null || e.deliveredDate != undefined ? (
                <Text> {moment(e.deliveredDate).format("DD-MM-YYYY")}</Text>
              ) : null}
              {/* <Text type="secondary">{e.deliveredDate}</Text> */}
            </div>
          </div>
        ))}

        <Divider style={{ marginTop: 10, marginBottom: 10 }} />

        {this.state.deliveryEscalationData != null &&
          this.state.deliveryEscalationData.map((data) => {
            return (
              <div>
                <div>
                  <Text strong>Escalation :</Text>
                </div>
                <div>
                  <Text>1st Delivery Escalation : </Text>
                  {data.firstEscalatedDate != null ||
                  data.firstEscalatedDate != undefined ? (
                    <Text>
                      {" "}
                      {moment(data.firstEscalatedDate).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </Text>
                  ) : null}
                  {data.firstEscalatedDate !== "" ? (
                    <Text>{"(" + data.firstEscalatedBy + ")"}</Text>
                  ) : null}
                </div>
                <div>
                  <Text>2nd Delivery Escalation : </Text>
                  {data.secondEscalatedDate != null ||
                  data.secondEscalatedDate != undefined ? (
                    <Text>
                      {" "}
                      {moment(data.secondEscalatedDate).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </Text>
                  ) : null}
                  {data.secondEscalatedDate !== "" ? (
                    <Text>{"(" + data.secondEscalatedBy + ")"}</Text>
                  ) : null}
                </div>
                <div>
                  <Text>3rd Delivery Escalation : </Text>
                  {data.lastEscalatedDate != null ||
                  data.lastEscalatedDate != undefined ? (
                    <Text>
                      {" "}
                      {moment(data.lastEscalatedDate).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                    </Text>
                  ) : null}
                  {data.lastEscalatedDate !== "" ? (
                    <Text>{"(" + data.lastEscalatedBy + ")"}</Text>
                  ) : null}
                </div>
                <Divider style={{ marginTop: 3, marginBottom: 3 }} />
              </div>
            );
          })}

        {/* {this.state.pickupcallback != null ||
        this.state.deliverycallback != null ? ( */}
        <div>
          <Text strong>Customer Communication</Text>
        </div>
        {/* ) : null} */}
        {/* {this.state.pickupcallback != null ? (
          <div>
            <div>
              <Text>Pickup :</Text>
            </div>
            {this.state.pickupcallback.map((e) => (
              <div>
                <Text>Call back request :</Text>
                {e.createdOn != null || e.createdOn != undefined ? (
                <Text>
                  {" "}
                  {moment(e.createdOn).format("DD-MM-YYYY HH:mm:ss")}
                </Text>
              ) : null}
              </div>
            ))}
          </div>
        ) : null} */}

        <div>
          <Text>Delivery call back request :</Text>
        </div>

        {this.state.deliverycallback != null ? (
          <div>
            {/* <div>
              <Text>Delivery :</Text>
            </div>
            <div>
              <Text>Call back request :</Text>
            </div> */}
            {this.state.deliverycallback.map((e) => (
              <div>
                <Text>Call back request : </Text>
                {e.createdOn != null || e.createdOn != undefined ? (
                  <Text>
                    {" "}
                    {moment(e.createdOn).format("DD-MM-YYYY HH:mm:ss")}
                  </Text>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}

        {/* <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <div>
          {" "}
          <Text strong>OTP Verification</Text>
        </div>
        <div>
          <Text>
            {" "}
            Pickup :{" "}
            {(this.state.factoryData != null && this.state.factoryData.pickupOTPVerifiedDate !=null)&&
              this.state.OrderHistoryData.mobileNo +
                " - " +
                moment(this.state.factoryData.pickupOTPVerifiedDate).format(
                  "DD-MM-YYYY HH:mm:ss"
                )}
          </Text>
        </div>
        <div>
          <Text>
            {" "}
            Delivery :{" "}
            {(this.state.factoryData != null && this.state.factoryData.deliveryOTPVerifiedDate != null) &&
              this.state.OrderHistoryData.mobileNo +
                " - " +
                moment(this.state.factoryData.deliveryOTPVerifiedDate).format(
                  "DD-MM-YYYY HH:mm:ss"
                )}
          </Text>
        </div> */}
      </div>
    );
  };

  renderItemForPaymentStatus = () => {
    return (
      <div>
        <div className={Styles.Title}>
          <Text underline>Payment Status</Text>
        </div>
        {this.state.factoryData != null ? (
          <div>
            <div>
              <Text>
                Split Order Status : {this.state.factoryData.splitOrderStatus}
              </Text>
              {/* <Text type="secondary">
                {this.state.factoryData.splitOrderStatus}
              </Text> */}
            </div>
            <div>
              <Text>
                Estimated Amount :{" "}
                {this.state.factoryData.estimatedInvoiceAmount}
              </Text>
              {/* <Text type="secondary">
                {this.state.factoryData.estimatedInvoiceAmount}
              </Text> */}
            </div>
          </div>
        ) : null}

        {this.state.paymentData.map((e) => (
          <div>
            <div>
              <Text>PI Amount : {e.piAmount}</Text>
              {/* <Text type="secondary">{e.piAmount}</Text> */}
            </div>
            <div>
              <Text>Invoice Amount : {e.invoiceAmount}</Text>
              {/* <Text type="secondary">{e.invoiceAmount}</Text> */}
            </div>
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <Text strong>Payment Details</Text>
            <div>
              <Text>Invoice Status : {e.invoiceStatus}</Text>
              {/* <Text type="secondary">{e.invoiceStatus}</Text> */}
            </div>
            <div>
              <Text>Invoice Number : {e.invoiceNumber}</Text>
              {/* <Text type="secondary">{e.invoiceNumber}</Text> */}
            </div>
            <div>
              <Text>Invoice Date :</Text>
              {e.invoiceDate != null || e.invoiceDate != undefined ? (
                <Text>
                  {" "}
                  {moment(e.invoiceDate).format("DD-MM-YYYY HH:mm:ss")}
                </Text>
              ) : null}
              {/* <Text type="secondary">{e.invoiceDate}</Text> */}
            </div>
            <div>
              <Text>
                Invoice Amount : {e.invoiceAmount} ({e.userName})
              </Text>
              {/* <Text type="secondary">{e.invoiceAmount}</Text> */}
            </div>
            <div>
              <Text>Cash : {e.cash}</Text>
              {/* <Text type="secondary">{e.cash}</Text> */}
            </div>
            <div>
              <Text>LK Wallet : {e.lkWallet}</Text>
              {/* <Text type="secondary">{e.lkWallet}</Text> */}
            </div>
            <div>
              <Text>LK Bonus : {e.lkBonus}</Text>
              {/* <Text type="secondary">{e.lkBonus}</Text> */}
            </div>
            <div>
              <Text>Paytm EDC/QR/PG : {e.paytm}</Text>
              {/* <Text type="secondary">{e.paytm}</Text> */}
            </div>
            <div>
              <Text>Transaction No : {e.transactionNo}</Text>
              {/* <Text type="secondary">{e.transactionNo}</Text> */}
            </div>
            <div>
              <Text>Transaction Date & Time :</Text>
              {e.transactionDate != null || e.transactionDate != undefined ? (
                <Text>
                  {" "}
                  {moment(e.transactionDate).format("DD-MM-YYYY HH:mm:ss")}
                </Text>
              ) : null}
              {/* <Text type="secondary">{e.transactionDate}</Text> */}
            </div>

            <div>
              <Text>View : </Text>
              <Button
                disabled={
                  e.invoiceStatus == "Performa Generated" ? false : true
                }
                type="link"
                variant="link"
                onClick={() => this.callInvoicePrint()}
              >
                PI
              </Button>
            </div>

            <div>
              <Text>View : </Text>
              <Button
                disabled={e.invoiceStatus == "Settled" ? false : true}
                type="link"
                variant="link"
                onClick={() => this.callInvoicePrint()}
              >
                Tax Invoice
              </Button>
            </div>
          </div>
        ))}

        <div>
          <Text>View : </Text>
          <Button
            type="link"
            variant="link"
            onClick={() => this.callEstimatedIncoiceprint()}
          >
            Estimated Invoice
          </Button>
        </div>

        <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        <Text strong>Remarks</Text>

        {this.state.remarksData.map((e) => (
          <div>
            <div>
              <Text>Customer Remarks : {e.customerRemark}</Text>
              {/* <Text type="secondary">{e.customerRemark}</Text> */}
            </div>
            <div>
              <Text>Pickup Boy Remarks : {e.pickupBoyRemark}</Text>
              {/* <Text type="secondary">{e.pickupBoyRemark}</Text> */}
            </div>
            <div>
              <Text>Logistics Remarks : {e.logisticsRemarks}</Text>
              {/* <Text type="secondary">{e.logisticsRemarks}</Text> */}
            </div>
            <div>
              <Text>
                Operational Instructions : {e.operationalInstructions}
              </Text>
              {/* <Text type="secondary">{e.operationalInstructions}</Text> */}
            </div>
          </div>
        ))}
        {this.state.paymentData.map((e) => (
          <div>
            <Text> Update Proforma Invoice : {e.updateProformaInvoice}</Text>
            {/* <Text type="secondary">{e.updateProformaInvoice}</Text> */}
          </div>
        ))}
        {this.state.remarksData.map((e) => (
          <div>
            <div>
              <Text>PI Remark Updated By : {e.piRemarkUpdatedBy}</Text>
            </div>
            <div>
              <Text>Invoice Remark : {e.invoiceRemark}</Text>
            </div>
            <div>
              <Text>
                Invoice Remark Updated By : {e.invoiceRemarkUpdatedBy}
              </Text>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // resetFields=()=>{
  //   this.setState({
  //     SearchType:'',
  //     orderId:''
  //   })
  // }

  render() {
    const { SearchType, orderId } = this.state;
    return (
      <div style={{ flex: 1 }}>
        <Form
          ref={this.formRef}
          layout="vertical"
          onFinish={() => {
            this.handlePopupAdd(SearchType, orderId);
          }}
          initialValues={{ SearchType, orderId }}
        >
          <Row gutter={24}>
            <Col xs={24} lg={8}>
              <Form.Item
                name="SearchType"
                rules={[{ required: true, message: "Please Select Any Id!" }]}
              >
                <Select
                  showSearch
                  placeholder="Select ID"
                  onChange={(value) => {
                    this.formRef.current.setFieldsValue({ orderId: undefined });
                    this.setState({
                      SearchType: value,
                      orderId: "",
                      OrderHistoryData: [],
                      spliteOrderwisedata: [],
                      showDetail: false,
                      ShowOrderList: false,
                      ShowPickupList: false,
                    });
                  }}
                >
                  {this.state.pickerData.map((x) => {
                    return (
                      <option key={x.value} value={x.value}>
                        {x.label}
                      </option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Form.Item
                // label="Order/Pickup ID"
                name="orderId"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Order/PickUp Id Or Mobile No.!",
                  },
                ]}
              >
                <Input
                  value={orderId.toString()}
                  // defaultValue={"123"}

                  placeholder={
                    SearchType == 1
                      ? "Enter Order ID"
                      : SearchType == 2
                      ? "Enter Pickup ID"
                      : SearchType == 3
                      ? "Enter Mobile No"
                      : null
                  }
                  onChange={(e) => {
                    let val = e.target.value;
                    this.setState({
                      orderId: val,
                    });
                  }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} lg={8}>
              <Space>
                <Button htmlType="submit" variant="purple">
                  {labels.SUBMIT}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        {this.state.ShowOrderList ? <Text strong>OrderID: </Text> : null}
        {this.state.ShowOrderList &&
        this.state.OrderList != null &&
        this.state.OrderList != undefined &&
        this.state.OrderList.length > 0
          ? this.state.OrderList.map((e) => {
              return (
                //<div style={{marginRight:10}}>
                <Button
                  style={{ marginRight: 10, width: 100, marginBottom: 20 }}
                  onClick={(event) => {
                    // console.log("Id->",event)
                    // this.setState({ SearchType: 1 });
                    this.handlePopupAdd(1, e.orderID);
                  }}
                >
                  {e.orderID}
                </Button>
                // </div>
              );
            })
          : null}
        <br />
        {this.state.ShowPickupList ? <Text strong>PickupID: </Text> : null}
        {this.state.ShowPickupList &&
        this.state.PickupList != null &&
        this.state.PickupList != undefined &&
        this.state.PickupList.length > 0
          ? this.state.PickupList.map((e) => {
              return (
                //<div style={{marginRight:10}}>
                <Button
                  style={{ marginRight: 10, width: 100, marginBottom: 20 }}
                  onClick={(event) => {
                    // console.log("Id->",event)
                    // this.setState({ SearchType: 1 });
                    this.handlePopupAdd(2, e.pickupID);
                  }}
                >
                  {e.pickupID}
                </Button>
                // </div>
              );
            })
          : null}
        {this.state.showDetail ? (
          <div
            style={{
              flex: 1,
              flexDirection: "row",
              display: "flex",
              border: "2px solid black",
            }}
          >
            <div
              style={{
                width: "50%",
                padding: 5,
                borderRight: "2px solid black",
                // borderRadius: 10,
                // marginRight: 20,
                // backgroundColor: "#f3f6f9",
                // boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <div className={Styles.Title}>
                <Text underline>Customer Detail</Text>
                <br></br>
              </div>
              <div>
                <Text>
                  {" "}
                  Customer Name : {
                    this.state.OrderHistoryData.customerName
                  }{" "}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.customerName}
                </Text> */}
              </div>
              <div>
                <Text>Mobile No : {this.state.OrderHistoryData.mobileNo} </Text>
                {/* <Text type="secondary">
                  {" "}
                  {this.state.OrderHistoryData.mobileNo}
                </Text> */}
              </div>
              <div>
                {this.state.OrderHistoryData.emailID == "" ? (
                  <Text>E-mail ID : NA</Text>
                ) : (
                  <Text>
                    E-mail ID : {this.state.OrderHistoryData.emailID}{" "}
                  </Text>
                )}
              </div>
              <div>
                <Text>
                  {" "}
                  Customer Type : {
                    this.state.OrderHistoryData.customerType
                  }{" "}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.customerType}
                </Text> */}
              </div>
              <div>
                <Text>
                  {" "}
                  Customer Order Count :{" "}
                  {this.state.OrderHistoryData.orderCount}{" "}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.orderCount}
                </Text> */}
              </div>
              <div>
                <Text>
                  {" "}
                  Wallet Cash : {this.state.OrderHistoryData.lkCashBlcAmt}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.lkCashBlcAmt}
                </Text> */}
              </div>
              <div>
                <Text>
                  Wallet Bonus : {this.state.OrderHistoryData.lkCreditBlcAmt}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.lkCreditBlcAmt}
                </Text> */}
              </div>
              <div>
                <Text>Last Order Date : </Text>
                {this.state.OrderHistoryData.lastOrderDate != null ||
                this.state.OrderHistoryData.lastOrderDate != undefined ? (
                  <Text>
                    {" "}
                    {moment(
                      this.state.OrderHistoryData.lastOrderDate.split(" ")[0]
                    ).format("DD-MM-YYYY")}
                  </Text>
                ) : null}
              </div>
              <div>
                <Text>
                  LTV : {this.state.OrderHistoryData.totalTaxInvoice}/
                  {this.state.OrderHistoryData.totalOrder} - Avg. Order Value :{" "}
                  {this.state.OrderHistoryData.avgOrderValue}
                </Text>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                padding: 5,
                // width: 350,
                // borderRadius: 20,
                // backgroundColor: "#f3f6f9",
                // boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              }}
            >
              <div className={Styles.Title}>
                <Text underline>Order Detail</Text>
              </div>
              <div>
                <Text>
                  Store Name : {this.state.OrderHistoryData.storeName}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.storeName}
                </Text> */}
              </div>
              <div>
                <Text>
                  Pickup Placed Platform :{" "}
                  {this.state.OrderHistoryData.pickupSourceFrom}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.pickupSourceFrom}
                </Text> */}
              </div>
              <div>
                <Text>Pick ID : {this.state.OrderHistoryData.pickupID}</Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.pickupID}
                </Text> */}
              </div>
              <div>
                <Text>
                  Pickup Boy : {this.state.OrderHistoryData.pickupBoy}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.pickupBoy}
                </Text> */}
              </div>
              <div>
                <Text>Pickup Request Created Date & Time : </Text>
                {this.state.OrderHistoryData.pickupCreatedOn != null ||
                this.state.OrderHistoryData.pickupCreatedOn != undefined ? (
                  <Text>
                    {moment(this.state.OrderHistoryData.pickupCreatedOn).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                  </Text>
                ) : null}

                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.pickupCreatedOn}
                </Text> */}
              </div>
              <div>
                <Text>Pickup Date :</Text>
                {this.state.OrderHistoryData.pickupDate != null ||
                this.state.OrderHistoryData.pickupDate != undefined ? (
                  <Text>
                    {moment(
                      this.state.OrderHistoryData.pickupDate.split(" ")[0]
                    ).format("DD-MM-YYYY")}
                  </Text>
                ) : null}
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.pickupDate}
                </Text> */}
              </div>
              <div>
                <Text>
                  Pickup Time Slot :{" "}
                  {this.state.OrderHistoryData.pickupSlotName}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.pickupSlotName}
                </Text> */}
              </div>
              <div>
                <Text>Pickup call back request : </Text>
                {this.state.OrderHistoryData.pickUpcallbackDate != null ||
                this.state.OrderHistoryData.pickUpcallbackDate != undefined ? (
                  <Text>
                    {moment(
                      this.state.OrderHistoryData.pickUpcallbackDate
                    ).format("DD-MM-YYYY HH:mm:ss")}
                  </Text>
                ) : null}
              </div>
              <div>
                <Text>Order Created Date & Time : </Text>
                {this.state.OrderHistoryData.orderCreatedOn != null ||
                this.state.OrderHistoryData.orderCreatedOn != undefined ? (
                  <Text>
                    {moment(this.state.OrderHistoryData.orderCreatedOn).format(
                      "DD-MM-YYYY HH:mm:ss"
                    )}
                  </Text>
                ) : null}
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.orderCreatedOn}
                </Text> */}
              </div>
              <div>
                <Text>
                  Order Created By :{" "}
                  {this.state.OrderHistoryData.ordercreatedBy}{" "}
                </Text>
              </div>
              <div>
                <Text>
                  Count of Pickup Rescheduled :{" "}
                  {this.state.OrderHistoryData.rescheduleCount}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.rescheduleCount}
                </Text> */}
              </div>
              <div>
                <Text>
                  Promo Code : {this.state.OrderHistoryData.promocode}
                </Text>
                {/* <Text type="secondary">
                  {this.state.OrderHistoryData.promocode}
                </Text> */}
              </div>
            </div>
          </div>
        ) : null}

        <Spin spinning={this.state.modalLoading}>
          <div style={{ marginTop: 10 }}>
            <Tabs
              defaultActiveKey={this.state.defultActivactionkey}
              type="card"
              tabBarStyle={{ color: "black", fontWeight: "bold" }}
              // animated={false}
              onChange={(key) => {
                this.setState({
                  defultActivactionkey: key,
                });
                this.getfactorystatusdata(key);
              }}
            >
              {this.state.spliteOrderwisedata.map((i) => (
                <TabPane tab={i.splitOrderNumber} key={i.splitOrderNumber}>
                  <div
                    style={{
                      flex: 1,
                      // flexDirection: "row",
                      display: "flex",
                      marginBottom: 10,
                      border: "2px solid black",
                    }}
                  >
                    <div className={Styles.factory}>
                      {this.renderItemForFactoryStatus(i.splitOrderNumber)}
                    </div>
                    <div className={Styles.PostProcess}>
                      {this.renderItemForPostProcess()}
                    </div>
                    <div className={Styles.PaymentStatus}>
                      {this.renderItemForPaymentStatus()}
                    </div>
                  </div>
                </TabPane>
              ))}
            </Tabs>
          </div>
        </Spin>
        <Modal
          title={"Order History"}
          visible={this.state.IsLoadMoreData}
          footer={null}
          onCancel={() => {
            this.setState({
              IsLoadMoreData: false,
            });
          }}
          className="modal-form"
          destroyOnClose
        >
          {this.state.factoryData != null && this.state.QcStatusData != null ? (
            <div>
              <Text strong>Factory-In status</Text>
              <div>
                <Text>
                  No. of Garments factory-in :{" "}
                  {this.state.QcStatusData.noOfFactoryInGarments}
                </Text>
              </div>
              <div>
                <Text>
                  Missing Garments : {this.state.QcStatusData.missingGarments}
                </Text>
              </div>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <Text strong>QC status</Text>
              <div>
                <Text>
                  Pending in QC :{" "}
                  {this.state.QcStatusData.garmentPendingInPreQC}
                </Text>
              </div>
              <div>
                <Text>
                  Pre - QC-Rejected :{" "}
                  {this.state.QcStatusData.garmentRejectedInPreQC}
                </Text>
              </div>
              <div>
                <Text>
                  Post - QC-Rejected :{" "}
                  {this.state.QcStatusData.garmentRejectedInPostQC}{" "}
                </Text>
              </div>
              <div>
                <Text>Missed : {this.state.QcStatusData.missed} </Text>
              </div>
              <div>
                <Text>Damage : {this.state.QcStatusData.damage} </Text>
              </div>
            </div>
          ) : null}
        </Modal>
      </div>
    );
  }
}
