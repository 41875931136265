import React from "react";
// import { Form, Input, Checkbox } from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser, faLock } from "@fortawesome/free-solid-svg-icons";
// import { Link, withRouter } from "react-router-dom";
// import Button from "../../components/Common/Button/button";
// import styles from "../../screens/Login/loginscreen.module.css";

export default class Dashboard extends React.Component {
  render() {
    return (
      <div>
        <br />
        <div style={{ width: 100 }}></div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>Welcome To Laundrokart</h1>
      </div>
    );
  }
}
