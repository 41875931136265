import React from "react";
import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Table,
  notification,
} from "antd";
import pendingcashsummaryservice from "../../../services/pendingcashsummaryservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";

export default class dailyordersummarycomponent extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.pendingcashsummaryservice = new pendingcashsummaryservice();
    this.common = new Common();
    this.state = {
      dailyordersummary: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 200,
        total: 0,
        showSizeChanger: true,
      },
      showfilterBox: true,
      loading: true,
      userRights: [],
      isResponseErrorShow: false,
      sorter: {
        field: null,
        order: null,
      },
    };
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }

    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.DAILY_ORDER_SUMMARY
    );

    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.SR_NO,
      dataIndex: "srNo",
      key: "srNo",
      align: "center",
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
      align: "center",
    },

    {
      title: "NO of Orders",
      dataIndex: "totalOrders",
      key: "totalOrders",
      align: "center",
      sorter: true,
    },

    {
      title: "ODM",
      children: [
        {
          title: "NO of Orders",
          dataIndex: "odmOrders",
          key: "odmOrders",
          align: "center",
          sorter: true,
        },
        {
          title: "Amount",
          dataIndex: "odmAmount",
          key: "odmAmount",
          align: "center",
          sorter: true,
        },

        {
          title: "Revenue %",
          dataIndex: "odmRevenue",
          key: "odmRevenue",
          align: "center",
          sorter: true,
        },
      ],
    },

    {
      title: "Walk-In",
      children: [
        {
          title: "NO of Orders",
          dataIndex: "wOrders",
          key: "wOrders",
          align: "center",
          sorter: true,
        },
        {
          title: "Amount",
          dataIndex: "wAmount",
          key: "wAmount",
          align: "center",
          sorter: true,
        },

        {
          title: "Revenue %",
          dataIndex: "wRevenue",
          key: "wRevenue",
          align: "center",
          sorter: true,
        },
      ],
    },

    {
      title: "Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      align: "center",
      sorter: true,
    },

    {
      title: "Revenue %",
      dataIndex: "revenue",
      key: "revenue",
      align: "center",
      sorter: true,
    },
  ];

  
  componentDidMount() {
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      sortColumn: null,
      sortOrder: null,
    });
  }

  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.pendingcashsummaryservice
      .getDailyOrderSummary(params)
      .then((items) => {
        // console.log("items->", items);
        this.setState({
          dailyordersummary: [],
        });
        if (items.statusCode === 200) {
          this.setState({
            dailyordersummary: items.data,
          });
        } else {
          if (!this.state.isResponseErrorShow) {
            notification["error"]({
              message: "Daily Order Summary",
              description: items.message
                ? items.message
                : "Unable to get Daily Order Summary data",
            });
            this.setState({
              isResponseErrorShow: true,
            });
          }
        }
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter, extra) => {
    // console.log("pagination->", pagination);
    this.setState(
      {
        sorter: {
          ...sorter,
          order:
            sorter.order === "ascend"
              ? "asc"
              : sorter.order === "descend"
              ? "desc"
              : sorter.order,
        },
      },
      () => {
        this.fetchItems({
          sortColumn: this.state.sorter.field,
          sortOrder: this.state.sorter.order,   
        });
      }
    );
  };

  render() {
    const { pagination,loading } = this.state;
    const dailyordersummary = this.state.dailyordersummary;
    // console.log("dailyordersummary->", dailyordersummary);
    const listItems = dailyordersummary.map((item, index) => ({
      key: item.storeID,
      srNo: item.rowNumber,
      storeName: item.storeName,
      totalOrders: item.totalOrders,
      totalAmount: item.totalAmount,
      revenue: item.revenue,
      odmOrders:item.odmOrders,
      odmAmount:item.odmAmount,
      odmRevenue:item.odmRevenue,
      wOrders:item.wOrders,
      wAmount:item.wAmount,
      wRevenue:item.wRevenue
    }));
    return (
      <div>
        <PageHeader
          title={labels.DAILY_ORDER_SUMMARY}
          // extra={[
          //   <Button
          //     type="link"
          //     className={styles.FilterToggle}
          //     onClick={() => {
          //       this.setState({ showfilterBox: !this.state.showfilterBox });
          //     }}
          //   >
          //     {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
          //     <FontAwesomeIcon
          //       icon={faChevronUp}
          //       className={
          //         this.state.showfilterBox
          //           ? styles.FilterToggleIcon
          //           : styles.FilterToggleIconRotated
          //       }
          //     />
          //   </Button>,
          // ]}
        />

        <Table
          size="small"
          onRow={(record, rowIndex) => {
            return {
              style: {
                fontWeight: record.storeName == "Grand Total" ? "bold" : null,
                color: record.storeName == "Grand Total" ? "black" : null,
              },
            };
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          bordered
        />
        <TableActions isPagination={false}></TableActions>
      </div>
    );
  }
}
