import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import { labels } from "../shared/language";

class Common {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
  }

  async GetSystemEnums(enumName) {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_SYSTEME_NUMS });
    let itemArray = [];
    let param = "?EnumNames=" + enumName;

    await myApi.endpoints.GetSystemEnums.getOne(param, {})
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    return Promise.resolve(itemArray);
  }

  async refreshToken(callback = () => null) {
    const myApi = new API({ url: this.config.LAUNDRETKART_AUTH });
    myApi.createEntity({ name: this.endpoint.REFRESH_TOKEN });
    return myApi.endpoints[this.endpoint.REFRESH_TOKEN].create({
      token: JSON.parse(localStorage.getItem("currentuser")).token,
      refreshToken: JSON.parse(localStorage.getItem("currentuser"))
        .refreshToken,
    });
    //   .then((response) => {
    //     if (response.data.statusCode === 200) {
    //       localStorage.setItem(
    //         "currentuser",
    //         JSON.stringify(response.data.data)
    //       );
    //       return callback();
    //     } else {
    //       //logout
    //     }
    //   })
    //   .catch((err) => {
    //     //logout
    //   });
    // return Promise.resolve();
  }

  setToken = (response) => {
    if (response.data.data !== null) {
      localStorage.setItem("currentuser", JSON.stringify(response.data.data));
    }
  };

  Message(message) {
    var res =
      labels[
        ![undefined, "", null].includes(message)
          ? message
              .toUpperCase()
              .replace("/", "_")
              .replace("-", "_")
              .replace(/[\*\^\'\!\,\.\-]/g, "")
              .replace("  ", " ")
              .split(" ")
              .join("_")
              .replace("__", "_")
          : ""
      ];
    return ![undefined, null, ""].includes(res) ? res : message;
  }

  PaytmTransNumber(ids, name) {
    let rendomNumber = Math.floor(Math.random() * 899999 + 100000);
    let prefix = "POS";
    let count = "";

    count = ids.indexOf(",") > 0 ? ids.split(",").length : ids.length;
    count =
      name === "Order Payment" ? count.toString().padStart(3, "0") : "001";

    let firstChar =
      ![undefined, null, ""].includes(name) && name.length > 1
        ? name.charAt(0)
        : "";
    let fid =
      ids.indexOf(",") > 0
        ? ids.split(",")[0].toString().padStart(7, "0")
        : ids.padStart(7, "0");

    let transNumber = rendomNumber + prefix + count + firstChar + fid;
    return transNumber;
  }

  handleError(err) {
    let strstatusCode = 500;
    let strmessage = "Something went wrong";
    if (![undefined, null, ""].includes(err.response)) {
      strstatusCode = err.response.status;
    }
    return { statusCode: strstatusCode, message: strmessage };
  }

  
}

export default Common;
