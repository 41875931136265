import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class LKCompensation {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  //     async removeItems(lkPackageID){
  //         const myApi=new API({url:this.config.ITEM_LKWALLET_URL})
  //         myApi.createEntity({name:this.endpoint.DELETE_LKPACKAGE_BY_ID})
  //         let itemArray=[];
  //         let ID="?LKPackageIDs="+lkPackageID
  //         await myApi.endpoints[this.endpoint.DELETE_LKPACKAGE_BY_ID].deleteById(ID)
  //         .then(({data})=>{
  //             itemArray=data
  //         })
  //          .catch(err=>{
  //             this.handleError(err);
  //             console.log(err)
  //             itemArray={
  //                 message:"Internal server error"
  //             }
  //         })
  //        return Promise.resolve(itemArray)
  //    }

  async insertUpdateItems(postData) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.ADD_LKCOMPENSATION });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.ADD_LKCOMPENSATION]
      .getAllPost(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.insertUpdateItems(postData);
      });
    }
    return Promise.resolve(itemArray);
  }
  async getOrderIDS(id, searchStr) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({
      name: this.endpoint.GET_ORDERIDS_COMPENSATION_BY_CUSTID,
    });
    let itemArray = [];

    // let ID = "?SearchString=" + searchStr;
    let ID = "?CustomerID=" + id + "&SearchString=" + searchStr;
    await myApi.endpoints[this.endpoint.GET_ORDERIDS_COMPENSATION_BY_CUSTID]
      .getOne(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getOrderIDS(searchStr);
      });
    }
    return Promise.resolve(itemArray);
  }
  async getCustomerData(searchStr) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_SEARCH_CUSTOMER });
    let itemArray = [];

    let ID = "?SearchString=" + searchStr;
    await myApi.endpoints.GetSearchCustomer.getOne(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getCustomerData(searchStr);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveCompensationConfiguration(param) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.GET_LKCOMPENSATION_MASTER });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.GET_LKCOMPENSATION_MASTER]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveCompensationConfiguration(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}

export default LKCompensation;
