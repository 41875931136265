import React from 'react';
import Faq from '../../components/Faq/faq.component';

export default class faqscreen extends React.Component {
  render() {
    return (
      <Faq></Faq>
    )
  }
}
