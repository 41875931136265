import React from 'react'
import Customerbulkdebit from '../../../components/LKWallet/CustomerBulkDebit/customerbulkdebit.component';

export default class Customerbulkdebits extends React.Component {
    render() {
        return (
            <div>
                <Customerbulkdebit></Customerbulkdebit>
            </div>
        )
    }
}
