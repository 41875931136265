import React from "react";
import { Typography } from "antd";
const { Text } = Typography;
export default class blankscreen extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifySelf: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <Text style={{ fontSize: 20, fontWeight: "bold", color: "#666666" }}>
            Welcome to Karelabel, use sidebar menu to access all functionality.
          </Text>
        </div>

        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <Text style={{ fontSize: 23, fontWeight: "bold", color: "#7e13d5"}}>
           <u>
           Owned and operated by “Kleenco On Demand Services Private Limited”</u> 
          </Text>
        </div> */}
      </div>
    );
  }
}
