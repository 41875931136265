import React,{createRef} from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  notification,
  Tooltip,
  Modal,
  DatePicker,
  Spin,
  Checkbox,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  // faPencilAlt,
  // faInfo,
  // faTrash,
  faUserEdit,
  faClock,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
// import ROUTES from "../../../routes";
import DeliveryList from "../../../services/storedeliverylistservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import PickupService from "../../../services/pickupservice";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";
import storemarkerimg from "../../../images/MapMarker/shoppurple.svg";

const { Search } = Input;
const { Option } = Select;
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);

/**
 * A factory master screen which holds grid view for master screens and form with steps
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
class Master extends React.Component {
  deliveryListService = new DeliveryList();
  UserRightsConfiguration = new UserRightsConfiguration();
  pickupService = new PickupService();
  common = new Common();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    UniquetimeSlot: [],
    SelectedTimeSlot: "",
    deliveryFromDate: moment(ONE_MONTHS_AGO),
    deliveryToDate: moment(),
    DisableReschedule: false,
    HolidayList: [],
    reschedulePopupLoading: false,
    reassignPopupLoading: false,
    cancelPopupLoading: false,
    expenseID: 0,
    showfilterBox: true,
    factories: [],
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    selectedBranch: localStorage.getItem("branchID"),
    showReschedulePopup: false,
    showReassignPopup: false,
    showCancelRequestPopup: false,
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
    popupMode: "add",
    currentDelivery: {},
    selectedDeliverySlot: "",
    deliverySlotFromDate: "",
    timeSlot: {
      disabled: true,
      data: [],
    },
    storeUsers: {
      disabled: true,
      data: [],
    },
    storeID: null,
    deliveryDateCompare: "",
    rescheduleModal: false,
    deliveryStatusFilter: {
      data: [],
      selected: "2",
    },
    showEscalatedDelivery: false,
    TodayDeliveryList: [],
    TodaySlot: [],
    ShowMapView: false,
    SelectedDeliveryData: {},
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.STORE_DELIVERY_LIST
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  handlePopupCancel = () => {
    this.setState(
      {
        showReassignPopup: false,
        showReschedulePopup: false,
        showCancelRequestPopup: false,
        rescheduleModal: true,
      },
      //when cancel button is clicked from reschedule modal only
      //that time need to reset the fields value
      //state var : deliveryDateCompare,rescheduleModal
      () => {
        if (this.state.rescheduleModal === true) {
          this.setState({
            deliveryDateCompare: "",
            deliverySlotFromDate: "",
            currentDelivery: { deliveryDate: "" },
          });
        }
      }
    );
  };

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: labels.SPLIT_ORDER_NO,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },
    {
      title: labels.CUSTOMER,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
      render: (text, record) => {
        return (
          <React.Fragment>
            <div>{record.customerName}</div>
            <small>{record.customerTypeName}</small>
          </React.Fragment>
        );
      },
    },
    {
      title: labels.CUST_MO_No,
      dataIndex: "posContactNumber",
      key: "posContactNumber",
      sorter: true,
    },
    {
      title: labels.ADDRESS,
      dataIndex: "address",
      key: "address",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (remarks) => {
        return (
          <Tooltip placement="topLeft" title={remarks}>
            {remarks}
          </Tooltip>
        );
      },
    },
    {
      title: labels.STORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
    {
      title: labels.DELIVERY_TYPE,
      dataIndex: "deliveryTypeName",
      key: "deliveryTypeName",
      sorter: true,
    },
    // {
    //   title: labels.STOREINDATE,
    //   dataIndex: "storeInDatetime",
    //   key: "storeInDatetime",
    //   sorter: true,
    //   render: (text) => {
    //     return moment(text).format("YYYY-MM-DD") === "0001-01-01"
    //       ? null
    //       : moment(text).format("DD-MM-YYYY");
    //   },
    // },
    // {
    //   title: labels.FACTORYOUTDATE,
    //   dataIndex: "factoryOutDatetime",
    //   key: "factoryOutDatetime",
    //   sorter: true,
    //   render: (text) => {
    //     return moment(text).format("YYYY-MM-DD") === "0001-01-01"
    //       ? null
    //       : moment(text).format("DD-MM-YYYY");
    //   },
    // },

    {
      title: labels.DELIVERY_DATE,
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.SLOT,
      dataIndex: "deliverySlotName",
      key: "deliverySlotName",
      sorter: true,
    },
    {
      title: labels.STATUS,
      dataIndex: "statusName",
      key: "statusName",
      sorter: true,
      // width: "100px",
    },
    {
      title: labels.DELIVERY_BOY,
      dataIndex: "assignedToUserName",
      key: "assignedToUserName",
      sorter: true,
    },
    {
      title: labels.CANCEL_REMARK,
      dataIndex: "cancelRemark",
      key: "cancelRemark",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (remarks) => {
        return (
          <Tooltip placement="topLeft" title={remarks}>
            {remarks}
          </Tooltip>
        );
      },
    },

    {
      title: labels.ACTIONS,
      key: "actions",
      width: "100px",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true &&
          [1, 2].includes(parseInt(record.status)) ? (
            <Tooltip title={labels.REASSIGN}>
              <Button
                type="link"
                onClick={() =>
                  this.setState(
                    {
                      currentDelivery: {
                        assignedToUserID: parseInt(record.assignedToUserID),
                        deliveryID: record.deliveryID,
                        status: record.status,
                      },
                    },
                    () => this.setState({ showReassignPopup: true })
                  )
                }
              >
                <FontAwesomeIcon icon={faUserEdit} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true &&
          [1, 2, 6].includes(parseInt(record.status)) ? (
            <Tooltip title={labels.RESCHEDULE}>
              <Button
                type="link"
                onClick={() =>
                  this.setState(
                    {
                      popupMode: "edit",
                      currentDelivery: {
                        deliveryID: record.deliveryID,
                        status: record.status,
                        deliveryDate: moment(record.deliveryDate),
                        deliverySlot: record.deliverySlotName,
                        storeID: record.storeID,
                      },
                    },
                    () => {
                      this.setState({ showReschedulePopup: true });
                      this.getTimeSlot(this.state.currentDelivery.storeID);
                      this.getHolidayList();
                    }
                  )
                }
              >
                <FontAwesomeIcon icon={faClock} />
              </Button>
            </Tooltip>
          ) : null}
           {/* Commented By Hasti - L17172 Delivery page - remove cancel option. */}
          {/* {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true &&
          [1, 2].includes(parseInt(record.status)) ? (
            <Tooltip title={labels.CANCEL}>
              <Button
                type="link"
                onClick={() =>
                  this.setState(
                    {
                      popupMode: "edit",
                      currentDelivery: {
                        deliveryID: record.deliveryID,
                        status: record.status,
                      },
                    },
                    () => this.setState({ showCancelRequestPopup: true })
                  )
                }
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </Button>
            </Tooltip>
          ) : null} */}
        </Space>
      ),
    },
    {
      title: labels.CS_ACTION,
      key: "csaction",
      render: (text, record) => (
        <React.Fragment>
          <Space>
            <Popconfirm
              title={labels.ARE_YOU_SURE_YOU_WANT_ESCALATE_DELIVERY}
              onConfirm={() => this.EscalateDelivery(record.splitOrderNumber)}
              onCancel={() => null}
              okText={labels.YES}
              cancelText={labels.NO}
            >
              <Button type="link">Escalate</Button>
            </Popconfirm>
          </Space>
        </React.Fragment>
      ),
    },
  ];

  EscalateDelivery = (splitOrderNumber) => {
    let param = {
      SplitOrderNumber: splitOrderNumber,
    };
    this.deliveryListService.escalateDelivery(param).then((response) => {
      // console.log("response->", response);
      if (response.statusCode === 200) {
        // console.log("in if");
        notification["success"]({
          message: labels.DELIVERIES,
          description: response.message
            ? this.common.Message(response.message)
            : labels.ESCALATED_SUCCESSFULLY,
          duration: 1.0,
          onClose: () => window.location.reload(),
        });
      } else {
        console.log("in else");
        notification["error"]({
          message: labels.DELIVERIES,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_ESCALATE_DELIVERY,
        });
      }
    });
  };

  componentDidMount() {
    // if (this.props.match.path !== ["/store/delivery-list"]) {
    //   return this.props.history.goBack();
    // }
    this.getDeliveryList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
    this.getStoreUsers();
    this.getDeliveryStatusList();
    this.getSlotByStoreIDS(this.state.selectedBranch);
    // this.getTimeSlot();
  }
  getHolidayList = () => {
    var postData = parseInt(localStorage.getItem("branchID"));
    this.pickupService.retriveHolidaylist(postData).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          HolidayList: response.data,
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_HOLIDAYLIST,
        });
      }
    });
  };

  getDeliveryStatusList = () => {
    this.deliveryListService.getDeliveryStatusList().then((response) => {
      if (response.statusCode === 200) {
        let data = response.data.map((single) => {
          return {
            value: single.enumDetailID,
            label: single.enumDetailName,
          };
        });
        this.setState({
          deliveryStatusFilter: {
            ...this.state.deliveryStatusFilter,
            data: data,
          },
        });
      } else {
        notification["error"]({
          message: labels.DELIVERIES,
          description: labels.UNABLE_TO_GET_DATA,
        });
      }
    });
  };
  getStoreUsers = () => {
    this.deliveryListService.getStoreUsers().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          storeUsers: {
            ...this.state.storeUsers,
            disabled: false,
            data: response.data,
          },
        });
      }
    });
  };

  getSlotByStoreIDS = (IDS) => {
    //  console.log("IDS->",IDS);
    this.pickupService.getSlotListByStoreIDS(IDS).then((response) => {
      if (response.statusCode === 200) {
        // console.log("response->", response);
        this.setState({
          UniquetimeSlot: response.data,
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_DATA_FOR_TIMESLOT,
        });
      }
    });
  };

  getTimeSlot = (storeID) => {
    // if (this.state.deliverySlotFromDate !== "") {
    //   this.setState({ currentDelivery: { deliverySlot: NaN } });
    // }
    // var storeid = parseInt(localStorage.getItem("branchID"));
    this.pickupService.getTimeSlotList(storeID).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          timeSlot: {
            ...this.state.timeSlot,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_DATA_FOR_TIMESLOT,
        });
      }
    });
  };

  handleReassign = (values) => {
    if (this.state.factories.length != 0) {
      if (![1, 2].includes(parseInt(this.state.currentDelivery.status))) {
        notification["error"]({
          message: labels.DELIVERIES,
          description:
            labels.CANNOT_REASSIGN_DELIVERY_AS_IT_IS_ALREADY_PROCEEDED_FURTHER,
        });
      } else {
        this.setState({ reassignPopupLoading: true });
        this.deliveryListService
          .reassignDeliveryRequest({
            deliveryID: values.deliveryID,
            assignedToUserID: values.assignedToUserID,
            sourceFrom: 1,
          })
          .then((response) => {
            if (response.statusCode === 200) {
              this.getDeliveryList(
                this.state.pagination.current,
                this.state.pagination.pageSize,
                this.state.sorter.field,
                this.state.sorter.order,
                this.state.search
              );
              this.setState({ showReassignPopup: false });
              notification["success"]({
                message: labels.DELIVERIES,
                description:
                  response.message != undefined || response.message != null
                    ? this.common.Message(response.message)
                    : labels.DELIVERY_REASSIGNED_SUCCESSFULLY,
              });
            } else {
              notification["error"]({
                message: labels.DELIVERIES,
                description:
                  response.message != undefined || response.message != null
                    ? this.common.Message(response.message)
                    : labels.UNABLE_TO_REASSIGN_DELIVERY_REQUEST,
              });
            }
            this.setState({ reassignPopupLoading: false });
          });
      }
    }
  };

  formInstance = React.createRef();

  handleReschedule = (values) => {
    if (this.state.factories.length != 0) {
      if (![1, 2, 6].includes(parseInt(this.state.currentDelivery.status))) {
        notification["error"]({
          message: labels.DELIVERIES,
          description:
            labels.DELIVERY_STATUS_MUST_BE_DELIVERY_SCHEDULED_OR_ASSIGNED,
        });
      } else {
        this.setState({ reschedulePopupLoading: true });
        this.deliveryListService
          .rescheduleDeliveryRequest({
            deliveryID: values.deliveryID,
            deliveryDate: moment(values.deliveryDate).format("YYYY-MM-DD"),
            deliverySlotID: parseInt(values.deliverySlot),
            remark: values.remark,
            sourceFrom: 1,
          })
          .then((response) => {
            if (response.statusCode === 200) {
              notification["success"]({
                message: labels.DELIVERIES,
                description: [null, undefined].includes(response.message)
                  ? labels.DELIVERY_RESCHEDULED_SUCCESSFULLY
                  : this.common.Message(response.message),
              });
              this.getDeliveryList(
                this.state.pagination.current,
                this.state.pagination.pageSize,
                this.state.sorter.field,
                this.state.sorter.order,
                this.state.search
              );
              this.setState({ showReschedulePopup: false });
            } else {
              notification["error"]({
                message: labels.DELIVERIES,
                description:
                  response.message != undefined || response.message != null
                    ? this.common.Message(response.message)
                    : labels.UNABLE_TO_RESCHEDULE_DELIVERY_REQUEST,
              });
            }
            this.setState({ reschedulePopupLoading: false });
          });
      }
    }
  };

  formRef = React.createRef();
  handleCancel = (values) => {
    if (this.state.factories.length != 0) {
      if (![1, 2].includes(parseInt(this.state.currentDelivery.status))) {
        notification["error"]({
          message: labels.DELIVERIES,
          description:
            labels.DELIVERY_STATUS_MUST_BE_DELIVERY_SCHEDULED_OR_ASSIGNED,
        });
      } else {
        this.setState({ cancelPopupLoading: true });
        this.deliveryListService
          .cancelDeliveryRequest({
            deliveryID: values.deliveryID,
            cancelReason: values.cancelReason,
          })
          .then((response) => {
            if (response.statusCode === 200) {
              this.getDeliveryList(
                this.state.pagination.current,
                this.state.pagination.pageSize,
                this.state.sorter.field,
                this.state.sorter.order,
                this.state.search
              );
              this.setState({ showCancelRequestPopup: false });
              notification["success"]({
                message: labels.DELIVERIES,
                description:
                  response.message != undefined || response.message != null
                    ? this.common.Message(response.message)
                    : labels.REQUEST_CANCELLED_SUCCESSFULLY,
              });
            } else {
              notification["error"]({
                message: labels.DELIVERIES,
                description:
                  response.message != undefined || response.message != null
                    ? this.common.Message(response.message)
                    : labels.UNABLE_TO_CANCEL_DELIVERY_REQUEST,
              });
            }
            this.setState({ cancelPopupLoading: false });
          });
      }
    }
  };

  getDeliveryList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    if (!this.state.tableLoading) {
      this.setState({ tableLoading: true });
      this.deliveryListService
        .getAllDeliveryList(
          index,
          size,
          sortColumn,
          sortOrder,
          search,
          this.state.selectedBranch,
          this.state.deliveryStatusFilter.selected,
          this.state.showEscalatedDelivery,
          this.state.deliveryFromDate,
          this.state.deliveryToDate,
          this.state.SelectedTimeSlot
        )
        .then((response) => {
          if (response.statusCode === 200) {
            if (
              response.data.length === 0 &&
              this.state.pagination.current !== 1
            ) {
              this.setState(
                {
                  pagination: { ...this.state.pagination, current: 1 },
                  tableLoading: false,
                },
                () => {
                  this.getDeliveryList(1, size, sortColumn, sortOrder, search);
                }
              );
            }
            let factoryData = [];
            response.data.map((singleData, index) => {
              singleData.key = singleData.deliveryID;
              factoryData.push(singleData);
              return null;
            });

            this.setState({ factories: factoryData });
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: factoryData.length ? factoryData[0].totalRecords : 0,
                current: factoryData.length ? this.state.pagination.current : 1,
              },
            });
          } else {
            notification["error"]({
              message: labels.DELIVERIES,
              description: response.message
                ? response.message
                : labels.UNABLE_TO_GET_LIST_OF_DELIVERY_LIST_DATA,
            });
          }
          this.setState({ tableLoading: false });
        });
    }
  };

  handleDeliverySlotChange = (event) => {
    this.setState({ selectedDeliverySlot: event });
  };

  handleChangeBranch = () => {
    const { pagination } = this.state;
    this.getDeliveryList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
    this.getSlotByStoreIDS(this.state.selectedBranch);
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  getTodayDeliverylist = () => {
    let params = {
      storeID: parseInt(localStorage.getItem("branchID")),
      sourceFrom:1,
      userID:0
    };
  

    this.deliveryListService.getTodayDeliverylist(params).then((response) => {
      if (response.statusCode === 200) {
        console.log("response->", response);

        this.setState({
          TodayDeliveryList: response.data.deliveryList,
          TodaySlot: response.data.slotList,
          ShowMapView: true,
        });

        {
          response.data.deliveryList !== null &&
            response.data.deliveryList !== undefined &&
            response.data.deliveryList.length > 0 &&
            this.initMap();
        }
      } else {
        notification["error"]({
          message: labels.DELIVERIES,
          description: response.message
            ? response.message
            : labels.UNABLE_TO_GET_LIST_OF_DELIVERY_LIST_DATA,
        });
      }
    });
  };

  initMap = (CheckboxValueType) => {
    var map;
    var bounds = new window.google.maps.LatLngBounds();
    let Storemarkers = this.state.TodayDeliveryList;
    var Storeposition = new window.google.maps.LatLng(
      Storemarkers[0].storeLat,
      Storemarkers[0].storeLong
    );

    this.setState({
      SelectedDeliveryData: {},
    });

    var mapOptions = {
      mapTypeId: "roadmap",
      center: Storeposition,
      zoom: 50,
    };

    const icon = {
      url: storemarkerimg,
      size: new window.google.maps.Size(50, 50),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(17, 34),
    };

    // Display a map on the web page
    map = new window.google.maps.Map(
      document.getElementById("map"),
      mapOptions
    );
    map.setTilt(50);
    //for pickup marker
    if (CheckboxValueType != undefined || CheckboxValueType != null) {
      let selectedmarker = CheckboxValueType.map((slot) => {
        return this.state.TodayDeliveryList.filter(
          (item) => item.slotID == slot
        );
      });

      // console.log("selectedmarker->", selectedmarker);
      // Add multiple markers to map
      var infoWindow = new window.google.maps.InfoWindow(),
        marker,
        i;

      // Place pickup marker on the map
      this.state.TodaySlot.map((slot) => {
        // console.log("slot->",slot);
        selectedmarker.map((markers) => {
          // console.log("markers->",markers)
          for (i = 0; i < markers.length; i++) {
            console.log("--", markers[i]);
            var position = new window.google.maps.LatLng(
              markers[i].latitude,
              markers[i].longitude
            );
            // map.setCenter(position);
            // bounds.extend(position);
            marker = new window.google.maps.Marker({
              position: position,
              map: map,
              title: markers[i].address,
              label: {
                text: `${markers[i].splitOrderNumber}`,
                color: this.state.TodaySlot.filter((e) => {
                  return e.slotID == markers[i].slotID;
                })[0].colorName,
                // fontSize: "20px",
                // fontWeight: "bold",
              },
              icon: {
                path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
                fillColor: this.state.TodaySlot.filter(
                  (e) => e.slotID == markers[i].slotID
                )[0].colorName,
                fillOpacity: 1,
                strokeWeight: 1,
                // rotation: 0,
                scale: 1.2,
                anchor: new window.google.maps.Point(0, 20),
                labelOrigin: new window.google.maps.Point(0, 30),
              },
            });

            // Add info window to marker
            var self = this;
            window.google.maps.event.addListener(
              marker,
              "click",
              (function (marker, i) {
                var tempobj = {
                  splitOrderNumber: markers[i].splitOrderNumber,
                  customerName: markers[i].customerName,
                  mobileNo: markers[i].mobileNo,
                  address:markers[i].address
                };

                return function () {
                  self.setState({
                    SelectedDeliveryData: tempobj,
                  });
                  infoWindow.setContent(markers[i].address);
                  infoWindow.open(map, marker);
                  navigator.geolocation.getCurrentPosition(function(position){
                    window.open("https://www.google.com/maps/dir/?api=1&origin="+position.coords.latitude+","+position.coords.longitude+"&destination="+markers[i].latitude+","+markers[i].longitude,'_blank','noreferrer')
                })
                };
              })(marker, i)
            );

            // Center the map to fit all markers on the screen
            map.fitBounds(bounds);
          }
        });
      });
    }

    //for Store Marker -- Start
    var infoWindow = new window.google.maps.InfoWindow(),
      storemarker,
      i;

    map.setCenter(Storeposition);
    bounds.extend(Storeposition);
    storemarker = new window.google.maps.Marker({
      position: Storeposition,
      map: map,
      icon,
      title: Storemarkers[0].storeAddress,
    });

    window.google.maps.event.addListener(
      storemarker,
      "click",
      (function (storemarker, i) {
        return function () {
          infoWindow.setContent(Storemarkers[0].storeAddress);
          infoWindow.open(map, storemarker);
        };
      })(storemarker, i)
    );

    map.fitBounds(bounds);
    // End for Store

    // Set zoom level
    var boundsListener = window.google.maps.event.addListener(
      map,
      "bounds_changed",
      function (event) {
        this.setZoom(10);
        window.google.maps.event.removeListener(boundsListener);
      }
    );
  };

  render() {
    const { pagination } = this.state;
    const disableAll = this.state.popupMode === "view";
    const storeBranchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo")).filter(
          (x) => x.branchType === "Store"
        )
      : [];

    const allStoreBranchArray = storeBranchTypes
      .map((branchType) => {
        return branchType.branchID;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();

    var currentTime = moment().format("HH:mm:00").toString();

    return (
      <div>
        <PageHeader
          title={labels.DELIVERY_LIST}
          extra={[
            <Button
              variant="purple"
              className='viewMapBtn'
              onClick={() => this.getTodayDeliverylist()}
            >
              View On Map
            </Button>,
            <div key="4" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={this.state.showEscalatedDelivery}
                onChange={(e) => {
                  if (e.target.checked) {
                    this.setState(
                      {
                        showEscalatedDelivery: true,
                      },
                      () => {
                        this.getDeliveryList(
                          this.state.pagination.current,
                          this.state.pagination.pageSize,
                          this.state.sorter.field,
                          this.state.sorter.order,
                          this.state.search
                        );
                      }
                    );
                  } else {
                    this.setState(
                      {
                        showEscalatedDelivery: false,
                      },
                      () => {
                        this.getDeliveryList(
                          this.state.pagination.current,
                          this.state.pagination.pageSize,
                          this.state.sorter.field,
                          this.state.sorter.order,
                          this.state.search
                        );
                      }
                    );
                  }
                }}
              >
                {labels.ESCALATED_DELIVERY}
              </Checkbox>
            </div>,
            <div key="0" style={{ alignSelf: "center" }}>
              <Select
                showSearch
                mode="multiple"
                allowClear
                placeholder={labels.SELECT_STATUS}
                className={styles.test1}
                style={{ minWidth: 150, maxWidth: 250 }}
                value={
                  [null, undefined, "", []].includes(
                    this.state.deliveryStatusFilter.selected
                  )
                    ? undefined
                    : this.state.deliveryStatusFilter.selected.split(",")
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value) =>
                  this.setState(
                    {
                      deliveryStatusFilter: {
                        ...this.state.deliveryStatusFilter,
                        selected: [null, undefined, ""].includes(value)
                          ? undefined
                          : value.join(","),
                      },
                    },
                    () => {
                      this.getDeliveryList(
                        this.state.pagination.current,
                        this.state.pagination.pageSize,
                        this.state.sorter.field,
                        this.state.sorter.order,
                        this.state.search
                      );
                    }
                  )
                }
              >
                {this.state.deliveryStatusFilter.data.map((x) => {
                  return x.value != "-1" ? (
                    <option key={x.value.toString()} value={x.value.toString()}>
                      {x.label}
                    </option>
                  ) : null;
                })}
              </Select>
            </div>,
            <div key="1" style={{ alignSelf: "center" }}>
              <Select
                showSearch
                mode="multiple"
                allowClear
                // onChange={this.handleSelect}
                onChange={(event) => {
                  this.setState(
                    {
                      selectedBranch: [null, undefined, ""].includes(event)
                        ? ""
                        : event.join(","),
                    },
                    () => {
                      this.handleChangeBranch();
                    }
                  );
                }}
                value={
                  [null, undefined, ""].includes(this.state.selectedBranch)
                    ? [localStorage.getItem("branchID")]
                    : this.state.selectedBranch.split(",")
                }
                placeholder={labels.SELECT_STORE}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ minWidth: 150, maxWidth: 330 }}
              >
                {storeBranchTypes.length !== 0
                  ? storeBranchTypes.map((branchType) => {
                      return branchType.branchID !== "-1" ? (
                        <Option
                          value={branchType.branchID.toString()}
                          key={branchType.branchID.toString()}
                        >
                          {branchType.branchName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </div>,
            <div key="2" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={
                  ![null, undefined, ""].includes(this.state.selectedBranch) &&
                  this.state.selectedBranch.split(",").length ===
                    allStoreBranchArray.length &&
                  this.state.selectedBranch.split(",").slice().sort()
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    let allOpt = storeBranchTypes
                      .map((branchType) => {
                        return branchType.branchID;
                      })
                      .filter((x) => x !== "-1");
                    this.setState(
                      {
                        selectedBranch: allOpt.join(","),
                        // storeKeys: [],
                        // factoryKeys: [],
                        // // tagNos:""
                      },
                      () => {
                        if (this.state.selectedBranch !== "") {
                          this.handleChangeBranch();
                        }
                        // else {
                        //   this.setState({
                        //     storeOutTags: [],
                        //     btnMissedTagEnable: true,
                        //   });
                        // }
                      }
                    );
                  } else {
                    this.setState(
                      {
                        selectedBranch: localStorage.getItem("branchID"),
                      },
                      () => {
                        this.handleChangeBranch();
                      }
                    );
                  }
                }}
              >
                {labels.ALL}
              </Checkbox>
            </div>,
            <div key="3" style={{ alignSelf: "center" }}>
              <Search
                placeholder={labels.SEARCH}
                className={styles.SearchInput}
                key="1"
                onChange={(event) => {
                  this.setState({
                    search: event.target.value.toString().toLowerCase(),
                  });
                  this.getDeliveryList(
                    this.state.pagination.current,
                    this.state.pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    event.target.value.toString().toLowerCase()
                  );
                }}
              />
            </div>,

            // /**
            //  * Add Factory Button redirects to add form
            //  */
            // this.state.userRights.length > 0 &&
            // this.state.userRights[0].isAdd == true ? (
            //   <Button
            //     variant="purple"
            //     htmlType="submit"
            //     onClick={() =>
            //       this.setState({ expenseID: 0, showAddPopup: true })
            //     }
            //     key="2"
            //   >
            //     Add Expense
            //   </Button>
            // ) : null,
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
              key="3"
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() => {
              this.getDeliveryList(
                this.state.pagination.current,
                this.state.pagination.pageSize,
                this.state.sorter.field,
                this.state.sorter.order,
                this.state.search
              );
            }}
            initialValues={{
              deliveryFromDate: this.state.deliveryFromDate,
              deliveryToDate: this.state.deliveryToDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"deliveryFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "deliveryFromDate")
                    }
                    value={this.state.deliveryFromDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(moment(), "day") ? true : false
                    // }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"deliveryToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "deliveryToDate")
                    }
                    value={this.state.deliveryToDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(moment(), "day") ? true : false
                    // }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={6}>
                <Form.Item name="slot">
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder={"delivery Timeslot"}
                    className={styles.test1}
                    style={{ minWidth: 150, maxWidth: 250 }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      this.setState(
                        {
                          SelectedTimeSlot: [null, undefined, ""].includes(
                            value
                          )
                            ? undefined
                            : value.join(","),
                        },
                        () => {
                          // console.log(this.state.OrderTypeFilter.selected);
                        }
                      )
                    }
                  >
                    {this.state.UniquetimeSlot.map((e) => {
                      return e.slot != "" ? (
                        <option key={e.slot} value={e.slot}>
                          {e.slot}
                        </option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          onRow={(record, rowIndex) => {
            return {
              style: { background: record.bgColor },
            };
          }}
          scroll={
            this.columns.length < 4
              ? { x: 0 }
              : this.columns.length < 6
              ? { x: 1000 }
              : this.columns.length > 6 && this.columns.length <= 12
              ? { x: 1400 }
              : { x: 2000 }
          }
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getDeliveryList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />

        <Modal
          width={600}
          title={labels.REASSIGN_DELIVERY}
          visible={this.state.showReassignPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.reassignPopupLoading}>
            <Form
              layout="vertical"
              onFinish={(values) => {
                this.handleReassign(values);
              }}
              initialValues={this.state.currentDelivery}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label={labels.ASSIGNED_TO}
                    name="assignedToUserID"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_ASSIGNEE,
                      },
                    ]}
                  >
                    <Select
                      disabled={disableAll || this.state.storeUsers.disabled}
                      placeholder={labels.SELECT_ASSIGNEE}
                    >
                      {this.state.storeUsers.data.length !== 0
                        ? this.state.storeUsers.data.map((res) => {
                            return res.value !== "-1" ? (
                              <Option
                                value={parseInt(res.value)}
                                key={res.value}
                              >
                                {res.text}
                              </Option>
                            ) : null;
                          })
                        : null}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={labels.DELIVERY_ID}
                    name="deliveryID"
                    rules={[
                      { required: true, message: "Please select Assignee" },
                    ]}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.popupMode === "view" ? (
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        {labels.CANCEL}
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.popupMode === "add"
                            ? labels.REASSIGN_DELIVERY
                            : labels.REASSIGN_DELIVERY}
                        </Button>
                      </React.Fragment>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>

        <Modal
          width={600}
          title={labels.RESCHEDULE_DELIVERY}
          visible={this.state.showReschedulePopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.reschedulePopupLoading}>
            <Form
              layout="vertical"
              onFinish={(values) => {
                this.handleReschedule(values);
              }}
              initialValues={this.state.currentDelivery}
              ref={this.formInstance}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label={labels.DELIVERY_ID}
                    name="deliveryID"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_ASSIGNEE,
                      },
                    ]}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={labels.DELIVERY_DATE}
                    name="deliveryDate"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_DELIVERY_DATE,
                      },
                    ]}
                  >
                    <DatePicker
                      onChange={(value) => {

                        this.formInstance.current.setFieldsValue({
                          deliverySlot: undefined,
                        });

                        let temparr = this.state.HolidayList;
                        var HolidayArr = [];
                        var delivery_date = moment(value).format("YYYY-MM-DD");
                        HolidayArr = temparr.filter((a) => {
                          var holiday_date = moment(a.holidayDate).format(
                            "YYYY-MM-DD"
                          );

                          return delivery_date == holiday_date;
                        });
                        let len = HolidayArr.length;
                        if (len > 0) {
                          notification["error"]({
                            message: HolidayArr[0].remark,
                            description: labels.SELECTED_DATE_IS_HOLIDAY,
                          });
                          this.setState({ DisableReschedule: true });
                        } else {
                          this.setState({ DisableReschedule: false });

                          this.setState(
                            {
                              deliveryDateCompare: value,
                              deliverySlotFromDate: moment(value).format("ddd"),
                            },
                            () => {
                              // this.setState({
                              //   currentDelivery: { deliverySlot: undefined },
                              // });
                              this.getTimeSlot(
                                this.state.currentDelivery.storeID
                              );
                            }
                          );
                        }
                      }}
                      disabledDate={(currentDate) =>
                        currentDate.isBefore(moment(), "day") ? true : false
                      }
                      format={"DD-MM-YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label={labels.DELIVERY_TIMESLOT}
                    name="deliverySlot"
                    onChange={this.handleDeliverySlotChange}
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_TIMESLOT,
                      },
                    ]}
                  >
                    <Select placeholder={labels.SELECT_TIMESLOT}>
                      {this.state.timeSlot.data.length !== 0
                        ? this.state.timeSlot.data.map((timeSlot) => {
                            return timeSlot.value !== "-1" ? (
                              [""].includes(this.state.deliverySlotFromDate) ? (
                                moment(
                                  this.state.currentDelivery.deliveryDate
                                ).format("ddd") === timeSlot.weekDayName ? (
                                  <Option
                                    value={timeSlot.slotID}
                                    key={timeSlot.slotID}
                                    disabled={
                                      this.state.currentDelivery.deliveryDate.format(
                                        "YYYY-MM-DD"
                                      ) < moment().format("YYYY-MM-DD")
                                        ? true
                                        : false //this.state.deliveryDateCompare
                                      //? this.state.deliveryDateCompare.format(
                                      //   "YYYY-MM-DD"
                                      //  ) === moment().format("YYYY-MM-DD")  ?
                                      // timeSlot.slotFrom < currentTime
                                      // ? true
                                      // : false
                                      // : false
                                      // : null
                                    }
                                  >
                                    {timeSlot.slotFrom.replace(":00", "") +
                                      "-" +
                                      timeSlot.slotTo.replace(":00", "")}
                                  </Option>
                                ) : null
                              ) : this.state.deliverySlotFromDate ===
                                timeSlot.weekDayName ? (
                                <Option
                                  value={timeSlot.slotID}
                                  key={timeSlot.slotID}
                                  disabled={
                                    this.state.deliveryDateCompare
                                      ? this.state.deliveryDateCompare.format(
                                          "YYYY-MM-DD"
                                        ) === moment().format("YYYY-MM-DD")
                                        ? timeSlot.slotFrom < currentTime
                                          ? true
                                          : false
                                        : false
                                      : null
                                  }
                                >
                                  {timeSlot.slotFrom.replace(":00", "") +
                                    "-" +
                                    timeSlot.slotTo.replace(":00", "")}
                                </Option>
                              ) : null
                            ) : null;
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label={labels.REMARKS}
                    name="remark"
                    rules={[
                      { required: true, message: labels.PLEASE_ENTER_REMARKS },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.popupMode === "view" ? (
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        {labels.CANCEL}
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                        <Button
                          htmlType="submit"
                          variant="purple"
                          disabled={this.state.DisableReschedule}
                        >
                          {this.state.popupMode === "add"
                            ? labels.ADD
                            : labels.RESCHEDULE_DELIVERY}
                        </Button>
                      </React.Fragment>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>

        <Modal
          width={600}
          title={labels.CANCEL_DELIVERY}
          visible={this.state.showCancelRequestPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.cancelPopupLoading}>
            <Form
              layout="vertical"
              ref={this.formRef}
              onFinish={(values) => {
                this.handleCancel(values);
              }}
              initialValues={this.state.currentDelivery}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label={labels.DELIVERY_ID}
                    name="deliveryID"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_ASSIGNEE,
                      },
                    ]}
                    hidden
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={labels.CANCEL_REASON}
                    name="cancelReason"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_CANCEL_REASON,
                      },
                    ]}
                  >
                    <Input placeholder={labels.ENTER_CANCEL_REASON} />
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.popupMode === "view" ? (
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        {labels.CANCEL}
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>

                        <Popconfirm
                          title={labels.ARE_YOU_SURE}
                          onConfirm={() =>
                            this.formRef.current
                              .validateFields()
                              .then(this.handleCancel)
                          }
                          onCancel={() => null}
                          okText={labels.YES}
                          cancelText={labels.NO}
                        >
                          <Button htmlType="submit" variant="purple">
                            {this.state.popupMode === "add"
                              ? labels.ADD
                              : labels.CANCEL_DELIVERY}
                          </Button>
                        </Popconfirm>
                      </React.Fragment>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        <Modal
          title={"Delivery List"}
          visible={this.state.ShowMapView}
          footer={null}
          onCancel={() => {
            this.setState({
              ShowMapView: false,
            });
          }}
          className="modal-form pickupslot"
          destroyOnClose
        >
          {this.state.TodayDeliveryList !== null &&
          this.state.TodayDeliveryList !== undefined &&
          this.state.TodayDeliveryList.length > 0 ? (
            <div>
              <Row gutter={24} style={{ border: "2px solid black" ,margin:0}}>
                <Col xs={24} lg={10}>
                  <div>
                    Store Name : {localStorage.getItem("Store/FactoryName")}
                  </div>
                  <div>Date : {moment().format("DD-MM-YYYY")}</div>
                  <div>
                    Total Delivery : {this.state.TodayDeliveryList.length}
                  </div>
                </Col>
                <Col xs={24} lg={14}>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    onChange={this.initMap}
                  >
                    {" "}
                    <Row>
                      {this.state.TodaySlot.map((item, index) => {
                        return (
                          <Col span={12}>
                            <Checkbox
                              value={item.slotID}
                              style={{
                                color: item.colorName,
                              }}
                            >
                              {item.slotName}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                </Col>
              </Row>
              <Row
                className="mapview"
                gutter={24}
                style={{
                  borderBottom: "2px solid black",
                  borderLeft: "2px solid black",
                  borderRight: "2px solid black",
                  margin:0
                }}
              >
                <Col xs={24} lg={6}>
                <b>SplitOrder Number</b>
                  {this.state.TodayDeliveryList.map((e) => {
                    return (
                      <div>
                        <Button
                          type="link"
                          onClick={() => {
                            this.setState({
                              SelectedDeliveryData: {
                                splitOrderNumber: e.splitOrderNumber,
                                customerName: e.customerName,
                                mobileNo: e.mobileNo,
                                address:e.address
                              },
                            });
                          }}
                        >
                          {console.log("slot->",this.state.TodaySlot)}
                          <text
                            style={{
                              color: this.state.TodaySlot.filter((item) => {
                                return item.slotID == e.slotID;
                              })[0].colorName,
                            }}
                          >
                           {e.splitOrderNumber}
                          </text>
                         
                        </Button>
                        <br />
                      </div>
                    );
                  })}
                </Col>
                <Col xs={24} lg={18} style={{padding:0}}>
                  <div className={styles.maps} id="map"></div>
                </Col>
              </Row>
              <Row
                gutter={24}
                style={{
                  borderBottom: "2px solid black",
                  borderLeft: "2px solid black",
                  borderRight: "2px solid black",
                  margin:0
                }}
              >
                <Col xs={24}>
                  <div>
                    <b>SplitOrder Number : </b>
                    {this.state.SelectedDeliveryData.splitOrderNumber}
                  </div>
                  <div>
                    <b> Customer Name :</b>{" "}
                    {this.state.SelectedDeliveryData.customerName}
                  </div>
                  <div>
                    <b>Mobile No. : </b>
                    {this.state.SelectedDeliveryData.mobileNo}
                  </div>
                  <div>
                    <b>Address : </b>
                    {this.state.SelectedDeliveryData.address}
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div style={{ display: "flex",
              justifyContent: "center",
              margin: 15,fontSize:15}}>No Delivery Available</div>
          )}
        </Modal>
      </div>
    );
  }
}

export default withRouter(Master);
