import React from "react";
import styles from "./lkemployeecredit.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Spin,
  // Popconfirm,
  Table,
  Modal,
  // Checkbox,
  notification,
  // DatePicker,
  Upload,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faInfo,
  faDownload,
  // faPencilAlt,
  // faTrash,
} from "@fortawesome/free-solid-svg-icons";
import LKEmployeeCredit from "../../../services/lkemployeecreditservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { UploadOutlined } from "@ant-design/icons";
import ReactExport from "react-export-excel";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Search } = Input;
const { Option } = Select;

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class EmployeeCredit extends React.Component {
  constructor(props) {
    super(props);
    this.employeeCreditService = new LKEmployeeCredit();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();
    this.state = {
      modalLoading: false,
      excelFile: <React.Fragment></React.Fragment>,
      lkEmployeeCreditItems: [],
      empCreditFileID: 0,
      fileName: "",
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      loading: true,
      showfilterBox: false,
      showAddPopup: false,

      uploading: false,
      fileList: [],
      binaryFile: "",
      currentURL: "",
      EmployeeCreditDetailsList: [],
    };
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.EMPLOYEE_CREDITS
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.FILE_NAME,
      dataIndex: "fileName",
      key: "fileName",
      sorter: true,
    },
    {
      title: labels.TOTAL_RECORDS,
      dataIndex: "totalCnt",
      key: "totalCnt",
      sorter: true,
    },
    {
      title: labels.SUCCEEDED,
      dataIndex: "succeeded",
      key: "succeeded",
      sorter: true,
      render: (text, record, index) => {
        return (
          <Space direction="horizontal">
            <span>{text}</span>
            <Tooltip title={labels.DOWNLOAD}>
              <Button
                onClick={() => this.getReport(record.empCreditFileID, true)}
                type="link"
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: labels.FAILED,
      dataIndex: "failed",
      key: "failed",
      sorter: true,
      render: (text, record, index) => {
        return (
          <Space direction="horizontal">
            <span>{text}</span>
            <Tooltip title={labels.DOWNLOAD}>
              <Button
                onClick={() => this.getReport(record.empCreditFileID, false)}
                type="link"
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {this.state.userRights.length > 0 &&
    //       this.state.userRights[0].isView == true ? (
    //         <Button
    //           type="link"
    //           onClick={() =>
    //             this.setState({
    //               showAddPopup: true,
    //               empCreditFileID: record.empCreditFileID,
    //               fileName: record.fileName,
    //               totalCnt: record.totalCnt,
    //               succeeded: record.succeeded,
    //               failed: record.failed,
    //               isEnabled: true,
    //               isVisible: true,
    //             })
    //           }
    //         >
    //           <FontAwesomeIcon icon={faInfo} />
    //         </Button>
    //       ) : null}
    //     </Space>
    //   ),
    // },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      empCreditFileID: this.state.empCreditFileID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      empCreditFileID: this.state.empCreditFileID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  getReport = (id, type) => {
    this.employeeCreditService
      .getReport({
        empCreditFileID: parseInt(id),
        isSucceeded: type,
      })
      .then((response) => {
        this.setState(
          {
            excelFile: (
              <div>
                <ExcelFile
                  hideElement={true}
                  filename={type ? "succeeded" : "failed"}
                >
                  <ExcelSheet
                    data={response.data}
                    name={type ? "Succeeded Records" : "Failed Records"}
                    columns={[]}
                  >
                    <ExcelColumn label="Mobile No." value="mobileNo" />
                    <ExcelColumn label="Amount" value="bonusAmount" />
                    <ExcelColumn
                      label="Validity (In Days)"
                      value="validityInDays"
                    />
                    <ExcelColumn label="Remarks" value="remark" />
                  </ExcelSheet>
                </ExcelFile>
              </div>
            ),
          },
          () => this.setState({ excelFile: <React.Fragment></React.Fragment> })
        );
      });
  };
  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      empCreditFileID: 0,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : sorter.order,
      // ...filters,
    });
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.employeeCreditService
      .retrieveEmployeeCreditConfiguration(params)
      .then((items) => {
        if (items.statusCode === 200) {
          if (
            items.length !== 0 &&
            items.data.length === 0 &&
            params.pagination.current !== 1
          ) {
            params.pagination.current = 1;
            this.fetchItems({
              empCreditFileID: 0,
              pageIndex: 1,
              pageSize: params.pageSize,
              pagination: params.pagination,
            });
          }
          this.setState({
            lkEmployeeCreditItems: items.data,
            pagination: {
              ...params.pagination,
              total:
                items.length !== 0 && items.data.length !== 0
                  ? items.data[0].totalRecords
                  : 0,
            },
          });
        } else {
          notification["error"]({
            message: labels.EMPLOYEE_CREDITS,
            description: items.message
              ? this.common.Message(items.message)
              : labels.UNABLE_TO_GET_LIST_OF_EMPLOYEE_CREDIT_DATA,
          });
        }
        this.setState({ loading: false });
      });
  };

  //function for adding data to back-end by server call
  handleUpload = (values) => {
    this.setState({ modalLoading: true });
    let postData = new FormData();
    postData.append("EmployeeCreditFile", values.file[0].originFileObj);
    this.employeeCreditService.uploadFile(postData).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.EMPLOYEE_CREDITS,
          description: this.common.Message(response.message),
        });
        this.fetchItems({
          pageIndex: this.state.pagination.current,
          pageSize: this.state.pagination.pageSize,
          ...this.state.pagination,
        });
      } else {
        notification["error"]({
          message: labels.EMPLOYEE_CREDITS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_UPLOAD_THE_FILE,
        });
      }
      this.setState({ modalLoading: false });
    });
    this.setState({ showAddPopup: false });
  };

  handleBonusAmount = (e) => {
    this.setState({ bonusAmount: e.target.value });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleRemark = (e) => {
    this.setState({ remark: e.target.value });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      empCreditFileID: 0,
      fileName: "",
      totalCnt: "",
      succeeded: "",
      failed: "",
      isEnabled: false,
      isVisible: false,
    });
  };

  // handleCheckbox=(event)=>{
  //      this.setState({ isAddOn: event.target.checked });
  // }

  render() {
    const {
      fileName,
      totalCnt,
      succeeded,
      failed,
      pagination,
      loading,
      remark,
    } = this.state;
    const lkEmployeeCreditItems = this.state.lkEmployeeCreditItems;
    let RegexInteger = /^[1-9]+[0-9]*$/g;
    const listItems = lkEmployeeCreditItems.map((item, index) => ({
      key: item.empCreditFileID,
      empCreditFileID: item.empCreditFileID,
      fileName: item.fileName,
      totalCnt: item.totalCnt,
      succeeded: item.succeeded,
      failed: item.failed,
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        {this.state.excelFile}
        <PageHeader
          title={labels.EMPLOYEE_CREDITS}
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
              key="1"
            />,
            <a
              href={window.location.origin + "/files/EmployeeCredit.xlsx"}
              key="4"
              style={{ alignSelf: "center" }}
            >
              {labels.DOWNLOAD_FORMAT}
            </a>,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
                key="2"
              >
                {labels.UPLOAD_FILE}
              </Button>
            ) : null,
            // <Button
            //     type="link"
            //     className={styles.FilterToggle}
            //     onClick={() => {
            //         this.setState({ showfilterBox: !this.state.showfilterBox });
            //     }}
            // >
            //     {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //     <FontAwesomeIcon
            //         icon={faChevronUp}
            //         className={
            //             this.state.showfilterBox
            //                 ? styles.FilterToggleIcon
            //                 : styles.FilterToggleIconRotated
            //         }
            //     />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}></TableActions>

        <Modal
          title={
            this.state.empCreditFileID === 0
              ? labels.ADD_EMPLOYEE_CREDITS
              : this.state.isEnabled
              ? labels.VIEW_EMPLOYEE_CREDITS
              : labels.EDIT_EMPLOYEE_CREDITS
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handleUpload}
              initialValues={{
                fileName,
                totalCnt,
                succeeded,
                failed,
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    name="file"
                    label={labels.UPLOAD_EMPLOYEE_CREDIT_CONFIGURATION_FILE}
                    rules={[
                      { required: true, message: labels.PLEASE_SELECT_FILE },
                    ]}
                    valuePropName="fileList"
                    getValueFromEvent={(e) => {
                      if (Array.isArray(e)) {
                        return e;
                      }
                      return e && e.fileList;
                    }}
                  >
                    <Upload
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls"
                      customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                          onSuccess("ok");
                        }, 0);
                      }}
                    >
                      <Button variant="transparentGreyOutline">
                        <UploadOutlined /> {labels.CLICK_TO_UPLOAD}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.empCreditFileID === 0
                            ? labels.ADD
                            : labels.UPDATE}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
