import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  TimePicker,
  Input,
  DatePicker,
  Space,
  notification,
} from "antd";
import Button from "../../../Common/Button/button";
import FactoryService from "../../../../services/factoryservice";
import {
  RegexLetters,
  RegexLettersDigits,
  MessageLetters,
  MessageLettersDigits,
} from "../../../../shared/validator";
const { Option } = Select;
/**
 * General form as a part of create factory screen
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 */
export default class GeneralForm extends React.Component {
  /**
   * Initalizing Factory Service
   */
  factoryService = new FactoryService();

  /**
   * Storing objects for dynamic controls
   *
   * @added 18 June 2020
   */
  state = {
    deliveryTypes: {
      disabled: true,
      data: [],
    },
    operatingDays: {
      disabled: true,
      data: [],
    },
    status: {
      disabled: true,
      data: [],
    },
    services: {
      disabled: true,
      data: [],
    },
  };
  /**
   * API Calls on component mounting to populate dropdowns
   * @added 18 June 2020
   */
  componentDidMount() {
    this.getDeliveryTypes();
    this.getOperatingDays();
    this.getStatusList();
    this.getServices();
  }
  /**
   * Populating Delivery Type Dropdown
   * @added 18 June 2020
   */
  getDeliveryTypes = () => {
    this.factoryService.getDeliveryTypeList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          deliveryTypes: {
            ...this.state.deliveryTypes,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Factory Master",
          description: response.message
            ? response.message
            : "Unable to get data for delivery types",
        });
      }
    });
  };
  /**
   * Populating Operating Days Dropdown
   * @added 18 June 2020
   */
  getOperatingDays = () => {
    this.factoryService.getOperatingDaysList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          operatingDays: {
            ...this.state.operatingDays,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Factory Master",
          description: response.message
            ? response.message
            : "Unable to get data for operating days",
        });
      }
    });
  };
  /**
   * Populating Status Dropdown
   * @added 18 June 2020
   */
  getStatusList = () => {
    this.factoryService.getFactoryStatusList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          status: {
            ...this.state.status,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Factory Master",
          description: response.message
            ? response.message
            : "Unable to get factory statuses",
        });
      }
    });
  };

  getServices = () => {
    this.factoryService.getServices().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          services: {
            ...this.state.services,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Factory Master",
          description: response.message
            ? response.message
            : "Unable to get factory services",
        });
      }
    });
  };
  render() {
    const disableAll = this.props.mode === "view";
    return (
      <Form
        key={this.props.values.factoryName}
        name="general"
        layout="vertical"
        initialValues={this.props.values}
        ref={this.props.instance}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={15}>
            <Form.Item
              label="Factory Name"
              name="factoryName"
              rules={[
                { required: true, message: "Please Enter Factory Name" },
                // {
                //   pattern: RegexLetters,
                //   message: MessageLetters,
                // },
              ]}
            >
              <Input placeholder="Enter Factory Name" disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={9}>
            <Form.Item
              label="Short Name"
              name="shortName"
              rules={[
                { required: true, message: "Please Enter Short Name" },
                {
                  pattern: RegexLettersDigits,
                  message: MessageLettersDigits,
                },
              ]}
            >
              <Input placeholder="Enter Short Name" disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            {/**
             * For More Clarity over what <DatePicker> Component can do
             * Please go through https://ant.design/components/date-picker/
             * https://ant.design/components/date-picker/#API
             */}
            <Form.Item
              label="Opening Date"
              name="openingDate"
              rules={[
                { required: true, message: "Please Select Opening Date" },
              ]}
            >
              <DatePicker
                onChange={() => null}
                disabled={disableAll}
                format={"DD-MM-YYYY"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} xl={16}>
            <Form.Item
              label="Delivery Type"
              name="deliveryType"
              rules={[
                { required: true, message: "Please Select Delivery Type" },
              ]}
            >
              <Select
                onChange={() => null}
                value={1}
                mode="multiple"
                placeholder="Select Delivery Types"
                disabled={this.state.deliveryTypes.disabled || disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.deliveryTypes.data.length !== 0
                  ? this.state.deliveryTypes.data.map((deliveryType) => {
                      return deliveryType.value !== "-1" ? (
                        <Option
                          value={deliveryType.value}
                          key={deliveryType.value}
                        >
                          {deliveryType.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Operating Days"
              name="operatingDays"
              rules={[
                { required: true, message: "Please Select Operating Days" },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Operating Days"
                onChange={() => null}
                disabled={this.state.operatingDays.disabled || disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.operatingDays.data.length !== 0
                  ? this.state.operatingDays.data.map((operatingDay) => {
                      return operatingDay.value !== "-1" ? (
                        <Option
                          value={operatingDay.value}
                          key={operatingDay.value}
                        >
                          {operatingDay.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Services"
              name="serviceIDs"
              rules={[{ required: true, message: "Please Select Services" }]}
            >
              <Select
                mode="multiple"
                placeholder="Select Services"
                onChange={() => null}
                disabled={this.state.services.disabled || disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.services.data.length !== 0
                  ? this.state.services.data.map((service) => {
                      return service.value !== "-1" ? (
                        <Option value={service.value} key={service.value}>
                          {service.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={10}>
            {/**
             * For More Clarity over what <TimePicker> Component can do
             * Please go through https://ant.design/components/time-picker/
             * https://ant.design/components/time-picker/#API
             */}
            <Form.Item
              label="Operating Time"
              name="operatingTime"
              rules={[
                {
                  required: true,
                  message: "Please Select Operating Time",
                },
              ]}
            >
              <TimePicker.RangePicker
                placeholder={["From", "To"]}
                format={"h:mm a"}
                disabled={disableAll}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: "Please Select Status" }]}
            >
              <Select
                placeholder="Select Status"
                onChange={() => null}
                disabled={this.state.status.disabled || disableAll}
              >
                {this.state.status.data.length !== 0
                  ? this.state.status.data.map((singleStatus) => {
                      return singleStatus.value !== "-1" ? (
                        <Option
                          value={singleStatus.value}
                          key={singleStatus.value}
                        >
                          {singleStatus.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} style={{ textAlign: "right" }}>
            <Space>
              <div className="w-100"></div>
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                Cancel
              </Button>
              <Button
                onClick={this.props.nextFunction}
                variant="purple"
                shape="round"
                size="large"
              >
                Next
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
