import React from "react";
import NoticeBoard from "../../components/NoticeBoard/noticeboard.component";

export default class NoticeBoardScreen extends React.Component {
  render() {
    return (
      <div>
        <NoticeBoard />
      </div>
    );
  }
}
