import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  TimePicker,
  Input,
  DatePicker,
  Space,
  notification,
  Radio,
} from "antd";
import Button from "../../../Common/Button/button";
import CashCouponMasterService from "../../../../services/cashcouponmasterservice";
import {
  RegexDecimalAndRestrictFirstZero,
  RegexInteger,
  MessageNumbers,
} from "../../../../shared/validator";
import Moment from "moment";
const { Option } = Select;

export default class GeneralForm extends React.Component {
  cashcouponmasterservice = new CashCouponMasterService();
  state = {
    applicableCityIds: {
      disabled: true,
      data: [],
    },
    couponType: {
      disabled: true,
      data: [],
    },
    cities: {
      disabled: true,
      data: [],
    },
  };

  componentDidMount() {
    this.getCouponType();
    this.getCities();
  }
  getCouponType = () => {
    this.cashcouponmasterservice.getCouponType().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          couponType: {
            ...this.state.couponType,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Cash Coupon",
          description: "Unable to get data for coupon type",
        });
      }
    });
  };

  getCities = () => {
    const params = {
      cityID: 0,
      cityName: "",
      pageIndex: 1,
      pageSize: 1000,
      sortColumn: "",
      sortOrder: "",
      commonSearch: "",
    };
    this.cashcouponmasterservice.getCities(params).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          cities: {
            ...this.state.cities,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Cash Coupon",
          description: "Unable to get data for cities",
        });
      }
    });
  };
  render() {
    const disableAll = this.props.mode === "view";
    return (
      <Form
        //key={this.props.values.cashCouponcode}
        name="general"
        layout="vertical"
        initialValues={this.props.values}
        ref={this.props.instance}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={12}>
            <Form.Item
              label="Cash Coupon Code"
              name="cashCouponcode"
              rules={[
                { required: true, message: "Please Enter Cash Coupon Code" },
              ]}
            >
              <Input
                placeholder="Enter Cash Coupon Code"
                disabled={disableAll}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={12}>
            {/**
             * For More Clarity over what <DatePicker> Component can do
             * Please go through https://ant.design/components/date-picker/
             * https://ant.design/components/date-picker/#API
             */}
            <Form.Item
              label="Valid Period "
              name="validFrom"
              rules={[
                { required: true, message: "Please Select Valid Period" },
              ]}
            >
              <DatePicker.RangePicker
                onChange={() => null}
                disabled={disableAll}
                format={"DD-MM-YYYY"}
                disabledDate={(currentDate) =>
                  currentDate.isBefore(Moment(), "day") ? true : false
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Coupon Type"
              name="couponType"
              rules={[{ required: true, message: "Please Select Coupon Type" }]}
            >
              <Select
                placeholder="Select Coupon Type"
                onChange={() => null}
                disabled={disableAll}
              >
                {this.state.couponType.data.length !== 0
                  ? this.state.couponType.data.map((couponType) => {
                      return couponType.value !== "-1" ? (
                        <Option value={couponType.value} key={couponType.value}>
                          {couponType.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="No Of Time"
              name="noOftimes"
              rules={[
                { required: true, message: "Please Enter No Of Times" },
                {
                  pattern: RegexInteger,
                  message: MessageNumbers,
                },
              ]}
            >
              <Input
                placeholder="Enter no of Times"
                maxLength={6}
                disabled={disableAll}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Value"
              name="value"
              rules={[
                { required: true, message: "Please Enter Value" },
                {
                  pattern: RegexDecimalAndRestrictFirstZero,
                  message: MessageNumbers,
                },
              ]}
            >
              <Input placeholder="Enter Value" disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Redeem Validity"
              name="redeemValidity"
              rules={[
                { required: true, message: "Please Enter Redeem Validity" },
                {
                  pattern: RegexInteger,
                  message: MessageNumbers,
                },
              ]}
            >
              <Input
                placeholder="Enter Redeem Validity"
                disabled={disableAll}
                maxLength={5}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="IsFirstOrderOnly"
              name="isFirstOrderOnly"
              rules={[
                { required: true, message: "Please Select IsFirstOrderOnly" },
              ]}
            >
              <Radio.Group disabled={disableAll}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="IsActive"
              name="isActive"
              rules={[{ required: true, message: "Please Select IsActive " }]}
            >
              <Radio.Group name="isActive" disabled={disableAll}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Applicable Cities"
              name="applicableCityIds"
              rules={[
                { required: true, message: "Please Select Applicable Cities" },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Applicable Cities"
                onChange={() => null}
                disabled={disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.cities.data.length !== 0
                  ? this.state.cities.data.map((cities) => {
                      return cities.value !== "-1" ? (
                        <Option value={cities.cityID} key={cities.cityID}>
                          {cities.cityName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} style={{ textAlign: "right" }}>
            <Space>
              <div className="w-100"></div>
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                Cancel
              </Button>
              {this.props.mode !== "view" ? (
                <Button
                  htmlType="submit"
                  onClick={this.props.nextFunction}
                  variant="purple"
                  shape="round"
                  size="large"
                >
                  Submit
                </Button>
              ) : null}
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
