import React from "react";
import Button from "../../../Common/Button/button";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  TimePicker,
  notification,
} from "antd";
import styles from "./createstore.module.css";
import StoreService from "../../../../services/storeservice";
import FactoryService from "../../../../services/factoryservice";
import {
  RegexLettersDigits,
  MessageLettersDigits,
  RegexLettersDigitsSymbols,
  MessageLettersDigitsSymbols,
} from "../../../../shared/validator";

const { Option } = Select;
// const openNotificationWithIcon = (type, title, message) => {
//   notification[type]({
//       message: title,
//       description:
//           message
//   });
// };
/**
 * A form as a part of create store - General Section/Step
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 2 June 2020
 *
 * @module Store
 */
export default class GeneralForm extends React.Component {
  constructor(props) {
    super(props);
    this.storeservice = new StoreService();
    this.factoryService = new FactoryService();
    this.state = {
      isEditmode: false,
      isViewmode: false,
      storeOperationType: [],
      typeOfStore: [],
      storeModel: [],
      storeOperatedBy: [],
      storeOperatingType: [],
      storeStatus: [],
      storeServiceType: [],
      storeDeliveryType: [],
      storeServicesOffered: [],
      storeOrdertoAssigned: [],
      operatingDays: {
        disabled: true,
        data: [],
      },
      isStoreDeliveryType: false
    };
  }

  componentDidMount() {
    this.getStoreOperationType();
    this.getStoreModel();
    this.getTypeOfStore();
    this.getStoreOperatedBy();
    this.getStoreOperationgType();
    this.getStoreStatus();
    this.getStoreServiceType();
   // if (!this.state.isStoreDeliveryType) {
      this.getStoreDeliveryType();
   // }
    this.getStoreServicesOffered();
    this.getStoreOrderedToAssigned();
    this.getOperatingDays();
  }
  getOperatingDays = () => {
    this.factoryService.getOperatingDaysList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          operatingDays: {
            ...this.state.operatingDays,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Store Master",
          description: response.message
            ? response.message
            : "Unable to get data for operating days",
        });
      }
    });
  };
  getStoreOperationType = () => {
    this.storeservice
      .retrieveStoreOperationType()
      .then((storeOperationType) => {
        if (storeOperationType.statusCode === 200) {
          this.setState({ storeOperationType: storeOperationType.data });
        } else {
          notification["error"]({
            message: "Store Master",
            description: "Unable to get data for operation type",
          });
        }
      });
  };

  getStoreModel = () => {
    this.storeservice.retrieveStoreModel().then((storeModel) => {
      if (storeModel.statusCode === 200) {
        this.setState({ storeModel: storeModel.data });
      } else {
        notification["error"]({
          message: "Store Master",
          description: "Unable to get data for store model",
        });
      }
    });
  };

  getTypeOfStore = () => {
    this.storeservice.retrieveTypeOfStore().then((typeOfStore) => {
      if (typeOfStore.statusCode === 200) {
        this.setState({ typeOfStore: typeOfStore.data });
      } else {
        notification["error"]({
          message: "Store Master",
          description: "Unable to get data for type of store",
        });
      }
    });
  };

  getStoreOperatedBy = () => {
    this.storeservice.retrieveStoreOperatedBy().then((storeOperatedBy) => {
      if (storeOperatedBy.statusCode === 200) {
        this.setState({ storeOperatedBy: storeOperatedBy.data });
      } else {
        notification["error"]({
          message: "Store Master",
          description: "Unable to get data for store  OperatedBy",
        });
      }
    });
  };

  getStoreOperationgType = () => {
    this.storeservice
      .retrieveStoreOperatingType()
      .then((storeOperatingType) => {
        if (storeOperatingType.statusCode === 200) {
          this.setState({ storeOperatingType: storeOperatingType.data });
        } else {
          notification["error"]({
            message: "Store Master",
            description: "Unable to get data for store Operating Type",
          });
        }
      });
  };

  getStoreStatus = () => {
    this.storeservice.retrieveStoreStatus().then((storeStatus) => {
      if (storeStatus.statusCode === 200) {
        this.setState({ storeStatus: storeStatus.data });
      } else {
        notification["error"]({
          message: "Store Master",
          description: "Unable to get data for store Status ",
        });
      }
    });
  };

  getStoreServiceType = () => {
    this.storeservice.retrieveStoreServiceType().then((storeServiceType) => {
      if (storeServiceType.statusCode === 200) {
        this.setState({ storeServiceType: storeServiceType.data });
      } else {
        notification["error"]({
          message: "Store Master",
          description: "Unable to get data for store Service Type ",
        });
      }
    });
  };

  getStoreDeliveryType = () => {
    this.storeservice.retrieveStoreDeliveryType().then((storeDeliveryType) => {
      if (storeDeliveryType.statusCode === 200) {
        this.setState({ storeDeliveryType: storeDeliveryType.data, isStoreDeliveryType: true });
      } else {
        notification["error"]({
          message: "Store Master",
          description: "Unable to get data for store Delivery Type ",
        });
       // this.setState({ StoreDeliveryType: false });
      }
    });
  };

  getStoreServicesOffered = () => {
    this.storeservice
      .retrieveStoreServicesOffered()
      .then((storeServicesOffered) => {
        if (storeServicesOffered.statusCode === 200) {
          this.setState({ storeServicesOffered: storeServicesOffered.data });
        } else {
          notification["error"]({
            message: "Store Master",
            description: "Unable to get data for store Services Offered ",
          });
        }
      });
  };

  getStoreOrderedToAssigned = () => {
    this.storeservice
      .retrieveStoreOrderedToAssigned()
      .then((storeOrdertoAssigned) => {
        if (storeOrdertoAssigned.statusCode === 200) {
          this.setState({ storeOrdertoAssigned: storeOrdertoAssigned.data });
        } else {
          notification["error"]({
            message: "Store Master",
            description: "Unable to get data for store Order  toAssigned",
          });
        }
      });
  };

  render() {
    const disabled = this.props.mode === "view";
    return (
      <Form
        layout="vertical"
        name="General"
        initialValues={this.props.values}
        ref={this.props.instance}
        onFinish={this.onFinishGeneralForm}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Store Name"
              name="storeName"
              rules={[
                { required: true, message: "Please Enter Store Name" },
                // {
                //   pattern: RegexLettersDigitsSymbols,
                //   message: MessageLettersDigitsSymbols,
                // },
              ]}
            >
              <Input placeholder="Enter Store Name" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Short Name"
              name="shortName"
              rules={[
                { required: true, message: "Please Enter Short Name" },

                {
                  pattern: RegexLettersDigits,
                  message: MessageLettersDigits,
                },
              ]}
            >
              <Input placeholder="Enter Short Name" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="Opening Date" name="openingDate">
              <DatePicker disabled={disabled} format={"DD-MM-YYYY"} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item
              label="Operation Type"
              name="operationType"
              rules={[
                { required: true, message: "Please Select Operation Type" },
              ]}
            >
              <Select placeholder="Select Operation Type" disabled={disabled}>
                {/* {this.state.storeOperationType.map((storeOperationType) => <Select.Option key={storeOperationType.value} value={storeOperationType.value}>{storeOperationType.text}</Select.Option>)}   */}
                {this.state.storeOperationType.map((storeOperationType) => {
                  return storeOperationType.value !== "-1" ? (
                    <Option
                      value={storeOperationType.value}
                      key={storeOperationType.value}
                    >
                      {storeOperationType.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={16}>
            <Form.Item
              label="Store Service Type"
              name="storeServiceType"
              rules={[
                { required: true, message: "Please Select Store Service Type" },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Store Service"
                disabled={disabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {/* {this.state.storeServiceType.map((storeServiceType) => <option key={storeServiceType.value} value={storeServiceType.value}>{storeServiceType.text}</option>)} */}
                {this.state.storeServiceType.map((storeServiceType) => {
                  return storeServiceType.value !== "-1" ? (
                    <Option
                      value={storeServiceType.value}
                      key={storeServiceType.value}
                    >
                      {storeServiceType.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Type of Store"
              name="typeOfStore"
              rules={[
                { required: true, message: "Please Select Type of Store" },
              ]}
            >
              <Select placeholder="Select Type Of Store" disabled={disabled}>
                {/* {this.state.typeOfStore.map((typeOfStore) => <Select.Option key={typeOfStore.value} value={typeOfStore.value}>{typeOfStore.text}</Select.Option>)}   */}
                {this.state.storeModel.map((storeModel) => {
                  return storeModel.value !== "-1" ? (
                    <Option value={storeModel.value} key={storeModel.value}>
                      {storeModel.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Store Model"
              name="storeModel"
              rules={[{ required: true, message: "Please Select Store Model" }]}
            >
              <Select placeholder="Select Store Model" disabled={disabled}>
                {/* {this.state.storeModel.map((storeModel) => <Select.Option key={storeModel.value} value={storeModel.value}>{storeModel.text}</Select.Option>)}   */}

                {this.state.typeOfStore.map((typeOfStore) => {
                  return typeOfStore.value !== "-1" ? (
                    <Option value={typeOfStore.value} key={typeOfStore.value}>
                      {typeOfStore.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Operated By"
              name="operatedBy"
              rules={[{ required: true, message: "Please Select Operated By" }]}
            >
              <Select placeholder="Select Operated By" disabled={disabled}>
                {/* {this.state.storeOperatedBy.map((storeOperatedBy) => <Select.Option key={storeOperatedBy.value} value={storeOperatedBy.value}>{storeOperatedBy.text}</Select.Option>)}   */}
                {this.state.storeOperatedBy.map((storeOperatedBy) => {
                  return storeOperatedBy.value !== "-1" ? (
                    <Option
                      value={storeOperatedBy.value}
                      key={storeOperatedBy.value}
                    >
                      {storeOperatedBy.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={8}>
            <Form.Item
              label="Operating Type"
              name="operatingType"
              rules={[
                { required: true, message: "Please Select Operating Type" },
              ]}
            >
              <Select placeholder="Select Operating Type" disabled={disabled}>
                {/* {this.state.storeOperatingType.map((storeOperatingType) => <Select.Option key={storeOperatingType.value} value={storeOperatingType.value}>{storeOperatingType.text}</Select.Option>)}    */}
                {this.state.storeOperatingType.map((storeOperatingType) => {
                  return storeOperatingType.value !== "-1" ? (
                    <Option
                      value={storeOperatingType.value}
                      key={storeOperatingType.value}
                    >
                      {storeOperatingType.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={16}>
            <Form.Item
              label="Operating Days"
              name="operatingDays"
              rules={[
                { required: true, message: "Please Select Operating Days" },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Operating Days"
                disabled={this.state.operatingDays.disabled || disabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.operatingDays.data.length !== 0
                  ? this.state.operatingDays.data.map((operatingDay) => {
                    return operatingDay.value !== "-1" ? (
                      <Option
                        value={operatingDay.value}
                        key={operatingDay.value}
                      >
                        {operatingDay.text}
                      </Option>
                    ) : null;
                  })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Operating From"
              name="operatingFrom"
              rules={[
                { required: true, message: "Please Select Operating From" },
              ]}
            >
              <TimePicker format={"h:mm a"} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Operating To"
              name="operatingTo"
              rules={[
                { required: true, message: "Please Select Operating To" },
              ]}
            >
              <TimePicker format={"h:mm a"} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: "Please Select Status" }]}
            >
              <Select placeholder="Select Status" disabled={disabled}>
                {/* {this.state.storeStatus.map((storeStatus) => <Select.Option key={storeStatus.value} value={storeStatus.value}>{storeStatus.text}</Select.Option>)}   */}
                {this.state.storeStatus.map((storeStatus) => {
                  return storeStatus.value !== "-1" ? (
                    <Option value={storeStatus.value} key={storeStatus.value}>
                      {storeStatus.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12}>
            <Form.Item
              label="Services Offered"
              name="serviceIDs"
              rules={[
                { required: true, message: "Please Select Services Offered" },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Services Offered"
                disabled={disabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {/* {this.state.storeServicesOffered.map((storeServicesOffered) => <option key={storeServicesOffered.value} value={storeServicesOffered.value}>{storeServicesOffered.text}</option>)} */}
                {this.state.storeServicesOffered.map((storeServicesOffered) => {
                  return storeServicesOffered.value !== "-1" ? (
                    <Option
                      value={storeServicesOffered.value}
                      key={storeServicesOffered.value}
                    >
                      {storeServicesOffered.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={12}>
            <Form.Item
              label="Delivery Type"
              name="deliveryType"
              rules={[
                { required: true, message: "Please Select Delivery Type" },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Delivery Type"
                disabled={disabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {/* {this.state.storeDeliveryType.map((storeDeliveryType) => <option key={storeDeliveryType.value} value={storeDeliveryType.value}>{storeDeliveryType.text}</option>)} */}
                {this.state.storeDeliveryType.map((storeDeliveryType) => {
                  return storeDeliveryType.value !== "-1" ? (
                    <Option
                      value={storeDeliveryType.value}
                      key={storeDeliveryType.value}
                    >
                      {storeDeliveryType.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={12}>
            <Form.Item
              label="Order Assigned To Store"
              name="orderAssignedToStore"
            >
              <Select
                placeholder="Select Order Assigned"
                disabled={disabled}
                allowClear
              >
                {/* {this.state.storeOrdertoAssigned.map((storeOrdertoAssigned) => <Select.Option key={storeOrdertoAssigned.value} value={storeOrdertoAssigned.value}>{storeOrdertoAssigned.text}</Select.Option>)}   */}
                {this.state.storeOrdertoAssigned.map((storeOrdertoAssigned) => {
                  return storeOrdertoAssigned.value !== "-1" ? (
                    <Option
                      value={storeOrdertoAssigned.value}
                      key={storeOrdertoAssigned.value}
                    >
                      {storeOrdertoAssigned.text}
                    </Option>
                  ) : null;
                })}
              </Select>
              {/* <Select
                showSearch
                placeholder="Select Store"
                onChange={() => null}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={1}>Store 1</Option>
                <Option value={2}>Store 2</Option>
                <Option value={3}>Store 3</Option>
              </Select> */}
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item label="GSTIN" name="gstIn">
              <Input placeholder="Enter GST IN" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} xl={24}>
            <Form.Item label="Feedback Link" name="feedbackLink"
            rules={[{ required: true, message: "Please enter Feedback Link" }]}>
              <Input placeholder="Enter Feedback Link" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} className={styles.ButtonContainer}>
            <Button
              variant="transparentGreyOutline"
              className={styles.CancelButton}
              shape="round"
              size="large"
              onClick={this.props.backFunction}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              variant="purple"
              shape="round"
              size="large"
              onClick={this.props.nextFunction}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
