import React from "react";
import AdvancePayment from "../../../components/LKWallet/AdvancePayment/lkadvancepayment.component";

export default class AdvancePaymentScreen extends React.Component {
  render() {
    return (
      <div>
        <AdvancePayment></AdvancePayment>
      </div>
    );
  }
}
