import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class StoreService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }
  async GetOfferedServicesByFactoryID(FactoryID) {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({
      name: this.endpoint.GET_FACTORY_OFFERED_SERVICES_BY_FACTORY_ID,
    });
    let itemArray = [];
    let id = "?FactoryID=" + FactoryID;
    await myApi.endpoints[
      this.endpoint.GET_FACTORY_OFFERED_SERVICES_BY_FACTORY_ID
    ]
      .getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetOfferedServicesByFactoryID(FactoryID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getFactoryMappingList(storeID) {
    const listApi = new API({
      url: this.config.ITEM_URL,
    });
    listApi.createEntity({
      name: this.endpoint.GET_STORE_FACTORY_MAPPING,
    });
    let factoryservicelist = [];
    let id = "?StoreID=" + storeID;
    await listApi.endpoints[this.endpoint.GET_STORE_FACTORY_MAPPING]
      .getOne(id)
      .then((response) => {
        factoryservicelist = response.data;
      })
      .catch((err) => {
        factoryservicelist = this.common.handleError(err);
      });
    if (factoryservicelist.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factoryservicelist = this.getFactoryMappingList(storeID);
      });
    }
    return Promise.resolve(factoryservicelist);
  }

  async getStoreFactoryMappingByID(storeFactoryMappingID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_FACTORY_MAPPING_BY_ID });
    let itemArray = [];
    let Id = "?StoreFactoryMappingID=" + storeFactoryMappingID;
    await myApi.endpoints[this.endpoint.GET_STORE_FACTORY_MAPPING_BY_ID]
      .getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getStoreSlotDetailBySlotID(storeFactoryMappingID);
      });
    }
    return Promise.resolve(itemArray);
  }
  async saveUpdateStoreFactoryMapping(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.SAVE_UPDATE_STORE_FACTORY_MAPPING,
    });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.SAVE_UPDATE_STORE_FACTORY_MAPPING]
      .create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.saveUpdateStoreFactoryMapping(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async deleteStoreFactoryMappingByID(storeFactoryMappingID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.DELETE_STORE_FACTORY_MAPPING_BY_ID,
    });
    let itemArray = [];
    let ID = "?StoreFactoryMappingIDs=" + storeFactoryMappingID.toString();
    await myApi.endpoints[this.endpoint.DELETE_STORE_FACTORY_MAPPING_BY_ID]
      .deleteById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        this.common.handleError(err);
        console.log(err);
      });
    return Promise.resolve(itemArray);
  }
  async retrieveStoreOperationType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_OPERATION_TYPE });
    let itemArray = [];
    await myApi.endpoints.GetStoreOperationTypeList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreOperationType();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreModel() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_TYPE });
    let itemArray = [];
    await myApi.endpoints.GetTypeOfStoreList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreModel();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveTypeOfStore() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_MODEL });
    let itemArray = [];
    await myApi.endpoints.GetStoreModelList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveTypeOfStore();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreOperatedBy() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_OPERATED_BY });
    let itemArray = [];
    await myApi.endpoints.GetStoreOperatedByList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreOperatedBy();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreOperatingType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_OPERATING_TYPE });
    let itemArray = [];
    await myApi.endpoints.GetStoreOperatingTypeList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreOperatingType();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreStatus() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_STATUS });
    let itemArray = [];
    await myApi.endpoints.GetStoreStatusList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreStatus();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreServiceType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_SERVICE_TYPE });
    let itemArray = [];
    await myApi.endpoints.GetStoreServiceTypeList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreServiceType();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreDeliveryType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_DELIVERY_TYPE });
    let itemArray = [];
    await myApi.endpoints.GetDeliveryTypeList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreDeliveryType();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreServicesOffered() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_SERVICES_OFFERED });
    let itemArray = [];
    await myApi.endpoints.GetServicesList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreServicesOffered();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreOrderedToAssigned() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE });
    let itemArray = [];
    await myApi.endpoints.GetStoreList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreOrderedToAssigned();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveZone() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_ZONE });
    let itemArray = [];
    await myApi.endpoints.GetZoneList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveZone();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreMaster(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_MASTER });
    let itemArray = [];
    await myApi.endpoints.GetStoreMaster.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreMaster(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStoreMasterByfilter(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_MASTER });
    let itemArray = [];
    await myApi.endpoints.GetStoreMaster.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreMasterByfilter(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  // Save User

  async saveUpdateStoreMaster(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_STOREMASTER });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.SaveUpdateStoreMaster.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.common.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal Server Error.",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.saveUpdateStoreMaster(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getStoreDetailByStoreID(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_STOREDETAIL_BY_STOREID });
    let itemArray = [];
    let Id = "?StoreID=" + id;
    await myApi.endpoints.GetStoreDetailByStoreID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getStoreDetailByStoreID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveWeekDaysList() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_WEEKDAYS_LIST });
    let itemArray = [];
    await myApi.endpoints.GetWeekDaysList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveWeekDaysList();
      });
    }
    return Promise.resolve(itemArray);
  }

  async saveUpdateSlotMappingDetails(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_STORE_SLOT });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateStoreSlot.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.saveUpdateSlotMappingDetails(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getStoreSlotDetailBySlotID(slotID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_SLOT_DETAILS_BY_SLOTID });
    let itemArray = [];
    let Id = "?SlotID=" + slotID;
    await myApi.endpoints.GetStoreSlotDetailBySlotID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getStoreSlotDetailBySlotID(slotID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getStoreSlotDetails(storeID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_SLOTS });
    let itemArray = [];
    let id = "?StoreID=" + storeID;
    await myApi.endpoints.GetStoreSlots.getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getStoreSlotDetails(storeID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async deleteStoreSlotBySlotID(slotID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.DELETE_STORE_SLOTS_By_SLOTID });
    let itemArray = [];

    let ID = "?slotIDs=" + slotID;
    await myApi.endpoints.DeleteStoreSlotBySlotID.deleteById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.deleteStoreSlotBySlotID(slotID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveState() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GETSTATELIST });
    let itemArray = [];
    await myApi.endpoints.GetStateList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveState();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveCity(id) {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_CITIES_BY_STATE });
    let itemArray = [];
    let Id = "?StateID=" + id;
    await myApi.endpoints.GetCityListByStateID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveCity(id);
      });
    }
    return Promise.resolve(itemArray);
  }
  // // User KYC details

  // async getUserKYCDetails(userID) {
  //     const myApi = new API({ url: this.config.ITEM_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_USER_KYC_DETAILS })
  //     let itemArray = [];

  //     await myApi.endpoints.GetUserKYCDetails.getOne(userID)
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // // Save User KYC

  // async saveUpdateUserKYCDetails(postSave) {
  //     const myApi = new API({ url: this.config.ITEM_URL })
  //     myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_USERKYC_DETAIL })
  //     let itemArray = [];
  //     await myApi.endpoints.SaveUpdateUserKYCDetail.create(postSave)
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // async deleteKYCByKYCID(id) {
  //     const myApi = new API({ url: this.config.ITEM_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_DELETE_USERKYC })
  //     let itemArray = [];
  //     await myApi.endpoints.DeleteUserKYCDetailByKYCID.deleteByKYCId(id)
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // async getKYCByKYCID(kycID) {
  //     const myApi = new API({ url: this.config.ITEM_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_KYC_DETAILS_BY_USERID })
  //     let itemArray = [];

  //     await myApi.endpoints.GetUserKYCDetailByKYCID.getByKYCId(kycID)
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // // User Role details

  // async getUserRoleDetails(userID) {
  //     const myApi = new API({ url: this.config.ITEM_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_USER_ROLE_DETAILS })
  //     let itemArray = [];

  //     await myApi.endpoints.GetUserRoleMappings.getOne(userID)
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // async getUserRoleByID(userrolemappingID) {
  //     const myApi = new API({ url: this.config.ITEM_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_ROLE_DETAILS_BY_ROLEID })
  //     let itemArray = [];

  //     await myApi.endpoints.GetUserRoleMappingByID.getByRoleId(userrolemappingID)
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // async deleteRoleByRoleID(id) {
  //     const myApi = new API({ url: this.config.ITEM_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_DELETE_USERROLE })
  //     let itemArray = [];
  //     await myApi.endpoints.DeleteUserRoleMappingByID.deleteByrRoleId(id)
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // async saveUpdateUserRoleDetails(postSave) {
  //     const myApi = new API({ url: this.config.ITEM_URL })
  //     myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_USERROLE_DETAIL })
  //     let itemArray = [];
  //     await myApi.endpoints.SaveUpdateUserRoleMapping.create(postSave)
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // //Delete records

  // // async deleteUserByUserID(id) {
  // //     const myApi = new API({ url: this.config.ITEM_URL })
  // //     myApi.createEntity({ name: this.endpoint.GET_DELETEUSERBYID })
  // //     let itemArray = [];
  // //     await myApi.endpoints.DeleteUserByUserID.deleteByUserId(id)
  // //         .then(({ data }) => {
  // //             itemArray = data
  // //         })
  // //         .catch(err => {
  // //             this.handleError(err);
  // //             console.log(err)
  // //         });
  // //     return Promise.resolve(itemArray)
  // // }

  // async getUserDetailByUserID(id) {
  //     const myApi = new API({ url: this.config.ITEM_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_USERDETAILSBYID })
  //     let itemArray = [];
  //     await myApi.endpoints.GetUserDetailByUserID.getOne(id)
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // // User Assignment step

  // async retrievePaymentType() {
  //     const myApi = new API({ url: this.config.DROPDOWN_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_PAYMENT_TYPE })
  //     let itemArray = [];

  //     await myApi.endpoints.GetPaymentTypeList.getAll()
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // async retrieveIronPersonType() {
  //     const myApi = new API({ url: this.config.DROPDOWN_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_IRON_PERSON_TYPE })
  //     let itemArray = [];

  //     await myApi.endpoints.GetIronPersonTypeList.getAll()
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // async retrieveStayAt() {
  //     const myApi = new API({ url: this.config.DROPDOWN_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_STAY_AT })
  //     let itemArray = [];

  //     await myApi.endpoints.GetStayAtList.getAll()
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // async retrieveStore() {
  //     const myApi = new API({ url: this.config.DROPDOWN_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_STORE })
  //     let itemArray = [];

  //     await myApi.endpoints.GetStoreList.getAll()
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // async retrieveFactory() {
  //     const myApi = new API({ url: this.config.DROPDOWN_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_FACTORY })
  //     let itemArray = [];

  //     await myApi.endpoints.GetFactoryList.getAll()
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // async retrieveRole() {
  //     const myApi = new API({ url: this.config.DROPDOWN_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_ROLE })
  //     let itemArray = [];

  //     await myApi.endpoints.GetRoleList.getAll()
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  // handleResponseError(response) {
  //     throw new Error("HTTP error, status = " + response.status);
  // }
}

export default StoreService;
