import { authHeader } from "../services/auth-header";
// import { throwError } from "rxjs";
const axios = require("axios");

function kebabCaseToCamel(str) {
  return str.replace(/(\-\w)/g, (matches) => matches[1].toUpperCase());
}

class API {
  constructor({ url }) {
    this.url = url;
    this.endpoints = {};
  }
  /**
   * Create and store a single entity's endpoints
   * @param {A entity Object} entity
   */
  createEntity(entity) {
    const name = kebabCaseToCamel(entity.name);
    this.endpoints[name] = this.createBasicCRUDEndpoints(entity);
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this));
  }
  /**
   * Create the basic endpoints handlers for CRUD operations
   * @param {A entity Object} entity
   */
  createBasicCRUDEndpoints({ name }) {
    var endpoints = {};
    let auth_header = authHeader();
    const resourceURL = `${this.url}/${name}`;

    endpoints.postAllWithoutToken = (params = {}, config = {}) =>
      axios.post(resourceURL, params, config);

    endpoints.getAll = (params = {}, config = {}) => {
      return auth_header
        ? axios.get(resourceURL, { ...params, headers: auth_header, ...config })
        : false;
    };

    endpoints.getByIdWithoutToken = (id, config = {}) =>
       axios.get(`${resourceURL}${id}`, { headers: auth_header, ...config })

    endpoints.getAllPost = (toCreate, config = {}) => {
      let { headers, ...rest } = config;
      let sendHeaders = { ...auth_header, ...headers };
      return auth_header
        ? axios.post(resourceURL, toCreate, {
            headers: sendHeaders,
            ...rest,
          })
        : false;
    };

    endpoints.getOne = (id, config = {}) =>
      auth_header
        ? axios.get(`${resourceURL}${id}`, { headers: auth_header, ...config })
        : false;

    endpoints.create = (toCreate, config = {}) =>
      auth_header
        ? axios.post(resourceURL, toCreate, { headers: auth_header, ...config })
        : false;

    endpoints.update = (toUpdate, config = {}) =>
      auth_header
        ? axios.put(`${resourceURL}/${toUpdate.id}`, toUpdate, {
            headers: auth_header,
            ...config,
          })
        : false;

    endpoints.patch = (id, toPatch, config = {}) =>
      auth_header
        ? axios.patch(`${resourceURL}/${id}`, toPatch, {
            headers: auth_header,
            ...config,
          })
        : false;

    endpoints.delete = ({ id }, config = {}) =>
      auth_header
        ? axios.delete(`${resourceURL}/${id}`, {
            headers: auth_header,
            ...config,
          })
        : false;
    endpoints.deleteById = (id, config = {}) =>
      auth_header
        ? axios.post(
            `${resourceURL}${id}`,
            {},
            { headers: auth_header, ...config }
          )
        : false; //added by PB

    endpoints.getDropdown = (config = {}) =>
      auth_header
        ? axios.get(`${resourceURL}`, { headers: auth_header, ...config })
        : false;

    endpoints.getById = (id, config = {}) =>
      auth_header
        ? axios.get(`${resourceURL}${id}`, { headers: auth_header, ...config })
        : false;
    endpoints.uploadFile = (id, postData, config = {}) =>
      auth_header
        ? axios.post(`${resourceURL}${id}`, postData, {
            headers: auth_header,
            ...config,
          })
        : false;

    endpoints.getOneCoupon = (id, CouponID, config = {}) =>
      auth_header
        ? axios.post(`${resourceURL}/${id}`, CouponID, {
            headers: auth_header,
            ...config,
          })
        : false;

    endpoints.getOneDiscount = (id, DiscountID, config = {}) =>
      auth_header
        ? axios.post(`${resourceURL}/${id}`, DiscountID, {
            headers: auth_header,
            ...config,
          })
        : false;

    return endpoints;
  }
}

export default API;
