import React from "react";
import { Layout, Typography } from "antd";
import Sidebar from "../Sidebar/sidebar";
import LaundrokartHeader from "../Header/header";
const { Header, Sider, Content, Footer } = Layout;
const { Text } = Typography;

/**
 * A common component to add header and sidebar to any screen
 *
 * Usage : <WithHeaderSidebar> { Your Screen Content } </WithHeaderSidebar>
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 1 June 2020
 *
 */
export default class WithHeaderSidebar extends React.Component {
  state = {
    collapsed: false,
  };
  menuToggler = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  /**
   * To collapse the sidebar on width less than 992px
   */
  responsiveSidebarToggle = () => {
    if (window.innerWidth < 992) {
      this.setState({ collapsed: true });
    } else {
      this.setState({ collapsed: false });
    }
  };

  componentDidMount() {
    this.responsiveSidebarToggle();
    window.addEventListener("resize", () => {
      this.responsiveSidebarToggle();
    });
  }
  render() {
    return (
      <Layout>
        <Header>
          <LaundrokartHeader props={this.props} />
        </Header>
        <Layout>
          <Sider
            collapsedWidth="80"
            collapsed={this.state.collapsed}
            collapsible
            trigger={null}
          >
            <Sidebar
              collapsedState={this.state.collapsed}
              toggleFunction={this.menuToggler}
            />
          </Sider>
          <Layout>
            <Content style={{ width: "100%" }}>
              <div style={{ padding: 30 }}>{this.props.children}</div>
            </Content>
          </Layout>
        </Layout>
        <Footer style={{ backgroundColor: "#352a54" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // marginTop: "20%",
              // backgroundColor:"#7e13d5"
            }}
          >
            <Text style={{ color: "#ffffff", opacity: 0.6 }}>
              <u>
                Owned and operated by “Kleenco On Demand Services Private
                Limited”
              </u>
            </Text>
          </div>
        </Footer>
      </Layout>
    );
  }
}
