import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";

import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  DatePicker,
  Tooltip,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import RewardsService from "../../../services/rewardsservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { allLabels, labels } from "../../../shared/language";
import {
  RegexDecimalAndRestrictFirstZero,
  RegexInteger,
  MessageNumbers,
} from "../../../shared/validator";

const { Search } = Input;
const { Option } = Select;
let todaysDate = Moment(new Date());

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.RewardsService = new RewardsService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.state = {
      modalLoading: false,
      rewardsItems: [],
      rewardsID: 0,
      cashbackPer: null,
      validityInDays: null,
      effectiveFrom: "",

      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
      disableUpdateBtn: false,
      SourceFilter: {
        data: [],
        selected: "1",
      },
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.REWARDS_CONFIG
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.CASHBACK_IN_PERCENTAGE,
      dataIndex: "cashbackPer",
      key: "cashbackPer",
      sorter: true,
    },
    {
      title: labels.PLATFORM,
      dataIndex: "platformName",
      key: "platformName",
      sorter: true,
    },

    {
      title: labels.VALIDITY_IN_DAYS,
      dataIndex: "validityInDays",
      key: "validityInDays",
      sorter: true,
    },

    {
      title: labels.EFFECTIVE_FROM,
      dataIndex: "effectiveFrom",
      key: "effectiveFrom",
      sorter: true,
      render: (data) => Moment(data, "DD-MM-YYYY").format("DD-MM-YYYY"),
    },

    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    rewardsID: record.rewardsID,
                    cashbackPer: record.cashbackPer,
                    validityInDays: record.validityInDays,
                    effectiveFrom: Moment(record.effectiveFrom, "DD/MM/YYYY"),
                    SourceFilter: {
                      ...this.state.SourceFilter,
                      selected: record.platForm,

                      // selected: [null, undefined, ""].includes(record.platForm)
                      //   ? undefined
                      //   : record.platForm.join(","),
                    },
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true &&
          !Moment(record.effectiveFrom, "DD/MM/YYYY").isBefore(
            todaysDate,
            "date"
          ) ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() => {
                  this.setState({
                    showAddPopup: true,
                    rewardsID: record.rewardsID,
                    cashbackPer: record.cashbackPer,
                    validityInDays: record.validityInDays,
                    effectiveFrom: Moment(record.effectiveFrom, "DD/MM/YYYY"),
                    platForm: record.platForm,
                  });
                  let effectiveDate = Moment(
                    record.effectiveFrom,
                    "DD/MM/YYYY"
                  );
                  if (effectiveDate.isBefore(todaysDate, "date")) {
                    this.setState({ disableUpdateBtn: true });
                    notification["error"]({
                      message: labels.REWARDS_CONFIG,
                      description: labels.PREVIOUS_REWARDS_CAN_NOT_BE_UPDATED,
                    });
                  } else {
                    this.setState({ disableUpdateBtn: false });
                  }
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {/* {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.rewardsID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null} */}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      rewardsID: this.state.rewardsID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
    this.getSourceList();
  }

  getSourceList = () => {
    this.RewardsService.getSourceList().then((response) => {
      if (response.statusCode === 200) {
        let data = response.data.map((single) => {
          return {
            value: single.enumDetailID,
            label: single.enumDetailName,
          };
        });
        this.setState({
          SourceFilter: {
            ...this.state.SourceFilter,
            data: data,
          },
        });
      } else {
        notification["error"]({
          message: labels.REWARDS_CONFIG,
          description: response.message
            ? response.message
            : labels.UNABLE_TO_GET_DATA,
        });
      }
    });
  };
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      rewardsID: this.state.rewardsID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      rewardsID: this.state.rewardsID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  //   selectionType = "checkbox";
  //   rowSelection = {
  //     onChange: (selectedRowKeys, selectedRows) => {
  //       this.setState({ rowselectedvalue: selectedRowKeys });
  //       console.log("rows", selectedRowKeys);
  //       console.log(
  //         `selectedRowKeys: ${selectedRowKeys}`,
  //         "selectedRows: ",
  //         selectedRows
  //       );
  //     },
  //     getCheckboxProps: (record) => ({
  //       disabled: record.name === "Disabled User", // Column configuration not to be checked
  //       name: record.name,
  //     }),
  //   };

  //function for deleting multiple seletion of rows
  //   deleteSelected = () => {
  //     var rowselectedvalue = this.state.rowselectedvalue.toString();
  //     this.removeItems(rowselectedvalue);
  //   };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.RewardsService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            rewardsID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          rewardsItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: labels.REWARDS_CONFIG,
          description: items.message
            ? items.message
            : labels.UNABLE_TO_GET_LIST_OF_REWARDS_DATA,
        });
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (rewardsID) => {
    if (rewardsID === "" || rewardsID === null || rewardsID === undefined) {
      openNotificationWithIcon(
        "error",
        labels.REWARDS_CONFIG,
        labels.PLEASE_SELECT_ATLEAST_ONE_ROW
      );
    } else {
      this.setState({ loading: true });
      this.RewardsService.removeItems(rewardsID).then((items) => {
        if (items.result === "Success") {
          openNotificationWithIcon(
            "success",
            labels.REWARDS_CONFIG,
            items.message
          );
          const { pagination } = this.state;
          this.fetchItems({
            rewardsID: parseInt(this.state.rewardsID),
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
          });
        } else {
          openNotificationWithIcon(
            "error",
            labels.REWARDS_CONFIG,
            items.message
          );
        }
        this.setState({ loading: false });
      });
    }
  };

  //function for adding data to back-end by server cal
  handlePopupAdd = (event) => {
    var postData = {
      rewardsID: this.state.rewardsID,
      cashbackPer: parseFloat(this.state.cashbackPer),
      validityInDays: parseInt(this.state.validityInDays),
      effectiveFrom: Moment(this.state.effectiveFrom).format("YYYY/MM/DD"),
      platForm:parseInt(this.state.SourceFilter.selected),
    };
    this.setState({ modalLoading: true });
    this.RewardsService.insertUpdateItems(postData).then((items) => {
      if (items.result === "Success") {
        openNotificationWithIcon(
          "success",
          labels.REWARDS_CONFIG,
          items.message
        );
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          rewardsID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        openNotificationWithIcon("error", labels.REWARDS_CONFIG, items.message);
        this.resetFields();
      }
      this.setState({ modalLoading: false });
    });
  };

  handleInputOnChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      rewardsID: 0,
      cashbackPer: null,
      validityInDays: null,
      effectiveFrom: "",
      isEnabled: false,
      isVisible: false,
      isEditbtndisable: false,
      disableUpdateBtn: false,
    });
  };

  render() {
    const {
      cashbackPer,
      validityInDays,
      effectiveFrom,
      pagination,
      // platform,
      loading,
    } = this.state;
    // let RegexDecimal= /^[0-9]+([.][0-9]+)?$/g;
    const RegexDecimalAndRestrict = /^[0-9]\d*(.\d*)?$/;
    const rewardsItems = this.state.rewardsItems;
    const platform = this.state.SourceFilter.selected;

    const listItems = rewardsItems.map((item, index) => ({
      key: item.rewardsID,
      rewardsID: item.rewardsID,
      cashbackPer: item.cashbackPer,
      validityInDays: item.validityInDays,
      effectiveFrom: Moment(item.effectiveFrom.split(" ")[0]).format(
        "DD-MM-YYYY"
      ),
      createdBy: item.createdBy,
      createdByName: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD/MM/YYYY  hh:mm:ss a"),
      totalRecords: item.totalRecords,
      modifiedBy: item.modifiedBy,
      modifiedByName: item.modifiedByName,
      modifiedOn: Moment(item.modifiedOn).format("DD/MM/YYYY  hh:mm:ss a"),
      platForm: item.platForm,
      platformName: item.platformName,
    }));
    return (
      <div>
        <PageHeader
          title={labels.REWARDS_CONFIG}
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                {labels.ADD_REWARDS_CONFIG}
              </Button>
            ) : null,
          ]}
        />
        <Table
          size="small"
          //   rowSelection={{
          //     type: this.selectionType,
          //     ...this.rowSelection,
          //   }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        {/* <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            rewardsItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions> */}
        <Modal
          title={
            this.state.rewardsID === 0
              ? labels.ADD_REWARDS_CONFIG
              : this.state.isEnabled
              ? labels.VIEW_REWARDS_CONFIG
              : labels.EDIT_REWARDS_CONFIG
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                cashbackPer,
                validityInDays,
                effectiveFrom,
                platform,
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Platform"
                    name={"platform"}
                    rules={[
                      {
                        required: true,
                        message: "Please Select Platform",
                      },
                    ]}
                  >
                    <Select
                      // mode="multiple"
                      placeholder="Please Select Platform"
                      disabled={this.state.isEnabled}
                      onChange={(value) =>
                        this.setState(
                          {
                            SourceFilter: {
                              ...this.state.SourceFilter,
                              selected: [null, undefined, ""].includes(value)
                                ? undefined
                                : value
                            },
                          },
                        )
                      }
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // value={
                      //   [null, undefined, "", []].includes(
                      //     this.state.SourceFilter.selected
                      //   )
                      //     ? undefined
                      //     : this.state.SourceFilter.selected
                      // }
                    >
                      {this.state.SourceFilter.data.length !== 0
                        ? this.state.SourceFilter.data.map((e) => {
                            return e.enumDetailID !== "-1" ? (
                              <Option
                                value={e.value.toString()}
                                key={e.value.toString()}
                              >
                                {e.label}
                              </Option>
                            ) : null;
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label={labels.CASHBACK_IN_PERCENTAGE}
                    name={"cashbackPer"}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter CashBack In %",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter CashBack In %"
                      name="cashbackPer"
                      maxLength={21}
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label={labels.VALIDITY_IN_DAYS}
                    name={"validityInDays"}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter validity(InDays)",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter validity(InDays) "
                      name="validityInDays"
                      maxLength={21}
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label={labels.EFFECTIVE_FROM}
                    name={"effectiveFrom"}
                    rules={[{ required: true, message: "Please Select Date" }]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      format={"DD-MM-YYYY"}
                      onChange={(date) =>
                        this.handleDateChange(date, "effectiveFrom")
                      }
                      disabled={this.state.isEnabled}
                      disabledDate={(currentDate) =>
                        currentDate < Moment().endOf("day") ? true : false
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                        <Button
                          htmlType="submit"
                          variant="purple"
                          disabled={this.state.disableUpdateBtn}
                        >
                          {this.state.rewardsID === 0
                            ? labels.ADD
                            : labels.UPDATE}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
