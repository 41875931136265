import React, { Component } from "react";
import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Row,
  Col,
  Form,
  Table,
  notification,
  DatePicker,
} from "antd";
import pendingcashsummaryservice from "../../../services/pendingcashsummaryservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
import moment from "moment";

export default class dailypickupsummarycomponent extends Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.pendingcashsummaryservice = new pendingcashsummaryservice();
    this.common = new Common();
    this.state = {
      dailypickupsummary: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 200,
        total: 0,
        showSizeChanger: true,
      },
      showfilterBox: true,
      loading: true,
      userRights: [],
      isResponseErrorShow: false,
      sorter: {
        field: null,
        order: null,
      },
    };
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }

    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.DAILY_PICKUP_SUMMARY
    );

    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.SR_NO,
      dataIndex: "srNo",
      key: "srNo",
      align: "center",
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
      align: "center",
    },

    {
      title: "Total No. of Pickups",
      dataIndex: "tPickup",
      key: "tPickup",
      align: "center",
      sorter: true,
    },

    {
      title: "No. of Pickups Assigned",
      dataIndex: "aPickup",
      key: "aPickup",
      align: "center",
      sorter: true,
    },
    {
      title: "No. of Pickups Done",
      dataIndex: "dPickup",
      key: "dPickup",
      align: "center",
      sorter: true,
    },

    {
      title: "No. of Pickups Rescheduled",
      dataIndex: "rpickup",
      key: "rpickup",
      align: "center",
      sorter: true,
    },
    {
      title: "No. of Pickups Cancelled ",
      dataIndex: "cPickup",
      key: "cPickup",
      align: "center",
      sorter: true,
    },
  ];

  componentDidMount() {
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      sortColumn: null,
      sortOrder: null,
    });
  }

  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.pendingcashsummaryservice
      .getDailyPickupSummary(params)
      .then((items) => {
        // console.log("items->", items);
        this.setState({
          dailypickupsummary: [],
        });
        if (items.statusCode === 200) {
          this.setState({
            dailypickupsummary: items.data,
          });
        } else {
          if (!this.state.isResponseErrorShow) {
            notification["error"]({
              message: "Daily Pickup Summary",
              description: items.message
                ? items.message
                : "Unable to get Daily Pickup Summary data",
            });
            this.setState({
              isResponseErrorShow: true,
            });
          }
        }
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter, extra) => {
    // console.log("pagination->", pagination);
    this.setState(
      {
        sorter: {
          ...sorter,
          order:
            sorter.order === "ascend"
              ? "asc"
              : sorter.order === "descend"
              ? "desc"
              : sorter.order,
        },
      },
      () => {
        this.fetchItems({
          sortColumn: this.state.sorter.field,
          sortOrder: this.state.sorter.order,
        });
      }
    );
  };

  render() {
    const {pagination, loading } = this.state;
    const dailypickupsummary = this.state.dailypickupsummary;
    console.log("dailypickupsummary->", dailypickupsummary);
    const listItems = dailypickupsummary.map((item, index) => ({
      key: item.storeID,
      srNo: item.rowNumber,
      storeName: item.storeName,
      tPickup: item.tPickup,
      aPickup: item.aPickup,
      dPickup: item.dPickup,
      rpickup: item.rpickup,
      cPickup: item.cPickup,
    }));
    return (
      <div>
        <PageHeader
          title={labels.DAILY_PICKUP_SUMMARY}
          // extra={[
          //   <Button
          //     type="link"
          //     className={styles.FilterToggle}
          //     onClick={() => {
          //       this.setState({ showfilterBox: !this.state.showfilterBox });
          //     }}
          //   >
          //     {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
          //     <FontAwesomeIcon
          //       icon={faChevronUp}
          //       className={
          //         this.state.showfilterBox
          //           ? styles.FilterToggleIcon
          //           : styles.FilterToggleIconRotated
          //       }
          //     />
          //   </Button>,
          // ]}
        />

        <Table
          size="small"
          onRow={(record, rowIndex) => {
            return {
              style: {
                fontWeight: record.storeName == "Grand Total" ? "bold" : null,
                color: record.storeName == "Grand Total" ? "black" : null,
              },
            };
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          bordered
        />
        <TableActions isPagination={false}></TableActions>
      </div>
    );
  }
}
