import React from "react";
import styles from "./packageconfigurationcomponent.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  // Checkbox,
  notification,
  Tooltip,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import PackageConfigurationService from "../../../services/lkpackageconfigurationservice";
// import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import {
  RegexLetters,
  MessageLetters,
  RegexInteger,
  MessageNumbers,
} from "../../../shared/validator";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";
const { Search } = Input;
const { Option } = Select;

export default class PackageConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.PackageConfigurationService = new PackageConfigurationService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();
    this.state = {
      modalLoading: false,
      lkPackageConfiguration: [],
      lkPackageID: 0,
      lkPackageName: "",
      packageAmount: "",
      bonusAmount: "",
      bonusAmountValidity: "",
      description: "",
      descriptionTitle: "",
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.PACKAGE_CONFIG
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  columns = [
    // {
    //   title: "ServiceID",
    //   dataIndex: "serviceID",
    //   key: "serviceID",
    // },
    {
      title: labels.PACKAGE_NAME,
      dataIndex: "packageName",
      key: "packageName",
      sorter: true,
    },

    {
      title: labels.PACKAGE_AMOUNT,
      dataIndex: "packageAmount",
      key: "packageAmount",
      sorter: true,
    },
    {
      title: labels.BONUS_AMOUNT,
      dataIndex: "bonusAmount",
      key: "bonusAmount",
      sorter: true,
    },
    {
      title: labels.BONUS_VALIDITY,
      dataIndex: "bonusAmountValidityInDays",
      key: "bonusAmountValidityInDays",
      sorter: true,
    },
    {
      title: labels.TITLE,
      dataIndex: "descriptionTitle",
      key: "descriptionTitle",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: labels.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title={labels.VIEW}>
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    lkPackageID: record.lkPackageID,
                    packageName: record.packageName,
                    packageAmount: record.packageAmount,
                    bonusAmount: record.bonusAmount,
                    bonusAmountValidity: record.bonusAmountValidityInDays,
                    description: record.description,
                    descriptionTitle: record.descriptionTitle,
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title={labels.EDIT}>
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    lkPackageID: record.lkPackageID,
                    packageName: record.packageName,
                    packageAmount: record.packageAmount,
                    bonusAmount: record.bonusAmount,
                    bonusAmountValidity: record.bonusAmountValidityInDays,
                    description: record.description,
                    descriptionTitle: record.descriptionTitle,
                  })
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}

          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title={labels.ARE_YOU_SURE}
              onConfirm={() => this.removeItems(record.lkPackageID)}
              onCancel={() => null}
              okText={labels.YES}
              cancelText={labels.NO}
            >
              <Tooltip title={labels.DELETE}>
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      lkPackageID: this.state.lkPackageID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      lkPackageID: this.state.lkPackageID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      lkPackageID: 0,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : sorter.order,
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.PackageConfigurationService.retrievePackageConfiguration(params).then(
      (items) => {
        if (items.statusCode === 200) {
          if (
            items.length !== 0 &&
            items.data.length === 0 &&
            params.pagination.current !== 1
          ) {
            params.pagination.current = 1;
            this.fetchItems({
              lkPackageID: 0,
              pageIndex: 1,
              pageSize: params.pageSize,
              pagination: params.pagination,
            });
          }
          this.setState({
            lkPackageConfiguration: items.data,
            pagination: {
              ...params.pagination,
              total:
                items.length !== 0 && items.data.length !== 0
                  ? items.data[0].totalRecords
                  : 0,
            },
          });
        } else {
          notification["error"]({
            message: labels.PACKAGE_CONFIGURATION,
            description: items.message
              ? this.common.Message(items.message)
              : labels.UNABLE_TO_GET_LIST_OF_PACKAGE_CONFIGURATION_DATA,
          });
        }
        this.setState({ loading: false });
      }
    );
  };

  //function for deleting data from server call
  removeItems = (lkPackageID) => {
    if (
      lkPackageID === "" ||
      lkPackageID === null ||
      lkPackageID === undefined
    ) {
      notification["error"]({
        message: labels.PACKAGE_CONFIGURATION,
        description: labels.PLEASE_SELECT_ATLEAST_ONE_ROW,
      });
    } else {
      this.setState({ loading: true });
      this.PackageConfigurationService.removeItems(lkPackageID).then(
        (items) => {
          if (items.result === "Success") {
            notification["success"]({
              message: labels.PACKAGE_CONFIGURATION,
              description: this.common.Message(items.message),
            });
            const { pagination } = this.state;
            this.fetchItems({
              lkPackageID: parseInt(this.state.lkPackageID),
              pageIndex: pagination.current,
              pageSize: pagination.pageSize,
              pagination,
            });
          } else {
            notification["error"]({
              message: labels.PACKAGE_CONFIGURATION,
              description: this.common.Message(items.message),
            });
          }
          this.setState({ loading: false });
        }
      );
    }
  };

  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    this.setState({ modalLoading: true });
    var postData = {
      lkPackageID: this.state.lkPackageID,
      packageName: this.state.packageName,
      packageAmount: parseInt(this.state.packageAmount),
      bonusAmount: parseInt(this.state.bonusAmount),
      bonusAmountValidityInDays: parseInt(this.state.bonusAmountValidity),
      description: this.state.description,
      descriptionTitle: this.state.descriptionTitle,
    };
    this.PackageConfigurationService.insertUpdateItems(postData).then(
      (items) => {
        if (items.result === "Success") {
          notification["success"]({
            message: labels.PACKAGE_CONFIGURATION,
            description: this.common.Message(items.message),
          });
          this.setState({ items: items, showAddPopup: false });
          const { pagination } = this.state;
          this.fetchItems({
            lkPackageID: 0,
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
            // ...filters,
          });
          this.resetFields();
        } else {
          notification["error"]({
            message: labels.PACKAGE_CONFIGURATION,
            description: this.common.Message(items.message),
          });
        }
        this.setState({ modalLoading: false });
      }
    );
  };

  handlePackageName = (e) => {
    this.setState({ packageName: e.target.value });
  };

  handlePackageAmount = (e) => {
    this.setState({ packageAmount: e.target.value });
  };

  handleBonusAmount = (e) => {
    this.setState({ bonusAmount: e.target.value });
  };

  handleBonusAmountValidity = (e) => {
    this.setState({ bonusAmountValidity: e.target.value });
  };

  handleDescriptionTitle = (e) => {
    this.setState({ descriptionTitle: e.target.value });
  };

  handleDescription = (e) => {
    this.setState({ description: e.target.value });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      lkPackageID: 0,
      packageName: "",
      packageAmount: "",
      bonusAmount: "",
      bonusAmountValidity: "",
      description: "",
      descriptionTitle: "",
      isEnabled: false,
      isVisible: false,
    });
  };

  // handleCheckbox=(event)=>{
  //      this.setState({ isAddOn: event.target.checked });
  // }

  render() {
    const {
      packageName,
      packageAmount,
      bonusAmount,
      bonusAmountValidity,
      pagination,
      loading,
      descriptionTitle,
      description,
    } = this.state;
    let updateDescriptionTitle = [null, undefined, ""].includes(
      descriptionTitle
    )
      ? undefined
      : descriptionTitle;

    const packageConfigurationItems = this.state.lkPackageConfiguration;
    // let RegexInteger = /^[1-9]+[0-9]*$/g;
    const listItems = packageConfigurationItems.map((item, index) => ({
      key: item.lkPackageID,
      lkPackageID: item.lkPackageID,
      packageName: item.packageName,
      packageAmount: item.packageAmount,
      bonusAmount: item.bonusAmount,
      bonusAmountValidityInDays: item.bonusAmountValidityInDays,
      description: item.description,
      descriptionTitle: item.descriptionTitle,
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title={labels.PACKAGE_CONFIGURATION}
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                {labels.ADD_PACKAGE_CONFIGURATION}
              </Button>
            ) : null,
            // <Button
            //     type="link"
            //     className={styles.FilterToggle}
            //     onClick={() => {
            //         this.setState({ showfilterBox: !this.state.showfilterBox });
            //     }}
            // >
            //     {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //     <FontAwesomeIcon
            //         icon={faChevronUp}
            //         className={
            //             this.state.showfilterBox
            //                 ? styles.FilterToggleIcon
            //                 : styles.FilterToggleIconRotated
            //         }
            //     />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            packageConfigurationItems.length > 0 ? (
              <Popconfirm
                title={labels.ARE_YOU_SURE}
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText={labels.YES}
                cancelText={labels.NO}
              >
                <Button variant="transparentGreyOutline">
                  {labels.DELETE_SELECTED}
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>
        <Modal
          title={
            this.state.lkPackageID === 0
              ? labels.ADD_PACKAGE_CONFIGURATION
              : this.state.isEnabled
              ? labels.VIEW_PACKAGE_CONFIGURATION
              : labels.EDIT_PACKAGE_CONFIGURATION
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                packageName,
                packageAmount,
                bonusAmount,
                bonusAmountValidity,
                description,
                descriptionTitle: updateDescriptionTitle,
              }}
            >
              <Row gutter={24}>
                <Col xs={12}>
                  <Form.Item
                    label={labels.PACKAGE_NAME}
                    name="packageName"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_PACKAGE_NAME,
                      },
                      // {
                      //   pattern: RegexLetters,
                      //   message: labels.PLEASE_ENTER_ONLY_ALPHABETS,
                      // },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_PACKAGE_NAME}
                      disabled={this.state.isEnabled}
                      onChange={this.handlePackageName}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={labels.PACKAGE_AMOUNT}
                    name="packageAmount"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_PACKAGE_AMOUNT,
                      },
                      {
                        pattern: RegexInteger,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_PACKAGE_AMOUNT}
                      disabled={this.state.isEnabled}
                      prefix="₹"
                      maxLength={20}
                      onChange={this.handlePackageAmount}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={labels.BONUS_AMOUNT}
                    name="bonusAmount"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_BONUS_AMOUNT,
                      },
                      {
                        pattern: RegexInteger,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_BONUS_AMOUNT}
                      disabled={this.state.isEnabled}
                      prefix="₹"
                      maxLength={20}
                      onChange={this.handleBonusAmount}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label={labels.BONUS_AMOUNT_VALIDITY_DAYS}
                    name="bonusAmountValidity"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_BONUS_AMOUNT_VALIDITY_DAYS,
                      },
                      {
                        pattern: RegexInteger,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_BONUS_VALIDITY_DAYS}
                      disabled={this.state.isEnabled}
                      maxLength={3}
                      onChange={this.handleBonusAmountValidity}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label={labels.DESCRIPTION_TITLE}
                    name="descriptionTitle"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_DESCRIPTION_TITLE,
                      },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_DESCRIPTION_TITLE}
                      disabled={this.state.isEnabled}
                      onChange={this.handleDescriptionTitle}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label={labels.DESCRIPTION}
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_DESCRIPTION,
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder={labels.ENTER_DESCRIPTION}
                      disabled={this.state.isEnabled}
                      onChange={this.handleDescription}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.lkPackageID === 0
                            ? labels.ADD
                            : labels.UPDATE}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
