import React from "react";
import { Menu } from "antd";
import styles from "./sidebar.module.css";
import Button from "../Button/button";
import DashboardIcon from "../../../images/Sidebar/dashboard.svg";
import DeliveryIcon from "../../../images/Sidebar/bikedelivery.svg";
import CustomersIcon from "../../../images/Sidebar/customers.svg";
// import PickupsIcon from "../../../images/Sidebar/pickups.svg";
import OrdersIcon from "../../../images/Sidebar/orders.svg";
import TruckIcon from "../../../images/Sidebar/truck.svg";
import MasterIcon from "../../../images/Sidebar/master.svg";
// import TaggingIcon from "../../../images/Sidebar/tagging.svg";
import BillingIcon from "../../../images/Sidebar/billing.svg";
// import TaggingIcon from "../../../images/Sidebar/tagging.svg";
import InventoryIcon from "../../../images/Sidebar/inventory.svg";
import AttendanceIcon from "../../../images/Sidebar/attendance.svg";
import SettingIcon from "../../../images/Sidebar/setting.svg";
import ToggleIcon from "../../../images/Sidebar/toggler.svg";
import { NavLink } from "react-router-dom";
import ROUTES from "../../../routes";
import Configuration from "../../../shared/configuration";
import AuditIcon from "../../../images/Sidebar/audit.svg";
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link,
//   useParams,
//   useRouteMatch,
// } from "react-router-dom";
// import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
const { SubMenu } = Menu;
/**
 * This file contains the sidebar of the application
 *
 * @author Niraj Gohel
 *
 * This class supports below mentioned props
 * @props toggleFunction : a function to modify parent state varibale which holds toggle status (collapsed or not)
 * @props collapsedState : parent state varibale to check whether it is collapsed or not
 *
 * @since 1.0.0
 * @created 2 June 2020
 */
export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.state = {
      userRights: [],
    };
    this.config = new Configuration();
  }

  componentWillMount() {
    // if (!IsAuthenticated()) {
    //   window.location.href = "/";
    // }

    var data = JSON.parse(localStorage.getItem("userrights"));
    // console.log("data->",data);
    // var res = data.filter((val) => val.screenName == this.UserRightsConfiguration.CITY_MASTER)
    this.setState({ userRights: data });
  }

  userRights = (role) => {
    // debugger;
    // var  data1=this.state.userRights.filter((val) => val.screenName == role)
    let filtered = this.state.userRights.filter(
      (val) => val.screenName == role
    );
    // console.log("filtered->",filtered);
    return filtered.length > 0 && filtered[0].isView === true;
  };

  render() {
    /**
     * Stored all menu configs in an array to dynamically render the list
     *
     * @param title : title to be displayed for the menu
     * @param icon : icon to be displayed along with the menu
     *
     * @todo add submenu rendering and more supported props
     * @todo add router links and active toggling
     */

    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    let menuItems = [];
    if (storeOrFactory === "store") {
      menuItems = [
        {
          title: labels.DASHBOARD,
          icon: DashboardIcon,
          url: ROUTES.STORE_DASHBOARD,
        },

        {
          title: labels.DELIVERY_DASHBORAD,
          icon: DeliveryIcon,
          url: ROUTES.DELIVERY_DASHBORAD,
        },

        this.userRights(this.UserRightsConfiguration.CUSTOMER) ||
        this.userRights(this.UserRightsConfiguration.PACKAGE_CONFIG) ||
        this.userRights(this.UserRightsConfiguration.REFERRAL_BONUS) ||
        this.userRights(this.UserRightsConfiguration.EMPLOYEE_CREDITS) ||
        this.userRights(this.UserRightsConfiguration.BUY_PACKAGE) ||
        this.userRights(this.UserRightsConfiguration.ADVANCE_PAYMENT) ||
        this.userRights(this.UserRightsConfiguration.COMPENSATION) ||
        this.userRights(this.UserRightsConfiguration.REFUND) ||
        this.userRights(this.UserRightsConfiguration.CUSTOMER_BULK_CREDIT) ||
        this.userRights(this.UserRightsConfiguration.CUSTOMER_BULK_DEBIT) ||
        this.userRights(
          this.UserRightsConfiguration.CUSTOM_BULK_NOTIFICATION_UPLOAD
        )
          ? {
              title: labels.CUSTOMERS,
              icon: CustomersIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.CUSTOMER)
                  ? {
                      title: labels.MANAGE_CUSTOMERS,
                      url: ROUTES.CUSTOMER_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.PACKAGE_CONFIG)
                  ? {
                      title: labels.PACKAGE_CONFIGURATION,
                      url: ROUTES.PACKAGE_CONFIGURATION,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.REFERRAL_BONUS)
                  ? {
                      title: labels.REFERRAL_BONUS_CONFIGURATION,
                      url: ROUTES.REFFERAL_BONUS_CONFIGURATION,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.EMPLOYEE_CREDITS)
                  ? {
                      title: labels.EMPLOYEE_CREDITS,
                      url: ROUTES.EMPLOYEE_CREDIT,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.BUY_PACKAGE)
                  ? {
                      title: labels.BUY_PACKAGE,
                      url: ROUTES.BUY_PACKAGE,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.ADVANCE_PAYMENT)
                  ? {
                      title: labels.ADVANCE_PAYMENTS,
                      url: ROUTES.ADVANCE_PAYMENT,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.COMPENSATION)
                  ? {
                      title: labels.COMPENSATION,
                      url: ROUTES.COMPENSATION_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.REFUND)
                  ? {
                      title: labels.REFUND,
                      url: ROUTES.LKREFUND,
                    }
                  : null,

                this.userRights(
                  this.UserRightsConfiguration.CUSTOMER_BULK_CREDIT
                )
                  ? {
                      title: labels.CUSTOMER_BULK_CREDIT,
                      url: ROUTES.CUSTOMER_BULK_CREDIT,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.CUSTOMER_BULK_DEBIT
                )
                  ? {
                      title: labels.CUSTOMER_BULK_DEBIT,
                      url: ROUTES.CUSTOMER_BULK_DEBIT,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.CUSTOM_BULK_NOTIFICATION_UPLOAD
                )
                  ? {
                      title: labels.CUSTOM_BULK_NOTIFICATION_UPLOAD,
                      url: ROUTES.CUSTOM_BULK_NOTIFICATION_UPLOAD,
                    }
                  : null,
              ],
            }
          : null,
        this.userRights(this.UserRightsConfiguration.STORE) ||
        this.userRights(this.UserRightsConfiguration.FACTORY) ||
        this.userRights(this.UserRightsConfiguration.CORPORATE) ||
        this.userRights(this.UserRightsConfiguration.CITY_MASTER) ||
        this.userRights(this.UserRightsConfiguration.STATE_MASTER) ||
        this.userRights(this.UserRightsConfiguration.DESIGNATION) ||
        this.userRights(this.UserRightsConfiguration.GARMENT_BRAND) ||
        this.userRights(this.UserRightsConfiguration.GARMENT_SIZE) ||
        this.userRights(this.UserRightsConfiguration.KYC_DOC_TYPE) ||
        this.userRights(this.UserRightsConfiguration.SERVICE) ||
        this.userRights(this.UserRightsConfiguration.GARMENT) ||
        this.userRights(this.UserRightsConfiguration.GARMENT_COLOR) ||
        this.userRights(this.UserRightsConfiguration.REWASH_REASON) ||
        this.userRights(this.UserRightsConfiguration.OPER_INSTRUCTION) ||
        this.userRights(this.UserRightsConfiguration.EXPENSE_TYPE) ||
        this.userRights(this.UserRightsConfiguration.SERVICE_ICONS)
          ? {
              title: labels.MASTER,
              icon: MasterIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.STORE)
                  ? {
                      title: labels.STORE,
                      url: ROUTES.STORE,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.FACTORY)
                  ? {
                      title: labels.FACTORY,
                      url: ROUTES.FACTORY_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.CORPORATE)
                  ? {
                      title: labels.CORPORATE,
                      url: ROUTES.CORPORATE_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.CITY_MASTER)
                  ? {
                      title: labels.CITY,
                      url: ROUTES.CITY_MASTER,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.STATE_MASTER)
                  ? {
                      title: labels.STATE,
                      url: ROUTES.STATE_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.DESIGNATION)
                  ? {
                      title: labels.DESIGNATION,
                      url: ROUTES.DESIGNATION_MASTER,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.GARMENT_BRAND)
                  ? {
                      title: labels.GARMENT_BRAND,
                      url: ROUTES.BRAND_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.GARMENT_SIZE)
                  ? {
                      title: labels.GARMENT_SIZE,
                      url: ROUTES.SIZE_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.KYC_DOC_TYPE)
                  ? {
                      title: labels.KYC_DOCUMENT_TYPE,
                      url: ROUTES.KYCDOCTYPE_MASTER,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.SERVICE)
                  ? {
                      title: labels.SERVICE,
                      url: ROUTES.SERVICE_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.GARMENT)
                  ? {
                      title: labels.GARMENT,
                      url: ROUTES.GARMENT_MASTER,
                    }
                  : null,
                // this.userRights(this.UserRightsConfiguration.GARMENT_COLOR)
                //   ? {
                //       title: "Garment Color",
                //       url: ROUTES.COLOR_MASTER,
                //     }
                //   : null,
                this.userRights(this.UserRightsConfiguration.REWASH_REASON)
                  ? {
                      title: labels.REWASH_REASON,
                      url: ROUTES.REWASH_REASON_MASTER,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.OPER_INSTRUCTION)
                  ? {
                      title: labels.OPERATION_INSTRUCTION,
                      url: ROUTES.OPERATION_INSTRUCTION_MASTER,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.EXPENSE_TYPE)
                  ? {
                      title: labels.EXPENSE_TYPE,
                      url: ROUTES.EXPENSE_TYPE_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.SERVICE_ICONS)
                  ? {
                      title: labels.SERVICE_ICONS,
                      url: ROUTES.SERVICE_ICONS,
                    }
                  : null,
              ],
            }
          : null,
        this.userRights(this.UserRightsConfiguration.PICKUP) ||
        this.userRights(this.UserRightsConfiguration.ORDER_LIST) ||
        this.userRights(
          this.UserRightsConfiguration.READY_FOR_DELIVERY_ORDERS
        ) ||
        this.userRights(this.UserRightsConfiguration.STORE_DELIVERY_LIST) ||
        this.userRights(this.UserRightsConfiguration.PARTIAL_ORDER_RECEIVED) ||
        this.userRights(this.UserRightsConfiguration.VIEW_ON_MAP) 
          ? {
              title: labels.ORDERS,
              icon: OrdersIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.PICKUP)
                  ? {
                      title: labels.PICKUPS,
                      url: ROUTES.PICKUP_LIST,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.ORDER_LIST)
                  ? {
                      title: labels.MANAGE_ORDERS,
                      url: ROUTES.ORDER_LISTING,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.READY_FOR_DELIVERY_ORDERS
                )
                  ? {
                      title: labels.READY_FOR_DELIVERY_OR_INVOICE,
                      url: ROUTES.STORE_READY_FOR_DELIVERY_ORDERS,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.STORE_DELIVERY_LIST
                )
                  ? {
                      title: labels.DELIVERIES,
                      url: ROUTES.DELIVERY_LIST,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.PARTIAL_ORDER_RECEIVED
                )
                  ? {
                      title: labels.PARTIAL_ORDER_RECEIVED,
                      url: ROUTES.PARTIAL_ORDER_RECEIVED,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.VIEW_ON_MAP
                )
                  ? {
                      title: labels.VIEW_ON_MAP,
                      url: ROUTES.VIEW_ON_MAP,
                    }
                  : null,
              ],
            }
          : null,
        this.userRights(this.UserRightsConfiguration.STORE_TRANSFER_IN) ||
        this.userRights(this.UserRightsConfiguration.STORE_TRANSFER_OUT) ||
        this.userRights(this.UserRightsConfiguration.GATE_PASS_LIST) ||
        this.userRights(this.UserRightsConfiguration.STORE_IN_PENDING)
          ? {
              title: labels.TRANSFER_IN_OUT,
              icon: TruckIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.STORE_TRANSFER_OUT)
                  ? {
                      title: labels.TRANSFER_OUT,
                      url: ROUTES.STORE_TRANSFER_OUT,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.GATE_PASS_LIST)
                  ? {
                      title: labels.GATE_PASS_LIST,
                      url: ROUTES.STORE_GATE_PASS_LIST,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.STORE_TRANSFER_IN)
                  ? {
                      title: labels.TRANSFER_IN,
                      url: ROUTES.STORE_TRANSFER_IN,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.STORE_IN_PENDING)
                  ? {
                      title: labels.STORE_IN_PENDING,
                      url: ROUTES.STORE_IN_PENDING,
                    }
                  : null,
              ],
            }
          : null,
        this.userRights(this.UserRightsConfiguration.STORE_MANAGE_QC)
          ? {
              title: labels.PRE_QC_RESPONSES,
              icon: AttendanceIcon,
              url: ROUTES.STORE_PRE_QC_RESPONSE_LIST,
            }
          : null,
        this.userRights(this.UserRightsConfiguration.EXPENSE_ENTRY)
          ? {
              title: labels.USER_EXPENSES,
              url: ROUTES.STORE_EXPENSE_ENTRY,
              icon: BillingIcon,
            }
          : null,
        this.userRights(this.UserRightsConfiguration.STORE_INVENTORY)
          ? {
              title: labels.INVENTORY,
              url: ROUTES.STORE_INVENTORY,
              icon: InventoryIcon,
            }
          : null,
        this.userRights(this.UserRightsConfiguration.USERS) ||
        this.userRights(this.UserRightsConfiguration.ROLES) ||
        this.userRights(this.UserRightsConfiguration.ROLE_OPERATION) ||
        this.userRights(this.UserRightsConfiguration.CASH_COLLECTION) || //added by pb
        this.userRights(this.UserRightsConfiguration.SERVICE_TIME) ||
        this.userRights(this.UserRightsConfiguration.SERVICE_RATE) ||
        this.userRights(this.UserRightsConfiguration.DISCOUNT_MASTER) ||
        this.userRights(this.UserRightsConfiguration.CASH_COUPON) ||
        this.userRights(
          this.UserRightsConfiguration.UPLOAD_CUSTOMER_TO_COUPON_MAPPING
        ) ||
        this.userRights(this.UserRightsConfiguration.PACKAGING_CHARGES) ||
        this.userRights(this.UserRightsConfiguration.DELIVERY_CHARGES) ||
        this.userRights(this.UserRightsConfiguration.REWARDS_CONFIG) ||
        this.userRights(this.UserRightsConfiguration.BANNER) ||
        this.userRights(this.UserRightsConfiguration.NOTICE_BOARD) ||
        this.userRights(this.UserRightsConfiguration.STANDARD_RATE_CARD) ||
        this.userRights(this.UserRightsConfiguration.COLLECT_FINGERPRINTS) ||
        this.userRights(this.UserRightsConfiguration.ATTENDANCE) ||
        this.userRights(this.UserRightsConfiguration.ATTENDANCE_REPORT) ||
        this.userRights(
          this.UserRightsConfiguration.PAYTM_EDC_MACHINE_CONFING
        ) ||
        this.userRights(this.UserRightsConfiguration.HOLIDAY_CONFIGURATION) ||
        this.userRights(this.UserRightsConfiguration.TERMS_AND_CONDITIONS) ||
        this.userRights(this.UserRightsConfiguration.FAQ) ||
        this.userRights(this.UserRightsConfiguration.APPSFLYER)
          ? {
              title: labels.SETTINGS,
              icon: SettingIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.USERS) ||
                this.userRights(this.UserRightsConfiguration.ROLES) ||
                this.userRights(this.UserRightsConfiguration.ROLE_OPERATION) ||
                this.userRights(this.UserRightsConfiguration.CASH_COLLECTION)
                  ? {
                      title: labels.AUTHENTICATION,
                      submenu: [
                        this.userRights(this.UserRightsConfiguration.USERS)
                          ? {
                              title: labels.USERS,
                              url: ROUTES.USER,
                            }
                          : null,

                        this.userRights(this.UserRightsConfiguration.ROLES)
                          ? {
                              title: labels.ROLES,
                              url: ROUTES.ROLE_MASTER,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.ROLE_OPERATION
                        )
                          ? {
                              title: labels.ROLE_OPERATIONS,
                              url: ROUTES.ROLE_SCREEN_MAPPING,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.CASH_COLLECTION
                        )
                          ? {
                              title: labels.CASH_COLLECTION,
                              url: ROUTES.CASH_COLLECTION,
                            }
                          : null,
                      ],
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.SERVICE_TIME) ||
                this.userRights(this.UserRightsConfiguration.SERVICE_RATE) ||
                this.userRights(this.UserRightsConfiguration.DISCOUNT_MASTER) ||
                this.userRights(this.UserRightsConfiguration.CASH_COUPON) ||
                this.userRights(
                  this.UserRightsConfiguration.UPLOAD_CUSTOMER_TO_COUPON_MAPPING
                ) ||
                this.userRights(
                  this.UserRightsConfiguration.PACKAGING_CHARGES
                ) ||
                this.userRights(
                  this.UserRightsConfiguration.DELIVERY_CHARGES
                ) ||
                this.userRights(this.UserRightsConfiguration.REWARDS_CONFIG) ||
                this.userRights(this.UserRightsConfiguration.BANNER) ||
                this.userRights(this.UserRightsConfiguration.NOTICE_BOARD) ||
                this.userRights(
                  this.UserRightsConfiguration.STANDARD_RATE_CARD
                ) ||
                this.userRights(
                  this.UserRightsConfiguration.PAYTM_EDC_MACHINE_CONFING
                ) ||
                this.userRights(
                  this.UserRightsConfiguration.HOLIDAY_CONFIGURATION
                ) ||
                this.userRights(
                  this.UserRightsConfiguration.TERMS_AND_CONDITIONS
                ) ||
                this.userRights(this.UserRightsConfiguration.FAQ) ||
                this.userRights(this.UserRightsConfiguration.APPSFLYER)
                  ? {
                      title: labels.CONFIGURATION,
                      submenu: [
                        this.userRights(
                          this.UserRightsConfiguration.SERVICE_TIME
                        )
                          ? {
                              title: labels.TIME_CARD,
                              url: ROUTES.TIMECARD,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.SERVICE_RATE
                        )
                          ? {
                              title: labels.RATE_CARD,
                              url: ROUTES.RATECARD,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.DISCOUNT_MASTER
                        )
                          ? {
                              title: labels.DISCOUNT,
                              url: ROUTES.DISCOUNT_MASTER,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.CASH_COUPON
                        )
                          ? {
                              title: labels.CASH_COUPON,
                              url: ROUTES.CASH_COUPON,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration
                            .UPLOAD_CUSTOMER_TO_COUPON_MAPPING
                        )
                          ? {
                              title: labels.UPLOAD_CUSTOMER_TO_COUPON_MAPPING,
                              url: ROUTES.UPLOAD_CUSTOMER_TO_COUPON_MAPPING,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.PACKAGING_CHARGES
                        )
                          ? {
                              title: labels.PACKAGING_CHARGES,
                              url: ROUTES.PACKAGE_CHARGES_MASTER,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.DELIVERY_CHARGES
                        )
                          ? {
                              title: labels.DELIVERY_CHARGES,
                              url: ROUTES.DELIVERY_CHARGES_MASTER,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.REWARDS_CONFIG
                        )
                          ? {
                              title: labels.REWARDS_CONFIG,
                              url: ROUTES.REWARDS_CONFIG,
                            }
                          : null,

                        this.userRights(this.UserRightsConfiguration.BANNER)
                          ? {
                              title: labels.BANNER,
                              url: ROUTES.BANNER,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.NOTICE_BOARD
                        )
                          ? {
                              title: labels.NOTICE_BOARD,
                              url: ROUTES.NOTICE_BOARD,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.STANDARD_RATE_CARD
                        )
                          ? {
                              title: labels.STANDARD_RATE_CARD,
                              url: ROUTES.STANDARD_RATE_CARD,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.PAYTM_EDC_MACHINE_CONFING
                        )
                          ? {
                              title: labels.PAYTM_EDC_MACHINE_CONFING,
                              url: ROUTES.PAYTM_EDC_MACHINE_CONFING,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.HOLIDAY_CONFIGURATION
                        )
                          ? {
                              title: labels.HOLIDAY_CONFIGURATION,
                              url: ROUTES.HOLIDAY_CONFIGURATION,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.TERMS_AND_CONDITIONS
                        )
                          ? {
                              title: labels.TERMS_AND_CONDITIONS,
                              url: ROUTES.TERMS_AND_CONDITIONS,
                            }
                          : null,
                        this.userRights(this.UserRightsConfiguration.FAQ)
                          ? {
                              title: labels.FAQ,
                              url: ROUTES.FAQ,
                            }
                          : null,
                        this.userRights(this.UserRightsConfiguration.APPSFLYER)
                          ? {
                              title: labels.APPSFLYER,
                              url: ROUTES.APPSFLYER,
                            }
                          : null,
                      ],
                    }
                  : null,

                this.userRights(
                  this.UserRightsConfiguration.COLLECT_FINGERPRINTS
                ) ||
                this.userRights(this.UserRightsConfiguration.ATTENDANCE) ||
                this.userRights(this.UserRightsConfiguration.ATTENDANCE_REPORT)
                  ? {
                      title: labels.FINGER_SCAN,
                      submenu: [
                        this.userRights(
                          this.UserRightsConfiguration.COLLECT_FINGERPRINTS
                        )
                          ? {
                              title: labels.COLLECT_FINGERPRINTS,
                              url: ROUTES.COLLECT_FINGERS,
                            }
                          : null,
                        this.userRights(this.UserRightsConfiguration.ATTENDANCE)
                          ? {
                              title: labels.ATTENDANCE,
                              url: ROUTES.ATTENDANCE,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.ATTENDANCE_REPORT
                        )
                          ? {
                              title: labels.ATTENDANCE_REPORT,
                              url: ROUTES.ATTENDANCEREPORT,
                            }
                          : null,
                      ],
                    }
                  : null,
              ],
            }
          : null,

        this.userRights(this.UserRightsConfiguration.STORE_ORDER_HISTORY)
          ? {
              title: labels.ORDER_HISTORY,
              url: ROUTES.STORE_ORDER_HISTORY,
              icon: OrdersIcon,
            }
          : null,

        this.userRights(this.UserRightsConfiguration.PENDING_CASH_SUMMARY) ||
        this.userRights(this.UserRightsConfiguration.ORDERS_SUMMARY) ||
        this.userRights(this.UserRightsConfiguration.OUT_OF_COVERAGE) ||
        this.userRights(
          this.UserRightsConfiguration.PICKUP_DELIVERY_ASSIGNMENT
        ) ||
        this.userRights(this.UserRightsConfiguration.DAILY_ORDER_SUMMARY) ||
        this.userRights(this.UserRightsConfiguration.MONTHLY_ORDER_SUMMARY) ||
        this.userRights(this.UserRightsConfiguration.DAILY_PICKUP_SUMMARY) ||
        this.userRights(this.UserRightsConfiguration.MONTHLY_PICKUP_SUMMARY)
          ? {
              title: labels.AUDIT,
              icon: AuditIcon,
              submenu: [
                this.userRights(
                  this.UserRightsConfiguration.PENDING_CASH_SUMMARY
                )
                  ? {
                      title: labels.PENDING_CASH_SUMMARY,
                      url: ROUTES.PENDING_CASH_SUMMARY,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.ORDERS_SUMMARY)
                  ? {
                      title: labels.ORDERS_SUMMARY,
                      url: ROUTES.ORDERS_SUMMARY,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.OUT_OF_COVERAGE)
                  ? {
                      title: labels.OUT_OF_COVERAGE,
                      url: ROUTES.OUT_OF_COVERAGE,
                    }
                  : null,

                this.userRights(
                  this.UserRightsConfiguration.PICKUP_DELIVERY_ASSIGNMENT
                )
                  ? {
                      title: labels.PICKUP_DELIVERY_ASSIGNMENT,
                      url: ROUTES.PICKUP_DELIVERY_ASSIGNMENT,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.DAILY_ORDER_SUMMARY
                )
                  ? {
                      title: labels.DAILY_ORDER_SUMMARY,
                      url: ROUTES.DAILY_ORDER_SUMMARY,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.MONTHLY_ORDER_SUMMARY
                )
                  ? {
                      title: labels.MONTHLY_ORDER_SUMMARY,
                      url: ROUTES.MONTHLY_ORDER_SUMMARY,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.DAILY_PICKUP_SUMMARY
                )
                  ? {
                      title: labels.DAILY_PICKUP_SUMMARY,
                      url: ROUTES.DAILY_PICKUP_SUMMARY,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.MONTHLY_PICKUP_SUMMARY
                )
                  ? {
                      title: labels.MONTHLY_PICKUP_SUMMARY,
                      url: ROUTES.MONTHLY_PICKUP_SUMMARY,
                    }
                  : null,
              ],
            }
          : null,
      ];
    } else if (storeOrFactory === "factory") {
      menuItems = [
        {
          title: labels.DASHBOARD,
          url: ROUTES.FACTORY_DASHBOARD,
          icon: DashboardIcon,
        },
        this.userRights(this.UserRightsConfiguration.STORE) ||
        this.userRights(this.UserRightsConfiguration.FACTORY) ||
        this.userRights(this.UserRightsConfiguration.CORPORATE) ||
        this.userRights(this.UserRightsConfiguration.CITY_MASTER) ||
        this.userRights(this.UserRightsConfiguration.STATE_MASTER) ||
        this.userRights(this.UserRightsConfiguration.DESIGNATION) ||
        this.userRights(this.UserRightsConfiguration.GARMENT_BRAND) ||
        this.userRights(this.UserRightsConfiguration.GARMENT_SIZE) ||
        this.userRights(this.UserRightsConfiguration.KYC_DOC_TYPE) ||
        this.userRights(this.UserRightsConfiguration.SERVICE) ||
        this.userRights(this.UserRightsConfiguration.GARMENT) ||
        this.userRights(this.UserRightsConfiguration.GARMENT_COLOR) ||
        this.userRights(this.UserRightsConfiguration.REWASH_REASON) ||
        this.userRights(this.UserRightsConfiguration.OPER_INSTRUCTION) ||
        this.userRights(this.UserRightsConfiguration.EXPENSE_TYPE) ||
        this.userRights(this.UserRightsConfiguration.SERVICE_ICONS)
          ? {
              title: labels.MASTER,
              icon: MasterIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.STORE)
                  ? {
                      title: labels.STORE,
                      url: ROUTES.STORE,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.FACTORY)
                  ? {
                      title: labels.FACTORY,
                      url: ROUTES.FACTORY_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.CORPORATE)
                  ? {
                      title: labels.CORPORATE,
                      url: ROUTES.CORPORATE_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.CITY_MASTER)
                  ? {
                      title: labels.CITY,
                      url: ROUTES.CITY_MASTER,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.STATE_MASTER)
                  ? {
                      title: labels.STATE,
                      url: ROUTES.STATE_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.DESIGNATION)
                  ? {
                      title: labels.DESIGNATION,
                      url: ROUTES.DESIGNATION_MASTER,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.GARMENT_BRAND)
                  ? {
                      title: labels.GARMENT_BRAND,
                      url: ROUTES.BRAND_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.GARMENT_SIZE)
                  ? {
                      title: labels.GARMENT_SIZE,
                      url: ROUTES.SIZE_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.KYC_DOC_TYPE)
                  ? {
                      title: labels.KYC_DOCUMENT_TYPE,
                      url: ROUTES.KYCDOCTYPE_MASTER,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.SERVICE)
                  ? {
                      title: labels.SERVICE,
                      url: ROUTES.SERVICE_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.GARMENT)
                  ? {
                      title: labels.GARMENT,
                      url: ROUTES.GARMENT_MASTER,
                    }
                  : null,
                // this.userRights(this.UserRightsConfiguration.GARMENT_COLOR)
                //   ? {
                //       title: "Garment Color",
                //       url: ROUTES.COLOR_MASTER,
                //     }
                //   : null,
                this.userRights(this.UserRightsConfiguration.REWASH_REASON)
                  ? {
                      title: labels.REWASH_REASON,
                      url: ROUTES.REWASH_REASON_MASTER,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.OPER_INSTRUCTION)
                  ? {
                      title: labels.OPERATION_INSTRUCTION,
                      url: ROUTES.OPERATION_INSTRUCTION_MASTER,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.EXPENSE_TYPE)
                  ? {
                      title: labels.EXPENSE_TYPE,
                      url: ROUTES.EXPENSE_TYPE_MASTER,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.SERVICE_ICONS)
                  ? {
                      title: labels.SERVICE_ICONS,
                      url: ROUTES.SERVICE_ICONS,
                    }
                  : null,
              ],
            }
          : null,
        this.userRights(this.UserRightsConfiguration.FACTORY_IN) ||
        this.userRights(this.UserRightsConfiguration.FACTORY_OUT) ||
        this.userRights(this.UserRightsConfiguration.RESORT_PACK) ||
        this.userRights(
          this.UserRightsConfiguration.RESORTING_AND_PACKAGING_LIST
        ) ||
        this.userRights(this.UserRightsConfiguration.FACTORY_GATE_PASS) ||
        this.userRights(this.UserRightsConfiguration.FACTORY_IN_PENDING)
          ? {
              title: labels.TRANSFER_IN_OUT,
              icon: TruckIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.FACTORY_IN)
                  ? {
                      title: labels.TRANSFER_IN,
                      url: ROUTES.TRANSFER_IN_FACTORY,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.RESORT_PACK)
                  ? {
                      title: labels.RESORTING_AND_PACKAGING,
                      url: ROUTES.FACTORY_Resorting_Packaging,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.FACTORY_OUT)
                  ? {
                      title: labels.TRANSFER_OUT,
                      url: ROUTES.TRANSFER_OUT_FACTORY,
                    }
                  : null,
                this.userRights(
                  this.UserRightsConfiguration.RESORTING_AND_PACKAGING_LIST
                )
                  ? {
                      title: labels.RESORTING_AND_PACKAGING_List,
                      url: ROUTES.RESORTING_AND_PACKAGING,
                    }
                  : null,

                this.userRights(this.UserRightsConfiguration.FACTORY_GATE_PASS)
                  ? {
                      title: labels.FACTORY_GATE_PASS,
                      url: ROUTES.FACTORY_GATE_PASS_LIST,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.FACTORY_IN_PENDING)
                  ? {
                      title: labels.FACTORY_IN_PENDING,
                      url: ROUTES.FACTORY_IN_PENDING,
                    }
                  : null,
              ],
            }
          : null,

        this.userRights(this.UserRightsConfiguration.GARMENT_PROCESSING) ||
        this.userRights(this.UserRightsConfiguration.PROCESSING_LIST)
          ? {
              title: labels.PROCESSING,
              icon: OrdersIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.GARMENT_PROCESSING)
                  ? {
                      title: labels.GARMENT_PROCESSING,
                      url: ROUTES.GARMENT_PROCESSING,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.PROCESSING_LIST)
                  ? {
                      title: labels.PROCESSING_LIST,
                      url: ROUTES.PROCESSING_LIST,
                    }
                  : null,
              ],
            }
          : null,

        this.userRights(this.UserRightsConfiguration.FACTORY_PRE_QC) ||
        this.userRights(this.UserRightsConfiguration.FACTORY_POST_QC)
          ? {
              title: labels.QC_RESPONSES,
              icon: AttendanceIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.FACTORY_PRE_QC)
                  ? {
                      title: labels.PRE_QC,
                      url: ROUTES.PRE_QC_FACTORY,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.FACTORY_POST_QC)
                  ? {
                      title: labels.POST_QC,
                      url: ROUTES.POST_QC_FACTORY,
                    }
                  : null,
              ],
            }
          : null,
        this.userRights(
          this.UserRightsConfiguration.FACTORY_GARMENT_INVENTORY
        ) || this.userRights(this.UserRightsConfiguration.INVENTORY_SUMMARY)
          ? {
              title: labels.INVENTORY,
              // url: ROUTES.FACTORY_INVENTORY,
              icon: InventoryIcon,
              submenu: [
                this.userRights(
                  this.UserRightsConfiguration.FACTORY_GARMENT_INVENTORY
                )
                  ? {
                      title: labels.FACTORY_GARMENT_INVENTORY,
                      url: ROUTES.FACTORY_GARMENT_INVENTORY,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.INVENTORY_SUMMARY)
                  ? {
                      title: labels.INVENTORY_SUMMARY,
                      url: ROUTES.INVENTORY_SUMMARY,
                    }
                  : null,
              ],
            }
          : null,

        this.userRights(this.UserRightsConfiguration.EXPRESS_URGENT) ||
        this.userRights(this.UserRightsConfiguration.REWASH) ||
        this.userRights(this.UserRightsConfiguration.FIVE_STARE)
          ? {
              title: labels.DAILY_SHEETS,
              // url: ROUTES.FACTORY_INVENTORY,
              icon: InventoryIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.EXPRESS_URGENT)
                  ? {
                      title: labels.EXPRESS_URGENT,
                      url: ROUTES.EXPRESS_URGENT,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.REWASH)
                  ? {
                      title: labels.REWASH,
                      url: ROUTES.REWASH,
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.FIVE_STARE)
                  ? {
                      title: labels.FIVE_STARE,
                      url: ROUTES.FIVE_STARE,
                    }
                  : null,
              ],
            }
          : null,

        // this.userRights(this.UserRightsConfiguration.FACTORY_INVENTORY)  // comment by Hasti
        // ? {
        //     title: labels.INVENTORY,
        //     url: ROUTES.FACTORY_INVENTORY,
        //     icon: InventoryIcon,
        //   }
        // : null,
        this.userRights(this.UserRightsConfiguration.USERS) ||
        this.userRights(this.UserRightsConfiguration.ROLES) ||
        this.userRights(this.UserRightsConfiguration.ROLE_OPERATION) ||
        this.userRights(this.UserRightsConfiguration.CASH_COLLECTION) ||
        this.userRights(this.UserRightsConfiguration.SERVICE_TIME) ||
        this.userRights(this.UserRightsConfiguration.SERVICE_RATE) ||
        this.userRights(this.UserRightsConfiguration.DISCOUNT_MASTER) ||
        this.userRights(this.UserRightsConfiguration.CASH_COUPON) ||
        this.userRights(
          this.UserRightsConfiguration.UPLOAD_CUSTOMER_TO_COUPON_MAPPING
        ) ||
        this.userRights(this.UserRightsConfiguration.PACKAGING_CHARGES) ||
        this.userRights(this.UserRightsConfiguration.DELIVERY_CHARGES) ||
        this.userRights(this.UserRightsConfiguration.REWARDS_CONFIG) ||
        this.userRights(this.UserRightsConfiguration.BANNER) ||
        this.userRights(this.UserRightsConfiguration.NOTICE_BOARD) ||
        this.userRights(this.UserRightsConfiguration.STANDARD_RATE_CARD) ||
        this.userRights(
          this.UserRightsConfiguration.PAYTM_EDC_MACHINE_CONFING
        ) ||
        this.userRights(this.UserRightsConfiguration.HOLIDAY_CONFIGURATION) ||
        this.userRights(this.UserRightsConfiguration.COLLECT_FINGERPRINTS) ||
        this.userRights(this.UserRightsConfiguration.ATTENDANCE) ||
        this.userRights(this.UserRightsConfiguration.ATTENDANCE_REPORT) ||
        this.userRights(this.UserRightsConfiguration.TERMS_AND_CONDITIONS) ||
        this.userRights(this.UserRightsConfiguration.FAQ)
          ? {
              title: labels.SETTINGS,
              icon: SettingIcon,
              submenu: [
                this.userRights(this.UserRightsConfiguration.USERS) ||
                this.userRights(this.UserRightsConfiguration.ROLES) ||
                this.userRights(
                  this.UserRightsConfiguration.ROLE_OPERATION ||
                    this.userRights(
                      this.UserRightsConfiguration.CASH_COLLECTION
                    )
                )
                  ? {
                      title: labels.AUTHENTICATION,
                      submenu: [
                        this.userRights(this.UserRightsConfiguration.USERS)
                          ? {
                              title: labels.USERS,
                              url: ROUTES.USER,
                            }
                          : null,

                        this.userRights(this.UserRightsConfiguration.ROLES)
                          ? {
                              title: labels.ROLES,
                              url: ROUTES.ROLE_MASTER,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.ROLE_OPERATION
                        )
                          ? {
                              title: labels.ROLE_OPERATIONS,
                              url: ROUTES.ROLE_SCREEN_MAPPING,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.CASH_COLLECTION
                        )
                          ? {
                              title: labels.CASH_COLLECTION,
                              url: ROUTES.CASH_COLLECTION,
                            }
                          : null,
                      ],
                    }
                  : null,
                this.userRights(this.UserRightsConfiguration.SERVICE_TIME) ||
                this.userRights(this.UserRightsConfiguration.SERVICE_RATE) ||
                this.userRights(this.UserRightsConfiguration.DISCOUNT_MASTER) ||
                this.userRights(this.UserRightsConfiguration.CASH_COUPON) ||
                this.userRights(
                  this.UserRightsConfiguration.UPLOAD_CUSTOMER_TO_COUPON_MAPPING
                ) ||
                this.userRights(
                  this.UserRightsConfiguration.PACKAGING_CHARGES
                ) ||
                this.userRights(
                  this.UserRightsConfiguration.DELIVERY_CHARGES
                ) ||
                this.userRights(this.UserRightsConfiguration.REWARDS_CONFIG) ||
                this.userRights(this.UserRightsConfiguration.BANNER) ||
                this.userRights(this.UserRightsConfiguration.NOTICE_BOARD) ||
                this.userRights(
                  this.UserRightsConfiguration.STANDARD_RATE_CARD
                ) ||
                this.userRights(
                  this.UserRightsConfiguration.PAYTM_EDC_MACHINE_CONFING
                ) ||
                this.userRights(
                  this.UserRightsConfiguration.HOLIDAY_CONFIGURATION
                ) ||
                this.userRights(
                  this.UserRightsConfiguration.TERMS_AND_CONDITIONS
                ) ||
                this.userRights(this.UserRightsConfiguration.FAQ)
                  ? {
                      title: labels.CONFIGURATION,
                      submenu: [
                        this.userRights(
                          this.UserRightsConfiguration.SERVICE_TIME
                        )
                          ? {
                              title: labels.TIME_CARD,
                              url: ROUTES.TIMECARD,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.SERVICE_RATE
                        )
                          ? {
                              title: labels.RATE_CARD,
                              url: ROUTES.RATECARD,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.DISCOUNT_MASTER
                        )
                          ? {
                              title: labels.DISCOUNT,
                              url: ROUTES.DISCOUNT_MASTER,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.CASH_COUPON
                        )
                          ? {
                              title: labels.CASH_COUPON,
                              url: ROUTES.CASH_COUPON,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration
                            .UPLOAD_CUSTOMER_TO_COUPON_MAPPING
                        )
                          ? {
                              title: labels.UPLOAD_CUSTOMER_TO_COUPON_MAPPING,
                              url: ROUTES.UPLOAD_CUSTOMER_TO_COUPON_MAPPING,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.PACKAGING_CHARGES
                        )
                          ? {
                              title: labels.PACKAGING_CHARGES,
                              url: ROUTES.PACKAGE_CHARGES_MASTER,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.DELIVERY_CHARGES
                        )
                          ? {
                              title: labels.DELIVERY_CHARGES,
                              url: ROUTES.DELIVERY_CHARGES_MASTER,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.REWARDS_CONFIG
                        )
                          ? {
                              title: labels.REWARDS_CONFIG,
                              url: ROUTES.REWARDS_CONFIG,
                            }
                          : null,
                        this.userRights(this.UserRightsConfiguration.BANNER)
                          ? {
                              title: labels.BANNER,
                              url: ROUTES.BANNER,
                            }
                          : null,

                        this.userRights(
                          this.UserRightsConfiguration.NOTICE_BOARD
                        )
                          ? {
                              title: labels.NOTICE_BOARD,
                              url: ROUTES.NOTICE_BOARD,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.STANDARD_RATE_CARD
                        )
                          ? {
                              title: labels.STANDARD_RATE_CARD,
                              url: ROUTES.STANDARD_RATE_CARD,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.PAYTM_EDC_MACHINE_CONFING
                        )
                          ? {
                              title: labels.PAYTM_EDC_MACHINE_CONFING,
                              url: ROUTES.PAYTM_EDC_MACHINE_CONFING,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.HOLIDAY_CONFIGURATION
                        )
                          ? {
                              title: labels.HOLIDAY_CONFIGURATION,
                              url: ROUTES.HOLIDAY_CONFIGURATION,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.TERMS_AND_CONDITIONS
                        )
                          ? {
                              title: labels.TERMS_AND_CONDITIONS,
                              url: ROUTES.TERMS_AND_CONDITIONS,
                            }
                          : null,
                        this.userRights(this.UserRightsConfiguration.FAQ)
                          ? {
                              title: labels.FAQ,
                              url: ROUTES.FAQ,
                            }
                          : null,
                      ],
                    }
                  : null,

                this.userRights(
                  this.UserRightsConfiguration.COLLECT_FINGERPRINTS
                ) ||
                this.userRights(this.UserRightsConfiguration.ATTENDANCE) ||
                this.userRights(this.UserRightsConfiguration.ATTENDANCE_REPORT)
                  ? {
                      title: labels.FINGER_SCAN,
                      submenu: [
                        this.userRights(
                          this.UserRightsConfiguration.COLLECT_FINGERPRINTS
                        )
                          ? {
                              title: labels.COLLECT_FINGERPRINTS,
                              url: ROUTES.COLLECT_FINGERS,
                            }
                          : null,
                        this.userRights(this.UserRightsConfiguration.ATTENDANCE)
                          ? {
                              title: labels.ATTENDANCE,
                              url: ROUTES.ATTENDANCE,
                            }
                          : null,
                        this.userRights(
                          this.UserRightsConfiguration.ATTENDANCE_REPORT
                        )
                          ? {
                              title: labels.ATTENDANCE_REPORT,
                              url: ROUTES.ATTENDANCEREPORT,
                            }
                          : null,
                      ],
                    }
                  : null,
              ],
            }
          : null,

        this.userRights(this.UserRightsConfiguration.FACTORY_ORDER_HISTORY)
          ? {
              title: labels.ORDER_HISTORY,
              url: ROUTES.FACTORY_ORDER_HISTORY,
              icon: OrdersIcon,
            }
          : null,
      ];
    }
    // menuItems = [
    //   {
    //     id: "1",
    //     title: "Dashboard",
    //     icon: DashboardIcon,
    //     exact: true,
    //     url: ROUTES.DASHBOARD,
    //   },

    //   {
    //     id: "21",
    //     title: "Authentication",
    //     icon: CustomersIcon,
    //     url: ROUTES.AUTHENTICATION,
    //     submenu: [
    //       this.userRights(this.UserRightsConfiguration.USERS).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.USERS)[0].isView == true
    //         ? {
    //             id: "19",
    //             title: "Manage Users ",
    //             url: ROUTES.USER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.ROLES).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.ROLES)[0].isView == true
    //         ? {
    //             id: "43",
    //             title: "Roles",
    //             url: ROUTES.ROLE_MASTER,
    //           }
    //         : null,
    //       this.userRights(this.UserRightsConfiguration.ROLE_OPERATION).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.ROLE_OPERATION)[0]
    //         .isView == true
    //         ? {
    //             id: "42",
    //             title: "Manage Role Operation",
    //             url: ROUTES.ROLE_SCREEN_MAPPING,
    //           }
    //         : null,
    //     ],
    //   },

    //   {
    //     id: "50",
    //     title: "Admin Configuration",
    //     icon: CustomersIcon,
    //     url: ROUTES.ADMIN_CONFIG,
    //     submenu: [
    //       this.userRights(this.UserRightsConfiguration.SERVICE_TIME).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.SERVICE_TIME)[0]
    //         .isView == true
    //         ? {
    //             id: "38",
    //             title: "Service Time Card",
    //             url: ROUTES.TIMECARD,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.SERVICE_RATE).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.SERVICE_RATE)[0]
    //         .isView == true
    //         ? {
    //             id: "22",
    //             title: "Service Rate Card",
    //             url: ROUTES.RATECARD,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.DISCOUNT_MASTER).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.DISCOUNT_MASTER)[0]
    //         .isView == true
    //         ? {
    //             id: "45",
    //             title: "Discount Master",
    //             url: ROUTES.DISCOUNT_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.CASH_COUPON).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.CASH_COUPON)[0].isView ==
    //         true
    //         ? {
    //             id: "44",
    //             title: "Cash Coupon",
    //             url: ROUTES.CASH_COUPON,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.PACKAGING_CHARGES)
    //         .length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.PACKAGING_CHARGES)[0]
    //         .isView == true
    //         ? {
    //             id: "15",
    //             title: "Packaging Charges",
    //             url: ROUTES.PACKAGE_CHARGES_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.DELIVERY_CHARGES)
    //         .length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.DELIVERY_CHARGES)[0]
    //         .isView == true
    //         ? {
    //             id: "16",
    //             title: "Delivery Charges",
    //             url: ROUTES.DELIVERY_CHARGES_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.BANNER).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.BANNER)[0].isView == true
    //         ? {
    //             id: "53",
    //             title: "Banner",
    //             url: ROUTES.BANNER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.NOTICE_BOARD).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.NOTICE_BOARD)[0]
    //         .isView == true
    //         ? {
    //             id: "54",
    //             title: "Notice Board",
    //             url: ROUTES.NOTICE_BOARD,
    //           }
    //         : null,
    //     ],
    //   },

    //   {
    //     id: "2",
    //     title: "Master",
    //     icon: InventoryIcon,
    //     url: ROUTES.MASTER,
    //     submenu: [
    //       this.userRights(this.UserRightsConfiguration.CUSTOMER).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.CUSTOMER)[0].isView ==
    //         true
    //         ? {
    //             id: "18",
    //             title: "Customer",
    //             url: ROUTES.CUSTOMER_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.STORE).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.STORE)[0].isView == true
    //         ? {
    //             id: "20",
    //             title: "Store",
    //             url: ROUTES.STORE,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.FACTORY).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.FACTORY)[0].isView ==
    //         true
    //         ? {
    //             id: "3",
    //             title: "Factory",
    //             url: ROUTES.FACTORY_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.CORPORATE).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.CORPORATE)[0].isView ==
    //         true
    //         ? {
    //             id: "28",
    //             title: "Corporate",
    //             url: ROUTES.CORPORATE_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.CITY_MASTER).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.CITY_MASTER)[0].isView ==
    //         true
    //         ? {
    //             id: "4",
    //             title: "City",
    //             url: ROUTES.CITY_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.STATE_MASTER).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.STATE_MASTER)[0]
    //         .isView == true
    //         ? {
    //             id: "5",
    //             title: "State",
    //             url: ROUTES.STATE_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.DESIGNATION).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.DESIGNATION)[0].isView ==
    //         true
    //         ? {
    //             id: "6",
    //             title: "Designation",
    //             url: ROUTES.DESIGNATION_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.GARMENT_BRAND).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.GARMENT_BRAND)[0]
    //         .isView == true
    //         ? {
    //             id: "7",
    //             title: "Garment Brand",
    //             url: ROUTES.BRAND_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.GARMENT_SIZE).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.GARMENT_SIZE)[0]
    //         .isView == true
    //         ? {
    //             id: "8",
    //             title: "Garment Size",
    //             url: ROUTES.SIZE_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.KYC_DOC_TYPE).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.KYC_DOC_TYPE)[0]
    //         .isView == true
    //         ? {
    //             id: "9",
    //             title: "KYC Document Type",
    //             url: ROUTES.KYCDOCTYPE_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.SERVICE).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.SERVICE)[0].isView ==
    //         true
    //         ? {
    //             id: "10",
    //             title: "Service",
    //             url: ROUTES.SERVICE_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.GARMENT).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.GARMENT)[0].isView ==
    //         true
    //         ? {
    //             id: "11",
    //             title: "Garment",
    //             url: ROUTES.GARMENT_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.GARMENT_COLOR).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.GARMENT_COLOR)[0]
    //         .isView == true
    //         ? {
    //             id: "12",
    //             title: "Garment Color",
    //             url: ROUTES.COLOR_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.REWASH_REASON).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.REWASH_REASON)[0]
    //         .isView == true
    //         ? {
    //             id: "13",
    //             title: "Rewash Reason",
    //             url: ROUTES.REWASH_REASON_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.OPER_INSTRUCTION)
    //         .length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.OPER_INSTRUCTION)[0]
    //         .isView == true
    //         ? {
    //             id: "14",
    //             title: "Operation Instruction",
    //             url: ROUTES.OPERATION_INSTRUCTION_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.EXPENSE_TYPE).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.EXPENSE_TYPE)[0]
    //         .isView == true
    //         ? {
    //             id: "17",
    //             title: "Expense Type",
    //             url: ROUTES.EXPENSE_TYPE_MASTER,
    //           }
    //         : null,
    //     ],
    //   },

    //   {
    //     id: "31",
    //     title: "Store Module",
    //     icon: InventoryIcon,
    //     url: ROUTES.STOREMODULE,
    //     submenu: [
    //       {
    //         id: "80",
    //         title: "Dashboard",
    //         url: ROUTES.STORE_DASHBOARD,
    //       },

    //       this.userRights(this.UserRightsConfiguration.PICKUP).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.PICKUP)[0].isView == true
    //         ? {
    //             id: "46",
    //             title: "Pickup",
    //             url: ROUTES.PICKUP_LIST,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.ORDER_LIST).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.ORDER_LIST)[0].isView ==
    //         true
    //         ? {
    //             id: "57",
    //             title: "Order List",
    //             url: ROUTES.ORDER_LISTING,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.STORE_TRANSFER_OUT)
    //         .length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.STORE_TRANSFER_OUT)[0]
    //         .isView == true
    //         ? {
    //             id: "44",
    //             title: "Transfer Out",
    //             url: ROUTES.STORE_TRANSFER_OUT,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.STORE_TRANSFER_IN)
    //         .length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.STORE_TRANSFER_IN)[0]
    //         .isView == true
    //         ? {
    //             id: "47",
    //             title: "Transfer In",
    //             url: ROUTES.STORE_TRANSFER_IN,
    //           }
    //         : null,

    //       this.userRights(
    //         this.UserRightsConfiguration.READY_FOR_DELIVERY_ORDERS
    //       ).length > 0 &&
    //       this.userRights(
    //         this.UserRightsConfiguration.READY_FOR_DELIVERY_ORDERS
    //       )[0].isView == true
    //         ? {
    //             id: "37",
    //             title: "Ready for Delivery Orders",
    //             url: ROUTES.STORE_READY_FOR_DELIVERY_ORDERS,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.STORE_MANAGE_QC).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.STORE_MANAGE_QC)[0]
    //         .isView == true
    //         ? {
    //             id: "36",
    //             title: "Pre-QC Response List",
    //             url: ROUTES.STORE_PRE_QC_RESPONSE_LIST,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.GATE_PASS_LIST).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.GATE_PASS_LIST)[0]
    //         .isView == true
    //         ? {
    //             id: "35",
    //             title: "Gate Pass List",
    //             url: ROUTES.STORE_GATE_PASS_LIST,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.STORE_DELIVERY_LIST)
    //         .length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.STORE_DELIVERY_LIST)[0]
    //         .isView == true
    //         ? {
    //             id: "55",
    //             title: "Delivery List",
    //             url: ROUTES.DELIVERY_LIST,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.EXPENSE_ENTRY).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.EXPENSE_ENTRY)[0]
    //         .isView == true
    //         ? {
    //             id: "34",
    //             title: "Expense Entry",
    //             url: ROUTES.STORE_EXPENSE_ENTRY,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.STORE_INVENTORY).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.STORE_INVENTORY)[0]
    //         .isView == true
    //         ? {
    //             id: "24",
    //             title: "Store Inventory",
    //             url: ROUTES.STORE_INVENTORY,
    //           }
    //         : null,

    //       // this.userRights(this.UserRightsConfiguration.INVOICE).length > 0 &&
    //       // this.userRights(this.UserRightsConfiguration.INVOICE)[0].isView ==
    //       //   true
    //       //   ? {
    //       //       id: "56",
    //       //       title: "Invoice",
    //       //       url: ROUTES.PROFORMA_INVOICE,
    //       //     }
    //       //   : null,
    //     ],
    //   },

    //   {
    //     id: "25",
    //     title: "Factory Module",
    //     icon: InventoryIcon,
    //     url: ROUTES.FACTORY,
    //     submenu: [
    //       {
    //         id: "30",
    //         title: "Dashboard",
    //         url: ROUTES.FACTORY_DASHBOARD,
    //       },

    //       this.userRights(this.UserRightsConfiguration.FACTORY_IN).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.FACTORY_IN)[0].isView ==
    //         true
    //         ? {
    //             id: "32",
    //             title: "Factory In",
    //             url: ROUTES.TRANSFER_IN_FACTORY,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.FACTORY_PRE_QC).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.FACTORY_PRE_QC)[0]
    //         .isView == true
    //         ? {
    //             id: "27",
    //             title: "Pre QC",
    //             url: ROUTES.PRE_QC_FACTORY,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.FACTORY_POST_QC).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.FACTORY_POST_QC)[0]
    //         .isView == true
    //         ? {
    //             id: "29",
    //             title: "Post QC",
    //             url: ROUTES.POST_QC_FACTORY,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.RESORT_PACK).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.RESORT_PACK)[0].isView ==
    //         true
    //         ? {
    //             id: "49",
    //             title: "Resorting & Packaging",
    //             url: ROUTES.FACTORY_Resorting_Packaging,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.FACTORY_OUT).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.FACTORY_OUT)[0].isView ==
    //         true
    //         ? {
    //             id: "26",
    //             title: "Factory Out",
    //             url: ROUTES.TRANSFER_OUT_FACTORY,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.FACTORY_INVENTORY)
    //         .length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.FACTORY_INVENTORY)[0]
    //         .isView == true
    //         ? {
    //             id: "33",
    //             title: "Factory Inventory",
    //             url: ROUTES.FACTORY_INVENTORY,
    //           }
    //         : null,
    //     ],
    //   },

    //   {
    //     id: "39",
    //     title: "LK Wallet",
    //     icon: InventoryIcon,
    //     url: ROUTES.LKWALLET,
    //     submenu: [
    //       this.userRights(this.UserRightsConfiguration.PACKAGE_CONFIG).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.PACKAGE_CONFIG)[0]
    //         .isView == true
    //         ? {
    //             id: "40",
    //             title: "Package Configuration",
    //             url: ROUTES.PACKAGE_CONFIGURATION,
    //           }
    //         : null,
    //       this.userRights(this.UserRightsConfiguration.PACKAGE_CONFIG).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.PACKAGE_CONFIG)[0]
    //         .isView == true
    //         ? {
    //             id: "60",
    //             title: "Buy Package",
    //             url: ROUTES.BUY_PACKAGE,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.REFERRAL_BONUS).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.REFERRAL_BONUS)[0]
    //         .isView == true
    //         ? {
    //             id: "41",
    //             title: "Refferal Bonus Confuguration",
    //             url: ROUTES.REFFERAL_BONUS_CONFIGURATION,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.COMPENSATION).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.COMPENSATION)[0]
    //         .isView == true
    //         ? {
    //             id: "51",
    //             title: "Compensation",
    //             url: ROUTES.COMPENSATION_MASTER,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.EMPLOYEE_CREDITS)
    //         .length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.EMPLOYEE_CREDITS)[0]
    //         .isView == true
    //         ? {
    //             id: "52",
    //             title: "Employee Credit",
    //             url: ROUTES.EMPLOYEE_CREDIT,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.REFUND).length > 0 &&
    //       this.userRights(this.UserRightsConfiguration.REFUND)[0].isView == true
    //         ? {
    //             id: "58",
    //             title: "Refund",
    //             url: ROUTES.LKREFUND,
    //           }
    //         : null,

    //       this.userRights(this.UserRightsConfiguration.ADVANCE_PAYMENT).length >
    //         0 &&
    //       this.userRights(this.UserRightsConfiguration.ADVANCE_PAYMENT)[0]
    //         .isView == true
    //         ? {
    //             id: "59",
    //             title: "Advance Payment",
    //             url: ROUTES.ADVANCE_PAYMENT,
    //           }
    //         : null,
    //     ],
    //   },
    // ];

    /**
     * A recursive function to generate the menu items dynamically.
     *
     * @param {array} menuObj : array of menu objects
     */
    let menuIndex = 0;
    const menuItemGenerator = (menuObj) => {
      return menuObj.map((singleMenu, index) => {
        menuIndex = menuIndex + 1;
        if (singleMenu != null && singleMenu.submenu) {
          return (
            <SubMenu
              key={menuIndex}
              icon={
                singleMenu.icon ? (
                  <img
                    className={styles.SidebarIcon}
                    src={singleMenu.icon}
                    alt="menu-icon"
                  />
                ) : null
              }
              title={
                singleMenu.url ? (
                  <NavLink to={singleMenu.url}>{singleMenu.title}</NavLink>
                ) : (
                  singleMenu.title
                )
              }
            >
              {menuItemGenerator(singleMenu.submenu)}
            </SubMenu>
          );
        } else {
          return singleMenu != null ? (
            <Menu.Item
              key={menuIndex}
              icon={
                singleMenu.icon ? (
                  <img
                    className={styles.SidebarIcon}
                    src={singleMenu.icon}
                    alt="menu-icon"
                  />
                ) : null
              }
            >
              <NavLink to={singleMenu.url}>{singleMenu.title}</NavLink>
            </Menu.Item>
          ) : null;
        }
      });
    };

    return (
      <React.Fragment>
        {/**
         * Button to toggle the sidebar
         */}
        <Button
          className={styles.MenuToggler}
          onClick={this.props.toggleFunction}
        >
          <img
            src={ToggleIcon}
            alt=">"
            className={
              this.props.collapsedState === false
                ? styles.TogglerIconOpen
                : null
            }
          />
        </Button>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Menu
            multiple
            mode="vertical"
            theme="dark"
            inlineIndent={34}
            selectable={false}
            className={styles.SidebarMenu}
          >
            {menuItemGenerator(menuItems)}
            {this.userRights(
              this.UserRightsConfiguration.STORE_GATE_PASS_REPORT
            ) ||
            this.userRights(
              this.UserRightsConfiguration.GARMENT_SUMMARY_REPORT
            ) ||
            this.userRights(this.UserRightsConfiguration.QC_REPORT) ||
            this.userRights(
              this.UserRightsConfiguration.FACTORY_IN_SUMMARY_REPORT
            ) ||
            this.userRights(
              this.UserRightsConfiguration.FACTORY_GATE_PASS_REPORT
            ) ||
            this.userRights(
              this.UserRightsConfiguration.STORE_GATE_PASS_SUMMARY_REPORT
            ) ||
            this.userRights(this.UserRightsConfiguration.CUSTOMER_REPORT) ||
            this.userRights(this.UserRightsConfiguration.RAF_REPORT) ||
            this.userRights(this.UserRightsConfiguration.REVENUE_REPORT) ||
            this.userRights(this.UserRightsConfiguration.ORDER_REPORT) ||
            this.userRights(
              this.UserRightsConfiguration.WALLET_TRANSACTION_REPORT
            ) ||
            this.userRights(
              this.UserRightsConfiguration.PACKAGE_RECHARGE_REPORT
            ) ||
            this.userRights(
              this.UserRightsConfiguration.STORE_TRANSFER_IN_REPORT
            ) ||
            this.userRights(
              this.UserRightsConfiguration.STORE_TRANSFER_OUT_REPORT
            ) ||
            this.userRights(this.UserRightsConfiguration.TRANSACTION_REPORT) ||
            this.userRights(this.UserRightsConfiguration.ODM_REPORT) ||
            this.userRights(this.UserRightsConfiguration.PROMOCODE_REPORT) ||
            this.userRights(
              this.UserRightsConfiguration.CUSTOMER_VALUE_SUMMARY_REPORT
            ) ||
            this.userRights(this.UserRightsConfiguration.CASH_COUPON_REPORT) ? (
              <div
                key={250}
                style={{
                  //marginTop: 6,
                  textAlign: "center",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  height: "3rem",
                }}
              >
                <img
                  className={styles.SidebarIcon}
                  src="/static/media/billing.a0849ff0.svg"
                  alt="menu-icon"
                  style={{ marginLeft: -44 }}
                />

                <a
                  href={this.config.REPORTS_URL}
                  target="_blank"
                  style={{
                    display: "block",
                    marginLeft: 0,
                    color: "#ffffff",
                    opacity: 0.6,
                  }}
                >
                  Reports
                </a>
              </div>
            ) : null}
            <div
              key={223}
              style={{
                marginTop: 50,
                textAlign: "center",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                height: "3rem",
              }}
            >
              <a
                href={this.config.FAQ_URL}
                target="_blank"
                style={{
                  display: "block",
                  marginRight: 5,
                  color: "#ffffff",
                  opacity: 0.6,
                }}
              >
                FAQs
              </a>
              |
              <a
                href={this.config.ELEARNING_URL}
                target="_blank"
                style={{
                  display: "block",
                  marginLeft: 5,
                  color: "#ffffff",
                  opacity: 0.6,
                }}
              >
                e-Learning
              </a>
            </div>
            <div
              key={223}
              style={{
                // marginTop: 50,
                textAlign: "center",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                height: "2rem",
              }}
            >
              <a
                href={this.config.RETURN_REFUND_URL}
                target="_blank"
                style={{
                  display: "block",
                  marginRight: 5,
                  color: "#ffffff",
                  opacity: 0.6,
                }}
              >
                Returns & Refunds
              </a>
            </div>
            <div
              key={223}
              style={{
                // marginTop: 50,
                textAlign: "center",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                height: "2rem",
              }}
            >
              <a
                href={this.config.TERMS_CONDITIONS_URL}
                target="_blank"
                style={{
                  display: "block",
                  marginRight: 5,
                  color: "#ffffff",
                  opacity: 0.6,
                }}
              >
                Terms & Conditions
              </a>
            </div>
          </Menu>
        </div>
      </React.Fragment>
    );
  }
}
