import { PageHeader, Table, Checkbox, Select, notification, Spin } from "antd";
import Button from "./components/Common/Button/button";
import React from "react";
import TableActions from "./components/Common/TableActions/tableactions";
import RoleService from "./services/roleservice";
const { Option } = Select;
class Roles extends React.Component {
  roleService = new RoleService();
  state = {
    table: {
      loading: false,
      data: [],
    },
    roles: {
      disabled: true,
      data: [],
    },
    change: {},
  };
  updateChange = (screen, key, value) => {
    this.setState({
      change: {
        ...this.state.change,
        [screen]: {
          ...this.state.change[screen],
          [key]: value,
        },
      },
    });
  };
  expandedRowRender = (record1) => {
    const columns = [
      { title: "Screen", dataIndex: "screenName", key: "screenName" },
      {
        title: "View",
        dataIndex: "isView",
        key: "isView",
        render: (text, record, index) => (
          <Checkbox
            defaultChecked={record.isView}
            onChange={(event) => {
              this.updateChange(record.screenID, "view", event.target.checked);
            }}
          ></Checkbox>
        ),
      },
      {
        title: "Create",
        dataIndex: "create",
        key: "create",
        render: (text, record, index) => (
          <Checkbox
            defaultChecked={record.isCreate}
            onChange={(event) => {
              this.updateChange(
                record.screenID,
                "create",
                event.target.checked
              );
            }}
          ></Checkbox>
        ),
      },
      {
        title: "Edit",
        dataIndex: "edit",
        key: "edit",
        render: (text, record, index) => (
          <Checkbox
            defaultChecked={record.isEdit}
            onChange={(event) => {
              this.updateChange(record.screenID, "edit", event.target.checked);
            }}
          ></Checkbox>
        ),
      },
      {
        title: "Delete",
        dataIndex: "delete",
        key: "delete",
        render: (text, record, index) => (
          <Checkbox
            defaultChecked={record.isDelete}
            onChange={(event) => {
              this.updateChange(
                record.screenID,
                "delete",
                event.target.checked
              );
            }}
          ></Checkbox>
        ),
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={record1.rights}
        pagination={false}
        size="small"
      />
    );
  };
  columns = [{ title: "Screen", dataIndex: "parentName", key: "parentName" }];
  componentDidMount() {
    this.getRoleList();
  }
  getRoleList() {
    this.roleService.getRolesList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          roles: {
            ...this.state.roles,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Unable to get data for roles",
        });
      }
    });
  }
  getTableData(id) {
    this.setState({ table: { ...this.state.table, loading: true } });
    this.roleService.getRolesRights(id).then((response) => {
      if (response.statusCode === 200) {
        var flags = [],
          output = [],
          l = response.data.length,
          i;
        for (i = 0; i < l; i++) {
          if (flags[response.data[i].parentID]) continue;
          flags[response.data[i].parentID] = true;
          let matchParent = response.data[i].parentID;
          const filteredData = response.data.filter((value) => {
            return matchParent === value.parentID;
          });
          let prepare = {
            key: response.data[i].parentID,
            parentID: response.data[i].parentID,
            parentName: response.data[i].parentName,
            rights: filteredData,
          };
          output.push(prepare);
        }
        this.setState({
          table: {
            ...this.state.table,
            data: output,
            loading: false,
          },
        });
      } else {
        notification["error"]({
          message: "Unable to get data for screen rights",
        });
      }
    });
  }
  render() {
    return (
      <React.Fragment>
        <PageHeader
          title="Role Screen Mapping"
          extra={[
            <Select
              placeholder="Select Role"
              key="1"
              disabled={this.state.roles.disabled}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => {
                this.getTableData(parseInt(value));
              }}
            >
              {this.state.roles.data.length !== 0
                ? this.state.roles.data.map((role) => {
                    return role.value !== "-1" ? (
                      <Option value={role.value} key={role.value}>
                        {role.text}
                      </Option>
                    ) : null;
                  })
                : null}
            </Select>,
          ]}
        />
        <Spin spinning={this.state.table.loading}>
          <Table
            className="components-table-demo-nested"
            columns={this.columns}
            expandable={{ expandedRowRender: this.expandedRowRender }}
            dataSource={this.state.table.data}
            pagination={false}
            size="small"
          />
        </Spin>
        <pre>{JSON.stringify(this.state.change, null, 2)}</pre>
        <TableActions style={{ textAlign: "right" }}>
          <Button htmlType="submit" shape="rounded" size="large">
            Submit
          </Button>
        </TableActions>
      </React.Fragment>
    );
  }
}

export default Roles;
