import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";

import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  // Popconfirm,
  Table,
  notification,
  Tooltip,
  // Popover,
  Popconfirm,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faPencilAlt,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../routes";
import CorporateService from "../../../services/corporateservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";

const { Search } = Input;
const { Option } = Select;

/**
 * A factory master screen which holds grid view for master screens and form with steps
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
class Master extends React.Component {
  corporateService = new CorporateService();
  UserRightsConfiguration = new UserRightsConfiguration();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    showfilterBox: false,
    factories: [],
    tableLoading: true,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    pagination: {
      showSizeChanger: true,
      pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 2,
      onChange: (page, pageSize) => {
        this.setState({
          tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.CORPORATE
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }
  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  // {
  //   title: "ID",
  //   dataIndex: "corporateID",
  //   key: "corporateID",
  //   width: "3%",
  //   sorter: true,
  // },
  columns = [
    {
      title: "Name",
      dataIndex: "corporateName",
      key: "corporateName",
      sorter: true,
    },
    {
      title: "Email Address",
      dataIndex: "emailID",
      key: "emailID",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (deliveryTypeName) => {
        return (
          <Tooltip placement="topLeft" title={deliveryTypeName}>
            {deliveryTypeName}
          </Tooltip>
        );
      },
    },
    {
      title: "Account Manager",
      dataIndex: "accManager",
      key: "accManager",
      sorter: true,
    },
    {
      title: "Pickup Frequency",
      dataIndex: "pickupFrequencyName",
      key: "pickupFrequencyName",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Link
                to={{
                  pathname: [ROUTES.VIEW_CORPORATE, record.corporateID].join(
                    "/"
                  ),
                  state: { corporateID: record.corporateID },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Link
                to={{
                  pathname: [ROUTES.EDIT_CORPORATE, record.corporateID].join(
                    "/"
                  ),
                  state: { corporateID: record.corporateID },
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.deleteCorporate(record.corporateID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <FontAwesomeIcon icon={faTrash} />
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    this.getCorporateList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  }

  deleteCorporate = (id) => {
    this.corporateService.deleteCorporate(id).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: "Corporate Master",
          description: response.message
            ? response.message
            : "Data deleted successfully",
        });
        this.getCorporateList(
          this.state.pagination.current,
          this.state.pageSize
        );
      } else {
        notification["error"]({
          message: "Corporate Master",
          description: response.message ? response.message : "Unable to delete",
        });
      }
    });
  };
  getCorporateList = (
    index,
    size,
    column = this.state.sorter.field,
    order = this.state.sorter.order,
    search = this.state.search
  ) => {
    this.corporateService
      .getAllCorporates(index, size, column, order, search)
      .then((response) => {
        if (response.statusCode === 200) {
          let factoryData = [];
          response.data.map((singleData) => {
            singleData.key = singleData.corporateID;
            factoryData.push(singleData);
            return null;
          });
          this.setState({ factories: factoryData });
          this.setState({
            pagination: {
              ...this.state.pagination,
              total: factoryData.length ? factoryData[0].totalRecords : 0,
            },
          });
        } else {
          notification["error"]({
            message: "Corporate Master",
            description: response.message
              ? response.message
              : "Unable to get list of corporates",
          });
        }
        this.setState({ tableLoading: false });
      });
  };
  render() {
    return (
      <div>
        <PageHeader
          title="Corporate Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              key="1"
              onChange={(event) => {
                this.setState(
                  {
                    search: event.target.value.toString().toLowerCase(),
                  },
                  () => {
                    this.getCorporateList(
                      this.state.pagination.current,
                      this.state.pagination.pageSize,
                      this.state.sorter.field,
                      this.state.sorter.order,
                      this.state.search
                    );
                  }
                );
              }}
            />,
            /**
             * Add Factory Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.props.history.push({
                    pathname: ROUTES.ADD_CORPORATE,
                    state: { corporateID: this.state.corporateID },
                  })
                }
                key="2"
              >
                Add Corporate
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState({
              sorter: {
                ...sorter,
                order:
                  sorter.order === "ascend"
                    ? "asc"
                    : sorter.order === "descend"
                    ? "desc"
                    : sorter.order,
              },
            });
            this.getCorporateList(
              pagination.current,
              pagination.pageSize,
              sorter.field,
              sorter.order === "ascend"
                ? "asc"
                : sorter.order === "descend"
                ? "desc"
                : sorter.order,
              this.state.search
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(Master);
