import React, { Component } from 'react'
import RewashFactoryComponent from '../../../components/FactoryModule/DailySheet/rewash.factory.component'

export default class RewashFactoryScreen extends Component {
  render() {
    return (
      <div>
        <RewashFactoryComponent></RewashFactoryComponent>
      </div>
    )
  }
}
