import html2pdf from "html2pdf.js";
import moment from "moment";
import CreateOrderService from "../../../../services/createorderservice";
import React from "react";
import { renderToString } from "react-dom/server";
import { notification } from "antd";
import { labels } from "../../../../shared/language";
import { faWindowRestore } from "@fortawesome/free-solid-svg-icons";

const getPDFEstimatedOrder = (orderID) => {
  let orderService = new CreateOrderService();
  orderService.getEstimatedOrderPrintByOrderID(orderID).then((response) => {
    if (response.statusCode === 200) {
      if (response.data.length > 0) {
        window.open(response.data[0].location, "_blank");
        window.location.reload();
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GENERATE_ESTIMATED_ORDER,
        });
      }
    } else {
      notification["error"]({
        message: labels.ORDERS,
        description: response.message
          ? this.common.Message(response.message)
          : labels.UNABLE_TO_GENERATE_ESTIMATED_ORDER,
      });
    }
    window.location.reload();
  });
};

export { getPDFEstimatedOrder };
