import React, { Component } from 'react';
import Dailypickupsummarycomponent from '../../../components/StoreModule/Audit/dailypickupsummary.component';

export default class dailypickupsummaryscreen extends Component {
  render() {
    return (
      <div><Dailypickupsummarycomponent></Dailypickupsummarycomponent></div>
    )
  }
}
