import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  // Popconfirm,
  Table,
  // notification,
  Tooltip,
  // Popover,
  // Popconfirm,
  DatePicker,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faPencilAlt,
  faInfo,
  // faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../routes";
import FactoryPreQC from "../../../services/factorypre-qc";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";
import Moment from "moment";
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);
const { Search } = Input;
const { Option } = Select;

class Master extends React.Component {
  UserRightsConfiguration = new UserRightsConfiguration();
  factoryPreQC = new FactoryPreQC();
  common = new Common();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    orderFromDate: Moment(ONE_MONTHS_AGO),
    orderToDate: Moment(),
    showfilterBox: true,
    PreQCRequestList: [],
    qcStatus: 1,
    tableLoading: true,
    sorter: {
      field: null,
      order: null,
    },
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      total: 0,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.FACTORY_PRE_QC
    );

    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.TAG,
      dataIndex: "tagNo",
      key: "tagNo",
    },
    {
      title: labels.CUSTOMER,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: labels.CONTACT,
      dataIndex: "customerContactNumber",
      key: "customerContactNumber",
      sorter: true,
    },
    {
      title: labels.GARMENT,
      dataIndex: "garmentName",
      key: "garmentName",
      sorter: true,
    },
    {
      title: labels.SPLIT_ORDER_NUMBER,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },

    {
      title: labels.QC_STATUS,
      dataIndex: "finalQCStatusName",
      key: "finalQCStatusName",
      sorter: true,
    },
    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title={labels.VIEW}>
              <Link
                to={{
                  pathname: [ROUTES.VIEW_PRE_QC_FACTORY, record.tagNo].join(
                    "/"
                  ),
                  state: { tagNo: record.tagNo },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {/* {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="View">
              <Link to={[ROUTES.EDIT_PRE_QC_FACTORY, record.tagNo].join("/")}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </Tooltip>
          ) : null} */}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    // this.getCorporateList(
    //   this.state.pagination.current,
    //   this.state.pagination.pageSize
    // );
    this.GetPreQCRequestList();
  }
  handleSearch = (event) => {
    this.setState({ search: event.target.value }, () =>
      this.GetPreQCRequestList()
    );
  };

  GetPreQCRequestList = () => {
    this.setState({ tableLoading: true });
    let param = {
      factoryID: parseInt(localStorage.getItem("branchID")),
      FromOrderDate: Moment(this.state.orderFromDate).format("YYYY-MM-DD"),
      ToOrderDate: Moment(this.state.orderToDate).format("YYYY-MM-DD"),
      qcStatus: this.state.qcStatus,
      tagNo: null,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      sortColumn: this.state.sorter.field,
      sortOrder: this.state.sorter.order,
      commonSearch: this.state.search === undefined ? null : this.state.search,
    };

    this.factoryPreQC.GetPreQCRequestList(param).then((res) => {
      if (res.statusCode === 200) {
        this.setState({
          PreQCRequestList: res.data,
          pagination: {
            ...this.state.pagination,
            total: res.data.length > 0 ? res.data[0].totalRecords : 0,
          },
          tableLoading: false,
        });
      } else {
        notification["error"]({
          message: labels.PRE_QC,
          description: res.message
            ? this.common.Message(res.message)
            : labels.UNABLE_TO_LOAD_PRE_QC_RESPONSES,
        });
      }
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        sorter: {
          ...sorter,
          order:
            sorter.order === "ascend"
              ? "asc"
              : sorter.order === "descend"
              ? "desc"
              : sorter.order,
        },
      },
      () => this.GetPreQCRequestList()
    );
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };


  render() {
    return (
      <div>
        <PageHeader
          title={labels.FACTORY_PRE_QC}
          extra={[
            
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            /**
             * Add Factory Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.props.history.push({
                    pathname: ROUTES.ADD_PRE_QC_FACTORY,
                    state: { tagNo: "" },
                  })
                }
                key="2"
              >
                {labels.ADD_GARMENT_FOR_QC}
              </Button>
            ) : null,
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
              key="3"
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
        <Form
            onFinish={() => this.GetPreQCRequestList()}
            initialValues={{
              orderFromDate: this.state.orderFromDate,
              orderToDate: this.state.orderToDate,
              qcStatus:this.state.qcStatus
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderFromDate")
                    }
                    value={this.state.orderFromDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderToDate")
                    }
                    value={this.state.orderToDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"qcStatus"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <Select
              key="99"
              showSearch
              placeholder={labels.SELECT_QC_STATUS}
              defaultValue={this.state.qcStatus}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) =>
                this.setState(
                  {
                    qcStatus: value,
                    pagination: { ...this.state.pagination, current: 1 },
                  },
                  // () => this.GetPreQCRequestList()
                )
              }
            >
              <Option value={1}>Pending</Option>
              <Option value={2}>Approved</Option>
              <Option value={3}>Rejected</Option>
            </Select>,
                </Form.Item>
                </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.PreQCRequestList}
          loading={this.state.tableLoading}
          // pagination={true}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}

export default withRouter(Master);
