import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Tooltip,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import CityService from "../../../services/cityservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import {
  RegexLettersDigitsUnderScoreHyphen,
  MessageLettersDigitsUnderScoreHyphen,
} from "../../../shared/validator";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";

// let cityname = "";

const { Search } = Input;
const { Option } = Select;

/** const variable for pagination
 * const getRandomuserParams = params => {
 *  return {
 *       cityID: params.cityID,
 *       pageIndex: params.pagination.current,
 *       pageSize: params.pagination.pageSize,
 *       ...params,
 *  };
};*/

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

/**
 * A city master screen which holds grid view for master screens and form inside a modal
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 4 June 2020
 */
export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.CityService = new CityService();
    this.common = new Common();
    this.state = {
      modalLoading: false,
      cityItems: [],
      cityID: 0,
      cityName: "",
      stateName: [],
      stateListOptions: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },

      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      showfilterBox: false,
      showAddPopup: false,
      loading: true,
      userRights: [],
      isResponseErrorShow: false,
      // isRenderComponent: false,
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }

    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.CITY_MASTER
    );

    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }

    // if (!res[0].isView) {
    // window.location.href = "/";
    // notification["error"]({
    //   message: "City Master",
    //   description: "User don't have the access of this screen",
    // });
    // window.location.href = "/store/dashboard";
    // this.props.history.goBack();
    // }
    this.setState({ userRights: res });
  }

  // shouldComponentUpdate() {
  //   return this.state.isRenderComponent ?? false;
  // }

  columns = [
    //{
    //     title: 'CityID',
    //     dataIndex: 'cityID',
    //     key: 'cityID',
    // },
    {
      title: "City Name",
      dataIndex: "cityName",
      key: "cityName",
      sorter: true,
    },
    {
      title: "State Name",
      dataIndex: "stateName",
      key: "stateName",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    cityID: record.cityID,
                    cityName: record.cityName,
                    stateName: record.stateName,
                    stateID: record.stateID,
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    cityID: record.cityID,
                    cityName: record.cityName,
                    stateName: record.stateName,
                    stateID: record.stateID,
                    // }, () => cityname = record.cityName)}
                  })
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.cityID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      cityID: this.state.cityID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
    this.DropDownStateList();
  }

  handleSearch = (event) => {
    this.fetchItems({
      cityID: this.state.cityID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table pagination when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      cityID: this.state.cityID,

      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.CityService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            cityID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          cityItems: items.data,
          pagination: {
            ...params.pagination,
            total: items.data.length > 0 ? items.data[0].totalRecords : 0,
          },
          // isRenderComponent: true,
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "City Master",
            description: items.message
              ? items.message
              : "Unable to get list of city data",
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (cityID) => {
    if (cityID === "" || cityID === null || cityID === undefined) {
      // openNotificationWithIcon("error", "City Master", "");
      notification["error"]({
        message: "City Master",
        description: "Please Select Atleast One Row",
      });
    } else {
      this.CityService.removeItems(cityID).then((items) => {
        if (items.result === "Success") {
          // openNotificationWithIcon("success", "City Master", items.message);
          notification["success"]({
            message: "City Master",
            description: items.message,
          });
          const { pagination } = this.state;
          this.fetchItems({
            cityID: parseInt(this.state.cityID),
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
          });
        } else {
          // openNotificationWithIcon("error", "City Master", items.message);
          notification["error"]({
            message: "City Master",
            description: items.message,
          });
        }
      });
    }
  };

  //function for getting the  data from server call into dropdown list
  DropDownStateList = () => {
    this.CityService.StateListItems().then((items) => {
      if (items.statusCode === 200) {
        this.setState({
          stateListOptions: items.data,
          // isRenderComponent: false,
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "City Master",
            description: items.message
              ? items.message
              : "Unable to get list of state data",
          });
        }
      }
    });
  };

  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    var postData = {
      cityID: parseInt(this.state.cityID),
      stateID: parseInt(this.state.stateID),
      cityName: this.state.cityName,
      isActive: true,
    };
    this.setState({ modalLoading: true });
    this.CityService.insertUpdateItems(postData).then((items) => {
      if (items.result === "Success") {
        // openNotificationWithIcon("success", "City Master", items.message);
        notification["success"]({
          message: "City Master",
          description: items.message,
        });
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          cityID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        notification["error"]({
          message: "City Master",
          description: items.message,
        });
        this.setState({
          isResponseErrorShow: true,
        });
      }
      this.setState({ modalLoading: false });
    });
  };

  //function for changing/updating the city name
  handleCityName = (e) => {
    let cityNm = e.target.value;
    this.setState({ cityName: cityNm });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      cityID: 0,
      cityName: "",
      stateID: "",
      stateName: [],
      isEnabled: false,
      isVisible: false,
    });
  };

  //function for filtering out the values inside list
  RestoreDDlValue = (e) => {
    var cityArray = this.state.stateListOptions.filter((x) => x.value === e);
    // let c = cityArray[0].text;
    if (cityArray !== undefined) {
      this.setState({
        stateID: cityArray[0].value,
        stateName: cityArray[0].text,
      });
    }
  };

  render() {
    const { cityName, stateName, pagination, loading } = this.state;
    const cityItems = this.state.cityItems;
    const listItems = cityItems.map((item, index) => ({
      key: item.cityID,
      cityID: item.cityID,
      stateID: item.stateID,
      stateName: item.stateName,
      cityName: item.cityName,
      isActive: item.isActive.toString(),
      createdBy: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD/MM/YYYY hh:mm:ss a"),
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title="City Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                Add City
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            cityItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>

        <Modal
          title={
            this.state.cityID === 0
              ? "Add City"
              : this.state.isEnabled
              ? "View City"
              : "Update City"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{ cityName, stateName }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="City Name "
                    name="cityName"
                    rules={[
                      { required: true, message: "Please Enter City Name !" },
                      // {
                      //   pattern: RegexLettersDigitsUnderScoreHyphen,
                      //   message: MessageLettersDigitsUnderScoreHyphen,
                      // },
                    ]}
                  >
                    <Input
                      placeholder="Enter City Name"
                      disabled={this.state.isEnabled}
                      onChange={this.handleCityName}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="State"
                    name="stateName"
                    rules={[
                      { required: true, message: "Please Select Any State !" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select State"
                      onChange={this.RestoreDDlValue}
                      disabled={this.state.isEnabled}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {/* {this.state.stateListOptions.length !== 0? */}
                      {
                        this.state.stateListOptions.map((state) => {
                          return state.value !== "-1" ? (
                            <option key={state.value} value={state.value}>
                              {state.text}
                            </option>
                          ) : null;
                        })
                        // :null
                        // }
                      }
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.cityID === 0 ? "Add" : "Update"}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
