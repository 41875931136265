import React from "react";
import ServiceMaster from "../../../components/Master/Service/servicemaster.component"

export default class ServiceScreen extends React.Component {
  render() {
    return (
      <div >
        <ServiceMaster></ServiceMaster>
      </div>
    );
  }
}