import React from "react";
import styles from "./filters.module.css";
/**
 * File Contains the filter container for master screens
 * provides pink background with proper margin-padding and animation for toggle
 *
 * @props show: variable to determine to show or not
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 4 June 2020
 */
export default class Filters extends React.Component {
  render() {
    return (
      <div
        className={[
          styles.FilterWrapper,
          this.props.show
            ? styles.FilterWrapperOpen
            : styles.FilterWrapperClosed,
        ].join(" ")}
      >
        {this.props.show ? this.props.children : null}
      </div>
    );
  }
}
