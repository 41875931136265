import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class LKRefund {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async insertItems(postData) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.ADD_REFUND });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.ADD_REFUND]
      .getAllPost(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.insertItems(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getCustomerData(searchStr) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_SEARCH_CUSTOMER });
    let itemArray = [];
    let ID = "?SearchString=" + searchStr;
    await myApi.endpoints.GetSearchCustomer.getOne(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getCustomerData(searchStr);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveRefundItems(param) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.GET_REFUND_MASTER });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_REFUND_MASTER]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveRefundItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}
