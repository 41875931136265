import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import jwt from "jsonwebtoken";
import Common from "./common";

export default class DeliveryList {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getStoreUsers() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({
      name: this.endpoint.GET_USER_LIST_BY_STORE,
    });
    let itemArray = [];
    let ID = "?storeID=" + parseInt(localStorage.getItem("branchID"));
    await myApi.endpoints[this.endpoint.GET_USER_LIST_BY_STORE]
      .getOne(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getStoreUsers();
      });
    }
    return Promise.resolve(itemArray);
  }

  async getDeliveryStatusList() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({
      name: this.endpoint.GET_SYSTEME_NUMS,
    });
    let append = "?EnumNames=DeliveryStatus";
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_SYSTEME_NUMS]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getDeliveryStatusList();
      });
    }
    return itemArray;
  }

  async getAllDeliveryList(
    index,
    size,
    sortColumn,
    sortOrder,
    search,
    selectedBranch = null,
    selectedDeliveryStatusFilter = null,
    showEscalatedDelivery=null,
    deliveryFromDate,
    deliveryToDate,
    deliverySlot

  ) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.GET_DELIVERY_REQUESTS,
    });
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_DELIVERY_REQUESTS]
      .getAllPost({
        StoreID: [null, undefined, ""].includes(selectedBranch)
          ? localStorage.getItem("branchID")
          : selectedBranch,
        // storeID: parseInt(localStorage.getItem("branchID")),
        DeliveryStatusID: selectedDeliveryStatusFilter,
        IsEscalate:showEscalatedDelivery,
        DeliveryFromDate:deliveryFromDate,
        DeliveryToDate:deliveryToDate,
        DeliverySlot:deliverySlot,
        pageIndex: index,
        pageSize: size,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        commonSearch: search,
        sourceFrom: 1,
      })
      .then(({ data }) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getAllDeliveryList(
          index,
          size,
          sortColumn,
          sortOrder,
          search
        );
      });
    }
    return Promise.resolve(itemArray);
  }

  async reassignDeliveryRequest(params) {
    const reassign = new API({
      url: this.config.ITEM_STORE_URL,
    });
    reassign.createEntity({
      name: this.endpoint.REASSIGN_DELIVERY_REQUEST,
    });
    let reassignDelivery = [];
    await reassign.endpoints[this.endpoint.REASSIGN_DELIVERY_REQUEST]
      .create(params)
      .then((response) => {
        reassignDelivery = response.data;
      })
      .catch((err) => {
        reassignDelivery = this.common.handleError(err);
      });
    if (reassignDelivery.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        reassignDelivery = this.reassignDeliveryRequest(params);
      });
    }
    return Promise.resolve(reassignDelivery);
  }

  async rescheduleDeliveryRequest(params) {
    const reschedule = new API({
      url: this.config.ITEM_STORE_URL,
    });
    reschedule.createEntity({
      name: this.endpoint.RESCHEDULE_DELIVERY_REQUEST,
    });
    let rescheduleDelivery = [];
    await reschedule.endpoints[this.endpoint.RESCHEDULE_DELIVERY_REQUEST]
      .create(params)
      .then((response) => {
        rescheduleDelivery = response.data;
      })
      .catch((err) => {
        rescheduleDelivery = this.common.handleError(err);
      });
    if (rescheduleDelivery.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        rescheduleDelivery = this.rescheduleDeliveryRequest(params);
      });
    }
    return Promise.resolve(rescheduleDelivery);
  }

  async cancelDeliveryRequest(params) {
    const cancel = new API({
      url: this.config.ITEM_STORE_URL,
    });
    cancel.createEntity({
      name: this.endpoint.CANCEL_DELIVERY_REQUEST,
    });
    let cancelDelivery = [];
    await cancel.endpoints[this.endpoint.CANCEL_DELIVERY_REQUEST]
      .create(params)
      .then((response) => {
        cancelDelivery = response.data;
      })
      .catch((err) => {
        cancelDelivery = this.common.handleError(err);
      });
    if (cancelDelivery.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        cancelDelivery = this.cancelDeliveryRequest(params);
      });
    }
    return Promise.resolve(cancelDelivery);
  }

  async getTimeSlotList(storeid) {
    const pickupStatusListAPI = new API({
      url: this.config.DROPDOWN_URL,
    });
    pickupStatusListAPI.createEntity({
      name: this.endpoint.GET_STORE_TIMESLOT_LIST,
    });
    let timeslot = [];
    const idParam = "?StoreID=" + storeid;
    await pickupStatusListAPI.endpoints[this.endpoint.GET_STORE_TIMESLOT_LIST]
      .getOne(idParam)
      .then((response) => {
        timeslot = response.data;
      })
      .catch((err) => {
        timeslot = this.common.handleError(err);
      });
    if (timeslot.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        timeslot = this.getTimeSlotList(storeid);
      });
    }
    return Promise.resolve(timeslot);
  }

  async escalateDelivery(params) {
    const cancel = new API({
      url: this.config.ITEM_STORE_URL,
    });
    cancel.createEntity({
      name: this.endpoint.ESCALATEDELIVERY,
    });
    let cancelDelivery = [];
    await cancel.endpoints[this.endpoint.ESCALATEDELIVERY]
      .create(params)
      .then((response) => {
        cancelDelivery = response.data;
      })
      .catch((err) => {
        cancelDelivery = this.common.handleError(err);
      });
    if (cancelDelivery.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        cancelDelivery = this.escalateDelivery(params);
      });
    }
    return Promise.resolve(cancelDelivery);
  }

  async getTodayDeliverylist(param){
    const userAPI = new API({ url: this.config.ITEM_STORE_URL });
    userAPI.createEntity({
      name: this.endpoint.GET_TODAY_DELIVERY_LIST,
    });
    let users = [];
    await userAPI.endpoints[this.endpoint.GET_TODAY_DELIVERY_LIST]
      .getAllPost(param)
      .then((response) => {
        users = response.data;
      })
      .catch((err) => {
        users = this.common.handleError(err);
      });
    if (users.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        users = this.getTodayDeliverylist(param);
      });
    }
    return Promise.resolve(users);
  }
}
