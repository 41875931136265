import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";
import moment from "moment";

class FactoryInventory {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getFactoryGarmentInventoryById(
    index = 0,
    size = 0,
    sortColumn = null,
    sortOrder = null,
    search = null,
    factoryID = null,
    IsPackagingDue = null,
    ServiceID = 0,
    DeliveryFromDate = null,
    DeliveryToDate = null
  ) {
    const inventoryApi = new API({ url: this.config.ITEM_FACTORY_URL });
    inventoryApi.createEntity({
      name: this.endpoint.FACTORY_GARMENT_INVENTORY,
    });
    let inventory = [];
    let payload = {
      factoryID: factoryID,
      pageIndex: index,
      pageSize: size,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      commonSearch: search,
      IsPackagingDue: IsPackagingDue,
      ServiceID: parseInt(ServiceID),
      DeliveryFromDate:
        DeliveryFromDate != null
          ? moment(DeliveryFromDate).format("YYYY-MM-DD")
          : null,
      DeliveryToDate:
        DeliveryToDate != null
          ? moment(DeliveryToDate).format("YYYY-MM-DD")
          : null,
    };

    await inventoryApi.endpoints[this.endpoint.FACTORY_GARMENT_INVENTORY]
      .getAllPost(payload)
      .then((response) => {
        inventory = response.data;
      })
      .catch((err) => {
        inventory = this.common.handleError(err);
      });
    if (inventory.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        inventory = this.getFactoryGarmentInventoryById(
          index,
          size,
          sortColumn,
          sortOrder,
          search,
          factoryID
        );
      });
    }
    return Promise.resolve(inventory);
  }

  async UpdateDeliveryDate(postData) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.UPDATE_DELIVERY_DATE });
    let itemArray = [];
    await myApi.endpoints.UpdateDeliveryDate.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.UpdateDeliveryDate(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getFactoryInventorySummary(params) {
    const inventoryApi = new API({ url: this.config.ITEM_FACTORY_URL });
    inventoryApi.createEntity({
      name: this.endpoint.GET_INVENTORY_SUMMARY,
    });
    let inventory = [];

    await inventoryApi.endpoints[this.endpoint.GET_INVENTORY_SUMMARY]
      .getAllPost(params)
      .then((response) => {
        inventory = response.data;
      })
      .catch((err) => {
        inventory = this.common.handleError(err);
      });
    if (inventory.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        inventory = this.getFactoryGarmentInventoryById(params);
      });
    }
    return Promise.resolve(inventory);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default FactoryInventory;
