import React from "react";
import styles from "./statscomponent.module.css";
import { Row, Col, notification, Typography, Spin } from "antd";
// import TruckIcon from "../../../images/Dashboard/truck.svg";
// import TshirtIcon from "../../../images/Dashboard/tshirt.svg";
import BagIcon from "../../../images/Dashboard/bag.svg";
import WalletIcon from "../../../images/Dashboard/wallet.svg";
import StoreDashboard from "../../../services/storedashboardservice";
import { labels } from "../../../shared/language";
import { IsAuthenticated } from "../../../services/auth-header";
import Common from "../../../services/common";

/**
 * This file holds the view of statastics cards placed on the top row of dashboard
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
export default class Stats extends React.Component {
  storeDashboard = new StoreDashboard();
  common = new Common();

  state = {
    fullLoading: false,
    dashboardData: [],
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    this.GetStoreDashboard();
  }

  GetStoreDashboard() {
    // this.setState({ fullLoading: true });
    this.props.toggleLoading(true);
    this.storeDashboard
      .GetStoreDashBoard(parseInt(localStorage.getItem("branchID")))
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            dashboardData: response.data,
          });
        } else {
          notification["error"]({
            message: labels.DASHBOARD,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_DATA_FOR_DASHBOARD,
          });
        }
        this.props.toggleLoading(false);
      });
  }

  StoreInventory = (data) => {
    return (
      <React.Fragment>
        <table className="w-100">
          <thead>
            <tr>
              <td></td>
              <td>{labels.PROCESSED}</td>
              <td>{labels.SOILED}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{labels.GARMENT}</td>
              <td>{data.processedGarments}</td>
              <td>{data.soiledGarments}</td>
            </tr>
            <tr>
              <td>{labels.ORDERS}</td>
              <td>{data.processedOrders}</td>
              <td>{data.soiledOrders}</td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  };
  otherStatastics = (data) => {
    return (
      <React.Fragment>
        <Row gutter={30} style={{ paddingBottom: 3 }}>
          <Col xs={12} style={{ borderRight: "1px solid #ffffff" }}>
            {labels.AMOUNT_DUE_FOR_COLLECTION}
            <br />₹ {data.amountDueForCollection}
          </Col>
          <Col xs={12}>
            {labels.ORDERS_READY_FOR_INVOICE} <br />
            {data.readyForInvoiceOrders}
          </Col>
        </Row>
      </React.Fragment>
    );
  };
  render() {
    // console.log(JSON.stringify(this.state.dashboardData, null, 2))
    const StatsBox = (props) => {
      /**
       * Array of background colors
       */
      const colors = ["#FE1D7F", "#FCC350", "#6ABEFF", "#7E13D5"];
      StatsBox.count++;
      return (
        <div
          className={styles.StatsWrapper}
          style={{
            backgroundColor: props.color
              ? props.color
              : colors[StatsBox.count % colors.length],
            minHeight: 108,
          }}
        >
          <div className="w-100">
            <div className={[styles.StatsTitle, "w-100"].join(" ")}>
              {props.title}
            </div>
            <div className={styles.StatsCount}>{props.count}</div>
          </div>
          {props.icon !== undefined ? (
            <div className={styles.StatsIcon}>
              <img src={props.icon} alt="icon" />
            </div>
          ) : null}
        </div>
      );
    };
    /**
     * Static property
     */
    StatsBox.count = -1;
    return (
      <Spin spinning={this.state.fullLoading}>
        <Row
          gutter={21}
          className={
            ["TAMIL", "TELUGU", "KANNADA"].includes(
              localStorage.getItem("locale")
            )
              ? "lan2"
              : ""
          }
        >
          <Col xs={12}>
            <Row gutter={21}>
              <Col xs={24}>
                <Typography.Title level={4}>
                  {labels.TODAYS_HIGHLIGHTS}
                </Typography.Title>
              </Col>
              {/* <pre>{JSON.stringify(this.state.dashboardData, null, 2)}</pre> */}
              <Col md={12}>
                <StatsBox
                  title={labels.ORDER_VS_ORDER_AMOUNT}
                  count={
                    <React.Fragment>
                      <span>{this.state.dashboardData.todaysOrderCount}</span>
                      <small>
                        /₹ {this.state.dashboardData.todaysOrderAmount}
                      </small>
                    </React.Fragment>
                  }
                  icon={BagIcon}
                />
              </Col>
              <Col md={12}>
                <StatsBox
                  title={labels.INVOICE_VS_INVOICE_AMOUNT}
                  count={
                    <React.Fragment>
                      <span>{this.state.dashboardData.todaysInvoiceCount}</span>
                      <small>
                        /₹ {this.state.dashboardData.todaysInvoiceAmount}
                      </small>
                    </React.Fragment>
                  }
                  icon={WalletIcon}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row gutter={21}>
              <Col xs={24}>
                <Typography.Title level={4}>
                  {labels.STORE_INVENTORY}
                </Typography.Title>
              </Col>
              <Col xs={24}>
                <StatsBox
                  title={this.StoreInventory(this.state.dashboardData)}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row gutter={21}>
              <Col xs={24}>
                <Typography.Title level={4}>
                  {labels.OTHER_STATASTICS}
                </Typography.Title>
              </Col>
              <Col xs={24}>
                <StatsBox
                  title={this.otherStatastics(this.state.dashboardData)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
}
