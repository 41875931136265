import React from "react";
import { Row, Col, Form, Select, Input, Space, notification } from "antd";
import Button from "../../../Common/Button/button";
import FactoryService from "../../../../services/factoryservice";

const { Option } = Select;

/**
 * Contact Form as a part of Factory Creation
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 */
export default class ContactForm extends React.Component {
  factoryService = new FactoryService();
  /**
   * State Varibales
   *
   * @var states : object related to state dropdown
   * @var cities : object related to cities dropdown
   * @var zones : object related to zones dropdown
   */
  state = {
    states: {
      selected: this.props.values.state,
      data: [],
      disabled: true,
    },
    cities: {
      selected: null,
      data: [],
      disabled: true,
    },
    zones: {
      selected: null,
      data: [],
      disabled: true,
    },
  };
  /**
   * API calls to fetch data and populate the dropdowns
   */
  componentDidMount() {
    this.getStateList();
    this.getZoneList();
    if (this.props.values.state) {
      this.getCityList(this.props.values.state);
    }
  }

  /**
   * Get the list of state to populate the dropdown
   * and enable the dropdown itself
   * @added 18 June 2020
   */
  getStateList = () => {
    this.factoryService.getStateList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          states: {
            ...this.state.states,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Factory Master",
          description: response.message
            ? response.message
            : "Unable to get list of states",
        });
      }
    });
  };

  getCityList = (cityID) => {
    this.factoryService
      .getCityListByState(cityID ? cityID : this.props.values.state)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            cities: {
              ...this.state.cities,
              data: response.data,
              disabled: false,
            },
          });
        } else {
          notification["error"]({
            message: "Factory Master",
            description: response.message
              ? response.message
              : "Unable to get list of states",
          });
        }
      });
  };
  /**
   * Get the list of zones and enable the zone dropdown
   * @added 18 June 2020
   */
  getZoneList = () => {
    this.factoryService.getZoneList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          zones: {
            ...this.state.zones,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Factory Master",
          description: response.message
            ? response.message
            : "Unable to get list of zones",
        });
      }
    });
  };
  render() {
    const disableAll = this.props.mode === "view";
    return (
      <Form
        key={this.props.values.state}
        name="contact"
        layout="vertical"
        initialValues={this.props.values}
        ref={this.props.instance}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={8}>
            {/**
             * For More Clarity over what <Input> Component can do
             * Please go through https://ant.design/components/input/
             * https://ant.design/components/input/#API
             */}
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: "Please Select State" }]}
            >
              <Select
                showSearch
                placeholder="Select State"
                /**
                 * Change the object in state and enable the dependant dropdown
                 */
                onChange={(value) => {
                  this.setState(
                    {
                      states: { ...this.state.states, selected: value },
                    },
                    () => this.getCityList(value)
                  );
                }}
                disabled={this.state.states.disabled || disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.states.data.length !== 0
                  ? this.state.states.data.map((state) => {
                      return state.value !== "-1" ? (
                        <Option value={state.value} key={state.value}>
                          {state.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            {/**
             * For More Clarity over what <Input> Component can do
             * Please go through https://ant.design/components/input/
             * https://ant.design/components/input/#API
             *
             * @todo Fetch Cities based on state selection
             */}
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: true, message: "Please Select City" }]}
              dependencies={["state"]}
              shouldUpdate={(prev, next) => prev.state !== next.state}
            >
              <Select
                showSearch
                placeholder="Select City"
                /**
                 * Change the object in state and enable the dependant dropdown
                 */
                onChange={(value) =>
                  this.setState({
                    cities: { ...this.state.cities, selected: value },
                    zones: { ...this.state.zones, disabled: false },
                  })
                }
                disabled={this.state.cities.disabled || disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.cities.data.length !== 0
                  ? this.state.cities.data.map((city) => {
                      return city.value !== "-1" ? (
                        <Option value={city.value} key={city.value}>
                          {city.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            {/**
             * For More Clarity over what <Input> Component can do
             * Please go through https://ant.design/components/input/
             * https://ant.design/components/input/#API
             *
             *  @todo Fetch Zones based on city selection
             */}
            <Form.Item
              label="Zone"
              name="zone"
              rules={[{ required: true, message: "Please Select Zone" }]}
            >
              <Select
                showSearch
                placeholder="Select Zone"
                /**
                 * Change the object in state
                 */
                onChange={(value) =>
                  this.setState({
                    zones: { ...this.state.zones, selected: value },
                  })
                }
                disabled={this.state.zones.disabled || disableAll}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.zones.data.length !== 0
                  ? this.state.zones.data.map((zone) => {
                      return zone.value !== "-1" ? (
                        <Option value={zone.value} key={zone.value}>
                          {zone.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={24}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Please Enter Address" }]}
            >
              <Input.TextArea
                placeholder="Enter Address"
                rows={2}
                disabled={disableAll}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                {
                  pattern: "[6789][0-9]{9}",
                  message: "Please Enter Valid Phone Number",
                },
              ]}
            >
              <Input placeholder="Enter Phone Number" disabled={disableAll} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Factory Executive"
              name="factoryExecutive"
              rules={[
                { required: true, message: "Please Select Factory Executive" },
              ]}
            >
              {/**
               * @todo Fetch Users from database
               */}
              <Input
                placeholder="Select Factory Executive"
                disabled={disableAll}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="Factory Manager" name="factoryManager">
              {/**
               * @todo Fetch Users from database
               */}
              <Input
                placeholder="Select Factory Manager"
                allowClear
                disabled={disableAll}
              />
            </Form.Item>
          </Col>

          <Col xs={24} style={{ textAlign: "right" }}>
            <Space>
              <div className="w-100"></div>
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                Back
              </Button>
              {!disableAll ? (
                <Button
                  // htmlType="submit"
                  variant="purple"
                  shape="round"
                  size="large"
                  onClick={this.props.nextFunction}
                >
                  Submit
                </Button>
              ) : null}
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
}
