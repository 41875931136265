import React from "react";
import { Button as AntButton } from "antd";
import styles from "./button.module.css";

/**
 * Customized Button
 *
 * Supports all properties from Ant Design and other props listed below
 * Ant Design Props : https:
 *
 * Additional Props
 * @props variant
 * 1. darkpurple : dark purple background with white text
 * 2. transparentGreyOutline: transparent button with grey outline
 * 3. purple : puple background with white text
 * 4. purpleOutline : button with purple outline
 * 5. whitePink : white background pink text (for filter apply)
 * 6. pink : pink button with white text
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 1 June 2020
 *
 */

export default class Button extends React.Component {
  static propTypes = {
    ...AntButton.propTypes,
  };
  render() {
    const { variant = "darkpurple", className, ...restProps } = this.props;
    switch (variant) {
      case "darkpurple":
        this.applyClass = styles.BtnDarkPurple;
        break;
      case "transparentGreyOutline":
        this.applyClass = styles.BtnTransparentGreyOutline;
        break;
      case "purple":
        this.applyClass = styles.BtnPurple;
        break;
      case "purpleOutline":
        this.applyClass = styles.BtnPurpleOutline;
        break;
      case "whitePink":
        this.applyClass = styles.BtnWhitePink;
        break;
      case "pink":
        this.applyClass = styles.BtnPink;
        break;
      default:
        this.applyClass = styles.BtnDarkPurple;
        break;
    }
    if (this.props.type === "link") {
      this.applyClass = styles.BtnLink;
    }
    const joinedClasses = [className, this.applyClass].join(" ");
    return <AntButton className={joinedClasses} {...restProps}></AntButton>;
  }
}
