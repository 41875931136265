import html2pdf from "html2pdf.js";
import moment from "moment";
import StoreInvoiceService from "../../../services/invoiceservice";
import React from "react";
import { renderToString } from "react-dom/server";

const getPDFProformaInvoice = (invoiceID, splitOrderNo) => {
  let invoiceService = new StoreInvoiceService();
  // let invoiceOrderData = [
  //   {
  //     orderID: 0,
  //     orderDate: "",
  //     deliveryDate: "",
  //     deliveryType: 0,
  //     priceType: "",
  //     deliveryTypeName: "",
  //     storeName: "",
  //     shortName: "",
  //     storeAddress: "",
  //     storeContactNo: "",
  //     storeEmail: "",
  //     website: "",
  //     storeGST: "",
  //     invoiceID: 0,
  //     invoiceDate: "",
  //     invoiceNumber: "",
  //     perfomaInvoiceDate: "",
  //     perfomaNumber: "",
  //     pickupDate: null,
  //     customerName: "",
  //     customerMobile: "",
  //     customerGST: "",
  //     customerAddress: "",
  //     discountAmount: 0,
  //     serviceAmount: 0,
  //     addOnServiceAmount: 0,
  //     deliveryChargesAmount: 0,
  //     packagingCharges: 0,
  //     cgst: 0,
  //     sgst: 0,
  //     igst: 0,
  //     adjustmentAmount: 0,
  //     adhocCharges: 0,
  //     cgstPer: 0,
  //     sgstPer: 0,
  //     igstPer: 0,
  //     finalInvoiceAmount: 0,
  //   },
  // ];
  // let invoiceGarmentData = [
  //   {
  //     garmentID: 0,
  //     garmentName: "",
  //     serviceID: 0,
  //     serviceName: "",
  //     servicePrefix: "",
  //     addOnServiceIDs: "",
  //     addOnServiceNames: "",
  //     addOnServicePrefix: "",
  //     hsnCode: "",
  //     unitPrice: 0,
  //     qty: 0,
  //     serviceAmount: 0,
  //     addonServiceAmount: 0,
  //     weight: 0,
  //   },
  // ];
  // let invoicePaymentModes = [
  //   {
  //     paymentDate: "",
  //     paymentMode: 0,
  //     paymentModeName: "",
  //     amount: 0,
  //   },
  // ];
  // let isInvoicePDFDataLoaded: [];

  // invoiceService.getPDFStoreInvoicePrint(splitOrderNo).then((response) => {
  //   invoiceOrderData = [
  //     {
  //       orderID: response.data.orderID,
  //       orderDate: response.data.orderDate,
  //       deliveryDate: response.data.deliveryDate,
  //       deliveryType: response.data.deliveryType,
  //       priceType: response.data.priceType,
  //       deliveryTypeName: response.data.deliveryTypeName,
  //       storeName: response.data.storeName,
  //       shortName: response.data.shortName,
  //       storeAddress: response.data.storeAddress,
  //       storeContactNo: response.data.storeContactNo,
  //       storeEmail: response.data.storeEmail,
  //       website: response.data.website,
  //       storeGST: response.data.storeGST,
  //       invoiceID: response.data.invoiceID,
  //       invoiceDate: response.data.invoiceDate,
  //       invoiceNumber: response.data.invoiceNumber,
  //       perfomaInvoiceDate: response.data.perfomaInvoiceDate,
  //       perfomaNumber: response.data.perfomaNumber,
  //       pickupDate: response.data.pickupDate,
  //       customerName: response.data.customerName,
  //       customerMobile: response.data.customerMobile,
  //       customerGST: response.data.customerGST,
  //       customerAddress: response.data.customerAddress,
  //       discountAmount: response.data.discountAmount,
  //       serviceAmount: response.data.serviceAmount,
  //       addOnServiceAmount: response.data.addOnServiceAmount,
  //       deliveryChargesAmount: response.data.deliveryChargesAmount,
  //       packagingCharges: response.data.packagingCharges,
  //       cgst: response.data.cgst,
  //       sgst: response.data.sgst,
  //       igst: response.data.igst,
  //       adjustmentAmount: response.data.adjustmentAmount,
  //       adhocCharges: response.data.adhocCharges,
  //       cgstPer: response.data.cgstPer,
  //       sgstPer: response.data.sgstPer,
  //       igstPer: response.data.igstPer,
  //       finalInvoiceAmount: response.data.finalInvoiceAmount,
  //     },
  //   ];
  //   invoiceGarmentData = response.data.vMGarmentDetails;
  //   invoicePaymentModes = response.data.vMPaymentModes;
  //   isInvoicePDFDataLoaded = true;
  //   let refElement = React.createRef();

  //   let data = <tr></tr>;
  //   if (isInvoicePDFDataLoaded) {
  //     data = invoiceGarmentData.map((item, index) => {
  //       return (
  //         <tr style={{ fontSize: 10 }} key={index}>
  //           <td colSpan={2}>{item.garmentName}</td>
  //           <td colSpan={2}>{item.servicePrefix}</td>
  //           <td colSpan={2}>{item.hsnCode}</td>
  //           <td colSpan={2}>{item.qty}</td>
  //           <td colSpan={2}>{item.unitPrice}</td>
  //           <td colSpan={2}>{item.qty * item.unitPrice}</td>
  //         </tr>
  //       );
  //     });
  //   }

  //   let paymentData = <tr></tr>;
  //   if (isInvoicePDFDataLoaded) {
  //     paymentData = invoicePaymentModes.map((item, index) => {
  //       return (
  //         <tr style={{ fontSize: 10 }} key={index}>
  //           <td colSpan={3}>
  //             {item.paymentModeName}:{item.amount}
  //           </td>
  //         </tr>
  //       );
  //     });
  //   }
  //   var tableData = (
  //     <div id="page" style={{ padding: "10px" }} ref={refElement}>
  //       <table
  //         align="center"
  //         style={{
  //           width: "100%",
  //         }}
  //       >
  //         <thead align="center" style={{ fontSize: 10 }}>
  //           <tr>
  //             <th colSpan={12} style={{ fontWeight: "normal" }}>
  //               <div style={{ fontSize: 12 }}>
  //                 {" "}
  //                 Kleenco On Demand Services Pvt Ltd
  //               </div>
  //               {invoiceOrderData[0].storeAddress}
  //               <div>
  //                 Phone No: {invoiceOrderData[0].storeContactNo}, Email:{" "}
  //                 {invoiceOrderData[0].storeEmail}, WebSite:{" "}
  //                 {invoiceOrderData[0].website}
  //               </div>
  //               <div>
  //                 Store Name: {invoiceOrderData[0].storeName}, Store Contact:{" "}
  //                 {invoiceOrderData[0].storeContactNo}
  //               </div>
  //               GST: {invoiceOrderData[0].storeGST}
  //               <br></br>
  //               <br></br>
  //               Estimated Bill
  //               <br></br>
  //               <br></br>
  //             </th>
  //           </tr>
  //           <tr style={{ textAlign: "center" }}>
  //             <td colSpan={4} valign="top">
  //               Order Request Date<br></br>
  //               {moment(invoiceOrderData[0].orderDate).format(
  //                 "DD/MM/YYYY h:mm a"
  //               )}
  //             </td>
  //             <td colSpan={4} valign="top">
  //               Pickup Date<br></br>
  //               {invoiceOrderData[0].pickupDate != null
  //                 ? moment(invoiceOrderData[0].pickupDate).format(
  //                     "DD/MM/YYYY h:mm a"
  //                   )
  //                 : ""}
  //             </td>
  //             <td colSpan={4} valign="top">
  //               Delivery Date<br></br>
  //               {moment(invoiceOrderData[0].deliveryDate).format(
  //                 "DD/MM/YYYY h:mm a"
  //               )}
  //             </td>
  //           </tr>
  //           <tr style={{ textAlign: "left" }}>
  //             {/* , paddingTop: 50 */}
  //             <td colSpan={8} style={{ paddingTop: 20 }}>
  //               <strong>Order Information</strong>
  //             </td>
  //             <td style={{ paddingTop: 20 }}></td>
  //             <td colSpan={3} style={{ textAlign: "left", paddingTop: 20 }}>
  //               <strong>Address Information</strong>
  //             </td>
  //           </tr>
  //           <tr style={{ textAlign: "left" }}>
  //             <td colSpan={3} valign="top" style={{ paddingTop: 10 }}>
  //               OrderID:{invoiceOrderData[0].orderID}
  //               <br></br>
  //               CustomerName:{invoiceOrderData[0].customerName}
  //               <br></br>
  //               Contact No:{invoiceOrderData[0].customerMobile}
  //               <br></br>
  //               Delivery Type:
  //               {invoiceOrderData[0].deliveryTypeName}
  //               <br></br>
  //               Price Type:{invoiceOrderData[0].priceType}
  //             </td>
  //             <td colSpan={5} style={{ paddingTop: 10 }} valign="top">
  //               Invoice No:{invoiceOrderData[0].invoiceNumber}
  //               <br></br>
  //               <br></br>
  //               Customer GST No:
  //               {invoiceOrderData[0].customerGST}
  //               <br></br>
  //             </td>
  //             <td style={{ paddingTop: 20 }}></td>
  //             <td
  //               colSpan={3}
  //               valign="top"
  //               style={{ paddingTop: 10, maxWidth: "60px" }}
  //             >
  //               {invoiceOrderData[0].customerAddress}
  //               {/* KARTHIK-BENGALARUSAI TEJA SEREIN -FLAT NO.316, ALFA GARDENS,7th
  //               CROSS -RIGHT, K.R.PURAM,OPP-DIYA ACADEMY, - ALFA GARDENS ,K R
  //               Puram, Bangalore */}
  //             </td>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           <tr>
  //             <td colSpan={12} style={{ fontSize: 10, paddingTop: 20 }}>
  //               <strong>Product Details</strong>
  //             </td>
  //           </tr>
  //           <br></br>
  //           <tr style={{ fontSize: 10 }}>
  //             <th colSpan={2} style={{ fontWeight: "normal", paddingTop: 10 }}>
  //               Cloth Type
  //             </th>
  //             <th colSpan={2} style={{ fontWeight: "normal", paddingTop: 10 }}>
  //               Wash Type
  //             </th>
  //             <th colSpan={2} style={{ fontWeight: "normal", paddingTop: 10 }}>
  //               HSN Code
  //             </th>
  //             <th colSpan={2} style={{ fontWeight: "normal", paddingTop: 10 }}>
  //               Quantity
  //             </th>
  //             <th colSpan={2} style={{ fontWeight: "normal", paddingTop: 10 }}>
  //               Unit Price
  //             </th>
  //             <th colSpan={2} style={{ fontWeight: "normal", paddingTop: 10 }}>
  //               Price
  //             </th>
  //           </tr>
  //           {data}
  //         </tbody>
  //         <tfoot>
  //           <tr style={{ fontSize: 10 }}>
  //             <td
  //               colSpan={3}
  //               valign="top"
  //               style={{ textAlign: "left", paddingTop: 20 }}
  //             >
  //               Total Quantity: {"missing"}
  //               <br></br>
  //               <br></br>
  //               <br></br>
  //             </td>
  //             <td colSpan={5} style={{ paddingTop: 20 }}></td>
  //             <td colSpan={2} valign="top" style={{ paddingTop: 20 }}>
  //               Subtotal:<br></br>
  //               Discount:<br></br>
  //               TaxesAndCharges:<br></br>
  //               Total:<br></br>
  //             </td>
  //             <td colSpan={2} style={{ paddingTop: 20 }}>
  //               {invoiceOrderData[0].serviceAmount}
  //               <br></br>
  //               {invoiceOrderData[0].discountAmount}
  //               <br></br>
  //               {invoiceOrderData[0].packagingCharges +
  //                 invoiceOrderData[0].deliveryChargesAmount +
  //                 invoiceOrderData[0].cgst +
  //                 invoiceOrderData[0].sgst +
  //                 invoiceOrderData[0].igst}
  //               <br></br>
  //               {invoiceOrderData[0].finalInvoiceAmount}
  //             </td>
  //           </tr>
  //           <br></br>
  //           <br></br>
  //           <tr>
  //             <td colSpan={12} style={{ fontSize: 10, paddingTop: 20 }}>
  //               Payment Details<br></br>
  //               <br></br>
  //               {paymentData}
  //             </td>
  //           </tr>
  //         </tfoot>
  //       </table>
  //     </div>
  //   );

  //   var opt = {
  //     margin: 1,
  //     filename: "EstimatedInvoice.pdf",
  //     jsPDF: { format: "a5", orientation: "portrait" },
  //     pagebreak: { mode: ["css", "legacy"], avoid: "tr" },
  //     html2canvas: {
  //       dpi: 192,
  //       scale: 4,
  //     },
  //   };
  //   debugger;
  //   console.log(renderToString(tableData));
  //   html2pdf().set(opt).from(renderToString(tableData)).save();
  // });
  invoiceService.getPDFLinks(invoiceID).then((response) => {
    
    if (response.statusCode === 200) {
      let filteredObj = response.data.filter(
        (x) => x.splitOrderNumber === splitOrderNo
      );
      if (filteredObj.length > 0) {
        window.open(filteredObj[0].location, "_blank");
      }
    }
  });
};

export { getPDFProformaInvoice };
