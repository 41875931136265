import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";

import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Table,
  notification,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faPencilAlt,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../routes";
import CustomerService from "../../../services/customerservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";
import Moment from "moment";

const { Search } = Input;
const { Option } = Select;

/**
 * A customer master screen which holds grid view for master screens and form with steps
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 25 June 2020
 */
class Master extends React.Component {
  customerService = new CustomerService();
  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    showfilterBox: false,
    factories: [],
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.CUSTOMER
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }
  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  // {
  //   title: "ID",
  //   dataIndex: "customerID",
  //   key: "customerID",
  //   width: "3%",
  //   sorter: true,
  // },
  columns = [
    {
      title: labels.FIRST_NAME,
      dataIndex: "firstName",
      key: "firstName",
      sorter: true,
    },
    {
      title: labels.LAST_NAME,
      dataIndex: "lastName",
      key: "lastName",
      sorter: true,
    },
    {
      title: labels.GENDER,
      dataIndex: "genderName",
      key: "genderName",
      width: "80px",
      sorter: true,
    },
    {
      title: labels.EMAIL,
      dataIndex: "emailID",
      key: "emailID",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (emailID) => {
        return (
          <Tooltip placement="topLeft" title={emailID}>
            {emailID}
          </Tooltip>
        );
      },
    },
    {
      title: labels.PHONE,
      dataIndex: "mobileNo",
      key: "mobileNo",
      width: "100px",
      sorter: true,
    },
    {
      title: labels.TYPE,
      dataIndex: "customerTypeName",
      key: "customerTypeName",
      width: "100px",
      sorter: true,
    },
    {
      title: labels.BILLING_CYCLE,
      dataIndex: "billingCycleName",
      key: "billingCycleName",
      width: "100px",
      sorter: true,
    },
    {
      title: labels.REGISTERED_ON,
      dataIndex: "createdOn",
      key: "createdOn",
      width: "100px",
      sorter: true,
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.ACTIONS,
      key: "actions",
      width: "100px",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title={labels.VIEW}>
              <Link to={[ROUTES.VIEW_CUSTOMER, record.customerID].join("/")}>
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title={labels.EDIT}>
              <Link to={[ROUTES.EDIT_CUSTOMER, record.customerID].join("/")}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    this.getCustomerList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  }
  getCustomerList = (
    index,
    size,
    column = this.state.sorter.field,
    order = this.state.sorter.order,
    search = this.state.search
  ) => {
    this.setState({ tableLoading: true });
    this.customerService
      .getAllCustomers(index, size, column, order, search)
      .then((response) => {
        if (response.statusCode === 200) {
          let customerData = [];
          response.data.map((singleData) => {
            singleData.key = singleData.customerID;
            customerData.push(singleData);
            return true;
          });
          this.setState({ factories: customerData });
          this.setState({
            pagination: {
              ...this.state.pagination,
              total: customerData.length ? customerData[0].totalRecords : 0,
            },
          });
        } else {
          notification["error"]({
            message: "Manage Customers",
            description: response.message
              ? this.common.Message(response.message)
              : "Unable to get list of customers",
          });
        }
        this.setState({ tableLoading: false });
      });
  };
  render() {
    return (
      <div>
        <PageHeader
          title={labels.MANAGE_CUSTOMERS}
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              key="1"
              onChange={(event) => {
                this.setState(
                  { search: event.target.value.toString().toLowerCase() },
                  () => {
                    this.getCustomerList(
                      this.state.pagination.current,
                      this.state.pagination.pageSize,
                      this.state.sorter.field,
                      this.state.sorter.order,
                      this.state.search
                    );
                  }
                );
              }}
            />,
            /**
             * Add Customer Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.props.history.push(ROUTES.ADD_CUSTOMER)}
                key="2"
              >
                {labels.ADD_CUSTOMER}
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>

                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getCustomerList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(Master);
