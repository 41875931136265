import React from "react";
import Box from "../../../Common/Box/box";
import {
  Table,
  PageHeader,
  Select,
  notification,
  Input,
  Row,
  Col,
  Space,
  Popconfirm,
  Form,
  InputNumber,
  Upload,
  Popover,
  Modal,
  Checkbox,
  Spin,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faTrash,
  faGift,
  faCamera,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../Common/Button/button";
import styles from "./createorder.module.css";
import ColorPicker from "../../../Common/ColorPicker/colorpicker";
import CreateOrderService from "../../../../services/createorderservice";
import moment from "moment";
import infoIcon from "../../../../images/Order/info.svg";
import { withRouter } from "react-router-dom";
import ROUTES from "../../../../routes";
import WalletIcon from "../../../../images/Billing/wallet.svg";
import { getPDFEstimatedOrder } from "./estimatedorderpdf.component";
import InvoiceService from "../../../../services/invoiceservice";
import PickupService from "../../../../services/pickupservice";
import PaytmTransService from "../../../../services/PaytmTransService";
import { labels } from "../../../../shared/language";
import Common from "../../../../services/common";

const { Option } = Select;
const confirm = Modal.confirm;

class ReviewOrder extends React.Component {
  PaytmTransService = new PaytmTransService();
  common = new Common();
  state = {
    disablegenerateOTP: false,
    TimerValue: 0,
    isResendOtp: false,
    ALTmobileNo: "",
    InputOTP: "",
    ReceivedOTP: "",
    ISVerified: false,
    showAddPopup: false,
    modalLoading: false,
    isTransFail: false,
    showAdvancePaymentPopup: false,
    HolidayList: [],
    paymentMethods: {
      data: [],
      disabled: true,
      selected: 1,
    },
    orderSummary: [],
    orderDate: this.props.orderDate,
    dataSource: [],
    addOnServices: {
      data: [],
      disabled: true,
    },
    brands: {
      data: [],
      disabled: true,
    },
    operationInstructions: {
      selected: [],
      data: [],
      disabled: true,
    },
    sizes: {
      data: [],
      disabled: true,
    },
    timeCard: null,
    dyeingColors: {
      data: [],
      disabled: true,
    },
    disableAll: false,
    shouldConfirm: false,
    orderSummaryDefined: false,
    rewashMode: false,
    rewashData: [],
    rewashReason: {
      data: [],
      disabled: true,
    },
    rewashRequestedBy: {
      data: [],
      disabled: true,
    },
    thisIsRewashOrder: false,
    garmentDefectOptions: {
      data: [],
      disabled: true,
    },
    // isConfirmBtnLoading: false,
    columns: [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
        render: (text, record) => parseInt(text),
      },
      parseInt(this.props.orderStatus) > 2
        ? {
            title: labels.TAG,
            dataIndex: "tagNo",
            key: "tagNo",
          }
        : {},
      {
        title: labels.GARMENT,
        dataIndex: "garmentName",
        key: "garmentName",
        render: (text, record, index) => {
          return (
            <React.Fragment>
              <div>
                <small>
                  {record.serviceName}
                  {record.splitOrderNumber !== ""
                    ? "-" + record.splitOrderNumber
                    : null}
                </small>
                <div>{record.garmentName}</div>
              </div>
              {record.UOM === 3 ? (
                <React.Fragment>
                  <Space
                    direction="horizontal"
                    className={styles.WidthLengthWrap}
                  >
                    <Form.Item
                      name={"width" + index}
                      style={{ marginRight: 0 }}
                      initialValue={record.width === 0 ? null : record.width}
                      label="W"
                      rules={[{ required: true, message: labels.REQUIRED }]}
                    >
                      <InputNumber
                        step={0.01}
                        min={0.01}
                        placeholder="W"
                        onChange={(value) => {
                          let updated = [...this.state.dataSource];
                          updated[index].width = value;
                          updated[index].price =
                            updated[index].width *
                            updated[index].length *
                            updated[index].rate;
                          this.setState({ dataSource: updated }, () =>
                            this.props.updateArray(updated)
                          );
                        }}
                        disabled={this.state.disableAll}
                      />
                    </Form.Item>
                    <Form.Item
                      name={"length" + index}
                      style={{ marginRight: 0 }}
                      initialValue={record.length === 0 ? null : record.length}
                      label="L"
                      rules={[{ required: true, message: labels.REQUIRED }]}
                    >
                      <InputNumber
                        step={0.01}
                        min={0.01}
                        placeholder="L"
                        onChange={(value) => {
                          let updated = [...this.state.dataSource];
                          updated[index].length = value;
                          updated[index].price =
                            updated[index].width *
                            updated[index].length *
                            updated[index].rate;
                          this.setState({ dataSource: updated }, () =>
                            this.props.updateArray(updated)
                          );
                        }}
                        disabled={this.state.disableAll}
                      />
                    </Form.Item>
                  </Space>
                  {/* </div> */}
                </React.Fragment>
              ) : null}
              {record.UOM === 2 ? (
                <React.Fragment>
                  <div className={styles.WidthLengthWrap}>
                    <Form.Item
                      name={"weight" + record.serviceId}
                      style={{ marginRight: 0 }}
                      label={labels.WEIGHT}
                      initialValue={
                        this.props.order[record.serviceId] !== undefined
                          ? this.props.order[record.serviceId]["-1"]
                          : null
                      }
                      rules={[{ required: true, message: labels.REQUIRED }]}
                    >
                      <InputNumber
                        step={0.01}
                        min={0.01}
                        placeholder="Wt."
                        onChange={(value) => {
                          let updated = { ...this.props.order };
                          updated[record.serviceId] = {
                            ...updated[record.serviceId],
                            ["-1"]: value,
                          };
                          this.props.updateOrder(updated);
                          this.props.updateOrderFunc(
                            record.serviceId,
                            -1,
                            value,
                            this.props.GarmentDeliveryType //Added by rashmi for L177728 - 22/02/23
                          );
                          let filteredLength = this.state.dataSource.filter(
                            (x) =>
                              parseInt(x.serviceId) ===
                              parseInt(record.serviceId)
                          ).length;
                          if (filteredLength !== 0) {
                            let updatedArray = this.state.dataSource.map(
                              (single) => {
                                if (
                                  parseInt(single.serviceId) ===
                                  parseInt(record.serviceId)
                                ) {
                                  single.price =
                                    (value * single.rate) / filteredLength;
                                }
                                return single;
                              }
                            );
                            this.setState({ dataSource: updatedArray });
                            this.props.updateArray(updatedArray);
                          }
                        }}
                        disabled={this.state.disableAll}
                      />
                    </Form.Item>
                  </div>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          );
        },
      },

      {
        title: labels.GARMENT_DEFECT,
        key: "garmentDefect",
        render: (text, record, index) => (
          <React.Fragment>
            <Checkbox.Group
              onChange={(value) => {
                let updated = [...this.state.dataSource];
                updated[index] = {
                  ...this.state.dataSource[index],
                  garmentDefect: value,
                };
                this.setState(
                  {
                    dataSource: updated,
                  },
                  () => this.props.updateArray(updated)
                );
                // console.log(this.state.garmentDefectOptions)
              }}
              value={this.state.dataSource[index].garmentDefect}
              disabled={this.state.garmentDefectOptions.disabled || this.state.disableAll}

            >
              {this.state.garmentDefectOptions.data.map((option, index) => (
                <Space direction="vertical" className="w-100" size="large">
                  <Checkbox key={index} value={option.enumDetailID}>
                    {option.enumDetailName}
                  </Checkbox>
                </Space>
              ))}
            </Checkbox.Group>
          </React.Fragment>
        ),
      },
      {
        title: labels.ADD_ON_SERVICES,
        key: "addon",
        width: "18%",
        render: (text, record, index) => {
          return (
            <React.Fragment>
              {record.UOM !== 2 ? (
                <React.Fragment>
                  {![8, 9].includes(parseInt(record.serviceId)) ? (
                    <Form.Item
                      name={"adddon" + index}
                      style={{ marginRight: 0 }}
                      initialValue={record.addOnServices}
                    >
                      <Select
                        size="small"
                        placeholder={labels.SELECT_ADD_ON_SERVICES}
                        mode="multiple"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        key={record.no}
                        value={record.addOnServices}
                        onChange={(value) => {
                          if (value.length > 0) {
                            value.map((singleService) => {
                              if (
                                parseInt(singleService) !== 8 &&
                                parseInt(singleService) !== 9
                              ) {
                                this.props.getRateForGarment(
                                  this.props.storeID,
                                  record.UOM,
                                  null,
                                  parseInt(singleService),
                                  record.garmentId,
                                  () => null,
                                  (response) => {
                                    let updated = [...this.state.dataSource];
                                    updated[index].addOnServices = value;
                                    // updated[index].deliveryDate = moment(
                                    //   this.props.orderDate
                                    // ).add(
                                    //   this.props.calculateDeliveryDateAdd(
                                    //     record.serviceId,
                                    //     value,
                                    //     record.garmentId
                                    //   ),
                                    //   "d"
                                    // );
                                    let DeliveryDateAfterAdd = moment(
                                      this.props.orderDate
                                    ).add(
                                      this.props.calculateDeliveryDateAdd(
                                        record.serviceId,
                                        value,
                                        record.garmentId
                                      ),
                                      "d"
                                    );
                                    let weekDayName = JSON.parse(
                                      localStorage.getItem("StoreSlots")
                                    ).map((dayName) => {
                                      return dayName.weekDayName;
                                    });

                                    updated[index].deliveryDate =
                                      weekDayName.includes(
                                        DeliveryDateAfterAdd.format("ddd")
                                      )
                                        ? DeliveryDateAfterAdd
                                        : DeliveryDateAfterAdd.add(1, "d");
                                    updated[index].deliveryDate =
                                      weekDayName.includes(
                                        updated[index].deliveryDate.format(
                                          "ddd"
                                        )
                                      )
                                        ? updated[index].deliveryDate
                                        : updated[index].deliveryDate.add(
                                            1,
                                            "d"
                                          );

                                    if (!value.includes("8")) {
                                      updated[index].dyingColorsUOM = null;
                                      updated[index].dyingColorsValue = null;
                                    }
                                    if (!value.includes("9")) {
                                      updated[index].darningSize = null;
                                    }
                                    if (value.includes("10")) {
                                      updated[index].ucPrice = response.rate;
                                    }
                                    this.setState(
                                      {
                                        dataSource: updated,
                                      },
                                      () =>
                                        this.props.updateArray(
                                          this.state.dataSource
                                        )
                                    );
                                  }
                                );
                              } else {
                                let updated = [...this.state.dataSource];
                                updated[index].addOnServices = value;
                                // updated[index].deliveryDate = moment(
                                //   this.props.orderDate
                                // ).add(
                                //   this.props.calculateDeliveryDateAdd(
                                //     record.serviceId,
                                //     value,
                                //     record.garmentId
                                //   ),
                                //   "d"
                                // );

                                let DeliveryDateAfterAdd = moment(
                                  this.props.orderDate
                                ).add(
                                  this.props.calculateDeliveryDateAdd(
                                    record.serviceId,
                                    value,
                                    record.garmentId
                                  ),
                                  "d"
                                );
                                let weekDayName = JSON.parse(
                                  localStorage.getItem("StoreSlots")
                                ).map((dayName) => {
                                  return dayName.weekDayName;
                                });

                                updated[index].deliveryDate =
                                  weekDayName.includes(
                                    DeliveryDateAfterAdd.format("ddd")
                                  )
                                    ? DeliveryDateAfterAdd
                                    : DeliveryDateAfterAdd.add(1, "d");

                                updated[index].deliveryDate =
                                  weekDayName.includes(
                                    updated[index].deliveryDate.format("ddd")
                                  )
                                    ? updated[index].deliveryDate
                                    : updated[index].deliveryDate.add(1, "d");
                                if (!value.includes("8")) {
                                  updated[index].dyingColorsUOM = null;
                                  updated[index].dyingColorsValue = null;
                                  updated[index].dyingPrice = 0;
                                }
                                if (!value.includes("9")) {
                                  updated[index].darningSize = null;
                                  updated[index].darningPrice = 0;
                                }
                                if (!value.includes("10")) {
                                  updated[index].ucPrice = 0;
                                }
                                this.setState(
                                  {
                                    dataSource: updated,
                                  },
                                  () =>
                                    this.props.updateArray(
                                      this.state.dataSource
                                    )
                                );
                              }
                            });
                          } else {
                            let updated = [...this.state.dataSource];
                            // updated[index].deliveryDate = moment(
                            //   this.props.orderDate
                            // ).add(
                            //   this.props.calculateDeliveryDateAdd(
                            //     record.serviceId,
                            //     [],
                            //     record.garmentId
                            //   ),
                            //   "d"
                            // );
                            let DeliveryDateAfterAdd = moment(
                              this.props.orderDate
                            ).add(
                              this.props.calculateDeliveryDateAdd(
                                record.serviceId,
                                value,
                                record.garmentId
                              ),
                              "d"
                            );
                            let weekDayName = JSON.parse(
                              localStorage.getItem("StoreSlots")
                            ).map((dayName) => {
                              return dayName.weekDayName;
                            });

                            updated[index].deliveryDate = weekDayName.includes(
                              DeliveryDateAfterAdd.format("ddd")
                            )
                              ? DeliveryDateAfterAdd
                              : DeliveryDateAfterAdd.add(1, "d");

                            updated[index].deliveryDate = weekDayName.includes(
                              updated[index].deliveryDate.format("ddd")
                            )
                              ? updated[index].deliveryDate
                              : updated[index].deliveryDate.add(1, "d");

                            updated[index].addOnServices = [];
                            updated[index].darningSize = null;
                            updated[index].dyingColorsUOM = null;
                            updated[index].dyingColorsValue = null;
                            updated[index].darningPrice = 0;
                            updated[index].dyingPrice = 0;
                            updated[index].ucPrice = 0;
                            this.setState(
                              {
                                dataSource: updated,
                              },
                              () =>
                                this.props.updateArray(this.state.dataSource)
                            );
                          }
                        }}
                        disabled={
                          this.state.addOnServices.disabled ||
                          this.state.disableAll
                        }
                      >
                        {this.state.addOnServices.data.length !== 0
                          ? this.state.addOnServices.data.map((service) => {
                              return service.value !== "-1" ? (
                                <Option
                                  value={service.value}
                                  key={service.value}
                                >
                                  {service.text}
                                </Option>
                              ) : null;
                            })
                          : null}
                      </Select>
                    </Form.Item>
                  ) : null}
                  {record.addOnServices.includes("9") ||
                  parseInt(record.serviceId) == 9 ? (
                    <div className={styles.TableFormItemWrap}>
                      <Form.Item
                        label={labels.DARNING_SIZE}
                        name={"darningSize" + index}
                        rules={[{ required: true, message: labels.REQUIRED }]}
                        initialValue={
                          record.darningSize !== null &&
                          parseInt(record.darningSize) !== 0 &&
                          record.darningSize !== ""
                            ? record.darningSize.toString()
                            : undefined
                        }
                      >
                        <Select
                          size="small"
                          className="w-100"
                          placeholder="Size"
                          onChange={(value) => {
                            if (value.length > 0) {
                              this.props.getRateForGarment(
                                this.props.storeID,
                                value,
                                null,
                                9,
                                record.garmentId,
                                () => null,
                                (response) => {
                                  let updated = [...this.state.dataSource];
                                  updated[index].darningSize = value;
                                  updated[index].darningPrice = response.rate;
                                  this.setState(
                                    {
                                      dataSource: updated,
                                    },
                                    () => this.props.updateArray(updated)
                                  );
                                }
                              );
                            } else {
                              let updated = [...this.state.dataSource];
                              updated[index].darningSize = value;
                              updated[index].darningPrice = 0;
                              this.setState(
                                {
                                  dataSource: updated,
                                },
                                () =>
                                  this.props.updateArray(this.state.dataSource)
                              );
                            }
                          }}
                          disabled={this.state.disableAll}
                        >
                          <Option value="11">{labels.SMALL}</Option>
                          <Option value="12">{labels.MEDIUM}</Option>
                          <Option value="13">{labels.LARGE}</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  ) : null}
                  {record.addOnServices.includes("8") ||
                  parseInt(record.serviceId) == 8 ? (
                    <div className={styles.TableFormItemWrap}>
                      <Form.Item
                        label={labels.DYING_COLORS}
                        name={"dyingColors" + index}
                        rules={[{ required: true, message: labels.REQUIRED }]}
                        initialValue={record.dyingColorsValue}
                      >
                        <Select
                          size="small"
                          mode="multiple"
                          className="w-100"
                          placeholder="Color"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            if (value.length > 0) {
                              let passUOM = 0;
                              if (value.length === 1) {
                                passUOM = 21;
                              } else if (value.length > 1) {
                                passUOM = 22;
                              }
                              this.props.getRateForGarment(
                                this.props.storeID,
                                passUOM,
                                null,
                                8,
                                record.garmentId,
                                () => null,
                                (response) => {
                                  let updated = [...this.state.dataSource];
                                  updated[index].dyingColorsValue = value;
                                  updated[index].dyingColorsUOM = passUOM;
                                  updated[index].dyingPrice = response.rate;
                                  this.setState(
                                    {
                                      dataSource: updated,
                                    },
                                    () =>
                                      this.props.updateArray(
                                        this.state.dataSource
                                      )
                                  );
                                }
                              );
                            } else {
                              let updated = [...this.state.dataSource];
                              updated[index].dyingColorsValue = "";
                              updated[index].dyingColorsUOM = null;
                              updated[index].dyingPrice = 0;
                              this.setState(
                                {
                                  dataSource: updated,
                                },
                                () =>
                                  this.props.updateArray(this.state.dataSource)
                              );
                            }
                          }}
                          disabled={this.state.disableAll}
                        >
                          {this.state.dyeingColors.data.map((object) => {
                            return (
                              <Option
                                value={object.enumDetailID.toString()}
                                key={object.enumDetailID}
                              >
                                {object.enumDetailName}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        title: labels.COLOR,
        dataIndex: "color",
        key: "color",
        align: "center",
        render: (text, record, index) => (
          <ColorPicker
            color={record.color !== null ? record.color.toString() : null}
            onChange={(value) => {
              let updated = [...this.state.dataSource];
              updated[index].color = value.hex.toString();
              this.setState(
                {
                  dataSource: updated,
                },
                () => this.props.updateArray(updated)
              );
            }}
            disabled={this.state.disableAll}
          />
        ),
      },
      {
        title: labels.BRAND,
        dataIndex: "brand",
        key: "brand",
        render: (text, record, index) => (
          <Form.Item
            name={"brand" + index}
            // rules={[{ required: true, message: "Required!" }]}
            initialValue={
              record.brand !== null && record.brand !== "" && record.brand !== 0
                ? record.brand.toString()
                : []
            }
          >
            <Select
              size="small"
              placeholder={labels.BRAND}
              key={record.no}
              onChange={(value) => {
                let updated = [...this.state.dataSource];
                updated[index] = {
                  ...this.state.dataSource[index],
                  brand: value,
                };
                this.setState(
                  {
                    dataSource: updated,
                  },
                  () => this.props.updateArray(this.state.dataSource)
                );
              }}
              disabled={this.state.brands.disabled || this.state.disableAll}
            >
              {this.state.brands.data.length !== 0
                ? this.state.brands.data.map((brand) => {
                    return brand.value !== "-1" ? (
                      <Option value={brand.value} key={brand.value}>
                        {brand.text}
                      </Option>
                    ) : null;
                  })
                : null}
            </Select>
          </Form.Item>
        ),
      },
      {
        title: labels.SIZE,
        dataIndex: "size",
        key: "size",
        width: "50px",
        render: (text, record, index) => (
          <Form.Item
            name={"size" + index}
            // rules={[{ required: true, message: "Required!" }]}
            initialValue={
              record.size !== null && record.size !== ""
                ? record.size.toString()
                : []
            }
          >
            <Select
              size="small"
              placeholder={labels.SIZE}
              key={record.no}
              onChange={(value) => {
                let updated = [...this.state.dataSource];
                updated[index] = {
                  ...this.state.dataSource[index],
                  size: value,
                };
                this.setState(
                  {
                    dataSource: updated,
                  },
                  () => this.props.updateArray(this.state.dataSource)
                );
              }}
              disabled={this.state.sizes.disabled || this.state.disableAll}
            >
              {this.state.sizes.data.length !== 0
                ? this.state.sizes.data.map((size) => {
                    return size.value !== "-1" ? (
                      <Option value={size.value} key={size.value}>
                        {size.text}
                      </Option>
                    ) : null;
                  })
                : null}
            </Select>
          </Form.Item>
        ),
      },
      {
        title: labels.DELIVERY_DATE,
        dataIndex: "deliveryDate",
        key: "deliveryDate",
        render: (text, record) => {
          this.skip_Holiday(record.deliveryDate);
          return record.deliveryDate !== null
            ? record.deliveryDate.format("DD-MM-YYYY")
            : null;
        },
        width: "100px",
      },
      {
        title: labels.PRICE,
        dataIndex: "price",
        key: "price",
        render: (text, record) => {
          return (
            <span style={{ whiteSpace: "nowrap" }}>
              ₹{" "}
              {this.state.thisIsRewashOrder || this.state.rewashMode ? (
                0
              ) : (
                <Tooltip
                  // visible={record.UOM !== 2}
                  title={
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Service Charges</div>
                        <div>₹ {parseFloat(record.price).toFixed(2)}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>Add-on Service Charges</div>
                        <div style={{ marginLeft: 5 }}>
                          ₹{" "}
                          {(
                            record.darningPrice +
                            record.dyingPrice +
                            record.ucPrice
                          ).toFixed(2)}
                        </div>
                      </div>
                    </React.Fragment>
                  }
                >
                  {(
                    record.price +
                    record.darningPrice +
                    record.dyingPrice +
                    record.ucPrice
                  ).toFixed(2)}
                </Tooltip>
              )}
            </span>
          );
        },
      },
      {
        title: labels.NOTES,
        dataIndex: "notes",
        key: "notes",
        width: "30px",
        render: (text, record, index) => {
          let shouldDisplay = true;
          if (this.state.disableAll && this.state.rewashMode) {
            shouldDisplay = false;
            if (
              ![null, undefined, ""].includes(record.notes) ||
              this.state.rewashMode
            ) {
              shouldDisplay = true;
            }
          }
          return shouldDisplay ? (
            <Popover
              content={
                <Input.TextArea
                  value={record.notes !== null ? record.notes.toString() : null}
                  placeholder={labels.NOTES}
                  onChange={(event) => {
                    let updated = [...this.state.dataSource];
                    updated[index].notes = event.target.value;
                    this.setState({
                      dataSource: updated,
                    });
                    this.props.updateArray(updated);
                  }}
                  disabled={
                    this.state.disableAll && !this.state.rewashMode
                      ? true
                      : false
                  }
                />
              }
              // title="Notes"
              trigger="click"
            >
              <Button type="link" varinat="link">
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Popover>
          ) : null;
        },
      },
      {
        title: labels.IMAGES,
        dataIndex: "images",
        key: "images",
        width: "30px",
        render: (text, record, index) => {
          return (
            <Upload
              multiple
              fileList={record.images.filter((x) => x !== null)}
              onChange={({ fileList }) => {
                if (fileList.length > 3) {
                  notification["error"]({
                    message: labels.ORDERS,
                    description: labels.ONLY_3_IMAGES_ARE_ALLOWED,
                  });
                  return false;
                }
                var file1 = fileList[fileList.length - 1];

                if (
                  !["image/jpeg", "image/png", "image/jpg"].includes(file1.type)
                ) {
                  notification["error"]({
                    message: "Orders",
                    description: "You can only upload JPG/PNG/JPEG file",
                  });
                  return false;
                }
                let isLt3M = file1.size / 1024 / 1024 < 5;
                if (!isLt3M) {
                  notification["error"]({
                    message: "Orders",
                    description: "Image must be smaller than 5 MB",
                  });
                  return false;
                }

                let updated = [...this.state.dataSource];
                for (let i = 0; i < 3; i++) {
                  if (fileList.length > i) {
                    fileList[i].status = "done";
                    updated[index].images[i] = fileList[i];
                  } else {
                    updated[index].images[i] = null;
                  }
                }
                this.setState({ dataSource: updated });
                this.props.updateArray(updated);
              }}
              customRequest={({ file, onSuccess, onError }) => {
                onSuccess({ status: "done" }, file);
              }}
            >
              <Button variant="link" type="link">
                <FontAwesomeIcon icon={faCamera} />
              </Button>
            </Upload>
          );
        },
      },
      [ROUTES.UPDATE_ORDER, ":orderID"].join("/") === this.props.match.path
        ? parseInt(this.props.orderStatus) >= 2
          ? {}
          : {
              title: labels.ACTIONS,
              key: "addon",
              width: "30px",
              render: (text, record, index) => (
                <div>
                  <Popconfirm
                    title={labels.ARE_YOU_SURE}
                    onConfirm={() => {
                      this.state.dataSource.splice(index, 1);
                      this.props.deleteGarments(record.orderGarmentID);
                      this.props.updateArray(
                        this.state.dataSource,
                        record.serviceId,
                        record.garmentId
                      );
                    }}
                    onCancel={() => null}
                    okText={labels.YES}
                    cancelText={labels.NO}
                  >
                    <Button type="link">
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Popconfirm>
                </div>
              ),
            }
        : {},
    ],
  };
  formInstance = React.createRef();
  rewashFormInstance = React.createRef();
  createOrderService = new CreateOrderService();
  invoiceService = new InvoiceService();
  pickupService = new PickupService();

  skip_Holiday = (deliveryDate) => {
    let temparr = this.state.HolidayList;
    var HolidayArr = [];
    var delivery_date = moment(deliveryDate).format("YYYY-MM-DD");
    HolidayArr = temparr.filter((a) => {
      var holiday_date = moment(a.holidayDate).format("YYYY-MM-DD");
      return delivery_date == holiday_date;
    });
    let len = HolidayArr.length;
    var new_deliveryDate;
    if (len > 0) {
      new_deliveryDate = deliveryDate.add(len, "d");
      this.skip_Holiday(new_deliveryDate);
    } else {
      new_deliveryDate = deliveryDate;
    }
  };

  componentWillMount() {
    this.getRewashReason();
    this.getRewashRequestedBy();
  }

  componentDidMount() {
    // console.log("detail", this.props.customerInfo);
    // this.generateDataSource();
    this.getPaymentMethods();
    this.getAddOnServices();
    this.getBrandsList();
    this.getSizeList();
    this.getOpeartionInstructions();
    this.getDyeingColors();
    this.getHolidayList();
    this.getGarmentDefect();
    this.setState({ dataSource: this.props.orderArray });
    if (parseInt(this.props.orderStatus) >= 2) {
      this.setState({ disableAll: true });
      this.setState({ orderSummaryDefined: true });
    }
    if (parseInt(this.props.orderType) == 2) {
      this.setState({ thisIsRewashOrder: true });
    }
    if (![null, undefined, ""].includes(this.props.view)) {
      this.setState({ disableAll: true });
    }
  }

  getGarmentDefect = () => {
    this.createOrderService.getGarmentDefect().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          garmentDefectOptions: {
            ...this.state.garmentDefectOptions,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_LOAD_COLORS,
          duration: 1.5,
        });
      }
    });
  };

  getHolidayList = () => {
    var postData = parseInt(localStorage.getItem("branchID"));
    this.pickupService.retriveHolidaylist(postData).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          HolidayList: response.data,
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_HOLIDAYLIST,
        });
      }
    });
  };

  getPaymentMethods = () => {
    this.invoiceService.getPaymentMethods().then((response) => {
      if (response.statusCode === 200) {
        let data = response.data.map((single) => {
          return {
            value: parseInt(single.enumDetailID),
            label: single.enumDetailName,
          };
        });
        this.setState({
          paymentMethods: {
            ...this.state.paymentMethods,
            data: data,
            disabled: false,
          },
        });
      }
    });
  };

  // CheckPaytmEDCMachineTransData = (values) => {
  //   if (this.state.paymentMethods.selected == 3) {
  //     this.setState({ modalLoading: true });

  //     this.PaytmTransService.PaytmEDCTransInfo(this.props.orderID).then(
  //       (res) => {
  //         if (
  //           res != null &&
  //           res.length > 0 &&
  //           res.data.id == 0 &&
  //           res.data.merchantTransactionId == null
  //         ) {
  //           this.addAdvanceAmountToWalletRemoteRequest(values);
  //         } else {
  //           var res = JSON.parse(res.data.response);
  //           if (res != null && res.body.resultInfo.resultStatus == "SUCCESS") {
  //             notification["success"]({
  //               message: "Transaction has been successfully processed.",
  //             });
  //             this.addAdvanceAmountToWallet(values);
  //             return false;
  //           }
  //           this.addAdvanceAmountToWalletRemoteRequest(values);
  //         }
  //         this.setState({ modalLoading: false });
  //       }
  //     );
  //   } else {
  //     this.addAdvanceAmountToWalletRemoteRequest(values);
  //   }
  // };

  CheckPaytmEDCMachineTransData = (values) => {
    if (this.state.paymentMethods.selected == 3) {
      this.EDCRequest(values);
    } else if (this.state.paymentMethods.selected == 4) {
      this.QRCodeRequest(values);
    } else {
      this.addAdvanceAmountToWallet(values);
    }
  };

  QRCodeRequest = (values) => {
    this.setState({
      isTransFail: false,
    });
    let transID = this.common.PaytmTransNumber(
      this.props.orderID.toString(),
      "Advance Payment"
    );
    this.setState({ modalLoading: true, transID: transID });
    this.setState({ showAddPopup: true });

    var postdata = {
      mid: "LAUNDR39826025976613",
      orderId: transID,
      amount: values.amount.toString(),
      businessType: "UPI_QR_CODE",
      posId: localStorage.getItem("branchID"),
      clientId: "Mr9HS8Zd1lClTaJO",
      channelId: "WEB",
      version: "V1",
      versionNumber: "",
      transactionType: 0,
      response: "",
      createdBy: 0,
      id: this.props.orderID.toString(),
      callFrom: "revieworder",
      platform: "web-QR",
    };
    this.setState({ modalLoading: true });
    this.PaytmTransService.QRCodeRequest(postdata).then((response) => {
      if (response.statusCode == 200) {
        var res = JSON.parse(response.data.qrCodeResponse);
        if (res.body.resultInfo.resultStatus == "SUCCESS") {
          this.setState({
            isShowQRCode: true,
            base64Image: res.body.image,
            isQRCodeShow: true,
          });
          postdata.transactionType = 1;
          const intervalID = setInterval(() => {
            this.PaytmTransService.QRCodeStatusCheck(postdata).then(
              (response) => {
                if (response.statusCode == 200) {
                  var res = JSON.parse(response.data.qrCodeResponse);
                  if (res.body.resultInfo.resultStatus == "TXN_SUCCESS") {
                    notification["success"]({
                      message: labels.ORDERS,
                      description:
                        labels.TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED,
                    });
                    setTimeout(() => {
                      this.addAdvanceAmountToWallet(values);
                      this.setState({
                        // transID: postdata.merchantTransactionId,
                        showAddPopup: false,
                        modalLoading: false,
                        isQRCodeShow: false,
                      });
                    }, 1000);
                    clearInterval(intervalID);
                    this.setState({ modalLoading: false });
                  } else {
                    if (res.body.resultInfo.resultStatus === "TXN_FAILURE") {
                      clearInterval(intervalID);
                      this.setState({
                        showAddPopup: false,
                        isTransFail: true,
                        modalLoading: false,
                        isQRCodeShow: false,
                      });
                      notification["error"]({
                        message: labels.ORDERS,
                        description:
                          labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                      });
                    } else {
                      // debugger;
                    }
                  }
                }
              }
            );
          }, 15000);
          setTimeout(() => {
            clearInterval(intervalID);
            this.setState({
              showAddPopup: false,
              modalLoading: false,
              isQRCodeShow: false,
            });
            // if (
            //   (res.body.resultInfo.resultStatus == "FAIL" ||
            //     res.body.resultInfo.resultStatus ==
            //       "PANDING") &&
            //   this.state.isTransFail == false
            // ) {
            notification["error"]({
              message: labels.ORDERS,
              description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
            });
            // }
          }, 360000);
        } else {
          this.setState({
            showAddPopup: false,
            modalLoading: false,
            isQRCodeShow: false,
          });
          notification["error"]({
            message: labels.ORDERS,
            description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
          });
        }
      } else {
        this.setState({
          showAddPopup: false,
          modalLoading: false,
          isQRCodeShow: false,
        });
        notification["error"]({
          message: labels.ORDERS,
          description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
        });
      }
      this.setState({ modalLoading: false });
    });

    // } else {
    //   this.setState({ showAddPopup: false, modalLoading: false });
    //   notification["error"]({
    //     message: labels.BUY_PACKAGE,
    //     description: labels.UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM,
    //   });
    // }
    this.setState({ modalLoading: true, isQRCodeShow: false });
    //});
  };

  EDCRequest = (values) => {
    if (this.state.paymentMethods.selected == 3) {
      this.setState({
        isTransFail: false,
      });
      this.setState({ modalLoading: true });
      this.PaytmTransService.getEDCMachineInfo(this.props.orderID).then(
        (response) => {
          if (response.statusCode == 200) {
            if ([undefined, "", null].includes(response.data.machineKey)) {
              notification["error"]({
                message: labels.ORDERS,
                description: labels.EDC_CONFIGURATION,
              });
              this.setState({ modalLoading: false });
              return false;
            }
            let transID = this.common.PaytmTransNumber(
              this.props.orderID.toString(),
              "Advance Payment"
            );

            this.setState({
              showAddPopup: true,
              EDCmachineInfo: response.data,
              transID: transID,
            });

            var postdata = {
              paytmMid: response.data.paytmMid,
              paytmTid: response.data.paytmTid,
              transactionDateTime: "",
              merchantTransactionId: "",
              merchantReferenceNo: "",
              transactionAmount: (values.amount * 100).toString(),
              callbackUrl: "",
              machineKey: response.data.machineKey,
              json: 0,
              response: "",
              transactionType: 0,
              requestParams: "",
              createdBy: 0,
              status: "",
              id: this.props.orderID.toString(),
              callFrom: "revieworder",
              platform: "web",
              transctionId: transID,
              versionNumber: response.data.versionNumber,
            };
            this.PaytmTransService.EDCMachineRequest(postdata).then(
              (response) => {
                if (response.statusCode == 200) {
                  var res = JSON.parse(response.data.response);
                  if (res.body.resultInfo.resultStatus == "ACCEPTED_SUCCESS") {
                    postdata.merchantTransactionId = this.state.transID;
                    postdata.transactionDateTime =
                      response.data.transactionDateTime;
                    postdata.transactionType = 1;
                    const intervalID = setInterval(
                      () =>
                        this.PaytmTransService.EDCMachineStatus(postdata).then(
                          (response) => {
                            if (response.statusCode == 200) {
                              var res = JSON.parse(response.data.response);
                              if (
                                res.body.resultInfo.resultStatus == "SUCCESS"
                              ) {
                                notification["success"]({
                                  message: labels.ORDERS,
                                  description:
                                    labels.TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED,
                                });
                                setTimeout(() => {
                                  this.addAdvanceAmountToWallet(values);
                                  this.setState({
                                    //  transID: postdata.merchantTransactionId,
                                    showAddPopup: false,
                                  });
                                }, 1000);
                                clearInterval(intervalID);
                                return false;
                              } else {
                                if (
                                  res.body.resultInfo.resultStatus != "PENDING"
                                ) {
                                  clearInterval(intervalID);
                                  this.setState({
                                    showAddPopup: false,
                                    modalLoading: false,
                                  });
                                  notification["error"]({
                                    message: labels.ORDERS,
                                    description:
                                      labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                                  });
                                } else {
                                  setTimeout(() => {
                                    clearInterval(intervalID);
                                    this.setState({
                                      showAddPopup: false,
                                      modalLoading: false,
                                    });
                                    // if (
                                    //   (res.body.resultInfo.resultStatus ==
                                    //     "FAIL" ||
                                    //     res.body.resultInfo.resultStatus ==
                                    //     "PANDING") &&
                                    //   this.state.isTransFail == false
                                    // ) {
                                    notification["error"]({
                                      message: labels.ORDERS,
                                      description:
                                        labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                                    });
                                    // }
                                  }, 360000);
                                }
                              }
                            }
                          }
                        ),
                      10000
                    );
                  } else {
                    this.setState({ showAddPopup: false, modalLoading: false });
                    notification["error"]({
                      message: labels.ORDERS,
                      description:
                        labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                    });
                  }
                } else {
                  this.setState({ showAddPopup: false, modalLoading: false });
                  notification["error"]({
                    message: labels.ORDERS,
                    description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                  });
                }
              }
            );
          } else {
            this.setState({ showAddPopup: false, modalLoading: false });
            notification["error"]({
              message: labels.ORDERS,
              description:
                labels.UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM,
            });
          }
          this.setState({ modalLoading: false });
        }
      );
    } else {
      this.addAdvanceAmountToWallet(values);
    }
  };

  addAdvanceAmountToWallet = (values) => {
    const postData = {
      customerID: parseInt(this.props.customerInfo.id),
      orderID: this.props.orderID === 0 ? null : this.props.orderID.toString(),
      invoiceID: null,
      storeID: localStorage.getItem("branchID"),
      advanceAmount: values.amount,
      isExcessAmount: false,
      paymentMode: parseInt(this.state.paymentMethods.selected),
      chequeNo: null,
      chequeDate: null,
      cardNo: null,
      transactionNo: this.state.transID,
      remark: "Advance Amount Added For Order " + this.props.orderID,
      sourceFrom: 1,
    };
    this.setState({ modalLoading: true });
    this.invoiceService.addExcessAmountToWallet(postData).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.ADVANCE_COLLECTED_SUCCESSFULLY,
        });
        this.setState({ showAdvancePaymentPopup: false });
        this.invoiceService
          .getWalletBalance({
            customerID: this.props.customerInfo.id,
          })
          .then((response) => {
            if (response.statusCode === 200) {
              this.props.setWalletBalance(
                response.data.totalCashAmount,
                response.data.totalBonusAmount
              );
            }
          });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_ADD_AMOUNT_TO_LKWALLET,
        });
      }
      this.setState({ modalLoading: false });
    });
  };
  componentDidUpdate() {
    if (this.state.dataSource !== this.props.orderArray) {
      this.setState({ dataSource: this.props.orderArray });
    }
    if (this.props.orderType === 2 && this.state.disableAll !== true) {
      this.setState({ disableAll: true });
    }
  }
  setRewashMode = () => {
    this.setState({ rewashMode: true });
    this.getRewashReason();
    this.getRewashRequestedBy();
    let tableData = [...this.state.dataSource];
    let updated = tableData.map((single) => {
      single.deliveryDate = moment().add(
        this.props.calculateDeliveryDateAdd(
          single.serviceId,
          single.addOnServices,
          single.garmentId
        ),
        "d"
      );
      single.price = 0;
      return single;
    });

    // console.log("updated->",updated);

    this.setState({ dataSource: updated });
    this.props.updateArray(updated);
  };
  createRewashOrder = () => {
    this.rewashFormInstance.current
      .validateFields()
      .then(() =>
        this.props.createOrder(
          this.state.rewashData,
          this.calculateFinalDeliveryDate(),
          false,
          true
        )
      )
      .catch(() => null);
  };

  validatePromocode = () => {
    if (ROUTES.CREATE_ORDER === this.props.match.path) {
      this.props.createOrder(
        this.state.dataSource,
        this.calculateFinalDeliveryDate(),
        true
      );
    } else if (
      [ROUTES.UPDATE_ORDER, ":orderID"].join("/") === this.props.match.path
    ) {
      this.props.updateOrderAPI(
        this.state.dataSource,
        this.calculateFinalDeliveryDate(),
        false,
        true
      );
    }
  };
  confirmOrder = () => {
    // console.log("confirmOrder");
    if (!this.state.thisIsRewashOrder) {
      this.setState({ shouldConfirm: true });
      // this.setState({ isConfirmBtnLoading: true });
      this.formInstance.current
        .validateFields()
        .then(() => {
          //update order
          this.props.updateOrderAPI(
            this.state.dataSource,
            this.calculateFinalDeliveryDate(),
            true
          );

          // this.setState({ isConfirmBtnLoading: false });
        })
        .catch(() => null);
    } else {
      //rewash order
      this.props.updateOrderAPI(
        this.state.dataSource,
        this.calculateFinalDeliveryDate(),
        true
      );
    }
  };

  calculateCount = (serviceId = null) => {
    let total = 0;
    if (serviceId === null) {
      this.state.orderSummary.map((singleCategory, index) => {
        singleCategory.data.map((garment, index) => {
          total = total + garment.qty;
        });
      });
    } else {
      this.state.orderSummary
        .filter((x) => x.serviceId === serviceId)[0]
        .data.map((garment, index) => {
          total = total + garment.qty;
        });
    }
    return total;
  };
  calculateTotal = () => {
    let total = parseFloat(this.state.subtotal);
    let add = parseFloat(
      this.state.cgst +
        this.state.sgst +
        this.state.igst +
        this.state.packagingChargeTotal
    ).toFixed(2);
    let updated = total + parseFloat(add);
    return parseFloat(updated).toFixed(2);
  };
  calculateSubtotal = (service = null, type = "whole") => {
    let total = 0;
    if (service === null) {
      this.state.dataSource.map((record) => {
        let addAmt =
          type === "whole"
            ? parseFloat(
                (
                  record.price +
                  record.darningPrice +
                  record.dyingPrice +
                  record.ucPrice
                ).toFixed(2)
              )
            : type === "service"
            ? parseFloat(record.price.toFixed(2))
            : type === "addon"
            ? parseFloat(
                (
                  record.darningPrice +
                  record.dyingPrice +
                  record.ucPrice
                ).toFixed(2)
              )
            : parseFloat(
                (
                  record.price +
                  record.darningPrice +
                  record.dyingPrice +
                  record.ucPrice
                ).toFixed(2)
              );
        total = total + addAmt;
      });
    } else {
      let filtered = this.state.dataSource.filter(
        (x) => x.serviceId === service
      );
      if (filtered.length > 0) {
        filtered.map((record) => {
          total =
            total +
            parseFloat(
              (
                record.price +
                record.darningPrice +
                record.dyingPrice +
                record.ucPrice
              ).toFixed(2)
            );
        });
      }
    }
    return total;
  };
  calculateTaxes(type) {
    let tax = 0;
    let total = 0;
    if (type === "CGST") {
      this.props.orderArray.map((record) => {
        total =
          total +
          parseFloat(
            parseFloat(
              (
                record.price +
                record.darningPrice +
                record.dyingPrice +
                record.ucPrice
              ).toFixed(2)
            ).toFixed(2)
          );
      });
      total =
        total +
        this.calculatePackagingCharges() +
        this.calculateDeliveryCharges() -
        this.props.discount;
      tax =
        (total *
          (this.props.orderArray.length > 0
            ? this.props.orderArray[0].cgst
            : 0)) /
        100;
    }
    if (type === "SGST") {
      this.props.orderArray.map((record) => {
        total =
          total +
          parseFloat(
            parseFloat(
              (
                record.price +
                record.darningPrice +
                record.dyingPrice +
                record.ucPrice
              ).toFixed(2)
            ).toFixed(2)
          );
      });
      total =
        total +
        this.calculatePackagingCharges() +
        this.calculateDeliveryCharges() -
        this.props.discount;
      tax =
        (total *
          (this.props.orderArray.length > 0
            ? this.props.orderArray[0].sgst
            : 0)) /
        100;
    }
    if (type === "IGST") {
      this.props.orderArray.map((record) => {
        total =
          total +
          parseFloat(
            parseFloat(
              (
                record.price +
                record.darningPrice +
                record.dyingPrice +
                record.ucPrice
              ).toFixed(2)
            ).toFixed(2)
          );
      });
      total =
        total +
        this.calculatePackagingCharges() +
        this.calculateDeliveryCharges() -
        this.props.discount;
      tax =
        (total *
          (this.props.orderArray.length > 0
            ? this.props.orderArray[0].igst
            : 0)) /
        100;
    }
    return tax;
  }
  getAddOnServices = () => {
    this.createOrderService.getAddOnServicesList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          addOnServices: {
            ...this.state.addOnServices,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_LOAD_ADD_ON_SERVICES,
          duration: 1.5,
        });
      }
    });
  };
  getRewashReason = () => {
    this.createOrderService.getRewashReason().then((response) => {
      // console.log("response->", response);
      if (response.statusCode === 200) {
        this.setState({
          rewashReason: {
            ...this.state.rewashReason,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_LOAD_REWASH_REASONS,
          duration: 1.5,
        });
      }
    });
  };
  getRewashRequestedBy = () => {
    this.createOrderService.getRewashRequestedBy().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          rewashRequestedBy: {
            ...this.state.rewashRequestedBy,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_LOAD_REWASH_REQUESTED_BY_LIST,
          duration: 1.5,
        });
      }
    });
  };

  getOpeartionInstructions = () => {
    this.createOrderService.getOpeartionInstructions().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          operationInstructions: {
            ...this.state.operationInstructions,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_LOAD_OPERATION_INSTRUCTIONS,
        });
      }
    });
  };

  getBrandsList = () => {
    this.createOrderService.getBrandsList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          brands: {
            ...this.state.brands,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_LOAD_BRANDS,
          duration: 1.5,
        });
      }
    });
  };
  getSizeList = () => {
    this.createOrderService.getSizeList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          sizes: {
            ...this.state.sizes,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_LOAD_SIZES,
          duration: 1.5,
        });
      }
    });
  };
  getDyeingColors = () => {
    this.createOrderService.getDyeingColors().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          dyeingColors: {
            ...this.state.dyeingColors,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_LOAD_COLORS,
          duration: 1.5,
        });
      }
    });
  };
  calculateFinalDeliveryDate = () => {
    let finalDeliveryDate = moment();
    this.state.dataSource.map((single) => {
      if (finalDeliveryDate.isBefore(single.deliveryDate)) {
        finalDeliveryDate = single.deliveryDate;
      }
    });
    return finalDeliveryDate;
  };

  calculatePackagingCharges = (shouldReturnTax = false) => {
    let total = 0;
    let serviceIDs = [];
    this.state.dataSource.map((record) => {
      if (!serviceIDs.includes(record.serviceId)) {
        serviceIDs.push(record.serviceId);
      }
    });
    for (let serviceId in serviceIDs) {
      if (![8, 9].includes(parseInt(serviceId))) {
        let filtered = this.props.packagingCharges.filter(
          (x) => parseInt(x.serviceID) === parseInt(serviceIDs[serviceId])
        );
        if (filtered.length > 0) {
          let filteredObj = filtered[0];
          let categorySubtotal = this.calculateSubtotal(serviceIDs[serviceId]);
          let rateCharges = (categorySubtotal * filteredObj.perCharges) / 100;
          if (shouldReturnTax) {
            total = {
              cgst: filteredObj.cgst,
              sgst: filteredObj.sgst,
              igst: filteredObj.igst,
            };
          } else {
            if (rateCharges > filteredObj.fixedCharges) {
              total = total + rateCharges;
            } else {
              total = total + filteredObj.fixedCharges;
            }
          }
        }
      } else {
        if (shouldReturnTax) {
          total = {
            cgst: 0,
            sgst: 0,
            igst: 0,
          };
        } else {
          total = 0;
        }
      }
    }
    return total;
  };
  calculateDeliveryCharges = (shouldReturnTax = false) => {
    let subtotal = this.calculateSubtotal();
    let finalCharges = 0;
    if (subtotal < this.props.deliveryCharges.minBillAmount) {
      finalCharges = this.props.deliveryCharges.fixedCharges;
    } else {
      let percentageCharges =
        (subtotal * this.props.deliveryCharges.perCharges) / 100;

      if (percentageCharges > this.props.deliveryCharges.fixedCharges) {
        finalCharges = percentageCharges;
      } else {
        finalCharges = this.props.deliveryCharges.fixedCharges;
      }
      if (finalCharges > this.props.deliveryCharges.maxCharges) {
        finalCharges = this.props.deliveryCharges.maxCharges;
      }
    }

    if (shouldReturnTax) {
      if (this.props.isFromPickup || this.props.isOnDemand) {
        return {
          cgst: this.props.deliveryCharges.cgst,
          sgst: this.props.deliveryCharges.sgst,
          igst: this.props.deliveryCharges.igst,
        };
      } else {
        return {
          cgst: 0,
          sgst: 0,
          igst: 0,
        };
      }
    } else {
      if (this.props.isFromPickup || this.props.isOnDemand) {
        return parseFloat(finalCharges.toFixed(2));
      } else {
        return 0;
      }
    }
  };
  calculateTotal = () => {
    let subtotal = this.calculateSubtotal();
    let total =
      parseFloat(subtotal.toFixed(2)) +
      parseFloat(this.calculateTaxes("CGST").toFixed(2)) +
      parseFloat(this.calculateTaxes("SGST").toFixed(2)) +
      parseFloat(this.calculateTaxes("IGST").toFixed(2)) +
      parseFloat(this.calculatePackagingCharges().toFixed(2)) +
      parseFloat(this.calculateDeliveryCharges().toFixed(2)) -
      parseFloat(parseFloat(this.props.discount).toFixed(2));
    return total.toFixed(2);
  };

  formInstanceforALTNO = React.createRef();
  formInstanceforOTP = React.createRef();
  AddAltNumberForCustomer = () => {
    if (this.state.ALTmobileNo.length > 0) {
      const postData = {
        CustomerID: parseInt(this.props.customerInfo.id),
        AlterNativeMobileNo: this.state.ALTmobileNo.toString(),
      };
      this.setState({ modalLoading: true });
      this.createOrderService
        .ADDAltNumberForCustomer(postData)
        .then((response) => {
          if (response.statusCode === 200) {
            notification["success"]({
              message: labels.ORDERS,
              description: labels.ALT_NUMBER_ADDED_SUCCESSFULLY,
            });
            this.setState({ modalLoading: false, ALTmobileNo: "" });
          } else {
            notification["error"]({
              message: labels.ORDERS,
              description: labels.UNABLE_TO_ADD_ALT_NUMBER,
            });
          }
          this.setState({ modalLoading: false, ALTmobileNo: "" });
          // this.formInstanceforALTNO.current.setFieldsValue({
          //   mobileNo: "",
          // });
        });
    } else {
      notification["error"]({
        message: labels.ORDERS,
        description: "Please Enter Alternative Mobile Number",
      });
    }
  };

  SendCustomerOTPForCreateOrder = () => {
    let Garment_Count =
      this.state.rewashMode || this.state.thisIsRewashOrder
        ? 0
        : this.state.orderSummaryDefined && !this.state.rewashMode
        ? this.props.orderSummaryObject.count
        : this.props.orderArray.length;

    let Estimated_Amount = parseFloat(
      this.state.rewashMode || this.state.thisIsRewashOrder
        ? 0
        : this.state.orderSummaryDefined && !this.state.rewashMode
        ? this.props.orderSummaryObject.total
        : this.calculateTotal()
    ).toFixed(2);

    const postData = {
      CustomerID: parseInt(this.props.customerInfo.id),
      MobileNo: this.props.customerInfo.mobileNo.toString(),
      AlterNativeMobileNo:
        this.props.customerInfo.alterNativeMobileNo.toString(),
      ISPickup: true,
      GarmentCount: Garment_Count,
      SplitOrderIDGarmentCount: "",
      Amount: Estimated_Amount,
    };
    this.setState({ modalLoading: true });
    this.createOrderService
      .SendCustomerOTPForCreateOrder(postData)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: labels.ORDERS,
            description: labels.OTP_SENT_SUCCESSFULLY,
          });
          this.setState({ modalLoading: false, disablegenerateOTP: true });
          this.setState({
            ReceivedOTP: response.data.otp,
          });
          var timeleft = 0;
          var downloadTimer = setInterval(() => {
            if (timeleft >= 30) {
              clearInterval(downloadTimer);
            }
            var time = 30 - timeleft;
            this.setState({
              TimerValue: time,
            });

            timeleft += 1;
            this.setState({
              isResendOtp: true,
            });

            if (timeleft >= 31) {
              this.setState({
                isResendOtp: false,
              });
            }
          }, 1000);
        } else {
          notification["error"]({
            message: labels.ORDERS,
            description: labels.UNABLE_TO_SENT_OTP,
          });
        }
        this.setState({ modalLoading: false });
      });
  };

  ValidateOTP = () => {
    if (this.state.ReceivedOTP != this.state.InputOTP) {
      notification["error"]({
        message: labels.ORDERS,
        description: "Please Enter Valid OTP",
      });
      this.setState({ ISVerified: false });
      this.formInstanceforOTP.current.setFieldsValue({
        OTP: "",
      });
    } else {
      notification["success"]({
        message: labels.ORDERS,
        description: "OTP Varified Successfully",
      });
      this.setState({ ISVerified: true });
      this.formInstanceforOTP.current.setFieldsValue({
        OTP: "",
      });
      this.UpdateVerifiedOTPDateTime(this.state.InputOTP);
    }
  };

  UpdateVerifiedOTPDateTime = (OTP) => {
    const postData = {
      OTP: OTP,
    };
    this.setState({ modalLoading: true });
    this.createOrderService
      .UpdateVerifiedOTPDateTime(postData)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({ modalLoading: false });
        } else {
          this.setState({ modalLoading: false });
        }
        this.setState({ modalLoading: false });
      });
  };

  confirmOrder1 = () => {
    if (ROUTES.CREATE_ORDER === this.props.match.path) {
      this.props.createOrder(
        this.state.dataSource,
        this.calculateFinalDeliveryDate()
      );
    } else if (
      [ROUTES.UPDATE_ORDER, ":orderID"].join("/") === this.props.match.path
    ) {
      this.props.updateOrderAPI(
        this.state.dataSource,
        this.calculateFinalDeliveryDate()
      );
    }
  };

  DeleteAltNumberForCustomer = () => {
    const postData = {
      CustomerID: parseInt(this.props.customerInfo.id),
      AlterNativeMobileNo: "",
    };
    this.setState({ modalLoading: true });
    this.createOrderService
      .ADDAltNumberForCustomer(postData)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: labels.ORDERS,
            description: labels.ALT_NUMBER_DELETED_SUCCESSFULLY,
          });
          this.setState({ modalLoading: false });
        } else {
          notification["error"]({
            message: labels.ORDERS,
            description: labels.UNABLE_TO_DELETE_ALT_NUMBER,
          });
        }
        this.setState({ modalLoading: false, ALTmobileNo: "" });
        this.formInstanceforALTNO.current.setFieldsValue({
          mobileNo: "",
        });
      });
  };
  render() {
    const { isShowQRCode, base64Image, isQRCodeShow } = this.state;
    const mobileNo = this.props.customerInfo.alterNativeMobileNo;

    var Cancel_order_by_admin_show = JSON.parse(
      localStorage.getItem("buttonAccess")
    ).filter((item) => {
      return item.accessName == "Cancel Order By Admin";
    });
    // console.log("access-?", localStorage.getItem("buttonAccess"));
    // console.log("Cancel_order_by_admin_show->",Cancel_order_by_admin_show);

    var Create_Order_Without_OTP = JSON.parse(
      localStorage.getItem("buttonAccess")
    ).filter((item) => {
      return item.accessName == "Create Order Without OTP";
    });

    return (
      <Box sub>
        <PageHeader
          onBack={
            parseInt(this.props.orderStatus) <= 1 &&
            parseInt(this.props.orderType) !== 2
              ? () => this.props.backFunction()
              : undefined
          }
          backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          title={labels.REVIEW_ORDER}
        />
        <Form
          layout="inline"
          ref={this.formInstance}
          onFinish={() => {
            if (ROUTES.CREATE_ORDER === this.props.match.path) {
              this.props.createOrder(
                this.state.dataSource,
                this.calculateFinalDeliveryDate(),
                false,
                false,
                true,
                this.state.InputOTP
              );
            } else if (
              [ROUTES.UPDATE_ORDER, ":orderID"].join("/") ===
              this.props.match.path
            ) {
              this.props.updateOrderAPI(
                this.state.dataSource,
                this.calculateFinalDeliveryDate()
              );
            }
          }}
        >
          <div
            key={this.state.dataSource}
            className="w-100"
            style={{ zIndex: 99 }}
          >
            <Table
              key={this.props.orderStatus}
              columns={this.state.columns}
              size="small"
              // dataSource={this.props.orderArray}
              dataSource={this.state.dataSource}
              rowKey={(record) => record.no}
              pagination={false}
              className="w-100 review-order-table"
              rowSelection={
                this.state.rewashMode
                  ? {
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows) => {
                        this.setState({ rewashData: selectedRows });
                      },
                      getCheckboxProps: (record) => {
                        let shouldDisable = false;
                        // console.log("record->", record);
                        if (record.finalQCStatus == 3 && record.price == 0)
                          shouldDisable = true;
                        else shouldDisable = false;
                        return { disabled: shouldDisable };
                      },
                    }
                  : null
              }
            />
          </div>
          <Row gutter={30} style={{ paddingTop: 30 }} className="w-100">
            <Col xs={8}>
              <Space direction="vertical" className="w-100" size="large">
                <Select
                  placeholder={labels.OPERATION_INSTRUCTION}
                  mode="multiple"
                  className="w-100"
                  value={this.props.opeartionInstruction}
                  onChange={(value) => {
                    this.props.updateOperationInstruction(value);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={this.state.disableAll}
                >
                  {this.state.operationInstructions.data.length !== 0
                    ? this.state.operationInstructions.data.map((inst) => {
                        return inst.value !== "-1" ? (
                          <Option value={inst.value} key={inst.value}>
                            {inst.text}
                          </Option>
                        ) : null;
                      })
                    : null}
                </Select>
                <Input.TextArea
                  placeholder={labels.LOGISTICS_INSTRUCTION}
                  value={this.props.logisticInstruction}
                  maxLength={200}
                  onChange={(event) => {
                    this.props.updateLogisticInstruction(event.target.value);
                  }}
                  disabled={this.state.disableAll}
                />
              </Space>
            </Col>
            <Col xs={8}>
              <Space direction="vertical" className="w-100" size="large">
                <div
                  className={styles.SummaryInfoWrap}
                  style={{ paddingTop: 16 }}
                >
                  <div className={styles.SummaryInfoLabel}>
                    {labels.DELIVERY_DATE}
                  </div>
                  <div className={styles.SummaryInfoValue}>
                    {this.state.orderSummaryDefined && !this.state.rewashMode
                      ? moment(
                          this.props.orderSummaryObject.deliveryDate
                        ).format("DD-MM-YYYY")
                      : this.calculateFinalDeliveryDate().format("DD-MM-YYYY")}
                  </div>
                </div>
                {this.props.isFromPickup ? null : (
                  <div className={styles.SummaryInfoWrap}>
                    <Checkbox
                      disabled={this.state.disableAll}
                      checked={this.props.isOnDemand}
                      onChange={(e) => {
                        this.props.changeIsOnDemand(e.target.checked);
                      }}
                    >
                      {labels.ON_DEMAND_DELIVERY}
                    </Checkbox>
                  </div>
                )}
                <Input.TextArea
                  placeholder={labels.ORDER_REMARKS}
                  value={this.props.orderRemarks}
                  maxLength={500}
                  onChange={(e) =>
                    this.props.updateOrderRemarks(e.target.value)
                  }
                  disabled={this.state.disableAll}
                />
                <Space direction="horizontal" className="w-100">
                  {parseInt(this.props.orderStatus) >= 3 &&
                  parseInt(this.props.orderStatus) < 7 ? (
                    <Button
                      variant="transparentGreyOutline"
                      onClick={this.props.printTags}
                      className="w-100"
                    >
                      {labels.REPRINT_TAGS}
                    </Button>
                  ) : null}
                  {parseInt(this.props.orderStatus) >= 2 &&
                  parseInt(this.props.orderStatus) < 7 ? (
                    <Button
                      variant="transparentGreyOutline"
                      className="w-100"
                      onClick={() =>
                        getPDFEstimatedOrder(this.props.match.params.orderID)
                      }
                    >
                      {[6, 7].includes(parseInt(this.props.orderStatus))
                        ? labels.REPRINT_TAX_INVOICE
                        : labels.REPRINT_ESTIMATED_ORDER}
                    </Button>
                  ) : null}
                </Space>
                {/* <Space direction="horizontal" className="w-100">
                  {parseInt(this.props.orderType) == 2 && parseInt(this.props.orderStatus) >= 3 &&
                  parseInt(this.props.orderStatus) < 7 ? (
                    <Button
                      variant="transparentGreyOutline"
                      onClick={this.props.rewashprintTags}
                      className="w-100"
                    >
                      Print Rewash Tags
                    </Button>
                  ) : null}
                  
                </Space> */}
              </Space>
            </Col>
            <Col xs={8}>
              <Space direction="vertical" className="w-100" size="middle">
                {this.state.rewashMode || this.state.thisIsRewashOrder ? (
                  <Form
                    layout="vertical"
                    ref={this.rewashFormInstance}
                    className={styles.RewashForm}
                  >
                    {this.state.rewashMode ? (
                      <React.Fragment>
                        <Form.Item
                          name="rewashReason"
                          rules={[{ required: true, message: labels.REQUIRED }]}
                          style={{ marginRight: 0 }}
                        >
                          <Select
                            placeholder={labels.REWASH_REASON}
                            mode="multiple"
                            className="w-100"
                            value={this.props.rewashReason}
                            onChange={(value) => {
                              this.props.updateRewashReason(value);
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={
                              !this.state.rewashMode ||
                              this.state.rewashReason.disabled
                            }
                          >
                            {this.state.rewashReason.data.length !== 0
                              ? this.state.rewashReason.data.map((inst) => {
                                  return inst.value !== "-1" ? (
                                    <Option
                                      value={inst.value.toString()}
                                      key={inst.value}
                                    >
                                      {inst.text}
                                    </Option>
                                  ) : null;
                                })
                              : null}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name="rewashRequestedBy"
                          rules={[{ required: true, message: labels.REQUIRED }]}
                          style={{ marginRight: 0 }}
                        >
                          <Select
                            placeholder={labels.REWASH_REQUESTED_BY}
                            className="w-100"
                            value={this.props.rewashRequestedBy}
                            onChange={(value) => {
                              this.props.updateRewashRequestedBy(value);
                            }}
                            disabled={
                              !this.state.rewashMode ||
                              this.state.rewashRequestedBy.disabled
                            }
                          >
                            {this.state.rewashRequestedBy.data.length !== 0
                              ? this.state.rewashRequestedBy.data.map(
                                  (inst) => {
                                    return (
                                      <Option
                                        value={inst.enumDetailID}
                                        key={inst.enumDetailID}
                                      >
                                        {inst.enumDetailName}
                                      </Option>
                                    );
                                  }
                                )
                              : null}
                          </Select>
                        </Form.Item>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {" "}
                        <Form.Item
                          // name=  "rewashReason"
                          rules={[{ required: true, message: labels.REQUIRED }]}
                          style={{ marginRight: 0 }}
                        >
                          <Select
                            placeholder={labels.REWASH_REASON}
                            mode="multiple"
                            className="w-100"
                            value={this.props.rewashReason}
                            onChange={(value) => {
                              this.props.updateRewashReason(value);
                            }}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={
                              !this.state.rewashMode ||
                              this.state.rewashReason.disabled
                            }
                          >
                            {this.state.rewashReason.data.length !== 0
                              ? this.state.rewashReason.data.map((inst) => {
                                  return inst.value !== "-1" ? (
                                    <Option
                                      value={inst.value.toString()}
                                      key={inst.value}
                                    >
                                      {inst.text}
                                    </Option>
                                  ) : null;
                                })
                              : null}
                          </Select>
                          <Form.Item
                            // name="rewashRequestedBy"
                            rules={[
                              { required: true, message: labels.REQUIRED },
                            ]}
                            style={{ marginRight: 0, marginTop: 16 }}
                          >
                            <Select
                              placeholder={labels.REWASH_REQUESTED_BY}
                              className="w-100"
                              value={this.props.rewashRequestedBy}
                              onChange={(value) => {
                                this.props.updateRewashRequestedBy(value);
                              }}
                              disabled={
                                !this.state.rewashMode ||
                                this.state.rewashRequestedBy.disabled
                              }
                            >
                              {this.state.rewashRequestedBy.data.length !== 0
                                ? this.state.rewashRequestedBy.data.map(
                                    (inst) => {
                                      return (
                                        <Option
                                          value={inst.enumDetailID}
                                          key={inst.enumDetailID}
                                        >
                                          {inst.enumDetailName}
                                        </Option>
                                      );
                                    }
                                  )
                                : null}
                            </Select>
                          </Form.Item>
                        </Form.Item>
                      </React.Fragment>
                    )}

                    {/* <Form.Item
                      name="rewashReason"
                      rules={[{ required: true, message: labels.REQUIRED }]}
                      style={{ marginRight: 0 }}
                    >

                      <Select
                        placeholder={labels.REWASH_REASON}
                        mode="multiple"
                        className="w-100"
                        value={this.props.rewashReason}
                        onChange={(value) => {
                          this.props.updateRewashReason(value);
                        }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={
                          !this.state.rewashMode ||
                          this.state.rewashReason.disabled
                        }
                      >
                        {this.state.rewashReason.data.length !== 0
                          ? this.state.rewashReason.data.map((inst) => {
                            return (
                              <Option
                                value={inst.enumDetailID.toString()}
                                key={inst.enumDetailID}
                              >
                                {inst.enumDetailName}
                              </Option>
                            );
                          })
                          : null}
                      </Select>
                    </Form.Item> */}
                    {/* <Form.Item
                      // name="rewashRequestedBy"
                      rules={[{ required: true, message: labels.REQUIRED }]}
                      style={{ marginRight: 0 }}
                    >
                      <Select
                        placeholder={labels.REWASH_REQUESTED_BY}
                        className="w-100"
                        value={this.props.rewashRequestedBy}
                        onChange={(value) => {
                          this.props.updateRewashRequestedBy(value);
                        }}
                        disabled={
                          !this.state.rewashMode ||
                          this.state.rewashRequestedBy.disabled
                        }
                      >
                        {this.state.rewashRequestedBy.data.length !== 0
                          ? this.state.rewashRequestedBy.data.map((inst) => {
                            return (
                              <Option
                                value={inst.enumDetailID}
                                key={inst.enumDetailID}
                              >
                                {inst.enumDetailName}
                              </Option>
                            );
                          })
                          : null}
                      </Select>
                    </Form.Item> */}
                  </Form>
                ) : null}

                {!this.props.isCorporate() &&
                !this.state.rewashMode &&
                this.props.orderType !== 2 ? (
                  <Input
                    prefix={<FontAwesomeIcon icon={faGift} />}
                    suffix={
                      <Button
                        variant="pink"
                        onClick={this.validatePromocode}
                        disabled={this.state.disableAll}
                      >
                        {labels.ADD}
                      </Button>
                    }
                    onPressEnter={this.validatePromocode}
                    className={styles.PromocodeInput}
                    placeholder={labels.PROMOCODE}
                    value={
                      this.props.promocode === "" ? null : this.props.promocode
                    }
                    disabled={this.state.disableAll}
                    onChange={(e) => this.props.updatePromocode(e.target.value)}
                  />
                ) : null}
                <div>
                  <div className={styles.SummaryInfoWrap}>
                    <div className={styles.SummaryInfoLabel}>
                      {labels.SUBTOTAL}
                    </div>
                    <div className={styles.SummaryInfoValue}>
                      <Tooltip
                        title={
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>Service Charges</div>
                              <div style={{ marginLeft: 5 }}>
                                ₹{" "}
                                {this.calculateSubtotal(
                                  null,
                                  "service"
                                ).toFixed(2)}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>Add-on Service Charges</div>
                              <div style={{ marginLeft: 5 }}>
                                ₹{" "}
                                {this.calculateSubtotal(null, "addon").toFixed(
                                  2
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        }
                      >
                        ₹
                        {parseFloat(
                          this.state.rewashMode || this.state.thisIsRewashOrder
                            ? 0
                            : this.state.orderSummaryDefined
                            ? this.props.orderSummaryObject.subtotal
                            : this.calculateSubtotal().toFixed(2)
                        ).toFixed(2)}
                      </Tooltip>
                    </div>
                  </div>
                  <div className={styles.SummaryInfoWrap}>
                    <div className={styles.SummaryInfoLabel}>
                      {labels.DISCOUNT}
                      <span
                        className={styles.InfoIcon}
                        onClick={() =>
                          this.setState({
                            displayDiscountInfo:
                              !this.state.displayDiscountInfo,
                          })
                        }
                      >
                        <img src={infoIcon} alt="i" />
                      </span>
                    </div>
                    <div className={styles.SummaryInfoValue}>
                      ₹{" "}
                      {parseFloat(
                        this.state.rewashMode || this.state.thisIsRewashOrder
                          ? 0
                          : this.state.orderSummaryDefined
                          ? this.props.orderSummaryObject.discount
                          : this.props.discount
                      ).toFixed(2)}
                    </div>
                  </div>
                  {this.state.displayDiscountInfo ? (
                    <div className={styles.SummaryInfoSubPart}>
                      <div className={styles.SummaryInfoWrap}>
                        <div className={styles.SummaryInfoLabel}>
                          {labels.PROMO_DISCOUNT}
                        </div>
                        <div className={styles.SummaryInfoValue}>
                          ₹{" "}
                          {parseFloat(
                            this.state.rewashMode ||
                              this.state.thisIsRewashOrder
                              ? 0
                              : this.state.orderSummaryDefined
                              ? this.props.orderSummaryObject.discount
                              : this.props.discount
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className={styles.SummaryInfoWrap}>
                    <div className={styles.SummaryInfoLabel}>
                      {labels.TAXES_AND_CHARGES}
                      <span
                        className={styles.InfoIcon}
                        onClick={() =>
                          this.setState({
                            displayTaxInfo: !this.state.displayTaxInfo,
                          })
                        }
                      >
                        <img src={infoIcon} alt="i" />
                      </span>
                    </div>
                    <div className={styles.SummaryInfoValue}>
                      ₹{" "}
                      {parseFloat(
                        this.state.rewashMode || this.state.thisIsRewashOrder
                          ? 0
                          : this.state.orderSummaryDefined
                          ? (
                              this.props.orderSummaryObject.cgst +
                              this.props.orderSummaryObject.sgst +
                              this.props.orderSummaryObject.igst +
                              this.props.orderSummaryObject.deliveryCharges +
                              this.props.orderSummaryObject.packagingCharges
                            ).toFixed(2)
                          : (
                              parseFloat(
                                this.calculateTaxes("CGST").toFixed(2)
                              ) +
                              parseFloat(
                                this.calculateTaxes("SGST").toFixed(2)
                              ) +
                              parseFloat(
                                this.calculateTaxes("IGST").toFixed(2)
                              ) +
                              parseFloat(
                                this.calculatePackagingCharges().toFixed(2)
                              ) +
                              parseFloat(
                                this.calculateDeliveryCharges().toFixed(2)
                              )
                            ).toFixed(2)
                      ).toFixed(2)}
                    </div>
                  </div>
                  {this.state.displayTaxInfo ? (
                    <div className={styles.SummaryInfoSubPart}>
                      <div className={styles.SummaryInfoWrap}>
                        <div className={styles.SummaryInfoLabel}>
                          {labels.PACKAGING_CHARGES}
                        </div>
                        <div className={styles.SummaryInfoValue}>
                          ₹{" "}
                          {parseFloat(
                            this.state.rewashMode ||
                              this.state.thisIsRewashOrder
                              ? 0
                              : this.state.orderSummaryDefined
                              ? this.props.orderSummaryObject.packagingCharges
                              : this.calculatePackagingCharges().toFixed(2)
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div className={styles.SummaryInfoWrap}>
                        <div className={styles.SummaryInfoLabel}>
                          {labels.DELIVERY_CHARGES}
                        </div>
                        <div className={styles.SummaryInfoValue}>
                          ₹{" "}
                          {parseFloat(
                            this.state.rewashMode ||
                              this.state.thisIsRewashOrder
                              ? 0
                              : this.state.orderSummaryDefined
                              ? this.props.orderSummaryObject.deliveryCharges
                              : this.calculateDeliveryCharges().toFixed(2)
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div className={styles.SummaryInfoWrap}>
                        <div className={styles.SummaryInfoLabel}>
                          {labels.CGST}
                        </div>
                        <div className={styles.SummaryInfoValue}>
                          ₹{" "}
                          {parseFloat(
                            this.state.rewashMode ||
                              this.state.thisIsRewashOrder
                              ? 0
                              : this.state.orderSummaryDefined
                              ? this.props.orderSummaryObject.cgst
                              : this.calculateTaxes("CGST").toFixed(2)
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div className={styles.SummaryInfoWrap}>
                        <div className={styles.SummaryInfoLabel}>
                          {labels.SGST}
                        </div>
                        <div className={styles.SummaryInfoValue}>
                          ₹{" "}
                          {parseFloat(
                            this.state.rewashMode ||
                              this.state.thisIsRewashOrder
                              ? 0
                              : this.state.orderSummaryDefined
                              ? this.props.orderSummaryObject.sgst
                              : this.calculateTaxes("SGST").toFixed(2)
                          ).toFixed(2)}
                        </div>
                      </div>
                      <div className={styles.SummaryInfoWrap}>
                        <div className={styles.SummaryInfoLabel}>
                          {labels.IGST}
                        </div>
                        <div className={styles.SummaryInfoValue}>
                          ₹{" "}
                          {parseFloat(
                            this.state.rewashMode ||
                              this.state.thisIsRewashOrder
                              ? 0
                              : this.state.orderSummaryDefined
                              ? this.props.orderSummaryObject.igst
                              : this.calculateTaxes("IGST").toFixed(2)
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className={styles.SummaryInfoWrap}>
                  {this.props.orderArray.length > 0 &&
                  [2, 3].includes(this.props.orderArray[0].deliveryType)
                    ? "Note : " +
                      this.props.orderArray[0].deliveryTypeName +
                      " - You have been charged " +
                      this.props.orderArray[0].rateRatio +
                      "x  of Standard price."
                    : null}
                </div>
                <div>
                  <div className={styles.TotalBlock}>
                    <span>{labels.TOTAL}</span>
                    <span>
                      {this.state.rewashMode || this.state.thisIsRewashOrder
                        ? 0
                        : this.state.orderSummaryDefined &&
                          !this.state.rewashMode
                        ? this.props.orderSummaryObject.count
                        : this.props.orderArray.length}
                    </span>
                    <span>
                      ₹{" "}
                      {parseFloat(
                        this.state.rewashMode || this.state.thisIsRewashOrder
                          ? 0
                          : this.state.orderSummaryDefined &&
                            !this.state.rewashMode
                          ? this.props.orderSummaryObject.total
                          : this.calculateTotal()
                      ).toFixed(2)}
                    </span>
                  </div>

                  {ROUTES.CREATE_ORDER === this.props.match.path &&
                  Create_Order_Without_OTP.length > 0 &&
                  parseInt(this.props.orderStatus) < 2 ? (
                    <div
                      style={{
                        // display: "flex",
                        // justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                    >
                      <text style={{ color: "red" }}>
                        Note: Cross check the order details before confirming.
                      </text>

                      {/* <Popconfirm // Comment For KG issue.-L16811
                        title={labels.ARE_YOU_SURE}
                        onConfirm={this.confirmOrder1}
                        onCancel={() => null}
                        okText={labels.YES}
                        cancelText={labels.NO}
                      > */}
                      <Button
                        variant="darkpurple"
                        style={{ marginTop: 5 }}
                        size="large"
                        block
                        htmlType="submit"
                      >
                        {labels.CONFIRM_ORDER}
                      </Button>
                      {/* </Popconfirm> */}
                    </div>
                  ) : null}

                  {/* Added BY HT - Start */}
                  {ROUTES.CREATE_ORDER === this.props.match.path &&
                  Create_Order_Without_OTP.length === 0 ? (
                    <div>
                      <div className={styles.SummaryInfoWrap}>
                        <b>Request OTP</b>
                      </div>
                      <div className={styles.SummaryInfoWrap}>
                        <text>Registered number </text>
                        <text>{this.props.customerInfo.mobileNo}</text>
                      </div>

                      {/* Comment By Hasti - L16815 */}
                      {/* <div
                        style={{
                          paddingRight: 20,
                          paddingLeft: 20,
                          marginTop: 10,
                        }}
                      >
                        <Form
                          style={{ display: "block" }}
                          ref={this.formInstanceforALTNO}
                          initialValues={{ mobileNo }}
                        >
                          <Form.Item
                            name="mobileNo"
                            validateFirst={true}
                            rules={[
                              {
                                pattern: "[6789][0-9]{9}",
                                message: labels.PLEASE_ENTER_VALID_PHONE_NUMBER,
                              },
                            ]}
                          >
                            <Input
                              suffix={
                                <div
                                  style={{
                                    display: "flex",
                                    flexdirection: "row",
                                  }}
                                >
                                  <div
                                    style={{ marginRight: 5, marginTop: 10 }}
                                  >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                    <Popconfirm
                                      title="Are you sure want to delete Alternative number ?"
                                      onConfirm={
                                        this.DeleteAltNumberForCustomer
                                      }
                                      onCancel={() => null}
                                      okText="Yes"
                                      cancelText="No"
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ marginLeft: 10 }}
                                      />
                                    </Popconfirm>
                                  </div>
                                  <div>
                                    <Button
                                      variant="pink"
                                      onClick={this.AddAltNumberForCustomer}
                                      disabled={
                                        !this.state.ALTmobileNo.length > 0
                                      }
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </div>
                              }
                              className={styles.PromocodeInput}
                              placeholder="Alternative Number"
                              // value={
                              //   this.props.customerInfo.altmobileNo === ""
                              //     ? null
                              //     :this.props.customerInfo.altmobileNo
                              // }
                              onChange={(e) =>
                                this.setState({
                                  ALTmobileNo: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                        </Form>
                      </div> */}

                      <Button
                        // htmlType="submit"
                        onClick={this.SendCustomerOTPForCreateOrder}
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          width: "89%",
                          marginLeft: 21,
                        }}
                        disabled={this.state.disablegenerateOTP}
                      >
                        <div style={{ fontSize: 18 }}>
                          <span>{labels.GENERATE_OTP}</span>
                        </div>
                      </Button>
                      <text style={{ fontSize: 12, marginLeft: 20 }}>
                        OTP will be sent to Registered mobile number
                      </text>
                      {/* <text style={{ fontSize: 12 }}> 
                        OTP will be sent to both Registered and Alternative
                        mobile number
                      </text> */}
                      <div
                        style={{
                          paddingRight: 20,
                          paddingLeft: 20,
                          marginTop: 10,
                          // marginBottom: 2,
                        }}
                      >
                        <Form
                          style={{ display: "block" }}
                          ref={this.formInstanceforOTP}
                        >
                          <Form.Item name="OTP" validateFirst={true}>
                            <Input
                              onPressEnter={this.ValidateOTP}
                              className={styles.PromocodeInput}
                              placeholder="Enter OTP"
                              value={
                                this.state.InputOTP === ""
                                  ? null
                                  : this.state.InputOTP
                              }
                              onChange={(e) =>
                                this.setState({
                                  InputOTP: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                        </Form>
                      </div>
                      <div
                        style={{
                          paddingRight: 42,
                          paddingLeft: 20,
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: -8,
                        }}
                      >
                        <Button
                          type="link"
                          onClick={this.SendCustomerOTPForCreateOrder}
                          // disabled={this.state.isResendOtp}
                          disabled={
                            !this.state.disablegenerateOTP
                              ? true
                              : this.state.isResendOtp
                              ? true
                              : false
                          }
                        >
                          <text style={{ textDecoration: "underline" }}>
                            Resend OTP
                          </text>
                        </Button>
                        <text style={{ marginTop: 8, fontWeight: "bold" }}>
                          0:{this.state.TimerValue}
                        </text>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 10,
                        }}
                      >
                        {/* <Popconfirm // Comment For KG issue.-L16811
                          title={labels.ARE_YOU_SURE}
                          onConfirm={this.confirmOrder1}
                          onCancel={() => null}
                          okText={labels.YES}
                          cancelText={labels.NO}
                        > */}
                        <Button
                          style={{ width: "40%", marginLeft: 20 }}
                          variant="pink"
                          size="small"
                          block
                          disabled={!this.state.ISVerified}
                          htmlType="submit"
                        >
                          {labels.CONFIRM_ORDER}
                        </Button>
                        {/* </Popconfirm> */}
                        <Button
                          onClick={() => {
                            this.formInstanceforOTP.current.setFieldsValue({
                              OTP: "",
                            });
                          }}
                          style={{ width: "40%", marginRight: 20 }}
                          variant="pink"
                          size="small"
                          block
                        >
                          {labels.CANCEL}
                        </Button>
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <text style={{ color: "red" }}>
                          Note: Cross check the order details before confirming.
                        </text>
                      </div>
                    </div>
                  ) : null}
                  {/* End */}

                  {/* Comment By HT for Change in Create ORder Flow */}
                  {/* {ROUTES.CREATE_ORDER === this.props.match.path ? (
                    <Button
                      className={[styles.ReviewOrderButton, "w-100"].join(" ")}
                      htmlType="submit"
                    >
                      <div>
                        <span>{labels.PLACE_ORDER}</span>
                        <span>₹ {this.calculateTotal()}</span>
                      </div>
                    </Button>
                  ) : null} */}

                  {/* {[ROUTES.UPDATE_ORDER, ":orderID"].join("/") ===
                    this.props.match.path &&
                  [undefined, null, ""].includes(this.props.view) ? (
                    ![6, 7].includes(parseInt(this.props.orderStatus)) ? (
                      <Button
                        type="link"
                        variant="link"
                        onClick={() =>
                          this.setState({ showAdvancePaymentPopup: true })
                        }
                        style={{ textAlign: "center" }}
                        block
                      >
                        {labels.COLLECT_ADVANCE_PAYMENT}
                      </Button>
                    ) : null
                  ) : null} */}

                  {[ROUTES.UPDATE_ORDER, ":orderID"].join("/") ===
                    this.props.match.path &&
                  [undefined, null, ""].includes(this.props.view) ? (
                    <React.Fragment>
                      {parseInt(this.props.orderStatus) === 1 ? (
                        <React.Fragment>
                          <Button
                            className={[styles.ReviewOrderButton, "w-100"].join(
                              " "
                            )}
                            htmlType="submit"
                            disabled={this.disableUpdateOrder()}
                          >
                            {this.state.thisIsRewashOrder ? (
                              labels.UPDATE_ORDER
                            ) : (
                              <div>
                                <span>{labels.UPDATE_ORDER}</span>

                                <span>₹ {this.calculateTotal()}</span>
                              </div>
                            )}
                          </Button>
                          {
                            <Popconfirm
                              title={labels.ARE_YOU_SURE}
                              onConfirm={this.confirmOrder}
                              onCancel={() => null}
                              okText={labels.YES}
                              cancelText={labels.NO}
                            >
                              <Button
                                variant="pink"
                                size="large"
                                style={{ marginTop: 0 , }}
                                className={styles.ReviewOrderButton}
                                block
                                disabled={this.disableUpdateOrder()}
                                // loading={this.state.isConfirmBtnLoading}
                              >
                                {labels.CONFIRM_ORDER}
                              </Button>
                              <div style={{ marginLeft: 20, marginTop:-10,marginBottom:10}}>
                                <text style={{ color: "red" }}>
                                  Note: Cross check the order details before
                                  confirming.
                                </text>
                              </div>
                            </Popconfirm>
                          }
                        </React.Fragment>
                      ) : null}
                      {parseInt(this.props.orderStatus) === 2 ? (
                        <React.Fragment>
                          <Popconfirm
                            title={labels.ARE_YOU_SURE}
                            onConfirm={this.props.generateTags}
                            onCancel={() => null}
                            okText={labels.YES}
                            cancelText={labels.NO}
                          >
                            <Button
                              variant="darkpurple"
                              style={{ marginTop: 0 }}
                              size="large"
                              className={styles.ReviewOrderButton}
                              block
                            >
                              {labels.TAG_IN_GARMENTS}
                            </Button>
                          </Popconfirm>
                        </React.Fragment>
                      ) : null}
                      {parseInt(this.props.orderStatus) < 2 ? (
                        <Popconfirm
                          title={labels.ARE_YOU_SURE}
                          onConfirm={this.props.cancelOrder}
                          onCancel={() => null}
                          okText={labels.YES}
                          cancelText={labels.NO}
                        >
                          <Button
                            variant="purpleOutline"
                            style={{ marginTop: 0 }}
                            size="large"
                            className={styles.ReviewOrderButton}
                            block
                          >
                            {labels.CANCEL_ORDER}
                          </Button>
                        </Popconfirm>
                      ) : null}

                      {parseInt(this.props.orderStatus) === 5 ||
                      parseInt(this.props.orderStatus) === 6 ? (
                        !this.state.rewashMode ? (
                          <React.Fragment>
                            <Button
                              variant="pink"
                              size="large"
                              style={{ marginTop: 0 }}
                              className={styles.ReviewOrderButton}
                              block
                              htmlType="button"
                              onClick={this.setRewashMode}
                            >
                              {labels.REWASH_ORDER}
                            </Button>
                          </React.Fragment>
                        ) : (
                          <Popconfirm
                            title={labels.ARE_YOU_SURE}
                            onConfirm={this.createRewashOrder}
                            onCancel={() => null}
                            okText={labels.YES}
                            cancelText={labels.NO}
                          >
                            <Button
                              variant="darkPurple"
                              size="large"
                              style={{ marginTop: 0 }}
                              className={styles.ReviewOrderButton}
                              block
                              htmlType="button"
                              onClick={() => null}
                              disabled={this.state.rewashData.length == 0}
                            >
                              {labels.CREATE_REWASH_ORDER}
                            </Button>
                          </Popconfirm>
                        )
                      ) : null}
                      {/* localStorage
                        .getItem("roleNames")
                        .split(",")
                        .indexOf("Admin") > -1
                      */}

                      {Cancel_order_by_admin_show.length > 0 &&
                      this.props.AllowCancelOrder ? (
                        // parseInt(this.props.orderStatus) < 4 ?  -- Comment this line for-L17101
                        <Popconfirm
                          title={labels.ARE_YOU_SURE}
                          onConfirm={this.props.cancelOrder}
                          onCancel={() => null}
                          okText={labels.YES}
                          cancelText={labels.NO}
                        >
                          <Button
                            variant="purpleOutline"
                            style={{ marginTop: 0 }}
                            size="large"
                            className={styles.ReviewOrderButton}
                            block
                          >
                            {labels.CANCEL_ORDER_BY_ADMIN}
                          </Button>
                        </Popconfirm>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </div>
              </Space>
            </Col>
          </Row>
        </Form>
        <Modal
          // width={600}
          title={labels.COLLECT_ADVANCE_PAYMENT}
          visible={this.state.showAdvancePaymentPopup}
          footer={null}
          onCancel={() => this.setState({ showAdvancePaymentPopup: false })}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.modalLoading}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1 style={{ margin: 0 }}>{this.props.customerInfo.name}</h1>
              <div className={styles.WalletBalance}>
                <img src={WalletIcon} alt="wallet" />
                <span style={{ marginLeft: 5 }}>
                  ₹{" "}
                  {this.props.customerInfo.walletBalance === ""
                    ? 0
                    : this.props.customerInfo.walletBalance}
                </span>
              </div>
            </div>

            <Form
              layout="vertical"
              onFinish={this.CheckPaytmEDCMachineTransData}
              initialValues={{ paymentMode: 1 }}
            >
              <Row gutter={30} style={{ marginTop: 30 }}>
                <Col xs={16}>
                  <Form.Item name="paymentMode" label={labels.PAYMENT_METHOD}>
                    <Select
                      placeholder={labels.PAYMENT_METHOD}
                      disabled={this.state.paymentMethods.disabled}
                      options={this.state.paymentMethods.data.filter(
                        (x) => x.label.toLowerCase() !== "lk wallet"
                      )}
                      value={this.state.paymentMethods.selected}
                      onChange={(value) =>
                        this.setState({
                          paymentMethods: {
                            ...this.state.paymentMethods,
                            selected: value,
                          },
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <div className={styles.advanceAmountInput}>
                    <Form.Item
                      name="amount"
                      label={labels.AMOUNT}
                      rules={[
                        {
                          required: true,
                          message: labels.AMOUNT_IS_REQUIRED,
                        },
                        {
                          min: 0.01,
                          type: "number",
                          message: labels.AMOUNT_SHOULD_BE_GREATER_THAN_0,
                        },
                      ]}
                    >
                      <InputNumber precision={2} placeholder={0} />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24}>
                  <Form.Item name="remarks" label={labels.REMARKS}>
                    <Input.TextArea placeholder={labels.REMARKS} />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    <React.Fragment>
                      <Button
                        variant="transparentGreyOutline"
                        onClick={() =>
                          this.setState({ showAdvancePaymentPopup: false })
                        }
                      >
                        {labels.CANCEL}
                      </Button>
                      <Button htmlType="submit" variant="purple">
                        {labels.COLLECT}
                      </Button>
                    </React.Fragment>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        <Modal
          title={labels.PROCESSING}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          maskClosable={false}
        >
          <Form layout="vertical" onFinish={this.handlePopupAdd}>
            <Row gutter={24}>
              <Col xs={24}>{labels.PROCESSING}.....</Col>
            </Row>
          </Form>
        </Modal>

        {isShowQRCode ? (
          <Modal
            title={"QR Code"}
            visible={isQRCodeShow}
            footer={null}
            onCancel={this.handlePopupCancel}
            className="modal-form"
            destroyOnClose
          >
            <Spin spinning={false}>
              <Row gutter={24}>
                <Col xs={24}>
                  <div style={{ marginLeft: "7%" }}>
                    <img
                      src={"data:image/jpeg;base64," + base64Image}
                      alt
                      style={{ width: 400 }}
                    />
                  </div>
                </Col>
              </Row>
            </Spin>
          </Modal>
        ) : null}
      </Box>
    );
  }

  disableUpdateOrder = () => {
    var orderArrayLength =
      this.props.orderArray.length > 0
        ? this.props.orderArray.filter(
            (x) =>
              !x.serviceIsAddOn ||
              (parseInt(x.serviceId) != 8 && parseInt(x.serviceId) != 9)
          ).length
        : 0;
    return orderArrayLength > 0 ? false : true;
  };

  handlePopupCancel = () => {
    confirm({
      title: "Do you want to kill these payment process?",
      content: "When clicked the OK button, Stop the payment process",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 3000);
          setTimeout(function () {
            window.location.reload();
          }, 4000);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };
}

export default withRouter(ReviewOrder);
