import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../../Common/Styles/master.module.css";
import { PageHeader, Form, notification, Spin, Steps, Space } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import GeneralForm from "./general.addcustomer";
import Addresses from "./addresses.addcustomer";
import Verification from "./verification.addcustomer";
import InvoiceService from "../../../../services/invoiceservice";

import CustomerService from "../../../../services/customerservice";
import ROUTES from "../../../../routes";
import { labels } from "../../../../shared/language";
import Common from "../../../../services/common";
import { IsAuthenticated } from "../../../../services/auth-header";
import UserRightsConfiguration from "../../../../shared/userrightsconfiguration";
const { Step } = Steps;

/**
 * Add Customer Form as a part of Customer Master Module
 *
 * @author Niraj Gohel
 * @created 25 June 2020
 *
 * @module Master
 */
class AddCustomer extends React.Component {
  customerService = new CustomerService();
  invoiceService = new InvoiceService();
  common = new Common();
  UserRightsConfiguration = new UserRightsConfiguration();
  /**
   * State Variables
   * @var customerID : ID of customer 0 if this is to add factory
   * @var activeStep : holds active form step
   * @var generalFormValues : values submitted through generalForm
   */
  state = {
    activeStep: 0,
    customerID: 0,
    fullLoading: false,
    generalFormValues: {
      gender: "1",
      isActive: true,
      isLaundrokartEmployee: false,
      customerType: "1",
    },
    mode: "add",
    stepsDirection: "vertical",
    stepsSize: "default",
    mobileOTPSent: false,
    emailOTPSent: false,
    walletCash: 0,
    walletBonus: 0,
  };
  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "small" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };
  /**
   * bind the setStepDirection to the resize event of windows
   */

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.CUSTOMER
    );
    // this.setState({ userRights: res });

    if (
      ["/master/customer/edit", ":customerID"].join("/") ===
        this.props.match.path &&
      [false, undefined, null, ""].includes(res[0].isUpdate) &&
      [undefined, null, ""].includes(this.props.location.state)
    ) {
      this.props.history.goBack();
    }

    if (
      "/master/customer/add" === this.props.match.path &&
      [false, undefined, null, ""].includes(res[0].isAdd) &&
      [undefined, null, ""].includes(this.props.location.state)
    ) {
      this.props.history.goBack();
    }

    // if ([false, undefined, null, ''].includes(res[0].isAdd) && [undefined, null, ''].includes(this.props.location.state)) {
    //   this.props.history.goBack();
    // }
    // isUpdate
  }

  componentDidMount() {
    if (![undefined, null, ""].includes(this.props.location.state)) {
      if (this.props.location.state.from != 0) {
        this.setState({ customerID: this.props.location.state.from });
        this.getCustomer(this.props.location.state.from);
        this.getWalletBalance(this.props.location.state.from);
        this.setState({ fullLoading: true });
        this.setState({ mode: "edit" });
        this.setState({ activeStep: 1 });
      }
      // else {
      //   this.props.history.goBack();
      // }
    } else {
      if (
        [ROUTES.EDIT_CUSTOMER, ":customerID"].join("/") ===
        this.props.match.path
      ) {
        this.setState({ fullLoading: true });
        this.setState({ mode: "edit" });
        this.getCustomer(this.props.match.params.customerID);
        this.getWalletBalance(this.props.match.params.customerID);
      } else if (
        [ROUTES.VIEW_CUSTOMER, ":customerID"].join("/") ===
        this.props.match.path
      ) {
        this.setState({ fullLoading: true });
        this.setState({ mode: "view" });
        this.getCustomer(this.props.match.params.customerID);
        this.getWalletBalance(this.props.match.params.customerID);
      } else if (
        [ROUTES.VIEW_ADDRESSES, ":customerID"].join("/") ===
        this.props.match.path
      ) {
        this.getCustomer(this.props.match.params.customerID);
        this.setState({ fullLoading: true });
        this.setState({ mode: "address" });
        this.setState({ activeStep: 1 });
      }
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
  }

  getCustomer = (id) => {
    this.customerService.getCustomer(id).then((response) => {
      if (
        response.statusCode === 200 &&
        response.data.customerID === parseInt(id)
      ) {
        this.setState({ customerID: response.data.customerID });
        this.setState({
          generalFormValues: {
            firstName: response.data.firstName.toString(),
            lastName: response.data.lastName.toString(),
            emailID: ![null, undefined, ""].includes(response.data.emailID)
              ? response.data.emailID.toString()
              : undefined,
            mobileNo: response.data.mobileNo.toString(),
            alterNativeMobileNo: ![null, undefined, ""].includes(
              response.data.altMobileNo
            )
              ? response.data.altMobileNo.toString()
              : undefined,
            referralCode: response.data.referralCode.toString(),
            customerType: response.data.customerType.toString(),
            billingCycle: response.data.billingCycle.toString(),
            gender: response.data.gender.toString(),
            gstin: response.data.gstin.toString(),
            corporateID: response.data.corporateID.toString(),
            dob: response.data.dob ? moment(response.data.dob) : "",
            annivesaryDate: response.data.annivesaryDate
              ? moment(response.data.annivesaryDate)
              : "",
            isActive: response.data.isActive,
            isLaundrokartEmployee: response.data.isLaundrokartEmployee,
          },
        });
        this.setState({ fullLoading: false });
        const prevStep = this.state.activeStep;
        this.setState({ activeStep: -1 });
        this.setState({ activeStep: prevStep });
      } else {
        notification["error"]({
          message: "Customer Master",
          description: response.message
            ? this.common.Message(response.message)
            : "Unable to get customer data",
          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
    });
  };
  addUpdateCustomer = () => {
    this.setState({ fullLoading: true });
    const postData = {
      customerID: this.state.customerID,
      firstName: this.state.generalFormValues.firstName.toString(),
      lastName: ![null, undefined, ""].includes(
        this.state.generalFormValues.lastName
      )
        ? this.state.generalFormValues.lastName.toString()
        : undefined,
      emailID: ![null, undefined, ""].includes(
        this.state.generalFormValues.emailID
      )
        ? this.state.generalFormValues.emailID.toString()
        : undefined,
      mobileNo: this.state.generalFormValues.mobileNo.toString(),
      AlterNativeMobileNo:![null, undefined, ""].includes( this.state.generalFormValues.alterNativeMobileNo)?
        this.state.generalFormValues.alterNativeMobileNo.toString():null,
      referralCode: this.state.generalFormValues.referralCode
        ? this.state.generalFormValues.referralCode.toString()
        : null,
      customerType: parseInt(this.state.generalFormValues.customerType),
      billingCycle: parseInt(this.state.generalFormValues.billingCycle),
      gender: parseInt(this.state.generalFormValues.gender),
      gstin: this.state.generalFormValues.gstin
        ? this.state.generalFormValues.gstin.toString()
        : null,
      corporateID: parseInt(this.state.generalFormValues.corporateID),
      dob: this.state.generalFormValues.dob
        ? this.state.generalFormValues.dob.format("YYYY-MM-DD")
        : null,
      annivesaryDate: this.state.generalFormValues.annivesaryDate
        ? this.state.generalFormValues.annivesaryDate.format("YYYY-MM-DD")
        : null,
      isActive: ![null, undefined].includes(
        this.state.generalFormValues.isActive
      )
        ? this.state.generalFormValues.isActive
        : null,
      isLaundrokartEmployee: this.state.generalFormValues.isLaundrokartEmployee,
    };
    this.customerService.addUpdateCustomer(postData).then((response) => {
      if (response.statusCode === 200) {
        this.setState({ customerID: response.data });
        notification["success"]({
          message: "Customer Master",
          description: response.message
            ? this.common.Message(response.message)
            : "Customer Created Successfully",
          duration: 1,
          onClose: () => {
            this.setState({ activeStep: 1 });
          },
        });
      } else {
        notification["error"]({
          message: "Customer Master",
          description: response.message
            ? this.common.Message(response.message)
            : "Unable to submit the form",
        });
      }
      this.setState({ fullLoading: false });
    });
  };

  getWalletBalance = (id) => {
    this.invoiceService
      .getWalletBalance({
        customerID: parseInt(id),
      })
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            walletCash: response.data.totalCashAmount,
            walletBonus: response.data.totalBonusAmount,
          });
        }
      });
  };

  generalForm = React.createRef();
  handleSubmit = (values) => {
    this.setState(
      {
        generalFormValues: values,
      },
      () => {
        this.addUpdateCustomer();
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          {/**
           * Page Header Component from Ant Design
           * Please check below link for more options
           * https://ant.design/components/page-header/
           * https://ant.design/components/page-header/#API
           *
           */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? labels.EDIT
                : this.state.mode === "view"
                ? labels.VIEW
                : labels.ADD,
              labels.CUSTOMER,
            ].join(" ")}
            extra={[
              this.state.mode === "edit" || this.state.mode === "view" ? (
                <Space size={30}>
                  <span>
                    {labels.WALLET_CASH} : ₹ {this.state.walletCash}
                  </span>
                  <span>
                    {labels.WALLET_BONUS} : ₹ {this.state.walletBonus}
                  </span>
                </Space>
              ) : null,
            ]}
          />

          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              {/**
               * Steps Component from Ant Design
               * Please check below link for more options
               * https://ant.design/components/steps/
               * https://ant.design/components/steps/#API
               *
               */}
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => {
                  if (this.state.customerID === 0) {
                    return false;
                  } else {
                    this.setState({ activeStep: current });
                  }
                }}
              >
                <Step
                  title={labels.GENERAL}
                  description={labels.GENERAL_CUSTOMER_INFORMATION}
                />
                <Step
                  title={labels.ADDRESS}
                  description={labels.CUSTOMER_ADDRESSES}
                />
                <Step
                  title={labels.VERIFICATION}
                  description={labels.VERIFY_PHONE_AND_EMAIL}
                />
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider
                onFormFinish={(name, info) => {
                  if (name === "general") {
                    this.handleSubmit(info.values);
                  }
                }}
              >
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading} style={{ height: "100%" }}>
                  <React.Fragment>
                    <GeneralForm
                      values={this.state.generalFormValues}
                      backFunction={() => this.props.history.goBack()}
                      isActive={this.state.activeStep === 0}
                      instance={this.generalForm}
                      mode={this.state.mode}
                    />
                    {this.state.activeStep === 1 ? (
                      <Addresses
                        mode={this.state.mode}
                        customerID={this.state.customerID}
                      />
                    ) : null}
                    {this.state.activeStep === 2 ? (
                      <Verification
                        customerID={this.state.customerID}
                        isActive={this.state.activeStep === 2}
                        mobileOTPSent={this.state.mobileOTPSent}
                        setMobileOTPSent={() =>
                          this.setState({ mobileOTPSent: true })
                        }
                        mode={this.state.mode}
                      />
                    ) : null}
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(AddCustomer);
