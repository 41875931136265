import React from "react";
import styles from "../Common/Styles/master.module.css";
import Button from "../../components/Common/Button/button";
import Filters from "../../components/Common/Filters/filters";
import TableActions from "../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Tooltip,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import PaytmEDCMachineConfigService from "../../services/paytmedcmachineconfigservice";
import Moment from "moment";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import {
  RegexLettersDigitsUnderScoreHyphen,
  MessageLettersDigitsUnderScoreHyphen,
} from "../../shared/validator";
import DiscountMasterService from "../../services/discountmasterservice";

const { Search } = Input;
const { Option } = Select;

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class PaytmEDCMachineConfig extends React.Component {
  constructor(props) {
    super(props);
    this.paytmEDCConfigService = new PaytmEDCMachineConfigService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.discountmasterservice = new DiscountMasterService();

    this.state = {
      modalLoading: false,
      paytmEDCConfigItems: [],
      edcInfoId: 0,
      platformList: [
        {
          key: 1,
          value: "web",
        },
        {
          key: 2,
          value: "android",
        },
      ],
      paytmMachineID: "",
      paytmTerminalID: "",
      VersionNo: "",
      MachineKey: "",
      serialNumber: "",
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      selectedPlatform: "",
      applicableStoreIds: {
        disabled: true,
        data: [],
      },
      UserList: {
        disabled: true,
        data: [],
      },
      selectedUser: "",
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.PAYTM_EDC_MACHINE_CONFING
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }

    this.setState({ userRights: res });
  }

  columns = [
    // {
    //  title: 'edcInfoId',
    //   dataIndex: 'edcInfoId',
    //   key: 'edcInfoId',
    // },
    {
      title: "Paytm Machine id",
      dataIndex: "paytmMid",
      key: "paytmMid",
      sorter: true,
    },
    {
      title: "Paytm Terminal id",
      dataIndex: "paytmTid",
      key: "paytmTid",
      sorter: true,
    },
    {
      title: "Machine Key",
      dataIndex: "machineKey",
      key: "machineKey",
      sorter: true,
    },
    {
      title: "Version No",
      dataIndex: "versionNumber",
      key: "versionNumber",
      sorter: true,
    },
    // {
    //   title: "Store/User ID",
    //   dataIndex: "id",
    //   key: "id",
    //   sorter: true,
    // },
    {
      title: "Store/User Name",
      dataIndex: "storeOrUserName",
      key: "storeOrUserName",
      sorter: true,
    },
    {
      title: "Serial Number",
      dataIndex: "serialNumber",
      key: "serialNumber",
      sorter: true,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    edcInfoId: record.edcInfoId,
                    paytmMachineID: record.paytmMid,
                    paytmTerminalID: record.paytmTid,
                    VersionNo: record.versionNumber,
                    MachineKey: record.machineKey,
                    selectedPlatform: record.platform,
                    selectedStore: record.storeOrUserName,
                    selectedUser: record.storeOrUserName,
                    serialNumber: record.serialNumber,
                    isEnabled: true,
                    isVisible: true,
                    showAddPopup: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}

          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() => {
                  this.setState({
                    edcInfoId: record.edcInfoId,
                    paytmMachineID: record.paytmMid,
                    paytmTerminalID: record.paytmTid,
                    VersionNo: record.versionNumber,
                    MachineKey: record.machineKey,
                    selectedPlatform: record.platform,
                    selectedStore: record.storeOrUserName,
                    selectedUser: record.storeOrUserName,
                    serialNumber: record.serialNumber,
                    showAddPopup: true,
                  });
                  if (record.platform === "web") {
                    this.getStores();
                  } else {
                    this.getActiveUserList();
                  }
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.edcInfoId)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table pagination when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  getStores = () => {
    this.discountmasterservice.getStoresList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          applicableStoreIds: {
            ...this.state.applicableStoreIds,
            data: response.data,
            disabled: false,
          },
          selectedUser: undefined,
        });
      } else {
        notification["error"]({
          message: "Unable to get data for applicable stores",
        });
      }
    });
  };

  getActiveUserList = () => {
    this.paytmEDCConfigService.getActiveUserList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          UserList: {
            ...this.state.UserList,
            data: response.data,
            disabled: false,
          },
          selectedStore: undefined,
        });
      } else {
        notification["error"]({
          message: "Unable to get data for applicable stores",
        });
      }
    });
  };

  fetchItems = (params = {}) => {
    this.paytmEDCConfigService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          paytmEDCConfigItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Paytm EDC Machine Configuration",
          description: items.message
            ? items.message
            : "Unable to get list of state data",
        });
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (edcInfoId) => {
    if (edcInfoId === "" || edcInfoId === null || edcInfoId === undefined) {
      // openNotificationWithIcon(
      //   "error",
      //   "State Master",
      //   "Please Select Atleast One Row"
      // );
      notification["error"]({
        message: "Paytm EDC Machine Configuration",
        description: "Please Select Atleast One Row",
      });
    } else {
      this.paytmEDCConfigService.removeItems(edcInfoId).then((items) => {
        if (items.result === "Success") {
          // openNotificationWithIcon("success", "State Master", items.message);
          notification["success"]({
            message: "Paytm EDC Machine Configuration",
            description: items.message,
          });
          const { pagination } = this.state;
          this.fetchItems({
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
          });
        } else {
          // openNotificationWithIcon("error", "State Master", items.message);
          notification["error"]({
            message: "Paytm EDC Machine Configuration",
            description: items.message,
          });
        }
      });
    }
  };

  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    let res = this.state.applicableStoreIds.data.filter(
      (x) => x.text === this.state.selectedStore
    );

    if (this.state.selectedPlatform !== "web") {
      res = this.state.UserList.data.filter(
        (x) => x.text === this.state.selectedUser
      );
    }

    var postData = {
      edcInfoId: this.state.edcInfoId,
      paytmMid: this.state.paytmMachineID,
      paytmTid: this.state.paytmTerminalID,
      machineKey: this.state.MachineKey,
      versionNumber: this.state.VersionNo,
      id: res.length > 0 ? parseInt(res[0].value) : 0,
      platform: this.state.selectedPlatform,
      serialNumber: this.state.serialNumber,
    };
    this.setState({ modalLoading: true });
    this.paytmEDCConfigService.insertUpdateItems(postData).then((items) => {
      if (items.result === "Success") {
        // openNotificationWithIcon("success", "State Master", items.message);
        notification["success"]({
          message: "Paytm EDC Machine Configuration",
          description: items.message,
        });
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        // openNotificationWithIcon("error", "State Master", items.message);
        notification["error"]({
          message: "Paytm EDC Machine Configuration",
          description: items.message,
        });
      }
      this.setState({ modalLoading: false });
    });
  };

  handleInputOnChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  //function for clearing out the states
  resetFields = () => {
    this.setState({
      edcInfoId: 0,
      //   stateID: 0,
      paytmMachineID: "",
      paytmTerminalID: "",
      VersionNo: "",
      MachineKey: "",
      selectedPlatform: "",
      selectedUser: "",
      selectedStore: "",
      serialNumber: "",
      isEnabled: false,
      isVisible: false,
    });
  };

  HandleApplicatoinDDL = (id, key) => {
    let res = [];
    if (key === "user") {
      res = this.state.UserList.data.filter((x) => x.value === id);
      this.setState({
        selectedUser: res.length > 0 ? res[0].text : 0,
        //  selectedStore: undefined,
      });
    } else {
      res = this.state.applicableStoreIds.data.filter((x) => x.value === id);
      this.setState({
        selectedStore: res.length > 0 ? res[0].text : 0,
        // selectedUser: undefined,
      });
    }
  };

  render() {
    const {
      paytmMachineID,
      paytmTerminalID,
      VersionNo,
      MachineKey,
      serialNumber,
      pagination,
      loading,
    } = this.state;
    const paytmEDCConfigItems = this.state.paytmEDCConfigItems;

    const listItems = paytmEDCConfigItems.map((item, index) => ({
      key: item.edcInfoId,
      edcInfoId: item.edcInfoId,
      paytmMid: item.paytmMid,
      paytmTid: item.paytmTid,
      machineKey: item.machineKey,
      versionNumber: item.versionNumber,
      id: item.id,
      storeOrUserName: item.storeOrUserName,
      platform: item.platform,
      serialNumber: item.serialNumber,
      isActive: item.isActive.toString(),
      createdBy: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD/MM/YYYY hh:mm:ss a"),
      totalRecords: item.totalRecords,
    }));
    const store = this.state.selectedStore;
    const users = this.state.selectedUser;
    return (
      <div>
        <PageHeader
          title="Paytm EDC Machine Configuration"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                Add Paytm EDC Machine Configuration
              </Button>
            ) : null,

            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            paytmEDCConfigItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>

        <Modal
          title={
            this.state.edcInfoId === 0
              ? "Add Paytm EDC Machine Configuration"
              : this.state.isEnabled
              ? "View Paytm EDC Machine Configuration"
              : "Edit Paytm EDC Machine Configuration"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          destroyOnClose
          className="modal-form"
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                paytmMachineID,
                paytmTerminalID,
                VersionNo,
                MachineKey,
                serialNumber,
                platform: this.state.selectedPlatform,
                store,
                users,
              }}
              key={store + users}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Paytm Machine ID"
                    name="paytmMachineID"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Paytm Machine ID",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Paytm Machine ID"
                      name="paytmMachineID"
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12}>
                  <Form.Item
                    label="Paytm Terminal ID"
                    name="paytmTerminalID"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Paytm Terminal ID",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Paytm Terminal ID"
                      name="paytmTerminalID"
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12}>
                  <Form.Item
                    label="Version Number"
                    name="VersionNo"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Version Number",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Version Number"
                      name="VersionNo"
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label="Machine Key"
                    name="MachineKey"
                    rules={[
                      { required: true, message: "Please Enter Machine Key" },
                    ]}
                  >
                    <Input
                      placeholder="Enter Machine Key"
                      name="MachineKey"
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label="Serial Number"
                    name="serialNumber"
                    rules={[
                      { required: true, message: "Please Enter Serial Number" },
                    ]}
                  >
                    <Input
                      placeholder="Enter Serial Number"
                      name="serialNumber"
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Platform"
                    name="platform"
                    rules={[
                      { required: true, message: "Please Select Platform" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Platform"
                      onChange={(e) => {
                        var platformArray = this.state.platformList.filter(
                          (x) => x.key === e
                        );

                        if (platformArray !== undefined) {
                          this.setState(
                            {
                              selectedPlatform: platformArray[0].value,
                            },
                            () => {
                              if (platformArray[0].value === "web") {
                                this.getStores();
                              } else {
                                this.getActiveUserList();
                              }
                            }
                          );
                        }
                      }}
                      disabled={this.state.isEnabled}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {/* {this.state.stateListOptions.length !== 0? */}
                      {
                        this.state.platformList.map((platform) => {
                          return (
                            <option key={platform.key} value={platform.key}>
                              {platform.value}
                            </option>
                          );
                        })
                        // :null
                        // }
                      }
                    </Select>
                  </Form.Item>
                </Col>
                {["", null, undefined].includes(
                  this.state.selectedPlatform
                ) ? null : this.state.selectedPlatform === "web" ? (
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label="Store"
                      name="store"
                      rules={[
                        { required: true, message: "Please Select Store" },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Store"
                        onChange={(e) => {
                          this.HandleApplicatoinDDL(e, "store");
                        }}
                        disabled={this.state.isEnabled}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        defaultValue={store}
                      >
                        {this.state.applicableStoreIds.data.length !== 0
                          ? this.state.applicableStoreIds.data.map(
                              (applicableStoreIds) => {
                                return applicableStoreIds.value !== "-1" ? (
                                  <Option
                                    value={applicableStoreIds.value}
                                    key={applicableStoreIds.value}
                                  >
                                    {applicableStoreIds.text}
                                  </Option>
                                ) : null;
                              }
                            )
                          : null}
                      </Select>
                    </Form.Item>
                  </Col>
                ) : (
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label="User"
                      name="users"
                      rules={[
                        { required: true, message: "Please Select User" },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select User"
                        onChange={(e) => {
                          this.HandleApplicatoinDDL(e, "user");
                        }}
                        disabled={this.state.isEnabled}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        defaultValue={users}
                      >
                        {this.state.UserList.data.length !== 0
                          ? this.state.UserList.data.map((user) => {
                              return user.value !== "-1" ? (
                                <Option value={user.value} key={user.value}>
                                  {user.text}
                                </Option>
                              ) : null;
                            })
                          : null}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.edcInfoId === 0 ? "Add" : "Update"}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
