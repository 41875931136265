import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class deliverydashboardservice {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getDeliveryTypeList() {
    const deliveryTypeAPI = new API({
      url: this.config.DROPDOWN_URL,
    });
    deliveryTypeAPI.createEntity({
      name: this.endpoint.GET_DELIVERY_TYPES,
    });
    let deliveryTypes = [];
    await deliveryTypeAPI.endpoints[this.endpoint.GET_DELIVERY_TYPES]
      .getAll()
      .then((response) => {
        deliveryTypes = response.data;
      })
      .catch((err) => {
        deliveryTypes = this.common.handleError(err);
      });
    if (deliveryTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        deliveryTypes = this.getDeliveryTypeList();
      });
    }
    return Promise.resolve(deliveryTypes);
  }

  async retrieveItems(param) {
    console.log("param->",param);
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_DELIVERY_MANAGEMENT });
    let itemArray = [];
    await myApi.endpoints.GetDeliveryManagmnet.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err)
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getDeliveryDatafromSplitOrderStatus(param) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_DELIVERYDATA_FROM_SPLITORDERSTATUS });
    let itemArray = [];
    await myApi.endpoints.GetDeliveryDataFromSplitOrderStatus.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err)
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getDeliveryDatafromSplitOrderStatus(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}
