import React from "react";
import styles from "./lkcompensation.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Spin,
  Table,
  Modal,
  // Checkbox,
  notification,
  DatePicker,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faInfo,
  // faPencilAlt,
  // faTrash,
} from "@fortawesome/free-solid-svg-icons";
import LKCompensation from "../../../services/lkcompensationservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { RegexInteger, MessageNumbers } from "../../../shared/validator";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export default class Compensation extends React.Component {
  constructor(props) {
    super(props);
    this.CompensationService = new LKCompensation();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();
    this.state = {
      modalLoading: false,
      lkCompensationItems: [],
      lkCompensationID: 0,
      // customerName:[],
      customerName: {
        //  disabled: true,
        data: [],
        selected: null,
      },
      splitOrderNos: {
        data: [],
        selected: null,
      },
      bonusAmount: "",
      // validFrom: "",
      // validTo: "",
      validDate: [],
      remark: "",
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.COMPENSATION
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.CUSTOMER_NAME,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: labels.SPLIT_ORDER_NO, //labels.CUSTOMER_NAME,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },
    {
      title: labels.BONUS_AMOUNT,
      dataIndex: "bonusAmount",
      key: "bonusAmount",
      sorter: true,
    },
    {
      title: labels.VALID_FROM,
      dataIndex: "validFrom",
      key: "validFrom",
      sorter: true,
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.VALID_TO,
      dataIndex: "validTo",
      key: "validTo",
      sorter: true,
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.REMARK,
      dataIndex: "remark",
      key: "remark",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: labels.USER_NAME,
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: true,
    },

    {
      title:labels.CREATED_ON,
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: true,
      render: (text) => {
        return [null, undefined, ""].includes(text)
          ? ""
          : Moment(text).format("DD-MM-YYYY hh:mm:ss a");
      },
    },
   
    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title={labels.VIEW}>
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    lkCompensationID: record.lkCompensationID,
                    customerName: {
                      ...this.state.customerName,
                      selected: record.customerName,
                    },
                    splitOrderNos: {
                      ...this.state.splitOrderNos,
                      selected: record.splitOrderNumber,
                    },
                    bonusAmount: record.bonusAmount,
                    validDate: [
                      Moment(record.validFrom),
                      Moment(record.validTo),
                    ],
                    remark: record.remark,
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {/* {this.state.userRights.length > 0 && this.state.userRights[0].isUpdate == true ?
                    <Button type="link"
                        onClick={() => this.setState({
                                   showAddPopup: true,
                            lkCompensationID: record.lkCompensationID,
                            customerName: record.customerName,
                            bonusAmount:record.bonusAmount,
                            validFrom:record.validFrom,
                            validTo:record.validTo,
                            remark:record.remark,
                        })}
                    >
                        <FontAwesomeIcon icon={faPencilAlt} />

                    </Button>
                    :
            null
          } */}
          {/* {console.log("delete",this.state.userRights[0].isDelete)}
          {this.state.userRights.length > 0 && this.state.userRights[0].isDelete == true ?
                    <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => this.removeItems(record.lkCompensationID)}
                        onCancel={() => null}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Popconfirm>
             :null
          }  */}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      lkCompensationID: this.state.lkCompensationID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      lkCompensationID: this.state.lkCompensationID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      lkCompensationID: 0,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : sorter.order,
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  /** selectionType = "checkbox";
    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({rowselectedvalue:selectedRowKeys})            
            console.log("rows",selectedRowKeys)
            console.log(
                `selectedRowKeys: ${selectedRowKeys}`,
                "selectedRows: ",
                selectedRows
            );
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
        }),
    };

    //function for deleting multiple seletion of rows
    deleteSelected=()=>{
        var rowselectedvalue=this.state.rowselectedvalue.toString();  
        this.removeItems(rowselectedvalue)       
    }*/

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.CompensationService.retrieveCompensationConfiguration(params).then(
      (items) => {
        if (items.statusCode === 200) {
          if (
            items.length !== 0 &&
            items.data.length === 0 &&
            params.pagination.current !== 1
          ) {
            params.pagination.current = 1;
            this.fetchItems({
              lkCompensationID: 0,
              pageIndex: 1,
              pageSize: params.pageSize,
              pagination: params.pagination,
            });
          }

          this.setState({
            lkCompensationItems: items.data,
            pagination: {
              ...params.pagination,
              total:
                items.length !== 0 && items.data.length !== 0
                  ? items.data[0].totalRecords
                  : 0,
            },
          });
        } else {
          notification["error"]({
            message: labels.COMPENSATION,
            description: items.message
              ? this.common.Message(items.message)
              : labels.UNABLE_TO_GET_LIST_OF_COMPENSATION_CONFIGURATION_DATA,
          });
        }
        this.setState({ loading: false });
      }
    );
  };

  onSearch = (searchText) => {
    this.CompensationService.getCustomerData(searchText).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          customerName: {
            ...this.state.customerName,
            data: response.data,
          },
        });
      } else {
        notification["error"]({
          message: labels.COMPENSATION,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_LIST_OF_CUSTOMERS,
        });
      }
    });
  };

  // onSearchSplitOrder = (searchText) => {
  //   debugger;
  //   this.onSearchOrderIDs(this.state.customerName.selected, searchText);
  // };

  onSearchOrderIDs = (value, searchString) => {
    this.CompensationService.getOrderIDS(parseInt(value), searchString).then(
      (response) => {
        if (response.statusCode === 200) {
          this.setState({
            splitOrderNos: {
              ...this.state.splitOrderNos,
              data: response.data,
            },
          });
        } else {
          notification["error"]({
            message: labels.COMPENSATION,
            description: labels[response.message]
              ? labels[response.message]
              : "Unable to get split order no", //labels.UNABLE_TO_GET_LIST_OF_CUSTOMERS,
          });
        }
      }
    );
  };

  //function for deleting data from server call
  /** removeItems = (lkCompensationID) => {        
         if(lkCompensationID ===""|| lkCompensationID===null || lkCompensationID===undefined){      
             openNotificationWithIcon('error', 'Compensation Configuration','Please Select Atleast One Row')
        }
        else {
       this.CompensationService.removeItems(lkCompensationID).then(items=>{
            if (items.result === 'Success') {
                openNotificationWithIcon('success', 'Compensation Configuration', items.message)
                const { pagination } = this.state;
                this.fetchItems({
                    lkCompensationID: parseInt(this.state.lkCompensationID),
                    pageIndex: pagination.current,
                    pageSize: pagination.pageSize,
                    pagination,
                });
            }
            else {
                openNotificationWithIcon('error', 'Compensation Configuration', items.message)
            }
        })
    }
    } */

  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    this.setState({ modalLoading: true });
    var postData = {
      lkCompensationID: this.state.lkCompensationID,
      customerID: this.state.customerName.selected,
      splitOrderNumber: this.state.splitOrderNos.selected,
      bonusAmount: parseInt(this.state.bonusAmount),
      validFrom: this.state.validDate[0].format("YYYY-MM-DD"),
      validTo: this.state.validDate[1].format("YYYY-MM-DD"),
      remark: this.state.remark,
    };
    this.CompensationService.insertUpdateItems(postData).then((items) => {
      if (items.statusCode === 200) {
        notification["success"]({
          message: labels.COMPENSATION,
          description: this.common.Message(items.message),
        });
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          lkCompensationID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        notification["error"]({
          message: labels.COMPENSATION,
          description: items.message
            ? this.common.Message(items.message)
            : labels.UNABLE_TO_ADD_THE_DATA,
        });
      }
      this.setState({ modalLoading: false });
    });
  };

  handleBonusAmount = (e) => {
    this.setState({ bonusAmount: e.target.value });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleRemark = (e) => {
    this.setState({ remark: e.target.value });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      lkCompensationID: 0,
      customerName: { data: [], selected: null },
      splitOrderNos: { data: [], selected: null },
      bonusAmount: "",
      // validFrom: "",
      // validTo: "",
      validDate: [],
      remark: "",
      isEnabled: false,
      isVisible: false,
    });
  };

  handleChangeRangePicker = (value) => {
    this.setState({ validDate: value });
  };
  // handleCheckbox=(event)=>{
  //      this.setState({ isAddOn: event.target.checked });
  // }

  render() {
    const {
      customerName,
      splitOrderNos,
      bonusAmount,
      // validFrom,
      // validTo,
      validDate,
      pagination,
      loading,
      remark,
    } = this.state;
    const lkCompensationItems = this.state.lkCompensationItems;
    // let RegexInteger = /^[1-9]+[0-9]*$/g;
    const listItems = lkCompensationItems.map((item, index) => ({
      key: item.lkCompensationID,
      lkCompensationID: item.lkCompensationID,
      customerName: item.customerName,
      bonusAmount: item.bonusAmount,
      validFrom: item.validFrom,
      validTo: item.validTo,
      remark: item.remark,
      splitOrderNumber: item.splitOrderNumber,
      totalRecords: item.totalRecords,
      createdByName:item.createdByName,
      createdOn:item.createdOn,
    }));
    return (
      <div>
        <PageHeader
          title={labels.COMPENSATION_CONFIGURATION}
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                {labels.ADD_COMPENSATION_CONFIGURATION}
              </Button>
            ) : null,
            // <Button
            //     type="link"
            //     className={styles.FilterToggle}
            //     onClick={() => {
            //         this.setState({ showfilterBox: !this.state.showfilterBox });
            //     }}
            // >
            //     {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //     <FontAwesomeIcon
            //         icon={faChevronUp}
            //         className={
            //             this.state.showfilterBox
            //                 ? styles.FilterToggleIcon
            //                 : styles.FilterToggleIconRotated
            //         }
            //     />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          //   rowSelection={{
          //     type: this.selectionType,
          //     ...this.rowSelection,
          //   }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}></TableActions>
        {/* {this.state.userRights.length > 0 &&
        this.state.userRights[0].isDelete == true ? (
          <Popconfirm
            title="Are you sure ?"
            onConfirm={this.deleteSelected}
            onCancel={() => null}
            okText="Yes"
            cancelText="No"
          >
            <Button variant="transparentGreyOutline">Delete Selected</Button>
          </Popconfirm>
        ) : null} */}
        <Modal
          title={
            this.state.lkCompensationID === 0
              ? labels.ADD_COMPENSATION_CONFIGURATION
              : this.state.isEnabled
              ? labels.VIEW_COMPENSATION_CONFIGURATION
              : labels.EDIT_COMPENSATION_CONFIGURATION
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                customerName: customerName.selected
                  ? customerName.selected
                  : undefined,
                splitOrderNos: splitOrderNos.selected
                  ? splitOrderNos.selected
                  : undefined,
                bonusAmount,
                // validFrom,
                // validTo,
                validDate,
                remark,
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label={labels.CUSTOMER}
                    name="customerName"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_CUSTOMER,
                      },
                    ]}
                    key={this.state.customerName.data}
                  >
                    <Select
                      showSearch
                      onSearch={this.onSearch}
                      allowClear
                      placeholder={labels.SEARCH_CUSTOMER}
                      onChange={(value) => {
                        this.setState(
                          {
                            customerName: {
                              ...this.state.customerName,
                              selected: value,
                            },
                          },
                          () => this.onSearchOrderIDs(value, "")
                        );
                      }}
                      disabled={this.state.isEnabled}
                      filterOption={false}
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      {this.state.customerName.data.length !== 0
                        ? this.state.customerName.data.map(
                            (singleCustomerName) => {
                              return singleCustomerName.customerID !== "-1" ? (
                                <Option
                                  value={singleCustomerName.customerID}
                                  key={singleCustomerName.customerID}
                                >
                                  {singleCustomerName.customerName +
                                    " (" +
                                    singleCustomerName.mobileNo +
                                    ")"}
                                </Option>
                              ) : null;
                            }
                          )
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Split Order No" //{labels.CUSTOMER}
                    name="splitOrderNos"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Split Order No", //labels.PLEASE_SELECT_CUSTOMER,
                      },
                    ]}
                    // key={this.state.customerName.data}
                  >
                    <Select
                      showSearch
                      onSearch={(value) => {
                        this.onSearchOrderIDs(
                          this.state.customerName.selected,
                          value
                        );
                      }}
                      allowClear
                      placeholder="Select Split Order No" //{labels.SEARCH_CUSTOMER}
                      onChange={(value) => {
                        this.setState({
                          splitOrderNos: {
                            ...this.state.splitOrderNos,
                            selected: value,
                          },
                        });
                      }}
                      disabled={this.state.isEnabled}
                      filterOption={false}
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      {this.state.splitOrderNos.data.length !== 0
                        ? this.state.splitOrderNos.data.map(
                            (singleSplitOrderNos) => {
                              return singleSplitOrderNos.splitOrderNumber !==
                                "-1" ? (
                                <Option
                                  value={singleSplitOrderNos.splitOrderNumber}
                                  key={singleSplitOrderNos.splitOrderNumber}
                                >
                                  {singleSplitOrderNos.splitOrderNumber}
                                </Option>
                              ) : null;
                            }
                          )
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} ml={12} xl={8}>
                  <Form.Item
                    label={labels.BONUS_AMOUNT}
                    name="bonusAmount"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_BONUS_AMOUNT,
                      },
                      {
                        pattern: RegexInteger,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_BONUS_AMOUNT}
                      disabled={this.state.isEnabled}
                      prefix="₹"
                      maxLength={20}
                      onChange={this.handleBonusAmount}
                    />
                  </Form.Item>
                </Col>

                {/* <Col xs={24} ml={12} xl={8}>
                <Form.Item
                  label="Valid From"
                  name="validFrom"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Valid From Date!",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select Date"
                    // format={"DD/MM/YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "validFrom")
                    }
                    disabled={this.state.isEnabled}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} ml={12} xl={8}>
                <Form.Item
                  label="Valid To"
                  name="validTo"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Valid To Date!",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Select Date"
                    // format={"DD/MM/YYYY"}
                    onChange={(date) => this.handleDateChange(date, "validTo")}
                    disabled={this.state.isEnabled}
                  />
                </Form.Item>
              </Col> */}
                <Col xs={24} ml={12} xl={16}>
                  <Form.Item
                    label={labels.VALID_FROM_AND_TO_DATE}
                    name="validDate"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_VALID_FROM_AND_TO_DATE,
                      },
                    ]}
                  >
                    <RangePicker
                      placeholder={[labels.FROM_DATE, labels.TO_DATE]}
                      disabled={this.state.isEnabled}
                      onChange={this.handleChangeRangePicker}
                      format={"DD-MM-YYYY"}
                      disabledDate={(currentDate) =>
                        currentDate.isBefore(Moment(), "day") ? true : false
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label={labels.REMARK}
                    name="remark"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_REMARK,
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder={labels.ENTER_REMARK}
                      disabled={this.state.isEnabled}
                      onChange={this.handleRemark}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.lkCompensationID === 0
                            ? labels.ADD
                            : labels.UPDATE}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
