import React from 'react';
import Outofcoveragecomponent from '../../../components/StoreModule/Audit/outofcoverage.component';

export default class outofcoveragescreen extends React.Component {
  render() {
    return (
      <div><Outofcoveragecomponent></Outofcoveragecomponent></div>
    )
  }
}
