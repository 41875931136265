import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class LkCustomBulkNotificationService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getcustomebulknotificationdata(param) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_BULK_NOTIFICATION_MASTER });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.GET_BULK_NOTIFICATION_MASTER]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getcustomebulknotificationdata(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async uploadFile(param) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.UPLOAD_BULK_PUSH_NOTIFICATION });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.UPLOAD_BULK_PUSH_NOTIFICATION]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.uploadFile(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getReport(param) {
    const myApi = new API({ url: this.config.ITEM_URL });

    myApi.createEntity({
      name: this.endpoint.GET_BULK_PUSH_NOTIFICATION_FILE_REPORT,
    });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.GET_BULK_PUSH_NOTIFICATION_FILE_REPORT]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getReport(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}
