import React, { Component } from 'react';
import Appsflyercomponent from '../../components/AppsFlyer/appsflyer.component';

export default class appsflyer extends Component {
  render() {
    return (
      <Appsflyercomponent></Appsflyercomponent>
    )
  }
}
