import React from 'react';
import ProcessingList from '../../../components/FactoryModule/Processing/processinglist.factory.component';

export default class ProcessingListscreen extends React.Component {
    render() {
        return (
            <div>
                <ProcessingList></ProcessingList>
            </div>
        )
    }
}
