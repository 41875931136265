import React from "react";
import Button from "../../../Common/Button/button";
import UserService from "../../../../services/userservice";
import { Row, Col, Form, Input, DatePicker, Select, notification } from "antd";
import styles from "./createuser.module.css";
import moment from "moment";
import {
  RegexLetters,
  RegexLettersDigits,
  MessageLetters,
  MessageLettersDigits,
} from "../../../../shared/validator";

const { Option } = Select;
/**
 * A form as a part of create user - General Section/Step
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 2 June 2020
 *
 * @module User
 */
export default class GeneralForm extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.userservice = new UserService();
    this.state = {
      isEditmode: false,
      isViewmode: false,
      empType: [],
      desType: [],
      rptTo: [],
    };
  }
  componentDidMount() {
    this._isMounted = true;
    //this.setState({isEditmode:this.props.isEditmode,isViewmode:this.props.isViewmode});
    this.getEmployeeType();
    this.getReportingTo();
    this.getDesignation();
  }

  handleIsMACChange(e) {
    this.setState({ isMACID: e.target.checked });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getEmployeeType = () => {
    if (this._isMounted) {
      this.userservice.retrieveEmployeeType().then((empType) => {
        if (empType.statusCode === 200) {
          this.setState({ empType: empType.data });
        } else {
          notification["error"]({
            message: "Users",
            description: "Unable to get data for employee types",
          });
        }
      });
    }
  };

  getReportingTo = () => {
    if (this._isMounted) {
      this.userservice.retrieveReportingTo().then((rptTo) => {
        if (rptTo.statusCode === 200) {
          this.setState({ rptTo: rptTo.data });
        } else {
          notification["error"]({
            message: "Users",
            description: "Unable to get data for reporting to",
          });
        }
      });
    }
  };

  getDesignation = () => {
    if (this._isMounted) {
      this.userservice.retrieveDesignation().then((desType) => {
        if (desType.statusCode === 200) {
          this.setState({ desType: desType.data });
        } else {
          notification["error"]({
            message: "Users",
            description: "Unable to get data for designation type",
          });
        }
      });
    }
  };

  render() {
    const disabled = this.props.mode === "view";
    return (
      <Form
        layout="vertical"
        name="General"
        initialValues={this.props.values}
        ref={this.props.instance}
        onFinish={this.onFinishGeneralForm}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={8}>
            {/**
             * For More Clarity over what <Input> Component can do
             * Please go through https://ant.design/components/input/
             * https://ant.design/components/input/#API
             */}
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please Enter First Name" },
                {
                  pattern: RegexLetters,
                  message: MessageLetters,
                },
              ]}
            >
              <Input placeholder="Enter First Name" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Middle Name"
              name="middleName"
              rules={[
                {
                  pattern: RegexLetters,
                  message: MessageLetters,
                },
              ]}
            >
              <Input placeholder="Enter Middle Name" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Please Enter Last Name" },
                {
                  pattern: RegexLetters,
                  message: MessageLetters,
                },
              ]}
            >
              <Input placeholder="Enter Last Name" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Mobile No"
              name="mobileNo"
              rules={[
                { required: true, message: "Please Enter Mobile No" },
                {
                  pattern: "[6789][0-9]{9}",
                  message: "Please Enter Valid Mobile No",
                },
              ]}
            >
              <Input
                maxLength={10}
                placeholder="Enter Mobile No"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Email"
              name="emailAddress"
              rules={[
                { required: true, message: "Please Enter Email" },
                {
                  type: "email",
                  message: "Please Enter Valid Email",
                },
              ]}
            >
              <Input placeholder="Enter Email" disabled={disabled} />
            </Form.Item>
          </Col>
          {this.props.mode !== "edit" ? (
            <Col xs={24} md={12} xl={8}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    pattern:
                      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-]).{8,}$",
                    message:
                      "Password Length Is 8 Including Minimum 1 Upper case,1 Lower Case,1 Numeric,1 Special Character Except *.",
                  },
                ]}
              >
                <Input
                  type="password"
                  placeholder="Enter Password"
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          ) : null}
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Employee Type"
              name="employeeType"
              rules={[
                { required: true, message: "Please Select Employee Type" },
              ]}
            >
              <Select placeholder="Select Employee Type" disabled={disabled}>
                {/* {this.state.empType.map((empType) => <Select.Option key={empType.value} value={empType.value}>{empType.text}</Select.Option>)}   */}

                {this.state.empType.map((empType) => {
                  return empType.value !== "-1" ? (
                    <Option value={empType.value} key={empType.value}>
                      {empType.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="DOB" name="dob">
              <DatePicker
                // onChange={() => null}
                disabled={disabled}
                format={"DD-MM-YYYY"}
                disabledDate={(current) => current.isSameOrAfter(moment())}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="DOJ" name="doj">
              <DatePicker
                // onChange={() => null}
                disabled={disabled}
                format={"DD-MM-YYYY"}
                disabledDate={(currentDate) =>
                  currentDate.isBefore(moment(), "day") ? true : false
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label="Designation" name="designationID">
              <Select
                placeholder="Select Designation"
                disabled={disabled}
                allowClear
              >
                {/* {this.state.desType.map((desType) => <Select.Option key={desType.value} value={desType.value}>{desType.text}</Select.Option>)} */}
                {this.state.desType.map((desType) => {
                  return desType.value !== "-1" ? (
                    <Option value={desType.value} key={desType.value}>
                      {desType.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label="Reporting To" name="reportingTo">
              <Select
                placeholder="Select Reporting To"
                disabled={disabled}
                allowClear
              >
                {/* {this.state.rptTo.map((rptTo) => <Select.Option key={rptTo.value} value={rptTo.value}>{rptTo.text}</Select.Option>)} */}
                {this.state.rptTo.map((rptTo) => {
                  return rptTo.value !== "-1" ? (
                    <Option value={rptTo.value} key={rptTo.value}>
                      {rptTo.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col xs={24} md={12} xl={6}>
            <Form.Item label="MAC">
              <Input placeholder="Enter MAC" name="macid" value={macid} onChange={handleChange('macid')}/>
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label="IsMACID">
              <Checkbox onChange={(event) => handleCheckbox(event,'isMACID')} checked={isMACID}>IsMACID</Checkbox>
            </Form.Item>
          </Col> */}
          <Col xs={24} className={styles.ButtonContainer}>
            <Button
              variant="transparentGreyOutline"
              className={styles.CancelButton}
              onClick={this.props.backFunction}
              shape="round"
              size="large"
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              variant="purple"
              shape="round"
              size="large"
              onClick={this.props.nextFunction}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
