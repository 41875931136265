import React from "react";
import {
  PageHeader,
  notification,
  Select,
  Table,
  InputNumber,
  Input,
  Form,
  Tooltip,
} from "antd";
import Button from "../../../Common/Button/button";
import styles from "../../../Common/Styles/master.module.css";
import CashCouponMasterService from "../../../../services/cashcouponmasterservice";
import { withRouter } from "react-router-dom";
import ReactExport from "react-export-excel";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Search } = Input;

class CashCouponCode extends React.Component {
  cashcouponmasterservice = new CashCouponMasterService();
  state = {
    excelFile: <React.Fragment></React.Fragment>,
    generateButtonLoading: false,
    couponID: null,
    cashCouponCodeName: this.props.cashCouponCodeName,
    cashCouponObj: {},
    cashCouponCode: [],
    showAddPopup: false,
    totalLength: null,
    prefixChar: "",
    tableLoading: true,
    currentCashCouponCode: {},
    popupMode: "add",
  };
  componentDidMount() {
    if (!this.props.match.params.couponID) {
      this.props.history.goBack();
    } else {
      //this.getCashCouponMaster(this.props.match.params.couponID);
    }
    this.setState({ couponID: this.props.match.params.couponID });
    this.getCashCouponCode(this.props.match.params.couponID);
  }
  getCashCouponMaster = (id) => {
    this.cashcouponmasterservice
      .getCashCouponDetailByCouponID(id)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({ cashCouponObjObj: response.data });
        } else {
          notification["error"]({
            message: "Cash Coupon",
            description: "Unable to get cash coupon master data",
            // description: "Redirecting....",
            onClose: () => {
              this.props.history.goBack();
            },
          });
        }
      });
  };

  getCashCouponCode = (id) => {
    this.setState({ tableLoading: true });
    this.cashcouponmasterservice
      .GetCashCouponCodesByCouponID(id)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({ cashCouponCode: response.data });
        } else {
          notification["error"]({
            message: "Cash Coupon",
            description: "Unable to get cash coupon codes",
            // description: "Redirecting....",
            onClose: () => {
              this.props.history.goBack();
            },
          });
        }
        this.setState({ tableLoading: false });
      });
  };

  GenerateCashCouponCodes = () => {
    if (
      [null, undefined, ""].includes(this.state.totalLength) ||
      [null, undefined, ""].includes(this.state.prefixChar)
    ) {
      notification["error"]({
        message: "Discount",
        description: "Values cannot be passed as blank",
      });
      return false;
    }
    this.setState({ generateButtonLoading: true });
    var payload = {
      couponID: parseInt(this.state.couponID),
      totalLength: this.state.totalLength,
      prefixChar: this.state.prefixChar,
    };
    this.cashcouponmasterservice
      .GenerateCashCouponCodesByCouponID(payload)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Cash Coupon",
            description: response.message,
          });
          this.getCashCouponCode(parseInt(this.state.couponID));
        }
        // else {
        //   notification["error"]({
        //     message: "Unable to get cash coupon master data",
        //     description: "Redirecting....",
        //     onClose: () => {
        //       this.props.history.goBack();
        //     },
        //   });
        // }
        this.setState({ generateButtonLoading: false });
      });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  exportCashCouponCodeToExcel = () => {
    this.setState(
      {
        excelFile: (
          <div>
            <ExcelFile hideElement={true} filename={"CashCouponCode"}>
              <ExcelSheet
                data={this.state.cashCouponCode}
                name={this.state.cashCouponCodeName}
                columns={[]}
              >
                <ExcelColumn label="Cash Coupon Codes" value="couponCode" />
              </ExcelSheet>
            </ExcelFile>
          </div>
        ),
      },
      () => this.setState({ excelFile: <React.Fragment></React.Fragment> })
    );
  };

  columns = [
    {
      title: "Coupon Code",
      dataIndex: "couponCode",
      key: "couponCode",
    },
  ];
  render() {
    const disableAll = this.state.popupMode === "view";
    let RegexAlphaNumberic = /^[a-zA-Z0-9]*$/;
    let validFromDate = moment(this.props.validFromDate).format("YYYY/MM/DD");
    let currentDate = moment().format("YYYY/MM/DD");
    return (
      <div>
        {this.state.excelFile}
        <PageHeader
          className={styles.SubHeader}
          title="Cash Coupon Codes"
          extra={[
            validFromDate > currentDate ? (
              this.props.couponTypeID === 1 &&
              this.state.cashCouponCode.length === 0 ? (
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>No Of Character</div>
                      <div>Minimum: 6</div>
                      <div>Maximum: 16 must be there</div>
                    </React.Fragment>
                  }
                >
                  <InputNumber
                    key="1"
                    max={16}
                    min={6}
                    placeholder="Length"
                    step={1}
                    precision={0}
                    type="number"
                    onChange={(value) => this.setState({ totalLength: value })}
                  />
                </Tooltip>
              ) : null
            ) : null,
            validFromDate > currentDate ? (
              this.props.couponTypeID === 1 &&
              this.state.cashCouponCode.length === 0 ? (
                <Tooltip title="Prefix Character">
                  <Input
                    key="2"
                    placeholder="Prefix"
                    style={{ maxWidth: 70 }}
                    maxLength={2}
                    onChange={(e) => {
                      let inputVal = e.target.value;
                      if (RegexAlphaNumberic.test(inputVal)) {
                        this.setState({ prefixChar: e.target.value });
                      } else {
                        notification["error"]({
                          message: "Discount",
                          description:
                            "You can only enter Alpha-Numeric inputs",
                        });
                      }
                    }}
                  />
                </Tooltip>
              ) : null
            ) : null,

            this.props.couponTypeID === 1 ? (
              this.state.cashCouponCode.length === 0 ? (
                validFromDate > currentDate ? (
                  <Button
                    variant="purple"
                    htmlType="submit"
                    onClick={() => this.GenerateCashCouponCodes()}
                    key="1"
                    loading={this.state.generateButtonLoading}
                  >
                    Generate Coupon Codes
                  </Button>
                ) : null
              ) : (
                <Button
                  variant="purple"
                  htmlType="submit"
                  onClick={() => this.exportCashCouponCodeToExcel()}
                  key="1"
                >
                  Export To Excel
                </Button>
              )
            ) : null,
          ]}
        />
        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.cashCouponCode}
          loading={this.state.tableLoading}
          pagination={false}
        />
      </div>
    );
  }
}
export default withRouter(CashCouponCode);
