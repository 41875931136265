import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class TransferOutService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async retrieveFactoryList(ServiceIds) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.GET_FACTORY_LIST_BY_SERVICEID });
    let itemArray = [];
    var param =
      "?ServiceIds=" +
      ServiceIds +
      "&StoreID=" +
      parseInt(localStorage.getItem("branchID"));
    await myApi.endpoints[this.endpoint.GET_FACTORY_LIST_BY_SERVICEID]
      .getOne(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveFactoryList(ServiceIds);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveServiceList() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_SERVICES_OFFERED });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.GetServicesList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveServiceList();
      });
    }
    return Promise.resolve(itemArray);
  }

  async GetSplitNumberToTransferout(
    serviceId = null,
    factoryId = null,
    index = 0,
    size = 0,
    sortColumn = null,
    sortOrder = null,
    search = null
  ) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_SPLIT_NUMBERTOTRANS_OUT });
    let itemArray = [];
    let shouldWait = false;
    // let param =
    //   "?StoreId=" +
    //   localStorage.getItem("branchID") +
    //   "&ServiceIds=" +
    //   serviceId +
    //   "&FactoryID=" +
    //   factoryId;
    let payload = {
      storeId: parseInt(localStorage.getItem("branchID")),
      serviceIds: serviceId,
      factoryID: factoryId,
      pageIndex: index,
      pageSize: size,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      commonSearch: search,
    };
    await myApi.endpoints[this.endpoint.GET_SPLIT_NUMBERTOTRANS_OUT]
      .getAllPost(payload)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetSplitNumberToTransferout(
          serviceId,
          factoryId,
          index,
          size,
          sortColumn,
          sortOrder,
          search
        );
      });
    }
    return Promise.resolve(itemArray);
  }

  async GetGarmentdatailBysplitOrdernumber(id) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.GET_GARMENTDATAIL_BY_SPLIT_ORDER_NUMBER,
    });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.GET_GARMENTDATAIL_BY_SPLIT_ORDER_NUMBER]
      .getById("?SplitOrderNumber=" + id, {})
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetGarmentdatailBysplitOrdernumber(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleError(error) {
    console.log(error.message);
  }

  async GenerateGatePass(store, factory, splitOrder) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.GENERATE_GATE_PASS,
    });
    let params = {
      storeID: store,
      factoryId: factory,
      splitOrderNumber: splitOrder,
    };
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.GENERATE_GATE_PASS]
      .getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GenerateGatePass(store, factory, splitOrder);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getPDFGatePass(gatePassNo) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE_PRINT_GATEPASS });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.GET_STORE_PRINT_GATEPASS]
      .create(gatePassNo)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getPDFGatePass(gatePassNo);
      });
    }
    return Promise.resolve(itemArray);
  }
}
export default TransferOutService;
