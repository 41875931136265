import React from "react";
import { notification, Radio, Table } from "antd";
import styles from "./attendance.module.css";
import Button from "../../components/Common/Button/button";
import AttendanceService from "../../services/attendanceservice";
import MantraHelper from "../FingerScan/mantraHelper";
import Moment from "moment";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";

export default class Attendance extends React.Component {
  attendanceService = new AttendanceService();
  mantraHelper = new MantraHelper();
  UserRightsConfiguration = new UserRightsConfiguration();
  state = {
    loading: false,
    dataSource: [],
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };
  lISNormal = 1;
  fingerMaster = [];
  store = null;
  factory = null;

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.ATTENDANCE
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  componentDidMount() {
    let storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();

    if (storeOrFactory === "store") {
      this.store = parseInt(localStorage.getItem("branchID"));
    } else {
      this.factory = parseInt(localStorage.getItem("branchID"));
    }
    this.getAttendanceData();
    this.getFIngerTempl();
  }

  async PunchIn(lIsPucnhin) {
    // debugger;
    // const params = {
    //   fingurePrintID: 10,
    //   userId: 0,
    //   storeId: this.store,
    //   isManual: 0,
    //   matchedTempl: 0,
    //   addedBy: 0,
    //   ISPunchIn: lIsPucnhin,
    //   ISNormal: this.lISNormal,//Need to get data whether is normal or OT
    //   FactoryId: this.factory,
    // };

    let quality = 60; //(1 to 100) (recommanded minimum 55)
    let timeout = 20; // seconds (minimum=10(recommanded), maximum=60, unlimited=0 )
    // let res1 = this.mantraHelper.VerifyFinger("test", "test");
    this.mantraHelper.CaptureFinger(quality, timeout).then((res) => {
      // debugger;
      if (res.httpStaus) {
        // let isMatched = false;
        // let indFinger = 0;
        let SwipedISOTempl = res.data.data.IsoTemplate;
        // //F1ISOTemplate
        // while ((!isMatched) && (this.fingerMaster.length > indFinger)) {
        // debugger;
        this.VerifyFingerArray(SwipedISOTempl, lIsPucnhin);
        // debugger;
        //  var aa =this.mantraHelper.VerifyFinger(SwipedISOTempl, this.fingerMaster[indFinger].F1ISOTemplate).then((resp) => {
        //     if (resp.httpStaus) {
        //       if (resp.data.Status) {
        //         isMatched = true;
        //         params.fingurePrintID = this.fingerMaster[indFinger].FingurePrintID;
        //         params.userId = this.fingerMaster[indFinger].UserId;
        //         params.MatchedTempl = 'F1ISOTemplate';
        //       }
        //     }
        //     else {
        //       notification["error"]({
        //         message: resp.err,
        //       });
        //     }
        //   })
        //Promise.resolve(aa);
        //   indFinger++;
        // }
        /*
         
        
                //F3ISOTemplate
                while ((!isMatched) && (this.fingerMaster.length > indFinger)) {
                  let res = this.mantraHelper.VerifyFinger(SwipedISOTempl, this.fingerMaster[indFinger].F3ISOTemplate);
                  if (res.httpStaus) {
                    if (res.data.Status) {
                      isMatched = true;
                      params.fingurePrintID = this.fingerMaster[indFinger].FingurePrintID;
                      params.userId = this.fingerMaster[indFinger].UserId;
                      params.MatchedTempl = 'F3ISOTemplate';
                    }
                  }
                  else {
                    notification["error"]({
                      message: res.err,
                    });
                  }
                  indFinger++;
                }
        
                if (isMatched) {
                  // Do punchin-OUT
                  this.attendanceService.AddUpdatePunchData(params).then((response) => {
                    if (response.statusCode === 200) {
                      notification["success"]({
                        message: "Attandance Marked successfully ",
                        duration: 1.5,
                      });
                      //Play Sound
                      let msg = new SpeechSynthesisUtterance('Have a good day!');
                      window.speechSynthesis.speak(msg);
                      this.getAttendanceData();
                    } else {
                      notification["error"]({
                        message: response.message
                          ? response.message
                          : "Unable to submit fingerprint data",
                      });
                    }
                  });
        
                } else {
                  //Not Matched 
                  notification["error"]({
                    message: "Finger not matched, Pleae swipe again.",
                  });
                  let msg = new SpeechSynthesisUtterance('Please swipe again.');
                  window.speechSynthesis.speak(msg);
                }
                */
      } else {
        // alert(res.err);
        notification["error"]({
          message: res.err,
        });
      }
    });
  }

  async VerifyFingerArray(SwipedISOTempl, lIsPucnhin) {
    const params = {
      fingurePrintID: 0,
      userId: 0,
      storeId: this.store,
      isManual: 0,
      matchedTempl: 0,
      addedBy: 0,
      iSPunchIn: lIsPucnhin,
      iSNormal: parseInt(this.lISNormal), //Need to get data whether is normal or OT
      factoryId: this.factory,
    };
    let isMatched = false;
    let indFinger = 0;

    //F1ISOTemplate
    while (!isMatched && this.fingerMaster.length > indFinger) {
      await this.mantraHelper
        .VerifyFinger(
          SwipedISOTempl,
          this.fingerMaster[indFinger].f1ISOTemplate
        )
        .then((resp) => {
          if (resp.httpStaus) {
            if (resp.data.data.Status) {
              isMatched = true;
              params.fingurePrintID = this.fingerMaster[
                indFinger
              ].fingurePrintID;
              params.userId = this.fingerMaster[indFinger].userId;
              params.matchedTempl = "F1ISOTemplate";
            }
          }
          // else {
          //   notification["error"]({
          //     message: resp.err,
          //   });
          // }
        });

      // debugger;
      indFinger++;
    }

    indFinger = 0;
    //F2ISOTemplate
    while (!isMatched && this.fingerMaster.length > indFinger) {
      await this.mantraHelper
        .VerifyFinger(
          SwipedISOTempl,
          this.fingerMaster[indFinger].f2ISOTemplate
        )
        .then((resp) => {
          if (resp.httpStaus) {
            if (resp.data.data.Status) {
              isMatched = true;
              params.fingurePrintID = this.fingerMaster[
                indFinger
              ].fingurePrintID;
              params.userId = this.fingerMaster[indFinger].userId;
              params.matchedTempl = "F2ISOTemplate";
            }
          } else {
            notification["error"]({
              message: resp.err,
            });
          }
        });
      indFinger++;
    }
    indFinger = 0;
    //F3ISOTemplate
    while (!isMatched && this.fingerMaster.length > indFinger) {
      await this.mantraHelper
        .VerifyFinger(
          SwipedISOTempl,
          this.fingerMaster[indFinger].f3ISOTemplate
        )
        .then((resp) => {
          if (resp.httpStaus) {
            if (resp.data.data.Status) {
              isMatched = true;
              params.fingurePrintID = this.fingerMaster[
                indFinger
              ].fingurePrintID;
              params.userId = this.fingerMaster[indFinger].userId;
              params.matchedTempl = "F3ISOTemplate";
            }
          } else {
            notification["error"]({
              message: resp.err,
            });
          }
        });
      indFinger++;
    }

   
    if (isMatched) {
      // Do punchin-OUT
      this.attendanceService.AddUpdatePunchData(params).then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Attandance Marked successfully ",
            duration: 1.5,
          });
          //Play Sound
          let msg = new SpeechSynthesisUtterance("Have a good day!");
          window.speechSynthesis.speak(msg);
          this.getAttendanceData();
        } else {
          notification["error"]({
            message: response.message
              ? response.message
              : "Unable to submit fingerprint data",
          });
        }
      });
    } else {
      //Not Matched
      notification["error"]({
        message: "Finger not matched, Pleae swipe again.",
      });
      let msg = new SpeechSynthesisUtterance("Please swipe again.");
      window.speechSynthesis.speak(msg);
    }
  }

  getAttendanceData = () => {
    // let storeOrFactory = localStorage
    //   .getItem("Store/FactoryType")
    //   .toLowerCase();
    // let store = null;
    // let factory = null;
    // if (storeOrFactory === "store") {
    //   store = parseInt(localStorage.getItem("branchID"));
    // } else {
    //   factory = parseInt(localStorage.getItem("branchID"));
    // }
    this.attendanceService
      .getAttendanceData(this.store, this.factory)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            dataSource: response.data,
          });
        } else {
          notification["error"]({
            message: response.message
              ? response.message
              : "Unable to get attendance data",
          });
        }
      });
  };

  getFIngerTempl = () => {
    this.attendanceService
      .getFIngerTempl(this.store, this.factory)
      .then((response) => {
        if (response.statusCode === 200) {
          this.fingerMaster = response.data;
        } else {
          notification["error"]({
            message: response.message
              ? response.message
              : "Unable to get data please try agin.",
          });
        }
      });
  };

  columns = [
    {
      title: "Id",
      dataIndex: "attandanceId",
      key: "attandanceId",
      sorter: false,
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
      sorter: false,
    },
    {
      title: "Normal/OT",
      dataIndex: "isNormal",
      key: "isNormal",
      sorter: true,
      render: (isNormal) => (isNormal !== 1 ? "OT" : "Normal"),
    },
    {
      title: "In TIme",
      dataIndex: "inTIme",
      key: "inTIme",
      sorter: true,
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY HH:mm");
      },
    },
  ];

  render() {
    return (
      <React.Fragment>
        <div className={styles.AttendanceOptionsWrap}>
          <Radio.Group
            defaultValue="1"
            onChange={(event) => (this.lISNormal = event.target.value)}
            buttonStyle="solid"
            size="large"
          >
            <Radio.Button value="1" className={styles.AttendanceOption}>
              Normal
            </Radio.Button>
            <Radio.Button value="0" className={styles.AttendanceOption}>
              OT
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className={styles.ButtonAndCountWrapper}>
          <div className={styles.ButtonWrapper}>
            <Button
              block
              size="large"
              className={styles.inButton}
              onClick={() => {
                this.PunchIn(1);
              }}
            >
              In
            </Button>
            <Button
              block
              size="large"
              className={styles.outButton}
              onClick={() => {
                this.PunchIn(0);
              }}
            >
              Out
            </Button>
          </div>
          <div className={styles.countWrap}>{this.state.dataSource.length}</div>
        </div>
        <p className={styles.info}>
          Press the button and just scan your finger. Once finger print will be
          matched, a sound will be played to notify that the attendance is
          marked
        </p>
        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.dataSource}
          pagination={this.state.pagination}
          loading={this.state.loading}
        />
      </React.Fragment>
    );
  }
}
