import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  DatePicker,
  notification,
  Tooltip,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import PackagingChargesService from "../../../services/packagingchargeservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import {
  RegexDecimalAndRestrictFirstZero,
  MessageNumbers,
} from "../../../shared/validator";
import moment from "moment";

const { Search } = Input;
const { Option } = Select;

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

let todaysDate = Moment(new Date());

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.PackageChargeService = new PackagingChargesService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.state = {
      modalLoading: false,
      packChargeItems: [],
      serviceListOptions: [],
      packageChargeID: 0,
      serviceID: 0,
      serviceName: [],
      fixedCharges: null,
      perCharges: null,
      effectiveFrom: "",
      cgst: 0,
      sgst: 0,
      igst: 0,
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
      disableUpdateBtn: false,
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.PACKAGING_CHARGES
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
      sorter: true,
    },
    {
      title: "Fixed Charges",
      dataIndex: "fixedCharges",
      key: "fixedCharges",
      sorter: true,
    },
    {
      title: "Percentage Charges",
      dataIndex: "perCharges",
      key: "perCharges",
      sorter: true,
    },
    {
      title: "Effective From",
      dataIndex: "effectiveFrom",
      key: "effectiveFrom",
      sorter: true,
      render: (data) => moment(data, "DD/MM/YYYY").format("DD-MM-YYYY"),
    },
    // {
    //   title: "CGST",
    //   dataIndex: "cgst",
    //   key: "cgst",
    //   sorter: true,
    // },
    // {
    //   title: "SGST",
    //   dataIndex: "sgst",
    //   key: "sgst",
    //   sorter: true,
    // },
    // {
    //   title: "IGST",
    //   dataIndex: "igst",
    //   key: "igst",
    //   sorter: true,
    // },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    packageChargeID: record.packageChargeID,
                    serviceName: record.serviceName,
                    fixedCharges: record.fixedCharges,
                    perCharges: record.perCharges,
                    // cgst: record.cgst,
                    // sgst: record.sgst,
                    // igst: record.igst,
                    effectiveFrom: Moment(record.effectiveFrom, "DD/MM/YYYY"),
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true &&
          !Moment(record.effectiveFrom, "DD/MM/YYYY").isBefore(
            todaysDate,
            "date"
          ) ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() => {
                  this.setState({
                    showAddPopup: true,
                    packageChargeID: record.packageChargeID,
                    serviceID: record.serviceID,
                    serviceName: record.serviceName,
                    fixedCharges: record.fixedCharges,
                    perCharges: record.perCharges,
                    // cgst: record.cgst,
                    // sgst: record.sgst,
                    // igst: record.igst,
                    effectiveFrom: Moment(record.effectiveFrom, "DD/MM/YYYY"),
                  });
                  var effectiveDate = Moment(
                    record.effectiveFrom,
                    "DD/MM/YYYY"
                  );
                  if (effectiveDate.isBefore(todaysDate, "date")) {
                    this.setState({ disableUpdateBtn: true });
                    notification["error"]({
                      message: "Packaging Charges",
                      description: "Previous rates can't be updated.!",
                    });
                  } else {
                    this.setState({ disableUpdateBtn: false });
                  }
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.packageChargeID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      packageChargeID: this.state.packageChargeID,
      effectiveFrom: todaysDate,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
    this.DropDownServiceList();
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      packageChargeID: this.state.packageChargeID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      packageChargeID: this.state.packageChargeID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  RestoreDDlValue = (e) => {
    var serviceArray = this.state.serviceListOptions.filter(
      (x) => x.value === e
    );
    if (serviceArray !== undefined) {
      this.setState({
        serviceID: serviceArray[0].value,
        serviceName: serviceArray[0].text,
      });
    }
  };

  DropDownServiceList = () => {
    this.PackageChargeService.serviceListItems().then((items) => {
      if (items.statusCode === 200) {
        this.setState({ serviceListOptions: items.data });
      } else {
        notification["error"]({
          message: "Packaging Charges",
          description: items.message
            ? items.message
            : "Unable to get list of service data",
        });
      }
    });
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.PackageChargeService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            packageChargeID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          packChargeItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Packaging Charges",
          description: items.message
            ? items.message
            : "Unable to get list of packaging charges data",
        });
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (packageChargeID) => {
    if (
      packageChargeID === "" ||
      packageChargeID === null ||
      packageChargeID === undefined
    ) {
      openNotificationWithIcon(
        "error",
        "Packaging Charges",
        "Please Select Atleast One Row"
      );
    } else {
      this.setState({ loading: true });
      this.PackageChargeService.removeItems(packageChargeID).then((items) => {
        if (items.result === "Success") {
          openNotificationWithIcon(
            "success",
            "Packaging Charges",
            items.message
          );
          const { pagination } = this.state;
          this.fetchItems({
            packageChargeID: parseInt(this.state.packageChargeID),
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
          });
        } else {
          openNotificationWithIcon("error", "Packaging Charges", items.message);
        }
        this.setState({ loading: false });
      });
    }
  };

  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    var postData = {
      packageChargeID: this.state.packageChargeID,
      serviceID: parseInt(this.state.serviceID),
      fixedCharges: parseFloat(this.state.fixedCharges),
      perCharges: parseFloat(this.state.perCharges),
      cgst: 0, // parseFloat(this.state.cgst),
      sgst: 0, // parseFloat(this.state.sgst),
      igst: 0, // parseFloat(this.state.igst),
      effectiveFrom: Moment(this.state.effectiveFrom).format("YYYY/MM/DD"),
    };
    this.setState({ modalLoading: true });
    this.PackageChargeService.insertUpdateItems(postData).then((items) => {
      if (items.result === "Success") {
        openNotificationWithIcon("success", "Packaging Charges", items.message);
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          packageChargeID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        openNotificationWithIcon("error", "Packaging Charges", items.message);
      }
      this.setState({ modalLoading: false });
    });
  };

  handleInputOnChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      packageChargeID: 0,
      serviceID: 0,
      serviceName: [],
      fixedCharges: 0,
      perCharges: 0,
      cgst: 0,
      sgst: 0,
      igst: 0,
      effectiveFrom: "",
      isEnabled: false,
      isVisible: false,
      disableUpdateBtn: false,
    });
  };

  render() {
    const {
      serviceName,
      fixedCharges,
      perCharges,
      cgst,
      sgst,
      igst,
      effectiveFrom,
      pagination,
      loading,
    } = this.state;
    const packChargeItems = this.state.packChargeItems;
    const RegexDecimalAndRestrict = /^[0-9]\d*(.\d*)?$/;
    const listItems = packChargeItems.map((item, index) => ({
      key: item.packageChargeID,
      packageChargeID: item.packageChargeID,
      serviceID: item.serviceID,
      serviceName: item.serviceName,
      fixedCharges: item.fixedCharges,
      perCharges: item.perCharges,
      // cgst: item.cgst,
      // sgst: item.sgst,
      // igst: item.igst,
      effectiveFrom: Moment(item.effectiveFrom.split(" ")[0]).format(
        "DD/MM/YYYY"
      ),
      isActive: item.isActive.toString(),
      createdByName: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD/MM/YYYY  hh:mm:ss a"),
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title="Packaging Charges"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                Add Charges
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            packChargeItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>
        <Modal
          title={
            this.state.packageChargeID === 0
              ? "Add Package Charges"
              : this.state.isEnabled
              ? "View Package Charges"
              : "Edit Package Charges"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                serviceName,
                fixedCharges,
                perCharges,
                // cgst,
                // sgst,
                // igst,
                effectiveFrom,
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Service Name "
                    name={"serviceName"}
                    rules={[
                      { required: true, message: "Please Select Service Name" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Service Name"
                      onChange={this.RestoreDDlValue}
                      disabled={this.state.isEnabled}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.serviceListOptions.map((service) =>
                        service.value !== "-1" ? (
                          <option key={service.value} value={service.value}>
                            {service.text}
                          </option>
                        ) : null
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Fixed Charges "
                    name={"fixedCharges"}
                    validateFirst
                    dependencies={["perCharges"]}
                    rules={[
                      { required: true, message: "Please Enter Fixed Charges" },
                      {
                        pattern: RegexDecimalAndRestrict,
                        message: "Please Enter Digits Only",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            parseFloat(value) > 0 ||
                            parseFloat(getFieldValue("perCharges")) > 0
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Either Fixed Charges or Percentage Charges must be greater than 0"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter Fixed Charge "
                      name="fixedCharges"
                      disabled={this.state.isEnabled}
                      maxlength={21}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Percentage Charges "
                    name={"perCharges"}
                    validateFirst
                    dependencies={["fixedCharges"]}
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Percentage Charge",
                      },
                      {
                        pattern: RegexDecimalAndRestrict,
                        message: "Please Enter Digits Only",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (
                            parseFloat(value) > 0 ||
                            parseFloat(getFieldValue("fixedCharges")) > 0
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Either Fixed Charges or Percentage Charges must be greater than 0"
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter Percentage Charge "
                      name="perCharges"
                      maxlength={21}
                      disabled={this.state.isEnabled}
                      onChange={this.handleInputOnChange}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={8}>
                <Form.Item
                  label="CGST"
                  name={"cgst"}
                  rules={[
                    { required: true, message: "Please Enter CGST" },
                    {
                      pattern: RegexDecimal,
                      message: "Please Enter Valid Number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter CGST "
                    name="cgst"
                    disabled={this.state.isEnabled}
                    onChange={this.handleInputOnChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  label="SGST"
                  name={"sgst"}
                  rules={[
                    { required: true, message: "Please Enter SGST" },
                    {
                      pattern: RegexDecimal,
                      message: "Please Enter Valid Number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter SGST "
                    name="sgst"
                    disabled={this.state.isEnabled}
                    onChange={this.handleInputOnChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  label="IGST"
                  name={"igst"}
                  rules={[
                    { required: true, message: "Please Enter IGST !" },
                    {
                      pattern: RegexDecimal,
                      message: "Please Enter Valid Number",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter IGST "
                    name="igst"
                    disabled={this.state.isEnabled}
                    onChange={this.handleInputOnChange}
                  />
                </Form.Item>
              </Col> */}
                <Col xs={24}>
                  <Form.Item
                    label="Effective From"
                    name={"effectiveFrom"}
                    rules={[{ required: true, message: "Please Select Date" }]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      format={"DD-MM-YYYY"}
                      onChange={(date) =>
                        this.handleDateChange(date, "effectiveFrom")
                      }
                      disabled={this.state.isEnabled}
                      disabledDate={(currentDate) =>
                        currentDate.isBefore(Moment(), "day") ? true : false
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          htmlType="submit"
                          variant="purple"
                          disabled={this.state.disableUpdateBtn}
                        >
                          {this.state.packageChargeID === 0 ? "Add" : "Update"}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
