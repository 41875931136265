import React from "react";
import FactoryMaster from "../../../components/Master/Factory/factorymaster.component";
/**
 * Factory Master Screen
 *
 * @author Niraj Gohel
 * @created 16 June 2020
 */
export default class FactoryMasterScreen extends React.Component {
  render() {
    return (
      <div>
        <FactoryMaster></FactoryMaster>
      </div>
    );
  }
}
