import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class PaytmTransService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
  }

  async getEDCMachineInfo() {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({
      name: this.endpoint.PAYTM_EDC_MACHINE_INFO,
    });
    let append =
      "?id=" +
      parseInt(localStorage.getItem("branchID")) +
      "&platform=WEB";
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.PAYTM_EDC_MACHINE_INFO]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          shouldWait = true;
        }
        console.log(error);
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.getEDCMachineInfo();
      });
    }
    return Promise.resolve(itemArray);
  }

  async EDCMachineRequest(postData) {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({ name: this.endpoint.PAYTM_EDC_TRANS_REQUEST });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.PaytmEDCTransRequest.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.EDCMachineRequest(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async EDCMachineStatus(postData) {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({ name: this.endpoint.PAYTM_EDC_TRANS_STATUS });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.PaytmEDCTransStatus.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.EDCMachineStatus(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async PaytmEDCTransInfo(invoiceId) {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({
      name: this.endpoint.PAYTM_EDC_TRANS_INFO,
    });
    let append = "?id=" + invoiceId + "&platfrom=WEB";
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.PAYTM_EDC_TRANS_INFO]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          shouldWait = true;
        }
        console.log(error);
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.PaytmEDCTransInfo(invoiceId);
      });
    }
    return Promise.resolve(itemArray);
  }

  async QRCodeRequest(postData) {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({ name: this.endpoint.QR_CODE_GENERATOR });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.QRCodeGenerator.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.QRCodeRequest(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async DynamicQRCodeRequest(postData) {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({ name: this.endpoint.DYNAMICQR_CODE_GENERATOR });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.DynamicQRCodeGenerator.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.DynamicQRCodeRequest(postData);
      });
    }
    return Promise.resolve(itemArray);
  }


  async QRCodeStatusCheck(postData) {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({ name: this.endpoint.QR_CODE_STATUS_CHECK });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.QRCodeStatusCheck.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.EDCMachineStatus(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async CheckPaymentTransactionStatus(postData) {
    const myApi = new API({ url: this.config.PAYTM_URL });
    myApi.createEntity({ name: this.endpoint.CHECK_PAYMENT_TRANSACTION_STATUS });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.CheckPaymentTransactionStatus.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.CheckPaymentTransactionStatus(postData);
      });
    }
    return Promise.resolve(itemArray);
  }


  async CheckPhonePeStatus(postData) {
    const myApi = new API({ url: this.config.PHONEPE_URL });
    myApi.createEntity({ name: this.endpoint.CHECK_PHONEPE_STATUS });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.CheckPhonePeStatus.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.CheckPhonePeStatus(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleError(error) {
    console.log(error.message);
  }
}
