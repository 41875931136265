import React from "react";
import StandardRateCard from "../../components/StandardRateCard/standardratecard.component";

export default class StandardRateCardScreen extends React.Component {
  render() {
    return (
      <div>
        <StandardRateCard />
      </div>
    );
  }
}
