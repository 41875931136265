import React, { Component } from "react";
import jwt from "jsonwebtoken";
import {
  // BrowserRouter as Router,
  Route,
  // useLocation,
  Switch,
  // NavLink,
  // browserHistory,
  // withRouter,
  Redirect,
} from "react-router-dom";
import Login from "./screens/Login/login.screen";
// import Session from "./Session";
import Dashboard from "./screens/Dashboard/dashboard.screen";
import BlankScreen from "./screens/BlankPage/blank.screen";
import WithHeaderSidebar from "./components/Common/WithHeaderSidebar/WithHeaderSidebar";
import Box from "./components/Common/Box/box";
import UserList from "./screens/Master/User/userlist.screen";
import CityMaster from "./screens/Master/City/citymaster.screen";
import StateMaster from "./screens/Master/State/statemaster.screen";
import DesignationMaster from "./screens/Master/Designation/designationmaster.screen";
import BrandMaster from "./screens/Master/Brand/brandmaster.screen";
import SizeMaster from "./screens/Master/Size/sizemaster.screen";
import KYCDocTypeMaster from "./screens/Master/KYCDocType/kycdoctypemaster.screen";
import ServiceMaster from "./screens/Master/Service/servicemaster.screen";
import GarmentMaster from "./screens/Master/Garment/garmentmaster.screen";
import GarmentColorMaster from "./screens/Master/GarmentColor/garmentcolormaster.screen";
import RewashReasonMaster from "./screens/Master/RewashReason/rewashreasonmaster.screen";
import OperationInstructionMaster from "./screens/Master/OperationInstruction/operationinstructmaster.screen";
import DeliveryChargesMaster from "./screens/Master/DeliveryCharges/deliverychargemaster.screen";
import RewardsScreen from "./screens/Master/Rewards/rewards.screen";
import PackagingChargesMaster from "./screens/Master/PackagingCharges/packagechargemaster.screen";
import ExpenseTypeMaster from "./screens/Master/ExpenseType/expensetypemaster.screen";
import FactoryMaster from "./screens/Master/Factory/factorymaster.screen";
import AddFactory from "./components/Master/Factory/AddFactory/addfactory.component";
import CustomerMaster from "./screens/Master/Customer/customermaster.screen";
import AddCustomer from "./components/Master/Customer/AddCustomer/addcustomer.component";
import ROUTES from "./routes";
import StoreList from "./screens/Master/Store/storelist.screen";
import CreateUser from "./screens/Master/User/createuser.screen";
import CreateStore from "./screens/Master/Store/createstore.screen";
// import MultiLanguage from "./screens/MultiLanguage";
import Test from "./test";
import RateCardList from "./screens/RateCard/ratecardlist.screen";
import CreateRateCard from "./screens/RateCard/createratecard.screen";
import TimeCardList from "./screens/TimeCard/timecardlist.screen";
import CreateTimeCard from "./screens/TimeCard/createtimecard.screen";
import RoleScreenMapping from "./screens/Role/RoleScreenMapping/rolescreenmapping.screen";
import RoleMasterScreen from "./screens/Role/RoleMaster/rolemaster.screen";
import CorporateMaster from "./screens/Master/Corporate/corporatemaster.screen";
import AddCorporate from "./components/Master/Corporate/AddCorporate/addcorporate.component";
import StoreInventory from "./screens/StoreModule/Inventory/inventory.store.screen";
import ExpenseEntryScreen from "./screens/StoreModule/ExpenseEntry/expenseentry.store.screen";
import GatePassListScreen from "./screens/StoreModule/GatePassList/gatepasslist.store.screen";
import PreQCResponseListScreen from "./screens/StoreModule/PreQCResponseList/preqcresponselist.store.screen";
import EditPreQCStore from "./components/StoreModule/PreQCResponseList/EditPreQCStore/editpreqcstore.component";
import ReadyForDeliveryOrdersScreen from "./screens/StoreModule/ReadyForDeliveryOrders/readyfordeliverysplitorders.store.screen";
import TransferOutStore from "./screens/StoreModule/TransferOut/transferout.store.screen";
import TransferInStore from "./screens/StoreModule/TransferIn/transferin.store.screen";
import StoreDashboard from "./screens/StoreModule/Dashboard/dashboard.screen";
import FactoryGatePassListScreen from "./screens/FactoryModule/FactoryGatepassList/factorygatepasslist.factory.screen";
import TransferInScreen from "./screens/FactoryModule/TransferIn/transferin.factory.screen";
import TransferOutScreen from "./screens/FactoryModule/TransferOut/transferout.factory.screen";
import FactoryInventoryScreen from "./screens/FactoryModule/Inventory/inventory.factory.screen";
import PreQCFactory from "./screens/FactoryModule/PreQC/preqc.factory.screen";
import AddPreQCFactory from "./components/FactoryModule/PreQC/AddPreQCFactory/addpreqcfactory.component";
import PostQCFactory from "./screens/FactoryModule/PostQC/postqc.factory.screen";
import AddPostQCFactory from "./components/FactoryModule/PostQC/AddPostQC/addpostqcfactory.component";
import FactoryDashboard from "./screens/FactoryModule/Dashboard/dashboard.screen";
import DiscountMaster from "./screens/DiscountMaster/discountmaster.screen";
import AddDiscountMaster from "./components/DiscountMaster/AddDiscountMaster/adddiscountmaster.component";
import TermsAndConditions from "./screens/TermsAndConditions/termsAndConditions.screen";
import PackageConfiguration from "./screens/LKWallet/PackageConfiguration/packageconfiguration.screen";
import RefferalBonusConfiguration from "./screens/LKWallet/RefferalBonusConfiguration/refferalbonusconfiguration.screen";
import Compensation from "./screens/LKWallet/Compensation/compensation.screen";
import EmployeeCredit from "./screens/LKWallet/EmployeeCredit/employeecredit.screen";
import Customerbulkdebits from "./screens/LKWallet/CustomerBulkDebit/customerbulkdebit.screen";
import Customerbulkcredit from "./screens/LKWallet/CustomerBulkCredit/customerbulkcredit.screen";
import Refund from "./screens/LKWallet/Refund/refund.screen";
import AdvancePayment from "./screens/LKWallet/AdvancePayment/advancepayment.screen";
import Faq from "./screens/Faq/faq.screen";

import CashCoupon from "./screens/Master/CashCoupon/cashcouponmaster.screen";
import AddCashCoupon from "./components/Master/CashCoupon/AddCashcouponMaster/addcashcouponmaster.component";

import CreateOrder from "./screens/StoreModule/Order/CreateOrder/createorder.screen";

import PickupScreen from "./screens/StoreModule/Pickup/CreatePickup/pickuplist.screen";
import AddPickup from "./components/StoreModule/Pickup/CreatePickup/AddPickup/addpickup.component";
import ResortingAndPackagingScreen from "./screens/FactoryModule/ResortingAndPackaging/ResortingAndPackaging.screen";
import StikerPrint from "./components/FactoryModule/ResortingAndPackaging/printstikerprint";
import NoticeBoard from "./screens/NoticeBoard/noticeboard.screen";
import Appsflyer from "./screens/AppsFlyer/appsflyer.screen";
import OrderListing from "./screens/StoreModule/Order/orderlist.screen";
import DeliveryList from "./screens/StoreModule/DeliveryList/deliverylist.store.screen";
import Banner from "./screens/Banner/banner.screen";
import Invoice from "./screens/StoreModule/Invoice/invoice.screen";
import BuyPackage from "./screens/LKWallet/BuyPackage/buypackage.screen";

import FingerScanScreen from "./screens/FingerScan/collectFingerPrint.screen";
import AttendanceScreen from "./screens/Attendance/attendance.screen";
import AttendanceReport from "./screens/Attendance/attendanceReport.screen";
import ResortingAndPackagingList from "./screens/FactoryModule/ResortingAndPackagingList/ResortingAndPackaging.screen";

import CashCollectionScreen from "./screens/CashCollection/cashcollection.screen";
import BulkUploadCouponCodesScreen from "./screens/BulkUploadCouponCodes/bulkuploadcouponcodes.screen";

import StandardRateCardScreen from "./screens/StandardRateCard/standardratecard.screen";
import ServiceIconsMaster from "./screens/Master/ServiceIncons/serviceicons.screen";
import RedirectInvalidRequestScreen from "./screens/RedirectInvalidReqestMessage";
import PartialOrderReceivedScreen from "./screens/StoreModule/PartialOrderReceived/partialorder.screen";
// import FactoryGatePassScreen from "./screens/FactoryModule/GatePassList/gatepasslist.factory.screen";
import Verification from "./components/Master/Customer/AddCustomer/emailverification.component";
import PaytmEDCMachineConfigScreen from "./screens/PaytmEDCMachineConfig/paytmedcmachineconfig.screen";
import HolidayConfigurationScreen from "./screens/HoliDay/holidayconfiguration.screen";
import StoreOrderhistoryscreen from "./screens/StoreModule/OrderHistory/orderhistory.store.screen";
import CustomBulkNotification from "./screens/LKWallet/CustomBulkNotification/customebulknotification.screen";
import StoreInPending from "./screens/StoreModule/StoreInPending/storeinpending.store.screen";
import FactoryInPending from "./screens/FactoryModule/FactoryInPending/factoryinpending.factory.screen";
import GarmentProcessing from "./screens/FactoryModule/Processing/garmentprocessing.factory.screen";
import ProcessingList from "./screens/FactoryModule/Processing/processinglist.factory.screen";

import DeliveryDashboard from "./screens/StoreModule/DeliveryDashboard/deliverydashboard.screen";
import Pendingcashsummaryscreen from "./screens/StoreModule/Audit/pendingcashsummary.screen";
import OrderSummary from "./screens/StoreModule/Audit/ordersummary.screen";
import Inventorysumarryscreen from "./screens/FactoryModule/Inventory/inventorysumarry.screen";
import OutOfCoverage from "./screens/StoreModule/Audit/outofcoverage.screen";
import PickupDeliveryAssignment from "./screens/StoreModule/Audit/pickupdeliveryassignment.screen";
import Dailyordersummaryscreen from "./screens/StoreModule/Audit/dailyordersummary.screen";
import Dailypickupsummaryscreen from "./screens/StoreModule/Audit/dailypickupsummary.screen";
import Monthlypickupsummaryscreen from "./screens/StoreModule/Audit/monthlypickupsummary.screen";
import Monthlyordersummaryscreen from "./screens/StoreModule/Audit/monthlyordersummary.screen";
import Expressfactoryscreen from "./screens/FactoryModule/DailySheet/express.factory.screen";
import RewashFactoryScreen from "./screens/FactoryModule/DailySheet/rewash.factory.screen";
import FivestarFactoryScreen from "./screens/FactoryModule/DailySheet/fivestar.factory.screen";
import ViewOnMap from "./screens/StoreModule/ViewOnMap/viewonmap.screen";


const verifyToken = () => {
  try {
    var decoded = jwt.verify(localStorage.getItem("currentuser"));
    if (decoded.exp < (new Date().getTime() + 1) / 1000) {
      return false;
    } else {
      return true;
    }
  } catch (err) {
    return false;
  }
};

const PrivateRoute = ({ component: Component, children, ...rest }) => (
  // <Route
  //   {...rest}
  //   render={(props) =>
  //     verifyToken() === false ? <Component {...props} /> : <Redirect to="/" />
  //   }
  // >
  <Route
    {...rest}
    render={(props) =>
      verifyToken() === false ? children : <Redirect to="/" />
    }
  />
);
export default class Navigation extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route path={ROUTES.LOGIN} component={Login} exact>
            <Login {...this.props} />
          </Route>
          <Route path={ROUTES.VERIFICATION} exact component={Verification}>
            <Verification {...this.props} />
          </Route>
          <PrivateRoute path={ROUTES.FACTORY_DASHBOARD} exact>
            <WithHeaderSidebar>
              <FactoryDashboard />
            </WithHeaderSidebar>
          </PrivateRoute>
          <PrivateRoute
            path={ROUTES.CREATE_ORDER}
            component={CreateOrder}
            exact
          >
            <WithHeaderSidebar>
              <CreateOrder />
            </WithHeaderSidebar>
          </PrivateRoute>
          <PrivateRoute
            path={[ROUTES.UPDATE_ORDER, ":orderID"].join("/")}
            exact
            component={CreateOrder}
          >
            <WithHeaderSidebar>
              <CreateOrder />
            </WithHeaderSidebar>
          </PrivateRoute>

          <PrivateRoute
            path={ROUTES.FACTORY_Resorting_Packaging}
            exact
            component={ResortingAndPackagingScreen}
          >
            <WithHeaderSidebar>
              <ResortingAndPackagingScreen {...this.props} />
            </WithHeaderSidebar>
          </PrivateRoute>
          <PrivateRoute path={ROUTES.SPLASH} exact component={BlankScreen}>
            <WithHeaderSidebar>
              <BlankScreen {...this.props} />
            </WithHeaderSidebar>
          </PrivateRoute>
          <PrivateRoute
            path={ROUTES.STORE_DASHBOARD}
            exact
            component={StoreDashboard}
          >
            <WithHeaderSidebar>
              <StoreDashboard {...this.props} />
            </WithHeaderSidebar>
          </PrivateRoute>

          <PrivateRoute path={ROUTES.BUY_PACKAGE} exact component={BuyPackage}>
            <WithHeaderSidebar>
              <BuyPackage {...this.props} />
            </WithHeaderSidebar>
          </PrivateRoute>
          <WithHeaderSidebar {...this.props}>
            <Box>
              <PrivateRoute path="/test" exact component={Test}>
                <Test {...this.props}></Test>
              </PrivateRoute>
              {/* <PrivateRoute path="/multiLanguage" exact component={MultiLanguage}>
                <MultiLanguage {...this.props}></MultiLanguage>
              </PrivateRoute> */}
              <PrivateRoute path={ROUTES.DASHBOARD} exact component={Dashboard}>
                <Dashboard {...this.props}></Dashboard>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.DELIVERY_DASHBORAD}
                exact
                component={DeliveryDashboard}
              >
                <DeliveryDashboard {...this.props}></DeliveryDashboard>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ORDER_LISTING}
                exact
                component={OrderListing}
              >
                <OrderListing {...this.props} />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.USER} exact component={UserList}>
                <UserList {...this.props}></UserList>
              </PrivateRoute>
              <PrivateRoute path={ROUTES.ADD_USER} exact component={CreateUser}>
                <CreateUser {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_USER, ":userID"].join("/")}
                exact
                component={CreateUser}
              >
                <CreateUser {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_USER, ":userID"].join("/")}
                exact
                component={CreateUser}
              >
                <CreateUser {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.CITY_MASTER}
                exact
                component={CityMaster}
              >
                <CityMaster {...this.props}></CityMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.STATE_MASTER}
                exact
                component={StateMaster}
              >
                <StateMaster {...this.props}></StateMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.DESIGNATION_MASTER}
                exact
                component={DesignationMaster}
              >
                <DesignationMaster {...this.props}></DesignationMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.BRAND_MASTER}
                exact
                component={BrandMaster}
              >
                <BrandMaster {...this.props}></BrandMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.SIZE_MASTER}
                exact
                component={SizeMaster}
              >
                <SizeMaster {...this.props}></SizeMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.KYCDOCTYPE_MASTER}
                exact
                component={KYCDocTypeMaster}
              >
                <KYCDocTypeMaster {...this.props}></KYCDocTypeMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.SERVICE_MASTER}
                exact
                component={ServiceMaster}
              >
                <ServiceMaster {...this.props}></ServiceMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.GARMENT_MASTER}
                exact
                component={GarmentMaster}
              >
                <GarmentMaster {...this.props}></GarmentMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.COLOR_MASTER}
                exact
                component={GarmentColorMaster}
              >
                <GarmentColorMaster {...this.props}></GarmentColorMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.REWASH_REASON_MASTER}
                exact
                component={RewashReasonMaster}
              >
                <RewashReasonMaster {...this.props}></RewashReasonMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.OPERATION_INSTRUCTION_MASTER}
                exact
                component={OperationInstructionMaster}
              >
                <OperationInstructionMaster {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.PACKAGE_CHARGES_MASTER}
                exact
                component={PackagingChargesMaster}
              >
                <PackagingChargesMaster
                  {...this.props}
                ></PackagingChargesMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.DELIVERY_CHARGES_MASTER}
                exact
                component={DeliveryChargesMaster}
              >
                <DeliveryChargesMaster {...this.props}></DeliveryChargesMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.REWARDS_CONFIG}
                exact
                component={RewardsScreen}
              >
                <RewardsScreen {...this.props}></RewardsScreen>
              </PrivateRoute>

              <PrivateRoute
                path={ROUTES.EXPENSE_TYPE_MASTER}
                exact
                component={ExpenseTypeMaster}
              >
                <ExpenseTypeMaster {...this.props}></ExpenseTypeMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.TRANSFER_IN_FACTORY}
                exact
                component={TransferInScreen}
              >
                <TransferInScreen {...this.props}></TransferInScreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.TRANSFER_OUT_FACTORY}
                exact
                component={TransferOutScreen}
              >
                <TransferOutScreen {...this.props}></TransferOutScreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.FACTORY_GARMENT_INVENTORY}
                exact
                component={FactoryInventoryScreen}
              >
                <FactoryInventoryScreen
                  {...this.props}
                ></FactoryInventoryScreen>
              </PrivateRoute>
              <PrivateRoute
                path={[
                  ROUTES.FACTORY_GARMENT_INVENTORY,
                  ":serviceID",
                  ":deliveryFromDate",
                  ":deliveryToDate",
                ].join("/")}
                exact
                component={FactoryInventoryScreen}
              >
                <FactoryInventoryScreen
                  {...this.props}
                ></FactoryInventoryScreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.INVENTORY_SUMMARY}
                exact
                component={Inventorysumarryscreen}
              >
                <Inventorysumarryscreen
                  {...this.props}
                ></Inventorysumarryscreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.STORE_INVENTORY}
                exact
                component={StoreInventory}
              >
                <StoreInventory {...this.props}></StoreInventory>
              </PrivateRoute>

              <PrivateRoute
                path={ROUTES.STORE_ORDER_HISTORY}
                exact
                component={StoreOrderhistoryscreen}
              >
                <StoreOrderhistoryscreen
                  {...this.props}
                ></StoreOrderhistoryscreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.FACTORY_ORDER_HISTORY}
                exact
                component={StoreOrderhistoryscreen}
              >
                <StoreOrderhistoryscreen
                  {...this.props}
                ></StoreOrderhistoryscreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.PENDING_CASH_SUMMARY}
                exact
                component={Pendingcashsummaryscreen}
              >
                <Pendingcashsummaryscreen
                  {...this.props}
                ></Pendingcashsummaryscreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ORDERS_SUMMARY}
                exact
                component={OrderSummary}
              >
                <OrderSummary {...this.props}></OrderSummary>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.OUT_OF_COVERAGE}
                exact
                component={OutOfCoverage}
              >
                <OutOfCoverage {...this.props}></OutOfCoverage>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.PICKUP_DELIVERY_ASSIGNMENT}
                exact
                component={PickupDeliveryAssignment}
              >
                <PickupDeliveryAssignment {...this.props}></PickupDeliveryAssignment>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.DAILY_ORDER_SUMMARY}
                exact
                component={Dailyordersummaryscreen}
              >
                <Dailyordersummaryscreen {...this.props}></Dailyordersummaryscreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.MONTHLY_ORDER_SUMMARY}
                exact
                component={Monthlyordersummaryscreen}
              >
                <Monthlyordersummaryscreen {...this.props}></Monthlyordersummaryscreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.DAILY_PICKUP_SUMMARY}
                exact
                component={Dailypickupsummaryscreen}
              >
                <Dailypickupsummaryscreen {...this.props}></Dailypickupsummaryscreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.MONTHLY_PICKUP_SUMMARY}
                exact
                component={Monthlypickupsummaryscreen}
              >
                <Monthlypickupsummaryscreen {...this.props}></Monthlypickupsummaryscreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.STORE_EXPENSE_ENTRY}
                exact
                component={ExpenseEntryScreen}
              >
                <ExpenseEntryScreen {...this.props}></ExpenseEntryScreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.STORE_GATE_PASS_LIST}
                exact
                component={GatePassListScreen}
              >
                <GatePassListScreen {...this.props}></GatePassListScreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.FACTORY_GATE_PASS_LIST}
                exact
                component={FactoryGatePassListScreen}
              >
                <FactoryGatePassListScreen
                  {...this.props}
                ></FactoryGatePassListScreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.STORE_PRE_QC_RESPONSE_LIST}
                exact
                component={PreQCResponseListScreen}
              >
                <PreQCResponseListScreen
                  {...this.props}
                ></PreQCResponseListScreen>
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_PRE_QC_STORE, ":qcId"].join("/")}
                exact
              >
                <EditPreQCStore />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_PRE_QC_STORE, ":qcId"].join("/")}
                exact
              >
                <EditPreQCStore />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.STORE_TRANSFER_OUT}
                exact
                component={TransferOutStore}
              >
                <TransferOutStore {...this.props}></TransferOutStore>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.STORE_TRANSFER_IN}
                exact
                component={TransferInStore}
              >
                <TransferInStore {...this.props}></TransferInStore>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.STORE_IN_PENDING}
                exact
                component={StoreInPending}
              >
                <StoreInPending {...this.props}></StoreInPending>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.FACTORY_IN_PENDING}
                exact
                component={FactoryInPending}
              >
                <FactoryInPending {...this.props}></FactoryInPending>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.STORE_READY_FOR_DELIVERY_ORDERS}
                exact
                component={ReadyForDeliveryOrdersScreen}
              >
                <ReadyForDeliveryOrdersScreen
                  {...this.props}
                ></ReadyForDeliveryOrdersScreen>
              </PrivateRoute>
              {/**
               * Start ::Factory Master Route
               * @author Niraj Gohel
               * @created 16 June 2020
               */}
              <PrivateRoute
                path={ROUTES.FACTORY_MASTER}
                exact
                component={FactoryMaster}
              >
                <FactoryMaster {...this.props}></FactoryMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ADD_FACTORY}
                exact
                component={AddFactory}
              >
                <AddFactory {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_FACTORY, ":factoryID"].join("/")}
                exact
                component={AddFactory}
              >
                <AddFactory {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_FACTORY, ":factoryID"].join("/")}
                exact
                component={AddFactory}
              >
                <AddFactory {...this.props} />
              </PrivateRoute>
              {/** End ::Factory Master Route */}
              {/**
               * Start ::Customer Master Route
               * @author Niraj Gohel
               * @created 25 June 2020
               */}
              <PrivateRoute
                path={ROUTES.CUSTOMER_MASTER}
                exact
                component={CustomerMaster}
              >
                <CustomerMaster {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ADD_CUSTOMER}
                exact
                component={AddCustomer}
              >
                <AddCustomer {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_CUSTOMER, ":customerID"].join("/")}
                exact
                component={AddCustomer}
              >
                <AddCustomer {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_CUSTOMER, ":customerID"].join("/")}
                exact
                component={AddCustomer}
              >
                <AddCustomer {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_ADDRESSES, ":customerID"].join("/")}
                exact
              >
                <AddCustomer {...this.props} />
              </PrivateRoute>
              {/** End ::Customer Master Route */}
              <PrivateRoute path={ROUTES.STORE} exact component={StoreList}>
                <StoreList {...this.props}></StoreList>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ADD_STORE}
                exact
                component={CreateStore}
              >
                <CreateStore {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_STORE, ":storeID"].join("/")}
                exact
                component={CreateStore}
              >
                <CreateStore {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_STORE, ":storeID"].join("/")}
                exact
                component={CreateStore}
              >
                <CreateStore {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.RATECARD}
                exact
                component={RateCardList}
              >
                <RateCardList {...this.props}></RateCardList>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ADD_RATECARD}
                exact
                component={CreateRateCard}
              >
                <CreateRateCard {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_RATECARD, ":rateCardID"].join("/")}
                exact
                component={CreateRateCard}
              >
                <CreateRateCard {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_RATECARD, ":rateCardID"].join("/")}
                exact
                component={CreateRateCard}
              >
                <CreateRateCard {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ROLE_SCREEN_MAPPING}
                exact
                component={RoleScreenMapping}
              >
                <RoleScreenMapping {...this.props}></RoleScreenMapping>
              </PrivateRoute>
              {/* added by PB */}
              <PrivateRoute
                path={ROUTES.ROLE_MASTER}
                exact
                component={RoleMasterScreen}
              >
                <RoleMasterScreen {...this.props}></RoleMasterScreen>
              </PrivateRoute>
              {/* ended by PB */}
              <PrivateRoute
                path={ROUTES.TIMECARD}
                exact
                component={TimeCardList}
              >
                <TimeCardList {...this.props}></TimeCardList>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ADD_TIMECARD}
                exact
                component={CreateTimeCard}
              >
                <CreateTimeCard {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_TIMECARD, ":timeCardID"].join("/")}
                exact
                component={CreateTimeCard}
              >
                <CreateTimeCard {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_TIMECARD, ":timeCardID"].join("/")}
                exact
                component={CreateTimeCard}
              >
                <CreateTimeCard {...this.props} />
              </PrivateRoute>
              {/**
               * Start ::Corporate Master Route
               * @author Niraj Gohel
               * @created 30 June 2020
               */}
              <PrivateRoute
                path={ROUTES.CORPORATE_MASTER}
                exact
                component={CorporateMaster}
              >
                <CorporateMaster {...this.props}></CorporateMaster>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ADD_CORPORATE}
                exact
                component={AddCorporate}
              >
                <AddCorporate {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_CORPORATE, ":corporateID"].join("/")}
                exact
                component={AddCorporate}
              >
                <AddCorporate {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_CORPORATE, ":corporateID"].join("/")}
                exact
                component={AddCorporate}
              >
                <AddCorporate {...this.props} />
              </PrivateRoute>
              {/** End ::Factory Master Route */}
              <PrivateRoute path={ROUTES.PRE_QC_FACTORY} exact>
                <PreQCFactory />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.ADD_PRE_QC_FACTORY} exact>
                <AddPreQCFactory {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_PRE_QC_FACTORY, ":tagNo"].join("/")}
                exact
                component={AddPreQCFactory}
              >
                <AddPreQCFactory {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_PRE_QC_FACTORY, ":tagNo"].join("/")}
                exact
                component={AddPreQCFactory}
              >
                <AddPreQCFactory {...this.props} />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.POST_QC_FACTORY} exact>
                <PostQCFactory />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.ADD_POST_QC_FACTORY} exact>
                <AddPostQCFactory {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_POST_QC_FACTORY, ":tagNo"].join("/")}
                exact
              >
                <AddPostQCFactory />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_POST_QC_FACTORY, ":tagNo"].join("/")}
                exact
              >
                <AddPostQCFactory />
              </PrivateRoute>
              {/* <PrivateRoute
                path={[ROUTES.VIEW_POST_QC_FACTORY, ":qcId"].join("/")}
                exact
              >
                <AddPostQCFactory />
              </PrivateRoute> */}
              <PrivateRoute
                path={ROUTES.DISCOUNT_MASTER}
                exact
                component={DiscountMaster}
              >
                <DiscountMaster {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ADD_DISCOUNT_MASTER}
                exact
                component={AddDiscountMaster}
              >
                <AddDiscountMaster {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_DISCOUNT_MASTER, ":discountID"].join("/")}
                exact
                component={AddDiscountMaster}
              >
                <AddDiscountMaster {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_DISCOUNT_MASTER, ":discountID"].join("/")}
                exact
                component={AddDiscountMaster}
              >
                <AddDiscountMaster {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_DISCOUNT_CODES, ":discountID"].join("/")}
                exact
              >
                <AddDiscountMaster {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.CASH_COUPON}
                exact
                component={CashCoupon}
              >
                <CashCoupon {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ADD_CASH_COUPON}
                exact
                component={AddCashCoupon}
              >
                <AddCashCoupon {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_CASH_COUPON, ":couponID"].join("/")}
                exact
                component={AddCashCoupon}
              >
                <AddCashCoupon {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_CASH_COUPON, ":couponID"].join("/")}
                exact
                component={AddCashCoupon}
              >
                <AddCashCoupon {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_CASHCOUPON_CODES, ":couponID"].join("/")}
                exact
              >
                <AddCashCoupon {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.PACKAGE_CONFIGURATION}
                exact
                component={PackageConfiguration}
              >
                <PackageConfiguration {...this.props}></PackageConfiguration>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.REFFERAL_BONUS_CONFIGURATION}
                exact
                component={RefferalBonusConfiguration}
              >
                <RefferalBonusConfiguration
                  {...this.props}
                ></RefferalBonusConfiguration>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.COMPENSATION_MASTER}
                exact
                component={Compensation}
              >
                <Compensation {...this.props}></Compensation>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.EMPLOYEE_CREDIT}
                exact
                component={EmployeeCredit}
              >
                <EmployeeCredit {...this.props}></EmployeeCredit>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.CUSTOMER_BULK_CREDIT}
                exact
                component={Customerbulkcredit}
              >
                <Customerbulkcredit {...this.props}></Customerbulkcredit>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.CUSTOMER_BULK_DEBIT}
                exact
                component={Customerbulkdebits}
              >
                <Customerbulkdebits {...this.props}></Customerbulkdebits>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.CUSTOM_BULK_NOTIFICATION_UPLOAD}
                exact
                component={CustomBulkNotification}
              >
                <CustomBulkNotification
                  {...this.props}
                ></CustomBulkNotification>
              </PrivateRoute>

              <PrivateRoute path={ROUTES.LKREFUND} exact component={Refund}>
                <Refund {...this.props}></Refund>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.ADVANCE_PAYMENT}
                exact
                component={AdvancePayment}
              >
                <AdvancePayment {...this.props}></AdvancePayment>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.PICKUP_LIST}
                exact
                component={PickupScreen}
              >
                <PickupScreen {...this.props}></PickupScreen>
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.VIEW_ON_MAP}
                exact
                component={ViewOnMap}
              >
                <ViewOnMap {...this.props}></ViewOnMap>
              </PrivateRoute>
              <PrivateRoute
                // path={ROUTES.ADD_PICKUP}
                path={[ROUTES.ADD_PICKUP, ":customerID"].join("/")}
                exact
                component={AddPickup}
              >
                <AddPickup {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.VIEW_PICKUP, ":pickupID"].join("/")}
                exact
                component={AddPickup}
              >
                <AddPickup {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.EDIT_PICKUP, ":pickupID"].join("/")}
                exact
                component={AddPickup}
              >
                <AddPickup {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.FACTORY_Resorting_Print}
                exact
                component={StikerPrint}
              >
                <StikerPrint {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.NOTICE_BOARD}
                exact
                component={NoticeBoard}
              >
                <NoticeBoard {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.APPSFLYER}
                exact
                component={Appsflyer}
              >
                <Appsflyer {...this.props} />
              </PrivateRoute>
              

              <PrivateRoute
                path={ROUTES.TERMS_AND_CONDITIONS}
                exact
                component={TermsAndConditions}
              >
                <TermsAndConditions {...this.props} />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.FAQ} exact component={Faq}>
                <Faq {...this.props} />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.BANNER} exact component={Banner}>
                <Banner {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.DELIVERY_LIST}
                exact
                component={DeliveryList}
              >
                <DeliveryList {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={[ROUTES.PROFORMA_INVOICE, ":splitOrderID"].join("/")}
                exact
                component={Invoice}
              >
                <Invoice {...this.props} />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.COLLECT_FINGERS} exact>
                <FingerScanScreen {...this.props} />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.ATTENDANCE} exact>
                <AttendanceScreen {...this.props} />
              </PrivateRoute>
              <PrivateRoute path={ROUTES.ATTENDANCEREPORT} exact>
                <AttendanceReport {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.CASH_COLLECTION}
                exact
                component={CashCollectionScreen}
              >
                <CashCollectionScreen {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.UPLOAD_CUSTOMER_TO_COUPON_MAPPING}
                exact
                component={BulkUploadCouponCodesScreen}
              >
                <BulkUploadCouponCodesScreen {...this.props} />
              </PrivateRoute>

              <PrivateRoute
                path={ROUTES.RESORTING_AND_PACKAGING}
                exact
                component={ResortingAndPackagingList}
              >
                <ResortingAndPackagingList {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.SERVICE_ICONS}
                exact
                component={ServiceIconsMaster}
              >
                <ServiceIconsMaster {...this.props} />
              </PrivateRoute>
              {/* <PrivateRoute
                path={ROUTES.FACTORY_GATE_PASS}
                exact
                component={FactoryGatePassScreen}
              >
                <FactoryGatePassScreen {...this.props}></FactoryGatePassScreen>
              </PrivateRoute> */}
              <PrivateRoute
                path={ROUTES.STANDARD_RATE_CARD}
                exact
                component={StandardRateCardScreen}
              >
                <StandardRateCardScreen {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.REDIRECT_INVALID_REQUEST}
                exact
                component={RedirectInvalidRequestScreen}
              >
                <RedirectInvalidRequestScreen {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.PARTIAL_ORDER_RECEIVED}
                exact
                component={PartialOrderReceivedScreen}
              >
                <PartialOrderReceivedScreen {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.PAYTM_EDC_MACHINE_CONFING}
                exact
                component={PaytmEDCMachineConfigScreen}
              >
                <PaytmEDCMachineConfigScreen {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.HOLIDAY_CONFIGURATION}
                exact
                component={HolidayConfigurationScreen}
              >
                <HolidayConfigurationScreen {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.GARMENT_PROCESSING}
                exact
                component={GarmentProcessing}
              >
                <GarmentProcessing {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.PROCESSING_LIST}
                exact
                component={ProcessingList}
              >
                <ProcessingList {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.EXPRESS_URGENT}
                exact
                component={Expressfactoryscreen}
              >
                <Expressfactoryscreen {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.REWASH}
                exact
                component={RewashFactoryScreen}
              >
                <RewashFactoryScreen {...this.props} />
              </PrivateRoute>
              <PrivateRoute
                path={ROUTES.FIVE_STARE}
                exact
                component={FivestarFactoryScreen}
              >
                <FivestarFactoryScreen {...this.props} />
              </PrivateRoute>
            </Box>
          </WithHeaderSidebar>
        </Switch>
        {/* <Session></Session> */}
      </div>
    );
  }
}
