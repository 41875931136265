import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import jwt from "jsonwebtoken";
import Common from "./common";

export default class faqservice  {
    constructor() {
        this.config = new Configuration();
        this.endpoint = new EndPointEntity();
        this.common = new Common();
      }
      handleError = () => {
        return null;
      };
     
    
      async deleteFaq(FaqIDs) {
        const myApi = new API({ url: this.config.ITEM_URL });
        myApi.createEntity({ name: this.endpoint.DELETE_FAQ });
        let itemArray = [];
        let ID = "?FaqIDs=" + FaqIDs;
        await myApi.endpoints.DeleteFaqByID.deleteById(ID)
          .then(({ data }) => {
            itemArray = data;
          })
          .catch((err) => {
            itemArray = this.common.handleError(err)
          });
        if (itemArray.statusCode == 401) {
          await this.common.refreshToken().then((response) => {
            this.common.setToken(response);
            itemArray = this.deleteFaq(FaqIDs);
          });
        }
        return Promise.resolve(itemArray);  
      }
    
      async addUpdateFaq(postData) {
        const myApi = new API({ url: this.config.ITEM_URL });
        myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_FAQ });
        let itemArray = [];
        await myApi.endpoints.SaveUpdateFaq.create(postData)
          .then(({ data }) => {
            itemArray = data;
          })
          .catch((err) => {
            itemArray = this.common.handleError(err)
          });
        if (itemArray.statusCode == 401) {
          await this.common.refreshToken().then((response) => {
            this.common.setToken(response);
            itemArray = this.addUpdateFaq(postData);
          });
        }
        return Promise.resolve(itemArray);
      }
    
      
    
      async getAllFaqList(
        index,
        size,
        sortColumn,
        sortOrder,
        search
      ) {
        const myApi = new API({ url: this.config.ITEM_URL });
        myApi.createEntity({ name: this.endpoint.GET_FAQ_LIST });
        let itemArray = [];
        await myApi.endpoints[this.endpoint.GET_FAQ_LIST]
          .getAllPost({
           faqID: null,
            pageIndex: index,
            pageSize: size,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            commonSearch: search,
          })
          .then(({ data }) => {
            itemArray = data;
          })
          .catch((err) => {
            itemArray = this.common.handleError(err);
          });
        if (itemArray.statusCode == 401) {
          await this.common.refreshToken().then((response) => {
            this.common.setToken(response);
            itemArray = this.getAllFaqList(
              index,
              size,
              sortColumn,
              sortOrder,
              search
            );
          });
        }
        return Promise.resolve(itemArray);
      }
}
