import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class FactoryPreQC {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async GetPreQCRequestList(param) {
    const stateListAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    stateListAPI.createEntity({ name: this.endpoint.FACTORY_GET_PRE_QC_REQUEST_LIST });
    let states = [];
    // let param = {

    // }
    await stateListAPI.endpoints.FactoryGetPreQCRequestList.getAllPost(param)
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.GetPreQCRequestList(param);
      });
    }
    return Promise.resolve(states);
  }

  async GetGarmentDetailByTagNo(tagNo) {
    const stateListAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_GARMENT_DETAIL_BY_TAGNO,
    });
    let states = [];
    let param = "?TagNo=" + tagNo;
    await stateListAPI.endpoints[this.endpoint.GET_GARMENT_DETAIL_BY_TAGNO]
      .getOne(param, {})
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.GetGarmentDetailByTagNo(tagNo);
      });
    }
    return Promise.resolve(states);
  }

  async getQCInfoByTagNumber(tagNo, QCPendingFrom) {
    const stateListAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_LAST_QCINFO_BY_TAG_NO,
    });
    let states = [];
    let param = "?TagNo=" + tagNo + "&QCPendingfrom=" + QCPendingFrom;
    await stateListAPI.endpoints[this.endpoint.GET_LAST_QCINFO_BY_TAG_NO]
      .getOne(param, {})
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getQCInfoByTagNumber(tagNo, QCPendingFrom);
      });
    }
    return Promise.resolve(states);
  }

  async OrderGarmentQCRequest(data) {
    const addUpdateAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    addUpdateAPI.createEntity({ name: this.endpoint.ORDER_GARMENT_QC_REQUEST });
    let returnVal = [];
    await addUpdateAPI.endpoints[this.endpoint.ORDER_GARMENT_QC_REQUEST]
      .create(data)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.OrderGarmentQCRequest(data);
      });
    }
    return Promise.resolve(returnVal);
  }

  async GetQCHistoryByTagNo(id) {
    const getFactoryAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    getFactoryAPI.createEntity({ name: this.endpoint.GET_QC_HISTORY_BY_TAGNO });
    let factory = [];
    let idParams = {
      params: {
        TagNo: id,
      },
    };
    await getFactoryAPI.endpoints[this.endpoint.GET_QC_HISTORY_BY_TAGNO]
      .getAll(idParams)
      .then((response) => {
        factory = response.data;
      })
      .catch((err) => {
        factory = this.common.handleError(err);
      });
    if (factory.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factory = this.GetQCHistoryByTagNo(id);
      });
    }
    return Promise.resolve(factory);
  }

  // async getAllFactories(
  //     index = 0,
  //     size = 0,
  //     sortColumn = null,
  //     sortOrder = null,
  //     search = null
  // ) {
  //     const allFactoriesAPI = new API({ url: this.config.ITEM_URL });
  //     allFactoriesAPI.createEntity({ name: this.endpoint.GET_ALL_FACTORIES });
  //     let factories = [];
  //     let payload = {
  //         factoryName: "",
  //         shortName: "",
  //         pageIndex: index,
  //         pageSize: size,
  //         sortColumn: sortColumn,
  //         sortOrder: sortOrder,
  //         commonSearch: search,
  //     };
  //     await allFactoriesAPI.endpoints[this.endpoint.GET_ALL_FACTORIES]
  //         .getAllPost(payload)
  //         .then((response) => {
  //             factories = response.data;
  //         })
  //         .catch((error) => {
  //             console.log(error);
  //         });
  //     return Promise.resolve(factories);
  // }
}
