import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  // Popconfirm,
  Table,
  notification,
  Tooltip,
  Checkbox,
  DatePicker,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faInfo,
  faChevronUp,
  // faInfo
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../routes";
import OrderService from "../../../services/createorderservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";

const { Search } = Input;
const { Option } = Select;
const TWO_MONTHS_AGO = new Date();
TWO_MONTHS_AGO.setMonth(TWO_MONTHS_AGO.getMonth() - 1);
/**
 * A factory master screen which holds grid view for master screens and form with steps
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 6 August 2020
 */
class Master extends React.Component {
  orderService = new OrderService();
  UserRightsConfiguration = new UserRightsConfiguration();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    showfilterBox: true,
    factories: [],
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    orderFromDate: moment(TWO_MONTHS_AGO),
    orderToDate: moment(),
    selectedBranch: localStorage.getItem("branchID"),
    OrderRequestStatusFilter: {
      data: [],
      selected: "1,2,3,4,5,6",
    },
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.ORDER_LIST
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }
  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Link
              to={{
                pathname: [ROUTES.UPDATE_ORDER, record.orderID].join("/"),
                state: { view: "view" },
              }}
            >
              <FontAwesomeIcon icon={faInfo} />
            </Link>
          ) : null}
          {record.storeID.toString() === localStorage.getItem("branchID") ? (
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isUpdate == true ? (
              <Tooltip title={labels.EDIT}>
                <Link to={[ROUTES.UPDATE_ORDER, record.orderID].join("/")}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Link>
              </Tooltip>
            ) : null
          ) : null}
        </Space>
      ),
    },
    {
      title: labels.ID,
      dataIndex: "orderID",
      key: "orderID",
      width: "3%",
      sorter: true,
    },
    {
      title: labels.PLATFORM,
      dataIndex: "platform",
      key: "platform",
      sorter: true,
    },
    {
      title: labels.ORDER_COUNT,
      dataIndex: "orderCount",
      key: "orderCount",
      sorter: true,
    },
    {
      title: labels.PICKUP_ID,
      dataIndex: "pickupID",
      key: "pickupID",
      sorter: true,
    },
    {
      title: labels.STORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
    {
      title: labels.PICK_BOY,
      dataIndex: "pickupBoy",
      key: "pickupBoy",
      sorter: true,
    },
    {
      title: labels.DELIVERY_TYPE,
      dataIndex: "deliveryType",
      key: "deliveryType",
      sorter: true,
    },
    {
      title: labels.REQUEST_CREATED_DATE,
      dataIndex: "requestCreateddatetime",
      key: "requestCreateddatetime",
      sorter: true,
      render: (text) => {
        return ![undefined, null, ""].includes(text)
          ? moment(text).format("DD-MM-YYYY HH:mm:ss")
          : "";
      },
    },
    {
      title: labels.PICKUP_DATE,
      dataIndex: "pickupDate",
      key: "pickupDate",
      sorter: true,
      render: (text) => {
        return ![undefined, null, ""].includes(text)
          ? moment(text).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: labels.PICKUP_TIMESLOT,
      dataIndex: "pickupSlotName",
      key: "pickupSlotName",
      sorter: true,
    },
    {
      title: labels.ORDER_CREATED_DATE,
      dataIndex: "orderCreatedDateTime",
      key: "orderCreatedDateTime",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm:ss");
      },
    },
    {
      title: labels.STATUS,
      dataIndex: "orderStatusName",
      key: "orderStatusName",
      sorter: true,
    },
    {
      title: labels.CUSTOMER,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },

    {
      title: labels.CUST_MO_No,
      dataIndex: "custMobileNo",
      key: "custMobileNo",
      sorter: true,
    },
    {
      title: labels.CUSTOMER_TYPE,
      dataIndex: "customerType",
      key: "customerType",
      sorter: true,
    },
    {
      title: labels.GARMENTS,
      dataIndex: "noOfGarment",
      key: "noOfGarment",
      sorter: true,
    },
    {
      title: labels.AMOUNT,
      dataIndex: "finalOrderAmount",
      key: "finalOrderAmount",
      sorter: true,
    },
    {
      title: labels.TYPE,
      dataIndex: "orderType",
      key: "orderType",
      sorter: true,
    },
    {
      title: labels.PROMO_CODE,
      dataIndex: "promocode",
      key: "promocode",
      sorter: true,
    },

    {
      title: labels.ON_DEMAND_WALK_IN,
      dataIndex: "ondemandWalkIn",
      key: "ondemandWalkIn",
      sorter: true,
    },

    {
      title: labels.DELIVERY_DATE,
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      sorter: true,
      render: (text) => {
        return ![undefined, null, ""].includes(text)
          ? moment(text).format("DD-MM-YYYY")
          : "";
      },
    },
  ];

  componentDidMount() {
    this.getOrderRequestStatusList();
    if (
      this.props.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      if (
        this.props.location.state.search !== undefined &&
        this.props.location.state.search !== null
      ) {
        //debugger;
        this.setState({ search: this.props.location.state.search }, () => {
          this.getOrderList(
            this.state.pagination.current,
            this.state.pagination.pageSize,
            this.state.sorter.field,
            this.state.sorter.order,
            this.state.search
          );
        });
      } else {
        this.getOrderList(
          this.state.pagination.current,
          this.state.pagination.pageSize,
          this.state.sorter.field,
          this.state.sorter.order,
          this.state.search
        );
      }
    } else {
      this.getOrderList(
        this.state.pagination.current,
        this.state.pagination.pageSize,
        this.state.sorter.field,
        this.state.sorter.order,
        this.state.search
      );
    }
  }

  getOrderRequestStatusList = () => {
    this.orderService.getOrderRequestStatusList().then((response) => {
      if (response.statusCode === 200) {
        let data = response.data.map((single) => {
          return {
            value: single.enumDetailID,
            label: single.enumDetailName,
          };
        });
        this.setState({
          OrderRequestStatusFilter: {
            ...this.state.OrderRequestStatusFilter,
            data: data,
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? response.message
            : labels.UNABLE_TO_GET_DATA,
        });
      }
    });
  };

  getOrderList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    if (!this.state.tableLoading) {
      this.setState({ tableLoading: true });
      this.orderService
        .getOrderList(
          index,
          size,
          sortColumn,
          sortOrder,
          search,
          this.state.selectedBranch,
          this.state.OrderRequestStatusFilter.selected,
          moment(this.state.orderFromDate).format("YYYY-MM-DD"),
          moment(this.state.orderToDate).format("YYYY-MM-DD"),
        )
        .then((response) => {
          if (response.statusCode === 200) {
            if (
              response.data.length === 0 &&
              this.state.pagination.current !== 1
            ) {
              this.setState(
                {
                  pagination: { ...this.state.pagination, current: 1 },
                  tableLoading: false,
                },
                () => {
                  this.getOrderList(1, size, sortColumn, sortOrder, search);
                }
              );
            }
            let factoryData = [];
            response.data.map((singleData) => {
              singleData.key = singleData.factoryID;
              singleData.openingDate = moment(singleData.openingDate).format(
                "YYYY-MM-DD"
              );
              singleData.operatingTimeFrom = moment(
                singleData.operatingTimeFrom,
                "H:mm"
              ).format("h:mm a");
              singleData.operatingTimeTo = moment(
                singleData.operatingTimeTo,
                "H:mm"
              ).format("h:mm a");
              factoryData.push(singleData);
              return null;
            });
            this.setState({ factories: factoryData });
            console.log("factoryData->",factoryData);
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: factoryData.length ? factoryData[0].totalRecords : 0,
              },
            });
          } else {
            notification["error"]({
              message: labels.ORDERS,
              description: response.message
                ? response.message
                : labels.UNABLE_TO_GET_LIST_OF_ORDERS,
            });
          }
          this.setState({ tableLoading: false });
        });
    }
  };

  handleChangeBranch = () => {
    this.getOrderList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  render() {
    const storeBranchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo")).filter(
          (x) => x.branchType === "Store"
        )
      : [];

    const allStoreBranchArray = storeBranchTypes
      .map((branchType) => {
        return branchType.branchID;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();
    return (
      <div>
        <PageHeader
          title={labels.MANAGE_ORDERS}
          extra={[
            <div key="0" style={{ alignSelf: "center" }}>
              <Select
                showSearch
                mode="multiple"
                allowClear
                placeholder={labels.SELECT_STATUS}
                className={styles.test1}
                style={{ minWidth: 150, maxWidth: 250 }}
                value={
                  [null, undefined, "", []].includes(
                    this.state.OrderRequestStatusFilter.selected
                  )
                    ? undefined
                    : this.state.OrderRequestStatusFilter.selected.split(",")
                }
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onChange={(value) =>
                  this.setState(
                    {
                      OrderRequestStatusFilter: {
                        ...this.state.OrderRequestStatusFilter,
                        selected: [null, undefined, ""].includes(value)
                          ? undefined
                          : value.join(","),
                      },
                    },
                    () => {
                      this.getOrderList(
                        this.state.pagination.current,
                        this.state.pagination.pageSize,
                        this.state.sorter.field,
                        this.state.sorter.order,
                        this.state.search
                      );
                    }
                  )
                }
              >
                {/* {this.state.OrderRequestStatusFilter.length !== 0? */}
                {
                  this.state.OrderRequestStatusFilter.data.map((x) => {
                    return x.value != "-1" ? (
                      <option
                        key={x.value.toString()}
                        value={x.value.toString()}
                      >
                        {x.label}
                      </option>
                    ) : null;
                  })
                  // :null
                  // }
                }
              </Select>
            </div>,
            <div key="1" style={{ alignSelf: "center" }}>
              <Select
                showSearch
                mode="multiple"
                allowClear
                // onChange={this.handleSelect}
                onChange={(event) => {
                  this.setState(
                    {
                      selectedBranch: [null, undefined, ""].includes(event)
                        ? ""
                        : event.join(","),
                    },
                    () => {
                      this.handleChangeBranch();
                    }
                  );
                }}
                value={
                  [null, undefined, ""].includes(this.state.selectedBranch)
                    ? [localStorage.getItem("branchID")]
                    : this.state.selectedBranch.split(",")
                }
                placeholder={labels.SELECT_STORE}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ minWidth: 150, maxWidth: 330 }}
              >
                {storeBranchTypes.length !== 0
                  ? storeBranchTypes.map((branchType) => {
                      return branchType.branchID !== "-1" ? (
                        <Option
                          value={branchType.branchID.toString()}
                          key={branchType.branchID.toString()}
                        >
                          {branchType.branchName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </div>,
            <div key="2" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={
                  ![null, undefined, ""].includes(this.state.selectedBranch) &&
                  this.state.selectedBranch.split(",").length ===
                    allStoreBranchArray.length &&
                  this.state.selectedBranch.split(",").slice().sort()
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    let allOpt = storeBranchTypes
                      .map((branchType) => {
                        return branchType.branchID;
                      })
                      .filter((x) => x !== "-1");
                    this.setState(
                      {
                        selectedBranch: allOpt.join(","),
                      },
                      () => {
                        if (this.state.selectedBranch !== "") {
                          this.handleChangeBranch();
                        }
                      }
                    );
                  } else {
                    this.setState(
                      {
                        selectedBranch: localStorage.getItem("branchID"),
                      },
                      () => {
                        this.handleChangeBranch();
                      }
                    );
                  }
                }}
              >
                {labels.ALL}
              </Checkbox>
            </div>,
            <div key="3" style={{ alignSelf: "center" }}>
              <Search
                placeholder={labels.SEARCH}
                value={this.state.search}
                className={styles.SearchInput}
                key="1"
                onChange={(event) => {
                  this.setState({
                    search: event.target.value.toString().toLowerCase(),
                  });
                  this.getOrderList(
                    this.state.pagination.current,
                    this.state.pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    event.target.value.toString().toLowerCase()
                  );
                }}
              />
            </div>,
            /**
             * Add Factory Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <div key="4" style={{ alignSelf: "center" }}>
                <Button
                  variant="purple"
                  htmlType="submit"
                  onClick={() => this.props.history.push(ROUTES.CREATE_ORDER)}
                  key="2"
                >
                  {labels.CREATE_ORDER}
                </Button>
              </div>
            ) : null,

            // <div key="4" style={{ alignSelf: "center" }}>
            <Button
              key="5"
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
              // key="5"
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.getOrderList(
                this.state.pagination.current,
                this.state.pagination.pageSize,
                this.state.sorter.field,
                this.state.sorter.order,
                this.state.search
              )
            }
            initialValues={{
              orderFromDate: this.state.orderFromDate,
              orderToDate: this.state.orderToDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderFromDate")
                    }
                    value={this.state.orderFromDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(moment(), "day") ? true : false
                    }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderToDate")
                    }
                    value={this.state.orderToDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>
        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getOrderList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
          scroll={
            this.columns.length < 4
              ? { x: 0 }
              : this.columns.length < 6
              ? { x: 1000 }
              : this.columns.length > 6 && this.columns.length <= 12
              ? { x: 1400 }
              : { x: 2000 }
          }
          // scroll={{ y: 'calc(100vh - 4em)' }}
          //  scroll={{ scrollToFirstRowOnChange: false }}
        />
      </div>
    );
  }
}

export default withRouter(Master);
