import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ColorService from "../../../services/garmentcolorservice";
import Moment from "moment";
import { ChromePicker } from "react-color";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { RegexLetters, MessageLetters } from "../../../shared/validator";

const { Search } = Input;
const { Option } = Select;

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.ColorService = new ColorService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.state = {
      colorItems: [],
      colorID: 0,
      colorName: "",
      changeColorHex: "#fff",
      color: {
        r: "0",
        g: "9",
        b: "153",
        // a:"1"
      },
      displayColorPicker: false,
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.GARMENT_COLOR
    );
    this.setState({ userRights: res });
  }

  columns = [
    // {
    //   title: "ColorID",
    //   dataIndex: "colorID",
    //   key: "colorID",
    // },
    {
      title: "Color Name",
      dataIndex: "colorName",
      key: "colorName",
      sorter: true,
    },
    {
      title: "Color Hex",
      dataIndex: "colorHex",
      key: "colorHex",
      sorter: true,
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    colorID: record.colorID,
                    colorName: record.colorName,
                    changeColorHex: record.colorHex,
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    colorID: record.colorID,
                    colorName: record.colorName,
                    changeColorHex: record.colorHex,
                  })
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.colorID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      colorID: this.state.colorID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      colorID: this.state.colorID,
      CommonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      colorID: this.state.colorID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.ColorService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            colorID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          colorItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Garment Color Master",
          description: "Unable to get list of colors data",
        });
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (colorID) => {
    this.ColorService.removeItems(colorID).then((items) => {
      if (items.result === "Success") {
        openNotificationWithIcon(
          "success",
          "Garment Color Master",
          items.message
        );
        const { pagination } = this.state;
        this.fetchItems({
          colorID: parseInt(this.state.colorID),
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
        });
      } else {
        openNotificationWithIcon(
          "error",
          "Garment Color Master",
          items.message
        );
      }
    });
  };

  handlePopupAdd = (event) => {
    var postData = {
      colorID: this.state.colorID,
      colorName: this.state.colorName,
      colorHex: this.state.changeColorHex,
      isActive: true,
    };
    this.ColorService.insertUpdateItems(postData).then((items) => {
      if (items.result === "Success") {
        openNotificationWithIcon(
          "success",
          "Garment Color Master",
          items.message
        );
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          colorID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        openNotificationWithIcon(
          "error",
          "Garment Color Master",
          items.message
        );
      }
    });
  };

  handleColorName = (e) => {
    let colorNm = e.target.value;
    this.setState({ colorName: colorNm });
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeColor = (color) => {
    this.setState({ color: color.rgb, changeColorHex: color.hex });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      colorID: 0,
      colorName: "",
      changeColorHex: "#fff",
      isEnabled: false,
      isVisible: false,
    });
  };

  render() {
    const popover = {
      position: "absolute",
      zIndex: "2",
    };
    const cover = {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    };
    const { colorName, changeColorHex, pagination, loading } = this.state;
    const colorItems = this.state.colorItems;
    const listItems = colorItems.map((item, index) => ({
      key: item.colorID,
      colorID: item.colorID,
      colorName: item.colorName,
      colorHex: item.colorHex,
      isActive: item.isActive.toString(),
      createdByName: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD/MM/YYYY hh:mm:ss a"),
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title="Garment Color Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                Add Color
              </Button>
            ) : null,

            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            colorItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>

        <Modal
          title={
            this.state.colorID === 0
              ? "Add Garment Color"
              : this.state.isEnabled
              ? "View Garment Color"
              : "Edit Garment Color"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Form
            layout="vertical"
            onFinish={this.handlePopupAdd}
            initialValues={{ colorName, changeColorHex }}
          >
            <Row gutter={24}>
              <Col xs={24}>
                <Form.Item
                  label="Color Name "
                  name="colorName"
                  rules={[
                    { required: true, message: "Please Enter Color Name!" },
                    {
                      pattern: RegexLetters,
                      message: MessageLetters,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Color Name"
                    disabled={this.state.isEnabled}
                    onChange={this.handleColorName}
                  />
                </Form.Item>
              </Col>

              <Col xs={24}>
                <Form.Item
                  label="Color Hex "
                  name="changeColorHex"
                  rules={[
                    { required: true, message: "Please Enter Color Hex!" },
                  ]}
                >
                  <Input
                    placeholder="Enter Color Hex"
                    value={changeColorHex}
                    readOnly
                    disabled={this.state.isEnabled}
                    // defaultValue={colorpicker}
                    onClick={this.handleClick}
                  />
                  {this.state.displayColorPicker ? (
                    <div style={popover}>
                      <div style={cover} onClick={this.handleClose} />
                      <ChromePicker
                        color={this.state.color}
                        disableAlpha
                        onChangeComplete={this.handleChangeColor}
                      />
                    </div>
                  ) : null}
                </Form.Item>
              </Col>

              <Col xs={24} className="custom-modal-footer">
                <Space>
                  {this.state.isVisible ? (
                    <>
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        Cancel
                      </Button>
                      <Button htmlType="submit" variant="purple">
                        {this.state.colorID === 0 ? "Add" : "Update"}
                      </Button>
                    </>
                  )}
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}
