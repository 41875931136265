import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class LKCustomerBulkCreditService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getcustomerbulkcreditdata(param) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.GET_CUSTOMER_BULK_CREDIT_MASTER });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.GET_CUSTOMER_BULK_CREDIT_MASTER]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getcustomerbulkcreditdata(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async uploadFile(param) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });
    myApi.createEntity({ name: this.endpoint.UPLOAD_CUSTOMER_BULK_CREDIT });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.UPLOAD_CUSTOMER_BULK_CREDIT]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.uploadFile(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getReport(param) {
    const myApi = new API({ url: this.config.ITEM_LKWALLET_URL });

    myApi.createEntity({
      name: this.endpoint.GET_CUSTOMER__BULK_CRDIT_FILE_REPORT,
    });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.GET_CUSTOMER__BULK_CRDIT_FILE_REPORT]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getReport(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}

export default LKCustomerBulkCreditService;
