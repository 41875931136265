import React, { Component } from "react";
import styles from "./dailysheet.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import {
  PageHeader,
  Input,
  Select,
  Table,
  Row,
  Col,
  Form,
  notification,
  Checkbox,
  Space,
  Tooltip,
  Modal,
  Spin,
  DatePicker,
} from "antd";
import { IsAuthenticated } from "../../../services/auth-header";
import Dailysheetservice from "../../../services/dailysheetservice";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Moment from "moment";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
import ReactExport from "react-export-excel";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faFileExcel,
  faInfo,
  // faPencilAlt,
  // faTrash,
} from "@fortawesome/free-solid-svg-icons";
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);

export default class RewashFactoryComponent extends Component {
  UserRightsConfiguration = new UserRightsConfiguration();
  Dailysheetservice = new Dailysheetservice();
  common = new Common();

  state = {
    showfilterBox: true,
    FromDate: Moment(ONE_MONTHS_AGO),
    ToDate: Moment(),
    showInfoPopup: false,
    rewashData: [],
    loading: true,
    printDate: Moment(),
    srNo: 0,
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.REWASH
    );
    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
  }

  count = 0;
  columns = [
    {
      title: "Sr.No",
      dataIndex: "srNumber",
      key: "srNumber",
      // align: 'center',
      render: (text, record, index) => {
        if (!record.isSummary) {
          this.count += 1;
        } else {
          this.count = null;
        }
        return this.count;
      },
    },
    {
      title: "Store Code",
      dataIndex: "shortName",
      key: "shortName",
    },
    {
      title: "Rewash Order ID",
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
    },
    {
      title: "Original Order ID",
      dataIndex: "rewashOrderID",
      key: "rewashOrderID",
      render: (text) => {
        return text != 0 ? text : "";
      },
    },
    {
      title: "Rewash Lot",
      dataIndex: "noOfGarment",
      key: "noOfGarment",
      width: "7%",
    },
    {
      title: "Rewash Remark",
      dataIndex: "rewashRemark",
      key: "rewashRemark",
    },

    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (text) => {
        return text ? Moment(text).format("YYYY-MM-DD") : "";
      },
    },

    {
      title: "Factory Remarks",
      dataIndex: "",
      key: "",
    },
  ];

  componentDidMount() {
    this.getRewashorderData();
  }

  getRewashorderData = () => {
    let Param = {};
    Param = {
      FromDate: Moment(this.state.FromDate).format("YYYY-MM-DD"),
      ToDate: Moment(this.state.ToDate).format("YYYY-MM-DD"),
    };
    this.setState({ loading: true });

    this.Dailysheetservice.getDailyRewashOrderData(Param).then((response) => {
      if (response.statusCode === 200) {
        this.setState({ rewashData: response.data });
      } else {
        notification["error"]({
          message: labels.REWASH,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_REWASh_DATA,
        });
      }
      this.setState({ loading: false });
    });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { loading, rewashData } = this.state;
    return (
      <div>
        <PageHeader
          title={labels.REWASH}
          extra={[
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
              key="3"
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />

        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() => {
              this.getRewashorderData();
              this.setState({
                printDate: this.state.ToDate,
              });
            }}
            initialValues={{
              FromDate: this.state.FromDate,
              ToDate: this.state.ToDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"FromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "FromDate")}
                    value={this.state.FromDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(Moment(), "day") ? true : false
                    // }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"ToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "ToDate")}
                    value={this.state.ToDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(Moment(), "day") ? true : false
                    // }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={2} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
              <Col xs={24} lg={9} className={styles.RewashPrintContainer}>
                <ReactToPrint
                  content={() => this.componentRef}
                  trigger={() => {
                    return (
                      // <a href="#">Print this out!</a>
                      <Button
                        // htmlType="submit"
                        variant="whitePink"
                        shape="round"
                        size="large"
                        // onClick={handlePrint}
                      >
                        Print
                      </Button>
                    );
                  }}
                ></ReactToPrint>
              </Col>
            </Row>
          </Form>
        </Filters>

        <PrintContainer
          ref={(el) => (this.componentRef = el)}
          rewashData={rewashData}
          columns={this.columns}
          loading={loading}
          printDate={this.state.printDate}
        />

        <style>{
          // print page style
          `
          .order-date{
            display : none;
          }

          
          @media print {
            .order-date{
              display : block;
              text-align : center;
            }

            @page {
              size: auto;
              margin: 0mm 0mm 0mm 5mm;
            }

            table thead {
              display: table-row-group !important;
            }
           
            table {
              width: 100%;
            }
               
            table,  th, td {
              border: 1px solid black !important;
              text-align: center !important;
              
            }
          }
          `
        }</style>
      </div>
    );
  }
}

export class PrintContainer extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        style={{
          textAlign: "center",
        }}
      >
        <h1 className="order-date">
          Rewash Orders {this.props.printDate.format("DD-MM-YYYY")}
        </h1>
        <Table
          className="dataTable"
          // ref={el => (this.componentRef = el)}
          size="small"
          onRow={(record, rowIndex) => {
            return {
              style: {
                background: record.isSummary ? "#a0c4e4" : null,
                fontWeight: record.isSummary ? "bold" : null,
                color: record.isSummary ? "black" : null,
              },
            };
          }}
          columns={this.props.columns}
          dataSource={this.props.rewashData}
          pagination={false}
          loading={this.props.loading}
        />
      </div>
    );
  }
}
