import React from "react";
import KYCForm from "./kycform.component";
import {
  Table,
  Space,
  Popconfirm,
  PageHeader,
  Typography,
  notification,
} from "antd";
import Button from "../../../Common/Button/button";
import styles from "./createuser.module.css";
import UserService from "../../../../services/userservice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};
/**
 * A tabular view as a part of create store - Slot Mapping, Slot List
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 2 June 2020
 *
 * @module User
 */
export default class UserKYC extends React.Component {
  /**
   * State variable to determine whether to display form or table
   */
  constructor(props) {
    super(props);
    this.userservice = new UserService();
    this.state = {
      tableLoading: false,
      isAdd: false,
      isEdit: false,
      isViewmode: false,
      disabled: false,
      kycid: 0,
      kycDocTypeID: 0,
      kycDocTypeList: [],
      kycDocTypeName: "",
      uploadedDocName: "",
      kycFormValues: {},
    };
  }

  componentDidMount() {
    this.setState({ isViewmode: this.props.isViewmode });
    this.getUserKYCDetails(this.props.userID);
  }

  getUserKYCDetails = (userID) => {
    this.setState({ tableLoading: true });
    this.userservice.getUserKYCDetails(userID).then((response) => {
      this.setState({ kycDocTypeList: response.data });
      this.setState({ tableLoading: false });
    });
  };

  deleteKYCByKYCID(kycID) {
    this.setState({ tableLoading: true });
    this.userservice.deleteKYCByKYCID(kycID).then((response) => {
      if (response.result === "Success") {
        openNotificationWithIcon("success", "Users", response.message);
      } else {
        openNotificationWithIcon("error", "Users", response.message);
      }
      this.getUserKYCDetails(this.props.userID);
      this.setState({ tableLoading: false });
    });
  }

  getKYCByKYCID(kycID) {
    this.setState({ tableLoading: true });
    this.userservice.getKYCByKYCID(kycID).then((response) => {
      this.setState({
        isEdit: true,
        kycid: kycID,
        kycDocTypeID: response.data.kycDocTypeID,
        kycDocTypeName: response.data.kycDocTypeName,
        uploadedDocName: response.data.uploadedDocName,
        kycFormValues: {
          kycDocTypeID: response.data.kycDocTypeID.toString(),
          uploadedDocName: [
            {
              uid: "1",
              name: response.data.uploadedDocName.split("/")[
                response.data.uploadedDocName.split("/").length - 1
              ],
              status: "done",
              url: response.data.uploadedDocName,
            },
          ],
        },
      });
      this.setState({ tableLoading: false });
    });
  }

  getViewKYCByKYCID(kycID) {
    this.setState({ tableLoading: true });
    this.userservice.getKYCByKYCID(kycID).then((response) => {
      this.setState({
        isView: true,
        disabled: true,
        kycid: kycID,
        kycDocTypeID: response.data.kycDocTypeID,
        kycDocTypeName: response.data.kycDocTypeName,
        kycFormValues: {
          kycDocTypeID: response.data.kycDocTypeID.toString(),
          uploadedDocName: [
            {
              uid: "1",
              name: "xxx.png",
              status: "done",
              response: "Server Error 500", // custom error message to show
              url: response.data.uploadedDocName,
            },
          ],
        },
      });
      this.setState({ tableLoading: false });
    });
  }

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: "KYC Doc Type",
      dataIndex: "kycDocTypeName",
      key: "kycDocTypeName",
    },
    {
      title: "Uploaded Doc Name",
      dataIndex: "uploadedDocName",
      key: "uploadedDocName",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={(event) => this.getViewKYCByKYCID(record.kycid)}
          >
            <FontAwesomeIcon icon={faInfo} />
          </Button>
          {!this.state.isViewmode ? (
            <Button
              type="link"
              onClick={(event) => this.getKYCByKYCID(record.kycid)}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          ) : null}
          {!this.state.isViewmode ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={(event) => this.deleteKYCByKYCID(record.kycid)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  /**
   * Data to be displayed in table
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   *
   */
  /**
   * A function to modify state varibles from child
   */
  bringback = () => {
    this.setState({
      isAdd: false,
      isEdit: false,
      isView: false,
      disabled: false,
      kycid: 0,
      kycFormValues: null,
    });
  };
  render() {
    return this.state.isAdd || this.state.isEdit || this.state.isView ? (
      <KYCForm
        backFunction={this.bringback}
        kycDocTypeList={this.props.data}
        userID={this.props.userID}
        kycid={this.state.kycid}
        kycDocTypeID={this.state.kycDocTypeID}
        kycDocTypeName={this.state.kycDocTypeName}
        isView={this.state.isView}
        disabled={this.state.disabled}
        isViewmode={this.props.isViewmode}
        values={this.state.kycFormValues}
        uploadedDocName={this.state.uploadedDocName}
        fileList={
          this.state.kycFormValues !== null
            ? this.state.kycFormValues.uploadedDocName
            : []
        }
      />
    ) : (
      <React.Fragment>
        {/**
         * Page Header Component to display title and button
         * https://ant.design/components/page-header/
         * https://ant.design/components/page-header/#API
         */}
        <PageHeader
          title={
            <Title level={4} className={styles.UserKYCTitle}>
              User KYC
            </Title>
          }
          extra={[
            !this.state.isViewmode ? (
              <Button
                variant="purple"
                shape="round"
                size="large"
                onClick={() => {
                  this.setState({ isAdd: true });
                }}
              >
                Add KYC Dcoument Type
              </Button>
            ) : null,
          ]}
          className={styles.UserKYCHeader}
        />
        {/**
         * Please go through below documention for <Table> Component
         * https://ant.design/components/table/
         * https://ant.design/components/table/#API
         *
         */}
        <Table
          size="small"
          bordered
          columns={this.columns}
          dataSource={this.state.kycDocTypeList}
          pagination={false}
          loading={this.state.tableLoading}
        />
      </React.Fragment>
    );
  }
}
