import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  notification,
  Tooltip,
  Modal,
  DatePicker,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //   faChevronUp,
  faPencilAlt,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
// import ROUTES from "../../../routes";
import ExpenseService from "../../../services/storeexpenseentryservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import {
  // RegexDecimalAndRestrictFirstZero,
  RegexInteger,
  MessageNumbers,
} from "../../../shared/validator";
import { labels } from "../../../shared/language";
import { Label } from "recharts";
import Common from "../../../services/common";
const { Search } = Input;
const { Option } = Select;

/**
 * A factory master screen which holds grid view for master screens and form with steps
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
class Master extends React.Component {
  expenseService = new ExpenseService();
  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    modalLoading: false,
    expenseID: 0,
    showfilterBox: false,
    factories: [],
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    showAddPopup: false,
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
    popupMode: "add",
    currentExpense: {},
    expenseTypes: {
      disabled: true,
      data: [],
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.EXPENSE_ENTRY
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: labels.USER_NAME,
      dataIndex: "userName",
      key: "userName",
      sorter: true,
    },
    {
      title: labels.EXPENSE_TYPE,
      dataIndex: "expenseTypeName",
      key: "expenseTypeName",
      sorter: true,
    },
    {
      title: labels.EXPENSE_DATE,
      dataIndex: "expenseDate",
      key: "expenseDate",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.AMOUNT,
      dataIndex: "amount",
      key: "amount",
      sorter: true,
    },
    {
      title: labels.REMARKS,
      dataIndex: "remark",
      key: "remark",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (remarks) => {
        return (
          <Tooltip placement="topLeft" title={remarks}>
            {remarks}
          </Tooltip>
        );
      },
    },

    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title={labels.VIEW}>
              <Button
                type="link"
                onClick={() =>
                  this.setState(
                    {
                      popupMode: "view",
                      expenseID: record.expenseID,
                      currentExpense: {
                        expenseTypeID: record.expenseTypeID,
                        amount: record.amount,
                        expenseDate: moment(record.expenseDate),
                        remarks: record.remark,
                      },
                    },
                    () => this.setState({ showAddPopup: true })
                  )
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title={labels.EDIT}>
              <Button
                type="link"
                onClick={() =>
                  this.setState(
                    {
                      popupMode: "edit",
                      expenseID: record.expenseID,
                      currentExpense: {
                        expenseTypeID: record.expenseTypeID,
                        amount: record.amount,
                        expenseDate: moment(record.expenseDate),
                        remarks: record.remark,
                      },
                    },
                    () => this.setState({ showAddPopup: true })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Popconfirm
              title={labels.ARE_YOU_SURE}
              onConfirm={() => this.handleDelete(record.expenseID)}
              onCancel={() => null}
              okText={labels.YES}
              cancelText={labels.CANCEL}
            >
              <Tooltip title={labels.DELETE}>
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    this.getFactoryList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
    this.getExpenseTypes();
  }
  handleDelete = (id) => {
    this.setState({ tableLoading: true });
    this.expenseService.deleteExpense(id).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.USER_EXPENSES,
          description: response.message
            ? this.common.Message(response.message)
            : labels.EXPENSE_DELETED_SUCCESSFULLY,
        });
        this.getFactoryList(
          this.state.pagination.current,
          this.state.pagination.pageSize,
          this.state.sorter.field,
          this.state.sorter.order,
          this.state.search
        );
      } else {
        notification["error"]({
          message: labels.USER_EXPENSES,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_DELETE_EXPENSE,
        });
      }
      this.setState({ tableLoading: false });
    });
  };
  addUpdateExpense = (values) => {
    let params = {
      expenseID: this.state.expenseID,
      expenseTypeID: values.expenseTypeID,
      amount: parseFloat(values.amount),
      expenseDate: values.expenseDate.format("YYYY-MM-DD"),
      remark: values.remarks,
    };
    this.setState({ modalLoading: true });
    this.expenseService.addUpdateExpense(params).then((response) => {
      if (response.statusCode === 200) {
        if (this.state.expenseID == 0) {
          notification["success"]({
            message: labels.USER_EXPENSES,
            description: response.message
              ? this.common.Message(response.message)
              : labels.EXPENSE_ADDED_SUCCESSFULLY,
          });
        } else {
          notification["success"]({
            message: labels.USER_EXPENSES,
            description: response.message
              ? this.common.Message(response.message)
              : labels.EXPENSE_UPDATED_SUCCESSFULLY,
          });
        }
        this.getFactoryList(
          this.state.pagination.current,
          this.state.pagination.pageSize,
          this.state.sorter.field,
          this.state.sorter.order,
          this.state.search
        );
        this.setState({ showAddPopup: false });
      } else {
        notification["error"]({
          message: labels.USER_EXPENSES,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_SUBMIT_DATA,
        });
      }
      this.setState({ modalLoading: false });
    });
  };
  getExpenseTypes = () => {
    this.expenseService.getExpenseTypes().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          expenseTypes: {
            ...this.state.expenseTypes,
            data: response.data,
            disabled: false,
          },
        });
      }
    });
  };
  getFactoryList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    this.setState({ tableLoading: true });
    this.expenseService
      .getAllExpenseList(index, size, sortColumn, sortOrder, search)
      .then((response) => {
        if (response.statusCode === 200) {
          let factoryData = [];
          response.data.map((singleData) => {
            singleData.key = singleData.factoryID;
            singleData.openingDate = moment(singleData.openingDate).format(
              "YYYY-MM-DD"
            );
            singleData.operatingTimeFrom = moment(
              singleData.operatingTimeFrom,
              "H:mm"
            ).format("h:mm a");
            singleData.operatingTimeTo = moment(
              singleData.operatingTimeTo,
              "H:mm"
            ).format("h:mm a");
            factoryData.push(singleData);
            return null;
          });
          this.setState({ factories: factoryData });
          this.setState({
            pagination: {
              ...this.state.pagination,
              total: factoryData.length ? factoryData[0].totalRecords : 0,
            },
          });
        } else {
          notification["error"]({
            message: labels.USER_EXPENSES,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_LIST_OF_EXPENSES,
          });
        }
        this.setState({ tableLoading: false });
      });
  };
  render() {
    const disableAll = this.state.popupMode === "view";
    return (
      <div>
        <PageHeader
          title={labels.USER_EXPENSE_ENTRY}
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              key="1"
              onChange={(event) => {
                this.setState({
                  search: event.target.value.toString().toLowerCase(),
                });
                this.getFactoryList(
                  this.state.pagination.current,
                  this.state.pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  event.target.value.toString().toLowerCase()
                );
              }}
            />,
            /**
             * Add Factory Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.setState({
                    expenseID: 0,
                    showAddPopup: true,
                    popupMode: "add",
                    currentExpense: {},
                  })
                }
                key="2"
              >
                {labels.ADD_EXPENSE}
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getFactoryList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />

        <Modal
          width={600}
          title={[
            this.state.popupMode === "add"
              ? labels.ADD
              : this.state.popupMode === "view"
              ? labels.VIEW
              : labels.EDIT,
            labels.EXPENSE,
          ].join(" ")}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={(values) => {
                this.addUpdateExpense(values);
              }}
              initialValues={this.state.currentExpense}
            >
              <Row gutter={24}>
                <Col xs={8}>
                  <Form.Item
                    label={labels.EXPENSE_TYPE}
                    name="expenseTypeID"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_EXPENSE_TYPE,
                      },
                    ]}
                  >
                    <Select
                      disabled={disableAll || this.state.expenseTypes.disabled}
                      placeholder={labels.SELECT_EXPENSE_TYPE}
                    >
                      {this.state.expenseTypes.data.length !== 0
                        ? this.state.expenseTypes.data.map((res) => {
                            return res.value !== "-1" ? (
                              <Option
                                value={parseInt(res.value)}
                                key={res.value}
                              >
                                {res.text}
                              </Option>
                            ) : null;
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label={labels.AMOUNT}
                    name="amount"
                    rules={[
                      { required: true, message: labels.PLEASE_ENTER_AMOUNT },
                      {
                        pattern: RegexInteger,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      disabled={disableAll}
                      placeholder={labels.ENTER_AMOUNT}
                      prefix="₹"
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label={labels.DATE}
                    name="expenseDate"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_EXPENSE_DATE,
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder={labels.SELECT_DATE}
                      format={"DD-MM-YYYY"}
                      disabled={disableAll}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item label={labels.REMARKS} name="remarks">
                    <Input.TextArea
                      disabled={disableAll}
                      placeholder={labels.ENTER_REMARKS}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.popupMode === "view" ? (
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        {labels.CANCLE}
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCLE}
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.popupMode === "add"
                            ? labels.ADD
                            : labels.UPDATE}
                        </Button>
                      </React.Fragment>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Master);
