import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Spin,
  Table,
  notification,
  Tooltip,
  Modal,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faPencilAlt,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../routes";
import LKRefund from "../../../services/lkrefundservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import TableActions from "../../../components/Common/TableActions/tableactions";
import { RegexInteger, MessageNumbers } from "../../../shared/validator";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";

const { Search } = Input;
const { Option } = Select;

/**
 * Refund screen
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 13 Sep 2020
 */
class Master extends React.Component {
  lkrefundService = new LKRefund();
  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    modalLoading: false,
    showfilterBox: false,
    // lkRefundID: 0,
    customerID: "",
    customerName: {
      data: [],
      selected: null,
    },
    amount: null,
    remark: "",
    refundItems: [],
    tableLoading: true,
    showAddPopup: false,
    isEnabled: false,
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.REFUND
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }
  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: labels.CUSTOMER_NAME,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: labels.AMOUNT,
      dataIndex: "amount",
      key: "amount",
      sorter: true,
    },
    {
      title: labels.REMARK,
      dataIndex: "remark",
      key: "remark",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },

    {
      title: labels.USER_NAME,
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: true,
    },
    {
      title:labels.CREATED_ON,
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: true,
      render: (text) => {
        return [null, undefined, ""].includes(text)
          ? ""
          : moment(text).format("DD-MM-YYYY hh:mm:ss a");
      },
    },

    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title={labels.VIEW}>
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    customerName: {
                      ...this.state.customerName,
                      selected: record.customerName,
                    },
                    amount: record.amount,
                    remark: record.remark,
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.getRefundList({
      customerID: "",
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  getRefundList = (params = {}) => {
    this.setState({ tableLoading: true });
    this.lkrefundService.retrieveRefundItems(params).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          refundItems: response.data,
        });
        this.setState({
          pagination: {
            ...params.pagination,
            total: response.data.length ? response.data[0].totalRecords : 0,
          },
        });
      } else {
        notification["error"]({
          message: labels.REFUND,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_LIST_OF_REFUND_DATA,
        });
      }
      this.setState({ tableLoading: false });
    });
  };

  handlePopupAdd = (event) => {
    this.setState({ modalLoading: true });
    var postData = {
      customerID: this.state.customerName.selected,
      amount: parseInt(this.state.amount),
      remark: this.state.remark,
    };

    this.lkrefundService.insertItems(postData).then((items) => {
      if (items.statusCode === 200) {
        notification["success"]({
          message: labels.REFUND,
          description: this.common.Message(items.message),
        });
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.getRefundList({
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        notification["error"]({
          message: labels.REFUND,
          description: items.message
            ? this.common.Message(items.message)
            : labels.UNABLE_TO_ADD_REFUND_DATA,
        });
      }
      this.setState({ modalLoading: false });
    });
  };

  onSearch = (searchText) => {
    this.setState({ tableLoading: true });
    this.lkrefundService.getCustomerData(searchText).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          customerName: {
            ...this.state.customerName,
            data: response.data,
          },
        });
      } else {
        notification["error"]({
          message: labels.REFUND,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_LIST_OF_CUSTOMERS,
        });
      }
      this.setState({ tableLoading: false });
    });
  };
  handleTableChange = (pagination, filters, sorter) => {
    this.getRefundList({
      customerID: "",
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? null
          : sorter.order === "descend"
          ? "desc"
          : "asc",
    });
  };

  handleSearch = (event) => {
    this.getRefundList({
      customerID: "",
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
    });
  };

  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };
  handleAmount = (e) => {
    this.setState({ amount: e.target.value });
  };

  handleRemark = (e) => {
    this.setState({ remark: e.target.value });
  };

  resetFields = () => {
    this.setState({
      isVisible: false,
      isEnabled: false,
      customerName: { data: [], selected: null },
      amount: "",
      remark: "",
    });
  };

  render() {
    const {
      pagination,
      tableLoading,
      amount,
      remark,
      customerName,
    } = this.state;
   
    const refundListItem = this.state.refundItems.map((items) => ({
      lkRefundID: items.lkRefundID,
      customerID: items.customerID,
      customerName: items.customerName,
      remark: items.remark,
      amount: items.amount,
      createdByName:items.createdByName,
      createdOn:items.createdOn,
    }));
    return (
      <div>
        <PageHeader
          title={labels.REFUND}
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              key="1"
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
                key="2"
              >
                {labels.ADD_REFUND}
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={refundListItem}
          loading={tableLoading}
          pagination={pagination}
          onChange={this.handleTableChange}
        />

        <Modal
          title={
            this.state.isEnabled !== true
              ? labels.ADD_REFUND
              : labels.VIEW_REFUND
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                customerName: customerName.selected
                  ? customerName.selected
                  : undefined,
                amount,
                remark,
              }}
            >
              <Row gutter={24}>
                <Col xs={12} ml={12} xl={16}>
                  <Form.Item
                    label={labels.CUSTOMER}
                    name={"customerName"}
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_CUSTOMER,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      onSearch={this.onSearch}
                      allowClear
                      placeholder={labels.SEARCH_CUSTOMER}
                      onChange={(value) => {
                        this.setState({
                          customerName: {
                            ...this.state.customerName,
                            selected: value,
                          },
                        });
                      }}
                      disabled={this.state.isEnabled}
                      filterOption={false}
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                    >
                      {this.state.customerName.data.length !== 0
                        ? this.state.customerName.data.map(
                            (singleCustomerName) => {
                              return singleCustomerName.customerID !== "-1" ? (
                                <Option
                                  value={singleCustomerName.customerID}
                                  key={singleCustomerName.customerID}
                                >
                                  {singleCustomerName.customerName +
                                    " (" +
                                    singleCustomerName.mobileNo +
                                    ")"}
                                </Option>
                              ) : null;
                            }
                          )
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} ml={12} xl={8}>
                  <Form.Item
                    label={labels.AMOUNT}
                    name="amount"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_AMOUNT,
                      },
                      {
                        pattern: RegexInteger,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_AMOUNT}
                      disabled={this.state.isEnabled}
                      prefix="₹"
                      maxLength={20}
                      onChange={this.handleAmount}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label={labels.REMARK}
                    name="remark"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_REMARK,
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder={labels.ENTER_REMARK}
                      disabled={this.state.isEnabled}
                      onChange={this.handleRemark}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {labels.ADD}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Master);
