import React from "react";
import RefferalBonusConfiguration from "../../../components/LKWallet/RefferalBonusConfiguration/refferalbonusconfiguration.component"

export default class RefferalBonusConfigurationScreen extends React.Component {
  render() {
    return (
      <div >
        <RefferalBonusConfiguration></RefferalBonusConfiguration>
      </div>
    );
  }
}