import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Tooltip,
  Spin,
  Upload,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  // faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import GarmentService from "../../../services/garmentservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import Configuration from "../../../shared/configuration";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { PlusOutlined } from "@ant-design/icons";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const { Search } = Input;
const { Option } = Select;

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.GarmentService = new GarmentService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.config = new Configuration();
    this.state = {
      modalLoading: false,
      garmentItems: [],
      garmentID: 0,
      garmentName: "",
      uom: [],
      storeDeliveryType: [],
      garmentDeliveryType: [],
      isStoreDeliveryType: false,
      garmentImage: "",
      fileList: [],
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      garmentUOMListOptions: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
      excelFile: <React.Fragment></React.Fragment>,
      excelButtonLoading: false,
      isResponseErrorShow: false,
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.GARMENT
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: "Garment ID",
      dataIndex: "garmentID",
      key: "garmentID",
    },
    {
      title: "Garment Name",
      dataIndex: "garmentName",
      key: "garmentName",
      sorter: true,
    },
    //  {
    //   title: "UOM",
    //   dataIndex: "uom",
    //   key: "uom",
    // },
    {
      title: "UOM",
      dataIndex: "uomName",
      key: "uomName",
      sorter: true,
    },
    {
      title: "DeliveryType",
      dataIndex: "garmentDeliveryTypeName",
      key: "garmentDeliveryTypeName",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "Garment Image",
      dataIndex: "garmentImage",
      key: "garmentImage",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip
          placement="topLeft"
          title={address.split(
            this.config.STANDARD_RATE_CARD_AND_GARMENT_MASTER_IMAGE_URL
          )}
        >
          {address.split(
            this.config.STANDARD_RATE_CARD_AND_GARMENT_MASTER_IMAGE_URL
          )}
        </Tooltip>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
            this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    garmentID: record.garmentID,
                    garmentName: record.garmentName,
                    uom: record.uom
                      .split(",")
                      .map((single) => single.toString()),
                    garmentDeliveryType: record.garmentDeliveryType
                      .split(",")
                      .map((single) => single.toString()),
                    garmentImage: record.garmentImage,
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
            this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    garmentID: record.garmentID,
                    garmentName: record.garmentName,
                    uom: record.uom
                      .split(",")
                      .map((single) => single.toString()),
                    garmentDeliveryType: record.garmentDeliveryType
                      .split(",")
                      .map((single) => single.toString()),
                    garmentImage: record.garmentImage,
                  })
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
            this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.garmentID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      garmentID: this.state.garmentID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
    this.DropDownGarmentUOMList();
    this.getStoreDeliveryType();
  }

  getStoreDeliveryType = () => {
    this.GarmentService.retrieveStoreDeliveryType().then((storeDeliveryType) => {
      if (storeDeliveryType.statusCode === 200) {
        this.setState({ storeDeliveryType: storeDeliveryType.data, isStoreDeliveryType: true });
      } else {
        notification["error"]({
          message: "Store Master",
          description: "Unable to get data for store Delivery Type ",
        });
        // this.setState({ StoreDeliveryType: false });
      }
    });
  };

  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      garmentID: this.state.garmentID,
      CommonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      garmentID: this.state.garmentID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
            ? "desc"
            : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.GarmentService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            garmentID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          garmentItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "Garment Master",
            description: items.message
              ? items.message
              : "Unable to get list of garments data",
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (garmentID) => {
    if (garmentID === "" || garmentID === null || garmentID === undefined) {
      // openNotificationWithIcon(
      //   "error",
      //   "Garment Master",
      //   "Please Select Atleast One Row"
      // );
      notification["error"]({
        message: "Garment Master",
        description: "Please Select Atleast One Row",
      });
    } else {
      this.setState({ loading: true });
      this.GarmentService.removeItems(garmentID).then((items) => {
        if (items.result === "Success") {
          // openNotificationWithIcon("success", "Garment Master", items.message);
          notification["success"]({
            message: "Garment Master",
            description: items.message,
          });
          const { pagination } = this.state;
          this.fetchItems({
            brandID: parseInt(this.state.garmentID),
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
          });
        } else {
          // openNotificationWithIcon("error", "Garment Master", items.message);
          notification["error"]({
            message: "Garment Master",
            description: items.message,
          });
        }
        this.setState({ loading: false });
      });
    }
  };

  oldGarmentImageFromUrl = () => {
    let str1 = "";
    if (![undefined, null, ""].includes(this.state.garmentImage)) {
      let str = this.state.garmentImage.split("/");
      str1 = str[str.length - 1];
    }
    return str1;
  };

  handlePopupAdd = (event) => {
    var fileData = this.state.fileList;
    const formData = new FormData();

    var uomList = "";
    for (var i = 0; i < this.state.uom.length; i++) {
      uomList = uomList + this.state.uom[i] + ",";
    }
    var uomvVal = uomList.substring(0, uomList.length - 1);

    var deliveryTypeList = "";
    for (var i = 0; i < this.state.garmentDeliveryType.length; i++) {
      deliveryTypeList = deliveryTypeList + this.state.garmentDeliveryType[i] + ",";
    }
    var deliveryTypeVal = deliveryTypeList.substring(0, deliveryTypeList.length - 1);

    formData.append("garmentID", this.state.garmentID);
    formData.append("garmentName", this.state.garmentName);
    formData.append("uom", uomvVal);
    formData.append("GarmentDeliveryType", deliveryTypeVal)
    formData.append(
      "garmentImage",

      this.state.fileList.length > 0
        ? this.state.fileList[0].originFileObj
        : null
    );
    formData.append("isNewImgUploaded", true);
    formData.append("oldGarmentImg", this.oldGarmentImageFromUrl());
    this.setState({ modalLoading: true });
    this.GarmentService.insertUpdateItems(formData).then((items) => {
      if (items.result === "Success") {
        // openNotificationWithIcon("success", "Garment Master", items.message);
        notification["success"]({
          message: "Garment Master",
          description: items.message,
        });
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          garmentID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        // openNotificationWithIcon("error", "Garment Master", items.message);
        notification["error"]({
          message: "Garment Master",
          description: items.message,
        });
      }
      this.setState({ modalLoading: false });
    });
  };

  handleGarmentName = (e) => {
    let garmentNm = e.target.value;
    this.setState({ garmentName: garmentNm });
  };

  DropDownGarmentUOMList = () => {
    this.GarmentService.GarmentUOMListItems().then((items) => {
      if (items.statusCode === 200) {
        this.setState({ garmentUOMListOptions: items.data });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "Garment Master",
            description: items.message
              ? items.message
              : "Unable to get list of uom data",
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
      }
    });
  };

  onChangeSelect = (event) => {
    this.setState({ uom: event });
  };

  onChangeDeliveryType = (event) => {
    this.setState({ garmentDeliveryType: event });
  }
  //function for clearing out the states
  resetFields = () => {
    this.setState({
      garmentID: 0,
      garmentName: "",
      uom: [],
      garmentDeliveryType: [],
      uomName: "",
      garmentDeliveryTypeName:"",
      fileList: [],
      isEnabled: false,
      isVisible: false,
    });
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleChange = (info) => {
    if (info.fileList.length > 0) {
      if (info.file !== undefined) {
        const isJpgOrPng =
          info.file.type === "image/svg+xml" ||
          info.file.type === "image/png" ||
          info.file.type === "image/jpeg" ||
          info.file.type === "image/jpg";
        if (!isJpgOrPng) {
          notification["error"]({
            message: "Garment Master",
            description: "You can only upload JPG/PNG/SVG/JPEG file!",
          });
          // this.setState({ fileList: [] });
          info.file = undefined;
        }
        let isLt1M = false;
        if (info.file !== undefined) {
          isLt1M = info.file.size / 1024 / 1024 < 1;
          if (!isLt1M) {
            notification["error"]({
              message: "Garment Master",
              description: "Image must be smaller than 1 MB!",
            });
            // this.setState({ fileList: [] });
            info.file = undefined;
          }
        }
        if (isJpgOrPng && isLt1M) {
          info.file.status = "done";
        }
      }
      var infoFileList = [];
      infoFileList.push(info.file);
      info.file = infoFileList.filter((x) => x != undefined);
      this.setState({ fileList: info.file });
      if (info.file.length > 0) {
        this.setState({ garmentImage: "" });
      }
    } else {
      this.setState({ fileList: [], garmentImage: "" });
    }
  };

  generateExcel = () => {
    if (this.state.garmentItems.length > 0) {
      this.setState({ excelButtonLoading: true });
      let totalRecords = this.state.garmentItems[0].totalRecords;
      this.GarmentService.retrieveItems({
        garmentID: null,
        pageIndex: 1,
        pageSize: totalRecords,
      }).then((response) => {
        if (response.statusCode === 200) {
          this.setState(
            {
              excelFile: (
                <div>
                  <ExcelFile hideElement={true} filename="Garment Master">
                    <ExcelSheet
                      data={response.data}
                      name={"Garments"}
                      columns={[]}
                    >
                      <ExcelColumn label="ID" value="garmentID" />
                      <ExcelColumn label="Name" value="garmentName" />
                      <ExcelColumn label="Image URL" value="garmentImage" />
                      <ExcelColumn label="UOM" value="uomName" /> 
                      <ExcelColumn label="DeliveryType" value="garmentDeliveryTypeName" />
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              ),
            },
            () =>
              this.setState({
                excelFile: <React.Fragment></React.Fragment>,
                excelButtonLoading: false,
              })
          );
        }
      });
    }
  };

  render() {
    const { garmentName, pagination, loading } = this.state;

    const Regex = /^[A-Za-z0-9]*$/;
    const uploadButton =
      this.state.fileList.length === 0 ? (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">Upload</div>
        </div>
      ) : null;
    const garmentItems = this.state.garmentItems;
    const listItems = garmentItems.map((item, index) => ({
      key: item.garmentID,
      garmentID: item.garmentID,
      garmentName: item.garmentName,
      deliveryType: item.deliveryType,
      uom: item.uom,
      uomName: item.uomName,
      garmentDeliveryType: item.garmentDeliveryType,
      garmentDeliveryTypeName: item.garmentDeliveryTypeName,
      garmentImage: item.garmentImage,
      isActive: item.isActive.toString(),
      createdByName: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD/MM/YYYY hh:mm:ss a"),
      totalRecords: item.totalRecords,
    }));

    return (
      <div>
        {this.state.excelFile}
        <PageHeader
          title="Garment Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
              key="1"
            />,
            this.state.userRights.length > 0 &&
              this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
                key="2"
              >
                Add Garment
              </Button>
            ) : null,
            this.state.garmentItems.length > 0 ? (
              <Button
                variant="transparentGreyOutline"
                style={{
                  display: "flex",
                  alignItems: "center",
                  lineHeight: "19px",
                }}
                onClick={() => {
                  this.generateExcel();
                }}
                loading={this.state.excelButtonLoading}
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  style={{
                    fontSize: 18,
                    marginRight: 5,
                  }}
                />{" "}
                Download Excel
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
            this.state.userRights[0].isDelete == true ? (
            garmentItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>

        <Modal
          title={
            this.state.garmentID === 0
              ? "Add Garment"
              : this.state.isEnabled
                ? "View Garment"
                : "Edit Garment"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                garmentName: garmentName,
                uomName: this.state.uom,
                garmentDeliveryTypeName: this.state.garmentDeliveryType
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Garment Name"
                    name="garmentName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Garment Name",
                      },
                      // {
                      //   pattern: Regex,
                      //   message: "You Can Enter Alphabets And Digits",
                      // },
                    ]}
                  >
                    <Input
                      placeholder="Enter Garment Name"
                      disabled={this.state.isEnabled}
                      onChange={this.handleGarmentName}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label="UOM"
                    name="uomName"
                    rules={[{ required: true, message: "Please select UOM !" }]}
                  >
                    <Select
                      mode="multiple"
                      disabled={this.state.isEnabled}
                      onChange={this.onChangeSelect}
                      placeholder="Select GarmentUOM"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.garmentUOMListOptions.map((uomitem) =>
                        uomitem.value !== "-1" ? (
                          <option key={uomitem.value} value={uomitem.value}>
                            {uomitem.text}
                          </option>
                        ) : null
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                {/* start Added By Rashmi 17-02-23 for #L177728 */}
                <Col xs={24}>
                  <Form.Item
                    label="Delivery Type"
                    name="garmentDeliveryTypeName"
                    rules={[{ required: true, message: "Please Select Delivery Type" }]}
                  >
                    <Select
                      mode="multiple"
                      disabled={this.state.isEnabled}
                      onChange={this.onChangeDeliveryType}
                      placeholder="Select Delivery Type"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.storeDeliveryType.map((storeDeliveryType) => {
                        return storeDeliveryType.value !== "-1" ? (
                          <Option
                            value={storeDeliveryType.value}
                            key={storeDeliveryType.value}
                          >
                            {storeDeliveryType.text}
                          </Option>
                        ) : null;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {/* End---- */}

                <Col xs={24}>
                  {this.state.isEnabled === false ? (
                    <>
                      <Space>
                        <Form.Item
                          label="Select File To Upload"
                          name="file"
                          rules={[
                            {
                              required: this.state.garmentID === 0,
                              message: "Please Select File To Upload",
                            },
                          ]}
                          valuePropName={"file"}
                        >
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={this.state.fileList}
                            // onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            customRequest={({ file, onSuccess, onError }) => {
                              onSuccess({ status: "done" }, file);
                            }}
                          >
                            {/* {this.state.fileList.length >= 8
                              ? null
                              : uploadButton} */}
                            {uploadButton}
                          </Upload>
                        </Form.Item>
                        {this.state.garmentID != 0 ? (
                          <Form.Item>
                            <img
                              src={this.state.garmentImage}
                              style={{ maxWidth: "100px", maxHeight: "100px" }}
                            />
                          </Form.Item>
                        ) : null}
                      </Space>
                    </>
                  ) : (
                    <Form.Item label="Image Url">
                      <img
                        src={this.state.garmentImage}
                        style={{ maxWidth: "50%", maxHeight: "50%" }}
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.garmentID === 0 ? "Add" : "Update"}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
