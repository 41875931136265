import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./createuserscreen.module.css";
import GeneralForm from "../../../components/Master/User/CreateUser/generalform.component";
import UserContact from "../../../components/Master/User/CreateUser/usercontact.component";
import UserAssignment from "../../../components/Master/User/CreateUser/userassignment.component";
import UserKYC from "../../../components/Master/User/CreateUser/userkyc.component";
import { PageHeader, Steps, notification, Form, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import UserService from "../../../services/userservice";
import UserRole from "../../../components/Master/User/CreateUser/userrole.component";
import Moment from "moment";
import ROUTES from "../../../routes";

const { Step } = Steps;

// const openNotificationWithIcon = (type, title, message) => {
//   notification[type]({
//       message: title,
//       description:
//           message
//   });
// };
/**
 * User Creation Screen with steps and form (Master - Form).
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 1 June 2020
 *
 */
class CreateUser extends React.Component {
  /**
   * State Varible to Hold Active Step Index starting from 0
   */
  constructor(props) {
    super(props);
    this.userservice = new UserService();
    this.state = {
      isAdduser: false,
      mode: "add",
      isViewmode: false,
      fullLoading: false,
      activeStep: 0,
      stepsDirection: "vertical",
      stepsSize: "default",
      generalFormValues: {},
      contactFormValues: {},
      userAssignmentFormValues: {},

      // General details
      userID: 0,
      companyID: 1,
      macid: "Blank",
      isMACID: true,
      isActive: true,
      // User Contact details
      userContactID: 0,
      geoFencing: "",
      geoFencingName: "Polygon",

      // User Assignment
      userAssignmentID: 0,
    };
  }

  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "mini" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };
  componentDidMount() {
    if ([ROUTES.EDIT_USER, ":userID"].join("/") === this.props.match.path) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });
      this.getUser(this.props.match.params.userID);
    } else if (
      [ROUTES.VIEW_USER, ":userID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.setState({ isViewmode: true });
      this.getUser(this.props.match.params.userID);
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
  }

  getUser = (id) => {
    this.userservice.getUserDetailByUserID(id).then((response) => {
      if (
        response.statusCode === 200 &&
        response.data.vMUserMaster.userID === parseInt(id)
      ) {
        this.setState({ userID: response.data.vMUserMaster.userID });
        this.setState({
          userContactID: response.data.vMUserContact.userContactID,
        });
        this.setState({
          userAssignmentID: response.data.vMUserAssignment.userAssignmentID,
        });
        // localStorage.setItem(
        //   "geoFencing",
        //   response.data.vMUserContact.geoFencing
        // );

        this.setState({
          generalFormValues: {
            firstName: response.data.vMUserMaster.firstName,
            middleName: response.data.vMUserMaster.middleName,
            lastName: response.data.vMUserMaster.lastName,
            mobileNo: response.data.vMUserMaster.mobileNo,
            emailAddress: response.data.vMUserMaster.emailAddress,
            password: response.data.vMUserMaster.password,
            employeeType: response.data.vMUserMaster.employeeType.toString(),
            dob: response.data.vMUserMaster.dob
              ? Moment(response.data.vMUserMaster.dob, "M/D/yyyy")
              : "",
            doj: response.data.vMUserMaster.doj
              ? Moment(response.data.vMUserMaster.doj, "M/D/yyyy")
              : "",
            designationID:
              response.data.vMUserMaster.designationID === 0
                ? null
                : response.data.vMUserMaster.designationID.toString(),
            reportingTo:
              response.data.vMUserMaster.reportingTo === 0
                ? null
                : response.data.vMUserMaster.reportingTo.toString(),
            macid: "Blank",
            isMACID: true,
            isActive: response.data.vMUserMaster.isActive,
          },
        });
        this.setState({
          contactFormValues: {
            userContactID: response.data.vMUserContact.userContactID,
            homeAddress: response.data.vMUserContact.homeAddress,
            permanentAddress: response.data.vMUserContact.permanentAddress,
            isPoliceVerified: response.data.vMUserContact.isPoliceVerified,
            familyContactNo1: response.data.vMUserContact.familyContactNo1,
            familyContactNo2: response.data.vMUserContact.familyContactNo2,
            familyName1: response.data.vMUserContact.familyName1,
            familyName2: response.data.vMUserContact.familyName2,
            geoFencing: response.data.vMUserContact.geoFencing,
            geoFencingName: "Polygon",
          },
        });
        this.setState({
          userAssignmentFormValues: {
            userAssignmentID: response.data.vMUserAssignment.userAssignmentID,
            //added by pb
            factoryID: [null, undefined, ""].includes(
              response.data.vMUserAssignment.factoryID
            )
              ? undefined
              : response.data.vMUserAssignment.factoryID.split(","),
            //added by pb
            storeID: [null, undefined, ""].includes(
              response.data.vMUserAssignment.storeID
            )
              ? undefined
              : response.data.vMUserAssignment.storeID.split(","),
            //added by pb
            ironPersonType: [null, undefined, 0, ""].includes(
              response.data.vMUserAssignment.ironPersonType
            )
              ? // response.data.vMUserAssignment.ironPersonType === 0
                undefined
              : response.data.vMUserAssignment.ironPersonType.toString(),
            isIronPerson: response.data.vMUserAssignment.isIronPerson,
            leavePerMonth:
              response.data.vMUserAssignment.leavePerMonth === 0
                ? null
                : response.data.vMUserAssignment.leavePerMonth,
            //added by pb
            paymentType: [null, undefined, 0, ""].includes(
              response.data.vMUserAssignment.paymentType
            )
              ? undefined
              : response.data.vMUserAssignment.paymentType.toString(),
            //added by pb
            stayAt: [null, undefined, 0, ""].includes(
              response.data.vMUserAssignment.stayAt
            )
              ? // response.data.vMUserAssignment.stayAt === 0
                undefined
              : response.data.vMUserAssignment.stayAt.toString(),
            //added by pb
            workingDays: ![null, undefined, 0, ""].includes(
              response.data.vMUserAssignment.workingDays
            )
              ? response.data.vMUserAssignment.workingDays.split(",")
              : undefined,
            //  ![null, undefined, 0, ""].includes(
            //   response.data.vMUserAssignment.workingDays
            // )
            //   ? response.data.vMUserAssignment.workingDays
            //       .split(",")
            //       .map(function (item) {
            //         return parseInt(item, 10);
            //       })
            //   : undefined,
            //added by pb
            workingTimeFrom: ![null, undefined, ""].includes(
              response.data.vMUserAssignment.workingTimeFrom
            )
              ? Moment(response.data.vMUserAssignment.workingTimeFrom, "h:mm a")
              : null,
            //added by pb
            workingTimeTo: ![null, undefined, ""].includes(
              response.data.vMUserAssignment.workingTimeFrom
            )
              ? Moment(response.data.vMUserAssignment.workingTimeTo, "h:mm a")
              : null,
            comments: response.data.vMUserAssignment.comments,
          },
        });
        this.setState({ fullLoading: false });
        this.setState({ activeStep: -1 });
        this.setState({ activeStep: 0 });
      } else {
        notification["error"]({
          message: "Users",
          description: "Unable to get user data",
          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
    });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleSelectChange = (event, ddlList, name, text) => {
    this.setState({ [name]: parseInt(event) });
    var ddlListArray = ddlList.filter((x) => x.value === parseInt(event));
    if (ddlListArray !== undefined) {
      this.setState({ [text]: ddlListArray[0].text });
    }
  };

  handleMultiSelectChange = (event, name) => {
    this.setState({ [name]: event });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleTimeChange = (time, name) => {
    this.setState({ [name]: time });
  };

  handleCheckbox = (event, name) => {
    this.setState({ [name]: event.target.checked });
  };

  handleSaveUpdateUserMaster(event) {
    this.setState({ fullLoading: true });
    var storeList = "";
    var factoryList = "";
    // var workingDaysList = "";

    if (
      this.state.userAssignmentFormValues.storeID !== undefined &&
      this.state.userAssignmentFormValues.storeID.length > 0
    ) {
      for (
        var i = 0;
        i < this.state.userAssignmentFormValues.storeID.length;
        i++
      ) {
        storeList += this.state.userAssignmentFormValues.storeID[i] + ",";
      }
    }

    if (
      this.state.userAssignmentFormValues.factoryID !== undefined &&
      this.state.userAssignmentFormValues.factoryID.length > 0
    ) {
      for (
        var j = 0;
        j < this.state.userAssignmentFormValues.factoryID.length;
        j++
      ) {
        factoryList += this.state.userAssignmentFormValues.factoryID[j] + ",";
      }
    }

    // if (this.state.userAssignmentFormValues.workingDays.length > 0) {
    //   for (
    //     var k = 0;
    //     k < this.state.userAssignmentFormValues.workingDays.length;
    //     k++
    //   ) {
    //     workingDaysList +=
    //       this.state.userAssignmentFormValues.workingDays[k] + ",";
    //   }
    // }
    const postSave = {
      userID: this.state.userID,
      companyID: 1,
      firstName: this.state.generalFormValues.firstName,
      middleName: this.state.generalFormValues.middleName,
      lastName: this.state.generalFormValues.lastName,
      mobileNo: this.state.generalFormValues.mobileNo,
      emailAddress: this.state.generalFormValues.emailAddress,
      password: this.state.generalFormValues.password,
      employeeType: parseInt(this.state.generalFormValues.employeeType),
      dob: this.state.generalFormValues.dob
        ? this.state.generalFormValues.dob.format("yyyy-MM-DD")
        : null,
      doj: this.state.generalFormValues.doj
        ? this.state.generalFormValues.doj.format("yyyy-MM-DD")
        : null,
      designationID: parseInt(this.state.generalFormValues.designationID),
      reportingTo: parseInt(this.state.generalFormValues.reportingTo),
      macid: "Blank",
      isMACID: true,
      isActive: true,

      vMUserSaveContact: {
        userContactID: this.state.userContactID,
        homeAddress: this.state.contactFormValues.homeAddress,
        permanentAddress: this.state.contactFormValues.permanentAddress,
        isPoliceVerified: this.state.contactFormValues.isPoliceVerified,
        familyName1: this.state.contactFormValues.familyName1,
        familyContactNo1: this.state.contactFormValues.familyContactNo1,
        familyName2: this.state.contactFormValues.familyName2,
        familyContactNo2: this.state.contactFormValues.familyContactNo2,
        geoFencing: null,
        geoFencingName: null,
      },

      vMUserSaveAssignment: {
        userAssignmentID: this.state.userAssignmentID,
        factoryID: factoryList.substring(0, factoryList.length - 1),
        storeID: storeList.substring(0, storeList.length - 1),
        ironPersonType: parseInt(
          this.state.userAssignmentFormValues.ironPersonType
        ),
        isIronPerson: this.state.userAssignmentFormValues.isIronPerson,
        leavePerMonth: parseInt(
          this.state.userAssignmentFormValues.leavePerMonth
        ),
        paymentType: parseInt(this.state.userAssignmentFormValues.paymentType),
        stayAt: parseInt(this.state.userAssignmentFormValues.stayAt),
        workingDays: this.state.userAssignmentFormValues.workingDays.join(","),
        //workingDaysList.substring(0, workingDaysList.length - 1),
        workingTimeFrom: this.state.userAssignmentFormValues.workingTimeFrom.format(
          "HH:mm"
        ),
        workingTimeTo: this.state.userAssignmentFormValues.workingTimeTo.format(
          "HH:mm"
        ),
        comments: this.state.userAssignmentFormValues.comments,
      },
    };
    this.userservice.saveUpdateUserMaster(postSave).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: "Users",
          description: "Data for user saved successfully",
          duration: 1.5,
          onClose: () => {
            this.setState({ fullLoading: false });
            this.props.history.push(
              [ROUTES.EDIT_USER, response.data.userID].join("/")
            );
          },
        });
      } else {
        notification["error"]({
          message: "Users",
          description: response.message,
        });
      }
      this.setState({ fullLoading: false });

      // if(response.result=="Success")
      // {
      //   openNotificationWithIcon('success', 'User Master', response.message)
      //   this.setState({isAdduser:true});
      // }
      // else {
      //   openNotificationWithIcon('error', 'User Master', response.message)
      // }
    });
  }

  generalForm = React.createRef();
  userContact = React.createRef();
  userAssignment = React.createRef();

  generalFormValidated = false;
  contactFormValidated = false;
  userAssignmentFormValidated = false;
  handleSubmit = () => {
    const generalValidator = this.generalForm.current.validateFields();
    const contactValidator = this.userContact.current.validateFields();
    const userAssignmentValidator = this.userAssignment.current.validateFields();

    Promise.allSettled([
      generalValidator,
      contactValidator,
      userAssignmentValidator,
    ]).then((responses) => {
      if (responses[0].status === "rejected") {
        this.setState({ activeStep: 0 });
      } else if (responses[1].status === "rejected") {
        this.setState({ activeStep: 1 });
      } else if (responses[2].status === "rejected") {
        this.setState({ activeStep: 2 });
      } else if (
        responses[0].status === "fulfilled" &&
        responses[1].status === "fulfilled" &&
        responses[2].status === "fulfilled"
      ) {
        this.setState(
          {
            generalFormValues: responses[0].value,
            contactFormValues: responses[1].value,
            userAssignmentFormValues: responses[2].value,
          },
          () => {
            this.handleSaveUpdateUserMaster();
          }
        );
      }
    });
  };

  /**
   * General Tab Form Submit Handler
   * Please go through https://ant.design/components/form/#API for more information
   *
   */

  render() {
    const { data } = this.state;

    //const{userMasterData,isEditmode,isViewmode,disabled}=this.props;
    return (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          {/**
           * Page Header Component from Ant Design
           * Please check below link for more options
           * https://ant.design/components/page-header/
           * https://ant.design/components/page-header/#API
           *
           */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? "Edit"
                : this.state.mode === "view"
                ? "View"
                : "Add",
              "User",
            ].join(" ")}
          />

          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              {/**
               * Steps Component from Ant Design
               * Please check below link for more options
               * https://ant.design/components/steps/
               * https://ant.design/components/steps/#API
               *
               */}
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => this.setState({ activeStep: current })}
              >
                <Step title="General" description="General Information" />
                <Step title="Contact" description="Contact Information" />
                <Step
                  title="User Assignment"
                  description="Store/Factory/Pickup/Delivery Assignment"
                />
                {this.state.mode === "edit" || this.state.mode === "view" ? (
                  <Step title="KYC" description="Upload KYC Document" />
                ) : null}
                {this.state.mode === "edit" || this.state.mode === "view" ? (
                  <Step title="Role" description="Role Assignment" />
                ) : null}
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider>
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading}>
                  <React.Fragment>
                    <GeneralForm
                      values={this.state.generalFormValues}
                      backFunction={() => this.props.history.goBack()}
                      nextFunction={() => this.setState({ activeStep: 1 })}
                      isActive={this.state.activeStep === 0}
                      instance={this.generalForm}
                      mode={this.state.mode}
                    />

                    <UserContact
                      geoFencing={null}
                      values={this.state.contactFormValues}
                      backFunction={() =>
                        this.setState({ activeStep: this.state.activeStep - 1 })
                      }
                      nextFunction={() => this.setState({ activeStep: 2 })}
                      isActive={this.state.activeStep === 1}
                      instance={this.userContact}
                      mode={this.state.mode}
                    />

                    <UserAssignment
                      values={this.state.userAssignmentFormValues}
                      backFunction={() =>
                        this.setState({ activeStep: this.state.activeStep - 1 })
                      }
                      nextFunction={this.handleSubmit}
                      isActive={this.state.activeStep === 2}
                      instance={this.userAssignment}
                      mode={this.state.mode}
                    />
                    {this.state.activeStep === 3 ? (
                      <UserKYC
                        data={data}
                        userID={this.state.userID}
                        isViewmode={this.state.isViewmode}
                        mode={this.state.mode}
                      />
                    ) : null}

                    {this.state.activeStep === 4 ? (
                      <UserRole
                        data={data}
                        userID={this.state.userID}
                        isViewmode={this.state.isViewmode}
                        mode={this.state.mode}
                      />
                    ) : null}
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}

export default withRouter(CreateUser);
