export const RegexLettersDigitsSymbols = /^[A-Za-z]{1,}[A-Za-z0-9_-\s,\\/!#$+=<>[\]|(){}.@~%^*&?"':;]*$/;
export const MessageLettersDigitsSymbols = "Input Must Start With Alphabet";

export const RegexLettersDigitsUnderScoreHyphen = /^[A-Za-z]{1,}[A-Za-z0-9_-\s]*$/;
export const MessageLettersDigitsUnderScoreHyphen =
  "Input Must Start With Alphabet And You Can Enter Digits,Underscore Or Hyphen";

export const RegexLetters = /^[a-zA-Z\s]*$/;
export const MessageLetters = "Please Enter Only Alphabets";

export const RegexLettersDigits = /^[A-Za-z]{1,}[A-Za-z0-9\s]*$/;
export const MessageLettersDigits =
  "Input Must Start With Alphabet And You Can Enter Digits";

export const RegexDecimalAndRestrictFirstZero = /^[1-9]\d*(.\d*)?$/;
// /^(?:[1-9]\d*)+([.][0-9]+)?$/;
export const RegexInteger = /^(?:[1-9]\d*)$/;
export const MessageNumbers = "Please Enter Only Digits Starting With 1 to 9";

class Validator {
  validateInputs(inputData) {
    let errorMsg = "";
    if (!inputData.name) {
      errorMsg += "Please enter name of this item.\n";
    }
    if (!inputData.summary) {
      errorMsg += "Please enter summary of this item.\n";
    }
    if (errorMsg.length == 0) {
      return true;
    } else {
      alert(errorMsg);
      return false;
    }
  }
}

export default Validator;
