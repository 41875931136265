import React from "react";
import DeliveryList from "../../../components/StoreModule/DeliveryList/deliverylist.store.component";

export default class DeliveryListScreen extends React.Component {
  render() {
    return (
      <div>
        <DeliveryList />
      </div>
    );
  }
}
