import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class FactoryService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getAllRoles(index = 0, size = 0) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.GET_ALL_ROLES });
    let factories = [];
    let payload = {
      roleName: "",
      pageIndex: index,
      pageSize: size,
    };
    await allFactoriesAPI.endpoints[this.endpoint.GET_ALL_ROLES]
      .getAllPost(payload)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getAllRoles((index = 0), (size = 0));
      });
    }
    return Promise.resolve(factories);
  }

  async getRolesList() {
    const allFactoriesAPI = new API({ url: this.config.DROPDOWN_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.GET_ROLES_DROPDOWN });
    let factories = [];

    await allFactoriesAPI.endpoints[this.endpoint.GET_ROLES_DROPDOWN]
      .getAll()
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getRolesList();
      });
    }
    return Promise.resolve(factories);
  }
  async getRolesRights(id) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.GET_SCREEN_RIGHTS });
    let factories = [];
    const idParams = "?RoleID=" + id;
    await allFactoriesAPI.endpoints[this.endpoint.GET_SCREEN_RIGHTS]
      .getOne(idParams)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getRolesRights(id);
      });
    }
    return Promise.resolve(factories);
  }

  async UpdateRoles(PostData) {
    console.log("123->",PostData);
   
    const allFactoriesAPI = new API({ url: this.config.ITEM_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.UPDATE_SCREEN_ROLES });
    let factories = [];
    await allFactoriesAPI.endpoints[this.endpoint.UPDATE_SCREEN_ROLES]
      .create(PostData)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.UpdateRoles(PostData);
      });
    }
    return Promise.resolve(factories);
  }

  async getRolesAccessList() {
    const allFactoriesAPI = new API({ url: this.config.DROPDOWN_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.GET_ROLEACCESS_DROPDOWN });
    let factories = [];

    await allFactoriesAPI.endpoints[this.endpoint.GET_ROLEACCESS_DROPDOWN]
      .getAll()
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getRolesAccessList();
      });
    }
    return Promise.resolve(factories);
  }
}
