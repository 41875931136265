import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class AuthService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
  }

  async MakeRemoteAuthRequest(username, password) {
    const myApi = new API({ url: this.config.LAUNDRETKART_AUTH });
    // console.log("myApi->",myApi);
    myApi.createEntity({ name: this.endpoint.AUTH_USER });
    // console.log("myApi1->",myApi);
    let payLoad;
    const post = {
      username: username,
      password: password,
    };
    await myApi.endpoints[this.endpoint.AUTH_USER]
      .postAllWithoutToken(post)
      .then((data) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        //if (response.data.accessToken) {

        localStorage.setItem("currentuser", JSON.stringify(data.data.data));

        // localStorage.setItem("branchID",selectedBranch)
        //}
        // return response.data;
        payLoad = data;
      })
      .catch((err) => {
        this.handleError(err);
        console.log(err);
      });
    return Promise.resolve(payLoad);
  }

  async getAssignStoreFactoryByUserId(postdata) {
    const myApi = new API({ url: this.config.LAUNDRETKART_AUTH + "/api/Auth" });
    myApi.createEntity({
      name: this.endpoint.GET_ASSIGNED_STORE_FACTORY_BY_USERID,
    });
    let payLoad = [];
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.GET_ASSIGNED_STORE_FACTORY_BY_USERID]
      .getAllPost(postdata)
      .then((data) => {
        localStorage.setItem("BranchInfo", JSON.stringify(data.data.data));
        payLoad = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        payLoad = this.getAssignStoreFactoryByUserId(postdata);
      });
    }
    return Promise.resolve(payLoad);
  }

  async GetScreenRightsByUser(userId) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_SCREENRIGHTBY_USER });
    let payLoad = [];
    const idParams = "?UserID=" + userId;
    let shouldWait = false;
    await myApi.endpoints[this.endpoint.GET_SCREENRIGHTBY_USER]
      .getById(idParams)
      .then(({ data }) => {
        payLoad = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        payLoad = this.GetScreenRightsByUser(userId);
      });
    }
    return Promise.resolve(payLoad);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("currentuser"));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentuser");
    localStorage.removeItem("userrights");
    localStorage.removeItem("branchID");
    localStorage.removeItem("Store/FactoryName");
    localStorage.removeItem("Store/FactoryType");
    localStorage.removeItem("BranchInfo");
    localStorage.removeItem("roleNames");
    localStorage.removeItem("StoreSlots");

    window.location.href = "/";
  }

  async SendOTP(mobileNo) {
    const myApi = new API({ url: this.config.LAUNDRETKART_AUTH });
    myApi.createEntity({ name: this.endpoint.GET_USER_INFO });
    let payLoad = [];
    const idParams = "?mobileNo=" + mobileNo;
    await myApi.endpoints[this.endpoint.GET_USER_INFO]
      .getByIdWithoutToken(idParams)
      .then(({ data }) => {
        payLoad = data;
      })
      .catch((err) => {
        this.handleError(err);
        console.log(err);
      });
    return Promise.resolve(payLoad);
  }

  async SubmitPassword(userId, password) {
    const myApi = new API({ url: this.config.LAUNDRETKART_AUTH });
    myApi.createEntity({ name: this.endpoint.UPDARE_PASSWORD });
    let payLoad = [];
    const post = {
      userId: userId,
      newPassword: password,
    };
    await myApi.endpoints[this.endpoint.UPDARE_PASSWORD]
      .postAllWithoutToken(post)
      .then((data) => {
        payLoad = data;
      })
      .catch((err) => {
        this.handleError(err);
        console.log(err);
      });
    return Promise.resolve(payLoad);
  }

  async ResetPassword(newPassword, oldPassword) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.CHANGE_PASSWORD });
    let payLoad = [];
    const post = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
    await myApi.endpoints[this.endpoint.CHANGE_PASSWORD]
      .create(post)
      .then((data) => {
        payLoad = data;
      })
      .catch((err) => {
        this.handleError(err);
        console.log(err);
      });
    return Promise.resolve(payLoad);
  }
}

export default AuthService;
