import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  // TimePicker,
  Input,
  // DatePicker,
  Space,
  notification,
  Modal,
  Descriptions,
  Upload,
  Table,
} from "antd";
import Button from "../../../Common/Button/button";
import FactoryPreQC from "../../../../services/factorypre-qc";
import { PlusOutlined } from "@ant-design/icons";
import Common from "../../../../services/common";
import ROUTES from "../../../../routes";
import { withRouter } from "react-router-dom";
import Moment from "moment";
import { BarcodeOutlined } from "@ant-design/icons";
import { labels } from "../../../../shared/language";
var jwt = require("jsonwebtoken");

const { Option } = Select;
/**
 * General form as a part of create factory screen
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 */

const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

class GeneralForm extends React.Component {
  /**
   * Initalizing Factory Service
   */
  factoryPreQC = new FactoryPreQC();
  commonReq = new Common();

  /**
   * Storing objects for dynamic controls
   
   *
   * @added 18 June 2020
   */
  state = {
    garmentLoaded: false,
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    tagNo: "",
    qcStatus: 1,
    qcReason: "",
    remark: "",
    fileList: [
      // {
      //   uid: "-1",
      //   name: "image.png",
      //   status: "done",
      //   url:
      //     "https://images.unsplash.com/photo-1549490349-8643362247b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80",
      // },
      // {
      //   uid: "-2",
      //   name: "image.png",
      //   status: "done",
      //   url:
      //     "https://images.unsplash.com/photo-1549490349-8643362247b5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=334&q=80",
      // },
      // {
      //   uid: "-5",
      //   name: "image.png",
      //   status: "error",
      // },
    ],
    garMentData: [],
    garMentHistoryData: [],
    showAddPopup: false,
    statusDDL: [],
    reasonDDL: [],
    orderGarmentID: 0,
    mode: "add",
  };

  columns = [
    {
      title: labels.TIME,
      dataIndex: "createdOn",
      key: "createdOn",
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY HH:mm a");
      },
    },
    //{
    //     title: 'CityID',
    //     dataIndex: 'cityID',
    //     key: 'cityID',
    // },
    {
      title: labels.QC_REASON,
      dataIndex: "qcReason",
      key: "qcReason",
      // sorter: true,
    },
    {
      title: labels.REMARK,
      dataIndex: "remark",
      key: "remark",
      // sorter: true,
    },
    {
      title: labels.QC_PENDING_FROM,
      dataIndex: "qcPendingFrom",
      key: "qcPendingFrom",
      //sorter: true,
    },
    {
      title: labels.QC_STATUS,
      dataIndex: "qcStatus",
      key: "qcStatus",
      // sorter: true,
    },
  ];

  componentDidMount() {
    this.GetQCStatus();
    this.GetQCResion();
    if (
      [ROUTES.VIEW_PRE_QC_FACTORY, ":tagNo"].join("/") === this.props.match.path
    ) {
      this.setState(
        { tagNo: this.props.match.params.tagNo, mode: "view" },
        () => this.GetGarmentInfo()
      );
    }
  }

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  handleCancel = () => this.setState({ previewVisible: false });
  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };
  handleChange = (info) => {
    if (info.fileList.length > 3) {
      notification["error"]({
        message: labels.PRE_QC,
        description: labels.ONLY_3_IMAGES_ARE_ALLOWED,
      });
      return false;
    }
    // if (this.state.fileList.length > 4) {
    //   openNotificationWithIcon(
    //     "error",
    //     "",
    //     "File upload should be three or less then three."
    //   );
    //   return false
    // }

    for (var i = 0; i < info.fileList.length; i++) {
      if (info.fileList[i] !== undefined) {
        const isJpgOrPng =
          info.fileList[i].type === "image/jpeg" ||
          info.fileList[i].type === "image/png" ||
          info.fileList[i].type === "image/jpg";
        if (!isJpgOrPng) {
          notification["error"]({
            message: labels.PRE_QC,
            description: labels.YOU_CAN_ONLY_UPLOAD_JPG_JPEG_PNG_FILE,
          });
          // this.setState({ fileList: [] });

          info.fileList[i] = undefined;
        }
        let isLt3M = false;
        if (info.fileList[i] !== undefined) {
          isLt3M = info.fileList[i].size / 1024 / 1024 < 5;
          if (!isLt3M) {
            notification["error"]({
              message: labels.PRE_QC,
              description: labels.IMAGE_MUST_BE_SMALLER_THAN_5_MB,
            });
            // this.setState({ fileList: [] });
            info.fileList[i] = undefined;
          }
        }
        if (isJpgOrPng && isLt3M) {
          info.fileList[i].status = "done";
        }
      }
    }
    info.fileList = info.fileList.filter((x) => x != undefined);
    this.setState({ fileList: info.fileList });
  };

  onChange = (name, e) => {
    this.setState({ [name]: e.target.value });
  };

  onChangeDDL = (name, e) => {
    this.setState({ [name]: e });
  };

  handleAdd = () => {
    var d = this.state.fileList;
    const formData = new FormData();
    formData.append(
      "QCImage1",
      this.state.fileList.length > 0
        ? this.state.fileList[0].originFileObj
        : null
    );
    formData.append("OrderGarmentID", this.state.orderGarmentID);
    formData.append("QCStatus", this.state.qcStatus);
    formData.append("QCReason", this.state.qcReason.toString());
    formData.append(
      "UserID",
      parseInt(
        jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
          .unique_name
      )
    );
    formData.append(
      "QCImage2",
      this.state.fileList.length > 1
        ? this.state.fileList[1].originFileObj
        : null
    );
    formData.append(
      "QCImage3",
      this.state.fileList.length > 2
        ? this.state.fileList[2].originFileObj
        : null
    );
    formData.append("QCPendingFrom", 1);
    formData.append("Remark", this.state.remark);

    this.factoryPreQC.OrderGarmentQCRequest(formData).then((res) => {
      if (res.result == "Success") {
        this.setState({
          orderGarmentID: [],
          fileList: [],
          remark: "",
          qcStatus: "",
          qcReason: 0,
        });
        notification["success"]({
          message: labels.PRE_QC,
          description: res.message
            ? this.commonReq.Message(res.message)
            : labels.DATA_IS_SUCCESSFULLY_SAVED,
          duration: 1.5,
        });
        this.props.backFunction();
      }
    });
  };
  GetGarmentInfo = () => {
    if (![null, undefined, ""].includes(this.state.tagNo)) {
      this.factoryPreQC
        .GetGarmentDetailByTagNo(this.state.tagNo)
        .then((items) => {
          if (items.data.length > 0) {
            if (this.state.mode === "add") {
              if ([4, 6, 7].includes(parseInt(items.data[0].garmentStatus))) {
                //debugger;
                if ([1, 2, 3, 4].includes(items.data[0].postQCStatus)) {
                  notification["error"]({
                    message: labels.PRE_QC,
                    description:
                      labels.GARMENT_PROCEEDED_FURTHER_FOR_POST_PROCESS,
                  });
                  return;
                }
                this.setState({
                  garMentData: items.data,
                  garmentLoaded: true,
                  orderGarmentID:
                    items.data.length > 0 ? items.data[0].orderGarmentID : 0,
                });
              } else {
                notification["error"]({
                  message: labels.PRE_QC,
                  description:
                    labels.GARMENT_SHOULD_BE_INFACTORY_TO_BE_ADDED_FOR_QC,
                });
              }
            } else {
              this.setState({
                garMentData: items.data,
                garmentLoaded: true,
                orderGarmentID:
                  items.data.length > 0 ? items.data[0].orderGarmentID : 0,
              });
            }
          } else {
            notification["error"]({
              message: labels.PRE_QC,
              description: labels.UNABLE_TO_GET_TAG_DATA,
            });
            this.setState({
              garmentLoaded: false,
              garMentData: [],
              orderGarmentID: 0,
            });
          }
        });
    } else {
      this.setState({
        garmentLoaded: false,
        garMentData: [],
        orderGarmentID: 0,
      });
    }
  };

  GetQCHistoryByTagNo = () => {
    this.setState({ showAddPopup: true });
    this.factoryPreQC.GetQCHistoryByTagNo(this.state.tagNo).then((items) => {
      let HistoryData = [];
      items.data.map((singleData) => {
        singleData.key = singleData.orderGarmentID;
        singleData.qcPendingFrom = ["Approved", "Rejected"].includes(
          singleData.qcStatus
        )
          ? ""
          : singleData.qcPendingFrom;

        HistoryData.push(singleData);
        return null;
      });
      this.setState({
        garMentHistoryData: HistoryData,
      });
    });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    // this.resetFields();
  };

  GetQCStatus = () => {
    this.commonReq.GetSystemEnums("FinalQCStatus").then((res) => {
      this.setState({ statusDDL: res.data });
    });
  };
  GetQCResion = () => {
    this.commonReq.GetSystemEnums("PreQCReason").then((res) => {
      this.setState({ reasonDDL: res.data });
    });
  };
  render() {
    const disableAll = this.props.mode === "view";
    const { garMentData } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">{labels.UPLOAD}</div>
      </div>
    );
    return (
      <Form
        key={this.props.values.qcTagNo}
        name="general"
        layout="vertical"
        initialValues={this.props.values}
        ref={this.props.instance}
        style={{ display: !this.props.isActive ? "none" : "block" }}
        onFinish={this.handleAdd}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={16}>
            <Form.Item
              label={labels.TAG_NUMBER}
              name="tagNumber"
              rules={[
                { required: true, message: labels.PLEASE_SCAN_ADD_TAG_NUMBER },
              ]}
            >
              <div className="w-100" style={{ display: "flex" }}>
                <Input
                  placeholder={labels.ENTER_SCAN_TAG_NUMBER}
                  disabled={disableAll}
                  value={this.state.tagNo || ""}
                  onChange={this.onChange.bind(this, "tagNo")}
                  onBlur={this.GetGarmentInfo}
                  suffix={<BarcodeOutlined />}
                />
                {/* <Button
                  variant="transparentGreyOutline"
                  style={{ marginLeft: 10 }}
                  onClick={this.GetGarmentInfo}
                >
                  Get Garment Info
                </Button> */}

                <Button
                  variant="transparentGreyOutline"
                  style={{ marginLeft: 10 }}
                  onClick={this.GetQCHistoryByTagNo}
                  disabled={!this.state.garmentLoaded}
                >
                  {labels.GET_GARMENT_HISTORY}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        {this.state.garmentLoaded && this.state.garMentData.length > 0 ? (
          <Descriptions
            title={labels.GARMENT_INFORMATION}
            bordered
            size="small"
            column={2}
          >
            <Descriptions.Item label={labels.CUSTOMER_NAME} span={2}>
              {this.state.garMentData[0].customerName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.CUSTOMER_TYPE}>
              {garMentData[0].customerType}
            </Descriptions.Item>
            <Descriptions.Item label={labels.CONTACT}>
              {garMentData[0].customerContactNo}
            </Descriptions.Item>
            <Descriptions.Item label={labels.ORDER_DATE}>
              {Moment(garMentData[0].orderDate).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label={labels.SPLIT_ORDER_NO}>
              {garMentData[0].splitOrderNumber}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GATE_PASS_NO}>
              {garMentData[0].gatePassNo}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GATE_PASS_DATE}>
              {Moment(garMentData[0].gatePassDate).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GARMENT_NAME}>
              {garMentData[0].garmentName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.GARMENT_STATUS}>
              {garMentData[0].garmentStatusName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.DELIVERY_DATE}>
              {Moment(garMentData[0].deliveryDate).format("DD-MM-YYYY")}
            </Descriptions.Item>
            <Descriptions.Item label={labels.BRAND}>
              {garMentData[0].garmentBrand}
            </Descriptions.Item>
            <Descriptions.Item label={labels.COLOR}>
              {garMentData[0].colorNames}
            </Descriptions.Item>
            <Descriptions.Item label={labels.SERVICE}>
              {garMentData[0].serviceName}
            </Descriptions.Item>
            <Descriptions.Item label={labels.ADD_ON_SERVICE}>
              {garMentData[0].additionalServiceName}
            </Descriptions.Item>
            {/* <Descriptions.Item label="UOM">{garMentData[0].uom}</Descriptions.Item> */}
            <Descriptions.Item label={labels.DELIVERY_TYPE}>
              {garMentData[0].deliveryType}
            </Descriptions.Item>
            {this.props.mode === "view" ? (
              <Descriptions.Item label="Images">
                <React.Fragment>
                  {this.props.values.qcImage1 !== "" &&
                  this.props.values.qcImage1 !== null ? (
                    <a href={this.props.values.qcImage1} target="_blank">
                      Image 1
                    </a>
                  ) : null}
                  <br />
                  {this.props.values.qcImage2 !== "" &&
                  this.props.values.qcImage2 !== null ? (
                    <a href={this.props.values.qcImage2} target="_blank">
                      Image 2
                    </a>
                  ) : null}
                  <br />
                  {this.props.values.qcImage3 !== "" &&
                  this.props.values.qcImage3 !== null ? (
                    <a href={this.props.values.qcImage3} target="_blank">
                      Image 3
                    </a>
                  ) : null}
                </React.Fragment>
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        ) : null}
        <Row gutter={30}>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.STATUS}
              name="qcStatus"
              rules={[{ required: true, message: labels.PLEASE_SELECT_STATUS }]}
              initialValue={1}
            >
              <Select
                placeholder={labels.SELECT_STATUS}
                disabled={disableAll}
                value={this.state.qcStatus || ""}
                onChange={(value) => this.setState({ qcStatus: value })}
                defaultActiveFirstOption
              >
                {this.state.statusDDL.length !== 0
                  ? this.state.statusDDL.map((res) => {
                      return res.enumDetailID !== "-1" ? (
                        <Option
                          value={res.enumDetailID}
                          key={res.enumDetailID}
                          disabled={parseInt(res.enumDetailID) === 2}
                        >
                          {res.enumDetailName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label={labels.REASON}
              name="qcReason"
              rules={[{ required: true, message: labels.PLEASE_SELECT_REASON }]}
            >
              <Select
                placeholder={labels.SELECT_REASON}
                disabled={disableAll}
                mode="multiple"
                onChange={this.onChangeDDL.bind(this, "qcReason")}
              >
                {this.state.reasonDDL.length !== 0
                  ? this.state.reasonDDL.map((res) => {
                      return res.enumDetailID !== "-1" ? (
                        <Option value={res.enumDetailID} key={res.enumDetailID}>
                          {res.enumDetailName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={24}>
            <Form.Item
              label={labels.REMARKS}
              name="remark"
              rules={[{ required: true, message: labels.PLEASE_ENTER_REMARKS }]}
            >
              <Input.TextArea
                placeholder={labels.ENTER_REMARKS}
                disabled={disableAll}
                value={this.state.remark}
                onChange={this.onChange.bind(this, "remark")}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            {this.props.mode !== "view" ? (
              <Form.Item valuePropName={"file"}>
                <Upload
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={this.state.fileList}
                  // onPreview={this.handlePreview}
                  onChange={this.handleChange}
                  customRequest={({ file, onSuccess, onError }) => {
                    onSuccess({ status: "done" }, file);
                  }}
                >
                  {uploadButton}

                  {/* {this.state.fileList.length >= 8 ? null : uploadButton} */}
                </Upload>
              </Form.Item>
            ) : null}
          </Col>

          <Col xs={24} style={{ textAlign: "right" }}>
            <Space>
              <div className="w-100"></div>
              <Button
                variant="transparentGreyOutline"
                onClick={this.props.backFunction}
                shape="round"
                size="large"
              >
                {labels.CANCEL}
              </Button>
              {!disableAll ? (
                <Button
                  htmlType="submit"
                  variant="purple"
                  shape="round"
                  size="large"
                  disabled={!this.state.garmentLoaded || disableAll}
                  // onClick={this.props.nextFunction}
                  // onClick={this.handleAdd}
                >
                  {labels.SUBMIT}
                </Button>
              ) : null}
            </Space>
          </Col>
        </Row>

        <Modal
          title={labels.COMMUNICATION_HISTORY}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Table
            size="large"
            columns={this.columns}
            dataSource={this.state.garMentHistoryData}
            loading={this.state.tableLoading}
            pagination={false}
          />
        </Modal>
      </Form>
    );
  }
}
export default withRouter(GeneralForm);
