import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class ResortingAndpackagingServices {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async GetPackingDetailsBySplitOrder(splitOrder) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    allFactoriesAPI.createEntity({
      name: this.endpoint.GET_PACKING_DETAILS_BY_SPLIT_ORDER,
    });
    let factories = [];
    let factoryID = parseInt(localStorage.getItem("branchID"));
    let payload = "?tagNumber=" + splitOrder + "&FactoryID=" + factoryID;
    await allFactoriesAPI.endpoints[
      this.endpoint.GET_PACKING_DETAILS_BY_SPLIT_ORDER
    ]
      .getOne(payload, {})
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.GetPackingDetailsBySplitOrder(splitOrder);
      });
    }
    return Promise.resolve(factories);
  }

  async GenerateStickers(postSave) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.GENERATE_STICKERS });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.GENERATE_STICKERS]
      .create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GenerateStickers(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async GetStickerInfoToPrint(stikerId) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.GET_STIKER_INFO_TO_PRINT });
    let itemArray = [];
    var param = "?StickerID=" + stikerId;
    await myApi.endpoints.GetStickerInfoToPrint.getOne(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetStickerInfoToPrint(stikerId);
      });
    }
    return Promise.resolve(itemArray);
  }

  async ResortingAndPackagingList(param) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.RESORTING_AND_PACKAGING_LIST });
    let itemArray = [];
    await myApi.endpoints.ResortingAndPackagingList.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleError(error) {
    console.log(error.message);
  }
}
