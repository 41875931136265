import React from "react";
import { withRouter } from "react-router-dom";
import CustomerService from "../../../../services/customerservice";
import { notification, Typography, Form, Input, Spin } from "antd";
import styles from "../../../Common/Styles/master.module.css";
import Button from "../../../Common/Button/button";
import { CloseCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";
import { labels } from "../../../../shared/language";

class Verification extends React.Component {
  customerService = new CustomerService();
  state = {
    customerObj: {},
    fullLoading: true,
    isDisableSentOTP: false,
    isDisableResendOTP: false,
    isDisableVerifyOTP: false,
  };

  componentDidMount() {
    this.getCustomer(this.props.customerID);
  }

  getCustomer = (id) => {
    this.setState({ fullLoading: true });
    this.customerService.getCustomer(id).then((response) => {
      if (response.statusCode === 200) {
        this.setState({ customerObj: response.data });
        this.setState({ fullLoading: false });
      } else {
        notification["error"]({
          message: "Customer Master",
          description: "Unable to get customer data",
          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
    });
  };

  sendMobileOTP = () => {
    this.setState({ isDisableVerifyOTP: true, isDisableResendOTP: true },
      () => this.customerService
        .sendMobileOTP(this.state.customerObj.customerID)
        .then((response) => {
          if (response.statusCode === 200) {
            this.props.setMobileOTPSent();
            notification["success"]({
              message: "Customer Master",
              description: response.message
                ? response.message
                : "OTP Sent Successfully",
            });
          } else {
            notification["error"]({
              message: "Customer Master",
              description: response.message
                ? response.message
                : "Unable to send OTP",
            });
          }
          this.setState({ isDisableVerifyOTP: false, isDisableResendOTP: false })
        }))
  };

  verifyMobileOTP = (values) => {
    const postData = {
      customerID: this.state.customerObj.customerID,
      otp: values.otp.toString(),
    };
    this.setState({ fullLoading: true, isDisableVerifyOTP: true },
      () => this.customerService.verifyMobileOTP(postData).then((response) => {
        if (response.statusCode === 200) {
          this.getCustomer(this.state.customerObj.customerID);
          notification["success"]({
            message: "Customer Master",
            description: response.message
              ? response.message
              : "Successfully Verified",
          });
        } else {
          notification["error"]({
            message: "Customer Master",
            description: response.message
              ? response.message
              : "Unable to validate OTP",
          });
        }
        this.setState({ fullLoading: false, isDisableVerifyOTP: false });
      })
    );

  };

  render() {
    return (
      <div className={styles.VerificationContainer}>
        <Spin spinning={this.state.fullLoading}>
          <div className={styles.VerificationWrapper}>
            <div>
              {!(
                this.state.customerObj.isMobileVerified ||
                this.state.customerObj.isEmailVerified
              ) ? (
                  this.props.mobileOTPSent ? (
                    <React.Fragment>
                      <div>
                        <Form
                          name="mobileOTPForm"
                          layout="inline"
                          onFinish={this.verifyMobileOTP}
                        >
                          <Form.Item
                            name="otp"
                            rules={[
                              {
                                required: true,
                                message: labels.PLEASE_ENTER_OTP,
                              },
                            ]}
                          >
                            <Input placeholder={labels.ENTER_OTP} />
                          </Form.Item>
                          <Form.Item>
                            <Button htmlType="submit" variant="purple" disabled={this.state.isDisableVerifyOTP} >
                              {labels.VERIFY}
                            </Button>
                          </Form.Item>
                        </Form>
                        <div className={styles.ResendLink}>
                          {labels.DIDNT_RECIEVED_ANYTHIG}
                          <Button
                            type="link"
                            variant="link"
                            onClick={this.sendMobileOTP}
                            // disabled={this.state.isDisableResendOTP}
                            loading={this.state.isDisableResendOTP}
                          >
                            {labels.RESEND_OTP}
                          </Button>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                      <React.Fragment>
                        <CloseCircleTwoTone
                          twoToneColor="#eb2f96"
                          style={{ fontSize: 35, marginBottom: 15 }}
                        />
                        <Typography.Title level={3}>
                          {labels.NOT_VERIFIED}
                        </Typography.Title>
                        {this.props.mode === "view" ? null : (
                          <Button variant="purple" onClick={this.sendMobileOTP} loading={this.state.isDisableVerifyOTP}>
                            {labels.VERIFY_PHONE_NUMBER}
                          </Button>
                        )}
                      </React.Fragment>
                    )
                ) : (
                  <React.Fragment>
                    <CheckCircleTwoTone
                      twoToneColor="#52c41a"
                      style={{ fontSize: 35, marginBottom: 15 }}
                    />
                    <Typography.Title level={3}>
                      {labels.VERIFIED}
                    </Typography.Title>
                  </React.Fragment>
                )}
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default withRouter(Verification);
