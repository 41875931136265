import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  Checkbox,
  notification,
  Tooltip,
  Spin,
  Upload,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,

  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ServiceIconItem from "../../../services/serviceiconsservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import Configuration from "../../../shared/configuration";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import PackagingChargesService from "../../../services/packagingchargeservice";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const { Search } = Input;
const { Option } = Select;

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.serviceIcon = new ServiceIconItem();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.PackageChargeService = new PackagingChargesService();
    this.config = new Configuration();
    this.state = {
      modalLoading: false,
      serviceItems: [],
      iconID: 0,
      iconName: "",
      serviceID: 0,
      serviceName: [],
      serviceListOptions: [],
      description: "",

      iconImage: "",
      fileList: [],
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
      isResponseErrorShow: false,
      mode: "add",
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.SERVICE_ICONS
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    // {
    //   title: "Icon ID",
    //   dataIndex: "iconID",
    //   key: "iconID",
    // },
    // {
    //   title: "Service ID",
    //   dataIndex: "serviceID",
    //   key: "serviceID",
    // },
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
      sorter: true,
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "Icon Name",
      dataIndex: "iconName",
      key: "iconName",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "Icon Image",
      dataIndex: "iconImage",
      key: "iconImage",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip
          placement="topLeft"
          title={address.split(this.config.SERVICE_ICONS_MASTER_IMAGE_URL)}
        >
          {address.split(this.config.SERVICE_ICONS_MASTER_IMAGE_URL)}
        </Tooltip>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    mode: "edit",
                    showAddPopup: true,
                    iconID: record.iconID,
                    serviceID: record.serviceID,
                    serviceName: record.serviceName,
                    iconName: record.iconName,
                    description: record.description,
                    iconImage: record.iconImage,
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    mode: "edit",
                    showAddPopup: true,
                    iconID: record.iconID,
                    serviceID: record.serviceID,
                    serviceName: record.serviceName,
                    iconName: record.iconName,
                    description: record.description,
                    iconImage: record.iconImage,
                  })
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.iconID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      iconID: this.state.iconID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
    this.DropDownServiceList();
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      iconID: this.state.iconID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      iconID: this.state.iconID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
     
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.serviceIcon.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            iconID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          serviceItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "Service Icons Master",
            description: items.message
              ? items.message
              : "Unable to get list of service icon data",
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (iconID) => {
    if ([null, undefined, ""].includes(iconID)) {
      // openNotificationWithIcon(
      //   "error",
      //   "Service Master",
      //   "Please Select Atleast One Row"
      // );
      notification["error"]({
        message: "Service Icons Master",
        description: "Please Select Atleast One Row",
      });
    } else {
      this.setState({ loading: true });
      this.serviceIcon.removeItems(iconID).then((items) => {
        if (items.result === "Success") {
          // openNotificationWithIcon("success", "Service Master", items.message);
          notification["success"]({
            message: "Service Icons Master",
            description: items.message,
          });
          const { pagination } = this.state;
          this.fetchItems({
            iconID: parseInt(this.state.iconID),
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
          });
        } else {
          // openNotificationWithIcon("error", "Service Master", items.message);
          notification["error"]({
            message: "Service Icons Master",
            description: items.message,
          });
        }
        this.setState({ loading: false });
      });
    }
  };

  oldIconImageFromUrl = () => {
    let str1 = "";
    if (![undefined, null, ""].includes(this.state.iconImage)) {
      let str = this.state.iconImage.split("/");
      str1 = str[str.length - 1];
    }
    return str1;
  };

  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    var fileData = this.state.fileList;
    const formData = new FormData();
    let isNewImageUpload = this.state.fileList.length === 0 ? false : true;
    formData.append(
      "image",
      this.state.fileList.length > 0
        ? this.state.fileList[0].originFileObj
        : null
    );
    formData.append("iconID", this.state.iconID);
    formData.append("serviceID", this.state.serviceID);
    formData.append("IconName", this.state.iconName);
    formData.append("description", this.state.description);
    formData.append("isActive", true);
    formData.append("isNewImgUploaded", isNewImageUpload);
    formData.append("oldIconImg", this.oldIconImageFromUrl());

    for (var value of formData.values()) {
      // console.log(value);
    }
    this.setState({ modalLoading: true });
    this.serviceIcon.insertUpdateItems(formData).then((items) => {
      if (items.result === "Success") {
        // openNotificationWithIcon("success", "Service Master", items.message);
        notification["success"]({
          message: "Service Icons Master",
          description: items.message,
        });
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          iconID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        // openNotificationWithIcon("error", "Service Master", items.message);
        notification["error"]({
          message: "Service Icons Master",
          description: items.message,
        });
      }
      this.setState({ modalLoading: false });
    });
  };
  //function for changing/updating the service name
  handleServiceIconName = (e) => {
    let iconNm = e.target.value;
    this.setState({ iconName: iconNm });
  };

  handleDescriptionChange = (e) => {
    let details = e.target.value;
    this.setState({ description: details });
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = (info) => {
    if (info.fileList.length > 0) {
      if (info.file !== undefined) {
        const isJpgOrPng =
          info.file.type === "image/svg+xml" ||
          info.file.type === "image/jpeg" ||
          info.file.type === "image/png" ||
          info.file.type === "image/jpg";
        if (!isJpgOrPng) {
          notification["error"]({
            message: "Service Icons Master",
            description: "You can only upload JPG/PNG/SVG/JPEG file!",
          });
          info.file = undefined;
        }
        let isLt1M = false;
        if (info.file !== undefined) {
          isLt1M = info.file.size / 1024 / 1024 < 1;
          if (!isLt1M) {
            notification["error"]({
              message: "Service Icons Master",
              description: "Image must be smaller than 1 MB!",
            });
            info.file = undefined;
          }
        }
        if (isJpgOrPng && isLt1M) {
          info.file.status = "done";
        }
        var infoFileList = [];
        infoFileList.push(info.file);
        info.file = infoFileList.filter((x) => x != undefined);
        this.setState({ fileList: info.file });
        if (info.file.length > 0) {
          this.setState({ iconImage: "" });
        }
      }
    } else {
      this.setState({ fileList: [], iconImage: "" });
    }
  };
  //function for clearing out the states
  resetFields = () => {
    this.setState({
      serviceID: 0,
      serviceName: [],
      iconName: "",
      iconID: 0,
      description: "",
      fileList: [],
      isAddOn: false,
      isEnabled: false,
      isVisible: false,
      iconImage: "",
    });
  };

  handleCheckbox = (event) => {
    this.setState({ isAddOn: event.target.checked });
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification["error"]({
        message: "Service Icons Master",
        description: "You can only upload JPG/PNG file!",
      });
      this.setState({ fileList: [] });
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      notification["error"]({
        message: "Service Icons Master",
        description: "Image must smaller than 1MB!",
      });
      this.setState({ fileList: [] });
    }
    return isJpgOrPng && isLt2M;
  };

  DropDownServiceList = () => {
    this.PackageChargeService.serviceListItems().then((items) => {
      if (items.statusCode === 200) {
        this.setState({ serviceListOptions: items.data });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "Service Icons Master",
            description: items.message
              ? items.message
              : "Unable to get list of service icons data",
          });
        }
      }
    });
  };

  RestoreDDlValue = (e) => {
    var serviceArray = this.state.serviceListOptions.filter(
      (x) => x.value === e
    );
    if (serviceArray !== undefined) {
      this.setState({
        serviceID: serviceArray[0].value,
        serviceName: serviceArray[0].text,
      });
    }
  };

  render() {
    const {
      serviceName,
      iconName,
      description,
      pagination,
      iconImage,
      loading,
    } = this.state;
    const Regex = /^[A-Za-z0-9_&-\s]*$/;
    const serviceItems = this.state.serviceItems;
    const listItems = serviceItems.map((item, index) => ({
      key: item.iconID,
      iconID: item.iconID,
      serviceID: item.serviceID,
      serviceName: item.serviceName,
      iconName: item.iconName,
      description: item.description,
      iconImage: item.iconImage,
      isActive: item.isActive.toString(),
      createdByName: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD/MM/YYYY hh:mm:ss a"),
      totalRecords: item.totalRecords,
    }));

    const uploadButton =
      this.state.fileList.length === 0 ? (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">Upload</div>
        </div>
      ) : null;
    return (
      <div>
        <PageHeader
          title="Service Icons Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,

            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                Add Service Icon
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            serviceItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>

        <Modal
          title={
            this.state.iconID === 0
              ? "Add Service Icon"
              : this.state.isEnabled
              ? "View Service Icon"
              : "Edit Service Icon"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                iconName,
                serviceName,
                description,
                iconImage,
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Service Name "
                    name={"serviceName"}
                    rules={[
                      { required: true, message: "Please Select Service Name" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Service Name"
                      onChange={this.RestoreDDlValue}
                      disabled={this.state.isEnabled}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.serviceListOptions.map((service) =>
                        service.value !== "-1" ? (
                          <option key={service.value} value={service.value}>
                            {service.text}
                          </option>
                        ) : null
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Icon Name "
                    name="iconName"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Service Icon Name",
                      },
                      // {
                      //   pattern: Regex,
                      //   message:
                      //     "You Can Enter Alphabets,Digits,Underscore, Hyphen Or Ampersand",
                      // },
                    ]}
                  >
                    <Input
                      placeholder="Enter Service Icon Name"
                      disabled={this.state.isEnabled}
                      onChange={this.handleServiceIconName}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      { required: true, message: "Please Enter Description" },
                    ]}
                  >
                    <TextArea
                      placeholder="Enter Description "
                      disabled={this.state.isEnabled}
                      onChange={this.handleDescriptionChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  {this.state.isEnabled === false ? (
                    <>
                      <Space>
                        <Form.Item
                          label="Select File To Upload"
                          name="file"
                          valuePropName={"file"}
                          rules={[
                            {
                              required:
                                this.state.mode === "edit" ? false : true,
                              message: "Please Select File To Upload",
                            },
                          ]}
                        >
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={this.state.fileList}
                            // onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            customRequest={({ file, onSuccess, onError }) => {
                              onSuccess({ status: "done" }, file);
                            }}
                          >
                            {/* {this.state.fileList.length >= 0
                              ? null
                              : uploadButton} */}
                            {uploadButton}
                          </Upload>
                        </Form.Item>
                        {this.state.serviceID != 0 ? (
                          <Form.Item>
                            <img
                              src={this.state.iconImage}
                              style={{ maxWidth: "100px", maxHeight: "100px" }}
                            />
                          </Form.Item>
                        ) : null}
                      </Space>
                    </>
                  ) : (
                    <Form.Item label="Image Url">
                      <img
                        src={this.state.iconImage}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.iconID === 0 ? "Add" : "Update"}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
