import React from "react";
import {
  Row,
  Col,
  notification,
  Spin,
  Modal,
  Form,
  Input,
  Space,
  Alert,
} from "antd";
import CustomerSelection from "./customerselection.component";
import GarmentSelection from "./garmentselection.component";
import OrderSummary from "./ordersummary.component";
import ReviewOrder from "./revieworder.component";
// import JeansIcon from "../../../../images/Order/jeans.svg";
// import BabyShirtIcon from "../../../../images/Order/babyshirt.svg";
// import BabySuitIcon from "../../../../images/Order/babysuit.svg";
// import CapIcon from "../../../../images/Order/cap.svg";
// import DressIcon from "../../../../images/Order/dress.svg";
// import ShirtIcon from "../../../../images/Order/shirt.svg";
// import ShortsIcon from "../../../../images/Order/shorts.svg";
// import SocksIcon from "../../../../images/Order/socks.svg";
// import TopIcon from "../../../../images/Order/top.svg";
// import TshirtIcon from "../../../../images/Order/tshirt.svg";
import Button from "../../../Common/Button/button";
import CreateOrderService from "../../../../services/createorderservice";
import moment from "moment";
import ROUTES from "../../../../routes";
import { withRouter, Link } from "react-router-dom";
import GeneratePrintInfo from "./printtag";
import GenerateRewashTagPrintInfo from "./printrewashtag";
import { getPDFEstimatedOrder } from "./estimatedorderpdf.component";
import { labels } from "../../../../shared/language";
import StoreService from "../../../../services/storeservice";
import Common from "../../../../services/common";
import PickupService from "../../../../services//pickupservice";

/**
 * Create Order Screen
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 5 June 2020
 */
var inc = 1;
class CreateOrder extends React.Component {
  storeservice = new StoreService();
  createOrderService = new CreateOrderService();
  common = new Common();
  pickupService = new PickupService();
  state = {
    cancelPopupLoading: false,
    spinning: false,
    showCancelPopup: false,
    isFromPickup: false,
    isOnDemand: false,
    order: {},
    GarmentDeliveryType: [], //Added by rashmi for L177728 - 22/02/23
    // order: {
    //   "1": {
    //     "1": 1,
    //     "2": 1,
    //     "3": 1,
    //   },
    //   "2": {
    //     "-1": 2,
    //     "2": 1,
    //   },
    // },
    HolidayList: [],
    orderArray: [],
    isReview: false,
    rates: {},
    uoms: {},
    garmentList: [],
    customerInfo: {},
    garmentSelectionShouldUpdate: false,
    timeCard: null,
    cgst: {},
    sgst: {},
    igst: {},
    packagingCharges: [],
    deliveryCharges: {
      minBillAmount: 0,
      fixedCharges: 0,
      perCharges: 0,
      maxCharges: 0,
      cgst: 0,
      sgst: 0,
      igst: 0,
    },
    storeID: parseInt(localStorage.getItem("branchID")),
    orderDate: moment(),
    orderNumber: 0,
    orderStatus: 0,
    logisticInstruction: "",
    operationInstruction: [],
    deletedGarmentIDs: [],
    orderSummaryObject: {},
    promocode: "",
    isPromocodeValid: false,
    discount: 0,
    rewashOrderID: 0,
    rewashReason: null,
    rewashRequestedBy: 0,
    orderType: 1,
    storeInfo: {
      services: [],
      status: 1,
      deliveryTypes: [],
      onDemandWalkIn: [],
    },
    isRender: true,
    rateCard: [],
    showrewashtag: false,
    AllowCancelOrder: false,
  };

  shouldComponentUpdate() {
    if (!this.state.isReview) return true;
    else return this.state.isRender ? true : false;
  }

  componentDidMount() {
    // getPDFEstimatedOrder("203");
    this.getStore(parseInt(localStorage.getItem("branchID")), () => {
      this.getGarmentsList(null, null);
      this.getTimeCard();
      this.getPackagingCharges();
      this.getDeliveryCharges();
      if (
        this.props.location.state !== undefined &&
        this.props.location.state !== null
      ) {
        if (
          this.props.location.state.pickupID !== undefined &&
          this.props.location.state.pickupID !== null
        ) {
          this.setState({ isFromPickup: true });
          this.getPickupInformation(this.props.location.state.pickupID);
        }
      }
    });
    this.getHolidayList();
  }

  getHolidayList = () => {
    var postData = parseInt(localStorage.getItem("branchID"));
    this.pickupService.retriveHolidaylist(postData).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          HolidayList: response.data,
        });
        // console.log("HolidayList in reviewOrder->", this.state.HolidayList);
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_HOLIDAYLIST,
        });
      }
    });
  };

  getStore = (id, callback = () => null) => {
    this.setState({ spinning: true });
    this.storeservice.getStoreDetailByStoreID(id).then((response) => {
      if (response.statusCode === 200) {
        this.setState(
          {
            storeInfo: {
              ...this.state.storeInfo,
              services: [null, undefined, ""].includes(response.data.serviceIds)
                ? []
                : response.data.serviceIds.split(","),
              status: parseInt(response.data.vMStoreMaster.status),
              deliveryTypes: [null, undefined, ""].includes(
                response.data.vMStoreMaster.deliveryType
              )
                ? []
                : response.data.vMStoreMaster.deliveryType.split(","),
              onDemandWalkIn: [null, undefined, ""].includes(
                response.data.vMStoreMaster.storeServiceType
              )
                ? []
                : response.data.vMStoreMaster.storeServiceType.split(","),
            },
          },
          () => callback()
        );
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: labels.UNABLE_TO_GET_STORE_DATA,

          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
      this.setState({ spinning: false });
    });
  };
  getPickupInformation = (id) => {
    this.createOrderService.getPickupInformation(id).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          isFromPickup: true,
          customerInfo: {
            ...this.state.customerInfo,
            id: response.data.customerID,
            name: response.data.customerName,
            mobileNo: response.data.contactNumber,
            alterNativeMobileNo: response.data.custAltMobileNo,
            selectedAddress: {
              name: response.data.address,
              id: response.data.customerAddressID,
            },
            serviceType: {
              name: response.data.deliveryTypeName,
              id: response.data.deliveryType,
            },
            pickupRequest: response.data.pickupID,
            customerType: response.data.customerTypeName,
            walletBalance: response.data.walletBalance,
            lkCreditAmt: response.data.lkCreditAmt,
          },
          opeartionInstruction: [null, undefined, ""].includes(
            response.data.operationInstID
          )
            ? undefined
            : response.data.operationInstID.split(","),
          promocode: [null, undefined, ""].includes(response.data.promocode)
            ? ""
            : response.data.promocode,
          // discount: response.data.discountAmount,
          isPromocodeValid: [null, undefined, ""].includes(
            response.data.promocode
          )
            ? false
            : true,
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: labels.UNABLE_TO_GET_PICKUP_DATA,
        });
      }
    });
  };
  showCancelPopup = () => {
    this.setState({ showCancelPopup: true });
  };
  generateTags = () => {
    this.createOrderService
      .generateTags(this.state.orderID)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: labels.ORDERS,
            description: labels.TAGS_GENERATED_SUCCESSFULLY,
            duration: 1.5,
          });
          this.printTags();
          // this.getOrderByID(this.state.orderID);
        } else {
          notification["error"]({
            message: labels.ORDERS,
            description: labels.UNABLE_TO_GENERATE_TAGS,
            duration: 1.5,
          });
        }
      });
  };
  printTags = () => {
    this.setState({ spinning: true });
    this.createOrderService.GetTagToPrint(this.state.orderID).then((res) => {
      this.setState({ spinning: false });
      // console.log("res->", res);
      this.setState({ showAddPopup: true, printInfo: res.data });
      this.printTagRef.current.click();
      this.setState({ showAddPopup: false });
    });
  };

  rewashprintTags = () => {
    this.setState({ spinning: true });
    this.createOrderService
      .GetRewashTagToPrint(this.state.orderID)
      .then((res) => {
        this.setState({ spinning: false });
        // console.log("rewash->", res);
        this.setState({ showrewashtag: true, printInfo: res.data });
        this.printRewashTagRef.current.click();
        this.setState({ showrewashtag: false });
      });
  };
  deleteGarments = (id) => {
    if (parseInt(id) !== 0) {
      let updated = this.state.deletedGarmentIDs;
      updated.push(parseInt(id));
      this.setState({ deletedGarmentIDs: updated });
    }
  };

  // getOrderByID(id) {
  //   this.setState({ spinning: true });
  //   this.setState({ order: {}, orderArray: [] });
  //   this.createOrderService.getOrderByID(id).then((response) => {
  //     if (response.statusCode === 200) {
  //       if (parseInt(response.data.orderStatus) >= 2) {
  //         this.setState({
  //           orderSummaryObject: {
  //             subtotal: response.data.serviceBasicAmount,
  //             cgst: response.data.cgst,
  //             sgst: response.data.sgst,
  //             igst: response.data.igst,
  //             discount: response.data.discountAmount,
  //             deliveryCharges: response.data.deliveryCharges,
  //             packagingCharges: response.data.packagingCharges,
  //             total: response.data.finalOrderAmount,
  //             count: response.data.noOfGarment,
  //             deliveryDate: response.data.deliveryDate,
  //           },
  //           promocode:
  //             response.data.promocode === null ? "" : response.data.promocode,
  //           discount: response.data.discountAmount,
  //           isPromocodeValid: true,
  //         });
  //       }
  //       this.setState({
  //         promocode: [null, undefined, ""].includes(response.data.promocode)
  //           ? ""
  //           : response.data.promocode,
  //         discount: response.data.discountAmount,
  //         isPromocodeValid: [null, undefined, ""].includes(
  //           response.data.promocode
  //         )
  //           ? false
  //           : true,
  //         isOnDemand: response.data.isOnDemandDelivery,
  //         isFromPickup: parseInt(response.data.pickupID) === 0 ? false : true,
  //         customerInfo: {
  //           ...this.state.customerInfo,
  //           id: response.data.customerID,
  //           name: response.data.customerName,
  //           pickupRequest: response.data.pickupID,
  //           selectedAddress: {
  //             ...this.state.customerInfo.selectedAddress,
  //             id: response.data.customerAddressID,
  //             name: response.data.customerAddressName,
  //           },
  //           serviceType: {
  //             ...this.state.customerInfo.serviceType,
  //             id: response.data.deliveryType,
  //             name: response.data.deliveryTypeName,
  //           },
  //           customerType: response.data.customerTypeName,
  //           corporateID: parseInt(response.data.corporateID),
  //           walletBalance: response.data.lkCashBlcAmt,
  //           lkCreditAmt: response.data.lkCreditBlcAmt,
  //         },
  //         orderID: response.data.orderID,
  //         orderDate: moment(response.data.orderDate),
  //         orderStatus: response.data.orderStatus,
  //         opeartionInstruction: ![null, undefined, ""].includes(
  //           response.data.operationInstruction
  //         )
  //           ? response.data.operationInstruction.split(",")
  //           : [],
  //         logisticInstruction: response.data.logisticInstruction,
  //         orderRemarks:
  //           response.data.orderRemark !== "undefined" &&
  //             response.data.orderRemark !== "null"
  //             ? response.data.orderRemark
  //             : null,
  //         // isReview:
  //         //   parseInt(response.data.orderStatus) === 1 &&
  //         //     parseInt(response.data.orderType) !== 2
  //         //     ? false
  //         //     : true,
  //         isReview: this.props.location.state != undefined ? this.props.location.state.view == 'view' ? true : parseInt(response.data.orderStatus) === 1 &&
  //           parseInt(response.data.orderType) !== 2
  //           ? false
  //           : true : parseInt(response.data.orderStatus) === 1 &&
  //             parseInt(response.data.orderType) !== 2
  //             ? false
  //             : true,
  //         rewashOrderID: response.data.rewashOrderID,
  //         // rewashReason: response.data.rewashReason,
  //         rewashReason: ![null, undefined, ""].includes(
  //           response.data.rewashReason
  //         )
  //           ? response.data.rewashReason.split(",")
  //           : [],

  //         rewashRequestedBy: response.data.rewashRequestedBy,
  //         orderType: response.data.orderType,
  //       });

  //       response.data.vMOrderGarmentDetails.map((garmentObj) => {
  //         let filteredServiceObject = this.state.garmentList.filter(
  //           (x) => parseInt(x.serviceId) === parseInt(garmentObj.serviceID)
  //         )[0];
  //         if (filteredServiceObject !== undefined) {
  //           let filteredGarmentObject = filteredServiceObject.garments.filter(
  //             (x) => parseInt(x.id) === parseInt(garmentObj.garmentID)
  //           )[0];
  //           this.setState({
  //             order: {
  //               ...this.state.order,
  //               [garmentObj.serviceID]:
  //                 this.state.order[garmentObj.serviceID] === undefined
  //                   ? {
  //                     [garmentObj.garmentID]: 1,
  //                   }
  //                   : this.state.order[garmentObj.serviceID][
  //                     garmentObj.garmentID
  //                   ] === undefined
  //                     ? {
  //                       ...this.state.order[garmentObj.serviceID],
  //                       [garmentObj.garmentID]: 1,
  //                     }
  //                     : {
  //                       ...this.state.order[garmentObj.serviceID],
  //                       [garmentObj.garmentID]:
  //                         this.state.order[garmentObj.serviceID][
  //                         garmentObj.garmentID
  //                         ] + 1,
  //                     },
  //             },
  //           });
  //           response.data.vMSplitOrderDetails.map((splitOrder) => {
  //             let filteredServiceObject = this.state.garmentList.filter(
  //               (x) => parseInt(x.serviceId) === parseInt(splitOrder.serviceID)
  //             )[0];
  //             if (parseInt(filteredServiceObject.UOM) === 2) {
  //               this.setState({
  //                 order: {
  //                   ...this.state.order,
  //                   [splitOrder.serviceID]:
  //                     this.state.order[splitOrder.serviceID] !== undefined
  //                       ? {
  //                         ...this.state.order[splitOrder.serviceID],
  //                         "-1": splitOrder.weight,
  //                       }
  //                       : { "-1": splitOrder.weight },
  //                 },
  //               });
  //             }
  //             if (parseInt(filteredServiceObject.UOM) === 3) {
  //               let sqftGarments = response.data.vMOrderGarmentDetails.filter(
  //                 (x) => parseInt(x.uom) === 3
  //               );
  //               let total = 0;
  //               sqftGarments.map((single) => {
  //                 total = total + single.width * single.length;
  //               });
  //               this.setState({
  //                 order: {
  //                   ...this.state.order,
  //                   [splitOrder.serviceID]:
  //                     this.state.order[splitOrder.serviceID] !== undefined
  //                       ? {
  //                         ...this.state.order[splitOrder.serviceID],
  //                         "-1": total,
  //                       }
  //                       : { "-1": total },
  //                 },
  //               });
  //             }
  //           });

  //           let splitOrderID = response.data.vMSplitOrderDetails.filter(
  //             (x) => x.splitOrderNumber === garmentObj.splitOrderNumber
  //           )[0].splitOrderID;
  //           this.state.orderArray.push({
  //             no: this.state.orderArray.length + 1,
  //             orderGarmentID: garmentObj.orderGarmentID,
  //             tagNo: garmentObj.tagNo,
  //             splitOrderID: splitOrderID,
  //             splitOrderNumber: garmentObj.splitOrderNumber,
  //             serviceId: garmentObj.serviceID,
  //             garmentId: garmentObj.garmentID,
  //             garmentName: filteredGarmentObject.name,
  //             serviceName: filteredServiceObject.title,
  //             darningSize: garmentObj.darningTypeID,
  //             dyingColorsUOM:
  //               garmentObj.dyeingColors === ""
  //                 ? 0
  //                 : garmentObj.dyeingColors.split(",").length === 1
  //                   ? 21
  //                   : garmentObj.dyeingColors.split(",").length > 1
  //                     ? 22
  //                     : 0,
  //             dyingColorsValue:
  //               garmentObj.dyeingColors !== "null" &&
  //                 garmentObj.dyeingColors !== "" &&
  //                 garmentObj.dyeingColors !== null
  //                 ? garmentObj.dyeingColors.split(",").length > 0
  //                   ? garmentObj.dyeingColors.split(",")
  //                   : []
  //                 : [],
  //             notes:
  //               garmentObj.specialInstruction !== "" &&
  //                 garmentObj.specialInstruction !== "null"
  //                 ? garmentObj.specialInstruction
  //                 : null,
  //             addOnServices:
  //               garmentObj.addOnServiceIDs !== "" &&
  //                 garmentObj.addOnServiceIDs !== "null" &&
  //                 garmentObj.addOnServiceIDs !== null
  //                 ? garmentObj.addOnServiceIDs.split(",").length > 0
  //                   ? garmentObj.addOnServiceIDs.split(",")
  //                   : []
  //                 : [],
  //             color:
  //               garmentObj.colorIDs !== "null" && garmentObj.colorIDs !== ""
  //                 ? garmentObj.colorIDs
  //                 : null,
  //             brand: garmentObj.brandID,
  //             size: garmentObj.sizeID === 0 ? null : garmentObj.sizeID,
  //             UOM: garmentObj.uom,
  //             deliveryDate:
  //               garmentObj.deliveryDate !== ""
  //                 ? moment(garmentObj.deliveryDate)
  //                 : parseInt(response.data.orderStatus) < 2
  //                   ? moment()
  //                   : null,
  //             price: garmentObj.serviceAmount + garmentObj.addOnServiceAmount,
  //             darningPrice: 0,
  //             dyingPrice: 0,
  //             ucPrice: 0,
  //             rate: garmentObj.serviceAmount,
  //             cgst: garmentObj.cgst,
  //             sgst: garmentObj.sgst,
  //             igst: garmentObj.igst,
  //             width: garmentObj.width,
  //             length: garmentObj.length,
  //             images: [
  //               garmentObj.garmentImage1 === ""
  //                 ? null
  //                 : {
  //                   uid: "1",
  //                   name: garmentObj.garmentImage1.split("/")[
  //                     garmentObj.garmentImage1.split("/").length - 1
  //                   ],
  //                   status: "done",
  //                   url: garmentObj.garmentImage1,
  //                 },
  //               garmentObj.garmentImage2 === ""
  //                 ? null
  //                 : {
  //                   uid: "2",
  //                   name: garmentObj.garmentImage2.split("/")[
  //                     garmentObj.garmentImage2.split("/").length - 1
  //                   ],
  //                   status: "done",
  //                   url: garmentObj.garmentImage2,
  //                 },
  //               garmentObj.garmentImage3 === ""
  //                 ? null
  //                 : {
  //                   uid: "3",
  //                   name: garmentObj.garmentImage3.split("/")[
  //                     garmentObj.garmentImage3.split("/").length - 1
  //                   ],
  //                   status: "done",
  //                   url: garmentObj.garmentImage3,
  //                 },
  //             ],
  //             deliveryType: response.data.deliveryType,
  //             rateRatio: response.data.rateRatio,
  //             deliveryTypeName: response.data.deliveryTypeName
  //           });
  //         }
  //       });
  //       this.GetRateCard()
  //       // this.setState({ spinning: false }, () => {
  //       //   // if (parseInt(response.data.orderStatus) < 2) {
  //       //   this.getRatesForPredefinedOrder();
  //       //   // }
  //       // });
  //     } else {
  //       notification["error"]({
  //         message: labels.ORDERS,
  //         description: response.message
  //           ? this.common.Message(response.message)
  //           : labels.UNABLE_TO_GET_ORDER_INFORMATION,
  //         duration: 1.5,
  //       });
  //     }
  //   });
  // }

  getOrderByID(id) {
    // this.setState({ spinning: true });
    this.setState({ spinning: true, order: {}, orderArray: [] });
    this.createOrderService.getOrderByID(id).then((response) => {
      // console.log("res->", response);
      if (response.statusCode === 200) {
        if (parseInt(response.data.orderStatus) >= 2) {
          this.setState({
            orderSummaryObject: {
              subtotal: response.data.serviceBasicAmount,
              cgst: response.data.cgst,
              sgst: response.data.sgst,
              igst: response.data.igst,
              discount: response.data.discountAmount,
              deliveryCharges: response.data.deliveryCharges,
              packagingCharges: response.data.packagingCharges,
              total: response.data.finalOrderAmount,
              count: response.data.noOfGarment,
              deliveryDate: response.data.deliveryDate,
            },
            promocode:
              response.data.promocode === null ? "" : response.data.promocode,
            discount: response.data.discountAmount,
            isPromocodeValid: true,
          });
        }

        //Umesh remove infinite rerendering Start

        // this.setState({
        //   promocode: [null, undefined, ""].includes(response.data.promocode)
        //     ? ""
        //     : response.data.promocode,
        //   discount: response.data.discountAmount,
        //   isPromocodeValid: [null, undefined, ""].includes(
        //     response.data.promocode
        //   )
        //     ? false
        //     : true,
        //   isOnDemand: response.data.isOnDemandDelivery,
        //   isFromPickup: parseInt(response.data.pickupID) === 0 ? false : true,
        //   customerInfo: {
        //     ...this.state.customerInfo,
        //     id: response.data.customerID,
        //     name: response.data.customerName,
        //     pickupRequest: response.data.pickupID,
        //     selectedAddress: {
        //       ...this.state.customerInfo.selectedAddress,
        //       id: response.data.customerAddressID,
        //       name: response.data.customerAddressName,
        //     },
        //     serviceType: {
        //       ...this.state.customerInfo.serviceType,
        //       id: response.data.deliveryType,
        //       name: response.data.deliveryTypeName,
        //     },
        //     customerType: response.data.customerTypeName,
        //     corporateID: parseInt(response.data.corporateID),
        //     walletBalance: response.data.lkCashBlcAmt,
        //     lkCreditAmt: response.data.lkCreditBlcAmt,
        //   },
        //   orderID: response.data.orderID,
        //   orderDate: moment(response.data.orderDate),
        //   orderStatus: response.data.orderStatus,
        //   opeartionInstruction: ![null, undefined, ""].includes(
        //     response.data.operationInstruction
        //   )
        //     ? response.data.operationInstruction.split(",")
        //     : [],
        //   logisticInstruction: response.data.logisticInstruction,
        //   orderRemarks:
        //     response.data.orderRemark !== "undefined" &&
        //       response.data.orderRemark !== "null"
        //       ? response.data.orderRemark
        //       : null,
        //   // isReview:
        //   //   parseInt(response.data.orderStatus) === 1 &&
        //   //     parseInt(response.data.orderType) !== 2
        //   //     ? false
        //   //     : true,
        //   isReview: this.props.location.state != undefined ? this.props.location.state.view == 'view' ? true : parseInt(response.data.orderStatus) === 1 &&
        //     parseInt(response.data.orderType) !== 2
        //     ? false
        //     : true : parseInt(response.data.orderStatus) === 1 &&
        //       parseInt(response.data.orderType) !== 2
        //       ? false
        //       : true,
        //   rewashOrderID: response.data.rewashOrderID,
        //   // rewashReason: response.data.rewashReason,
        //   rewashReason: ![null, undefined, ""].includes(
        //     response.data.rewashReason
        //   )
        //     ? response.data.rewashReason.split(",")
        //     : [],

        //   rewashRequestedBy: response.data.rewashRequestedBy,
        //   orderType: response.data.orderType,
        // });

        // response.data.vMOrderGarmentDetails.map((garmentObj) => {
        //   let filteredServiceObject = this.state.garmentList.filter(
        //     (x) => parseInt(x.serviceId) === parseInt(garmentObj.serviceID)
        //   )[0];
        //   if (filteredServiceObject !== undefined) {
        //     let filteredGarmentObject = filteredServiceObject.garments.filter(
        //       (x) => parseInt(x.id) === parseInt(garmentObj.garmentID)
        //     )[0];
        //     this.setState({
        //       order: {
        //         ...this.state.order,
        //         [garmentObj.serviceID]:
        //           this.state.order[garmentObj.serviceID] === undefined
        //             ? {
        //               [garmentObj.garmentID]: 1,
        //             }
        //             : this.state.order[garmentObj.serviceID][
        //               garmentObj.garmentID
        //             ] === undefined
        //               ? {
        //                 ...this.state.order[garmentObj.serviceID],
        //                 [garmentObj.garmentID]: 1,
        //               }
        //               : {
        //                 ...this.state.order[garmentObj.serviceID],
        //                 [garmentObj.garmentID]:
        //                   this.state.order[garmentObj.serviceID][
        //                   garmentObj.garmentID
        //                   ] + 1,
        //               },
        //       },
        //     });
        //     response.data.vMSplitOrderDetails.map((splitOrder) => {
        //       let filteredServiceObject = this.state.garmentList.filter(
        //         (x) => parseInt(x.serviceId) === parseInt(splitOrder.serviceID)
        //       )[0];
        //       if (parseInt(filteredServiceObject.UOM) === 2) {
        //         this.setState({
        //           order: {
        //             ...this.state.order,
        //             [splitOrder.serviceID]:
        //               this.state.order[splitOrder.serviceID] !== undefined
        //                 ? {
        //                   ...this.state.order[splitOrder.serviceID],
        //                   "-1": splitOrder.weight,
        //                 }
        //                 : { "-1": splitOrder.weight },
        //           },
        //         });
        //       }
        //       if (parseInt(filteredServiceObject.UOM) === 3) {
        //         let sqftGarments = response.data.vMOrderGarmentDetails.filter(
        //           (x) => parseInt(x.uom) === 3
        //         );
        //         let total = 0;
        //         sqftGarments.map((single) => {
        //           total = total + single.width * single.length;
        //         });
        //         this.setState({
        //           order: {
        //             ...this.state.order,
        //             [splitOrder.serviceID]:
        //               this.state.order[splitOrder.serviceID] !== undefined
        //                 ? {
        //                   ...this.state.order[splitOrder.serviceID],
        //                   "-1": total,
        //                 }
        //                 : { "-1": total },
        //           },
        //         });
        //       }
        //     });
        //     let splitOrderID = response.data.vMSplitOrderDetails.filter(
        //       (x) => x.splitOrderNumber === garmentObj.splitOrderNumber
        //     )[0].splitOrderID;
        //     this.state.orderArray.push({
        //       no: this.state.orderArray.length + 1,
        //       orderGarmentID: garmentObj.orderGarmentID,
        //       tagNo: garmentObj.tagNo,
        //       splitOrderID: splitOrderID,
        //       splitOrderNumber: garmentObj.splitOrderNumber,
        //       serviceId: garmentObj.serviceID,
        //       garmentId: garmentObj.garmentID,
        //       garmentName: filteredGarmentObject.name,
        //       serviceName: filteredServiceObject.title,
        //       darningSize: garmentObj.darningTypeID,
        //       dyingColorsUOM:
        //         garmentObj.dyeingColors === ""
        //           ? 0
        //           : garmentObj.dyeingColors.split(",").length === 1
        //             ? 21
        //             : garmentObj.dyeingColors.split(",").length > 1
        //               ? 22
        //               : 0,
        //       dyingColorsValue:
        //         garmentObj.dyeingColors !== "null" &&
        //           garmentObj.dyeingColors !== "" &&
        //           garmentObj.dyeingColors !== null
        //           ? garmentObj.dyeingColors.split(",").length > 0
        //             ? garmentObj.dyeingColors.split(",")
        //             : []
        //           : [],
        //       notes:
        //         garmentObj.specialInstruction !== "" &&
        //           garmentObj.specialInstruction !== "null"
        //           ? garmentObj.specialInstruction
        //           : null,
        //       addOnServices:
        //         garmentObj.addOnServiceIDs !== "" &&
        //           garmentObj.addOnServiceIDs !== "null" &&
        //           garmentObj.addOnServiceIDs !== null
        //           ? garmentObj.addOnServiceIDs.split(",").length > 0
        //             ? garmentObj.addOnServiceIDs.split(",")
        //             : []
        //           : [],
        //       color:
        //         garmentObj.colorIDs !== "null" && garmentObj.colorIDs !== ""
        //           ? garmentObj.colorIDs
        //           : null,
        //       brand: garmentObj.brandID,
        //       size: garmentObj.sizeID === 0 ? null : garmentObj.sizeID,
        //       UOM: garmentObj.uom,
        //       deliveryDate:
        //         garmentObj.deliveryDate !== ""
        //           ? moment(garmentObj.deliveryDate)
        //           : parseInt(response.data.orderStatus) < 2
        //             ? moment()
        //             : null,
        //       price: garmentObj.serviceAmount + garmentObj.addOnServiceAmount,
        //       darningPrice: 0,
        //       dyingPrice: 0,
        //       ucPrice: 0,
        //       rate: garmentObj.serviceAmount,
        //       cgst: garmentObj.cgst,
        //       sgst: garmentObj.sgst,
        //       igst: garmentObj.igst,
        //       width: garmentObj.width,
        //       length: garmentObj.length,
        //       images: [
        //         garmentObj.garmentImage1 === ""
        //           ? null
        //           : {
        //             uid: "1",
        //             name: garmentObj.garmentImage1.split("/")[
        //               garmentObj.garmentImage1.split("/").length - 1
        //             ],
        //             status: "done",
        //             url: garmentObj.garmentImage1,
        //           },
        //         garmentObj.garmentImage2 === ""
        //           ? null
        //           : {
        //             uid: "2",
        //             name: garmentObj.garmentImage2.split("/")[
        //               garmentObj.garmentImage2.split("/").length - 1
        //             ],
        //             status: "done",
        //             url: garmentObj.garmentImage2,
        //           },
        //         garmentObj.garmentImage3 === ""
        //           ? null
        //           : {
        //             uid: "3",
        //             name: garmentObj.garmentImage3.split("/")[
        //               garmentObj.garmentImage3.split("/").length - 1
        //             ],
        //             status: "done",
        //             url: garmentObj.garmentImage3,
        //           },
        //       ],
        //       deliveryType: response.data.deliveryType,
        //       rateRatio: response.data.rateRatio,
        //       deliveryTypeName: response.data.deliveryTypeName
        //     });
        //   }
        // });

        // this.setState({ spinning: false }, () => {
        //   // if (parseInt(response.data.orderStatus) < 2) {
        //   // this.getRatesForPredefinedOrder();
        //   // }
        // });
        // //Umesh remove infinite rerendering End

        /////Umesh Test start from here

        let tmporderArray = [];
        let tmporder = {};
        response.data.vMOrderGarmentDetails.map((garmentObj) => {
          let filteredServiceObject = this.state.garmentList.filter(
            (x) => parseInt(x.serviceId) === parseInt(garmentObj.serviceID)
          )[0];

          if (filteredServiceObject !== undefined) {
            let filteredGarmentObject = filteredServiceObject.garments.filter(
              (x) => parseInt(x.id) === parseInt(garmentObj.garmentID)
            )[0];

            //this.setState({
            //order: {
            tmporder = {
              ...tmporder,
              [garmentObj.serviceID]:
                tmporder[garmentObj.serviceID] === undefined
                  ? {
                      [garmentObj.garmentID]: 1,
                    }
                  : tmporder[garmentObj.serviceID][garmentObj.garmentID] ===
                    undefined
                  ? {
                      ...tmporder[garmentObj.serviceID],
                      [garmentObj.garmentID]: 1,
                    }
                  : {
                      ...tmporder[garmentObj.serviceID],
                      [garmentObj.garmentID]:
                        tmporder[garmentObj.serviceID][garmentObj.garmentID] +
                        1,
                    },
            };
            //});

            response.data.vMSplitOrderDetails.map((splitOrder) => {
              let filteredServiceObject = this.state.garmentList.filter(
                (x) => parseInt(x.serviceId) === parseInt(splitOrder.serviceID)
              )[0];

              if (parseInt(filteredServiceObject.UOM) === 2) {
                // this.setState({
                //   order: {

                tmporder = {
                  ...tmporder,
                  [splitOrder.serviceID]:
                    tmporder[splitOrder.serviceID] !== undefined
                      ? {
                          ...tmporder[splitOrder.serviceID],
                          "-1": splitOrder.weight,
                        }
                      : { "-1": splitOrder.weight },
                };
                // });
              }
              if (parseInt(filteredServiceObject.UOM) === 3) {
                let sqftGarments = response.data.vMOrderGarmentDetails.filter(
                  (x) => parseInt(x.uom) === 3
                );
                let total = 0;
                sqftGarments.map((single) => {
                  total = total + single.width * single.length;
                });
                // this.setState({
                //   order: {
                tmporder = {
                  //...this.state.order,
                  ...tmporder,
                  [splitOrder.serviceID]:
                    tmporder[splitOrder.serviceID] !== undefined
                      ? {
                          ...tmporder[splitOrder.serviceID],
                          "-1": total,
                        }
                      : { "-1": total },
                };
                // });
              }
            });

            let splitOrderID = response.data.vMSplitOrderDetails.filter(
              (x) => x.splitOrderNumber === garmentObj.splitOrderNumber
            )[0].splitOrderID;

            // this.state.orderArray.push({
            tmporderArray.push({
              no: tmporderArray.length + 1,
              orderGarmentID: garmentObj.orderGarmentID,
              tagNo: garmentObj.tagNo,
              splitOrderID: splitOrderID,
              splitOrderNumber: garmentObj.splitOrderNumber,
              serviceId: garmentObj.serviceID,
              garmentId: garmentObj.garmentID,
              garmentName: filteredGarmentObject.name,
              serviceName: filteredServiceObject.title,
              darningSize: garmentObj.darningTypeID,
              dyingColorsUOM:
                garmentObj.dyeingColors === ""
                  ? 0
                  : garmentObj.dyeingColors.split(",").length === 1
                  ? 21
                  : garmentObj.dyeingColors.split(",").length > 1
                  ? 22
                  : 0,
              dyingColorsValue:
                garmentObj.dyeingColors !== "null" &&
                garmentObj.dyeingColors !== "" &&
                garmentObj.dyeingColors !== null
                  ? garmentObj.dyeingColors.split(",").length > 0
                    ? garmentObj.dyeingColors.split(",")
                    : []
                  : [],
              notes:
                garmentObj.specialInstruction !== "" &&
                garmentObj.specialInstruction !== "null"
                  ? garmentObj.specialInstruction
                  : null,
              addOnServices:
                garmentObj.addOnServiceIDs !== "" &&
                garmentObj.addOnServiceIDs !== "null" &&
                garmentObj.addOnServiceIDs !== null
                  ? garmentObj.addOnServiceIDs.split(",").length > 0
                    ? garmentObj.addOnServiceIDs.split(",")
                    : []
                  : [],
              color:
                garmentObj.colorIDs !== "null" && garmentObj.colorIDs !== ""
                  ? garmentObj.colorIDs
                  : null,
              brand: garmentObj.brandID,
              size: garmentObj.sizeID === 0 ? null : garmentObj.sizeID,
              UOM: garmentObj.uom,
              deliveryDate:
                garmentObj.deliveryDate !== ""
                  ? moment(garmentObj.deliveryDate)
                  : parseInt(response.data.orderStatus) < 2
                  ? moment()
                  : null,
              price: garmentObj.serviceAmount + garmentObj.addOnServiceAmount,
              darningPrice: 0,
              dyingPrice: 0,
              ucPrice: 0,
              rate: garmentObj.serviceAmount,
              cgst: garmentObj.cgst,
              sgst: garmentObj.sgst,
              igst: garmentObj.igst,
              width: garmentObj.width,
              length: garmentObj.length,
              images: [
                garmentObj.garmentImage1 === ""
                  ? null
                  : {
                      uid: "1",
                      name: garmentObj.garmentImage1.split("/")[
                        garmentObj.garmentImage1.split("/").length - 1
                      ],
                      status: "done",
                      url: garmentObj.garmentImage1,
                    },
                garmentObj.garmentImage2 === ""
                  ? null
                  : {
                      uid: "2",
                      name: garmentObj.garmentImage2.split("/")[
                        garmentObj.garmentImage2.split("/").length - 1
                      ],
                      status: "done",
                      url: garmentObj.garmentImage2,
                    },
                garmentObj.garmentImage3 === ""
                  ? null
                  : {
                      uid: "3",
                      name: garmentObj.garmentImage3.split("/")[
                        garmentObj.garmentImage3.split("/").length - 1
                      ],
                      status: "done",
                      url: garmentObj.garmentImage3,
                    },
              ],
              deliveryType: response.data.deliveryType,
              rateRatio: response.data.rateRatio,
              deliveryTypeName: response.data.deliveryTypeName,
              finalQCStatus: garmentObj.finalQCStatus,
              garmentDefect: garmentObj.garmentDefect,
            });
          }
        });
        this.setState({
          order: {
            ...this.state.order,
            ...tmporder,
          },
          orderArray: [...tmporderArray],
          // });

          // this.setState({
          promocode: [null, undefined, ""].includes(response.data.promocode)
            ? ""
            : response.data.promocode,
          discount: response.data.discountAmount,
          isPromocodeValid: [null, undefined, ""].includes(
            response.data.promocode
          )
            ? false
            : true,
          isOnDemand: response.data.isOnDemandDelivery,
          isFromPickup: parseInt(response.data.pickupID) === 0 ? false : true,
          customerInfo: {
            ...this.state.customerInfo,
            id: response.data.customerID,
            name: response.data.customerName,
            pickupRequest: response.data.pickupID,
            selectedAddress: {
              ...this.state.customerInfo.selectedAddress,
              id: response.data.customerAddressID,
              name: response.data.customerAddressName,
            },
            serviceType: {
              ...this.state.customerInfo.serviceType,
              id: response.data.deliveryType,
              name: response.data.deliveryTypeName,
            },
            customerType: response.data.customerTypeName,
            corporateID: parseInt(response.data.corporateID),
            walletBalance: response.data.lkCashBlcAmt,
            lkCreditAmt: response.data.lkCreditBlcAmt,
          },
          orderID: response.data.orderID,
          orderDate: moment(response.data.orderDate),
          orderStatus: response.data.orderStatus,
          opeartionInstruction: ![null, undefined, ""].includes(
            response.data.operationInstruction
          )
            ? response.data.operationInstruction.split(",")
            : [],
          logisticInstruction: response.data.logisticInstruction,
          orderRemarks:
            response.data.orderRemark !== "undefined" &&
            response.data.orderRemark !== "null"
              ? response.data.orderRemark
              : null,
          // isReview:
          //   parseInt(response.data.orderStatus) === 1 &&
          //     parseInt(response.data.orderType) !== 2
          //     ? false
          //     : true,
          isReview:
            this.props.location.state != undefined
              ? this.props.location.state.view == "view"
                ? true
                : parseInt(response.data.orderStatus) === 1 &&
                  parseInt(response.data.orderType) !== 2
                ? false
                : true
              : parseInt(response.data.orderStatus) === 1 &&
                parseInt(response.data.orderType) !== 2
              ? false
              : true,
          rewashOrderID: response.data.rewashOrderID,
          // rewashReason: response.data.rewashReason,
          rewashReason: ![null, undefined, ""].includes(
            response.data.rewashReason
          )
            ? response.data.rewashReason.split(",")
            : [],

          rewashRequestedBy: response.data.rewashRequestedBy,
          orderType: response.data.orderType,
          // });

          // this.setState({
          spinning: false,
          // },() => {
          //   if (parseInt(response.data.orderStatus) < 2) {
          //   this.getRatesForPredefinedOrder();
          //   }
        });

        /////Umesh Test End At here
        //*********************************************************************************************************
        /********************************************************************* */
        let OrderStatusList = [];

        OrderStatusList = response.data.vMSplitOrderDetails.filter(
          (x) => parseInt(x.splitOrderStatus) >= 6
        );
        // console.log("OrderStatusList->", OrderStatusList);
        if (OrderStatusList != undefined && OrderStatusList.length > 0) {
          this.setState({ AllowCancelOrder: false });
        } else {
          this.setState({ AllowCancelOrder: true });
        }
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_ORDER_INFORMATION,
          duration: 1.5,
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.customerInfo.id !== prevState.customerInfo.id) {
      this.getTimeCard();
      this.getRatesForPredefinedOrder();
    }
    if (
      this.state.customerInfo.serviceType !== undefined &&
      prevState.customerInfo.serviceType !== undefined
    ) {
      if (
        this.state.customerInfo.serviceType.id !==
        prevState.customerInfo.serviceType.id
      ) {
        this.getRatesForPredefinedOrder();
      }
    }
  }
  calculateDeliveryDatesForOrder = () => {
    let orgArray = [...this.state.orderArray];
    // let updated = orgArray.map((record) => {
    //   record.deliveryDate = moment(this.state.orderDate).add(
    //     this.calculateDeliveryDateAdd(
    //       record.serviceId,
    //       record.addOnServices,
    //       record.garmentId
    //     ),
    //     "d"
    //   );
    let updated = orgArray.map((record) => {
      let DeliveryDateAfterAdd = moment(this.state.orderDate).add(
        this.calculateDeliveryDateAdd(
          record.serviceId,
          record.addOnServices,
          record.garmentId
        ),
        "d"
      );
      let weekDayName = JSON.parse(localStorage.getItem("StoreSlots")).map(
        (dayName) => {
          return dayName.weekDayName;
        }
      );
      record.deliveryDate = weekDayName.includes(
        DeliveryDateAfterAdd.format("ddd")
      )
        ? DeliveryDateAfterAdd
        : DeliveryDateAfterAdd.add(1, "d");

      record.deliveryDate = weekDayName.includes(
        record.deliveryDate.format("ddd")
      )
        ? record.deliveryDate
        : record.deliveryDate.add(1, "d");

      return record;
    });
    this.setState({ orderArray: updated });
  };
  isCorporate = () => {
    if (this.state.customerInfo.customerType === "B2B/Corporate") {
      return true;
    } else {
      return false;
    }
  };
  calculateDeliveryDateAdd = (
    service,
    addOnServices = [],
    garment = 1,
    garmentDeliveryType
  ) => {
    let days = 0;
    if (parseInt(garment) !== -1) {
      if (this.state.timeCard !== null && this.state.timeCard.length > 0) {
        let filteredArray = this.state.timeCard.filter(
          (singleService) =>
            parseInt(singleService.serviceID) === parseInt(service) &&
            parseInt(singleService.garmentID) === parseInt(garment)
        );
        if (filteredArray.length > 0) {
          let filtered = filteredArray[0];
          if (filtered !== undefined) {
            if (this.state.customerInfo !== undefined) {
              if (this.state.customerInfo.serviceType !== undefined) {
                switch (parseInt(this.state.customerInfo.serviceType.id)) {
                  case 2:
                    days = days + filtered.semiExpressTAT;
                    break;
                  case 3:
                    days = days + filtered.expressTAT;
                    break;
                  default:
                    days = days + filtered.tat;
                    break;
                }
              }
            }

            let temparr = this.state.HolidayList;
            var pickup_date = moment(this.state.orderDate).format("YYYY-MM-DD");
            var delivery_date = moment(this.state.orderDate)
              .add(days, "days")
              .format("YYYY-MM-DD"); //Added By Hasti
            // var delivery_date = moment().add(days, "days").format("YYYY-MM-DD");
            var HolidayArr = [];
            // console.log("date->",pickup_date,delivery_date);

            HolidayArr = temparr.filter((a) => {
              var holiday_date = moment(a.holidayDate).format("YYYY-MM-DD");
              // console.log("date->",pickup_date,holiday_date,delivery_date);
              return (
                pickup_date <= holiday_date && delivery_date >= holiday_date
              );
            });

            let len = HolidayArr.length;
            days = days + len;
          }
        } else {
          notification["error"]({
            message: labels.ORDERS,
            description: labels.UNABLE_TO_GET_TIME_CARD,
          });
          days = false;
        }
        if (addOnServices.length > 0) {
          addOnServices.map((singleService) => {
            days =
              days + this.calculateDeliveryDateAdd(singleService, [], garment);
          });
        }
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: labels.UNABLE_TO_GET_TIME_CARD,
        });
        days = false;
      }
    }
    return days;
  };
  getTimeCard = () => {
    this.createOrderService
      .getTimeCard(
        this.isCorporate()
          ? this.state.customerInfo.corporateID
          : this.state.storeID,
        this.state.orderDate,
        this.isCorporate(),
        this.state.customerInfo.serviceType !== undefined
          ? this.state.customerInfo.serviceType.id
          : null
      )
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            timeCard: response.data,
          });
        } else {
          notification["error"]({
            message: labels.ORDERS,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_LOAD_ADD_ON_SERVICES,
            duration: 1.5,
          });
        }
      });
  };
  getPackagingCharges = () => {
    this.createOrderService.getPackagingCharges().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          packagingCharges: response.data,
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_PACKAGING_CHARGES,
        });
      }
    });
  };
  getDeliveryCharges = () => {
    this.createOrderService.getDeliveryCharges().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          deliveryCharges: response.data,
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_DELIVERY_CHARGES,
        });
      }
    });
  };
  getRatesForPredefinedOrder() {
    if (this.state.isReview && this.state.orderStatus != 0) {
      this.setState({ isRender: true });
      return false;
    }
    if (this.state.orderArray.length !== 0) {
      for (let key in this.state.orderArray) {
        let service = this.state.orderArray[key].serviceId;
        let garment = this.state.orderArray[key].garmentId;
        //  if (![8, 9].includes(parseInt(this.state.orderArray[key].serviceId))) { added By Jalpa
        this.getRateForGarment(
          this.state.storeID,
          this.state.garmentList.filter(
            (x) => parseInt(x.serviceId) === parseInt(service)
          )[0].UOM,
          this.state.customerInfo.serviceType !== undefined
            ? this.state.customerInfo.serviceType.id !== undefined
              ? this.state.customerInfo.serviceType.id
              : 1
            : 1,
          service,
          garment,
          () => null,
          (response) => {
            let serviceObject = this.state.garmentList.filter(
              (x) => parseInt(x.serviceId) === parseInt(service)
            )[0];
            let addedGarments = this.state.orderArray.filter(
              (x) => parseInt(x.serviceId) === parseInt(service)
            );
            let price = 0;
            if (serviceObject.UOM === 1) {
              price = response.rate;
            } else if (serviceObject.UOM === 2) {
              price =
                this.state.order[service][-1] !== undefined
                  ? (parseInt(this.state.order[service][-1]) * response.rate) /
                    addedGarments.length
                  : 0;
            }
            let orgArray = [...this.state.orderArray];
            let newArray = orgArray.map((singleObj, index) => {
              let single = { ...singleObj };
              if (
                parseInt(single.serviceId) === parseInt(service) &&
                parseInt(single.garmentId) === parseInt(garment)
              ) {
                if (parseInt(single.UOM) === 3) {
                  single.price = single.width * single.length * response.rate;
                } else {
                  single.price = price;
                }
                single.rate = response.rate;
                single.cgst = response.cgst;
                single.sgst = response.sgst;
                single.igst = response.igst;
              }
              return single;
            });
            this.setState({ orderArray: newArray });
          }
        );
        //}
      }
    }

    let newArray = [...this.state.orderArray].map((oldrecord, index) => {
      let record = { ...oldrecord };
      //   if (record.addOnServices.includes("10")) {
      //     this.getRateForGarment(
      //       this.state.storeID,
      //       record.UOM,
      //       this.state.customerInfo.serviceType.id,
      //       10,
      //       record.garmentId,
      //       () => null,
      //       (response) => {
      //         record.ucPrice = response.rate;
      //         let duplicate = [...this.state.orderArray];
      //         duplicate[index].ucPrice = response.rate;
      //         this.setState({
      //           orderArray: duplicate,
      //         });
      //       }
      //     );
      //   }
      if (
        record.addOnServices.includes("9") ||
        parseInt(record.serviceId) === 9
      ) {
        if (record.darningSize !== null && record.darningSize !== 0)
          this.getRateForGarment(
            this.state.storeID,
            parseInt(record.darningSize),
            this.state.customerInfo.serviceType.id,
            9,
            record.garmentId,
            () => null,
            (response) => {
              record.darningPrice = response.rate;
              let duplicate = [...this.state.orderArray];
              duplicate[index].darningPrice = response.rate;
              if (parseInt(record.serviceId) === 9) {
                duplicate[index].price = 0;
                duplicate[index].rate = response.rate;
              }
              this.setState({
                orderArray: duplicate,
              });
            }
          );
      }
      if (
        record.addOnServices.includes("8") ||
        parseInt(record.serviceId) === 8
      ) {
        if (![null, undefined, ""].includes(record.dyingColorsValue)) {
          record.dyingColorsUOM =
            record.dyingColorsValue.length === 1
              ? 21
              : record.dyingColorsValue.length > 1
              ? 22
              : null;
          this.getRateForGarment(
            this.state.storeID,
            record.dyingColorsUOM,
            this.state.customerInfo.serviceType.id,
            8,
            record.garmentId,
            () => null,
            (response) => {
              record.dyingPrice = response.rate;
              let duplicate = [...this.state.orderArray];
              duplicate[index].dyingPrice = response.rate;
              if (parseInt(record.serviceId) === 8) {
                duplicate[index].price = 0;
                duplicate[index].rate = response.rate;
              }
              this.setState({
                orderArray: duplicate,
              });
            }
          );
        }
      }
      return record;
    });
    this.setState({ orderArray: newArray }, () =>
      this.calculateDeliveryDatesForOrder()
    );
  }
  getGarmentsList = (service = null, uom = null) => {
    let storeID = this.state.storeID;
    // debugger;
    this.createOrderService
      .getGarmentsList(service, uom, storeID)
      .then((response) => {
        if (response.status === undefined) {
          let unchanged = this.state.garmentList.filter((singleService) => {
            return (
              response.filter(
                (updatedService) =>
                  updatedService.serviceId === singleService.serviceId
              ).length === 0
            );
          });
          this.setState(
            {
              garmentList: [...unchanged, ...response].sort((a, b) => {
                return parseInt(a.serviceId) > parseInt(b.serviceId) ? 1 : -1;
              }),
              garmentSelectionShouldUpdate: true,
            },
            () => {
              if (
                [ROUTES.UPDATE_ORDER, ":orderID"].join("/") ===
                this.props.match.path
              ) {
                this.getOrderByID(this.props.match.params.orderID);
              }
              this.getRatesForPredefinedOrder();
            }
          );
        } else {
          notification["error"]({
            message: labels.ORDERS,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_LIST_OF_GARMENTS,
          });
        }
      });
  };
  goToReview = () => {
    this.setState({ isReview: true });
  };
  goToOrder = () => {
    this.setState({ isReview: false });
  };
  getCountForGarment = (service, garment) => {
    if (this.state.order[service] !== undefined) {
      if (this.state.order[service][garment] !== undefined) {
        //return this.state.orderArray.filter(x => x.serviceId == service && x.garmentId == garment).length
        return this.state.order[service][garment];
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  getRateForGarment = (
    store,
    uom,
    delivery = null,
    service,
    garment,
    revert = () => null,
    callback = () => null
  ) => {
    this.createOrderService
      .getRateCardForStore(
        this.isCorporate() ? this.state.customerInfo.corporateID : store,
        [8, 9].includes(parseInt(service)) && uom === 1 ? null : uom,
        delivery !== null
          ? delivery
          : this.state.customerInfo.serviceType !== undefined
          ? this.state.customerInfo.serviceType.id
          : 1,
        service,
        parseInt(garment) === -1
          ? parseInt(uom) === 3
            ? parseInt(garment)
            : Object.keys(this.state.order[service])[0]
          : // : this.state.garmentList[service].garments[1].id
            garment,
        this.state.orderDate.format("YYYY-MM-DD"),
        this.isCorporate()
      )
      .then((response) => {
        if (response.statusCode === 200 && response.data[0] !== undefined) {
          if (response.data[0].rate <= 0) {
            notification["error"]({
              message: labels.ORDERS,
              description: labels.UNABLE_TO_GET_RATES,
            });
            revert();
            return;
          }

          if ([11, 12, 13, 21, 22].includes(parseInt(response.data[0].uomid))) {
            let uomid = response.data[0].uomid;

            this.setState(
              {
                rates: {
                  ...this.state.rates,
                  [uomid]: {
                    ...this.state.rates[uomid],
                    [garment]: parseInt(response.data[0].rate),
                  },
                  // [service]: {
                  //   ...this.state.rates[service],
                  //   [garment]: parseInt(response.data[0].rate),
                  // },
                },
                //   cgst: {
                //     ...this.state.cgst,
                //     [uomid]: {
                //       ...this.state.cgst[uomid],
                //       [garment]:
                //         (parseFloat(response.data[0].cgst) *
                //           parseInt(response.data[0].rate)) /
                //         100,
                //     },
                //   },
                //   sgst: {
                //     ...this.state.sgst,
                //     [uomid]: {
                //       ...this.state.sgst[uomid],
                //       [garment]:
                //         (parseFloat(response.data[0].sgst) *
                //           parseInt(response.data[0].rate)) /
                //         100,
                //     },
                //   },
                //   igst: {
                //     ...this.state.igst,
                //     [uomid]: {
                //       ...this.state.igst[uomid],
                //       [garment]:
                //         (parseFloat(response.data[0].igst) *
                //           parseInt(response.data[0].rate)) /
                //         100,
                //     },
                //   },
              },
              callback(response.data[0])
            );
          } else {
            this.setState(
              {
                rates: {
                  ...this.state.rates,
                  [service]: {
                    ...this.state.rates[service],
                    [garment]: parseInt(response.data[0].rate),
                  },
                },
                cgst: {
                  ...this.state.cgst,
                  [service]: {
                    ...this.state.cgst[service],
                    [garment]:
                      (parseFloat(response.data[0].cgst) *
                        parseInt(response.data[0].rate)) /
                      100,
                  },
                },
                sgst: {
                  ...this.state.sgst,
                  [service]: {
                    ...this.state.sgst[service],
                    [garment]:
                      (parseFloat(response.data[0].sgst) *
                        parseInt(response.data[0].rate)) /
                      100,
                  },
                },
                igst: {
                  ...this.state.igst,
                  [service]: {
                    ...this.state.igst[service],
                    [garment]:
                      (parseFloat(response.data[0].igst) *
                        parseInt(response.data[0].rate)) /
                      100,
                  },
                },
              },
              callback(response.data[0])
            );
          }
        } else {
          notification["error"]({
            message: labels.ORDERS,
            description: labels.UNABLE_TO_GET_RATES,
          });
          revert();
        }
      });
  };

  // getRateForGarment = (
  //   store,
  //   uom,
  //   delivery = null,
  //   service,
  //   garment,
  //   revert = () => null,
  //   callback = () => null
  // ) => {
  //   // this.createOrderService
  //   //   .getRateCardForStore(
  //   //     this.isCorporate() ? this.state.customerInfo.corporateID : store,
  //   //     [8, 9].includes(parseInt(service)) && uom === 1 ? null : uom,
  //   //     delivery !== null
  //   //       ? delivery
  //   //       : this.state.customerInfo.serviceType !== undefined
  //   //         ? this.state.customerInfo.serviceType.id
  //   //         : 1,
  //   //     service,
  //   //     parseInt(garment) === -1
  //   //       ? parseInt(uom) === 3
  //   //         ? parseInt(garment)
  //   //         : Object.keys(this.state.order[service])[0]
  //   //       // : this.state.garmentList[service].garments[1].id
  //   //       : garment,
  //   //     this.state.orderDate.format("YYYY-MM-DD"),
  //   //     this.isCorporate()
  //   //   )
  //   //   .then((response) => {
  //   if (this.state.orderArray.length == inc) {
  //     this.setState({ isRender: true, spinning: false })
  //   }
  //   inc++

  //   if (this.state.rateCard.length > 0) {
  //     let res = this.state.rateCard.filter(x => x.serviceID == service && x.garmentID == garment && x.uomid == uom)

  //     if ([11, 12, 13, 21, 22].includes(parseInt(res[0].uomid))) {
  //       let uomid = res[0].uomid;
  //       this.setState(
  //         {
  //           rates: {
  //             ...this.state.rates,
  //             [uomid]: {
  //               ...this.state.rates[uomid],
  //               [garment]: parseInt(res[0].rate),
  //             },
  //             // [service]: {
  //             //   ...this.state.rates[service],
  //             //   [garment]: parseInt(response.data[0].rate),
  //             // },
  //           },
  //           //   cgst: {
  //           //     ...this.state.cgst,
  //           //     [uomid]: {
  //           //       ...this.state.cgst[uomid],
  //           //       [garment]:
  //           //         (parseFloat(response.data[0].cgst) *
  //           //           parseInt(response.data[0].rate)) /
  //           //         100,
  //           //     },
  //           //   },
  //           //   sgst: {
  //           //     ...this.state.sgst,
  //           //     [uomid]: {
  //           //       ...this.state.sgst[uomid],
  //           //       [garment]:
  //           //         (parseFloat(response.data[0].sgst) *
  //           //           parseInt(response.data[0].rate)) /
  //           //         100,
  //           //     },
  //           //   },
  //           //   igst: {
  //           //     ...this.state.igst,
  //           //     [uomid]: {
  //           //       ...this.state.igst[uomid],
  //           //       [garment]:
  //           //         (parseFloat(response.data[0].igst) *
  //           //           parseInt(response.data[0].rate)) /
  //           //         100,
  //           //     },
  //           //   },
  //         },
  //         callback(res[0])
  //       );
  //     } else {
  //       this.setState(
  //         {
  //           rates: {
  //             ...this.state.rates,
  //             [service]: {
  //               ...this.state.rates[service],
  //               [garment]: parseInt(res[0].rate),
  //             },
  //           },
  //           cgst: {
  //             ...this.state.cgst,
  //             [service]: {
  //               ...this.state.cgst[service],
  //               [garment]:
  //                 (parseFloat(res[0].cgst) *
  //                   parseInt(res[0].rate)) /
  //                 100,
  //             },
  //           },
  //           sgst: {
  //             ...this.state.sgst,
  //             [service]: {
  //               ...this.state.sgst[service],
  //               [garment]:
  //                 (parseFloat(res[0].sgst) *
  //                   parseInt(res[0].rate)) /
  //                 100,
  //             },
  //           },
  //           igst: {
  //             ...this.state.igst,
  //             [service]: {
  //               ...this.state.igst[service],
  //               [garment]:
  //                 (parseFloat(res[0].igst) *
  //                   parseInt(res[0].rate)) /
  //                 100,
  //             },
  //           },
  //         },
  //         callback(res[0])

  //       );

  //     }
  //   } else {
  //     notification["error"]({
  //       message: labels.ORDERS,
  //       description: labels.UNABLE_TO_GET_RATES,
  //     });
  //     revert();
  //   }

  //   // });
  // };
  updateOrder = (
    service,
    garment,
    count,
    garmentDeliveryType, //Added by rashmi for L177728 - 20/02/23
    revert = () => null,
    callback = () => null
  ) => {
    this.setState({ GarmentDeliveryType: garmentDeliveryType }); //Added by rashmi for L177728 - 22/02/23
    let orderType = this.state.customerInfo.serviceType.id.toString(); //Added by rashmi for L177728 - 20/02/23
    let GarOrderType = garmentDeliveryType.includes(orderType);

    if (GarOrderType) {
      //Added by rashmi for L177728 - 20/02/23
      this.getRateForGarment(
        this.state.storeID,
        this.state.garmentList.filter(
          (serviceFilter) =>
            parseInt(serviceFilter.serviceId) === parseInt(service)
        )[0].UOM,
        parseInt(this.state.customerInfo.serviceType.id) !== undefined ||
          parseInt(this.state.customerInfo.serviceType.id) !== 0
          ? parseInt(this.state.customerInfo.serviceType.id)
          : 1,
        service,
        garment,
        revert,
        (response) => {
          let deliveryDaysAdd = this.calculateDeliveryDateAdd(
            service,
            [],
            garment
          );
          if (parseInt(garment) !== -1) {
            let filteredObject = this.state.orderArray.filter(
              (x) =>
                parseInt(x.serviceId) === parseInt(service) &&
                parseInt(x.garmentId) === parseInt(garment)
            );
            let serviceObject = this.state.garmentList.filter(
              (x) => parseInt(x.serviceId) === parseInt(service)
            )[0];
            let garmentObject = serviceObject.garments.filter(
              (x) => parseInt(x.id) === parseInt(garment)
            )[0];

            // console.log("garmentObject->",garmentObject);

            let price = 0;
            let countExceptThisGarment = this.state.orderArray.filter(
              (x) =>
                parseInt(x.serviceId) === parseInt(service) &&
                parseInt(x.garmentId) !== parseInt(garment)
            ).length;

            if (count !== 0) {
              if (parseInt(serviceObject.UOM) !== 2) {
                price = response.rate;
              } else {
                if (this.state.order[service] !== undefined) {
                  if (this.state.order[service][-1] !== undefined) {
                    price =
                      (parseInt(this.state.order[service][-1]) *
                        response.rate) /
                      (countExceptThisGarment + count);
                  } else {
                    price = 0;
                  }
                } else {
                  price = 0;
                }

                let newArray = this.state.orderArray.map((single, index) => {
                  if (single.serviceId === service) {
                    single.price = price;
                  }
                  return single;
                });
                this.setState({ orderArray: newArray });
              }
            }

            if (filteredObject.length < count) {
              for (let i = 0; i < count - filteredObject.length; i++) {
                let deliveryDate = moment(this.state.orderDate);
                if (deliveryDaysAdd !== false) {
                  //added by pb
                  let DeliveryDateAfterAdd = deliveryDate.add(
                    this.calculateDeliveryDateAdd(service, [], garment),
                    "d"
                  );

                  let weekDayName = JSON.parse(
                    localStorage.getItem("StoreSlots")
                  ).map((dayName) => {
                    return dayName.weekDayName;
                  });

                  let DDeliveryDate = weekDayName.includes(
                    DeliveryDateAfterAdd.format("ddd")
                  )
                    ? DeliveryDateAfterAdd
                    : DeliveryDateAfterAdd.add(1, "d");

                  DDeliveryDate = weekDayName.includes(
                    DDeliveryDate.format("ddd")
                  )
                    ? DDeliveryDate
                    : DDeliveryDate.add(1, "d");
                  //ended
                  this.state.orderArray.push({
                    no: this.state.orderArray.length + 1,
                    garmentDeliveryType: garmentObject.garmentDeliveryType, //Added by rashmi for L177728 - 20/02/23
                    orderGarmentID: 0,
                    tagNo: "",
                    splitOrderID: 0,
                    splitOrderNumber: "",
                    serviceId: service,
                    garmentId: garment,
                    garmentName: garmentObject.name,
                    serviceName: serviceObject.title,
                    serviceIsAddOn: serviceObject.isAddOn, // added by pb for C15614 this ticket
                    darningSize: "",
                    dyingColorsUOM: "",
                    dyingColorsValue: [],
                    notes: "",
                    addOnServices: [],
                    color: null,
                    brand: null,
                    size: null,
                    UOM: serviceObject.UOM,
                    // deliveryDate: deliveryDate.add(
                    //   this.calculateDeliveryDateAdd(service, [], garment),
                    //   "d"
                    // ),
                    deliveryDate: DDeliveryDate,
                    price: ![8, 9].includes(parseInt(service)) ? price : 0,
                    darningPrice: parseInt(service) === 9 ? price : 0,
                    dyingPrice: parseInt(service) === 8 ? price : 0,
                    ucPrice: 0,
                    rate: response.rate,
                    cgst: response.cgst,
                    sgst: response.sgst,
                    igst: response.igst,
                    width: 0,
                    length: 0,
                    images: [null, null, null],
                    garmentDefect: null,
                  });

                  // console.log("orderArry->",  this.state.orderArray);
                }
              }
            } else if (filteredObject.length > count) {
              let filteredIndexes = [];
              this.state.orderArray.filter((x, i) => {
                if (
                  parseInt(x.serviceId) === parseInt(service) &&
                  parseInt(x.garmentId) === parseInt(garment)
                ) {
                  filteredIndexes.push(i);
                  return i;
                }
              });
              if (filteredIndexes.length > 0) {
                this.state.orderArray.splice(
                  filteredIndexes[filteredIndexes.length - 1],
                  1
                );
              }
            }
          }
          if (deliveryDaysAdd !== false) {
            this.setState({
              order: {
                ...this.state.order,
                [service]:
                  this.state.order[service] === undefined
                    ? {
                        ...this.state.order[service],
                        [garment]: count === 0 ? undefined : count,
                      }
                    : Object.keys(this.state.order[service]).filter(
                        (x) => this.state.order[service][x] !== undefined
                      ).length === 1 && count === 0
                    ? undefined
                    : {
                        ...this.state.order[service],
                        [garment]: count === 0 ? undefined : count,
                      },
              },
            });
          }
          callback();
        }
      );
    } else {
      notification["error"]({
        message: labels.ORDERS,
        description: `${this.state.customerInfo.serviceType.name} service is not available for this garment`,
      });
      callback();
    }
  };

  getCustomerInfo = (customerInfo) => {
    this.setState({
      customerInfo: customerInfo,
      orderDate: moment(customerInfo.systemDateTimeApiValue), // added by pb for solving DeliveryDate issue  [L15549]
    });
  };

  createOrder = (
    dataSource,
    finalDeliveryDate,
    isPromocode = false,
    isRewash = false,
    shouldValidateBefore = true
    // InputOTP
  ) => {
    // console.log("isRewash->",isRewash);
    this.setState({ spinning: true });
    let vMSplitOrderReqInfo = [];
    for (let service in this.state.order) {
      let filteredData = dataSource.filter(
        (x) => parseInt(x.serviceId) === parseInt(service)
      );
      if (filteredData.length > 0) {
        let object = {
          splitOrderID: 0,
          garmentID: 1,
          serviceID: parseInt(service),
        };
        let addOnServiceIDs = [];
        let deliveryDate;
        // let deliveryDate = moment();//commented by pb
        for (let singleRecord in filteredData) {
          if (filteredData[singleRecord].addOnServices.length > 0) {
            deliveryDate = moment();
            addOnServiceIDs = [
              ...addOnServiceIDs,
              ...filteredData[singleRecord].addOnServices,
            ];
            if (deliveryDate.isBefore(singleRecord.deliveryDate)) {
              deliveryDate = filteredData[singleRecord].deliveryDate;
            }
          }
          deliveryDate = filteredData[singleRecord].deliveryDate;
        }
        addOnServiceIDs = addOnServiceIDs
          .filter((value, index, self) => self.indexOf(value) === index)
          .join(",");
        object.addOnServiceIDs = addOnServiceIDs;
        object.deliveryDate = deliveryDate.format("YYYY-MM-DD");

        object.uom = this.state.garmentList.filter(
          (x) => parseInt(x.serviceId) === parseInt(service)
        )[0].UOM;
        object.noOfGarment = filteredData.length;
        object.weight =
          this.state.order[service] !== undefined
            ? this.state.order[service][-1] !== undefined
              ? this.state.order[service][-1]
              : 0
            : 0;

        vMSplitOrderReqInfo.push(object);
      }
    }

    // console.log("dataSource->",dataSource);

    let vMOrderGarmentReqInfo = [];
    for (let index in dataSource) {
      let object = {
        orderGarmentID: 0,
        garmentID: parseInt(dataSource[index].garmentId),
        serviceID: parseInt(dataSource[index].serviceId),
        addOnServiceIDs:
          dataSource[index].addOnServices !== null
            ? dataSource[index].addOnServices.join(",")
            : null,
        uom: parseInt(dataSource[index].UOM),
        nos: 1,
        length: dataSource[index].length,
        width: dataSource[index].width,
        colorIDs:
          dataSource[index].color === null ? "" : dataSource[index].color,
        brandID:
          dataSource[index].brand === null
            ? 0
            : parseInt(dataSource[index].brand),
        sizeID:
          dataSource[index].size === null
            ? 0
            : parseInt(dataSource[index].size),
        specialInstruction: dataSource[index].notes,
        dyeingColors:
          dataSource[index].dyingColorsValue !== null
            ? dataSource[index].dyingColorsValue.join(",")
            : "",
        darningTypeID:
          dataSource[index].darningSize !== "" &&
          dataSource[index].darningSize !== null
            ? parseInt(dataSource[index].darningSize)
            : 0,
        dyeingTypeID:
          dataSource[index].dyingColorsUOM !== null &&
          dataSource[index].dyingColorsUOM !== ""
            ? dataSource[index].dyingColorsUOM
            : 0,
        GarmentImage1:
          dataSource[index].images[0] !== null
            ? dataSource[index].images[0].originFileObj
            : null,
        GarmentImage2:
          dataSource[index].images[1] !== null
            ? dataSource[index].images[1].originFileObj
            : null,
        GarmentImage3:
          dataSource[index].images[2] !== null
            ? dataSource[index].images[2].originFileObj
            : null,
        GarmentDefect:
          dataSource[index].garmentDefect !== null  &&  dataSource[index].garmentDefect !== ""
            ? dataSource[index].garmentDefect
            : null,
        // unchangedImages: "",
      };

      // console.log("object->",object);

      vMOrderGarmentReqInfo.push(object);
    }

    // Start-Added By Hasti - L17462
    var tempPickupID =
      parseInt(this.state.customerInfo.pickupRequest) === null || isRewash
        ? 0
        : parseInt(this.state.customerInfo.pickupRequest);
    //End

    // console.log("Address->", this.state.customerInfo.selectedAddress.id);

    let postData = {
      orderID: 0,
      storeID: this.state.storeID,
      // inputOTP:InputOTP,
      pickupID:
        parseInt(this.state.customerInfo.pickupRequest) === null || isRewash
          ? 0
          : parseInt(this.state.customerInfo.pickupRequest),
      customerID: parseInt(this.state.customerInfo.id),
      // customerAddressID: parseInt(this.state.customerInfo.selectedAddress.id),
      customerAddressID:
        (tempPickupID != null && tempPickupID != 0) || this.state.isOnDemand
          ? parseInt(this.state.customerInfo.selectedAddress.id)
          : this.state.customerInfo.selectedAddress.id != null &&
            this.state.customerInfo.selectedAddress.id != 0 &&
            this.state.customerInfo.selectedAddress.id != ""
          ? parseInt(this.state.customerInfo.selectedAddress.id)
          : 0,
      orderDate: isRewash? moment().format("YYYY-MM-DD")  : this.state.orderDate.format("YYYY-MM-DD"),
      orderType: isRewash ? 2 : this.state.orderType,
      promocode: isRewash
        ? null
        : shouldValidateBefore || isPromocode
        ? this.state.promocode
        : this.state.isPromocodeValid
        ? this.state.promocode
        : null,
      orderStatus: 2, // Added by HT
      // orderStatus: 1,
      deliveryType: this.state.customerInfo.serviceType.id,
      noOfGarment: dataSource.length,
      orderUOM: 0,
      operationInstruction:
        this.state.opeartionInstruction !== undefined
          ? this.state.opeartionInstruction.join(",")
          : "",
      logisticInstruction: this.state.logisticInstruction,
      deliveryDate: finalDeliveryDate.format("YYYY-MM-DD"),
      orderRemark: this.state.orderRemarks,
      isOnDemandDelivery: this.state.isOnDemand,
      deletedOrderGarmentIDs: "",
      sourceFrom: 1,
      rewashOrderID: isRewash ? this.state.orderID : this.state.rewashOrderID,
      rewashRequestedBy: this.state.rewashRequestedBy,
      rewashReason: this.state.rewashReason,
      vMSplitOrderReqInfo: vMSplitOrderReqInfo,
      vMOrderGarmentReqInfo: vMOrderGarmentReqInfo,
    };

    let postFormData = new FormData();
    for (let key in postData) {
      if (key !== "vMSplitOrderReqInfo" && key !== "vMOrderGarmentReqInfo") {
        postFormData.append(
          key,
          postData[key] !== null && postData[key] !== undefined
            ? postData[key]
            : ""
        );
      } else {
        for (let index in postData[key]) {
          for (let nestedKey in postData[key][index]) {
            postFormData.append(
              key + "[" + index + "]." + nestedKey,
              postData[key][index][nestedKey] !== undefined &&
                postData[key][index][nestedKey] !== null
                ? postData[key][index][nestedKey]
                : ""
            );
          }
        }
      }
    }

    if (isPromocode === false || isRewash) {
      if (
        shouldValidateBefore === false ||
        [null, undefined, ""].includes(this.state.promocode) ||
        isRewash
      ) {
        this.createOrderService.createOrder(postFormData).then((response) => {
          if (response.statusCode === 200) {
            notification["success"]({
              message: labels.ORDERS,
              description: response.message
                ? this.common.Message(response.message)
                : labels.ORDER_SUCCESSFULLY_CREATED,
              duration: 1.5,

              onClose: () => {
                this.props.history.push(
                  [ROUTES.UPDATE_ORDER, parseInt(response.data.orderID)].join(
                    "/"
                  )
                );
                this.getOrderByID(response.data.orderID);
                // getPDFEstimatedOrder(response.data.orderID); // added by HT
              },
            });
          } else {
            notification["error"]({
              message: labels.ORDERS,
              description: response.message
                ? this.common.Message(response.message)
                : labels.UNABLE_TO_CREATE_THE_ORDER,
              duration: 1.5,
            });
            this.setState({ spinning: false });
          }
        });
      } else {
        this.validatePromocode(
          postData,
          postFormData,
          () =>
            this.createOrder(
              dataSource,
              finalDeliveryDate,
              isPromocode,
              isRewash,
              false
            ),
          true
        );
      }
    } else if (isPromocode === true) {
      //added by PB for solving out Q161707 issue
      if (![null, undefined, ""].includes(this.state.promocode)) {
        this.validatePromocode(postData, postFormData);
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: labels.PLEASE_ENTER_THE_PROMOCODE,
          duration: 1.5,
        });
      }
      this.setState({ spinning: false });
    }
  };
  validatePromocode = (
    postData,
    postFormData,
    callback = () => null,
    fwdRequest = false
  ) => {
    this.createOrderService.validatePromocode(postFormData).then((response) => {
      if (response.statusCode === 200) {
        if (response.data.isValid === true) {
          this.setState(
            {
              promocode: postData.promocode,
              discount: response.data.discountAmount,
              isPromocodeValid: true,
            },
            () => callback()
          );
          if (!fwdRequest) {
            notification["success"]({
              message: labels.ORDERS,
              description: labels.PROMOCODE_APPLIED_SUCCESSFULLY,
              duration: 1.5,
            });
          }
        } else {
          this.setState({
            promocode: "",
            discount: 0,
            isPromocodeValid: false,
          });
          notification["error"]({
            message: labels.ORDERS,
            description: labels.PROMOCODE_IS_INVALID,
            duration: 1.5,
          });
        }
      } else {
        this.setState({ promocode: "", discount: 0, isPromocodeValid: false });
        notification["error"]({
          message: labels.ORDERS,
          description: labels.UNABLE_TO_VALIDATE_PROMOCODE,
          duration: 1.5,
        });
      }
    });
  };
  recalculateRates = () => {};
  calculateTotal = () => {
    let total = 0;
    for (let service in this.state.order) {
      for (let garment in this.state.order[service]) {
        total = total + this.state.order[service][garment];
      }
    }
    return total;
  };
  updateOrderArray = (updatedArray, serviceId, garmentId) => {
    if (
      ![undefined, null, ""].includes(serviceId) &&
      ![undefined, null, ""].includes(garmentId)
    ) {
      let neworder = this.state.order;
      neworder[serviceId][garmentId] =
        parseInt(neworder[serviceId][garmentId]) - 1;
      this.setState({ order: neworder });
    }
    this.setState({ orderArray: updatedArray });
  };
  updateOrderAPI = (
    dataSource,
    finalDeliveryDate,
    shouldConfirm = false,
    isPromocode = false,
    shouldValidateBefore = true
    // inputOTP
  ) => {
    // console.log("updateorder");
    // this.setState({ dataSource: dataSource });
    let vMSplitOrderReqInfo = [];
    for (let service in this.state.order) {
      let filteredGarmentObj = dataSource.filter(
        (x) => parseInt(x.serviceId) === parseInt(service)
      )[0];
      if (filteredGarmentObj !== undefined) {
        let object = {
          splitOrderID: filteredGarmentObj.splitOrderID,
          garmentID: 0,
          serviceID: parseInt(service),
        };
        let filteredData = dataSource.filter(
          (x) => parseInt(x.serviceId) === parseInt(service)
        );
        let addOnServiceIDs = [];
        let deliveryDate;
        // let deliveryDate = moment();commented by pb
        for (let singleRecord in filteredData) {
          if (filteredData[singleRecord].addOnServices.length > 0) {
            deliveryDate = moment();
            addOnServiceIDs = [
              ...addOnServiceIDs,
              ...filteredData[singleRecord].addOnServices,
            ];
            if (deliveryDate.isBefore(singleRecord.deliveryDate)) {
              deliveryDate = filteredData[singleRecord].deliveryDate;
            }
          }
          deliveryDate = filteredData[singleRecord].deliveryDate; // added by pb
        }
        addOnServiceIDs = addOnServiceIDs
          .filter((value, index, self) => self.indexOf(value) === index)
          .join(",");
        object.addOnServiceIDs = addOnServiceIDs;
        object.deliveryDate = deliveryDate.format("YYYY-MM-DD");

        object.uom = this.state.garmentList.filter(
          (x) => parseInt(x.serviceId) === parseInt(service)
        )[0].UOM;
        object.noOfGarment = filteredData.length;
        object.weight =
          this.state.order[service] !== undefined
            ? this.state.order[service][-1] !== undefined
              ? this.state.order[service][-1]
              : 0
            : 0;

        vMSplitOrderReqInfo.push(object);
      }
    }
    let vMOrderGarmentReqInfo = [];
    for (let index in dataSource) {
      let unchangedImages = dataSource[index].images
        .filter((x) => x !== null)
        .filter((x) => x.url !== undefined)
        .map((x, index) => x.url.split("/")[x.url.split("/").length - 1])
        .join(",");
      let object = {
        orderGarmentID: dataSource[index].orderGarmentID,
        garmentID: parseInt(dataSource[index].garmentId),
        serviceID: parseInt(dataSource[index].serviceId),
        addOnServiceIDs:
          dataSource[index].addOnServices !== null
            ? dataSource[index].addOnServices.join(",")
            : null,
        uom: parseInt(dataSource[index].UOM),
        nos: 1,
        length: dataSource[index].length,
        width: dataSource[index].width,
        colorIDs: dataSource[index].color,
        brandID:
          dataSource[index].brand === null
            ? 0
            : parseInt(dataSource[index].brand),
        sizeID:
          dataSource[index].size === null
            ? 0
            : parseInt(dataSource[index].size),
        specialInstruction: dataSource[index].notes,
        dyeingColors:
          dataSource[index].dyingColorsValue !== null
            ? dataSource[index].dyingColorsValue.join(",")
            : "",
        darningTypeID:
          dataSource[index].darningSize !== "" &&
          dataSource[index].darningSize !== null
            ? parseInt(dataSource[index].darningSize)
            : 0,
        dyeingTypeID:
          dataSource[index].dyingColorsUOM === "" ||
          dataSource[index].dyingColorsUOM === null
            ? 0
            : dataSource[index].dyingColorsUOM,
        GarmentImage1:
          dataSource[index].images[0] !== null
            ? dataSource[index].images[0].url !== undefined
              ? dataSource[index].images[0].url
              : dataSource[index].images[0].originFileObj
            : null,
        GarmentImage2:
          dataSource[index].images[1] !== null
            ? dataSource[index].images[1].url !== undefined
              ? dataSource[index].images[1].url
              : dataSource[index].images[1].originFileObj
            : null,
        GarmentImage3:
          dataSource[index].images[2] !== null
            ? dataSource[index].images[2].url !== undefined
              ? dataSource[index].images[2].url
              : dataSource[index].images[2].originFileObj
            : null,
        GarmentDefect:
          dataSource[index].garmentDefect !== null
            ? dataSource[index].garmentDefect.join(",")
            : null,
        unchangedImages: unchangedImages,
      };
      vMOrderGarmentReqInfo.push(object);
    }
    let postData = {
      orderID: this.state.orderID,
      // inputOTP:inputOTP,
      storeID: this.state.storeID,
      pickupID: this.state.customerInfo.pickupRequest,
      customerID: parseInt(this.state.customerInfo.id),
      customerAddressID: parseInt(this.state.customerInfo.selectedAddress.id),
      orderDate: moment(this.state.orderDate).format("YYYY-MM-DD"),
      orderType: this.state.orderType,
      promocode:
        shouldValidateBefore || isPromocode
          ? this.state.promocode
          : this.state.isPromocodeValid
          ? this.state.promocode
          : null,
      orderStatus: shouldConfirm ? 2 : 1,
      deliveryType: this.state.customerInfo.serviceType.id,
      noOfGarment: dataSource.length,
      orderUOM: 0,
      operationInstruction: this.state.opeartionInstruction.join(","),
      logisticInstruction: this.state.logisticInstruction,
      deliveryDate: finalDeliveryDate.format("YYYY-MM-DD"),
      orderRemark: this.state.orderRemarks,
      isOnDemandDelivery: this.state.isOnDemand,
      deletedOrderGarmentIDs: this.state.deletedGarmentIDs.join(","),
      sourceFrom: 1,
      rewashOrderID: this.state.rewashOrderID,
      rewashRequestedBy: this.state.rewashRequestedBy,
      rewashReason: this.state.rewashReason,
      vMSplitOrderReqInfo: vMSplitOrderReqInfo,
      vMOrderGarmentReqInfo: vMOrderGarmentReqInfo,
    };


    let postFormData = new FormData();
    for (let key in postData) {
      if (key !== "vMSplitOrderReqInfo" && key !== "vMOrderGarmentReqInfo") {
        postFormData.append(
          key,
          postData[key] !== null && postData[key] !== undefined
            ? postData[key]
            : ""
        );
      } else {
        for (let index in postData[key]) {
          for (let nestedKey in postData[key][index]) {
            postFormData.append(
              key + "[" + index + "]." + nestedKey,
              postData[key][index][nestedKey] !== undefined &&
                postData[key][index][nestedKey] !== null
                ? postData[key][index][nestedKey]
                : ""
            );
          }
        }
      }
    }
    if (isPromocode === false) {
      if (
        shouldValidateBefore === false ||
        [null, undefined, ""].includes(this.state.promocode)
      ) {
        //added by pb L15565 : Add Loader while confirming order
        if (shouldConfirm == true) {
          this.setState({ spinning: true });
        }
        //ended
        this.createOrderService.updateOrder(postFormData).then((response) => {
          if (response.statusCode === 200) {
            // notification["success"]({
            //   message: labels.ORDERS,
            //   description: response.message
            //     ? this.common.Message(response.message)
            //     : labels.ORDER_SUCCESSFULLY_UPDATED,
            //   duration: 1.5,
            // });
            if (shouldConfirm === true) {
              //added by pb L15565 : Add Loader while confirming order
              this.setState({ spinning: false });
              //ended
              this.getOrderByID(this.props.match.params.orderID);
              // getPDFEstimatedOrder(this.props.match.params.orderID); //comment by Hasti
            } else {
              window.location.reload();
            }
            //window.location.reload();
            // this.getOrderByID(this.props.match.params.orderID);
            // this.forceUpdate();
          } else {
            notification["error"]({
              message: labels.ORDERS,
              description: response.message
                ? this.common.Message(response.message)
                : labels.UNABLE_TO_UPDATE_THE_ORDER,
              duration: 1.5,
            });
            this.setState({ spinning: false });
          }
        });
      } else {
        this.validatePromocode(
          postData,
          postFormData,
          () =>
            this.updateOrderAPI(
              dataSource,
              finalDeliveryDate,
              shouldConfirm,
              isPromocode,
              false
            ),
          true
        );
      }
    } else if (isPromocode === true) {
      //added by PB for solving out Q161707 issue
      if (![null, undefined, ""].includes(this.state.promocode)) {
        this.validatePromocode(postData, postFormData);
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: labels.PLEASE_ENTER_THE_PROMOCODE,
          duration: 1.5,
        });
      }
      this.setState({ spinning: false });
    }
  };
  cancelOrder = (values) => {
    this.setState({ cancelPopupLoading: true });
    this.createOrderService
      .cancelOrder(this.state.orderID, values.reason)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: labels.ORDERS,
            description: response.message
              ? this.common.Message(response.message)
              : labels.ORDER_SUCCESSFULLY_CANCELLED,
            duration: 1.5,
          });
          this.getOrderByID(this.props.match.params.orderID);
        } else {
          notification["error"]({
            message: labels.ORDERS,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_CANCEL_ORDER,
            duration: 1.5,
          });
        }
        this.setState({ cancelPopupLoading: false, showCancelPopup: false });
      });
    //cancelOrder
  };

  printTagRef = React.createRef();
  printRewashTagRef = React.createRef();
  render() {
    return (
      <Spin spinning={this.state.spinning}>
        {/* {JSON.stringify(this.state.orderArray)} */}
        {parseInt(this.state.storeInfo.status) === 1 ? (
          (this.state.storeInfo.onDemandWalkIn.includes("1") &&
            this.state.isFromPickup) ||
          (this.state.storeInfo.onDemandWalkIn.includes("2") &&
            !this.state.isFromPickup) ? (
            <React.Fragment>
              <CustomerSelection
                customerInfo={this.state.customerInfo}
                getCustomerInfo={this.getCustomerInfo}
                recalculateRates={this.recalculateRates}
                orderStatus={this.state.orderStatus}
                orderType={this.state.orderType}
                storeInfo={this.state.storeInfo}
                orderArray={this.state.orderArray} //Added by rashmi for L177728 - 21/02/23
              />
              {this.state.customerInfo.id !== "" ? (
                <React.Fragment>
                  {this.state.isReview ? (
                    <ReviewOrder
                      AllowCancelOrder={this.state.AllowCancelOrder}
                      storeID={this.state.storeID}
                      orderArray={this.state.orderArray}
                      order={this.state.order}
                      orderStatus={this.state.orderStatus}
                      orderType={this.state.orderType}
                      updateOrder={(updated) => {
                        this.setState({ order: updated });
                      }}
                      garmentList={this.state.garmentList}
                      backFunction={this.goToOrder}
                      rates={this.state.rates}
                      getRateForGarment={this.getRateForGarment}
                      calculateDeliveryDateAdd={this.calculateDeliveryDateAdd}
                      // cgst={this.state.cgst}
                      // sgst={this.state.sgst}
                      // igst={this.state.igst}
                      packagingCharges={this.state.packagingCharges}
                      deliveryCharges={this.state.deliveryCharges}
                      createOrder={this.createOrder}
                      updateOrderAPI={this.updateOrderAPI}
                      updateOrderFunc={this.updateOrder}
                      updateArray={this.updateOrderArray}
                      updateOrderRemarks={(value) => {
                        this.setState({ orderRemarks: value });
                      }}
                      orderRemarks={this.state.orderRemarks}
                      updateLogisticInstruction={(value) => {
                        this.setState({ logisticInstruction: value });
                      }}
                      logisticInstruction={this.state.logisticInstruction}
                      updateOperationInstruction={(value) => {
                        this.setState({ opeartionInstruction: value });
                      }}
                      opeartionInstruction={this.state.opeartionInstruction}
                      deleteGarments={this.deleteGarments}
                      cancelOrder={this.showCancelPopup}
                      generateTags={this.generateTags}
                      printTags={this.printTags}
                      rewashprintTags={this.rewashprintTags}
                      orderSummaryObject={this.state.orderSummaryObject}
                      isCorporate={this.isCorporate}
                      promocode={this.state.promocode}
                      GarmentDeliveryType={this.state.GarmentDeliveryType} //Added by rashmi for L177728 - 22/02/23
                      updatePromocode={(value) =>
                        this.setState({ promocode: value })
                      }
                      discount={this.state.discount}
                      rewashRequestedBy={this.state.rewashRequestedBy}
                      rewashReason={this.state.rewashReason}
                      updateRewashRequestedBy={(value) =>
                        this.setState({ rewashRequestedBy: parseInt(value) })
                      }
                      updateRewashReason={(value) =>
                        this.setState({ rewashReason: value.join(",") })
                      }
                      orderDate={this.state.orderDate}
                      customerInfo={this.state.customerInfo}
                      orderID={this.state.orderID}
                      isOnDemand={this.state.isOnDemand}
                      changeIsOnDemand={(value) => {
                        this.setState({ isOnDemand: value });
                      }}
                      isFromPickup={this.state.isFromPickup}
                      setWalletBalance={(cash, credit) => {
                        this.setState({
                          customerInfo: {
                            ...this.state.customerInfo,
                            walletBalance: cash,
                            lkCreditAmt: credit,
                          },
                        });
                      }}
                      view={
                        this.props.location.state != undefined
                          ? this.props.location.state.view
                          : null
                      }
                    />
                  ) : (
                    <Row gutter={24}>
                      <Col xs={24} md={17}>
                        {/* {this.state.orderArray.length > 0 ? */}
                        <GarmentSelection
                          key={this.state.garmentSelectionShouldUpdate}
                          storeInfo={this.state.storeInfo}
                          garmentList={this.state.garmentList}
                          order={this.state.order}
                          updateOrder={this.updateOrder}
                          getCountFunction={this.getCountForGarment}
                          disabled={
                            this.state.customerInfo.serviceType !== undefined
                              ? this.state.customerInfo.serviceType.id !== ""
                                ? false
                                : true
                              : true
                          }
                          orderArray={this.state.orderArray}
                          updateArray={this.updateOrderArray}
                          serviceType={
                            //Added by rashmi for L177728 - 22/02/23
                            this.state.customerInfo.serviceType !== undefined
                              ? this.state.customerInfo.serviceType.id !== ""
                                ? this.state.customerInfo.serviceType.id
                                : null
                              : null
                          }
                        />
                        {/* : null} */}
                      </Col>
                      <Col xs={24} md={7}>
                        <OrderSummary
                          order={this.state.order}
                          garmentList={this.state.garmentList}
                          goToReview={this.goToReview}
                          rates={this.state.rates}
                          disabled={this.state.customerInfo === {}}
                          cgst={this.state.cgst}
                          sgst={this.state.sgst}
                          igst={this.state.igst}
                          packagingCharges={this.state.packagingCharges}
                          deliveryCharges={this.state.deliveryCharges}
                          updateOrderRemarks={(value) => {
                            this.setState({ orderRemarks: value });
                          }}
                          orderRemarks={this.state.orderRemarks}
                          orderArray={this.state.orderArray}
                          cancelOrder={this.showCancelPopup}
                          discount={this.state.discount}
                          isFromPickup={this.state.isFromPickup}
                          isOnDemand={this.state.isOnDemand}
                          customer={this.state.customerInfo}
                          orderStatus={this.state.orderStatus}
                        />
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              ) : null}
              <Modal
                title={labels.PRINT_TAGS}
                visible={this.state.showAddPopup}
                footer={null}
                onCancel={() => this.setState({ showAddPopup: false })}
                className="modal-form"
                destroyOnClose
              >
                <div
                  style={{
                    overflowY: "scroll",
                    justifyContent: "flex",
                    justifyContent: "center",
                  }}
                >
                  <GeneratePrintInfo
                    printInfo={this.state.printInfo}
                    instance={this.printTagRef}
                    // stickerID={this.state.stickerID}
                    // customerID={
                    //   this.state.resortingAndPackagingServices != null
                    //     ? this.state.resortingAndPackagingServices.customerID
                    //     : 0
                    // }
                  />
                  {/* <GeneratePrintInfo
              printInfo={this.state.printInfo}
              stickerID={5}
              customerID={26}
            ></GeneratePrintInfo> */}
                </div>
              </Modal>
              <Modal
                title="Print Rewash Tag"
                visible={this.state.showrewashtag}
                footer={null}
                onCancel={() => this.setState({ showrewashtag: false })}
                className="modal-form"
                destroyOnClose
              >
                <div
                  style={{
                    overflowY: "scroll",
                    justifyContent: "flex",
                    justifyContent: "center",
                  }}
                >
                  <GenerateRewashTagPrintInfo
                    printInfo={this.state.printInfo}
                    instance={this.printRewashTagRef}
                  />
                </div>
              </Modal>
              <Modal
                title={labels.CANCEL_ORDER}
                visible={this.state.showCancelPopup}
                footer={null}
                onCancel={() => this.setState({ showCancelPopup: false })}
                className="modal-form"
                destroyOnClose
              >
                <Spin spinning={this.state.cancelPopupLoading}>
                  <Form layout="vertical" onFinish={this.cancelOrder}>
                    <Row gutter={24}>
                      <Col xs={24}>
                        <Form.Item
                          label={labels.CANCEL_REASON}
                          name="reason"
                          rules={[
                            {
                              required: true,
                              message: labels.PLEASE_ENTER_REASON,
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder={
                              labels.ENTER_REASON_TO_CANCEL_THE_ORDER
                            }
                            disabled={this.state.isEnabled}
                            onChange={this.handleSizeName}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} className="custom-modal-footer">
                        <Space>
                          <Button
                            variant="transparentGreyOutline"
                            onClick={() =>
                              this.setState({ showCancelPopup: false })
                            }
                          >
                            {labels.CANCEL}
                          </Button>
                          <Button htmlType="submit" variant="purple">
                            {labels.SUBMIT}
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Form>
                </Spin>
              </Modal>
            </React.Fragment>
          ) : (
            <div style={{ textAlign: "center" }}>
              {labels.THIS_TYPE_OF_ORDER_CAN_NOT_BE_CREATED_FROM_THIS_STORE}
            </div>
          )
        ) : (
          <div style={{ textAlign: "center" }}>
            {labels.ORDER_CAN_NOT_BE_CREATED_USING_CLOSED_STORE}
          </div>
        )}
      </Spin>
    );
  }
}
export default withRouter(CreateOrder);
