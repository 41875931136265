import React from "react";
import { PageHeader, Input, Row, Col, Form, Space, Table, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import styles from "../../../components/Common/Styles/master.module.css";
import CreateStore from "./createstore.screen";
import StoreService from "../../../services/storeservice";
import Moment from "moment";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../routes";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";

const { Search } = Input;
// const { Option } = Select;
/**
 * Store listing screen.
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 1 June 2020
 *
 */
class StoreList extends React.Component {
  /**
   * State Varible to Hold Active Step Index starting from 0
   */
  constructor(props) {
    super(props);
    this.storeservice = new StoreService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.state = {
      tableLoading: true,
      isEditmode: false,
      showfilterBox: false,
      isViewmode: false,
      disabled: false,
      showAddPopup: false,
      isAdd: false,
      storeList: [],
      storeMasterData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      loading: false,
      shouldRefresh: false,
    };
    this.getStoreMasterByfilter = this.getStoreMasterByfilter.bind(this);
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.getStoreMasterDetails({
      storeID: this.state.storeID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      SourceFrom:1
      // ...filters,
    });
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.STORE
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      pagination: {
        current: pagination.current,
        pageSize: pagination.pageSize,
      },
    });
    this.getStoreMasterDetails({
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      SourceFrom:1
      // ...filters,
    });
  };

  getStoreMasterDetails(params = {}) {
    this.setState({ tableLoading: true });
    this.storeservice.retrieveStoreMaster(params).then((storeList) => {
     
      this.setState({
        storeList: storeList.data,
        pagination: {
          ...params.pagination,
          total: storeList.data.length > 0 ? storeList.data[0].totalRecords : 0,
          current:
            storeList.data.length === 0 ? 1 : this.state.pagination.current,
        },
      });
      this.setState({ tableLoading: false });
    });
  }

  getStoreMasterByfilter(pagination) {
    const params = {
      storeName: document.getElementById("storeName").value,
      shortName: document.getElementById("shortName").value,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    };

    this.storeservice.retrieveStoreMasterByfilter(params).then((storeList) => {
      this.setState({
        storeList: storeList.data,
        pagination: {
          ...params.pagination,
          total: storeList.data[0].totalRecords,
        },
      });
    });
  }

  // deleteUserByUserID(id){
  //   this.userservice.deleteUserByUserID(id).then(response => {
  //       console.log(response);
  //       alert(response.message);
  //       this.getUserMasterDetails();
  //   });
  // }

  getStoreDetailByStoreID(id) {
    this.storeservice.getStoreDetailByStoreID(id).then((storeMasterData) => {
      if (storeMasterData.result === "Success") {
        this.setState(
          { storeMasterData: storeMasterData.data, isEditmode: true },
          () => this.setState({ shouldRefresh: true })
        );
      } else {
        alert(storeMasterData.message);
      }
      // console.log(this.state.shouldRefresh);
    });
  }

  getStoreDetailsViewbyStoreID(id) {
    this.storeservice.getStoreDetailByStoreID(id).then((storeMasterData) => {
      if (storeMasterData.result === "Success") {
        this.setState({
          storeMasterData: storeMasterData.data,
          isViewmode: true,
          disabled: true,
        });
      } else {
        alert(storeMasterData.message);
      }
    });
  }

  handleSearch = (event) => {
    this.getStoreMasterDetails({
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      SourceFrom:1
      // ...filters,
    });
  };

  bringback = () => {
    this.setState({ isAdd: false });
  };

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
      sorter: true,
    },
    {
      title: "Opening Date",
      dataIndex: "openingDate",
      key: "openingDate",
      sorter: true,
      render: (data) =>
        [null, undefined, ""].includes(data)
          ? undefined
          : Moment(data, "DD/MM/YYYY").format("DD-MM-YYYY"),
    },
    {
      title: "Store Type",
      dataIndex: "typeOfStoreName",
      key: "typeOfStoreName",
      sorter: true,
    },
    {
      title: "Store Model",
      dataIndex: "storeModelName",
      key: "storeModelName",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "statusName",
      key: "statusName",
      sorter: true,
    },
    {
      title: "FeedbackLink",
      dataIndex: "feedbackLink",
      key: "feedbackLink",
      sorter: false,
      ellipsis: {
        showTitle: false,
      },
      render: (feedbackLink) => {
        return (
          <Tooltip placement="topLeft" title={feedbackLink}>
            {feedbackLink}
          </Tooltip>
        );
      }
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Link
                to={{
                  pathname: [ROUTES.VIEW_STORE, record.storeID].join("/"),
                  state: { storeID: record.storeID },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Link
                to={{
                  pathname: [ROUTES.EDIT_STORE, record.storeID].join("/"),
                  state: { storeID: record.storeID },
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </Tooltip>
          ) : null}

          {/* <Button type="link" onClick={()=>this.getStoreDetailsViewbyStoreID(record.storeID)}>
            <FontAwesomeIcon icon={faInfo} />
          </Button> 
          <Button type="link"
          onClick={()=>this.getStoreDetailByStoreID(record.storeID)}
          >
          <FontAwesomeIcon icon={faPencilAlt} />
          
          </Button> */}

          {/* <Popconfirm
            title="Are you sure ?"
            onConfirm={()=>this.deleteStoreByUserID(record.storeID)}
            onCancel={() => null}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Popconfirm>  */}
        </Space>
      ),
    },
  ];

  /**
   * Variables to be used in table for selection property
   * kindly go through
   * https://ant.design/components/table/
   * https://ant.design/components/table/#components-table-demo-row-selection-and-operation
   * https://ant.design/components/table/#components-table-demo-row-selection
   */
  // selectionType = "checkbox";
  // rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log(
  //       `selectedRowKeys: ${selectedRowKeys}`,
  //       "selectedRows: ",
  //       selectedRows
  //     );
  //   },
  //   getCheckboxProps: (record) => ({
  //     disabled: record.firstName === "Disabled User", // Column configuration not to be checked
  //     name: record.firstName,
  //   }),
  // };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  render() {
    const {
      storeMasterData,
      isEditmode,
      pagination,
      isViewmode,
      disabled,
    } = this.state;
    const storeList = this.state.storeList;
    const storeLists = storeList.map((storeList) => ({
      storeID: storeList.storeID,
      storeName: storeList.storeName,
      shortName: storeList.shortName,
      typeOfStoreName: storeList.typeOfStoreName,
      storeModelName: storeList.storeModelName,
      statusName: storeList.statusName,
      feedbackLink: storeList.feedbackLink,
      openingDate: [null, undefined, ""].includes(storeList.openingDate)
        ? null
        : Moment(storeList.openingDate).format("DD-MM-YYYY"),
    }));
    return this.state.isAdd ||
      this.state.isEditmode ||
      this.state.isViewmode ? (
      <div key={this.state.shouldRefresh}>
        <CreateStore
          backFunction={this.bringback}
          storeMasterData={storeMasterData}
          isEditmode={isEditmode}
          isViewmode={isViewmode}
          disabled={disabled}
        />
      </div>
    ) : (
      <React.Fragment>
        <PageHeader
          title="Store Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
              key="1"
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                onClick={() =>
                  this.props.history.push({
                    pathname: ROUTES.ADD_STORE,
                    state: { state: { storeID: this.state.storeID } },
                  })
                }
                key="2"
                //onClick={() => this.setState({ showAddPopup: true,isAdd:true })}
              >
                Add Store
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />

        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item>
                  <Input placeholder="Enter Store Name" id="storeName" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item>
                  <Input placeholder="Enter Short Name" id="shortName" />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  variant="whitePink"
                  shape="round"
                  size="large"
                  onClick={this.getStoreMasterByfilter}
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>
        {/**
         * Please go through below documention for <Table> Component
         * https://ant.design/components/table/
         * https://ant.design/components/table/#API
         *
         */}
        <Table
          size="small"
          // rowSelection={{
          //   type: this.selectionType,
          //   ...this.rowSelection,
          // }}
          columns={this.columns}
          dataSource={storeLists}
          pagination={pagination}
          loading={this.state.tableLoading}
          onChange={this.handleTableChange}
        />

        <TableActions isPagination={true}></TableActions>
      </React.Fragment>
    );
  }
}
export default withRouter(StoreList);
