import React from "react";
import { Row, Col, Form, Select, notification } from "antd";
import FingerScanService from "../../services/fingerscanservice";
import styles from "./fingerprint.module.css";
import Button from "../Common/Button/button";
import MantraHelper from "./mantraHelper";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";

export default class FingerScanScreen extends React.Component {
  fingerScanService = new FingerScanService();
  UserRightsConfiguration = new UserRightsConfiguration();
  mantraHelper = new MantraHelper();
  state = {
    storeOptions: {
      data: [],
      disabled: true,
    },
    factoryOptions: {
      data: [],
      disabled: true,
    },
    userOptions: {
      data: [],
      disabled: true,
    },
    fingerData: {
      data: [],
      isChanged: false,
    },
  };
  dummyImg =
    "iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8+PDVYgAIqANRetRIXAAAAABJRU5ErkJggg==";

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.COLLECT_FINGERPRINTS
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  componentDidMount() {
    this.getStores();
    this.getFactories();
  }
  getStores = () => {
    this.setState({
      storeOptions: {
        ...this.state.storeOptions,
        disabled: true,
      },
    });
    this.fingerScanService.getStores().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          storeOptions: {
            ...this.state.storeOptions,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: response.message
            ? response.message
            : "Unable to get List of Stores",
        });
      }
    });
  };
  getFactories = () => {
    this.setState({
      factoryOptions: {
        ...this.state.factoryOptions,
        disabled: true,
      },
    });
    this.fingerScanService.getFactories().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          factoryOptions: {
            ...this.state.factoryOptions,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: response.message
            ? response.message
            : "Unable to get List of Factories",
        });
      }
    });
  };
  getUsers = (store, factory) => {
    this.fingerScanService.getUsers(store, factory).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          userOptions: {
            ...this.state.userOptions,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: response.message
            ? response.message
            : "Unable to get List of Users",
        });
      }
    });
  };

  getFingers = (userid) => {
    this.fingerScanService.getFingers(userid).then((response) => {
      if (response.statusCode === 200) {
        response.data.userId = userid;
        this.setState({
          fingerData: {
            data: response.data,
            isChanged: false,
          },
        });
      } else {
        notification["error"]({
          message: response.message ? response.message : "Unable to get data",
        });
      }
    });
  };

  CollectFinger = (imgId) => {
    let quality = 60; //(1 to 100) (recommanded minimum 55)
    let timeout = 20; // seconds (minimum=10(recommanded), maximum=60, unlimited=0 )

    // let res =
    this.mantraHelper
      .CaptureFinger(quality, timeout)
      .then((res) => {
        if (res.httpStaus) {
          // document.getElementById("body_txtStatus").value = "ErrorCode: " + res.data.ErrorCode + " ErrorDescription: " + res.data.ErrorDescription;
          if (res.data.data.ErrorCode == "0") {
            switch (imgId) {
              case "1":
                this.setState({
                  fingerData: {
                    ...this.state.fingerData,
                    data: {
                      ...this.state.fingerData.data,
                      f1Image: res.data.data.BitmapData,
                      f1ISOTemplate: res.data.data.IsoTemplate,
                      f1AnsiTemplate: res.data.data.AnsiTemplate,
                    },
                    isChanged: true,
                  },
                });
                // this.state.fingerData.data.f1Image=  res.data.BitmapData;
                // this.state.fingerData.data.f1IsoTemplate=  res.data.IsoTemplate;
                // this.state.fingerData.data.f1AnsiTemplate=  res.data.AnsiTemplate;
                break;
              case "2":
                this.setState({
                  fingerData: {
                    ...this.state.fingerData,
                    data: {
                      ...this.state.fingerData.data,
                      f2Image: res.data.data.BitmapData,
                      f2ISOTemplate: res.data.data.IsoTemplate,
                      f2AnsiTemplate: res.data.data.AnsiTemplate,
                    },
                    isChanged: true,
                  },
                });
                // this.state.fingerData.data.f2Image = res.data.BitmapData;
                // this.state.fingerData.data.f2IsoTemplate=  res.data.IsoTemplate;
                // this.state.fingerData.data.f2AnsiTemplate=  res.data.AnsiTemplate;
                break;
              case "3":
                this.setState({
                  fingerData: {
                    ...this.state.fingerData,
                    data: {
                      ...this.state.fingerData.data,
                      f3Image: res.data.data.BitmapData,
                      f3ISOTemplate: res.data.data.IsoTemplate,
                      f3AnsiTemplate: res.data.data.AnsiTemplate,
                    },
                    isChanged: true,
                  },
                });
                // this.state.fingerData.data.f3Image = res.data.BitmapData;
                // this.state.fingerData.data.f3IsoTemplate=  res.data.IsoTemplate;
                // this.state.fingerData.data.f3AnsiTemplate=  res.data.AnsiTemplate;
                break;
              default:
                notification["warning"]({
                  message: "Please refresh and try again.",
                });
            }
          
            // document.getElementById("body_imgFinger").src =  res.data.BitmapData;
            // document.getElementById("body_txtQuality").value = res.data.Quality;
            // document.getElementById("body_txtISOTemplate").value = res.data.IsoTemplate;
            // document.getElementById("body_txtANSITemplate").value = res.data.AnsiTemplate;
          }
        } else {
          // alert(res.err);
          notification["error"]({
            message: res.err,
          });
        }
      })
      .catch((error) => {
        //res = { httpStaus: false, err:error };
         console.log(error);
      });
  };

  saveUpdateFingerPrint = () => {
    // const params = {
    //   FingurePrintID: 2,
    //   UserId: 1,
    //   F1Image: 'test',
    //   F1ISOTemplate: 'test',
    //   F1AnsiTemplate: 'test',
    //   F2Image: 'test',
    //   F2ISOTemplate: 'test',
    //   F2AnsiTemplate: 'test',
    //   F3Image: 'test',
    //   F3ISOTemplate: 'test',
    //   F3AnsiTemplate: 'test',
    // };

  
    if (!this.state.fingerData.isChanged) {
      notification["warning"]({
        message: "No chages detected.",
      });
      return;
    }

    this.fingerScanService
      .saveUpdateFingerPrint(this.state.fingerData.data)
      .then((response) => {
        if (response.statusCode === 200) {
          //msg
          notification["success"]({
            message: "Finger print saved successfully",
            duration: 1.5,
          });
        } else {
          notification["error"]({
            message: response.message
              ? response.message
              : "Unable to submit fingerprint data",
          });
        }
      });
  };
  render() {
    return (
      <React.Fragment>
        <Form onFinish={this.saveUpdateFingerPrint} layout="vertical">
          <Row gutter={30} style={{ paddingTop: 50 }}>
            <Col xs={12} offset={6}>
              <Row gutter={30}>
                <Col xs={12}>
                  <Form.Item
                    name="storeID"
                    label="Store/Factory"
                    rules={[
                      {
                        required: true,
                        message: "Please select Store/Factory",
                      },
                    ]}
                  >
                    <Select
                      disabled={
                        this.state.storeOptions.disabled ||
                        this.state.factoryOptions.disabled
                      }
                      placeholder="Select Store/Factory"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={(value, option) => {
                        this.getUsers(
                          option.type === "store" ? value.substr(1, 2) : null,
                          option.type === "factory" ? value.substr(1, 2) : null
                        );
                      }}
                    >
                      {this.state.storeOptions.data.map((store) => {
                        return parseInt(store.value) !== -1 ? (
                          <Select.Option
                            value={"s" + parseInt(store.value)}
                            key={parseInt(store.value)}
                            type="store"
                          >
                            {store.text}
                          </Select.Option>
                        ) : null;
                      })}

                      {this.state.factoryOptions.data.map((factory) => {
                        return parseInt(factory.value) !== -1 ? (
                          <Select.Option
                            value={"f" + parseInt(factory.value)}
                            key={parseInt(factory.value)}
                            type="factory"
                          >
                            {factory.text}
                          </Select.Option>
                        ) : null;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    name="userID"
                    label="User"
                    rules={[
                      {
                        required: true,
                        message: "Please select User",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select User"
                      disabled={this.state.userOptions.disabled}
                      onChange={(value, option) => {
                        this.getFingers(value);
                      }}
                    >
                      {this.state.userOptions.data.map((user) => {
                        return (
                          <Select.Option
                            value={parseInt(user.userID)}
                            key={parseInt(user.value)}
                          >
                            {user.firstName}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item name="fingerImages">
                    <div className={styles.fingerPrintContainer}>
                      <div className={styles.fingerPrintBox}>
                        <div className={styles.fingerPrintWrap}>
                          <img
                            className={styles.fingerPrint}
                            src={
                              "data:image/png;base64," +
                              (this.state.fingerData.data.f1Image == undefined
                                ? this.dummyImg
                                : this.state.fingerData.data.f1Image)
                            }
                          />
                        </div>
                        <Button
                          variant="purple"
                          className={styles.PlusButton}
                          onClick={() => {
                            this.CollectFinger("1");
                          }}
                        >
                          Collect
                        </Button>
                      </div>
                      <div className={styles.fingerPrintBox}>
                        <div className={styles.fingerPrintWrap}>
                          <img
                            className={styles.fingerPrint}
                            src={
                              "data:image/png;base64," +
                              (this.state.fingerData.data.f2Image == undefined
                                ? this.dummyImg
                                : this.state.fingerData.data.f2Image)
                            }
                          />
                        </div>
                        <Button
                          variant="purple"
                          className={styles.PlusButton}
                          onClick={() => {
                            this.CollectFinger("2");
                          }}
                        >
                          Collect
                        </Button>
                      </div>
                      <div className={styles.fingerPrintBox}>
                        <div className={styles.fingerPrintWrap}>
                          <img
                            className={styles.fingerPrint}
                            src={
                              "data:image/png;base64," +
                              (this.state.fingerData.data.f3Image == undefined
                                ? this.dummyImg
                                : this.state.fingerData.data.f3Image)
                            }
                          />
                        </div>
                        <Button
                          variant="purple"
                          className={styles.PlusButton}
                          onClick={() => {
                            this.CollectFinger("3");
                          }}
                        >
                          Collect
                        </Button>
                      </div>
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} style={{ textAlign: "center" }}>
                  <Button htmlType="submit" size="large">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}
