import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class FingerScanService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
  }

  async getAttendanceData(store, factory) {
    const stateListAPI = new API({ url: this.config.FINGERPRINT_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_ATTENDANCE_DATA,
    });
    let states = [];
    let factoryAppend =
      factory !== null ? "FactoryId=" + parseInt(factory) : "";
    let storeAppend = store !== null ? "StoreID=" + parseInt(store) : "";
    let shouldWait = false;
    await stateListAPI.endpoints[this.endpoint.GET_ATTENDANCE_DATA]
      .getById(
        "?" +
          factoryAppend +
          (factoryAppend !== "" && storeAppend !== "" ? "&" : "") +
          storeAppend
      )
      .then((response) => {
        states = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          shouldWait = true;
        }
        console.log(error);
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        states = this.getAttendanceData(store, factory);
      });
    }
    return Promise.resolve(states);
  }

  async getFIngerTempl(store, factory) {
    const stateListAPI = new API({ url: this.config.FINGERPRINT_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_STORE_FICTORY_ISOTEMP,
    });
    let states = [];
    let factoryAppend =
      factory !== null ? "FactoryId=" + parseInt(factory) : "";
    let storeAppend = store !== null ? "StoreID=" + parseInt(store) : "";
    await stateListAPI.endpoints[this.endpoint.GET_STORE_FICTORY_ISOTEMP]
      .getById(
        "?" +
          factoryAppend +
          (factoryAppend !== "" && storeAppend !== "" ? "&" : "") +
          storeAppend
      )
      .then((response) => {
        states = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return Promise.resolve(states);
  }

 async AddUpdatePunchData(params) {
    const stateListAPI = new API({ url: this.config.FINGERPRINT_URL });
    stateListAPI.createEntity({
      name: this.endpoint.ADD_UPDATE_PUNCHIN,
    });
    let states = [];
    await stateListAPI.endpoints[this.endpoint.ADD_UPDATE_PUNCHIN]
      .create(params)
      .then((response) => {
        states = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return Promise.resolve(states);
  }




  async getAttendanceReport(param) {
    const myApi = new API({ url: this.config.FINGERPRINT_URL });
    myApi.createEntity({
      name: this.endpoint.GET_ATTENDANCE_REPORT,
    }); //var change
    let itemArray = [];
    //method name change
    await myApi.endpoints[this.endpoint.GET_ATTENDANCE_REPORT]
      .getAllPost(param)
      .then(({ data }) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = data;
      })
      .catch((err) => {
        // this.handleError(err);
        console.log(err);
      });
    return Promise.resolve(itemArray);
  }


}
