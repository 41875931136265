import React from "react";
import PackageConfiguration from "../../../components/LKWallet/PackageConfiguration/packageconfiguration.component"

export default class PackageConfigurationScreen extends React.Component {
  render() {
    return (
      <div >
        <PackageConfiguration></PackageConfiguration>
      </div>
    );
  }
}