import React from "react";
import PartialOrderReceived from "../../../components/StoreModule/PartialOrderReceived/partialorderreceivedlist.component";

/**
 * Create PickUp Screen
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 13 July 2020
 */
export default class PartialOrderReceivedScreen extends React.Component {
  render() {
    return <PartialOrderReceived />;
  }
}
