import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class GarmentProcessingService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getGarmentProcessingDetail(TagNumber, FactoryID) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({
      name: this.endpoint.GET_FACTORY_GARMENT_PROCESS_DETAIL,
    });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.GET_FACTORY_GARMENT_PROCESS_DETAIL]
      .getAllPost({
        TagNumber,
        FactoryID,
      })
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getGarmentProcessingDetail(TagNumber, FactoryID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async FactorySetProcessGarment(params) {
    const gatePassApi = new API({ url: this.config.ITEM_FACTORY_URL });
    gatePassApi.createEntity({
      name: this.endpoint.FACTORY_SET_PROCESS_GARMENT,
    });
    let itemArray = [];
    await gatePassApi.endpoints[this.endpoint.FACTORY_SET_PROCESS_GARMENT]
      .getAllPost(params)
      .then((response) => {
        itemArray = response.data})
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.FactorySetProcessGarment(params);
      });
    }
    return Promise.resolve(itemArray);
  }

}
