import React from "react";
import styles from "./refferalbonusconfigurationcomponent.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  Spin,
  notification,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import RefferalBonusConfigurationService from "../../../services/lkrefferalbonusconfigurationservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import {
  RegexLetters,
  MessageLetters,
  RegexInteger,
  MessageNumbers,
} from "../../../shared/validator";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";

const { Search } = Input;
const { Option } = Select;

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class RefferalBonusConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.RefferalBonusConfigurationService =
      new RefferalBonusConfigurationService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();
    this.state = {
      modalLoading: false,
      lkRefferalBonusConfiguration: [],
      lkReferralProgramID: 0,
      programName: "",
      referralBonus: "",
      refereeBonus: "",
      bonusValidityInDays: "",
      msgForReferral: "",
      msgForReferee: "",
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
      iActive: "",
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.REFERRAL_BONUS
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  columns = [
    // {
    //   title: "ServiceID",
    //   dataIndex: "serviceID",
    //   key: "serviceID",
    // },
    {
      title: labels.REFFERAL_PROGRAM_NAME,
      dataIndex: "programName",
      key: "programName",
      sorter: true,
    },

    {
      title: labels.REFFERAL_BONUS_AMOUNT,
      dataIndex: "referralBonus",
      key: "referralBonus",
      sorter: true,
    },
    {
      title: labels.REFEREE_BONUS_AMOUNT,
      dataIndex: "refereeBonus",
      key: "refereeBonus",
      sorter: true,
    },
    {
      title: labels.BONUS_AMOUNT_VALIDITY_DAYS,
      dataIndex: "bonusValidityInDays",
      key: "bonusValidityInDays",
      sorter: true,
    },
    // {
    //   title: "Message For Referral",
    //   dataIndex: "msgForReferral",
    //   key: "msgForReferral",
    //   sorter:true
    // },
    // {
    //   title: "Message For Referee",
    //   dataIndex: "msgForReferee",
    //   key: "msgForReferee",
    //   sorter:true
    // },
    // {
    //   title: "IsActive",
    //   dataIndex: "isActive",
    //   key: "isActive",
    //   sorter:true
    // },

    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title={labels.VIEW}>
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    lkReferralProgramID: record.lkReferralProgramID,
                    programName: record.programName,
                    referralBonus: record.referralBonus,
                    refereeBonus: record.refereeBonus,
                    bonusValidityInDays: record.bonusValidityInDays,
                    msgForReferral: record.msgForReferral,
                    msgForReferee: record.msgForReferee,
                    isActive: record.isActive,
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title={labels.EDIT}>
              <Button
                type="link"
                //  disabled={record.isActive==="false"?true:false}
                onClick={() => {
                  this.setState({
                    showAddPopup: true,
                    lkReferralProgramID: record.lkReferralProgramID,
                    programName: record.programName,
                    referralBonus: record.referralBonus,
                    refereeBonus: record.refereeBonus,
                    bonusValidityInDays: record.bonusValidityInDays,
                    msgForReferral: record.msgForReferral,
                    msgForReferee: record.msgForReferee,
                    isActive: record.isActive,
                  });
                  if (record.isActive === "false") {
                    notification["error"]({
                      message: labels.REFERRAL_BONUS_CONFIGURATION,
                      description:
                        labels.YOU_CAN_ONLY_UPDATE_THE_LAST_ADDED_PROGRAM,
                    });
                  }
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {/* {this.state.userRights.length > 0 && this.state.userRights[0].isDelete == true ?
                    <Popconfirm
                        title="Are you sure ?"
                        onConfirm={() => this.removeItems(record.lkReferralProgramID)}
                        onCancel={() => null}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link">
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Popconfirm>
                    :
            null
          } */}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      lkReferralProgramID: this.state.lkReferralProgramID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      lkReferralProgramID: 0,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      lkReferralProgramID: 0,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
          ? "desc"
          : sorter.order,
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.RefferalBonusConfigurationService.retrieveReferralBonusConfiguration(
      params
    ).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            lkReferralProgramID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          lkRefferalBonusConfiguration: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: labels.REFERRAL_BONUS_CONFIGURATION,
          description: items.message
            ? this.common.Message(items.message)
            : labels.UNABLE_TO_GET_LIST_OF_REFFERAL_BONUS_CONFIGURATION_DATA,
        });
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  // removeItems = (lkReferralProgramID) => {
  //   if (
  //     lkReferralProgramID === "" ||
  //     lkReferralProgramID === null ||
  //     lkReferralProgramID === undefined
  //   ) {
  //     openNotificationWithIcon(
  //       "error",
  //       "Refferal Bonus Configuration",
  //       "Please Select Atleast One Row"
  //     );
  //   } else {
  //     this.RefferalBonusConfigurationService.removeItems(
  //       lkReferralProgramID
  //     ).then((items) => {
  //       if (items.result === "Success") {
  //         openNotificationWithIcon(
  //           "success",
  //           "Refferal Bonus Configuration",
  //           items.message
  //         );
  //         const { pagination } = this.state;
  //         this.fetchItems({
  //           lkReferralProgramID: parseInt(this.state.lkReferralProgramID),
  //           pageIndex: pagination.current,
  //           pageSize: pagination.pageSize,
  //           pagination,
  //         });
  //       } else {
  //         openNotificationWithIcon(
  //           "error",
  //           "Refferal Bonus Configuration",
  //           items.message
  //         );
  //       }
  //     });
  //   }
  // };

  formRef = React.createRef();
  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    this.setState({ modalLoading: true });
    var postData = {
      lkReferralProgramID: this.state.lkReferralProgramID,
      programName: this.state.programName,
      referralBonus: parseInt(this.state.referralBonus),
      refereeBonus: parseInt(this.state.refereeBonus),
      bonusValidityInDays: parseInt(this.state.bonusValidityInDays),
      msgForReferral: null,
      msgForReferee: null,
    };
    this.RefferalBonusConfigurationService.insertUpdateItems(postData).then(
      (items) => {
        if (items.result === "Success") {
          notification["success"]({
            message: labels.REFERRAL_BONUS_CONFIGURATION,
            description: this.common.Message(items.message),
          });
          this.setState({ items: items, showAddPopup: false });
          const { pagination } = this.state;
          this.fetchItems({
            lkReferralProgramID: 0,
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
            // ...filters,
          });
          this.resetFields();
        } else {
          notification["error"]({
            message: labels.REFERRAL_BONUS_CONFIGURATION,
            description: items.message
              ? this.common.Message(items.message)
              : labels.UNABLE_TO_SUBMIT_THE_DATA,
          });
        }
        this.setState({ modalLoading: false });
      }
    );
  };

  handleProgramName = (e) => {
    this.setState({ programName: e.target.value });
  };

  handlereferralBonus = (e) => {
    this.setState({ referralBonus: e.target.value });
  };

  handleRefereeBonus = (e) => {
    this.setState({ refereeBonus: e.target.value });
  };

  handleBonusValidityInDays = (e) => {
    this.setState({ bonusValidityInDays: e.target.value });
  };

  handleMsgForReferral = (e) => {
    this.setState({ msgForReferral: e.target.value });
  };

  handleMsgForReferee = (e) => {
    this.setState({ msgForReferee: e.target.value });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      lkReferralProgramID: 0,
      programName: "",
      referralBonus: "",
      refereeBonus: "",
      bonusValidityInDays: "",
      msgForReferral: "",
      msgForReferee: "",
      isEnabled: false,
      isVisible: false,
    });
  };

  // handleCheckbox=(event)=>{
  //      this.setState({ isAddOn: event.target.checked });
  // }

  render() {
    const {
      programName,
      referralBonus,
      refereeBonus,
      bonusValidityInDays,
      isActive,
      msgForReferee,
      msgForReferral,
      pagination,
      loading,
    } = this.state;
    // let RegexInteger = /^[1-9]+[0-9]*$/g;
    const lkPackageConfigurationItems = this.state.lkRefferalBonusConfiguration;
    const listItems = lkPackageConfigurationItems.map((item, index) => ({
      key: item.lkReferralProgramID,
      lkReferralProgramID: item.lkReferralProgramID,
      programName: item.programName,
      referralBonus: item.referralBonus,
      refereeBonus: item.refereeBonus,
      bonusValidityInDays: item.bonusValidityInDays,
      msgForReferral: item.msgForReferral,
      msgForReferee: item.msgForReferee,
      isActive: item.isActive.toString(),
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title={labels.REFERRAL_BONUS_CONFIGURATION}
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                {labels.ADD_REFFERAL_BONUS_CONFIGURATION}
              </Button>
            ) : null,
            // <Button
            //     type="link"
            //     className={styles.FilterToggle}
            //     onClick={() => {
            //         this.setState({ showfilterBox: !this.state.showfilterBox });
            //     }}
            // >
            //     {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //     <FontAwesomeIcon
            //         icon={faChevronUp}
            //         className={
            //             this.state.showfilterBox
            //                 ? styles.FilterToggleIcon
            //                 : styles.FilterToggleIconRotated
            //         }
            //     />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          // rowSelection={{
          //     type: this.selectionType,
          //     ...this.rowSelection,
          // }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}></TableActions>
        {/* {this.state.userRights.length > 0 && this.state.userRights[0].isDelete == true ?
                 <Popconfirm
                        title="Are you sure ?"
                        onConfirm={this.deleteSelected}
                        onCancel={() => null}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button variant="transparentGreyOutline" >Delete Selected</Button>
                </Popconfirm>
                :null} */}
        <Modal
          title={
            this.state.lkReferralProgramID === 0
              ? labels.ADD_REFFERAL_BONUS_CONFIGURATION
              : this.state.isEnabled
              ? labels.VIEW_REFFERAL_BONUS_CONFIGURATION
              : labels.EDIT_REFFERAL_BONUS_CONFIGURATION
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              ref={this.formRef}
              initialValues={{
                programName,
                referralBonus,
                refereeBonus,
                bonusValidityInDays,
              }}
            >
              {/* ,msgForReferral,msgForReferee}}> */}
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label={labels.REFFERAL_PROGRAM_NAME}
                    name="programName"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_REFFERAL_PROGRAM_NAME,
                      },
                      // {
                      //   pattern: RegexLetters,
                      //   message: labels.PLEASE_ENTER_ONLY_ALPHABETS,
                      // },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_REFFERAL_PROGRAM_NAME}
                      disabled={this.state.isEnabled}
                      onChange={this.handleProgramName}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label={labels.REFFERAL_BONUS_AMOUNT}
                    name="referralBonus"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_REFERRAL_BONUS_AMOUNT,
                      },
                      {
                        pattern: RegexInteger,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_REFERRAL_BONUS_AMOUNT}
                      disabled={this.state.isEnabled}
                      prefix="₹"
                      maxLength={20}
                      onChange={this.handlereferralBonus}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label={labels.REFEREE_BONUS_AMOUNT}
                    name="refereeBonus"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_REFEREE_BONUS_AMOUNT,
                      },
                      {
                        pattern: RegexInteger,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_REFEREE_BONUS_AMOUNT}
                      disabled={this.state.isEnabled}
                      prefix="₹"
                      maxLength={20}
                      onChange={this.handleRefereeBonus}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label={labels.BONUS_AMOUNT_VALIDITY_DAYS}
                    name="bonusValidityInDays"
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_ENTER_BONUS_AMOUNT_VALIDITY_DAYS,
                      },
                      {
                        pattern: RegexInteger,
                        message:
                          labels.PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9,
                      },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_BONUS_AMOUNT_VALIDITY_DAYS}
                      disabled={this.state.isEnabled}
                      maxLength={3}
                      onChange={this.handleBonusValidityInDays}
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={12}>
                                <Form.Item label="Message For Referral"
                                    name="msgForReferral"
                                    rules={[{ required: true, message: 'Please Enter Message For Referral !' }]}>
                                    <Input.TextArea
                                    placeholder="Enter Message For Referral"
                                    disabled={this.state.isEnabled}
                                    onChange={this.handleMsgForReferral}
                                    />
                                </Form.Item>                                 
                            </Col>
                            <Col xs={24}>
                                <Form.Item label="Message For Referee"
                                    name="msgForReferee"
                                    rules={[{ required: true, message: 'Please Enter Message For Referee !' }]}>
                                    <Input.TextArea
                                    placeholder="Enter Message For Referee"
                                    disabled={this.state.isEnabled}
                                    onChange={this.handleMsgForReferee}
                                    />
                                </Form.Item>                                 
                            </Col> */}
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>

                        {this.state.lkReferralProgramID === 0 ? (
                          <Popconfirm
                            title={
                              labels.ARE_YOU_SURE_YOU_WANT_TO_CREATE_THIS_PROGRAM_AS_PREVIOUS_PROGRAMS_WILL_GET_DEACTIVATED
                            }
                            onConfirm={() =>
                              this.formRef.current
                                .validateFields()
                                .then(this.handlePopupAdd)
                            }
                            onCancel={() => null}
                            okText={labels.YES}
                            cancelText={labels.NO}
                          >
                            <Button
                              htmlType="submit"
                              variant="purple"
                              disabled={
                                this.state.lkReferralProgramID != 0
                                  ? this.state.isActive === "false"
                                    ? true
                                    : false
                                  : false
                              }
                            >
                              {this.state.lkReferralProgramID === 0
                                ? labels.ADD
                                : labels.UPDATE}
                            </Button>
                          </Popconfirm>
                        ) : (
                          <Button
                            htmlType="submit"
                            variant="purple"
                            disabled={
                              this.state.lkReferralProgramID != 0
                                ? this.state.isActive === "false"
                                  ? true
                                  : false
                                : false
                            }
                          >
                            {this.state.lkReferralProgramID === 0
                              ? labels.ADD
                              : labels.UPDATE}
                          </Button>
                        )}
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
