import React from "react";
import styles from "./chartscomponent.module.css";
import {
  Row,
  Col,
  Typography,
  // Select,
  notification,
  Spin,
} from "antd";
import Box from "../../Common/Box/box";
import moment from "moment";
// import BarChartImg from "../../../images/Dashboard/bar.png";
// import RingChart from "../../../images/Dashboard/ring.png";
// import DoubleBarChart from "../../../images/Dashboard/double.png";
import StoreDashboard from "../../../services/storedashboardservice";
import {
  ResponsiveContainer,
  BarChart,
  // PieChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  // Cell,
  // Pie,
  Bar,
  // Customized,
  // Sector,
} from "recharts";
import { labels } from "../../../shared/language";
import Commmon from "../../../services/common";
import Common from "../../../services/common";
/**
 * Charts Row as a part of dashboard screen.
 * Bar chart, ring chart and double bar chart
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 15 June 2020
 */
export default class Charts extends React.Component {
  storeDashboard = new StoreDashboard();
  common = new Common();
  state = {
    fullLoading: false,
    pieActiveIndex: 0,
    dashboardChartData: [],
    doubleBarChartData: [],
    dashboardSplitedData: [],
    dashboardPickupData: [],
  };

  componentWillMount() {
    this.GetStoreDashBoardPickUpData();
    this.GetStoreDashBoardDeliveryRequestData();
    this.GetStoreSplitedOrderDashboard();
  }
  GetStoreDashBoardDeliveryRequestData() {
    this.props.toggleLoading(true);
    this.storeDashboard
      .GetStoreDashBoardDeliveryRequestData(
        parseInt(localStorage.getItem("branchID"))
      )
      .then((response) => {
        if (response.statusCode === 200) {
          let res = [];
          let dashboardChartres = [];
          response.data.forEach((element) => {
            res = {
              // date: element.calDate,
              // "Pending Deliveries By Delivery Person": element.pendingDeliveriesByDeliveryPerson,
              // "Pending Delivery of Walkin": element.pendingDeliveryofWalkin

              date: moment(element.calDate).format("D"),
              "Delivery Person": element.pendingDeliveriesByDeliveryPerson,
              "Walk-in": element.pendingDeliveryofWalkin,
            };
            dashboardChartres.push(res);
          });

          this.setState({
            dashboardChartData: dashboardChartres,
          });
        } else {
          notification["error"]({
            message: labels.DASHBOARD,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_ORDERS_DUE_FOR_DELIVERY,
          });
        }
        this.props.toggleLoading(false);
      });
  }

  GetStoreDashBoardPickUpData() {
    this.props.toggleLoading(true);
    this.storeDashboard
      .GetStoreDashBoardPickUpData(parseInt(localStorage.getItem("branchID")))
      .then((response) => {
        if (response.statusCode === 200) {
          let res = [];
          let dashboardPickupChartres = [];
          response.data.forEach((element) => {
            res = {
              date: moment(element.calDate).format("D"),
              "Pending Pickups": element.pendingPickups,
            };
            dashboardPickupChartres.push(res);
          });

          this.setState({
            dashboardPickupData: dashboardPickupChartres,
          });
        } else {
          notification["error"]({
            message: labels.DASHBOARD,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_PENDING_PICKUPS,
          });
        }
        this.props.toggleLoading(false);
      });
  }

  GetStoreSplitedOrderDashboard() {
    this.props.toggleLoading(true);
    this.storeDashboard
      .GetStoreDashBoardSplitOrderData(
        parseInt(localStorage.getItem("branchID"))
      )
      .then((response) => {
        if (response.statusCode === 200) {
          let res = [];
          let dashboardSplitedChartres = [];
          response.data.forEach((element) => {
            res = {
              date: moment(element.calDate).format("D"),
              orders: parseInt(element.splitOrderNumberCount),
            };
            dashboardSplitedChartres.push(res);
          });
          this.setState({
            dashboardSplitedData: dashboardSplitedChartres,
          });
        } else {
          notification["error"]({
            message: labels.DASHBOARD,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_LOAD_ORDERS_PENDING_TO_TRANSFER_OUT_TO_FACTORY,
          });
        }
        this.props.toggleLoading(false);
      });
  }

  render() {
    return (
      <Spin spinning={this.state.fullLoading}>
        <Row gutter={28} className={styles.ChartsContainer}>
          <Col xs={24} lg={8}>
            <Box sub className={styles.ChartsWrapper}>
              <div className={styles.ChartsTitleWrap}>
                <Typography.Title level={4}>
                  {labels.PENDING_TO_TRANSFER_OUT_TO_FACTORY}
                </Typography.Title>
              </div>
              <ResponsiveContainer height={250}>
                <BarChart data={this.state.dashboardSplitedData} mirror={true}>
                  <XAxis
                    dataKey="date"
                    tickLine={false}
                    axisLine={{ strokeWidth: 0.5, stroke: "#666666" }}
                    padding={{ right: 1 }}
                    label={{
                      value: labels.DATE,
                      position: "bottom",
                      offset: -4,
                    }}
                  />
                  <YAxis
                    dataKey="orders"
                    tickLine={false}
                    axisLine={{ strokeWidth: 0.5, stroke: "#666666" }}
                    width={35}
                    // ticks={[0, 10, 20, 30, 40]}
                    allowDecimals={false}
                    padding={{ top: 15 }}
                    label={{
                      value: labels.NO_OF_ORDERS,
                      position: "left",
                      offset: 0,
                      angle: -90,
                    }}
                  />
                  <Tooltip
                    allowEscapeViewBox={false}
                    isAnimationActive={false}
                  />
                  <Bar dataKey="orders" fill="#7E13D5" barSize={12} />
                </BarChart>
              </ResponsiveContainer>
              {/* <img src={BarChartImg} alt="bar" /> */}
            </Box>
          </Col>

          <Col xs={24} lg={8}>
            <Box sub className={styles.ChartsWrapper}>
              <div className={styles.ChartsTitleWrap}>
                <Typography.Title level={4}>
                  {labels.PENDING_PICKUPS}
                </Typography.Title>
              </div>
              <ResponsiveContainer height={250}>
                <BarChart data={this.state.dashboardPickupData} mirror={true}>
                  <XAxis
                    dataKey="date"
                    tickLine={false}
                    axisLine={{ strokeWidth: 0.5, stroke: "#666666" }}
                    padding={{ right: 1 }}
                    label={{
                      value: labels.DATE,
                      position: "bottom",
                      offset: -4,
                    }}
                  />
                  <YAxis
                    dataKey="Pending Pickups"
                    tickLine={false}
                    axisLine={{ strokeWidth: 0.5, stroke: "#666666" }}
                    width={35}
                    // ticks={[0, 10, 20, 30, 40]}
                    allowDecimals={false}
                    padding={{ top: 15 }}
                    label={{
                      value: labels.NO_OF_ORDERS,
                      position: "left",
                      offset: 0,
                      angle: -90,
                    }}
                  />
                  <Tooltip
                    allowEscapeViewBox={false}
                    isAnimationActive={false}
                  />
                  <Bar dataKey="Pending Pickups" fill="#7E13D5" barSize={12} />
                </BarChart>
              </ResponsiveContainer>
              {/* <img src={BarChartImg} alt="bar" /> */}
            </Box>
          </Col>

          <Col xs={24} lg={8}>
            <Box sub className={styles.ChartsWrapper}>
              <div className={styles.ChartsTitleWrap}>
                <Typography.Title level={4}>
                  {labels.ORDERS_DUE_FOR_DELIVERY}
                </Typography.Title>
              </div>
              <ResponsiveContainer height={250}>
                <BarChart
                  data={this.state.dashboardChartData}
                  mirror={true}
                  barGap={1}
                >
                  <XAxis
                    dataKey="date"
                    tickLine={false}
                    axisLine={{ strokeWidth: 0.5, stroke: "#666666" }}
                    padding={{ right: 1 }}
                    label={{
                      value: labels.DATE,
                      position: "bottom",
                      offset: -4,
                    }}
                  />
                  <YAxis
                    // dataKey="Delivery Person"
                    tickLine={false}
                    axisLine={{ strokeWidth: 0.5, stroke: "#666666" }}
                    width={35}
                    // ticks={[0, 10, 20, 30, 40]}
                    allowDecimals={false}
                    padding={{ top: 15 }}
                    label={{
                      value: labels.NO_OF_ORDERS,
                      position: "left",
                      offset: 0,
                      angle: -90,
                    }}
                  />
                  <Tooltip
                    allowEscapeViewBox={false}
                    isAnimationActive={false}
                  />

                  <Legend
                    layout="vertical"
                    align="center"
                    verticalAlign="middle"
                    wrapperStyle={{ left: 50, position: "absolute", top: 0 }}
                    iconType="square"
                    iconSize={12}
                  />
                  <Bar dataKey="Delivery Person" fill="#7E13D5" barSize={7} />
                  <Bar dataKey="Walk-in" fill="#FE1D7F" barSize={7} />
                </BarChart>
              </ResponsiveContainer>
              {/* <img src={DoubleBarChart} alt="bar" /> */}
            </Box>
          </Col>
        </Row>
      </Spin>
    );
  }
}
