import React from "react";
import CustomerMaster from "../../../components/Master/Customer/customermaster.component";
/**
 * Customer Master Screen
 *
 * @author Niraj Gohel
 * @created 25 June 2020
 */
export default class CustomerMasterScreen extends React.Component {
  render() {
    return (
      <div>
        <CustomerMaster />
      </div>
    );
  }
}
