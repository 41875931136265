import React from "react";
import UserService from "../../../../services/userservice";
import { Row, Col, Form, Input, Radio } from "antd";
import styles from "./createuser.module.css";
import Button from "../../../Common/Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { RegexLetters, MessageLetters } from "../../../../shared/validator";

let map;
// let bounds = new window.google.maps.LatLngBounds();
let coordinates = [];
var drawingManager;
var selectedShape;
var allPolygonNew = [];
var allPolygonOld = [];
var newShape = "";
var selectedCoordinates = "";
var geoCoordinate = "";
/**
 * A form as a part of create user - General Section/Step
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 2 June 2020
 *
 * @module User
 */
export default class UserContact extends React.Component {
  constructor(props) {
    super(props);
    this.userservice = new UserService();
    this.state = {
      isPolicyVerified: "",
      geoFencing: "",
    };
    this.renderCoordinate = this.renderCoordinate.bind(this);
    // this.initMap = this.initMap.bind(this);
    this.deleteSelectedShape = this.deleteSelectedShape.bind(this);
  }
  componentDidMount() {
    // this.initMap();
  }

  // Geo-Fencing Map

  initMap = () => {
    if (
      localStorage.getItem("geoFencing") !== "" &&
      localStorage.getItem("geoFencing") !== undefined &&
      localStorage.getItem("geoFencing") !== null
    ) {
      selectedCoordinates = localStorage.getItem("geoFencing");
    }
    if (this.props.mode === "add") {
      selectedCoordinates = "";
    }
    map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 12.9716, lng: 77.5946 },
      zoom: 10,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER,
      },
      scrollwheel: false,
      streetViewControl: false,
      mapTypeControl: false,
      mapTypeId: "roadmap",
    });
    var myPolygon = new window.google.maps.Polygon({
      //paths: this.buildCoordinates("20.385816,77.281992 19.694305,77.682993 20.576216,78.446543"),
      paths: this.buildCoordinates(selectedCoordinates),
      description: ["1", "0", "", ""],
      IsChanged: "0",
      draggable: true,
      editable: true,
      strokeColor: "#1E90FF",
      strokeOpacity: 0.8,
      strokeWeight: 0,
      fillColor: "#66ccff",
      fillOpacity: 0.45,
    });

    myPolygon.setMap(map);
    allPolygonOld.push(myPolygon);
    window.google.maps.event.addListener(myPolygon, "click", function () {
      selectedShape = myPolygon;
      window.google.maps.event.addListener(
        myPolygon.getPath(),
        "insert_at",
        this.mapEditing
      );
      window.google.maps.event.addListener(
        myPolygon.getPath(),
        "set_at",
        this.mapEditing
      );
    });

    drawingManager = new window.google.maps.drawing.DrawingManager({
      markerOptions: {
        draggable: true,
      },
      drawingControl: true,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        strokeWeight: 0,
        fillOpacity: 0.45,
        editable: true,
        description: [],
        strokeColor: "#1E90FF",
        fillColor: "#1E90FF",
        IsChanged: "0",
        draggable: true,
      },

      map: map,
    });

    window.google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      function (e) {
        selectedCoordinates = "";
        var firstPointCoordinates = "";
        newShape = e.overlay;
        newShape.type = e.type;

        newShape.description = ["1", "0", "", ""];
        if (e.type !== window.google.maps.drawing.OverlayType.MARKER) {
          for (var i = 0; i < newShape.getPath().getLength(); i++) {
            selectedCoordinates +=
              newShape.getPath().getAt(i).toUrlValue(6) + " ";
            geoCoordinate = selectedCoordinates;
            if (i === 0) {
              firstPointCoordinates = selectedCoordinates;
            }
            if (i === newShape.getPath().getLength() - 1) {
              selectedCoordinates += firstPointCoordinates;
            }
          }
          // localStorage.setItem("geoFencing", selectedCoordinates);
          selectedShape = newShape;
          allPolygonNew.push(newShape);
        }
      }
    );

    window.google.maps.event.addListener(
      drawingManager,
      "drawingmode_changed",
      this.clearSelection()
    );
    window.google.maps.event.addListener(map, "click", this.clearSelection());
  };

  deleteSelectedShape = () => {
    var shape = selectedShape;
    if (shape !== undefined && shape.type !== "marker") {
      window.google.maps.event.clearListeners(
        selectedShape.getPath(),
        "insert_at"
      );
      window.google.maps.event.clearListeners(
        selectedShape.getPath(),
        "set_at"
      );
      selectedShape.setEditable(false);
      selectedShape = null;
      shape.setEditable(true);
    }
    selectedShape = shape;

    if (selectedShape) {
      if (selectedShape.description[0] !== "1") {
        for (var i = 0; i < allPolygonNew.length; i++) {
          if (
            allPolygonNew[i].description[1] === selectedShape.description[1]
          ) {
            allPolygonNew.splice(i, 1);
            selectedShape.setMap(null);
          }
        }
      } else {
        selectedShape.description[2] = "D";
        selectedShape.setMap(null);
      }
    }
    // localStorage.setItem("geoFencing", "");
  };

  buildCoordinates(cordinates) {
    var finalData = [];
    var grouped = cordinates.split(" ");
    grouped.forEach(function (item, i) {
      let a = item.trim().split(",");
      finalData.push(new window.google.maps.LatLng(a[0], a[1]));
    });

    return finalData;
  }

  clearSelection() {
    if (selectedShape) {
      window.google.maps.event.clearListeners(
        selectedShape.getPath(),
        "insert_at"
      );
      window.google.maps.event.clearListeners(
        selectedShape.getPath(),
        "set_at"
      );
      selectedShape.setEditable(false);
      selectedShape = null;
    }
  }

  mapEditing() {
    if (selectedShape && selectedShape.IsChanged === "0") {
      selectedShape.IsChanged = "1";
    }
  }

  renderCoordinate(paths) {
    coordinates = [];
    let position = 0;
    paths.map((location) => {
      if (position % 10 === 0) {
        coordinates.push({ lat: location[1], lng: location[0] });
      }
      position++;
      return true;
    });
  }

  render() {
    const disabled = this.props.mode === "view";
    return (
      <Form
        layout="vertical"
        name="Contact"
        initialValues={this.props.values}
        ref={this.props.instance}
        onFinish={this.onFinishGeneralForm}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={12}>
            <Form.Item label="Home Address" name="homeAddress">
              <Input.TextArea
                rows={2}
                placeholder="Enter Home Address"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={12}>
            <Form.Item label="Permanent Address" name="permanentAddress">
              <Input.TextArea
                rows={2}
                placeholder="Enter Permanent Address"
                disabled={disabled}
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Family Name 1"
              name="familyName1"
              rules={[
                {
                  pattern: RegexLetters,
                  message: MessageLetters,
                },
              ]}
            >
              <Input placeholder="Enter Family Name 1" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Family ContactNo 1"
              name="familyContactNo1"
              rules={[
                { required: false },
                {
                  pattern: "[6789][0-9]{9}",
                  message: "Please Enter Valid Family Contact No 1",
                },
              ]}
            >
              <Input
                placeholder="Enter Family Contact No 1"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Family Name 2"
              name="familyName2"
              rules={[
                {
                  pattern: RegexLetters,
                  message: MessageLetters,
                },
              ]}
            >
              <Input placeholder="Enter Family Name 2" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Family ContactNo 2"
              name="familyContactNo2"
              rules={[
                { required: false },
                {
                  pattern: "[6789][0-9]{9}",
                  message: "Please Enter Valid Family Contact No 2",
                },
              ]}
            >
              <Input
                placeholder="Enter Family Contact No 2"
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item label="Police Verified" name="isPoliceVerified">
              <Radio.Group disabled={disabled}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {/* <Col xs={24} md={12} xl={24}>
            Geo-Fencing */}
          {/* {this.props.mode !="view" ? <Popconfirm
            title="Are you sure ?"
            onConfirm={this.deleteSelectedShape}
            onCancel={() => null}
            okText="Yes"
            cancelText="No"
          >
          <Button type="link">
              <FontAwesomeIcon icon={faTrash} />
          </Button>
          </Popconfirm> : null} */}
          {/* <Button
              type="link"
              id="delete-button"
              onClick={this.deleteSelectedShape}
              disabled={disabled}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button> */}
          {/* <Form.Item label=" " name="geoFencing" getValueFromEvent= { e => e.target.value.toUpperCase().trim()}>
              
            </Form.Item > */}
          {/* <div className={styles.maps} id="map" disabled={disabled}></div> */}
          {/* <Button
              variant="transparentGreyOutline"
              shape="round"
              size="large" id="delete-button" onClick={this.deleteSelectedShape}>
              Delete
            </Button> */}
          {/* </Col> */}
          <Col> </Col>
          <Col xs={24} className={styles.ButtonContainer}>
            <Button
              variant="transparentGreyOutline"
              className={styles.CancelButton}
              shape="round"
              size="large"
              onClick={this.props.backFunction}
            >
              Previuos
            </Button>
            <Button
              htmlType="submit"
              variant="purple"
              shape="round"
              size="large"
              onClick={this.props.nextFunction}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}
