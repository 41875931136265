import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../../Common/Styles/master.module.css";
import { Steps, PageHeader, Form, Spin, notification } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import GeneralForm from "./garment.editpreqcstore";
import ROUTES from "../../../../routes";
import PreQCResponseList from "../../../../services/storepreqcresponselistservice";
import jwt from "jsonwebtoken";
import { labels } from "../../../../shared/language";
const { Step } = Steps;

/**
 * Add Factory Form as a part of Factory Master Module
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 *
 * @module Master
 */
class AddFactory extends React.Component {
  /**
   * State Variables
   * @var factoryID : ID of factory 0 if this is to add factory
   * @var activeStep : holds active form step
   * @var stepDirection : holds direction of steps horizontal/vertical
   * @var stepSize : size of steps
   * @var generalFormValues : values submitted through generalForm
   * @var contactFormValues : values submitted through contactForm
   */
  state = {
    factoryID: 0,
    activeStep: 0,
    fullLoading: false,
    stepsDirection: "vertical",
    stepsSize: "default",
    generalFormValues: {},
    contactFormValues: {},
    mode: "add",
  };
  preQCService = new PreQCResponseList();
  /**
   * A function to responsively set direction of the steps
   */
  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "small" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };

  /**
   * bind the setStepDirection to the resize event of windows
   */
  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    if (
      [ROUTES.EDIT_PRE_QC_STORE, ":qcId"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });
      this.getQCDetails(this.props.location.state.qcID);
    } else if (
      [ROUTES.VIEW_PRE_QC_STORE, ":qcId"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.getQCDetails(this.props.location.state.qcID);
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
    setTimeout(() => {
      this.setState({ fullLoading: false });
    }, 1500);
  }

  getQCDetails = (id) => {
    // this.corporateService.getCorporate(id).then((response) => {
    //   if (
    //     response.statusCode === 200 &&
    //     response.data.corporateID === parseInt(id)
    //   ) {
    //     this.setState({ corporateID: response.data.corporateID });
    //     this.setState({
    //       generalFormValues: {
    //         corporateID: response.data.corporateID,
    //         corporateName: response.data.corporateName,
    //         gstNo: response.data.gstNo,
    //         poNo: response.data.poNo,
    //         poValidity: response.data.poValidity
    //           ? moment(response.data.poValidity, "M/DD/YYYY hh:mm:ss A")
    //           : "",
    //         sez: response.data.sez,
    //         billingCycle: response.data.billingCycleID.toString(),
    //         paymentTerms: response.data.paymentTerms,
    //         isDeliveryCharge: response.data.isDeliveryCharge,
    //         pickupFrequency: response.data.pickupFrequencyID.toString(),
    //       },
    //     });
    //     this.setState({
    //       contactFormValues: {
    //         billingAddress: response.data.billingAddress,
    //         serviceAddress: response.data.serviceAddress,
    //         emailID: response.data.emailID,
    //         accManager: response.data.accManager,
    //         accManagerContact: response.data.accManagerContact,
    //       },
    //     });
    //     this.setState({ fullLoading: false });
    //     this.setState({ activeStep: -1 });
    //     this.setState({ activeStep: 0 });
    //   } else {
    //     notification["error"]({
    //       message: response.message
    //         ? response.message
    //         : "Unable to get corporate data",
    //       description: "Redirecting....",
    //       onClose: () => {
    //         this.props.history.goBack();
    //       },
    //     });
    //   }
    // });
  };
  addUpdateCorporate = () => {
    this.setState({ fullLoading: true });
    // let postData = {
    //   orderGarmentID: parseInt(this.state.generalFormValues.orderGarmentID),
    //   qcStatus: this.state.generalFormValues.qcStatus,
    //   qcReason: this.state.generalFormValues.qcReason.join(","),
    //   remark: this.state.generalFormValues.qcRemarks,
    //   qcPendingFrom: 2,
    //   qcImage1: null,
    //   qcImage2: null,
    //   qcImage3: null,
    //   userID: parseInt(
    //     jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
    //       .unique_name
    //   ),
    // };
    let postData = new FormData();
    postData.append(
      "orderGarmentID",
      parseInt(this.state.generalFormValues.orderGarmentID)
    );
    postData.append("qcStatus", this.state.generalFormValues.qcStatus);
    postData.append(
      "qcReason",
      this.state.generalFormValues.qcReason.join(",")
    );
    postData.append("remark", this.state.generalFormValues.qcRemarks);
    postData.append("qcPendingFrom", 2);
    postData.append(
      "userID",
      parseInt(
        jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
          .unique_name
      )
    );
    this.preQCService.OrderGarmentQCRequest(postData).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.PRE_QC_RESPONSES,
          description: labels.QC_RESPONSE_SUBMITTED_SUCCESSFULLY,
          onClose: () => {
            this.props.history.goBack();
          },
        });
      } else {
        notification["error"]({
          message: labels.PRE_QC_RESPONSES,
          description: labels.UNABLE_TO_SUBMIT_QC_RESPONSE,
        });
      }
    });
  };
  generalForm = React.createRef();
  contactForm = React.createRef();
  handleSubmit = () => {
    const generalValidator = this.generalForm.current.validateFields();
    Promise.allSettled([generalValidator]).then((responses) => {
      if (responses[0].status === "rejected") {
        this.setState({ activeStep: 0 });
      } else if (responses[0].status === "fulfilled") {
        this.setState(
          {
            generalFormValues: responses[0].value,
          },
          () => {
            this.addUpdateCorporate();
          }
        );
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          {/* {JSON.stringify(this.state)} */}
          {/**
           * Page Header Component from Ant Design
           * Please check below link for more options
           * https://ant.design/components/page-header/
           * https://ant.design/components/page-header/#API
           *
           */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? labels.EDIT
                : this.state.mode === "view"
                ? labels.VIEW
                : labels.ADD,
              labels.GARMENT_FOR_QC,
            ].join(" ")}
          />

          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              {/**
               * Steps Component from Ant Design
               * Please check below link for more options
               * https://ant.design/components/steps/
               * https://ant.design/components/steps/#API
               *
               */}
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => this.setState({ activeStep: current })}
              >
                <Step
                  title={labels.GARMENT}
                  description={labels.SELECT_SCAN_GARMENT}
                />

                {/* <Step
                  title="Communication History"
                  description="History of this garment"
                /> */}
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider>
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div>
                  <React.Fragment>
                    <GeneralForm
                      values={this.state.generalFormValues}
                      backFunction={() => this.props.history.goBack()}
                      nextFunction={() => this.handleSubmit()}
                      isActive={this.state.activeStep === 0}
                      instance={this.generalForm}
                      mode={this.state.mode}
                    />
                    {/* <ContactForm
                      values={this.state.contactFormValues}
                      backFunction={() =>
                        this.setState({ activeStep: this.state.activeStep - 1 })
                      }
                      nextFunction={this.handleSubmit}
                      isActive={this.state.activeStep === 1}
                      instance={this.contactForm}
                      mode={this.state.mode}
                    /> */}
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(AddFactory);
