import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class CustomerService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }
  async getAllCustomers(
    index = 0,
    size = 0,
    column = null,
    order = null,
    search = null
  ) {
    const allCustomersAPI = new API({ url: this.config.ITEM_URL });
    allCustomersAPI.createEntity({ name: this.endpoint.GET_ALL_CUSTOMERS });
    let customers = [];
    let payload = {
      customerID: 0,
      firstName: "",
      lastName: "",
      mobileNo: "",
      emailID: "",
      pageIndex: index,
      pageSize: size,
      sortColumn: column,
      sortOrder: order,
      commonSearch: search,
    };
    await allCustomersAPI.endpoints[this.endpoint.GET_ALL_CUSTOMERS]
      .getAllPost(payload)
      .then((response) => {
        customers = response.data;
      })
      .catch((err) => {
        customers = this.common.handleError(err);
      });
    if (customers.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        customers = this.getAllCustomers(index, size, column, order, search);
      });
    }
    return Promise.resolve(customers);
  }
  async getCustomerTypes() {
    const customerTypesAPI = new API({ url: this.config.DROPDOWN_URL });
    customerTypesAPI.createEntity({ name: this.endpoint.GET_CUSTOMER_TYPES });
    let returnVal = [];
    await customerTypesAPI.endpoints[this.endpoint.GET_CUSTOMER_TYPES]
      .getAll()
      .then((response) => (returnVal = response.data))
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getCustomerTypes();
      });
    }
    return Promise.resolve(returnVal);
  }
  async getBillingCycle() {
    const billingCycleAPI = new API({ url: this.config.DROPDOWN_URL });
    billingCycleAPI.createEntity({ name: this.endpoint.GET_BILLING_CYCLES });
    let returnVal = [];
    await billingCycleAPI.endpoints[this.endpoint.GET_BILLING_CYCLES]
      .getAll()
      .then((response) => (returnVal = response.data))
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getBillingCycle();
      });
    }
    return Promise.resolve(returnVal);
  }
  async getGenders() {
    const genderAPI = new API({ url: this.config.DROPDOWN_URL });
    genderAPI.createEntity({ name: this.endpoint.GET_GENDERS });
    let returnVal = [];
    await genderAPI.endpoints[this.endpoint.GET_GENDERS]
      .getAll()
      .then((response) => (returnVal = response.data))
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getGenders();
      });
    }
    return Promise.resolve(returnVal);
  }

  async getCorporate() {
    const genderAPI = new API({ url: this.config.DROPDOWN_URL });
    genderAPI.createEntity({ name: this.endpoint.GET_CORPORATE });
    let returnVal = [];
    await genderAPI.endpoints[this.endpoint.GET_CORPORATE]
      .getAll()
      .then((response) => (returnVal = response.data))
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getCorporate();
      });
    }
    return Promise.resolve(returnVal);
  }

  async getCustomer(ID) {
    const customerAPI = new API({ url: this.config.ITEM_URL });
    customerAPI.createEntity({ name: this.endpoint.GET_CUSTOMER });
    let customer = [];
    const idParams = "?CustomerID=" + ID;
    await customerAPI.endpoints[this.endpoint.GET_CUSTOMER]
      .getById(idParams)
      .then((response) => (customer = response.data))
      .catch((err) => {
        customer = this.common.handleError(err);
      });
    if (customer.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        customer = this.getCustomer(ID);
      });
    }
    return Promise.resolve(customer);
  }

  async addUpdateCustomer(customer) {
    const addUpdateAPI = new API({ url: this.config.ITEM_URL });
    addUpdateAPI.createEntity({ name: this.endpoint.ADD_UPDATE_CUSTOMER });
    let returnVal = [];
    const params = { ...customer, sourceFrom: 1 };
    await addUpdateAPI.endpoints[this.endpoint.ADD_UPDATE_CUSTOMER]
      .create(params)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.addUpdateCustomer(customer);
      });
    }
    return Promise.resolve(returnVal);
  }

  async addUpdateAddress(address) {
    const addUpdateAPI = new API({ url: this.config.ITEM_URL });
    addUpdateAPI.createEntity({ name: this.endpoint.ADD_UPDATE_ADDRESS });
    let returnVal = [];
    const params = {
      ...address,
      sourceFrom: 1,
    };
    await addUpdateAPI.endpoints[this.endpoint.ADD_UPDATE_ADDRESS]
      .getAllPost(params)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.addUpdateAddress(address);
      });
    }
    return Promise.resolve(returnVal);
  }

  async getCustomerAddresses(id) {
    const getAddressAPI = new API({ url: this.config.ITEM_URL });
    getAddressAPI.createEntity({ name: this.endpoint.GET_CUSTOMER_ADDRESSES });
    let addresses = [];
    const idParam = "?CustomerId=" + id;
    await getAddressAPI.endpoints[this.endpoint.GET_CUSTOMER_ADDRESSES]
      .getOne(idParam)
      .then((response) => (addresses = response.data))
      .catch((err) => {
        addresses = this.common.handleError(err);
      });
    if (addresses.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        addresses = this.addUpdateAddress(id);
      });
    }
    return Promise.resolve(addresses);
  }

  async getStateList() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GETSTATELIST });
    let states = [];
    await stateListAPI.endpoints[this.endpoint.GETSTATELIST]
      .getAll()
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getStateList();
      });
    }
    return Promise.resolve(states);
  }

  async getCityListByState(id) {
    const getCitiesAPI = new API({ url: this.config.DROPDOWN_URL });
    getCitiesAPI.createEntity({ name: this.endpoint.GET_CITIES_BY_STATE });
    let cities = [];
    let idParams = {
      params: {
        StateID: id,
      },
    };
    await getCitiesAPI.endpoints[this.endpoint.GET_CITIES_BY_STATE]
      .getAll(idParams)
      .then((response) => {
        cities = response.data;
      })
      .catch((err) => {
        cities = this.common.handleError(err);
      });
    if (cities.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        cities = this.getCityListByState(id);
      });
    }
    return Promise.resolve(cities);
  }

  async sendMobileOTP(id) {
    const sendOTPAPI = new API({ url: this.config.LAUNDRETKART_AUTH });
    sendOTPAPI.createEntity({ name: this.endpoint.SEND_OTP });
    let otp = [];
    let idParams = {
      customerID: id,
    };
    await sendOTPAPI.endpoints[this.endpoint.SEND_OTP]
      .getAllPost(idParams)
      .then((response) => {
        otp = response.data;
      })
      .catch((err) => {
        otp = this.common.handleError(err);
      });
    if (otp.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        otp = this.sendMobileOTP(id);
      });
    }
    return Promise.resolve(otp);
  }

  async verifyMobileOTP(postData) {
    const sendOTPAPI = new API({ url: this.config.LAUNDRETKART_AUTH });
    sendOTPAPI.createEntity({ name: this.endpoint.VERIFY_OTP });
    let otp = [];
    await sendOTPAPI.endpoints[this.endpoint.VERIFY_OTP]
      .getAllPost(postData)
      .then((response) => {
        otp = response.data;
      })
      .catch((err) => {
        otp = this.common.handleError(err);
      });
    if (otp.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        otp = this.verifyMobileOTP(postData);
      });
    }
    return Promise.resolve(otp);
  }

  async deleteCustomerAddress(id) {
    const getAddressAPI = new API({ url: this.config.ITEM_URL });
    getAddressAPI.createEntity({ name: this.endpoint.DELETE_ADDRESS });
    let addresses = [];
    const idParam = {
      customerAddressIDs: id.toString(),
      sourceFrom: 1,
    };
    await getAddressAPI.endpoints[this.endpoint.DELETE_ADDRESS]
      .getAllPost(idParam)
      .then((response) => (addresses = response.data))
      .catch((err) => {
        addresses = this.common.handleError(err);
      });
    if (addresses.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        addresses = this.deleteCustomerAddress(id);
      });
    }
    return Promise.resolve(addresses);
  }

  async getAddressTypes() {
    const customerTypesAPI = new API({ url: this.config.DROPDOWN_URL });
    customerTypesAPI.createEntity({ name: this.endpoint.GET_ADDRESS_TYPES });
    let returnVal = [];
    await customerTypesAPI.endpoints[this.endpoint.GET_ADDRESS_TYPES]
      .getAll()
      .then((response) => (returnVal = response.data))
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.getAddressTypes();
      });
    }
    return Promise.resolve(returnVal);
  }

  async VerifyCutomerEmail(Emailtoken) {
    const sendOTPAPI = new API({ url: this.config.LAUNDRETKART_AUTH });
    sendOTPAPI.createEntity({
      name: this.endpoint.CUSTOMER_EMAIL_VERIFICATION,
    });
    let itemArray = [];
    let idParams = {
      customerID: 0,
      token: Emailtoken,
      isEmailVerified: true,
    };
    await sendOTPAPI.endpoints[this.endpoint.CUSTOMER_EMAIL_VERIFICATION]
      .getAllPost(idParams)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.VerifyCutomerEmail(Emailtoken);
      });
    }
    return Promise.resolve(itemArray);
  }
}
