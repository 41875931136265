/**
 * This file should hold all the routes for the application
 * to be used in navigation for creating routes and
 * to be used while redirecting from anywhere
 * so just in case it is needed to be changed,
 * it can be changed globally.
 *
 * @author Niraj Gohel
 * @created 17 June 2020
 */

let ROUTES = {};
ROUTES.LOGIN = "/";
ROUTES.SPLASH = "/splash";
ROUTES.DASHBOARD = "/dashboard";
ROUTES.ADMIN_CONFIG = "/adminconfiguration";
ROUTES.MASTER = "/master";
ROUTES.AUTHENTICATION = "/authentication";
ROUTES.STOREMODULE = "/store";
ROUTES.FACTORY = "/factory";
ROUTES.LKWALLET = "/lkwallet";
ROUTES.DELIVERY_DASHBORAD = "/deliverydashboard";
ROUTES.PACKAGE_CHARGES_MASTER = "/adminconfiguration/packagingcharges";
ROUTES.DELIVERY_CHARGES_MASTER = "/adminconfiguration/deliverycharges";
ROUTES.REWARDS_CONFIG = "/adminconfiguration/rewardsconfig";
ROUTES.BANNER = "/adminconfiguration/banner";
ROUTES.NOTICE_BOARD = "/adminconfiguration/notice-board";
ROUTES.STANDARD_RATE_CARD = "/adminconfiguration/standard-rate-card";
ROUTES.PAYTM_EDC_MACHINE_CONFING =
  "/adminconfiguration/paytm-edc-machine-configuration";
 ROUTES.TERMS_AND_CONDITIONS = "/adminconfiguration/terms-and-conditions";
ROUTES.RATECARD = "/adminconfiguration/serviceratecard";
ROUTES.ADD_RATECARD = "/adminconfiguration/serviceratecard/add";
ROUTES.EDIT_RATECARD = "/adminconfiguration/serviceratecard/edit";
ROUTES.VIEW_RATECARD = "/adminconfiguration/serviceratecard/view";
ROUTES.VIEW_RATESTOREASSIGNMENT =
  "/adminconfiguration/serviceratecard/storeassignment";
ROUTES.VIEW_RATECORPORATEASSIGNMENT =
  "/adminconfiguration/serviceratecard/corporateassignment";
ROUTES.VIEW_ROLE = "/role";

ROUTES.TIMECARD = "/adminconfiguration/servicetimecard";
ROUTES.ADD_TIMECARD = "/adminconfiguration/servicetimecard/add";
ROUTES.EDIT_TIMECARD = "/adminconfiguration/servicetimecard/edit";
ROUTES.VIEW_TIMECARD = "/adminconfiguration/servicetimecard/view";
ROUTES.VIEW_TIMESTOREASSIGNMENT =
  "/adminconfiguration/servicetimecard/storeassignment";
ROUTES.VIEW_TIMECORPORATEASSIGNMENT =
  "/adminconfiguration/servicetimecard/corporateassignment";

ROUTES.DISCOUNT_MASTER = "/adminconfiguration/discountmaster";
ROUTES.VIEW_DISCOUNT_CODES = "/adminconfiguration/discountmaster/discountcodes";
ROUTES.ADD_DISCOUNT_MASTER = "/adminconfiguration/discountmaster/add";
ROUTES.VIEW_DISCOUNT_MASTER = "/adminconfiguration/discountmaster/view";
ROUTES.EDIT_DISCOUNT_MASTER = "/adminconfiguration/discountmaster/edit";
ROUTES.FAQ = "/adminconfiguration/faq";

ROUTES.STORE = "/master/store";
ROUTES.CITY_MASTER = "/master/city";
ROUTES.STATE_MASTER = "/master/state";
ROUTES.DESIGNATION_MASTER = "/master/designation";
ROUTES.BRAND_MASTER = "/master/garmentbrand";
ROUTES.SIZE_MASTER = "/master/garmentsize";
ROUTES.KYCDOCTYPE_MASTER = "/master/kycdocumenttype";
ROUTES.SERVICE_MASTER = "/master/service";
ROUTES.GARMENT_MASTER = "/master/garment";
ROUTES.COLOR_MASTER = "/master/garmentcolor";
ROUTES.REWASH_REASON_MASTER = "/master/rewashreason";
ROUTES.OPERATION_INSTRUCTION_MASTER = "/master/operationinstruction";
ROUTES.EXPENSE_TYPE_MASTER = "/master/expensetype";
ROUTES.FACTORY_MASTER = "/master/factory";
ROUTES.ADD_FACTORY = "/master/factory/add";
ROUTES.EDIT_FACTORY = "/master/factory/edit";
ROUTES.VIEW_FACTORY = "/master/factory/view";

ROUTES.SERVICE_ICONS = "/master/service-icons";

ROUTES.USER = "/authentication/manageuser";
ROUTES.ADD_USER = "/authentication/manageuser/add";
ROUTES.EDIT_USER = "/authentication/manageuser/edit";
ROUTES.VIEW_USER = "/authentication/manageuser/view";

ROUTES.CUSTOMER_MASTER = "/master/customer";
ROUTES.ADD_CUSTOMER = "/master/customer/add";
ROUTES.EDIT_CUSTOMER = "/master/customer/edit";
ROUTES.VIEW_CUSTOMER = "/master/customer/view";
ROUTES.VIEW_ADDRESSES = "/master/customer/addresses";

ROUTES.ROLE_SCREEN_MAPPING = "/authentication/manage-role-operation";
ROUTES.ROLE_MASTER = "/authentication/roles";

ROUTES.ADD_STORE = "/master/store/add";
ROUTES.EDIT_STORE = "/master/store/edit";
ROUTES.VIEW_STORE = "/master/store/view";

ROUTES.CORPORATE_MASTER = "/master/corporate";
ROUTES.ADD_CORPORATE = "/master/corporate/add";
ROUTES.EDIT_CORPORATE = "/master/corporate/edit";
ROUTES.VIEW_CORPORATE = "/master/corporate/view";

ROUTES.STORE_GATE_PASS_LIST = "/store/gate-pass-list";
ROUTES.FACTORY_GATE_PASS_LIST = "/factory/gate-pass-list";

ROUTES.STORE_PRE_QC_RESPONSE_LIST = "/store/pre-qc-response-list";
ROUTES.VIEW_PRE_QC_STORE = "/store/pre-qc-response-list/view";
ROUTES.EDIT_PRE_QC_STORE = "/store/pre-qc-response-list/edit";

ROUTES.STORE_READY_FOR_DELIVERY_ORDERS = "/store/ready-for-delivery-orders";
ROUTES.STORE_EXPENSE_ENTRY = "/store/expense-entry";
ROUTES.STORE_INVENTORY = "/store/storeinventory";
ROUTES.STORE_TRANSFER_OUT = "/store/transfer-out";
ROUTES.STORE_TRANSFER_IN = "/store/transfer-in";
ROUTES.STORE_IN_PENDING = "/store/store-in-pending";

ROUTES.STORE_DASHBOARD = "/store/dashboard";
ROUTES.STORE_ORDER_HISTORY = "/store/storeorderhistory";

ROUTES.TRANSFER_IN_FACTORY = "/factory/factory-in";
ROUTES.TRANSFER_OUT_FACTORY = "/factory/factory-out";
// ROUTES.FACTORY_INVENTORY = "/factory/factoryinventory";
ROUTES.FACTORY_GARMENT_INVENTORY="/factory/factoryinventory/GarmentInventory";
ROUTES.INVENTORY_SUMMARY="/factory/factoryinventory/inventorySummary";
ROUTES.FACTORY_Resorting_Packaging = "/factory/resorting&packaging";
ROUTES.FACTORY_Resorting_Print = "/factory/Stiker-Print";
ROUTES.RESORTING_AND_PACKAGING = "/factory/resorting&packaginglist";
// ROUTES.FACTORY_GATE_PASS = "/factory/factory-gate-pass";
ROUTES.FACTORY_ORDER_HISTORY = "/factory/factoryorderhistory";
ROUTES.FACTORY_IN_PENDING = "/factory/factory-in-pending";

ROUTES.GARMENT_PROCESSING = "/factory/garment-processing";
ROUTES.PROCESSING_LIST = "/factory/processing-list";

ROUTES.PRE_QC_FACTORY = "/factory/pre-qc";
ROUTES.ADD_PRE_QC_FACTORY = "/factory/pre-qc/add";
ROUTES.VIEW_PRE_QC_FACTORY = "/factory/pre-qc/view";
ROUTES.EDIT_PRE_QC_FACTORY = "/factory/pre-qc/edit";

ROUTES.POST_QC_FACTORY = "/factory/post-qc";
ROUTES.ADD_POST_QC_FACTORY = "/factory/post-qc/add";
ROUTES.VIEW_POST_QC_FACTORY = "/factory/post-qc/view";
ROUTES.EDIT_POST_QC_FACTORY = "/factory/post-qc/edit";

ROUTES.FACTORY_DASHBOARD = "/factory/dashboard";

ROUTES.PACKAGE_CONFIGURATION = "/lkwallet/packageconfiguration";
ROUTES.REFFERAL_BONUS_CONFIGURATION = "/lkwallet/refferalbonusconfiguration";
ROUTES.COMPENSATION_MASTER = "/lkwallet/compensation";
ROUTES.EMPLOYEE_CREDIT = "/lkwallet/employeecredit";
ROUTES.LKREFUND = "/lkwallet/refund";
ROUTES.ADVANCE_PAYMENT = "/lkwallet/advancepayment";
ROUTES.BUY_PACKAGE = "/lkwallet/buy-package";
ROUTES.CUSTOMER_BULK_CREDIT = "/lkwallet/customerbulkcredit";
ROUTES.CUSTOMER_BULK_DEBIT = "/lkwallet/customerbulkdebit";
ROUTES.CUSTOM_BULK_NOTIFICATION_UPLOAD = "/lkwallet/custombulknotificationupload";



ROUTES.CASH_COUPON = "/adminconfiguration/cashcoupon";
ROUTES.VIEW_CASHCOUPON_CODES = "/adminconfiguration/cashcoupon/cashcouponcodes";
ROUTES.ADD_CASH_COUPON = "/adminconfiguration/cashcoupon/add";
ROUTES.VIEW_CASH_COUPON = "/adminconfiguration/cashcoupon/view";
ROUTES.EDIT_CASH_COUPON = "/adminconfiguration/cashcoupon/edit";
ROUTES.UPLOAD_CUSTOMER_TO_COUPON_MAPPING = "/adminconfiguration/uploadcustomertocouponmapping";
ROUTES.HOLIDAY_CONFIGURATION="/adminconfiguration/holidayconfiguration";


ROUTES.COLLECT_FINGERS = "/adminconfiguration/collect-fingerprints";
ROUTES.ATTENDANCE = "/adminconfiguration/attendance";
ROUTES.ATTENDANCEREPORT = "/adminconfiguration/attendanceReport";

ROUTES.CREATE_ORDER = "/store/order/create";
ROUTES.ORDER_LISTING = "/store/order-list";
ROUTES.UPDATE_ORDER = "/store/order/update";

ROUTES.PICKUP_LIST = "/store/pickup";
ROUTES.ADD_PICKUP = "/store/pickup/add";
ROUTES.VIEW_PICKUP = "/store/pickup/view";
ROUTES.EDIT_PICKUP = "/store/pickup/edit";

ROUTES.DELIVERY_LIST = "/store/delivery-list";

ROUTES.PROFORMA_INVOICE = "/store/invoice";

ROUTES.PARTIAL_ORDER_RECEIVED = "/store/partial-order-received";

ROUTES.CASH_COLLECTION = "/authentication/cash-collection";

ROUTES.REDIRECT_INVALID_REQUEST = "/invalid-request";

ROUTES.VERIFICATION = "/Verification";

ROUTES.PENDING_CASH_SUMMARY = "/Audit/Pending-cash-summary";
ROUTES.ORDERS_SUMMARY = "/Audit/Orders-summary";
ROUTES.STORE_TRANSFER_IN = "/store/transfer-in";

ROUTES.OUT_OF_COVERAGE = "/Audit/out-of-coverage";
ROUTES.PICKUP_DELIVERY_ASSIGNMENT="/Audit/Pickup-Delivery-Assignment";
ROUTES.DAILY_ORDER_SUMMARY ="/Audit/Daily-Order-Summary";
ROUTES.MONTHLY_ORDER_SUMMARY ="/Audit/Monthly-Order-Summary";
ROUTES.DAILY_PICKUP_SUMMARY ="/Audit/Daily-Pickup-Summary";
ROUTES.MONTHLY_PICKUP_SUMMARY ="/Audit/Pickup-Summary";

ROUTES.APPSFLYER = "/adminconfiguration/appsflyer";

ROUTES.EXPRESS_URGENT="/factory/daily-sheets/Express-Urgent";
ROUTES.REWASH="/factory/daily-sheets/Rewash";
ROUTES.FIVE_STARE="/factory/daily-sheets/5-Star";

ROUTES.VIEW_ON_MAP = "/store/view-on-map"

export default ROUTES;
