import React from "react";
// import styles from "./transferoutstorescreen.module.css";
import Button from "../../Common/Button/button";
// import Filters from "../../Common/Filters/filters";
import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Select,
  Table,
  Modal,
  Descriptions,
  notification,
  Space,
  Spin,
  Checkbox,
  Input,
} from "antd";
import TransferOutService from "../../../services/storetransferoutservice";
import { IsAuthenticated } from "../../../services/auth-header";
import styles from "../../Common/Styles/master.module.css";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Logo from "../../../images/Common/Logo.png";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";

const { Option } = Select;
const { Search } = Input;

export default class Master extends React.Component {
  TransferOutService = new TransferOutService();
  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();
  state = {
    loading: false,
    generateButtonLoading: false,
    selectedServices: "",
    selectedFactory: [],
    showInfoPopup: false,
    factoryListOptions: [],
    serviceListOptions: [],
    StoreTransferOuts: [],
    GetGarmentdatailBysplitOrdernumbers: [],
    isExapndableLoading: false,
    factoryid: 0,
    printInfoHeaderData: {
      gatePassNo: "",
      gatePassDate: "",
      storeName: "",
      factoryName: "",
      factoryAddress: "",
      userName: "",
    },
    printInfoRowData: [],
    isPDFDataLoaded: false,
    gatePassNumber: false,
    isGenerateGetPass: true,
    sorter: {
      field: null,
      order: null,
    },
    search: null,
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  columns = [
    {
      title: labels.SPLIT_ORDER, // "Split Order",
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },
    {
      title: labels.CUSTOMER_NAME, //"Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: labels.SERVICE, // "Service",
      dataIndex: "serviceName",
      key: "serviceName",
      sorter: true,
    },
    {
      title: labels.ORDER_DATE, //"Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      sorter: true,
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.TOTAL_GARMENTS, //"Total Garments",
      dataIndex: "noOfGarment",
      key: "noOfGarment",
      sorter: true,
    },
    {
      title: labels.READY_FOR_TRANSFER_OUT, //"Total Garments",
      dataIndex: "readyforTransferOutCnt",
      key: "readyforTransferOutCnt",
      sorter: true,
    },
    {
      title: labels.DELIVERY_TYPE, //"Total Garments",
      dataIndex: "deliveryType",
      key: "deliveryType",
      sorter: true,
    },
    ,
  ];

  expandableColumns = [
    // {
    //   title: labels.SPLIT_ORDER_NUMBER, //"Split Order Number",
    //   dataIndex: "splitOrderNumber",
    //   key: "splitOrderNumber",
    // },
    {
      title: labels.TAG_NUMBER, //"Tag Number",
      dataIndex: "tagNo",
      key: "tagNo",
    },
    {
      title: labels.GARMENT_NAME, //"Garment Name",
      dataIndex: "garmentName",
      key: "garmentName",
    },
    // {
    //   title: "Split Order Number",
    //   dataIndex: "splitOrderNumber",
    //   key: "splitOrderNumber",
    // },
    {
      title: labels.SERVICE, //"Service",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: labels.ADD_ON_SERVICES, //"Add-On Services",
      dataIndex: "addOnServiceName",
      key: "addOnServiceName",
    },
    {
      title: labels.STATUS,
      dataIndex: "garmentStatus",
      key: "garmentStatus",
    },
    // {
    //   title: "Size",
    //   dataIndex: "uomName",
    //   key: "sizeName",
    // },
  ];

  GetSplitNumberToTransferoutRes = [];

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.STORE_TRANSFER_OUT
    );

    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res, isGenerateGetPass: true });
    this.getServiceList();
  }

  handleChangee = () => {
    if ([null, undefined, [], ""].includes(this.state.selectedServices)) {
      this.setState({
        selectedFactory: [],
        factoryListOptions: [],
        factoryid: 0,
        StoreTransferOuts: [],
        search: null,
      });
    } else {
      this.setState({
        selectedFactory: [],
        StoreTransferOuts: [],
        search: null,
      });
      this.getFactoryList(this.state.selectedServices);
    }
    // if (this.state.factoryid > 0) {
    //   this.GetSplitNumberToTransferout(event.join(","), this.state.factoryid);
    // }
  };

  getServiceList = () => {
    this.TransferOutService.retrieveServiceList().then((items) => {
      if (items.statusCode === 200) {
        this.setState({ serviceListOptions: items.data });
      } else {
        notification["error"]({
          message: labels.TRANSFER_OUT,
          description: items.message
            ? items.message
            : labels.UNABLE_TO_GET_LIST_OF_SERVICE_DATA,
        });
      }
    });
  };

  GetSplitNumberToTransferout = (
    // localStorage.getItem("branchID"),
    serviceId,
    factoryid,
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    this.setState({ loading: true });
    this.TransferOutService.GetSplitNumberToTransferout(
      // storeId,
      serviceId,
      factoryid,
      index,
      size,
      sortColumn,
      sortOrder,
      search
    ).then((items) => {
      let data = [];
      if (items.statusCode === 200) {
        if (items.data.length === 0 && this.state.pagination.current !== 1) {
          this.setState(
            { pagination: { ...this.state.pagination, current: 1 } },
            () => {
              this.GetSplitNumberToTransferout(
                serviceId,
                factoryid,
                1,
                size,
                sortColumn,
                sortOrder,
                search
              );
            }
          );
        }
        items.data.forEach((element) => {
          data.push({
            ...element,
            key: element.splitOrderNumber,
          });
        });
        this.setState({
          StoreTransferOuts: data,
          printInfoHeaderData: {
            gatePassNo: "",
            gatePassDate: "",
            storeName: "",
            factoryName: "",
            factoryAddress: "",
            userName: "",
          },
          printInfoRowData: [],
          isPDFDataLoaded: false,
          isGenerateGetPass: true,
        });
        this.setState({
          pagination: {
            ...this.state.pagination,
            total: this.state.StoreTransferOuts.length
              ? this.state.StoreTransferOuts[0].totalRecords
              : 0,
            current: this.state.StoreTransferOuts.length
              ? this.state.pagination.current
              : 1,
          },
        });
      } else {
        notification["error"]({
          message: labels.TRANSFER_OUT,
          description: labels.UNABLE_TO_GET_SPLIT_ORDER_NUMBERS,
        });
      }
      this.setState({ loading: false });
    });
  };

  GetGarmentdatailBysplitOrdernumber = (id) => {
    this.setState({ isExapndableLoading: true });
    this.TransferOutService.GetGarmentdatailBysplitOrdernumber(id).then(
      (items) => {
        if (items.statusCode === 200) {
          this.setState({
            GetGarmentdatailBysplitOrdernumbers: {
              ...this.state.GetGarmentdatailBysplitOrdernumbers,
              [id]: items.data,
            },
          });
          this.setState({ isExapndableLoading: false });
        } else {
          notification["error"]({
            message: labels.TRANSFER_OUT,
            description: labels.UNABLE_TO_GET_GARMENT_DETAILS,
          });
        }
      }
    );
  };

  getFactoryList = (event) => {
    this.TransferOutService.retrieveFactoryList(event).then((items) => {
      if (items.statusCode === 200) {
        let res = [];
        for (let i = 0; i < items.data.length; i++) {
          res.push({
            value: items.data[i].factoryID,
            text: items.data[i].factoryName,
          });
        }
        this.setState({ factoryListOptions: res });
      } else {
        notification["error"]({
          message: labels.TRANSFER_OUT,
          description: items.message
            ? items.message
            : labels.UNABLE_TO_GET_LIST_OF_FACTORY_DATA,
        });
      }
    });
  };

  element = React.createRef();

  GenerateGatePass = (store, factory) => {
    this.setState({ generateButtonLoading: true });
    this.TransferOutService.GenerateGatePass(
      parseInt(localStorage.getItem("branchID")),
      this.state.factoryid,
      this.state.rowselectedvalue.join(",")
    ).then((response) => {
      if (response.statusCode === 200) {
        // this.setState({ gatePass: response.data });
        this.setState({
          gatePassNumber: response.data,
          StoreTransferOuts: [],
          selectedServices: "",
          selectedFactory: [],
          factoryListOptions: [],
          factoryid: 0,
          search: null,
        });
        let post = {
          gatePassNumber: this.state.gatePassNumber,
        };
        this.TransferOutService.getPDFGatePass(post).then((res) => {
          this.setState({
            printInfoHeaderData: {
              gatePassNo: res.data.gatePassNo,
              gatePassDate: res.data.gatePassDate,
              storeName: res.data.storeName,
              factoryName: res.data.factoryName,
              factoryAddress: res.data.factoryAddress,
              userName: res.data.userName,
            },
            printInfoRowData: res.data.vMSplitOrderDelivery,
            isPDFDataLoaded: true,
          });
        });
        notification["success"]({
          message: labels.TRANSFER_OUT,
          description:
            labels.GATE_PASS +
            post.gatePassNumber +
            " " +
            labels.HAS_BEEN_GENERATED,
        });
      } else {
        notification["error"]({
          message: labels.TRANSFER_OUT,
          description: this.common.Message(response.message), /// labels.UNABLE_TO_GET_LIST_OF_STICKER_DATA,
        });
      }
      this.setState({ generateButtonLoading: false });
    });
  };

  downloadPDF = () => {
    var opt = {
      margin: 1,
      filename: "StoreGatePass.pdf",
      jsPDF: { format: "a5", orientation: "portrait" },
      pagebreak: { mode: ["css", "legacy"], avoid: "tr" },
      html2canvas: {
        dpi: 192,
        scale: 4,
      },
    };
    html2pdf().set(opt).from(this.element.current).save();
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length > 0 && this.state.factoryid > 0) {
        this.setState({
          isGenerateGetPass: false,
        });
      } else {
        this.setState({
          isGenerateGetPass: true,
        });
      }
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
     // disabled: record.noOfGarment !== record.readyforTransferOutCnt, // Column configuration not to be checked
      name: record.name,
    }),
  };

  expandedRowRender = (record) => {
    return (
      <Table
        columns={this.expandableColumns}
        dataSource={this.state.GetGarmentdatailBysplitOrdernumbers[record.key]}
        pagination={false}
        size="small"
      />
    );
  };

  handlefactorychange = (value) => {
    this.setState({ factoryid: value, selectedFactory: value }, () => {
      if (
        this.state.selectedServices != null &&
        this.state.selectedServices.length > 0
      ) {
        this.GetSplitNumberToTransferout(
          this.state.selectedServices.toString(),
          value,
          this.state.pagination.current,
          this.state.pagination.pageSize,
          this.state.sorter.field,
          this.state.sorter.order,
          this.state.search
        );
      }
      if (
        this.state.rowselectedvalue != undefined &&
        this.state.rowselectedvalue.length > 0
      ) {
        this.setState({ isGenerateGetPass: false });
      }
    });
  };

  render() {
    let data = <tr></tr>;
    if (this.state.isPDFDataLoaded) {
      data = this.state.printInfoRowData.map((item, index) => {
        return (
          <tr style={{ fontSize: 12 }} key={index}>
            <td>{item.rowNumber}</td>
            <td>{item.splitOrderNumber}</td>
            <td>{item.deliveryType}</td>
            <td>{item.noOfGarments}</td>
            <td>
              {item.pickupDate === ""
                ? null
                : moment(item.pickupDate).format("DD/MM/YYYY")}
            </td>
            <td>
              {item.deliveryDate === ""
                ? null
                : moment(item.deliveryDate).format("DD/MM/YYYY")}
            </td>
          </tr>
        );
      });
    }

    const allStoreArray = this.state.serviceListOptions
      .map((store) => {
        return store.value;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();

    return (
      <div>
        <PageHeader
          title={labels.TRANSFER_OUT} //"Transfer-Out "
          extra={[
            <Select
              showSearch
              placeholder={labels.SELECT_SERVICE} //"Select Service"
              allowClear
              mode="multiple"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(event) => {
                this.setState(
                  {
                    selectedServices: [null, undefined, ""].includes(event)
                      ? ""
                      : event.join(","),
                  },
                  () => {
                    this.handleChangee();
                  }
                );
              }}
              style={{ minWidth: 150, maxWidth: 450 }}
              value={
                this.state.selectedServices === ""
                  ? []
                  : this.state.selectedServices.split(",")
              }
            >
              {this.state.serviceListOptions.length !== 0
                ? this.state.serviceListOptions.map((service) => {
                    return service.value !== "-1" ? (
                      <Option value={service.value} key={service.value}>
                        {service.text}
                      </Option>
                    ) : null;
                  })
                : null}
            </Select>,
            <div key="2" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={
                  ![null, undefined, ""].includes(
                    this.state.selectedServices
                  ) &&
                  this.state.selectedServices.split(",").length ===
                    allStoreArray.length &&
                  this.state.selectedServices
                    .split(",")
                    .slice()
                    .sort()
                    .every((value, index) => value === allStoreArray[index])
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    let allOpt = this.state.serviceListOptions
                      .map((store) => {
                        return store.value;
                      })
                      .filter((x) => x !== "-1");
                    this.setState(
                      {
                        selectedServices: allOpt.join(","),
                      },
                      () => this.handleChangee()
                    );
                  } else {
                    this.setState(
                      {
                        selectedServices: "",
                      },
                      () => this.handleChangee()
                    );
                  }
                }}
              >
                {labels.ALL}
              </Checkbox>
            </div>,
            <div key="3" style={{ alignSelf: "center" }}>
              <Select
                showSearch
                placeholder={labels.SELECT_FACTORY} //"Select Factory"
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ minWidth: 150, maxWidth: 200 }}
                onSelect={this.handlefactorychange}
                value={this.state.selectedFactory}
              >
                {this.state.factoryListOptions.length !== 0
                  ? this.state.factoryListOptions.map((factory) => {
                      return factory.value !== "-1" ? (
                        <Option value={factory.value} key={factory.value}>
                          {factory.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </div>,
            <div key="4" style={{ alignSelf: "center" }}>
              <Search
                placeholder="Search"
                className={styles.SearchInput}
                value={this.state.search}
                disabled={
                  this.state.selectedServices != "" &&
                  this.state.selectedFactory != ""
                    ? false
                    : true
                }
                onChange={(event) => {
                  this.setState({
                    search: event.target.value.toString().toLowerCase(),
                  });
                  this.GetSplitNumberToTransferout(
                    this.state.selectedServices,
                    this.state.factoryid,
                    this.state.pagination.current,
                    this.state.pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    event.target.value.toString().toLowerCase()
                  );
                }}
              />
            </div>,
          ]}
        />
        <Table
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          expandRowByClick={true}
          expandIconColumnIndex={1}
          indentSize={120}
          expandable={{
            onExpand: (expanded, record) => {
              this.GetGarmentdatailBysplitOrdernumber(record.key);
            },
            expandedRowRender: this.expandedRowRender,
          }}
          // className="components-table-demo-nested"
          columns={this.columns}
          dataSource={this.state.StoreTransferOuts}
          size="small"
          loading={this.state.loading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.GetSplitNumberToTransferout(
                  this.state.selectedServices,
                  this.state.factoryid,
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />
        <TableActions
          isPagination={this.state.StoreTransferOuts.length > 0 ? true : false}
        >
          <Space>
            <Button
              variant="purple"
              htmlType="submit"
              shape="rounded"
              onClick={() => {
                this.GenerateGatePass();
              }}
              loading={this.state.generateButtonLoading}
              disabled={
                this.state.isGenerateGetPass || this.state.isPDFDataLoaded
              }
            >
              {labels.GENERATE_GATE_PASS}
            </Button>
            {this.state.isPDFDataLoaded ? (
              <Button
                htmlType="submit"
                shape="rounded"
                variant="transparentGreyOutline"
                onClick={() => {
                  this.downloadPDF();
                }}
              >
                {labels.DOWNLOAD_GATE_PASS}
              </Button>
            ) : null}
          </Space>
        </TableActions>

        {this.state.isPDFDataLoaded ? (
          <div style={{ display: "none" }}>
            <div id="page" ref={this.element} style={{ padding: "10px" }}>
              <table
                align="center"
                style={{
                  width: "100%",
                }}
              >
                <tr
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    {labels.STORE_GATE_PASS_NUMBER} :
                    {this.state.printInfoHeaderData.gatePassNo}
                  </td>
                </tr>
                <tr
                  style={{ fontSize: 12, fontWeight: "bold" }}
                  // key={index}
                >
                  <td>
                    <img src={Logo} alt="Laundrokart" className="img-fluid" />
                  </td>

                  <td style={{ textAlign: "right" }}>
                    {labels.DATE}:
                    {moment(this.state.printInfoHeaderData.gatePassDate).format(
                      "DD/MM/YYYY"
                    )}
                    <br></br>
                    {labels.FACTORY_NAME}:
                    {this.state.printInfoHeaderData.factoryName}
                    <br></br>
                    {labels.FACTORY_ADDRESS}:
                    {this.state.printInfoHeaderData.factoryAddress}
                    <br></br>
                    {labels.STORE_NAME}:
                    {this.state.printInfoHeaderData.storeName}
                    <br></br>
                    {labels.CREATED_BY}:{" "}
                    {this.state.printInfoHeaderData.userName}
                  </td>
                </tr>
              </table>
              <table
                border="1"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
                cellPadding={2}
              >
                <thead>
                  <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                    <th>{labels.S_NO}</th>
                    <th>{labels.SPLIT_ORDER_ID}</th>
                    <th>{labels.DELIVERY_TYPE}</th>
                    <th>{labels.NUMBER_OF_GARMENTS}</th>
                    <th>{labels.PICKUP_DATE}</th>
                    <th>{labels.DELIVERY_DATE}</th>
                  </tr>
                </thead>
                <tbody>{data}</tbody>
              </table>
              <br></br>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
