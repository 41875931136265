import React from "react";
import TransferIn from "../../../components/FactoryModule/TransferIn/transferin.factory.component";

/**
 * This file holds the view of Transfer In screen
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 11 June 2020
 */
export default class TransferInScreen extends React.Component {
  render() {
    return (
      <div>
        <TransferIn></TransferIn>
      </div>
    );
  }
}
