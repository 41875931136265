import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Tooltip,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import RewashReasonService from "../../../services/rewashreasonservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import {
  RegexLettersDigitsUnderScoreHyphen,
  MessageLettersDigitsUnderScoreHyphen,
} from "../../../shared/validator";

const { Search } = Input;
const { Option } = Select;

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.RewashReasonService = new RewashReasonService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.state = {
      modalLoading: false,
      reasonItems: [],
      reasonID: 0,
      rewashReason: "",
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.REWASH_REASON
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    // {
    //   title: "ReasonID",
    //   dataIndex: "reasonID",
    //   key: "reasonID",
    // },
    {
      title: "Rewash Reason",
      dataIndex: "rewashReason",
      key: "rewashReason",
      sorter: true,
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    reasonID: record.reasonID,
                    rewashReason: record.rewashReason,
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    reasonID: record.reasonID,
                    rewashReason: record.rewashReason,
                  })
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.reasonID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      reasonID: this.state.reasonID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      reasonID: this.state.reasonID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      reasonID: this.state.reasonID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.RewashReasonService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            reasonID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          reasonItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Rewash Reason Master",
          description: items.message
            ? items.message
            : "Unable to get list of rewash reason data",
        });
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (reasonID) => {
    if (reasonID === "" || reasonID === null || reasonID === undefined) {
      // openNotificationWithIcon(
      //   "error",
      //   "Rewash Reason Master",
      //   "Please Select Atleast One Row"
      // );
      notification["error"]({
        message: "Rewash Reason Master",
        description: "Please Select Atleast One Row",
      });
    } else {
      this.setState({ loading: true });
      this.RewashReasonService.removeItems(reasonID).then((items) => {
        if (items.result === "Success") {
          // openNotificationWithIcon(
          //   "success",
          //   "Rewash Reason Master",
          //   items.message
          // );
          notification["success"]({
            message: "Rewash Reason Master",
            description: items.message,
          });
          const { pagination } = this.state;
          this.fetchItems({
            reasonID: parseInt(this.state.reasonID),
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
          });
        } else {
          // openNotificationWithIcon(
          //   "error",
          //   "Rewash Reason Master",
          //   items.message
          // );
          notification["error"]({
            message: "Rewash Reason Master",
            description: items.message,
          });
        }
        this.setState({ loading: false });
      });
    }
  };

  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    var postData = {
      reasonID: this.state.reasonID,
      rewashReason: this.state.rewashReason,
      isActive: true,
    };
    this.setState({ modalLoading: true });
    this.RewashReasonService.insertUpdateItems(postData).then((items) => {
      this.setState({ items: items, showAddPopup: false });
      if (items.result === "Success") {
        // openNotificationWithIcon(
        //   "success",
        //   "Rewash Reason Master",
        //   items.message
        // );
        notification["success"]({
          message: "Rewash Reason Master",
          description: items.message,
        });
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          reasonID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        // openNotificationWithIcon(
        //   "error",
        //   "Rewash Reason Master",
        //   items.message
        // );
        notification["error"]({
          message: "Rewash Reason Master",
          description: items.message,
        });
      }
      this.setState({ modalLoading: false });
    });
  };

  handleReasonName = (e) => {
    let reasonNm = e.target.value;
    this.setState({ rewashReason: reasonNm });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      reasonID: 0,
      rewashReason: "",
      isEnabled: false,
      isVisible: false,
    });
  };

  render() {
    const { rewashReason, pagination, loading } = this.state;
    const reasonItems = this.state.reasonItems;
    const listItems = reasonItems.map((item, index) => ({
      key: item.reasonID,
      reasonID: item.reasonID,
      rewashReason: item.rewashReason,
      isActive: item.isActive.toString(),
      createdByName: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD/MM/YYYY hh:mm:ss a"),
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title="Rewash Reason Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                Add Reason
              </Button>
            ) : null,

            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            reasonItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>

        <Modal
          title={
            this.state.reasonID === 0
              ? "Add Rewash Reason"
              : this.state.isEnabled
              ? "View Rewash reason"
              : "Edit Rewash reason"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{ rewashReason }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Reason Name "
                    name="rewashReason"
                    rules={[
                      { required: true, message: "Please Enter Reason !" },
                      // {
                      //   pattern: RegexLettersDigitsUnderScoreHyphen,
                      //   message: MessageLettersDigitsUnderScoreHyphen,
                      // },
                    ]}
                  >
                    <Input
                      placeholder="Enter Reason "
                      disabled={this.state.isEnabled}
                      onChange={this.handleReasonName}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.reasonID === 0 ? "Add" : "Update"}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
