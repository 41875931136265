import React from "react";
import TransferOut from "../../../components/StoreModule/TransferOut/transferout.store.component";

/**
 * This file holds the view of Transfer Out screen
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 10 July 2020
 */
export default class TransferOutStore extends React.Component {
  render() {
    return (
      <div>
        <TransferOut/>
      </div>
    )
  }
}
