import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

 class BulkUploadCouponService  {
    constructor() {
        this.config = new Configuration();
        this.endpoint = new EndPointEntity();
        this.common = new Common();
      }
    
      async getbulkcouponcodesdata(param) {
        const myApi = new API({ url: this.config.ITEM_URL });
        myApi.createEntity({ name: this.endpoint.GET_BULK_COUPON_MAP_UPLOAD_MASTER });
        let itemArray = [];
        let shouldWait = false;
        await myApi.endpoints[this.endpoint.GET_BULK_COUPON_MAP_UPLOAD_MASTER]
          .getAllPost(param)
          .then(({ data }) => {
            itemArray = data;
            // console.log('data->',data);
          })
          .catch((err) => {
            itemArray = this.common.handleError(err);
          });
        if (itemArray.statusCode == 401) {
          await this.common.refreshToken().then((response) => {
            this.common.setToken(response);
            itemArray = this.getbulkcouponcodesdata(param);
          });
        }
        return Promise.resolve(itemArray);
      }
    
      async uploadFile(param) {
        const myApi = new API({ url: this.config.ITEM_URL });
        
        myApi.createEntity({ name: this.endpoint.UPLOAD_BULK_COUPON_CUSTMAPPING });
        let itemArray = [];
        let shouldWait = false;
        await myApi.endpoints[this.endpoint.UPLOAD_BULK_COUPON_CUSTMAPPING]
          .getAllPost(param)
          .then(({ data }) => {
            itemArray = data;
            // console.log("uploaddata->",data);
          })
          .catch((err) => {
            itemArray = this.common.handleError(err);
          });
        if (itemArray.statusCode == 401) {
          await this.common.refreshToken().then((response) => {
            this.common.setToken(response);
            itemArray = this.uploadFile(param);
          });
        }
        return Promise.resolve(itemArray);
      }
    
      async getReport(param) {
        const myApi = new API({ url: this.config.ITEM_URL });
        // console.log("paramgetreport->",param);
        myApi.createEntity({ name: this.endpoint.GET_COUPON_BULK_UPLOAD_FILE_REPORT });
        let itemArray = [];
        let shouldWait = false;
        await myApi.endpoints[this.endpoint.GET_COUPON_BULK_UPLOAD_FILE_REPORT]
          .getAllPost(param)
          .then(({ data }) => {
            itemArray = data;
            
          })
          .catch((err) => {
            itemArray = this.common.handleError(err);
          });
        if (itemArray.statusCode == 401) {
          await this.common.refreshToken().then((response) => {
            this.common.setToken(response);
            itemArray = this.getReport(param);
          });
        }
        return Promise.resolve(itemArray);
      }
    
      handleResponseError(response) {
        throw new Error("HTTP error, status = " + response.status);
      }
}

export default BulkUploadCouponService;
