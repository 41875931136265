import React from "react";
import CityMaster from "../../../components/Master/City/citymaster.component"

export default class CityScreen extends React.Component {
  render() {
    return (
      <div >
        <CityMaster></CityMaster>
      </div>
    );
  }
}