import React, { Component } from "react";
import TableActions from "../../Common/TableActions/tableactions";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import {
  PageHeader,
  Row,
  Col,
  Form,
  Table,
  notification,
  DatePicker,
} from "antd";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import pendingcashsummaryservice from "../../../services/pendingcashsummaryservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
const CURRENT_DATE = new Date();
const monthFormat = "YYYY/MM";
export default class monthlypickupsummarycomponent extends Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.pendingcashsummaryservice = new pendingcashsummaryservice();
    this.common = new Common();
    this.state = {
      currentMonth: moment(CURRENT_DATE),
      selectedmonth: moment(CURRENT_DATE).format("M"),
      selectedyear: moment(CURRENT_DATE).format("Y"),
      monthlypickupsummary: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 200,
        total: 0,
        showSizeChanger: true,
      },
      showfilterBox: true,
      loading: true,
      userRights: [],
      isResponseErrorShow: false,
      sorter: {
        field: null,
        order: null,
      },
    };
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }

    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.MONTHLY_PICKUP_SUMMARY
    );

    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.SR_NO,
      dataIndex: "srNo",
      key: "srNo",
      align: "center",
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
      align: "center",
    },

    {
      title: "NO of Orders",
      dataIndex: "orders",
      key: "orders",
      align: "center",
      sorter: true,
    },
    {
      title: "No. of ODM Orders",
      dataIndex: "odmOrder",
      key: "odmOrder",
      align: "center",
      sorter: true,
    },

    {
      title: "No. of Walking Orders",
      dataIndex: "walkingOrder",
      key: "walkingOrder",
      align: "center",
      sorter: true,
    },
    {
      title: "No. of Cancelled Pickups",
      dataIndex: "canceledPickup",
      key: "canceledPickup",
      align: "center",
      sorter: true,
    },
  ];

  componentDidMount() {
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      fMonth: this.state.selectedmonth,
      fYear: this.state.selectedyear,
      sortColumn: null,
      sortOrder: null,
    });
  }

  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.pendingcashsummaryservice
      .getMonthlyPickupSummary(params)
      .then((items) => {
        // console.log("items->", items);
        this.setState({
          monthlypickupsummary: [],
        });
        if (items.statusCode === 200) {
          this.setState({
            monthlypickupsummary: items.data,
          });
        } else {
          if (!this.state.isResponseErrorShow) {
            notification["error"]({
              message: "Monthly Pickup Summary",
              description: items.message
                ? items.message
                : "Unable to get Monthly Pickup Summary data",
            });
            this.setState({
              isResponseErrorShow: true,
            });
          }
        }
        this.setState({ loading: false });
      });
  };

  handleTableChange = (pagination, filters, sorter, extra) => {
    // console.log("pagination->", pagination);
    this.setState(
      {
        sorter: {
          ...sorter,
          order:
            sorter.order === "ascend"
              ? "asc"
              : sorter.order === "descend"
              ? "desc"
              : sorter.order,
        },
      },
      () => {
        this.fetchItems({
          fMonth: this.state.selectedmonth,
          fYear: this.state.selectedyear,
          sortColumn: this.state.sorter.field,
          sortOrder: this.state.sorter.order,
        });
      }
    );
  };

  handleDateChange = (date, dateString) => {
    //console.log("dateString->", dateString);
    let month = dateString.split("/")[1];
    let year = dateString.split("/")[0];
    // console.log("month->", month, year);
    this.setState({ selectedmonth: month, selectedyear: year });
  };

  render() {
    const { pagination, loading } = this.state;
    const monthlypickupsummary = this.state.monthlypickupsummary;
    // console.log("monthlypickupsummary->", monthlypickupsummary);
    const listItems = monthlypickupsummary.map((item, index) => ({
      key: item.storeID,
      srNo: item.rowNumber,
      storeName: item.storeName,
      orders: item.orders,
      odmOrder: item.odmOrder,
      walkingOrder: item.walkingOrder,
      canceledPickup: item.canceledPickup,
    }));
    return (
      <div>
        <PageHeader
          title={labels.MONTHLY_PICKUP_SUMMARY}
          extra={[
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.fetchItems({
                fMonth: this.state.selectedmonth,
                fYear: this.state.selectedyear,
                sortColumn: null,
                sortOrder: null,
              })
            }
            initialValues={{
              currentMonth: this.state.currentMonth,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"Month"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    // defaultValue={moment("2015/01", monthFormat)}
                    defaultValue={moment(CURRENT_DATE, monthFormat)}
                    value={this.state.currentMonth}
                    format={monthFormat}
                    picker="month"
                    onChange={(date, dateString) =>
                      this.handleDateChange(date, dateString)
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                >
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          onRow={(record, rowIndex) => {
            return {
              style: {
                fontWeight: record.storeName == "Grand Total" ? "bold" : null,
                color: record.storeName == "Grand Total" ? "black" : null,
              },
            };
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
          bordered
        />
        <TableActions isPagination={false}></TableActions>
      </div>
    );
  }
}
