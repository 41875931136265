import React from "react";
import CreateOrderComp from "../../../../components/StoreModule/Order/CreateOrder/createorder.component";

/**
 * Create Order Screen
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 5 June 2020
 */
export default class CreateOrder extends React.Component {
  render() {
    return <CreateOrderComp></CreateOrderComp>;
  }
}
