import React from 'react';
import CustomBulkNotification from '../../../components/LKWallet/CustomBulkNotification/custombulknotification.comonent';

export default class customebulknotificationscreen extends React.Component {
    render() {
        return (
            <div>
           <CustomBulkNotification></CustomBulkNotification>
           </div>
        )
    }
}
