import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class UserService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async retrieveEmployeeType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_EMPLOYEETYPE });
    let itemArray = [];
    await myApi.endpoints.GetEmployeeTypeList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveEmployeeType();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveReportingTo() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_REPORTINGTO });
    let itemArray = [];
    await myApi.endpoints.GetReportingToList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveReportingTo();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveDesignation() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_DESIGNATION });
    let itemArray = [];
    await myApi.endpoints.GetDesignationList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveDesignation();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveKYCDocumentType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_KYCDOCUMENT_TYPE });
    let itemArray = [];
    await myApi.endpoints.GetKYCDocumentTypeList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveKYCDocumentType();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveUserMaster(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_USERMASTER });
    let itemArray = [];
    await myApi.endpoints.GetUserMaster.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveUserMaster(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveUserMasterByfilter(param) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_USERMASTER });
    let itemArray = [];
    await myApi.endpoints.GetUserMaster.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveUserMasterByfilter(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  // Save User

  async saveUpdateUserMaster(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_USERMASTER });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateUserMaster.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.saveUpdateUserMaster(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  // User KYC details

  async getUserKYCDetails(userID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_USER_KYC_DETAILS });
    let itemArray = [];
    let id = "?UserID=" + userID;

    await myApi.endpoints.GetUserKYCDetails.getOne(id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getUserKYCDetails(userID);
      });
    }
    return Promise.resolve(itemArray);
  }

  // Save User KYC

  async saveUpdateUserKYCDetails(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_USERKYC_DETAIL });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateUserKYCDetail.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.saveUpdateUserKYCDetails(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  async deleteKYCByKYCID(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_DELETE_USERKYC });
    let itemArray = [];
    let Id = "?KYCIDs=" + id.toString();
    await myApi.endpoints.DeleteUserKYCDetailByKYCID.deleteById(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.deleteKYCByKYCID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getKYCByKYCID(kycID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_KYC_DETAILS_BY_USERID });
    let itemArray = [];
    let Id = "?KYCID=" + kycID;
    await myApi.endpoints.GetUserKYCDetailByKYCID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getKYCByKYCID(kycID);
      });
    }
    return Promise.resolve(itemArray);
  }

  // User Role details

  async getUserRoleDetails(userID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_USER_ROLE_DETAILS });
    let itemArray = [];
    let Id = "?UserID=" + userID;
    await myApi.endpoints.GetUserRoleMappings.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getUserRoleDetails(userID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getUserRoleByID(userrolemappingID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_ROLE_DETAILS_BY_ROLEID });
    let itemArray = [];
    let Id = "?UserRoleMappingID=" + userrolemappingID;
    await myApi.endpoints.GetUserRoleMappingByID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getUserRoleByID(userrolemappingID);
      });
    }

    return Promise.resolve(itemArray);
  }

  async deleteRoleByRoleID(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_DELETE_USERROLE });
    let itemArray = [];
    let Id = "?UserRoleMappingIDs=" + id.toString();
    await myApi.endpoints.DeleteUserRoleMappingByID.deleteById(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.deleteRoleByRoleID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async saveUpdateUserRoleDetails(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_USERROLE_DETAIL });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateUserRoleMapping.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.saveUpdateUserRoleDetails(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }

  //Delete records

  // async deleteUserByUserID(id) {
  //     const myApi = new API({ url: this.config.ITEM_URL })
  //     myApi.createEntity({ name: this.endpoint.GET_DELETEUSERBYID })
  //     let itemArray = [];
  //     await myApi.endpoints.DeleteUserByUserID.deleteByUserId(id)
  //         .then(({ data }) => {
  //             itemArray = data
  //         })
  //         .catch(err => {
  //             this.handleError(err);
  //             console.log(err)
  //         });
  //     return Promise.resolve(itemArray)
  // }

  async getUserDetailByUserID(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_USERDETAILSBYID });
    let itemArray = [];
    let Id = "?UserID=" + id;
    await myApi.endpoints.GetUserDetailByUserID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getUserDetailByUserID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  // User Assignment step

  async retrievePaymentType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_PAYMENT_TYPE });
    let itemArray = [];
    await myApi.endpoints.GetPaymentTypeList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrievePaymentType();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveIronPersonType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_IRON_PERSON_TYPE });
    let itemArray = [];
    await myApi.endpoints.GetIronPersonTypeList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveIronPersonType();
      });
    }

    return Promise.resolve(itemArray);
  }

  async retrieveStayAt() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STAY_AT });
    let itemArray = [];
    await myApi.endpoints.GetStayAtList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStayAt();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveStore() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE });
    let itemArray = [];
    await myApi.endpoints.GetStoreList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStore();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveFactory() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_FACTORIES_LIST });
    let itemArray = [];
    await myApi.endpoints.GetFactoryList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveFactory();
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveRole() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_ROLE });
    let itemArray = [];
    await myApi.endpoints.GetRoleList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveRole();
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default UserService;
