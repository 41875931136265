import React from "react";
import styles from "../../../components/Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
// import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Table,
  notification,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import GatePassList from "../../../services/storegatepasslistservice";
import moment, { isMoment } from "moment";
import Logo from "../../../images/Common/Logo.png";
import html2pdf from "html2pdf.js";
import TransferOutService from "../../../services/storetransferoutservice";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";

const { Search } = Input;
const { Option } = Select;

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.GatePassListService = new GatePassList();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.TransferOutService = new TransferOutService();
    this.common = new Common();

    this.state = {
      gatePassListItems: [],
      gatePassNo: "",
      gatepassDatefrom: moment().subtract(1, "months").date(1),
      gatepassDateTo: moment(new Date()),
      storeIDs: localStorage.getItem("branchID"),
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      showfilterBox: false,
      loading: false,
      userRights: [],
      printInfoHeaderData: {
        gatePassNo: "",
        gatePassDate: "",
        storeName: "",
        factoryName: "",
        factoryAddress: "",
        userName: "",
      },
      printInfoRowData: [],
      isPDFDataLoaded: false,
      gatePassNumber: "",
    };
  }

  columns = [
    // {
    //   title: "GatePassID",
    //   dataIndex: "gatePassID",
    //   key: "gatePassID",
    // },
    {
      title: labels.GATE_PASS_NUMBER,
      dataIndex: "gatePassNo",
      key: "gatePassNo",
      sorter: true,
      render: (text, record) => {
        return (
          <Button
            type="link"
            variant="link"
            onClick={() => {
              this.downloadGatePassPDF(record.gatePassNo);
            }}
          >
            {record.gatePassNo}{" "}
            <Tooltip title="Download">
              {" "}
              <FontAwesomeIcon icon={faDownload} />
            </Tooltip>
          </Button>
        );
      },
    },
    {
      title: labels.STORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
    {
      title: labels.FACTORY_NAME,
      dataIndex: "factoryName",
      key: "factoryName",
      sorter: true,
    },
    {
      title: labels.STORE_GARMENTS_OUT,
      dataIndex: "storeGarmentsOut",
      key: "storeGarmentsOut",
      sorter: true,
    },
    {
      title: labels.TOTAL_SPLIT_NUMBER,
      dataIndex: "noofSplitNumber",
      key: "noofSplitNumber",
      sorter: true,
    },
    {
      title: labels.GATE_PASS_DATE,
      dataIndex: "gatePassDate",
      key: "gatePassDate",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm:ss");
      },
    },
  ];
  element = React.createRef();
  downloadGatePassPDF = (gatepassNumber) => {
    let post = {
      gatePassNumber: gatepassNumber,
    };
    this.setState({ loading: true });
    this.TransferOutService.getPDFGatePass(post).then((res) => {
      this.setState(
        {
          printInfoHeaderData: {
            gatePassNo: res.data.gatePassNo,
            gatePassDate: res.data.gatePassDate,
            storeName: res.data.storeName,
            factoryName: res.data.factoryName,
            factoryAddress: res.data.factoryAddress,
            userName: res.data.userName,
          },
          printInfoRowData: res.data.vMSplitOrderDelivery,
          isPDFDataLoaded: true,
          loading: false,
        },
        () => {
          var opt = {
            margin: 1,
            filename: "StoreGatePass.pdf",
            jsPDF: { format: "a5", orientation: "portrait" },
            pagebreak: { mode: ["css", "legacy"], avoid: "tr" },
            html2canvas: {
              dpi: 192,
              scale: 4,
            },
          };
          html2pdf().set(opt).from(this.element.current).save();
          this.setState({ loading: false });
        }
      );
    });
  };
  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.GATE_PASS_LIST
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  //   componentWillMount() {
  //     let data = [];
  //     for (let i = 0; i < 100; i++) {
  //       let obj = {
  //         key: i,
  //         gatePassNumber: "G00" + i,
  //         storeName: "ECC Store",
  //         factoryName: "ECC Factory",
  //         splitOrderCount: "5",
  //         garmentsOut: "2",
  //         garmentsIn: "1",
  //         pendingGarments: "1",
  //         gatePassCreatedOn: "08/07/2020",
  //       };
  //       data.push(obj);
  //     }
  //     this.setState({ gatePassListItems: data, loading: false });
  //   }

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      gatePassNo: this.state.gatePassNo,
      gatepassDatefrom: this.state.gatepassDatefrom,
      gatepassDateTo: this.state.gatepassDateTo,
      storeIDs: this.state.storeIDs,
      fcatoryIDs: "",
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      // gatePassNo: this.state.gatePassNo,
      gatepassDatefrom: this.state.gatepassDatefrom,
      gatepassDateTo: this.state.gatepassDateTo,
      // storeIDs: this.state.storeIDs,
      // fcatoryIDs: "",
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      gatePassNo: this.state.gatePassNo,
      gatepassDatefrom: this.state.gatepassDatefrom,
      gatepassDateTo: this.state.gatepassDateTo,
      storeIDs: this.state.storeIDs,
      fcatoryIDs: "",
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? null
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.GatePassListService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            gatePassNo: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          gatePassListItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: labels.GATE_PASS_LIST,
          description: items.message
            ? this.common.Message(items.message)
            : labels.UNABLE_TO_GET_LIST_OF_GATE_PASS_DATA,
        });
      }
      this.setState({ loading: false });
    });
  };

  render() {
    const { pagination, loading } = this.state;
    const gatePassListItems = this.state.gatePassListItems;
    const listItems = gatePassListItems;
    gatePassListItems.map((item, index) => ({
      key: item.storeOutID,
      gatePassNo: item.gatePassNo,
      storeOutID: item.storeOutID,
      gatePassDate: item.gatePassDate,
      storeID: item.storeID,
      factoryID: item.factoryID,
      storeGarmentsOut: item.storeGarmentsOut,
      noofSplitNumber: item.noofSplitNumber,
      factoryName: item.factoryName,
      storeName: item.storeName,
      totalRecords: item.totalRecords,
    }));

    let data = <tr></tr>;
    if (this.state.isPDFDataLoaded) {
      data = this.state.printInfoRowData.map((item, index) => {
        return (
          <tr style={{ fontSize: 12 }} key={index}>
            <td>{item.rowNumber}</td>
            <td>{item.splitOrderNumber}</td>
            <td>{item.deliveryType}</td>
            <td>{item.noOfGarments}</td>
            <td>
              {item.pickupDate === ""
                ? null
                : moment(item.pickupDate).format("DD/MM/YYYY")}
            </td>
            <td>
              {item.deliveryDate === ""
                ? null
                : moment(item.deliveryDate).format("DD/MM/YYYY")}
            </td>
          </tr>
        );
      });
    }
    return (
      <div>
        <PageHeader
          title={labels.GATE_PASS_LIST} //"Gate Pass List"
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,

            // <Button
            //     type="link"
            //     className={styles.FilterToggle}
            //     onClick={() => {
            //         this.setState({ showfilterBox: !this.state.showfilterBox });
            //     }}
            // >
            //     {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //     <FontAwesomeIcon
            //         icon={faChevronUp}
            //         className={
            //             this.state.showfilterBox
            //                 ? styles.FilterToggleIcon
            //                 : styles.FilterToggleIconRotated
            //         }
            //     />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />

        {this.state.isPDFDataLoaded ? (
          <div style={{ display: "none" }}>
            <div id="page" ref={this.element} style={{ padding: "10px" }}>
              <table
                align="center"
                style={{
                  width: "100%",
                }}
              >
                <tr
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    Store Gate Pass Number :
                    {this.state.printInfoHeaderData.gatePassNo}
                  </td>
                </tr>
                <tr
                  style={{ fontSize: 12, fontWeight: "bold" }}
                  // key={index}
                >
                  <td>
                    <img src={Logo} alt="Laundrokart" className="img-fluid" />
                  </td>

                  <td style={{ textAlign: "right" }}>
                    Date:
                    {moment(this.state.printInfoHeaderData.gatePassDate).format(
                      "DD/MM/YYYY"
                    )}
                    <br></br>
                    Factory Name:
                    {this.state.printInfoHeaderData.factoryName}
                    <br></br>
                    Factory Address:
                    {this.state.printInfoHeaderData.factoryAddress}
                    <br></br>
                    Store Name:
                    {this.state.printInfoHeaderData.storeName}
                    <br></br>
                    Created By: {this.state.printInfoHeaderData.userName}
                  </td>
                </tr>
              </table>
              <table
                border="1"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
                cellPadding={2}
              >
                <thead>
                  <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                    <th>S.No</th>
                    <th>Split Order Id</th>
                    <th>Delivery Type</th>
                    <th>Number Of Garments</th>
                    <th>Pickup Date</th>
                    <th>Delivery Date</th>
                  </tr>
                </thead>
                <tbody>{data}</tbody>
              </table>
              <br></br>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
