import React from "react";
import styles from "./chartscomponent.module.css";
import {
  Row,
  Col,
  Typography,
  // Select,
  notification,
} from "antd";
import Box from "../../Common/Box/box";
// import BarChartImg from "../../../images/Dashboard/bar.png";
// import RingChart from "../../../images/Dashboard/ring.png";
// import DoubleBarChart from "../../../images/Dashboard/double.png";
import FactoryDashboard from "../../../services/factorydashboardservice";
import {
  ResponsiveContainer,
  BarChart,
  // PieChart,
  XAxis,
  YAxis,
  Tooltip,
   Legend,
  // Cell,
  // Pie,
  Bar,
  // Customized,
  Sector,
} from "recharts";
import Moment from "moment";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";

/**
 * Charts Row as a part of dashboard screen.
 * Bar chart, ring chart and double bar chart
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 15 June 2020
 */
export default class Charts extends React.Component {
  factoryDashboard = new FactoryDashboard();
  common = new Common();
  state = {
    pieActiveIndex: 0,
    dashboardChartData: [],
  };
  barChartData = [
    {
      date: "12",
      orders: 5,
    },
    {
      date: "13",
      orders: 9,
    },
    {
      date: "14",
      orders: 12,
    },
    {
      date: "15",
      orders: 5,
    },
    {
      date: "16",
      orders: 22,
    },
    {
      date: "17",
      orders: 29,
    },
    {
      date: "18",
      orders: 35,
    },
    {
      date: "19",
      orders: 21,
    },
  ];
  ringChartData = [
    {
      name: "Wash and Fold",
      value: 60,
      color: "#FE1D7F",
    },
    {
      name: "Dry Cleaning",
      value: 40,
      color: "#7AE473",
    },
    {
      name: "Wash and Iron",
      value: 20,
      color: "#FCC350",
    },
  ];
  doubleBarChartData = [
    {
      date: "12",
      "Take Away": 9,
      Delivery: 7,
    },
    {
      date: "13",
      "Take Away": 9,
      Delivery: 10,
    },
    {
      date: "14",
      "Take Away": 12,
      Delivery: 10,
    },
    {
      date: "15",
      "Take Away": 8,
      Delivery: 17,
    },
    {
      date: "16",
      "Take Away": 24,
      Delivery: 19,
    },
    {
      date: "17",
      "Take Away": 26,
      Delivery: 32,
    },
    {
      date: "18",
      "Take Away": 35,
      Delivery: 30,
    },
    {
      date: "19",
      "Take Away": 26,
      Delivery: 19,
    },
  ];
  activeShape = (props) => {
    const {
      cx,
      cy,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
    } = props;

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={18}
          textAnchor="middle"
          fill={fill}
          fontSize={36}
        >
          {payload.value}%
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };
  onPieEnter = (data, index) => {
    this.setState({
      pieActiveIndex: index,
    });
  };

  componentWillMount() {
    this.GetFactoryDashboardOrderProcessCount();
  }
  GetFactoryDashboardOrderProcessCount() {
    this.props.toggleLoading(true);
    this.factoryDashboard
      .GetFactoryDashboardOrderProcessCount(
        parseInt(localStorage.getItem("branchID"))
      )
      .then((response) => {
        if (response.statusCode === 200) {
          let res = [];
          let dashboardChartres = [];
          response.data.forEach((element) => {
            res = {
              date: Moment(element.calDate).format("D"),
              "Overdue Garments": element.orderProcessCount,
              "Order":element.orderCount
            };
            dashboardChartres.push(res);
          });

          this.setState({
            dashboardChartData: dashboardChartres,
          });
        } else {
          // notification["error"]({
          //   message: labels.DASHBOARD,
          //   description: labels.UNABLE_TO_GET_DATA_FOR_DASHBOARD,
          // });
          notification["error"]({
            message: labels.DASHBOARD,
            description: response.message
              ? this.common.Message(response.message)
              : labels.UNABLE_TO_GET_DATA_FOR_DASHBOARD,
          });
        }
        this.props.toggleLoading(false);
      });
  }

  GetStoreDashBoardPickUpData() {
    this.props.toggleLoading(true);
    this.storeDashboard
      .GetStoreDashBoardPickUpData(parseInt(localStorage.getItem("branchID")))
      .then((response) => {
        if (response.statusCode === 200) {
          let res = [];
          let dashboardPickupChartres = [];
          response.data.forEach((element) => {
            res = {
              date: Moment(element.calDate).format("D"),
              "Pending Pickups": element.pendingPickups,
            };
            dashboardPickupChartres.push(res);
          });

          this.setState({
            dashboardPickupData: dashboardPickupChartres,
          });
        } else {
          notification["error"]({
            message: labels.DASHBOARD,
            description: labels.UNABLE_TO_GET_DATA_FOR_DASHBOARD,
          });
        }
        this.props.toggleLoading(false);
      });
  }

  render() {
    return (
      <Row
        gutter={28}
        className={styles.ChartsContainer}
        style={{ height: "100%" }}
      >
        <Col xs={24} lg={24}>
          <Box sub className={styles.ChartsWrapper}>
            <div className={styles.ChartsTitleWrap}>
              <Typography.Title level={4}>
                {labels.OVERDUE_GARMENTS}
              </Typography.Title>
            </div>
            <ResponsiveContainer height={250}>
              <BarChart data={this.state.dashboardChartData} mirror={true}>
                <XAxis
                  dataKey="date"
                  tickLine={false}
                  axisLine={{ strokeWidth: 0.5, stroke: "#666666" }}
                  padding={{ right: 1 }}
                  label={{
                    value: labels.DATE,
                    position: "bottom",
                    offset: -4,
                  }}
                />
                <YAxis
                  dataKey="Overdue Garments"
                  tickLine={false}
                  axisLine={{ strokeWidth: 0.5, stroke: "#666666" }}
                  width={35}
                  // ticks={[0, 10, 20, 30, 40]}
                  padding={{ top: 15 }}
                  label={{
                    value: labels.NO_OF_ORDERS,
                    position: "left",
                    offset: 0,
                    angle: -90,
                  }}
                />
                <Tooltip allowEscapeViewBox={false} isAnimationActive={false} />
                <Legend
                    layout="vertical"
                    align="center"
                    verticalAlign="middle"
                    wrapperStyle={{ left: 50, position: "absolute", top: 0 }}
                    iconType="square"
                    iconSize={12}
                  />
                <Bar dataKey="Overdue Garments" fill="#7E13D5" barSize={12} />
                <Bar dataKey="Order" fill="#FE1D7F" barSize={12} />
              </BarChart>
            </ResponsiveContainer>
            {/* <img src={BarChartImg} alt="bar" /> */}
          </Box>
        </Col>
      </Row>
    );
  }
}
