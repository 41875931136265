import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class FingerScanService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
  }
  async getStores() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_STORE });
    let states = [];
    let shouldWait = false;
    await stateListAPI.endpoints[this.endpoint.GET_STORE]
      .getAll()
      .then((response) => {
        states = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          shouldWait = true;
        }
        console.log(error);
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        states = this.getStores();
      });
    }
    return Promise.resolve(states);
  }

  async getFactories() {
    const stateListAPI = new API({ url: this.config.DROPDOWN_URL });
    stateListAPI.createEntity({ name: this.endpoint.GET_FACTORY_LIST });
    let states = [];
    let shouldWait = false;
    await stateListAPI.endpoints[this.endpoint.GET_FACTORY_LIST]
      .getAll()
      .then((response) => {
        states = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          shouldWait = true;
        }
        console.log(error);
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        states = this.getFactories();
      });
    }
    return Promise.resolve(states);
  }

  async getUsers(store, factory) {
    const stateListAPI = new API({ url: this.config.FINGERPRINT_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_USER_BY_STORE_FACTORY,
    });
    let states = [];
    let factoryAppend =
      factory !== null ? "FactoryId=" + parseInt(factory) : "";
    let storeAppend = store !== null ? "StoreID=" + parseInt(store) : "";
    let shouldWait = false;
    await stateListAPI.endpoints[this.endpoint.GET_USER_BY_STORE_FACTORY]
      .getById(
        "?" +
          factoryAppend +
          (factoryAppend !== "" && storeAppend !== "" ? "&" : "") +
          storeAppend
      )
      .then((response) => {
        states = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          shouldWait = true;
        }
        console.log(error);
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        states = this.getUsers(store, factory);
      });
    }
    return Promise.resolve(states);
  }

  async getFingers(userid) {
    const FingersAPI = new API({ url: this.config.FINGERPRINT_URL });
    FingersAPI.createEntity({
      name: this.endpoint.GET_FINGER_PRINT_DATA,
    });
    let respData = [];
    let userID = "UserID=" + parseInt(userid) ;
    await FingersAPI.endpoints[this.endpoint.GET_FINGER_PRINT_DATA]
      .getById(
        "?" +
        userID 
      )
      .then((response) => {
        respData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    return Promise.resolve(respData);
  }

  async saveUpdateFingerPrint(params) {
    const stateListAPI = new API({ url: this.config.FINGERPRINT_URL });
    stateListAPI.createEntity({
      name: this.endpoint.SAVE_UPDATE_FINGERPRINT,
    });
    let states = [];
    let shouldWait = false;
    await stateListAPI.endpoints[this.endpoint.SAVE_UPDATE_FINGERPRINT]
      .create(params)
      .then((response) => {
        states = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          shouldWait = true;
        }
        console.log(error);
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        states = this.saveUpdateFingerPrint(params);
      });
    }
    return Promise.resolve(states);
  }
}
