import React from "react";
import styles from "../../../components/Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
// import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  // Popconfirm,
  Table,
  // Modal,
  // notification,
  DatePicker,
  Tooltip,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  // faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ROUTES from "../../../routes";
import PreQCResponseList from "../../../services/storepreqcresponselistservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
import Moment from "moment";
import Common from "../../../services/common";
const { Search } = Input;
const { Option } = Select;
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.PreQCResponseListService = new PreQCResponseList();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.common = new Common();

    this.state = {
      orderFromDate: Moment(ONE_MONTHS_AGO),
      orderToDate: Moment(),
      preQCResponseItems: [],
      ID: 0,
      listData: [],
      QCStatusOptions: [],
      data: [],
      qcStatusFilter: 1,
      sorter: {
        field: null,
        order: null,
      },
      pagination: {
        showSizeChanger: true,
        // pageSizeOptions: [1, 2, 3, 4],
        current: 1,
        pageSize: 10,
        onChange: (page, pageSize) => {
          this.setState({
            // tableLoading: true,
            pagination: {
              ...this.state.pagination,
              current: page,
              pageSize: pageSize,
            },
          });
        },
        onShowSizeChange: (current, size) => {
          this.setState({
            // tableLoading: true,
            pagination: {
              ...this.state.pagination,
              current: current,
              pageSize: size,
            },
          });
        },
      },
      showfilterBox: true,
      loading: false,
    };
  }

  columns = [
    {
      title: labels.TAG,
      dataIndex: "tagNo",
      key: "tagNo",
    },
    {
      title: labels.CUSTOMER,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: labels.CONTACT,
      dataIndex: "customerContactNumber",
      key: "customerContactNumber",
      sorter: true,
    },
    {
      title: labels.GARMENT,
      dataIndex: "garmentName",
      key: "garmentName",
      sorter: true,
    },
    {
      title: labels.SPLIT_ORDER_NUMBER,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },
    {
      title: labels.LAST_UPDATED_ON,
      dataIndex: "lastUpdatedOn",
      key: "lastUpdatedOn",
      render: (text) =>
        text !== null ? Moment(text).format("DD-MM-YYYY HH:mm:ss") : null,
      sorter: true,
    },
    {
      title: labels.LAST_UPDATED_BY,
      dataIndex: "lastUpdatedBy",
      key: "lastUpdatedBy",
      sorter: true,
    },

    {
      title: labels.QC_REQUEST,
      dataIndex: "finalQCStatusName",
      key: "finalQCStatusName",
      sorter: true,
    },
    {
      title: labels.ACTION,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title={labels.VIEW}>
              <Link
                to={{
                  pathname: [ROUTES.VIEW_PRE_QC_STORE, record.tagNo].join("/"),
                  state: { qcID: record.tagNo },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {record.finalQCStatus === 1 ? (
            <React.Fragment>
              {this.state.userRights.length > 0 &&
              this.state.userRights[0].isUpdate == true ? (
                <Tooltip title={labels.EDIT}>
                  <Link
                    to={{
                      pathname: [ROUTES.EDIT_PRE_QC_STORE, record.tagNo].join(
                        "/"
                      ),
                      state: { qcID: record.tagNo },
                    }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Link>
                </Tooltip>
              ) : null}
            </React.Fragment>
          ) : null}
        </Space>
      ),
    },
  ];

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.STORE_MANAGE_QC
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  componentDidMount() {
    this.getResponseList();
  }
  getResponseList = () => {
    this.setState({ loading: true });
    if (this.props.toggleLoading !== undefined) {
      this.props.toggleLoading(true);
    }
    this.PreQCResponseListService.getResponseList({
      storeID: parseInt(localStorage.getItem("branchID")),
      FromOrderDate: Moment(this.state.orderFromDate).format("YYYY-MM-DD"),
      ToOrderDate: Moment(this.state.orderToDate).format("YYYY-MM-DD"),
      qcStatus: this.state.qcStatusFilter,
      tagNo: null,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      sortColumn: this.state.sorter.field,
      sortOrder: this.state.sorter.order,
      commonSearch: this.state.search,
    }).then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          listData: response.data,
          pagination: {
            ...this.state.pagination,
            total:
              response.data.length !== 0 ? response.data[0].totalRecords : 0,
          },
          loading: false,
        });
      } else {
        notification["error"]({
          message: labels.PRE_QC_RESPONSES,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_PRE_QC_RESPONSE_LIST,
        });
      }
      if (this.props.toggleLoading !== undefined) {
        this.props.toggleLoading(false);
      }
    });
  };

  //function for handling search change event
  handleSearch = (event) => {
    this.setState({ search: event.target.value }, () => this.getResponseList());
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.setState(
      {
        sorter: {
          ...sorter,
          order:
            sorter.order === "ascend"
              ? "asc"
              : sorter.order === "descend"
              ? "desc"
              : sorter.order,
        },
      },
      () => this.getResponseList()
    );
  };

  //function for get data from server call
  // fetchItems = (params = {}) => {
  //      this.PreQCResponseListService.retrieveItems(params).then(items => {
  //          if(items.statusCode===200){
  //      if(items.length!==0 && items.data.length===0){
  //             params.pagination.current=1;
  //             this.fetchItems({
  //                 ID:0,
  //                 pageIndex:1,
  //                 pageSize:params.pageSize,
  //                 pagination:params.pagination
  //             })
  //         };
  //         this.setState({ preQCResponseItems: items.data,
  //          pagination: {
  //                 ...params.pagination,
  //                 total: items.length!==0  && items.data.length!==0 ? items.data[0].totalRecords : 0
  //             }
  //         });
  //            }else{
  //              notification["error"]({
  //       message: "Unable to get list of pre-qc response list data",
  //     });
  //  }
  //         this.setState({loading:false})
  //     });
  // }

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  render() {
    const { pagination, loading } = this.state;
    const preQCResponseItems = this.state.preQCResponseItems;
    const listItems = preQCResponseItems;
    return (
      <div>
        <PageHeader
          title={labels.PRE_QC_RESPONSE_LIST}
          extra={[
           
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,

            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() => this.getResponseList()}
            initialValues={{
              orderFromDate: this.state.orderFromDate,
              orderToDate: this.state.orderToDate,
              qcStatusFilter:this.state.qcStatusFilter
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderFromDate")
                    }
                    value={this.state.orderFromDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderToDate")
                    }
                    value={this.state.orderToDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(Moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
              <Form.Item
                  name={"qcStatusFilter"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_QC_STATUS },
                  ]}
                >
              <Select
              showSearch
              placeholder={labels.SELECT_QC_STATUS}
              defaultValue={this.state.qcStatusFilter}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) =>
                this.setState(
                  {
                    qcStatusFilter: value,
                    pagination: { ...this.state.pagination, current: 1 },
                  },
                  // () => this.getResponseList()
                )
              }
            >
              <Option value={1}>Pending</Option>
              <Option value={2}>Approved</Option>
              <Option value={3}>Rejected</Option>

              {/* {this.state.QCStatusOptions.length !== 0
                            ? this.state.QCStatusOptions.map((status) => {
                                return status.value !== "-1" ? (
                                    <Option value={status.value} key={status.value}>
                                    {status.text}
                                    </Option>
                                ) : null;
                                })
                            : null} */}
            </Select>
            </Form.Item>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.listData}
          rowKey={(record) => record.tagNo}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}
