import React from 'react';
import DeliveryDashboard from '../../../components/StoreModule/DeliveryDashboard/deliverydashboard.component';

export default class deliverydashboardScreen extends React.Component {
  render() {
    return (
      <div>
        <DeliveryDashboard></DeliveryDashboard>
      </div>
     
    )
  }
}
