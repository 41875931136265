import React from "react";
import GarmentColorMaster from "../../../components/Master/GarmentColor/garmentcolormaster.component"
export default class GarmentColorScreen extends React.Component {
  render() {
    return (
      <div >
        <GarmentColorMaster></GarmentColorMaster>
      </div>
    );
  }
}