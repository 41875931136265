import React from "react";
import {
  PageHeader,
  Input,
  Select,
  Table,
  notification,
  Tooltip,
  Checkbox,
  Row,
  Col,
  Form,
  DatePicker,
  Typography
} from "antd";
import styles from "../../Common/Styles/master.module.css";
import Storeinpendingservice from "../../../services/storeinpendingservice";
import moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
const { Search } = Input;
const { Option } = Select;
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);
const { Text } = Typography;

export default class Master extends React.Component {
  storeInPendingService = new Storeinpendingservice();
  UserRightsConfiguration = new UserRightsConfiguration();
  state = {
    showZeroDayDelay: 0,
    showOneDayDelay: 0,
    factories: [],
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    showfilterBox: true,
    orderFromDate: moment(ONE_MONTHS_AGO),
    orderToDate: moment(),
    selectedBranch: localStorage.getItem("branchID"),
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.STORE_IN_PENDING
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  columns = [
    {
      title: labels.SPLIT_ORDER_ID,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },

    {
      title: labels.STICKER_ID,
      dataIndex: "stickerId",
      key: "stickerId",
      sorter: true,
    },

    {
      title: labels.PACKAGING_ID,
      dataIndex: "packagingIds",
      key: "packagingIds",
      sorter: true,
      width: "8%",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: labels.GATEPASSNUMBER,
      dataIndex: "gatePassNumber",
      key: "gatePassNumber",
      sorter: true,
    },
    {
      title: labels.STORE_CODE,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
    {
      title: labels.PACKED_ON_DATE,
      dataIndex: "packedDate",
      key: "packedDate",
      sorter: true,
      render: (text) => {
        return ![undefined, null, ""].includes(text)
          ? moment(text).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: labels.FACTORY_OUT_DATE,
      dataIndex: "factoryOutDateTime",
      key: "factoryOutDateTime",
      sorter: true,
      render: (text) => {
        return ![undefined, null, ""].includes(text)
        ? moment(text).format("DD-MM-YYYY HH:mm:ss")
        : "";
      },
    },
    {
      title: labels.TOTAL_GARMENTS,
      dataIndex: "noofGarment",
      key: "noofGarment",
      sorter: true,
    },
    {
      title: labels.PACKED_GARMENTS,
      dataIndex: "packedGarments",
      key: "packedGarments",
      sorter: true,
    },

    {
      title: labels.SPLIT_ORDER_STATUS,
      dataIndex: "splitOrderStatus",
      key: "splitOrderStatus",
      sorter: true,
    },

    {
      title: labels.DELAY_DAYS,
      dataIndex: "delay",
      key: "delay",
      sorter: true,
    },
  ];

  componentDidMount() {
    this.getstoreinpendingList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  }

  getstoreinpendingList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    if (!this.state.tableLoading) {
      this.setState({ tableLoading: true });
      this.storeInPendingService
        .getstoreinpendingList(
          index,
          size,
          sortColumn,
          sortOrder,
          search,
          this.state.selectedBranch,
          moment(this.state.orderFromDate).format("YYYY-MM-DD"),
          moment(this.state.orderToDate).format("YYYY-MM-DD"),
          this.state.showZeroDayDelay,
          this.state.showOneDayDelay
        )
        .then((response) => {
          if (response.statusCode === 200) {
            if (
              response.data.length === 0 &&
              this.state.pagination.current !== 1
            ) {
              this.setState(
                {
                  pagination: { ...this.state.pagination, current: 1 },
                  tableLoading: false,
                },
                () => {
                  this.getstoreinpendingList(
                    1,
                    size,
                    sortColumn,
                    sortOrder,
                    search
                  );
                }
              );
            }
            let factoryData = [];
            response.data.map((singleData) => {
              //   singleData.key = singleData.splitOrderNumber;
              factoryData.push(singleData);
              return null;
            });
            this.setState({ factories: factoryData });
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: factoryData.length ? factoryData[0].totalRecords : 0,
              },
            });
          } else {
            notification["error"]({
              message: labels.STORE_IN_PENDING,
              description: response.message
                ? response.message
                : labels.UNABLE_TO_GET_LIST_OF_STORE_IN_PENDING,
            });
          }
          this.setState({ tableLoading: false });
        });
    }
  };

  handleChangeBranch = () => {
    this.getstoreinpendingList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };
  render() {
    const storeBranchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo")).filter(
          (x) => x.branchType === "Store"
        )
      : [];

    const allStoreBranchArray = storeBranchTypes
      .map((branchType) => {
        return branchType.branchID;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();
    return (
      <div>
        <PageHeader
          title={labels.STORE_IN_PENDING}
          extra={[
            <div key="1" style={{ alignSelf: "center" }}>
              <Select
                showSearch
                mode="multiple"
                allowClear
                // onChange={this.handleSelect}
                onChange={(event) => {
                  this.setState(
                    {
                      selectedBranch: [null, undefined, ""].includes(event)
                        ? ""
                        : event.join(","),
                    },
                    () => {
                      this.handleChangeBranch();
                    }
                  );
                }}
                value={
                  [null, undefined, ""].includes(this.state.selectedBranch)
                    ? [localStorage.getItem("branchID")]
                    : this.state.selectedBranch.split(",")
                }
                placeholder={labels.SELECT_STORE}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ minWidth: 150, maxWidth: 330 }}
              >
                {storeBranchTypes.length !== 0
                  ? storeBranchTypes.map((branchType) => {
                      return branchType.branchID !== "-1" ? (
                        <Option
                          value={branchType.branchID.toString()}
                          key={branchType.branchID.toString()}
                        >
                          {branchType.branchName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </div>,
            <div key="2" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={
                  ![null, undefined, ""].includes(this.state.selectedBranch) &&
                  this.state.selectedBranch.split(",").length ===
                    allStoreBranchArray.length &&
                  this.state.selectedBranch.split(",").slice().sort()
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    let allOpt = storeBranchTypes
                      .map((branchType) => {
                        return branchType.branchID;
                      })
                      .filter((x) => x !== "-1");
                    this.setState(
                      {
                        selectedBranch: allOpt.join(","),
                      },
                      () => {
                        if (this.state.selectedBranch !== "") {
                          this.handleChangeBranch();
                        }
                      }
                    );
                  } else {
                    this.setState(
                      {
                        selectedBranch: localStorage.getItem("branchID"),
                      },
                      () => {
                        this.handleChangeBranch();
                      }
                    );
                  }
                }}
              >
                {labels.ALL}
              </Checkbox>
            </div>,
            <div key="3" style={{ alignSelf: "center" }}>
              <Search
                placeholder={labels.SEARCH}
                className={styles.SearchInput}
                key="1"
                onChange={(event) => {
                  this.setState({
                    search: event.target.value.toString().toLowerCase(),
                  });
                  this.getstoreinpendingList(
                    this.state.pagination.current,
                    this.state.pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    event.target.value.toString().toLowerCase()
                  );
                }}
              />
            </div>,
            <Button
              key="7"
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />

        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.getstoreinpendingList(
                this.state.pagination.current,
                this.state.pagination.pageSize,
                this.state.sorter.field,
                this.state.sorter.order,
                this.state.search
              )
            }
            initialValues={{
              orderFromDate: this.state.orderFromDate,
              orderToDate: this.state.orderToDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderFromDate")
                    }
                    value={this.state.orderFromDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(moment(), "day") ? true : false
                    }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"orderToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "orderToDate")
                    }
                    value={this.state.orderToDate}
                    disabledDate={(currentDate) =>
                      currentDate.isAfter(moment(), "day") ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={18} lg={2}>
                <Checkbox
                  checked={this.state.showZeroDayDelay}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.setState(
                        {
                          showZeroDayDelay: 1,
                        },           
                      );
                    } else {
                      this.setState(
                        {
                          showZeroDayDelay: 0,
                        },
                      );
                    }
                  }}
                >
                  <Text style={{ fontSize: 18, fontWeight: "bold" }}> 0D</Text>
                </Checkbox>
              </Col>
              <Col xs={18} lg={2}>
                <Checkbox
                  checked={this.state.showOneDayDelay}
                  onChange={(e) => {
                    if (e.target.checked) {
                      this.setState(
                        {
                          showOneDayDelay: 1,
                        },
                      );
                    } else {
                      this.setState(
                        {
                          showOneDayDelay: 0,
                        },
                      );
                    }
                  }}
                >
                  <Text style={{ fontSize: 18, fontWeight: "bold" }}> 1D</Text>
                </Checkbox>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          scroll={
            this.columns.length < 4
              ? { x: 0 }
              : this.columns.length < 6
              ? { x: 1000 }
              : this.columns.length > 6 && this.columns.length <= 12
              ? { x: 1400 }
              : { x: 2000 }
          }
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getstoreinpendingList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />
      </div>
    );
  }
}
