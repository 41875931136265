import React from "react";
import Button from "../../../Common/Button/button";
import {
  Row,
  Col,
  Form,
  Input,
  Radio,
  Select,
  TimePicker,
  notification,
} from "antd";
import UserService from "../../../../services/userservice";
import FactoryService from "../../../../services/factoryservice";
import styles from "./createuser.module.css";

const { Option } = Select;
/**
 * A form as a part of create store - General Section/Step
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 2 June 2020
 *
 * @module Store
 */
export default class UserAssignment extends React.Component {
  constructor(props) {
    super(props);
    this.userservice = new UserService();
    this.factoryService = new FactoryService();
    this.state = {
      isEditmode: false,
      isViewmode: false,
      payType: [],
      ironType: [],
      stayAt: [],
      store: [],
      factory: [],
      operatingDays: {
        disabled: true,
        data: [],
      },
    };
  }

  componentDidMount() {
    this.setState({
      isEditmode: this.props.isEditmode,
      isViewmode: this.props.isViewmode,
    });
    this.getPaymentType();
    this.getIronPersonType();
    this.getStayAt();
    this.getStore();
    this.getFactory();
    this.getOperatingDays();
  }

  getOperatingDays = () => {
    this.factoryService.getOperatingDaysList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          operatingDays: {
            ...this.state.operatingDays,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Users",
          description: response.message
            ? response.message
            : "Unable to get data for working days",
        });
      }
    });
  };

  getPaymentType = () => {
    this.userservice.retrievePaymentType().then((payType) => {
      if (payType.statusCode === 200) {
        this.setState({ payType: payType.data });
      } else {
        notification["error"]({
          message: "Users",
          description: "Unable to get data for payment type",
        });
      }
    });
  };

  getIronPersonType = () => {
    this.userservice.retrieveIronPersonType().then((ironType) => {
      if (ironType.statusCode === 200) {
        this.setState({ ironType: ironType.data });
      } else {
        notification["error"]({
          message: "Users",
          description: "Unable to get data for iron type",
        });
      }
    });
  };

  getStayAt = () => {
    this.userservice.retrieveStayAt().then((stayAt) => {
      if (stayAt.statusCode === 200) {
        this.setState({ stayAt: stayAt.data });
      } else {
        notification["error"]({
          message: "Users",
          description: "Unable to get data for stay at",
        });
      }
    });
  };

  getStore = () => {
    this.userservice.retrieveStore().then((store) => {
      if (store.statusCode === 200) {
        this.setState({ store: store.data });
      } else {
        notification["error"]({
          message: "Users",
          description: "Unable to get data for store",
        });
      }
    });
  };

  getFactory = () => {
    this.userservice.retrieveFactory().then((factory) => {
      if (factory.statusCode === 200) {
        this.setState({ factory: factory.data });
      } else {
        notification["error"]({
          message: "Users",
          description: "Unable to get data for factory",
        });
      }
    });
  };

  render() {
    let RegexDecimal = /^[0-9]?$/g;
    const disabled = this.props.mode === "view";
    return (
      <Form
        layout="vertical"
        name="UserAssignment"
        initialValues={this.props.values}
        ref={this.props.instance}
        onFinish={this.onFinishGeneralForm}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24}>
            <Form.Item label="Store" name="storeID">
              <Select
                mode="multiple"
                placeholder="Select Store"
                disabled={disabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {/* {this.state.store.map((stores) => <option key={stores.value} value={stores.value}>{stores.text}</option>)} */}
                {this.state.store.map((stores) => {
                  return stores.value !== "-1" ? (
                    <Option value={stores.value} key={stores.value}>
                      {stores.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label="Factory" name="factoryID">
              <Select
                mode="multiple"
                placeholder="Select Factory"
                disabled={disabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {/* {this.state.factory.map((factories) => <option key={factories.value} value={factories.value}>{factories.text}</option>)} */}
                {this.state.factory.map((factories) => {
                  return factories.value !== "-1" ? (
                    <Option value={factories.value} key={factories.value}>
                      {factories.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              label="Working Days"
              name="workingDays"
              rules={[
                { required: true, message: "Please Select Working Days" },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select Working Days"
                disabled={disabled}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.operatingDays.data.length !== 0
                  ? this.state.operatingDays.data.map((operatingDay) => {
                      return operatingDay.value !== "-1" ? (
                        <Option
                          value={operatingDay.value}
                          key={operatingDay.value}
                        >
                          {operatingDay.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            {/**
             * For More Clarity over what <TimePicker> Component can do
             * Please go through https://ant.design/components/time-picker/
             * https://ant.design/components/time-picker/#API
             */}
            <Form.Item
              label="Working Time From"
              name="workingTimeFrom"
              rules={[
                { required: true, message: "Please Select Working Time From" },
              ]}
            >
              <TimePicker format={"h:mm a"} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Working Time To"
              name="workingTimeTo"
              rules={[
                { required: true, message: "Please Select Working Time To" },
              ]}
            >
              <TimePicker format={"h:mm a"} disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={6}>
            <Form.Item label="Iron Person" name="isIronPerson">
              <Radio.Group disabled={disabled}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="Iron Person Type" name="ironPersonType">
              <Select placeholder="Select Iron Person Type" disabled={disabled}>
                {/* {this.state.ironType.map((ironType) => <option key={ironType.value} value={ironType.value}>{ironType.text}</option>)} */}
                {this.state.ironType.map((ironType) => {
                  return ironType.value !== "-1" ? (
                    <Option value={ironType.value} key={ironType.value}>
                      {ironType.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Payment Type"
              name="paymentType"
              rules={[
                { required: true, message: "Please Select Payment Type" },
              ]}
            >
              <Select placeholder="Select Payment Type" disabled={disabled}>
                {/* {this.state.payType.map((payType) => <option key={payType.value} value={payType.value}>{payType.text}</option>)} */}
                {this.state.payType.map((payType) => {
                  return payType.value !== "-1" ? (
                    <Option value={payType.value} key={payType.value}>
                      {payType.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item label="Stay At" name="stayAt">
              <Select placeholder="Select Stay At" disabled={disabled}>
                {/* {this.state.stayAt.map((stayAt) => <option key={stayAt.value} value={stayAt.value}>{stayAt.text}</option>)} */}
                {this.state.stayAt.map((stayAt) => {
                  return stayAt.value !== "-1" ? (
                    <Option value={stayAt.value} key={stayAt.value}>
                      {stayAt.text}
                    </Option>
                  ) : null;
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Leave Per Month"
              name="leavePerMonth"
              rules={[
                { required: false },
                {
                  pattern: RegexDecimal,
                  message: "Please Enter Valid Leave Per Month",
                },
              ]}
            >
              <Input placeholder="Enter Leave Per Month" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={16}>
            <Form.Item label="Comments" name="comments">
              <Input placeholder="Enter Comments" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} className={styles.ButtonContainer}>
            <Button
              variant="transparentGreyOutline"
              className={styles.CancelButton}
              shape="round"
              size="large"
              onClick={this.props.backFunction}
            >
              Previuos
            </Button>
            {!disabled ? (
              <Button
                // htmlType="submit"
                variant="purple"
                shape="round"
                size="large"
                onClick={this.props.nextFunction}
              >
                Submit
              </Button>
            ) : null}
          </Col>
        </Row>
      </Form>
    );
  }
}
