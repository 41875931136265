import React from "react";
import styles from "./dailysheet.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import {
  PageHeader,
  Input,
  Select,
  Table,
  Row,
  Col,
  Form,
  notification,
  Checkbox,
  Space,
  Tooltip,
  Modal,
  Spin,
  DatePicker,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactToPrint from "react-to-print";
import {
  faChevronUp,
  faFileExcel,
  faInfo,
  // faPencilAlt,
  // faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { IsAuthenticated } from "../../../services/auth-header";
import Dailysheetservice from "../../../services/dailysheetservice";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Moment from "moment";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);

export default class expressfactorycomponent extends React.Component {
  UserRightsConfiguration = new UserRightsConfiguration();
  Dailysheetservice = new Dailysheetservice();
  common = new Common();

  state = {
    showfilterBox: true,
    FromDate: Moment(ONE_MONTHS_AGO),
    ToDate: Moment(),
    expressData: [],
    loading: true,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    SplitOrderNumber: "",
    showModal: false,
    printDate: Moment(),
    IsPrint: false,
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.EXPRESS_URGENT
    );
    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: labels.SR_NO,
      dataIndex: "srNo",
      key: "srNo",
      render: (text) => {
        return text != 0 ? text : "";
      },
    },
    {
      title: "Store Code",
      dataIndex: "shortName",
      key: "shortName",
    },
    {
      title: "Order ID",
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
    },

    {
      title: "Lot",
      dataIndex: "noOfGarment",
      key: "noOfGarment",
    },
    {
      title: "Factory-In Date",
      dataIndex: "factoryInTime",
      key: "factoryInTime",
      render: (text) => {
        return text != "" ? Moment(text).format("YYYY-MM-DD") : "";
      },
    },
    {
      title: "Delivery Date",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      render: (text) => {
        return text != "" ? Moment(text).format("YYYY-MM-DD") : "";
      },
    },
    {
      title: "Order Remarks",
      dataIndex: "orderRemarks",
      key: "orderRemarks",
      render: (text) => {
        return {
          props: {
            style: { background: text == "Urgent Order" ? "#a9d08e" : null },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Packed",
      dataIndex: "noofPackedgarments",
      key: "noofPackedgarments",
      width: "7%",
    },
    {
      title: "Packing Due",
      dataIndex: "packingDue",
      key: "packingDue",
      width: "7%",
    },
    {
      title: "Remark",
      dataIndex: "remarks",
      key: "remarks",
      width: "16%",
    },
  ];

  componentDidMount() {
    this.getExpressorderData();
  }

  getExpressorderData = () => {
    let Param = {};
    Param = {
      FromDate: Moment(this.state.FromDate).format("YYYY-MM-DD"),
      ToDate: Moment(this.state.ToDate).format("YYYY-MM-DD"),
    };
    this.setState({ loading: true });

    this.Dailysheetservice.getDailyExpressOrderData(Param).then((response) => {
      if (response.statusCode === 200) {
        this.setState({ expressData: response.data });
      } else {
        notification["error"]({
          message: labels.EXPRESS_URGENT,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_EXPRESS_URGENT_DATA,
        });
      }
      this.setState({ loading: false });
    });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleOk = () => {
    this.setState({ loading: true, showModal: false });
    let Param = {};
    Param = {
      SplitOrderNumber: this.state.SplitOrderNumber,
    };

    this.Dailysheetservice.AddUrgentOrder(Param).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.EXPRESS_URGENT,
          description: response.message,
        });
      } else {
        notification["error"]({
          message: labels.EXPRESS_URGENT,
          description: response.message,
        });
      }
      this.setState({ loading: false });
    });

    this.setState({ SplitOrderNumber: "" });
    this.getExpressorderData();
  };

  render() {
    const { loading } = this.state;
    const expressData = this.state.expressData;
    // console.log("expressData->", expressData);
    const listItems = expressData.map((item) => ({
      srNo: item.srNo,
      splitOrderNumber: item.splitOrderNumber,
      shortName: item.shortName,
      noOfGarment: item.noOfGarment,
      noofPackedgarments: item.noofPackedgarments,
      packingDue: item.packingDue,
      factoryInTime: item.factoryInTime,
      deliveryDate: item.deliveryDate,
      orderRemarks: item.orderRemarks,
      isSummary: item.isSummary,
    }));

    return (
      <div>
        <PageHeader
          title={labels.EXPRESS_URGENT}
          extra={[
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
              key="3"
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() => {
              this.getExpressorderData();
              this.setState({
                printDate: this.state.ToDate,
              });
            }}
            initialValues={{
              FromDate: this.state.FromDate,
              ToDate: this.state.ToDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"FromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "FromDate")}
                    value={this.state.FromDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(Moment(), "day") ? true : false
                    // }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"ToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "ToDate")}
                    value={this.state.ToDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(Moment(), "day") ? true : false
                    // }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={2} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
              <Col xs={24} lg={9} className={styles.PrintContainer}>
                <ReactToPrint
                  content={() => this.componentRef}
                  trigger={() => {
                    return (
                      // <a href="#">Print this out!</a>
                      <Button
                        // htmlType="submit"
                        variant="whitePink"
                        shape="round"
                        size="large"
                        onClick={() => {
                          this.setState({ IsPrint: true });
                        }}
                      >
                        Print
                      </Button>
                    );
                  }}
                ></ReactToPrint>

                <Button
                  // htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  onClick={() => this.setState({ showModal: true })}
                >
                  Add Urgent Order
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <PrintContainer
          ref={(el) => (this.componentRef = el)}
          listItems={listItems}
          columns={this.columns}
          loading={loading}
          printDate={this.state.printDate}
          IsPrint={this.state.IsPrint}
        />

        <style>{
          // print page style
          `
          .order-date{
            display : none;
          }

          
          @media print {
            .order-date{
              display : block;
              text-align : center;
            }

            @page {
              size: auto;
              margin: 0mm 0mm 0mm 5mm;
            }

            table thead {
              display: table-row-group !important;
            }
           
            table {
              width: 100%;
              text-align: center;
            }
            
            table,  th, td {
              border: 1px solid black !important;
              text-align: center !important;
              
            }
          }
          `
        }</style>
        <Modal
          title="Add Urgent Order"
          visible={this.state.showModal}
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}
          className="modal-form"
          destroyOnClose
        >
          <div>
            <Input
              placeholder="Enter Urgent Order"
              value={this.state.SplitOrderNumber}
              // disabled={this.state.isEnabled}
              onChange={(event) =>
                this.setState({
                  SplitOrderNumber: event.target.value,
                })
              }
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export class PrintContainer extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <h1 className="order-date">
          Express Orders {this.props.printDate.format("DD-MM-YYYY")}
        </h1>
        <Table
          className="dataTable"
          // ref={el => (this.componentRef = el)}
          size="small"
          onRow={(record, rowIndex) => {
            return {
              style: {
                background: record.isSummary ? "#a0c4e4" : null,
                fontWeight: record.isSummary ? "bold" : null,
                color: record.isSummary ? "black" : null,
              },
            };
          }}
          columns={this.props.columns}
          dataSource={this.props.listItems}
          pagination={false}
          loading={this.props.loading}
        />
      </div>
    );
  }
}
