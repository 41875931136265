import React from "react";
import PickupList from "../../../../components/StoreModule/Pickup/CreatePickup/pickuplist.component";

/**
 * Create PickUp Screen
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 13 July 2020
 */
export default class PickupScreen extends React.Component {
  render() {
    return <PickupList></PickupList>;
  }
}
