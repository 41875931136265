import React from "react";
import StoreInventory from "../../../components/StoreModule/Inventory/inventory.store.component";

export default class StoreInventoryScreen extends React.Component {
  render() {
    return (
      <div>
        <StoreInventory />
      </div>
    );
  }
}
