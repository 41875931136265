import React from "react";
import {
  PageHeader,
  notification,
  Select,
  Table,
  InputNumber,
  Input,
  Form,
  Tooltip,
} from "antd";
import Button from "../../Common/Button/button";
import styles from "../../Common/Styles/master.module.css";
import DiscountMasterService from "../../../services/discountmasterservice";
import { withRouter } from "react-router-dom";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
// const { Search } = Input;

class DiscountCode extends React.Component {
  constructor(props) {
    super(props);
    this.discountmasterservice = new DiscountMasterService();
    this.state = {
      generateButtonLoading: false,
      excelFile: <React.Fragment></React.Fragment>,

      discountID: null,
      totalLength: null,
      prefixChar: "",
      discountObj: {},
      discountCode: [],
      discountName: this.props.discountName,
      showAddPopup: false,

      tableLoading: true,
      currentCode: {},
      popupMode: "add",

      usageTypeID: this.props.usageTypeID,
      DiscountStatusID: this.props.DiscountStatusName,
    };
    //this.GenerateDiscountCodes = this.GenerateDiscountCodes.bind(this);
  }

  componentDidMount() {
    if (!this.props.match.params.discountID) {
      this.props.history.goBack();
    } else {
      //this.getDiscountMaster(this.props.match.params.discountID);
    }
    this.setState({ discountID: this.props.match.params.discountID });
    this.getDiscountCode(this.props.match.params.discountID);
  }
  getDiscountMaster = (id) => {
    this.setState({ tableLoading: true });
    this.discountmasterservice
      .getDiscountDetailByDiscountID(id)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({ discountObj: response.data });
        } else {
          notification["error"]({
            message: "Discount",
            description: "Unable to get discount master data",
            // description: "Redirecting....",
            onClose: () => {
              this.props.history.goBack();
            },
          });
        }
        this.setState({ tableLoading: false });
      });
  };

  getDiscountCode = (id) => {
    this.setState({ tableLoading: true });
    this.discountmasterservice
      .GetDiscountCodesByDiscountID(id)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({ discountCode: response.data });
        } else {
          notification["error"]({
            message: "Discount",
            description: "Unable to get discount codes",
            // description: "Redirecting....",
            onClose: () => {
              this.props.history.goBack();
            },
          });
        }
        this.setState({ tableLoading: false });
      });
  };

  GenerateDiscountCodes = (id) => {
    if (
      [null, undefined, ""].includes(this.state.totalLength) ||
      [null, undefined, ""].includes(this.state.prefixChar)
    ) {
      notification["error"]({
        message: "Discount",
        description: "Values cannot be passed as blank",
      });
      return false;
    }
    this.setState({ generateButtonLoading: true });
    var payload = {
      discountID: parseInt(this.state.discountID),
      totalLength: this.state.totalLength,
      prefixChar: this.state.prefixChar,
    };
    this.discountmasterservice
      .GenerateDiscountCodesByDiscountID(payload)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Discount",
            description: response.message,
          });
          this.getDiscountCode(parseInt(this.state.discountID));
        }
        // else {
        //   notification["error"]({
        //     message: "Unable to get discount master data",
        //     description: "Redirecting....",
        //     // onClose: () => {
        //     //   this.props.history.goBack();
        //     // },
        //   });
        // }
        this.setState({ generateButtonLoading: false });
      });
  };

  exportDiscountCodeToExcel = () => {
    this.setState(
      {
        excelFile: (
          <div>
            <ExcelFile hideElement={true} filename={"DiscountCodes"}>
              <ExcelSheet
                data={this.state.discountCode}
                name={this.state.discountName}
                columns={[]}
              >
                <ExcelColumn label="Discount Codes" value="code" />
              </ExcelSheet>
            </ExcelFile>
          </div>
        ),
      },
      () => this.setState({ excelFile: <React.Fragment></React.Fragment> })
    );
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  columns = [
    {
      title: "Discount Code",
      dataIndex: "code",
      key: "code",
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   width: "100px",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Button
    //         type="link"
    //         variant="link"
    //         onClick={() => this.handleViewEditAddress(record, "view")}
    //       >
    //         <FontAwesomeIcon icon={faInfo} />
    //       </Button>
    //       <Button
    //         type="link"
    //         variant="link"
    //         onClick={() => this.handleViewEditAddress(record, "edit")}
    //       >
    //         <FontAwesomeIcon icon={faPencilAlt} />
    //       </Button>
    //       <Popconfirm
    //         title="Are you sure ?"
    //         onConfirm={() => this.handleDeleteAddress(record.customerAddressID)}
    //         onCancel={() => null}
    //         okText="Yes"
    //         cancelText="No"
    //       >
    //         <Button type="link">
    //           <FontAwesomeIcon icon={faTrash} />
    //         </Button>
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ];
  render() {
    const disableAll = this.state.popupMode === "view";
    let RegexAlphaNumberic = /^[a-zA-Z0-9]*$/;
    return (
      <div>
        {this.state.excelFile}
        <PageHeader
          className={styles.SubHeader}
          title="Discount Codes"
          extra={[
            [1].includes(this.state.DiscountStatusID) ? (
              this.state.usageTypeID === 1 &&
              this.state.discountCode.length === 0 ? (
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>No Of Character</div>
                      <div>Minimum: 6</div>
                      <div>Maximum: 16 must be there</div>
                    </React.Fragment>
                  }
                >
                  <InputNumber
                    key="1"
                    max={16}
                    min={6}
                    placeholder="Length"
                    step={1}
                    precision={0}
                    type="number"
                    onChange={(value) => this.setState({ totalLength: value })}
                  />
                </Tooltip>
              ) : null
            ) : null,
            [1].includes(this.state.DiscountStatusID) ? (
              this.state.usageTypeID === 1 &&
              this.state.discountCode.length === 0 ? (
                <Tooltip title="Prefix Character">
                  <Input
                    key="2"
                    placeholder="Prefix"
                    style={{ maxWidth: 70 }}
                    maxLength={2}
                    onChange={(e) => {
                      let inputVal = e.target.value;
                      if (RegexAlphaNumberic.test(inputVal)) {
                        this.setState({ prefixChar: e.target.value });
                      } else {
                        notification["error"]({
                          message: "Discount",
                          description:
                            "You can only enter Alpha-Numeric inputs",
                        });
                      }
                    }}
                  />
                </Tooltip>
              ) : null
            ) : null,

            this.state.usageTypeID === 1 ? (
              [1].includes(this.state.DiscountStatusID) &&
              this.state.discountCode.length === 0 ? (
                <Button
                  variant="purple"
                  htmlType="submit"
                  onClick={() => this.GenerateDiscountCodes()}
                  key="3"
                  loading={this.state.generateButtonLoading}
                >
                  Generate Discount Codes
                </Button>
              ) : this.state.discountCode.length !== 0 ? (
                <Button
                  variant="purple"
                  htmlType="submit"
                  onClick={() => this.exportDiscountCodeToExcel()}
                  // key="3"
                >
                  Export To Excel
                </Button>
              ) : null
            ) : null,
          ]}
        />
        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.discountCode}
          loading={this.state.tableLoading}
          pagination={false}
        />
      </div>
    );
  }
}
export default withRouter(DiscountCode);
