import React from 'react'
import Customerbulkcredit from '../../../components/LKWallet/CustomerBulkCredit/customerbulkcredit.component';

export default class Customerbulkcreditscreen extends React.Component {
    render() {
        return (
            <div>
                <Customerbulkcredit></Customerbulkcredit>
            </div>
        )
    }
}
