import React from "react";
import {
  Collapse,
  Input,
  Badge,
  notification,
  Select,
  Space,
  InputNumber,
} from "antd";
import styles from "./createorder.module.css";
import AccordinIcon from "../../../../images/Order/accordianHandler.svg";
import Garment from "./garmentblock.component";
import CreateOrderService from "../../../../services/createorderservice";
import { labels } from "../../../../shared/language";
const { Panel } = Collapse;
const { Option } = Select;
/**
 * This holds the view of collapsible panels to add garments in the order
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 8 June 2020
 */
export default class GarmentSelection extends React.Component {
  createOrderService = new CreateOrderService();
  state = {
    garmentList: this.props.garmentList,
    uom: {
      data: [],
      disabled: true,
    },
  };

  componentDidMount() {
    this.createOrderService.getUOMList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          uom: {
            ...this.state.uom,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_UOM_LIST,
        });
      }
    });
   
  }

  static getDerivedStateFromProps(props, state) {
    // state.garmentList = props.garmentList;
    return state;
  }

  render() {
    /**
     * A varibale that holds the filter input and toggle icon for each collapsible panel
     *
     */
    const panelRightContent = (serviceId, serviceIndex, UOM) => {
      let widthRef = React.createRef();
      let heightRef = React.createRef();
      return (
        <div>
          <div className={styles.PanelToggleIconWrapper}>
            <img src={AccordinIcon} alt="toggle" />
          </div>
          <div
            className={styles.PanelSearchWrapper}
            onClick={(event) => event.stopPropagation()}
          >
            <Space className={styles.Extras}>
              {UOM === 2 ? (
                <div className="w-100">
                  Kg:
                  <InputNumber
                    step={0.01}
                    min={0}
                    value={
                      this.props.order[serviceId] !== undefined
                        ? this.props.order[serviceId]["-1"]
                        : null
                    }
                    onChange={(value) => {
                      this.props.updateOrder(
                        serviceId, 
                        -1, 
                        value,
                        [this.props.serviceType.toString()] //Added by rashmi for L177728 - 22/02/23
                        );
                      let filteredLength = this.props.orderArray.filter(
                        (x) => parseInt(x.serviceId) === parseInt(serviceId)
                      ).length;
                      if (filteredLength !== 0) {
                        let updatedArray = this.props.orderArray.map(
                          (single) => {
                            if (
                              parseInt(single.serviceId) === parseInt(serviceId)
                            ) {
                              single.price =
                                (value * single.rate) / filteredLength;
                            }
                            return single;
                          }
                        );
                        this.props.updateArray(updatedArray);
                      }
                    }}                   
                    disabled={this.props.order[serviceId] !== undefined ? false : true}  //Added by rashmi for L177728 - 22/02/23
                  />
                </div>
              ) : null}

              {/* {UOM === 3 ? (
                <React.Fragment>
                  <div className="w-100">
                    Width:
                    <InputNumber
                      step={0.01}
                      min={0}
                      ref={widthRef}
                      defaultValue="0"
                      onChange={(value) => {
                        console.log(heightRef);
                        this.props.updateOrder(
                          serviceId,
                          -1,
                          parseFloat(value) *
                            parseFloat(heightRef.current.input.value)
                        );
                      }}
                    />
                  </div>
                  <div className="w-100">
                    Height:
                    <InputNumber
                      step={0.01}
                      min={0}
                      ref={heightRef}
                      defaultValue="0"
                      onChange={(value) =>
                        this.props.updateOrder(
                          serviceId,
                          -1,
                          parseFloat(value) *
                            parseFloat(widthRef.current.input.value)
                        )
                      }
                    />
                  </div>
                </React.Fragment>
              ) : null} */}
              {/* <Select
                size="small"
                placeholder="UOM"
                value={this.props.getUOM(serviceId)}
                onChange={(value) => this.props.setUOM(serviceId, value)}
                disabled={this.state.uom.disabled}
              >
                {this.state.uom.data.map((option, index) => {
                  return option.value !== "-1" ? (
                    <Option key={option.value} value={parseInt(option.value)}>
                      {option.text}
                    </Option>
                  ) : null;
                })}
              </Select> */}
              <Input.Search
                placeholder={labels.FILTER}
                className={styles.PanelSearch}
                onClick={(event) => event.stopPropagation()}
                onChange={(event) => {
                  let updatedGarments = this.props.garmentList[
                    serviceIndex
                  ].garments.filter((garment) => {
                    return (
                      garment.name
                        .toLowerCase()
                        .indexOf(event.target.value.toLowerCase()) >= 0
                    );
                  });
                  const updatedList = [...this.state.garmentList];
                  updatedList[serviceIndex] = {
                    ...this.props.garmentList[serviceIndex],
                    garments: updatedGarments,
                  };
                  this.setState({ garmentList: updatedList });
                }}
              />
            </Space>
          </div>
        </div>
      );
    };

    /**
     * A function to generate the panel header with count
     * @param {String} panelHeadText
     * @param {Number} count
     */
    const getPanelHeader = (panelHeadText, serviceId = 0) => {
      let total = 0;
      // let res = this.props.orderArray.filter(x => x.serviceId == serviceId).length;

      for (let key in this.props.order[serviceId]) {
        if (
          key !== undefined &&
          parseInt(key) !== -1 &&
          this.props.order[serviceId][key] !== undefined
        ) {
          total = total + this.props.order[serviceId][key];
        }
      }
      return (
        <span className={styles.PanelHeader}>
          {panelHeadText}
          <Badge count={total} />
        </span>
      );
    };

    /**
     * Please go through below documentation for Collapse component
     * https://ant.design/components/collapse/
     * https://ant.design/components/collapse/#API
     */
    return (
      <Collapse
        bordered={false}
        defaultActiveKey={this.props.disabled === true ? null : ["1"]}
        expandIcon={() => null}
        className={styles.GarmentSelectionCollapse}
        accordion
      >
        {this.state.garmentList.map((singleCategory, index) => {  
          return this.props.storeInfo.services.includes(
            singleCategory.serviceId.toString()
          ) ? (
              <Panel
                header={getPanelHeader(
                  singleCategory.title,
                  singleCategory.serviceId
                )}
                key={singleCategory.serviceId}
                extra={panelRightContent(
                  singleCategory.serviceId,
                  index,
                  singleCategory.UOM
                )}
                disabled={this.props.disabled}
              >
                <div className={styles.GarmentsContainer}>
                  {singleCategory.garments.map((singleGarment, index) => {   
                    return (
                      <Garment
                        garmentObj={singleGarment}
                        key={singleGarment.id}
                        service={singleCategory.serviceId}
                        updateOrder={this.props.updateOrder}
                        getCountFunction={this.props.getCountFunction}
                      />
                    );
                  })}
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v, i) => {
                    return (
                      <div
                        style={{
                          minWidth: 123,
                        }}
                        key={i}
                      ></div>
                    );
                  })}
                </div>
              </Panel>
            ) : null;
        })}
      </Collapse>
    );
  }
}
