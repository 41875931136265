import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class StateService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getStateDetailByStateId(StateID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_STATE_DETAIL_BY_ID });
    let itemArray = [];
    let ID = "?StateID=" + StateID;
    await myApi.endpoints.GetStateDetailByStateID.getById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getStateDetailByStateId(StateID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async removeItems(stateID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.DELETE_STATE_BY_ID });
    let itemArray = [];
    let ID = "?StateIds=" + stateID;
    await myApi.endpoints.DeleteStateByStateID.deleteById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.removeItems(stateID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async insertUpdateItems(postData) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.ADDUPDATE_STATE_MASTER });
    let itemArray = [];

    await myApi.endpoints.SaveUpdateStateMaster.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.insertUpdateItems(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveItems(param) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.STATE_MASTER });
    let itemArray = [];

    await myApi.endpoints.GetStateMaster.getAllPost(param)
      .then(({ data }) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}

export default StateService;
