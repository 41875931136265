import React from "react";
import styles from "../Common/Styles/master.module.css";
import Button from "../Common/Button/button";
import Filters from "../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  notification,
  Tooltip,
  Modal,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //   faChevronUp,
  faPencilAlt,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
// import ROUTES from "../../../routes";
import faqservice from "../../services/faqservice";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import { labels } from "../../shared/language";
const { Search } = Input;
const { Option } = Select;

export default class faq extends React.Component {
    FaqService = new faqservice();
    UserRightsConfiguration = new UserRightsConfiguration();
   
    state = {
      modalLoading: false,
      faqID: 0,
      showfilterBox: false,
      factories: [],
      tableLoading: false,
      search: null,
      sorter: {
        field: null,
        order: null,
      },
      showAddPopup: false,
      pagination: {
        showSizeChanger: true,
        // pageSizeOptions: [1, 2, 3, 4],
        current: 1,
        pageSize: 10,
        onChange: (page, pageSize) => {
          this.setState({
            // tableLoading: true,
            pagination: {
              ...this.state.pagination,
              current: page,
              pageSize: pageSize,
            },
          });
        },
        onShowSizeChange: (current, size) => {
          this.setState({
            // tableLoading: true,
            pagination: {
              ...this.state.pagination,
              current: current,
              pageSize: size,
            },
          });
        },
      },
      popupMode: "add",
      currentExpense: {},
    };
  
    componentWillMount() {
      if (!IsAuthenticated()) {
        window.location.href = "/";
      }
      var data = JSON.parse(localStorage.getItem("userrights"));
      var res = data.filter(
        (val) => val.screenName == this.UserRightsConfiguration.FAQ
      );
      const storeOrFactory = localStorage
        .getItem("Store/FactoryType")
        .toLowerCase();
      if (!res[0].isView) {
        if (storeOrFactory === "store") {
          window.location.href = "/store/dashboard";
        } else {
          window.location.href = "/factory/dashboard";
        }
      }
      this.setState({ userRights: res });
    }
  
    handlePopupCancel = () => {
      this.setState({ showAddPopup: false });
    };
  
    /**
     * Columns Object for rendering table kindly go through
     * https://ant.design/components/table/#API
     * https://ant.design/components/table/
     * https://ant.design/components/table/#Column
     */
    columns = [
      {
        title: "User Name",
        dataIndex: "createdByName",
        key: "createdByName",
        sorter: true,
      },
      {
        title: "Questation",
        dataIndex: "que",
        key: "que",
        sorter: true,
      },
      {
        title: "Answer",
        dataIndex: "ans",
        key: "ans",
        sorter: true,
        ellipsis: {
          showTitle: false,
        },
        render: (ans) => (
          <Tooltip
            overlayStyle={{ whiteSpace: "pre-line" }}
            placement="topLeft"
            title={ans}
          >
            {ans}
          </Tooltip>
        ),
      },
      {
        title: "Created Date",
        dataIndex: "createdOn",
        key: "createdOn",
        sorter: true,
        render: (text) => {
          return moment(text).format("DD-MM-YYYY");
        },
      },
      {
        title: "Actions",
        key: "actions",
        render: (text, record) => (
          <Space size="middle">
            {this.state.userRights.length > 0 &&
            this.state.userRights[0].isView == true ? (
              <Tooltip title="View">
                <Button
                  type="link"
                  onClick={() =>
                    this.setState(
                      {
                        popupMode: "view",
                        faqID: record.faqID,
                        currentExpense: {
                          que: record.que,
                          ans :record.ans
                        },
                      },
                      () => this.setState({ showAddPopup: true })
                    )
                  }
                >
                  <FontAwesomeIcon icon={faInfo} />
                </Button>
              </Tooltip>
            ) : null}
            {this.state.userRights.length > 0 &&
            this.state.userRights[0].isUpdate == true ? (
              <Tooltip title="Edit">
                <Button
                  type="link"
                  onClick={() =>
                    this.setState(
                      {
                        popupMode: "edit",
                        faqID: record.faqID,
                        currentExpense: {
                          que: record.que,
                          ans :record.ans
                        },
                      },
                      () => this.setState({ showAddPopup: true })
                    )
                  }
                >
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
              </Tooltip>
            ) : null}
            {this.state.userRights.length > 0 &&
            this.state.userRights[0].isUpdate == true ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => this.handleDelete(record.faqID)}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete">
                  <Button type="link">
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </Tooltip>
              </Popconfirm>
            ) : null}
          </Space>
        ),
      },
    ];
  
    componentDidMount() {
      this.getFaqList(
        this.state.pagination.current,
        this.state.pagination.pageSize,
        this.state.sorter.field,
        this.state.sorter.order,
        this.state.search
      );
    }
    handleDelete = (id) => {
      // this.setState({ tableLoading: true });
      this.FaqService.deleteFaq(id).then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Faq",
            description: "Faq deleted successfully",
          });
         
          this.getFaqList(
              this.state.pagination.current,
              this.state.pagination.pageSize,
              this.state.sorter.field,
              this.state.sorter.order,
              this.state.search
            );
        } else {
          notification["error"]({
            message: "Faq",
            description: "Unable to delete Faq",
          });
        }
      //   this.setState({ tableLoading: false });
      });
    };
    addUpdateFaq = (values) => {
      let params = {
          faqID:parseInt(this.state.faqID),
          que: values.que,
          ans:values.ans
      };
      this.setState({ modalLoading: true });
      this.FaqService.addUpdateFaq(params).then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Faq",
            description: response.message,
          });
          this.getFaqList(
            this.state.pagination.current,
            this.state.pagination.pageSize,
            this.state.sorter.field,
            this.state.sorter.order,
            this.state.search
          );
          this.setState({ showAddPopup: false });
        } else {
          notification["error"]({
            message: "Faq",
            description: response.message,
          });
        }
        this.setState({ modalLoading: false });
      });
    };
  
    getFaqList = (
      index,
      size,
      sortColumn = this.state.sorter.field,
      sortOrder = this.state.sorter.order,
      search = this.state.search
    ) => {
      if (!this.state.tableLoading) {
        this.setState({ tableLoading: true });
        this.FaqService
          .getAllFaqList(index, size, sortColumn, sortOrder, search)
          .then((response) => {
            if (response.statusCode === 200) {
              let factoryData = [];
              response.data.map((singleData) => {
                singleData.key = singleData.faqID;
                factoryData.push(singleData);
                return null;
              });
              this.setState({ factories: factoryData });
              this.setState({
                pagination: {
                  ...this.state.pagination,
                  total: factoryData.length ? factoryData[0].totalRecords : 0,
                },
              });
            } else {
              notification["error"]({
                message: "Faq",
                description: response.message
                  ? response.message
                  : "Unable to get list of Faq",
              });
            }
            this.setState({ tableLoading: false });
          });
      }
    };
    render() {
      const disableAll = this.state.popupMode === "view";
      return (
        <div>
          <PageHeader
            title={labels.FAQ}
            extra={[
              <Search
                placeholder="Search"
                className={styles.SearchInput}
                key="1"
                onChange={(event) => {
                  this.setState({
                    search: event.target.value.toString().toLowerCase(),
                  });
                  this.getFaqList(
                    this.state.pagination.current,
                    this.state.pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    event.target.value.toString().toLowerCase()
                  );
                }}
              />,
              
              this.state.userRights.length > 0 &&
              this.state.userRights[0].isAdd == true ? (
                <Button
                  variant="purple"
                  htmlType="submit"
                  onClick={() =>
                    this.setState({
                      faqID: 0,
                      showAddPopup: true,
                      popupMode: "add",
                      currentExpense: {},
                    })
                  }
                  key="2"
                >
                  Add Faq
                </Button>
              ) : null,
              
              
            ]}
          />
         
  
          <Table
            size="small"
            columns={this.columns}
            dataSource={this.state.factories}
            loading={this.state.tableLoading}
            pagination={this.state.pagination}
            onChange={(pagination, filters, sorter, extra) => {
              this.setState(
                {
                  sorter: {
                    ...sorter,
                    order:
                      sorter.order === "ascend"
                        ? "asc"
                        : sorter.order === "descend"
                        ? "desc"
                        : sorter.order,
                  },
                },
                () => {
                  this.getFaqList(
                    pagination.current,
                    pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    this.state.search
                  );
                }
              );
            }}
          />
  
          <Modal
            width={600}
            title={[
              this.state.popupMode === "add"
                ? "Add"
                : this.state.popupMode === "view"
                ? "View"
                : "Edit",
              "Faq",
            ].join(" ")}
            visible={this.state.showAddPopup}
            footer={null}
            onCancel={this.handlePopupCancel}
            className="modal-form"
            destroyOnClose
            centered
          >
            <Spin spinning={this.state.modalLoading}>
              <Form
                layout="vertical"
                onFinish={(values) => {
                  this.addUpdateFaq(values);
                }}
                initialValues={this.state.currentExpense}
              >
                <Row gutter={24} >
                <Col xs={24}  type="flex">
                    <Form.Item
                      label="Question"
                      name="que"
                      rules={[{ required: true, message: "Please enter Question" }]}
                    >
                      <Input.TextArea
                        disabled={disableAll}
                        placeholder="Question"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}  type="flex" >
                    <Form.Item
                      label="Answer"
                      name="ans"
                      rules={[{ required: true, message: "Please enter Answer" }]}
                    >
                      <Input.TextArea
                        disabled={disableAll}
                        placeholder="Answer"
                        style={{height:200}}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} className="custom-modal-footer">
                    <Space>
                      {this.state.popupMode === "view" ? (
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      ) : (
                        <React.Fragment>
                          <Button
                            variant="transparentGreyOutline"
                            onClick={this.handlePopupCancel}
                          >
                            Cancel
                          </Button>
                          <Button htmlType="submit" variant="purple">
                            {this.state.popupMode === "add" ? "Add" : "Update"}
                          </Button>
                        </React.Fragment>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Modal>
        </div>
      );
    }
}
