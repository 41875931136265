class EndPointEntity {
  //Maulik S
  WEATHER_FORECAST = "WeatherForecast";
  AUTH_USER = "WebPOSUserLogin";
  GET_SCREENRIGHTBY_USER = "GetScreenRightsByUserID";
  GET_SPLIT_NUMBERTOTRANS_OUT = "GetSplitNumberToTransferout";
  GET_GARMENTDATAIL_BY_SPLIT_ORDER_NUMBER =
    "GetGarmentdatailBysplitOrdernumber";
  GENERATE_GATE_PASS = "GenerateGatePass";
  GET_SYSTEME_NUMS = "GetSystemEnums";
  GET_PACKING_DETAILS_BY_SPLIT_ORDER = "GetPackingDetailsBySplitOrder";
  GENERATE_STICKERS = "GenerateStickers";
  GET_STIKER_INFO_TO_PRINT = "GetStickerInfoToPrint";
  GET_STORE_DASHBOARD = "GetStoreDashBoard";
  GET_STORE_DASHBOARD_DELIVERY_REQUEST_DATA =
    "GetStoreDashBoardDeliveryRequestData";
  GET_STORE_DASHBOARD_SPLIT_ORDER_DATA = "GetStoreDashBoardSplitOrderData";
  GET_STORE_DASHBOARD_PICKUP_DATA = "GetStoreDashBoardPickUpData";
  GET_FACTORY_DASHBOARD_ORDER_PROCESS = "getFactoryDashboardOrderProcessCount";
  GET_FACTORY_DASHBOARD = "getFactoryDashboard";
  PAYTM_EDC_MACHINE_INFO = "PaytmEDCMachineInfo";
  PAYTM_EDC_TRANS_REQUEST = "PaytmEDCTransRequest";
  PAYTM_EDC_TRANS_STATUS = "PaytmEDCTransStatus";
  PAYTM_EDC_TRANS_INFO = "PaytmEDCTransInfo";
  GET_USER_INFO = "GetUserInfo";
  CHANGE_PASSWORD = "ChangePassword";
  REFRESH_TOKEN = "RefreshTokenForPOSUser";
  UPDATE_SCANNED_TAGS_STATUS_FOR_READY_FOR_TRANS_OUT =
    "UpdateScannedTagsStatusForReadyForTransOut";
  RESORTING_AND_PACKAGING_LIST = "ResortingAndPackagingList";

  GET_PRE_QC_PANDING_LIST = "getPreQCPandingList";

  //payal on 01/09/2020
  GET_STORE_PRINT_GATEPASS = "PrintGatePass";

  //Shailesh
  GET_EMPLOYEETYPE = "GetEmployeeTypeList";
  GET_REPORTINGTO = "GetReportingToList";
  GET_DESIGNATION = "GetDesignationList";

  GET_KYCDOCUMENT_TYPE = "GetKYCDocumentTypeList";

  GET_USERMASTER = "GetUserMaster";
  GET_USERDETAILSBYID = "GetUserDetailByUserID";
  GET_DELETEUSERBYID = "DeleteUserByUserID";

  GET_PAYMENT_TYPE = "GetPaymentTypeList";
  GET_IRON_PERSON_TYPE = "GetIronPersonTypeList";
  GET_STAY_AT = "GetStayAtList";
  GET_STORE = "GetStoreList";
  GET_FACTORY_LIST = "GetFactoryList";
  GET_FACTORY_LIST_BY_SERVICEID = "GetFactoryListByServiceIds";
  SAVE_UPDATE_USERMASTER = "SaveUpdateUserMaster";
  SAVE_UPDATE_USERKYC_DETAIL = "SaveUpdateUserKYCDetail";
  GET_DELETE_USERKYC = "DeleteUserKYCDetailByKYCID";
  GET_USER_KYC_DETAILS = "GetUserKYCDetails";
  GET_KYC_DETAILS_BY_USERID = "GetUserKYCDetailByKYCID";
  GET_USER_ROLE_DETAILS = "GetUserRoleMappings";
  GET_ROLE_DETAILS_BY_ROLEID = "GetUserRoleMappingByID";
  GET_DELETE_USERROLE = "DeleteUserRoleMappingByID";
  SAVE_UPDATE_USERROLE_DETAIL = "SaveUpdateUserRoleMapping";
  GET_ROLE = "GetRoleList";
  GET_FACTORIES_LIST = "GetFactoryList";

  GET_STORE_OPERATION_TYPE = "GetStoreOperationTypeList";
  GET_STORE_TYPE = "GetTypeOfStoreList";
  GET_PROMOCODE_TYPE = "GetPromocodeTypeList";
  GET_STORE_MODEL = "GetStoreModelList";
  GET_STORE_OPERATED_BY = "GetStoreOperatedByList";
  GET_STORE_OPERATING_TYPE = "GetStoreOperatingTypeList";
  GET_STORE_STATUS = "GetStoreStatusList";
  GET_STORE_SERVICE_TYPE = "GetStoreServiceTypeList";
  GET_DELIVERY_TYPE = "GetDeliveryTypeList";
  GET_ZONE = "GetZoneList";
  GET_SERVICES_OFFERED = "GetServicesList";
  SAVE_UPDATE_STOREMASTER = "SaveUpdateStoreMaster";

  GET_STORE_MASTER = "GetStoreMaster";
  GET_STOREDETAIL_BY_STOREID = "GetStoreDetailByStoreID";

  GET_WEEKDAYS_LIST = "GetWeekDaysList";
  SAVE_UPDATE_STORE_SLOT = "SaveUpdateStoreSlot";
  GET_STORE_SLOTS = "GetStoreSlots";
  GET_STORE_SLOTS_BY_STOREIDS = "GetStoreSlotsByStoreIDS";

  DELETE_STORE_SLOTS_By_SLOTID = "DeleteStoreSlotBySlotID";
  GET_GARMENT_DETAIL_BY_TAGNO = "GetGarmentDetailByTagNo";
  ORDER_GARMENT_QC_REQUEST = "OrderGarmentQCRequest";
  GET_QC_HISTORY_BY_TAGNO = "GetQCHistoryByTagNo";
  GET_PRE_QC_REQUEST_LIST = "GetPreQCRequestList";
  GET_LAST_QCINFO_BY_TAG_NO = "getLastQCInfoByTagNumber";
  STORE_GET_PRE_QC_REQUEST_LIST="StoreGetPreQCRequestList";
  FACTORY_GET_PRE_QC_REQUEST_LIST="FactoryGetPreQCRequestList";
  //Payal
  GETSTATELIST = "GetStateList";
  GET_GARMENTUOM_LIST = "GetGarmentUOMList";
  GET_EXPENSE_TYPE = "GetExpenseTypeList"; //need to update after receveing the api

  CITY_MASTER = "GetCityMaster";
  ADDUPDATE_CITY_MASTER = "SaveUpdateCityMaster";
  REMOVE_CITY_MASTER = "DeleteCityByCityID";
  GET_CITY_DETAIL_BY_ID = "GetCityDetailByCityID";

  STATE_MASTER = "GetStateMaster";
  ADDUPDATE_STATE_MASTER = "SaveUpdateStateMaster";
  DELETE_STATE_BY_ID = "DeleteStateByStateID";
  GET_STATE_DETAIL_BY_ID = "GetStateDetailByStateID";

  DESIGNATION_MASTER = "GetDesignationMaster";
  ADDUPDATE_DESIGNATION_MASTER = "SaveUpdateDesignationMaster";
  DELETE_DESIGNATION_BY_ID = "DeleteDesignationByDesignationID";
  GET_SLOT_DETAILS_BY_SLOTID = "GetStoreSlotDetailBySlotID";
  GET_DESIGNATION_DETAIL_BY_ID = "GetDesignationDetailByDesignationID";

  SIZE_MASTER = "GetSizeMaster";
  ADDUPDATE_SIZE_MASTER = "SaveUpdateSizeMaster";
  DELETE_SIZE_BY_ID = "DeleteSizeBySizeID";
  GET_SIZE_DETAIL_BY_ID = "GetSizeDetailBySizeID";

  BRAND_MASTER = "GetBrandMaster";
  ADDUPDATE_BRAND_MASTER = "SaveUpdateBrandMaster";
  DELETE_BRAND_BY_ID = "DeleteBrandByBrandID";
  GET_BRAND_DETAIL_BY_ID = "GetBrandDetailByBrandID";

  KYCDOC_MASTER = "GetKYCDocTypeMaster";
  ADDUPDATE_KYCDOC_MASTER = "SaveUpdateKYCDocTypeMaster";
  DELETE_KYCDOC_BY_ID = "DeleteKYCDocTypeByID";
  GET_KYCDOC_DETAIL_BY_ID = "GetKYCDocTypeDetailByID";

  SERVICE_MASTER = "GetServiceMaster";
  ADDUPDATE_SERVICE_MASTER = "SaveUpdateServiceMaster";
  DELETE_SERVICE_BY_ID = "DeleteServiceByServiceID";
  GET_SERVICE_DETAIL_BY_ID = "GetServiceDetailByServiceID";

  COLOR_MASTER = "GetColorMaster";
  ADDUPDATE_COLOR_MASTER = "SaveUpdateColorMaster";
  DELETE_COLOR_BY_ID = "DeleteColorByColorID";
  GET_COLOR_DETAIL_BY_ID = "GetColorDetailByColorID";

  GARMENT_MASTER = "GetGarmentMaster";
  ADDUPDATE_GARMENT_MASTER = "SaveUpdateGarmentMaster";
  DELETE_GARMENT_BY_ID = "DeleteGarmentByGarmentID";
  GET_GARMENT_DETAIL_BY_ID = "GetGarmentDetailByGarmentID";

  REWASH_REASON_MASTER = "GetRewashReasonMaster";
  ADDUPDATE_REASON_MASTER = "SaveUpdateRewashReasonMaster";
  DELETE_REASON_MASTER_BY_ID = "DeleteRewashReasonByRewashReasonID";
  GET_REASON_DETAIL_BY_ID = "GetRewashReasonDetailByRewashReasonID";

  OPERATION_INSTRUCT_MASTER = "GetOperInstMaster";
  ADDUPDATE_OPERINST_MASTER = "SaveUpdateOperInstMaster";
  DELETE_OPERINST_BY_ID = "DeleteOperInstByOperInstID";
  GET_OPERINST_DETAIL_BY_ID = "GetOperInstDetailByOperInstID";

  PACKCHARGE_MASTER = "GetPackagingChargesMaster";
  ADDUPDATE_PACKCHARGE_MASTER = "SaveUpdatePackagingChargesMaster";
  DELETE_PACKCHARGE_BY_ID = "DeletePackagingChargesByPackagingChargesID";
  GET_PACKCHARGE_DETAIL_BY_ID = "GetPackagingChargesDetailByPackagingChargesID";

  DELIVERYCHARGE_MASTER = "GetDeliveryChargesMaster";
  ADDUPDATE_DELIVERYCHARGE_MASTER = "SaveUpdateDeliveryChargesMaster";
  DELETE_DELIVERYCHARGE_BY_ID = "DeleteDeliveryChargesByDeliveryChargesID";
  GET_DELIVERYCHARGE_DETAIL_BY_ID =
    "GetDeliveryChargesDetailByDeliveryChargesID";

   GET_REWARDS_CONFIG_MASTER ="GetRewardsConfigMaster";
   SAVEUPDATE_REWARDS_CONFIG_MASTER="SaveUpdateRewardsConfigMaster";
   GET_REWARDS_CONFIG_FROM_REWARDSID="GetRewardsConfigfromRewardsID";

   GET_HOLIDAY_MASTER="GetHolidayMaster";
   GET_HOLIDAY_DETAIL_BY_HOLIDAYID="GetHolidayDetailByHolidayID";
   SAVE_UPDATE_HOLIDAY_MASTER="SaveUpdateHolidayMaster";
   DELETE_HOLIDAY_BY_HOLIDAYID="DeleteHolidayByHolidayID";
   GET_HOLIDAYLIST_BY_STOREID="GetHolidaylistByStoreID";
   


  EXPENSETYPE_MASTER = "GetExpenseTypeMaster";
  ADDUPDATE_EXPENSETYPE_MASTER = "SaveUpdateExpenseTypeMaster";
  DELETE_EXPENSETYPE_BY_ID = "DeleteExpenseTypeByExpenseTypeID";
  GET_EXPENSETYPE_DETAIL_BY_ID = "GetExpenseTypeDetailByExpenseTypeID";

  /**
   * Constants required by Factory Service
   * @author Niraj Gohel
   * @created 18 June 2020
   */

  GET_DELIVERY_TYPES = "GetDeliveryTypeList";
  GET_WEEKDAYS = "GetWeekDaysList";
  GET_FACTORY_STATUS_LIST = "GetFactoryStatusList";
  GET_CITIES_BY_STATE = "GetCityListByStateID";
  GET_ZONES = "GetZoneList";
  ADD_UPDATE_FACTORY = "SaveUpdateFactoryMaster";
  GET_ALL_FACTORIES = "GetFactoryMaster";
  GET_FACTORY = "GetFactoryDetailByFactoryID";
  DELETE_FACTORY = "";

  /**
   * Constants required by Customer Service
   * @author Niraj Gohel
   * @created 25 June 2020
   */

  GET_ALL_CUSTOMERS = "GetCustomerMaster";
  GET_CUSTOMER_TYPES = "GetCustomerTypeList";
  GET_BILLING_CYCLES = "GetBillingCycleList";
  GET_GENDERS = "GetGenderList";
  GET_CORPORATE = "GetCorporateList";
  GET_CUSTOMER = "GetCustomerByCustomerID";
  ADD_UPDATE_CUSTOMER = "SaveUpdateCustomer";
  GET_CUSTOMER_ADDRESSES = "GetCustomerAddressesByCustomerId";
  ADD_UPDATE_ADDRESS = "SaveCustomerAddress";
  DELETE_ADDRESS = "DeleteCustomerAddressByCustomerAddressID";
  SEND_OTP = "ResendCustomerOTP";
  VERIFY_OTP = "CustomerValidateOTP";
  GET_ADDRESS_TYPES = "GetCustomerAddressTypeList";

  /**
   * Constants for Role Master
   * @author Niraj Gohel
   * @created 26 June 2020
   */
  GET_ALL_ROLES = "GetRoleMaster";
  //added by Payal
  ADDUPDATE_ROLE_MASTER = "SaveUpdateRoleMaster";
  DELETE_ROLE_BY_ID = "DeleteRoleByRoleID";
  GET_ROLE_DETAIL_BY_ID = "GetRoleDetailByRoleID";
  //ended by Payal
  GET_ROLES_DROPDOWN = "GetRoleList";
  GET_SCREEN_RIGHTS = "GetScreenRightsByRoleID";
  UPDATE_SCREEN_ROLES = "UpdateRoleWiseRights";

  /**
   * Constants for Corporate Master
   * @author Niraj Gohel
   * @created 30 June 2020
   */
  ADD_UPDATE_CORPORATE = "SaveUpdateCorporateMaster";
  GET_ALL_CORPORATES = "GetCorporateMaster";
  GET_CORPORATE_FROM_ID = "GetCorporateDetailByCorporateID";
  DELETE_CORPORATE = "DeleteCorporateByCorporateID";

  GET_RATECARD_MASTER = "GetRateCardMaster";
  SAVE_UPDATE_RATECARD_MASTER = "SaveUpdateRateCardMaster";
  SAVE_UPDATE_ASSIGN_RATECARD_TO_STORE = "SaveUpdateAssignRateCardToStores";
  SAVE_UPDATE_ASSIGN_RATECARD_TO_CORPORATE =
    "SaveUpdateAssignRateCardToCorporate";
  GET_RATECARD_DETAIL_BY_RATECARDID = "GetRateCardDetailByRateCardID";
  GET_RATECARD_ASSIGN_TO_STORE = "GetRateCardAssginedToStore";
  GET_RATECARD_ASSIGN_TO_CORPORATE = "GetRateCardAssignedToCorporate";
  UPLOAD_SERVICE_RATE_CARD = "UploadServiceRateCard";
  GET_RATECARD_DETAILS = "GetRateCardInfoByRateCardID";
  GET_TIMECARD_DETAILS = "GetTimeCardInfoByTimeCardID";

  GET_TIMECARD_MASTER = "GetTimeCardMaster";
  SAVE_UPDATE_TIMECARD_MASTER = "SaveUpdateTimeCardMaster";
  SAVE_UPDATE_ASSIGN_TIMECARD_TO_STORE = "SaveUpdateAssignTimeCardToStores";
  SAVE_UPDATE_ASSIGN_TIMECARD_TO_CORPORATE =
    "SaveUpdateAssignTimeCardToCorporate";
  GET_TIMECARD_DETAIL_BY_TIMECARDID = "GetTimeCardDetailByTimeCardID";
  GET_TIMECARD_ASSIGN_TO_STORE = "GetTimeCardAssginedToStore";
  GET_TIMECARD_ASSIGN_TO_CORPORATE = "GetTimeCardAssignedToCorpoTime";
  UPLOAD_SERVICE_TIME_CARD = "UploadServiceTimeCard";

  GET_DISCOUNT_MASTER = "GetDiscountMaster";
  SAVE_UPDATE_DISCOUNT_MASTER = "SaveUpdateDiscountMaster";
  GET_DISCOUNT_DETAIL_BY_DISCOUNTID = "GetDiscountDetailByDiscountID";
  GET_DISCOUNT_CODE_BY_DISCOUNTID = "GetDiscountCodesByDiscountID";
  GENERATE_DISCOUNT_CODES_BY_DISCOUNTID = "GenerateDiscountCodesByDiscountID";
  GET_MODE = "GetPromocodeModeList";
  GET_USAGE_TYPE = "GetPromocodeUsageTypeList";
  GET_DISCOUNT_TYPE = "GetDiscountTypeList";
  AUTHORIZE_DISCOUNT_BY_ID = "AuthorizeDiscountByDiscountID";
  BLOCK_DISCOUNT_BY_ID = "BlockDiscountByDiscountID";

  GET_CASH_COUPON_MASTER = "GetCashCouponMaster";
  SAVE_UPDATE_CASH_COUPON_MASTER = "SaveUpdateCashCouponMaster";
  GET_CASHCOUPON_DETAIL_BY_COUPONID = "GetCashCouponDetailByCouponID";
  GET_CASHCOUPON_CODE_BY_COUPONID = "GetCashCouponCodesByCouponID";
  GENERATE_CASHCOUPON_CODES_BY_COUPONID = "GenerateCashCouponCodesByCouponID";
  GET_COUPON_TYPE = "GetCouponTypeList";

  /**
   * Constants required by pickup Service [Store Module]
   * @author Payal
   * @created 17 July 2020
   */
  GET_OPERATION_INSTRUCT_LIST = "GetOperationInstructionList";
  GET_REWASH_REASON_LIST="GetRewashReasonList"
  GET_PICKUP_STATUS_LIST = "GetPickupStatusList";
  GET_STORE_TIMESLOT_LIST = "GetStoreTimeSlotList";
  // GET_PICKUP_CANCEL_REASON_LIST = "GetPickupCanceledReasonList";
  GET_PICKUP_CANCEL_REASON_LIST = "GetPickupCancelReasonList";
  GET_USER_LIST_BY_STORE_ID = "GetUserListByStoreID";
  // GET_PICKUP_REQUEST = "GetPickupRequests";
  GET_PICKUP_REQUEST = "GetPickupRequestsForWeb";
  GET_PICKUP_REQUEST_BY_PICKUPID = "GetPickupRequestByPickupID";
  SAVE_UPDATE_PICKUP_REQUEST = "SaveUpdatePickupRequest";
  RESCHEDULE_PICKUP_REQUEST = "ReschedulePickupRequest";
  CANCEL_PICKUP_REQUEST = "CancelPickupRequest";
  REASSIGN_PICKUP_REQUEST = "ReassignPickupRequest";
  GET_PICKUP_REQUEST_HISTORY = "GetPickupRequestHistory";
  PICKUP_VALIDATE_PROMOCODE = "ValidatePromocode";

  GET_SEARCH_CUSTOMER = "GetSearchCustomer";

  //constants for calculating the deliverydate
  GET_SAMPLE_TIME_CARD = "GetSampleTimeCard";

  //constants required for store trasfer in
  GET_STICKERINFO_BY_STICKER_NO = "GetStikerInfoByStickerNumber";
  TRANSFER_INTO_STORE = "TransferIntoStore";

  // Constants required for login
  GET_ASSIGNED_STORE_FACTORY_BY_USERID = "GetAssignedStoreFactoryByUserId";

  //constants required for Factory Module
  TRANSFER_IN_FACTORY = "TransferedInToFactory";
  GET_STORE_OUT_GARMENTS_BY_ID = "GetStoreOutGarmentsByStoreID";
  FACTORY_MISSING_GARMENT = "GetMissingGarments";
  FACTORY_MANAGE_POST_QC = "ManagePostQC";
  GET_POST_QC_LIST = "GetPostQCList"; //added on 26 nov by pb
  GET_LAST_POSTQC_INFO = "getLastPostQCInfoByTagNumber"; // added on 27 nov by pb
  GET_STICKERS_TO_TRANSFER_OUT = "GetStikersToTransferOut";
  FACTORY_GARMENT_INVENTORY = "GetGarmentInventory";
  FACTORY_GENERATE_GATE_PASS = "FactoryGenerateGatePass";
  GET_FACTORY_PRINT_GATEPASS = "getFactoryPrintGatePass";
  /**
   * Constants required by Create Order Service [Store Module]
   * @author Niraj Gohel
   * @created 24 July 2020
   */
  GET_ADDON_SERVICES = "GetAddOnServicesList";
  GET_GARMENTS_WITH_SERVICES = "GetGarmentListByService";
  GET_RATE_CARD_FOR_STORE = "GetRateCardForStore";
  GET_RATE_CARD_FOR_CORPORATE = "GetRateCardForCorporate";
  GET_BRAND_DROPDOWN = "GetBrandList";
  GET_SIZE_DROPDOWN = "GetSizeList";
  GET_EFFECTIVE_PACKAGING_CHARGES = "GetEffectivePackagingCharges";
  GET_EFFECTIVE_DELIVERY_CHARGES = "GetEffectiveDeliveryCharges";
  CREATE_ORDER = "CreateOrder";
  GET_TIME_CARD_FOR_STORE = "GetTimeCardForStore";
  GET_TIME_CARD_FOR_CORPORATE = "GetTimeCardForCorporate";
  GET_ORDER_LIST = "GetOrderList";
  GET_ORDER_BY_ID = "GetOrderDetails";
  UPDATE_ORDER = "UpdateOrder";
  CANCEL_ORDER = "CancelOrderRequestByOrderID";
  GENERATE_TAGS = "GenerateTagsOfOrder";
  VALIDATE_PROMOCODE = "CalculateOrderSummary";
  //added by Payal on 02/09/2020
  GET_STORE_ESTIMATED_ORDER = "getStoreEstimatedOrder";

  /**
   * Enpoints required by Store Pre-QC
   *
   * @author Niraj Gohel
   * @created 17 Aug 2020
   */
  GET_LAST_QC_INFO = "getLastQCInfoByTagNumber";

  /**
   * required constants for  PackageConfiguration and ReferralBonusConfiguration (LK Wallet)
   *
   * @author Payal Bharakhda
   * @created 22 Aug 2020
   */

  GET_LKPACKAGE_MASTER = "GetLKPackageMaster";
  SAVE_UPDATE_LKPACKAGE_MASTER = "SaveUpdateLKPackageMaster";
  DELETE_LKPACKAGE_BY_ID = "DeleteLKPackageByLKPackageID";
  GET_LKPACKAGE_DETAIL_BY_ID = "GetLKPackageDetailByLKPackageID";
  BUY_PACKAGE = "BuyLKPackage";
  GET_LKREFERRAL_PROGRAM_MASTER = "GetLKReferralProgramMaster";
  SAVE_UPDATE_LKREFERRAL_PROGRAM = "SaveUpdateLKReferralProgram";
  DELETE_LKREFERRAL_PROGRAM_BY_ID = "DeleteLKReferralProgramByProgramID";
  GET_REFERRAL_PROGRAM_DETAIL_BY_ID = "GetReferralProgramDetailByProgramID";

  /**
   * required constants for Compensation (LKWallet Module)
   *
   * @author Payal Bharakhda
   * @created 24 Aug 2020
   */

  GET_LKCOMPENSATION_MASTER = "GetLkCompensationMaster";
  ADD_LKCOMPENSATION = "AddLKCompensation";
  GET_LKCOMPENSATION_DETAIL_BY_ID = "GetLKCompensationDetailByLKCompensationID";

  // added on 27/10/2020
  GET_ORDERIDS_COMPENSATION_BY_CUSTID =
    "GetOrderIdsforCompensationByCustomerID";

  /**
   * required constants for Store Garment Inventory (Store Module)
   *
   * @author Payal Bharakhda
   * @created 24 Aug 2020
   */
  GET_GARMENT_INVENTORY_AT_STORE = "GetGarmentInventoryAtStore";

  /**
   * required constants for expense entry (store module)
   *
   * @author Niraj Gohel
   * @created 24 Aug 2020
   */
  EXPENSE_ENTRY_LIST = "GetUserExpenseInfo";
  ADD_UPDATE_EXPENSE = "SaveUpdateUserExpenseInfo";
  DELETE_EXPENSE = "DeleteuserExpenseInfoByExpenseID";

  /**
   * required constants for notice board (store module)
   *
   * @author Niraj Gohel
   * @created 24 Aug 2020
   */
  NOTICE_BOARD_LIST = "GetNoticeboardInfoList";
  ADD_UPDATE_NOTICE = "SaveUpdateNoticeboardInfo";
  DELETE_NOTICE = "DeleteNoticeboardInfoByNoticeID";
  /**
   * required constants for Delivery list  (store module)
   *
   * @author Niraj Gohel
   * @created 24 Aug 2020
   */
  GET_DELIVERY_REQUESTS = "GetDeliveryRequestsForWebportal";
  GET_USER_LIST_BY_STORE = "GetUserListByStoreID";
  //26 Aug 2020 by PB
  REASSIGN_DELIVERY_REQUEST = "ReassignDeliveryRequest";
  RESCHEDULE_DELIVERY_REQUEST = "RescheduleDeliveryRequest";
  CANCEL_DELIVERY_REQUEST = "CancelDeliveryRequest";
  GE_DELIVERIES_BY_ID = "GetDeliveriesByDeliveryID";

  /**
   * required constants for Employee Credits (LKWallet Module)
   *
   * @author Payal Bharakhda
   * @created 26 Aug 2020
   */

  GET_EMPLOYEE_CREDIT_MASTER = "GetEmployeeCreditMaster";
  GET_EMPLOYEE_CRDIT_FILE_REPORT = "GetEmployCreditFileReport";
  UPLOAD_EMPLOYEE_CREDIT = "UploadEmployeeCredit";
  GET_TAG_TO_PRINT = "GetTagDetailToPrint";
  GET_REWASh_TAG_TO_PRINT ='GetRewashTagDetailToPrint';

  GET_CUSTOMER_BULK_CREDIT_MASTER="GetCustomerBulkCreditMaster";
  UPLOAD_CUSTOMER_BULK_CREDIT="UploadCustomerBulkCredit";
  GET_CUSTOMER__BULK_CRDIT_FILE_REPORT ="GetCustomerBulkCreditFileReport";

  GET_CUSTOMER_BULK_DEBIT_MASTER="GetCustomerBulkDebitMaster";
  UPLOAD_CUSTOMER_BULK_DEBIT="UploadCustomerBulkDebit";
  GET_CUSTOMER__BULK_DEBIT_FILE_REPORT ="GetCustomerBulkDebitFileReport";

  GET_BULK_COUPON_MAP_UPLOAD_MASTER="GetBulkCouponMapUploadMaster";
  GET_COUPON_BULK_UPLOAD_FILE_REPORT="GetCouponBulkUploadFileReport";
	UPLOAD_BULK_COUPON_CUSTMAPPING="UploadBulkCouponCustMapping";

  



  /**
   * required constants for Banner  (Admin Module)
   *
   * @author Payal Bharakhda
   * @created 27 Aug 2020
   */

  GET_BANNER_MASTER = "GetBannerMaster";
  SAVE_UPDATE_BANNER_MASTER = "SaveUpdateBannerMaster";
  DELETE_BANNER_MASTER_BY_ID = "DeleteBannerMasterByBannerID";
  GET_BANNER_MASTER_BY_ID = "GetBannerMasterByBannerID";

  /**
   * required constants for Ready For Delivery Orders  (Store Module)
   *
   * @author Payal Bharakhda
   * @created 28 Aug 2020
   */

  SPILT_ORDER_READY_FOR_INVOICE = "SplitOrderReadyForInvoice";

  /* required constants for Invoice Module
   *
   * @author Niraj Gohel
   * @created 01 Sept 2020
   */

  GET_INVOICE_DATA_FROM_SPLITORDER = "GetDataForInvoiceCreation";
  SAVE_UPDATE_INVOICE_INFO = "SaveUpdateInvoiceInfo";
  SAVE_UPDATE_INVOICE_PAYMENT_INFO = "SaveUpdateInvoicePaymentInfo";
  ADD_ADVANCE_PAYMENT = "AddAdvancePayment";

  /* required constants for Invoice Module
   *
   * @author Payal Bharakhda
   * @created 04 Sept 2020
   */

  GET_STORE_INVOICE_PRINT = "GetStoreInvoicePrint";
  GET_INVOICE_DATA = "GetInvoiceData";
  SETTLE_LESS_PAID = "SettleLessPaidInvoice";

  /* required constants for Gate Pass List (Store Module)
   *
   * @author Payal Bharakhda
   * @created 12 Sept 2020
   */
  GET_GATE_PASS_LIST = "GetGatePassList";

  /* required constants for Refund (LK Module)
   *
   * @author Payal Bharakhda
   * @created 13 Sept 2020
   */

  GET_REFUND_MASTER = "GetRefundMaster";
  ADD_REFUND = "AddRefund";
  GET_REFUND_DETAIL_BY_ID = "GetRefundDetailByLKRefundID";

  /**
   * required constants for Advance Payment (LK module)
   *
   * @author Niraj Gohel
   * @created 17 Sep 2020
   */
  GET_ADVANCE_PAYMENT_MASTER = "GetAdvancePaymentMaster";
  ADD_ADVANCE_PAYMENT = "AddAdvancePayment";
  GET_WALLET_BALANCE = "GetMyWalletAmountByCustomerID";

  /**
   * Finger Scan
   * @author Niraj Gohel
   * @created 1 Oct 2020
   */
  GET_USER_BY_STORE_FACTORY = "GetUserOfStoreFactory";
  SAVE_UPDATE_FINGERPRINT = "SaveUpdateFIngurePrint";
  GET_FINGER_PRINT_DATA = "GetFingurePrintData";

  /** Attendance
   * @author Niraj Gohel
   * @created 7 Oct 2020
   */
  GET_ATTENDANCE_DATA = "GetAttendanceData";
  GET_STORE_FICTORY_ISOTEMP = "GetStoreFactoryISOTemplate";
  ADD_UPDATE_PUNCHIN = "AddUpdatePunchData";
  REFRESH_TOKEN = "RefreshTokenForPOSUser";
  GET_ATTENDANCE_REPORT = "GetAttendanceReport";

  GET_ESTIMATED_PDF_BY_ORDER_ID = "GetEstimatedOrderPrintByOrderID";

  UPDARE_PASSWORD = "UpdatePassword";
  GET_PROFORMA_INVOICE_BY_ORDER_ID = "GetPerformaInvoiceByOrderID";

  /* required constants for Cash Collection Screen
   *
   * @author Payal Bharakhda
   * @created 26 Oct 2020
   */

  GET_CASH_COLLECTION_DATA = "GetCashCollectionData";
  SUBMIT_CASH_COLLECTION_DATA = "SubmitCashCollectionData";
  // 17th March 2021
  GET_ACCOUNT_EXEC_LIST_BY_STOREID = "GetAcExecListByStoreID";

  /* required constants for Service Icon Master Screen
   *
   * @author Payal Bharakhda
   * @created 30 Nov 2020
   */

  GET_SERVICE_ICON_MASTER = "GetServiceIconMaster";
  GET_SERVICE_ICON_DETAILS_BY_ICON_ID = "GetServiceIconsDetailsByIconID";
  DELETE_SERVICE_ICONS_BY_ID = "DeleteServiceIconsByIconIDs";
  SAVE_UPDATE_SERVICE_ICON_MASTER = "SaveUpdateServiceIconMaster";

  /* required constants for pickup screen
   *
   * @author Payal Bharakhda
   * @created 30 Nov 2020
   */
  GET_STORE_ID_BY_CUST_ADDRESS = "GetStoreIDByCustomerAddressID";

  /* required constants for factory gate pass screen
   *
   * @author Payal Bharakhda
   * @created 01 Dec 2020
   */
  GET_FACTORY_GATE_PASS_LIST = "GetFactoryGatePassList";

  /* required constants for Standard Rate Card Screen
   *
   * @author Payal Bharakhda
   * @created 13th Oct 2020
   */

  GET_STATIC_SERVICE_RATE_SAMPLE = "GetStaticServiceRateSample";
  DELETE_STATIC_SERVICE_RATE_BY_ID = "DeleteStaticServiceRateSampleByID";
  SAVE_UPDATE_STATIC_SERVICE_RATE_SAMPLE = "SaveUpdateStaticServiceRateSample";

  SPLIT_ORDER_MARK_DELIVERY_AS_DELIVERED = "SplitOrderMarkDeliveryAsDelivered";

  /* required constants for Partial Order Received Screen
   *
   * @author Payal Bharakhda
   * @created 15th April 2021
   */

  GET_PARTIAL_ORDER_RECEIVED_LIST = "GetPartialOrderReceivedList";

  /* required constants for Email Verification
   *
   * @author Payal Bharakhda
   * @created 27th April 2021
   */
  CUSTOMER_EMAIL_VERIFICATION = "CustomerEmailVerification";

  /* required constants for Factory Mapping Screen inside Store Master Screen
   *
   * @author Payal Bharakhda
   * @created 28th April 2021
   */
  GET_FACTORY_OFFERED_SERVICES_BY_FACTORY_ID =
    "GetFactoryOfferedServicesByFactoryID";
  GET_STORE_FACTORY_MAPPING = "GetStoreFactoryMappings";
  GET_STORE_FACTORY_MAPPING_BY_ID = "GetStoreFactoryMappingByMappingID";
  SAVE_UPDATE_STORE_FACTORY_MAPPING = "SaveUpdateStoreFactoryMapping";
  DELETE_STORE_FACTORY_MAPPING_BY_ID = "DeleteStoreFactoryMappingByMappingID";

  /* required constants for Paytm EDC Machine Configuration Screen
   *
   * @author Payal Bharakhda
   * @created 5th May 2021
   */

  GET_PAYTM_EDC_MASTER = "GetPaytmEDCMaster";
  GET_PAYTM_EDC_MASTER_BY_ID = "GetPaytmEDCMasterByEDCInfoID";
  ADD_UPDATE_PAYTM_EDC_MACHINE_INFO = "AddUpdatePaytmEDCMachineInfo";
  DELETE_PAYTM_EDC_MACHINE_MASTER = "DeletePaytmEDCMachineMaster";

  GET_ACTIVE_USER_LIST = "GetActiveUserList";
  QR_CODE_GENERATOR = "QRCodeGenerator";
  QR_CODE_STATUS_CHECK = "QRCodeStatusCheck";
  CHECK_PAYMENT_TRANSACTION_STATUS="CheckPaymentTransactionStatus";
  QR_CODE_STATUS_UPDATE = "SaveUpdateQRCodeStatus"; 

  /* @auther Hasti Tilva
     @created 17/09/2021
     */
  SETTLE_INVOICE_WITHOUT_PAYMENT  ="SettleInvoiceWithoutPayment";
  GET_ORDER_HISTORY="GetOrderHistory";
  GET_ORDERLISTBYMOBILENO="GetOrderListByMobileNo";
  GET_ORDER_HISTORY_FROM_SPLITEORDERNUMBER ="GetOrderHistoryFromSplitOrderNumber";
  GET_ORDER_HISTORY_LOADMOREDATA ="GetOrderHistoryLoadMoreData";

  GET_BULK_NOTIFICATION_MASTER="GetBulkPushNotiMaster";
  GET_BULK_PUSH_NOTIFICATION_FILE_REPORT="GetBulkPushNotiFileReport";
  UPLOAD_BULK_PUSH_NOTIFICATION="UploadBulkPushNotification";

  GET_STORE_IN_PENDING_LIST="GetStoreInPendingList";
  GET_FACTORY_IN_PENDING_LIST="GetFactoryInPendingList";
 GET_FACTORY_GARMENT_PROCESS_DETAIL="GetFactoryGarmentProcessDetail"
 FACTORY_SET_PROCESS_GARMENT="FactorySetProcessGarment"
 GET_PROCESS_LIST="GetProcessList"

 TERMS_AND_CONDITIONS_LIST = "GetTermsAndConditionInfoList";
 ADD_UPDATE_TERMS_AND_CONDITIONS="SaveUpdateTermsAndConditions";
 DELETE_TERMS_AND_CONDITIONS="DeleteTermsAndConditionByID";

 GET_DELIVERY_MANAGEMENT="GetDeliveryManagmnet";
  GET_DELIVERYDATA_FROM_SPLITORDERSTATUS="GetDeliveryDataFromSplitOrderStatus"

  UPDATE_DELIVERY_DATE="UpdateDeliveryDate";

 GET_FAQ_LIST ="GetfaqList";
 SAVE_UPDATE_FAQ="SaveUpdateFaq";
 DELETE_FAQ="DeleteFaqByID";


 UPDATE_INVOICE_REMARK ="UpdateInvoiceRemark";

 GET_PENDING_CASH_SUMMARY="GetPendingCashSummary";
 GET_PENDING_CASH_SUMMARY_DETAIL="GetPendingCashSummaryDetail";
 ESCALATEDELIVERY="EscalateDelivery";
 GET_ORDER_SUMMARY_REPORT="OrderSummaryReport";

 GET_INVENTORY_SUMMARY="GetInventorySummary";
 GET_ROLEACCESS_DROPDOWN = "GetRoleAccessList";

 GET_OUT_OF_COVERAGE="OutOfCoverage";
 GET_PICKUP_DELIVERY_ASSIGNMENT_LIST="GetPickupAndDeliveryExecutive";
 CDA_ADD_ALT_NUMBER_FOR_CUSTOMER="CDAAddAltNumberForCustomer";
 CDA_SEND_RESEND_CUSTOMEROTP="CDASendResendCustomerOTP";

 DYNAMICQR_CODE_GENERATOR ="DynamicQRCodeGenerator";
 GET_PAYMENT_LOG_DATA="GetPaymentLogdata";

 INSERT_PAYMENT_PROCESSLOG="InsertPaytmprocesslog";
 GET_TODAY_PICKUP_LIST="GetTodayPickupList";
 GET_TODAY_DELIVERY_LIST="GetTodayDeliveryList";
 RESEND_DELIVERY_OTP ="ResendDeliveryOTP"
 DAILY_ORDER_SUMMARY ="DailyOrderSummary";
 DAILY_PICKUP_SUMMARY ="DailyPickupSummary";
 MONTHLY_PICKUP_SUMMARY="MonthlyPickupSummary";
 UPDATE_VERIFIED_OTP_DATETIME="UpdateVerifiedOTPDateTime";
 MONTHLY_ORDER_SUMMARY ="MonthlyOrderSummary";

 UPDATE_PICKUP_STOREID="UpdatePickupStoreID";
 GETAPPFLYERDATA="GetAppflyerData";

 CHECK_PHONEPE_STATUS ="CheckPhonePeStatus";
 SAVE_UPDATE_PHONEPE_STATUS="SaveUpdatePhonePeStatus";

 GET_DAILY_EXPRESSORDER_DATA="GetDailyExpressOrderData";
 GET_DAILY_REWASHORDER_DATA="GetDailyRewashOrderData";
 GET_DAILY_FIVESTARORDER_DATA="GetDailyFiveStarOrderData";
 ADD_URGENT_ORDER="AddUrgentOrder";
 
}

export default EndPointEntity;
