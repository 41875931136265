import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class processinglistservice {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getprocessingList(
    index,
    size,
    sortColumn,
    sortOrder,
    search,
    selectedBranch,
    selectedstoreBranch
  ) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.GET_PROCESS_LIST });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.GET_PROCESS_LIST]
      .getAllPost({
        factoryIDs: [null, undefined, ""].includes(selectedBranch)
          ? localStorage.getItem("branchID")
          : selectedBranch,
       storeIDs:selectedstoreBranch,
        pageIndex: index,
        pageSize: size,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        commonSearch: search,
      })
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getprocessingList(
          index,
          size,
          sortColumn,
          sortOrder,
          search
        );
      });
    }
    return Promise.resolve(itemArray);
  }
}
