import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class TransferInService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async retrieveStoreList() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE });
    let itemArray = [];
    await myApi.endpoints.GetStoreList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreList();
      });
    }
    return Promise.resolve(itemArray);
  }

  async getStoreOutGarmentsById(params) {
    const getStoreOutTagAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    getStoreOutTagAPI.createEntity({
      name: this.endpoint.GET_STORE_OUT_GARMENTS_BY_ID,
    });
    let storeOutTag = [];
    await getStoreOutTagAPI.endpoints[
      this.endpoint.GET_STORE_OUT_GARMENTS_BY_ID
    ]
      .getAllPost(params)
      .then((response) => (storeOutTag = response.data))
      .catch((err) => {
        storeOutTag = this.common.handleError(err);
      });
    if (storeOutTag.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        storeOutTag = this.getStoreOutGarmentsById(params);
      });
    }
    return Promise.resolve(storeOutTag);
  }

  async getFactoryTransferInData(param) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.TRANSFER_IN_FACTORY });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.TRANSFER_IN_FACTORY]
      .getAllPost(param)
      .then((response) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getFactoryTransferInData(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getMissingGarments(param) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.FACTORY_MISSING_GARMENT });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.FACTORY_MISSING_GARMENT]
      .getAllPost(param)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getMissingGarments(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default TransferInService;
