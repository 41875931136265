import React from 'react'
import BulkuploadcouponcodesComponent from '../../components/BulkUploadCouponCodes/bulkuploadcouponcodes.component';

export default class BulkUploadCouponCodesScreen extends React.Component {
    render() {
        return (
            <div>
                <BulkuploadcouponcodesComponent></BulkuploadcouponcodesComponent>
            </div>
        )
    }
}
