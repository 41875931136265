import React from "react";
import TimeCardService from "../../../services/timecardservice";
import { Row, Col, Form, Input, Radio } from "antd";
import styles from "./../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";

/**
 * A form as a part of time card - General Section/Step
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 26 June 2020
 *
 * @module TimeCard
 */
export default class TimeCardMaster extends React.Component {
  constructor(props) {
    super(props);
    this.timecardservice = new TimeCardService();
    this.state = {
      isEditmode: false,
      isViewmode: false,
    };
  }
  componentDidMount() {
    //   this.setState({
    //     isEditmode:this.props.isEditmode,
    //     isViewmode:this.props.isViewmode
    //   })
  }

  render() {
    const disabled = this.props.mode === "view";
    return (
      <Form
        layout="vertical"
        name="TimeCardMaster"
        initialValues={this.props.values}
        ref={this.props.instance}
        onFinish={this.onFinishGeneralForm}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        <Row gutter={30}>
          <Col xs={24} md={12} xl={8}>
            <Form.Item
              label="Time Card Name"
              name="timeCardName"
              rules={[
                { required: true, message: "Please enter Time card name" },
              ]}
            >
              <Input placeholder="Enter Time Card Name" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col xs={24} xl={8}>
            <Form.Item
              label="Corporate"
              name="isCorporate"
              rules={[{ required: true, message: "Please select Corporate" }]}
            >
              <Radio.Group disabled={disabled}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xs={24} className={styles.ButtonContainer}>
            <Button
              variant="transparentGreyOutline"
              className={styles.CancelButton}
              shape="round"
              size="large"
              onClick={this.props.backFunction}
            >
              Cancel
            </Button>
            {!disabled ? (
              <Button
                htmlType="submit"
                variant="purple"
                shape="round"
                size="large"
                //onClick={this.props.nextFunction}
              >
                Submit
              </Button>
            ) : null}
          </Col>
        </Row>
      </Form>
    );
  }
}
