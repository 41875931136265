import React from "react";
import TimeCardService from "../../../services/timecardservice";
import { Row, Col, Form, Space, Tag, notification, Table } from "antd";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

/**
 * A form as a part of time card - General Section/Step
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 26 June 2020
 *
 * @module TimeCard
 */
export default class TimeCardDetail extends React.Component {
  constructor(props) {
    super(props);
    this.timecardservice = new TimeCardService();
    this.state = {
      isEditmode: false,
      isViewmode: false,
      fileName: "",
      timeCardID: 0,
      fileBinary: "",
      uploading: false,
      fileList: [],
      binaryFile: "",
      currentURL: "",
      timeCardDetailsList: [],
    };
  }
  componentDidMount() {
    this.setState({ timeCardID: this.props.timeCardID });
    this.state.currentURL = window.location.origin + "/files/TimeCard_TAT.xls";
    this.getTimeCardDetails(this.props.timeCardID);
    //   this.setState({
    //     isEditmode:this.props.isEditmode,
    //     isViewmode:this.props.isViewmode
    //   })
  }
  getTimeCardDetails = (timeCardID) => {
    if (timeCardID !== 0) {
      this.props.toggleLoading(true);
      this.timecardservice.getTimeCardDetails(timeCardID).then((response) => {
        this.setState({ timeCardDetailsList: response.data });
        this.props.toggleLoading(false);
      });
    }
  };
  handleFileChange = (event) => {
    this.setState({
      files: event.target.files[0],
      fileName: event.target.files[0].name,
    });
  };
  handleSubmit = () => {
    if (this.state.fileList.length === 0) {
      notification["error"]({
        message: "Time Card",
        description: "Please select time card file",
      });
    } else {
      const formData = new FormData();
      formData.append("timeCardFile", this.state.fileList[0]);
      formData.append("timeCardID", this.props.timeCardID);
      this.props.toggleLoading(true);
      this.timecardservice
        .uploadServiceTimeCard(this.state.timeCardID, formData)
        .then((response) => {
          if (response.statusCode === 200) {
            notification["success"]({
              message: "Time Card",
              description: response.message,
            });
          } else {
            notification["error"]({
              message: "Time Card",
              description: response.message
                ? response.message
                : "Unable to upload time card",
            });
          }
          this.props.toggleLoading(false);
        });
    }
  };

  columns = [
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "Garment Name",
      dataIndex: "garmentName",
      key: "garmentName",
    },
    {
      title: "TAT",
      dataIndex: "tat",
      key: "tat",
    },
  ];

  render() {
    const disabled = this.props.mode === "view";
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 6,
        },
      },
    };
    const updateFileBinary = (bin) => {
      this.setState({ fileBinary: bin });
    };
    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };
    const uploadProps = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [file],
        }));
        return false;
      },
      fileList: this.state.fileList,
    };
    return (
      <Form
        layout="vertical"
        name="TimeCardDetail"
        initialValues={this.props.values}
        ref={this.props.instance}
        onFinish={this.handleSubmit}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        {this.state.timeCardDetailsList.length === 0 ? (
          <Row gutter={30}>
            <Tag color="#FCC350" style={{ fontSize: 13 }}>
              <a href={this.state.currentURL} download>
                Click here to Download Sample Time Card
              </a>
            </Tag>
          </Row>
        ) : null}
        {this.state.timeCardDetailsList.length === 0 ? (
          <Row gutter={30}>
            <Col xs={8}>
              <Form.Item
                // {...tailFormItemLayout}
                label="Upload Time Card"
                rules={[{ required: true, message: "Please select File" }]}
                disabled={disabled}
                getValueFromEvent={normFile}
                name="timeCardFile"
              >
                <Upload {...uploadProps}>
                  <Button>
                    <UploadOutlined /> Click to Upload
                  </Button>
                </Upload>
                {/* <input  type = "file" id = "files" ref = { file => this.fileUpload } onChange={this.handleFileChange} /> */}
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        {this.state.timeCardDetailsList.length === 0 ? (
          <Row className={styles.ActionsRow}>
            <Col xs={24} className={styles.ButtonContainer}>
              <Space size="middle">
                <Button
                  variant="transparentGreyOutline"
                  className={styles.CancelButton}
                  shape="round"
                  size="large"
                  onClick={this.props.backFunction}
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  variant="purple"
                  shape="round"
                  size="large"
                >
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        ) : null}
        {this.state.timeCardDetailsList.length > 0 ? (
          <Table
            size="small"
            bordered
            columns={this.columns}
            dataSource={this.state.timeCardDetailsList}
            pagination={false}
          />
        ) : null}
      </Form>
    );
  }
}
