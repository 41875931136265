import React from "react";
import Role from "../../../components/Role/RoleScreenMapping/rolescreenmapping.component"

export default class RoleScreenMapping extends React.Component {
  render() {
    return (
      <div >
        <Role></Role>
      </div>
    );
  }
}