import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class PreQCResponseList {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getResponseList(param) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.STORE_GET_PRE_QC_REQUEST_LIST }); //var change
    let itemArray = [];
    await myApi.endpoints[this.endpoint.STORE_GET_PRE_QC_REQUEST_LIST]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getResponseList(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  // async getResponseList(param) {
  //   const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
  //   myApi.createEntity({ name: this.endpoint.GET_PRE_QC_REQUEST_LIST });
  //   let itemArray = [];
  //   await myApi.endpoints[this.endpoint.GET_PRE_QC_REQUEST_LIST]
  //     .getAllPost(param)
  //     .then(({ data }) => {
  //       itemArray = data;
  //     })
  //     .catch((err) => {
  //       itemArray = this.common.handleError(err);
  //     });
  //   if (itemArray.statusCode == 401) {
  //     await this.common.refreshToken().then((response) => {
  //       this.common.setToken(response);
  //       itemArray = this.getResponseList(param);
  //     });
  //   }
  //   return Promise.resolve(itemArray);
  // }

  async getGarmentDetailByTagNo(tagNo) {
    const stateListAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_GARMENT_DETAIL_BY_TAGNO,
    });
    let states = [];
    let param = "?TagNo=" + tagNo;
    await stateListAPI.endpoints[this.endpoint.GET_GARMENT_DETAIL_BY_TAGNO]
      .getOne(param, {})
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getGarmentDetailByTagNo(tagNo);
      });
    }
    return Promise.resolve(states);
  }

  async getLastQC(tagNo, pendingFrom) {
    const stateListAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_LAST_QC_INFO,
    });
    let states = [];
    let param = "?TagNo=" + tagNo + "&QCPendingfrom=" + parseInt(pendingFrom);
    await stateListAPI.endpoints[this.endpoint.GET_LAST_QC_INFO]
      .getOne(param, {})
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getLastQC(tagNo, pendingFrom);
      });
    }
    return Promise.resolve(states);
  }
  async OrderGarmentQCRequest(data) {
    const addUpdateAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    addUpdateAPI.createEntity({ name: this.endpoint.ORDER_GARMENT_QC_REQUEST });
    let returnVal = [];
    await addUpdateAPI.endpoints[this.endpoint.ORDER_GARMENT_QC_REQUEST]
      .create(data)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.OrderGarmentQCRequest(data);
      });
    }
    return Promise.resolve(returnVal);
  }
  async GetQCHistoryByTagNo(id) {
    const getFactoryAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    getFactoryAPI.createEntity({ name: this.endpoint.GET_QC_HISTORY_BY_TAGNO });
    let factory = [];
    let shouldWait = false;
    let idParams = {
      params: {
        TagNo: id,
      },
    };
    await getFactoryAPI.endpoints[this.endpoint.GET_QC_HISTORY_BY_TAGNO]
      .getAll(idParams)
      .then((response) => {
        factory = response.data;
      })
      .catch((err) => {
        factory = this.common.handleError(err);
      });
    if (factory.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factory = this.GetQCHistoryByTagNo(id);
      });
    }
    return Promise.resolve(factory);
  }

  async getResponseListForDashboard(param) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.GET_PRE_QC_REQUEST_LIST }); 
    let itemArray = [];
    await myApi.endpoints[this.endpoint.GET_PRE_QC_REQUEST_LIST]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getResponseListForDashboard(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default PreQCResponseList;
