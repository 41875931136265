import React from "react";
import styles from "../../../components/Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
// import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Table,
  notification,
  Tooltip,
  DatePicker,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import moment, { isMoment } from "moment";
import Logo from "../../../images/Common/Logo.png";
import html2pdf from "html2pdf.js";
import TransferOutService from "../../../services/factorytransferoutservice";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";
import FactoryGatePassList from "../../../services/factorygatepasslistservice";

const { Search } = Input;
const { Option } = Select;
const TWO_MONTHS_AGO = new Date();
TWO_MONTHS_AGO.setMonth(TWO_MONTHS_AGO.getMonth() - 1);

export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.GatePassListService = new FactoryGatePassList();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.TransferOutService = new TransferOutService();
    this.common = new Common();

    this.state = {
      gatePassListItems: [],
      gatePassNo: "",
      orderFromDate: moment(TWO_MONTHS_AGO).startOf(1, "months").date(1),
      orderToDate: moment(),
      fcatoryIDs: localStorage.getItem("branchID"),
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      totalMaterial: {},
      servicewisedata: [],
      showfilterBox: false,
      loading: false,
      userRights: [],
      printInfoHeaderData: {
        gatePassNumber: "",
        gatePassDate: "",
        storeName: "",
        factoryName: "",
        factoryAddress: "",
        userName: "",
      },
      printInfoRowData: [],
      isPDFDataLoaded: false,
      gatePassNumber: "",
    };
  }

  columns = [
    // {
    //   title: "GatePassID",
    //   dataIndex: "gatePassID",
    //   key: "gatePassID",
    // },
    {
      title: labels.GATE_PASS_NUMBER,
      dataIndex: "gatePassNumber",
      key: "gatePassNumber",
      sorter: true,
      render: (text, record) => {
        return (
          <Button
            type="link"
            variant="link"
            onClick={() => {
              this.downloadGatePassPDF(record.gatePassNumber);
            }}
          >
            {record.gatePassNumber}{" "}
            <Tooltip title="Download">
              {" "}
              <FontAwesomeIcon icon={faDownload} />
            </Tooltip>
          </Button>
        );
      },
    },
    {
      title: labels.STORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
    {
      title: labels.FACTORY_NAME,
      dataIndex: "factoryName",
      key: "factoryName",
      sorter: true,
    },
    {
      title: labels.NO_OF_GARMENTS,
      dataIndex: "noOfGarment",
      key: "NoOfGarment",
      sorter: true,
    },
    {
      title: labels.TOTAL_STICKERS,
      dataIndex: "totalSticker",
      key: "TotalSticker",
      sorter: true,
    },
    {
      title: labels.GATE_PASS_DATE,
      dataIndex: "gatePassDate",
      key: "GatePassDate",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm:ss");
      },
    },
  ];
  element = React.createRef();

  downloadGatePassPDF = (gatepassNumber) => {
    this.TransferOutService.getPDFGatePass(gatepassNumber).then((res) => {
      console.log("res123->", res);
      this.setState(
        {
          printInfoHeaderData: res.data.objVMFactoryPrintGatePassHeaderData,
          printInfoRowData: res.data.lstVMFactoryPrintGatePassRowData,
          totalMaterial: res.data.totalMaterial,
          servicewisedata: res.data.servicewisedata,
          isPDFDataLoaded: true,
        },
        () => {
          var opt = {
            margin: 1,
            filename: "FactoryGatePass.pdf",
            jsPDF: { format: "a4", orientation: "portrait" },
            pagebreak: { mode: ["css", "legacy"], avoid: "tr" },
            html2canvas: {
              dpi: 192,
              scale: 4,
            },
          };
          html2pdf().set(opt).from(this.element.current).save();
        }
      );
      this.setState({
        selectedStore: "",
        rowselectedvalue: "",
        storeOutStickerData: [],
        tableLoading: false,
      });
    });
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.FACTORY_GATE_PASS
    );
    if (!res[0].isView) {
      window.location.href = "/factory/dashboard";
    }
    this.setState({ userRights: res });
  }

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      fcatoryIDs: this.state.fcatoryIDs,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      fcatoryIDs: this.state.fcatoryIDs,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      fcatoryIDs: this.state.fcatoryIDs,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? null
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.GatePassListService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            gatePassNo: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          gatePassListItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: labels.FACTORY_GATE_PASS,
          description: items.message
            ? this.common.Message(items.message)
            : labels.UNABLE_TO_GET_LIST_OF_GATE_PASS_DATA,
        });
      }
      this.setState({ loading: false });
    });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  render() {
    const { pagination, loading } = this.state;
    const gatePassListItems = this.state.gatePassListItems;
    const listItems = gatePassListItems;
    gatePassListItems.map((item, index) => ({
      key: item.storeOutID,
      gatePassNo: item.gatePassNo,
      storeOutID: item.storeOutID,
      gatePassDate: item.gatePassDate,
      storeID: item.storeID,
      factoryID: item.factoryID,
      storeGarmentsOut: item.storeGarmentsOut,
      noofSplitNumber: item.noofSplitNumber,
      factoryName: item.factoryName,
      storeName: item.storeName,
      totalRecords: item.totalRecords,
    }));

    let data = <tr></tr>;
    if (this.state.isPDFDataLoaded) {
      data = this.state.printInfoRowData.map((item, index) => {
        return (
          <tr style={{ fontSize: 12 }} key={index}>
            <td>{item.id}</td>
            <td>{item.splitOrderNumber}</td>
            <td>{item.deliveryTypeName}</td>
            <td>{item.totalGarments}</td>
            <td>{item.previouslySentGarment}</td>
            <td>{item.sendingTodayGarments}</td>
            <td>{item.pendingGarments}</td>
            <td>{item.bag}</td>
            <td>{item.hanger}</td>
            <td>{item.blanket}</td>
            <td>{item.shoe}</td>
            <td>{item.carpet}</td>
            <td>{item.curtain}</td>
            <td>{item.roll}</td>
          </tr>
        );
      });
    }

    let BagDCSIData = 0;
    let BagRestData = 0;
    let HangerData = 0;
    let BlanketData = 0;
    let ShoeData = 0;
    let CarpetData = 0;
    let CurtainData = 0;
    let RollData = 0;

    let Item = this.state.servicewisedata;
    if (this.state.isPDFDataLoaded) {
      for (let i = 0; i < Item.length; i++) {
        console.log("i->", Item[i]);
        if (Item[i].packingMaterialID == 1) {
          if (Item[i].serviceID == 4 || Item[i].serviceID == 6) {
            BagDCSIData = BagDCSIData + Item[i].totalCounts;
          } else {
            BagRestData = BagRestData + Item[i].totalCounts;
          }
        } else if (Item[i].packingMaterialID == 2) {
          HangerData = HangerData + Item[i].totalCounts;
        } else if (Item[i].packingMaterialID == 3) {
          CurtainData = CurtainData + Item[i].totalCounts;
        } else if (Item[i].packingMaterialID == 4) {
          CarpetData = CarpetData + Item[i].totalCounts;
        } else if (Item[i].packingMaterialID == 5) {
          ShoeData = ShoeData + Item[i].totalCounts;
        } else if (Item[i].packingMaterialID == 6) {
          BlanketData = BlanketData + Item[i].totalCounts;
        } else if (Item[i].packingMaterialID == 7) {
          RollData = RollData + Item[i].totalCounts;
        }
      }
    }
    


    return (
      <div>
        <PageHeader
          title={labels.FACTORY_GATE_PASS} //"Gate Pass List"
          extra={[
            // <Button
            //   key="1"
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
          ]}
        />

        <Table
          size="small"
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />

        {this.state.isPDFDataLoaded ? (
          <div style={{ display: "none" }}>
            <div id="page" ref={this.element} style={{ padding: "10px" }}>
              <table
                align="center"
                style={{
                  width: "100%",
                }}
              >
                <tr
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    fontSize: 12,
                  }}
                >
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    Factory Gate Pass Number :
                    {this.state.printInfoHeaderData.gatePassNumber}
                  </td>
                </tr>
                <tr
                  style={{ fontSize: 12, fontWeight: "bold" }}
                  // key={index}
                >
                  <td>
                    <img src={Logo} alt="Laundrokart" className="img-fluid" />
                  </td>

                  <td style={{ textAlign: "right" }}>
                    Date :
                    {moment(this.state.printInfoHeaderData.gatePassDate).format(
                      "DD/MM/YYYY"
                    )}
                    <br></br>
                    Store Name :{this.state.printInfoHeaderData.storeName}
                    <br></br>
                    Store Address :{this.state.printInfoHeaderData.storeAddress}
                    <br></br>
                    Factory Name :{this.state.printInfoHeaderData.factoryName}
                    <br></br>
                    Created By : {this.state.printInfoHeaderData.userName}
                  </td>
                </tr>
              </table>
              <table
                border="1"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
                cellPadding={2}
              >
                <thead>
                  <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                    <th>S. No</th>
                    <th>Split Order Id</th>
                    <th>Delivery Type</th>
                    <th colspan="4">Number Of Garments</th>
                    <th colspan="7">Packed</th>
                  </tr>
                  <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>Order Lot Size</th>
                    <th>Previously Sent</th>
                    <th>Sending Today</th>
                    <th>Pending</th>
                    <th>Bag</th>
                    <th>Hanger</th>
                    <th>Blanket</th>
                    <th>Shoe</th>
                    <th>Carpet</th>
                    <th>Curtain</th>
                    <th>Roll</th>
                  </tr>
                </thead>
                <tbody>{data}</tbody>
                <tr style={{ fontSize: 12 }}>
                  <td colspan="7">Total</td>
                  <td>{this.state.totalMaterial.bag}</td>
                  <td>{this.state.totalMaterial.hanger}</td>
                  <td>{this.state.totalMaterial.blanket}</td>
                  <td>{this.state.totalMaterial.shoe}</td>
                  <td>{this.state.totalMaterial.carpet}</td>
                  <td>{this.state.totalMaterial.curtain}</td>
                  <td>{this.state.totalMaterial.roll}</td>
                </tr>
              </table>
              <br></br>

              {/* Added By Hasti */}

               <table
                border="1"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
                cellPadding={2}
              >
                <thead>
                  <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                    <th>Summary</th>
                    <th>Bag - DC/SI</th>
                    <th>Bag - Rest</th>
                    <th>Hanger</th>
                    <th>Blanket</th>
                    <th>Shoe</th>
                    <th>Carpet</th>
                    <th>Curtain</th>
                    <th>Roll</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ fontSize: 12 }}>
                    <td style={{ fontWeight: "bold" }}>Count</td>
                    <td>{BagDCSIData}</td>
                    <td>{BagRestData}</td>
                    <td>{HangerData}</td>
                    <td>{BlanketData}</td>
                    <td>{ShoeData}</td>
                    <td>{CarpetData}</td>
                    <td>{CurtainData}</td>
                    <td>{RollData}</td>
                  </tr>
                  <tr style={{ fontSize: 12 }}>
                    <td style={{ fontWeight: "bold" }}>Check</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
              <br></br> 
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
