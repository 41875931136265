import React from "react";
import ReactToPrint from "react-to-print";
import Box from "../../../Common/Box/box";
import Logo from "../../../../images/Common/Logo.png";
import styles from "./printtag.module.css";
var Barcode = require("react-barcode");

export default class printrewashtag extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    console.log("In Print->", this.props.printInfo);
    const hh = this.props.printInfo;
    return (
      <div>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return (
              <a href="#" ref={this.props.instance}>
                Print this out!
              </a>
            );
          }}
          content={() => this.componentRef}
          pnintInfo={this.props}
          // onAfterPrint={() => window.location.reload()}
        />
        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          printInfo={this.props.printInfo}
          //   stickerID={this.props.stickerID}
          //   customerID={this.props.customerID}
        />
      </div>
    );
  }
}

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    // const hh = this.props
    const data = this.props.printInfo.map((element, index) => {
      return (
        // <div style={{ width: '188px', height: '302px', 'border-style': 'dotted', 'margin-top': '0mm', 'margin-bottom': '0px', 'margin-right': '5mm', 'margin-left': '5mm' }}>
        <div
          style={{
            width: "50mm",
            height: "50mm",
            // borderWidth: "0px 0px 0px 2px",
            // borderStyle: "dotted",
          }}
        >
          {/* <Box sub> */}
          <div
            style={{
              "border-radius": "5px",
              background: "#fff",
              "box-shadow": "0px 0px 22px rgba(0, 0, 0, 0.04)",
              "min-height": "calc(100vh - 133px)",
              // padding: "15px",
              //paddingBottom: "30px",
              "min-height": "1px",
              height: "100%",
            }}
          >
            <table
              cellspacing="0"
              cellpadding="0"
              style={{
                textAlign: "center",
                width: "90%",
                // marginTop: -17,
                marginLeft: -7,
              }}
              className="tags-table"
            >
              <tbody>
                <tr>
                  <td style={{ fontSize: 16, fontWeight: "bold" }}>
                    {element.splitOrderNumber}
                  </td>
                </tr>
                <tr style={{ fontSize: 12, fontWeight: "bold" }}>
                  <td>{element.rewashReason}</td>
                </tr> 
              </tbody>
            </table>
            {/* </Box> */}
          </div>
        </div>
      );
    });

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {/* //repeat this in case of multiple stickers */}
        {data}
      </div>
    );
  }
}
