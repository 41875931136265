import React from "react";
import Refund from "../../../components/LKWallet/Refund/lkrefund.component";

export default class RefundScreen extends React.Component {
  render() {
    return (
      <div>
        <Refund></Refund>
      </div>
    );
  }
}
