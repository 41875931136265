import React from "react";
import styles from "./header.module.css";
import {
  Input,
  Modal,
  Menu,
  Row,
  Col,
  Space,
  Form,
  notification,
  Tooltip,
  Select,
  Spin,
  Table,
  Badge,
  Dropdown,
} from "antd";
import { Link, withRouter } from "react-router-dom";
import Button from "../../Common/Button/button";
import Logo from "../../../images/Common/Dashboardwhitebg.png";
import seperatorIcon from "../../../images/Header/seperator.svg";
import BellIcon from "../../../images/Header/notification.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faKey,
  faPlus,
  faPowerOff,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
// import userImage from "../../../images/Header/userImage.jpg";
import AuthService from "../../../services/authservice";
import ROUTES from "../../../routes";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { labels, allLabels } from "../../../shared/language";
import HeaderService from "../../../services/headerservice";
import { OmitProps } from "antd/lib/transfer/ListBody";
import Common, { common } from "../../../services/common";
import { BarcodeOutlined } from "@ant-design/icons";
import TableActions from "../../Common/TableActions/tableactions";
import PreQCResponseList from "../../../services/storepreqcresponselistservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import PickupService from "../../../services/pickupservice";
const { Option } = Select;
var jwt = require("jsonwebtoken");

const { Search } = Input;
const { confirm } = Modal;
/**
 * This file contains the header of the application
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 3 June 2020
 */
class Header extends React.Component {
  /**
   * Header variables are stored in the state property to make it easy to modify
   *
   * @var storeName.name : holds the name of the store user logged in to
   * @var currentUser.name : Name of the current user
   * @var currentUser.designation : designation of the current user
   * @var photo : Image object or url of the profile picture
   *
   * @todo currentUser object can be fetched from the global variable storage via service/redux.
   */

  constructor(props) {
    super(props);
    this.authservice = new AuthService();
    this.headerService = new HeaderService();
    this.common = new Common();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.pickupService = new PickupService();
    // this.PreQCResponseListService = new PreQCResponseList();
  }

  state = {
    modalLoading: false,
    storeName: "ECC Road Store",
    currentUser: {
      name: "",
      designation: "",
    },
    showAddPopup: false,
    newPassword: "",
    oldPassword: "",
    showScanGarmentPopup: false,
    ScnanGarmentRecords: [],
    AddScanGarmentRecords: [],
    GarmentNumber: "",
    PendingPreQCResponse: 0,
    IsShowWrongTag: false,
    MessageWrongTag: "",
    isPleaseScanTagCount: 0,
    timeSlot: {
      data: [],
    },
  };

  notificationMenu = (
    <Menu>
      {/* <Menu.Item key="0">
        <Link to="/login">1st menu item</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <Link to="/login">2nd menu item</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <Link to="/login">3rd menu item</Link>
      </Menu.Item> */}
    </Menu>
  );

  Logout = () => {
    confirm({
      title: "Are you sure you want to logout?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        this.authservice.logout();
      },
      onCancel() { },
    });
  };

  componentDidMount() {
    let userInfo = jwt.decode(
      JSON.parse(localStorage.getItem("currentuser")).token
    );

    if (userInfo !== undefined) {
      this.setState({
        currentUser: {
          ...this.state.currentUser,
          name: userInfo.UserFullName,
          designation: userInfo.DesignationName,
        },
      });

      const intervalID = setInterval(() => {
        this.getPreQcresponseCount();
      },10800000);
    }

    let branchName = localStorage.getItem("Store/FactoryName");
    if (branchName !== undefined) {
      this.setState({
        storeName: branchName,
      });
    }
  }
  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.ORDER_LIST
    );
    this.setState({ userRights: res });

    this.getPreQcresponseCount();
  }

  getPreQcresponseCount = () => {
    var f = parseInt(localStorage.getItem("branchID"));
    this.headerService
      .getPreQcresponseCount({
        storeID: parseInt(localStorage.getItem("branchID")),
      })
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            PendingPreQCResponse: response.data.pendingPreQcCount,
          });
        }
        // else {
        //   notification["error"]({
        //     message: labels.PRE_QC_RESPONSES,
        //     description: response.message
        //       ? this.common.Message(response.message)
        //       : labels.UNABLE_TO_GET_PRE_QC_RESPONSE_LIST,
        //   });
        // }
      });
  };

  ChangePassword = () => {
    this.setState({ showAddPopup: true });
  };
  handlePopupCancel = () => {
    window.location.reload();
    this.setState({
      showAddPopup: false,
      showScanGarmentPopup: false,
      ScnanGarmentRecords: [],
    });
    this.formInstance.current.setFieldsValue({
      ScanGarment: undefined,
    });
  };

  handleInputPassword = (e) => {
    this.setState({
      newPassword: e.target.value,
    });
  };
  handleOldPassword = (e) => {
    this.setState({
      oldPassword: e.target.value,
    });
  };

  ResetPassword = () => {
    if (this.state.newPassword == this.state.oldPassword) {
      notification["error"]({
        message: "Change Password",
        description: "Old password and new password should not  same.",
      });
      return false;
    }
    this.setState({ modalLoading: true });
    this.authservice
      .ResetPassword(this.state.newPassword, this.state.oldPassword)
      .then((response) => {
        if (response.data.result == "Success") {
          notification["success"]({
            message: "Change Password",
            description: "Password has been changed.",
          });

          this.setState({
            showAddPopup: false,
          });
        } else {
          notification["error"]({
            message: "Change Password",
            description: response.data.message,
          });
        }
        this.setState({ modalLoading: false });
      });
  };
  ShowScanGarmentModel = () => {
    this.setState({
      showScanGarmentPopup: true,
    });
  };

  columns = [
    {
      title: labels.NO,
      dataIndex: "no",
      key: "no",
      width: 70,
      // sorte: true,
    },
    {
      title: "Tag No",
      dataIndex: "tagNo",
      key: "tagNo",
      // sorter: true,
    },
    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record, index) => (
        <Space size="middle">
          {/* <Popconfirm
            title="Are you sure ?"
            onConfirm={() => this.removeItems(record.cityID)}
            onCancel={() => null}
            okText="Yes"
            cancelText="No"
          > */}
          <Tooltip title={labels.DELETE}>
            <Button
              type="link"
              onClick={() => {
                let updated = [...this.state.ScnanGarmentRecords];
                updated[index] = undefined;
                updated = updated.filter((x) => x !== undefined);
                this.setState({
                  ScnanGarmentRecords: updated,
                });
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>
          {/* </Popconfirm> */}
        </Space>
      ),
    },
  ];

  handleScanedGarment = (e) => {
    var res =
      this.state.ScnanGarmentRecords.length > 0
        ? this.state.ScnanGarmentRecords.filter(
          (item) => item == e.target.value
        )
        : [];
    if (res.length == 0) {
      let garmentRes = this.state.ScnanGarmentRecords;
      if (![undefined, null, "", " ", "  "].includes(e.target.value.trim())) {
        garmentRes.push(e.target.value);
        this.setState({
          ScnanGarmentRecords: garmentRes,
          armentNumber: "",
        });
        this.formInstance.current.setFieldsValue({
          ScanGarment: undefined,
        });
      }
    } else {
      notification["error"]({
        message: labels.SCAN_TAG,
        description: labels.TAG_ALREADY_EXISTS.replace("{0}", e.target.value),
      });
    }
  };

  handlePopupClear = () => {
    this.setState({
      ScnanGarmentRecords: [],
      GarmentNumber: undefined,
    });
    this.formInstance.current.setFieldsValue({
      ScanGarment: undefined,
    });
  };

  handleGarmentNumber = (e) => {
    this.setState({ GarmentNumber: e.target.value });
  };

  AddScannedgarmentRecords = () => {
    if (this.state.ScnanGarmentRecords.length > 0) {
      let postData = {
        tagNos: this.state.ScnanGarmentRecords.toString(),
        sourceFrom: 1,
        storeID: parseInt(localStorage.getItem("branchID")),
      };

      this.headerService.AddScanGarmentRecords(postData).then((res) => {
        if (res.statusCode === 200) {
          this.setState({ ScnanGarmentRecords: [] });
          this.formInstance.current.setFieldsValue({
            ScanGarment: undefined,
            isPleaseScanTagCount: 0,
          });

          if (
            res.message.toUpperCase() === "DATA IS SUCCESSFULLY UPDATED" &&
            res.result == "Success"
          ) {
            notification["success"]({
              message: labels.SCAN_TAG,
              description: this.common.Message(res.message),
            });
            window.location.reload();
            this.setState({
              showScanGarmentPopup: false,
              isPleaseScanTagCount: 0,
            });
          } else {
            this.setState({
              MessageWrongTag: res.message,
              IsShowWrongTag: true,
              isPleaseScanTagCount: 0,
            });
          }
        } else {
          notification["error"]({
            message: labels.SCAN_TAG,
            description: this.common.Message(res.message),
          });
        }
      });
    } else {
      if (this.state.isPleaseScanTagCount === 0) {
        notification["error"]({
          message: labels.SCAN_TAG,
          description: labels.PLEASE_SCAN_TAG,
        });

        this.setState({
          isPleaseScanTagCount: this.state.isPleaseScanTagCount + 1,
        });
      }
    }
  };

  getTimeSlot = (storeid) => {
    this.pickupService.getTimeSlotList(storeid).then((response) => {
      if (response.statusCode === 200) {
        this.setState(
          {
            timeSlot: {
              ...this.state.timeSlot,
              data: response.data,
            },
          },
          () => {
            localStorage.setItem(
              "StoreSlots",
              JSON.stringify(this.state.timeSlot.data)
            );
            // this.props.history.push(ROUTES.STORE_DASHBOARD);
            this.props.history.push(ROUTES.SPLASH);
          }
        );
      }
    });
  };

  handleSelect = (event1) => {
    localStorage.removeItem("StoreSlots");
    let event = parseInt(event1.slice(1));
    let type = event1.charAt(0);

    const branchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo"))
      : [];
    var branchArray = branchTypes.filter(
      (x) =>
        x.branchID === event &&
        ((x.branchType === "Store" && type === "s") ||
          (x.branchType === "Factory" && type === "f"))
    );
    if (branchArray !== undefined) {
      localStorage.setItem("branchID", branchArray[0].branchID);
      localStorage.setItem("Store/FactoryName", branchArray[0].branchName);
      localStorage.setItem("Store/FactoryType", branchArray[0].branchType);
      if (branchArray[0].branchType.toLowerCase() === "store") {
        this.getTimeSlot(branchArray[0].branchID);
      } else if (branchArray[0].branchType.toLowerCase() === "factory") {
        localStorage.removeItem("StoreSlots");
        // this.props.history.push(ROUTES.FACTORY_DASHBOARD);
        this.props.history.push(ROUTES.SPLASH);
      }
    }
  };

  // handleClick = () => {
  //   if (
  //     this.state.userName == this.state.oldUserName &&
  //     this.state.password == this.state.oldPassword
  //   ) {
  //     if (this.state.validation == "") {
  //       // notification["error"]({
  //       //   message: "Select Store/Factory.",
  //       // });
  //       return false;
  //     }
  //     localStorage.setItem("branchID", this.state.selectedBranch);
  //     localStorage.setItem("Store/FactoryName", this.state.selectedBranchName);
  //     localStorage.setItem("Store/FactoryType", this.state.selectedBranchType);
  //     if (this.state.selectedBranchType.toLowerCase() === "store") {
  //       this.props.history.push(ROUTES.STORE_DASHBOARD);
  //     } else if (this.state.selectedBranchType.toLowerCase() === "factory") {
  //       this.props.history.push(ROUTES.FACTORY_DASHBOARD);
  //     }
  //   } else {
  //     this.setState({ validateStatus: "error" }, () =>
  //       console.log(this.state.validateStatus)
  //     );
  //     openNotificationWithIcon("error", "Wrong credentials", "");
  //     this.setState({ validateStatus: "error" });
  //     // this.setState({ usernameValidateStatus: "error" });
  //     // this.setState({ passwordValidateStatus: "error" });
  //   }
  // };

  formInstance = React.createRef();

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };
    let branchType = localStorage.getItem("Store/FactoryType").toLowerCase();

    const listItems =
      this.state.ScnanGarmentRecords.length > 0
        ? this.state.ScnanGarmentRecords.map((item, index) => ({
          no: index + 1,
          tagNo: item,
        }))
        : [];

    const branchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo"))
      : [];
    const branchname = localStorage.getItem("Store/FactoryName");
    return (
      <div className={styles.HeaderWrapper}>
        <div>
          <div className={styles.LogoWrapper}>
            {/* <Link
              to={
                branchType === "store"
                  ? ROUTES.STORE_DASHBOARD
                  : ROUTES.FACTORY_DASHBOARD
              }
            > */}
              <img src={Logo} alt="Laundrokart" className="img-fluid" />
            {/* </Link> */}
            <img src={seperatorIcon} className={styles.Seperator} alt=">" />
          </div>

          <div style={{ marginTop: 22, marginLeft: 10 }}>
            <Form initialValues={{ branchname }}>
              <Form.Item
                name="branchname"
                rules={[
                  { required: true, message: "Please Select Store/Factory" },
                ]}
              >
                <Select
                  showSearch
                  onChange={this.handleSelect}
                  placeholder="Select Store/Factory"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ minWidth: 150 }}
                >
                  {branchTypes.length !== 0
                    ? branchTypes.map((branchType) => {
                      return branchType.branchID !== "-1" ? (
                        <Option
                          value={
                            branchType.branchType === "Store"
                              ? "s" + branchType.branchID
                              : "f" + branchType.branchID
                          }
                          key={
                            branchType.branchType === "Store"
                              ? "s" + branchType.branchID
                              : "f" + branchType.branchID
                          }
                        >
                          {branchType.branchName}
                        </Option>
                      ) : null;
                    })
                    : null}
                </Select>
              </Form.Item>
            </Form>
          </div>

          {/* <span className={styles.StoreNameWrap}>{this.state.storeName}</span> */}
        </div>
        <div>
          <div className={styles.HeaderRightSide}>
            {branchType === "store" ? (
              <React.Fragment>
                <Button
                  variant="purpleOutline"
                  size="large"
                  // icon={
                  //   <FontAwesomeIcon
                  //     icon={faPlus}
                  //     className={styles.ButtonIcon}
                  //   />
                  // }
                  // onClick={()=>{this.props.history.push('/empty');this.props.history.replace(ROUTES.CRE)}}
                  className={styles.CreateOrderButton}
                  onClick={this.ShowScanGarmentModel}
                >
                  {labels.SCAN_GARMENT}
                </Button>
                <Link to={ROUTES.CREATE_ORDER}>
                  {this.state.userRights[0].isAdd == true ? (
                    <Button
                      variant="purpleOutline"
                      size="large"
                      icon={
                        <FontAwesomeIcon
                          icon={faPlus}
                          className={styles.ButtonIcon}
                        />
                      }
                      // onClick={()=>{this.props.history.push('/empty');this.props.history.replace(ROUTES.CRE)}}
                      className={styles.CreateOrderButton}
                    >
                      {labels.CREATE_ORDER}
                    </Button>
                  ) : null}
                </Link>
              </React.Fragment>
            ) : null}
            {/**
             * Please go through below documentation for badge to display notification count
             * https:
             * https:
             *
             */}
            {branchType === "store" ? (
              <Tooltip title={labels.PADING_QC_RESPONSE} placement={"left"}>
                <Badge count={this.state.PendingPreQCResponse}>
                  <img src={BellIcon} alt="bell" />
                </Badge>
              </Tooltip>
            ) : null}
            <div>
              <Tooltip title={labels.LANGUAGE} placement={"left"}>
                <Select
                  value={
                    Object.keys(allLabels).includes(
                      localStorage.getItem("locale")
                    )
                      ? localStorage.getItem("locale")
                      : "ENGLISH"
                  }
                  onChange={(value) => {
                    localStorage.setItem("locale", value);
                    window.location.reload();
                  }}
                >
                  {Object.keys(allLabels).map((single) => {
                    return (
                      <Select.Option
                        value={single.toUpperCase()}
                        key={single.toUpperCase()}
                      >
                        {single.charAt(0).toUpperCase() +
                          single.slice(1).toLowerCase()}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Tooltip>
            </div>

            <div className={styles.ProfileHolder}>
              {/* <img
                className={styles.ProfileImage}
                src={this.state.currentUser.photo}
                alt="NS"
              /> */}
              <div>
                <div className={styles.UserName}>
                  <span className={styles.FullUserName}>
                    {this.state.currentUser.name}
                  </span>
                  <span className={styles.UserInitials}>
                    {this.state.currentUser.name
                      .split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </span>
                </div>
                <div className={styles.UserDesignation}>
                  <span className={styles.FullDesignation}>
                    {this.state.currentUser.designation}
                  </span>
                  <span className={styles.DesignationInitials}>
                    {this.state.currentUser.designation
                      .split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </span>
                </div>
              </div>
              <div>
                {/**
                 * Logout Button
                 *
                 * @todo Put Logout Function on onClick Listener
                 */}
                <Tooltip title={labels.CHANGE_PASSWORD}>
                  <Button
                    type="link"
                    className={styles.passwordIcon}
                    onClick={this.ChangePassword}
                  >
                    <FontAwesomeIcon icon={faKey} />
                  </Button>
                </Tooltip>
                <Tooltip title={labels.LOGOUT}>
                  <Button
                    onClick={this.Logout}
                    type="link"
                    className={styles.LogoutIcon}
                  >
                    <FontAwesomeIcon icon={faPowerOff} />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title="Change Password"
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              onFinish={this.ResetPassword}
              {...layout}
            //onFinish
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    name="oldPassword"
                    label="Old Password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your old password",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password onChange={this.handleOldPassword} />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="New Password"
                    rules={[
                      {
                        required: true,
                        pattern:
                          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-]).{8,}$",
                        message:
                          this.state.newPassword !== ""
                            ? "Minimum password length should be 8 including minimum 1 upper case,1 lower case,1 numeric,1 special character except *."
                            : "Please enter your new password",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password onChange={this.handleInputPassword} />
                  </Form.Item>
                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        pattern:
                          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&-]).{8,}$",
                        message:
                          this.state.oldPassword !== ""
                            ? "Mininum password length should be 8 including minimum 1 upper case,1 lower case,1 numeric,1 special character except *."
                            : "Please confirm your new password",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Password & confirm password do not match."
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    <Button type="primary" htmlType="submit">
                      Reset Password
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        <Modal
          title={labels.SCAN_GARMENT}
          visible={this.state.showScanGarmentPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          maskClosable={false}
        >
          <Spin spinning={this.state.modalLoading}>
            {!this.state.IsShowWrongTag ? (
              <Form
                layout="vertical"
                // onFinish={this.handlePopupAdd}
                //initialValues={{ ScanGarment }}
                ref={this.formInstance}
              >
                <Row gutter={24}>
                  <Col xs={24}>
                    <Form.Item
                      label={labels.SCAN_GARMENT}
                      name="ScanGarment"
                      rules={[
                        { required: true, message: labels.PLEASE_SCAN_TAG },
                      ]}
                    >
                      <Input
                        placeholder={labels.PLEASE_SCAN_TAG}
                        onPressEnter={this.handleScanedGarment}
                        onChange={this.handleGarmentNumber}
                        suffix={<BarcodeOutlined />}
                        value={this.state.GarmentNumber}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    {/* {scanGarmentList} */}
                    <Row>
                      <Col
                        className="gutter-row"
                        style={{ marginBottom: 10, fontWeight: "bold" }}
                        span={24}
                      >
                        {labels.SCANNED_GARMENT_LIST}
                      </Col>
                    </Row>
                    <Table
                      size="small"
                      // rowSelection={{
                      //   type: this.selectionType,
                      //   ...this.rowSelection,
                      // }}
                      columns={this.columns}
                      dataSource={listItems}
                    // pagination={pagination}
                    />
                    {/* <TableActions
                    isPagination={false}
                  ></TableActions> */}
                  </Col>
                  <Col xs={24} className="custom-modal-footer">
                    <Space>
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupClear}
                        >
                          {labels.CLEAR}
                        </Button>
                      </>
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                        <Button
                          variant="purple"
                          onClick={this.AddScannedgarmentRecords}
                        >
                          {labels.ADD}
                        </Button>
                      </>
                    </Space>
                  </Col>
                </Row>
              </Form>
            ) : (
              <div>
                <Row>
                  <span>
                    {" "}
                    {![undefined, null, ""].includes(this.state.MessageWrongTag)
                      ? this.state.MessageWrongTag.split(",").join(", ")
                      : null}{" "}
                  </span>
                  <Col
                    xs={24}
                    className="custom-modal-footer"
                    style={{ marginTop: 20 }}
                  >
                    <Space>
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          {labels.CANCEL}
                        </Button>
                      </>
                    </Space>
                  </Col>
                </Row>
              </div>
            )}
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Header);
