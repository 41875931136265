import React from 'react';
import Pendingcashsummary from '../../../components/StoreModule/Audit/pendingcashsummary.component';

export default class pendingcashsummaryscreen extends React.Component {
  render() {
    return (
      <div>
          <Pendingcashsummary></Pendingcashsummary>
      </div>
    )
  }
}
