import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class CashCouponMasterService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }
  async getCouponType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_USAGE_TYPE });
    let stores = [];
    await myApi.endpoints[this.endpoint.GET_USAGE_TYPE]
      .getAll()
      .then((response) => {
        stores = response.data;
      })
      .catch((err) => {
        stores = this.common.handleError(err);
      });
    if (stores.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        stores = this.getCouponType();
      });
    }
    return Promise.resolve(stores);
  }
  async retrieveCashCouponMaster(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_CASH_COUPON_MASTER });
    let itemArray = [];
    await myApi.endpoints.GetCashCouponMaster.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveCashCouponMaster(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async saveUpdateCashCouponMaster(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_CASH_COUPON_MASTER });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateCashCouponMaster.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.saveUpdateCashCouponMaster(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }
  async getCashCouponDetailByCouponID(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.GET_CASHCOUPON_DETAIL_BY_COUPONID,
    });
    let itemArray = [];
    let Id = "?CouponID=" + id;
    await myApi.endpoints.GetCashCouponDetailByCouponID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getCashCouponDetailByCouponID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async GetCashCouponCodesByCouponID(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_CASHCOUPON_CODE_BY_COUPONID });
    let itemArray = [];
    let Id = "?CouponID=" + id;
    await myApi.endpoints.GetCashCouponCodesByCouponID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetCashCouponCodesByCouponID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getCities(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.CITY_MASTER });
    let itemArray = [];
    await myApi.endpoints.GetCityMaster.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getCities(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async GenerateCashCouponCodesByCouponID(payload) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.GENERATE_CASHCOUPON_CODES_BY_COUPONID,
    });
    let itemArray = [];
    await myApi.endpoints.GenerateCashCouponCodesByCouponID.getAllPost(payload)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GenerateCashCouponCodesByCouponID(payload);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default CashCouponMasterService;
