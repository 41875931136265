import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class DiscountMasterService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getWeekDaysList() {
    const daysListAPI = new API({ url: this.config.DROPDOWN_URL });
    daysListAPI.createEntity({ name: this.endpoint.GET_WEEKDAYS });
    let days = [];
    await daysListAPI.endpoints[this.endpoint.GET_WEEKDAYS]
      .getAll()
      .then((response) => {
        days = response.data;
      })
      .catch((err) => {
        days = this.common.handleError(err);
      });
    if (days.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        days = this.getWeekDaysList();
      });
    }
    return Promise.resolve(days);
  }

  async getServices() {
    const servicesAPI = new API({ url: this.config.DROPDOWN_URL });
    servicesAPI.createEntity({ name: this.endpoint.GET_SERVICES_OFFERED });
    let services = [];
    await servicesAPI.endpoints[this.endpoint.GET_SERVICES_OFFERED]
      .getAll()
      .then((response) => {
        services = response.data;
      })
      .catch((err) => {
        services = this.common.handleError(err);
      });
    if (services.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        services = this.getServices();
      });
    }
    return Promise.resolve(services);
  }

  async getStoresList() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE });
    let stores = [];
    await myApi.endpoints[this.endpoint.GET_STORE]
      .getAll()
      .then((response) => {
        stores = response.data;
      })
      .catch((err) => {
        stores = this.common.handleError(err);
      });
    if (stores.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        stores = this.getStoresList();
      });
    }
    return Promise.resolve(stores);
  }

  async getMode() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_MODE });
    let stores = [];
    await myApi.endpoints[this.endpoint.GET_MODE]
      .getAll()
      .then((response) => {
        stores = response.data;
      })
      .catch((err) => {
        stores = this.common.handleError(err);
      });
    if (stores.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        stores = this.getMode();
      });
    }
    return Promise.resolve(stores);
  }

  async getUsageType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_USAGE_TYPE });
    let stores = [];
    await myApi.endpoints[this.endpoint.GET_USAGE_TYPE]
      .getAll()
      .then((response) => {
        stores = response.data;
      })
      .catch((err) => {
        stores = this.common.handleError(err);
      });
    if (stores.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        stores = this.getUsageType();
      });
    }
    return Promise.resolve(stores);
  }

  async getDiscountType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_DISCOUNT_TYPE });
    let stores = [];
    await myApi.endpoints[this.endpoint.GET_DISCOUNT_TYPE]
      .getAll()
      .then((response) => {
        stores = response.data;
      })
      .catch((err) => {
        stores = this.common.handleError(err);
      });
    if (stores.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        stores = this.getDiscountType();
      });
    }
    return Promise.resolve(stores);
  }

  async getPromocodeType() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_PROMOCODE_TYPE });
    let stores = [];
    await myApi.endpoints[this.endpoint.GET_PROMOCODE_TYPE]
      .getAll()
      .then((response) => {
        stores = response.data;
      })
      .catch((err) => {
        stores = this.common.handleError(err);
      });
    if (stores.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        stores = this.getPromocodeType();
      });
    }
    return Promise.resolve(stores);
  }

  async getCities(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.CITY_MASTER });
    let itemArray = [];
    await myApi.endpoints.GetCityMaster.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getCities(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveDiscountMaster(params) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_DISCOUNT_MASTER });
    let itemArray = [];
    await myApi.endpoints.GetDiscountMaster.getAllPost(params)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveDiscountMaster(params);
      });
    }
    return Promise.resolve(itemArray);
  }

  async saveUpdateDiscountMaster(postSave) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.SAVE_UPDATE_DISCOUNT_MASTER });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateDiscountMaster.create(postSave)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.saveUpdateDiscountMaster(postSave);
      });
    }
    return Promise.resolve(itemArray);
  }
  async getDiscountDetailByDiscountID(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.GET_DISCOUNT_DETAIL_BY_DISCOUNTID,
    });
    let itemArray = [];
    let Id = "?DiscountID=" + id;
    await myApi.endpoints.GetDiscountDetailByDiscountID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getDiscountDetailByDiscountID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async GetDiscountCodesByDiscountID(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_DISCOUNT_CODE_BY_DISCOUNTID });
    let itemArray = [];
    let Id = "?DiscountID=" + id;
    await myApi.endpoints.GetDiscountCodesByDiscountID.getOne(Id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GetDiscountCodesByDiscountID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async GenerateDiscountCodesByDiscountID(payload) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.GENERATE_DISCOUNT_CODES_BY_DISCOUNTID,
    });
    let itemArray = [];

    await myApi.endpoints.GenerateDiscountCodesByDiscountID.getAllPost(payload)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.GenerateDiscountCodesByDiscountID(payload);
      });
    }
    return Promise.resolve(itemArray);
  }

  async requestAuthorizeDiscount(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.AUTHORIZE_DISCOUNT_BY_ID });
    let itemArray = [];
    let Id = "?DiscountID=" + id;
    await myApi.endpoints[this.endpoint.AUTHORIZE_DISCOUNT_BY_ID]
      .getOneDiscount(Id, id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.requestAuthorizeDiscount(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async requestBlockDiscount(id) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.BLOCK_DISCOUNT_BY_ID });
    let itemArray = [];
    let Id = "?DiscountID=" + id;
    await myApi.endpoints[this.endpoint.BLOCK_DISCOUNT_BY_ID]
      .getOneDiscount(Id, id)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.requestBlockDiscount(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default DiscountMasterService;
