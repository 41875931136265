import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class ColorService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
  }

  async removeItems(colorID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.DELETE_COLOR_BY_ID });
    let itemArray = [];
    let ID = "?ColorIds=" + colorID;
    let shouldWait = false;
    //need to change method name
    await myApi.endpoints.DeleteColorByColorID.deleteById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.removeItems(colorID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async insertUpdateItems(postData) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.ADDUPDATE_COLOR_MASTER });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.SaveUpdateColorMaster.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.insertUpdateItems(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveItems(param) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.COLOR_MASTER });
    let itemArray = [];
    let shouldWait = false;
    await myApi.endpoints.GetColorMaster.getAllPost(param)
      .then(({ data }) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = data;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          shouldWait = true;
        }
        this.handleError(err);
        console.log(err);
        itemArray = {
          message: "Internal server error",
        };
      });
    if (shouldWait) {
      let common = new Common();
      await common.refreshToken().then((response) => {
        common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default ColorService;
