import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class AdvancePayment {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async addUpdateFactory(factory) {
    const addUpdateAPI = new API({ url: this.config.ITEM_LKWALLET_URL });
    addUpdateAPI.createEntity({ name: this.endpoint.ADD_ADVANCE_PAYMENT });
    let returnVal = [];
    await addUpdateAPI.endpoints[this.endpoint.ADD_ADVANCE_PAYMENT]
      .create(factory)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.addUpdateFactory(factory);
      });
    }
    return Promise.resolve(returnVal);
  }
  async getAllAdvancePaymentList(params) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_LKWALLET_URL });
    allFactoriesAPI.createEntity({
      name: this.endpoint.GET_ADVANCE_PAYMENT_MASTER,
    });
    let factories = [];
    await allFactoriesAPI.endpoints[this.endpoint.GET_ADVANCE_PAYMENT_MASTER]
      .getAllPost(params)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getAllAdvancePaymentList(params);
      });
    }
    return Promise.resolve(factories);
  }
 

}
