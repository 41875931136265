import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  // Popconfirm,
  Table,
  notification,
  Tooltip,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faPencilAlt,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../routes";
import FactoryService from "../../../services/factoryservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";

const { Search } = Input;
const { Option } = Select;

/**
 * A factory master screen which holds grid view for master screens and form with steps
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
class Master extends React.Component {
  factoryService = new FactoryService();
  UserRightsConfiguration = new UserRightsConfiguration();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    showfilterBox: false,
    factories: [],
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.FACTORY
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }
  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: "Name",
      dataIndex: "factoryName",
      key: "factoryName",
      sorter: true,
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
      sorter: true,
    },
    {
      title: "Opening Date",
      dataIndex: "openingDate",
      key: "openingDate",
      sorter: true,
      render: (text) => {
        return [null, undefined, ""].includes(text)
          ? ""
          : moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Delivery Type",
      dataIndex: "deliveryTypeName",
      key: "deliveryTypeName",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (deliveryTypeName) => {
        return (
          <Tooltip placement="topLeft" title={deliveryTypeName}>
            {deliveryTypeName}
          </Tooltip>
        );
      },
    },
    {
      title: "Operating Days",
      dataIndex: "operatingDays",
      key: "operatingDays",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (operatingDays) => {
        return (
          <Tooltip placement="topLeft" title={operatingDays}>
            {operatingDays}
          </Tooltip>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "statusName",
      key: "statusName",
      sorter: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Link
                to={{
                  pathname: [ROUTES.VIEW_FACTORY, record.factoryID].join("/"),
                  state: { factoryID: record.factoryID },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Link
                to={{
                  pathname: [ROUTES.EDIT_FACTORY, record.factoryID].join("/"),
                  state: { factoryID: record.factoryID },
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Link>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    this.getFactoryList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  }
  getFactoryList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    if (!this.state.tableLoading) {
      this.setState({ tableLoading: true });
      this.factoryService
        .getAllFactories(index, size, sortColumn, sortOrder, search)
        .then((response) => {
          if (response.statusCode === 200) {
            let factoryData = [];
            response.data.map((singleData) => {
              singleData.key = singleData.factoryID;
              singleData.openingDate = [null, undefined, ""].includes(
                singleData.openingDate
              )
                ? undefined
                : moment(singleData.openingDate).format("YYYY-MM-DD");
              singleData.operatingTimeFrom = moment(
                singleData.operatingTimeFrom,
                "H:mm"
              ).format("h:mm a");
              singleData.operatingTimeTo = moment(
                singleData.operatingTimeTo,
                "H:mm"
              ).format("h:mm a");
              factoryData.push(singleData);
              return null;
            });
            this.setState({ factories: factoryData });
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: factoryData.length ? factoryData[0].totalRecords : 0,
              },
            });
          } else {
            notification["error"]({
              message: "Factory Master",
              description: response.message
                ? response.message
                : "Unable to get list of factories",
            });
          }
          this.setState({ tableLoading: false });
        });
    }
  };
  render() {
    return (
      <div>
        <PageHeader
          title="Factory Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              key="1"
              onChange={(event) => {
                this.setState({
                  search: event.target.value.toString().toLowerCase(),
                });
                this.getFactoryList(
                  this.state.pagination.current,
                  this.state.pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  event.target.value.toString().toLowerCase()
                );
              }}
            />,
            /**
             * Add Factory Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.props.history.push({
                    pathname: ROUTES.ADD_FACTORY,
                    state: { factoryID: this.state.factoryID },
                  })
                }
                key="2"
              >
                Add Factory
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getFactoryList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(Master);
