import React from "react";
import { Alert, Badge, Spin } from "antd";
import Button from "../../../Common/Button/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import styles from "./createorder.module.css";
import Placeholder from "../../../../images/Order/placeholder.png";
import ROUTES from "../../../../routes";
import { withRouter } from "react-router-dom";
/**
 * This file holds the view of single garment block with plus, minus button and count
 *
 * Please check the garmentObj passed in garment selection file.
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 8 June 2020
 */
class GarmentBlock extends React.Component {
  state = {
    count: 0,
    loading: false,
  };
  componentDidMount() {
    this.getCount();
  }
  getCount = () => {
    let count = this.props.getCountFunction(
      this.props.service.toString(),
      this.props.garmentObj.id.toString()
    );
    this.setState({ count: count });
  };

  static getDerivedStateFromProps(props, state) {
    let count = props.getCountFunction(
      props.service.toString(),
      props.garmentObj.id.toString()
    );
    state.count = count;
    return state;
  }

  revertCount = (prev) => {
    this.setState({ count: prev });
  };

  render() {
    return (
      <div
        className={[
          styles.GarmentBlockContainer,
          this.state.count ? styles.GarmentBlockHasValue : null,
        ].join(" ")}
      >
        <Spin spinning={this.state.loading}>
          {/**
           * Please check Badge documentation
           * https://ant.design/components/badge/
           * https://ant.design/components/badge/#API
           */}

          
          <Badge count={this.state.count}>
            <div className={styles.GarmentBlockWrapper}>
              <img
                src={
                  this.props.garmentObj.icon === ""
                    ? Placeholder
                    : this.props.garmentObj.icon
                }
                alt={this.props.garmentObj.name}
                className={styles.GarmentImage}
              />
              <div>{this.props.garmentObj.name}</div>
              {[ROUTES.UPDATE_ORDER, ":orderID"].join("/") !==
              this.props.match.path ? (
                <Button
                  variant="purple"
                  className={styles.MinusButton}
                  onClick={
                    
                    () => { 
                    let tmparr=  this.props.garmentObj.garmentDeliveryType.split(",");                    
                    this.setState({ loading: true });                  
                    this.props.updateOrder(
                      this.props.service,
                      this.props.garmentObj.id,
                      this.state.count - 1,
                      tmparr,
                      () => {
                        this.revertCount(this.state.count - 1);
                        this.setState({ loading: false });
                      },
                      () => this.setState({ loading: false })
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
              ) : null}
              <Button
                variant="purple"
                className={styles.PlusButton}
                onClick={() => {

                  // this.setState({ loading: true });
                  // this.props.updateOrder(
                  //   this.props.service,
                  //   this.props.garmentObj.id,
                  //   this.state.count + 1,
                  //   () => {
                  //     this.revertCount(this.state.count - 1);
                  //     this.setState({ loading: false });
                  //   },
                  //   () => this.setState({ loading: false })
                  // );

                  //Added by rashmi for L177728 - 20/02/23

                  this.setState({ loading: true });
                  let tmparr=  this.props.garmentObj.garmentDeliveryType.split(",");
                  this.props.updateOrder(
                    this.props.service,
                    this.props.garmentObj.id,
                    this.state.count + 1,
                    tmparr,
                    () => {
                      this.revertCount(this.state.count - 1);
                      this.setState({ loading: false });
                    },
                    () => this.setState({ loading: false })
                  );

                  //End..
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </Badge>
        </Spin>
      </div>
    );
  }
}
export default withRouter(GarmentBlock);
