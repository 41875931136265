import React from "react";
import SizeMaster from "../../../components/Master/Size/sizemaster.component"
export default class SizeScreen extends React.Component {
  render() {
    return (
      <div >
        <SizeMaster></SizeMaster>
      </div>
    );
  }
}