import React from "react";
import ReactToPrint from "react-to-print";
import Box from "../../../Common/Box/box";
import Logo from "../../../../images/Common/Logo.png";
import styles from "./printtag.module.css";
var Barcode = require("react-barcode");

export default class PrintTag extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    console.log("In Print->", this.props.printInfo);
    const hh = this.props.printInfo;
    return (
      <div>
        <ReactToPrint
          trigger={() => {
            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
            // to the root node of the returned component as it will be overwritten.
            return (
              <a href="#" ref={this.props.instance}>
                Print this out!
              </a>
            );
          }}
          content={() => this.componentRef}
          pnintInfo={this.props}
          onAfterPrint={() => window.location.reload()}
        />
        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          printInfo={this.props.printInfo}
          //   stickerID={this.props.stickerID}
          //   customerID={this.props.customerID}
        />
      </div>
    );
  }
}

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
  }
  // <div style={{ width: '188px', height: '302px', 'border-style': 'dotted', 'margin-top': '0mm', 'margin-bottom': '0px', 'margin-right': '5mm', 'margin-left': '5mm' }}>
  render() {
    // const hh = this.props
    const data = this.props.printInfo.map((element, index) => {
      return element.storeName != "" ? (
        <div
          style={{
            marginTop: 2,
            borderRadius: 5,
            background: "#fff",
            boxShadow: "0px 0px 22px rgba(0, 0, 0, 0.04)",
            borderBottomColor: "grey",
            borderBottomStyle: "dashed",
            borderBottomWidth: 1,
            lineHeight: '1.2'
          }}
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              borderBottom: "1px solid black",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            o
          </div>
          <div>
            <Barcode
              fontSize={12}
              displayValue={true}
              margin={12}
              format={"CODE128"}
              width={1.4}
              height={27}
              value={element.tagNo}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // lineHeight: '1.2'
            }}
          >
            <div
              style={{
                borderTop: "0.5px solid black",
                borderBottom: "0.5px solid black",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                
              }}
            >
              <div style={{ fontSize: 20, fontWeight: "bold",  }}>
                {element.splitOrderNumber}
                {" ("}
                {element.totalOrderPlacedByCustomer}
                {") "}
              </div>
              <div
                style={{
                  fontSize: 15,
                  fontWeight: "bold",
                }}
              >
                {element.storeName}
              </div>
            </div>

            <div style={{ fontSize: 14 }}>{element.garmentName}</div>
            <div style={{ fontSize: 12 }}>
              {element.garmentDefect
                ? element.garmentDefect.replace(/,/g, " & ")
                : "\u00A0"}
            </div>
            <div style={{ fontSize: 12 }}>
              {element.pickupDate.split(" ").slice(0, 2).join("-")} /{" "}
              {element.deliveryDate.split(" ").slice(0, 2).join("-")}
            </div>
            <div style={{ fontSize: 14, marginBottom: 4 }}>
              {element.sequenceNumber}/{element.noOfGarment} -{" "}
              {element.deliveryTypeName}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "50mm",
            height: "50mm",
            // borderWidth: "0px 0px 0px 2px",
            // borderStyle: "dotted",
          }}
        >
          {/* <Box sub> */}
          <div
            style={{
              "border-radius": "5px",
              background: "#fff",
              "box-shadow": "0px 0px 22px rgba(0, 0, 0, 0.04)",
              "min-height": "calc(100vh - 133px)",
              // padding: "15px",
              //paddingBottom: "30px",
              "min-height": "1px",
              height: "100%",
            }}
          >
            <table
              cellspacing="0"
              cellpadding="0"
              style={{
                textAlign: "center",
                width: "90%",
                // marginTop: -17,
                marginLeft: -7,
              }}
              className="tags-table"
            >
              <tbody>
                <tr>
                  <td style={{ fontSize: 16, fontWeight: "bold" }}>
                    {element.splitOrderNumber}
                  </td>
                </tr>

                <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                  <td>{element.garmentName}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    });

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {/* //repeat this in case of multiple stickers */}
        {data}
      </div>
    );
  }
}
