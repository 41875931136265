import React from "react";
import FactoryInventory from "../../../components/FactoryModule/Inventory/inventory.factory.component";

/**
 * This file holds the view of Transfer In screen
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 11 June 2020
 */
export default class FactoryInventoryScreen extends React.Component {
  render() {
    return (
      <div>
        <FactoryInventory/>
      </div>
    )
  }
}
