import React from "react";
import GarmentMaster from "../../../components/Master/Garment/garmentmaster.component"

export default class GarmentScreen extends React.Component {
  render() {
    return (
      <div >
        <GarmentMaster></GarmentMaster>
      </div>
    );
  }
}