import React from "react";
import {
  PageHeader,
  Input,
  Select,
  Table,
  notification,
  Tooltip,
  Checkbox,
} from "antd";
import styles from "../../Common/Styles/master.module.css";
import TransferInService from "../../../services/factorytransferinservice";
import Processinglistservice from "../../../services/processinglistservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
import moment from "moment";
const { Search } = Input;
const { Option } = Select;

export default class ProcessingList extends React.Component {
  TransferInService = new TransferInService();
  processinglistService = new Processinglistservice();
  UserRightsConfiguration = new UserRightsConfiguration();
  state = {
    processinglist: [],
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    storeListOptions: [],
    selectedStore: "",
    selectedBranch: localStorage.getItem("branchID"),
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.PROCESSING_LIST
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  columns = [
    {
      title: labels.SPLIT_ORDER_ID,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },
    {
      title: labels.STORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
      //   with:"5%"
    },
    {
      title: labels.DELIVERY_TYPE,
      dataIndex: "deliveryType",
      key: "deliveryType",
      sorter: true,
    },
    {
      title: labels.LOT_SIZE,
      dataIndex: "noofGarment",
      key: "noofGarment",
      sorter: true,
    },
    {
      title: labels.FACTORY_IN_DATETIME,
      dataIndex: "factoryInTime",
      key: "factoryInTime",
      sorter: true,
      width: "15%",
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm:ss");
      },
    },
    {
      title: labels.NO_OF_GARMENTS,
      dataIndex: "noofgarmentsFactoryIn",
      key: "noofgarmentsFactoryIn",
      sorter: true,
    },

    {
      title: labels.PROCESSING_IN_DATE_TIME,
      dataIndex: "processTime",
      key: "processTime",
      sorter: true,
      render: (text) => {
        return [null, undefined, " "].includes(text)
          ? null
          : moment(text).format("DD-MM-YYYY HH:mm:ss");
      },
    },

    {
      title: labels.NO_OF_ITEMS_PENDING_FOR_PROCESSING,
      dataIndex: "pendingToProcessGarments",
      key: "pendingToProcessGarments",
      sorter: true,
    },

    {
      title: labels.USER_NAME,
      dataIndex: "processByUser",
      key: "processByUser",
      sorter: true,
    },
  ];

  componentDidMount() {
    this.getStoreList();
    this.getprocessingList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  }

  getprocessingList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    if (!this.state.tableLoading) {
      this.setState({ tableLoading: true });
      this.processinglistService
        .getprocessingList(
          index,
          size,
          sortColumn,
          sortOrder,
          search,
          this.state.selectedBranch,
          this.state.selectedStore
        )
        .then((response) => {
          if (response.statusCode === 200) {
            if (
              response.data.length === 0 &&
              this.state.pagination.current !== 1
            ) {
              this.setState(
                {
                  pagination: { ...this.state.pagination, current: 1 },
                  tableLoading: false,
                },
                () => {
                  this.getprocessingList(
                    1,
                    size,
                    sortColumn,
                    sortOrder,
                    search
                  );
                }
              );
            }
            let ProcessingListData = [];
            response.data.map((singleData) => {
              //   singleData.key = singleData.splitOrderNumber;
              ProcessingListData.push(singleData);
              return null;
            });
            this.setState({ processinglist: ProcessingListData });
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: ProcessingListData.length
                  ? ProcessingListData[0].totalRecords
                  : 0,
              },
            });
          } else {
            notification["error"]({
              message: labels.PROCESSING_LIST,
              description: response.message
                ? response.message
                : labels.UNABLE_TO_GET_LIST_OF_PROCESSING_DATA,
            });
          }
          this.setState({ tableLoading: false });
        });
    }
  };

  handleChangeBranch = () => {
    this.getprocessingList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  };

  getStoreList = () => {
    this.TransferInService.retrieveStoreList().then((items) => {
      if (items.statusCode === 200) {
        this.setState({ storeListOptions: items.data });
      } else {
        notification["error"]({
          message: labels.TRANSFER_IN,
          description: items.message
            ? this.common.Message(items.message)
            : labels.UNABLE_TO_GET_LIST_OF_STORE_DATA,
        });
      }
    });
  };
  render() {
    const factoryBranchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo")).filter(
          (x) => x.branchType === "Factory"
        )
      : [];
    const allfactoryBranchArray = factoryBranchTypes
      .map((branchType) => {
        return branchType.branchID;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();

    const allStoreArray = this.state.storeListOptions
      .map((store) => {
        return store.value;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();
    return (
      <div>
        <PageHeader
          title={labels.PROCESSING_LIST}
          extra={[
            <div key="5" style={{ alignSelf: "center" }}>
              <Select
                showSearch
                mode="multiple"
                allowClear
                onChange={(event) => {
                  this.setState(
                    {
                      selectedStore: [null, undefined, ""].includes(event)
                        ? ""
                        : event.join(","),
                    },
                    () => {
                      this.handleChangeBranch();
                    }
                  );
                }}
                value={
                  this.state.selectedStore === ""
                    ? []
                    : this.state.selectedStore.split(",")
                }
                placeholder={labels.SELECT_STORE}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ minWidth: 150, maxWidth: 330 }}
              >
                {this.state.storeListOptions.length !== 0
                  ? this.state.storeListOptions.map((branchType) => {
                      return branchType.value !== "-1" ? (
                        <Option
                          value={branchType.value.toString()}
                          key={branchType.value.toString()}
                        >
                          {branchType.text}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </div>,
            <div key="6" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={
                  ![null, undefined, ""].includes(this.state.selectedStore) &&
                  this.state.selectedStore.split(",").length ===
                    allStoreArray.length &&
                  this.state.selectedStore
                    .split(",")
                    .slice()
                    .sort()
                    .every((value, index) => value === allStoreArray[index])
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    let allOpt = this.state.storeListOptions
                      .map((branchType) => {
                        return branchType.value;
                      })
                      .filter((x) => x !== "-1");
                    this.setState(
                      {
                        selectedStore: allOpt.join(","),
                      },
                      () => {
                        if (this.state.selectedStore !== "") {
                          this.handleChangeBranch();
                        }
                      }
                    );
                  } else {
                    this.setState(
                      {
                        selectedStore: "",
                      },
                      () => {
                        this.handleChangeBranch();
                      }
                    );
                  }
                }}
              >
                {labels.ALL}
              </Checkbox>
            </div>,
            <div key="1" style={{ alignSelf: "center" }}>
              <Select
                showSearch
                mode="multiple"
                allowClear
                onChange={(event) => {
                  this.setState(
                    {
                      selectedBranch: [null, undefined, ""].includes(event)
                        ? ""
                        : event.join(","),
                    },
                    () => {
                      this.handleChangeBranch();
                    }
                  );
                }}
                value={
                  [null, undefined, ""].includes(this.state.selectedBranch)
                    ? [localStorage.getItem("branchID")]
                    : this.state.selectedBranch.split(",")
                }
                placeholder={labels.SELECT_FACTORY}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ minWidth: 150, maxWidth: 330 }}
              >
                {factoryBranchTypes.length !== 0
                  ? factoryBranchTypes.map((branchType) => {
                      return branchType.branchID !== "-1" ? (
                        <Option
                          value={branchType.branchID.toString()}
                          key={branchType.branchID.toString()}
                        >
                          {branchType.branchName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </div>,
            <div key="2" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={
                  ![null, undefined, ""].includes(this.state.selectedBranch) &&
                  this.state.selectedBranch.split(",").length ===
                    allfactoryBranchArray.length &&
                  this.state.selectedBranch.split(",").slice().sort()
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    let allOpt = factoryBranchTypes
                      .map((branchType) => {
                        return branchType.branchID;
                      })
                      .filter((x) => x !== "-1");
                    this.setState(
                      {
                        selectedBranch: allOpt.join(","),
                      },
                      () => {
                        if (this.state.selectedBranch !== "") {
                          this.handleChangeBranch();
                        }
                      }
                    );
                  } else {
                    this.setState(
                      {
                        selectedBranch: localStorage.getItem("branchID"),
                      },
                      () => {
                        this.handleChangeBranch();
                      }
                    );
                  }
                }}
              >
                {labels.ALL}
              </Checkbox>
            </div>,
            <div key="3" style={{ alignSelf: "center" }}>
              <Search
                placeholder={labels.SEARCH}
                className={styles.SearchInput}
                key="1"
                onChange={(event) => {
                  this.setState({
                    search: event.target.value.toString().toLowerCase(),
                  });
                  this.getprocessingList(
                    this.state.pagination.current,
                    this.state.pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    event.target.value.toString().toLowerCase()
                  );
                }}
              />
            </div>,
          ]}
        />

        <Table
          size="small"
          scroll={
            this.columns.length < 4
              ? { x: 0 }
              : this.columns.length < 6
              ? { x: 1000 }
              : this.columns.length > 6 && this.columns.length <= 12
              ? { x: 1400 }
              : { x: 2000 }
          }
          columns={this.columns}
          dataSource={this.state.processinglist}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getprocessingList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />
      </div>
    );
  }
}
