import React from "react";
import { withRouter } from "react-router-dom";
import styles from "./addcashcouponcomponent.module.css";
import { Steps, PageHeader, Form, notification, Spin } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import ROUTES from "../../../../routes";
import CashCouponMaster from "../AddCashcouponMaster/cashcouponmaster.component";
import { from } from "rxjs";
import CashCouponMasterService from "../../../../services/cashcouponmasterservice";
import CashCouponCode from "../AddCashcouponMaster/cashcouponcode.component";

const { Step } = Steps;

class AddCashCoupon extends React.Component {
  cashcouponmasterservice = new CashCouponMasterService();
  state = {
    activeStep: 0,
    couponID: 0,
    fullLoading: false,
    generalFormValues: {},
    mode: "add",
    stepsDirection: "vertical",
    stepsSize: "default",
    couponTypeID: 0,
    validFromDate: "",
  };

  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "small" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };

  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    if (
      [ROUTES.EDIT_CASH_COUPON, ":couponID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });
      this.getCashCouponMaster(this.props.location.state.couponID);
    } else if (
      [ROUTES.VIEW_CASH_COUPON, ":couponID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.getCashCouponMaster(this.props.location.state.couponID);
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
  }

  getCashCouponMaster = (id) => {
    this.cashcouponmasterservice
      .getCashCouponDetailByCouponID(id)
      .then((response) => {
        if (
          response.statusCode === 200 &&
          response.data.couponID === parseInt(id)
        ) {
          this.setState({
            couponID: response.data.couponID,
            couponTypeID: response.data.couponType,
            validFromDate: response.data.validFrom
              ? response.data.validFrom
              : null,
          });
          this.setState({
            generalFormValues: {
              cashCouponcode: response.data.cashCouponcode,
              validFrom: [
                response.data.validFrom
                  ? moment(response.data.validFrom)
                  : null,
                response.data.validTo ? moment(response.data.validTo) : null,
              ],
              value: parseInt(response.data.value),
              couponType: response.data.couponType.toString(),
              noOftimes: response.data.noOftimes,
              isFirstOrderOnly: response.data.isFirstOrderOnly,
              isActive: response.data.isActive,
              redeemValidity: response.data.redeemValidity,
              applicableCityIds: response.data.applicableCityIds
                .split(",")
                .map(function (item) {
                  return parseInt(item, 10);
                }),
            },
          });
          var resDate = this.compare_dates(
            new Date(),
            new Date(this.state.generalFormValues.validFrom)
          );
          if (resDate === "Date1 > Date2") {
            this.setState({ mode: "view" });
          }
          this.setState({ fullLoading: false });
          const prevStep = this.state.activeStep;
          this.setState({ activeStep: -1 });
          this.setState({ activeStep: prevStep });
        } else {
          notification["error"]({
            message: "Cash Coupon",
            description: "Unable to get cash coupon data",
            // description: "Redirecting....",
            onClose: () => {
              this.props.history.goBack();
            },
          });
        }
      });
  };
  compare_dates = function (date1, date2) {
    if (date1 > date2) return "Date1 > Date2";
    else if (date1 < date2) return "Date2 > Date1";
    else return "Date1 = Date2";
  };
  addUpdateCashCoupon = () => {
    this.setState({ fullLoading: true });
    const postData = {
      couponID: this.state.couponID,
      cashCouponCode: this.state.generalFormValues.cashCouponcode.toString(),
      validFrom: this.state.generalFormValues.validFrom
        ? this.state.generalFormValues.validFrom[0].format("YYYY-MM-DD")
        : null,
      validTo: this.state.generalFormValues.validFrom
        ? this.state.generalFormValues.validFrom[1].format("YYYY-MM-DD")
        : null,
      value: parseInt(this.state.generalFormValues.value),
      couponType: parseInt(this.state.generalFormValues.couponType),
      noOftimes: parseInt(this.state.generalFormValues.noOftimes),
      isFirstOrderOnly: this.state.generalFormValues.isFirstOrderOnly
        ? this.state.generalFormValues.isFirstOrderOnly
        : false,
      isActive: this.state.generalFormValues.isActive
        ? this.state.generalFormValues.isActive
        : false,
      redeemValidity: parseInt(this.state.generalFormValues.redeemValidity),
      applicableCityIds: this.state.generalFormValues.applicableCityIds.join(
        ","
      ),
    };
    this.cashcouponmasterservice
      .saveUpdateCashCouponMaster(postData)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({ couponID: response.data });
          notification["success"]({
            message: "Cash Coupon",
            description: response.message,
            duration: 1,
            onClose: () => {
              this.props.history.push(ROUTES.CASH_COUPON);
              //  this.setState({ activeStep: 1 });
              // this.setState({ fullLoading: false });
            },
          });
        } else {
          notification["error"]({
            message: "Cash Coupon",
            description: response.message
              ? response.message
              : "Unable to submit the form",
          });
        }
        this.setState({ fullLoading: false });
      });
  };
  generalForm = React.createRef();
  handleSubmit = (values) => {
    this.setState(
      {
        generalFormValues: values,
      },
      () => {
        this.addUpdateCashCoupon();
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          {/* <Spin spinning={true}> */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? "Edit"
                : this.state.mode === "view"
                ? "View"
                : "Add",
              "Cash Coupon",
            ].join(" ")}
          />
          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                //onChange={(current) => this.setState({ activeStep: current })}
                onChange={(current) => {
                  if (this.state.couponID === 0) {
                    return false;
                  } else {
                    this.setState({ activeStep: current });
                  }
                }}
              >
                <Step
                  title="Coupon Master"
                  description="Coupon Master information"
                />
                <Step
                  title="Code Master"
                  description="Code Master Information"
                />
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider
                onFormFinish={(name, info) => {
                  if (name === "general") {
                    this.handleSubmit(info.values);
                  }
                }}
              >
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading} style={{ height: "100%" }}>
                  <React.Fragment>
                    <CashCouponMaster
                      values={this.state.generalFormValues}
                      backFunction={() => this.props.history.goBack()}
                      isActive={this.state.activeStep === 0}
                      instance={this.generalForm}
                      mode={this.state.mode}
                    ></CashCouponMaster>

                    {this.state.activeStep === 1 ? (
                      <CashCouponCode
                        cashCouponCodeName={this.state.generalFormValues.cashCouponcode.toString()}
                        couponTypeID={this.state.couponTypeID}
                        validFromDate={this.state.validFromDate}
                      />
                    ) : null}
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(AddCashCoupon);
