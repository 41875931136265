import React from "react";
import { PageHeader, Table, notification, Space, Tooltip ,Form,Row,Col,Select} from "antd";
import OutOfCoverageservice from "../../../services/outofcoverageservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
import Filters from "../../Common/Filters/filters";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";

export default class pickupdeliveryassignmentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.OutOfCoverageservice = new OutOfCoverageservice();
    this.common = new Common();
    this.state = {
      AssignmentItems: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      showfilterBox: true,
      loading: true,
      userRights: [],
      isResponseErrorShow: false,
      storeStatusFilter: {
        data: [],
        selected: "1",
      },
    };
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }

    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName ==
        this.UserRightsConfiguration.PICKUP_DELIVERY_ASSIGNMENT
    );

    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }

    this.setState({ userRights: res });
  }

  columns = [
    {
      title: "Sl.No.",
      dataIndex: "slno",
      key: "slno",
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
      render: (text, record) =>
      (
        <Tooltip title={record.storePhone} >
          {text}
        </Tooltip>
      ),
    },
    {
      title: "Pickup Executive",
      dataIndex: "pickupExecutive",
      key: "pickupExecutive",
      render: (text, record) => (
        <Space size="middle">
          {text.split(",").map((data, index, array) => (
            <Tooltip key={data} title={`${record.pMobileNo.split(",")[index]}`}>
              <a>{data + `${index !== array.length - 1 ? "," : ""}`}</a>
            </Tooltip>
          ))}
        </Space>
      ),
    },
    {
      title: "Delivery Executive",
      dataIndex: "deliveryExecutive",
      key: "deliveryExecutive",
      render: (text, record) => (
        <Space size="middle">
          {text.split(",").map((data, index, array) => (
            <Tooltip key={data} title={`${record.dMobileNo.split(",")[index]}`}>
              <a>{data + `${index !== array.length - 1 ? "," : ""}`}</a>
            </Tooltip>
          ))}
        </Space>
      ),
    },
    {
      title: "Both Roles",
      dataIndex: "bothRoles",
      key: "bothRoles",
      render: (text, record) => (
        <Space size="middle">
          {text.split(",").map((data, index, array) => (
            <Tooltip key={data} title={`${record.bMobileNo.split(",")[index]}`}>
              <a>{data + `${index !== array.length - 1 ? "," : ""}`}</a>
            </Tooltip>
          ))}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      storeStatus: this.state.storeStatusFilter.selected,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
    this.getStoreStatusList();
  }

  getStoreStatusList = () => {
    this.OutOfCoverageservice.getStoreStatusList().then((response) => {
      if (response.statusCode === 200) {
        let data = response.data.map((single) => {
          return {
            value: single.enumDetailID,
            label: single.enumDetailName,
          };
        });
        this.setState({
          storeStatusFilter: {
            ...this.state.storeStatusFilter,
            data: data,
          },
        });
      }
    });
  };

  //function to handle table pagination when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      storeStatus: this.state.storeStatusFilter.selected,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.OutOfCoverageservice.getpickupdeliveryassignmentlist(params).then(
      (items) => {
        if (items.statusCode === 200) {
          if (
            items.length !== 0 &&
            items.data.length === 0 &&
            params.pagination.current !== 1
          ) {
            params.pagination.current = 1;
            this.fetchItems({
              pageIndex: 1,
              pageSize: params.pageSize,
              pagination: params.pagination,
              storeStatus: this.state.storeStatusFilter.selected
            });
          }
          this.setState({
            AssignmentItems: items.data,
            pagination: {
              ...params.pagination,
              total: items.data.length > 0 ? items.data[0].totalRecords : 0,
            },
          });
        } else {
          if (!this.state.isResponseErrorShow) {
            notification["error"]({
              message: "Pickup And Delivery Executive List",
              description: items.message
                ? items.message
                : "Unable to get list of Pickup And Delivery Executive List",
            });
            this.setState({
              isResponseErrorShow: true,
            });
          }
        }
        this.setState({ loading: false });
      }
    );
  };

  render() {
    const { pagination, loading } = this.state;
    const AssignmentItems = this.state.AssignmentItems;
    const listItems = AssignmentItems.map((item, index) => ({
      key: index,
      slno: item.rowNumber,
      storeName: item.storeName,
      pickupExecutive: item.pickupExecutive,
      pMobileNo: item.pMobileNo,
      deliveryExecutive: item.deliveryExecutive,
      dMobileNo: item.dMobileNo,
      bothRoles: item.bothRoles,
      bMobileNo: item.bMobileNo,
      storePhone: item.storePhone,
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title={labels.PICKUP_DELIVERY_ASSIGNMENT}
          extra={
            <Button
              key="5"
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>
          }
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() =>
              this.fetchItems({
                pageIndex: pagination.current,
                pageSize: pagination.pageSize,
                pagination,
                storeStatus: this.state.storeStatusFilter.selected,
              })
            }
            // initialValues={{
            //   fromDate: this.state.fromDate,
            //   toDate: this.state.toDate,
            // }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={6}>
                <Form.Item name="StoreStatus">
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder={labels.STORE_STATUS}
                    className={styles.test1}
                    style={{ minWidth: 150, maxWidth: 250 }}
                    // defaultValue={["Performa Generated","Partial Payment received"]}
                    defaultValue={
                      [null, undefined, "", []].includes(
                        this.state.storeStatusFilter.selected
                      )
                        ? undefined
                        : this.state.storeStatusFilter.selected.split(",")
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      this.setState(
                        {
                          storeStatusFilter: {
                            ...this.state.storeStatusFilter,
                            selected: [null, undefined, ""].includes(value)
                              ? undefined
                              : value.join(","),
                          },
                        },
                        () => {
                          // console.log(this.state.storeStatusFilter.selected);
                        }
                      )
                    }
                  >
                    {this.state.storeStatusFilter.data.map((x) => {
                      return x.value != "-1" ? (
                        <option
                          key={x.value.toString()}
                          value={x.value.toString()}
                        >
                          {x.label}
                        </option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}
