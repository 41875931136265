import React from "react";
import styles from "./createorder.module.css";
import {
  Row,
  Col,
  AutoComplete,
  Input,
  Space,
  Dropdown,
  Menu,
  Modal,
  Form,
  notification,
} from "antd";
import Button from "../../../Common/Button/button";
import AddCustomer from "./addcustomer.component";
import CreateOrderService from "../../../../services/createorderservice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faHome,
  faPhoneAlt,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../../routes";
import { labels } from "../../../../shared/language";
import Common from "../../../../services/common";
import moment from "moment";

/**
 * This file contains the component responsible for customer selection in create order screen
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 5 June 2020
 */
class CustomerSelection extends React.Component {
  createOrderService = new CreateOrderService();
  common = new Common();
  /**
   * state varibale to hold information regarding the perticular selection
   * @var isAddressModal : is address modal is open or not
   * @var isAddForm : is Add Customer Form is open or not
   * @var customerInfo.name : Name of the selected Customer
   * @var customerInfo.selectedAddress : selected address of the customer
   * @var customerInfo.serviceType : service type for the order
   * @var customerInfo.pickupRequest : pickup request number if order is created from pickup
   * @var customerInfo.customerType : type of the customer new/old.
   * @var customerInfo.walletBalance : Laundrokart Wallet Balance
   * @var cusotmerInfo.b2bCustomer: b2b customer infromation
   * @var customerInfo.addresses: list of address for selected customer
   */
  state = {
    isAddressModal: false,
    isAddForm: false,
    serviceType: {
      disabled: true,
      data: [],
    },
    searchAutoComplete: {
      value: "",
      selected: "",
      data: [],
    },
    addressSelection: {
      data: [],
    },
    customerInfo: {
      systemDateTimeApiValue: moment(), // added by pb for solving DeliveryDate issue  [L15549]
      id: "",
      name: "",
      selectedAddress: {
        name: "",
        id: "",
      },
      serviceType: {
        name: null,
        id: null,
      },
      pickupRequest: 0,
      customerType: "",
      walletBalance: "",
      lkCreditAmt: 0,
      b2bCustomer: "",
      corporateID: "",
      customerID: 0,
      mobileNo:"",
      alterNativeMobileNo:""
    },
    isEdit: false,
    disableAll: false,
    isServiceTypes: false,
  };
  componentDidMount() {
    this.props.getCustomerInfo(this.state.customerInfo);
    if ([ROUTES.UPDATE_ORDER, ":orderID"].join("/") === this.props.match.path) {
      this.setState({ isEdit: true });
    }
    if (!this.state.isServiceTypes) this.getServiceTypes();
  }
  componentDidUpdate() {
    if ([ROUTES.UPDATE_ORDER, ":orderID"].join("/") !== this.props.match.path) {
      if (
        this.props.location.state !== undefined &&
        this.props.location.state !== null
      ) {
        if (
          this.props.location.state.pickupID !== undefined &&
          this.props.location.state.pickupID !== null
        ) {
          if (this.state.customerInfo.id !== this.props.customerInfo.id) {
            this.setState({ customerInfo: this.props.customerInfo });
            this.getCustomerAddresses(this.props.customerInfo.id);
            this.setState({ isEdit: true });
          }
        }
      } else if (this.state.customerInfo !== this.props.customerInfo) {
        this.props.getCustomerInfo(this.state.customerInfo);
      }
    } else {
      if (
        this.state.customerInfo.id !== this.props.customerInfo.id ||
        this.state.customerInfo.walletBalance !==
          this.props.customerInfo.walletBalance
      ) {
        this.setState({ customerInfo: this.props.customerInfo });
        this.getCustomerAddresses(this.props.customerInfo.id);
      }
    }
    if (
      (parseInt(this.props.orderStatus) >= 2 || this.props.orderType === 2) &&
      this.state.disableAll === false
    ) {
      this.setState({ disableAll: true });
    }
    // if (
    //   this.props.storeInfo.deliveryTypes.length > 0 &&
    //   this.state.serviceType.data.length === 0 &&
    //   this.state.customerInfo.serviceType.id === null
    // ) {
    //   debugger;
    //   if (!this.state.isServiceTypes)
    //     this.getServiceTypes();
    // }
  }
  getServiceTypes = () => {
    this.createOrderService.getServiceTypeList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          serviceType: {
            ...this.state.serviceType,
            data: response.data.filter((x) =>
              this.props.storeInfo.deliveryTypes.includes(x.value.toString())
            ),
          },
          isServiceTypes: true,
          customerInfo: {
            ...this.state.customerInfo,
            serviceType: {
              name:
                this.state.customerInfo.serviceType.name === null
                  ? response.data.filter((x) =>
                      this.props.storeInfo.deliveryTypes.includes(
                        x.value.toString()
                      )
                    ).length > 0
                    ? response.data.filter((x) =>
                        this.props.storeInfo.deliveryTypes.includes(
                          x.value.toString()
                        )
                      )[0].text
                    : null
                  : this.state.customerInfo.serviceType.name,
              id:
                this.state.customerInfo.serviceType.id === null
                  ? parseInt(
                      response.data.filter((x) =>
                        this.props.storeInfo.deliveryTypes.includes(
                          x.value.toString()
                        )
                      ).length > 0
                        ? response.data.filter((x) =>
                            this.props.storeInfo.deliveryTypes.includes(
                              x.value.toString()
                            )
                          )[0].value
                        : null
                    )
                  : this.state.customerInfo.serviceType.id,
            },
          },
        });
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_LIST_OF_SERVICES,
        });
      }
    });
  };
  searchCustomer = (value, callback = () => null) => {
    this.createOrderService.searchCustomer(value).then((response) => {
      if (response.statusCode === 200) {
        this.setState(
          {
            searchAutoComplete: {
              ...this.state.searchAutoComplete,
              data: response.data.map((customer) => {
                return {
                  value: customer.customerID.toString(),
                  label: this.renderOption(
                    customer.customerID,
                    customer.customerName,
                    customer.address,
                    customer.customerType,
                    customer.corporateID,
                    customer.mobileNo,
                    customer.walletBalance,
                    customer.lkCreditAmt,
                    customer.alterNativeMobileNo,
                  ),
                };
              }),
            },
          },
          callback()
        );
      } else {
        notification["error"]({
          message: labels.ORDERS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_LIST_OF_CUSTOMERS,
        });
      }
    });
  };
  getCustomerAddresses(customerID) {
    this.setState({ customerID: customerID });
    this.createOrderService
      .getCustomerAddresses(customerID)
      .then((response) => {
        if (response.statusCode === 200) {
          this.setState({
            addressSelection: {
              ...this.state.addressSelection,
              data: response.data,
            },
          });
          this.setState({
            customerInfo: {
              ...this.state.customerInfo,
              selectedAddress: {
                name: "",
                id: "",
              },
              // added by pb for solving DeliveryDate issue  [L15549]
              systemDateTimeApiValue: response.data[0].systemDateTimeValue, //"2021-03-26T17:10:16.5519372+05:30",
            },
          });

          let isAddressSet = false;
          response.data.map((address, index) => {
            if (!isAddressSet && address.addressType === "Home") {
              this.setState({
                customerInfo: {
                  ...this.state.customerInfo,
                  selectedAddress: {
                    name: address.fullAddress,
                    id: address.customerAddressID,
                  },
                },
              });
              isAddressSet = true;
            }
            if (!isAddressSet) {
              if (response.data.length > 0) {
                this.setState({
                  customerInfo: {
                    ...this.state.customerInfo,
                    selectedAddress: {
                      name: response.data[0].fullAddress,
                      id: response.data[0].customerAddressID,
                    },
                  },
                });
                isAddressSet = true;
              }
            }
          });
        }
      });
  }
  bringBack = () => {
    this.setState({ isAddForm: false });
  };

  openModal = () => {
    this.setState({ isAddressModal: true });
  };

  closeModal = () => {
    this.setState({ isAddressModal: false });
  };

  customerAutoCompleteOptions = () => {
    let returnArray = [];
    this.state.searchAutoComplete.data.map((customer) => {
      returnArray.push({
        value: customer.customerID.toString(),
        label: this.renderOption(
          customer.customerID,
          customer.customerName,
          customer.address,
          customer.customerType,
          customer.corporateID
        ),
      });
    });
    return returnArray;
  };
  renderOption = (
    customerID,
    customerName,
    customerAddress,
    customerMembership = null,
    corporateID = 0,
    mobileNo = null,
    walletBalance = 0,
    lkCreditAmt = 0,
    alterNativeMobileNo=null
  ) => {
    return (
      <div
        className={styles.CustomerSelectionContainer}
        onClick={() => {
          this.setState({
            customerInfo: {
              ...this.state.customerInfo,
              id: customerID,
              name: customerName,
              corporateID: corporateID,
              mobileNo:mobileNo,
              alterNativeMobileNo:alterNativeMobileNo,
              // serviceType: {
              //   ...this.state.customerInfo.serviceType,
              //   name: this.state.serviceType.data.filter(
              //     (x) => x.text === "Normal"
              //   )[0].text,
              //   id: parseInt(
              //     this.state.serviceType.data.filter(
              //       (x) => x.text === "Normal"
              //     )[0].value
              //   ),
              // },
              customerType: customerMembership,
              walletBalance: walletBalance,
              lkCreditAmt: lkCreditAmt,
            },
          });
          this.getCustomerAddresses(customerID);
        }}
      >
        <div className={styles.CustomerSelectionOption}>
          <div className={styles.CustomerNameAndMembership}>
            <div className={styles.CustomerName}>{customerName}</div>
            {customerMembership !== null && customerMembership !== "Regular" ? (
              <div className={styles.CustomerMembership}>
                {customerMembership}
              </div>
            ) : null}
          </div>
          <div className={styles.CustomerAddressWrapper}>
            <div className={styles.HomeIcon}>
              <FontAwesomeIcon icon={faPhoneAlt} />
            </div>
            <div className={styles.CustomerAddress}>{mobileNo}</div>
          </div>
          <div className={styles.CustomerAddressWrapper}>
            <div className={styles.HomeIcon}>
              <FontAwesomeIcon icon={faHome} />
            </div>
            <div className={styles.CustomerAddress}>{customerAddress}</div>
          </div>
        </div>
      </div>
    );
  };

  NavigateToAddCustomer() {
    return (
      <Link
        to={{
          pathname: [ROUTES.ADD_CUSTOMER],
          state: { from: "add" },
        }}
      >
        <FontAwesomeIcon icon={faInfo} />
      </Link>
    );
  }

  render() {
    /**
     * A function to render the customer selection dropdown option
     *
     * @param {string/JSX} customerName : name of the customer
     * @param {string/JSX} customerAddress : address of the customer
     * @param {string/JSX} customerMembership : membership - optional
     */

    /**
     * Dropdown/Selection render for address selection
     *
     * @uses state.customerInfo
     */
    const customerAddressSelect = (
      <Menu>
        {this.state.addressSelection.data.map((singleAddress, index) => {
          return (
            <Menu.Item
              key={index}
              onClick={() => {
                this.setState({
                  customerInfo: {
                    ...this.state.customerInfo,
                    selectedAddress: {
                      ...this.state.customerInfo.singleAddress,
                      id: singleAddress.customerAddressID,
                      name: singleAddress.fullAddress,
                    },
                  },
                  searchAutoComplete: {
                    ...this.state.searchAutoComplete,
                    value: "",
                  },
                });
              }}
            >
              <div className={styles.AddressSelectionContainer}>
                <div
                  className={[
                    styles.AddressSelectionOption,
                    singleAddress.isSelected
                      ? styles.AddressSelectionOptionSelected
                      : null,
                  ].join(" ")}
                >
                  <div className={styles.AddressType}>
                    {singleAddress.addressType}
                  </div>
                  <div className={styles.AddressText}>
                    {singleAddress.fullAddress}
                  </div>
                </div>
              </div>
            </Menu.Item>
          );
        })}
        {/* <Menu.Item key="-1">
          <div className={styles.AddOption} onClick={this.openModal}>
            <FontAwesomeIcon icon={faPlus} /> <span>New Address</span>
          </div>
        </Menu.Item> */}
      </Menu>
    );

    /**
     * Dropdown/Selection render for service type select
     */
    const serviceTypeSelect = (
      <Menu>
        {this.state.serviceType.data.map((service) => {
          return parseInt(service.value) !== -1 ? (
            this.props.storeInfo.deliveryTypes.includes(
              service.value.toString()
            ) ? (
              <Menu.Item
                key={service.value}
                onClick={() => {
                  this.props.recalculateRates();
                  this.setState({
                    customerInfo: {
                      ...this.state.customerInfo,
                      serviceType: {
                        ...this.state.customerInfo.serviceType,
                        name: service.text,
                        id: parseInt(service.value),
                      },
                    },
                  });
                }}
              >
                <div className={styles.ServiceSelectionContainer}>
                  <div
                    className={[
                      styles.ServiceSelectionOption,
                      parseInt(this.state.customerInfo.serviceType.id) ===
                      parseInt(service.value)
                        ? styles.ServiceSelectionOptionSelected
                        : null,
                    ].join(" ")}
                  >
                    <div className={styles.ServiceType}>{service.text}</div>
                    {/* <div className={styles.ServiceText}>Delivery in xx hours</div> */}
                  </div>
                </div>
              </Menu.Item>
            ) : null
          ) : null;
        })}
      </Menu>
    );
    const addCustomerPlaceholder = (
      <div
        className={styles.AddOption}
        onClick={() => this.setState({ isAddForm: true })}
      >
        <FontAwesomeIcon icon={faPlus} /> <span>Add Customer</span>
      </div>
    );

    /**
     * Data to generate options for customer autocomplete.
     */
    let refrence = React.createRef();
    return (
      <React.Fragment>
        {/* {JSON.stringify(this.state.searchAutoComplete.data)} */}
        {!this.state.isAddForm ? (
          <div className={styles.CustomerSelectionWrapper}>
            <Row gutter={15} align="middle">
              {!this.state.isEdit ? (
                <Col xs={24} lg={5}>
                  <Space>
                    {/**
                     * Please go through below documentation related to autocomplete
                     * https://ant.design/components/auto-complete/
                     * https://ant.design/components/auto-complete/#API
                     */}
                    <AutoComplete
                      key={this.state.searchAutoComplete.data.length}
                      autoFocus={this.state.searchAutoComplete.data.length > 0}
                      dropdownMatchSelectWidth={340}
                      defaultOpen={
                        this.state.searchAutoComplete.data.length > 0
                      }
                      options={this.state.searchAutoComplete.data}
                      onSelect={(event, options) => {
                        options.label.props.onClick();
                        this.setState({
                          searchAutoComplete: {
                            ...this.state.searchAutoComplete,
                            value: "",
                          },
                        });
                      }}
                      onSearch={() => null}
                      onChange={(value) => {
                        this.setState({
                          searchAutoComplete: {
                            ...this.state.searchAutoComplete,
                            value: value,
                          },
                        });
                        this.searchCustomer(value);
                      }}
                      filterOption={(inputValue, option) => {
                        return 1;
                      }}
                      value={this.state.searchAutoComplete.value}
                    >
                      {/**
                       * Please go through below documentation before starting
                       * https://ant.design/components/input/
                       * https://ant.design/components/input/#API
                       */}
                      <Input.Search placeholder={labels.CUSTOMER_DETAILS} />
                    </AutoComplete>
                    {/* <Button
                      icon={<FontAwesomeIcon icon={faPlus} />}
                      className={styles.AddButton}
                      variant="whitePink"
                      onClick={() => this.setState({ isAddForm: true })}
                    ></Button> */}
                    <Button
                      className={styles.AddButton}
                      variant="whitePink"
                      style={{ width: 45 }}
                    >
                      <Link
                        style={{ paddingLeft: 0, fontSize: "larger" }}
                        to={{
                          pathname: [ROUTES.ADD_CUSTOMER],
                          state: { from: 0 },
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Link>
                    </Button>
                  </Space>
                </Col>
              ) : null}
              <Col xs={24} lg={this.state.isEdit ? 24 : 19}>
                {this.state.customerInfo.id !== "" ? (
                  <Row gutter={10} align="top">
                    <Col xs={24} md={3}>
                      <div className={styles.CustomerInfoLabel}>
                        {labels.NAME}
                      </div>
                      <div className={styles.CustomerInfoValue}>
                        {this.state.customerInfo.name}
                      </div>
                    </Col>
                    <Col xs={24} md={4}>
                      <Row gutter={15}>
                        <Col xs={24} md={15}>
                          {this.state.disableAll || this.state.isEdit ? (
                            <div className={styles.DropdownTrigger}>
                              <div className={styles.CustomerInfoLabel}>
                                {labels.ADDRESS}
                              </div>
                              <div className={styles.CustomerInfoValue}>
                                {this.state.customerInfo.selectedAddress.name !=
                                  undefined &&
                                this.state.customerInfo.selectedAddress.name
                                  .length > 32
                                  ? this.state.customerInfo.selectedAddress.name.substr(
                                      0,
                                      32
                                    ) + "..."
                                  : this.state.customerInfo.selectedAddress
                                      .name != undefined
                                  ? this.state.customerInfo.selectedAddress.name
                                  : ""}
                              </div>
                            </div>
                          ) : (
                            <Dropdown overlay={customerAddressSelect}>
                              <div className={styles.DropdownTrigger}>
                                <div className={styles.CustomerInfoLabel}>
                                  {labels.ADDRESS}
                                </div>
                                <div className={styles.CustomerInfoValue}>
                                  {this.state.customerInfo.selectedAddress.name
                                    .length > 32
                                    ? this.state.customerInfo.selectedAddress.name.substr(
                                        0,
                                        32
                                      ) + "..."
                                    : this.state.customerInfo.selectedAddress
                                        .name}
                                </div>
                              </div>
                            </Dropdown>
                          )}
                        </Col>
                        <Row gutter={1} align="middle">
                          <Col xs={24} md={1}>
                            <Button
                              className={styles.AddButton}
                              variant="whitePink"
                              style={{ width: 45 }}
                            >
                              <Link
                                style={{ paddingLeft: 0, fontSize: "larger" }}
                                to={{
                                  pathname: [
                                    ROUTES.EDIT_CUSTOMER,
                                    this.state.customerID,
                                  ].join("/"),
                                  state: { from: this.state.customerID },
                                }}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Link>

                              {/* <Link style={{ paddingLeft: 0, fontSize: "larger" }}  to={[ROUTES.EDIT_CUSTOMER, this.state.customerID].join("/")}>
                              <FontAwesomeIcon icon={faPlus} />
                            </Link> */}
                            </Button>
                          </Col>
                        </Row>
                      </Row>
                    </Col>

                    <Col xs={24} md={4}>
                      {/**
                       * Please go through below documentation for Dropdown Component
                       * https://ant.design/components/dropdown/
                       * https://ant.design/components/dropdown/#API
                       *
                       */}
                     
                      {this.state.disableAll || this.state.isEdit 
                      || this.props.orderArray.length > 0  //Added by rashmi for L177728 - 21/02/23
                      ? (
                        <div className={styles.DropdownTrigger}>
                          <div className={styles.CustomerInfoLabel}>
                            {labels.TYPE_OF_SERVICE}
                          </div>
                          <div className={styles.CustomerInfoValue}>
                            {this.state.customerInfo.serviceType.name}
                          </div>
                        </div>
                      ) : 
                      (
                        <Dropdown overlay={serviceTypeSelect}>
                          <div className={styles.DropdownTrigger}>
                            <div className={styles.CustomerInfoLabel}>
                              {labels.TYPE_OF_SERVICE}
                            </div>
                            <div className={styles.CustomerInfoValue}>
                              {this.state.customerInfo.serviceType.name}
                            </div>
                          </div>
                        </Dropdown>
                      )
                      }
                    </Col>
                    {this.state.customerInfo.pickupRequest !== 0 ? (
                      <Col xs={24} md={3}>
                        <div className={styles.CustomerInfoLabel}>
                          {labels.PICKUP_REQUEST}
                        </div>
                        <div className={styles.CustomerInfoValue}>
                          {this.state.customerInfo.pickupRequest}
                        </div>
                      </Col>
                    ) : null}
                    <Col xs={24} md={3}>
                      <div className={styles.CustomerInfoLabel}>
                        {labels.TYPE_OF_CUSTOMER}
                      </div>
                      <div className={styles.CustomerInfoValue}>
                        {this.state.customerInfo.customerType}
                      </div>
                    </Col>
                    {this.state.customerInfo.customerType !==
                    "B2B/Corporate" ? (
                      <React.Fragment>
                        <Col xs={24} md={3}>
                          <div className={styles.CustomerInfoLabel}>
                            {labels.WALLET_CASH}
                          </div>
                          <div className={styles.CustomerInfoValue}>
                            ₹{" "}
                            {parseFloat(
                              this.state.customerInfo.walletBalance
                            ).toFixed(2)}
                          </div>
                        </Col>
                        <Col xs={24} md={3}>
                          <div className={styles.CustomerInfoLabel}>
                            {labels.WALLET_CREDIT}
                          </div>
                          <div className={styles.CustomerInfoValue}>
                            ₹{" "}
                            {parseFloat(
                              this.state.customerInfo.lkCreditAmt
                            ).toFixed(2)}
                          </div>
                        </Col>
                      </React.Fragment>
                    ) : null}
                    {/* <Col xs={24} md={4}>
                      <div className={styles.CustomerInfoLabel}>
                        B2B Customer
                      </div>
                      <div className={styles.CustomerInfoValue}>
                        {this.state.customerInfo.b2bCustomer}
                      </div>
                    </Col> */}
                  </Row>
                ) : null}
              </Col>
            </Row>
          </div>
        ) : (
          <AddCustomer
            bringBack={this.bringBack}
            openAddressModal={this.openModal}
          />
        )}
        {/**
         * Add Address Modal
         * Please go through below documents
         * https://ant.design/components/modal/
         * https://ant.design/components/modal/#API
         */}
        <Modal
          title="Add Address"
          visible={this.state.isAddressModal}
          onOk={this.closeModal}
          onCancel={this.closeModal}
          footer={null}
          className="modal-form"
        >
          {/**
           * Please go through below documentation
           * https://ant.design/components/form/
           * https://ant.design/components/form/#API
           */}
          <Form layout="vertical">
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item label="Address Type" name="addressType">
                  <Input placeholder="Enter Address Type" />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Form.Item label="Address Text" name="addressText">
                  <Input.TextArea rows={3} placeholder="Enter Address " />
                </Form.Item>
              </Col>
              <Col xs={24} className="custom-modal-footer">
                <Space>
                  <Button
                    variant="transparentGreyOutline"
                    onClick={this.closeModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    variant="purple"
                    onClick={this.closeModal}
                  >
                    Add
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}
export default withRouter(CustomerSelection);
