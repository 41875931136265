import React from "react";
import styles from "../../../components/Common/Styles/master.module.css";
import GeneralForm from "../../../components/Master/Store/CreateStore/generalform.component";
import StoreContact from "../../../components/Master/Store/CreateStore/storecontact.component";
import StoreList from "./storelist.screen";
import { PageHeader, Steps, notification, Form, Spin, message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import StoreService from "../../../services/storeservice";
import SlotMapping from "../../../components/Master/Store/CreateStore/slotmapping.component";
import FactoryMapping from "../../../components/Master/Store/CreateStore/factorymapping.component";
import Moment from "moment";
import { withRouter } from "react-router-dom";
import ROUTES from "../../../routes";

const { Step } = Steps;

// const openNotificationWithIcon = (type, title, message) => {
//   notification[type]({
//       message: title,
//       description:
//           message
//   });
// };
/**
 * User Creation Screen with steps and form (Master - Form).
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 1 June 2020
 *
 */
class CreateStore extends React.Component {
  /**
   * State Varible to Hold Active Step Index starting from 0
   */
  constructor(props) {
    super(props);
    this.storeservice = new StoreService();
    this.state = {
      stateID: 0,
      isAdduser: false,
      mode: "add",
      isViewmode: false,
      fullLoading: false,
      activeStep: 0,
      stepsDirection: "vertical",
      stepsSize: "default",
      generalFormValues: {},
      contactFormValues: {},

      // General details
      storeID: 0,
      companyID: 1,

      // Store Contact details
      storeContactID: 0,
    };
  }

  setStepsDirection = () => {
    if (window.innerWidth < 801) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "mini" });
    } else if (window.innerWidth < 992) {
      this.setState({ stepsDirection: "horizontal" });
      this.setState({ stepsSize: "default" });
    } else {
      this.setState({ stepsDirection: "vertical" });
      this.setState({ stepsSize: "default" });
    }
  };
  
  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.props.history.push(ROUTES.REDIRECT_INVALID_REQUEST);
    }
    if ([ROUTES.EDIT_STORE, ":storeID"].join("/") === this.props.match.path) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "edit" });
      this.getStore(this.props.location.state.storeID);
    } else if (
      [ROUTES.VIEW_STORE, ":storeID"].join("/") === this.props.match.path
    ) {
      this.setState({ fullLoading: true });
      this.setState({ mode: "view" });
      this.setState({ isViewmode: true });
      this.getStore(this.props.location.state.storeID);
    }
    this.setStepsDirection();
    window.addEventListener("resize", this.setStepsDirection);
  }

  getStore = (id) => {
    this.storeservice.getStoreDetailByStoreID(id).then((response) => {
      if (
        response.statusCode === 200 &&
        response.data.vMStoreMaster.storeID === parseInt(id)
      ) {
        this.setState({ storeID: response.data.vMStoreMaster.storeID });
        this.setState({
          storeContactID:
            response.data.vMStoreMaster.vMStoreContact.storeContactID,
          stateID: response.data.vMStoreMaster.vMStoreContact.stateID,
        });
        localStorage.setItem(
          "geoFencing",
          response.data.vMStoreMaster.vMStoreContact.geoCoOrdinates
        );
        this.setState({
          generalFormValues: {
            companyID: response.data.vMStoreMaster.companyID,
            storeName: response.data.vMStoreMaster.storeName,
            shortName: response.data.vMStoreMaster.shortName,
            openingDate: response.data.vMStoreMaster.openingDate
              ? Moment(response.data.vMStoreMaster.openingDate, "M/D/yyyy")
              : "",
            operationType: response.data.vMStoreMaster.operationType.toString(),
            storeServiceType: response.data.vMStoreMaster.storeServiceType.split(
              ","
            ),
            typeOfStore: response.data.vMStoreMaster.typeOfStore.toString(),
            storeModel: response.data.vMStoreMaster.storeModel.toString(),
            operatedBy: response.data.vMStoreMaster.operatedBy.toString(),
            operatingType: response.data.vMStoreMaster.operatingType.toString(),
            operatingDays: response.data.vMStoreMaster.operatingDays.split(","),
            // response.data.vMStoreMaster.operatingDays
            //   .split(",")
            //   .map(function (item) {
            //     return parseInt(item, 10);
            //   }),

            operatingFrom: Moment(
              response.data.vMStoreMaster.operatingFrom,
              "h:mm a"
            ),
            operatingTo: Moment(
              response.data.vMStoreMaster.operatingTo,
              "h:mm a"
            ),
            status: response.data.vMStoreMaster.status.toString(),
            orderAssignedToStore: [null, undefined, "", 0].includes(
              response.data.vMStoreMaster.orderAssignedToStore
            )
              ? undefined
              : response.data.vMStoreMaster.orderAssignedToStore.toString(),
            gstIn: response.data.vMStoreMaster.gstIn,
            deliveryType:
              response.data.vMStoreMaster.deliveryType === ""
                ? []
                : response.data.vMStoreMaster.deliveryType.split(","),
            serviceIDs:
              response.data.serviceIds === null
                ? null
                : response.data.serviceIds.split(","),
            feedbackLink : response.data.vMStoreMaster.feedbackLink
          },
        });

        this.setState({
          contactFormValues: {
            storeContactID:
              response.data.vMStoreMaster.vMStoreContact.storeContactID,
            address: response.data.vMStoreMaster.vMStoreContact.address,
            zone: response.data.vMStoreMaster.vMStoreContact.zone.toString(),
            geoCoOrdinates:
              response.data.vMStoreMaster.vMStoreContact.geoCoOrdinates,
            phone: response.data.vMStoreMaster.vMStoreContact.phone,
            storeManager:
              response.data.vMStoreMaster.vMStoreContact.storeManager,
            storeExecutive:
              response.data.vMStoreMaster.vMStoreContact.storeExecutive,
            areaManager: response.data.vMStoreMaster.vMStoreContact.areaManager,
            stateID: response.data.vMStoreMaster.vMStoreContact.stateID.toString(),
            cityID: response.data.vMStoreMaster.vMStoreContact.cityID.toString(),
            latitude: response.data.vMStoreMaster.vMStoreContact.latitude,
            longitude: response.data.vMStoreMaster.vMStoreContact.longitude,
            pinCode: response.data.vMStoreMaster.vMStoreContact.pinCode,
            showOnWebsite:response.data.vMStoreMaster.vMStoreContact.showOnWebsite,
            showMarkerOnApp:response.data.vMStoreMaster.vMStoreContact.showMarkerOnApp  
          },
        });

        this.setState({ fullLoading: false });
        this.setState({ activeStep: -1 });
        this.setState({ activeStep: 0 });
      } else {
        notification["error"]({
          description: response.message
            ? response.message
            : "Unable to get store data",
          message: "Store Master",
          onClose: () => {
            this.props.history.goBack();
          },
        });
      }
    });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleSelectChange = (event, ddlList, name, text) => {
    this.setState({ [name]: parseInt(event) });
    var ddlListArray = ddlList.filter((x) => x.value === parseInt(event));
    if (ddlListArray !== undefined) {
      this.setState({ [text]: ddlListArray[0].text });
    }
  };

  handleMultiSelectChange = (event, name) => {
    this.setState({ [name]: event });
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  handleTimeChange = (time, name) => {
    this.setState({ [name]: time });
  };

  handleSaveUpdatStoreMaster(event) {
    var storeSericeTypeList = "";
    var storeSeviceOfferedList = "";
    // var operatingDaysList = "";
    var deliveryTypeList = "";
    if (this.state.generalFormValues.storeServiceType.length > 0) {
      for (
        var i = 0;
        i < this.state.generalFormValues.storeServiceType.length;
        i++
      ) {
        storeSericeTypeList +=
          this.state.generalFormValues.storeServiceType[i] + ",";
      }
    }
    if (this.state.generalFormValues.serviceIDs.length > 0) {
      for (var j = 0; j < this.state.generalFormValues.serviceIDs.length; j++) {
        storeSeviceOfferedList +=
          this.state.generalFormValues.serviceIDs[j] + ",";
      }
    }
    if (
      this.state.generalFormValues.deliveryType != null &&
      this.state.generalFormValues.deliveryType != undefined
    ) {
      if (this.state.generalFormValues.deliveryType.length > 0) {
        for (
          var k = 0;
          k < this.state.generalFormValues.deliveryType.length;
          k++
        ) {
          deliveryTypeList +=
            this.state.generalFormValues.deliveryType[k] + ",";
        }
      }
    }

    // if (this.state.generalFormValues.operatingDays.length > 0) {
    //   for (
    //     var l = 0;
    //     l < this.state.generalFormValues.operatingDays.length;
    //     l++
    //   ) {
    //     operatingDaysList +=
    //       this.state.generalFormValues.operatingDays[l] + ",";
    //   }
    // }

    const postSave = {
      storeID: this.state.storeID,
      storeName: this.state.generalFormValues.storeName,
      shortName: this.state.generalFormValues.shortName,
      openingDate: this.state.generalFormValues.openingDate
        ? this.state.generalFormValues.openingDate.format("yyyy-MM-DD")
        : null,
      operationType: parseInt(this.state.generalFormValues.operationType),
      storeServiceType: storeSericeTypeList.substring(
        0,
        storeSericeTypeList.length - 1
      ),
      typeOfStore: parseInt(this.state.generalFormValues.typeOfStore),
      storeModel: parseInt(this.state.generalFormValues.storeModel),
      operatedBy: parseInt(this.state.generalFormValues.operatedBy),
      operatingType: parseInt(this.state.generalFormValues.operatingType),
      operatingDays: this.state.generalFormValues.operatingDays.join(","),
      //  operatingDaysList.substring(
      //   0,
      //   operatingDaysList.length - 1
      // ),
      operatingFrom: this.state.generalFormValues.operatingFrom.format("HH:mm"),
      operatingTo: this.state.generalFormValues.operatingTo.format("HH:mm"),
      status: parseInt(this.state.generalFormValues.status),
      orderAssignedToStore: parseInt(
        this.state.generalFormValues.orderAssignedToStore
      ),
      gstIn: this.state.generalFormValues.gstIn,
      feedbackLink : this.state.generalFormValues.feedbackLink,
      deliveryType: deliveryTypeList.substring(0, deliveryTypeList.length - 1),
      serviceIDs: storeSeviceOfferedList.substring(
        0,
        storeSeviceOfferedList.length - 1
      ),
      vMStoreSaveContact: {
        storeContactID: this.state.storeContactID,
        zone: parseInt(this.state.contactFormValues.zone),
        geoCoOrdinates:
          localStorage.getItem("geoFencing") !== undefined &&
          localStorage.getItem("geoFencing") !== null
            ? localStorage.getItem("geoFencing").trim()
            : null,
        address: this.state.contactFormValues.address,
        phone: this.state.contactFormValues.phone,
        storeExecutive: this.state.contactFormValues.storeExecutive,
        storeManager: this.state.contactFormValues.storeManager,
        areaManager: this.state.contactFormValues.areaManager,
        stateID: parseInt(this.state.contactFormValues.stateID),
        cityID: parseInt(this.state.contactFormValues.cityID),
        latitude: this.state.contactFormValues.latitude,
        longitude: this.state.contactFormValues.longitude,
        pinCode: this.state.contactFormValues.pinCode,
        showOnWebsite:this.state.contactFormValues.showOnWebsite === false ? false : true,
        showMarkerOnApp:this.state.contactFormValues.showMarkerOnApp === false ? false : true ,
      },
    };

    // console.log("postSave->",postSave);

    this.setState({ fullLoading: true });
    this.storeservice.saveUpdateStoreMaster(postSave).then((response) => {
      if (response.statusCode === 200) {
        localStorage.setItem("geoFencing", "");
        notification["success"]({
          message: "Store Master",
          description: response.message
            ? response.message
            : "Data saved successfully",
          duration: 1.5,
          onClose: () => {
            this.props.history.goBack();
          },
        });
      } else {
        notification["error"]({
          message: "Store Master",
          description: response.message,
        });
      }
      this.setState({ fullLoading: false });
      // if(response.result=="Success")
      // {
      //   openNotificationWithIcon('success', 'Store Master', response.message)
      //   this.setState({isAdduser:true});
      // }
      // else {
      //   openNotificationWithIcon('error', 'Store Master', response.message)
      // }
    });
  }

  /**
   * General Tab Form Submit Handler
   * Please go through https://ant.design/components/form/#API for more information
   *
   */

  generalForm = React.createRef();
  contactForm = React.createRef();

  generalFormValidated = false;
  contactFormValidated = false;
  handleSubmit = () => {
    const generalValidator = this.generalForm.current.validateFields();
    const contactValidator = this.contactForm.current.validateFields();

    Promise.allSettled([generalValidator, contactValidator]).then(
      (responses) => {
        if (responses[0].status === "rejected") {
          this.setState({ activeStep: 0 });
        } else if (responses[1].status === "rejected") {
          this.setState({ activeStep: 1 });
        } else if (
          responses[0].status === "fulfilled" &&
          responses[1].status === "fulfilled"
        ) {
          this.setState(
            {
              generalFormValues: responses[0].value,
              contactFormValues: responses[1].value,
            },
            () => {
              this.handleSaveUpdatStoreMaster(); 
            }
          );
        }
      }
    );
  };

  render() {
    const { data } = this.state;

    // const{storeMasterData,isEditmode,isViewmode,disabled}=this.props;
    return this.state.isAdduser ? (
      <StoreList backFunction={this.bringback} />
    ) : (
      <React.Fragment>
        <Spin spinning={this.state.fullLoading}>
          {/**
           * Page Header Component from Ant Design
           * Please check below link for more options
           * https://ant.design/components/page-header/
           * https://ant.design/components/page-header/#API
           *
           */}
          <PageHeader
            onBack={() => this.props.history.goBack()}
            backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            title={[
              this.state.mode === "edit"
                ? "Edit"
                : this.state.mode === "view"
                ? "View"
                : "Add",
              "Store",
            ].join(" ")}
          />

          <div className={styles.StepsContentWrap}>
            <div className={styles.StepsWrapper}>
              {/**
               * Steps Component from Ant Design
               * Please check below link for more options
               * https://ant.design/components/steps/
               * https://ant.design/components/steps/#API
               *
               */}
              <Steps
                direction={this.state.stepsDirection}
                size={this.state.stepsSize}
                current={this.state.activeStep}
                onChange={(current) => this.setState({ activeStep: current })}
              >
                <Step title="General" description="General Information" />
                <Step title="Contact" description="Contact Information" />
                {this.state.mode === "edit" || this.state.mode === "view" ? (
                  <Step title="Slot Mapping" description="Slot Mapping" />
                ) : null}
                {this.state.mode === "edit" || this.state.mode === "view" ? (
                  <Step title="Factory Mapping" description="Factory Mapping" />
                ) : null}
              </Steps>
            </div>
            <div className={styles.StepsContent}>
              <Form.Provider>
                {this.state.activeStep === -1 ? "Loading...." : null}
                <div key={this.state.fullLoading}>
                  <React.Fragment>
                    <GeneralForm
                      values={this.state.generalFormValues}
                      backFunction={() => this.props.history.goBack()}
                      nextFunction={() => this.setState({ activeStep: 1 })}
                      isActive={this.state.activeStep === 0}
                      instance={this.generalForm}
                      mode={this.state.mode}
                    />
                    <StoreContact
                      geoFencing={localStorage.getItem("geoFencing")}
                      values={this.state.contactFormValues}
                      backFunction={() =>
                        this.setState({ activeStep: this.state.activeStep - 1 })
                      }
                      nextFunction={this.handleSubmit}
                      isActive={this.state.activeStep === 1}
                      instance={this.contactForm}
                      mode={this.state.mode}
                      stateID={this.state.stateID}
                    />
                    {this.state.activeStep === 2 ? (
                      <SlotMapping
                        data={data}
                        storeID={this.state.storeID}
                        isViewmode={this.state.isViewmode}
                        mode={this.state.mode}
                      />
                    ) : null}
                    {this.state.activeStep === 3 ? (
                      <FactoryMapping
                        // isActive={this.state.activeStep === 2}
                        // data={data}
                        storeID={this.state.storeID}
                        isViewmode={this.state.s}
                        mode={this.state.mode}
                      />
                    ) : null}
                  </React.Fragment>
                </div>
              </Form.Provider>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }
}
export default withRouter(CreateStore);
