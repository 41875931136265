//var allLabels = require("../../public/files/language.json")

import { faMehRollingEyes } from "@fortawesome/free-solid-svg-icons";

// const allLabels = data.map((res) => {
//   return res;
// })

const allLabels = {
  ENGLISH: {
    SELECT_SERVICE: "Select Service",
    SELECT_FACTORY: "Select Factory",
    SPLIT_ORDER: "Split Order",
    CUSTOMER_NAME: "Customer Name",
    ORDER_DATE: "Order Date",
    TOTAL_GARMENTS: "Total Garments",
    SPLIT_ORDER_NUMBER: "Split Order Number",
    TAG_NUMBER: "Tag Number",
    GARMENT_NAME: "Garment Name",
    GARMENT_DEFECT : "Garment Defect",
    ADD_ON_SERVICES: "Add-on Services",
    GENERATE_GATE_PASS: "Generate Gate Pass",
    GATE_PASS_NUMBER: "Gate pass Number",
    STORE_NAME: "Store Name",
    FACTORY_NAME: "Factory Name",
    STORE_GARMENTS_OUT: "Store Garments Out",
    TOTAL_SPLIT_NUMBER: "Total Split number",
    GATE_PASS_DATE: "Gate Pass Date",
    STIKER_NUMBER: "Sticker Number",
    PLEASESCAN_ADD_STICKER: "Please Scan/Add Sticker",
    ENTER_SCAN_STICKER: "Enter/Scan Sticker",
    CUSTOMER_CONTACT: "Customer Contact",
    DELIVERY_TYPE: "Delivery Type",
    SPLIT_ORDER_NO: "Split Order No",
    GATE_PASS_NO: "Gate Pass No",
    DELIVERY_DATE: "Delivery Date",
    GARMENTS_IN_PROCESS: "Garments In Process",
    PACKED_GARMENTS: "Packed Garments",
    PACKING_MATERIAL: "Packing Material",
    STICKER_STATUS: "Sticker Status",
    SPLIT_ORDER_STATUS: "Split Order Status",
    GARMENTS_PACKED_INFORMATION: "Garments Packed Information",
    USER_EXPENSE_ENTRY: "User Expense Entry",
    USER_NAME: "User Name",
    EXPENSE_DATE: "Expense Date",
    AMOUNT: "Amount",
    REMARKS: "Remarks",
    ARE_YOU_SURE: "Are you sure ?",
    ADD_EXPENSE: "Add Expense",
    APPLY: "Apply",
    SELECT_EXPENSE_TYPE: "Select Expense Type",
    PLEASE_ENTER_AMOUNT: "Please Enter Amount",
    ENTER_AMOUNT: "Enter Amount",
    ENTER_REMARKS: "Enter Remarks",
    GARMENTS_PENDING_TO_SEND_TO_FACTORY: "Garments Pending to send to Factory",
    ORDERS_PENDING_TO_SEND_TO_FACTORY: "Orders Pending to send to Factory",
    SPILT_ORDERS_RECEIVED_FROM_FACTORY: "Spilt Orders Received from Factory",
    PENDING_FOR_NO_OF_DAYS: "Pending for No of Days",
    STORE_GARMENT_INVENTORY: "Store Garment Inventory",
    GARMENTS_RECEIVED_FROM_FACTORY: "Garments Received from Factory",
    FACTORY_TRANSFER_IN: "Factory Transfer-In",
    SELECT_STORE: "Select Store",
    GET_MISSING_GARMENTS: "Get Missing Garments",
    TAGS_FROM_STORES: "Tags From Stores",
    SCANNING: "Scanning",
    TAGS_TO_THE_FACTORY: "Tags To The Factory",
    MISSING_GARMENTS: "Missing Garments",
    SCAN_COMPLETE: "Scan Complete",
    SEARCH_HERE: "Search Here",
    RESORTING_AND_PACKAGING: "Resorting & Packaging",
    ORDER_NUMBER: "Order Number",
    SRORE_NAME: "Store Name",
    NO_OF_GARMENTS: "No Of Garments",
    GARMENT_LEVEL_INFO: "Garment Level Info",
    SELECTED_PENDING_GARMENT: "Selected Pending Garment",
    PREVIOUSLY_GARMENTS_PACKED_IN: "Previously Garments Packed In",
    PENDING_GARMENTS_TO_BE_PACKED_IN: "Pending Garments To Be Packed In",
    GENERATE_STICKER: "Generate Sticker",
    PRINT_GENERATE_STICKER: "Print Generate Sticker",
    SR_NO: "Sr. No",
    GARMENTS_PACKED: "Garments Packed",
    PENDING_GARMENTS: "Pending Garments",
    PACKING_GARMENTS: "Packing Garments",
    FACTORY_TRANSFER_OUT: "Factory Transfer Out",
    FACTORY_GATE_PASS_NUMBER: "Factory Gate Pass Number :",
    STORE_ADDRESS: "Store Address",
    CREATED_BY: "Created By",
    S_NO: "S. No",
    SPLIT_ORDER_ID: "Split Order Id",
    NUMBER_OF_GARMENTS: "Number Of Garments",
    PACKED: "Packed",
    ORDER_LOT_SIZE: "Order Lot Size",
    PREVIOUSLY_SENT: "Previously Sent",
    SENDING_TODAY: "Sending Today",
    PENDING: "Pending",
    BAG: "Bag",
    HANGER: "Hanger",
    BLANKET: "Blanket",
    SHOE: "Shoe",
    CARPET: "Carpet",
    CURTAIN: "Curtain",
    OTHERS: "Others",
    STICKER_NO: "Sticker No",
    SPLLIT_ORDER_NO: "Split Order No",
    PACKING_MATERIAL_NAME: "Packing Material Name",
    PRE_QC_RESPONSE_LIST: "Pre-QC Response List",
    SELECT_QC_STATUS: "Select QC Status",
    TAG: "Tag",
    CONTACT: "Contact",
    QC_REQUEST: "Qc Request",
    TIME: "Time",
    QC_REASON: "QC Reason",
    REMARK: "Remark",
    QC_PENDING_FROM: "QC Pending From",
    QC_STATUS: "QC Status",
    ENTER_SCAN_TAG_NUMBER: "Enter/Scan Tag Number",
    GARMENT_HISTORY: "Garment History",
    GARMENT_INFORMATION: "Garment Information",
    GARMENT_STATUS: "Garment Status",
    GATE_PASS: "Gate Pass",
    BRAND: "Brand",
    COLOR: "Color",
    ADD_ON_SERVICE: "Add On Service",
    SPECIAL_INSTRUCTION: "Special Instruction",
    LATEST_QC_DETAILS: "Latest QC Details",
    IMAGES: "Images",
    REASON: "Reason",
    SELECT_REASON: "Select Reason",
    COMMUNICATION_HISTORY: "Communication History",
    SELECT_SCAN_GARMENT: "Select/Scan Garment",
    GARMENT_FOR_QC: "Garment for QC",
    SCANNED_ITEM: "Scanned Item",
    ADDON_SERVICE: "AddOn Service",
    CONTACT_NO: "Contact No",
    STICKER_INFORMATION: "Sticker Information",
    DOWNLOAD_GATE_PASS: "Download Gate Pass",
    STORE_GATE_PASS_NUMBER: "Store Gate Pass Number",
    FACTORY_ADDRESS: "Factory Address",
    PICKUP_DATE: "Pickup Date",
    REFFERAL_PROGRAM_NAME: "Refferal Program Name",
    REFFERAL_BONUS_AMOUNT: "Refferal Bonus Amount",
    REFEREE_BONUS_AMOUNT: "Referee Bonus Amount",
    YOU_CAN_ONLY_UPDATE_THE_LAST_ADDED_PROGRAM:
      "You can only update the last added program",
    UNABLE_TO_GET_LIST_OF_REFFERAL_BONUS_CONFIGURATION_DATA:
      "Unable to get list of refferal bonus configuration data",
    UNABLE_TO_SUBMIT_THE_DATA: "Unable to submit the data",
    ADD_REFFERAL_BONUS_CONFIGURATION: "Add Refferal Bonus Configuration",
    VIEW_REFFERAL_BONUS_CONFIGURATION: "View Refferal Bonus Configuration",
    EDIT_REFFERAL_BONUS_CONFIGURATION: "Edit Refferal Bonus Configuration",
    PLEASE_ENTER_REFFERAL_PROGRAM_NAME: "Please Enter Refferal Program Name",
    ENTER_REFFERAL_PROGRAM_NAME: "Enter Refferal Program Name",
    PLEASE_ENTER_REFERRAL_BONUS_AMOUNT: "Please Enter Refferal Bonus Amount",
    ENTER_REFERRAL_BONUS_AMOUNT: "Enter Refferal Bonus Amount",
    PLEASE_ENTER_REFEREE_BONUS_AMOUNT: "Please Enter Referee Bonus Amount",
    ENTER_REFEREE_BONUS_AMOUNT: "Enter Referee Bonus Amount",
    ENTER_BONUS_AMOUNT_VALIDITY_DAYS: "Enter Bonus Amount Validity (Days)",
    ARE_YOU_SURE_YOU_WANT_TO_CREATE_THIS_PROGRAM_AS_PREVIOUS_PROGRAMS_WILL_GET_DEACTIVATED:
      "Are you sure you want to create this program as previous programs will get deactivated",
    FILE_NAME: "File Name",
    TOTAL_RECORDS: "Total Records",
    SUCCEEDED: "Succeeded",
    DOWNLOAD: "Download",
    FAILED: "Failed",
    UPLOADED_BY: "Uploaded By",
    UPLOADED_ON: "Uploaded On",
    UNABLE_TO_GET_LIST_OF_EMPLOYEE_CREDIT_DATA:
      "Unable to get list of employee credit data",
    UNABLE_TO_UPLOAD_THE_FILE: "Unable to upload the file",
    DOWNLOAD_FORMAT: "Download Format",
    UPLOAD_FILE: "Upload File",
    ADD_EMPLOYEE_CREDITS: "Add Employee Credits",
    VIEW_EMPLOYEE_CREDITS: "View Employee Credits",
    EDIT_EMPLOYEE_CREDITS: "Edit Employee Credits",
    UPLOAD_EMPLOYEE_CREDIT_CONFIGURATION_FILE:
      "Upload Employee Credit Configuration File",

    ADD_CUSTOMER_BULK_CREDIT: "Add Customer Bulk Credit",
    VIEW_CUSTOMER_BULK_CREDIT: "View Customer Bulk Credit",
    EDIT_CUSTOMER_BULK_CREDIT: "Edit Customer Bulk Credit",
    UPLOAD_CUSTOMER_BULK_CREDIT_CONFIGURATION_FILE:
      "Upload Customer Bulk Credit Configuration File",
    UNABLE_TO_GET_LIST_OF_CUSTOMER_BULK_CREDIT_DATA:
      "Unable to get list of customer bulk credit data",

    ADD_CUSTOMER_BULK_DEBIT: "Add Customer Bulk Debit",
    VIEW_CUSTOMER_BULK_DEBIT: "View Customer Bulk Debit",
    EDIT_CUSTOMER_BULK_DEBIT: "Edit Customer Bulk Debit",
    UPLOAD_CUSTOMER_BULK_DEBIT_CONFIGURATION_FILE:
      "Upload Customer Bulk Debit Configuration File",
    UNABLE_TO_GET_LIST_OF_CUSTOMER_BULK_DEBIT_DATA:
      "Unable to get list of customer bulk debit data",

    ADD_BULK_UPLOAD_COUPON_CODES: "Add Bulk Upload Coupon Codes",
    VIEW_BULK_UPLOAD_COUPON_CODES: "View Bulk Upload Coupon Codes",
    EDIT_BULK_UPLOAD_COUPON_CODES: "Edit Bulk Upload Coupon Codes",
    UPLOAD_BULK_UPLOAD_COUPON_CODES_CONFIGURATION_FILE:
      "Upload Bulk Upload Coupon Codes Configuration File",
    UNABLE_TO_GET_LIST_OF_BULK_UPLOAD_COUPON_CODES_DATA:
      "Unable to get list of Bulk Upload Coupon Codes data",

    PLEASE_SELECT_FILE: "Please select File",
    CLICK_TO_UPLOAD: "Click to Upload",
    UPLOAD: "Upload",
    UNABLE_TO_LOAD_PACKAGES: "Unable to Load Packages",
    UNABLE_TO_GET_LIST_OF_CUSTOMERS: "Unable to get list of customers",
    TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED:
      "Transaction has been successfully processed",
    UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN:
      "Unable to Process transaction,please try again",
    UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM:
      "Unable to Process. Please contact technical team",
    PACKAGE_PURCHASED_SUCCESSFULLY: "Package purchased successfully",
    UNABLE_TO_BUY_PACKAGE: "Unable to buy package",
    BUY: "Buy",
    PLEASE_SELECT_CUSTOMER: "Please Select Customer",
    SEARCH_CUSTOMER: "Search Customer",
    MONEY_TYPE: "Money Type",
    ADDED_BY: "Added By",
    ADDED_ON: "Added On",
    UNABLE_TO_GET_LIST_OF_ADVANCE_PAYMENT_DATA:
      "Unable to get list of advance payment data",
    ADVANCE_COLLECTED_SUCCESSFULLY: "Advance collected successfully",
    UNABLE_TO_ADD_AMOUNT_TO_LKWALLET: "Unable to add amount to LKWallet",
    ADD_ADVANCE_PAYMENT: "Add Advance Payment",
    VALID_FROM: "Valid From",
    VALID_TO: "Valid To",
    UNABLE_TO_GET_LIST_OF_COMPENSATION_CONFIGURATION_DATA:
      "Unable to get list of compensation configuration data",
    UNABLE_TO_ADD_THE_DATA: "Unable to add the data",
    COMPENSATION_CONFIGURATION: "Compensation Configuration",
    ADD_COMPENSATION_CONFIGURATION: "Add Compensation Configuration",
    VIEW_COMPENSATION_CONFIGURATION: "View Compensation Configuration",
    EDIT_COMPENSATION_CONFIGURATION: "Edit Compensation Configuration",
    VALID_FROM_AND_TO_DATE: "Valid From And To Date",
    PLEASE_SELECT_VALID_FROM_AND_TO_DATE:
      "Please Select Valid From And To Date",
    PLEASE_ENTER_REMARK: "Please Enter Remark",
    ENTER_REMARK: "Enter Remark",
    UNABLE_TO_GET_LIST_OF_REFUND_DATA: "Unable to get list of refund data",
    UNABLE_TO_ADD_REFUND_DATA: "Unable to add refund data",
    ADD_REFUND: "Add Refund",
    VIEW_REFUND: "View Refund",
    DATA_SUCCESSFULLY_SUBMITTED: "Data successfully submitted",
    UNABLE_TO_SUBMIT_TAG_DATA: "Unable to submit tag data",
    UNABLE_TO_LOAD_TAG_DATA: "Unable to Load tag data",
    UNABLE_TO_GET_TAG_DATA: "Unable to get tag data",
    PLEASE_ENTER_TAG_NO: "Please enter tag no",
    FACTORY_POST_QC: "Factory Post-QC",
    PLEASE_SCAN_ADD_TAG_NUMBER: "Please scan/add tag number",
    SELECT_STATUS: "Select Status",
    PLEASE_ENTER_REMARKS: "Please Enter Remarks",
    UNABLE_TO_LOAD_PRE_QC_RESPONSES: "Unable to load pre-qc responses",
    FACTORY_PRE_QC: "Factory Pre-QC",
    ADD_GARMENT_FOR_QC: "Add Garment for QC",
    DATA_IS_SUCCESSFULLY_SAVED: "Data is successfully saved",
    GARMENT_SHOULD_BE_IN_FACTORY_TO_BE_ADDED_FOR_QC:
      "Garment should be in factory to be added for QC",
    UNABLE_TO_GET_GARMENT_DETAILS: "Unable to get garment details",
    GET_GARMENT_HISTORY: "Get Garment History",
    PLEASE_SELECT_REASON: "Please Select Reason",
    UNABLE_TO_GET_CORPORATE_DATA: "Unable to get corporate data",
    GARMENTS_AT_QC: "Garments At QC",
    GARMENTS_IN_POST_QC: "Garments in Post QC",
    UNABLE_TO_GET_FACTORY_GARMENTS_INVENTORY_DATA:
      "Unable to get Factory Garments Inventory Data",
    GARMENT_INVENTORY_AT_FACTORY: "Garment Inventory At Factory",
    DASHBOARD: "Dashboard",
    CUSTOMERS: "Customers",
    MANAGE_CUSTOMERS: "Manage Customers",
    PACKAGE_CONFIGURATION: "Package Configuration",
    REFERRAL_BONUS_CONFIGURATION: "Refferal Bonus Configuration",
    EMPLOYEE_CREDITS: "Employee Credits",
    MISSING: "Missing",
    DAMAGE: "Damage",

    BUY_PACKAGE: "Buy Package",
    CUSTOMER_BULK_CREDIT: "Customer Bulk Credit",
    CUSTOMER_BULK_DEBIT: "Customer Bulk Debit",
    ADVANCE_PAYMENTS: "Advance Payments",
    COMPENSATION: "Compensation",
    REFUND: "Refund",
    MASTER: "Master",
    STORE: "Store",
    FACTORY: "Factory",
    CORPORATE: "Corporate",
    CITY: "City",
    STATE: "State",
    DESIGNATION: "Designation",
    GARMENT_BRAND: "Garment Brand",
    GARMENT_SIZE: "Garment Size",
    KYC_DOCUMENT_TYPE: "KYC Document Type",
    SERVICE: "Service",
    GARMENT: "Garment",
    REWASH_REASON: "Rewash Reason",
    OPERATION_INSTRUCTION: "Operation Instruction",
    EXPENSE_TYPE: "Expense Type",
    ORDERS: "Orders",
    PICKUPS: "Pickups",
    MANAGE_ORDERS: "Manage Orders",
    READY_FOR_DELIVERY_OR_INVOICE: "Ready for Delivery/Invoice",
    DELIVERIES: "Deliveries",
    TRANSFER_IN_OUT: "Transfer In/Out",
    TRANSFER_OUT: "Transfer Out",
    GATE_PASS_LIST: "Gate Pass List",
    TRANSFER_IN: "Transfer In",
    PRE_QC_RESPONSES: "Pre-QC Response",
    USER_EXPENSES: "User Expenses",
    INVENTORY: "Inventory",
    SETTINGS: "Settings",
    AUTHENTICATION: "Authentication",
    USERS: "Users",
    ROLES: "Roles",
    ROLE_OPERATIONS: "Role Operations",
    CONFIGURATION: "Configuration",
    TIME_CARD: "Time Card",
    RATE_CARD: "Rate Card",
    DISCOUNT: "Discount",
    CASH_COUPON: "Cash Coupon",
    UPLOAD_CUSTOMER_TO_COUPON_MAPPING: "Upload Customer to Coupon Mapping",
    PACKAGING_CHARGES: "Packaging Charges",
    DELIVERY_CHARGES: "Delivery Charges",
    REWARDS_CONFIG: "Rewards Config",
    BANNER: "Banner",
    NOTICE_BOARD: "Notice Board",
    FINGER_SCAN: "Finger Scan",
    COLLECT_FINGERPRINTS: "Collect Fingerprints",
    QC_RESPONSES: "QC Responses",
    PRE_QC: "Pre QC",
    POST_QC: "Post QC",
    TODAYS_HIGHLIGHTS: "Today's Highlights",
    ORDER_VS_ORDER_AMOUNT: "Orders/Order Amount",
    INVOICE_VS_INVOICE_AMOUNT: "Invoice/Invoice Amount",
    STORE_INVENTORY: "Store Inventory",
    OTHER_STATASTICS: "Other Statistics",
    PROCESSED: "Processed",
    SOILED: "Soiled",
    AMOUNT_DUE_FOR_COLLECTION: "Amount Due for collection",
    ORDERS_READY_FOR_INVOICE: "Orders Ready for Invoice",
    DELIVERY_PERSON: "Delivery Person",
    WALK_IN: "Walk-in",
    PENDING_TO_TRANSFER_OUT_TO_FACTORY: "Pending to transfer out to factory",
    NO_OF_ORDERS: "No. of Orders",
    PENDING_PICKUPS: "Pending Pickups",
    DATE: "Date",
    ORDERS_DUE_FOR_DELIVERY: "Orders due for delivery",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    GENDER: "Gender",
    EMAIL: "Email",
    PHONE: "Phone",
    TYPE: "Type",
    BILLING_CYCLE: "Biling Cycle",
    ACTIONS: "Actions",
    VIEW: "View",
    EDIT: "Edit",
    DELETE: "Delete",
    SEARCH: "Search",
    ADD_CUSTOMER: "Add Customer",
    ADD: "Add",
    CUSTOMER: "Customer",
    WALLET_CASH: "Wallet Cash",
    WALLET_BONUS: "Wallet Bonus",
    GENERAL: "General",
    GENERAL_CUSTOMER_INFORMATION: "General Customer Information",
    ADDRESS: "Address",
    CUSTOMER_ADDRESSES: "Customer Addresses",
    VERIFICATION: "Verification",
    VERIFY_PHONE_AND_EMAIL: "Verfiy Phone and Email",
    PLEASE: "Please",
    SELECT: "Select",
    ENTER: "Enter",
    VALID: "Valid",
    PHONE_NUMBER: "Phone Number",
    DATE_OF_BIRTH: "Date of Birth",
    ANNIVERSARY_DATE: "Anniversary Date",
    REFERRAL_CODE: "Referral Code",
    CUSTOMER_TYPE: "Customer Type",
    GSTIN: "GSTIN",
    ACTIVE: "Active",
    STATUS: "Status",
    YES: "Yes",
    NO: "No",
    CANCEL: "Cancel",
    SUBMIT: "Submit",
    ID: "ID",
    PINCODE: "Pincode",
    ADRESSES_OF: "Addresses of ",
    HOME: "Home",
    OFFICE: "Office",
    OTHER: "Other",
    LONGITUDE: "Longitude",
    LATITUDE: "Latitude",
    IS_DEFAULT: "Set this as Default",
    PLEASE_ENTER_FIRST_NAME: "Please Enter First Name",
    ENTER_FIRST_NAME: "Enter First Name",
    PLEASE_ENTER_LAST_NAME: "Please Enter Last Name",
    ENTER_LAST_NAME: "Enter Last Name",
    PLEASE_ENTER_EMAIL: "Please Enter Email",
    PLEASE_ENTER_VALID_EMAIL: "Please Enter Valid Email",
    ENTER_EMAIL: "Enter Email",
    PLEASE_ENTER_PHONE_NUMBER: "Please Enter Phone Number",
    PLEASE_ENTER_VALID_PHONE_NUMBER: "Please Enter Valid Phone Number",
    ENTER_PHONE_NUMBER: "Enter Phone Number",
    PLEASE_SELECT_GENDER: "Please Select Gender",
    SELECT_DATE: "Select Date",
    ENTER_REFERRAL_CODE: "Enter Referral Code",
    PLEASE_SELECT_CUSTOMER_TYPE: "Please Select Customer Type",
    SELECT_CUSTOMER_TYPE: "Select Customer Type",
    SELECT_CORPORATE: "Select Corporate",
    PLEASE_SELECT_BILLING_CYCLE: "Please Select Billing Cycle",
    SELECT_BILLING_CYCLE: "Select Billing Cycle",
    ENTER_VALID_GSTIN: "Enter valid GSTIN",
    ENTER_GSTIN: "Enter GSTIN",
    PLEASE_SELECT_STATUS: "Please Select Status",
    PLEASE_SELECT_WALKIN: "Please Select Walkin",
    ADD_ADDRESS: "Add Address",
    VIEW_ADDRESS: "View Address",
    EDIT_ADDRESS: "Edit Address",
    PLEASE_SELECT_ADDRESS_TYPE: "Please Select Address Type",
    SELECT_TYPE: "Select Type",
    ENTER_LONGITUDE: "Enter Longitude",
    ENTER_LATITUDE: "Enter Latitude",
    PLEASE_SELECT_STATE: "Please Select State",
    SELECT_STATE: "Select State",
    PLEASE_SELECT_CITY: "Please Select City",
    SELECT_CITY: "Select City",
    PLEASE_ENTER_PINCODE: "Please Enter Pincode",
    ENTER_PINCODE: "Enter Pincode",
    HOUSE_NO: "House No./Flat No./Floor/Street",
    PLEASE_ENTER_HOUSE_NO: "Please Enter House No./Flat No./Floor/Street",
    ENTER_HOUSE_NO: "Enter House No./Flat No./Floor/Street",
    LANDMARK: "Landmark",
    ENTER_LANDMARK: "Enter Landmark",
    HOW_TO_REACH: "How To Reach",
    ENTER_HOW_TO_REACH: "Enter How To Reach",
    PLEASE_ENTER_ADDRESS: "Please Enter Address",
    ENTER_ADDRESS: "Enter Address",
    UPDATE: "Update",
    PLEASE_ENTER_OTP: "Please Enter OTP",
    ENTER_OTP: "Enter OTP",
    VERIFY: "Verify",
    DIDNT_RECIEVED_ANYTHIG: "Didn't Received Anything ?",
    RESEND_OTP: "Resend OTP",
    NOT_VERIFIED: "Not Verified",
    VERIFY_PHONE_NUMBER: "Verify Phone Number",
    VERIFIED: "Verified",
    PACKAGE_NAME: "Package Name",
    PACKAGE_AMOUNT: "Package Amount",
    BONUS_AMOUNT: "Bonus Amount",
    BONUS_VALIDITY: "Bonus Validity",
    TITLE: "Title",
    DESCRIPTION: "Description",
    ADD_PACKAGE_CONFIGURATION: "Add Package Configuration",
    DELETE_SELECTED: "Delete Selected",
    VIEW_PACKAGE_CONFIGURATION: "View Package Configuration",
    EDIT_PACKAGE_CONFIGURATION: "Edit Package Configuration",
    PLEASE_ENTER_PACKAGE_NAME: "Please Enter Package Name",
    ENTER_PACKAGE_NAME: "Enter Package Name",
    PLEASE_ENTER_PACKAGE_AMOUNT: "Please Enter Package Amount",
    ENTER_PACKAGE_AMOUNT: "Enter Package Amount",
    PLEASE_ENTER_BONUS_AMOUNT: "Please Enter Bonus Amount",
    ENTER_BONUS_AMOUNT: "Enter Bonus Amount",
    BONUS_AMOUNT_VALIDITY_DAYS: "Bonus Amount Validity (Days)",
    PLEASE_ENTER_BONUS_AMOUNT_VALIDITY_DAYS:
      "Please Enter Bonus Amount Validity (Days)",
    ENTER_BONUS_VALIDITY_DAYS: "Enter Bonus Validity (Days)",
    DESCRIPTION_TITLE: "Description Title",
    ENTER_DESCRIPTION_TITLE: "Enter Description Title",
    PLEASE_ENTER_DESCRIPTION_TITLE: "Please Enter Description Title",
    PLEASE_ENTER_DESCRIPTION: "Please Enter Description",
    ENTER_DESCRIPTION: "Enter Description",
    PICKUP_ID: "Pickup ID",
    PROMOCODE: "Promocode",
    ASSIGNED_USER_NAME: "Assigned User Name",
    ORDER: "Order",
    REASSIGN: "Reassign",
    ADD_PICKUP: "Add Pickup",
    REASSIGN_PICKUP_EXECUTIVE: "Reassign Pickup Executive",
    PICKUP_EXECUTIVE: "Pickup Executive",
    PLEASE_SELECT_ANY_PICKUP_EXECUTIVE: "Please Select Any Pickup Executive",
    SELECT_PICKUP_EXECUTIVE: "Select Pickup Executive",
    PICKUP: "Pickup",
    HISTORY: "History",
    GENERAL_PICKUP_INFORMATION: "General Pickup Information",
    HISTORY_OF_RESCHEDULED_PICKUPS: "History Of Rescheduled Pickups",
    PICKUP_STATUS: "Pickup Status",
    SELECT_PICKUP_STATUS: "Select Pickup Status",
    PLEASE_SELECT_PICKUP_DATE: "Please Select Pickup Date",
    PICKUP_TIMESLOT: "Pickup TimeSlot",
    PLEASE_SELECT_TIMESLOT: "Please Select TimeSlot",
    SELECT_TIMESLOT: "Select Timeslot",
    SELECT_ADDRESS: "Select Address",
    PLEASE_SELECT_DELIVERY_TYPE: "Please Select Delivery Type",
    SELECT_DELIVERY_TYPE: "Select Delivery Type",
    ENTER_NO_OF_GARMENTS: "Enter No. Of Garments",
    ENTER_PROMOCODE: "Enter Promocode",
    SERVICES: "Services",
    PLEASE_SELECT_SERVICES: "Please Select Services",
    SELECT_SERVICES: "Select Services",
    DELIVERY_TIMESLOT: "Delivery TimeSlot",
    CANCELLATION_REASON: "Cancellation Reason",
    SELECT_OPERATION_INSTRUCTION: "Select Operation Instruction",
    RESCHEDULED_PICKUP_HISTORY: "Rescheduled Pickup History",
    ON_DEMAND_WALK_IN: "On Demand/Walk-in",
    GARMENTS: "Garments",
    CREATE_ORDER: "Create Order",
    PRINT_TAGS: "Print Tags",
    CANCEL_ORDER: "Cancel Order",
    CANCEL_ORDER_BY_ADMIN: "Cancel Order By Admin",
    CANCEL_REASON: "Cancel Reason",
    PLEASE_ENTER_REASON: "Please Enter Reason",
    ENTER_REASON_TO_CANCEL_THE_ORDER: "Enter Reason to Cancel The Order",
    CUSTOMER_DETAILS: "Customer Details",
    NAME: "Name",
    TYPE_OF_SERVICE: "Type Of Service",
    PICKUP_REQUEST: "Pickup Request",
    TYPE_OF_CUSTOMER: "Type of customer",
    WALLET_CREDIT: "Wallet Credit",
    FILTER: "Filter",
    ORDER_SUMMARY: "Order Summary",
    DETAILS: "Details",
    QTY: "Qty",
    RATES: "Rates",
    FEES: "Fees",
    SUBTOTAL: "Subtotal",
    TAXES_AND_CHARGES: "Taxes & Charges",
    PROMO_DISCOUNT: "Promo Discount",
    SGST: "SGST",
    CGST: "CGST",
    IGST: "IGST",
    TOTAL: "TOTAL",
    ORDER_REMARKS: "Order Remarks",
    REVIEW_ORDER: "Review Order",
    MEDIUM: "Medium",
    REQUIRED: "Required",
    WEIGHT: "Weight",
    SELECT_ADD_ON_SERVICES: "Select Add-on Services",
    DARNING_SIZE: "Darning Size",
    SMALL: "Small",
    LARGE: "Large",
    DYING_COLORS: "Dying Color(s)",
    SIZE: "Size",
    PRICE: "Price",
    NOTES: "Notes",
    ONLY_3_IMAGES_ARE_ALLOWED: "Only 3 Images Are Allowed",
    LOGISTICS_INSTRUCTION: "Logistics Instruction",
    ON_DEMAND_DELIVERY: "On Demand Delivery",
    REPRINT_TAGS: "Reprint Tags",
    REPRINT_ESTIMATED_ORDER: "Reprint Estimated Order",
    REWASH_REQUESTED_BY: "Rewash Requested By",
    PLACE_ORDER: "Place Order",
    COLLECT_ADVANCE_PAYMENT: "Collect Advance Payment",
    UPDATE_ORDER: "Update Order",
    CONFIRM_ORDER: "Confirm Order",
    TAG_IN_GARMENTS: "Tag In Garments",
    CREATE_REWASH_ORDER: "Create Rewash Order",
    PAYMENT_METHOD: "Payment Method",
    COLLECT: "Collect",
    PROCESSING: "Processing",
    INVOICE_NUMBER: "Invoice Number",
    GENERATE: "Generate",
    SETTLE: "Settle",
    READY_FOR_DELIVERY_ORDERS: "Ready For Delivery Orders",
    HIDE_FILTERS: "Hide Filters",
    SHOW_FILTERS: "Show Filters",
    PLEASE_SELECT_FROM_DATE: "Please Select From Date",
    FROM_DATE: "From Date",
    PLEASE_SELECT_TO_DATE: "Please Select To Date",
    TO_DATE: "To Date",
    ENTER_SPLIT_ORDER_NUMBER: "Enter Split Order number",
    PROFORMA_INVOICE_NUMBER: "Proforma Invoice Number",
    PROFORMA_INVOICE_DATE: "Proforma Invoice Date",
    INVOICE_DATE: "Invoice Date",
    INVOICE: "Invoice",
    ORDER_TYPE: "Order Type",
    PICKUP_BY: "Pickup by",
    ADJUSTED_LK_BONUS_AMOUNT: "Adjusted LK Bonus Amount",
    PROFORMA_INVOICE: "Proforma Invoice",
    TOTAL_AMOUNT: "Total Amount",
    ADD_ON_SERVICE_CHARGES: "Add-on Service Charges",
    ADHOC_CHARGES: " Adhoc Charges",
    PAIDLESS_AMOUNT: "Adjusted By Admin",
    ADJUSTED_AMOUNT: "Paid Less",
    AMOUNT_TO_COLLECT: "Amount to Collect",
    ADD_REMARKS: "Add Remarks",
    UPDATE_PROFORMA_INVOICE: "Update Proforma Invoice",
    CREATE_PROFORMA_INVOICE: "Create Proforma Invoice",
    USE_LK_WALLET: "Use LK Wallet",
    GENERATE_TAX_INVOICE: "Generate Tax Invoice",
    DO_YOU_WANT_TO_ADD_THE_EXCESS_AMOUNT_TO_THE_LKWALLET:
      "Do you want to add the excess amount to the LKWallet",
    SETTLE_LESS_PAID_INVOICE: "Settle Less Paid Invoice",
    SLOT: "Slot",
    ASSIGNED_TO: "Assigned To",
    RESCHEDULE: "Reschedule",
    DELIVERY_LIST: "Delivery List",
    REASSIGN_DELIVERY: "Reassign Delivery",
    PLEASE_SELECT_ASSIGNEE: "Please select Assignee",
    SELECT_ASSIGNEE: "Select Assignee",
    DELIVERY_ID: "Delivery ID",
    RESCHEDULE_DELIVERY: "Reschedule Delivery",
    CANCEL_DELIVERY: "Cancel Delivery",
    PLEASE_SELECT_DELIVERY_DATE: "Please select delivery date",
    PLEASE_ENTER_CANCEL_REASON: "Please Enter Cancel Reason",
    ENTER_CANCEL_REASON: "Enter Cancel Reason",
    PLEASE_SELECT_EXPENSE_TYPE: "Please select Expense Type",
    PLEASE_SELECT_EXPENSE_DATE: "Please select Expense Date",
    PLEASE_SELECT_QC_STATUS: "Please select QC Status",
    VIEW_ON_MAP:"View On Map",
    // OVERDUE_GARMENTS: "Overdue Garments",
    OVERDUE_ORDERS: "Overdue Orders", // changed by pb on 23/11/2020 for C15180
    GARMENT_COUNTS: "Garment Counts",
    ORDER_COUNTS: "Order Counts",
    TOTAL_PENDING_GARMENTS_ORDERS: "Total pending garments/Orders",
    TODAYS_DUE: "Today’s Due",
    TICKETS_COUNTS: "Tickets Counts",
    ORDER_CAN_NOT_BE_CREATED_USING_CLOSED_STORE:
      "Order can not be created using closed store",
    THIS_TYPE_OF_ORDER_CAN_NOT_BE_CREATED_FROM_THIS_STORE:
      "This type of order can not be created from this store",
    AMOUNT_IS_REQUIRED: "Amount is required",
    AMOUNT_SHOULD_BE_GREATER_THAN_0: "Amount should be greater than 0",
    IS_LAUNDROKART_EMPLOYEE: "Is Laundrokart Employee",
    IS_WALK_IN : "Walkin",
    REWASH_ORDER: "Rewash Order",
    PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9:
      "Please Enter Only Digits Starting With 1 to 9",
    PLEASE_ENTER_ONLY_ALPHABETS: "Please Enter Only Alphabets",
    MISSING_TAG_NOS: "Missing Tag Nos",
    //store dashboard
    UNABLE_TO_GET_ORDERS_DUE_FOR_DELIVERY:
      "Unable to get orders due for delivery",
    UNABLE_TO_GET_PENDING_PICKUPS: "Unable to get Pending Pickups",
    UNABLE_TO_LOAD_ORDERS_PENDING_TO_TRANSFER_OUT_TO_FACTORY:
      "Unable to load orders pending to transfer out to factory",
    UNABLE_TO_GET_DATA_FOR_DASHBOARD: "Unable to get data for dashboard",
    // delivery list
    UNABLE_TO_GET_DATA_FOR_TIMESLOT: "Unable to get data for timeSlot",
    CANNOT_REASSIGN_DELIVERY_AS_IT_IS_ALREADY_PROCEEDED_FURTHER:
      "Cannot reassign delivery as it is already proceeded further",
    DELIVERY_REASSIGNED_SUCCESSFULLY: "Delivery reassigned successfully",
    UNABLE_TO_REASSIGN_DELIVERY_REQUEST: "Unable to reassign delivery request",
    DELIVERY_STATUS_MUST_BE_DELIVERY_SCHEDULED_OR_ASSIGNED:
      "Delivery Status Must Be Delivery Scheduled or Assigned",
    DELIVERY_RESCHEDULED_SUCCESSFULLY: "Delivery rescheduled successfully",
    UNABLE_TO_RESCHEDULE_DELIVERY_REQUEST:
      "Unable to reschedule delivery request",
    REQUEST_CANCELLED_SUCCESSFULLY: "Request cancelled successfully",
    UNABLE_TO_CANCEL_DELIVERY_REQUEST: "Unable to cancel delivery request",
    UNABLE_TO_GET_LIST_OF_DELIVERY_LIST_DATA:
      "Unable to get list of delivery list data",
    //Expense type
    EXPENSE_DELETED_SUCCESSFULLY: "Expense deleted successfully",
    UNABLE_TO_DELETE_EXPENSE: "Unable to delete expense",
    EXPENSE_ADDED_SUCCESSFULLY: "Expense added successfully",
    EXPENSE_UPDATED_SUCCESSFULLY: "Expense updated successfully",
    UNABLE_TO_SUBMIT_DATA: "Unable to submit data",
    UNABLE_TO_GET_LIST_OF_EXPENSES: "Unable to get list of expenses",
    //gate pass list
    UNABLE_TO_GET_LIST_OF_GATE_PASS_DATA:
      "Unable to get list of gate pass data",
    // store Inventory
    UNABLE_TO_GET_STORE_GARMENT_INVENTORY_DATA:
      "Unable to get store garment inventory data",
    //Invoice
    UNABLE_TO_GET_SPLIT_ORDER_DETAILS: "Unable to get split order details",
    INVOICE_GENERATED_SUCCESSFULLY: "Invoice generated successfully",
    UNABLE_TO_GENERATE_INVOICE: "Unable to generate invoice",
    INVOICE_DATA_SUBMITTED_SUCCESSFULLY: "Invoice data submitted successfully",
    UNABLE_TO_SUBMIT_INVOICE_DATA: "Unable to submit invoice data",
    INVOICE_SETTLED_SUCCESSFULLY: "Invoice settled successfully",
    UNABLE_TO_SETTLE_INVOICE: "Unable to settle invoice",
    //orders
    UNABLE_TO_GET_LIST_OF_ORDERS: "Unable to get list of orders",
    UNABLE_TO_GENERATE_ESTIMATED_ORDER: "Unable to generate estimated order",
    UNABLE_TO_GET_LIST_OF_SERVICES: "Unable to get list of services",
    UNABLE_TO_GET_STORE_DATA: "Unable to get store data",
    UNABLE_TO_GET_PICKUP_DATA: "Unable to get pickup data",
    TAGS_GENERATED_SUCCESSFULLY: "Tags generated successfully",
    UNABLE_TO_GENERATE_TAGS: "Unable to generate tags",
    UNABLE_TO_GET_ORDER_INFORMATION: "Unable to get order information",
    UNABLE_TO_GET_TIME_CARD: "Unable to get time card",
    UNABLE_TO_LOAD_ADD_ON_SERVICES: "Unable to load add on services",
    UNABLE_TO_GET_PACKAGING_CHARGES: "Unable to get packaging charges",
    UNABLE_TO_GET_DELIVERY_CHARGES: "Unable to get delivery charges",
    UNABLE_TO_GET_LIST_OF_GARMENTS: "Unable to get list of garments",
    UNABLE_TO_GET_RATES: "Unable to get rates",
    ORDER_SUCCESSFULLY_CREATED: "Order successfully created",
    UNABLE_TO_CREATE_THE_ORDER: "Unable to create the order",
    PLEASE_ENTER_THE_PROMOCODE: "Please enter the promocode",
    PROMOCODE_APPLIED_SUCCESSFULLY: "Promocode applied successfully",
    PROMOCODE_IS_INVALID: "Promocode is invalid",
    UNABLE_TO_VALIDATE_PROMOCODE: "Unable to validate promocode",
    ORDER_SUCCESSFULLY_UPDATED: "Order successfully updated",
    UNABLE_TO_UPDATE_THE_ORDER: "Unable to update the order",
    ORDER_SUCCESSFULLY_CANCELLED: "Order successfully cancelled",
    UNABLE_TO_CANCEL_ORDER: "Unable to cancel order",
    //Pickups
    UNABLE_TO_GET_LIST_OF_PICKUP_DATA: "Unable to get list of pickup data",
    UNABLE_TO_GET_LIST_OF_USER_DATA: "Unable to get list of user data",
    UNABLE_TO_REASSIGN_THE_PICKUP: "Unable to reassign the pickup",
    UNABLE_TO_GET_DATA_FOR_HISTORY: "Unable to get data for history",
    UNABLE_TO_GET_DATA_FOR_DELIVERY_TYPES:
      "Unable to get data for delivery types",
    UNABLE_TO_GET_DATA_FOR_PICKUP_STATUS:
      "Unable to get data for pickup status",
    UNABLE_TO_GET_LIST_OF_OPERATION_INSTRUCTION_DATA:
      "Unable to get list of operation instruction data",
    UNABLE_TO_GET_SERVICES_DATA: "Unable to get services data",
    UNABLE_TO_GET_LIST_OF_REASONS_DATA: "Unable to get list of reasons data",
    UNABLE_TO_GET_DATA_FOR_CUSTOMER: "Unable to get data for customer",
    UNABLE_TO_GET_CUSTOMER_ADDRESSES: "Unable to get customer addresses",
    UNABLE_TO_GET_DATA: "Unable to get data",
    PLEASE_INPUT_CUSTOMERNAME_ADDRESS_SERVICES_PROMOCODE:
      "Please input CustomerName/Address/Services/Promocode",
    PICKUP_IS_UPDATED: "Pickup is updated",
    PICKUP_IS_SCHEDULED: "Pickup is scheduled",
    PICKUP_IS_RESCHEDULED_SUCCESSFULLY: "Pickup is rescheduled successfully",
    UNABLE_TO_RESCHEDULE_THE_PICKUP: "Unable to reschedule the pickup",
    PICKUP_IS_CANCELLED_SUCCESSFULLY: "Pickup is cancelled successfully",
    UNABLE_TO_CANCEL_PICKUP: "Unable to cancel pickup",
    SELECTED_DATE_IS_HOLIDAY: "Selected date is a holiday",
    //pre qc response
    UNABLE_TO_GET_PRE_QC_RESPONSE_LIST: "Unable to get pre-qc response list",
    QC_RESPONSE_SUBMITTED_SUCCESSFULLY: "QC response submitted successfully",
    UNABLE_TO_SUBMIT_QC_RESPONSE: "Unable to submit QC response",
    //ready for delivery/invoice
    UNABLE_TO_GET_LIST_OF_READY_FOR_DELIVERY_INVOICE_DATA:
      "Unable to get list of ready for delivery/invoice data",
    //store transfer in
    UNABLE_TO_SUBMIT_STICKER_DATA: "Unable to submit sticker data",
    UNABLE_TO_GET_STICKER_DATA: "Unable to get sticker data",
    PLEASE_ENTER_STICKER_ID: "Please enter sticker id",
    //store transfer out
    UNABLE_TO_GET_LIST_OF_SERVICE_DATA: "Unable to get list of service data",
    UNABLE_TO_GET_SPLIT_ORDER_NUMBERS: "Unable to get split order numbers",
    UNABLE_TO_GET_LIST_OF_FACTORY_DATA: "Unable to get list of factory data",
    GATE_PASS: "Gate pass ",
    HAS_BEEN_GENERATED: "has been generated",
    UNABLE_TO_GET_LIST_OF_STICKER_DATA: "Unable to get list of sticker data",
    //packageconfiguration
    UNABLE_TO_GET_LIST_OF_PACKAGE_CONFIGURATION_DATA:
      "Unable to get list of package configuration data",
    PLEASE_SELECT_ATLEAST_ONE_ROW: "Please Select Atleast One Row",
    //review order
    UNABLE_TO_LOAD_REWASH_REASONS: "Unable to load rewash reasons",
    UNABLE_TO_LOAD_REWASH_REQUESTED_BY_LIST:
      "Unable to load rewash requested by list",
    UNABLE_TO_LOAD_OPERATION_INSTRUCTIONS:
      "Unable to load operation instructions",
    UNABLE_TO_LOAD_BRANDS: "Unable to load brands",
    UNABLE_TO_LOAD_SIZES: "Unable to load sizes",
    UNABLE_TO_LOAD_COLORS: "Unable to load colors",
    UNABLE_TO_LOAD_GARMENT_DEFECT: "Unable to load garment defect",

    //resorting and packaging 29/10/2020
    PREVIOUSLY_PACKED_ON: "Previously Packed On",

    //sidebar.json 29/10/2020
    CASH_COLLECTION: "Cash Collection",

    //Server Masages
    CUSTOMER_IS_SUCCESSFULLY_REGISTERED: "Customer is successfully registered",
    DATA_IS_SUCCESSFULLY_RETRIEVED: "Data is successfully retrieved  ",
    DATA_IS_SUCCESSFULLY_DELETED: "Data is successfully deleted  ",
    DATA_IS_SUCCESSFULLY_SAVED: "Data is successfully saved  ",
    DATA_IS_SUCCESSFULLY_UPDATED: "Data is successfully updated  ",
    RECORD_IS_ALREADY_EXISTS: "Record is already exists",
    INTERNAL_SERVER_ERROR: "Internal Server Error",
    DATA_IS_SUCCESSFULLY_UPLOADED: "Data is successfully uploaded  ",
    DISCOUNT_IS_SUCCESSFULLY_BLOCKED: "Discount is successfully blocked",
    DISCOUNT_IS_SUCCESSFULLY_AUTHORIZED: "Discount is successfully authorized",
    DISCOUNT_IS_SUCCESSFULLY_GENERATED: "Discount is successfully generated",
    OTP_HAS_BEEN_SUCCESSFULLY_RESENT: "OTP has been successfully re-sent",
    PICKUP_IS_SUCCESSFULLY_RESCHEDULED: "Pickup is successfully rescheduled  ",
    PICKUP_IS_SUCCESSFULLY_SCHEDULED: "Pickup is successfully scheduled  ",
    PICKUP_IS_SUCCESSFULLY_CANCELLED: "Pickup is successfully cancelled  ",
    PICKUP_IS_SUCCESSFULLY_REASSIGNED: "Pickup is successfully reassigned  ",
    ERROR_WHILE_SENDING_CALL_BACK_REQUEST_NOTIFICATION:
      "Error while sending Call back request notification",
    ORDER_IS_SUCCESSFULLY_CANCELLED: "Order is successfully cancelled  ",
    ORDER_IS_SUCCESSFULLY_CREATED_PLEASE_CONFIRM_ORDER_TO_PROCEED_FURTHER:
      "Order is successfully created  please confirm order to proceed further",
    ORDER_IS_SUCCESSFULLY_UPDATED: "Order is successfully updated  ",
    GENERATE_TAGS_FOR_ORDER_SUCCESSFULLY:
      "Generate tags for order successfully  ",
    GETEPASS_IS_SUCCESSFULLY_GENERATED: "Getepass is successfully Generated",
    PROMOCODE_VALIDATED_SUCCESSFULLY: "Promocode validated successfully",
    INVALID_PROMOCODE: "Invalid Promocode",
    DELIVERY_IS_SUCCESSFULLY_REASSIGNED:
      "Delivery is successfully reassigned  ",
    AMOUNT_SUCCESSFULLY_BLOCKED: "Amount Successfully Blocked",
    USER_IS_VALID: "User is valid ",
    USER_IS_INVALID: "User is Invalid",
    ALREADY_IN_USED: "Already In Used",
    PDF_FILE_IS_GENERATED_SUCCESSFULLY: "PDF file is generated successfully",
    OLD_PASSWORD_DOES_NOT_MATCH: "Old password does not match",
    NOTIFICATION_HAS_BEEN_SENT_SUCCESSFULLY:
      "Notification has been sent Successfully",
    ERROR_WHILE_SENDING_NOTIFICATION: "Error while sending notification",
    NOTIFICATION_IS_SUCCESSFULLY_DISMISS:
      "Notification is successfully dismiss",
    THERE_WAS_AN_INTERNAL_ERROR_PLEASE_CONTACT_TO_TECHNICAL_SUPPORT:
      "There was an internal error  please contact to technical support",
    // EMAIL_SENT_SUCCESSFULLY: "Email Sent Successfully!",
    TAGS_NOT_FOUND: "Tags Not Found",
    UNABLE_TO_GET_LIST_OF_STORE_DATA: "Unable to get list of store data",
    UNABLE_TO_GET_MISSING_TAG_DATA: "Unable to get missing tag data",
    PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_TAG:
      "Please select store before scanning the tag",
    PLEASE_ENTER_THE_TAG_TO_SCAN: "Please enter the tag to scan",
    TAG_ALREADY_SCANNED: "Tag already scanned",
    PLEASE_SELECT_FACTORY_TAG_NO_FOR_TRANSFERIN_PROCESS:
      "Please select factory tag no for transferin process",
    UNABLE_TO_SCAN_THE_TAG: "Unable to scan the tag",
    TAGS_ARE_TRANSFERRED_IN_SUCCESSFULLY:
      "Tags are transferred in successfully",
    GENERATING_GATE_PASS: "Generating Gate Pass",
    PLEASE_SELECT_STICKERS: "Please Select Stickers",
    UNABLE_TO_GET_STICKERS_TO_TRANSFER_OUT: "No stickers found to transfer out",
    INFO: "Info",
    ENTERED_NUMBER_SHOULD_BE_SMALLER_THEN_NO_OF_PENDING_GARMENTS:
      "Entered number should be smaller then no of pending garments",
    PLEASE_SELECT_ATLEAST_ONE_PENDING_GARMENTS_TO_BE_PACKED_IN:
      "Please select atleast one pending garments to be packed in",
    STICKER_SUCCESSFULLY_GENERATED: "Sticker successfully generated",
    UNABLE_TO_GET_STICKERID: "Unable to get stickerID",
    GARMENT_ALREADY_PACKED:
      "Some of garments from this order is already packed , Please refresh and try again",
    REDIRECTING: "Redirecting",
    SUCCESS: "Success",
    FAIL: "Fail",
    WALLET_BALANCE: "Wallet Balance",
    INVALID: "Invalid",
    SPLITORDERNUMBER_SHOULD_NOT_BE_BLANK:
      "SplitOrderNumber should not be blank",
    VALID: "Valid",
    ADJUSTMENTAMOUNT_SHOULD_BE_BETWEEN_0_AND_10:
      "AdjustmentAmount should be between 0 and 10.",
    SERVICE_RATE_NOT_AVAILABLE: "Service rate not available.",
    ADDON_SERVICE_RATE_NOT_AVAILABLE: "Addon Service rate not available.",
    SCAN_GARMENT: "Scan Garment",
    CLEAR: "Clear",
    SCAN_TAG: "Scan Tag",
    PLEASE_SCAN_TAG: "Please scan tag",
    TAG_ALREADY_EXISTS: "This tag already exists",
    READY_FOR_TRANSFER_OUT: "Ready for Transfer Out",
    SCANNED_GARMENT_LIST: "Scanned Garment List",
    THIS_STICKER_BELONGS_TO_ANOTHER_STORE:
      "This sticker belongs to another store",
    PLEASE_ENTER_VALID_STICKER_NUMBER: "Please enter valid sticker number",
    ALL: "All",
    ESCALATED_DELIVERY: "Escalated Delivery",
    THIS_TAG_ALREADY_EXISTS: "This tag already exists",
    PREVIOUSLY_PACKE_ON: "Previously packed on",
    CASH_COLLECTION: "Cash collection",
    ALREADY_EXISTS: "Already exists",
    USER_PUNCHIN_NOT_FOUND: "User punch in not foud",
    IT_IS_ALREADY_BLOCKED: "It is already blocked",
    AUTHORIZED: "Authorized",
    BLOCKED: "Blocked",
    INVALID_OTP: "Invalid otp",
    PLEASE_CHECK_THE_PROVIDED_BANNERID: "Please check the provided banner id",
    ALREADY_IN_USE: "Already in use",
    ALREADY_EXISTS: "Already exists",
    DATE_OVERLAPPING: "date overlapping",
    REFERRAL_CODE_DOES_NOT_EXISTS: "Referral code does not exists",
    REFERRAL_CODE_IS_ALREADY_USED: "Referral code is already used",
    USER_NAME_IS_INVALID: "User name is invalid",
    PASSWORD_IS_INVALID: "Password is invalid",
    USER_NAME_AND_PASSWORD_DOES_NOT_MATCH:
      "Username and password does not match",
    USER_IS_NOT_EXISTS: "User is not exists",
    SPLIT_ORDER_NUMBER_IS_REQUIRED: "Split order number is required",
    INVALID_SPLIT_ORDER_NUMBER: "Invalid split order number",
    IT_IS_ALREADY_INACTIVATED: "It is already inactivated",
    COUPON_CODE_NOT_EXISTS: "Cash coupon not exists",
    CASH_COUPON_EXPIRED: "Cash coupon expired",
    IT_IS_APPLICABLE_FOR_ONLY_FIRST_TIME_USERS:
      "It is applicable for only first time users",
    YOU_HAVE_ALREADY_USED_THIS_COUPON: "You have already used this coupon",
    INVALID_COUPON: "Invalid coupon",
    SPLITORDERNUMBER_SHOULD_NOT_BE_BLANK:
      "Split order number should bot be blank",
    THIS_PICKUP_IS_ALREADY_IN_PROGRESS: "This pickup is already in process",
    YOU_HAVE_ALREADY_RESCHEDULED_THIS_PICKUP_3_TIMES:
      "You have already rescheduled this pickup 3 times",
    PLEASE_CHECK_WITH_DELIVERYID: "Please cheeck with delivery id",
    PLEASE_ASSIGN_IT_TO_SOMEONE_FIRST: "Please assign it to someone first",
    CANNOT_REASSIGN_DELIVERY_AS_ALREADY_PROCEED_FURTHER:
      "Cannot reassign delivery as already proceed further",
    PLEASE_CHECK_WITH_STATUS: "Please check with status",
    STICKER_ID: "Sticker ID",
    PACKED_On: "Packed On",
    PACKING_IDS: "Packing Ids",
    RESORTING_AND_PACKAGING: "Resorting And Packaging",
    RESORTING_AND_PACKAGING_List: "Resorting And Packaging List",
    PADING_QC_RESPONSE: "Pending Pre-Qc response count",
    LANGUAGE: "Language",
    CHANGE_PASSWORD: "Change Password",
    LOGOUT: "Logout",
    TAG_NUMBER_NOT_FOUND: "Tag number not found",
    TAG_NO: "Tag No",

    //factory pre qc added on 09/11/2020 by PB
    YOU_CAN_ONLY_UPLOAD_JPG_JPEG_PNG_FILE:
      "You can only upload JPG/PNG/JPEG file",
    IMAGE_MUST_BE_SMALLER_THAN_5_MB: "Image must be smaller than 5 MB",
    GARMENT_SHOULD_BE_INFACTORY_TO_BE_ADDED_FOR_QC:
      "Garment should be in factory to be added for QC",
    ORDER_ALREADY_CONFIRMED: "Order already confirmed",
    PENDING_PAYMENT: "Pending Payment",

    IT_SEEMS_LIKE_FEW_OF_THE_SERVICES_ARE_NOT_EXISTS_INTO_SYSTEM:
      "It seems like few of the services are not exists into system. Please check details in service master screen and try again",
    IT_SEEMS_LIKE_FEW_OF_THE_GARMENTS_ARE_NOT_EXISTS_INTO_SYSTEM:
      "It seems like few of the garments are not exists into system. Please check details in garment master screen and try again",
    SERVICE_TAX_DETAIL_IS_MISSING_IN_SECOND_SHEET:
      "Service tax detail is missing in second sheet",
    PENDING_PAYMENT: "Pending Payment",

    PLATFORM: "Platform",
    ORDER_COUNT: "Order Count",
    PROMO_CODE: "Promo Code",
    PICK_BOY: "Pickup Boy",
    CUST_MO_No: "Customer Mobile No",
    SERVICE_ICONS: "Service Icons",
    REQUEST_CREATED_DATE: "Request Created date & time",
    PICKUP_REQUEST_DATE: "Pickup Requested Date & Slot",
    ORDER_CREATED_DATE: "Order Created Date & Time",

    REPRINT_TAX_INVOICE: "Reprint Tax Invoice",
    SEMI_EXPRESS_RATE: "(Semi Express - 1.5x)",
    EXPRESS_RATE: "(Express - 2x)",
    TOTAL_STICKERS: "Total Stickers",
    DELIVERY_BOY: "Delivery Boy",

    //added by PB
    // 25th november;
    // factory post qc
    POST_QC: "Post QC",
    UNABLE_TO_GET_POST_QC_DATA: "Unable to get post qc data",
    GARMENT_PROCEEDED_FURTHER_FOR_POST_PROCESS:
      "Garment proceeded further for post qc process",
    POST_QC_STATUS: "PostQC Status",
    POST_QC_REMARK: "PostQC Remark",
    POST_QC_DATETIME: "Post QC DateTime",
    POSTED_BY: "Posted By",
    ADD_GARMENT_FOR_POST_QC: "Add Garment For Post QC",
    LATEST_POSTQC_DETAILS: "Latest PostQc Details",
    PICKUP_TIMESLOT: "Pickup TimeSlot",

    // 30th november
    //pickuplist
    UNABLE_TO_GET_STORE_NAME: "Unable to get store name",
    CANCEL_REASON_IS_REQUIRED: "The CancelReason field is required",
    //added by PB on 01th dec
    FACTORY_GATE_PASS: "Factory Gate Pass List",
    CUSTOMER_REMARK: "Customer Remark",
    UNABLE_TO_GET_HOLIDAYLIST: "Unable to get HolidayList",

    //02/12/2020
    //factory in screen
    STORE_OUT_DATE: "Store Out Date",

    //pre qc response list
    LAST_UPDATED_ON: "Last Updated On",
    LAST_UPDATED_BY: "Last Updated By",

    //on 08/11/2020
    //factory inventory
    FACTORY_IN_DATETIME: "Factory In DateTime",
    GARMENTS_FACTORY_IN: "Garments Factory In",
    GARMENTS_MISSING: "Garments Missing",
    BALANCED_GARMENTS: "Balanced Garments",
    FACTORY_IN_DATE: "Factory In Date",
    LOT_SIZE: "Lot Size",

    //added on 13/11/2020
    STANDARD_RATE_CARD: "Standard Rate Card",
    ORDER_STATUS: "Order status",
    INVOICE_STATUS: "Invoice Status",
    STORE_STATUS : "Store Status",

    //on 15/12/2020
    //for deliveries list screen
    STOREINDATE: "Store In Date",
    FACTORYOUTDATE: "Factory Out Date",
    PLEASE_SELECT_PAYMENT_MODE: "Please select payment mode",

    //ended by PB

    SELECT_PACKING_MATERIAL: "You can only select packing material up to 99",
    SOMETHING_WENT_WRONG: "Something went wrong",
    UNABLE_TO_GET_PAYMENT_METHOD_DATA: "Unable to get payment method data",

    ARE_YOU_SURE_YOU_WANT_TO_MARK_ORDER_AS_COMPLETED:
      "Are you sure you want to mark order as completed?",

    ORDER_MARKED_AS_COMPLETED: "Order marked as completed",
    UNABLE_TO_MARK_ORDER_AS_COMPLETED: "Unable to mark order as completed",
    EDC_CONFIGURATION: "EDC device is not linked with this store",

    ATTENDANCE: "Attendance",
    ATTENDANCE_REPORT: "Attendance Report",
    REPORTS: "Reports",
    OTHER_TYPE: "Other Type",
    PLEASE_ENTER_OTHER_TYPE: "Please Enter Other Type",
    ENTER_OTHER_TYPE: "Enter Other Type",

    CANCEL_REMARK: "Cancel Remark",
    REGISTERED_ON: "Registered On",
    CASH: "Cash",
    LKBONUS: "LKBonus",
    LKWALLET: "LKWallet",
    PAYTM: "Paytm",
    BAG_COUNT: "Bag Count",
    PARTIAL_ORDER_RECEIVED: "Partial Order Received",
    SPILT_ORDER_ID: "SplitOrderID",
    STORE_OUT_GATE_PASS: "StoreOut GatePassNo",
    STORE_OUT_GATEPASS_TIME: "StoreOut GatePassTime",
    FACTORY_IN_DATE: "FactoryInDate",
    RECENT_STORE_IN_DATES: "Recent StoreIn Dates",
    ORDER_LOT_SIZE: "OrderLotSize",
    STORE_IN_GARMENTS: "StoreIn Garments",
    STORE_IN_STICKER_ID: "StoreIn StickerID",
    BALANCE_GARMENTS: "Balance Garments",
    UNABLE_TO_GET_LIST_OF_PARTIAL_ORDER_DATE:
      "Unable to get list of partial order received data",

    PAYTM_EDC_MACHINE_CONFING: "Paytm EDC Machine Configuration",

    //Added By Hasti

    //Rewards
    CASHBACK_IN_PERCENTAGE: "CashBack In %",
    VALIDITY_IN_DAYS: "Validity(In Days)",
    EFFECTIVE_FROM: "Effective From",
    PREVIOUS_REWARDS_CAN_NOT_BE_UPDATED: "Previous Rewards can't be updated !",
    UNABLE_TO_GET_LIST_OF_REWARDS_DATA: "Unable to get list of Rewards data",
    ADD_REWARDS_CONFIG: "Add Rewards Config",
    VIEW_REWARDS_CONFIG: "View Rewards Config",
    EDIT_REWARDS_CONFIG: "Edit Rewards Config",

    //Holiday Calendar
    HOLIDAY_CONFIGURATION: "Holiday Configuration",
    SETTLE_WITHOUT_PAYMENT: "Settle Without Payment",
    INVOICE_SETTLED_WITH_OUT_PAYMEN_SUCCESSFULLY:
      "Invoice Settled WithOut Payment Successfully",
    UNABLE_TO_SETTLE_INVOICE_WITH_OUT_PAYMENT:
      "Unable to Settle Invoice Without Payment",
    CREATED_ON: "Created On",

    //Order History
    ORDER_HISTORY: "Order History",

    //Custom Bulk Notification Upload.
    CUSTOM_BULK_NOTIFICATION_UPLOAD: "Custom Bulk Notification Upload",
    DATE_TIME_FOR_EXECUTION: "Date & Time Of Execution",
    ADD_CUSTOM_BULK_NOTIFICATION: "Add Custom Bulk Notification",
    VIEW_CUSTOM_BULK_NOTIFICATION: "View Custom Bulk Notification",
    EDIT_CUSTOM_BULK_NOTIFICATION: "Edit Custom Bulk Notification",
    UPLOAD_CUSTOM_BULK_NOTIFICATION_CONFIGURATION_FILE:
      "Upload Custom Bulk Notification Configuration File",
    UNABLE_TO_GET_LIST_OF_CUSTOM_BULK_NOTIFICATION_DATA:
      "Unable to get list of custom bulk Notification data",

    //factory Inventory
    FACTORY_OUT: "Factory Out",
    PACKED: "Packed",
    PACKING_DUE: "Packing Due",
    STORECODE: "StoreCode",

    //Factory Dashboadr
    FACTORY_INVENTORY: "Factory Inventory",
    FACTORY_GARMENT_INVENTORY: "Factory Garment Inventory",
    INVENTORY_SUMMARY: "Inventory Summary",
    OVERDUE_GARMENTS: "Overdue Garments",
    PACKED_GATEPASS_NOT_GENERATED: "Packed Gate pass not generated",
    TODAYS_DUE_GARMENT_ORDER: "Todays Due Garments/Orders",
    PREVIOUS_DUE: "Previous Due",
    MISSING_DAMAGE: "Missing/Damage",
    //Store In Pending  on 18 Nov 2021
    STORE_IN_PENDING: "Store In Pending",
    UNABLE_TO_GET_LIST_OF_STORE_IN_PENDING:
      "Unable to get list of store In Pending",
    PACKAGING_ID: "Packaging ID",
    GATEPASSNUMBER: "Gate Pass No.",
    STORE_CODE: "Store Short Code",
    PACKED_ON_DATE: "Packed On Date & Time ",
    FACTORY_OUT_DATE: "Factory Out Date & Time",
    DELAY_DAYS: "Delay Days",

    //Factory In Pending On 18 Nov 2021
    FACTORY_IN_PENDING: "Factory In Pending",
    STORE_OUT_DATE: "Store Out Date & Time",
    TAG_NO_PENDING: "Tag Nos(Pending)",
    FACTORY_IN_GARMENTS: "Factory In Garments",
    PENDING_FACTORY_IN: "Pending Factory In",
    UNABLE_TO_GET_LIST_OF_FACTORY_IN_PENDING:
      "Unable to get list of factory In Pending",

    //Processing
    PROCESSING: "Processing",
    GARMENT_PROCESSING: "Garment Processing",
    PROCESSING_LIST: "Processing List",
    SCAN_TAG: "Scan Tag",
    ENTER_TAG: "Enter/Scan Tag",
    UNABLE_TO_GET_TAG_DATA: "Unable To get Tag Number Data.",
    NO_OF_GARMENT_ALLREADY_PROCEED: "No of garment already proceed",
    LAST_PROCEED_DATETIME: "Last proceed datetime",
    PLEASE_ENTER_GARMENT: "Please Enter Garment",
    UNABLE_TO_GET_LIST_OF_PROCESSING_DATA:
      "Unable to get list of Processing Data.",
    FACTORY_IN_DATETIME: "Factory In Date & Time",
    PROCESSING_IN_DATE_TIME: "Processing In Date & Time",
    NO_OF_ITEMS_PENDING_FOR_PROCESSING: "No.of Items Pending for Processing",
    LOT_SIZE_FACTORY_IN: "Lot Size/Factory In",
    ROLL: "Roll",
    WASH_TYPE: "Wash Type",
    REWASH_REASON: " Rewash Reason",

    //Terms And Condition
    TERMS_AND_CONDITIONS: "Terms And Conditions",

    //Delivery Dashboard
    DELIVERY_DASHBORAD: "Delivery",

    //Rewars
    PLATFORM: "PlatForm",
    SELECT_PLATFORM: "select PlatForm",

    //Faq
    FAQ: "Faq",
    UPDATE_INVOICE_REMARK: "Update Invoice Remark",

    INVOICE_REMARK_UPDATED_SUCCESSFULLY: "Invoice Remark Updated Successfully",
    UNABLE_TO_UPDATE_INVOICE_REMARK: "Unable To Update Invoice Remark",

    //Audit
    AUDIT: "Audit",
    PENDING_CASH_SUMMARY: "Pending Cash Summary",

    CS_ACTION: "CS Action",
    ARE_YOU_SURE_YOU_WANT_ESCALATE_DELIVERY:
      "Are you sure you want Escalate Delivery?",
    UNABLE_TO_ESCALATE_DELIVERY: "Unable to Escalate Delivery",
    ESCALATED_SUCCESSFULLY: "Escalated Successfully",

    //Orders Summary

    ORDERS_SUMMARY: "Orders Summary",

    //Factory Inventory Summary
    SERVICE_TYPE: "Service Type",
    ORDER_COUNT: "Orders Count",
    NO_OF_GARMENT_DUE_FOR_PACKAGING: "No. of Garments Due for Packaging ",

    // PickUP screen
    CANCEL_REQUESTED_BY: "Cancel Requested By",
    CANCEL_REQUESTED_REASON: "Cancel Requested Reason",
    CANCELLED_REASON: "Cancelled Reason",
    CANCELLED_BY: "Cancelled By",
    ORIGINAL_PICKUPID: "Original PickupID",
    PLEASE_ENTER_ORIGINAL_PICKUP: "Please Enter Original Pickup",

    OUT_OF_COVERAGE: "Out Of Coverage",
    PICKUP_DELIVERY_ASSIGNMENT: "Pickup Delivery Assignment",
    GENERATE_OTP: "Generate OTP",
    ALT_NUMBER_ADDED_SUCCESSFULLY: "Alternative Number Added Successfully",
    UNABLE_TO_ADD_ALT_NUMBER: "Unable to Add Alternative Number",
    OTP_SENT_SUCCESSFULLY: "OTP sent Successfully",
    UNABLE_TO_SENT_OTP: "Unable to sent OTP",

    ALTER_PHONE_NUMBER: "Alternative Phone Number",
    ALT_NUMBER_DELETED_SUCCESSFULLY: "Alternative Number Deleted Successfully",
    UNABLE_TO_DELETE_ALT_NUMBER: "Unable to Deleted Alternative Number",
    VIEW_ON_MAP: "View On Map",
    DAILY_ORDER_SUMMARY: "Daily Order Summary",
    MONTHLY_ORDER_SUMMARY: "Monthly Order Summary",
    DAILY_PICKUP_SUMMARY: "Daily Pickup Summary",
    MONTHLY_PICKUP_SUMMARY: "Monthly Pickup Summary",

    STOREID_UPDATED_SUCCESSFULLY: "StoreID Updated Successfully",
    UNABLE_TO_UPDATE_STOREID: "Unable To Update StoreID",
    REASSIGN_STORE: "Reassign Store",
    PLEASE_SELECT_STORE: "Please Select Store",
    APPSFLYER: "AppsFlyer",

    ORDER_ALREADY_SCANNED: "Order already scanned",
    ORDER_NOT_FOUND: "Order Not Found",
    PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_ORDER:
      "Please select store before scanning the order",

    DAILY_SHEETS: "Daily Sheets",
    EXPRESS_URGENT: "Express/Urgent",
    REWASH: "Rewash",
    FIVE_STARE: "5 Star",

    UNABLE_TO_GET_EXPRESS_URGENT_DATA:
    "Unable to get Express/Semi-Express/Urgent Data",
    UNABLE_TO_GET_REWASH_DATA:
    "Unable to get Rewash Data",
    UNABLE_TO_GET_FIVESTAR_DATA:
    "Unable to get Five Star Data",

    GARMENT_DEFECT:"Garment Defect",
    STORE_STATUS : "Store Status",
  },
  HINDI: {
    SELECT_SERVICE: "सेवा का चयन करें",
    SELECT_FACTORY: "फैक्टरी का चयन करें",
    SPLIT_ORDER: "स्प्लिट ऑर्डर",
    CUSTOMER_NAME: "ग्राहक का नाम",
    ORDER_DATE: "आदेश की तारीख",
    TOTAL_GARMENTS: "कुल वस्त्र",
    SPLIT_ORDER_NUMBER: "स्प्लिट ऑर्डर नंबर",
    TAG_NUMBER: "संख्या मार्क करना",
    GARMENT_NAME: "परिधान का नाम",
    ADD_ON_SERVICES: "सेवाओं पर जोड़ें",
    GENERATE_GATE_PASS: "गेट पास उत्पन्न करें",
    GATE_PASS_NUMBER: "गेट पास नंबर",
    STORE_NAME: "स्टोर नाम",
    FACTORY_NAME: "फैक्टरी का नाम",
    STORE_GARMENTS_OUT: "स्टोर गारमेंट्स आउट",
    TOTAL_SPLIT_NUMBER: "कुल विभाजन संख्या",
    GATE_PASS_DATE: "गेट पास की तारीख",
    STIKER_NUMBER: "स्टीकर नंबर",
    PLEASESCAN_ADD_STICKER: "कृपया स्कैन करें / स्टिकर जोड़ें",
    ENTER_SCAN_STICKER: "स्टिकर दर्ज करें / स्कैन करें",
    CUSTOMER_CONTACT: "ग्राहक से संपर्क",
    DELIVERY_TYPE: "वितरण के प्रकार",
    SPLIT_ORDER_NO: "स्प्लिट ऑर्डर नं",
    GATE_PASS_NO: "गेट पास नं",
    DELIVERY_DATE: "डिलीवरी की तारीख",
    GARMENTS_IN_PROCESS: "प्रक्रिया में वस्त्र",
    PACKED_GARMENTS: "पैक किए गए वस्त्र",
    PACKING_MATERIAL: "पैकिंग सामग्री",
    STICKER_STATUS: "स्टिकर की स्थिति",
    SPLIT_ORDER_STATUS: "स्प्लिट ऑर्डर की स्थिति",
    GARMENTS_PACKED_INFORMATION: "गारमेंट्स पैक की गई जानकारी",
    USER_EXPENSE_ENTRY: "उपयोगकर्ता व्यय प्रविष्टि",
    USER_NAME: "उपयोगकर्ता नाम",
    EXPENSE_DATE: "व्यय तिथि",
    AMOUNT: "रकम",
    REMARKS: "टिप्पणियों",
    ARE_YOU_SURE: "क्या आपको यकीन है ?",
    ADD_EXPENSE: "व्यय जोड़ें",
    APPLY: "लागू",
    SELECT_EXPENSE_TYPE: "व्यय प्रकार का चयन करें",
    PLEASE_ENTER_AMOUNT: "कृपया राशि दर्ज करें",
    ENTER_AMOUNT: "राशी डालें",
    ENTER_REMARKS: "टिप्पणी दर्ज करें",
    GARMENTS_PENDING_TO_SEND_TO_FACTORY:
      "कारखाने में भेजने के लिए लंबित वस्त्र",
    ORDERS_PENDING_TO_SEND_TO_FACTORY: "फैक्टरी भेजने के लिए लंबित आदेश",
    SPILT_ORDERS_RECEIVED_FROM_FACTORY: "फैक्ट्री से प्राप्त आदेश",
    PENDING_FOR_NO_OF_DAYS: "नो दिनों के लिए लंबित",
    STORE_GARMENT_INVENTORY: "स्टोर परिधान सूची",
    GARMENTS_RECEIVED_FROM_FACTORY: "कारखाने से प्राप्त वस्त्र",
    FACTORY_TRANSFER_IN: "फैक्टरी ट्रांसफर-इन",
    SELECT_STORE: "स्टोर का चयन करें",
    GET_MISSING_GARMENTS: "लापता गारमेंट्स प्राप्त करें",
    TAGS_FROM_STORES: "स्टोर से टैग",
    SCANNING: "स्कैनिंग",
    TAGS_TO_THE_FACTORY: "कारखाने के लिए टैग",
    MISSING_GARMENTS: "लापता वस्त्र",
    SCAN_COMPLETE: "स्कैन पूरा करें",
    SEARCH_HERE: "यहाँ ढूँढे",
    RESORTING_AND_PACKAGING: "रिज़ॉर्टिंग और पैकेजिंग",
    ORDER_NUMBER: "क्रम संख्या",
    SRORE_NAME: "श्रीहर नाम",
    NO_OF_GARMENTS: "गारमेंट्स की नहीं",
    GARMENT_LEVEL_INFO: "परिधान स्तर की जानकारी",
    SELECTED_PENDING_GARMENT: "चयनित परिधान",
    PREVIOUSLY_GARMENTS_PACKED_IN: "इससे पहले परिधानों में पैक किया जाता है",
    PENDING_GARMENTS_TO_BE_PACKED_IN: "लंबित परिधानों को पैक किया जाना है",
    GENERATE_STICKER: "स्टिकर उत्पन्न करें",
    PRINT_GENERATE_STICKER: "स्टिकर जनरेट करें",
    SR_NO: "अनु क्रमांक",
    GARMENTS_PACKED: "गारमेंट्स पैक किया",
    PENDING_GARMENTS: "लंबित वस्त्र",
    PACKING_GARMENTS: "पैकिंग वस्त्र",
    FACTORY_TRANSFER_OUT: "फैक्ट्री ट्रांसफर आउट",
    FACTORY_GATE_PASS_NUMBER: "फैक्टरी गेट पास संख्या:",
    STORE_ADDRESS: "स्टोर का पता",
    CREATED_BY: "के द्वारा बनाई गई",
    S_NO: "एस। नहीं",
    SPLIT_ORDER_ID: "स्प्लिट ऑर्डर आईडी",
    NUMBER_OF_GARMENTS: "गारमेंट्स की संख्या",
    PACKED: "पैक्ड",
    ORDER_LOT_SIZE: "ऑर्डर लॉट साइज",
    PREVIOUSLY_SENT: "पहले भेजा गया",
    SENDING_TODAY: "आज भेजा जा रहा है",
    PENDING: "विचाराधीन",
    BAG: "बैग",
    HANGER: "कांटा",
    BLANKET: "कंबल",
    SHOE: "जूता",
    CARPET: "गलीचा",
    BLAZER: "रंगीन जाकेट",
    OTHERS: "अन्य",
    STICKER_NO: "स्टीकर नं",
    SPLLIT_ORDER_NO: "स्प्लिट्ल ऑर्डर नं",
    PACKING_MATERIAL_NAME: "पैकिंग सामग्री का नाम",
    PRE_QC_RESPONSE_LIST: "प्री-क्यूसी रिस्पांस लिस्ट",
    SELECT_QC_STATUS: "क्यूसी स्थिति का चयन करें",
    TAG: "टैग",
    CONTACT: "संपर्क करें",
    QC_REQUEST: "Qc अनुरोध",
    TIME: "समय",
    QC_REASON: "क्यूसी कारण",
    REMARK: "टिप्पणी",
    QC_PENDING_FROM: "क्यूसी लंबित",
    QC_STATUS: "क्यूसी स्थिति",
    ENTER_SCAN_TAG_NUMBER: "टैग संख्या दर्ज / स्कैन करें",
    GARMENT_HISTORY: "परिधान का इतिहास",
    GARMENT_INFORMATION: "परिधान जानकारी",
    GARMENT_STATUS: "परिधान की स्थिति",
    GATE_PASS: "गेट पास",
    BRAND: "ब्रांड",
    COLOR: "रंग",
    ADD_ON_SERVICE: "सेवा पर जोड़ें",
    SPECIAL_INSTRUCTION: "विशेष निर्देश",
    LATEST_QC_DETAILS: "नवीनतम QC विवरण",
    IMAGES: "इमेजिस",
    REASON: "कारण",
    SELECT_REASON: "कारण चुनें",
    COMMUNICATION_HISTORY: "संचार इतिहास",
    SELECT_SCAN_GARMENT: "परिधान का चयन / स्कैन करें",
    GARMENT_FOR_QC: "क्यूसी के लिए परिधान",
    SCANNED_ITEM: "स्कैन की गई वस्तु",
    ADDON_SERVICE: "AddOn सेवा",
    CONTACT_NO: "संपर्क नंबर",
    STICKER_INFORMATION: "स्टीकर की जानकारी",
    DOWNLOAD_GATE_PASS: "गेट पास डाउनलोड करें",
    STORE_GATE_PASS_NUMBER: "स्टोर गेट पास नंबर",
    FACTORY_ADDRESS: "फैक्टरी का पता",
    PICKUP_DATE: "तारीख ले लो",
    REFFERAL_PROGRAM_NAME: "रेफ़रल प्रोग्राम का नाम",
    REFFERAL_BONUS_AMOUNT: "रेफ़रल बोनस राशि",
    REFEREE_BONUS_AMOUNT: "रेफरी बोनस राशि",
    YOU_CAN_ONLY_UPDATE_THE_LAST_ADDED_PROGRAM:
      "आप केवल अंतिम जोड़े गए प्रोग्राम को अपडेट कर सकते हैं",
    UNABLE_TO_GET_LIST_OF_REFFERAL_BONUS_CONFIGURATION_DATA:
      "रेफ़रल बोनस कॉन्फ़िगरेशन डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_SUBMIT_THE_DATA: "डेटा जमा करने में असमर्थ",
    ADD_REFFERAL_BONUS_CONFIGURATION: "रेफ़रल बोनस कॉन्फ़िगरेशन जोड़ें",
    VIEW_REFFERAL_BONUS_CONFIGURATION: "रेफ़रल बोनस कॉन्फ़िगरेशन देखें",
    EDIT_REFFERAL_BONUS_CONFIGURATION: "रेफ़रल बोनस कॉन्फ़िगरेशन संपादित करें",
    PLEASE_ENTER_REFFERAL_PROGRAM_NAME: "कृपया Refferal Program Name दर्ज करें",
    ENTER_REFFERAL_PROGRAM_NAME: "रेफ़रल प्रोग्राम नाम दर्ज करें",
    PLEASE_ENTER_REFERRAL_BONUS_AMOUNT: "कृपया रीफ़रल बोनस राशि दर्ज करें",
    ENTER_REFERRAL_BONUS_AMOUNT: "रेफ़रल बोनस राशि दर्ज करें",
    PLEASE_ENTER_REFEREE_BONUS_AMOUNT: "कृपया रेफरी बोनस राशि दर्ज करें",
    ENTER_REFEREE_BONUS_AMOUNT: "रेफरी बोनस राशि दर्ज करें",
    ENTER_BONUS_AMOUNT_VALIDITY_DAYS: "बोनस राशि की वैधता (दिन) दर्ज करें",
    ARE_YOU_SURE_YOU_WANT_TO_CREATE_THIS_PROGRAM_AS_PREVIOUS_PROGRAMS_WILL_GET_DEACTIVATED:
      "क्या आप वाकई इस प्रोग्राम को बनाना चाहते हैं क्योंकि पिछले प्रोग्राम निष्क्रिय हो जाएंगे",
    FILE_NAME: "फ़ाइल का नाम",
    TOTAL_RECORDS: "कुल रिकॉर्ड",
    SUCCEEDED: "सफल हुए",
    DOWNLOAD: "डाउनलोड",
    FAILED: "अनुत्तीर्ण होना",
    UNABLE_TO_GET_LIST_OF_EMPLOYEE_CREDIT_DATA:
      "कर्मचारी क्रेडिट डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_UPLOAD_THE_FILE: "फ़ाइल अपलोड करने में असमर्थ",
    DOWNLOAD_FORMAT: "प्रारूप डाउनलोड करें",
    UPLOAD_FILE: "दस्तावेज अपलोड करें",
    ADD_EMPLOYEE_CREDITS: "कर्मचारी क्रेडिट जोड़ें",
    VIEW_EMPLOYEE_CREDITS: "कर्मचारी क्रेडिट देखें",
    EDIT_EMPLOYEE_CREDITS: "कर्मचारी क्रेडिट संपादित करें",
    UPLOAD_EMPLOYEE_CREDIT_CONFIGURATION_FILE:
      "कर्मचारी क्रेडिट कॉन्फ़िगरेशन फ़ाइल अपलोड करें",
    PLEASE_SELECT_FILE: "कृपया फ़ाइल का चयन करें",
    CLICK_TO_UPLOAD: "अपलोड करने के लिए क्लिक करें",
    UPLOAD: "डालना",
    UNABLE_TO_LOAD_PACKAGES: "पैकेज लोड करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_CUSTOMERS: "ग्राहकों की सूची प्राप्त करने में असमर्थ",
    TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED:
      "लेनदेन सफलतापूर्वक संसाधित किया गया है",
    UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN:
      "लेनदेन करने में असमर्थ फिर से प्रयास करें",
    UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM:
      "प्रोसेस करने में अक्षम। कृपया तकनीकी टीम से संपर्क करें",
    PACKAGE_PURCHASED_SUCCESSFULLY: "पैकेज सफलतापूर्वक खरीदा गया",
    UNABLE_TO_BUY_PACKAGE: "पैकेज खरीदने में असमर्थ",
    BUY: "खरीदें",
    PLEASE_SELECT_CUSTOMER: "कृपया ग्राहक चुनें",
    SEARCH_CUSTOMER: "ग्राहक खोजें",
    MONEY_TYPE: "पैसा प्रकार",
    ADDED_BY: "द्वारा जोड़ा",
    ADDED_ON: "पर जोड़ा",
    UNABLE_TO_GET_LIST_OF_ADVANCE_PAYMENT_DATA:
      "अग्रिम भुगतान डेटा की सूची प्राप्त करने में असमर्थ",
    ADVANCE_COLLECTED_SUCCESSFULLY: "अग्रिम एकत्रित सफलतापूर्वक",
    UNABLE_TO_ADD_AMOUNT_TO_LKWALLET: "LKWallet में राशि जोड़ने में असमर्थ",
    ADD_ADVANCE_PAYMENT: "एडवांस भुगतान जोड़ें",
    VALID_FROM: "से मान्य",
    VALID_TO: "इस तक मान्य",
    UNABLE_TO_GET_LIST_OF_COMPENSATION_CONFIGURATION_DATA:
      "मुआवजा कॉन्फ़िगरेशन डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_ADD_THE_DATA: "डेटा जोड़ने में असमर्थ",
    COMPENSATION_CONFIGURATION: "मुआवजा विन्यास",
    ADD_COMPENSATION_CONFIGURATION: "मुआवजा कॉन्फ़िगरेशन जोड़ें",
    VIEW_COMPENSATION_CONFIGURATION: "मुआवजा विन्यास देखें",
    EDIT_COMPENSATION_CONFIGURATION: "मुआवजा कॉन्फ़िगरेशन संपादित करें",
    VALID_FROM_AND_TO_DATE: "से मान्य और आज तक",
    PLEASE_SELECT_VALID_FROM_AND_TO_DATE: "कृपया तिथि से मान्य चुनें",
    PLEASE_ENTER_REMARK: "कृपया टिप्पणी दर्ज करें",
    ENTER_REMARK: "टिप्पणी दर्ज करें",
    UNABLE_TO_GET_LIST_OF_REFUND_DATA:
      "धनवापसी डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_ADD_REFUND_DATA: "धनवापसी डेटा जोड़ने में असमर्थ",
    ADD_REFUND: "रिफंड जोड़ें",
    VIEW_REFUND: "रिफंड देखें",
    DATA_SUCCESSFULLY_SUBMITTED: "डेटा सफलतापूर्वक सबमिट किया गया",
    UNABLE_TO_SUBMIT_TAG_DATA: "टैग डेटा सबमिट करने में असमर्थ",
    UNABLE_TO_LOAD_TAG_DATA: "टैग डेटा लोड करने में असमर्थ",
    UNABLE_TO_GET_TAG_DATA: "टैग डेटा प्राप्त करने में असमर्थ",
    PLEASE_ENTER_TAG_NO: "कृपया टैग नं दर्ज करें",
    FACTORY_POST_QC: "फैक्टरी पोस्ट-क्यूसी",
    PLEASE_SCAN_ADD_TAG_NUMBER: "कृपया टैग नंबर को स्कैन / जोड़ें",
    SELECT_STATUS: "स्थिति का चयन करें",
    PLEASE_ENTER_REMARKS: "कृपया टिप्पणी दर्ज करें",
    UNABLE_TO_LOAD_PRE_QC_RESPONSES:
      "प्री-क्यूसी प्रतिक्रियाओं को लोड करने में असमर्थ",
    FACTORY_PRE_QC: "फैक्टरी प्री-क्यूसी",
    ADD_GARMENT_FOR_QC: "QC के लिए परिधान जोड़ें",
    DATA_IS_SUCCESSFULLY_SAVED: "डेटा सफलतापूर्वक सहेजा गया है",
    GARMENT_SHOULD_BE_IN_FACTORY_TO_BE_ADDED_FOR_QC:
      "परिधान कारखाने में होना चाहिए क्यूसी के लिए जोड़ा जा सकता है",
    UNABLE_TO_GET_GARMENT_DETAILS: "परिधान विवरण प्राप्त करने में असमर्थ",
    GET_GARMENT_HISTORY: "परिधान इतिहास प्राप्त करें",
    PLEASE_SELECT_REASON: "कृपया कारण चुनें",
    UNABLE_TO_GET_CORPORATE_DATA: "कॉर्पोरेट डेटा प्राप्त करने में असमर्थ",
    GARMENTS_AT_QC: "क्यूसी में वस्त्र",
    GARMENTS_IN_POST_QC: "डाक QC में गारमेंट्स",
    UNABLE_TO_GET_FACTORY_GARMENTS_INVENTORY_DATA:
      "फैक्ट्री गारमेंट्स इन्वेंटरी डेटा प्राप्त करने में असमर्थ",
    GARMENT_INVENTORY_AT_FACTORY: "फैक्ट्री में गारमेंट इन्वेंटरी",
    DASHBOARD: "डैशबोर्ड",
    CUSTOMERS: "ग्राहकों",
    MANAGE_CUSTOMERS: "ग्राहकों को प्रबंधित करें",
    PACKAGE_CONFIGURATION: "पैकेज कॉन्फ़िगरेशन",
    REFERRAL_BONUS_CONFIGURATION: "रेफ़रल बोनस कॉन्फ़िगरेशन",
    EMPLOYEE_CREDITS: "कर्मचारी क्रेडिट",
    BUY_PACKAGE: "पैकेज खरीदें",
    ADVANCE_PAYMENTS: "अग्रिम भुगतान",
    COMPENSATION: "नुकसान भरपाई",
    REFUND: "धन की वापसी",
    MASTER: "गुरुजी",
    STORE: "दुकान",
    FACTORY: "फ़ैक्टरी",
    CORPORATE: "कॉर्पोरेट",
    CITY: "Faridabad",
    STATE: "राज्य",
    DESIGNATION: "पद",
    GARMENT_BRAND: "परिधान ब्रांड",
    GARMENT_SIZE: "परिधान का आकार",
    KYC_DOCUMENT_TYPE: "केवाईसी दस्तावेज़ प्रकार",
    SERVICE: "सेवा",
    GARMENT: "परिधान",
    REWASH_REASON: "रीवाश कारण",
    OPERATION_INSTRUCTION: "ऑपरेशन निर्देश",
    EXPENSE_TYPE: "व्यय प्रकार",
    ORDERS: "आदेश",
    PICKUPS: "पिकप",
    MANAGE_ORDERS: "आदेश प्रबंधित करें",
    READY_FOR_DELIVERY_OR_INVOICE: "डिलीवरी / चालान के लिए तैयार",
    DELIVERIES: "वितरण",
    TRANSFER_IN_OUT: "में / बाहर स्थानांतरण",
    TRANSFER_OUT: "बाहर स्थानांतरण",
    GATE_PASS_LIST: "गेट पास सूची",
    TRANSFER_IN: "में स्थानांतरण",
    PRE_QC_RESPONSES: "प्री-क्यूसी प्रतिक्रिया",
    USER_EXPENSES: "उपयोगकर्ता व्यय",
    INVENTORY: "इन्वेंटरी",
    SETTINGS: "समायोजन",
    AUTHENTICATION: "प्रमाणीकरण",
    USERS: "उपयोगकर्ता",
    ROLES: "भूमिकाएँ",
    ROLE_OPERATIONS: "भूमिका संचालन",
    CONFIGURATION: "विन्यास",
    TIME_CARD: "टाइम कार्ड",
    RATE_CARD: "दर कार्ड",
    DISCOUNT: "छूट",
    CASH_COUPON: "कैश कूपन",
    PACKAGING_CHARGES: "पैकेजिंग शुल्क",
    DELIVERY_CHARGES: "पहुंचाने का शुल्क",
    BANNER: "बैनर",
    NOTICE_BOARD: "सूचना पट्ट",
    FINGER_SCAN: "अंगुलि स्कैन",
    COLLECT_FINGERPRINTS: "उंगलियों के निशान ले लीजिए",
    QC_RESPONSES: "QC प्रतिक्रियाएँ",
    PRE_QC: "प्री क्यूसी",
    POST_QC: "क्यूसी पोस्ट करें",
    TODAYS_HIGHLIGHTS: "आज की मुख्य बातें",
    ORDER_VS_ORDER_AMOUNT: "आदेश / आदेश राशि",
    INVOICE_VS_INVOICE_AMOUNT: "चालान / चालान राशि",
    STORE_INVENTORY: "स्टोर इन्वेंटरी",
    OTHER_STATASTICS: "अन्य आँकड़े",
    PROCESSED: "प्रसंस्कृत",
    SOILED: "गंदे",
    AMOUNT_DUE_FOR_COLLECTION: "संग्रह के लिए देय राशि",
    ORDERS_READY_FOR_INVOICE: "चालान के लिए तैयार आदेश",
    DELIVERY_PERSON: "डिलिवरी करने वाला व्यक्ति",
    WALK_IN: "बिना नियोजित भेंट के चला आने वाला",
    PENDING_TO_TRANSFER_OUT_TO_FACTORY:
      "फ़ैक्टरी से बाहर स्थानांतरित करने के लिए लंबित",
    NO_OF_ORDERS: "आदेशों की संख्या",
    PENDING_PICKUPS: "लंबित पिकअप",
    DATE: "तारीख",
    ORDERS_DUE_FOR_DELIVERY: "प्रसव के कारण आदेश",
    FIRST_NAME: "पहला नाम",
    LAST_NAME: "उपनाम",
    GENDER: "लिंग",
    EMAIL: "ईमेल",
    PHONE: "फ़ोन",
    TYPE: "प्रकार",
    BILLING_CYCLE: "साइकिल चलाना",
    ACTIONS: "क्रिया",
    VIEW: "राय",
    EDIT: "संपादित करें",
    DELETE: "हटाएं",
    SEARCH: "खोज",
    ADD_CUSTOMER: "ग्राहक जोड़ें",
    ADD: "जोड़ना",
    CUSTOMER: "ग्राहक",
    WALLET_CASH: "बटुआ नकद",
    WALLET_BONUS: "वॉलेट बोनस",
    GENERAL: "सामान्य",
    GENERAL_CUSTOMER_INFORMATION: "सामान्य ग्राहक जानकारी",
    ADDRESS: "पता",
    CUSTOMER_ADDRESSES: "ग्राहक के पते",
    VERIFICATION: "सत्यापन",
    VERIFY_PHONE_AND_EMAIL: "Verfiy फोन और ईमेल",
    PLEASE: "कृप्या",
    SELECT: "चुनते हैं",
    ENTER: "दर्ज",
    VALID: "मान्य",
    PHONE_NUMBER: "फ़ोन नंबर",
    DATE_OF_BIRTH: "जन्म की तारीख",
    ANNIVERSARY_DATE: "सालगिरह की तिथि",
    REFERRAL_CODE: "रेफरल कोड",
    CUSTOMER_TYPE: "ग्राहक प्रकार",
    GSTIN: "GSTIN",
    ACTIVE: "सक्रिय",
    STATUS: "स्थिति",
    YES: "हाँ",
    NO: "नहीं",
    CANCEL: "रद्द करना",
    SUBMIT: "प्रस्तुत",
    ID: "आईडी",
    PINCODE: "पिन कोड",
    ADRESSES_OF: "के पते",
    HOME: "घर",
    OFFICE: "कार्यालय",
    OTHER: "अन्य",
    LONGITUDE: "देशान्तर",
    LATITUDE: "अक्षांश",
    IS_DEFAULT: "इसे डिफ़ॉल्ट के रूप में सेट करें",
    PLEASE_ENTER_FIRST_NAME: "कृपया पहला नाम दर्ज करें",
    ENTER_FIRST_NAME: "पहला नाम दर्ज करें",
    PLEASE_ENTER_LAST_NAME: "कृपया अंतिम नाम दर्ज करें",
    ENTER_LAST_NAME: "अंतिम नाम दर्ज करो",
    PLEASE_ENTER_EMAIL: "कृपया ईमेल दर्ज करें",
    PLEASE_ENTER_VALID_EMAIL: "कृपया वैध ईमेल दर्ज़ करें",
    ENTER_EMAIL: "ईमेल दर्ज करें",
    PLEASE_ENTER_PHONE_NUMBER: "कृपया फ़ोन नंबर दर्ज करें",
    PLEASE_ENTER_VALID_PHONE_NUMBER: "कृपया एक वैध नंबर डालें",
    ENTER_PHONE_NUMBER: "फोन नंबर दर्ज",
    PLEASE_SELECT_GENDER: "कृपया लिंग चुनें",
    SELECT_DATE: "तारीख़ चुनें",
    ENTER_REFERRAL_CODE: "रेफ़रल कोड दर्ज करें",
    PLEASE_SELECT_CUSTOMER_TYPE: "कृपया ग्राहक प्रकार चुनें",
    SELECT_CUSTOMER_TYPE: "ग्राहक प्रकार का चयन करें",
    SELECT_CORPORATE: "कॉर्पोरेट का चयन करें",
    PLEASE_SELECT_BILLING_CYCLE: "कृपया बिलिंग चक्र का चयन करें",
    SELECT_BILLING_CYCLE: "बिलिंग साइकिल का चयन करें",
    ENTER_VALID_GSTIN: "मान्य GSTIN दर्ज करें",
    ENTER_GSTIN: "GSTIN दर्ज करें",
    PLEASE_SELECT_STATUS: "कृपया स्थिति चुनें",
    ADD_ADDRESS: "पता जोड़ें",
    VIEW_ADDRESS: "पता देखें",
    EDIT_ADDRESS: "पता संपाद��त करें",
    PLEASE_SELECT_ADDRESS_TYPE: "कृपया पता प्रकार चुनें",
    SELECT_TYPE: "प्रकार चुनें",
    ENTER_LONGITUDE: "देशांतर दर्ज करें",
    ENTER_LATITUDE: "अक्षांश दर्ज करें",
    PLEASE_SELECT_STATE: "कृपया राज्य चुनें",
    SELECT_STATE: "राज्य चुनें",
    PLEASE_SELECT_CITY: "कृपया शहर चुनें",
    SELECT_CITY: "शहर चुनें",
    PLEASE_ENTER_PINCODE: "कृपया पिनकोड दर्ज करें",
    ENTER_PINCODE: "पिनकोड दर्ज करें",
    HOUSE_NO: "हाउस नं ./Flat No./Floor/Street",
    PLEASE_ENTER_HOUSE_NO: "कृपया हाउस नं ./Flat No./Floor/Street दर्ज करें",
    ENTER_HOUSE_NO: "घर का नंबर नं। दर्ज करें। नं",
    LANDMARK: "सीमा चिन्ह",
    ENTER_LANDMARK: "मील का पत्थर दर्ज करें",
    HOW_TO_REACH: "कैसे पहुंचा जाये",
    ENTER_HOW_TO_REACH: "कैसे पहुँचें",
    PLEASE_ENTER_ADDRESS: "कृपया पता दर्ज करें",
    ENTER_ADDRESS: "पता लिखिए",
    UPDATE: "अपडेट करें",
    PLEASE_ENTER_OTP: "कृपया OTP दर्ज करें",
    ENTER_OTP: "OTP दर्ज करें",
    VERIFY: "सत्यापित करें",
    DIDNT_RECIEVED_ANYTHIG: "कुछ भी नहीं मिला?",
    RESEND_OTP: "ओटीपी पुनः भेजें",
    NOT_VERIFIED: "प्रमाणित नहीं है",
    VERIFY_PHONE_NUMBER: "फ़ोन नंबर सत्यापित करें",
    VERIFIED: "सत्यापित",
    PACKAGE_NAME: "पैकेज का नाम",
    PACKAGE_AMOUNT: "पैकेज राशि",
    BONUS_AMOUNT: "बोनस राशि",
    BONUS_VALIDITY: "बोनस की वैधता",
    TITLE: "शीर्षक",
    DESCRIPTION: "विवरण",
    ADD_PACKAGE_CONFIGURATION: "पैकेज कॉन्फ़िगरेशन जोड़ें",
    DELETE_SELECTED: "चयनित मिटाएं",
    VIEW_PACKAGE_CONFIGURATION: "पैकेज कॉन्फ़िगरेशन देखें",
    EDIT_PACKAGE_CONFIGURATION: "पैकेज कॉन्फ़िगरेशन संपादित करें",
    PLEASE_ENTER_PACKAGE_NAME: "कृपया पैकेज का नाम दर्ज करें",
    ENTER_PACKAGE_NAME: "पैकेज का नाम दर्ज करें",
    PLEASE_ENTER_PACKAGE_AMOUNT: "कृपया पैकेज राशि दर्ज करें",
    ENTER_PACKAGE_AMOUNT: "पैकेज राशि दर्ज करें",
    PLEASE_ENTER_BONUS_AMOUNT: "कृपया बोनस राशि दर्ज करें",
    ENTER_BONUS_AMOUNT: "बोनस राशि दर्ज करें",
    BONUS_AMOUNT_VALIDITY_DAYS: "बोनस राशि की वैधता (दिन)",
    PLEASE_ENTER_BONUS_AMOUNT_VALIDITY_DAYS:
      "कृपया बोनस राशि की वैधता दर्ज करें (दिन)",
    ENTER_BONUS_VALIDITY_DAYS: "बोनस वैधता (दिन) दर्ज करें",
    DESCRIPTION_TITLE: "विवरण शीर्षक",
    ENTER_DESCRIPTION_TITLE: "विवरण शीर्षक दर्ज करें",
    PLEASE_ENTER_DESCRIPTION_TITLE: "कृपया विवरण शीर्षक दर्ज करें",
    PLEASE_ENTER_DESCRIPTION: "कृपया विवरण दर्ज करें",
    ENTER_DESCRIPTION: "विवरण दर्ज करें",
    PICKUP_ID: "पिकअप आई.डी.",
    PROMOCODE: "प्रचार कोड",
    ASSIGNED_USER_NAME: "सौंपा गया उपयोगकर्ता नाम",
    ORDER: "गण",
    REASSIGN: "पुनः असाइन",
    ADD_PICKUP: "पिकअप जोड़ें",
    REASSIGN_PICKUP_EXECUTIVE: "पुनः पिक पिक कार्यकारी",
    PICKUP_EXECUTIVE: "पिक कार्यकारी",
    PLEASE_SELECT_ANY_PICKUP_EXECUTIVE: "कृपया कोई भी पिक कार्यकारी चुनें",
    SELECT_PICKUP_EXECUTIVE: "पिक कार्यकारी चुनें",
    PICKUP: "उठाना",
    HISTORY: "इतिहास",
    GENERAL_PICKUP_INFORMATION: "सामान्य पिकअप जानकारी",
    HISTORY_OF_RESCHEDULED_PICKUPS: "पुनर्निर्धारित पिकअप का इतिहास",
    PICKUP_STATUS: "पिक की स्थिति",
    SELECT_PICKUP_STATUS: "पिकअप स्थिति चुनें",
    PLEASE_SELECT_PICKUP_DATE: "कृपया पिक तिथि चुनें",
    PICKUP_TIMESLOT: "पिकअप टाइमस्लेट",
    PLEASE_SELECT_TIMESLOT: "कृपया TimeSlot का चयन करें",
    SELECT_TIMESLOT: "Timeslot का चयन करें",
    SELECT_ADDRESS: "पता चुनें",
    PLEASE_SELECT_DELIVERY_TYPE: "कृपया वितरण प्रकार चुनें",
    SELECT_DELIVERY_TYPE: "वितरण प्रकार चुनें",
    ENTER_NO_OF_GARMENTS: "गारमेंट्स की संख्या दर्ज करें",
    ENTER_PROMOCODE: "प्रचार कि नियमावली दर्ज करो",
    SERVICES: "सेवाएं",
    PLEASE_SELECT_SERVICES: "कृपया सेवाओं का चयन करें",
    SELECT_SERVICES: "सेवाओं का चयन करें",
    DELIVERY_TIMESLOT: "डिलीवरी का समय",
    CANCELLATION_REASON: "रद्द करने का कारण",
    SELECT_OPERATION_INSTRUCTION: "ऑपरेशन इंस्ट्रक्शन का चयन करें",
    RESCHEDULED_PICKUP_HISTORY: "पुनर्निर्धारित पिकअप इतिहास",
    ON_DEMAND_WALK_IN: "डिमांड / वॉक-इन पर",
    GARMENTS: "गारमेंट्स",
    CREATE_ORDER: "आदेश बनाएँ",
    PRINT_TAGS: "प्रिंट टैग",
    CANCEL_ORDER: "आदेश रद्द",
    CANCEL_REASON: "कारण रद्द करें",
    PLEASE_ENTER_REASON: "कृपया कारण दर्ज करें",
    ENTER_REASON_TO_CANCEL_THE_ORDER: "ऑर्डर रद्द करने का कारण दर्ज करें",
    CUSTOMER_DETAILS: "उपभोक्ता विवरण",
    NAME: "नाम",
    TYPE_OF_SERVICE: "सेवा का प्रकार",
    PICKUP_REQUEST: "पिक अनुरोध",
    TYPE_OF_CUSTOMER: "ग्राहक का प्रकार",
    WALLET_CREDIT: "वॉलेट क्रेडिट",
    FILTER: "फ़िल्टर",
    ORDER_SUMMARY: "आदेश सारांश",
    DETAILS: "विवरण",
    QTY: "मात्रा",
    RATES: "दरें",
    FEES: "फीस",
    SUBTOTAL: "उप-योग",
    TAXES_AND_CHARGES: "कर और शुल्क",
    PROMO_DISCOUNT: "प्रोमो डिस्काउंट",
    SGST: "एसजीएसटी",
    CGST: "सीजीएसटी",
    IGST: "IGST",
    TOTAL: "कुल",
    ORDER_REMARKS: "आदेश",
    REVIEW_ORDER: "अादेश का पुनः निरिक्षण",
    MEDIUM: "मध्यम",
    REQUIRED: "अपेक्षित",
    WEIGHT: "वजन",
    SELECT_ADD_ON_SERVICES: "ऐड-ऑन सेवाओं का चयन करें",
    DARNING_SIZE: "डारिंग आकार",
    SMALL: "छोटा",
    LARGE: "विशाल",
    DYING_COLORS: "मरने का रंग",
    SIZE: "आकार",
    PRICE: "कीमत",
    NOTES: "टिप्पणियाँ",
    ONLY_3_IMAGES_ARE_ALLOWED: "केवल 3 छवियां अनुमत हैं",
    LOGISTICS_INSTRUCTION: "रसद निर्देश",
    ON_DEMAND_DELIVERY: "डिमांड डिलीवरी पर",
    REPRINT_TAGS: "पुनर्मुद्रण टैग",
    REPRINT_ESTIMATED_ORDER: "पुनर्मुद्रित अनुमानित आदेश",
    REWASH_REQUESTED_BY: "रिवाश रिक्वेस्ट बाय",
    PLACE_ORDER: "आदेश देना",
    COLLECT_ADVANCE_PAYMENT: "एडवांस पेमेंट लीजिए",
    UPDATE_ORDER: "आर्डर का अद्यतन करें",
    CONFIRM_ORDER: "आदेश की पुष्टि करें",
    TAG_IN_GARMENTS: "गारमेंट्स में टैग",
    CREATE_REWASH_ORDER: "रीवाश ऑर्डर बनाएं",
    PAYMENT_METHOD: "भुगतान का तरीका",
    COLLECT: "कलेक्ट",
    PROCESSING: "प्रसंस्करण",
    INVOICE_NUMBER: "बीजक संख्या",
    GENERATE: "उत्पन्न",
    SETTLE: "रुकना",
    READY_FOR_DELIVERY_ORDERS: "डिलीवरी ऑर्डर के लिए तैयार",
    HIDE_FILTERS: "फिल्टर छुपाएँ",
    SHOW_FILTERS: "फिल्टर दिखाएं",
    PLEASE_SELECT_FROM_DATE: "कृपया तारीख से चयन करें",
    FROM_DATE: "तारीख से",
    PLEASE_SELECT_TO_DATE: "कृपया तिथि का चयन करें",
    TO_DATE: "तारीख तक",
    ENTER_SPLIT_ORDER_NUMBER: "स्प्लिट ऑर्डर नंबर दर्ज करें",
    PROFORMA_INVOICE_NUMBER: "प्रोफार्मा चालान नंबर",
    PROFORMA_INVOICE_DATE: "प्रोफार्मा चालान तिथि",
    INVOICE_DATE: "चालान की तारीख",
    INVOICE: "बीजक",
    ORDER_TYPE: "आदेश प्रकार",
    PICKUP_BY: "द्वारा पिकअप",
    ADJUSTED_LK_BONUS_AMOUNT: "समायोजित एलके बोनस राशि",
    PROFORMA_INVOICE: "प्रोफार्मा चालान",
    TOTAL_AMOUNT: "कुल रकम",
    ADD_ON_SERVICE_CHARGES: "सेवा शुल्क जोड़ें",
    ADHOC_CHARGES: " एडहॉक शुल्क",
    PAIDLESS_AMOUNT: "Adjusted By Admin",
    ADJUSTED_AMOUNT: "Paid Less",
    AMOUNT_TO_COLLECT: "इकट्ठा करने के लिए राशि",
    ADD_REMARKS: "रिमार्क्स जोड़ें",
    UPDATE_PROFORMA_INVOICE: "अद्यतन प्रोफार्मा चालान",
    CREATE_PROFORMA_INVOICE: "प्रोफार्मा चालान बनाएँ",
    USE_LK_WALLET: "एलके वॉलेट का उपयोग करें",
    GENERATE_TAX_INVOICE: "कर चालान जनरेट करें",
    DO_YOU_WANT_TO_ADD_THE_EXCESS_AMOUNT_TO_THE_LKWALLET:
      "क्या आप LKWallet में अतिरिक्त राशि जोड़ना चाहते हैं",
    SETTLE_LESS_PAID_INVOICE: "सेटल कम पेड इनवॉइस",
    SLOT: "स्लॉट",
    ASSIGNED_TO: "को सौंपना",
    RESCHEDULE: "पुनर्निर्धारित",
    DELIVERY_LIST: "वितरण सूची",
    REASSIGN_DELIVERY: "रीसाइन डिलीवरी",
    PLEASE_SELECT_ASSIGNEE: "कृपया असाइन करें चुनें",
    SELECT_ASSIGNEE: "असाइन करें का चयन करें",
    DELIVERY_ID: "डिलीवरी आईडी",
    RESCHEDULE_DELIVERY: "पुनर्निर्धारित वितरण",
    CANCEL_DELIVERY: "वितरण रद्द करें",
    PLEASE_SELECT_DELIVERY_DATE: "कृपया डिलीवरी की तारीख चुनें",
    PLEASE_ENTER_CANCEL_REASON: "कृपया कारण रद्द करें दर्ज करें",
    ENTER_CANCEL_REASON: "कारण रद्द करें दर्ज करें",
    PLEASE_SELECT_EXPENSE_TYPE: "कृपया व्यय प्रकार चुनें",
    PLEASE_SELECT_EXPENSE_DATE: "कृपया व्यय तिथि चुनें",
    // OVERDUE_GARMENTS: "गारमेंट्स पर काबू",
    OVERDUE_ORDERS: "अतिदेय आदेश",
    GARMENT_COUNTS: "परिधान मायने रखता है",
    ORDER_COUNTS: "आदेश मायने रखता है",
    TOTAL_PENDING_GARMENTS_ORDERS: "कुल लंबित वस्त्र / आदेश",
    TODAYS_DUE: "आज का दिन",
    TICKETS_COUNTS: "टिकट मायने रखता है",
    ORDER_CAN_NOT_BE_CREATED_USING_CLOSED_STORE:
      "बंद स्टोर का उपयोग करके ऑर्डर नहीं बनाया जा सकता है",
    THIS_TYPE_OF_ORDER_CAN_NOT_BE_CREATED_FROM_THIS_STORE:
      "इस स्टोर से इस तरह का ऑर्डर नहीं बनाया जा सकता है",
    AMOUNT_IS_REQUIRED: "राशि की आवश्यकता है",
    AMOUNT_SHOULD_BE_GREATER_THAN_0: "राशि 0 से अधिक होनी चाहिए",
    IS_LAUNDROKART_EMPLOYEE: "लॉन्ड्रोकार्ट कर्मचारी है",
    REWASH_ORDER: "रीवाश ऑर्डर",

    PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9:
      "कृपया केवल 1 से 9 तक के अंकों को दर्ज करें",
    PLEASE_ENTER_ONLY_ALPHABETS: "कृपया केवल अक्षर दर्ज करें",
    MISSING_TAG_NOS: "लापता टैग नं",

    //Server Message
    CUSTOMER_IS_SUCCESSFULLY_REGISTERED: "ग्राहक सफलतापूर्वक पंजीकृत है",
    DATA_IS_SUCCESSFULLY_RETRIEVED:
      "डेटा सफलतापूर्वक पुनर्प्राप्त किया जाता है",
    DATA_IS_SUCCESSFULLY_DELETED: "डेटा सफलतापूर्वक हटा दिया गया है",
    DATA_IS_SUCCESSFULLY_SAVED: "डेटा सफलतापूर्वक सहेजा गया है",
    DATA_IS_SUCCESSFULLY_UPDATED: "डेटा सफलतापूर्वक अपडेट किया गया है",
    RECORD_IS_ALREADY_EXISTS: "रिकॉर्ड पहले से मौजूद है",
    INTERNAL_SERVER_ERROR: "आंतरिक सर्वर त्रुटि",
    DATA_IS_SUCCESSFULLY_UPLOADED: "डेटा सफलतापूर्वक अपलोड किया गया है",
    DISCOUNT_IS_SUCCESSFULLY_BLOCKED: "छूट सफलतापूर्वक अवरुद्ध है",
    DISCOUNT_IS_SUCCESSFULLY_AUTHORIZED: "छूट सफलतापूर्वक अधिकृत है",
    DISCOUNT_IS_SUCCESSFULLY_GENERATED: "छूट सफलतापूर्वक उत्पन्न होती है",
    OTP_HAS_BEEN_SUCCESSFULLY_RESENT: "ओटीपी को सफलतापूर्वक भेजा गया है",
    PICKUP_IS_SUCCESSFULLY_RESCHEDULED:
      "पिकअप सफलतापूर्वक पुनर्निर्धारित किया गया है",
    PICKUP_IS_SUCCESSFULLY_SCHEDULED: "पिकअप सफलतापूर्वक निर्धारित है",
    PICKUP_IS_SUCCESSFULLY_CANCELLED: "पिकअप सफलतापूर्वक रद्द कर दिया गया है",
    PICKUP_IS_SUCCESSFULLY_REASSIGNED:
      "पिकअप सफलतापूर्वक पुन: असाइन किया गया है",
    ERROR_WHILE_SENDING_CALL_BACK_REQUEST_NOTIFICATION:
      "कॉल बैक अनुरोध सूचना भेजते समय त्रुटि",
    ORDER_IS_SUCCESSFULLY_CANCELLED: "आदेश सफलतापूर्वक रद्द कर दिया गया है",
    ORDER_IS_SUCCESSFULLY_CREATED_PLEASE_CONFIRM_ORDER_TO_PROCEED_FURTHER:
      "आदेश सफलतापूर्वक बनाया गया है कृपया आगे बढ़ने के लिए आदेश की पुष्टि करें",
    ORDER_IS_SUCCESSFULLY_UPDATED: "आदेश सफलतापूर्वक अपडेट किया गया है",
    GENERATE_TAGS_FOR_ORDER_SUCCESSFULLY: "आदेश के लिए टैग सफलतापूर्वक बनाएं",
    GETEPASS_IS_SUCCESSFULLY_GENERATED: "गेटपास सफलतापूर्वक जनरेट किया गया है",
    PROMOCODE_VALIDATED_SUCCESSFULLY: "प्रोमोकोड सफलतापूर्वक सत्यापित किया गया",
    INVALID_PROMOCODE: "अमान्य प्रचार",
    DELIVERY_IS_SUCCESSFULLY_REASSIGNED:
      "वितरण सफलतापूर्वक पुन: असाइन किया गया है",
    AMOUNT_SUCCESSFULLY_BLOCKED: "राशि सफलतापूर्वक ब्लॉक की गई",
    USER_IS_VALID: "उपयोगकर्ता मान्य है",
    USER_IS_INVALID: "उपयोगकर्ता अमान्य है",
    ALREADY_IN_USED: "पहले से ही इस्तेमाल में है",
    PDF_FILE_IS_GENERATED_SUCCESSFULLY:
      "पीडीएफ फाइल सफलतापूर्वक उत्पन्न होती है",
    OLD_PASSWORD_DOES_NOT_MATCH: "पुराना पासवर्ड मेल नहीं खाता",
    NOTIFICATION_HAS_BEEN_SENT_SUCCESSFULLY:
      "अधिसूचना को सफलतापूर्वक भेज दिया गया है",
    ERROR_WHILE_SENDING_NOTIFICATION: "सूचना भेजते समय त्रुटि",
    NOTIFICATION_IS_SUCCESSFULLY_DISMISS:
      "अधिसूचना सफलतापूर्वक खारिज कर दी जाती है",
    THERE_WAS_AN_INTERNAL_ERROR_PLEASE_CONTACT_TO_TECHNICAL_SUPPORT:
      "एक आंतरिक त्रुटि थी कृपया तकनीकी सहायता से संपर्क करें",
    EMAIL_SENT_SUCCESSFULLY: "सफलतापूर्वक भेजा गया ईमेल!",
    TAGS_NOT_FOUND: "टैग नहीं मिला",
    UNABLE_TO_GET_LIST_OF_STORE_DATA:
      "स्टोर डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_MISSING_TAG_DATA: "लापता टैग डेटा प्राप्त करने में असमर्थ",
    PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_TAG:
      "टैग स्कैन करने से पहले कृपया स्टोर चुनें",
    PLEASE_ENTER_THE_TAG_TO_SCAN: "कृपया स्कैन करने के लिए टैग दर्ज करें",
    TAG_ALREADY_SCANNED: "टैग पहले से ही स्कैन किया गया",
    PLEASE_SELECT_FACTORY_TAG_NO_FOR_TRANSFERIN_PROCESS:
      "कृपया ट्रांसफर प्रक्रिया के लिए फ़ैक्टरी टैग नं चुनें",
    UNABLE_TO_SCAN_THE_TAG: "टैग को स्कैन करने में असमर्थ",
    TAGS_ARE_TRANSFERRED_IN_SUCCESSFULLY:
      "टैग सफलतापूर्वक स्थानांतरित किए जाते हैं",
    GENERATING_GATE_PASS: "गेट पास का निर्माण",
    PLEASE_SELECT_STICKERS: "कृपया स्टिकर चुनें",
    UNABLE_TO_GET_STICKERS_TO_TRANSFER_OUT:
      "बाहर स्थानांतरित करने के लिए स्टिकर प्राप्त करने में असमर्थ",
    INFO: "जानकारी",
    ENTERED_NUMBER_SHOULD_BE_SMALLER_THEN_NO_OF_PENDING_GARMENTS:
      "दर्ज संख्या छोटी होनी चाहिए फिर लंबित कपड़ों की नहीं",
    PLEASE_SELECT_ATLEAST_ONE_PENDING_GARMENTS_TO_BE_PACKED_IN:
      "कृपया कम से कम एक लंबित कपड़ों का चयन करें",
    STICKER_SUCCESSFULLY_GENERATED: "स्टिकर सफलतापूर्वक उत्पन्न हुआ",
    UNABLE_TO_GET_STICKERID: "स्टिकर प्राप्त करने में असमर्थ",
    GARMENT_ALREADY_PACKED:
      "इस उपनियम के कुछ वस्त्र पहले ही पैक किए जा चुके हैं, कृपया ताज़ा करें और पुनः प्रयास करें",
    REDIRECTING: "पुन: निर्देशित",
    SUCCESS: "सफलता",
    FAIL: "विफल",

    //11/06/2020
    CUSTOMER_IS_SUCCESSFULLY_REGISTERED: "ग्राहक सफलतापूर्वक पंजीकृत है",
    DATA_IS_SUCCESSFULLY_RETRIEVED:
      "डेटा सफलतापूर्वक पुनर्प्राप्त किया जाता है",
    DATA_IS_SUCCESSFULLY_DELETED: "डेटा सफलतापूर्वक हटा दिया गया है",
    DATA_IS_SUCCESSFULLY_SAVED: "डेटा सफलतापूर्वक सहेजा गया है",
    DATA_IS_SUCCESSFULLY_UPDATED: "डेटा सफलतापूर्वक अपडेट किया गया है",
    RECORD_IS_ALREADY_EXISTS: "रिकॉर्ड पहले से मौजूद है",
    INTERNAL_SERVER_ERROR: "आंतरिक सर्वर त्रुटि",
    DATA_IS_SUCCESSFULLY_UPLOADED: "डेटा सफलतापूर्वक अपलोड किया गया है",
    DISCOUNT_IS_SUCCESSFULLY_BLOCKED: "छूट सफलतापूर्वक अवरुद्ध है",
    DISCOUNT_IS_SUCCESSFULLY_AUTHORIZED: "छूट सफलतापूर्वक अधिकृत है",
    DISCOUNT_IS_SUCCESSFULLY_GENERATED: "छूट सफलतापूर्वक उत्पन्न होती है",
    OTP_HAS_BEEN_SUCCESSFULLY_RE_SENT: "ओटीपी को सफलतापूर्वक भेजा गया है",
    PICKUP_IS_SUCCESSFULLY_RESCHEDULED:
      "पिकअप सफलतापूर्वक पुनर्निर्धारित किया गया है",
    PICKUP_IS_SUCCESSFULLY_SCHEDULED: "पिकअप सफलतापूर्वक निर्धारित है",
    PICKUP_IS_SUCCESSFULLY_CANCELLED: "पिकअप सफलतापूर्वक रद्द कर दिया गया है",
    PICKUP_IS_SUCCESSFULLY_REASSIGNED:
      "पिकअप सफलतापूर्वक पुन: असाइन किया गया है",
    ERROR_WHILE_SENDING_CALL_BACK_REQUEST_NOTIFICATION:
      "कॉल बैक अनुरोध सूचना भेजते समय त्रुटि",
    ORDER_IS_SUCCESSFULLY_CANCELLED: "आदेश सफलतापूर्वक रद्द कर दिया गया है",
    ORDER_IS_SUCCESSFULLY_CREATED_PLEASE_CONFIRM_ORDER_TO_PROCEED_FURTHER:
      "आदेश सफलतापूर्वक बनाया गया है कृपया आगे बढ़ने के लिए आदेश की पुष्टि करें",
    ORDER_IS_SUCCESSFULLY_UPDATED: "आदेश सफलतापूर्वक अपडेट किया गया है",
    GENERATE_TAGS_FOR_ORDER_SUCCESSFULLY: "आदेश के लिए टैग सफलतापूर्वक बनाएं",
    GETEPASS_IS_SUCCESSFULLY_GENERATED: "गेटपास सफलतापूर्वक जनरेट किया गया है",
    PROMOCODE_VALIDATED_SUCCESSFULLY: "प्रोमोकोड सफलतापूर्वक सत्यापित किया गया",
    INVALID_PROMOCODE: "अमान्य प्रचार",
    DELIVERY_IS_SUCCESSFULLY_REASSIGNED:
      "वितरण सफलतापूर्वक पुन: असाइन किया गया है",
    AMOUNT_SUCCESSFULLY_BLOCKED: "राशि सफलतापूर्वक ब्लॉक की गई",
    USER_IS_VALID: "उपयोगकर्ता मान्य है",
    USER_IS_INVALID: "उपयोगकर्ता अमान्य है",
    ALREADY_IN_USED: "पहले से ही इस्तेमाल में है",
    PDF_FILE_IS_GENERATED_SUCCESSFULLY:
      "पीडीएफ फाइल सफलतापूर्वक उत्पन्न होती है",
    OLD_PASSWORD_DOES_NOT_MATCH: "पुराना पासवर्ड मेल नहीं खाता",
    NOTIFICATION_HAS_BEEN_SENT_SUCCESSFULLY:
      "अधिसूचना को सफलतापूर्वक भेज दिया गया है",
    ERROR_WHILE_SENDING_NOTIFICATION: "सूचना भेजते समय त्रुटि",
    NOTIFICATION_IS_SUCCESSFULLY_DISMISS:
      "अधिसूचना सफलतापूर्वक खारिज कर दी जाती है",
    THERE_WAS_AN_INTERNAL_ERROR_PLEASE_CONTACT_TO_TECHNICAL_SUPPORT:
      "एक आंतरिक त्रुटि थी तकनीकी सहायता के लिए संपर्क करें",
    //EMAIL_SENT_SUCCESSFULLY: 'सफलतापूर्वक भेजा गया ईमेल!',
    TAGS_NOT_FOUND: "टैग नहीं मिला",
    UNABLE_TO_GET_LIST_OF_STORE_DATA:
      "स्टोर डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_MISSING_TAG_DATA: "लापता टैग डेटा प्राप्त करने में असमर्थ",
    PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_TAG:
      "टैग स्कैन करने से पहले कृपया स्टोर चुनें",
    PLEASE_ENTER_THE_TAG_TO_SCAN: "कृपया स्कैन करने के लिए टैग दर्ज करें",
    TAG_ALREADY_SCANNED: "टैग पहले से ही स्कैन किया गया",
    PLEASE_SELECT_FACTORY_TAG_NO_FOR_TRANSFERIN_PROCESS:
      "कृपया ट्रांसफर प्रक्रिया के लिए फ़ैक्टरी टैग नं का चयन करें",
    UNABLE_TO_SCAN_THE_TAG: "टैग को स्कैन करने में असमर्थ",
    TAGS_ARE_TRANSFERRED_IN_SUCCESSFULLY:
      "टैग सफलतापूर्वक स्थानांतरित किए जाते हैं",
    GENERATING_GATE_PASS: "गेट पास का निर्माण",
    PLEASE_SELECT_STICKERS: "कृपया स्टिकर चुनें",
    UNABLE_TO_GET_STICKERS_TO_TRANSFER_OUT:
      "बाहर स्थानांतरित करने के लिए स्टिकर प्राप्त करने में असमर्थ",
    INFO: "जानकारी",
    ENTERED_NUMBER_SHOULD_BE_SMALLER_THEN_NO_OF_PENDING_GARMENTS:
      "दर्ज संख्या छोटी होनी चाहिए फिर लंबित कपड़ों की नहीं",
    PLEASE_SELECT_ATLEAST_ONE_PENDING_GARMENTS_TO_BE_PACKED_IN:
      "कृपया कम से कम एक लंबित कपड़ों का चयन करें",
    STICKER_SUCCESSFULLY_GENERATED: "स्टिकर सफलतापूर्वक उत्पन्न हुआ",
    UNABLE_TO_GET_STICKERID: "स्टिकर प्राप्त करने में असमर्थ",
    REDIRECTING: "पुन: निर्देशित",
    SUCCESS: "सफलता",
    FAIL: "विफल",
    WALLET_BALANCE: "वॉलेट शेष राशि",
    PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9:
      "कृपया केवल 1 से 9 तक के अंकों को दर्ज करें",
    PLEASE_ENTER_ONLY_ALPHABETS: "कृपया केवल अक्षर दर्ज करें",
    MISSING_TAG_NOS: "गुम टैग नग",
    UNABLE_TO_GET_ORDERS_DUE_FOR_DELIVERY:
      "प्रसव के कारण आदेश प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_PENDING_PICKUPS: "लंबित पिकअप प्राप्त करने में असमर्थ",
    UNABLE_TO_LOAD_ORDERS_PENDING_TO_TRANSFER_OUT_TO_FACTORY:
      "कारखाने से बाहर स्थानांतरित करने के लिए लंबित आदेशों को लोड करने में असमर्थ",
    UNABLE_TO_GET_DATA_FOR_DASHBOARD:
      "डैशबोर्ड के लिए डेटा प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_DATA_FOR_TIMESLOT:
      "समयावधि के लिए डेटा प्राप्त करने में असमर्थ",
    CANNOT_REASSIGN_DELIVERY_AS_IT_IS_ALREADY_PROCEEDED_FURTHER:
      "पुनः वितरण नहीं कर सकता क्योंकि यह पहले से ही आगे बढ़ चुका है",
    DELIVERY_REASSIGNED_SUCCESSFULLY: "डिलीवरी सफलतापूर्वक जारी की गई",
    UNABLE_TO_REASSIGN_DELIVERY_REQUEST:
      "वितरण अनुरोध को पुन: असाइन करने में असमर्थ",
    DELIVERY_STATUS_MUST_BE_DELIVERY_SCHEDULED_OR_ASSIGNED:
      "डिलीवरी की स्थिति डिलिवरी शेड्यूल या असाइन की जानी चाहिए",
    DELIVERY_RESCHEDULED_SUCCESSFULLY:
      "वितरण सफलतापूर्वक पुनर्निर्धारित किया गया",
    UNABLE_TO_RESCHEDULE_DELIVERY_REQUEST:
      "पुनर्निर्धारित वितरण अनुरोध करने में असमर्थ",
    REQUEST_CANCELLED_SUCCESSFULLY: "अनुरोध सफलतापूर्वक रद्द कर दिया गया",
    UNABLE_TO_CANCEL_DELIVERY_REQUEST: "वितरण अनुरोध रद्द करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_DELIVERY_LIST_DATA:
      "वितरण सूची डेटा की सूची प्राप्त करने में असमर्थ",
    EXPENSE_DELETED_SUCCESSFULLY: "व्यय सफलतापूर्वक हटा दिया गया",
    UNABLE_TO_DELETE_EXPENSE: "व्यय हटाने में असमर्थ",
    EXPENSE_ADDED_SUCCESSFULLY: "व्यय सफलतापूर्वक जोड़ा गया",
    EXPENSE_UPDATED_SUCCESSFULLY: "व्यय सफलतापूर्वक अपडेट किया गया",
    UNABLE_TO_SUBMIT_DATA: "डेटा सबमिट करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_EXPENSES: "खर्चों की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_GATE_PASS_DATA:
      "गेट पास डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_STORE_GARMENT_INVENTORY_DATA:
      "स्टोर परिधान सूची डेटा प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_SPLIT_ORDER_DETAILS:
      "विभाजन आदेश विवरण प्राप्त करने में असमर्थ",
    INVOICE_GENERATED_SUCCESSFULLY: "चालान सफलतापूर्वक उत्पन्न हुआ",
    UNABLE_TO_GENERATE_INVOICE: "चालान उत्पन्न करने में असमर्थ",
    INVOICE_DATA_SUBMITTED_SUCCESSFULLY:
      "चालान डेटा सफलतापूर्वक सबमिट किया गया",
    UNABLE_TO_SUBMIT_INVOICE_DATA: "चालान डेटा जमा करने में असमर्थ",
    INVOICE_SETTLED_SUCCESSFULLY: "चालान सफलतापूर्वक निपट गया",
    UNABLE_TO_SETTLE_INVOICE: "चालान का निपटान करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_ORDERS: "आदेशों की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_UOM_LIST: "यूओएम सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GENERATE_ESTIMATED_ORDER: "अनुमानित क्रम उत्पन्न करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_SERVICES: "सेवाओं की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_STORE_DATA: "स्टोर डेटा प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_PICKUP_DATA: "पिकअप डेटा प्राप्त करने में असमर्थ",
    TAGS_GENERATED_SUCCESSFULLY: "टैग सफलतापूर्वक उत्पन्न हुआ",
    UNABLE_TO_GENERATE_TAGS: "टैग उत्पन्न करने में असमर्थ",
    UNABLE_TO_GET_ORDER_INFORMATION: "आदेश की जानकारी प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_TIME_CARD: "समय कार्ड प्राप्त करने में असमर्थ",
    UNABLE_TO_LOAD_ADD_ON_SERVICES: "सेवाओं पर ऐड लोड करने में असमर्थ",
    UNABLE_TO_GET_PACKAGING_CHARGES: "पैकेजिंग शुल्क प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_DELIVERY_CHARGES: "वितरण शुल्क प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_GARMENTS: "वस्त्रों की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_RATES: "दरें प्राप्त करने में असमर्थ",
    ORDER_SUCCESSFULLY_CREATED: "आदेश सफलतापूर्वक बनाया गया",
    UNABLE_TO_CREATE_THE_ORDER: "आदेश बनाने में असमर्थ",
    PLEASE_ENTER_THE_PROMOCODE: "कृपया प्रोमोकोड दर्ज करें",
    PROMOCODE_APPLIED_SUCCESSFULLY: "प्रोमोकोड सफलतापूर्वक लागू हुआ",
    PROMOCODE_IS_INVALID: "प्रोमोकोड अमान्य है",
    UNABLE_TO_VALIDATE_PROMOCODE: "प्रोमोकोड सत्यापित करने में असमर्थ",
    ORDER_SUCCESSFULLY_UPDATED: "आदेश सफलतापूर्वक अपडेट किया गया",
    UNABLE_TO_UPDATE_THE_ORDER: "आदेश अपडेट करने में असमर्थ",
    ORDER_SUCCESSFULLY_CANCELLED: "आदेश सफलतापूर्वक रद्द कर दिया गया",
    UNABLE_TO_CANCEL_ORDER: "आदेश रद्द करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_PICKUP_DATA:
      "पिकअप डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_USER_DATA:
      "उपयोगकर्ता डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_REASSIGN_THE_PICKUP: "पिकअप को पुन: सौंपने में असमर्थ",
    UNABLE_TO_GET_DATA_FOR_HISTORY:
      "इतिहास के लिए डेटा प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_DATA_FOR_DELIVERY_TYPES:
      "वितरण प्रकारों के लिए डेटा प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_DATA_FOR_PICKUP_STATUS:
      "पिकअप की स्थिति के लिए डेटा प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_OPERATION_INSTRUCTION_DATA:
      "ऑपरेशन अनुदेश डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_SERVICES_DATA: "सेवाएँ डेटा प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_REASONS_DATA:
      "कारणों की सूची डेटा प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_DATA_FOR_CUSTOMER:
      "ग्राहक के लिए डेटा प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_CUSTOMER_ADDRESSES: "ग्राहक पते प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_DATA: "डेटा प्राप्त करने में असमर्थ",
    PLEASE_INPUT_CUSTOMERNAME_ADDRESS_SERVICES_PROMOCODE:
      "कृपया ग्राहक का नाम / पता / सेवा / प्रोमोकोड इनपुट करें",
    PICKUP_IS_UPDATED: "पिकअप अपडेट किया गया है",
    PICKUP_IS_SCHEDULED: "पिकअप निर्धारित है",
    PICKUP_IS_RESCHEDULED_SUCCESSFULLY:
      "पिकअप को सफलतापूर्वक पुनर्निर्धारित किया जाता है",
    UNABLE_TO_RESCHEDULE_THE_PICKUP: "पिकअप को पुनर्निर्धारित करने में असमर्थ",
    PICKUP_IS_CANCELLED_SUCCESSFULLY: "पिकअप सफलतापूर्वक रद्द कर दिया गया है",
    UNABLE_TO_CANCEL_PICKUP: "पिकअप को रद्द करने में असमर्थ",
    UNABLE_TO_GET_PRE_QC_RESPONSE_LIST:
      "प्री-क्यूसी प्रतिक्रिया सूची प्राप्त करने में असमर्थ",
    QC_RESPONSE_SUBMITTED_SUCCESSFULLY:
      "QC प्रतिक्रिया सफलतापूर्वक सबमिट की गई",
    UNABLE_TO_SUBMIT_QC_RESPONSE: "QC प्रतिक्रिया सबमिट करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_READY_FOR_DELIVERY_INVOICE_DATA:
      "डिलीवरी / चालान डेटा के लिए तैयार सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_SUBMIT_STICKER_DATA: "स्टिकर डेटा सबमिट करने में असमर्थ",
    UNABLE_TO_GET_STICKER_DATA: "स्टिकर डेटा प्राप्त करने में असमर्थ",
    PLEASE_ENTER_STICKER_ID: "कृपया स्टिकर आईडी दर्ज करें",
    UNABLE_TO_GET_LIST_OF_SERVICE_DATA:
      "सेवा डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_SPLIT_ORDER_NUMBERS:
      "स्प्लिट ऑर्डर नंबर प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_FACTORY_DATA:
      "फ़ैक्टरी डेटा की सूची प्राप्त करने में असमर्थ",
    HAS_BEEN_GENERATED: "गेट पास जनरेट हो गया है",
    UNABLE_TO_GET_LIST_OF_STICKER_DATA:
      "स्टिकर डेटा की सूची प्राप्त करने में असमर्थ",
    UNABLE_TO_GET_LIST_OF_PACKAGE_CONFIGURATION_DATA:
      "पैकेज कॉन्फ़िगरेशन डेटा की सूची प्राप्त करने में असमर्थ",
    PLEASE_SELECT_ATLEAST_ONE_ROW: "कृपया कम से कम एक पंक्ति का चयन करें",
    UNABLE_TO_LOAD_REWASH_REASONS: "रीवाश कारण लोड करने में असमर्थ",
    UNABLE_TO_LOAD_REWASH_REQUESTED_BY_LIST:
      "सूची द्वारा अनुरोध किए गए रीवाश को लोड करने में असमर्थ",
    UNABLE_TO_LOAD_OPERATION_INSTRUCTIONS: "ऑपरेशन निर्देश लोड करने में असमर्थ",
    UNABLE_TO_LOAD_BRANDS: "ब्रांडों को लोड करने में असमर्थ",
    UNABLE_TO_LOAD_SIZES: "आकार लोड करने में असमर्थ",
    UNABLE_TO_LOAD_COLORS: "रंगों को लोड करने में असमर्थ",
    WALLET_BALANCE: "वॉलेट शेष राशि",
    INVALID: "अमान्य",
    SPLITORDERNUMBER_SHOULD_NOT_BE_BLANK:
      "SplitOrderNumber खाली नहीं होना चाहिए",
    VALID: "मान्य",
    ADJUSTMENTAMOUNT_SHOULD_BE_BETWEEN_0_AND_10:
      "AdjustmentAmount 0 और 10 के बीच होना चाहिए",
    SERVICE_RATE_NOT_AVAILABLE: "सेवा दर उपलब्ध नहीं",
    ADDON_SERVICE_RATE_NOT_AVAILABLE: "एड-ऑन सेवा दर उपलब्ध नहीं",
    SCAN_GARMENT: "स्कैन गारमेंट",
    CLEAR: "स्पष्ट",
    SCAN_TAG: "स्कैन टैग",
    PLEASE_SCAN_TAG: "कृपया टैग स्कैन",
    READY_FOR_TRANSFER_OUT: "स्थानांतरण आउट के लिए तैयार",
    SCANNED_GARMENT_LIST: "स्कैन किए गारमेंट सूची",
    THIS_STICKER_BELONGS_TO_ANOTHER_STORE:
      "इस स्टीकर एक और स्टोर के अंतर्गत आता है",
    PLEASE_ENTER_VALID_STICKER_NUMBER: "वैध स्टीकर संख्या दर्ज करें",
    ALL: "सब",
    THIS_TAG_ALREADY_EXISTS: "इस टैग को पहले से मौजूद है",
    PREVIOUSLY_PACKE_ON: "इससे पहले पर पैक्ड",
    CASH_COLLECTION: "नकद - संग्रह",
    ALREADY_EXISTS: "पहले से ही मौजूद है",
    USER_PUNCHIN_NOT_FOUND: "उपयोगकर्ता पंचिन नहीं मिला",
    IT_IS_ALREADY_BLOCKED: "यह पहले से ही अवरुद्ध है",
    AUTHORIZED: "अधिकार दिया गया",
    BLOCKED: "अवरोधित",
    INVALID_OTP: "अमान्य ओटीपी",
    PLEASE_CHECK_THE_PROVIDED_BANNERID: "कृपया दिए गए बैनर की जांच करें",
    ALREADY_IN_USE: "पहले से ही उपयोग में",
    ALREADY_EXISTS: "पहले से ही मौजूद है",
    DATE_OVERLAPPING: "ओवरलैपिंग की तारीख",
    REFERRAL_CODE_DOES_NOT_EXISTS: "रेफरल कोड मौजूद नहीं है",
    REFERRAL_CODE_IS_ALREADY_USED: "रेफरल कोड पहले से ही उपयोग किया जाता है",
    USER_NAME_IS_INVALID: "उपयोगकर्ता का नाम अमान्य है",
    PASSWORD_IS_INVALID: "पासवर्ड गलत है",
    USER_NAME_AND_PASSWORD_DOES_NOT_MATCH:
      "उपयोगकर्ता नाम और पासवर्ड मेल नहीं खाते हैं",
    USER_IS_NOT_EXISTS: "उपयोगकर्ता मौजूद नहीं है",
    SPLIT_ORDER_NUMBER_IS_REQUIRED: "स्प्लिट ऑर्डर नंबर आवश्यक है",
    INVALID_SPLIT_ORDER_NUMBER: "अमान्य विभाजन आदेश संख्या",
    IT_IS_ALREADY_INACTIVATED: "यह पहले से ही निष्क्रिय है",
    COUPON_CODE_NOT_EXISTS: "कूपन कोड मौजूद नहीं है",
    CASH_COUPON_EXPIRED: "कैश कूपन की समय सीमा समाप्त हो गई",
    IT_IS_APPLICABLE_FOR_ONLY_FIRST_TIME_USERS:
      "यह केवल पहली बार उपयोगकर्ताओं के लिए लागू है",
    YOU_HAVE_ALREADY_USED_THIS_COUPON:
      "आपने पहले ही इस कूपन का उपयोग कर लिया है",
    INVALID_COUPON: "अमान्य कूपन",
    SPLITORDERNUMBER_SHOULD_NOT_BE_BLANK:
      "स्प्लिटऑर्डरनंबर खाली नहीं होना चाहिए",
    THIS_PICKUP_IS_ALREADY_IN_PROGRESS: "यह पिकअप पहले से ही प्रगति पर है",
    YOU_HAVE_ALREADY_RESCHEDULED_THIS_PICKUP_3_TIMES:
      "आपने पहले ही इस पिक को 3 बार पुनर्निर्धारित किया है",
    PLEASE_CHECK_WITH_DELIVERYID: "कृपया डिलीवरी के साथ जाँच करें",
    PLEASE_ASSIGN_IT_TO_SOMEONE_FIRST: "कृपया इसे पहले किसी को असाइन करें",
    CANNOT_REASSIGN_DELIVERY_AS_ALREADY_PROCEED_FURTHER:
      "पहले से ही आगे बढ़ने के रूप में पुन: वितरण नहीं कर सकता",
    PLEASE_CHECK_WITH_STATUS: "कृपया स्थिति की जाँच करें",

    STICKER_ID: "स्टिकर आईडी",
    PACKED_On: "पर पैक किया",
    PACKING_IDS: "पैकिंग Ids",
    RESORTING_AND_PACKAGING: "रिज़ॉर्टिंग और पैकेजिंग",
    RESORTING_AND_PACKAGING_List: "रिज़ॉर्टिंग और पैकेजिंग सूची",
    PADING_QC_RESPONSE: "लंबित प्री-क्यूसी प्रतिक्रिया गिनती",
    LANGUAGE: "भाषा:",
    CHANGE_PASSWORD: "पासवर्ड बदलें",
    LOGOUT: "लॉग आउट",
    TAG_NUMBER_NOT_FOUND: "टैग नंबर नहीं मिला",
    TAG_NO: "संख्या मार्क करना",

    YOU_CAN_ONLY_UPLOAD_JPG_JPEG_PNG_FILE:
      "आप केवल JPG / PNG / JPEG फ़ाइल अपलोड कर सकते हैं",
    IMAGE_MUST_BE_SMALLER_THAN_5_MB: "छवि 5 एमबी से छोटी होनी चाहिए",
    GARMENT_SHOULD_BE_INFACTORY_TO_BE_ADDED_FOR_QC:
      "परिधान कारखाने में होना चाहिए क्यूसी के लिए जोड़ा जाए",
    ORDER_ALREADY_CONFIRMED: "आदेश पहले से ही पुष्टि की गई",
    PENDING_PAYMENT: "लंबित भुगतान",
    IT_SEEMS_LIKE_FEW_OF_THE_SERVICES_ARE_NOT_EXISTS_INTO_SYSTEM:
      "ऐसा लगता है कि कुछ सेवाएँ सिस्टम में मौजूद नहीं हैं। कृपया सेवा मास्टर स्क्रीन में विवरण देखें और पुनः प्रयास करें",
    IT_SEEMS_LIKE_FEW_OF_THE_GARMENTS_ARE_NOT_EXISTS_INTO_SYSTEM:
      "ऐसा लगता है कि कुछ वस्त्र प्रणाली में मौजूद नहीं हैं। कृपया परिधान मास्टर स्क्रीन में विवरण देखें और पुनः प्रयास करें",
    SERVICE_TAX_DETAIL_IS_MISSING_IN_SECOND_SHEET:
      "दूसरी शीट में सेवा कर विवरण गायब है",
    PLATFORM: "लंबित भुगतान",
    ORDER_COUNT: "मंच",
    PROMO_CODE: "प्रचार कोड",
    PICK_BOY: "पिकअप बॉय",
    CUST_MO_No: "ग्राहक मोबाइल नं",
    SERVICE_ICONS: "सेवा प्रतीक",
    REQUEST_CREATED_DATE: "अनुरोध तिथि और समय बनाया गया",
    PICKUP_REQUEST_DATE: "पिकअप अनुरोधित तिथि और स्लॉट",
    ORDER_CREATED_DATE: "आदेश दिनांक और समय बनाया गया",
    REPRINT_TAX_INVOICE: "पुनर्मुद्रण कर चालान",
    SEMI_EXPRESS_RATE: "(सेमी एक्सप्रेस - 1.5x)",
    EXPRESS_RATE: "(एक्सप्रेस - 2x)",
    TOTAL_STICKERS: "कुल स्टिकर",
    DELIVERY_BOY: "वितरण लड़का",
    POST_QC: "क्यूसी पोस्ट करें",
    UNABLE_TO_GET_POST_QC_DATA: "पोस्ट qc डेटा प्राप्त करने में असमर्थ",
    GARMENT_PROCEEDED_FURTHER_FOR_POST_PROCESS:
      "परिधान पोस्ट क्यूसी प्रक्रिया के लिए आगे बढ़े",
    POST_QC_STATUS: "पोस्टक्यूसी स्थिति",
    POST_QC_REMARK: "PostQC रिमार्क",
    POST_QC_DATETIME: "क्यूसी डेटटाइम पोस्ट करें",
    POSTED_BY: "द्वारा प्रकाशित किया गया था",
    ADD_GARMENT_FOR_POST_QC: "पोस्ट QC के लिए परिधान जोड़ें",
    LATEST_POSTQC_DETAILS: "नवीनतम PostQc विवरण",
    PICKUP_TIMESLOT: "पिकअप टाइमस्लेट",
    UNABLE_TO_GET_STORE_NAME: "स्टोर नाम पाने में असमर्थ",
    CANCEL_REASON_IS_REQUIRED: "रद्द करने का क्षेत्र आवश्यक है",
    FACTORY_GATE_PASS: "फैक्टरी गेट पास सूची",
    CUSTOMER_REMARK: "ग्राहक",
    STORE_OUT_DATE: "स्टोर आउट डेट",
    LAST_UPDATED_ON: "पिछला नवीनीकरण",
    LAST_UPDATED_BY: "अंतिम अपडेट",
    FACTORY_IN_DATETIME: "फैक्ट्री में डेटाइम",
    GARMENTS_FACTORY_IN: "गारमेंट्स फैक्टरी में",
    GARMENTS_MISSING: "वस्त्र गुम होना",
    BALANCED_GARMENTS: "संतुलित वस्त्र",
    FACTORY_IN_DATE: "फैक्ट्री इन डेट",
    LOT_SIZE: "बड़ा आकार",
    STANDARD_RATE_CARD: "मानक दर कार्ड",
    ORDER_STATUS: "आदेश की स्थिति",
    INVOICE_STATUS: "चालान की स्थिति",
    STOREINDATE: "स्टोर इन डेट",
    FACTORYOUTDATE: "फैक्ट्री आउट डेट",
    PLEASE_SELECT_PAYMENT_MODE: "कृपया भुगतान मोड का चयन करें",
    SELECT_PACKING_MATERIAL:
      "आप केवल 99 तक की पैकिंग सामग्री का चयन कर सकते हैं",
    SOMETHING_WENT_WRONG: "कुछ गलत हो गया",
    UNABLE_TO_GET_PAYMENT_METHOD_DATA:
      "भुगतान विधि डेटा प्राप्त करने में असमर्थ",
    ARE_YOU_SURE_YOU_WANT_TO_MARK_ORDER_AS_COMPLETED:
      "क्या आप सुनिश्चित हैं कि आप आदेश को पूरा करना चाहते हैं?",
    ORDER_MARKED_AS_COMPLETED: "पूर्ण के रूप में चिह्नित किया गया आदेश",
    UNABLE_TO_MARK_ORDER_AS_COMPLETED: "आदेश को पूरा करने में असमर्थ",
    EDC_CONFIGURATION: "EDC डिवाइस इस स्टोर से लिंक नहीं है",
    ALTER_PHONE_NUMBER: "वैकल्पिक फोन नंबर",
    // ATTENDANCE: "उपस्थिति",
    // ATTENDANCE_REPORT: "उपस्थिति विवरण",
    // OTHER_TYPE: "अन्य प्रकार",
    // PLEASE_ENTER_OTHER_TYPE: "कृपया अन्य प्रकार दर्ज करें",
    // ENTER_OTHER_TYPE: "अन्य प्रकार दर्ज करें",
  },
  KANNADA: {
    SELECT_SERVICE: "ಸೇವೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    SELECT_FACTORY: "ಫ್ಯಾಕ್ಟರಿ ಆಯ್ಕೆಮಾಡಿ",
    SPLIT_ORDER: "ಸ್ಪ್ಲಿಟ್ ಆರ್ಡರ್",
    CUSTOMER_NAME: "ಗ್ರಾಹಕ ಹೆಸರು",
    ORDER_DATE: "ಆದೇಶದ ದಿನಾಂಕ",
    TOTAL_GARMENTS: "ಒಟ್ಟು ಉಡುಪುಗಳು",
    SPLIT_ORDER_NUMBER: "ಸ್ಪ್ಲಿಟ್ ಆರ್ಡರ್ ಸಂಖ್ಯೆ",
    TAG_NUMBER: "ಟ್ಯಾಗ್ ಸಂಖ್ಯೆ",
    GARMENT_NAME: "ಉಡುಪಿನ ಹೆಸರು",
    ADD_ON_SERVICES: "ಆಡ್-ಆನ್ ಸೇವೆಗಳು",
    GENERATE_GATE_PASS: "ಗೇಟ್ ಪಾಸ್ ರಚಿಸಿ",
    GATE_PASS_NUMBER: "ಗೇಟ್ ಪಾಸ್ ಸಂಖ್ಯೆ",
    STORE_NAME: "ಅಂಗಡಿ ಹೆಸರು",
    FACTORY_NAME: "ಕಾರ್ಖಾನೆಯ ಹೆಸರು",
    STORE_GARMENTS_OUT: "ಉಡುಪುಗಳನ್ನು ಸಂಗ್ರಹಿಸಿ",
    TOTAL_SPLIT_NUMBER: "ಒಟ್ಟು ಸ್ಪ್ಲಿಟ್ ಸಂಖ್ಯೆ",
    GATE_PASS_DATE: "ಗೇಟ್ ಪಾಸ್ ದಿನಾಂಕ",
    STIKER_NUMBER: "ಸ್ಟಿಕ್ಕರ್ ಸಂಖ್ಯೆ",
    PLEASESCAN_ADD_STICKER: "ದಯವಿಟ್ಟು ಸ್ಕ್ಯಾನ್ / ಸ್ಟಿಕ್ಕರ್ ಸೇರಿಸಿ",
    ENTER_SCAN_STICKER: "ಸ್ಟಿಕ್ಕರ್ ಅನ್ನು ನಮೂದಿಸಿ / ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
    CUSTOMER_CONTACT: "ಗ್ರಾಹಕ ಸಂಪರ್ಕ",
    DELIVERY_TYPE: "ವಿತರಣಾ ಪ್ರಕಾರ",
    SPLIT_ORDER_NO: "ಸ್ಪ್ಲಿಟ್ ಆರ್ಡರ್ ಸಂಖ್ಯೆ",
    GATE_PASS_NO: "ಗೇಟ್ ಪಾಸ್ ನಂ",
    DELIVERY_DATE: "ವಿತರಣಾ ದಿನಾಂಕ",
    GARMENTS_IN_PROCESS: "ಉಡುಪುಗಳು ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿವೆ",
    PACKED_GARMENTS: "ಪ್ಯಾಕ್ ಮಾಡಿದ ಉಡುಪುಗಳು",
    PACKING_MATERIAL: "ಪ್ಯಾಕಿಂಗ್ ವಸ್ತು",
    STICKER_STATUS: "ಸ್ಟಿಕ್ಕರ್ ಸ್ಥಿತಿ",
    SPLIT_ORDER_STATUS: "ಸ್ಪ್ಲಿಟ್ ಆರ್ಡರ್ ಸ್ಥಿತಿ",
    GARMENTS_PACKED_INFORMATION: "ಗಾರ್ಮೆಂಟ್ಸ್ ಪ್ಯಾಕ್ ಮಾಡಿದ ಮಾಹಿತಿ",
    USER_EXPENSE_ENTRY: "ಬಳಕೆದಾರರ ಖರ್ಚು ಪ್ರವೇಶ",
    USER_NAME: "ಬಳಕೆದಾರ ಹೆಸರು",
    EXPENSE_DATE: "ಖರ್ಚು ದಿನಾಂಕ",
    AMOUNT: "ಮೊತ್ತ",
    REMARKS: "ಟೀಕೆಗಳು",
    ARE_YOU_SURE: "ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ ?",
    ADD_EXPENSE: "ವೆಚ್ಚವನ್ನು ಸೇರಿಸಿ",
    APPLY: "ಅನ್ವಯಿಸು",
    SELECT_EXPENSE_TYPE: "ಖರ್ಚು ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    PLEASE_ENTER_AMOUNT: "ದಯವಿಟ್ಟು ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    ENTER_AMOUNT: "ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    ENTER_REMARKS: "ಟೀಕೆಗಳನ್ನು ನಮೂದಿಸಿ",
    GARMENTS_PENDING_TO_SEND_TO_FACTORY:
      "ಫ್ಯಾಕ್ಟರಿಗೆ ಕಳುಹಿಸಲು ಉಡುಪುಗಳು ಬಾಕಿ ಉಳಿದಿವೆ",
    ORDERS_PENDING_TO_SEND_TO_FACTORY:
      "ಕಾರ್ಖಾನೆಗೆ ಕಳುಹಿಸಲು ಆದೇಶಗಳು ಬಾಕಿ ಉಳಿದಿವೆ",
    SPILT_ORDERS_RECEIVED_FROM_FACTORY: "ಕಾರ್ಖಾನೆಯಿಂದ ಪಡೆದ ಚೆಲ್ಲಿದ ಆದೇಶಗಳು",
    PENDING_FOR_NO_OF_DAYS: "ಯಾವುದೇ ದಿನಗಳ ಬಾಕಿ ಉಳಿದಿದೆ",
    STORE_GARMENT_INVENTORY: "ಗಾರ್ಮೆಂಟ್ ಇನ್ವೆಂಟರಿ ಸಂಗ್ರಹಿಸಿ",
    GARMENTS_RECEIVED_FROM_FACTORY: "ಕಾರ್ಖಾನೆಯಿಂದ ಪಡೆದ ಉಡುಪುಗಳು",
    FACTORY_TRANSFER_IN: "ಫ್ಯಾಕ್ಟರಿ ವರ್ಗಾವಣೆ",
    SELECT_STORE: "ಅಂಗಡಿ ಆಯ್ಕೆಮಾಡಿ",
    GET_MISSING_GARMENTS: "ಕಾಣೆಯಾದ ಉಡುಪುಗಳನ್ನು ಪಡೆಯಿರಿ",
    TAGS_FROM_STORES: "ಅಂಗಡಿಗಳಿಂದ ಟ್ಯಾಗ್‌ಗಳು",
    SCANNING: "ಸ್ಕ್ಯಾನಿಂಗ್",
    TAGS_TO_THE_FACTORY: "ಕಾರ್ಖಾನೆಗೆ ಟ್ಯಾಗ್ಗಳು",
    MISSING_GARMENTS: "ಕಾಣೆಯಾದ ಉಡುಪುಗಳು",
    SCAN_COMPLETE: "ಸ್ಕ್ಯಾನ್ ಪೂರ್ಣಗೊಂಡಿದೆ",
    SEARCH_HERE: "ಇಲ್ಲಿ ಹುಡುಕಿ",
    RESORTING_AND_PACKAGING: "ರೆಸಾರ್ಟಿಂಗ್ ಮತ್ತು ಪ್ಯಾಕೇಜಿಂಗ್",
    ORDER_NUMBER: "ಆದೇಶ ಸಂಖ್ಯೆ",
    SRORE_NAME: "Srore ಹೆಸರು",
    NO_OF_GARMENTS: "ಉಡುಪುಗಳ ಸಂಖ್ಯೆ",
    GARMENT_LEVEL_INFO: "ಗಾರ್ಮೆಂಟ್ ಮಟ್ಟದ ಮಾಹಿತಿ",
    SELECTED_PENDING_GARMENT: "ಆಯ್ದ ಬಾಕಿ ಉಡುಪು",
    PREVIOUSLY_GARMENTS_PACKED_IN: "ಹಿಂದೆ ಗಾರ್ಮೆಂಟ್ಸ್ ಪ್ಯಾಕ್ ಮಾಡಲಾಗಿದೆ",
    PENDING_GARMENTS_TO_BE_PACKED_IN: "ಪ್ಯಾಕ್ ಮಾಡಲು ಉಡುಪುಗಳು ಬಾಕಿ ಉಳಿದಿವೆ",
    GENERATE_STICKER: "ಸ್ಟಿಕ್ಕರ್ ರಚಿಸಿ",
    PRINT_GENERATE_STICKER: "ಮುದ್ರಣ ರಚಿಸುವ ಸ್ಟಿಕ್ಕರ್",
    SR_NO: "ಸೀನಿಯರ್ ನಂ",
    GARMENTS_PACKED: "ಗಾರ್ಮೆಂಟ್ಸ್ ಪ್ಯಾಕ್ ಮಾಡಲಾಗಿದೆ",
    PENDING_GARMENTS: "ಉಡುಪುಗಳು ಬಾಕಿ ಉಳಿದಿವೆ",
    PACKING_GARMENTS: "ಪ್ಯಾಕಿಂಗ್ ಗಾರ್ಮೆಂಟ್ಸ್",
    FACTORY_TRANSFER_OUT: "ಫ್ಯಾಕ್ಟರಿ ವರ್ಗಾವಣೆ .ಟ್",
    FACTORY_GATE_PASS_NUMBER: "ಫ್ಯಾಕ್ಟರಿ ಗೇಟ್ ಪಾಸ್ ಸಂಖ್ಯೆ:",
    STORE_ADDRESS: "ಅಂಗಡಿ ವಿಳಾಸ",
    CREATED_BY: "ರಚಿಸಿದವರು",
    S_NO: "ಎಸ್. ಇಲ್ಲ",
    SPLIT_ORDER_ID: "ಸ್ಪ್ಲಿಟ್ ಆರ್ಡರ್ ಐಡಿ",
    NUMBER_OF_GARMENTS: "ಉಡುಪುಗಳ ಸಂಖ್ಯೆ",
    PACKED: "ಕಟ್ಟಿ ಇಡುವುದು",
    ORDER_LOT_SIZE: "ಲಾಟ್ ಗಾತ್ರವನ್ನು ಆದೇಶಿಸಿ",
    PREVIOUSLY_SENT: "ಹಿಂದೆ ಕಳುಹಿಸಲಾಗಿದೆ",
    SENDING_TODAY: "ಇಂದು ಕಳುಹಿಸಲಾಗುತ್ತಿದೆ",
    PENDING: "ಬಾಕಿ ಉಳಿದಿದೆ",
    BAG: "ಚೀಲ",
    HANGER: "ಹ್ಯಾಂಗರ್",
    BLANKET: "ಕಂಬಳಿ",
    SHOE: "ಶೂ",
    CARPET: "ಕಾರ್ಪೆಟ್",
    BLAZER: "ಬ್ಲೇಜರ್",
    OTHERS: "ಇತರರು",
    STICKER_NO: "ಸ್ಟಿಕ್ಕರ್ ಸಂಖ್ಯೆ",
    SPLLIT_ORDER_NO: "ಸ್ಪ್ಲಿಟ್ ಆದೇಶ ಸಂಖ್ಯೆ",
    PACKING_MATERIAL_NAME: "ಪ್ಯಾಕಿಂಗ್ ಮೆಟೀರಿಯಲ್ ಹೆಸರು",
    PRE_QC_RESPONSE_LIST: "ಪೂರ್ವ ಕ್ಯೂಸಿ ಪ್ರತಿಕ್ರಿಯೆ ಪಟ್ಟಿ",
    SELECT_QC_STATUS: "ಕ್ಯೂಸಿ ಸ್ಥಿತಿ ಆಯ್ಕೆಮಾಡಿ",
    TAG: "ಟ್ಯಾಗ್",
    CONTACT: "ಸಂಪರ್ಕಿಸಿ",
    QC_REQUEST: "ಕ್ಯೂಸಿ ವಿನಂತಿ",
    TIME: "ಸಮಯ",
    QC_REASON: "ಕ್ಯೂಸಿ ಕಾರಣ",
    REMARK: "ಟೀಕೆ",
    QC_PENDING_FROM: "ಕ್ಯೂಸಿ ಬಾಕಿ ಉಳಿದಿದೆ",
    QC_STATUS: "ಕ್ಯೂಸಿ ಸ್ಥಿತಿ",
    ENTER_SCAN_TAG_NUMBER: "ಟ್ಯಾಗ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ / ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
    GARMENT_HISTORY: "ಗಾರ್ಮೆಂಟ್ ಇತಿಹಾಸ",
    GARMENT_INFORMATION: "ಉಡುಪು ಮಾಹಿತಿ",
    GARMENT_STATUS: "ಉಡುಪು ಸ್ಥಿತಿ",
    GATE_PASS: "ಗೇಟ್ ಪಾಸ್",
    BRAND: "ಬ್ರಾಂಡ್",
    COLOR: "ಬಣ್ಣ",
    ADD_ON_SERVICE: "ಸೇವೆಯನ್ನು ಸೇರಿಸಿ",
    SPECIAL_INSTRUCTION: "ವಿಶೇಷ ಸೂಚನೆ",
    LATEST_QC_DETAILS: "ಇತ್ತೀಚಿನ ಕ್ಯೂಸಿ ವಿವರಗಳು",
    IMAGES: "ಚಿತ್ರಗಳು",
    REASON: "ಕಾರಣ",
    SELECT_REASON: "ಕಾರಣವನ್ನು ಆರಿಸಿ",
    COMMUNICATION_HISTORY: "ಸಂವಹನ ಇತಿಹಾಸ",
    SELECT_SCAN_GARMENT: "ಉಡುಪನ್ನು ಆಯ್ಕೆ ಮಾಡಿ / ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
    GARMENT_FOR_QC: "ಕ್ಯೂಸಿಗೆ ಉಡುಪು",
    SCANNED_ITEM: "ಸ್ಕ್ಯಾನ್ ಮಾಡಿದ ಐಟಂ",
    ADDON_SERVICE: "ಆಡ್ಆನ್ ಸೇವೆ",
    CONTACT_NO: "ಸಂಪರ್ಕ ಸಂಖ್ಯೆ",
    STICKER_INFORMATION: "ಸ್ಟಿಕ್ಕರ್ ಮಾಹಿತಿ",
    DOWNLOAD_GATE_PASS: "ಗೇಟ್ ಪಾಸ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    STORE_GATE_PASS_NUMBER: "ಗೇಟ್ ಪಾಸ್ ಸಂಖ್ಯೆ ಸಂಗ್ರಹಿಸಿ",
    FACTORY_ADDRESS: "ಕಾರ್ಖಾನೆ ವಿಳಾಸ",
    PICKUP_DATE: "ಎತ್ತಿಕೊಳ್ಳುವ ದಿನಾಂಕ",
    REFFERAL_PROGRAM_NAME: "ರೆಫರಲ್ ಪ್ರೋಗ್ರಾಂ ಹೆಸರು",
    REFFERAL_BONUS_AMOUNT: "ರೆಫರಲ್ ಬೋನಸ್ ಮೊತ್ತ",
    REFEREE_BONUS_AMOUNT: "ತೀರ್ಪುಗಾರರ ಬೋನಸ್ ಮೊತ್ತ",
    YOU_CAN_ONLY_UPDATE_THE_LAST_ADDED_PROGRAM:
      "ನೀವು ಕೊನೆಯದಾಗಿ ಸೇರಿಸಿದ ಪ್ರೋಗ್ರಾಂ ಅನ್ನು ಮಾತ್ರ ನವೀಕರಿಸಬಹುದು",
    UNABLE_TO_GET_LIST_OF_REFFERAL_BONUS_CONFIGURATION_DATA:
      "ರೆಫರಲ್ ಬೋನಸ್ ಕಾನ್ಫಿಗರೇಶನ್ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_SUBMIT_THE_DATA: "ಡೇಟಾವನ್ನು ಸಲ್ಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    ADD_REFFERAL_BONUS_CONFIGURATION: "ರೆಫರಲ್ ಬೋನಸ್ ಕಾನ್ಫಿಗರೇಶನ್ ಸೇರಿಸಿ",
    VIEW_REFFERAL_BONUS_CONFIGURATION: "ರೆಫರಲ್ ಬೋನಸ್ ಕಾನ್ಫಿಗರೇಶನ್ ವೀಕ್ಷಿಸಿ",
    EDIT_REFFERAL_BONUS_CONFIGURATION:
      "ರೆಫರಲ್ ಬೋನಸ್ ಕಾನ್ಫಿಗರೇಶನ್ ಅನ್ನು ಸಂಪಾದಿಸಿ",
    PLEASE_ENTER_REFFERAL_PROGRAM_NAME:
      "ದಯವಿಟ್ಟು ರೆಫರಲ್ ಪ್ರೋಗ್ರಾಂ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    ENTER_REFFERAL_PROGRAM_NAME: "ರೆಫರಲ್ ಪ್ರೋಗ್ರಾಂ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    PLEASE_ENTER_REFERRAL_BONUS_AMOUNT:
      "ದಯವಿಟ್ಟು ರೆಫರಲ್ ಬೋನಸ್ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    ENTER_REFERRAL_BONUS_AMOUNT: "ರೆಫರಲ್ ಬೋನಸ್ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    PLEASE_ENTER_REFEREE_BONUS_AMOUNT:
      "ದಯವಿಟ್ಟು ತೀರ್ಪುಗಾರರ ಬೋನಸ್ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    ENTER_REFEREE_BONUS_AMOUNT: "ತೀರ್ಪುಗಾರರ ಬೋನಸ್ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    ENTER_BONUS_AMOUNT_VALIDITY_DAYS:
      "ಬೋನಸ್ ಮೊತ್ತದ ಮಾನ್ಯತೆಯನ್ನು ನಮೂದಿಸಿ (ದಿನಗಳು)",
    ARE_YOU_SURE_YOU_WANT_TO_CREATE_THIS_PROGRAM_AS_PREVIOUS_PROGRAMS_WILL_GET_DEACTIVATED:
      "ಹಿಂದಿನ ಪ್ರೋಗ್ರಾಂಗಳು ನಿಷ್ಕ್ರಿಯಗೊಳ್ಳುವುದರಿಂದ ಈ ಪ್ರೋಗ್ರಾಂ ಅನ್ನು ರಚಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
    FILE_NAME: "ಕಡತದ ಹೆಸರು",
    TOTAL_RECORDS: "ಒಟ್ಟು ದಾಖಲೆಗಳು",
    SUCCEEDED: "ಯಶಸ್ವಿಯಾಗಿದೆ",
    DOWNLOAD: "ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    FAILED: "ವಿಫಲವಾಗಿದೆ",
    UNABLE_TO_GET_LIST_OF_EMPLOYEE_CREDIT_DATA:
      "ನೌಕರರ ಕ್ರೆಡಿಟ್ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_UPLOAD_THE_FILE: "ಫೈಲ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    DOWNLOAD_FORMAT: "ಫಾರ್ಮ್ಯಾಟ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
    UPLOAD_FILE: "ಫೈಲ್ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    ADD_EMPLOYEE_CREDITS: "ನೌಕರರ ಸಾಲಗಳನ್ನು ಸೇರಿಸಿ",
    VIEW_EMPLOYEE_CREDITS: "ನೌಕರರ ಸಾಲಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
    EDIT_EMPLOYEE_CREDITS: "ನೌಕರರ ಸಾಲಗಳನ್ನು ಸಂಪಾದಿಸಿ",
    UPLOAD_EMPLOYEE_CREDIT_CONFIGURATION_FILE:
      "ನೌಕರರ ಕ್ರೆಡಿಟ್ ಕಾನ್ಫಿಗರೇಶನ್ ಫೈಲ್ ಅನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    PLEASE_SELECT_FILE: "ದಯವಿಟ್ಟು ಫೈಲ್ ಆಯ್ಕೆಮಾಡಿ",
    CLICK_TO_UPLOAD: "ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
    UPLOAD: "ಅಪ್‌ಲೋಡ್ ಮಾಡಿ",
    UNABLE_TO_LOAD_PACKAGES: "ಪ್ಯಾಕೇಜ್‌ಗಳನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_CUSTOMERS: "ಗ್ರಾಹಕರ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED:
      "ವಹಿವಾಟನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲಾಗಿದೆ",
    UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN:
      "ವಹಿವಾಟನ್ನು ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM:
      "ಪ್ರಕ್ರಿಯೆಗೊಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ. ದಯವಿಟ್ಟು ತಾಂತ್ರಿಕ ತಂಡವನ್ನು ಸಂಪರ್ಕಿಸಿ",
    PACKAGE_PURCHASED_SUCCESSFULLY: "ಪ್ಯಾಕೇಜ್ ಯಶಸ್ವಿಯಾಗಿ ಖರೀದಿಸಲಾಗಿದೆ",
    UNABLE_TO_BUY_PACKAGE: "ಪ್ಯಾಕೇಜ್ ಖರೀದಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    BUY: "ಖರೀದಿಸಿ",
    PLEASE_SELECT_CUSTOMER: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕರನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    SEARCH_CUSTOMER: "ಗ್ರಾಹಕರನ್ನು ಹುಡುಕಿ",
    MONEY_TYPE: "ಹಣದ ಪ್ರಕಾರ",
    ADDED_BY: "ಇವರಿಂದ ಸೇರಿಸಲಾಗಿದೆ",
    ADDED_ON: "ಆನ್ ಸೇರಿಸಲಾಗಿದೆ",
    UNABLE_TO_GET_LIST_OF_ADVANCE_PAYMENT_DATA:
      "ಮುಂಗಡ ಪಾವತಿ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    ADVANCE_COLLECTED_SUCCESSFULLY: "ಮುಂಗಡವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಂಗ್ರಹಿಸಲಾಗಿದೆ",
    UNABLE_TO_ADD_AMOUNT_TO_LKWALLET:
      "LKWallet ಗೆ ಮೊತ್ತವನ್ನು ಸೇರಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    ADD_ADVANCE_PAYMENT: "ಮುಂಗಡ ಪಾವತಿ ಸೇರಿಸಿ",
    VALID_FROM: "ನಿಂದ ಮಾನ್ಯವಾಗಿದೆ",
    VALID_TO: "ಗೆ ಮಾನ್ಯವಾಗಿದೆ",
    UNABLE_TO_GET_LIST_OF_COMPENSATION_CONFIGURATION_DATA:
      "ಪರಿಹಾರ ಸಂರಚನಾ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_ADD_THE_DATA: "ಡೇಟಾವನ್ನು ಸೇರಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    COMPENSATION_CONFIGURATION: "ಪರಿಹಾರ ಸಂರಚನೆ",
    ADD_COMPENSATION_CONFIGURATION: "ಪರಿಹಾರ ಸಂರಚನೆಯನ್ನು ಸೇರಿಸಿ",
    VIEW_COMPENSATION_CONFIGURATION: "ಪರಿಹಾರ ಸಂರಚನೆಯನ್ನು ವೀಕ್ಷಿಸಿ",
    EDIT_COMPENSATION_CONFIGURATION: "ಪರಿಹಾರ ಸಂರಚನೆಯನ್ನು ಸಂಪಾದಿಸಿ",
    VALID_FROM_AND_TO_DATE: "ದಿನಾಂಕ ಮತ್ತು ದಿನಾಂಕದವರೆಗೆ ಮಾನ್ಯವಾಗಿದೆ",
    PLEASE_SELECT_VALID_FROM_AND_TO_DATE:
      "ದಯವಿಟ್ಟು ದಿನಾಂಕದಿಂದ ಮಾನ್ಯವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    PLEASE_ENTER_REMARK: "ದಯವಿಟ್ಟು ಟಿಪ್ಪಣಿ ನಮೂದಿಸಿ",
    ENTER_REMARK: "ಟಿಪ್ಪಣಿ ನಮೂದಿಸಿ",
    UNABLE_TO_GET_LIST_OF_REFUND_DATA:
      "ಮರುಪಾವತಿ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_ADD_REFUND_DATA: "ಮರುಪಾವತಿ ಡೇಟಾವನ್ನು ಸೇರಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    ADD_REFUND: "ಮರುಪಾವತಿಯನ್ನು ಸೇರಿಸಿ",
    VIEW_REFUND: "ಮರುಪಾವತಿಯನ್ನು ವೀಕ್ಷಿಸಿ",
    DATA_SUCCESSFULLY_SUBMITTED: "ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ",
    UNABLE_TO_SUBMIT_TAG_DATA: "ಟ್ಯಾಗ್ ಡೇಟಾವನ್ನು ಸಲ್ಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_LOAD_TAG_DATA: "ಟ್ಯಾಗ್ ಡೇಟಾವನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_TAG_DATA: "ಟ್ಯಾಗ್ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    PLEASE_ENTER_TAG_NO: "ದಯವಿಟ್ಟು ಟ್ಯಾಗ್ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ",
    FACTORY_POST_QC: "ಫ್ಯಾಕ್ಟರಿ ನಂತರದ ಕ್ಯೂಸಿ",
    PLEASE_SCAN_ADD_TAG_NUMBER: "ಟ್ಯಾಗ್ ಸಂಖ್ಯೆಯನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ / ಸೇರಿಸಿ",
    SELECT_STATUS: "ಸ್ಥಿತಿ ಆಯ್ಕೆಮಾಡಿ",
    PLEASE_ENTER_REMARKS: "ದಯವಿಟ್ಟು ಟೀಕೆಗಳನ್ನು ನಮೂದಿಸಿ",
    UNABLE_TO_LOAD_PRE_QC_RESPONSES:
      "ಪೂರ್ವ-ಕ್ಯೂಸಿ ಪ್ರತಿಕ್ರಿಯೆಗಳನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    FACTORY_PRE_QC: "ಫ್ಯಾಕ್ಟರಿ ಪೂರ್ವ ಕ್ಯೂಸಿ",
    ADD_GARMENT_FOR_QC: "ಕ್ಯೂಸಿಗೆ ಗಾರ್ಮೆಂಟ್ ಸೇರಿಸಿ",
    DATA_IS_SUCCESSFULLY_SAVED: "ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ",
    GARMENT_SHOULD_BE_IN_FACTORY_TO_BE_ADDED_FOR_QC:
      "ಕ್ಯೂಸಿಗೆ ಸೇರಿಸಲು ಗಾರ್ಮೆಂಟ್ ಕಾರ್ಖಾನೆಯಲ್ಲಿರಬೇಕು",
    UNABLE_TO_GET_GARMENT_DETAILS: "ಉಡುಪಿನ ವಿವರಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    GET_GARMENT_HISTORY: "ಗಾರ್ಮೆಂಟ್ ಇತಿಹಾಸವನ್ನು ಪಡೆಯಿರಿ",
    PLEASE_SELECT_REASON: "ದಯವಿಟ್ಟು ಕಾರಣವನ್ನು ಆರಿಸಿ",
    UNABLE_TO_GET_CORPORATE_DATA: "ಕಾರ್ಪೊರೇಟ್ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    GARMENTS_AT_QC: "ಕ್ಯೂಸಿಯಲ್ಲಿ ಉಡುಪುಗಳು",
    GARMENTS_IN_POST_QC: "ಪೋಸ್ಟ್ ಕ್ಯೂಸಿಯಲ್ಲಿ ಉಡುಪುಗಳು",
    UNABLE_TO_GET_FACTORY_GARMENTS_INVENTORY_DATA:
      "ಫ್ಯಾಕ್ಟರಿ ಗಾರ್ಮೆಂಟ್ಸ್ ಇನ್ವೆಂಟರಿ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    GARMENT_INVENTORY_AT_FACTORY: "ಕಾರ್ಖಾನೆಯಲ್ಲಿ ಗಾರ್ಮೆಂಟ್ ಇನ್ವೆಂಟರಿ",
    DASHBOARD: "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್",
    CUSTOMERS: "ಗ್ರಾಹಕರು",
    MANAGE_CUSTOMERS: "ಗ್ರಾಹಕರನ್ನು ನಿರ್ವಹಿಸಿ",
    PACKAGE_CONFIGURATION: "ಪ್ಯಾಕೇಜ್ ಸಂರಚನೆ",
    REFERRAL_BONUS_CONFIGURATION: "ರೆಫರಲ್ ಬೋನಸ್ ಕಾನ್ಫಿಗರೇಶನ್",
    EMPLOYEE_CREDITS: "ನೌಕರರ ಸಾಲಗಳು",
    BUY_PACKAGE: "ಪ್ಯಾಕೇಜ್ ಖರೀದಿಸಿ",
    ADVANCE_PAYMENTS: "ಮುಂಗಡ ಪಾವತಿಗಳು",
    COMPENSATION: "ಪರಿಹಾರ",
    REFUND: "ಮರುಪಾವತಿ",
    MASTER: "ಮಾಸ್ಟರ್",
    STORE: "ಅಂಗಡಿ",
    FACTORY: "ಕಾರ್ಖಾನೆ",
    CORPORATE: "ಕಾರ್ಪೊರೇಟ್",
    CITY: "ನಗರ",
    STATE: "ರಾಜ್ಯ",
    DESIGNATION: "ಹುದ್ದೆ",
    GARMENT_BRAND: "ಗಾರ್ಮೆಂಟ್ ಬ್ರಾಂಡ್",
    GARMENT_SIZE: "ಉಡುಪಿನ ಗಾತ್ರ",
    KYC_DOCUMENT_TYPE: "ಕೆವೈಸಿ ಡಾಕ್ಯುಮೆಂಟ್ ಪ್ರಕಾರ",
    SERVICE: "ಸೇವೆ",
    GARMENT: "ಉಡುಪು",
    REWASH_REASON: "ರಿವಾಶ್ ಕಾರಣ",
    OPERATION_INSTRUCTION: "ಕಾರ್ಯಾಚರಣೆ ಸೂಚನೆ",
    EXPENSE_TYPE: "ಖರ್ಚು ಪ್ರಕಾರ",
    ORDERS: "ಆದೇಶಗಳು",
    PICKUPS: "ಪಿಕಪ್ಗಳು",
    MANAGE_ORDERS: "ಆದೇಶಗಳನ್ನು ನಿರ್ವಹಿಸಿ",
    READY_FOR_DELIVERY_OR_INVOICE: "ವಿತರಣೆ / ಸರಕುಪಟ್ಟಿ ಸಿದ್ಧ",
    DELIVERIES: "ವಿತರಣೆಗಳು",
    TRANSFER_IN_OUT: "ಇನ್ / .ಟ್ ವರ್ಗಾವಣೆ",
    TRANSFER_OUT: "ವರ್ಗಾವಣೆ .ಟ್",
    GATE_PASS_LIST: "ಗೇಟ್ ಪಾಸ್ ಪಟ್ಟಿ",
    TRANSFER_IN: "ಸೈನ್ ಇನ್ ಮಾಡಿ",
    PRE_QC_RESPONSES: "ಪೂರ್ವ ಕ್ಯೂಸಿ ಪ್ರತಿಕ್ರಿಯೆ",
    USER_EXPENSES: "ಬಳಕೆದಾರರ ವೆಚ್ಚಗಳು",
    INVENTORY: "ದಾಸ್ತಾನು",
    SETTINGS: "ಸಂಯೋಜನೆಗಳು",
    AUTHENTICATION: "ದೃ ation ೀಕರಣ",
    USERS: "ಬಳಕೆದಾರರು",
    ROLES: "ಪಾತ್ರಗಳು",
    ROLE_OPERATIONS: "ಪಾತ್ರ ಕಾರ್ಯಾಚರಣೆಗಳು",
    CONFIGURATION: "ಸಂರಚನೆ",
    TIME_CARD: "ಸಮಯ ಕಾರ್ಡ್",
    RATE_CARD: "ದರದ ಚೀಟಿ",
    DISCOUNT: "ರಿಯಾಯಿತಿ",
    CASH_COUPON: "ನಗದು ಕೂಪನ್",
    PACKAGING_CHARGES: "ಪ್ಯಾಕೇಜಿಂಗ್ ಶುಲ್ಕಗಳು",
    DELIVERY_CHARGES: "ವಿತರಣಾ ಶುಲ್ಕಗಳು",
    BANNER: "ಬ್ಯಾನರ್",
    NOTICE_BOARD: "ಸೂಚನಾ ಫಲಕ",
    FINGER_SCAN: "ಫಿಂಗರ್ ಸ್ಕ್ಯಾನ್",
    COLLECT_FINGERPRINTS: "ಫಿಂಗರ್‌ಪ್ರಿಂಟ್‌ಗಳನ್ನು ಸಂಗ್ರಹಿಸಿ",
    QC_RESPONSES: "ಕ್ಯೂಸಿ ಪ್ರತಿಕ್ರಿಯೆಗಳು",
    PRE_QC: "ಪೂರ್ವ ಕ್ಯೂಸಿ",
    POST_QC: "ಕ್ಯೂಸಿ ಪೋಸ್ಟ್ ಮಾಡಿ",
    TODAYS_HIGHLIGHTS: "ಇಂದಿನ ಮುಖ್ಯಾಂಶಗಳು",
    ORDER_VS_ORDER_AMOUNT: "ಆದೇಶಗಳು / ಆದೇಶ ಮೊತ್ತ",
    INVOICE_VS_INVOICE_AMOUNT: "ಸರಕುಪಟ್ಟಿ / ಸರಕುಪಟ್ಟಿ ಮೊತ್ತ",
    STORE_INVENTORY: "ದಾಸ್ತಾನು ಸಂಗ್ರಹಿಸಿ",
    OTHER_STATASTICS: "ಇತರ ಅಂಕಿಅಂಶಗಳು",
    PROCESSED: "ಸಂಸ್ಕರಿಸಲಾಗಿದೆ",
    SOILED: "ಮಣ್ಣಾದ",
    AMOUNT_DUE_FOR_COLLECTION: "ಸಂಗ್ರಹಣೆಗೆ ಬಾಕಿ ಇರುವ ಮೊತ್ತ",
    ORDERS_READY_FOR_INVOICE: "ಸರಕುಪಟ್ಟಿ ಸಿದ್ಧವಾಗಿದೆ",
    DELIVERY_PERSON: "ವಿತರಣಾ ವ್ಯಕ್ತಿ",
    WALK_IN: "ಒಳಗೆ ನಡೆದು",
    PENDING_TO_TRANSFER_OUT_TO_FACTORY: "ಕಾರ್ಖಾನೆಗೆ ವರ್ಗಾಯಿಸಲು ಬಾಕಿ ಇದೆ",
    NO_OF_ORDERS: "ಆದೇಶಗಳ ಸಂಖ್ಯೆ",
    PENDING_PICKUPS: "ಪಿಕಪ್‌ಗಳು ಬಾಕಿ ಉಳಿದಿವೆ",
    DATE: "ದಿನಾಂಕ",
    ORDERS_DUE_FOR_DELIVERY: "ವಿತರಣೆಗೆ ಕಾರಣ ಆದೇಶಗಳು",
    FIRST_NAME: "ಮೊದಲ ಹೆಸರು",
    LAST_NAME: "ಕೊನೆಯ ಹೆಸರು",
    GENDER: "ಲಿಂಗ",
    EMAIL: "ಇಮೇಲ್",
    PHONE: "ದೂರವಾಣಿ",
    TYPE: "ಮಾದರಿ",
    BILLING_CYCLE: "ಬೈಲಿಂಗ್ ಸೈಕಲ್",
    ACTIONS: "ಕ್ರಿಯೆಗಳು",
    VIEW: "ನೋಟ",
    EDIT: "ತಿದ್ದು",
    DELETE: "ಅಳಿಸಿ",
    SEARCH: "ಹುಡುಕಿ ",
    ADD_CUSTOMER: "ಗ್ರಾಹಕರನ್ನು ಸೇರಿಸಿ",
    ADD: "ಸೇರಿಸಿ",
    CUSTOMER: "ಗ್ರಾಹಕ",
    WALLET_CASH: "ವಾಲೆಟ್ ನಗದು",
    WALLET_BONUS: "ವಾಲೆಟ್ ಬೋನಸ್",
    GENERAL: "ಜನರಲ್",
    GENERAL_CUSTOMER_INFORMATION: "ಸಾಮಾನ್ಯ ಗ್ರಾಹಕ ಮಾಹಿತಿ",
    ADDRESS: "ವಿಳಾಸ",
    CUSTOMER_ADDRESSES: "ಗ್ರಾಹಕ ವಿಳಾಸಗಳು",
    VERIFICATION: "ಪರಿಶೀಲನೆ",
    VERIFY_PHONE_AND_EMAIL: "ವರ್ಫಿ ಫೋನ್ ಮತ್ತು ಇಮೇಲ್",
    PLEASE: "ದಯವಿಟ್ಟು",
    SELECT: "ಆಯ್ಕೆ ಮಾಡಿ",
    ENTER: "ನಮೂದಿಸಿ",
    VALID: "ಮಾನ್ಯ",
    PHONE_NUMBER: "ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
    DATE_OF_BIRTH: "ಹುಟ್ತಿದ ದಿನ",
    ANNIVERSARY_DATE: "ವಾರ್ಷಿಕೋತ್ಸವ ದಿನಾಂಕ",
    REFERRAL_CODE: "ರೆಫರಲ್ ಕೋಡ್",
    CUSTOMER_TYPE: "ಗ್ರಾಹಕ ಪ್ರಕಾರ",
    GSTIN: "ಜಿಎಸ್ಟಿಎನ್",
    ACTIVE: "ಸಕ್ರಿಯ",
    STATUS: "ಸ್ಥಿತಿ",
    YES: "ಹೌದು",
    NO: "ಇಲ್ಲ",
    CANCEL: "ರದ್ದುಮಾಡಿ",
    SUBMIT: "ಸಲ್ಲಿಸು",
    ID: "ಐಡಿ",
    PINCODE: "ಪಿನ್ಕೋಡ್",
    ADRESSES_OF: "ನ ವಿಳಾಸಗಳು",
    HOME: "ಮನೆ",
    OFFICE: "ಕಚೇರಿ",
    OTHER: "ಇತರೆ",
    LONGITUDE: "ರೇಖಾಂಶ",
    LATITUDE: "ಅಕ್ಷಾಂಶ",
    IS_DEFAULT: "ಇದನ್ನು ಡೀಫಾಲ್ಟ್ ಆಗಿ ಹೊಂದಿಸಿ",
    PLEASE_ENTER_FIRST_NAME: "ದಯವಿಟ್ಟು ಮೊದಲ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    ENTER_FIRST_NAME: "ಮೊದಲ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    PLEASE_ENTER_LAST_NAME: "ದಯವಿಟ್ಟು ಕೊನೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    ENTER_LAST_NAME: "ಕೊನೆಯ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    PLEASE_ENTER_EMAIL: "ದಯವಿಟ್ಟು ಇಮೇಲ್ ನಮೂದಿಸಿ",
    PLEASE_ENTER_VALID_EMAIL: "ದಯವಿಟ್ಟು ಮಾನ್ಯ ಇಮೇಲ್ ನಮೂದಿಸಿ",
    ENTER_EMAIL: "ಇಮೇಲ್ ನಮೂದಿಸಿ",
    PLEASE_ENTER_PHONE_NUMBER: "ದಯವಿಟ್ಟು ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    PLEASE_ENTER_VALID_PHONE_NUMBER: "ದಯವಿಟ್ಟು ಮಾನ್ಯ ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    ENTER_PHONE_NUMBER: "ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    PLEASE_SELECT_GENDER: "ದಯವಿಟ್ಟು ಲಿಂಗ ಆಯ್ಕೆಮಾಡಿ",
    SELECT_DATE: "ದಿನಾಂಕ ಆಯ್ಕೆಮಾಡಿ",
    ENTER_REFERRAL_CODE: "ರೆಫರಲ್ ಕೋಡ್ ನಮೂದಿಸಿ",
    PLEASE_SELECT_CUSTOMER_TYPE: "ದಯವಿಟ್ಟು ಗ್ರಾಹಕ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    SELECT_CUSTOMER_TYPE: "ಗ್ರಾಹಕ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    SELECT_CORPORATE: "ಕಾರ್ಪೊರೇಟ್ ಆಯ್ಕೆಮಾಡಿ",
    PLEASE_SELECT_BILLING_CYCLE: "ದಯವಿಟ್ಟು ಬಿಲ್ಲಿಂಗ್ ಸೈಕಲ್ ಆಯ್ಕೆಮಾಡಿ",
    SELECT_BILLING_CYCLE: "ಬಿಲ್ಲಿಂಗ್ ಸೈಕಲ್ ಆಯ್ಕೆಮಾಡಿ",
    ENTER_VALID_GSTIN: "ಮಾನ್ಯ GSTIN ಅನ್ನು ನಮೂದಿಸಿ",
    ENTER_GSTIN: "GSTIN ಅನ್ನು ನಮೂದಿಸಿ",
    PLEASE_SELECT_STATUS: "ದಯವಿಟ್ಟು ಸ್ಥಿತಿ ಆಯ್ಕೆಮಾಡಿ",
    ADD_ADDRESS: "ವಿಳಾಸವನ್ನು ಸೇರಿಸಿ",
    VIEW_ADDRESS: "ವಿಳಾಸವನ್ನು ವೀಕ್ಷಿಸಿ",
    EDIT_ADDRESS: "ವಿಳಾಸವನ್ನು ಸಂಪಾದಿಸಿ",
    PLEASE_SELECT_ADDRESS_TYPE: "ದಯವಿಟ್ಟು ವಿಳಾಸ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    SELECT_TYPE: "ಪ್ರಕಾರವನ್ನು ಆರಿಸಿ",
    ENTER_LONGITUDE: "ರೇಖಾಂಶವನ್ನು ನಮೂದಿಸಿ",
    ENTER_LATITUDE: "ಅಕ್ಷಾಂಶವನ್ನು ನಮೂದಿಸಿ",
    PLEASE_SELECT_STATE: "ದಯವಿಟ್ಟು ರಾಜ್ಯವನ್ನು ಆರಿಸಿ",
    SELECT_STATE: "ರಾಜ್ಯವನ್ನು ಆರಿಸಿ",
    PLEASE_SELECT_CITY: "ದಯವಿಟ್ಟು ನಗರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    SELECT_CITY: "ನಗರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    PLEASE_ENTER_PINCODE: "ದಯವಿಟ್ಟು ಪಿನ್‌ಕೋಡ್ ನಮೂದಿಸಿ",
    ENTER_PINCODE: "ಪಿನ್ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
    HOUSE_NO: "ಮನೆ ಸಂಖ್ಯೆ / ಫ್ಲಾಟ್ ಸಂಖ್ಯೆ / ಫ್ಲೋರ್ / ಸ್ಟ್ರೀಟ್",
    PLEASE_ENTER_HOUSE_NO:
      "ದಯವಿಟ್ಟು ಮನೆ ಸಂಖ್ಯೆ / ಫ್ಲಾಟ್ ಸಂಖ್ಯೆ / ಫ್ಲೋರ್ / ಸ್ಟ್ರೀಟ್ ಅನ್ನು ನಮೂದಿಸಿ",
    ENTER_HOUSE_NO:
      "ಮನೆ ಸಂಖ್ಯೆ / ಫ್ಲಾಟ್ ಸಂಖ್ಯೆ / ಫ್ಲೋರ್ / ಸ್ಟ್ರೀಟ್ ಅನ್ನು ನಮೂದಿಸಿ",
    LANDMARK: "ಹೆಗ್ಗುರುತು",
    ENTER_LANDMARK: "ಹೆಗ್ಗುರುತನ್ನು ನಮೂದಿಸಿ",
    HOW_TO_REACH: "ತಲುಪುವುದು ಹೇಗೆ",
    ENTER_HOW_TO_REACH: "ತಲುಪುವುದು ಹೇಗೆ ಎಂದು ನಮೂದಿಸಿ",
    PLEASE_ENTER_ADDRESS: "ದಯವಿಟ್ಟು ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
    ENTER_ADDRESS: "ವಿಳಾಸವನ್ನು ನಮೂದಿಸಿ",
    UPDATE: "ನವೀಕರಿಸಿ",
    PLEASE_ENTER_OTP: "ದಯವಿಟ್ಟು ಒಟಿಪಿ ನಮೂದಿಸಿ",
    ENTER_OTP: "ಒಟಿಪಿ ನಮೂದಿಸಿ",
    VERIFY: "ಪರಿಶೀಲಿಸಿ",
    DIDNT_RECIEVED_ANYTHIG: "ಏನನ್ನೂ ಸ್ವೀಕರಿಸಲಿಲ್ಲವೇ?",
    RESEND_OTP: "ಒಟಿಪಿಯನ್ನು ಮರುಹೊಂದಿಸಿ",
    NOT_VERIFIED: "ಪರಿಶೀಲಿಸಿಲ್ಲ",
    VERIFY_PHONE_NUMBER: "ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ಪರಿಶೀಲಿಸಿ",
    VERIFIED: "ಪರಿಶೀಲಿಸಲಾಗಿದೆ",
    PACKAGE_NAME: "ಪ್ಯಾಕೇಜ್ ಹೆಸರು",
    PACKAGE_AMOUNT: "ಪ್ಯಾಕೇಜ್ ಮೊತ್ತ",
    BONUS_AMOUNT: "ಬೋನಸ್ ಮೊತ್ತ",
    BONUS_VALIDITY: "ಬೋನಸ್ ಮಾನ್ಯತೆ",
    TITLE: "ಶೀರ್ಷಿಕೆ",
    DESCRIPTION: "ವಿವರಣೆ",
    ADD_PACKAGE_CONFIGURATION: "ಪ್ಯಾಕೇಜ್ ಸಂರಚನೆಯನ್ನು ಸೇರಿಸಿ",
    DELETE_SELECTED: "ಆಯ್ಕೆಮಾಡಿದ ಅಳಿಸಿ",
    VIEW_PACKAGE_CONFIGURATION: "ಪ್ಯಾಕೇಜ್ ಸಂರಚನೆಯನ್ನು ವೀಕ್ಷಿಸಿ",
    EDIT_PACKAGE_CONFIGURATION: "ಪ್ಯಾಕೇಜ್ ಸಂರಚನೆಯನ್ನು ಸಂಪಾದಿಸಿ",
    PLEASE_ENTER_PACKAGE_NAME: "ದಯವಿಟ್ಟು ಪ್ಯಾಕೇಜ್ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    ENTER_PACKAGE_NAME: "ಪ್ಯಾಕೇಜ್ ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
    PLEASE_ENTER_PACKAGE_AMOUNT: "ದಯವಿಟ್ಟು ಪ್ಯಾಕೇಜ್ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    ENTER_PACKAGE_AMOUNT: "ಪ್ಯಾಕೇಜ್ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    PLEASE_ENTER_BONUS_AMOUNT: "ದಯವಿಟ್ಟು ಬೋನಸ್ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    ENTER_BONUS_AMOUNT: "ಬೋನಸ್ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ",
    BONUS_AMOUNT_VALIDITY_DAYS: "ಬೋನಸ್ ಮೊತ್ತ ಮಾನ್ಯತೆ (ದಿನಗಳು)",
    PLEASE_ENTER_BONUS_AMOUNT_VALIDITY_DAYS:
      "ದಯವಿಟ್ಟು ಬೋನಸ್ ಮೊತ್ತದ ಮಾನ್ಯತೆಯನ್ನು ನಮೂದಿಸಿ (ದಿನಗಳು)",
    ENTER_BONUS_VALIDITY_DAYS: "ಬೋನಸ್ ಮಾನ್ಯತೆಯನ್ನು ನಮೂದಿಸಿ (ದಿನಗಳು)",
    DESCRIPTION_TITLE: "ವಿವರಣೆ ಶೀರ್ಷಿಕೆ",
    ENTER_DESCRIPTION_TITLE: "ವಿವರಣೆ ಶೀರ್ಷಿಕೆಯನ್ನು ನಮೂದಿಸಿ",
    PLEASE_ENTER_DESCRIPTION_TITLE: "ದಯವಿಟ್ಟು ವಿವರಣೆಯ ಶೀರ್ಷಿಕೆಯನ್ನು ನಮೂದಿಸಿ",
    PLEASE_ENTER_DESCRIPTION: "ದಯವಿಟ್ಟು ವಿವರಣೆಯನ್ನು ನಮೂದಿಸಿ",
    ENTER_DESCRIPTION: "ವಿವರಣೆಯನ್ನು ನಮೂದಿಸಿ",
    PICKUP_ID: "ಪಿಕಪ್ ಐಡಿ",
    PROMOCODE: "ಪ್ರೋಮೊ ಕೋಡ್",
    ASSIGNED_USER_NAME: "ನಿಯೋಜಿಸಲಾದ ಬಳಕೆದಾರರ ಹೆಸರು",
    ORDER: "ಆದೇಶ",
    REASSIGN: "ಮರುಹೊಂದಿಸಿ",
    ADD_PICKUP: "ಪಿಕಪ್ ಸೇರಿಸಿ",
    REASSIGN_PICKUP_EXECUTIVE: "ಪಿಕಪ್ ಕಾರ್ಯನಿರ್ವಾಹಕನನ್ನು ಮರುಹೊಂದಿಸಿ",
    PICKUP_EXECUTIVE: "ಪಿಕಪ್ ಕಾರ್ಯನಿರ್ವಾಹಕ",
    PLEASE_SELECT_ANY_PICKUP_EXECUTIVE:
      "ದಯವಿಟ್ಟು ಯಾವುದೇ ಪಿಕಪ್ ಕಾರ್ಯನಿರ್ವಾಹಕನನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    SELECT_PICKUP_EXECUTIVE: "ಪಿಕಪ್ ಕಾರ್ಯನಿರ್ವಾಹಕ ಆಯ್ಕೆಮಾಡಿ",
    PICKUP: "ಪಿಕಪ್",
    HISTORY: "ಇತಿಹಾಸ",
    GENERAL_PICKUP_INFORMATION: "ಸಾಮಾನ್ಯ ಪಿಕಪ್ ಮಾಹಿತಿ",
    HISTORY_OF_RESCHEDULED_PICKUPS: "ಮರುಹೊಂದಿಸಿದ ಪಿಕಪ್‌ಗಳ ಇತಿಹಾಸ",
    PICKUP_STATUS: "ಪಿಕಪ್ ಸ್ಥಿತಿ",
    SELECT_PICKUP_STATUS: "ಪಿಕಪ್ ಸ್ಥಿತಿ ಆಯ್ಕೆಮಾಡಿ",
    PLEASE_SELECT_PICKUP_DATE: "ದಯವಿಟ್ಟು ಪಿಕಪ್ ದಿನಾಂಕವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    PICKUP_TIMESLOT: "ಪಿಕಪ್ ಟೈಮ್‌ಸ್ಲಾಟ್",
    PLEASE_SELECT_TIMESLOT: "ದಯವಿಟ್ಟು ಟೈಮ್‌ಸ್ಲಾಟ್ ಆಯ್ಕೆಮಾಡಿ",
    SELECT_TIMESLOT: "ಟೈಮ್ಸ್ಲಾಟ್ ಆಯ್ಕೆಮಾಡಿ",
    SELECT_ADDRESS: "ವಿಳಾಸ ಆಯ್ಕೆಮಾಡಿ",
    PLEASE_SELECT_DELIVERY_TYPE: "ದಯವಿಟ್ಟು ವಿತರಣಾ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    SELECT_DELIVERY_TYPE: "ವಿತರಣಾ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    ENTER_NO_OF_GARMENTS: "ಉಡುಪುಗಳ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ",
    ENTER_PROMOCODE: "ಪ್ರೋಮೋಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
    SERVICES: "ಸೇವೆಗಳು",
    PLEASE_SELECT_SERVICES: "ದಯವಿಟ್ಟು ಸೇವೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    SELECT_SERVICES: "ಸೇವೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    DELIVERY_TIMESLOT: "ವಿತರಣಾ ಟೈಮ್‌ಸ್ಲಾಟ್",
    CANCELLATION_REASON: "ರದ್ದತಿ ಕಾರಣ",
    SELECT_OPERATION_INSTRUCTION: "ಕಾರ್ಯಾಚರಣೆ ಸೂಚನೆಯನ್ನು ಆರಿಸಿ",
    RESCHEDULED_PICKUP_HISTORY: "ಪಿಕಪ್ ಇತಿಹಾಸವನ್ನು ಮರು ನಿಗದಿಪಡಿಸಲಾಗಿದೆ",
    ON_DEMAND_WALK_IN: "ಬೇಡಿಕೆ / ವಾಕ್-ಇನ್",
    GARMENTS: "ಉಡುಪುಗಳು",
    CREATE_ORDER: "ಆದೇಶವನ್ನು ರಚಿಸಿ",
    PRINT_TAGS: "ಟ್ಯಾಗ್‌ಗಳನ್ನು ಮುದ್ರಿಸಿ",
    CANCEL_ORDER: "ಆದೇಶವನ್ನು ರದ್ದುಗೊಳಿಸಿ",
    CANCEL_REASON: "ಕಾರಣವನ್ನು ರದ್ದುಮಾಡಿ",
    PLEASE_ENTER_REASON: "ದಯವಿಟ್ಟು ಕಾರಣವನ್ನು ನಮೂದಿಸಿ",
    ENTER_REASON_TO_CANCEL_THE_ORDER:
      "ಆದೇಶವನ್ನು ರದ್ದುಗೊಳಿಸಲು ಕಾರಣವನ್ನು ನಮೂದಿಸಿ",
    CUSTOMER_DETAILS: "ಗ್ರಾಹಕರ ವಿವರಗಳು",
    NAME: "ಹೆಸರು",
    TYPE_OF_SERVICE: "ಸೇವೆಯ ಪ್ರಕಾರ",
    PICKUP_REQUEST: "ಪಿಕಪ್ ವಿನಂತಿ",
    TYPE_OF_CUSTOMER: "ಗ್ರಾಹಕರ ಪ್ರಕಾರ",
    WALLET_CREDIT: "ವಾಲೆಟ್ ಕ್ರೆಡಿಟ್",
    FILTER: "ಫಿಲ್ಟರ್ ಮಾಡಿ",
    ORDER_SUMMARY: "ಆದೇಶ ಸಾರಾಂಶ",
    DETAILS: "ವಿವರಗಳು",
    QTY: "ಕ್ಯೂಟಿ",
    RATES: "ದರಗಳು",
    FEES: "ಶುಲ್ಕ",
    SUBTOTAL: "ಒಟ್ಟು ಮೊತ್ತ",
    TAXES_AND_CHARGES: "ತೆರಿಗೆಗಳು ಮತ್ತು ಶುಲ್ಕಗಳು",
    PROMO_DISCOUNT: "ಪ್ರೋಮೋ ರಿಯಾಯಿತಿ",
    SGST: "ಎಸ್‌ಜಿಎಸ್‌ಟಿ",
    CGST: "ಸಿಜಿಎಸ್ಟಿ",
    IGST: "ಐಜಿಎಸ್ಟಿ",
    TOTAL: "ಒಟ್ಟು",
    ORDER_REMARKS: "ಆದೇಶ ಟೀಕೆಗಳು",
    REVIEW_ORDER: "ವಿಮರ್ಶೆ ಆದೇಶ",
    MEDIUM: "ಮಾಧ್ಯಮ",
    REQUIRED: "ಅಗತ್ಯವಿದೆ",
    WEIGHT: "ತೂಕ",
    SELECT_ADD_ON_SERVICES: "ಆಡ್-ಆನ್ ಸೇವೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    DARNING_SIZE: "ಡಾರ್ನಿಂಗ್ ಗಾತ್ರ",
    SMALL: "ಸಣ್ಣ",
    LARGE: "ದೊಡ್ಡದು",
    DYING_COLORS: "ಸಾಯುತ್ತಿರುವ ಬಣ್ಣ (ಗಳು)",
    SIZE: "ಗಾತ್ರ",
    PRICE: "ಬೆಲೆ",
    NOTES: "ಟಿಪ್ಪಣಿಗಳು",
    ONLY_3_IMAGES_ARE_ALLOWED: "ಕೇವಲ 3 ಚಿತ್ರಗಳನ್ನು ಮಾತ್ರ ಅನುಮತಿಸಲಾಗಿದೆ",
    LOGISTICS_INSTRUCTION: "ಲಾಜಿಸ್ಟಿಕ್ಸ್ ಸೂಚನೆ",
    ON_DEMAND_DELIVERY: "ಬೇಡಿಕೆಯ ವಿತರಣೆಯಲ್ಲಿ",
    REPRINT_TAGS: "ಟ್ಯಾಗ್‌ಗಳನ್ನು ಮರುಮುದ್ರಣ ಮಾಡಿ",
    REPRINT_ESTIMATED_ORDER: "ಅಂದಾಜು ಆದೇಶವನ್ನು ಮರುಮುದ್ರಣ ಮಾಡಿ",
    REWASH_REQUESTED_BY: "ರಿವಾಶ್ ವಿನಂತಿಸಲಾಗಿದೆ",
    PLACE_ORDER: "ಸ್ಥಳ ಆದೇಶ",
    COLLECT_ADVANCE_PAYMENT: "ಮುಂಗಡ ಪಾವತಿ ಸಂಗ್ರಹಿಸಿ",
    UPDATE_ORDER: "ನವೀಕರಣ ಆದೇಶ",
    CONFIRM_ORDER: "ಆದೇಶವನ್ನು ದೃ irm ೀಕರಿಸಿ",
    TAG_IN_GARMENTS: "ಟ್ಯಾಗ್ ಇನ್ ಗಾರ್ಮೆಂಟ್ಸ್",
    CREATE_REWASH_ORDER: "ರಿವಾಶ್ ಆದೇಶವನ್ನು ರಚಿಸಿ",
    PAYMENT_METHOD: "ಪಾವತಿ ವಿಧಾನ",
    COLLECT: "ಸಂಗ್ರಹಿಸಿ",
    PROCESSING: "ಸಂಸ್ಕರಣೆ",
    INVOICE_NUMBER: "ಸರಕುಪಟ್ಟಿ ಸಂಖ್ಯೆ",
    GENERATE: "ರಚಿಸಿ",
    SETTLE: "ನೆಲೆ",
    READY_FOR_DELIVERY_ORDERS: "ವಿತರಣಾ ಆದೇಶಗಳಿಗೆ ಸಿದ್ಧವಾಗಿದೆ",
    HIDE_FILTERS: "ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಮರೆಮಾಡಿ",
    SHOW_FILTERS: "ಫಿಲ್ಟರ್‌ಗಳನ್ನು ತೋರಿಸಿ",
    PLEASE_SELECT_FROM_DATE: "ದಯವಿಟ್ಟು ದಿನಾಂಕದಿಂದ ಆಯ್ಕೆಮಾಡಿ",
    FROM_DATE: "ದಿನಾಂಕದಿಂದ",
    PLEASE_SELECT_TO_DATE: "ದಿನಾಂಕವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
    TO_DATE: "ದಿನಾಂಕ",
    ENTER_SPLIT_ORDER_NUMBER: "ಸ್ಪ್ಲಿಟ್ ಆರ್ಡರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    PROFORMA_INVOICE_NUMBER: "ಪ್ರೊಫಾರ್ಮಾ ಸರಕುಪಟ್ಟಿ ಸಂಖ್ಯೆ",
    PROFORMA_INVOICE_DATE: "ಪ್ರೊಫಾರ್ಮಾ ಸರಕುಪಟ್ಟಿ ದಿನಾಂಕ",
    INVOICE_DATE: "ಸರಕುಪಟ್ಟಿ ದಿನಾಂಕ",
    INVOICE: "ಸರಕುಪಟ್ಟಿ",
    ORDER_TYPE: "ಆದೇಶ ಪ್ರಕಾರ",
    PICKUP_BY: "ಇವರಿಂದ ಎತ್ತಿಕೊಳ್ಳಿ",
    ADJUSTED_LK_BONUS_AMOUNT: "ಸರಿಹೊಂದಿಸಲಾದ ಎಲ್ಕೆ ಬೋನಸ್ ಮೊತ್ತ",
    PROFORMA_INVOICE: "Proforma ಇನ್ವಾಯ್ಸ್",
    TOTAL_AMOUNT: "ಒಟ್ಟು ಮೊತ್ತ",
    ADD_ON_SERVICE_CHARGES: "ಆಡ್-ಆನ್ ಸೇವಾ ಶುಲ್ಕಗಳು",
    ADHOC_CHARGES: " ಅಧೋಕ್ ಶುಲ್ಕಗಳು",
    PAIDLESS_AMOUNT: "Adjusted By Admin",
    ADJUSTED_AMOUNT: "Paid Less",
    AMOUNT_TO_COLLECT: "ಸಂಗ್ರಹಿಸಬೇಕಾದ ಮೊತ್ತ",
    ADD_REMARKS: "ಟೀಕೆಗಳನ್ನು ಸೇರಿಸಿ",
    UPDATE_PROFORMA_INVOICE: "ಪ್ರೊಫಾರ್ಮಾ ಸರಕುಪಟ್ಟಿ ನವೀಕರಿಸಿ",
    CREATE_PROFORMA_INVOICE: "ಪ್ರೊಫಾರ್ಮಾ ಸರಕುಪಟ್ಟಿ ರಚಿಸಿ",
    USE_LK_WALLET: "ಎಲ್ಕೆ ವಾಲೆಟ್ ಬಳಸಿ",
    GENERATE_TAX_INVOICE: "ತೆರಿಗೆ ಸರಕುಪಟ್ಟಿ ರಚಿಸಿ",
    DO_YOU_WANT_TO_ADD_THE_EXCESS_AMOUNT_TO_THE_LKWALLET:
      "ಹೆಚ್ಚುವರಿ ಮೊತ್ತವನ್ನು ಎಲ್‌ಕೆ ವಾಲೆಟ್‌ಗೆ ಸೇರಿಸಲು ನೀವು ಬಯಸುವಿರಾ",
    SETTLE_LESS_PAID_INVOICE: "ಕಡಿಮೆ ಪಾವತಿಸಿದ ಸರಕುಪಟ್ಟಿ ಹೊಂದಿಸಿ",
    SLOT: "ಸ್ಲಾಟ್",
    ASSIGNED_TO: "ಗೆ ನಿಯೋಜಿಸಲಾಗಿದೆ",
    RESCHEDULE: "ಮರುಹೊಂದಿಸಿ",
    DELIVERY_LIST: "ವಿತರಣಾ ಪಟ್ಟಿ",
    REASSIGN_DELIVERY: "ಮರುಹಂಚಿಕೆ ವಿತರಣೆ",
    PLEASE_SELECT_ASSIGNEE: "ದಯವಿಟ್ಟು ನಿಯೋಜಕನನ್ನು ಆರಿಸಿ",
    SELECT_ASSIGNEE: "ನಿಯೋಜಕನನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    DELIVERY_ID: "ವಿತರಣಾ ID",
    RESCHEDULE_DELIVERY: "ಮರುಹೊಂದಿಸುವ ವಿತರಣೆ",
    CANCEL_DELIVERY: "ವಿತರಣೆಯನ್ನು ರದ್ದುಗೊಳಿಸಿ",
    PLEASE_SELECT_DELIVERY_DATE: "ದಯವಿಟ್ಟು ವಿತರಣಾ ದಿನಾಂಕವನ್ನು ಆರಿಸಿ",
    PLEASE_ENTER_CANCEL_REASON: "ದಯವಿಟ್ಟು ರದ್ದುಮಾಡು ಕಾರಣವನ್ನು ನಮೂದಿಸಿ",
    ENTER_CANCEL_REASON: "ರದ್ದುಮಾಡು ಕಾರಣವನ್ನು ನಮೂದಿಸಿ",
    PLEASE_SELECT_EXPENSE_TYPE: "ದಯವಿಟ್ಟು ಖರ್ಚು ಪ್ರಕಾರವನ್ನು ಆರಿಸಿ",
    PLEASE_SELECT_EXPENSE_DATE: "ದಯವಿಟ್ಟು ಖರ್ಚು ದಿನಾಂಕವನ್ನು ಆರಿಸಿ",
    // OVERDUE_GARMENTS: "ಮಿತಿಮೀರಿದ ಉಡುಪುಗಳು",
    OVERDUE_ORDERS: "ಮಿತಿಮೀರಿದ ಆದೇಶಗಳು",
    GARMENT_COUNTS: "ಗಾರ್ಮೆಂಟ್ ಎಣಿಕೆಗಳು",
    ORDER_COUNTS: "ಆದೇಶ ಎಣಿಕೆಗಳು",
    TOTAL_PENDING_GARMENTS_ORDERS: "ಒಟ್ಟು ಬಾಕಿ ಇರುವ ಉಡುಪುಗಳು / ಆದೇಶಗಳು",
    TODAYS_DUE: "ಇಂದಿನ ಬಾಕಿ",
    TICKETS_COUNTS: "ಟಿಕೆಟ್ ಎಣಿಕೆಗಳು",
    ORDER_CAN_NOT_BE_CREATED_USING_CLOSED_STORE:
      "ಮುಚ್ಚಿದ ಅಂಗಡಿಯನ್ನು ಬಳಸಿಕೊಂಡು ಆದೇಶವನ್ನು ರಚಿಸಲಾಗುವುದಿಲ್ಲ",
    THIS_TYPE_OF_ORDER_CAN_NOT_BE_CREATED_FROM_THIS_STORE:
      "ಈ ಅಂಗಡಿಯಿಂದ ಈ ರೀತಿಯ ಆದೇಶವನ್ನು ರಚಿಸಲಾಗುವುದಿಲ್ಲ",
    AMOUNT_IS_REQUIRED: "ಮೊತ್ತದ ಅಗತ್ಯವಿದೆ",
    AMOUNT_SHOULD_BE_GREATER_THAN_0: "ಮೊತ್ತ 0 ಕ್ಕಿಂತ ಹೆಚ್ಚಿರಬೇಕು",
    IS_LAUNDROKART_EMPLOYEE: "ಲಾಂಡ್ರೊಕಾರ್ಟ್ ಉದ್ಯೋಗಿ",
    REWASH_ORDER: "ರಿವಾಶ್ ಆರ್ಡರ್",

    PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9:
      "1 ರಿಂದ 9 ರಿಂದ ಪ್ರಾರಂಭವಾಗುವ ಅಂಕೆಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ",
    PLEASE_ENTER_ONLY_ALPHABETS: "ದಯವಿಟ್ಟು ವರ್ಣಮಾಲೆಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ",
    MISSING_TAG_NOS: "ಕಾಣೆಯಾದ ಟ್ಯಾಗ್ ಸಂಖ್ಯೆ",

    //11/06/2020
    CUSTOMER_IS_SUCCESSFULLY_REGISTERED:
      "ಗ್ರಾಹಕರನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನೋಂದಾಯಿಸಲಾಗಿದೆ",
    DATA_IS_SUCCESSFULLY_RETRIEVED: "ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಹಿಂಪಡೆಯಲಾಗಿದೆ",
    DATA_IS_SUCCESSFULLY_DELETED: "ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ",
    DATA_IS_SUCCESSFULLY_SAVED: "ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ",
    DATA_IS_SUCCESSFULLY_UPDATED: "ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ",
    RECORD_IS_ALREADY_EXISTS: "ದಾಖಲೆ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ",
    INTERNAL_SERVER_ERROR: "ಆಂತರಿಕ ಸರ್ವರ್ ದೋಷ",
    DATA_IS_SUCCESSFULLY_UPLOADED: "ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿದೆ",
    DISCOUNT_IS_SUCCESSFULLY_BLOCKED:
      "ರಿಯಾಯಿತಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನಿರ್ಬಂಧಿಸಲಾಗಿದೆ",
    DISCOUNT_IS_SUCCESSFULLY_AUTHORIZED:
      "ರಿಯಾಯಿತಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಧಿಕೃತಗೊಳಿಸಲಾಗಿದೆ",
    DISCOUNT_IS_SUCCESSFULLY_GENERATED: "ರಿಯಾಯಿತಿ ಯಶಸ್ವಿಯಾಗಿ ಉತ್ಪತ್ತಿಯಾಗುತ್ತದೆ",
    OTP_HAS_BEEN_SUCCESSFULLY_RE_SENT: "ಒಟಿಪಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮರು ಕಳುಹಿಸಲಾಗಿದೆ",
    PICKUP_IS_SUCCESSFULLY_RESCHEDULED:
      "ಪಿಕಪ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮರುಹೊಂದಿಸಲಾಗಿದೆ",
    PICKUP_IS_SUCCESSFULLY_SCHEDULED: "ಪಿಕಪ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನಿಗದಿಪಡಿಸಲಾಗಿದೆ",
    PICKUP_IS_SUCCESSFULLY_CANCELLED: "ಪಿಕಪ್ ಯಶಸ್ವಿಯಾಗಿ ರದ್ದುಗೊಂಡಿದೆ",
    PICKUP_IS_SUCCESSFULLY_REASSIGNED:
      "ಪಿಕಪ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮರು ನಿಯೋಜಿಸಲಾಗಿದೆ",
    ERROR_WHILE_SENDING_CALL_BACK_REQUEST_NOTIFICATION:
      "ಕಾಲ್ ಬ್ಯಾಕ್ ವಿನಂತಿ ಅಧಿಸೂಚನೆಯನ್ನು ಕಳುಹಿಸುವಾಗ ದೋಷ",
    ORDER_IS_SUCCESSFULLY_CANCELLED: "ಆದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರದ್ದುಪಡಿಸಲಾಗಿದೆ",
    ORDER_IS_SUCCESSFULLY_CREATED_PLEASE_CONFIRM_ORDER_TO_PROCEED_FURTHER:
      "ಆದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ ದಯವಿಟ್ಟು ಮುಂದೆ ಮುಂದುವರಿಯಲು ಆದೇಶವನ್ನು ದೃ irm ೀಕರಿಸಿ",
    ORDER_IS_SUCCESSFULLY_UPDATED: "ಆದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ",
    GENERATE_TAGS_FOR_ORDER_SUCCESSFULLY:
      "ಆದೇಶಕ್ಕಾಗಿ ಟ್ಯಾಗ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಿ",
    GETEPASS_IS_SUCCESSFULLY_GENERATED: "ಗೆಟ್‌ಪಾಸ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ",
    PROMOCODE_VALIDATED_SUCCESSFULLY:
      "ಪ್ರೋಮೋಕೋಡ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮೌಲ್ಯೀಕರಿಸಲಾಗಿದೆ",
    INVALID_PROMOCODE: "ಅಮಾನ್ಯ ಪ್ರೋಮೋಕೋಡ್",
    DELIVERY_IS_SUCCESSFULLY_REASSIGNED:
      "ವಿತರಣೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮರು ನಿಯೋಜಿಸಲಾಗಿದೆ",
    AMOUNT_SUCCESSFULLY_BLOCKED: "ಮೊತ್ತವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನಿರ್ಬಂಧಿಸಲಾಗಿದೆ",
    USER_IS_VALID: "ಬಳಕೆದಾರ ಮಾನ್ಯವಾಗಿದೆ",
    USER_IS_INVALID: "ಬಳಕೆದಾರ ಅಮಾನ್ಯವಾಗಿದೆ",
    ALREADY_IN_USED: "ಈಗಾಗಲೇ ಬಳಕೆಯಲ್ಲಿದೆ",
    PDF_FILE_IS_GENERATED_SUCCESSFULLY:
      "ಪಿಡಿಎಫ್ ಫೈಲ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ",
    OLD_PASSWORD_DOES_NOT_MATCH: "ಹಳೆಯ ಪಾಸ್‌ವರ್ಡ್ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ",
    NOTIFICATION_HAS_BEEN_SENT_SUCCESSFULLY:
      "ಅಧಿಸೂಚನೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ",
    ERROR_WHILE_SENDING_NOTIFICATION: "ಅಧಿಸೂಚನೆಯನ್ನು ಕಳುಹಿಸುವಾಗ ದೋಷ",
    NOTIFICATION_IS_SUCCESSFULLY_DISMISS:
      "ಅಧಿಸೂಚನೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ವಜಾಗೊಳಿಸಲಾಗಿದೆ",
    THERE_WAS_AN_INTERNAL_ERROR_PLEASE_CONTACT_TO_TECHNICAL_SUPPORT:
      "ಆಂತರಿಕ ದೋಷ ಕಂಡುಬಂದಿದೆ ದಯವಿಟ್ಟು ತಾಂತ್ರಿಕ ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ",
    EMAIL_SENT_SUCCESSFULLY: "ಇಮೇಲ್ ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ!",
    TAGS_NOT_FOUND: "ಟ್ಯಾಗ್‌ಗಳು ಕಂಡುಬಂದಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_STORE_DATA:
      "ಅಂಗಡಿ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_MISSING_TAG_DATA:
      "ಕಾಣೆಯಾದ ಟ್ಯಾಗ್ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_TAG:
      "ಟ್ಯಾಗ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡುವ ಮೊದಲು ದಯವಿಟ್ಟು ಅಂಗಡಿ ಆಯ್ಕೆಮಾಡಿ",
    PLEASE_ENTER_THE_TAG_TO_SCAN:
      "ಸ್ಕ್ಯಾನ್ ಮಾಡಲು ದಯವಿಟ್ಟು ಟ್ಯಾಗ್ ಅನ್ನು ನಮೂದಿಸಿ",
    TAG_ALREADY_SCANNED: "ಟ್ಯಾಗ್ ಅನ್ನು ಈಗಾಗಲೇ ಸ್ಕ್ಯಾನ್ ಮಾಡಲಾಗಿದೆ",
    PLEASE_SELECT_FACTORY_TAG_NO_FOR_TRANSFERIN_PROCESS:
      "ವರ್ಗಾವಣೆ ಪ್ರಕ್ರಿಯೆಗಾಗಿ ಫ್ಯಾಕ್ಟರಿ ಟ್ಯಾಗ್ ಸಂಖ್ಯೆ ಆಯ್ಕೆಮಾಡಿ",
    UNABLE_TO_SCAN_THE_TAG: "ಟ್ಯಾಗ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    TAGS_ARE_TRANSFERRED_IN_SUCCESSFULLY:
      "ಟ್ಯಾಗ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ವರ್ಗಾಯಿಸಲಾಗುತ್ತದೆ",
    GENERATING_GATE_PASS: "ಗೇಟ್ ಪಾಸ್ ಉತ್ಪಾದಿಸಲಾಗುತ್ತಿದೆ",
    PLEASE_SELECT_STICKERS: "ದಯವಿಟ್ಟು ಸ್ಟಿಕ್ಕರ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    UNABLE_TO_GET_STICKERS_TO_TRANSFER_OUT:
      "ವರ್ಗಾವಣೆ ಮಾಡಲು ಸ್ಟಿಕ್ಕರ್‌ಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    INFO: "ಮಾಹಿತಿ",
    ENTERED_NUMBER_SHOULD_BE_SMALLER_THEN_NO_OF_PENDING_GARMENTS:
      "ನಮೂದಿಸಿದ ಸಂಖ್ಯೆ ಚಿಕ್ಕದಾಗಿರಬೇಕು ನಂತರ ಬಾಕಿ ಉಳಿದಿರುವ ಉಡುಪುಗಳಿಲ್ಲ",
    PLEASE_SELECT_ATLEAST_ONE_PENDING_GARMENTS_TO_BE_PACKED_IN:
      "ಪ್ಯಾಕ್ ಮಾಡಲು ಕನಿಷ್ಠ ಒಂದು ಬಾಕಿ ಇರುವ ಉಡುಪುಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    STICKER_SUCCESSFULLY_GENERATED: "ಸ್ಟಿಕ್ಕರ್ ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ",
    UNABLE_TO_GET_STICKERID: "ಸ್ಟಿಕ್ಕರ್ ಐಡಿ ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    GARMENT_ALREADY_PACKED:
      "ಈ ಆದೇಶದ ಕೆಲವು ಉಡುಪುಗಳನ್ನು ಈಗಾಗಲೇ ಪ್ಯಾಕ್ ಮಾಡಲಾಗಿದೆ, ದಯವಿಟ್ಟು ರಿಫ್ರೆಶ್ ಮಾಡಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    REDIRECTING: "ಮರುನಿರ್ದೇಶಿಸಲಾಗುತ್ತಿದೆ",
    SUCCESS: "ಯಶಸ್ಸು",
    FAIL: "ಅನುತ್ತೀರ್ಣ",
    WALLET_BALANCE: "ವಾಲೆಟ್ ಬ್ಯಾಲೆನ್ಸ್",
    PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9:
      "1 ರಿಂದ 9 ರಿಂದ ಪ್ರಾರಂಭವಾಗುವ ಅಂಕೆಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ",
    PLEASE_ENTER_ONLY_ALPHABETS: "ದಯವಿಟ್ಟು ವರ್ಣಮಾಲೆಗಳನ್ನು ಮಾತ್ರ ನಮೂದಿಸಿ",
    MISSING_TAG_NOS: "ಟ್ಯಾಗ್ ಸಂಖ್ಯೆ ಕಾಣೆಯಾಗಿದೆ",
    UNABLE_TO_GET_ORDERS_DUE_FOR_DELIVERY:
      "ವಿತರಣೆಯಿಂದಾಗಿ ಆದೇಶಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_PENDING_PICKUPS:
      "ಬಾಕಿ ಉಳಿದಿರುವ ಪಿಕಪ್‌ಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_LOAD_ORDERS_PENDING_TO_TRANSFER_OUT_TO_FACTORY:
      "ಕಾರ್ಖಾನೆಗೆ ವರ್ಗಾಯಿಸಲು ಬಾಕಿ ಇರುವ ಆದೇಶಗಳನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_DATA_FOR_DASHBOARD:
      "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್‌ಗಾಗಿ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_DATA_FOR_TIMESLOT:
      "ಟೈಮ್‌ಸ್ಲಾಟ್‌ಗಾಗಿ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    CANNOT_REASSIGN_DELIVERY_AS_IT_IS_ALREADY_PROCEEDED_FURTHER:
      "ವಿತರಣೆಯನ್ನು ಈಗಾಗಲೇ ಮತ್ತಷ್ಟು ಮುಂದುವರಿಸಿರುವ ಕಾರಣ ಅದನ್ನು ಮರುಹೊಂದಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    DELIVERY_REASSIGNED_SUCCESSFULLY:
      "ವಿತರಣೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮರು ನಿಯೋಜಿಸಲಾಗಿದೆ",
    UNABLE_TO_REASSIGN_DELIVERY_REQUEST:
      "ವಿತರಣಾ ವಿನಂತಿಯನ್ನು ಮರುಹೊಂದಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    DELIVERY_STATUS_MUST_BE_DELIVERY_SCHEDULED_OR_ASSIGNED:
      "ವಿತರಣಾ ಸ್ಥಿತಿ ವಿತರಣಾ ನಿಗದಿತ ಅಥವಾ ನಿಯೋಜಿತವಾಗಿರಬೇಕು",
    DELIVERY_RESCHEDULED_SUCCESSFULLY:
      "ವಿತರಣೆಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮರು ನಿಗದಿಪಡಿಸಲಾಗಿದೆ",
    UNABLE_TO_RESCHEDULE_DELIVERY_REQUEST:
      "ವಿತರಣಾ ವಿನಂತಿಯನ್ನು ಮರುಹೊಂದಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    REQUEST_CANCELLED_SUCCESSFULLY: "ವಿನಂತಿಯನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರದ್ದುಗೊಳಿಸಲಾಗಿದೆ",
    UNABLE_TO_CANCEL_DELIVERY_REQUEST:
      "ವಿತರಣಾ ವಿನಂತಿಯನ್ನು ರದ್ದುಗೊಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_DELIVERY_LIST_DATA:
      "ವಿತರಣಾ ಪಟ್ಟಿ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    EXPENSE_DELETED_SUCCESSFULLY: "ವೆಚ್ಚವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಅಳಿಸಲಾಗಿದೆ",
    UNABLE_TO_DELETE_EXPENSE: "ವೆಚ್ಚವನ್ನು ಅಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    EXPENSE_ADDED_SUCCESSFULLY: "ವೆಚ್ಚವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸೇರಿಸಲಾಗಿದೆ",
    EXPENSE_UPDATED_SUCCESSFULLY: "ವೆಚ್ಚವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ",
    UNABLE_TO_SUBMIT_DATA: "ಡೇಟಾವನ್ನು ಸಲ್ಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_EXPENSES: "ವೆಚ್ಚಗಳ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_GATE_PASS_DATA:
      "ಗೇಟ್ ಪಾಸ್ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_STORE_GARMENT_INVENTORY_DATA:
      "ಅಂಗಡಿ ಉಡುಪುಗಳ ದಾಸ್ತಾನು ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_SPLIT_ORDER_DETAILS:
      "ಸ್ಪ್ಲಿಟ್ ಆರ್ಡರ್ ವಿವರಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    INVOICE_GENERATED_SUCCESSFULLY: "ಸರಕುಪಟ್ಟಿ ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ",
    UNABLE_TO_GENERATE_INVOICE: "ಸರಕುಪಟ್ಟಿ ರಚಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    INVOICE_DATA_SUBMITTED_SUCCESSFULLY:
      "ಸರಕುಪಟ್ಟಿ ಡೇಟಾವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ",
    UNABLE_TO_SUBMIT_INVOICE_DATA: "ಸರಕುಪಟ್ಟಿ ಡೇಟಾವನ್ನು ಸಲ್ಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    INVOICE_SETTLED_SUCCESSFULLY: "ಸರಕುಪಟ್ಟಿ ಯಶಸ್ವಿಯಾಗಿ ನೆಲೆಸಿದೆ",
    UNABLE_TO_SETTLE_INVOICE: "ಸರಕುಪಟ್ಟಿ ಇತ್ಯರ್ಥಪಡಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_ORDERS: "ಆದೇಶಗಳ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_UOM_LIST: "Uom ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GENERATE_ESTIMATED_ORDER: "ಅಂದಾಜು ಆದೇಶವನ್ನು ರಚಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_SERVICES: "ಸೇವೆಗಳ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_STORE_DATA: "ಸ್ಟೋರ್ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_PICKUP_DATA: "ಪಿಕಪ್ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    TAGS_GENERATED_SUCCESSFULLY: "ಟ್ಯಾಗ್‌ಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ",
    UNABLE_TO_GENERATE_TAGS: "ಟ್ಯಾಗ್‌ಗಳನ್ನು ರಚಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_ORDER_INFORMATION: "ಆದೇಶ ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_TIME_CARD: "ಸಮಯ ಕಾರ್ಡ್ ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_LOAD_ADD_ON_SERVICES:
      "ಸೇವೆಗಳಲ್ಲಿ ಆಡ್ ಅನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_PACKAGING_CHARGES: "ಪ್ಯಾಕೇಜಿಂಗ್ ಶುಲ್ಕವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_DELIVERY_CHARGES: "ವಿತರಣಾ ಶುಲ್ಕವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_GARMENTS: "ಉಡುಪುಗಳ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_RATES: "ದರಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    ORDER_SUCCESSFULLY_CREATED: "ಆದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರಚಿಸಲಾಗಿದೆ",
    UNABLE_TO_CREATE_THE_ORDER: "ಆದೇಶವನ್ನು ರಚಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    PLEASE_ENTER_THE_PROMOCODE: "ದಯವಿಟ್ಟು ಪ್ರೋಮೋಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
    PROMOCODE_APPLIED_SUCCESSFULLY: "ಪ್ರೋಮೋಕೋಡ್ ಯಶಸ್ವಿಯಾಗಿ ಅನ್ವಯಿಸಲಾಗಿದೆ",
    PROMOCODE_IS_INVALID: "ಪ್ರೋಮೋಕೋಡ್ ಅಮಾನ್ಯವಾಗಿದೆ",
    UNABLE_TO_VALIDATE_PROMOCODE: "ಪ್ರೊಮೊಕೋಡ್ ಅನ್ನು ಮೌಲ್ಯೀಕರಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    ORDER_SUCCESSFULLY_UPDATED: "ಆದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ",
    UNABLE_TO_UPDATE_THE_ORDER: "ಆದೇಶವನ್ನು ನವೀಕರಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    ORDER_SUCCESSFULLY_CANCELLED: "ಆದೇಶವನ್ನು ಯಶಸ್ವಿಯಾಗಿ ರದ್ದುಪಡಿಸಲಾಗಿದೆ",
    UNABLE_TO_CANCEL_ORDER: "ಆದೇಶವನ್ನು ರದ್ದುಗೊಳಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_PICKUP_DATA:
      "ಪಿಕಪ್ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_USER_DATA:
      "ಬಳಕೆದಾರ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_REASSIGN_THE_PICKUP: "ಪಿಕಪ್ ಅನ್ನು ಮರು ನಿಯೋಜಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_DATA_FOR_HISTORY: "ಇತಿಹಾಸಕ್ಕಾಗಿ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_DATA_FOR_DELIVERY_TYPES:
      "ವಿತರಣಾ ಪ್ರಕಾರಗಳಿಗಾಗಿ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_DATA_FOR_PICKUP_STATUS:
      "ಪಿಕಪ್ ಸ್ಥಿತಿಗಾಗಿ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_OPERATION_INSTRUCTION_DATA:
      "ಕಾರ್ಯಾಚರಣೆಯ ಸೂಚನಾ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_SERVICES_DATA: "ಸೇವೆಗಳ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_REASONS_DATA:
      "ಕಾರಣಗಳ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_DATA_FOR_CUSTOMER: "ಗ್ರಾಹಕರಿಗಾಗಿ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_CUSTOMER_ADDRESSES: "ಗ್ರಾಹಕರ ವಿಳಾಸಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_DATA: "ಡೇಟಾ ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    PLEASE_INPUT_CUSTOMERNAME_ADDRESS_SERVICES_PROMOCODE:
      "ದಯವಿಟ್ಟು ಗ್ರಾಹಕ ಹೆಸರು / ವಿಳಾಸ / ಸೇವೆಗಳು / ಪ್ರೋಮೋಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
    PICKUP_IS_UPDATED: "ಪಿಕಪ್ ನವೀಕರಿಸಲಾಗಿದೆ",
    PICKUP_IS_SCHEDULED: "ಪಿಕಪ್ ನಿಗದಿಪಡಿಸಲಾಗಿದೆ",
    PICKUP_IS_RESCHEDULED_SUCCESSFULLY:
      "ಪಿಕಪ್ ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಮರುಹೊಂದಿಸಲಾಗಿದೆ",
    UNABLE_TO_RESCHEDULE_THE_PICKUP: "ಪಿಕಪ್ ಅನ್ನು ಮರುಹೊಂದಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    PICKUP_IS_CANCELLED_SUCCESSFULLY: "ಪಿಕಪ್ ಯಶಸ್ವಿಯಾಗಿ ರದ್ದುಗೊಂಡಿದೆ",
    UNABLE_TO_CANCEL_PICKUP: "ಪಿಕಪ್ ರದ್ದು ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_PRE_QC_RESPONSE_LIST:
      "ಪೂರ್ವ-ಕ್ಯೂಸಿ ಪ್ರತಿಕ್ರಿಯೆ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    QC_RESPONSE_SUBMITTED_SUCCESSFULLY:
      "ಕ್ಯೂಸಿ ಪ್ರತಿಕ್ರಿಯೆ ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ",
    UNABLE_TO_SUBMIT_QC_RESPONSE: "ಕ್ಯೂಸಿ ಪ್ರತಿಕ್ರಿಯೆ ಸಲ್ಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_READY_FOR_DELIVERY_INVOICE_DATA:
      "ವಿತರಣೆ / ಸರಕುಪಟ್ಟಿ ಡೇಟಾಗೆ ಸಿದ್ಧವಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_SUBMIT_STICKER_DATA: "ಸ್ಟಿಕ್ಕರ್ ಡೇಟಾವನ್ನು ಸಲ್ಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_STICKER_DATA: "ಸ್ಟಿಕ್ಕರ್ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    PLEASE_ENTER_STICKER_ID: "ದಯವಿಟ್ಟು ಸ್ಟಿಕ್ಕರ್ ಐಡಿ ನಮೂದಿಸಿ",
    UNABLE_TO_GET_LIST_OF_SERVICE_DATA:
      "ಸೇವಾ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_SPLIT_ORDER_NUMBERS:
      "ಸ್ಪ್ಲಿಟ್ ಆರ್ಡರ್ ಸಂಖ್ಯೆಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_FACTORY_DATA:
      "ಫ್ಯಾಕ್ಟರಿ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    HAS_BEEN_GENERATED: "ಗೇಟ್ ಪಾಸ್ ಅನ್ನು ರಚಿಸಲಾಗಿದೆ",
    UNABLE_TO_GET_LIST_OF_STICKER_DATA:
      "ಸ್ಟಿಕ್ಕರ್ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_GET_LIST_OF_PACKAGE_CONFIGURATION_DATA:
      "ಪ್ಯಾಕೇಜ್ ಕಾನ್ಫಿಗರೇಶನ್ ಡೇಟಾದ ಪಟ್ಟಿಯನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    PLEASE_SELECT_ATLEAST_ONE_ROW: "ದಯವಿಟ್ಟು ಕನಿಷ್ಠ ಒಂದು ಸಾಲು ಆಯ್ಕೆಮಾಡಿ",
    UNABLE_TO_LOAD_REWASH_REASONS: "ರಿವಾಶ್ ಕಾರಣಗಳನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_LOAD_REWASH_REQUESTED_BY_LIST:
      "ಪಟ್ಟಿಯಿಂದ ವಿನಂತಿಸಿದ ರಿವಾಶ್ ಅನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_LOAD_OPERATION_INSTRUCTIONS:
      "ಕಾರ್ಯಾಚರಣೆ ಸೂಚನೆಗಳನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_LOAD_BRANDS: "ಬ್ರ್ಯಾಂಡ್‌ಗಳನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_LOAD_SIZES: "ಗಾತ್ರಗಳನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    UNABLE_TO_LOAD_COLORS: "ಬಣ್ಣಗಳನ್ನು ಲೋಡ್ ಮಾಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
    WALLET_BALANCE: "ವಾಲೆಟ್ ಬ್ಯಾಲೆನ್ಸ್",
    INVALID: "ಅಮಾನ್ಯವಾಗಿದೆ",
    SPLITORDERNUMBER_SHOULD_NOT_BE_BLANK: "SplitOrderNumber ಖಾಲಿಯಾಗಿರಬಾರದು",
    VALID: "ಮಾನ್ಯ",
    ADJUSTMENTAMOUNT_SHOULD_BE_BETWEEN_0_AND_10:
      "ಹೊಂದಾಣಿಕೆ ಮೊತ್ತವು 0 ಮತ್ತು 10 ರ ನಡುವೆ ಇರಬೇಕು",
    SERVICE_RATE_NOT_AVAILABLE: "ಸೇವಾ ದರ ಲಭ್ಯವಿಲ್ಲ",
    ADDON_SERVICE_RATE_NOT_AVAILABLE: "ಆಡ್-ಆನ್ ಸೇವಾ ದರ ಲಭ್ಯವಿಲ್ಲ",
    SCAN_GARMENT: "ಉಡುಪನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
    CLEAR: "ಸ್ಪಷ್ಟ",
    SCAN_TAG: "ಟ್ಯಾಗ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
    PLEASE_SCAN_TAG: "ದಯವಿಟ್ಟು ಟ್ಯಾಗ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
    READY_FOR_TRANSFER_OUT: "ವರ್ಗಾವಣೆಗೆ ಸಿದ್ಧವಾಗಿದೆ",
    SCANNED_GARMENT_LIST: "ಸ್ಕ್ಯಾನ್ ಮಾಡಿದ ಉಡುಪು ಪಟ್ಟಿ",
    THIS_STICKER_BELONGS_TO_ANOTHER_STORE:
      "ಈ ಸ್ಟಿಕ್ಕರ್ ಮತ್ತೊಂದು ಅಂಗಡಿಗೆ ಸೇರಿದೆ",
    PLEASE_ENTER_VALID_STICKER_NUMBER:
      "ದಯವಿಟ್ಟು ಮಾನ್ಯ ಸ್ಟಿಕ್ಕರ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
    ALL: "ಎಲ್ಲಾ",
    THIS_TAG_ALREADY_EXISTS: "ಈ ಟ್ಯಾಗ್ ಈಗಾಗಲೇ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ",
    PREVIOUSLY_PACKE_ON: "ಹಿಂದೆ ಪ್ಯಾಕ್ ಮಾಡಲಾಗಿದೆ",
    CASH_COLLECTION: "ನಗದು ಸಂಗ್ರಹ",
    ALREADY_EXISTS: "ಈಗಾಗಲೇ ಇದೆ",
    USER_PUNCHIN_NOT_FOUND: "ಬಳಕೆದಾರ ಪಂಚಿನ್ ಕಂಡುಬಂದಿಲ್ಲ",
    IT_IS_ALREADY_BLOCKED: "ಇದನ್ನು ಈಗಾಗಲೇ ನಿರ್ಬಂಧಿಸಲಾಗಿದೆ",
    AUTHORIZED: "ಅಧಿಕೃತ",
    BLOCKED: "ನಿರ್ಬಂಧಿಸಲಾಗಿದೆ",
    INVALID_OTP: "ಅಮಾನ್ಯ ಒಟಿಪಿ",
    PLEASE_CHECK_THE_PROVIDED_BANNERID:
      "ದಯವಿಟ್ಟು ಒದಗಿಸಿದ ಬ್ಯಾನರ್ ಐಡಿ ಪರಿಶೀಲಿಸಿ",
    ALREADY_IN_USE: "ಈಗಾಗಲೇ ಬಳಕೆಯಲ್ಲಿದೆ",
    ALREADY_EXISTS: "ಈಗಾಗಲೇ ಇದೆ",
    DATE_OVERLAPPING: "ದಿನಾಂಕ ಅತಿಕ್ರಮಣ",
    REFERRAL_CODE_DOES_NOT_EXISTS: "ರೆಫರಲ್ ಕೋಡ್ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ",
    REFERRAL_CODE_IS_ALREADY_USED: "ರೆಫರಲ್ ಕೋಡ್ ಅನ್ನು ಈಗಾಗಲೇ ಬಳಸಲಾಗುತ್ತದೆ",
    USER_NAME_IS_INVALID: "ಬಳಕೆದಾರರ ಹೆಸರು ಅಮಾನ್ಯವಾಗಿದೆ",
    PASSWORD_IS_INVALID: "ಪಾಸ್ವರ್ಡ್ ಅಮಾನ್ಯವಾಗಿದೆ",
    USER_NAME_AND_PASSWORD_DOES_NOT_MATCH:
      "ಬಳಕೆದಾರರ ಹೆಸರು ಮತ್ತು ಪಾಸ್‌ವರ್ಡ್ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ",
    USER_IS_NOT_EXISTS: "ಬಳಕೆದಾರ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ",
    SPLIT_ORDER_NUMBER_IS_REQUIRED: "ಸ್ಪ್ಲಿಟ್ ಆರ್ಡರ್ ಸಂಖ್ಯೆ ಅಗತ್ಯವಿದೆ",
    INVALID_SPLIT_ORDER_NUMBER: "ಅಮಾನ್ಯ ಸ್ಪ್ಲಿಟ್ ಆರ್ಡರ್ ಸಂಖ್ಯೆ",
    IT_IS_ALREADY_INACTIVATED: "ಇದು ಈಗಾಗಲೇ ನಿಷ್ಕ್ರಿಯಗೊಂಡಿದೆ",
    COUPON_CODE_NOT_EXISTS: "ಕೂಪನ್ ಕೋಡ್ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ",
    CASH_COUPON_EXPIRED: "ನಗದು ಕೂಪನ್ ಅವಧಿ ಮೀರಿದೆ",
    IT_IS_APPLICABLE_FOR_ONLY_FIRST_TIME_USERS:
      "ಇದು ಮೊದಲ ಬಾರಿಗೆ ಬಳಕೆದಾರರಿಗೆ ಮಾತ್ರ ಅನ್ವಯಿಸುತ್ತದೆ",
    YOU_HAVE_ALREADY_USED_THIS_COUPON: "ನೀವು ಈಗಾಗಲೇ ಈ ಕೂಪನ್ ಅನ್ನು ಬಳಸಿದ್ದೀರಿ",
    INVALID_COUPON: "ಅಮಾನ್ಯ ಕೂಪನ್",
    SPLITORDERNUMBER_SHOULD_NOT_BE_BLANK: "SplitOrderNumber ಖಾಲಿಯಾಗಿರಬಾರದು",
    THIS_PICKUP_IS_ALREADY_IN_PROGRESS: "ಈ ಪಿಕಪ್ ಈಗಾಗಲೇ ಪ್ರಗತಿಯಲ್ಲಿದೆ",
    YOU_HAVE_ALREADY_RESCHEDULED_THIS_PICKUP_3_TIMES:
      "ನೀವು ಈಗಾಗಲೇ ಈ ಪಿಕಪ್ ಅನ್ನು 3 ಬಾರಿ ಮರು ನಿಗದಿಪಡಿಸಿದ್ದೀರಿ",
    PLEASE_CHECK_WITH_DELIVERYID: "ದಯವಿಟ್ಟು DeliveryID ನೊಂದಿಗೆ ಪರಿಶೀಲಿಸಿ",
    PLEASE_ASSIGN_IT_TO_SOMEONE_FIRST:
      "ದಯವಿಟ್ಟು ಅದನ್ನು ಮೊದಲು ಯಾರಿಗಾದರೂ ನಿಯೋಜಿಸಿ",
    CANNOT_REASSIGN_DELIVERY_AS_ALREADY_PROCEED_FURTHER:
      "ಈಗಾಗಲೇ ಮುಂದುವರೆದಂತೆ ವಿತರಣೆಯನ್ನು ಮರುಹೊಂದಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    PLEASE_CHECK_WITH_STATUS: "ದಯವಿಟ್ಟು ಸ್ಥಿತಿಯೊಂದಿಗೆ ಪರಿಶೀಲಿಸಿ",
    STICKER_ID: "ಸ್ಟಿಕ್ಕರ್ ಐಡಿ",
    PACKED_On: "ಪ್ಯಾಕ್ ಮಾಡಲಾಗಿದೆ",
    PACKING_IDS: "ಪ್ಯಾಕಿಂಗ್ ಐಡಿಎಸ್",
    RESORTING_AND_PACKAGING: "ರೆಸಾರ್ಟಿಂಗ್ ಮತ್ತು ಪ್ಯಾಕೇಜಿಂಗ್",
    RESORTING_AND_PACKAGING_List: "ರೆಸಾರ್ಟಿಂಗ್ ಮತ್ತು ಪ್ಯಾಕೇಜಿಂಗ್ ಪಟ್ಟಿ",
    PADING_QC_RESPONSE: "ಪೂರ್ವ-ಕ್ಯೂಸಿ ಪ್ರತಿಕ್ರಿಯೆ ಎಣಿಕೆ ಬಾಕಿ ಉಳಿದಿದೆ",
    LANGUAGE: "ಭಾಷೆ",
    CHANGE_PASSWORD: "ಗುಪ್ತಪದವನ್ನು ಬದಲಿಸಿ",
    LOGOUT: "ಲಾಗ್ ಔಟ್",
    TAG_NUMBER_NOT_FOUND: "ಟ್ಯಾಗ್ ಸಂಖ್ಯೆ ಕಂಡುಬಂದಿಲ್ಲ",
    TAG_NO: "ಟ್ಯಾಗ್ ಸಂಖ್ಯೆ",
    YOU_CAN_ONLY_UPLOAD_JPG_JPEG_PNG_FILE:
      "ನೀವು ಜೆಪಿಜಿ / ಪಿಎನ್‌ಜಿ / ಜೆಪಿಇಜಿ ಫೈಲ್ ಅನ್ನು ಮಾತ್ರ ಅಪ್‌ಲೋಡ್ ಮಾಡಬಹುದು",
    IMAGE_MUST_BE_SMALLER_THAN_5_MB: "ಚಿತ್ರವು 5 MB ಗಿಂತ ಚಿಕ್ಕದಾಗಿರಬೇಕು",
    GARMENT_SHOULD_BE_INFACTORY_TO_BE_ADDED_FOR_QC:
      "ಕ್ಯೂಸಿಗೆ ಸೇರಿಸಲು ಗಾರ್ಮೆಂಟ್ ಕಾರ್ಖಾನೆಯಲ್ಲಿರಬೇಕು",
    ORDER_ALREADY_CONFIRMED: "ಆದೇಶವನ್ನು ಈಗಾಗಲೇ ದೃ .ಪಡಿಸಲಾಗಿದೆ",
    PENDING_PAYMENT: "ಪಾವತಿ ಬಾಕಿ ಉಳಿದಿದೆ",
    IT_SEEMS_LIKE_FEW_OF_THE_SERVICES_ARE_NOT_EXISTS_INTO_SYSTEM:
      "ಕೆಲವು ಸೇವೆಗಳು ವ್ಯವಸ್ಥೆಯಲ್ಲಿ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ ಎಂದು ತೋರುತ್ತಿದೆ. ದಯವಿಟ್ಟು ಸೇವಾ ಮಾಸ್ಟರ್ ಪರದೆಯಲ್ಲಿ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    IT_SEEMS_LIKE_FEW_OF_THE_GARMENTS_ARE_NOT_EXISTS_INTO_SYSTEM:
      "ಕೆಲವು ಉಡುಪುಗಳು ವ್ಯವಸ್ಥೆಯಲ್ಲಿ ಅಸ್ತಿತ್ವದಲ್ಲಿಲ್ಲ ಎಂದು ತೋರುತ್ತದೆ. ದಯವಿಟ್ಟು ಗಾರ್ಮೆಂಟ್ ಮಾಸ್ಟರ್ ಪರದೆಯಲ್ಲಿ ವಿವರಗಳನ್ನು ಪರಿಶೀಲಿಸಿ ಮತ್ತು ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
    SERVICE_TAX_DETAIL_IS_MISSING_IN_SECOND_SHEET:
      "ಎರಡನೇ ಹಾಳೆಯಲ್ಲಿ ಸೇವಾ ತೆರಿಗೆ ವಿವರ ಕಾಣೆಯಾಗಿದೆ",
    PLATFORM: "ಪಾವತಿ ಬಾಕಿ ಉಳಿದಿದೆ",
    ORDER_COUNT: "ವೇದಿಕೆ",
    PROMO_CODE: "ಪ್ರೋಮೊ ಕೋಡ್",
    PICK_BOY: "ಪಿಕಪ್ ಬಾಯ್",
    CUST_MO_No: "ಗ್ರಾಹಕ ಮೊಬೈಲ್ ಸಂಖ್ಯೆ",
    SERVICE_ICONS: "ಸೇವಾ ಚಿಹ್ನೆಗಳು",
    REQUEST_CREATED_DATE: "ವಿನಂತಿಯನ್ನು ರಚಿಸಿದ ದಿನಾಂಕ ಮತ್ತು ಸಮಯ",
    PICKUP_REQUEST_DATE: "ಪಿಕಪ್ ವಿನಂತಿಸಿದ ದಿನಾಂಕ ಮತ್ತು ಸ್ಲಾಟ್",
    ORDER_CREATED_DATE: "ಆದೇಶ ದಿನಾಂಕ ಮತ್ತು ಸಮಯವನ್ನು ರಚಿಸಲಾಗಿದೆ",
    REPRINT_TAX_INVOICE: "ತೆರಿಗೆ ಸರಕುಪಟ್ಟಿ ಮರುಮುದ್ರಣ ಮಾಡಿ",
    SEMI_EXPRESS_RATE: "(ಸೆಮಿ ಎಕ್ಸ್‌ಪ್ರೆಸ್ - 1.5x)",
    EXPRESS_RATE: "(ಎಕ್ಸ್‌ಪ್ರೆಸ್ - 2x)",
    TOTAL_STICKERS: "ಒಟ್ಟು ಸ್ಟಿಕ್ಕರ್‌ಗಳು",
    DELIVERY_BOY: "ಹಂಚಿಕೆ ಅಥವ ವಿತರಣೆ ಮಾಡುವ ಹುಡುಗ",
    POST_QC: "ಕ್ಯೂಸಿ ಪೋಸ್ಟ್ ಮಾಡಿ",
    UNABLE_TO_GET_POST_QC_DATA: "ಪೋಸ್ಟ್ ಕ್ಯೂಸಿ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    GARMENT_PROCEEDED_FURTHER_FOR_POST_PROCESS:
      "ಪೋಸ್ಟ್ ಕ್ಯೂಸಿ ಪ್ರಕ್ರಿಯೆಗೆ ಗಾರ್ಮೆಂಟ್ ಮತ್ತಷ್ಟು ಮುಂದುವರಿಯಿತು",
    POST_QC_STATUS: "ಪೋಸ್ಟ್ ಕ್ಯೂಸಿ ಸ್ಥಿತಿ",
    POST_QC_REMARK: "ಪೋಸ್ಟ್ ಕ್ಯೂಸಿ ರಿಮಾರ್ಕ್",
    POST_QC_DATETIME: "ಕ್ಯೂಸಿ ಡೇಟ್‌ಟೈಮ್ ಪೋಸ್ಟ್ ಮಾಡಿ",
    POSTED_BY: "ಇವರಿಂದ ಪೋಸ್ಟ್ ಮಾಡಲಾಗಿದೆ",
    ADD_GARMENT_FOR_POST_QC: "ಪೋಸ್ಟ್ ಕ್ಯೂಸಿಗೆ ಗಾರ್ಮೆಂಟ್ ಸೇರಿಸಿ",
    LATEST_POSTQC_DETAILS: "ಇತ್ತೀಚಿನ ಪೋಸ್ಟ್‌ಕ್ಯೂಸಿ ವಿವರಗಳು",
    PICKUP_TIMESLOT: "ಪಿಕಪ್ ಟೈಮ್‌ಸ್ಲಾಟ್",
    UNABLE_TO_GET_STORE_NAME: "ಸ್ಟೋರ್ ನಾಮ್ ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    CANCEL_REASON_IS_REQUIRED: "ರದ್ದುಮಾಡು ಕ್ಷೇತ್ರದ ಅಗತ್ಯವಿದೆ",
    FACTORY_GATE_PASS: "ಫ್ಯಾಕ್ಟರಿ ಗೇಟ್ ಪಾಸ್ ಪಟ್ಟಿ",
    CUSTOMER_REMARK: "ಗ್ರಾಹಕರ ಟಿಪ್ಪಣಿ",
    STORE_OUT_DATE: "ದಿನಾಂಕವನ್ನು ಸಂಗ್ರಹಿಸಿ",
    LAST_UPDATED_ON: "ಕೊನೆಯದಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ",
    LAST_UPDATED_BY: "ಕೊನೆಯದಾಗಿ ನವೀಕರಿಸಲಾಗಿದೆ",
    FACTORY_IN_DATETIME: "ಡೇಟ್‌ಟೈಮ್‌ನಲ್ಲಿ ಕಾರ್ಖಾನೆ",
    GARMENTS_FACTORY_IN: "ಗಾರ್ಮೆಂಟ್ಸ್ ಫ್ಯಾಕ್ಟರಿ ಇನ್",
    GARMENTS_MISSING: "ಗಾರ್ಮೆಂಟ್ಸ್ ಕಾಣೆಯಾಗಿದೆ",
    BALANCED_GARMENTS: "ಸಮತೋಲಿತ ಉಡುಪುಗಳು",
    FACTORY_IN_DATE: "ಫ್ಯಾಕ್ಟರಿ ದಿನಾಂಕ",
    LOT_SIZE: "ಸಾಕಷ್ಟು ಗಾತ್ರ",
    STANDARD_RATE_CARD: "ಸ್ಟ್ಯಾಂಡರ್ಡ್ ರೇಟ್ ಕಾರ್ಡ್",
    ORDER_STATUS: "ಆದೇಶ ಸ್ಥಿತಿ",
    INVOICE_STATUS: "ಸರಕುಪಟ್ಟಿ ಸ್ಥಿತಿ",
    STOREINDATE: "ದಿನಾಂಕದಂದು ಸಂಗ್ರಹಿಸಿ",
    FACTORYOUTDATE: "ಫ್ಯಾಕ್ಟರಿ ದಿನಾಂಕ",
    PLEASE_SELECT_PAYMENT_MODE: "ದಯವಿಟ್ಟು ಪಾವತಿ ಮೋಡ್ ಆಯ್ಕೆಮಾಡಿ",
    SELECT_PACKING_MATERIAL:
      "ನೀವು 99 ರವರೆಗೆ ಪ್ಯಾಕಿಂಗ್ ವಸ್ತುಗಳನ್ನು ಮಾತ್ರ ಆಯ್ಕೆ ಮಾಡಬಹುದು",
    SOMETHING_WENT_WRONG: "ಏನೋ ತಪ್ಪಾಗಿದೆ",
    UNABLE_TO_GET_PAYMENT_METHOD_DATA:
      "ಪಾವತಿ ವಿಧಾನದ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಿಲ್ಲ",
    ARE_YOU_SURE_YOU_WANT_TO_MARK_ORDER_AS_COMPLETED:
      "ಆದೇಶವನ್ನು ಪೂರ್ಣಗೊಂಡಂತೆ ಗುರುತಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
    ORDER_MARKED_AS_COMPLETED: "ಆದೇಶ ಪೂರ್ಣಗೊಂಡಿದೆ ಎಂದು ಗುರುತಿಸಲಾಗಿದೆ",
    UNABLE_TO_MARK_ORDER_AS_COMPLETED:
      "ಪೂರ್ಣಗೊಂಡಂತೆ ಆದೇಶವನ್ನು ಗುರುತಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
    EDC_CONFIGURATION: "ಇಡಿಸಿ ಸಾಧನವು ಈ ಅಂಗಡಿಯೊಂದಿಗೆ ಸಂಪರ್ಕ ಹೊಂದಿಲ್ಲ",
    ALTER_PHONE_NUMBER: "ಪರ್ಯಾಯ ದೂರವಾಣಿ ಸಂಖ್ಯೆ",
    // ATTENDANCE: "ಹಾಜರಾತಿ",
    // ATTENDANCE_REPORT: "ಹಾಜರಾತಿ ವರದಿ",
    // OTHER_TYPE: "ಇತರೆ ಪ್ರಕಾರ",
    // PLEASE_ENTER_OTHER_TYPE: "ದಯವಿಟ್ಟು ಇತರ ಪ್ರಕಾರವನ್ನು ನಮೂದಿಸಿ",
    // ENTER_OTHER_TYPE: "ಇತರೆ ಪ್ರಕಾರವನ್ನು ನಮೂದಿಸಿ",
  },
  TELUGU: {
    SELECT_SERVICE: "సేవను ఎంచుకోండి",
    SELECT_FACTORY: "ఫ్యాక్టరీని ఎంచుకోండి",
    SPLIT_ORDER: "స్ప్లిట్ ఆర్డర్",
    CUSTOMER_NAME: "వినియోగదారుని పేరు",
    ORDER_DATE: "ఆర్డర్ తేదీ",
    TOTAL_GARMENTS: "మొత్తం వస్త్రాలు",
    SPLIT_ORDER_NUMBER: "స్ప్లిట్ ఆర్డర్ సంఖ్య",
    TAG_NUMBER: "ట్యాగ్ సంఖ్య",
    GARMENT_NAME: "వస్త్ర పేరు",
    ADD_ON_SERVICES: "యాడ్-ఆన్ సేవలు",
    GENERATE_GATE_PASS: "గేట్ పాస్ సృష్టించండి",
    GATE_PASS_NUMBER: "గేట్ పాస్ సంఖ్య",
    STORE_NAME: "స్టోర్ పేరు",
    FACTORY_NAME: "ఫ్యాక్టరీ పేరు",
    STORE_GARMENTS_OUT: "గార్మెంట్స్ స్టోర్",
    TOTAL_SPLIT_NUMBER: "మొత్తం స్ప్లిట్ సంఖ్య",
    GATE_PASS_DATE: "గేట్ పాస్ తేదీ",
    STIKER_NUMBER: "స్టిక్కర్ సంఖ్య",
    PLEASESCAN_ADD_STICKER: "దయచేసి స్టిక్కర్‌ను స్కాన్ చేయండి / జోడించండి",
    ENTER_SCAN_STICKER: "స్టిక్కర్‌ను నమోదు చేయండి / స్కాన్ చేయండి",
    CUSTOMER_CONTACT: "కస్టమర్ పరిచయం",
    DELIVERY_TYPE: "డెలివరీ రకం",
    SPLIT_ORDER_NO: "స్ప్లిట్ ఆర్డర్ నం",
    GATE_PASS_NO: "గేట్ పాస్ నం",
    DELIVERY_DATE: "డెలివరీ తేదీ",
    GARMENTS_IN_PROCESS: "వస్త్రాలు ప్రక్రియలో ఉన్నాయి",
    PACKED_GARMENTS: "ప్యాక్ చేసిన వస్త్రాలు",
    PACKING_MATERIAL: "ప్యాకింగ్ పదార్థం",
    STICKER_STATUS: "స్టిక్కర్ స్థితి",
    SPLIT_ORDER_STATUS: "స్ప్లిట్ ఆర్డర్ స్థితి",
    GARMENTS_PACKED_INFORMATION: "గార్మెంట్స్ ప్యాక్ చేసిన సమాచారం",
    USER_EXPENSE_ENTRY: "వినియోగదారు ఖర్చు ఎంట్రీ",
    USER_NAME: "వినియోగదారు పేరు",
    EXPENSE_DATE: "ఖర్చు తేదీ",
    AMOUNT: "మొత్తం",
    REMARKS: "వ్యాఖ్యలు",
    ARE_YOU_SURE: "మీరు చెప్పేది నిజమా ?",
    ADD_EXPENSE: "ఖర్చు జోడించండి",
    APPLY: "వర్తించు",
    SELECT_EXPENSE_TYPE: "ఖర్చు రకాన్ని ఎంచుకోండి",
    PLEASE_ENTER_AMOUNT: "దయచేసి మొత్తాన్ని నమోదు చేయండి",
    ENTER_AMOUNT: "మొత్తాన్ని నమోదు చేయండి",
    ENTER_REMARKS: "వ్యాఖ్యలను నమోదు చేయండి",
    GARMENTS_PENDING_TO_SEND_TO_FACTORY:
      "ఫ్యాక్టరీకి పంపడానికి వస్త్రాలు పెండింగ్‌లో ఉన్నాయి",
    ORDERS_PENDING_TO_SEND_TO_FACTORY:
      "ఫ్యాక్టరీకి పంపాలని ఆదేశాలు పెండింగ్‌లో ఉన్నాయి",
    SPILT_ORDERS_RECEIVED_FROM_FACTORY:
      "ఫ్యాక్టరీ నుండి స్వీకరించబడిన చిందిన ఆదేశాలు",
    PENDING_FOR_NO_OF_DAYS: "నో డేస్ కోసం పెండింగ్‌లో ఉంది",
    STORE_GARMENT_INVENTORY: "గార్మెంట్ ఇన్వెంటరీని నిల్వ చేయండి",
    GARMENTS_RECEIVED_FROM_FACTORY: "ఫ్యాక్టరీ నుండి స్వీకరించిన వస్త్రాలు",
    FACTORY_TRANSFER_IN: "ఫ్యాక్టరీ బదిలీ-ఇన్",
    SELECT_STORE: "స్టోర్ ఎంచుకోండి",
    GET_MISSING_GARMENTS: "తప్పిపోయిన వస్త్రాలను పొందండి",
    TAGS_FROM_STORES: "దుకాణాల నుండి టాగ్లు",
    SCANNING: "స్కానింగ్",
    TAGS_TO_THE_FACTORY: "ఫ్యాక్టరీకి టాగ్లు",
    MISSING_GARMENTS: "వస్త్రాలు లేవు",
    SCAN_COMPLETE: "స్కాన్ పూర్తయింది",
    SEARCH_HERE: "ఇక్కడ శోధించండి",
    RESORTING_AND_PACKAGING: "రిసార్టింగ్ & ప్యాకేజింగ్",
    ORDER_NUMBER: "ఆర్డర్ సంఖ్య",
    SRORE_NAME: "Srore పేరు",
    NO_OF_GARMENTS: "వస్త్రాలు లేవు",
    GARMENT_LEVEL_INFO: "వస్త్ర స్థాయి సమాచారం",
    SELECTED_PENDING_GARMENT: "ఎంచుకున్న పెండింగ్ వస్త్రం",
    PREVIOUSLY_GARMENTS_PACKED_IN: "గతంలో వస్త్రాలు ప్యాక్ చేయబడ్డాయి",
    PENDING_GARMENTS_TO_BE_PACKED_IN:
      "ప్యాక్ చేయాల్సిన వస్త్రాలు పెండింగ్‌లో ఉన్నాయి",
    GENERATE_STICKER: "స్టిక్కర్‌ను రూపొందించండి",
    PRINT_GENERATE_STICKER: "ప్రింట్ జనరేట్ స్టిక్కర్",
    SR_NO: "సీనియర్ నం",
    GARMENTS_PACKED: "వస్త్రాలు ప్యాక్ చేయబడ్డాయి",
    PENDING_GARMENTS: "పెండింగ్ వస్త్రాలు",
    PACKING_GARMENTS: "వస్త్రాలు ప్యాకింగ్",
    FACTORY_TRANSFER_OUT: "ఫ్యాక్టరీ బదిలీ",
    FACTORY_GATE_PASS_NUMBER: "ఫ్యాక్టరీ గేట్ పాస్ సంఖ్య:",
    STORE_ADDRESS: "స్టోర్ చిరునామా",
    CREATED_BY: "సృష్టికర్త",
    S_NO: "ఎస్. లేదు",
    SPLIT_ORDER_ID: "స్ప్లిట్ ఆర్డర్ ఐడి",
    NUMBER_OF_GARMENTS: "వస్త్రాల సంఖ్య",
    PACKED: "ప్యాక్ చేయబడింది",
    ORDER_LOT_SIZE: "లాట్ సైజును ఆర్డర్ చేయండి",
    PREVIOUSLY_SENT: "గతంలో పంపారు",
    SENDING_TODAY: "ఈ రోజు పంపుతోంది",
    PENDING: "పెండింగ్‌లో ఉంది",
    BAG: "బాగ్",
    HANGER: "హ్యాంగర్",
    BLANKET: "దుప్పటి",
    SHOE: "షూ",
    CARPET: "కార్పెట్",
    BLAZER: "బ్లేజర్",
    OTHERS: "ఇతరులు",
    STICKER_NO: "స్టిక్కర్ నం",
    SPLLIT_ORDER_NO: "స్ప్లిట్ ఆర్డర్ నం",
    PACKING_MATERIAL_NAME: "ప్యాకింగ్ మెటీరియల్ పేరు",
    PRE_QC_RESPONSE_LIST: "ప్రీ-క్యూసి ప్రతిస్పందన జాబితా",
    SELECT_QC_STATUS: "QC స్థితిని ఎంచుకోండి",
    TAG: "ట్యాగ్",
    CONTACT: "సంప్రదించండి",
    QC_REQUEST: "Qc అభ్యర్థన",
    TIME: "సమయం",
    QC_REASON: "QC కారణం",
    REMARK: "వ్యాఖ్య",
    QC_PENDING_FROM: "QC పెండింగ్ నుండి",
    QC_STATUS: "QC స్థితి",
    ENTER_SCAN_TAG_NUMBER: "ట్యాగ్ సంఖ్యను నమోదు చేయండి / స్కాన్ చేయండి",
    GARMENT_HISTORY: "వస్త్ర చరిత్ర",
    GARMENT_INFORMATION: "వస్త్ర సమాచారం",
    GARMENT_STATUS: "వస్త్ర స్థ���తి",
    GATE_PASS: "ప్రవేశ అనుమతి",
    BRAND: "బ్రాండ్",
    COLOR: "రంగు",
    ADD_ON_SERVICE: "సేవలో జోడించు",
    SPECIAL_INSTRUCTION: "ప్రత్యేక సూచన",
    LATEST_QC_DETAILS: "తాజా QC వివరాలు",
    IMAGES: "చిత్రాలు",
    REASON: "కారణం",
    SELECT_REASON: "కారణం ఎంచుకోండి",
    COMMUNICATION_HISTORY: "కమ్యూనికేషన్ చరిత్ర",
    SELECT_SCAN_GARMENT: "వస్త్రాన్ని ఎంచుకోండి / స్కాన్ చేయండి",
    GARMENT_FOR_QC: "క్యూసి కోసం వస్త్రాలు",
    SCANNED_ITEM: "స్కాన్ చేసిన అంశం",
    ADDON_SERVICE: "యాడ్ఆన్ సేవ",
    CONTACT_NO: "సంప్రదంచాల్సిన నెం",
    STICKER_INFORMATION: "స్టిక్కర్ సమాచారం",
    DOWNLOAD_GATE_PASS: "గేట్ పాస్ డౌన్లోడ్",
    STORE_GATE_PASS_NUMBER: "గేట్ పాస్ సంఖ్యను నిల్వ చేయండి",
    FACTORY_ADDRESS: "ఫ్యాక్టరీ చిరునామా",
    PICKUP_DATE: "తేదీ ఎంచుకొని",
    REFFERAL_PROGRAM_NAME: "రెఫరల్ ప్రోగ్రామ్ పేరు",
    REFFERAL_BONUS_AMOUNT: "రెఫరల్ బోనస్ మొత్తం",
    REFEREE_BONUS_AMOUNT: "రిఫరీ బోనస్ మొత్తం",
    YOU_CAN_ONLY_UPDATE_THE_LAST_ADDED_PROGRAM:
      "మీరు చివరిగా జోడించిన ప్రోగ్రామ్‌ను మాత్రమే నవీకరించగలరు",
    UNABLE_TO_GET_LIST_OF_REFFERAL_BONUS_CONFIGURATION_DATA:
      "రిఫరల్ బోనస్ కాన్ఫిగరేషన్ డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_SUBMIT_THE_DATA: "డేటాను సమర్పించడం సాధ్యం కాలేదు",
    ADD_REFFERAL_BONUS_CONFIGURATION: "రెఫరల్ బోనస్ కాన్ఫిగరేషన్‌ను జోడించండి",
    VIEW_REFFERAL_BONUS_CONFIGURATION: "రెఫరల్ బోనస్ కాన్ఫిగరేషన్‌ను చూడండి",
    EDIT_REFFERAL_BONUS_CONFIGURATION: "రెఫరల్ బోనస్ కాన్ఫిగరేషన్‌ను సవరించండి",
    PLEASE_ENTER_REFFERAL_PROGRAM_NAME:
      "దయచేసి రెఫరల్ ప్రోగ్రామ్ పేరును నమోదు చేయండి",
    ENTER_REFFERAL_PROGRAM_NAME: "రెఫరల్ ప్రోగ్రామ్ పేరును నమోదు చేయండి",
    PLEASE_ENTER_REFERRAL_BONUS_AMOUNT:
      "దయచేసి రెఫరల్ బోనస్ మొత్తాన్ని నమోదు చేయండి",
    ENTER_REFERRAL_BONUS_AMOUNT: "రెఫరల్ బోనస్ మొత్తాన్ని నమోదు చేయండి",
    PLEASE_ENTER_REFEREE_BONUS_AMOUNT:
      "దయచేసి రిఫరీ బోనస్ మొత్తాన్ని నమోదు చేయండి",
    ENTER_REFEREE_BONUS_AMOUNT: "రిఫరీ బోనస్ మొత్తాన్ని నమోదు చేయండి",
    ENTER_BONUS_AMOUNT_VALIDITY_DAYS:
      "బోనస్ మొత్తం చెల్లుబాటు (రోజులు) నమోదు చేయండి",
    ARE_YOU_SURE_YOU_WANT_TO_CREATE_THIS_PROGRAM_AS_PREVIOUS_PROGRAMS_WILL_GET_DEACTIVATED:
      "మునుపటి ప్రోగ్రామ్‌లు నిష్క్రియం అవుతాయి కాబట్టి మీరు ఖచ్చితంగా ఈ ప్రోగ్రామ్‌ను సృష్టించాలనుకుంటున్నారా?",
    FILE_NAME: "ఫైల్ పేరు",
    TOTAL_RECORDS: "మొత్తం రికార్డులు",
    SUCCEEDED: "విజయవంతమైంది",
    DOWNLOAD: "డౌన్‌లోడ్",
    FAILED: "విఫలమైంది",
    UNABLE_TO_GET_LIST_OF_EMPLOYEE_CREDIT_DATA:
      "ఉద్యోగుల క్రెడిట్ డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_UPLOAD_THE_FILE: "ఫైల్‌ను అప్‌లోడ్ చేయడం సాధ్యం కాలేదు",
    DOWNLOAD_FORMAT: "ఫార్మాట్‌ను డౌన్‌లోడ్ చేయండి",
    UPLOAD_FILE: "ఫైల్‌ను అప్‌లోడ్ చేయండి",
    ADD_EMPLOYEE_CREDITS: "ఉద్యోగుల క్రెడిట్లను జోడించండి",
    VIEW_EMPLOYEE_CREDITS: "ఉద్యోగుల క్రెడిట్‌లను చూడండి",
    EDIT_EMPLOYEE_CREDITS: "ఉద్యోగుల క్రెడిట్‌లను సవరించండి",
    UPLOAD_EMPLOYEE_CREDIT_CONFIGURATION_FILE:
      "ఉద్యోగి క్రెడిట్ కాన్ఫిగరేషన్ ఫైల్‌ను అప్‌లోడ్ చేయండి",
    PLEASE_SELECT_FILE: "దయచేసి ఫైల్ను ఎంచుకోండి",
    CLICK_TO_UPLOAD: "అప్‌లోడ్ చేయడానికి క్లిక్ చేయండి",
    UPLOAD: "అప్‌లోడ్ చేయండి",
    UNABLE_TO_LOAD_PACKAGES: "ప్యాకేజీలను లోడ్ చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_CUSTOMERS:
      "వినియోగదారుల జాబితాను పొందడం సాధ్యం కాలేదు",
    TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED:
      "లావాదేవీ విజయవంతంగా ప్రాసెస్ చేయబడింది",
    UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN:
      "లావాదేవీని ప్రాసెస్ చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM:
      "ప్రాసెస్ చేయడం సాధ్యం కాలేదు. దయచేసి సాంకేతిక బృందాన్ని సంప్రదించండి",
    PACKAGE_PURCHASED_SUCCESSFULLY: "ప్యాకేజీ విజయవంతంగా కొనుగోలు చేయబడింది",
    UNABLE_TO_BUY_PACKAGE: "ప్యాకేజీ కొనడం సాధ్యం కాలేదు",
    BUY: "కొనుగోలు",
    PLEASE_SELECT_CUSTOMER: "దయచేసి కస్టమర్‌ను ఎంచుకోండి",
    SEARCH_CUSTOMER: "కస్టమర్‌ను శోధించండి",
    MONEY_TYPE: "డబ్బు రకం",
    ADDED_BY: "ద్వారా జోడించబడింది",
    ADDED_ON: "ఆన్ చేయబడింది",
    UNABLE_TO_GET_LIST_OF_ADVANCE_PAYMENT_DATA:
      "అడ్వాన్స్ చెల్లింపు డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    ADVANCE_COLLECTED_SUCCESSFULLY: "అడ్వాన్స్ విజయవంతంగా సేకరించబడింది",
    UNABLE_TO_ADD_AMOUNT_TO_LKWALLET:
      "LKWallet కు మొత్తాన్ని జోడించడం సాధ్యం కాలేదు",
    ADD_ADVANCE_PAYMENT: "అడ్వాన్స్ చెల్లింపును జోడించండి",
    VALID_FROM: "నుండి చెల్లుబాటు అయ్యే",
    VALID_TO: "చెల్లుతుంది",
    UNABLE_TO_GET_LIST_OF_COMPENSATION_CONFIGURATION_DATA:
      "పరిహారం కాన్ఫిగరేషన్ డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_ADD_THE_DATA: "డేటాను జోడించడం సాధ్యం కాలేదు",
    COMPENSATION_CONFIGURATION: "పరిహారం ఆకృతీకరణ",
    ADD_COMPENSATION_CONFIGURATION: "పరిహార ఆకృతీకరణను జోడించండి",
    VIEW_COMPENSATION_CONFIGURATION: "పరిహార ఆకృతీకరణను చూడండి",
    EDIT_COMPENSATION_CONFIGURATION: "పరిహార ఆకృతీకరణను సవరించండి",
    VALID_FROM_AND_TO_DATE: "నుండి తేదీ వరకు చెల్లుతుంది",
    PLEASE_SELECT_VALID_FROM_AND_TO_DATE:
      "దయచేసి చెల్లుబాటు అయ్యే నుండి తేదీ వరకు ఎంచుకోండి",
    PLEASE_ENTER_REMARK: "దయచేసి వ్యాఖ్యను నమోదు చేయండి",
    ENTER_REMARK: "వ్యాఖ్యను నమోదు చేయండి",
    UNABLE_TO_GET_LIST_OF_REFUND_DATA:
      "వాపసు డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_ADD_REFUND_DATA: "వాపసు డేటాను జోడించడం సాధ్యం కాలేదు",
    ADD_REFUND: "వాపసు జోడించండి",
    VIEW_REFUND: "వాపసు చూడండి",
    DATA_SUCCESSFULLY_SUBMITTED: "డేటా విజయవంతంగా సమర్పించబడింది",
    UNABLE_TO_SUBMIT_TAG_DATA: "ట్యాగ్ డేటాను సమర్పించడం సాధ్యం కాలేదు",
    UNABLE_TO_LOAD_TAG_DATA: "ట్యాగ్ డేటాను లోడ్ చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_TAG_DATA: "ట్యాగ్ డేటాను పొందడం సాధ్యం కాలేదు",
    PLEASE_ENTER_TAG_NO: "ట్యాగ్ నెం",
    FACTORY_POST_QC: "ఫ్యాక్టరీ పోస్ట్-క్యూసి",
    PLEASE_SCAN_ADD_TAG_NUMBER: "ట్యాగ్ నంబర్‌ను స్కాన్ చేయండి / జోడించండి",
    SELECT_STATUS: "స్థితిని ఎంచుకోండి",
    PLEASE_ENTER_REMARKS: "దయచేసి వ్యాఖ్యలను నమోదు చేయండి",
    UNABLE_TO_LOAD_PRE_QC_RESPONSES:
      "ప్రీ-క్యూసి ప్రతిస్పందనలను లోడ్ చేయడం సాధ్యం కాలేదు",
    FACTORY_PRE_QC: "ఫ్యాక్టరీ ప్రీ-క్యూసి",
    ADD_GARMENT_FOR_QC: "QC కోసం వస్త్రాన్ని జోడించండి",
    DATA_IS_SUCCESSFULLY_SAVED: "డేటా విజయవంతంగా సేవ్ చేయబడింది",
    GARMENT_SHOULD_BE_IN_FACTORY_TO_BE_ADDED_FOR_QC:
      "క్యూసి కోసం జోడించడానికి వస్త్రం ఫ్యాక్టరీలో ఉండాలి",
    UNABLE_TO_GET_GARMENT_DETAILS: "వస్త్ర వివరాలు పొందలేకపోయాము",
    GET_GARMENT_HISTORY: "వస్త్ర చరిత్ర పొందండి",
    PLEASE_SELECT_REASON: "దయచేసి కారణాన్ని ఎంచుకోండి",
    UNABLE_TO_GET_CORPORATE_DATA: "కార్పొరేట్ డేటాను పొందడం సాధ్యం కాలేదు",
    GARMENTS_AT_QC: "QC వద్ద వస్త్రాలు",
    GARMENTS_IN_POST_QC: "పోస్ట్ క్యూసిలో వస్త్రాలు",
    UNABLE_TO_GET_FACTORY_GARMENTS_INVENTORY_DATA:
      "ఫ్యాక్టరీ గార్మెంట్స్ ఇన్వెంటరీ డేటాను పొందడం సాధ్యం కాలేదు",
    GARMENT_INVENTORY_AT_FACTORY: "ఫ్యాక్టరీలో గార్మెంట్ ఇన్వెంటరీ",
    DASHBOARD: "డాష్బోర్డ్",
    CUSTOMERS: "వినియోగదారులు",
    MANAGE_CUSTOMERS: "వినియోగదారులను నిర్వహించండి",
    PACKAGE_CONFIGURATION: "ప్యాకేజీ ఆకృతీకరణ",
    REFERRAL_BONUS_CONFIGURATION: "రెఫరల్ బోనస్ కాన్ఫిగరేషన్",
    EMPLOYEE_CREDITS: "ఉద్యోగుల క్రెడిట్స్",
    BUY_PACKAGE: "ప్యాకేజీ కొనండి",
    ADVANCE_PAYMENTS: "ముందస్తు చెల్లింపులు",
    COMPENSATION: "పరిహారం",
    REFUND: "వాపసు",
    MASTER: "మాస్టర్",
    STORE: "స్టోర్",
    FACTORY: "ఫ్యాక్టరీ",
    CORPORATE: "కార్పొరేట్",
    CITY: "నగరం",
    STATE: "రాష్ట్రం",
    DESIGNATION: "హోదా",
    GARMENT_BRAND: "గార్మెంట్ బ్రాండ్",
    GARMENT_SIZE: "వస్త్ర పరిమాణం",
    KYC_DOCUMENT_TYPE: "KYC పత్ర రకం",
    SERVICE: "సేవ",
    GARMENT: "వస్త్రం",
    REWASH_REASON: "రీవాష్ కారణం",
    OPERATION_INSTRUCTION: "ఆపరేషన్ ఇన్స్ట్రక్షన్",
    EXPENSE_TYPE: "ఖర్చు రకం",
    ORDERS: "ఆదేశాలు",
    PICKUPS: "పికప్‌లు",
    MANAGE_ORDERS: "ఆర్డర్‌లను నిర్వహించండి",
    READY_FOR_DELIVERY_OR_INVOICE: "డెలివరీ / ఇన్వాయిస్ కోసం సిద్ధంగా ఉంది",
    DELIVERIES: "డెలివరీలు",
    TRANSFER_IN_OUT: "ఇన్ / అవుట్ బదిలీ",
    TRANSFER_OUT: "బదిలీ",
    GATE_PASS_LIST: "గేట్ పాస్ జాబితా",
    TRANSFER_IN: "ఇన్ బదిలీ",
    PRE_QC_RESPONSES: "ప్రీ-క్యూసి స్పందన",
    USER_EXPENSES: "వినియోగదారు ఖర్చులు",
    INVENTORY: "జాబితా",
    SETTINGS: "సెట్టింగులు",
    AUTHENTICATION: "ప్రామాణీకరణ",
    USERS: "వినియోగదారులు",
    ROLES: "పాత్రలు",
    ROLE_OPERATIONS: "పాత్ర కార్యకలాపాలు",
    CONFIGURATION: "ఆకృతీకరణ",
    TIME_CARD: "టైమ్ కార్డ్",
    RATE_CARD: "రేట్ కార్డు",
    DISCOUNT: "డిస్కౌంట్",
    CASH_COUPON: "నగదు కూపన్",
    PACKAGING_CHARGES: "ప్యాకేజింగ్ ఛార్జీలు",
    DELIVERY_CHARGES: "డెలివరీ ఛార్జీలు",
    BANNER: "బ్యానర్",
    NOTICE_BOARD: "నోటీసు బోర్డు",
    FINGER_SCAN: "ఫింగర్ స్కాన్",
    COLLECT_FINGERPRINTS: "వేలిముద్రలను సేకరించండి",
    QC_RESPONSES: "QC స్పందనలు",
    PRE_QC: "ప్రీ క్యూసి",
    POST_QC: "QC ను పోస్ట్ చేయండి",
    TODAYS_HIGHLIGHTS: "నేటి ముఖ్యాంశాలు",
    ORDER_VS_ORDER_AMOUNT: "ఆర్డర్లు / ఆర్డర్ మొత్తం",
    INVOICE_VS_INVOICE_AMOUNT: "ఇన్వాయిస్ / ఇన్వాయిస్ మొత్తం",
    STORE_INVENTORY: "స్టోర్ ఇన్వెంటరీ",
    OTHER_STATASTICS: "ఇతర గణాంకాలు",
    PROCESSED: "ప్రాసెస్ చేయబడింది",
    SOILED: "మట్టి",
    AMOUNT_DUE_FOR_COLLECTION: "సేకరణకు చెల్లించాల్సిన మొత్తం",
    ORDERS_READY_FOR_INVOICE: "ఇన్వాయిస్ కోసం ఆర్డర్లు సిద్ధంగా ఉన్నాయి",
    DELIVERY_PERSON: "రవాణా చేయు మనిషి",
    WALK_IN: "వాక్-ఇన్",
    PENDING_TO_TRANSFER_OUT_TO_FACTORY:
      "ఫ్యాక్టరీకి బదిలీ చేయడానికి పెండింగ్‌లో ఉంది",
    NO_OF_ORDERS: "ఆర్డర్ల సంఖ్య",
    PENDING_PICKUPS: "పికప్‌లు పెండింగ్‌లో ఉన్నాయి",
    DATE: "తేదీ",
    ORDERS_DUE_FOR_DELIVERY: "డెలివరీ కోసం ఆర్డర్లు",
    FIRST_NAME: "మొదటి పేరు",
    LAST_NAME: "చివరి పేరు",
    GENDER: "లింగం",
    EMAIL: "ఇమెయిల్",
    PHONE: "ఫోన్",
    TYPE: "టైప్ చేయండి",
    BILLING_CYCLE: "బైలింగ్ సైకిల్",
    ACTIONS: "చర్యలు",
    VIEW: "చూడండి",
    EDIT: "సవరించండి",
    DELETE: "తొలగించు",
    SEARCH: "వెతకండి",
    ADD_CUSTOMER: "కస్టమర్‌ను జోడించండి",
    ADD: "జోడించు",
    CUSTOMER: "కస్టమర్",
    WALLET_CASH: "వాలెట్ క్యాష్",
    WALLET_BONUS: "వాలెట్ బోనస్",
    GENERAL: "జనరల్",
    GENERAL_CUSTOMER_INFORMATION: "సాధారణ కస్టమర్ సమాచారం",
    ADDRESS: "చిరునామా",
    CUSTOMER_ADDRESSES: "కస్టమర్ చిరునామాలు",
    VERIFICATION: "ధృవీకరణ",
    VERIFY_PHONE_AND_EMAIL: "వెర్ఫీ ఫోన్ మరియు ఇమెయిల్",
    PLEASE: "దయచేసి",
    SELECT: "ఎంచుకోండి",
    ENTER: "నమోదు చేయండి",
    VALID: "చెల్లుతుంది",
    PHONE_NUMBER: "ఫోను నంబరు",
    DATE_OF_BIRTH: "పుట్టిన తేది",
    ANNIVERSARY_DATE: "వార్షికోత్సవ తేదీ",
    REFERRAL_CODE: "రెఫరల్ కోడ్",
    CUSTOMER_TYPE: "కస్టమర్ రకం",
    GSTIN: "GSTIN",
    ACTIVE: "యాక్టివ్",
    STATUS: "స్థితి",
    YES: "అవును",
    NO: "లేదు",
    CANCEL: "రద్దు చేయండి",
    SUBMIT: "సమర్పించండి",
    ID: "ID",
    PINCODE: "పిన్ కోడ్",
    ADRESSES_OF: "యొక్క చిరునామాలు",
    HOME: "హోమ్",
    OFFICE: "కార్యాలయం",
    OTHER: "ఇతర",
    LONGITUDE: "రేఖాంశం",
    LATITUDE: "అక్షాంశం",
    IS_DEFAULT: "దీన్ని డిఫాల్ట్‌గా సెట్ చేయండి",
    PLEASE_ENTER_FIRST_NAME: "దయచేసి మొదటి పేరును నమోదు చేయండి",
    ENTER_FIRST_NAME: "మొదటి పేరును నమోదు చేయండి",
    PLEASE_ENTER_LAST_NAME: "దయచేసి చివరి పేరును నమోదు చేయండి",
    ENTER_LAST_NAME: "చివరి పేరును నమోదు చేయండి",
    PLEASE_ENTER_EMAIL: "దయచేసి ఇమెయిల్‌ను నమోదు చేయండి",
    PLEASE_ENTER_VALID_EMAIL: "దయచేసి చెల్లుబాటు అయ్యే ఇమెయిల్‌ను నమోదు చేయండి",
    ENTER_EMAIL: "ఇమెయిల్ నమోదు చేయండి",
    PLEASE_ENTER_PHONE_NUMBER: "దయచేసి ఫోన్ నంబర్‌ను నమోదు చేయండి",
    PLEASE_ENTER_VALID_PHONE_NUMBER:
      "దయచేసి చెల్లుబాటు అయ్యే ఫోన్ నంబర్‌ను నమోదు చేయండి",
    ENTER_PHONE_NUMBER: "ఫోన్ నంబర్‌ను నమోదు చేయండి",
    PLEASE_SELECT_GENDER: "దయచేసి లింగం ఎంచుకోండి",
    SELECT_DATE: "తేదీని ఎంచుకోండి",
    ENTER_REFERRAL_CODE: "రెఫరల్ కోడ్‌ను నమోదు చేయండి",
    PLEASE_SELECT_CUSTOMER_TYPE: "దయచేసి కస్టమర్ రకాన్ని ఎంచుకోండి",
    SELECT_CUSTOMER_TYPE: "కస్టమర్ రకాన్ని ఎంచుకోండి",
    SELECT_CORPORATE: "కార్పొరేట్ ఎంచుకోండి",
    PLEASE_SELECT_BILLING_CYCLE: "దయచేసి బిల్లింగ్ సైకిల్‌ని ఎంచుకోండి",
    SELECT_BILLING_CYCLE: "బిల్లింగ్ సైకిల్ ఎంచుకోండి",
    ENTER_VALID_GSTIN: "చెల్లుబాటు అయ్యే GSTIN ని నమోదు చేయండి",
    ENTER_GSTIN: "GSTIN ని నమోదు చేయండి",
    PLEASE_SELECT_STATUS: "దయచేసి స్థితిని ఎంచుకోండి",
    ADD_ADDRESS: "చిరునామాను జోడించండి",
    VIEW_ADDRESS: "చిరునామాను చూడండి",
    EDIT_ADDRESS: "చిరునామాను సవరించండి",
    PLEASE_SELECT_ADDRESS_TYPE: "దయచేసి చిరునామా రకాన్ని ఎంచుకోండి",
    SELECT_TYPE: "రకం ఎంచుకోండి",
    ENTER_LONGITUDE: "రేఖాంశాన్ని నమోదు చేయండి",
    ENTER_LATITUDE: "అక్షాంశాన్ని నమోదు చేయండి",
    PLEASE_SELECT_STATE: "దయచేసి రాష్ట్రాన్ని ఎంచుకోండి",
    SELECT_STATE: "రాష్ట్రం ఎంచుకోండి",
    PLEASE_SELECT_CITY: "దయచేసి నగరాన్ని ఎంచుకోండి",
    SELECT_CITY: "నగరాన్ని ఎంచుకోండి",
    PLEASE_ENTER_PINCODE: "దయచేసి పిన్‌కోడ్‌ను నమోదు చేయండి",
    ENTER_PINCODE: "పిన్‌కోడ్‌ను నమోదు చేయండి",
    HOUSE_NO: "హౌస్ నం. / ఫ్లాట్ నం. / ఫ్లోర్ / స్ట్రీట్",
    PLEASE_ENTER_HOUSE_NO:
      "దయచేసి హౌస్ నంబర్ / ఫ్లాట్ నం / ఫ్లోర్ / స్ట్రీట్ నమోదు చేయండి",
    ENTER_HOUSE_NO: "హౌస్ నం / ఫ్లాట్ నం / ఫ్లోర్ / స్ట్రీట్ నమోదు చేయండి",
    LANDMARK: "మైలురాయి",
    ENTER_LANDMARK: "ల్యాండ్‌మార్క్‌ను నమోదు చేయండి",
    HOW_TO_REACH: "ఎలా చేరుకోవాలి",
    ENTER_HOW_TO_REACH: "ఎలా చేరుకోవాలో నమోదు చేయండి",
    PLEASE_ENTER_ADDRESS: "దయచేసి చిరునామాను నమోదు చేయండి",
    ENTER_ADDRESS: "చిరునామాను నమోదు చేయండి",
    UPDATE: "నవీకరణ",
    PLEASE_ENTER_OTP: "దయచేసి OTP ని నమోదు చేయండి",
    ENTER_OTP: "OTP ని నమోదు చేయండి",
    VERIFY: "ధృవీకరించండి",
    DIDNT_RECIEVED_ANYTHIG: "ఏదైనా స్వీకరించలేదా?",
    RESEND_OTP: "OTP ను మళ్ళీ పంపు",
    NOT_VERIFIED: "తనిఖీ చెయ్యబడలేదు",
    VERIFY_PHONE_NUMBER: "ఫోన్ నంబర్‌ను ధృవీకరించండి",
    VERIFIED: "ధృవీకరించబడింది",
    PACKAGE_NAME: "ప్యాకేజీ పేరు",
    PACKAGE_AMOUNT: "ప్యాకేజీ మొత్తం",
    BONUS_AMOUNT: "బోనస్ మొత్తం",
    BONUS_VALIDITY: "బోనస్ చెల్లుబాటు",
    TITLE: "శీర్షిక",
    DESCRIPTION: "వివరణ",
    ADD_PACKAGE_CONFIGURATION: "ప్యాకేజీ ఆకృతీకరణను జోడించండి",
    DELETE_SELECTED: "ఎంచుకున్నవాటిని రద్దు చేయుట",
    VIEW_PACKAGE_CONFIGURATION: "ప్యాకేజీ ఆకృతీకరణను చూడండి",
    EDIT_PACKAGE_CONFIGURATION: "ప్యాకేజీ ఆకృతీకరణను సవరించండి",
    PLEASE_ENTER_PACKAGE_NAME: "దయచేసి ప్యాకేజీ పేరును నమోదు చేయండి",
    ENTER_PACKAGE_NAME: "ప్యాకేజీ పేరును నమోదు చేయండి",
    PLEASE_ENTER_PACKAGE_AMOUNT: "దయచేసి ప్యాకేజీ మొత్తాన్ని నమోదు చేయండి",
    ENTER_PACKAGE_AMOUNT: "ప్యాకేజీ మొత్తాన్ని నమోదు చేయండి",
    PLEASE_ENTER_BONUS_AMOUNT: "దయచేసి బోనస్ మొత్తాన్ని నమోదు చేయండి",
    ENTER_BONUS_AMOUNT: "బోనస్ మొత్తాన్ని నమోదు చేయండి",
    BONUS_AMOUNT_VALIDITY_DAYS: "బోనస్ మొత్తం చెల్లుబాటు (రోజులు)",
    PLEASE_ENTER_BONUS_AMOUNT_VALIDITY_DAYS:
      "దయచేసి బోనస్ మొత్తం చెల్లుబాటును నమోదు చేయండి (రోజులు)",
    ENTER_BONUS_VALIDITY_DAYS: "బోనస్ చెల్లుబాటు (రోజులు) నమోదు చేయండి",
    DESCRIPTION_TITLE: "వివరణ శీర్షిక",
    ENTER_DESCRIPTION_TITLE: "వివరణ శీర్షికను నమోదు చేయండి",
    PLEASE_ENTER_DESCRIPTION_TITLE: "దయచేసి వివరణ శీర్షికను నమోదు చేయండి",
    PLEASE_ENTER_DESCRIPTION: "దయచేసి వివరణను నమోదు చేయండి",
    ENTER_DESCRIPTION: "వివరణను నమోదు చేయండి",
    PICKUP_ID: "పికప్ ID",
    PROMOCODE: "ప్రోమోకోడ్",
    ASSIGNED_USER_NAME: "కేటాయించిన వినియోగదారు పేరు",
    ORDER: "ఆర్డర్",
    REASSIGN: "తిరిగి కేటాయించండి",
    ADD_PICKUP: "పికప్ జోడించండి",
    REASSIGN_PICKUP_EXECUTIVE: "పికప్ ఎగ్జిక్యూటివ్‌ను తిరిగి కేటాయించండి",
    PICKUP_EXECUTIVE: "పికప్ ఎగ్జిక్యూటివ్",
    PLEASE_SELECT_ANY_PICKUP_EXECUTIVE:
      "దయచేసి ఏదైనా పికప్ ఎగ్జిక్యూటివ్‌ను ఎంచుకోండి",
    SELECT_PICKUP_EXECUTIVE: "పికప్ ఎగ్జిక్యూటివ్ ఎంచుకోండి",
    PICKUP: "తీసుకోవడం",
    HISTORY: "చరిత్ర",
    GENERAL_PICKUP_INFORMATION: "సాధారణ పికప్ సమాచారం",
    HISTORY_OF_RESCHEDULED_PICKUPS: "రీషెడ్యూల్ చేసిన పికప్‌ల చరిత్ర",
    PICKUP_STATUS: "పికప్ స్థితి",
    SELECT_PICKUP_STATUS: "పికప్ స్థితిని ఎంచుకోండి",
    PLEASE_SELECT_PICKUP_DATE: "దయచేసి పికప్ తేదీని ఎంచుకోండి",
    PICKUP_TIMESLOT: "పికప్ టైమ్‌స్లాట్",
    PLEASE_SELECT_TIMESLOT: "దయచేసి టైమ్‌స్లాట్‌ను ఎంచుకోండి",
    SELECT_TIMESLOT: "టైమ్‌స్లాట్‌ను ఎంచుకోండి",
    SELECT_ADDRESS: "చిరునామాను ఎంచుకోండి",
    PLEASE_SELECT_DELIVERY_TYPE: "దయచేసి డెలివరీ రకాన్ని ఎంచుకోండి",
    SELECT_DELIVERY_TYPE: "డెలివరీ రకాన్ని ఎంచుకోండి",
    ENTER_NO_OF_GARMENTS: "వస్త్రాల సంఖ్యను నమోదు చేయండి",
    ENTER_PROMOCODE: "ప్రోమోకోడ్‌ను నమోదు చేయండి",
    SERVICES: "సేవలు",
    PLEASE_SELECT_SERVICES: "దయచేసి సేవలను ఎంచుకోండి",
    SELECT_SERVICES: "సేవలను ఎంచుకోండి",
    DELIVERY_TIMESLOT: "డెలివరీ టైమ్‌స్లాట్",
    CANCELLATION_REASON: "రద్దు కారణం",
    SELECT_OPERATION_INSTRUCTION: "ఆపరేషన్ ఇన్స్ట్రక్షన్ ఎంచుకోండి",
    RESCHEDULED_PICKUP_HISTORY: "పికప్ చరిత్రను తిరిగి షెడ్యూల్ చేసింది",
    ON_DEMAND_WALK_IN: "డిమాండ్ / వాక్-ఇన్",
    GARMENTS: "వస్త్రాలు",
    CREATE_ORDER: "ఆర్డర్ సృష్టించండి",
    PRINT_TAGS: "టాగ్లను ముద్రించండి",
    CANCEL_ORDER: "ఆర్డర్‌ను రద్దు చేయండి",
    CANCEL_REASON: "కారణాన్ని రద్దు చేయండి",
    PLEASE_ENTER_REASON: "దయచేసి కారణాన్ని నమోదు చేయండి",
    ENTER_REASON_TO_CANCEL_THE_ORDER:
      "ఆర్డర్‌ను రద్దు చేయడానికి కారణాన్ని నమోదు చేయండి",
    CUSTOMER_DETAILS: "వినియోగదారుని వివరాలు",
    NAME: "పేరు",
    TYPE_OF_SERVICE: "సేవ రకం",
    PICKUP_REQUEST: "పికప్ అభ్యర్థన",
    TYPE_OF_CUSTOMER: "కస్టమర్ రకం",
    WALLET_CREDIT: "వాలెట్ క్రెడిట్",
    FILTER: "ఫిల్టర్",
    ORDER_SUMMARY: "కొనుగోలు వివరణ",
    DETAILS: "వివరాలు",
    QTY: "Qty",
    RATES: "రేట్లు",
    FEES: "ఫీజు",
    SUBTOTAL: "మొత్తం",
    TAXES_AND_CHARGES: "పన్నులు & ఛార్జీలు",
    PROMO_DISCOUNT: "ప్రోమో డిస్కౌంట్",
    SGST: "SGST",
    CGST: "సిజిఎస్‌టి",
    IGST: "IGST",
    TOTAL: "మొత్తం",
    ORDER_REMARKS: "ఆర్డర్ వ్యాఖ్యలు",
    REVIEW_ORDER: "సమీక్ష ఆర్డర్",
    MEDIUM: "మధ్యస్థం",
    REQUIRED: "అవసరం",
    WEIGHT: "బరువు",
    SELECT_ADD_ON_SERVICES: "యాడ్-ఆన్ సేవలను ఎంచుకోండి",
    DARNING_SIZE: "డార్నింగ్ సైజు",
    SMALL: "చిన్నది",
    LARGE: "పెద్దది",
    DYING_COLORS: "మరణిస్తున్న రంగు (లు)",
    SIZE: "పరిమాణం",
    PRICE: "ధర",
    NOTES: "గమనికలు",
    ONLY_3_IMAGES_ARE_ALLOWED: "3 చిత్రాలు మాత్రమే అనుమతించబడతాయి",
    LOGISTICS_INSTRUCTION: "లాజిస్టిక్స్ ఇన్స్ట్రక్షన్",
    ON_DEMAND_DELIVERY: "ఆన్ డిమాండ్ డెలివరీ",
    REPRINT_TAGS: "ట్యాగ్‌లను తిరిగి ముద్రించండి",
    REPRINT_ESTIMATED_ORDER: "అంచనా ఆర్డర్‌ను తిరిగి ముద్రించండి",
    REWASH_REQUESTED_BY: "రీవాష్ ద్వారా అభ్యర్థించబడింది",
    PLACE_ORDER: "ప్లేస్ ఆర్డర్",
    COLLECT_ADVANCE_PAYMENT: "అడ్వాన్స్ చెల్లింపును సేకరించండి",
    UPDATE_ORDER: "నవీకరణ ఆర్డర్",
    CONFIRM_ORDER: "ఆర్డర్‌ను నిర్ధారించండి",
    TAG_IN_GARMENTS: "వస్త్రాలలో ట్యాగ్ చేయండి",
    CREATE_REWASH_ORDER: "రివాష్ ఆర్డర్‌ను సృష్టించండి",
    PAYMENT_METHOD: "చెల్లింపు పద్ధతి",
    COLLECT: "సేకరించండి",
    PROCESSING: "ప్రాసెసింగ్",
    INVOICE_NUMBER: "ఇన్వాయిస్ సంఖ్యా",
    GENERATE: "ఉత్పత్తి",
    SETTLE: "స్థిరపడండి",
    READY_FOR_DELIVERY_ORDERS: "డెలివరీ ఆర్డర్‌లకు సిద్ధంగా ఉంది",
    HIDE_FILTERS: "ఫిల్టర్‌లను దాచు",
    SHOW_FILTERS: "ఫిల్టర్‌లను చూపించు",
    PLEASE_SELECT_FROM_DATE: "తేదీ నుండి ఎంచుకోండి",
    FROM_DATE: "తేదీ నుంచి",
    PLEASE_SELECT_TO_DATE: "తేదీకి ఎంచుకోండి",
    TO_DATE: "తేదీకి",
    ENTER_SPLIT_ORDER_NUMBER: "స్ప్లిట్ ఆర్డర్ సంఖ్యను నమోదు చేయండి",
    PROFORMA_INVOICE_NUMBER: "ప్రొఫార్మా ఇన్వాయిస్ సంఖ్య",
    PROFORMA_INVOICE_DATE: "ప్రొఫార్మా ఇన్వాయిస్ తేదీ",
    INVOICE_DATE: "చలానా తారీకు",
    INVOICE: "ఇన్వాయిస్",
    ORDER_TYPE: "ఆర్డర్ రకం",
    PICKUP_BY: "ద్వారా పికప్",
    ADJUSTED_LK_BONUS_AMOUNT: "సర్దుబాటు చేసిన ఎల్‌కె బోనస్ మొత్తం",
    PROFORMA_INVOICE: "ప్రొఫార్మా ఇన్వాయిస్",
    TOTAL_AMOUNT: "మొత్తం మొత్తం",
    ADD_ON_SERVICE_CHARGES: "యాడ్-ఆన్ సేవా ఛార్జీలు",
    ADHOC_CHARGES: " తాత్కాలిక ఛార్జీలు",
    PAIDLESS_AMOUNT: "Adjusted By Admin",
    ADJUSTED_AMOUNT: "Paid Less",
    AMOUNT_TO_COLLECT: "సేకరించాల్సిన మొత్తం",
    ADD_REMARKS: "వ్యాఖ్యలను జోడించండి",
    UPDATE_PROFORMA_INVOICE: "ప్రొఫార్మా ఇన్‌వాయిస్‌ను నవీకరించండి",
    CREATE_PROFORMA_INVOICE: "ప్రొఫార్మా ఇన్వాయిస్ సృష్టించండి",
    USE_LK_WALLET: "LK Wallet ఉపయోగించండి",
    GENERATE_TAX_INVOICE: "పన్ను ఇన్వాయిస్ సృష్టించండి",
    DO_YOU_WANT_TO_ADD_THE_EXCESS_AMOUNT_TO_THE_LKWALLET:
      "మీరు అదనపు మొత్తాన్ని LKWallet కు జోడించాలనుకుంటున్నారా",
    SETTLE_LESS_PAID_INVOICE: "తక్కువ చెల్లింపు ఇన్‌వాయిస్‌ను పరిష్కరించండి",
    SLOT: "స్లాట్",
    ASSIGNED_TO: "కేటాయించిన",
    RESCHEDULE: "రీషెడ్యూల్",
    DELIVERY_LIST: "డెలివరీ జాబితా",
    REASSIGN_DELIVERY: "డెలివరీని తిరిగి కేటాయించండి",
    PLEASE_SELECT_ASSIGNEE: "దయచేసి అసైన్‌ని ఎంచుకోండి",
    SELECT_ASSIGNEE: "అసైన్‌ని ఎంచుకోండి",
    DELIVERY_ID: "డెలివరీ ఐడి",
    RESCHEDULE_DELIVERY: "రీషెడ్యూల్ డెలివరీ",
    CANCEL_DELIVERY: "డెలివరీని రద్దు చేయండి",
    PLEASE_SELECT_DELIVERY_DATE: "దయచేసి డెలివరీ తేదీని ఎంచుకోండి",
    PLEASE_ENTER_CANCEL_REASON: "దయచేసి రద్దు చేయి కారణాన్ని నమోదు చేయండి",
    ENTER_CANCEL_REASON: "రద్దు చేయి కారణాన్ని నమోదు చేయండి",
    PLEASE_SELECT_EXPENSE_TYPE: "దయచేసి ఖర్చు రకాన్ని ఎంచుకోండి",
    PLEASE_SELECT_EXPENSE_DATE: "దయచేసి ఖర్చు తేదీని ఎంచుకోండి",
    // OVERDUE_GARMENTS: "మీరిన వస్త్రాలు",
    OVERDUE_ORDERS: "మీరిన ఆర్డర్లు",
    GARMENT_COUNTS: "వస్త్ర గణనలు",
    ORDER_COUNTS: "ఆర్డర్ గణనలు",
    TOTAL_PENDING_GARMENTS_ORDERS: "మొత్తం పెండింగ్ వస్త్రాలు / ఆర్డర్లు",
    TODAYS_DUE: "నేటి డ్యూ",
    TICKETS_COUNTS: "టికెట్ల గణనలు",
    ORDER_CAN_NOT_BE_CREATED_USING_CLOSED_STORE:
      "క్లోజ్డ్ స్టోర్ ఉపయోగించి ఆర్డర్ సృష్టించబడదు",
    THIS_TYPE_OF_ORDER_CAN_NOT_BE_CREATED_FROM_THIS_STORE:
      "ఈ స్టోర్ నుండి ఈ రకమైన ఆర్డర్ సృష్టించబడదు",
    AMOUNT_IS_REQUIRED: "మొత్తం అవసరం",
    AMOUNT_SHOULD_BE_GREATER_THAN_0: "మొత్తం 0 కంటే ఎక్కువగా ఉండాలి",
    IS_LAUNDROKART_EMPLOYEE: "లాండ్రోకార్ట్ ఉద్యోగి",
    REWASH_ORDER: "రివాష్ ఆర్డర్",

    PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9:
      "దయచేసి 1 నుండి 9 వరకు ప్రారంభమయ్యే అంకెలను మాత్రమే నమోదు చేయండి",
    PLEASE_ENTER_ONLY_ALPHABETS: "దయచేసి అక్షరాలను మాత్రమే నమోదు చేయండి",
    MISSING_TAG_NOS: "తప్పిపోయిన ట్యాగ్ నం",

    //11/06/2020
    CUSTOMER_IS_SUCCESSFULLY_REGISTERED: "కస్టమర్ విజయవంతంగా నమోదు చేయబడ్డారు",
    DATA_IS_SUCCESSFULLY_RETRIEVED: "డేటా విజయవంతంగా తిరిగి పొందబడింది",
    DATA_IS_SUCCESSFULLY_DELETED: "డేటా విజయవంతంగా తొలగించబడుతుంది",
    DATA_IS_SUCCESSFULLY_SAVED: "డేటా విజయవంతంగా సేవ్ చేయబడింది",
    DATA_IS_SUCCESSFULLY_UPDATED: "డేటా విజయవంతంగా నవీకరించబడింది",
    RECORD_IS_ALREADY_EXISTS: "రికార్డ్ ఇప్పటికే ఉంది",
    INTERNAL_SERVER_ERROR: "అంతర్గత సర్వర్ లోపం",
    DATA_IS_SUCCESSFULLY_UPLOADED: "డేటా విజయవంతంగా అప్‌లోడ్ చేయబడింది",
    DISCOUNT_IS_SUCCESSFULLY_BLOCKED: "డిస్కౌంట్ విజయవంతంగా బ్లాక్ చేయబడింది",
    DISCOUNT_IS_SUCCESSFULLY_AUTHORIZED:
      "డిస్కౌంట్ విజయవంతంగా అధికారం పొందింది",
    DISCOUNT_IS_SUCCESSFULLY_GENERATED:
      "డిస్కౌంట్ విజయవంతంగా ఉత్పత్తి అవుతుంది",
    OTP_HAS_BEEN_SUCCESSFULLY_RE_SENT: "OTP విజయవంతంగా తిరిగి పంపబడింది",
    PICKUP_IS_SUCCESSFULLY_RESCHEDULED: "పికప్ విజయవంతంగా షెడ్యూల్ చేయబడింది",
    PICKUP_IS_SUCCESSFULLY_SCHEDULED: "పికప్ విజయవంతంగా షెడ్యూల్ చేయబడింది",
    PICKUP_IS_SUCCESSFULLY_CANCELLED: "పికప్ విజయవంతంగా రద్దు చేయబడింది",
    PICKUP_IS_SUCCESSFULLY_REASSIGNED: "పికప్ విజయవంతంగా తిరిగి కేటాయించబడింది",
    ERROR_WHILE_SENDING_CALL_BACK_REQUEST_NOTIFICATION:
      "కాల్ బ్యాక్ అభ్యర్థన నోటిఫికేషన్ పంపేటప్పుడు లోపం",
    ORDER_IS_SUCCESSFULLY_CANCELLED: "ఆర్డర్ విజయవంతంగా రద్దు చేయబడింది",
    ORDER_IS_SUCCESSFULLY_CREATED_PLEASE_CONFIRM_ORDER_TO_PROCEED_FURTHER:
      "ఆర్డర్ విజయవంతంగా సృష్టించబడింది దయచేసి మరింత ముందుకు వెళ్ళడానికి క్రమాన్ని నిర్ధారించండి",
    ORDER_IS_SUCCESSFULLY_UPDATED: "ఆర్డర్ విజయవంతంగా నవీకరించబడింది",
    GENERATE_TAGS_FOR_ORDER_SUCCESSFULLY:
      "ఆర్డర్ కోసం ట్యాగ్‌లను విజయవంతంగా రూపొందించండి",
    GETEPASS_IS_SUCCESSFULLY_GENERATED: "గెటెపాస్ విజయవంతంగా రూపొందించబడింది",
    PROMOCODE_VALIDATED_SUCCESSFULLY: "ప్రోమోకోడ్ విజయవంతంగా ధృవీకరించబడింది",
    INVALID_PROMOCODE: "చెల్లని ప్రోమోకోడ్",
    DELIVERY_IS_SUCCESSFULLY_REASSIGNED:
      "డెలివరీ విజయవంతంగా తిరిగి కేటాయించబడింది",
    AMOUNT_SUCCESSFULLY_BLOCKED: "మొత్తం విజయవంతంగా నిరోధించబడింది",
    USER_IS_VALID: "వినియోగదారు చెల్లుతుంది",
    USER_IS_INVALID: "వినియోగదారు చెల్లదు",
    ALREADY_IN_USED: "ఇప్పటికే ఉపయోగించబడింది",
    PDF_FILE_IS_GENERATED_SUCCESSFULLY: "PDF ఫైల్ విజయవంతంగా ఉత్పత్తి అవుతుంది",
    OLD_PASSWORD_DOES_NOT_MATCH: "పాత పాస్‌వర్డ్ సరిపోలలేదు",
    NOTIFICATION_HAS_BEEN_SENT_SUCCESSFULLY: "నోటిఫికేషన్ విజయవంతంగా పంపబడింది",
    ERROR_WHILE_SENDING_NOTIFICATION: "నోటిఫికేషన్ పంపేటప్పుడు లోపం",
    NOTIFICATION_IS_SUCCESSFULLY_DISMISS:
      "నోటిఫికేషన్ విజయవంతంగా తీసివేయబడింది",
    THERE_WAS_AN_INTERNAL_ERROR_PLEASE_CONTACT_TO_TECHNICAL_SUPPORT:
      "అంతర్గత లోపం ఉంది దయచేసి సాంకేతిక మద్దతుతో సంప్రదించండి",
    EMAIL_SENT_SUCCESSFULLY: "ఇమెయిల్ విజయవంతంగా పంపబడింది!",
    TAGS_NOT_FOUND: "టాగ్లు కనుగొనబడలేదు",
    UNABLE_TO_GET_LIST_OF_STORE_DATA:
      "స్టోర్ డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_MISSING_TAG_DATA:
      "తప్పిపోయిన ట్యాగ్ డేటాను పొందడం సాధ్యం కాలేదు",
    PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_TAG:
      "ట్యాగ్‌ను స్కాన్ చేసే ముందు స్టోర్ ఎంచుకోండి",
    PLEASE_ENTER_THE_TAG_TO_SCAN:
      "స్కాన్ చేయడానికి దయచేసి ట్యాగ్‌ను నమోదు చేయండి",
    TAG_ALREADY_SCANNED: "ట్యాగ్ ఇప్పటికే స్కాన్ చేయబడింది",
    PLEASE_SELECT_FACTORY_TAG_NO_FOR_TRANSFERIN_PROCESS:
      "ట్రాన్స్‌ఫిన్ ప్రాసెస్ కోసం ఫ్యాక్టరీ ట్యాగ్ నెం",
    UNABLE_TO_SCAN_THE_TAG: "ట్యాగ్‌ను స్కాన్ చేయడం సాధ్యం కాలేదు",
    TAGS_ARE_TRANSFERRED_IN_SUCCESSFULLY: "టాగ్లు విజయవంతంగా బదిలీ చేయబడతాయి",
    GENERATING_GATE_PASS: "గేట్ పాస్ ఉత్పత్తి",
    PLEASE_SELECT_STICKERS: "దయచేసి స్టిక్కర్లను ఎంచుకోండి",
    UNABLE_TO_GET_STICKERS_TO_TRANSFER_OUT:
      "బదిలీ చేయడానికి స్టిక్కర్లను పొందడం సాధ్యం కాలేదు",
    INFO: "సమాచారం",
    ENTERED_NUMBER_SHOULD_BE_SMALLER_THEN_NO_OF_PENDING_GARMENTS:
      "ఎంటర్ చేసిన సంఖ్య చిన్నదిగా ఉండాలి, అప్పుడు పెండింగ్‌లో ఉన్న వస్త్రాలు లేవు",
    PLEASE_SELECT_ATLEAST_ONE_PENDING_GARMENTS_TO_BE_PACKED_IN:
      "ప్యాక్ చేయడానికి కనీసం పెండింగ్‌లో ఉన్న వస్త్రాలను ఎంచుకోండి",
    STICKER_SUCCESSFULLY_GENERATED: "స్టిక్కర్ విజయవంతంగా ఉత్పత్తి చేయబడింది",
    UNABLE_TO_GET_STICKERID: "స్టిక్కర్ ఐడి పొందడం సాధ్యం కాలేదు",
    GARMENT_ALREADY_PACKED:
      "ఈ ఆర్డర్ నుండి కొన్ని వస్త్రాలు ఇప్పటికే ప్యాక్ చేయబడ్డాయి, దయచేసి రిఫ్రెష్ చేసి, మళ్లీ ప్రయత్నించండి",
    REDIRECTING: "దారి మళ్లించడం",
    SUCCESS: "విజయం",
    FAIL: "విఫలమైంది",
    WALLET_BALANCE: "వాలెట్ బ్యాలెన్స్",
    PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9:
      "దయచేసి 1 నుండి 9 వరకు ప్రారంభమయ్యే అంకెలను మాత్రమే నమోదు చేయండి",
    PLEASE_ENTER_ONLY_ALPHABETS: "దయచేసి అక్షరాలను మాత్రమే నమోదు చేయండి",
    MISSING_TAG_NOS: "ట్యాగ్ సంఖ్య లేదు",
    UNABLE_TO_GET_ORDERS_DUE_FOR_DELIVERY:
      "డెలివరీ కారణంగా ఆర్డర్లు పొందలేకపోయాము",
    UNABLE_TO_GET_PENDING_PICKUPS: "పెండింగ్ పికప్‌లను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_LOAD_ORDERS_PENDING_TO_TRANSFER_OUT_TO_FACTORY:
      "ఫ్యాక్టరీకి బదిలీ చేయడానికి పెండింగ్‌లో ఉన్న ఆర్డర్‌లను లోడ్ చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_DATA_FOR_DASHBOARD:
      "డాష్‌బోర్డ్ కోసం డేటాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_DATA_FOR_TIMESLOT:
      "టైమ్‌స్లాట్ కోసం డేటాను పొందడం సాధ్యం కాలేదు",
    CANNOT_REASSIGN_DELIVERY_AS_IT_IS_ALREADY_PROCEEDED_FURTHER:
      "ఇప్పటికే మరింత ముందుకు సాగినందున డెలివరీని తిరిగి కేటాయించలేము",
    DELIVERY_REASSIGNED_SUCCESSFULLY:
      "డెలివరీ విజయవంతంగా తిరిగి కేటాయించబడింది",
    UNABLE_TO_REASSIGN_DELIVERY_REQUEST:
      "డెలివరీ అభ్యర్థనను తిరిగి కేటాయించడం సాధ్యం కాలేదు",
    DELIVERY_STATUS_MUST_BE_DELIVERY_SCHEDULED_OR_ASSIGNED:
      "డెలివరీ స్థితి డెలివరీ షెడ్యూల్ లేదా కేటాయించినదిగా ఉండాలి",
    DELIVERY_RESCHEDULED_SUCCESSFULLY: "డెలివరీ విజయవంతంగా షెడ్యూల్ చేయబడింది",
    UNABLE_TO_RESCHEDULE_DELIVERY_REQUEST:
      "డెలివరీ అభ్యర్థనను రీషెడ్యూల్ చేయడం సాధ్యం కాలేదు",
    REQUEST_CANCELLED_SUCCESSFULLY: "అభ్యర్థన విజయవంతంగా రద్దు చేయబడింది",
    UNABLE_TO_CANCEL_DELIVERY_REQUEST:
      "డెలివరీ అభ్యర్థనను రద్దు చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_DELIVERY_LIST_DATA:
      "డెలివరీ జాబితా డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    EXPENSE_DELETED_SUCCESSFULLY: "ఖర్చు విజయవంతంగా తొలగించబడింది",
    UNABLE_TO_DELETE_EXPENSE: "ఖర్చును తొలగించడం సాధ్యం కాలేదు",
    EXPENSE_ADDED_SUCCESSFULLY: "ఖర్చు విజయవంతంగా జోడించబడింది",
    EXPENSE_UPDATED_SUCCESSFULLY: "ఖర్చు విజయవంతంగా నవీకరించబడింది",
    UNABLE_TO_SUBMIT_DATA: "డేటాను సమర్పించడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_EXPENSES: "ఖర్చుల జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_GATE_PASS_DATA:
      "గేట్ పాస్ డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_STORE_GARMENT_INVENTORY_DATA:
      "స్టోర్ వస్త్ర జాబితా డేటాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_SPLIT_ORDER_DETAILS:
      "స్ప్లిట్ ఆర్డర్ వివరాలను పొందడం సాధ్యం కాలేదు",
    INVOICE_GENERATED_SUCCESSFULLY: "ఇన్వాయిస్ విజయవంతంగా ఉత్పత్తి చేయబడింది",
    UNABLE_TO_GENERATE_INVOICE: "ఇన్వాయిస్ ఉత్పత్తి చేయలేకపోయింది",
    INVOICE_DATA_SUBMITTED_SUCCESSFULLY:
      "ఇన్వాయిస్ డేటా విజయవంతంగా సమర్పించబడింది",
    UNABLE_TO_SUBMIT_INVOICE_DATA: "ఇన్వాయిస్ డేటాను సమర్పించడం సాధ్యం కాలేదు",
    INVOICE_SETTLED_SUCCESSFULLY: "ఇన్వాయిస్ విజయవంతంగా పరిష్కరించబడింది",
    UNABLE_TO_SETTLE_INVOICE: "ఇన్వాయిస్ పరిష్కరించడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_ORDERS: "ఆర్డర్‌ల జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_UOM_LIST: "Uom జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GENERATE_ESTIMATED_ORDER:
      "అంచనా క్రమాన్ని రూపొందించడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_SERVICES: "సేవల జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_STORE_DATA: "స్టోర్ డేటాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_PICKUP_DATA: "పికప్ డేటాను పొందడం సాధ్యం కాలేదు",
    TAGS_GENERATED_SUCCESSFULLY: "టాగ్లు విజయవంతంగా సృష్టించబడ్డాయి",
    UNABLE_TO_GENERATE_TAGS: "ట్యాగ్‌లను రూపొందించడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_ORDER_INFORMATION: "ఆర్డర్ సమాచారం పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_TIME_CARD: "టైమ్ కార్డ్ పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_LOAD_ADD_ON_SERVICES: "సేవలపై యాడ్‌ను లోడ్ చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_PACKAGING_CHARGES:
      "ప్యాకేజింగ్ ఛార్జీలను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_DELIVERY_CHARGES: "డెలివరీ ఛార్జీలు పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_GARMENTS: "వస్త్రాల జాబితాను పొందలేకపోయాము",
    UNABLE_TO_GET_RATES: "రేట్లు పొందడం సాధ్యం కాలేదు",
    ORDER_SUCCESSFULLY_CREATED: "ఆర్డర్ విజయవంతంగా సృష్టించబడింది",
    UNABLE_TO_CREATE_THE_ORDER: "ఆర్డర్‌ను సృష్టించడం సాధ్యం కాలేదు",
    PLEASE_ENTER_THE_PROMOCODE: "దయచేసి ప్రోమోకోడ్‌ను నమోదు చేయండి",
    PROMOCODE_APPLIED_SUCCESSFULLY: "ప్రోమోకోడ్ విజయవంతంగా వర్తించబడింది",
    PROMOCODE_IS_INVALID: "ప్రోమోకోడ్ చెల్లదు",
    UNABLE_TO_VALIDATE_PROMOCODE: "ప్రోమోకోడ్‌ను ధృవీకరించడం సాధ్యం కాలేదు",
    ORDER_SUCCESSFULLY_UPDATED: "ఆర్డర్ విజయవంతంగా నవీకరించబడింది",
    UNABLE_TO_UPDATE_THE_ORDER: "ఆర్డర్‌ను నవీకరించడం సాధ్యం కాలేదు",
    ORDER_SUCCESSFULLY_CANCELLED: "ఆర్డర్ విజయవంతంగా రద్దు చేయబడింది",
    UNABLE_TO_CANCEL_ORDER: "ఆర్డర్‌ను రద్దు చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_PICKUP_DATA:
      "పికప్ డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_USER_DATA:
      "వినియోగదారు డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_REASSIGN_THE_PICKUP: "పికప్‌ను తిరిగి కేటాయించడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_DATA_FOR_HISTORY: "చరిత్ర కోసం డేటాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_DATA_FOR_DELIVERY_TYPES:
      "డెలివరీ రకాల కోసం డేటాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_DATA_FOR_PICKUP_STATUS:
      "పికప్ స్థితి కోసం డేటాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_OPERATION_INSTRUCTION_DATA:
      "ఆపరేషన్ ఇన్స్ట్రక్షన్ డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_SERVICES_DATA: "సేవల డేటాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_REASONS_DATA: "కారణాల డేటాను పొందలేకపోయాము",
    UNABLE_TO_GET_DATA_FOR_CUSTOMER: "కస్టమర్ కోసం డేటాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_CUSTOMER_ADDRESSES:
      "కస్టమర్ చిరునామాలను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_DATA: "డేటాను పొందడం సాధ్యం కాలేదు",
    PLEASE_INPUT_CUSTOMERNAME_ADDRESS_SERVICES_PROMOCODE:
      "దయచేసి కస్టమర్ పేరు / చిరునామా / సేవలు / ప్రోమోకోడ్‌ను ఇన్పుట్ చేయండి",
    PICKUP_IS_UPDATED: "పికప్ నవీకరించబడింది",
    PICKUP_IS_SCHEDULED: "పికప్ షెడ్యూల్ చేయబడింది",
    PICKUP_IS_RESCHEDULED_SUCCESSFULLY: "పికప్ విజయవంతంగా షెడ్యూల్ చేయబడింది",
    UNABLE_TO_RESCHEDULE_THE_PICKUP: "పికప్‌ను రీషెడ్యూల్ చేయడం సాధ్యం కాలేదు",
    PICKUP_IS_CANCELLED_SUCCESSFULLY: "పికప్ విజయవంతంగా రద్దు చేయబడింది",
    UNABLE_TO_CANCEL_PICKUP: "పికప్‌ను రద్దు చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_PRE_QC_RESPONSE_LIST:
      "ప్రీ-క్యూసి ప్రతిస్పందన జాబితాను పొందడం సాధ్యం కాలేదు",
    QC_RESPONSE_SUBMITTED_SUCCESSFULLY:
      "QC ప్రతిస్పందన విజయవంతంగా సమర్పించబడింది",
    UNABLE_TO_SUBMIT_QC_RESPONSE: "QC ప్రతిస్పందనను సమర్పించడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_READY_FOR_DELIVERY_INVOICE_DATA:
      "డెలివరీ / ఇన్వాయిస్ డేటా కోసం సిద్ధంగా ఉన్న జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_SUBMIT_STICKER_DATA: "స్టిక్కర్ డేటాను సమర్పించడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_STICKER_DATA: "స్టిక్కర్ డేటాను పొందడం సాధ్యం కాలేదు",
    PLEASE_ENTER_STICKER_ID: "దయచేసి స్టిక్కర్ ఐడిని నమోదు చేయండి",
    UNABLE_TO_GET_LIST_OF_SERVICE_DATA:
      "సేవా డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_SPLIT_ORDER_NUMBERS:
      "స్ప్లిట్ ఆర్డర్ సంఖ్యలను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_FACTORY_DATA:
      "ఫ్యాక్టరీ డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    HAS_BEEN_GENERATED: "గేట్ పాస్ రూపొందించబడింది",
    UNABLE_TO_GET_LIST_OF_STICKER_DATA:
      "స్టిక్కర్ డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    UNABLE_TO_GET_LIST_OF_PACKAGE_CONFIGURATION_DATA:
      "ప్యాకేజీ కాన్ఫిగరేషన్ డేటా జాబితాను పొందడం సాధ్యం కాలేదు",
    PLEASE_SELECT_ATLEAST_ONE_ROW: "దయచేసి కనీసం ఒక వరుసను ఎంచుకోండి",
    UNABLE_TO_LOAD_REWASH_REASONS: "రీవాష్ కారణాలను లోడ్ చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_LOAD_REWASH_REQUESTED_BY_LIST:
      "జాబితా ద్వారా అభ్యర్థించిన రీవాష్‌ను లోడ్ చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_LOAD_OPERATION_INSTRUCTIONS:
      "ఆపరేషన్ సూచనలను లోడ్ చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_LOAD_BRANDS: "బ్రాండ్‌లను లోడ్ చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_LOAD_SIZES: "పరిమాణాలను లోడ్ చేయడం సాధ్యం కాలేదు",
    UNABLE_TO_LOAD_COLORS: "రంగులను లోడ్ చేయడం సాధ్యం కాలేదు",
    WALLET_BALANCE: "వాలెట్ బ్యాలెన్స్",
    INVALID: "చెల్లదు",
    SPLITORDERNUMBER_SHOULD_NOT_BE_BLANK: "SplitOrderNumber ఖాళీగా ఉండకూడదు",
    VALID: "చెల్లుతుంది",
    ADJUSTMENTAMOUNT_SHOULD_BE_BETWEEN_0_AND_10:
      "సర్దుబాటు మొత్తం 0 మరియు 10 మధ్య ఉండాలి",
    SERVICE_RATE_NOT_AVAILABLE: "సేవా రేటు అందుబాటులో లేదు",
    ADDON_SERVICE_RATE_NOT_AVAILABLE: "యాడ్-ఆన్ సేవా రేటు అందుబాటులో లేదు",
    SCAN_GARMENT: "గార్మెంట్ స్కాన్ చేయండి",
    CLEAR: "క్లియర్",
    SCAN_TAG: "ట్యాగ్ స్కాన్ చేయండి",
    PLEASE_SCAN_TAG: "ట్యాగ్ స్కాన్ చేయండి",
    READY_FOR_TRANSFER_OUT: "బదిలీ కోసం సిద్ధంగా ఉంది",
    SCANNED_GARMENT_LIST: "స్కాన్ చేసిన వస్త్ర జాబితా",
    THIS_STICKER_BELONGS_TO_ANOTHER_STORE:
      "ఈ స్టిక్కర్ మరొక దుకాణానికి చెందినది",
    PLEASE_ENTER_VALID_STICKER_NUMBER:
      "దయచేసి చెల్లుబాటు అయ్యే స్టిక్కర్ సంఖ్యను నమోదు చేయండి",
    ALL: "అన్నీ",
    THIS_TAG_ALREADY_EXISTS: "ఈ ట్యాగ్ ఇప్పటికే ఉంది",
    PREVIOUSLY_PACKE_ON: "గతంలో ప్యాక్ చేయబడింది",
    CASH_COLLECTION: "నగదు సేకరణ",
    ALREADY_EXISTS: "ఇప్పటికే ఉన్నది",
    USER_PUNCHIN_NOT_FOUND: "వినియోగదారు పంచిన్ కనుగొనబడలేదు",
    IT_IS_ALREADY_BLOCKED: "ఇది ఇప్పటికే బ్లాక్ చేయబడింది",
    AUTHORIZED: "అధీకృత",
    BLOCKED: "నిరోధించబడింది",
    INVALID_OTP: "చెల్లని OTP",
    PLEASE_CHECK_THE_PROVIDED_BANNERID:
      "దయచేసి అందించిన బ్యానర్ ఐడిని తనిఖీ చేయండి",
    ALREADY_IN_USE: "ఇప్పటికే వాడుకలో ఉంది",
    ALREADY_EXISTS: "ఇప్పటికే ఉన్నది",
    DATE_OVERLAPPING: "తేదీ అతివ్యాప్తి",
    REFERRAL_CODE_DOES_NOT_EXISTS: "రెఫరల్ కోడ్ లేదు",
    REFERRAL_CODE_IS_ALREADY_USED: "రెఫరల్ కోడ్ ఇప్పటికే ఉపయోగించబడింది",
    USER_NAME_IS_INVALID: "మీ యూసర్ నేమ్ సరైనది కాదు",
    PASSWORD_IS_INVALID: "పాస్వర్డ్ చెల్లదు",
    USER_NAME_AND_PASSWORD_DOES_NOT_MATCH:
      "వినియోగదారు పేరు మరియు పాస్‌వర్డ్ సరిపోలడం లేదు",
    USER_IS_NOT_EXISTS: "వినియోగదారు ఉనికిలో లేరు",
    SPLIT_ORDER_NUMBER_IS_REQUIRED: "స్ప్లిట్ ఆర్డర్ సంఖ్య అవసరం",
    INVALID_SPLIT_ORDER_NUMBER: "చెల్లని స్ప్లిట్ ఆర్డర్ సంఖ్య",
    IT_IS_ALREADY_INACTIVATED: "ఇది ఇప్పటికే క్రియారహితం చేయబడింది",
    COUPON_CODE_NOT_EXISTS: "కూపన్ కోడ్ లేదు",
    CASH_COUPON_EXPIRED: "నగదు కూపన్ గడువు ముగిసింది",
    IT_IS_APPLICABLE_FOR_ONLY_FIRST_TIME_USERS:
      "ఇది మొదటిసారి వినియోగదారులకు మాత్రమే వర్తిస్తుంది",
    YOU_HAVE_ALREADY_USED_THIS_COUPON: "మీరు ఇప్పటికే ఈ కూపన్‌ను ఉపయోగించారు",
    INVALID_COUPON: "చెల్లని కూపన్",
    SPLITORDERNUMBER_SHOULD_NOT_BE_BLANK: "SplitOrderNumber ఖాళీగా ఉండకూడదు",
    THIS_PICKUP_IS_ALREADY_IN_PROGRESS: "ఈ పికప్ ఇప్పటికే ప్రోగ్రెస్‌లో ఉంది",
    YOU_HAVE_ALREADY_RESCHEDULED_THIS_PICKUP_3_TIMES:
      "మీరు ఇప్పటికే ఈ పికప్‌ను 3 సార్లు రీ షెడ్యూల్ చేసారు",
    PLEASE_CHECK_WITH_DELIVERYID: "దయచేసి DeliveryID తో తనిఖీ చేయండి",
    PLEASE_ASSIGN_IT_TO_SOMEONE_FIRST:
      "దయచేసి దీన్ని మొదట ఎవరికైనా కేటాయించండి",
    CANNOT_REASSIGN_DELIVERY_AS_ALREADY_PROCEED_FURTHER:
      "ఇప్పటికే మరింత ముందుకు సాగినందున డెలివరీని తిరిగి కేటాయించలేము",
    PLEASE_CHECK_WITH_STATUS: "దయచేసి స్థితితో తనిఖీ చేయండి",
    STICKER_ID: "స్టిక్కర్ ID",
    PACKED_On: "ప్యాక్ ఆన్",
    PACKING_IDS: "ప్యాకింగ్ ఐడిలు",
    RESORTING_AND_PACKAGING: "రిసార్టింగ్ మరియు ప్యాకేజింగ్",
    RESORTING_AND_PACKAGING_List: "రిసార్టింగ్ మరియు ప్యాకేజింగ్ జాబితా",
    PADING_QC_RESPONSE: "పెండింగ్‌లో ఉన్న ప్రీ-క్యూసి ప్రతిస్పందన గణన",
    LANGUAGE: "భాష",
    CHANGE_PASSWORD: "పాస్వర్డ్ మార్చండి",
    LOGOUT: "లాగ్ అవుట్",
    TAG_NUMBER_NOT_FOUND: "ట్యాగ్ సంఖ్య కనుగొనబడలేదు",
    TAG_NO: "ట్యాగ్ సంఖ్య",

    YOU_CAN_ONLY_UPLOAD_JPG_JPEG_PNG_FILE:
      "మీరు JPG / PNG / JPEG ఫైల్‌ను మాత్రమే అప్‌లోడ్ చేయవచ్చు",
    IMAGE_MUST_BE_SMALLER_THAN_5_MB: "చిత్రం 5 MB కన్నా చిన్నదిగా ఉండాలి",
    GARMENT_SHOULD_BE_INFACTORY_TO_BE_ADDED_FOR_QC:
      "క్యూసి కోసం జోడించడానికి వస్త్రం ఫ్యాక్టరీలో ఉండాలి",
    ORDER_ALREADY_CONFIRMED: "ఆర్డర్ ఇప్పటికే ధృవీకరించబడింది",
    PENDING_PAYMENT: "చెల్లింపు పెండింగ్‌లో ఉంది",
    IT_SEEMS_LIKE_FEW_OF_THE_SERVICES_ARE_NOT_EXISTS_INTO_SYSTEM:
      "కొన్ని సేవలు వ్యవస్థలో లేనట్లు కనిపిస్తోంది. దయచేసి సర్వీస్ మాస్టర్ స్క్రీన్‌లో వివరాలను తనిఖీ చేసి, మళ్లీ ప్రయత్నించండి",
    IT_SEEMS_LIKE_FEW_OF_THE_GARMENTS_ARE_NOT_EXISTS_INTO_SYSTEM:
      "కొన్ని వస్త్రాలు వ్యవస్థలో లేనట్లు కనిపిస్తోంది. దయచేసి గార్మెంట్ మాస్టర్ స్క్రీన్‌లో వివరాలను తనిఖీ చేసి, మళ్లీ ప్రయత్నించండి",
    SERVICE_TAX_DETAIL_IS_MISSING_IN_SECOND_SHEET:
      "సేవా పన్ను వివరాలు రెండవ షీట్లో లేవు",
    PLATFORM: "చెల్లింపు పెండింగ్‌లో ఉంది",
    ORDER_COUNT: "వేదిక",
    PROMO_CODE: "ప్రోమో కోడ్",
    PICK_BOY: "పికప్ బాయ్",
    CUST_MO_No: "కస్టమర్ మొబైల్ నం",
    SERVICE_ICONS: "సేవా చిహ్నాలు",
    REQUEST_CREATED_DATE: "అభ్యర్థన సృష్టించిన తేదీ & సమయం",
    PICKUP_REQUEST_DATE: "పికప్ అభ్యర్థించిన తేదీ & స్లాట్",
    ORDER_CREATED_DATE: "ఆర్డర్ సృష్టించిన తేదీ & సమయం",
    REPRINT_TAX_INVOICE: "పన్ను ఇన్వాయిస్ను తిరిగి ముద్రించండి",
    SEMI_EXPRESS_RATE: "(సెమీ ఎక్స్‌ప్రెస్ - 1.5x)",
    EXPRESS_RATE: "(ఎక్స్‌ప్రెస్ - 2x)",
    TOTAL_STICKERS: "మొత్తం స్టిక్కర్లు",
    DELIVERY_BOY: "డెలివరీ బాయ్",
    POST_QC: "QC ను పోస్ట్ చేయండి",
    UNABLE_TO_GET_POST_QC_DATA: "పోస్ట్ qc డేటాను పొందడం సాధ్యం కాలేదు",
    GARMENT_PROCEEDED_FURTHER_FOR_POST_PROCESS:
      "పోస్ట్ క్యూసి ప్రక్రియ కోసం వస్త్రం మరింత ముందుకు సాగింది",
    POST_QC_STATUS: "పోస్ట్ క్యూసి స్థితి",
    POST_QC_REMARK: "పోస్ట్ క్యూసి రిమార్క్",
    POST_QC_DATETIME: "QC డేట్‌టైమ్‌ను పోస్ట్ చేయండి",
    POSTED_BY: "ద్వారా",
    ADD_GARMENT_FOR_POST_QC: "పోస్ట్ క్యూసి కోసం వస్త్రాన్ని జోడించండి",
    LATEST_POSTQC_DETAILS: "తాజా పోస్ట్ క్యూసి వివరాలు",
    PICKUP_TIMESLOT: "పికప్ టైమ్‌స్లాట్",
    UNABLE_TO_GET_STORE_NAME: "స్టోర్ నామ్ పొందడం సాధ్యం కాలేదు",
    CANCEL_REASON_IS_REQUIRED: "CancelReason ఫీల్డ్ అవసరం",
    FACTORY_GATE_PASS: "ఫ్యాక్టరీ గేట్ పాస్ జాబితా",
    CUSTOMER_REMARK: "కస్టమర్ వ్యాఖ్య",
    STORE_OUT_DATE: "నిల్వ తేదీ",
    LAST_UPDATED_ON: "చివరిగా నవీకరించబడింది",
    LAST_UPDATED_BY: "చివరిగా నవీకరించబడింది",
    FACTORY_IN_DATETIME: "డేట్‌టైమ్‌లో ఫ్యాక్టరీ",
    GARMENTS_FACTORY_IN: "గార్మెంట్స్ ఫ్యాక్టరీ ఇన్",
    GARMENTS_MISSING: "వస్త్రాలు లేవు",
    BALANCED_GARMENTS: "సమతుల్య వస్త్రాలు",
    FACTORY_IN_DATE: "ఫ్యాక్టరీ ఇన్ డేట్",
    LOT_SIZE: "లాట్ సైజు",
    STANDARD_RATE_CARD: "ప్రామాణిక రేటు కార్డు",
    ORDER_STATUS: "ఆర్డర్ స్థితి",
    INVOICE_STATUS: "ఇన్వాయిస్ స్థితి",
    STOREINDATE: "తేదీలో స్టోర్ చేయండి",
    FACTORYOUTDATE: "ఫ్యాక్టరీ ముగిసిన తేదీ",
    PLEASE_SELECT_PAYMENT_MODE: "దయచేసి చెల్లింపు మోడ్‌ను ఎంచుకోండి",
    SELECT_PACKING_MATERIAL:
      "మీరు ప్యాకింగ్ మెటీరియల్‌ను 99 వరకు మాత్రమే ఎంచుకోవచ్చు",
    SOMETHING_WENT_WRONG: "ఏదో తప్పు జరిగింది",
    UNABLE_TO_GET_PAYMENT_METHOD_DATA:
      "చెల్లింపు పద్ధతి డేటాను పొందడం సాధ్యం కాలేదు",
    ARE_YOU_SURE_YOU_WANT_TO_MARK_ORDER_AS_COMPLETED:
      "ఆర్డర్ పూర్తయినట్లు మీరు ఖచ్చితంగా గుర్తించాలనుకుంటున్నారా?",
    ORDER_MARKED_AS_COMPLETED: "ఆర్డర్ పూర్తయినట్లు గుర్తించబడింది",
    UNABLE_TO_MARK_ORDER_AS_COMPLETED:
      "ఆర్డర్ పూర్తయినట్లు గుర్తించడం సాధ్యం కాలేదు",
    EDC_CONFIGURATION: "EDC పరికరం ఈ స్టోర్తో లింక్ చేయబడలేదు",
    ALTER_PHONE_NUMBER: "ప్రత్యామ్నాయ ఫోన్ నంబర్",
    // ATTENDANCE: "హాజరు",
    // ATTENDANCE_REPORT: "హాజరు నివేదిక",
    // OTHER_TYPE: "ఇతర రకం",
    // PLEASE_ENTER_OTHER_TYPE: "దయచేసి ఇతర రకాన్ని నమోదు చేయండి",
    // ENTER_OTHER_TYPE: "ఇతర రకాన్ని నమోదు చేయండి",
  },
  TAMIL: {
    SELECT_SERVICE: "சேவையைத் தேர்ந்தெடுக்கவும்",
    SELECT_FACTORY: "தொழிற்சாலையைத் தேர்ந்தெடுக்கவும்",
    SPLIT_ORDER: "பிளவு ஆணை",
    CUSTOMER_NAME: "வாடிக்கையாளர் பெயர்",
    ORDER_DATE: "ஆர்டர் தேதி",
    TOTAL_GARMENTS: "மொத்த ஆடைகள்",
    SPLIT_ORDER_NUMBER: "ஆர்டர் எண் பிரிக்கவும்",
    TAG_NUMBER: "குறிச்சொல் எண்",
    GARMENT_NAME: "ஆடை பெயர்",
    ADD_ON_SERVICES: "கூடுதல் சேவைகள்",
    GENERATE_GATE_PASS: "கேட் பாஸை உருவாக்குங்கள்",
    GATE_PASS_NUMBER: "கேட் பாஸ் எண்",
    STORE_NAME: "கடை பெயர்",
    FACTORY_NAME: "தொழிற்சாலை பெயர்",
    STORE_GARMENTS_OUT: "ஆடைகளை சேமிக்கவும்",
    TOTAL_SPLIT_NUMBER: "மொத்த பிளவு எண்",
    GATE_PASS_DATE: "கேட் பாஸ் தேதி",
    STIKER_NUMBER: "ஸ்டைக்கர் எண்",
    PLEASESCAN_ADD_STICKER: "தயவுசெய்து ஸ்கேன் / ஸ்டிக்கரைச் சேர்க்கவும்",
    ENTER_SCAN_STICKER: "ஸ்டிக்கரை உள்ளிடவும் / ஸ்கேன் செய்யவும்",
    CUSTOMER_CONTACT: "வாடிக்கையாளர் தொடர்பு",
    DELIVERY_TYPE: "டெலிவரி வகை",
    SPLIT_ORDER_NO: "பிளவு ஆணை எண்",
    GATE_PASS_NO: "கேட் பாஸ் எண்",
    DELIVERY_DATE: "டெலிவரி தேதி",
    GARMENTS_IN_PROCESS: "ஆடைகள் செயல்பாட்டில் உள்ளன",
    PACKED_GARMENTS: "பேக் செய்யப்பட்ட ஆடைகள்",
    PACKING_MATERIAL: "பேக்கிங் பொருள்",
    STICKER_STATUS: "ஸ்டிக்கர் நிலை",
    SPLIT_ORDER_STATUS: "பிளவு ஆணை நிலை",
    GARMENTS_PACKED_INFORMATION: "ஆடைகள் நிரம்பிய தகவல்",
    USER_EXPENSE_ENTRY: "பயனர் செலவு நுழைவு",
    USER_NAME: "பயனர் பெயர்",
    EXPENSE_DATE: "செலவு தேதி",
    AMOUNT: "தொகை",
    REMARKS: "குறிப்புகள்",
    ARE_YOU_SURE: "நீ சொல்வது உறுதியா ?",
    ADD_EXPENSE: "செலவு சேர்க்கவும்",
    APPLY: "விண்ணப்பிக்கவும்",
    SELECT_EXPENSE_TYPE: "செலவு வகையைத் தேர்ந்தெடுக்கவும்",
    PLEASE_ENTER_AMOUNT: "தொகையை உள்ளிடவும்",
    ENTER_AMOUNT: "தொகையை உள்ளிடவும்",
    ENTER_REMARKS: "குறிப்புகளை உள்ளிடவும்",
    GARMENTS_PENDING_TO_SEND_TO_FACTORY:
      "ஆடைகள் தொழிற்சாலைக்கு அனுப்ப நிலுவையில் உள்ளன",
    ORDERS_PENDING_TO_SEND_TO_FACTORY:
      "தொழிற்சாலைக்கு அனுப்ப உத்தரவுகள் நிலுவையில் உள்ளன",
    SPILT_ORDERS_RECEIVED_FROM_FACTORY:
      "தொழிற்சாலையிலிருந்து பெறப்பட்ட சிதறிய ஆர்டர்கள்",
    PENDING_FOR_NO_OF_DAYS: "நாட்கள் இல்லை என்ற நிலுவையில் உள்ளது",
    STORE_GARMENT_INVENTORY: "ஆடை சரக்குகளை சேமிக்கவும்",
    GARMENTS_RECEIVED_FROM_FACTORY: "தொழிற்சாலையிலிருந்து பெறப்பட்ட ஆடைகள்",
    FACTORY_TRANSFER_IN: "தொழிற்சாலை பரிமாற்றம்",
    SELECT_STORE: "கடையைத் தேர்ந்தெடுக்கவும்",
    GET_MISSING_GARMENTS: "காணாமல் போன ஆடைகளைப் பெறுங்கள்",
    TAGS_FROM_STORES: "குறிச்சொற்கள் கடைகளில் இருந்து",
    SCANNING: "ஸ்கேன் செய்கிறது",
    TAGS_TO_THE_FACTORY: "தொழிற்சாலைக்கு குறிச்சொற்கள்",
    MISSING_GARMENTS: "காணாமல் போன ஆடைகள்",
    SCAN_COMPLETE: "ஸ்கேன் முடிந்தது",
    SEARCH_HERE: "இங்கே தேடவும்",
    RESORTING_AND_PACKAGING: "ரிசார்டிங் & பேக்கேஜிங்",
    ORDER_NUMBER: "ஆர்டர் எண்",
    SRORE_NAME: "சோர் பெயர்",
    NO_OF_GARMENTS: "ஆடைகள் இல்லை",
    GARMENT_LEVEL_INFO: "ஆடை நிலை தகவல்",
    SELECTED_PENDING_GARMENT: "தேர்ந்தெடுக்கப்பட்ட நிலுவையில் உள்ள ஆடை",
    PREVIOUSLY_GARMENTS_PACKED_IN: "முன்பு ஆடைகள் நிரம்பியிருந்தன",
    PENDING_GARMENTS_TO_BE_PACKED_IN:
      "பேக் செய்யப்பட வேண்டிய ஆடைகள் நிலுவையில் உள்ளன",
    GENERATE_STICKER: "ஸ்டிக்கரை உருவாக்குங்கள்",
    PRINT_GENERATE_STICKER: "ஸ்டிக்கரை உருவாக்கு அச்சிடுக",
    SR_NO: "சீனியர் எண்",
    GARMENTS_PACKED: "ஆடைகள் நிரம்பியுள்ளன",
    PENDING_GARMENTS: "நிலுவையில் உள்ள ஆடைகள்",
    PACKING_GARMENTS: "ஆடைகளை பொதி செய்தல்",
    FACTORY_TRANSFER_OUT: "தொழிற்சாலை இடமாற்றம்",
    FACTORY_GATE_PASS_NUMBER: "தொழிற்சாலை கேட் பாஸ் எண்:",
    STORE_ADDRESS: "கடை முகவரி",
    CREATED_BY: "உருவாக்கியது",
    S_NO: "எஸ். இல்லை",
    SPLIT_ORDER_ID: "ஸ்ப்ளிட் ஆர்டர் ஐடி",
    NUMBER_OF_GARMENTS: "ஆடைகளின் எண்ணிக்கை",
    PACKED: "நிரம்பியுள்ளது",
    ORDER_LOT_SIZE: "ஆர்டர் நிறைய அளவு",
    PREVIOUSLY_SENT: "முன்பு அனுப்பப்பட்டது",
    SENDING_TODAY: "இன்று அனுப்புகிறது",
    PENDING: "நிலுவையில் உள்ளது",
    BAG: "பை",
    HANGER: "ஆபத்து",
    BLANKET: "போர்வை",
    SHOE: "ஷூ",
    CARPET: "கம்பளம்",
    BLAZER: "பிளேஸர்",
    OTHERS: "மற்றவைகள்",
    STICKER_NO: "ஸ்டிக்கர் எண்",
    SPLLIT_ORDER_NO: "ஸ்ப்ளிட் ஆர்டர் எண்",
    PACKING_MATERIAL_NAME: "பேக்கிங் பொருள் பெயர்",
    PRE_QC_RESPONSE_LIST: "முன் QC மறுமொழி பட்டியல்",
    SELECT_QC_STATUS: "QC நிலையைத் தேர்ந்தெடுக்கவும்",
    TAG: "குறிச்சொல்",
    CONTACT: "தொடர்பு கொள்ளுங்கள்",
    QC_REQUEST: "Qc கோரிக்கை",
    TIME: "நேரம்",
    QC_REASON: "QC காரணம்",
    REMARK: "கருத்து",
    QC_PENDING_FROM: "QC நிலுவையில் உள்ளது",
    QC_STATUS: "QC நிலை",
    ENTER_SCAN_TAG_NUMBER: "குறிச்சொல் எண்ணை உள்ளிடவும் / ஸ்கேன் செய்யவும்",
    GARMENT_HISTORY: "ஆடை வரலாறு",
    GARMENT_INFORMATION: "ஆடை தகவல்",
    GARMENT_STATUS: "ஆடை நிலை",
    GATE_PASS: "கடவுச்சீட்டு",
    BRAND: "பிராண்ட்",
    COLOR: "நிறம்",
    ADD_ON_SERVICE: "சேவையைச் சேர்",
    SPECIAL_INSTRUCTION: "சிறப்பு வழிமுறை",
    LATEST_QC_DETAILS: "சமீபத்திய QC விவரங்கள்",
    IMAGES: "படங்கள்",
    REASON: "காரணம்",
    SELECT_REASON: "காரணம் என்பதைத் தேர்ந்தெடுக்கவும்",
    COMMUNICATION_HISTORY: "தொடர்பு வரலாறு",
    SELECT_SCAN_GARMENT: "ஆடையைத் தேர்ந்தெடுக்கவும் / ஸ்கேன் செய்யவும்",
    GARMENT_FOR_QC: "QC க்கான ஆடை",
    SCANNED_ITEM: "ஸ்கேன் செய்யப்பட்ட பொருள்",
    ADDON_SERVICE: "AddOn சேவை",
    CONTACT_NO: "தொடர்பு எண்",
    STICKER_INFORMATION: "ஸ்டிக்கர் தகவல்",
    DOWNLOAD_GATE_PASS: "கேட் பாஸைப் பதிவிறக்கவும்",
    STORE_GATE_PASS_NUMBER: "ஸ்டோர் கேட் பாஸ் எண்",
    FACTORY_ADDRESS: "தொழிற்சாலை முகவரி",
    PICKUP_DATE: "இடும் தேதி",
    REFFERAL_PROGRAM_NAME: "பரிந்துரைப்பு நிரல் பெயர்",
    REFFERAL_BONUS_AMOUNT: "பரிந்துரை போனஸ் தொகை",
    REFEREE_BONUS_AMOUNT: "நடுவர் போனஸ் தொகை",
    YOU_CAN_ONLY_UPDATE_THE_LAST_ADDED_PROGRAM:
      "கடைசியாக சேர்க்கப்பட்ட நிரலை மட்டுமே நீங்கள் புதுப்பிக்க முடியும்",
    UNABLE_TO_GET_LIST_OF_REFFERAL_BONUS_CONFIGURATION_DATA:
      "பரிந்துரைப்பு போனஸ் உள்ளமைவு தரவின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_SUBMIT_THE_DATA: "தரவை சமர்ப்பிக்க முடியவில்லை",
    ADD_REFFERAL_BONUS_CONFIGURATION: "பரிந்துரை போனஸ் உள்ளமைவைச் சேர்க்கவும்",
    VIEW_REFFERAL_BONUS_CONFIGURATION: "பரிந்துரை போனஸ் உள்ளமைவைக் காண்க",
    EDIT_REFFERAL_BONUS_CONFIGURATION: "பரிந்துரை போனஸ் உள்ளமைவைத் திருத்துக",
    PLEASE_ENTER_REFFERAL_PROGRAM_NAME:
      "தயவுசெய்து பரிந்துரைப்பு நிரல் பெயரை உள்ளிடவும்",
    ENTER_REFFERAL_PROGRAM_NAME: "பரிந்துரை நிரல் பெயரை உள்ளிடவும்",
    PLEASE_ENTER_REFERRAL_BONUS_AMOUNT:
      "தயவுசெய்து பரிந்துரை போனஸ் தொகையை உள்ளிடவும்",
    ENTER_REFERRAL_BONUS_AMOUNT: "பரிந்துரை போனஸ் தொகையை உள்ளிடவும்",
    PLEASE_ENTER_REFEREE_BONUS_AMOUNT:
      "தயவுசெய்து நடுவர் போனஸ் தொகையை உள்ளிடவும்",
    ENTER_REFEREE_BONUS_AMOUNT: "நடுவர் போனஸ் தொகையை உள்ளிடவும்",
    ENTER_BONUS_AMOUNT_VALIDITY_DAYS:
      "போனஸ் தொகை செல்லுபடியை (நாட்கள்) உள்ளிடவும்",
    ARE_YOU_SURE_YOU_WANT_TO_CREATE_THIS_PROGRAM_AS_PREVIOUS_PROGRAMS_WILL_GET_DEACTIVATED:
      "முந்தைய நிரல்கள் செயலிழக்கப்படும் என்பதால் இந்த நிரலை உருவாக்க விரும்புகிறீர்களா?",
    FILE_NAME: "கோப்பு பெயர்",
    TOTAL_RECORDS: "மொத்த பதிவுகள்",
    SUCCEEDED: "வெற்றி பெற்றது",
    DOWNLOAD: "பதிவிறக்க Tamil",
    FAILED: "தோல்வி",
    UNABLE_TO_GET_LIST_OF_EMPLOYEE_CREDIT_DATA:
      "பணியாளர் கடன் தரவுகளின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_UPLOAD_THE_FILE: "கோப்பை பதிவேற்ற முடியவில்லை",
    DOWNLOAD_FORMAT: "பதிவிறக்க வடிவம்",
    UPLOAD_FILE: "கோப்பை பதிவேற்றவும்",
    ADD_EMPLOYEE_CREDITS: "பணியாளர் வரவுகளைச் சேர்க்கவும்",
    VIEW_EMPLOYEE_CREDITS: "பணியாளர் வரவுகளைக் காண்க",
    EDIT_EMPLOYEE_CREDITS: "பணியாளர் வரவுகளைத் திருத்துக",
    UPLOAD_EMPLOYEE_CREDIT_CONFIGURATION_FILE:
      "பணியாளர் கடன் உள்ளமைவு கோப்பை பதிவேற்றவும்",
    PLEASE_SELECT_FILE: "கோப்பைத் தேர்ந்தெடுக்கவும்",
    CLICK_TO_UPLOAD: "பதிவேற்ற கிளிக் செய்க",
    UPLOAD: "பதிவேற்றவும்",
    UNABLE_TO_LOAD_PACKAGES: "தொகுப்புகளை ஏற்ற முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_CUSTOMERS:
      "வாடிக்கையாளர்களின் பட்டியலைப் பெற முடியவில்லை",
    TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED:
      "பரிவர்த்தனை வெற்றிகரமாக செயல்படுத்தப்பட்டது",
    UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN:
      "பரிவர்த்தனை செயலாக்க முடியவில்லை மீண்டும் முயற்சிக்கவும்",
    UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM:
      "செயலாக்க முடியவில்லை. தொழில்நுட்ப குழுவை தொடர்பு கொள்ளவும்",
    PACKAGE_PURCHASED_SUCCESSFULLY: "தொகுப்பு வெற்றிகரமாக வாங்கப்பட்டது",
    UNABLE_TO_BUY_PACKAGE: "தொகுப்பு ���ாங்க முடியவில்லை",
    BUY: "வாங்க",
    PLEASE_SELECT_CUSTOMER: "வாடிக்கையாளரைத் தேர்ந்தெடுக்கவும்",
    SEARCH_CUSTOMER: "வாடிக்கையாளரைத் தேடுங்கள்",
    MONEY_TYPE: "பண வகை",
    ADDED_BY: "மூலம் சேர்க்கப்பட்டது",
    ADDED_ON: "சேர்க்கப்பட்டது",
    UNABLE_TO_GET_LIST_OF_ADVANCE_PAYMENT_DATA:
      "முன்கூட்டியே செலுத்தும் தரவின் பட்டியலைப் பெற முடியவில்லை",
    ADVANCE_COLLECTED_SUCCESSFULLY: "அட்வான்ஸ் வெற்றிகரமாக சேகரிக்கப்பட்டது",
    UNABLE_TO_ADD_AMOUNT_TO_LKWALLET:
      "LKWallet இல் தொகையைச் சேர்க்க முடியவில்லை",
    ADD_ADVANCE_PAYMENT: "முன்கூட்டியே கட்டணம் சேர்க்கவும்",
    VALID_FROM: "செல்லுபடியாகும்",
    VALID_TO: "செல்லுபடியாகும்",
    UNABLE_TO_GET_LIST_OF_COMPENSATION_CONFIGURATION_DATA:
      "இழப்பீட்டு உள்ளமைவு தரவின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_ADD_THE_DATA: "தரவைச் சேர்க்க முடியவில்லை",
    COMPENSATION_CONFIGURATION: "இழப்பீட்டு கட்டமைப்பு",
    ADD_COMPENSATION_CONFIGURATION: "இழப்பீட்டு உள்ளமைவைச் சேர்க்கவும்",
    VIEW_COMPENSATION_CONFIGURATION: "இழப்பீட்டு உள்ளமைவைக் காண்க",
    EDIT_COMPENSATION_CONFIGURATION: "இழப்பீட்டு உள்ளமைவைத் திருத்து",
    VALID_FROM_AND_TO_DATE: "முதல் தேதி வரை செல்லுபடியாகும்",
    PLEASE_SELECT_VALID_FROM_AND_TO_DATE:
      "தயவுசெய்து தேதி முதல் செல்லுபடியாகும் என்பதைத் தேர்ந்தெடுக்கவும்",
    PLEASE_ENTER_REMARK: "குறிப்பை உள்ளிடவும்",
    ENTER_REMARK: "குறிப்பை உள்ளிடவும்",
    UNABLE_TO_GET_LIST_OF_REFUND_DATA:
      "பணத்தைத் திரும்பப்பெறும் தரவின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_ADD_REFUND_DATA:
      "பணத்தைத் திரும்பப்பெறும் தரவைச் சேர்க்க முடியவில்லை",
    ADD_REFUND: "பணத்தைத் திரும்பச் சேர்க்கவும்",
    VIEW_REFUND: "பணத்தைத் திரும்பப் பார்க்கவும்",
    DATA_SUCCESSFULLY_SUBMITTED: "தரவு வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது",
    UNABLE_TO_SUBMIT_TAG_DATA: "குறிச்சொல் தரவை சமர்ப்பிக்க முடியவில்லை",
    UNABLE_TO_LOAD_TAG_DATA: "டேக் தரவை ஏற்ற முடியவில்லை",
    UNABLE_TO_GET_TAG_DATA: "டேக் தரவைப் பெற முடியவில்லை",
    PLEASE_ENTER_TAG_NO: "குறிச்சொல் எண் உள்ளிடவும்",
    FACTORY_POST_QC: "தொழிற்சாலை பிந்தைய QC",
    PLEASE_SCAN_ADD_TAG_NUMBER: "குறிச்சொல் எண்ணை ஸ்கேன் / சேர்க்கவும்",
    SELECT_STATUS: "நிலையைத் தேர்ந்தெடுக்கவும்",
    PLEASE_ENTER_REMARKS: "குறிப்புகளை உள்ளிடவும்",
    UNABLE_TO_LOAD_PRE_QC_RESPONSES: "முன் qc பதில்களை ஏற்ற முடியவில்லை",
    FACTORY_PRE_QC: "தொழிற்சாலை முன்- QC",
    ADD_GARMENT_FOR_QC: "QC க்கு ஆடை சேர்க்கவும்",
    DATA_IS_SUCCESSFULLY_SAVED: "தரவு வெற்றிகரமாக சேமிக்கப்படுகிறது",
    GARMENT_SHOULD_BE_IN_FACTORY_TO_BE_ADDED_FOR_QC:
      "க்யூ.சி.க்கு சேர்க்க ஆலை தொழிற்சாலையில் இருக்க வேண்டும்",
    UNABLE_TO_GET_GARMENT_DETAILS: "ஆடை விவரங்களைப் பெற முடியவில்லை",
    GET_GARMENT_HISTORY: "ஆடை வரலாற்றைப் பெறுங்கள்",
    PLEASE_SELECT_REASON: "தயவுசெய்து காரணத்தைத் தேர்ந்தெடுக்கவும்",
    UNABLE_TO_GET_CORPORATE_DATA: "கார்ப்பரேட் தரவைப் பெற முடியவில்லை",
    GARMENTS_AT_QC: "QC இல் ஆடைகள்",
    GARMENTS_IN_POST_QC: "போஸ்ட் கியூசியில் ஆடைகள்",
    UNABLE_TO_GET_FACTORY_GARMENTS_INVENTORY_DATA:
      "தொழிற்சாலை ஆடைகள் சரக்கு தரவைப் பெற முடியவில்லை",
    GARMENT_INVENTORY_AT_FACTORY: "தொழிற்சாலையில் ஆடை சரக்கு",
    DASHBOARD: "டாஷ்போர்டு",
    CUSTOMERS: "வாடிக்கையாளர்கள்",
    MANAGE_CUSTOMERS: "வாடிக்கையாளர்களை நிர்வகிக்கவும்",
    PACKAGE_CONFIGURATION: "தொகுப்பு கட்டமைப்பு",
    REFERRAL_BONUS_CONFIGURATION: "பரிந்துரை போனஸ் கட்டமைப்பு",
    EMPLOYEE_CREDITS: "பணியாளர் வரவு",
    BUY_PACKAGE: "தொகுப்பு வாங்க",
    ADVANCE_PAYMENTS: "முன்கூட்டியே செலுத்துதல்",
    COMPENSATION: "இழப்பீடு",
    REFUND: "பணத்தைத் திரும்பப் பெறுங்கள்",
    MASTER: "குரு",
    STORE: "கடை",
    FACTORY: "தொழிற்சாலை",
    CORPORATE: "பெருநிறுவன",
    CITY: "நகரம்",
    STATE: "நிலை",
    DESIGNATION: "பதவி",
    GARMENT_BRAND: "ஆடை பிராண்ட்",
    GARMENT_SIZE: "ஆடை அளவு",
    KYC_DOCUMENT_TYPE: "KYC ஆவண வகை",
    SERVICE: "சேவை",
    GARMENT: "ஆடை",
    REWASH_REASON: "ரீவாஷ் காரணம்",
    OPERATION_INSTRUCTION: "செயல்பாட்டு வழிமுறை",
    EXPENSE_TYPE: "செலவு வகை",
    ORDERS: "ஆர்டர்கள்",
    PICKUPS: "இடும்",
    MANAGE_ORDERS: "ஆர்டர்களை நிர்வகிக்கவும்",
    READY_FOR_DELIVERY_OR_INVOICE: "டெலிவரி / விலைப்பட்டியல் தயாராக உள்ளது",
    DELIVERIES: "விநியோகங்கள்",
    TRANSFER_IN_OUT: "உள்ளே / வெளியே இடமாற்றம்",
    TRANSFER_OUT: "இடமாற்றம்",
    GATE_PASS_LIST: "கேட் பாஸ் பட்டியல்",
    TRANSFER_IN: "இடமாற்றம்",
    PRE_QC_RESPONSES: "முன்-கியூசி பதில்",
    USER_EXPENSES: "பயனர் செலவுகள்",
    INVENTORY: "சரக்கு",
    SETTINGS: "அமைப்புகள்",
    AUTHENTICATION: "அங்கீகார",
    USERS: "பயனர்கள்",
    ROLES: "பாத்திரங்கள்",
    ROLE_OPERATIONS: "பங்கு செயல்பாடுகள்",
    CONFIGURATION: "கட்டமைப்பு",
    TIME_CARD: "நேர அட்டை",
    RATE_CARD: "விகித அட்டை",
    DISCOUNT: "தள்ளுபடி",
    CASH_COUPON: "பண கூப்பன்",
    PACKAGING_CHARGES: "பேக்கேஜிங் கட்டணங்கள்",
    DELIVERY_CHARGES: "விநியோக கட்டணம்",
    BANNER: "பதாகை",
    NOTICE_BOARD: "அறிவிப்பு வாரியம்",
    FINGER_SCAN: "விரல் ஸ்கேன்",
    COLLECT_FINGERPRINTS: "கைரேகைகளை சேகரிக்கவும்",
    QC_RESPONSES: "QC மறுமொழிகள்",
    PRE_QC: "முன் QC",
    POST_QC: "QC ஐ இடுங்கள்",
    TODAYS_HIGHLIGHTS: "இன்றைய சிறப்பம்சங்கள்",
    ORDER_VS_ORDER_AMOUNT: "ஆர்டர்கள் / ஆர்டர் தொகை",
    INVOICE_VS_INVOICE_AMOUNT: "விலைப்பட்டியல் / விலைப்பட்டியல் தொகை",
    STORE_INVENTORY: "ஸ்டோர் சரக்கு",
    OTHER_STATASTICS: "பிற புள்ளிவிவரங்கள்",
    PROCESSED: "செயலாக்கப்பட்டது",
    SOILED: "மண்ணானது",
    AMOUNT_DUE_FOR_COLLECTION: "வசூலிக்க வேண்டிய தொகை",
    ORDERS_READY_FOR_INVOICE: "விலைப்பட்டியலுக்கு ஆர்டர்கள் தயார்",
    DELIVERY_PERSON: "விநியோக நபர்",
    WALK_IN: "வாக்-இன்",
    PENDING_TO_TRANSFER_OUT_TO_FACTORY:
      "தொழிற்சாலைக்கு மாற்ற நிலுவையில் உள்ளது",
    NO_OF_ORDERS: "ஆர்டர்களின் எண்ணிக்கை",
    PENDING_PICKUPS: "இடும் நிலுவைகள்",
    DATE: "தேதி",
    ORDERS_DUE_FOR_DELIVERY: "வழங்குவதற்கான ஆர்டர்கள்",
    FIRST_NAME: "முதல் பெயர்",
    LAST_NAME: "கடைசி பெயர்",
    GENDER: "பாலினம்",
    EMAIL: "மின்னஞ்சல்",
    PHONE: "தொலைபேசி",
    TYPE: "வகை",
    BILLING_CYCLE: "பைலிங் சுழற்சி",
    ACTIONS: "செயல்கள்",
    VIEW: "காண்க",
    EDIT: "தொகு",
    DELETE: "அழி",
    SEARCH: "தேடல்",
    ADD_CUSTOMER: "வாடிக்கையாளரைச் சேர்க்கவும்",
    ADD: "கூட்டு",
    CUSTOMER: "வாடிக்கையாளர்",
    WALLET_CASH: "Wallet Cash",
    WALLET_BONUS: "Wallet போனஸ்",
    GENERAL: "பொது",
    GENERAL_CUSTOMER_INFORMATION: "பொது வாடிக்கையாளர் தகவல்",
    ADDRESS: "முகவரி",
    CUSTOMER_ADDRESSES: "வாடிக்கையாளர் முகவரிகள்",
    VERIFICATION: "சரிபார்ப்பு",
    VERIFY_PHONE_AND_EMAIL: "வெர்பி தொலைபேசி மற்றும் மின்னஞ்சல்",
    PLEASE: "தயவு செய்து",
    SELECT: "தேர்ந்தெடு",
    ENTER: "உள்ளிடவும்",
    VALID: "செல்லுபடியாகும்",
    PHONE_NUMBER: "தொலைபேசி எண்",
    DATE_OF_BIRTH: "பிறந்த தேதி",
    ANNIVERSARY_DATE: "ஆண்டு தேதி",
    REFERRAL_CODE: "பரிந்துரை குறியீடு",
    CUSTOMER_TYPE: "வாடிக்கையாளர் வகை",
    GSTIN: "GSTIN",
    ACTIVE: "செயலில்",
    STATUS: "நிலை",
    YES: "ஆம்",
    NO: "இல்லை",
    CANCEL: "ரத்துசெய்",
    SUBMIT: "சமர்ப்பிக்கவும்",
    ID: "ஐடி",
    PINCODE: "அஞ்சல் குறியீடு",
    ADRESSES_OF: "முகவரிகள்",
    HOME: "வீடு",
    OFFICE: "அலுவலகம்",
    OTHER: "மற்றவை",
    LONGITUDE: "தீர்க்கரேகை",
    LATITUDE: "அட்சரேகை",
    IS_DEFAULT: "இதை இயல்புநிலையாக அமைக்கவும்",
    PLEASE_ENTER_FIRST_NAME: "முதல் பெயரை உள்ளிடவும்",
    ENTER_FIRST_NAME: "முதல் பெயரை உள்ளிடவும்",
    PLEASE_ENTER_LAST_NAME: "கடைசி பெயரை உள்ளிடவும்",
    ENTER_LAST_NAME: "கடைசி பெயரை உள்ளிடவும்",
    PLEASE_ENTER_EMAIL: "மின்னஞ்சலை உள்ளிடவும்",
    PLEASE_ENTER_VALID_EMAIL: "செல்லுபடியாகும் மின்னஞ்சலை உள்ளிடவும்",
    ENTER_EMAIL: "மின்னஞ்சலை உள்ளிடவும்",
    PLEASE_ENTER_PHONE_NUMBER: "தொலைபேசி எண்ணை உள்ளிடவும்",
    PLEASE_ENTER_VALID_PHONE_NUMBER:
      "செல்லுபடியாகும் தொலைபேசி எண்ணை உள்ளிடவும்",
    ENTER_PHONE_NUMBER: "தொலைபேசி எண்ணை உள்ளிடவும்",
    PLEASE_SELECT_GENDER: "பாலினத்தைத் தேர்ந்தெடுக்கவும்",
    SELECT_DATE: "தேதி தேர்ந்தெடுக்கவும்",
    ENTER_REFERRAL_CODE: "பரிந்துரை குறியீட்டை உள்ளிடவும்",
    PLEASE_SELECT_CUSTOMER_TYPE: "வாடிக்கையாளர் வகையைத் தேர்ந்தெடுக்கவும்",
    SELECT_CUSTOMER_TYPE: "வாடிக்கையாளர் வகையைத் தேர்ந்தெடுக்கவும்",
    SELECT_CORPORATE: "கார்ப்பரேட் என்பதைத் தேர்ந்தெடுக்கவும்",
    PLEASE_SELECT_BILLING_CYCLE: "பில்லிங் சுழற்சியைத் தேர்ந்தெடுக்கவும்",
    SELECT_BILLING_CYCLE: "பில்லிங் சுழற்சியைத் தேர்ந்தெடுக்கவும்",
    ENTER_VALID_GSTIN: "செல்லுபடியாகும் GSTIN ஐ உள்ளிடவும்",
    ENTER_GSTIN: "GSTIN ஐ உள்ளிடவும்",
    PLEASE_SELECT_STATUS: "நிலையைத் தேர்ந்தெடுக்கவும்",
    ADD_ADDRESS: "முகவரியைச் சேர்க்கவும்",
    VIEW_ADDRESS: "முகவரியைக் காண்க",
    EDIT_ADDRESS: "முகவரியைத் திருத்து",
    PLEASE_SELECT_ADDRESS_TYPE: "முகவரி வகையைத் தேர்ந்தெடுக்கவும்",
    SELECT_TYPE: "வகையைத் தேர்ந்தெடுக்கவும்",
    ENTER_LONGITUDE: "தீர்க்கரேகை உள்ளிடவும்",
    ENTER_LATITUDE: "அட்சரேகை உள்ளிடவும்",
    PLEASE_SELECT_STATE: "மாநிலத்தைத் தேர்ந்தெடுக்கவும்",
    SELECT_STATE: "மாநிலத்தைத் தேர்ந்தெடுக்கவும்",
    PLEASE_SELECT_CITY: "நகரத்தைத் தேர்ந்தெடுக்கவும்",
    SELECT_CITY: "நகரத்தைத் தேர்ந்தெடுக்கவும்",
    PLEASE_ENTER_PINCODE: "தயவுசெய்து பின்கோடை உள்ளிடவும்",
    ENTER_PINCODE: "பின்கோடை உள்ளிடவும்",
    HOUSE_NO: "வீடு எண் / பிளாட் எண் / ஃப்ளூர் / ஸ்ட்ரீட்",
    PLEASE_ENTER_HOUSE_NO:
      "தயவுசெய்து ஹவுஸ் எண் / பிளாட் எண் / ஃப்ளூர் / ஸ்ட்ரீட் உள்ளிடவும்",
    ENTER_HOUSE_NO: "ஹவுஸ் எண் / பிளாட் எண் / ஃப்ளூர் / ஸ்ட்ரீட் உள்ளிடவும்",
    LANDMARK: "மைல்கல்",
    ENTER_LANDMARK: "லேண்ட்மார்க் உள்ளிடவும்",
    HOW_TO_REACH: "எப்படி அடைவது",
    ENTER_HOW_TO_REACH: "எப்படி அடைவது என்பதை உள்ளிடவும்",
    PLEASE_ENTER_ADDRESS: "முகவரியை உள்ளிடவும்",
    ENTER_ADDRESS: "முகவரியை உள்ளிடவும்",
    UPDATE: "புதுப்பிப்பு",
    PLEASE_ENTER_OTP: "OTP ஐ உள்ளிடவும்",
    ENTER_OTP: "OTP ஐ உள்ளிடவும்",
    VERIFY: "சரிபார்க்கவும்",
    DIDNT_RECIEVED_ANYTHIG: "எதையும் பெறவில்லையா?",
    RESEND_OTP: "OTP ஐ மீண்டும் அனுப்பு",
    NOT_VERIFIED: "சரிபார்க்கப்படவில்லை",
    VERIFY_PHONE_NUMBER: "தொலைபேசி எண்ணைச் சரிபார்க்கவும்",
    VERIFIED: "சரிபார்க்கப்பட்டது",
    PACKAGE_NAME: "தொகுப்பு பெயர்",
    PACKAGE_AMOUNT: "தொகுப்பு தொகை",
    BONUS_AMOUNT: "போனஸ் தொகை",
    BONUS_VALIDITY: "போனஸ் செல்லுபடியாகும்",
    TITLE: "தலைப்பு",
    DESCRIPTION: "விளக்கம்",
    ADD_PACKAGE_CONFIGURATION: "தொகுப்பு உள்ளமைவைச் சேர்க்கவும்",
    DELETE_SELECTED: "தேர்ந்தெடுத்ததை அழி",
    VIEW_PACKAGE_CONFIGURATION: "தொகுப்பு உள்ளமைவைக் காண்க",
    EDIT_PACKAGE_CONFIGURATION: "தொகுப்பு உள்ளமைவைத் திருத்து",
    PLEASE_ENTER_PACKAGE_NAME: "தொகுப்பு பெயரை உள்ளிடவும்",
    ENTER_PACKAGE_NAME: "தொகுப்பு பெயரை உள்ளிடவும்",
    PLEASE_ENTER_PACKAGE_AMOUNT: "தொகுப்பு தொகையை உள்ளிடவும்",
    ENTER_PACKAGE_AMOUNT: "தொகுப்பு தொகையை உள்ளிடவும்",
    PLEASE_ENTER_BONUS_AMOUNT: "போனஸ் தொகையை உள்ளிடவும்",
    ENTER_BONUS_AMOUNT: "போனஸ் தொகையை உள்ளிடவும்",
    BONUS_AMOUNT_VALIDITY_DAYS: "போனஸ் தொகை செல்லுபடியாகும் (நாட்கள்)",
    PLEASE_ENTER_BONUS_AMOUNT_VALIDITY_DAYS:
      "போனஸ் தொகை செல்லுபடியை (நாட்கள்) உள்ளிடவும்",
    ENTER_BONUS_VALIDITY_DAYS: "போனஸ் செல்லுபடியை உள்ளிடவும் (நாட்கள்)",
    DESCRIPTION_TITLE: "விளக்கம் தலைப்பு",
    ENTER_DESCRIPTION_TITLE: "விளக்கம் தலைப்பை உள்ளிடவும்",
    PLEASE_ENTER_DESCRIPTION_TITLE: "விளக்க தலைப்பை உள்ளிடவும்",
    PLEASE_ENTER_DESCRIPTION: "விளக்கத்தை உள்ளிடவும்",
    ENTER_DESCRIPTION: "விளக்கத்தை உள்ளிடவும்",
    PICKUP_ID: "இடும் ஐடி",
    PROMOCODE: "விளம்பர குறியீடு",
    ASSIGNED_USER_NAME: "ஒதுக்கப்பட்ட பயனர் பெயர்",
    ORDER: "ஆர்டர்",
    REASSIGN: "மறு ஒதுக்கீடு",
    ADD_PICKUP: "இடும் சேர்க்க",
    REASSIGN_PICKUP_EXECUTIVE: "இடும் நிர்வாகியை மீண்டும் நியமிக்கவும்",
    PICKUP_EXECUTIVE: "இடும் நிர்வாகி",
    PLEASE_SELECT_ANY_PICKUP_EXECUTIVE:
      "தயவுசெய்து எந்த இடும் நிர்வாகியையும் தேர்ந்தெடுக்கவும்",
    SELECT_PICKUP_EXECUTIVE: "பிக்கப் எக்ஸிகியூட்டிவ் தேர்ந்தெடுக்கவும்",
    PICKUP: "இடும்",
    HISTORY: "வரலாறு",
    GENERAL_PICKUP_INFORMATION: "பொது இடும் தகவல்",
    HISTORY_OF_RESCHEDULED_PICKUPS: "மாற்றியமைக்கப்பட்ட இடங்களின் வரலாறு",
    PICKUP_STATUS: "இடும் நிலை",
    SELECT_PICKUP_STATUS: "இடும் நிலையைத் தேர்ந்தெடுக்கவும்",
    PLEASE_SELECT_PICKUP_DATE: "இடும் தேதியைத் தேர்ந்தெடுக்கவும்",
    PICKUP_TIMESLOT: "பிக்அப் டைம்ஸ்லாட்",
    PLEASE_SELECT_TIMESLOT: "டைம்ஸ்லாட்டைத் தேர்ந்தெடுக்கவும்",
    SELECT_TIMESLOT: "டைம்ஸ்லாட்டைத் தேர்ந்தெடுக்கவும்",
    SELECT_ADDRESS: "முகவரியைத் தேர்ந்தெடுக்கவும்",
    PLEASE_SELECT_DELIVERY_TYPE: "டெலிவரி வகையைத் தேர்ந்தெடுக்கவும்",
    SELECT_DELIVERY_TYPE: "டெலிவரி வகையைத் தேர்ந்தெடுக்கவும்",
    ENTER_NO_OF_GARMENTS: "ஆடைகளின் எண்ணிக்கையை உள்ளிடவும்",
    ENTER_PROMOCODE: "விளம்பர குறியீட்டை உள்ளிடவும்",
    SERVICES: "சேவைகள்",
    PLEASE_SELECT_SERVICES: "சேவைகளைத் தேர்ந்தெடுக்கவும்",
    SELECT_SERVICES: "சேவைகளைத் தேர்ந்தெடுக்கவும்",
    DELIVERY_TIMESLOT: "டெலிவரி டைம்ஸ்லாட்",
    CANCELLATION_REASON: "ரத்து செய்வதற்கான காரணம்",
    SELECT_OPERATION_INSTRUCTION: "செயல்பாட்டு வழிமுறையைத் தேர்ந்தெடுக்கவும்",
    RESCHEDULED_PICKUP_HISTORY: "மாற்றியமைக்கப்பட்ட இடும் வரலாறு",
    ON_DEMAND_WALK_IN: "தேவை / நடைப்பயணத்தில்",
    GARMENTS: "ஆடைகள்",
    CREATE_ORDER: "ஆர்டரை உருவாக்கவும்",
    PRINT_TAGS: "குறிச்சொற்களை அச்சிடுக",
    CANCEL_ORDER: "ஆர்டரை ரத்துசெய்",
    CANCEL_REASON: "காரணத்தை ரத்துசெய்",
    PLEASE_ENTER_REASON: "தயவுசெய்து காரணத்தை உள்ளிடவும்",
    ENTER_REASON_TO_CANCEL_THE_ORDER:
      "ஆர்டரை ரத்து செய்வதற்கான காரணத்தை உள்ளிடவும்",
    CUSTOMER_DETAILS: "வாடிக்கையாளர் விவரங்கள்",
    NAME: "பெயர்",
    TYPE_OF_SERVICE: "சேவை வகை",
    PICKUP_REQUEST: "இடும் கோரிக்கை",
    TYPE_OF_CUSTOMER: "வாடிக்கையாளர் வகை",
    WALLET_CREDIT: "Wallet கடன்",
    FILTER: "வடிகட்டி",
    ORDER_SUMMARY: "ஆர்டர் சுருக்கம்",
    DETAILS: "விவரங்கள்",
    QTY: "Qty",
    RATES: "விகிதங்கள்",
    FEES: "கட்டணம்",
    SUBTOTAL: "கூட்டுத்தொகை",
    TAXES_AND_CHARGES: "வரி மற்றும் கட்டணங்கள்",
    PROMO_DISCOUNT: "விளம்பர தள்ளுபடி",
    SGST: "எஸ்.ஜி.எஸ்.டி.",
    CGST: "சி.ஜி.எஸ்.டி.",
    IGST: "ஐ.ஜி.எஸ்.டி.",
    TOTAL: "மொத்தம்",
    ORDER_REMARKS: "ஆர்டர் குறிப்புகள்",
    REVIEW_ORDER: "மறுஆய்வு ஆணை",
    MEDIUM: "நடுத்தர",
    REQUIRED: "தேவை",
    WEIGHT: "எடை",
    SELECT_ADD_ON_SERVICES: "கூடுதல் சேவைகளைத் தேர்ந்தெடுக்கவும்",
    DARNING_SIZE: "எச்சரிக்கை அளவு",
    SMALL: "சிறிய",
    LARGE: "பெரியது",
    DYING_COLORS: "இறக்கும் வண்ணம் (கள்)",
    SIZE: "அளவு",
    PRICE: "விலை",
    NOTES: "குறிப்புகள்",
    ONLY_3_IMAGES_ARE_ALLOWED: "3 படங்கள் மட்டுமே அனுமதிக்கப்படுகின்றன",
    LOGISTICS_INSTRUCTION: "தளவாட வழிமுறை",
    ON_DEMAND_DELIVERY: "தேவை வழங்கலில்",
    REPRINT_TAGS: "குறிச்சொற்களை மீண்டும் அச்சிடுங்கள்",
    REPRINT_ESTIMATED_ORDER: "மதிப்பிடப்பட்ட ஆர்டரை மீண்டும் அச்சிடுங்கள்",
    REWASH_REQUESTED_BY: "மூலம் கோரப்பட்டது",
    PLACE_ORDER: "இடம் ஒழுங்கு",
    COLLECT_ADVANCE_PAYMENT: "முன்கூட்டியே கட்டணம் வசூலிக்கவும்",
    UPDATE_ORDER: "புதுப்பிப்பு ஆர்டர்",
    CONFIRM_ORDER: "ஆணையை உறுதிப்படுத்தவும்",
    TAG_IN_GARMENTS: "ஆடைகளில் குறிச்சொல்",
    CREATE_REWASH_ORDER: "Rewash ஆர்டரை உருவாக்கவும்",
    PAYMENT_METHOD: "கட்டணம் செலுத்தும் முறை",
    COLLECT: "திரட்டுதல்",
    PROCESSING: "செயலாக்கம்",
    INVOICE_NUMBER: "விலைப்பட்டியல் எண்",
    GENERATE: "உருவாக்கு",
    SETTLE: "செட்டில்",
    READY_FOR_DELIVERY_ORDERS: "டெலிவரி ஆர்டர்களுக்கு தயாராக உள்ளது",
    HIDE_FILTERS: "வடிப்பான்களை மறைக்க",
    SHOW_FILTERS: "வடிப்பான்களைக் காட்டு",
    PLEASE_SELECT_FROM_DATE: "தேதியிலிருந்து தேர்ந்தெடுக்கவும்",
    FROM_DATE: "தேதியிலிருந்து",
    PLEASE_SELECT_TO_DATE: "தேதி தேர்வு செய்யவும்",
    TO_DATE: "தேதி வரை",
    ENTER_SPLIT_ORDER_NUMBER: "பிளவு ஆர்டர் எண்ணை உள்ளிடவும்",
    PROFORMA_INVOICE_NUMBER: "விவரக்குறிப்பு விலைப்பட்டியல் எண்",
    PROFORMA_INVOICE_DATE: "விவரக்குறிப்பு விலைப்பட்டியல் தேதி",
    INVOICE_DATE: "விலைப்பட்டியல் தேதி",
    INVOICE: "விலைப்பட்டியல்",
    ORDER_TYPE: "ஆர்டர் வகை",
    PICKUP_BY: "எடுத்தது",
    ADJUSTED_LK_BONUS_AMOUNT: "சரிசெய்யப்பட்ட எல்.கே போனஸ் தொகை",
    PROFORMA_INVOICE: "விவரக்குறிப்பு விலைப்பட்டியல்",
    TOTAL_AMOUNT: "மொத்த தொகை",
    ADD_ON_SERVICE_CHARGES: "கூடுதல் சேவை கட்டணங்கள்",
    ADHOC_CHARGES: " தற்காலிக கட்டணங்கள்",
    AMOUNT_TO_COLLECT: "சேகரிக்க வேண்டிய தொகை",
    ADD_REMARKS: "குறிப்புகளைச் சேர்க்கவும்",
    UPDATE_PROFORMA_INVOICE: "விவரக்குறிப்பு விலைப்பட்டியல் புதுப்பிக்கவும்",
    CREATE_PROFORMA_INVOICE: "புரோஃபோர்மா விலைப்பட்டியல் உருவாக்கவும்",
    USE_LK_WALLET: "எல்.கே. வாலட்டைப் பயன்படுத்துங்கள்",
    GENERATE_TAX_INVOICE: "வரி விலைப்பட்டியல் உருவாக்கு",
    DO_YOU_WANT_TO_ADD_THE_EXCESS_AMOUNT_TO_THE_LKWALLET:
      "கூடுதல் தொகையை எல்.கே.வாலட்டில் சேர்க்க விரும்புகிறீர்களா?",
    SETTLE_LESS_PAID_INVOICE: "குறைந்த கட்டண விலைப்பட்டியல் அமைக்கவும்",
    SLOT: "ஸ்லாட்",
    ASSIGNED_TO: "ஒதுக்கப்படும்",
    RESCHEDULE: "மறுபரிசீலனை",
    DELIVERY_LIST: "விநியோக பட்டியல்",
    REASSIGN_DELIVERY: "விநியோகத்தை மீண்டும் ஒதுக்குங்கள்",
    PLEASE_SELECT_ASSIGNEE: "ஒதுக்கீட்டாளரைத் தேர்ந்தெடுக்கவும்",
    SELECT_ASSIGNEE: "ஒதுக்குபவரைத் தேர்ந்தெடுக்கவும்",
    DELIVERY_ID: "டெலிவரி ஐடி",
    RESCHEDULE_DELIVERY: "மறுசீரமைப்பு டெலிவரி",
    CANCEL_DELIVERY: "விநியோகத்தை ரத்துசெய்",
    PLEASE_SELECT_DELIVERY_DATE: "விநியோக தேதியைத் தேர்ந்தெடுக்கவும்",
    PLEASE_ENTER_CANCEL_REASON: "ரத்துசெய் காரணத்தை உள்ளிடவும்",
    ENTER_CANCEL_REASON: "ரத்துசெய் காரணத்தை உள்ளிடவும்",
    PLEASE_SELECT_EXPENSE_TYPE: "செலவு வகையைத் தேர்ந்தெடுக்கவும்",
    PLEASE_SELECT_EXPENSE_DATE: "செலவு தேதி என்பதைத் தேர்ந்தெடுக்கவும்",
    // OVERDUE_GARMENTS: "அதிகப்படியான ஆடைகள்",
    OVERDUE_ORDERS: "தாமதமான ஆர்டர்கள",
    GARMENT_COUNTS: "ஆடை எண்ணிக்கைகள்",
    ORDER_COUNTS: "ஆர்டர் எண்ணிக்கைகள்",
    TOTAL_PENDING_GARMENTS_ORDERS: "மொத்த நிலுவையில் உள்ள ஆடைகள் / ஆர்டர்கள்",
    TODAYS_DUE: "இன்றைய காலக்கெடு",
    TICKETS_COUNTS: "டிக்கெட் எண்ணிக்கை",
    ORDER_CAN_NOT_BE_CREATED_USING_CLOSED_STORE:
      "மூடிய கடையைப் பயன்படுத்தி ஆர்டரை உருவாக்க முடியாது",
    THIS_TYPE_OF_ORDER_CAN_NOT_BE_CREATED_FROM_THIS_STORE:
      "இந்த கடையிலிருந்து இந்த வகை வரிசையை உருவாக்க முடியாது",
    AMOUNT_IS_REQUIRED: "தொகை தேவை",
    AMOUNT_SHOULD_BE_GREATER_THAN_0: "தொகை 0 ஐ விட அதிகமாக இருக்க வேண்டும்",
    IS_LAUNDROKART_EMPLOYEE: "லாண்டிரோகார்ட் ஊழியர்",
    REWASH_ORDER: "மீண்டும் ஆர்டர்",

    PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9:
      "1 முதல் 9 வரை தொடங்கி இலக்கங்களை மட்டும் உள்ளிடவும்",
    PLEASE_ENTER_ONLY_ALPHABETS: "தயவுசெய்து எழுத்துக்களை மட்டும் உள்ளிடவும்",
    MISSING_TAG_NOS: "குறிச்சொல் எண் இல்லை",

    //11/06/2020
    CUSTOMER_IS_SUCCESSFULLY_REGISTERED:
      "வாடிக்கையாளர் வெற்றிகரமாக பதிவு செய்யப்பட்டுள்ளார்",
    DATA_IS_SUCCESSFULLY_RETRIEVED: "தரவு வெற்றிகரமாக மீட்டெடுக்கப்பட்டது",
    DATA_IS_SUCCESSFULLY_DELETED: "தரவு வெற்றிகரமாக நீக்கப்பட்டது",
    DATA_IS_SUCCESSFULLY_SAVED: "தரவு வெற்றிகரமாக சேமிக்கப்படுகிறது",
    DATA_IS_SUCCESSFULLY_UPDATED: "தரவு வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
    RECORD_IS_ALREADY_EXISTS: "பதிவு ஏற்கனவே உள்ளது",
    INTERNAL_SERVER_ERROR: "உள் சேவையக பிழை",
    DATA_IS_SUCCESSFULLY_UPLOADED: "தரவு வெற்றிகரமாக பதிவேற்றப்பட்டது",
    DISCOUNT_IS_SUCCESSFULLY_BLOCKED: "தள்ளுபடி வெற்றிகரமாக தடுக்கப்பட்டுள்ளது",
    DISCOUNT_IS_SUCCESSFULLY_AUTHORIZED:
      "தள்ளுபடி வெற்றிகரமாக அங்கீகரிக்கப்பட்டுள்ளது",
    DISCOUNT_IS_SUCCESSFULLY_GENERATED:
      "தள்ளுபடி வெற்றிகரமாக உருவாக்கப்படுகிறது",
    OTP_HAS_BEEN_SUCCESSFULLY_RE_SENT:
      "OTP வெற்றிகரமாக மீண்டும் அனுப்பப்பட்டுள்ளது",
    PICKUP_IS_SUCCESSFULLY_RESCHEDULED:
      "இடும் வெற்றிகரமாக மாற்றியமைக்கப்பட்டுள்ளது",
    PICKUP_IS_SUCCESSFULLY_SCHEDULED: "இடும் வெற்றிகரமாக திட்டமிடப்பட்டுள்ளது",
    PICKUP_IS_SUCCESSFULLY_CANCELLED: "எடுப்பது வெற்றிகரமாக ரத்துசெய்யப்பட்டது",
    PICKUP_IS_SUCCESSFULLY_REASSIGNED: "இடும் வெற்றிகரமாக மறு ஒதுக்கப்பட்டது",
    ERROR_WHILE_SENDING_CALL_BACK_REQUEST_NOTIFICATION:
      "அழைப்பு திரும்ப கோரிக்கை அறிவிப்பை அனுப்பும்போது பிழை",
    ORDER_IS_SUCCESSFULLY_CANCELLED: "ஆர்டர் வெற்றிகரமாக ரத்து செய்யப்பட்டது",
    ORDER_IS_SUCCESSFULLY_CREATED_PLEASE_CONFIRM_ORDER_TO_PROCEED_FURTHER:
      "ஆர்டர் வெற்றிகரமாக உருவாக்கப்பட்டது மேலும் தொடர உத்தரவை உறுதிப்படுத்தவும்",
    ORDER_IS_SUCCESSFULLY_UPDATED: "ஆர்டர் வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
    GENERATE_TAGS_FOR_ORDER_SUCCESSFULLY:
      "ஆர்டருக்கான குறிச்சொற்களை வெற்றிகரமாக உருவாக்கவும்",
    GETEPASS_IS_SUCCESSFULLY_GENERATED:
      "கெட் பாஸ் வெற்றிகரமாக உருவாக்கப்படுகிறது",
    PROMOCODE_VALIDATED_SUCCESSFULLY:
      "விளம்பர குறியீடு வெற்றிகரமாக சரிபார்க்கப்பட்டது",
    INVALID_PROMOCODE: "தவறான விளம்பரக் குறியீடு",
    DELIVERY_IS_SUCCESSFULLY_REASSIGNED:
      "டெலிவரி வெற்றிகரமாக மறு ஒதுக்கப்பட்டது",
    AMOUNT_SUCCESSFULLY_BLOCKED: "தொகை வெற்றிகரமாக தடுக்கப்பட்டது",
    USER_IS_VALID: "பயனர் செல்லுபடியாகும்",
    USER_IS_INVALID: "பயனர் தவறானது",
    ALREADY_IN_USED: "ஏற்கனவே பயன்படுத்தப்பட்டது",
    PDF_FILE_IS_GENERATED_SUCCESSFULLY:
      "PDF கோப்பு வெற்றிகரமாக உருவாக்கப்படுகிறது",
    OLD_PASSWORD_DOES_NOT_MATCH: "பழைய கடவுச்சொல் பொருந்தவில்லை",
    NOTIFICATION_HAS_BEEN_SENT_SUCCESSFULLY:
      "அறிவிப்பு வெற்றிகரமாக அனுப்பப்பட்டுள்ளது",
    ERROR_WHILE_SENDING_NOTIFICATION: "அறிவிப்பை அனுப்பும்போது பிழை",
    NOTIFICATION_IS_SUCCESSFULLY_DISMISS:
      "அறிவிப்பு வெற்றிகரமாக நிராகரிக்கப்பட்டது",
    THERE_WAS_AN_INTERNAL_ERROR_PLEASE_CONTACT_TO_TECHNICAL_SUPPORT:
      "உள் பிழை ஏற்பட்டது, தொழில்நுட்ப ஆதரவைத் தொடர்பு கொள்ளவும்",
    EMAIL_SENT_SUCCESSFULLY: "மின்னஞ்சல் வெற்றிகரமாக அனுப்பப்பட்டது!",
    TAGS_NOT_FOUND: "குறிச்சொற்கள் கிடைக்கவில்லை",
    UNABLE_TO_GET_LIST_OF_STORE_DATA:
      "ஸ்டோர் தரவின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_MISSING_TAG_DATA: "குறிக்கப்பட்ட டேக் தரவைப் பெற முடியவில்லை",
    PLEASE_SELECT_STORE_BEFORE_SCANNING_THE_TAG:
      "குறிச்சொல்லை ஸ்கேன் செய்வதற்கு முன் கடையைத் தேர்ந்தெடுக்கவும்",
    PLEASE_ENTER_THE_TAG_TO_SCAN: "ஸ்கேன் செய்ய குறிச்சொல்லை உள்ளிடவும்",
    TAG_ALREADY_SCANNED: "டேக் ஏற்கனவே ஸ்கேன் செய்யப்பட்டது",
    PLEASE_SELECT_FACTORY_TAG_NO_FOR_TRANSFERIN_PROCESS:
      "பரிமாற்ற செயல்முறைக்கு தொழிற்சாலை குறிச்சொல் எண் என்பதைத் தேர்ந்தெடுக்கவும்",
    UNABLE_TO_SCAN_THE_TAG: "குறிச்சொல்லை ஸ்கேன் செய்ய முடியவில்லை",
    TAGS_ARE_TRANSFERRED_IN_SUCCESSFULLY:
      "குறிச்சொற்கள் வெற்றிகரமாக மாற்றப்படுகின்றன",
    GENERATING_GATE_PASS: "கேட் பாஸை உருவாக்குகிறது",
    PLEASE_SELECT_STICKERS: "ஸ்டிக்கர்களைத் தேர்ந்தெடுக்கவும்",
    UNABLE_TO_GET_STICKERS_TO_TRANSFER_OUT:
      "மாற்றுவதற்கு ஸ்டிக்கர்களைப் பெற முடியவில்லை",
    INFO: "தகவல்",
    ENTERED_NUMBER_SHOULD_BE_SMALLER_THEN_NO_OF_PENDING_GARMENTS:
      "உள்ளிட்ட எண் சிறியதாக இருக்க வேண்டும், பின்னர் நிலுவையில் உள்ள ஆடைகள் இல்லை",
    PLEASE_SELECT_ATLEAST_ONE_PENDING_GARMENTS_TO_BE_PACKED_IN:
      "பேக் செய்ய நிலுவையில் உள்ள ஒரு ஆடைகளைத் தேர்ந்தெடுக்கவும்",
    STICKER_SUCCESSFULLY_GENERATED: "ஸ்டிக்கர் வெற்றிகரமாக உருவாக்கப்பட்டது",
    UNABLE_TO_GET_STICKERID: "ஸ்டிக்கர் ஐடியைப் பெற முடியவில்லை",
    GARMENT_ALREADY_PACKED:
      "இந்த ஆர்டரில் இருந்து சில ஆடைகள் ஏற்கனவே நிரம்பியுள்ளன, தயவுசெய்து புதுப்பித்து மீண்டும் முயற்சிக்கவும்",
    REDIRECTING: "திருப்பி விடுகிறது",
    SUCCESS: "வெற்றி",
    FAIL: "தோல்வி",
    WALLET_BALANCE: "Wallet இருப்பு",
    PLEASE_ENTER_ONLY_DIGITS_STARTING_WITH_1_TO_9:
      "1 முதல் 9 வரை தொடங்கி இலக்கங்களை மட்டும் உள்ளிடவும்",
    PLEASE_ENTER_ONLY_ALPHABETS: "தயவுசெய்து எழுத்துக்களை மட்டும் உள்ளிடவும்",
    MISSING_TAG_NOS: "டேக் எண் காணவில்லை",
    UNABLE_TO_GET_ORDERS_DUE_FOR_DELIVERY:
      "வழங்குவதற்கான ஆர்டர்களைப் பெற முடியவில்லை",
    UNABLE_TO_GET_PENDING_PICKUPS:
      "நிலுவையிலுள்ள இடும் இடங்களைப் பெற முடியவில்லை",
    UNABLE_TO_LOAD_ORDERS_PENDING_TO_TRANSFER_OUT_TO_FACTORY:
      "தொழிற்சாலைக்கு மாற்ற நிலுவையில் உள்ள ஆர்டர்களை ஏற்ற முடியவில்லை",
    UNABLE_TO_GET_DATA_FOR_DASHBOARD: "டாஷ்போர்டுக்கான தரவைப் பெற முடியவில்லை",
    UNABLE_TO_GET_DATA_FOR_TIMESLOT: "டைம்ஸ்லாட்டுக்கான தரவைப் பெற முடியவில்லை",
    CANNOT_REASSIGN_DELIVERY_AS_IT_IS_ALREADY_PROCEEDED_FURTHER:
      "ஏற்கனவே மேலும் முன்னேறியுள்ளதால் விநியோகத்தை மீண்டும் ஒதுக்க முடியாது",
    DELIVERY_REASSIGNED_SUCCESSFULLY: "டெலிவரி வெற்றிகரமாக ஒதுக்கப்பட்டது",
    UNABLE_TO_REASSIGN_DELIVERY_REQUEST:
      "விநியோக கோரிக்கையை மீண்டும் ஒதுக்க முடியவில்லை",
    DELIVERY_STATUS_MUST_BE_DELIVERY_SCHEDULED_OR_ASSIGNED:
      "டெலிவரி நிலை டெலிவரி திட்டமிடப்பட்ட அல்லது ஒதுக்கப்பட்டதாக இருக்க வேண்டும்",
    DELIVERY_RESCHEDULED_SUCCESSFULLY: "டெலிவரி வெற்றிகரமாக திட்டமிடப்பட்டது",
    UNABLE_TO_RESCHEDULE_DELIVERY_REQUEST:
      "விநியோக கோரிக்கையை மறுபரிசீலனை செய்ய முடியவில்லை",
    REQUEST_CANCELLED_SUCCESSFULLY: "கோரிக்கை வெற்றிகரமாக ரத்து செய்யப்பட்டது",
    UNABLE_TO_CANCEL_DELIVERY_REQUEST:
      "விநியோக கோரிக்கையை ரத்து செய்ய முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_DELIVERY_LIST_DATA:
      "விநியோக பட்டியல் தரவின் பட்டியலைப் பெற முடியவில்லை",
    EXPENSE_DELETED_SUCCESSFULLY: "செலவு வெற்றிகரமாக நீக்கப்பட்டது",
    UNABLE_TO_DELETE_EXPENSE: "செலவை நீக்க முடியவில்லை",
    EXPENSE_ADDED_SUCCESSFULLY: "செலவு வெற்றிகரமாக சேர்க்கப்பட்டது",
    EXPENSE_UPDATED_SUCCESSFULLY: "செலவு வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
    UNABLE_TO_SUBMIT_DATA: "தரவை சமர்ப்பிக்க முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_EXPENSES: "செலவுகளின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_GATE_PASS_DATA:
      "கேட் பாஸ் தரவின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_STORE_GARMENT_INVENTORY_DATA:
      "கடை ஆடை சரக்கு தரவைப் பெற முடியவில்லை",
    UNABLE_TO_GET_SPLIT_ORDER_DETAILS:
      "பிளவு ஒழுங்கு விவரங்களைப் பெற முடியவில்லை",
    INVOICE_GENERATED_SUCCESSFULLY:
      "விலைப்பட்டியல் வெற்றிகரமாக உருவாக்கப்பட்டது",
    UNABLE_TO_GENERATE_INVOICE: "விலைப்பட்டியல் உருவாக்க முடியவில்லை",
    INVOICE_DATA_SUBMITTED_SUCCESSFULLY:
      "விலைப்பட்டியல் தரவு வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது",
    UNABLE_TO_SUBMIT_INVOICE_DATA:
      "விலைப்பட்டியல் தரவை சமர்ப்பிக்க முடியவில்லை",
    INVOICE_SETTLED_SUCCESSFULLY:
      "விலைப்பட்டியல் வெற்றிகரமாக தீர்வு காணப்பட்டது",
    UNABLE_TO_SETTLE_INVOICE: "விலைப்பட்டியல் தீர்க்க முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_ORDERS: "ஆர்டர்களின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_UOM_LIST: "Uom பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GENERATE_ESTIMATED_ORDER:
      "மதிப்பிடப்பட்ட வரிசையை உருவாக்க முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_SERVICES: "சேவைகளின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_STORE_DATA: "ஸ்டோர் தரவைப் பெற முடியவில்லை",
    UNABLE_TO_GET_PICKUP_DATA: "இடும் தரவைப் பெற முடியவில்லை",
    TAGS_GENERATED_SUCCESSFULLY: "குறிச்சொற்கள் வெற்றிகரமாக உருவாக்கப்பட்டன",
    UNABLE_TO_GENERATE_TAGS: "குறிச்சொற்களை உருவாக்க முடியவில்லை",
    UNABLE_TO_GET_ORDER_INFORMATION: "ஆர்டர் தகவலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_TIME_CARD: "நேர அட்டை பெற முடியவில்லை",
    UNABLE_TO_LOAD_ADD_ON_SERVICES:
      "சேவைகளில் சேர்க்க ஏற்றுவதை ஏற்ற முடியவில்லை",
    UNABLE_TO_GET_PACKAGING_CHARGES: "பேக்கேஜிங் கட்டணங்களைப் பெற முடியவில்லை",
    UNABLE_TO_GET_DELIVERY_CHARGES: "விநியோக கட்டணங்களை பெற முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_GARMENTS: "ஆடைகளின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_RATES: "கட்டணங்களைப் பெற முடியவில்லை",
    ORDER_SUCCESSFULLY_CREATED: "ஆர்டர் வெற்றிகரமாக உருவாக்கப்பட்டது",
    UNABLE_TO_CREATE_THE_ORDER: "வரிசையை உருவாக்க முடியவில்லை",
    PLEASE_ENTER_THE_PROMOCODE: "விளம்பர குறியீட்டை உள்ளிடவும்",
    PROMOCODE_APPLIED_SUCCESSFULLY:
      "விளம்பர குறியீடு வெற்றிகரமாக பயன்படுத்தப்பட்டது",
    PROMOCODE_IS_INVALID: "விளம்பர குறியீடு தவறானது",
    UNABLE_TO_VALIDATE_PROMOCODE: "விளம்பர குறியீட்டை சரிபார்க்க முடியவில்லை",
    ORDER_SUCCESSFULLY_UPDATED: "ஆர்டர் வெற்றிகரமாக புதுப்பிக்கப்பட்டது",
    UNABLE_TO_UPDATE_THE_ORDER: "ஆர்டரைப் புதுப்பிக்க முடியவில்லை",
    ORDER_SUCCESSFULLY_CANCELLED: "ஆர்டர் வெற்றிகரமாக ரத்து செய்யப்பட்டது",
    UNABLE_TO_CANCEL_ORDER: "ஆர்டரை ரத்து செய்ய முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_PICKUP_DATA:
      "இடும் தரவின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_USER_DATA: "பயனர் தரவின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_REASSIGN_THE_PICKUP: "இடும் இடத்தை மீண்டும் ஒதுக்க முடியவில்லை",
    UNABLE_TO_GET_DATA_FOR_HISTORY: "வரலாற்றுக்கான தரவைப் பெற முடியவில்லை",
    UNABLE_TO_GET_DATA_FOR_DELIVERY_TYPES:
      "விநியோக வகைகளுக்கான தரவைப் பெற முடியவில்லை",
    UNABLE_TO_GET_DATA_FOR_PICKUP_STATUS:
      "இடும் நிலைக்கு தரவைப் பெற முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_OPERATION_INSTRUCTION_DATA:
      "செயல்பாட்டு வழிமுறை தரவின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_SERVICES_DATA: "சேவை தரவைப் பெற முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_REASONS_DATA: "காரணங்களின் தரவைப் பெற முடியவில்லை",
    UNABLE_TO_GET_DATA_FOR_CUSTOMER:
      "வாடிக்கையாளருக்கான தரவைப் பெற முடியவில்லை",
    UNABLE_TO_GET_CUSTOMER_ADDRESSES:
      "வாடிக்கையாளர் முகவரிகளைப் பெற முடியவில்லை",
    UNABLE_TO_GET_DATA: "தரவைப் பெற முடியவில்லை",
    PLEASE_INPUT_CUSTOMERNAME_ADDRESS_SERVICES_PROMOCODE:
      "வாடிக்கையாளர் பெயர் / முகவரி / சேவைகள் / விளம்பர குறியீட்டை உள்ளிடவும்",
    PICKUP_IS_UPDATED: "இடும் புதுப்பிக்கப்பட்டது",
    PICKUP_IS_SCHEDULED: "இடும் திட்டமிடப்பட்டுள்ளது",
    PICKUP_IS_RESCHEDULED_SUCCESSFULLY:
      "இடும் வெற்றிகரமாக மாற்றியமைக்கப்பட்டுள்ளது",
    UNABLE_TO_RESCHEDULE_THE_PICKUP:
      "இடும் நேரத்தை மறுபரிசீலனை செய்ய முடியவில்லை",
    PICKUP_IS_CANCELLED_SUCCESSFULLY:
      "எடுப்பது வெற்றிகரமாக ரத்து செய்யப்படுகிறது",
    UNABLE_TO_CANCEL_PICKUP: "இடும் ரத்து செய்ய முடியவில்லை",
    UNABLE_TO_GET_PRE_QC_RESPONSE_LIST:
      "முன் qc மறுமொழி பட்டியலைப் பெற முடியவில்லை",
    QC_RESPONSE_SUBMITTED_SUCCESSFULLY:
      "QC பதில் வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது",
    UNABLE_TO_SUBMIT_QC_RESPONSE: "QC பதிலைச் சமர்ப்பிக்க முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_READY_FOR_DELIVERY_INVOICE_DATA:
      "டெலிவரி / விலைப்பட்டியல் தரவுகளுக்குத் தயாரான பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_SUBMIT_STICKER_DATA: "ஸ்டிக்கர் தரவை சமர்ப்பிக்க முடியவில்லை",
    UNABLE_TO_GET_STICKER_DATA: "ஸ்டிக்கர் தரவைப் பெற முடியவில்லை",
    PLEASE_ENTER_STICKER_ID: "ஸ்டிக்கர் ஐடியை உள்ளிடவும்",
    UNABLE_TO_GET_LIST_OF_SERVICE_DATA:
      "சேவை தரவின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_SPLIT_ORDER_NUMBERS: "பிளவு வரிசை எண்களைப் பெற முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_FACTORY_DATA:
      "தொழிற்சாலை தரவுகளின் பட்டியலைப் பெற முடியவில்லை",
    HAS_BEEN_GENERATED: "கேட் பாஸ் உருவாக்கப்பட்டுள்ளது",
    UNABLE_TO_GET_LIST_OF_STICKER_DATA:
      "ஸ்டிக்கர் தரவின் பட்டியலைப் பெற முடியவில்லை",
    UNABLE_TO_GET_LIST_OF_PACKAGE_CONFIGURATION_DATA:
      "தொகுப்பு உள்ளமைவு தரவின் பட்டியலைப் பெற முடியவில்லை",
    PLEASE_SELECT_ATLEAST_ONE_ROW:
      "குறைந்தபட்சம் ஒரு வரிசையைத் தேர்ந்தெடுக்கவும்",
    UNABLE_TO_LOAD_REWASH_REASONS: "Rewash காரணங்களை ஏற்ற முடியவில்லை",
    UNABLE_TO_LOAD_REWASH_REQUESTED_BY_LIST:
      "பட்டியல் மூலம் கோரப்பட்ட Rewash ஐ ஏற்ற முடியவில்லை",
    UNABLE_TO_LOAD_OPERATION_INSTRUCTIONS:
      "செயல்பாட்டு வழிமுறைகளை ஏற்ற முடியவில்லை",
    UNABLE_TO_LOAD_BRANDS: "பிராண்டுகளை ஏற்ற முடியவில்லை",
    UNABLE_TO_LOAD_SIZES: "அளவுகளை ஏற்ற முடியவில்லை",
    UNABLE_TO_LOAD_COLORS: "வண்ணங்களை ஏற்ற முடியவில்லை",
    WALLET_BALANCE: "Wallet இருப்பு",
    INVALID: "தவறானது",
    SPLITORDERNUMBER_SHOULD_NOT_BE_BLANK:
      "SplitOrderNumber காலியாக இருக்கக்கூடாது",
    VALID: "செல்லுபடியாகும்",
    ADJUSTMENTAMOUNT_SHOULD_BE_BETWEEN_0_AND_10:
      "சரிசெய்தல் தொகை 0 முதல் 10 வரை இருக்க வேண்டும்",
    SERVICE_RATE_NOT_AVAILABLE: "சேவை வீதம் கிடைக்கவில்லை",
    ADDON_SERVICE_RATE_NOT_AVAILABLE: "கூடுதல் சேவை வீதம் கிடைக்கவில்லை",
    SCAN_GARMENT: "ஆடை ஸ்கேன்",
    CLEAR: "அழி",
    SCAN_TAG: "ஸ்கேன் டேக்",
    PLEASE_SCAN_TAG: "குறிச்சொல்லை ஸ்கேன் செய்யவும்",
    READY_FOR_TRANSFER_OUT: "பரிமாற்றத்திற்கு தயாராக உள்ளது",
    SCANNED_GARMENT_LIST: "ஸ்கேன் செய்யப்பட்ட ஆடை பட்டியல்",
    THIS_STICKER_BELONGS_TO_ANOTHER_STORE:
      "இந்த ஸ்டிக்கர் மற்றொரு கடைக்கு சொந்தமானது",
    PLEASE_ENTER_VALID_STICKER_NUMBER:
      "செல்லுபடியாகும் ஸ்டிக்கர் எண்ணை உள்ளிடவும்",
    ALL: "அனைத்தும்",
    THIS_TAG_ALREADY_EXISTS: "இந்த குறிச்சொல் ஏற்கனவே உள்ளது",
    PREVIOUSLY_PACKE_ON: "முன்பு பேக் செய்யப்பட்டது",
    CASH_COLLECTION: "பண வசூல்",
    ALREADY_EXISTS: "ஏற்கனவே இருக்கிறது",
    USER_PUNCHIN_NOT_FOUND: "பயனர் புஞ்சின் கிடைக்கவில்லை",
    IT_IS_ALREADY_BLOCKED: "இது ஏற்கனவே தடுக்கப்பட்டுள்ளது",
    AUTHORIZED: "அங்கீகாரம் பெற்றது",
    BLOCKED: "தடுக்கப்பட்டது",
    INVALID_OTP: "தவறான OTP",
    PLEASE_CHECK_THE_PROVIDED_BANNERID:
      "வழங்கப்பட்ட பேனர் ஐடியைச் சரிபார்க்கவும்",
    ALREADY_IN_USE: "ஏற்கனவே உபயோகத்தில் உள்ளது",
    ALREADY_EXISTS: "ஏற்கனவே இருக்கிறது",
    DATE_OVERLAPPING: "தேதி ஒன்றுடன் ஒன்று",
    REFERRAL_CODE_DOES_NOT_EXISTS: "பரிந்துரை குறியீடு இல்லை",
    REFERRAL_CODE_IS_ALREADY_USED:
      "பரிந்துரை குறியீடு ஏற்கனவே பயன்படுத்தப்பட்டுள்ளது",
    USER_NAME_IS_INVALID: "பயனர்பெயர் தவறானது",
    PASSWORD_IS_INVALID: "கடவுச்சொல் தவறானது",
    USER_NAME_AND_PASSWORD_DOES_NOT_MATCH:
      "பயனர் பெயர் மற்றும் கடவுச்சொல் பொருந்தவில்லை",
    USER_IS_NOT_EXISTS: "பயனர் இல்லை",
    SPLIT_ORDER_NUMBER_IS_REQUIRED: "பிளவு ஆர்டர் எண் தேவை",
    INVALID_SPLIT_ORDER_NUMBER: "தவறான பிளவு ஆர்டர் எண்",
    IT_IS_ALREADY_INACTIVATED: "இது ஏற்கனவே செயலிழந்துள்ளது",
    COUPON_CODE_NOT_EXISTS: "கூப்பன் குறியீடு இல்லை",
    CASH_COUPON_EXPIRED: "பண கூப்பன் காலாவதியானது",
    IT_IS_APPLICABLE_FOR_ONLY_FIRST_TIME_USERS:
      "இது முதல் முறையாக பயனர்களுக்கு மட்டுமே பொருந்தும்",
    YOU_HAVE_ALREADY_USED_THIS_COUPON:
      "நீங்கள் ஏற்கனவே இந்த கூப்பனைப் பயன்படுத்தியுள்ளீர்கள்",
    INVALID_COUPON: "தவறான கூப்பன்",
    SPLITORDERNUMBER_SHOULD_NOT_BE_BLANK:
      "SplitOrderNumber காலியாக இருக்கக்கூடாது",
    THIS_PICKUP_IS_ALREADY_IN_PROGRESS: "இந்த இடும் ஏற்கனவே செயலில் உள்ளது",
    YOU_HAVE_ALREADY_RESCHEDULED_THIS_PICKUP_3_TIMES:
      "நீங்கள் ஏற்கனவே இந்த இடத்தை 3 முறை மாற்றியமைத்துள்ளீர்கள்",
    PLEASE_CHECK_WITH_DELIVERYID: "DeliveryID உடன் சரிபார்க்கவும்",
    PLEASE_ASSIGN_IT_TO_SOMEONE_FIRST: "முதலில் அதை ஒருவருக்கு ஒதுக்குங்கள்",
    CANNOT_REASSIGN_DELIVERY_AS_ALREADY_PROCEED_FURTHER:
      "ஏற்கனவே மேலும் முன்னேறுவதால் விநியோகத்தை மீண்டும் ஒதுக்க முடியாது",
    PLEASE_CHECK_WITH_STATUS: "நிலையை சரிபார்க்கவும்",

    STICKER_ID: "ஸ்டிக்கர் ஐடி",
    PACKED_On: "பேக் ஆன்",
    PACKING_IDS: "பேக்கிங் ஐடிகள்",
    RESORTING_AND_PACKAGING: "ரிசார்டிங் மற்றும் பேக்கேஜிங்",
    RESORTING_AND_PACKAGING_List: "ரிசார்டிங் மற்றும் பேக்கேஜிங் பட்டியல்",
    PADING_QC_RESPONSE: "முன்-க்யூசி மறுமொழி எண்ணிக்கை நிலுவையில் உள்ளது",
    LANGUAGE: "மொழி",
    CHANGE_PASSWORD: "கடவுச்சொல்லை மாற்று",
    LOGOUT: "வெளியேறு",
    TAG_NUMBER_NOT_FOUND: "குறிச்சொல் எண் கிடைக்கவில்லை",
    TAG_NO: "குறிச்சொல் எண்",

    YOU_CAN_ONLY_UPLOAD_JPG_JPEG_PNG_FILE:
      "நீங்கள் JPG / PNG / JPEG கோப்பை மட்டுமே பதிவேற்ற முடியும்",
    IMAGE_MUST_BE_SMALLER_THAN_5_MB:
      "படம் 5 எம்பியை விட சிறியதாக இருக்க வேண்டும்",
    GARMENT_SHOULD_BE_INFACTORY_TO_BE_ADDED_FOR_QC:
      "க்யூ.சி.க்கு சேர்க்க ஆலை தொழிற்சாலையில் இருக்க வேண்டும்",
    ORDER_ALREADY_CONFIRMED: "ஆர்டர் ஏற்கனவே உறுதிப்படுத்தப்பட்டுள்ளது",
    PENDING_PAYMENT: "கட்டணம் நிலுவையில்",
    IT_SEEMS_LIKE_FEW_OF_THE_SERVICES_ARE_NOT_EXISTS_INTO_SYSTEM:
      "சில சேவைகள் கணினியில் இல்லை என்பது போல் தெரிகிறது. சேவை மாஸ்டர் திரையில் விவரங்களைச் சரிபார்த்து மீண்டும் முயற்சிக்கவும்",
    IT_SEEMS_LIKE_FEW_OF_THE_GARMENTS_ARE_NOT_EXISTS_INTO_SYSTEM:
      "சில ஆடைகள் அமைப்பில் இல்லை என்பது போல் தெரிகிறது. ஆடை மாஸ்டர் திரையில் விவரங்களைச் சரிபார்த்து மீண்டும் முயற்சிக்கவும்",
    SERVICE_TAX_DETAIL_IS_MISSING_IN_SECOND_SHEET:
      "சேவை வரி விவரம் இரண்டாவது தாளில் இல்லை",
    PLATFORM: "கட்டணம் நிலுவையில்",
    ORDER_COUNT: "நடைமேடை",
    PROMO_CODE: "விளம்பர குறியீடு",
    PICK_BOY: "பிக்கப் பாய்",
    CUST_MO_No: "வாடிக்கையாளர் மொபைல் எண்",
    SERVICE_ICONS: "சேவை சின்னங்கள்",
    REQUEST_CREATED_DATE: "கோரிக்கை உருவாக்கிய தேதி & நேரம்",
    PICKUP_REQUEST_DATE: "இடும் கோரிக்கை தேதி & ஸ்லாட்",
    ORDER_CREATED_DATE: "ஆர்டர் உருவாக்கப்பட்ட தேதி & நேரம்",
    REPRINT_TAX_INVOICE: "வரி விலைப்பட்டியல் மறுபதிப்பு",
    SEMI_EXPRESS_RATE: "(செமி எக்ஸ்பிரஸ் - 1.5 எக்ஸ்)",
    EXPRESS_RATE: "(எக்ஸ்பிரஸ் - 2 எக்ஸ்)",
    TOTAL_STICKERS: "மொத்த ஸ்டிக்கர்கள்",
    DELIVERY_BOY: "டெலிவரி பாய்",
    POST_QC: "QC ஐ இடுங்கள்",
    UNABLE_TO_GET_POST_QC_DATA: "பிந்தைய qc தரவைப் பெற முடியவில்லை",
    GARMENT_PROCEEDED_FURTHER_FOR_POST_PROCESS:
      "பிந்தைய qc செயல்முறைக்கு ஆடை மேலும் தொடர்ந்தது",
    POST_QC_STATUS: "PostQC நிலை",
    POST_QC_REMARK: "PostQC குறிப்பு",
    POST_QC_DATETIME: "QC தேதிநேரத்தை இடுகையிடவும்",
    POSTED_BY: "பதிவிட்டவர்",
    ADD_GARMENT_FOR_POST_QC: "இடுகை QC க்கு ஆடை சேர்க்கவும்",
    LATEST_POSTQC_DETAILS: "சமீபத்திய PostQc விவரங்கள்",
    PICKUP_TIMESLOT: "பிக்அப் டைம்ஸ்லாட்",
    UNABLE_TO_GET_STORE_NAME: "ஸ்டோர் நம் பெற முடியவில்லை",
    CANCEL_REASON_IS_REQUIRED: "ரத்துசெய் புலம் தேவை",
    FACTORY_GATE_PASS: "தொழிற்சாலை கேட் பாஸ் பட்டியல்",
    CUSTOMER_REMARK: "வாடிக்கையாளர் கருத்து",
    STORE_OUT_DATE: "தேதியை சேமிக்கவும்",
    LAST_UPDATED_ON: "கடைசியாக புதுப்பிக்கப்பட்டது",
    LAST_UPDATED_BY: "கடைசியாக புதுப்பிக்கப்பட்டது",
    FACTORY_IN_DATETIME: "தேதிநேரத்தில் தொழிற்சாலை",
    GARMENTS_FACTORY_IN: "ஆடைகள் தொழிற்சாலை",
    GARMENTS_MISSING: "ஆடைகள் காணவில்லை",
    BALANCED_GARMENTS: "சமச்சீர் ஆடைகள்",
    FACTORY_IN_DATE: "தேதியில் தொழிற்சாலை",
    LOT_SIZE: "நிறைய அளவு",
    STANDARD_RATE_CARD: "நிலையான வீத அட்டை",
    ORDER_STATUS: "ஆர்டர் நிலை",
    INVOICE_STATUS: "விலைப்பட்டியல் நிலை",
    STOREINDATE: "தேதியில் சேமிக்கவும்",
    FACTORYOUTDATE: "தொழிற்சாலை அவுட் தேதி",
    PLEASE_SELECT_PAYMENT_MODE: "கட்டண பயன்முறையைத் தேர்ந்தெடுக்கவும்",
    SELECT_PACKING_MATERIAL:
      "நீங்கள் 99 வரை மட்டுமே பொதி பொருளைத் தேர்ந்தெடுக்க முடியும்",
    SOMETHING_WENT_WRONG: "ஏதோ தவறு நடந்துவிட்டது",
    UNABLE_TO_GET_PAYMENT_METHOD_DATA: "கட்டண முறை தரவைப் பெற முடியவில்லை",
    ARE_YOU_SURE_YOU_WANT_TO_MARK_ORDER_AS_COMPLETED:
      "ஆர்டர் முடிந்ததை குறிக்க விரும்புகிறீர்களா?",
    ORDER_MARKED_AS_COMPLETED: "ஆர்டர் முடிந்ததாக குறிக்கப்பட்டுள்ளது",
    UNABLE_TO_MARK_ORDER_AS_COMPLETED: "முடிந்ததை ஆர்டர் குறிக்க முடியவில்லை",
    EDC_CONFIGURATION: "EDC சாதனம் இந்த கடையுடன் இணைக்கப்படவில்லை",
    ALTER_PHONE_NUMBER: "மாற்று தொலைபேசி எண்",
    // ATTENDANCE: "வருகை",
    // ATTENDANCE_REPORT: "வருகை அறிக்கை",
    // OTHER_TYPE: "பிற வகை",
    // PLEASE_ENTER_OTHER_TYPE: "தயவுசெய்து பிற வகையை உள்ளிடுக",
    // ENTER_OTHER_TYPE: "பிற வகையை உள்ளிடுக",
  },
};

const currentLang = Object.keys(allLabels).includes(
  localStorage.getItem("locale")
)
  ? localStorage.getItem("locale")
  : "ENGLISH";

const currentLangLabels = allLabels[currentLang];
const englishLabels = allLabels["ENGLISH"];
const labels = { ...englishLabels, ...currentLangLabels };

let engKeys = Object.keys(allLabels["ENGLISH"]);
let langKeys = Object.keys(allLabels["HINDI"]);
let nonExist = engKeys.filter(function (el) {
  return langKeys.indexOf(el) < 0;
});
export { labels, currentLang, allLabels };
