import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../../components/Common/Button/button";
import Filters from "../../../components/Common/Filters/filters";
import TableActions from "../../../components/Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  TextArea,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  Checkbox,
  notification,
  Tooltip,
  Spin,
  Upload,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faFileExcel,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ServiceItem from "../../../services/serviceservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import Configuration from "../../../shared/configuration";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { PlusOutlined } from "@ant-design/icons";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { Search } = Input;
const { Option } = Select;



// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};



export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.ServiceItem = new ServiceItem();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.config = new Configuration();
    this.state = {
      modalLoading: false,
      serviceItems: [],
      serviceID: 0,
      serviceName: "",
      prefix: "",
      isAddOn: false,
      showInApp: false,
      showInWeb: false,
      description: "",
      hsnCode: "",
      title: "",
      uom: [],
      serviceImage: "",
      fileList: [],
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },
      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      loading: true,
      showfilterBox: false,
      showAddPopup: false,
      excelFile: <React.Fragment></React.Fragment>,
      excelButtonLoading: false,
      garmentUOMListOptions: [],
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.SERVICE
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  DropDownGarmentUOMList = () => {
    this.ServiceItem.GarmentUOMListItems().then((items) => {
      if (items.statusCode === 200) {
        this.setState({ garmentUOMListOptions: items.data });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "Garment Master",
            description: items.message
              ? items.message
              : "Unable to get list of uom data",
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
      }
    });
  };

  columns = [
    {
      title: "Service ID",
      dataIndex: "serviceID",
      key: "serviceID",
    },
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
      sorter: true,
    },

    {
      title: "Prefix",
      dataIndex: "prefix",
      key: "prefix",
      sorter: true,
    },
    {
      title: "Is Add On",
      dataIndex: "isAddOn",
      key: "isAddOn",
      sorter: true,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: "HSN Code",
      dataIndex: "hsnCode",
      key: "hsnCode",
      sorter: true,
    },
    {
      title: "Service Image",
      dataIndex: "serviceImage",
      key: "serviceImage",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip
          placement="topLeft"
          title={address.split(this.config.SERVICE_MASTER_IMAGE_URL)}
        >
          {address.split(this.config.SERVICE_MASTER_IMAGE_URL)}
        </Tooltip>
      ),
    },

    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    serviceID: record.serviceID,
                    serviceName: record.serviceName,
                    prefix: record.prefix,
                    isAddOn: record.isAddOn === "false" ? 0 : 1,
                    // showInApp: record.showInApp === "false" ? "false" : "true",
                    // showInWeb: record.showInWeb === "false" ? "false" : "true",
                    showInApp: record.showInApp === "false" ? 0 : 1,
                    showInWeb: record.showInWeb === "false" ? 0 : 1,
                    description: record.description,
                    hsnCode: record.hsnCode,
                    title: record.title,
                    serviceImage: record.serviceImage,
                    isEnabled: true,
                    isVisible: true,
                    uom: record.uom.toString(),
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              {/* {console.log("ressss->",record)} */}
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    serviceID: record.serviceID,
                    serviceName: record.serviceName,
                    prefix: record.prefix,
                    isAddOn: record.isAddOn === "false" ? 0 : 1,
                    // showInApp: record.showInApp === "false" ? "false" : "true",
                    // showInWeb: record.showInWeb === "false" ? "false" : "true",
                    showInApp: record.showInApp === "false" ? 0 : 1,
                    showInWeb: record.showInWeb === "false" ? 0 : 1,
                    description: record.description,
                    hsnCode: record.hsnCode,
                    title: record.title,
                    serviceImage: record.serviceImage,
                    uom: record.uom.toString(),
                  })
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.serviceID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      serviceID: this.state.serviceID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
    this.DropDownGarmentUOMList();
  }
  //function for handling search change event
  handleSearch = (event) => {
    this.fetchItems({
      serviceID: this.state.serviceID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table paginaion when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      serviceID: this.state.serviceID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.ServiceItem.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            serviceID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          serviceItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Service Master",
          description: items.message
            ? items.message
            : "Unable to get list of service data",
        });
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (serviceID) => {
    if (serviceID === "" || serviceID === null || serviceID === undefined) {
      // openNotificationWithIcon(
      //   "error",
      //   "Service Master",
      //   "Please Select Atleast One Row"
      // );
      notification["error"]({
        message: "Service Master",
        description: "Please Select Atleast One Row",
      });
    } else {
      this.setState({ loading: true });
      this.ServiceItem.removeItems(serviceID).then((items) => {
        if (items.result === "Success") {
          // openNotificationWithIcon("success", "Service Master", items.message);
          notification["success"]({
            message: "Service Master",
            description: items.message,
          });
          const { pagination } = this.state;
          this.fetchItems({
            serviceID: parseInt(this.state.serviceID),
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
          });
        } else {
          // openNotificationWithIcon("error", "Service Master", items.message);
          notification["error"]({
            message: "Service Master",
            description: items.message,
          });
        }
        this.setState({ loading: false });
      });
    }
  };

  oldServiceImageFromUrl = () => {
    let str1 = "";
    if (![undefined, null, ""].includes(this.state.serviceImage)) {
      let str = this.state.serviceImage.split("/");
      str1 = str[str.length - 1];
    }
    return str1;
  };

  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    var fileData = this.state.fileList;
    const formData = new FormData();

    formData.append(
      "serviceImage",
      this.state.fileList.length > 0
        ? this.state.fileList[0].originFileObj
        : null
    );
    formData.append("serviceID", this.state.serviceID);
    formData.append("serviceName", this.state.serviceName);
    formData.append("prefix", this.state.prefix);
    formData.append("isAddOn", this.state.isAddOn === false ? false : true);
    formData.append("showInApp", this.state.showInApp === false ? false : true);
    formData.append("showInWeb", this.state.showInWeb === false ? false : true);
    formData.append("description", this.state.description);
    formData.append("hsnCode", this.state.hsnCode);
    formData.append("title", this.state.title);
    formData.append("isNewImgUploaded", true);
    formData.append("oldServiceImg", this.oldServiceImageFromUrl());
    formData.append("uom", this.state.uom);

    // var postData = {
    //   serviceID: this.state.serviceID,
    //   serviceName: this.state.serviceName,
    //   prefix: this.state.prefix,
    //   isAddOn: this.state.isAddOn === false ? false : true,
    //   description: this.state.description,
    //   hsnCode: this.state.hsnCode,
    //   title: this.state.title,
    //   isActive: true,
    // };

    for (var value of formData.values()) {
      // console.log(value);
    }
    this.setState({ modalLoading: true });
    this.ServiceItem.insertUpdateItems(formData).then((items) => {
      if (items.result === "Success") {
        // openNotificationWithIcon("success", "Service Master", items.message);
        notification["success"]({
          message: "Service Master",
          description: items.message,
        });
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          serviceID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        // openNotificationWithIcon("error", "Service Master", items.message);
        notification["error"]({
          message: "Service Master",
          description: items.message,
        });
      }
      this.setState({ modalLoading: false });
    });
  };
  //function for changing/updating the service name
  handleServiceName = (e) => {
    let serviceNm = e.target.value;
    this.setState({ serviceName: serviceNm });
  };
  handleHSNCodeChange = (e) => {
    this.setState({ hsnCode: e.target.value });
  };

  //function for changing/updating the prefix
  handlePrefixChange = (e) => {
    let prefix = e.target.value;
    this.setState({ prefix: prefix });
  };

  handleDescriptionChange = (e) => {
    let details = e.target.value;
    this.setState({ description: details });
  };

  handleTitleChange = (e) => {
    this.setState({ title: e.target.value });
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = (info) => {
    if (info.fileList.length > 0) {
      if (info.file !== undefined) {
        const isJpgOrPng =
          info.file.type === "image/jpeg" ||
          info.file.type === "image/png" ||
          info.file.type === "image/jpg";
        if (!isJpgOrPng) {
          notification["error"]({
            message: "Service Master",
            description: "You can only upload JPG/PNG/JPEG file!",
          });
          info.file = undefined;
        }
        let isLt3M = false;
        if (info.file !== undefined) {
          isLt3M = info.file.size / 1024 / 1024 < 3;
          if (!isLt3M) {
            notification["error"]({
              message: "Service Master",
              description: "Image must be smaller than 3 MB!",
            });
            info.file = undefined;
          }
        }
        if (isJpgOrPng && isLt3M) {
          info.file.status = "done";
        }
        var infoFileList = [];
        infoFileList.push(info.file);
        info.file = infoFileList.filter((x) => x != undefined);
        this.setState({ fileList: info.file });
        if (info.file.length > 0) {
          this.setState({ serviceImage: "" });
        }
      }
    } else {
      this.setState({ fileList: [], serviceImage: "" });
    }
  };
  //function for clearing out the states
  resetFields = () => {
    this.setState({
      serviceID: 0,
      serviceName: "",
      prefix: "",
      description: "",
      hsnCode: "",
      title: "",
      fileList: [],
      isAddOn: false,
      showInApp: false,
      showInWeb: false,
      isEnabled: false,
      isVisible: false,
      serviceImage: "",
      uom: [],
    });
  };

  handleCheckbox = (event) => {
    this.setState({ isAddOn: event.target.checked });
  };

  handleAppCheckbox = (event) => {
    this.setState({ showInApp: event.target.checked });
  };

  handleWebCheckbox = (event) => {
    this.setState({ showInWeb: event.target.checked });
  };

  generateExcel = () => {
    if (this.state.serviceItems.length > 0) {
      this.setState({ excelButtonLoading: true });
      let totalRecords = this.state.serviceItems[0].totalRecords;
      this.ServiceItem.retrieveItems({
        serviceID: null,
        pageIndex: 1,
        pageSize: totalRecords,
      }).then((response) => {
        if (response.statusCode === 200) {
          this.setState(
            {
              excelFile: (
                <div>
                  <ExcelFile hideElement={true} filename="Service Master">
                    <ExcelSheet
                      data={response.data}
                      name={"Services"}
                      columns={[]}
                    >
                      <ExcelColumn label="ID" value="serviceID" />
                      <ExcelColumn label="Prefix" value="prefix" />
                      <ExcelColumn label="Name" value="serviceName" />
                      <ExcelColumn label="Image URL" value="serviceImage" />
                      <ExcelColumn label="HSN" value="hsnCode" />
                      <ExcelColumn label="Title" value="title" />
                      <ExcelColumn label="Description" value="description" />
                      <ExcelColumn label="Is Add-on ?" value="isAddOn" />
                      {/* <ExcelColumn label="Show In APP" value="showInApp" />
                      <ExcelColumn label="Show In Web" value="showInWeb" /> */}
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              ),
            },
            () =>
              this.setState({
                excelFile: <React.Fragment></React.Fragment>,
                excelButtonLoading: false,
              })
          );
        }
      });
    }
  };
  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification["error"]({
        message: "Service Master",
        description: "You can only upload JPG/PNG file!",
      });
      this.setState({ fileList: [] });
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      notification["error"]({
        message: "Service Master",
        description: "Image must smaller than 1MB!",
      });
      this.setState({ fileList: [] });
    }
    return isJpgOrPng && isLt2M;
  };

  render() {
    const {
      serviceName,
      prefix,
      description,
      hsnCode,
      title,
      pagination,
      serviceImage,
      uom,
      loading,
      showInApp,
      showInWeb,
    } = this.state;
    const Regex = /^[A-Za-z0-9_&-\s]*$/;
    const serviceItems = this.state.serviceItems;
    const listItems = serviceItems.map((item, index) => ({
      key: item.serviceID,
      serviceID: item.serviceID,
      serviceName: item.serviceName,
      prefix: item.prefix,
      uom: item.uom,
      isAddOn: item.isAddOn.toString(),
      showInApp:item.showInApp.toString(),
      showInWeb:item.showInWeb.toString(),
      description: item.description,
      hsnCode: item.hsnCode,
      title: item.title,
      serviceImage: item.serviceImage,
      isActive: item.isActive.toString(),
      createdByName: item.createdByName,
      createdOn: Moment(item.createdOn).format("DD/MM/YYYY hh:mm:ss a"),
      totalRecords: item.totalRecords,
    }));

    const uploadButton =
      this.state.fileList.length === 0 ? (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">Upload</div>
        </div>
      ) : null;
    return (
      <div>
        {this.state.excelFile}
        <PageHeader
          title="Service Master"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,

            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddPopup: true })}
              >
                Add Service
              </Button>
            ) : null,
            this.state.serviceItems.length > 0 ? (
              <Button
                variant="transparentGreyOutline"
                style={{
                  display: "flex",
                  alignItems: "center",
                  lineHeight: "19px",
                }}
                onClick={() => {
                  this.generateExcel();
                }}
                loading={this.state.excelButtonLoading}
              >
                <FontAwesomeIcon
                  icon={faFileExcel}
                  style={{
                    fontSize: 18,
                    marginRight: 5,
                  }}
                />{" "}
                Download Excel
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          rowSelection={{
            type: this.selectionType,
            ...this.rowSelection,
          }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            serviceItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null}
        </TableActions>

        <Modal
          title={
            this.state.serviceID === 0
              ? "Add Service"
              : this.state.isEnabled
              ? "View Service"
              : "Edit Service"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{
                serviceName,
                prefix,
                description,
                hsnCode,
                title,
                serviceImage,
                uom,
                showInApp,
                showInWeb,
              }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Service Name "
                    name="serviceName"
                    rules={[
                      { required: true, message: "Please Enter Service Name!" },
                      // {
                      //   pattern: Regex,
                      //   message:
                      //     "You Can Enter Alphabets,Digits,Underscore, Hyphen Or Ampersand",
                      // },
                    ]}
                  >
                    <Input
                      placeholder="Enter Service Name"
                      disabled={this.state.isEnabled}
                      onChange={this.handleServiceName}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label="Prefix"
                    name="prefix"
                    rules={[
                      { required: true, message: "Please Enter Prefix!" },
                    ]}
                  >
                    <Input
                      placeholder="Enter Prefix "
                      disabled={this.state.isEnabled}
                      onChange={this.handlePrefixChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item
                    label="HSN Code"
                    name="hsnCode"
                    rules={[
                      { required: true, message: "Please Enter HSN Code!" },
                    ]}
                  >
                    <Input
                      placeholder="Enter HSN Code "
                      disabled={this.state.isEnabled}
                      onChange={this.handleHSNCodeChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item label="isAddOn" name="isAddOn">
                    <Checkbox
                      onChange={this.handleCheckbox}
                      disabled={this.state.isEnabled}
                      checked={this.state.isAddOn}
                    >
                      {/* IsAddOn? */}
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="UOM"
                    name="uom"
                    rules={[{ required: true, message: "Please select UOM !" }]}
                  >
                    <Select
                      disabled={this.state.isEnabled}
                      onChange={(event) => {
                        this.setState({ uom: event });
                      }}
                      placeholder="Select GarmentUOM"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.garmentUOMListOptions.map((uomitem) =>
                        uomitem.value !== "-1" ? (
                          <option key={uomitem.value} value={uomitem.value}>
                            {uomitem.text}
                          </option>
                        ) : null
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    label="Title"
                    name="title"
                    // rules={[{ required: true, message: "Please Enter Description!" }]}
                  >
                    <Input
                      placeholder="Enter Title "
                      disabled={this.state.isEnabled}
                      onChange={this.handleTitleChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Description"
                    name="description"
                    // rules={[{ required: true, message: "Please Enter Description!" }]}
                  >
                    <Input.TextArea
                      placeholder="Enter Description "
                      disabled={this.state.isEnabled}
                      onChange={this.handleDescriptionChange}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={12}>
                  {this.state.isEnabled === false ? (
                    <>
                      <Space>
                        <Form.Item
                          label="Select File To Upload"
                          name="file"
                          valuePropName={"file"}
                          rules={[
                            {
                              required: this.state.serviceID === 0,
                              message: "Please Select File To Upload",
                            },
                          ]}
                        >
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture-card"
                            fileList={this.state.fileList}
                            // onPreview={this.handlePreview}
                            onChange={this.handleChange}
                            customRequest={({ file, onSuccess, onError }) => {
                              onSuccess({ status: "done" }, file);
                            }}
                          >
                            {/* {this.state.fileList.length >= 0
                              ? null
                              : uploadButton} */}
                            {uploadButton}
                          </Upload>
                        </Form.Item>
                        {this.state.serviceID != 0 ? (
                          <Form.Item>
                            <img
                              src={this.state.serviceImage}
                              style={{ maxWidth: "100px", maxHeight: "100px" }}
                            />
                          </Form.Item>
                        ) : null}
                      </Space>
                    </>
                  ) : (
                    <Form.Item label="Image Url">
                      <img
                        src={this.state.serviceImage}
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </Form.Item>
                  )}
                </Col>

                 <Col xs={24} md={12}>
                  <Form.Item
                    label=" "
                    name="showInApp"
                    style={{ marginBottom: -20, marginTop: 15 }}
                  >
                    <Checkbox
                      onChange={this.handleAppCheckbox}
                      disabled={this.state.isEnabled}
                      checked={this.state.showInApp}
                    >
                      Show in the App
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    label=" "
                    name="showInWeb"
                    style={{ marginTop: -15 }}
                  >
                    <Checkbox
                      onChange={this.handleWebCheckbox}
                      disabled={this.state.isEnabled}
                      checked={this.state.showInWeb}
                    >
                      Show in the Web
                    </Checkbox>
                  </Form.Item>
                </Col> 

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.serviceID === 0 ? "Add" : "Update"}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
