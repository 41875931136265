import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class RewardsService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

//   async removeItems(deliverychargesID) {
//     const myApi = new API({ url: this.config.ITEM_URL });
//     myApi.createEntity({ name: this.endpoint.DELETE_DELIVERYCHARGE_BY_ID });
//     let itemArray = [];
//     let ID = "?DeliveryChargesIDs=" + deliverychargesID;
//     await myApi.endpoints[this.endpoint.DELETE_DELIVERYCHARGE_BY_ID]
//       .deleteById(ID)
//       .then(({ data }) => {
//         itemArray = data;
//       })
//       .catch((err) => {
//         itemArray = this.common.handleError(err);
//       });
//     if (itemArray.statusCode == 401) {
//       await this.common.refreshToken().then((response) => {
//         this.common.setToken(response);
//         itemArray = this.removeItems(deliverychargesID);
//       });
//     }
//     return Promise.resolve(itemArray);
//   }

  async insertUpdateItems(postData) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.SAVEUPDATE_REWARDS_CONFIG_MASTER });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateRewardsConfigMaster.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.insertUpdateItems(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveItems(param) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_REWARDS_CONFIG_MASTER});
    let itemArray = [];
    await myApi.endpoints.GetRewardsConfigMaster.getAllPost(param)
      .then(({ data }) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getSourceList() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({
      name: this.endpoint.GET_SYSTEME_NUMS,
    });
    let append = "?EnumNames=Source";
    let itemArray = [];

    await myApi.endpoints[this.endpoint.GET_SYSTEME_NUMS]
      .getOne(append)
      .then((response) => {
        itemArray = response.data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getSourceList();
      });
    }
    return itemArray;
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default RewardsService;
