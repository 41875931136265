import React from "react";
import BrandMaster from "../../../components/Master/Brand/brandmaster.component"

export default class BrandScreen extends React.Component {
  render() {
    return (
      <div >
        <BrandMaster></BrandMaster>
      </div>
    );
  }
}