import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  notification,
  Tooltip,
  Modal,
  Checkbox,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //   faChevronUp,
  faPencilAlt,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
// import ROUTES from "../../../routes";
import CreateOrderService from "../../../services/createorderservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";

const { Search } = Input;
const { Option } = Select;

/**
 * A factory master screen which holds grid view for master screens and form with steps
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
class Master extends React.Component {
  createOrderService = new CreateOrderService();
  UserRightsConfiguration = new UserRightsConfiguration();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    showfilterBox: false,
    factories: [],
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    selectedBranch: localStorage.getItem("branchID"),
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) =>
        val.screenName == this.UserRightsConfiguration.PARTIAL_ORDER_RECEIVED
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: labels.SPLIT_ORDER_ID,
      dataIndex: "splitOrderNumber",
      key: "splitOrderNumber",
      sorter: true,
    },
    {
      title: labels.STORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
    {
      title: labels.STORE_OUT_GATE_PASS,
      dataIndex: "storeOutGatePassNo",
      key: "storeOutGatePassNo",
      sorter: true,
    },
    {
      title: labels.STORE_OUT_GATEPASS_TIME,
      dataIndex: "storeOutGatePassTime",
      key: "storeOutGatePassTime",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm:ss");
      },
    },
    {
      title: labels.FACTORY_IN_DATE,
      dataIndex: "factoryInDate",
      key: "factoryInDate",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.RECENT_STORE_IN_DATES,
      dataIndex: "recentStoreInDates",
      key: "recentStoreInDates",
      sorter: true,
      width: "12%",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: labels.DELIVERY_DATE,
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      sorter: true,
      render: (text) => {
        return [null, "", undefined].includes(text)
          ? undefined
          : moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.ORDER_LOT_SIZE,
      dataIndex: "orderLotSize",
      key: "orderLotSize",
      sorter: true,
    },
    {
      title: labels.STORE_IN_GARMENTS,
      dataIndex: "storeInGarments",
      key: "storeInGarments",
      sorter: true,
    },
    {
      title: labels.STORE_IN_STICKER_ID,
      dataIndex: "stickerIds",
      key: "stickerIds",
      sorter: true,
      width: "10%",
      ellipsis: {
        showTitle: false,
      },
      render: (stickerIds) => {
        return (
          <Tooltip placement="topLeft" title={stickerIds}>
            {stickerIds}
          </Tooltip>
        );
      },
    },
    {
      title: labels.BALANCE_GARMENTS,
      dataIndex: "balanceGarments",
      key: "balanceGarments",
      sorter: true,
    },
  ];

  componentDidMount() {
    this.getPartialOrderReceivedList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  }

  getPartialOrderReceivedList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    if (!this.state.tableLoading) {
      this.setState({ tableLoading: true });
      this.createOrderService
        .getPartialOrderReceivedList(
          index,
          size,
          sortColumn,
          sortOrder,
          search,
          this.state.selectedBranch
        )
        .then((response) => {
          if (response.statusCode === 200) {
            if (
              response.data.length === 0 &&
              this.state.pagination.current !== 1
            ) {
              this.setState(
                {
                  pagination: { ...this.state.pagination, current: 1 },
                  tableLoading: false,
                },
                () => {
                  this.getPartialOrderReceivedList(
                    1,
                    size,
                    sortColumn,
                    sortOrder,
                    search
                  );
                }
              );
            }
            let factoryData = [];
            response.data.map((singleData) => {
              singleData.key = singleData.splitOrderNumber;
              factoryData.push(singleData);
              return null;
            });
            this.setState({ factories: factoryData });
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: factoryData.length ? factoryData[0].totalRecords : 0,
              },
            });
          } else {
            notification["error"]({
              message: labels.PARTIAL_ORDER_RECEIVED,
              description: response.message
                ? response.message
                : labels.UNABLE_TO_GET_LIST_OF_PARTIAL_ORDER_DATE,
            });
          }
          this.setState({ tableLoading: false });
        });
    }
  };

  handleChangeBranch = () => {
    this.getPartialOrderReceivedList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  };
  render() {
    const storeBranchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo")).filter(
          (x) => x.branchType === "Store"
        )
      : [];

    const allStoreBranchArray = storeBranchTypes
      .map((branchType) => {
        return branchType.branchID;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();
    return (
      <div>
        <PageHeader
          title={labels.PARTIAL_ORDER_RECEIVED}
          extra={[
            <div key="1" style={{ alignSelf: "center" }}>
              <Select
                showSearch
                mode="multiple"
                allowClear
                // onChange={this.handleSelect}
                onChange={(event) => {
                  this.setState(
                    {
                      selectedBranch: [null, undefined, ""].includes(event)
                        ? ""
                        : event.join(","),
                    },
                    () => {
                      this.handleChangeBranch();
                    }
                  );
                }}
                value={
                  [null, undefined, ""].includes(this.state.selectedBranch)
                    ? [localStorage.getItem("branchID")]
                    : this.state.selectedBranch.split(",")
                }
                placeholder={labels.SELECT_STORE}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ minWidth: 150, maxWidth: 330 }}
              >
                {storeBranchTypes.length !== 0
                  ? storeBranchTypes.map((branchType) => {
                      return branchType.branchID !== "-1" ? (
                        <Option
                          value={branchType.branchID.toString()}
                          key={branchType.branchID.toString()}
                        >
                          {branchType.branchName}
                        </Option>
                      ) : null;
                    })
                  : null}
              </Select>
            </div>,
            <div key="2" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={
                  ![null, undefined, ""].includes(this.state.selectedBranch) &&
                  this.state.selectedBranch.split(",").length ===
                    allStoreBranchArray.length &&
                  this.state.selectedBranch.split(",").slice().sort()
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    let allOpt = storeBranchTypes
                      .map((branchType) => {
                        return branchType.branchID;
                      })
                      .filter((x) => x !== "-1");
                    this.setState(
                      {
                        selectedBranch: allOpt.join(","),
                      },
                      () => {
                        if (this.state.selectedBranch !== "") {
                          this.handleChangeBranch();
                        }
                      }
                    );
                  } else {
                    this.setState(
                      {
                        selectedBranch: localStorage.getItem("branchID"),
                      },
                      () => {
                        this.handleChangeBranch();
                      }
                    );
                  }
                }}
              >
                {labels.ALL}
              </Checkbox>
            </div>,
            <div key="3" style={{ alignSelf: "center" }}>
              <Search
                placeholder={labels.SEARCH}
                className={styles.SearchInput}
                key="1"
                onChange={(event) => {
                  this.setState({
                    search: event.target.value.toString().toLowerCase(),
                  });
                  this.getPartialOrderReceivedList(
                    this.state.pagination.current,
                    this.state.pagination.pageSize,
                    this.state.sorter.field,
                    this.state.sorter.order,
                    event.target.value.toString().toLowerCase()
                  );
                }}
              />
            </div>,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          scroll={
            this.columns.length < 4
              ? { x: 0 }
              : this.columns.length < 6
              ? { x: 1000 }
              : this.columns.length > 6 && this.columns.length <= 12
              ? { x: 1400 }
              : { x: 2000 }
          }
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getPartialOrderReceivedList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(Master);
