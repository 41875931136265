import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import TableActions from "../../Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Tooltip,
  Spin,
  DatePicker,
} from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faInfo,
  faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import OutOfCoverageservice from "../../../services/outofcoverageservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import Common from "../../../services/common";
import { labels } from "../../../shared/language";
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);

export default class outofcoveragecomponent extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.OutOfCoverageservice = new OutOfCoverageservice();
    this.common = new Common();
    this.state = {
      FromDate: moment(ONE_MONTHS_AGO),
      ToDate: moment(),
      modalLoading: false,
      OutOfCoverageItems: [],
      stateName: [],
      stateListOptions: [],
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },

      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      showfilterBox: true,
      showAddPopup: false,
      loading: true,
      userRights: [],
      isResponseErrorShow: false,
      // isRenderComponent: false,
    };
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }

    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.OUT_OF_COVERAGE
    );

    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }

    this.setState({ userRights: res });
  }

  columns = [
    {
      title: "Date",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (text) => {
        return ![undefined, null, ""].includes(text)
          ? moment(text).format("DD-MM-YYYY")
          : "";
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      // sorter: true,
    },
    {
      title: "Gender",
      dataIndex: "genderName",
      key: "genderName",
      // sorter: true,
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      key: "mobileNo",
      // sorter: true,
    },
    {
      title: "Platform",
      dataIndex: "sourceFrom",
      key: "sourceFrom",
      // sorter: true,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      // sorter: true,
    },
    {
      title: "X Axis",
      dataIndex: "xAxis",
      key: "xAxis",
      // sorter: true,
    },
    {
      title: "Y Axis",
      dataIndex: "yAxis",
      key: "yAxis",
      // sorter: true,
    },
    {
      title: "Registration date",
      dataIndex: "registrationDate",
      key: "registrationDate",
      // sorter: true,
      render: (text) => {
        return ![undefined, null, ""].includes(text)
          ? moment(text).format("DD-MM-YYYY")
          : "";
      },
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {this.state.userRights.length > 0 &&
    //       this.state.userRights[0].isView == true ? (
    //         <Tooltip title="View">
    //           <Button
    //             type="link"
    //             onClick={() =>
    //               this.setState({
    //                 showAddPopup: true,
    //                 cityID: record.cityID,
    //                 cityName: record.cityName,
    //                 stateName: record.stateName,
    //                 stateID: record.stateID,
    //                 isEnabled: true,
    //                 isVisible: true,
    //               })
    //             }
    //           >
    //             <FontAwesomeIcon icon={faInfo} />
    //           </Button>
    //         </Tooltip>
    //       ) : null}
    //       {this.state.userRights.length > 0 &&
    //       this.state.userRights[0].isUpdate == true ? (
    //         <Tooltip title="Edit">
    //           <Button
    //             type="link"
    //             onClick={() =>
    //               this.setState({
    //                 showAddPopup: true,
    //                 cityID: record.cityID,
    //                 cityName: record.cityName,
    //                 stateName: record.stateName,
    //                 stateID: record.stateID,
    //                 // }, () => cityname = record.cityName)}
    //               })
    //             }
    //           >
    //             <FontAwesomeIcon icon={faPencilAlt} />
    //           </Button>
    //         </Tooltip>
    //       ) : null}
    //       {this.state.userRights.length > 0 &&
    //       this.state.userRights[0].isDelete == true ? (
    //         <Popconfirm
    //           title="Are you sure ?"
    //           onConfirm={() => this.removeItems(record.cityID)}
    //           onCancel={() => null}
    //           okText="Yes"
    //           cancelText="No"
    //         >
    //           <Tooltip title="Delete">
    //             <Button type="link">
    //               <FontAwesomeIcon icon={faTrash} />
    //             </Button>
    //           </Tooltip>
    //         </Popconfirm>
    //       ) : null}
    //     </Space>
    //   ),
    // },
  ];

  componentDidMount() {
    const { pagination } = this.state;
    this.setState({ isResponseErrorShow: false });
    this.fetchItems({
      FromDate: moment(this.state.FromDate).format("YYYY-MM-DD"),
      ToDate: moment(this.state.ToDate).format("YYYY-MM-DD"),
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
  }

  handleSearch = (event) => {
    this.fetchItems({
      FromDate: moment(this.state.FromDate).format("YYYY-MM-DD"),
      ToDate: moment(this.state.ToDate).format("YYYY-MM-DD"),
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table pagination when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
    this.fetchItems({
      FromDate: moment(this.state.FromDate).format("YYYY-MM-DD"),
      ToDate: moment(this.state.ToDate).format("YYYY-MM-DD"),
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.OutOfCoverageservice.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            FromDate: moment(this.state.FromDate).format("YYYY-MM-DD"),
            ToDate: moment(this.state.ToDate).format("YYYY-MM-DD"),
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          OutOfCoverageItems: items.data,
          pagination: {
            ...params.pagination,
            total: items.data.length > 0 ? items.data[0].totalRecords : 0,
          },
          // isRenderComponent: true,
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: "Out Of Coverage",
            description: items.message
              ? items.message
              : "Unable to get list of Out Of Coverage data",
          });
          this.setState({
            isResponseErrorShow: true,
          });
        }
      }
      this.setState({ loading: false });
    });
  };
  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  render() {
    const { pagination, loading } = this.state;
    const OutOfCoverageItems = this.state.OutOfCoverageItems;
    const listItems = OutOfCoverageItems.map((item, index) => ({
      key: index,
      createdOn: item.createdOn,
      customerName: item.customerName,
      genderName: item.genderName,
      mobileNo: item.mobileNo,
      sourceFrom: item.sourceFrom,
      address: item.address,
      xAxis: item.xAxis,
      yAxis: item.yAxis,
      registrationDate: item.registrationDate,
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title="Out Of Coverage"
          extra={
            <Button
              key="5"
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>
          }
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() => {
              this.fetchItems({
                FromDate: moment(this.state.FromDate).format("YYYY-MM-DD"),
                ToDate: moment(this.state.ToDate).format("YYYY-MM-DD"),
                pageIndex: pagination.current,
                pageSize: pagination.pageSize,
                pagination,
              });
            }}
            initialValues={{
              FromDate: this.state.FromDate,
              ToDate: this.state.ToDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"FromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "FromDate")}
                    value={this.state.FromDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(moment(), "day") ? true : false
                    // }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"ToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) => this.handleDateChange(date, "ToDate")}
                    value={this.state.orderToDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(moment(), "day") ? true : false
                    // }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>
        <Table
          size="small"
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
      </div>
    );
  }
}
