import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";
import moment from "moment";

export default class dailysheetservice {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getDailyExpressOrderData(params) {
    const inventoryApi = new API({ url: this.config.ITEM_FACTORY_URL });
    inventoryApi.createEntity({
      name: this.endpoint.GET_DAILY_EXPRESSORDER_DATA,
    });
    let inventory = [];

    await inventoryApi.endpoints[this.endpoint.GET_DAILY_EXPRESSORDER_DATA]
      .getAllPost(params)
      .then((response) => {
        inventory = response.data;
      })
      .catch((err) => {
        inventory = this.common.handleError(err);
      });
    if (inventory.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        inventory = this.getDailyExpressOrderData(params);
      });
    }
    return Promise.resolve(inventory);
  }

  async getDailyRewashOrderData(params) {
    const inventoryApi = new API({ url: this.config.ITEM_FACTORY_URL });
    inventoryApi.createEntity({
      name: this.endpoint.GET_DAILY_REWASHORDER_DATA,
    });
    let inventory = [];

    await inventoryApi.endpoints[this.endpoint.GET_DAILY_REWASHORDER_DATA]
      .getAllPost(params)
      .then((response) => {
        inventory = response.data;
      })
      .catch((err) => {
        inventory = this.common.handleError(err);
      });
    if (inventory.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        inventory = this.getDailyRewashOrderData(params);
      });
    }
    return Promise.resolve(inventory);
  }

  
  async getDailyFiveStarOrderData(params) {
    const inventoryApi = new API({ url: this.config.ITEM_FACTORY_URL });
    inventoryApi.createEntity({
      name: this.endpoint.GET_DAILY_FIVESTARORDER_DATA,
    });
    let inventory = [];

    await inventoryApi.endpoints[this.endpoint.GET_DAILY_FIVESTARORDER_DATA]
      .getAllPost(params)
      .then((response) => {
        inventory = response.data;
      })
      .catch((err) => {
        inventory = this.common.handleError(err);
      });
    if (inventory.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        inventory = this.getDailyFiveStarOrderData(params);
      });
    }
    return Promise.resolve(inventory);
  }

  async AddUrgentOrder(postData) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.ADD_URGENT_ORDER });
    let itemArray = [];
    await myApi.endpoints.AddUrgentOrder.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.AddUrgentOrder(postData);
      });
    }
    return Promise.resolve(itemArray);
  }
}
