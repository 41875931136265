import React from "react";
import styles from "../Common/Styles/master.module.css";
import Button from "../Common/Button/button";
import Filters from "../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  notification,
  Tooltip,
  Modal,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  //   faChevronUp,
  faPencilAlt,
  faInfo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
// import ROUTES from "../../../routes";
import NoticeBoardService from "../../services/noticeboardservice";
import { IsAuthenticated } from "../../services/auth-header";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";

const { Search } = Input;
const { Option } = Select;

/**
 * A factory master screen which holds grid view for master screens and form with steps
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 16 June 2020
 */
class Master extends React.Component {
  noticeBoardService = new NoticeBoardService();
  UserRightsConfiguration = new UserRightsConfiguration();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    modalLoading: false,
    noticeID: 0,
    showfilterBox: false,
    factories: [],
    tableLoading: false,
    search: null,
    sorter: {
      field: null,
      order: null,
    },
    showAddPopup: false,
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
    popupMode: "add",
    currentExpense: {},
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.NOTICE_BOARD
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };

  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: "User Name",
      dataIndex: "createdByName",
      key: "createdByName",
      sorter: true,
    },
    {
      title: "Notice",
      dataIndex: "notice",
      key: "notice",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (notice) => (
        <Tooltip
          overlayStyle={{ whiteSpace: "pre-line" }}
          placement="topLeft"
          title={notice}
        >
          {notice}
        </Tooltip>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState(
                    {
                      popupMode: "view",
                      noticeID: record.noticeID,
                      currentExpense: {
                        notice: record.notice,
                      },
                    },
                    () => this.setState({ showAddPopup: true })
                  )
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                onClick={() =>
                  this.setState(
                    {
                      popupMode: "edit",
                      noticeID: record.noticeID,
                      currentExpense: {
                        notice: record.notice,
                      },
                    },
                    () => this.setState({ showAddPopup: true })
                  )
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.handleDelete(record.noticeID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  componentDidMount() {
    this.getNoticeList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
  }
  handleDelete = (id) => {
    this.setState({ tableLoading: true });
    this.noticeBoardService.deleteNotice(id).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: "Notice Board",
          description: "Notice deleted successfully",
        });
        this.getNoticeList(
          this.state.pagination.current,
          this.state.pagination.pageSize,
          this.state.sorter.field,
          this.state.sorter.order,
          this.state.search
        );
      } else {
        notification["error"]({
          message: "Notice Board",
          description: "Unable to delete notice",
        });
      }
      this.setState({ tableLoading: false });
    });
  };
  addUpdateNotice = (values) => {
    let params = {
      noticeID: this.state.noticeID,
      notice: values.notice,
    };
    this.setState({ modalLoading: true });
    this.noticeBoardService.addUpdateNotice(params).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: "Notice Board",
          description: "Form Submitted successfully",
        });
        this.getNoticeList(
          this.state.pagination.current,
          this.state.pagination.pageSize,
          this.state.sorter.field,
          this.state.sorter.order,
          this.state.search
        );
        this.setState({ showAddPopup: false });
      } else {
        notification["error"]({
          message: "Notice Board",
          description: "Unable to submit data",
        });
      }
      this.setState({ modalLoading: false });
    });
  };

  getNoticeList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    if (!this.state.tableLoading) {
      this.setState({ tableLoading: true });
      this.noticeBoardService
        .getAllNoticeList(index, size, sortColumn, sortOrder, search)
        .then((response) => {
          if (response.statusCode === 200) {
            let factoryData = [];
            response.data.map((singleData) => {
              singleData.key = singleData.noticeID;
              factoryData.push(singleData);
              return null;
            });
            this.setState({ factories: factoryData });
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: factoryData.length ? factoryData[0].totalRecords : 0,
              },
            });
          } else {
            notification["error"]({
              message: "Notice Board",
              description: response.message
                ? response.message
                : "Unable to get list of notices",
            });
          }
          this.setState({ tableLoading: false });
        });
    }
  };
  render() {
    const disableAll = this.state.popupMode === "view";
    return (
      <div>
        <PageHeader
          title="Notice Board"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              key="1"
              onChange={(event) => {
                this.setState({
                  search: event.target.value.toString().toLowerCase(),
                });
                this.getNoticeList(
                  this.state.pagination.current,
                  this.state.pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  event.target.value.toString().toLowerCase()
                );
              }}
            />,
            /**
             * Add Factory Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.setState({
                    noticeID: 0,
                    showAddPopup: true,
                    popupMode: "add",
                    currentExpense: {},
                  })
                }
                key="2"
              >
                Add Notice
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.factories}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                      ? "desc"
                      : sorter.order,
                },
              },
              () => {
                this.getNoticeList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />

        <Modal
          width={600}
          title={[
            this.state.popupMode === "add"
              ? "Add"
              : this.state.popupMode === "view"
              ? "View"
              : "Edit",
            "Notice",
          ].join(" ")}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={(values) => {
                this.addUpdateNotice(values);
              }}
              initialValues={this.state.currentExpense}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item
                    label="Notice"
                    name="notice"
                    rules={[{ required: true, message: "Please enter Notice" }]}
                  >
                    <Input.TextArea
                      disabled={disableAll}
                      placeholder="Notice"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.popupMode === "view" ? (
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.popupMode === "add" ? "Add" : "Update"}
                        </Button>
                      </React.Fragment>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Master);
