import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import jwt from "jsonwebtoken";
import Common from "./common";

class ExpenseEntryService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }
  handleError = () => {
    return null;
  };
  async getExpenseTypes() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_EXPENSE_TYPE });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.GET_EXPENSE_TYPE]
      .getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getExpenseTypes();
      });
    }
    return Promise.resolve(itemArray);
  }

  async deleteNotice(noticeID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.DELETE_NOTICE });
    let itemArray = [];
    let ID =
      "?NoticeID=" +
      parseInt(noticeID) +
      "&UserID=" +
      parseInt(
        jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
          .unique_name
      );

    await myApi.endpoints[this.endpoint.DELETE_NOTICE]
      .getOne(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.deleteNotice(noticeID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async addUpdateNotice(postData) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.ADD_UPDATE_NOTICE });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.ADD_UPDATE_NOTICE]
      .create({
        ...postData,
        userID: parseInt(
          jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
            .unique_name
        ),
      })
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.addUpdateNotice(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getAllNoticeList(index, size, sortColumn, sortOrder, search) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.NOTICE_BOARD_LIST });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.NOTICE_BOARD_LIST]
      .getAllPost({
        noticeID: null,
        // userID: parseInt(
        //   jwt.decode(JSON.parse(localStorage.getItem("currentuser")).token)
        //     .unique_name
        // ),
        notice: null,
        pageIndex: index,
        pageSize: size,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        commonSearch: search,
      })
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getAllNoticeList(
          index,
          size,
          sortColumn,
          sortOrder,
          search
        );
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveItems(param) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({ name: this.endpoint.GETAPPFLYERDATA });
    let itemArray = [];
    await myApi.endpoints.GetAppflyerData.getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err)
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }
}
export default ExpenseEntryService;
