import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

class TransferOutService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async retrieveStoreList() {
    const myApi = new API({ url: this.config.DROPDOWN_URL });
    myApi.createEntity({ name: this.endpoint.GET_STORE });
    let itemArray = [];
    await myApi.endpoints.GetStoreList.getAll()
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveStoreList();
      });
    }
    return Promise.resolve(itemArray);
  }

  async getStickersToTransferOut(
    index = 0,
    size = 0,
    sortColumn = null,
    sortOrder = null,
    search = null,
    storeID = null,
    factoryID = null
  ) {
    const getStickersAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    getStickersAPI.createEntity({
      name: this.endpoint.GET_STICKERS_TO_TRANSFER_OUT,
    });
    let Stickers = [];
    let payload = {
      storeID: storeID,
      factoryID: factoryID,
      pageIndex: index,
      pageSize: size,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      commonSearch: search,
    };
    await getStickersAPI.endpoints[this.endpoint.GET_STICKERS_TO_TRANSFER_OUT]
      .getAllPost(payload)
      .then((response) => {
        Stickers = response.data;
      })
      .catch((err) => {
        Stickers = this.common.handleError(err);
      });
    if (Stickers.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        Stickers = this.getStickersToTransferOut(
          index,
          size,
          sortColumn,
          sortOrder,
          search,
          storeID,
          factoryID
        );
      });
    }
    return Promise.resolve(Stickers);
  }

  async generateGatePass(params) {
    const gatePassApi = new API({ url: this.config.ITEM_FACTORY_URL });
    gatePassApi.createEntity({
      name: this.endpoint.FACTORY_GENERATE_GATE_PASS,
    });
    let Stickers = [];
    await gatePassApi.endpoints[this.endpoint.FACTORY_GENERATE_GATE_PASS]
      .getAllPost(params)
      .then((response) => (Stickers = response.data))
      .catch((err) => {
        Stickers = this.common.handleError(err);
      });
    if (Stickers.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        Stickers = this.generateGatePass(params);
      });
    }
    return Promise.resolve(Stickers);
  }

  async getPDFGatePass(gatePassNo) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.GET_FACTORY_PRINT_GATEPASS });
    let itemArray = [];
    // let gatePassIDs = "FAJK0000014";
    var param = "?GatePassNumber=" + gatePassNo;
    await myApi.endpoints[this.endpoint.GET_FACTORY_PRINT_GATEPASS]
      .getOne(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getPDFGatePass(gatePassNo);
      });
    }
    return Promise.resolve(itemArray);
  }

  handleError(error) {
    console.log(error.message);
  }
}
export default TransferOutService;
