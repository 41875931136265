import React from "react";
import Banner from "../../components/Banner/banner.component";

export default class BannerScreen extends React.Component {
  render() {
    return (
      <div>
        <Banner />
      </div>
    );
  }
}
