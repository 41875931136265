import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  PageHeader,
  Typography,
  Space,
  Upload,
  notification,
  Spin,
} from "antd";
import Button from "../../../Common/Button/button";
import styles from "./createuser.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import UserService from "../../../../services/userservice";
import UserKYC from "./userkyc.component";
import { UploadOutlined } from "@ant-design/icons";
//import axios from 'axios';
//const BASE_URL = 'E:/LaundroKart_Final/Development SourceCode/src/documents/users/ids';
//const BASE_URL='//DTC173/ServiceRateAndTimeCardDocuments';

const { Option } = Select;
const { Title } = Typography;
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

/**
 * A form as a part of create KYC - Add User KYC
 *
 * @props backFunction: a function to modify parent state to go back to tabular view
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 3 June 2020
 *
 * @module User
 */
export default class UserForm extends React.Component {
  /**
   * A function to handle form submit
   * please check below link for more details
   * https://ant.design/components/form/#API
   *
   */
  constructor(props) {
    super(props);
    this.userservice = new UserService();
    this.state = {
      fullLoading: false,
      kycid: 0,
      isAdd: false,
      isView: false,
      disabled: false,
      kycDocTypeId: 0,
      kycDocTypeName: "",
      fileName: "",
      kycDoctype: [],
      selectedFiles: "",
      kycDocTypeList: [],
      showComponent: false,
      currentComponent: true,
      kycFormValues: {},
      // images: [],
      // imageUrls: [],
      // message: ''
      fileListProp: [],
    };
    this.handleSaveUpdateKYC = this.handleSaveUpdateKYC.bind(this);
  }
  componentDidMount() {
    this.getKYCDocType();
    this.setState({
      kycid: this.props.kycid,
      kycDocTypeName: this.props.kycDocTypeName,
      kycDocTypeId: this.props.kycDocTypeID,
      fileName: this.props.uploadedDocName,
      isView: this.props.isView,
      disabled: this.props.disabled,
    });
    if (this.props.kycDocTypeList !== null) {
      this.setState({ kycDocTypeList: this.props.kycDocTypeList });
    }
    this.setState({ fileListProp: this.props.fileList });
  }
  static defaultProps = {
    fileListProp: [],
  };
  getKYCDocType = () => {
    this.userservice.retrieveKYCDocumentType().then((kycDoctype) => {
      this.setState({ kycDoctype: kycDoctype.data });
    });
  };

  handleChange = (event) => {
    this.setState({ kycDocTypeId: parseInt(event) });
    this.setState({
      kycDocTypeName: this.state.kycDoctype.filter((x) => x.value === event)[0]
        .text,
    });
  };
  handleFileChange = (event) => {
    // let images = []
    // for (var i = 0; i < event.target.files.length; i++) {
    // images[i] = event.target.files.item(i);
    // }
    // images = images.filter(image => image.name.match(/\.(jpg|jpeg|png|gif)$/))
    // let message = `${images.length} valid image(s) selected`
    // this.setState({ images, message })

    if (event.target.files[0].size >= 10 * 1024 * 1024) {
      openNotificationWithIcon(
        "error",
        "Users",
        "Max. file size is allow upto 10 MB"
      );
      document.getElementById("files").value = null;
    } else if (
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpg" ||
      event.target.files[0].type === "image/jpeg"
    ) {
      this.state.fileName = event.target.files[0].name;
      this.state.selectedFiles = event.target.files[0];
    } else {
      openNotificationWithIcon(
        "error",
        "Users",
        "Invalid file extension, select jpg or png."
      );
      document.getElementById("files").value = null;
    }
  };

  handleSaveUpdateKYC = () => {
    var fileCaption_GUID = (Math.random().toString(16) + "000000000").substr(
      1,
      12
    );
    var fileCaption = (Math.random().toString(16) + "000000000").substr(1, 6);
    if (this.state.fileName !== "") {
      const formData = new FormData();
      if (this.state.fileListProp.length > 0) {
        let file = this.state.fileListProp[0];
        if (file.url !== undefined) {
          formData.append("UnchangedImage", file.name);
        } else {
          formData.append("UnchangedImage", "");
        }
      } else {
        formData.append("UnchangedImage", "");
      }
      formData.append("kycImage", this.state.selectedFiles);
      formData.append("kycid", this.state.kycid);
      formData.append("userID", this.props.userID);
      formData.append(
        "kycDocTypeID",
        parseInt(this.state.kycFormValues.kycDocTypeID)
      );
      formData.append("isKYCUploaded", true);
      formData.append(
        "uploadedDocName",
        this.props.userID +
          "_" +
          this.state.kycFormValues.kycDocTypeID +
          "_" +
          fileCaption +
          "_" +
          this.state.fileName.split("/")[
            this.state.fileName.split("/").length - 1
          ]
      );
      formData.append("fileCaption", fileCaption_GUID);
      // const uploaders = this.state.images.map(image => {
      // const data = new FormData();
      // var GUID = (Math.random().toString(16)+"000000000").substr(1,12);
      // data.append("image", image, image.name);
      //   // Make an AJAX upload request using Axios
      //   return axios.post(BASE_URL, data)
      //   .then(response => {
      //     // this.setState({
      //     // imageUrls: [ response.data.imageUrl, ...this.state.imageUrls ]
      //     // });
      //   })
      // });
      // // Once all the files are uploaded
      // axios.all(uploaders).then(() => {
      // console.log('done');
      // }).catch(err => alert(err.message));
      this.setState({ fullLoading: true });
      this.userservice.saveUpdateUserKYCDetails(formData).then((response) => {
        if (response.result === "Success") {
          openNotificationWithIcon("success", "Users", response.message);
          this.setState({ showComponent: true });
        } else {
          openNotificationWithIcon("error", "Users", response.message);
        }
        this.setState({ fullLoading: false });
      });
    } else {
      openNotificationWithIcon(
        "error",
        "Users",
        "Please select KYC doc Type & document file"
      );
    }
  };

  kycForm = React.createRef();
  kycValidated = false;

  handleSubmit = () => {
    const kycValidator = this.kycForm.validateFields();

    Promise.allSettled([kycValidator]).then((responses) => {
      if (responses[0].status === "rejected") {
      } else if (responses[0].status === "fulfilled") {
        this.setState(
          {
            kycFormValues: responses[0].value,
          },
          () => {
            this.handleSaveUpdateKYC();
          }
        );
      }
    });
  };

  render() {
    const { disabled } = this.state;
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 0,
        },
      },
    };
    return (
      <Spin spinning={this.state.fullLoading}>
        {this.state.showComponent ? (
          <UserKYC backFunction={this.bringback} userID={this.props.userID} />
        ) : (
          <Form
            layout="vertical"
            initialValues={this.props.values}
            ref={(kycForm) => (this.kycForm = kycForm)}
            className={styles.AddKYCForm}
          >
            {/**
             * Please go through below documentation befor modifying
             * https://ant.design/components/page-header/
             * https://ant.design/components/page-header/#API
             */}
            <PageHeader
              title={
                <Title level={4} className={styles.UserKYCTitle}>
                  {this.state.kycid === 0
                    ? "Add KYC"
                    : this.state.isView
                    ? "View KYC"
                    : "Edit KYC"}
                </Title>
              }
              backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
              onBack={this.props.backFunction}
              className={styles.AddKYCFormHeader}
            />
            <Row gutter={30}>
              <Col xs={8}>
                <Form.Item
                  label="KYC Doc Type"
                  name="kycDocTypeID"
                  rules={[
                    { required: true, message: "Please select KYC Doc Type" },
                  ]}
                >
                  {/* <Select placeholder="Select KYC Doc Type" onChange={this.handleChange} value={this.state.kycDocTypeName} disabled={disabled}> */}
                  <Select placeholder="Select KYC Doc Type" disabled={disabled}>
                    {/* {this.state.kycDoctype.map((kycDoctype) => <Option key={kycDoctype.value} value={kycDoctype.value}>{kycDoctype.text}</Option>)} */}
                    {this.state.kycDoctype.map((kycDoctype) => {
                      return kycDoctype.value !== "-1" ? (
                        <Option value={kycDoctype.value} key={kycDoctype.value}>
                          {kycDoctype.text}
                        </Option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col xs={8}>
                <Form.Item
                  {...tailFormItemLayout}
                  label="Upload File"
                  rules={[{ required: true, message: "Please select File" }]}
                  disabled={disabled}
                  name="uploadedDocName"
                  getValueFromEvent={(e) => {
                    if (Array.isArray(e)) {
                      return e;
                    }
                    return e && e.fileList;
                  }}
                >
                  <Upload
                    fileList={this.state.fileListProp}
                    accept=".png"
                    customRequest={({ file, onSuccess, onError }) => {
                      onSuccess({ status: "done" }, file);
                    }}
                    onChange={(info) => {
                      let fileList = [...info.fileList];
                      if (fileList.length > 0) {
                        fileList = fileList.slice(-1);
                        let file = fileList[0];
                        if (file.size >= 10 * 1024 * 1024) {
                          openNotificationWithIcon(
                            "error",
                            "Users",
                            "Max. file size is allow upto 10 MB"
                          );
                          this.setState({ fileListProp: [] });
                        } else {
                          let setFileList = [];
                          file.status = "done";
                          setFileList.push(file);
                          this.setState({ fileListProp: setFileList });
                          this.setState({
                            selectedFiles: file.originFileObj,
                            fileName: file.name,
                          });
                        }
                      } else {
                        this.setState({ fileListProp: [] });
                      }
                    }}
                  >
                    <Button variant="transparentGreyOutline">
                      <UploadOutlined /> Upload
                    </Button>
                  </Upload>
                  {/* <input
                type="file"
                id="files"
                ref={(file) => this.fileUpload}
                onChange={this.handleFileChange}
              /> */}
                </Form.Item>
              </Col>
            </Row>
            <Row className={styles.ActionsRow}>
              <Col xs={24} className={styles.KYCButtonContainer}>
                <Space size="middle">
                  <Button
                    variant="transparentGreyOutline"
                    className={styles.CancelButton}
                    shape="round"
                    size="large"
                    onClick={this.props.backFunction}
                  >
                    Cancel
                  </Button>
                  {!this.state.isView ? (
                    <Button
                      htmlType="submit"
                      variant="purple"
                      shape="round"
                      size="large"
                      //onClick={(event) => this.handleSaveUpdateKYC(event,this.state.kycDocTypeList)}
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                  ) : null}
                  {this.state.showComponent ? (
                    <UserKYC data={this.state.kycDocTypeList} />
                  ) : null}
                </Space>
              </Col>
            </Row>
          </Form>
        )}
      </Spin>
    );
  }
}
