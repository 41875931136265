import React from 'react';
import RewardsComponent from "../../../components/Master/Rewards/rewards.components";
export default class RewardsScreen extends React.Component {
    render() {
        return (
            <div>
                <RewardsComponent></RewardsComponent>
            </div>
        )
    }
}
