import React from "react";
import DiscountMaster from "../../components/DiscountMaster/discountmaster.component";

export default class Master extends React.Component {

    render() {
        const listItems = null;
        return (
            <div>
                <DiscountMaster></DiscountMaster>
            </div>
        );
    }
}