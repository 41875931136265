import React from "react";
import styles from "../Common/Styles/master.module.css";
import Button from "../Common/Button/button";
import Filters from "../Common/Filters/filters";
import TableActions from "../Common/TableActions/tableactions";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Popconfirm,
  Table,
  Modal,
  notification,
  Upload,
  Tooltip,
  Spin,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChevronUp,
  faInfo,
  // faPencilAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import BannerService from "../../services/bannerservice";
// import Moment from "moment";
import { IsAuthenticated } from "../../services/auth-header";
import Configuration from "../../shared/configuration";
import UserRightsConfiguration from "../../shared/userrightsconfiguration";
import Common from "../../services/common";
import { PlusOutlined } from "@ant-design/icons";

// let cityname = "";

const { Search } = Input;
const { Option } = Select;

// const variable for notification
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

/**
 * A Banner master screen which holds grid view for master screens and form inside a modal
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 28 July 2020
 */
export default class Master extends React.Component {
  constructor(props) {
    super(props);
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.BannerService = new BannerService();
    this.config = new Configuration();
    this.commonReq = new Common();
    this.state = {
      modalLoading: false,
      bannerItems: [],
      bannerID: 0,
      description: "",
      bannerImage: "",
      statusDDL: [],
      selectedType: "",
      fileList: [],
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
      },

      isEnabled: false,
      isVisible: false,
      rowselectedvalue: "",
      showfilterBox: false,
      showAddPopup: false,
      loading: true,
      userRights: [],
    };
    this.handlePopupAdd = this.handlePopupAdd.bind(this);
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.BANNER
    );
    const storeOrFactory = localStorage
      .getItem("Store/FactoryType")
      .toLowerCase();
    if (!res[0].isView) {
      if (storeOrFactory === "store") {
        window.location.href = "/store/dashboard";
      } else {
        window.location.href = "/factory/dashboard";
      }
    }
    this.setState({ userRights: res });
  }

  columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
    },
    {
      title: "Banner Type",
      dataIndex: "bannerType",
      key: "bannerType",
      sorter: true,
    },
    {
      title: "Banner Image",
      dataIndex: "bannerImage",
      key: "bannerImage",
      sorter: true,
      render: (imagetext) => (
        <Tooltip
          placement="topLeft"
          title={imagetext.split(this.config.BANNER_MASTER_IMAGE_URL)}
        >
          {imagetext.split(this.config.BANNER_MASTER_IMAGE_URL)}
        </Tooltip>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title="View">
              <Button
                type="link"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    bannerID: record.bannerID,
                    description: record.description,
                    selectedType: [null, undefined, ""].includes(
                      record.bannerType
                    )
                      ? undefined
                      : record.bannerType,
                    bannerImage: record.bannerImage,
                    isEnabled: true,
                    isVisible: true,
                  })
                }
              >
                <FontAwesomeIcon icon={faInfo} />
              </Button>
            </Tooltip>
          ) : null}
          {/* {this.state.userRights.length > 0 &&
          this.state.userRights[0].isUpdate == true ? (
            <Button
              type="link"
              onClick={() =>
                this.setState({
                  showAddPopup: true,
                  bannerID: record.bannerID,
                  description: record.description,
                  selectedType: record.bannerType,
                })
              }
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          ) : null} */}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            <Popconfirm
              title="Are you sure ?"
              onConfirm={() => this.removeItems(record.bannerID)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Delete">
                <Button type="link">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Tooltip>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = (info) => {
    this.setState({ fileList: info.fileList });
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === "image/png";
    if (!isJpgOrPng) {
      notification["error"]({
        message: "Banner",
        description: "You can only upload JPG/PNG file!",
      });
      this.setState({ fileList: [] });
    }
    const isLt3M = info.file.size / 1024 / 1024 < 5;
    if (!isLt3M) {
      notification["error"]({
        message: "Banner",
        description: "Image must be smaller than 5 MB!",
      });
      this.setState({ fileList: [] });
    }
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetchItems({
      bannerID: this.state.bannerID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
    });
    this.GetBannerTypeStatus();
  }

  handleSearch = (event) => {
    this.fetchItems({
      bannerID: this.state.bannerID,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      // ...filters,
    });
  };

  //function to handle table pagination when click on specific page no.
  handleTableChange = (pagination, filters, sorter) => {
   
    this.fetchItems({
      bannerID: this.state.bannerID,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? ""
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      // ...filters,
    });
  };

  //function for multiple selection of rows by using checkbox
  selectionType = "checkbox";
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({ rowselectedvalue: selectedRowKeys });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  //function for deleting multiple seletion of rows
  deleteSelected = () => {
    var rowselectedvalue = this.state.rowselectedvalue.toString();
    this.removeItems(rowselectedvalue);
  };

  /**
   * Functions to handle popup actions
   *
   * @todo handle ok button with Form.onFinish instead of button onClick
   */
  handlePopupOk = () => {
    this.setState({ showAddPopup: false });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
    this.resetFields();
  };

  //function for get data from server call
  fetchItems = (params = {}) => {
    this.setState({ loading: true });
    this.BannerService.retrieveItems(params).then((items) => {
      if (items.statusCode === 200) {
        if (
          items.length !== 0 &&
          items.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.fetchItems({
            bannerID: 0,
            pageIndex: 1,
            pageSize: params.pageSize,
            pagination: params.pagination,
          });
        }
        this.setState({
          bannerItems: items.data,
          pagination: {
            ...params.pagination,
            total:
              items.length !== 0 && items.data.length !== 0
                ? items.data[0].totalRecords
                : 0,
          },
        });
      } else {
        notification["error"]({
          message: "Banner",
          description: items.message
            ? items.message
            : "Unable to get list of banner master data",
        });
      }
      this.setState({ loading: false });
    });
  };

  //function for deleting data from server call
  removeItems = (bannerID) => {
    if (bannerID === "" || bannerID === null || bannerID === undefined) {
      openNotificationWithIcon(
        "error",
        "Banner",
        "Please Select Atleast One Row"
      );
    } else {
      this.setState({ loading: true });
      this.BannerService.removeItems(bannerID).then((items) => {
        if (items.result === "Success") {
          openNotificationWithIcon("success", "Banner", items.message);
          const { pagination } = this.state;
          this.fetchItems({
            bannerID: parseInt(this.state.bannerID),
            pageIndex: pagination.current,
            pageSize: pagination.pageSize,
            pagination,
          });
        } else {
          openNotificationWithIcon("error", "Banner", items.message);
        }
        this.setState({ loading: false });
      });
    }
  };

  //function for adding data to back-end by server call
  handlePopupAdd = (event) => {
    var fileData = this.state.fileList;
    const formData = new FormData();

    formData.append(
      "bannerImage",
      this.state.fileList.length > 0
        ? this.state.fileList[0].originFileObj
        : null
    );
    formData.append("bannerID", this.state.bannerID);
    formData.append("description", this.state.description);
    formData.append("type", parseInt(this.state.selectedType));

    this.setState({ modalLoading: true });
    this.BannerService.insertUpdateItems(formData).then((items) => {
      if (items.result === "Success") {
        openNotificationWithIcon("success", "Banner", items.message);
        this.setState({ items: items, showAddPopup: false });
        const { pagination } = this.state;
        this.fetchItems({
          bannerID: 0,
          pageIndex: pagination.current,
          pageSize: pagination.pageSize,
          pagination,
          // ...filters,
        });
        this.resetFields();
      } else {
        openNotificationWithIcon("error", "Banner", items.message);
      }
      this.setState({ modalLoading: false });
    });
  };

  //function for changing/updating the Banner description
  handleDescription = (e) => {
    this.setState({ description: e.target.value });
  };

  //function for clearing out the states
  resetFields = () => {
    this.setState({
      bannerID: 0,
      description: "",
      selectedType: "",
      fileList: [],
      isEnabled: false,
      isVisible: false,
    });
  };

  handleTypeChange = (event) => {
    this.setState({ selectedType: event });
  };

  GetBannerTypeStatus = () => {
    this.commonReq.GetSystemEnums("BannerType").then((items) => {
      if (items.statusCode === 200) {
        this.setState({ statusDDL: items.data });
      } else {
        notification["error"]({
          message: "Banner",
          description: items.message
            ? items.message
            : "Unable to get banner type data",
        });
      }
    });
  };

  render() {
    const { description, selectedType, pagination, loading } = this.state;
    const uploadButton =
      this.state.fileList.length === 0 ? (
        <div>
          <PlusOutlined />
          <div className="ant-upload-text">Upload</div>
        </div>
      ) : null;
    const bannerItems = this.state.bannerItems;
    const listItems = bannerItems.map((item, index) => ({
      key: item.bannerID,
      bannerID: item.bannerID,
      description: item.description,
      type: item.type,
      bannerType: item.bannerType,
      bannerImage: item.bannerImage,
      totalRecords: item.totalRecords,
    }));
    return (
      <div>
        <PageHeader
          title="Banner"
          extra={[
            <Search
              placeholder="Search"
              className={styles.SearchInput}
              onChange={this.handleSearch}
            />,
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.setState({ showAddPopup: true, selectedType: undefined })
                }
              >
                Add Banner
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          // rowSelection={{
          //   type: this.selectionType,
          //   ...this.rowSelection,
          // }}
          columns={this.columns}
          dataSource={listItems}
          pagination={pagination}
          loading={loading}
          onChange={this.handleTableChange}
        />
        <TableActions isPagination={true}>
          {/* {this.state.userRights.length > 0 &&
          this.state.userRights[0].isDelete == true ? (
            bannerItems.length > 0 ? (
              <Popconfirm
                title="Are you sure ?"
                onConfirm={this.deleteSelected}
                onCancel={() => null}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="transparentGreyOutline" loading={loading}>
                  Delete Selected
                </Button>
              </Popconfirm>
            ) : null
          ) : null} */}
        </TableActions>

        <Modal
          title={
            this.state.bannerID === 0
              ? "Add Banner"
              : this.state.isEnabled
              ? "View Banner"
              : "Update Banner"
          }
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.handlePopupAdd}
              initialValues={{ description, selectedType }}
            >
              <Row gutter={24}>
                <Col xs={24}>
                  <Form.Item label="bannerID" name="bannerID" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Description "
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Description!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Description"
                      disabled={this.state.isEnabled}
                      onChange={this.handleDescription}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label="Banner Type"
                    name="selectedType"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Banner Type !",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Banner Type"
                      onChange={this.handleTypeChange}
                      disabled={this.state.isEnabled}
                    >
                      {this.state.statusDDL.length !== 0
                        ? this.state.statusDDL.map((res) => {
                            return res.enumDetailID !== "-1" ? (
                              <Option
                                value={res.enumDetailID}
                                key={res.enumDetailID}
                              >
                                {res.enumDetailName}
                              </Option>
                            ) : null;
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  {this.state.bannerID === 0 ? (
                    <Form.Item
                      label="Select File To Upload"
                      name="file"
                      valuePropName={"file"}
                      rules={[
                        {
                          required: true,
                          message: "Please Select File To Upload",
                        },
                      ]}
                    >
                      <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={this.state.fileList}
                        // onPreview={this.handlePreview}
                        onChange={this.handleChange}
                      >
                        {uploadButton}

                        {/* {this.state.fileList.length >= 8 ? null : uploadButton} */}
                      </Upload>
                    </Form.Item>
                  ) : (
                    <Form.Item label="Image Url">
                      <img
                        src={this.state.bannerImage}
                        style={{ maxWidth: "100%" }}
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.isVisible ? (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.bannerID === 0 ? "Add" : "Update"}
                        </Button>
                      </>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
