import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class RoleMasterService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async removeItems(roleID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.DELETE_ROLE_BY_ID });
    let itemArray = [];
    let ID = "?RoleIDs=" + roleID;
    await myApi.endpoints.DeleteRoleByRoleID.deleteById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.removeItems(roleID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async insertUpdateItems(postData) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.ADDUPDATE_ROLE_MASTER });
    let itemArray = [];
    await myApi.endpoints.SaveUpdateRoleMaster.create(postData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.insertUpdateItems(postData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async retrieveItems(param) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_ALL_ROLES });
    let itemArray = [];
    await myApi.endpoints.GetRoleMaster.getAllPost(param)
      .then(({ data }) => {
        // if (!data.ok) {
        //     this.handleResponseError(response);
        // }
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retrieveItems(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getRolesList() {
    const allFactoriesAPI = new API({ url: this.config.DROPDOWN_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.GET_ROLES_DROPDOWN });
    let factories = [];
    await allFactoriesAPI.endpoints[this.endpoint.GET_ROLES_DROPDOWN]
      .getAll()
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getRolesList();
      });
    }
    return Promise.resolve(factories);
  }
  async getRolesRights(id) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.GET_SCREEN_RIGHTS });
    let factories = [];
    const idParams = "?RoleID=" + id;
    await allFactoriesAPI.endpoints[this.endpoint.GET_SCREEN_RIGHTS]
      .getOne(idParams)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.getRolesRights(id);
      });
    }
    return Promise.resolve(factories);
  }

  async UpdateRoles(roles) {
    const allFactoriesAPI = new API({ url: this.config.ITEM_URL });
    allFactoriesAPI.createEntity({ name: this.endpoint.UPDATE_SCREEN_ROLES });
    let factories = [];
    await allFactoriesAPI.endpoints[this.endpoint.UPDATE_SCREEN_ROLES]
      .create(roles)
      .then((response) => {
        factories = response.data;
      })
      .catch((err) => {
        factories = this.common.handleError(err);
      });
    if (factories.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        factories = this.UpdateRoles(roles);
      });
    }
    return Promise.resolve(factories);
  }

  handleError(error) {
    console.log(error.message);
  }
}
