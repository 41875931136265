import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import jwt from "jsonwebtoken";
import Common from "./common";

class StandardRateCardService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }
  handleError = () => {
    return null;
  };

  async deleteStandardRateCard(standardRateID) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.DELETE_STATIC_SERVICE_RATE_BY_ID,
    });
    let itemArray = [];
    let ID = "?IDs=" + standardRateID;

    await myApi.endpoints[this.endpoint.DELETE_STATIC_SERVICE_RATE_BY_ID]
      .deleteById(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.deleteStandardRateCard(standardRateID);
      });
    }
    return Promise.resolve(itemArray);
  }

  async addUpdateStandardRateCard(postFormData) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.SAVE_UPDATE_STATIC_SERVICE_RATE_SAMPLE,
    });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.SAVE_UPDATE_STATIC_SERVICE_RATE_SAMPLE]
      .create(postFormData)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.addUpdateStandardRateCard(postFormData);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getStandardRateCardData(index, size, sortColumn, sortOrder, search) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({ name: this.endpoint.GET_STATIC_SERVICE_RATE_SAMPLE });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.GET_STATIC_SERVICE_RATE_SAMPLE]
      .getAllPost({
        garmentCategory: "",
        pageIndex: index,
        pageSize: size,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        commonSearch: search,
      })
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getStandardRateCardData(
          index,
          size,
          sortColumn,
          sortOrder,
          search
        );
      });
    }
    return Promise.resolve(itemArray);
  }
}
export default StandardRateCardService;
