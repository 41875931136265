import React from "react";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import Filters from "../../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  Modal,
  Table,
  notification,
  InputNumber,
  Tooltip,
  Spin,
  Popconfirm,
} from "antd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faChevronUp,
//   faPencilAlt,
//   faInfo,
// } from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
// import ROUTES from "../../../routes";
import AdvancePayment from "../../../services/lkadvancepaymentservice";
import InvoiceService from "../../../services/invoiceservice";
import ReadyForDeliveryOrderService from "../../../services/storereadyfordeliverysplitorderservice";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import PaytmTransService from "../../../services/PaytmTransService";
import { labels } from "../../../shared/language";
import Common from "../../../services/common";
const { Search } = Input;
const { Option } = Select;
const confirm = Modal.confirm;

/**
 *
 * @author Niraj Gohel
 *
 * @since 1.0.0
 * @created 17 September 2020
 */
class Master extends React.Component {
  advancePaymentService = new AdvancePayment();
  invoiceService = new InvoiceService();
  UserRightsConfiguration = new UserRightsConfiguration();
  paytmTransService = new PaytmTransService();
  common = new Common();
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    modalLoading: false,
    showAddModal: false,
    showfilterBox: false,
    advancePayment: [],
    tableLoading: false,
    search: null,
    showAddPopup: false,
    isTransFail: false,
    sorter: {
      field: null,
      order: null,
    },
    customerName: {
      //  disabled: true,
      data: [],
      selected: null,
    },
    paymentMethods: {
      data: [],
      disabled: true,
      selected: 1,
    },
    isResponseErrorShow: false,
    pagination: {
      showSizeChanger: true,
      // pageSizeOptions: [1, 2, 3, 4],
      current: 1,
      pageSize: 10,
      onChange: (page, pageSize) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: page,
            pageSize: pageSize,
          },
        });
      },
      onShowSizeChange: (current, size) => {
        this.setState({
          // tableLoading: true,
          pagination: {
            ...this.state.pagination,
            current: current,
            pageSize: size,
          },
        });
      },
    },
  };

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.ADVANCE_PAYMENT
    );
    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }
  /**
   * Columns Object for rendering table kindly go through
   * https://ant.design/components/table/#API
   * https://ant.design/components/table/
   * https://ant.design/components/table/#Column
   */
  columns = [
    {
      title: labels.CUSTOMER_NAME,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: labels.MONEY_TYPE,
      dataIndex: "moneyType",
      key: "moneyType",
      sorter: true,
    },
    {
      title: labels.AMOUNT,
      dataIndex: "advanceAmount",
      key: "advanceAmount",
      sorter: true,
      render: (amount) => {
        return <React.Fragment>₹ {amount}</React.Fragment>;
      },
    },

    {
      title: labels.REMARK,
      dataIndex: "remark",
      key: "remark",
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    // {
    //   title: "Invoice",
    //   dataIndex: "invoiceID",
    //   key: "invoiceID",
    //   sorter: true,
    // },
    // {
    //   title: "Order",
    //   dataIndex: "orderID",
    //   key: "orderID",
    //   sorter: true,
    // },
    {
      title: labels.ADDED_BY,
      dataIndex: "createdByName",
      key: "createdByName",
    },
    {
      title: labels.ADDED_ON,
      dataIndex: "createdOn",
      key: "createdOn",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY H:m");
      },
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       {this.state.userRights.length > 0 &&
    //       this.state.userRights[0].isView == true ? (
    //         <Tooltip title="View">
    //           <Button type="link" variant="link" view>
    //             <FontAwesomeIcon icon={faInfo} />
    //           </Button>
    //         </Tooltip>
    //       ) : null}
    //       {this.state.userRights.length > 0 &&
    //       this.state.userRights[0].isUpdate == true ? (
    //         <Tooltip title="Edit">
    //           <Link to={[ROUTES.EDIT_FACTORY, record.factoryID].join("/")}>
    //             <FontAwesomeIcon icon={faPencilAlt} />
    //           </Link>
    //         </Tooltip>
    //       ) : null}
    //     </Space>
    //   ),
    // },
  ];

  componentDidMount() {
    this.setState({ isResponseErrorShow: false });
    this.getAdvancePaymentList(
      this.state.pagination.current,
      this.state.pagination.pageSize,
      this.state.sorter.field,
      this.state.sorter.order,
      this.state.search
    );
    this.getPaymentMethods();
  }
  getPaymentMethods = () => {
    this.invoiceService.getPaymentMethods().then((response) => {
      if (response.statusCode === 200) {
        let data = response.data.map((single) => {
          return {
            value: parseInt(single.enumDetailID),
            label: single.enumDetailName,
          };
        });
        this.setState({
          paymentMethods: {
            ...this.state.paymentMethods,
            data: data,
            disabled: false,
          },
        });
      } else {
        if (!this.state.isResponseErrorShow) {
          notification["error"]({
            message: labels.ADVANCE_PAYMENTS,
            description: response.message
              ? response.message
              : labels.UNABLE_TO_GET_PAYMENT_METHOD_DATA,
          });
        }
      }
    });
  };
  readyForDeliveryOrderService = new ReadyForDeliveryOrderService();
  searchCustomer = (value) => {
    if (value !== "" && value !== null && value !== undefined) {
      this.readyForDeliveryOrderService
        .searchCustomer(value)
        .then((response) => {
          if (response.statusCode === 200) {
            this.setState({
              customerName: {
                ...this.state.customerName,
                data: response.data,
              },
            });
          } else {
            notification["error"]({
              message: labels.ADVANCE_PAYMENTS,
              description: response.message
                ? this.common.Message(response.message)
                : labels.UNABLE_TO_GET_LIST_OF_CUSTOMERS,
            });
          }
        });
    }
  };
  getAdvancePaymentList = (
    index,
    size,
    sortColumn = this.state.sorter.field,
    sortOrder = this.state.sorter.order,
    search = this.state.search
  ) => {
    if (!this.state.tableLoading) {
      this.setState({ tableLoading: true });
      this.advancePaymentService
        .getAllAdvancePaymentList({
          customerID: null,
          pageIndex: index,
          pageSize: size,
          sortColumn: sortColumn,
          sortOrder: sortOrder,
          commonSearch: search,
        })
        .then((response) => {
          if (response.statusCode === 200) {
            let advancePayData = [];
            response.data.map((singleData) => {
              singleData.key = singleData.factoryID;
              //   singleData.openingDate = moment(singleData.openingDate).format(
              //     "YYYY-MM-DD"
              //   );
              //   singleData.operatingTimeFrom = moment(
              //     singleData.operatingTimeFrom,
              //     "H:mm"
              //   ).format("h:mm a");
              //   singleData.operatingTimeTo = moment(
              //     singleData.operatingTimeTo,
              //     "H:mm"
              //   ).format("h:mm a");
              advancePayData.push(singleData);
              return null;
            });
            this.setState({ advancePayment: advancePayData });
            this.setState({
              pagination: {
                ...this.state.pagination,
                total: advancePayData.length
                  ? advancePayData[0].totalRecords
                  : 0,
              },
            });
          } else {
            if (!this.state.isResponseErrorShow) {
              notification["error"]({
                message: labels.ADVANCE_PAYMENTS,
                description: response.message
                  ? response.message
                  : labels.UNABLE_TO_GET_LIST_OF_ADVANCE_PAYMENT_DATA,
              });
              this.setState({ isResponseErrorShow: true });
            }
          }
          this.setState({ tableLoading: false });
        });
    }
  };

  generateTaxInvoiceRemoteRequest = (values) => {
    // if (this.state.paymentMethods.selected == 3 && this.state.restAmount > 0) {
    if (this.state.paymentMethods.selected == 3) {
      this.EDCRequest(values);
    }
    else if (this.state.paymentMethods.selected == 4) {
      this.QRCodeRequest(values);
    }
    else {
      this.addAdvanceAmountToWallet(values);
    }
  };

  QRCodeRequest = (values) => {

    this.setState({
      isTransFail: false,
    });
    let transID = this.common.PaytmTransNumber(
      this.state.customerName.selected.toString(),
      "Advance Payment"
    );
    this.setState({ modalLoading: true, transID: transID });
    this.setState({ showAddPopup: true });

    var postdata = {
      mid: "LAUNDR39826025976613",
      orderId: transID,
      amount: (values.amount).toString(),
      businessType: "UPI_QR_CODE",
      posId: localStorage.getItem("branchID"),
      clientId: "Mr9HS8Zd1lClTaJO",
      channelId: "WEB",
      version: "V1",
      versionNumber: "",
      transactionType: 0,
      response: "",
      createdBy: 0,
      id: this.state.customerName.selected.toString(),
      callFrom: "lkWalate-Advance Paymrnt",
      platform: "web-QR",
    };
    this.setState({ modalLoading: true });
    this.paytmTransService
      .QRCodeRequest(postdata)
      .then((response) => {
  
      
        if (response.statusCode == 200) {
          var res = JSON.parse(response.data.qrCodeResponse);
          if (res.body.resultInfo.resultStatus == "SUCCESS") {
            this.setState({ isShowQRCode: true, base64Image: res.body.image, isQRCodeShow: true })
            postdata.transactionType = 1;
            const intervalID = setInterval(() => {
              this.paytmTransService
                .QRCodeStatusCheck(postdata)
                .then((response) => {
                  if (response.statusCode == 200) {
                    var res = JSON.parse(response.data.qrCodeResponse);
                    if (res.body.resultInfo.resultStatus == "TXN_SUCCESS") {
                      notification["success"]({
                        message: labels.ADVANCE_PAYMENTS,
                        description:
                          labels.TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED,
                      });
                      setTimeout(() => {
                        this.addAdvanceAmountToWallet(values);
                        this.setState({
                          // transID: postdata.merchantTransactionId,
                          showAddPopup: false,
                          modalLoading: false,
                          isQRCodeShow: false
                        });
                      }, 1000);
                      clearInterval(intervalID);
                      this.setState({ modalLoading: false });
                    } else {
                      if (res.body.resultInfo.resultStatus === "TXN_FAILURE") {
                        clearInterval(intervalID);
                        this.setState({
                          showAddPopup: false,
                          isTransFail: true,
                          modalLoading: false,
                          isQRCodeShow: false
                        });
                        notification["error"]({
                          message: labels.ADVANCE_PAYMENTS,
                          description:
                            labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                        });
                      } else {
                        // debugger;

                      }
                    }
                  }
                });
            }, 15000);
            setTimeout(() => {
              clearInterval(intervalID);
              this.setState({
                showAddPopup: false,
                modalLoading: false,
                isQRCodeShow: false
              });
              // if (
              //   (res.body.resultInfo.resultStatus == "FAIL" ||
              //     res.body.resultInfo.resultStatus ==
              //       "PANDING") &&
              //   this.state.isTransFail == false
              // ) {
              notification["error"]({
                message: labels.ADVANCE_PAYMENTS,
                description:
                  labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
              });
              // }
            }, 360000);
          } else {
            this.setState({ showAddPopup: false, modalLoading: false, isQRCodeShow: false });
            notification["error"]({
              message: labels.ADVANCE_PAYMENTS,
              description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
            });
          }
        } else {
          this.setState({ showAddPopup: false, modalLoading: false, isQRCodeShow: false });
          notification["error"]({
            message: labels.ADVANCE_PAYMENTS,
            description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
          });
        }
        this.setState({ modalLoading: false });
      });

    // } else {
    //   this.setState({ showAddPopup: false, modalLoading: false });
    //   notification["error"]({
    //     message: labels.BUY_PACKAGE,
    //     description: labels.UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM,
    //   });
    // }
    this.setState({ modalLoading: true, isQRCodeShow: false });
    //});
  }

  EDCRequest = (values) => {
    // if (this.state.paymentMethods.selected == 3) {
    this.setState({
      isTransFail: false,
    });
    let transID = this.common.PaytmTransNumber(
      this.state.customerName.selected.toString(),
      "Advance Payment"
    );

    this.setState({ modalLoading: true, transID: transID });
    this.paytmTransService.getEDCMachineInfo().then((response) => {
      if (response.statusCode == 200) {
        if ([undefined, "", null].includes(response.data.machineKey)) {
          notification["error"]({
            message: labels.ADVANCE_PAYMENTS,
            description: labels.EDC_CONFIGURATION,
          });
          this.setState({ modalLoading: false });
          return false;
        }
        this.setState({ showAddPopup: true, EDCmachineInfo: response.data });
        var postdata = {
          paytmMid: response.data.paytmMid,
          paytmTid: response.data.paytmTid,
          transactionDateTime: "",
          merchantTransactionId: "",
          merchantReferenceNo: "",
          transactionAmount: (values.amount * 100).toString(),
          callbackUrl: "",
          machineKey: response.data.machineKey,
          json: 0,
          response: "",
          transactionType: 0,
          requestParams: "",
          createdBy: 0,
          status: "",
          id: this.state.customerName.selected.toString(), //this.state.invoiceData.invoiceID,
          callFrom: "lkWalate-Advance Paymrnt",
          platform: "web",
          transctionId: transID,
          versionNumber: response.data.versionNumber,
        };
        this.setState({ modalLoading: true });
        this.paytmTransService
          .EDCMachineRequest(postdata)
          .then((response) => {
            if (response.statusCode == 200) {
              var res = JSON.parse(response.data.response);
              if (res.body.resultInfo.resultStatus == "ACCEPTED_SUCCESS") {
                postdata.merchantTransactionId = this.state.transID;
                postdata.transactionDateTime =
                  response.data.transactionDateTime;
                postdata.transactionType = 1;
                const intervalID = setInterval(() => {
                  this.setState({ modalLoading: true });
                  this.paytmTransService
                    .EDCMachineStatus(postdata)
                    .then((response) => {
                      if (response.statusCode == 200) {
                        var res = JSON.parse(response.data.response);
                        if (res.body.resultInfo.resultStatus == "SUCCESS") {
                          notification["success"]({
                            message: labels.ADVANCE_PAYMENTS,
                            description:
                              labels.TRANSACTION_HAS_BEEN_SUCCESSFULLY_PROCESSED,
                          });
                          this.setState({ modalLoading: false });
                          setTimeout(() => {
                            this.addAdvanceAmountToWallet(values);
                            this.setState({
                              // transID: postdata.merchantTransactionId,
                              showAddPopup: false,
                            });
                          }, 1000);
                          clearInterval(intervalID);
                          return false;
                        } else {
                          if (res.body.resultInfo.resultStatus == "FAIL") {
                            clearInterval(intervalID);
                            this.setState({
                              showAddPopup: false,
                              isTransFail: true,
                              modalLoading: false,
                            });
                            notification["error"]({
                              message: labels.ADVANCE_PAYMENTS,
                              description:
                                labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                            });
                          } else {
                            setTimeout(() => {
                              clearInterval(intervalID);
                              this.setState({
                                showAddPopup: false,
                                modalLoading: false,
                              });
                              // if (
                              //   (res.body.resultInfo.resultStatus == "FAIL" ||
                              //     res.body.resultInfo.resultStatus ==
                              //     "PANDING") &&
                              //   this.state.isTransFail == false
                              // ) {
                              this.setState({
                                modalLoading: false,
                                showAddPopup: false,
                              });
                              notification["error"]({
                                message: labels.ADVANCE_PAYMENTS,
                                description:
                                  labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                              });
                              // }
                            }, 360000);
                          }
                        }
                      }
                    });
                }, 10000);
              } else {
                this.setState({ showAddPopup: false, modalLoading: false });
                notification["error"]({
                  message: labels.ADVANCE_PAYMENTS,
                  description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
                });
              }
              this.setState({ modalLoading: false });
            } else {
              this.setState({ showAddPopup: false, modalLoading: false });
              notification["error"]({
                message: labels.ADVANCE_PAYMENTS,
                description: labels.UNABLE_TO_PROCESS_TRANSACTION_TRY_AGAIN,
              });
            }
          });
      } else {
        this.setState({ showAddPopup: false });
        notification["error"]({
          message: labels.ADVANCE_PAYMENTS,
          description: labels.UNABLE_TO_PROCESS_PLEASE_CONTACT_TECHNICAL_TEAM,
        });
      }
      this.setState({ modalLoading: false });
    });
    // } else {
    //   this.addAdvanceAmountToWallet(values);
    // }
  };
  addAdvanceAmountToWallet = (values) => {
    this.setState({ modalLoading: true });
    const postData = {
      customerID: parseInt(this.state.customerName.selected),
      orderID: null,
      invoiceID: null,
      storeID: localStorage.getItem("branchID"),
      advanceAmount: values.amount,
      isExcessAmount: false,
      paymentMode: parseInt(this.state.paymentMethods.selected),
      chequeNo: null,
      chequeDate: null,
      cardNo: null,
      transactionNo: this.state.transID,
      remark: values.remarks
        ? values.remarks
        : "Advance Amount Added From Advance Amount Master Screen",
      sourceFrom: 1,
    };
    this.invoiceService.addExcessAmountToWallet(postData).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.ADVANCE_PAYMENTS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.ADVANCE_COLLECTED_SUCCESSFULLY,
        });
        this.setState({ showAddModal: false });
      } else {
        notification["error"]({
          message: labels.ADVANCE_PAYMENTS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_ADD_AMOUNT_TO_LKWALLET,
        });
      }
      this.setState({ modalLoading: false });
    });
  };

  render() {
    const { isShowQRCode, base64Image, isQRCodeShow } = this.state
    return (
      <div>
        <PageHeader
          title={labels.ADVANCE_PAYMENTS}
          extra={[
            <Search
              placeholder={labels.SEARCH}
              className={styles.SearchInput}
              key="1"
              onChange={(event) => {
                this.setState({
                  search: event.target.value.toString().toLowerCase(),
                });
                this.getAdvancePaymentList(
                  this.state.pagination.current,
                  this.state.pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  event.target.value.toString().toLowerCase()
                );
              }}
            />,
            /**
             * Add Factory Button redirects to add form
             */
            this.state.userRights.length > 0 &&
              this.state.userRights[0].isAdd == true ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() => this.setState({ showAddModal: true })}
                key="2"
              >
                {labels.ADD_ADVANCE_PAYMENT}
              </Button>
            ) : null,
            // <Button
            //   type="link"
            //   className={styles.FilterToggle}
            //   onClick={() => {
            //     this.setState({ showfilterBox: !this.state.showfilterBox });
            //   }}
            //   key="3"
            // >
            //   {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
            //   <FontAwesomeIcon
            //     icon={faChevronUp}
            //     className={
            //       this.state.showfilterBox
            //         ? styles.FilterToggleIcon
            //         : styles.FilterToggleIconRotated
            //     }
            //   />
            // </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form onFinish={() => null}>
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  {/**
                   * For More Clarity over what <Select> Component can do
                   * Please go through https://ant.design/components/select/
                   * https://ant.design/components/select/#API
                   */}
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item name="state">
                  <Select placeholder="Select State" allowClear>
                    <Option value={1}>Gujarat</Option>
                    <Option value={2}>Maharashtra</Option>
                    <Option value={3}>Madhya Pradesh</Option>
                    <Option value={4}>Rajasthan</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4}></Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button variant="whitePink" shape="round" size="large">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>

        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.advancePayment}
          loading={this.state.tableLoading}
          pagination={this.state.pagination}
          onChange={(pagination, filters, sorter, extra) => {
            this.setState(
              {
                sorter: {
                  ...sorter,
                  order:
                    sorter.order === "ascend"
                      ? "asc"
                      : sorter.order === "descend"
                        ? "desc"
                        : sorter.order,
                },
              },
              () => {
                this.getAdvancePaymentList(
                  pagination.current,
                  pagination.pageSize,
                  this.state.sorter.field,
                  this.state.sorter.order,
                  this.state.search
                );
              }
            );
          }}
        />
        <Modal
          title={labels.COLLECT_ADVANCE_PAYMENT}
          visible={this.state.showAddModal}
          footer={null}
          onCancel={() => this.setState({ showAddModal: false })}
          className="modal-form"
          destroyOnClose
          centered
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={this.generateTaxInvoiceRemoteRequest}
              initialValues={{ paymentMode: 1, amount: 0 }}
            >
              <Row gutter={21}>
                <Col xs={16}>
                  <Form.Item
                    name="customer"
                    label={labels.CUSTOMER}
                    rules={[
                      {
                        required: true,
                        message: labels.PLEASE_SELECT_CUSTOMER,
                      },
                    ]}
                  >
                    <Select
                      onSearch={this.searchCustomer}
                      placeholder={labels.SEARCH_CUSTOMER}
                      showSearch
                      allowClear
                      onChange={(value) => {
                        this.setState({
                          customerName: {
                            ...this.state.customerName,
                            selected: value,
                          },
                        });
                      }}
                      filterOption={false}
                    // filterOption={(input, option) =>
                    //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                    //   0
                    // }
                    >
                      {this.state.customerName.data.length !== 0
                        ? this.state.customerName.data.map(
                          (singleCustomerName) => {
                            return singleCustomerName.customerID !== "-1" ? (
                              <Option
                                value={singleCustomerName.customerID}
                                key={singleCustomerName.customerID}
                              >
                                {singleCustomerName.customerName +
                                  " (" +
                                  singleCustomerName.mobileNo +
                                  ")"}
                              </Option>
                            ) : null;
                          }
                        )
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={8}></Col>
                <Col xs={12}>
                  <Form.Item name="paymentMode" label={labels.PAYMENT_METHOD}>
                    <Select
                      placeholder={labels.PAYMENT_METHOD}
                      disabled={this.state.paymentMethods.disabled}
                      options={this.state.paymentMethods.data.filter(
                        (x) => x.label.toLowerCase() !== "lk wallet"
                      )}
                      value={this.state.paymentMethods.selected}
                      onChange={(value) =>
                        this.setState({
                          paymentMethods: {
                            ...this.state.paymentMethods,
                            selected: value,
                          },
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <div className={styles.advanceAmountInput}>
                    <Form.Item
                      name="amount"
                      label={labels.AMOUNT}
                      rules={[
                        {
                          required: true,
                          message: labels.AMOUNT_IS_REQUIRED,
                        },
                        {
                          min: 0.01,
                          type: "number",
                          message: labels.AMOUNT_SHOULD_BE_GREATER_THAN_0,
                        },
                      ]}
                    >
                      <InputNumber precision={2} placeholder={0} />
                    </Form.Item>
                  </div>
                </Col>
                <Col xs={24}>
                  <Form.Item name="remarks" label={labels.REMARKS}  rules={[
                        {
                          required: true,
                          message: labels.PLEASE_ENTER_REMARKS,
                        }]}>
                    <Input.TextArea placeholder={labels.REMARKS} rows={3} />
                  </Form.Item>
                </Col>
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    <React.Fragment>
                      <Button
                        variant="transparentGreyOutline"
                        onClick={() => this.setState({ showAddModal: false })}
                      >
                        {labels.CANCEL}
                      </Button>
                      <Button htmlType="submit" variant="purple">
                        {labels.COLLECT}
                      </Button>
                    </React.Fragment>
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>

        <Modal
          title={labels.PROCESSING}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
          maskClosable={false}
        >
          <Form layout="vertical" onFinish={this.handlePopupAdd}>
            <Row gutter={24}>
              <Col xs={24}>{labels.PROCESSING}.....</Col>
            </Row>
          </Form>
        </Modal>

        {isShowQRCode ?
          <Modal
            title={"QR Code"}
            visible={isQRCodeShow}
            footer={null}
            onCancel={this.handlePopupCancel}
            className="modal-form"
            destroyOnClose
          >
            <Spin spinning={false}>
              <Row gutter={24}>
                <Col xs={24}>
                <div style= {{marginLeft : "7%"}}>
                    <img src={"data:image/jpeg;base64," + base64Image} alt  style = {{width : 400}}  />
                  </div>
                </Col>
              </Row>
            </Spin>
          </Modal>

          : null}

      </div>
    );
  }

  handlePopupCancel = () => {
    confirm({
      title: "Do you want to kill these payment process?",
      content: "When clicked the OK button, Stop the payment process",
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.5 ? resolve : reject, 3000);
          setTimeout(function () {
            window.location.reload();
          }, 4000);
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() { },
    });
  };
}

export default withRouter(Master);
