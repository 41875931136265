import React, { createRef } from "react";
import styles from "../../../Common/Styles/master.module.css";
import Button from "../../../Common/Button/button";
import Filters from "../../../Common/Filters/filters";
import moment from "moment";
import {
  PageHeader,
  Input,
  Row,
  Col,
  Select,
  Form,
  Space,
  // Popconfirm,
  Table,
  notification,
  Tooltip,
  Modal,
  Checkbox,
  DatePicker,
  Divider,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faPencilAlt,
  faInfo,
  faUserEdit,
  faList,
  faPlusSquare,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter, Link } from "react-router-dom";
import ROUTES from "../../../../routes";
import PickupService from "../../../../services/pickupservice";
import { IsAuthenticated } from "../../../../services/auth-header";
import UserRightsConfiguration from "../../../../shared/userrightsconfiguration";
import TableActions from "../../../../components/Common/TableActions/tableactions";
import { labels } from "../../../../shared/language";
import Common from "../../../../services/common";
import storemarkerimg from "../../../../images/MapMarker/shoppurple.svg";

const { Search } = Input;
const { Option } = Select;
const ONE_MONTHS_AGO = new Date();
ONE_MONTHS_AGO.setMonth(ONE_MONTHS_AGO.getMonth() - 1);

var ReAssign_Store_By_Admin = [];

/**
 * A pickup screen which holds grid view for pickuplist and form with steps
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 13 July 2020
 */
class Master extends React.Component {
  pickupService = new PickupService();
  UserRightsConfiguration = new UserRightsConfiguration();
  common = new Common();
  tempcolumns =[
    {
      title: labels.PICKUP_ID,
      dataIndex: "pickupID",
      key: "pickupID",
      sorter: true,
    },

    {
      title: labels.REQUEST_CREATED_DATE,
      dataIndex: "createdOn",
      key: "createdOn",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY HH:mm:ss");
      },
      cancelhide:true,
      cancelledhide:true
    },

    {
      title: labels.PLATFORM,
      dataIndex: "platform",
      key: "platform",
      sorter: true,
    },
    {
      title: labels.ORDER_COUNT,
      dataIndex: "orderCount",
      key: "orderCount",
      sorter: true,
    },
    {
      title: labels.STORE_NAME,
      dataIndex: "storeName",
      key: "storeName",
      sorter: true,
    },
    {
      title: labels.CUSTOMER_NAME,
      dataIndex: "customerName",
      key: "customerName",
      sorter: true,
    },
    {
      title: labels.CUST_MO_No,
      dataIndex: "custMobileNo",
      key: "custMobileNo",
      sorter: true,
      cancelhide:true,
      cancelledhide:true
    },
    {
      title: labels.PICKUP_DATE,
      dataIndex: "pickupDate",
      key: "pickupDate",
      sorter: true,
      render: (text) => {
        return moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: labels.PICKUP_TIMESLOT,
      dataIndex: "pickupSlotName",
      key: "pickupSlotName",
      sorter: true,
    },
    {
      title: labels.STATUS,
      dataIndex: "statusName",
      key: "statusName",
      sorter: true,
      cancelhide:true,
      cancelledhide:true
    },
    {
      title: "Services",
      dataIndex: "serviceNames",
      key: "serviceNames",
      sorter: true,
      cancelhide:true,
      cancelledhide:true
    },
    {
      title: labels.DELIVERY_TYPE,
      dataIndex: "deliveryTypeName",
      key: "deliveryTypeName",
      sorter: true,
    },
    {
      title: labels.PROMOCODE,
      dataIndex: "promocode",
      key: "promocode",
      sorter: true,
    },
    // {
    //   title: "Delivery TimeSlot",
    //   dataIndex: "deliverySlotName",
    //   key: "deliverySlotName",
    //   sorter: true,
    // },
    {
      title: labels.PICK_BOY,
      dataIndex: "assignedToUserName",
      key: "assignedToUserName",
      sorter: true,
    },
    {
      title: labels.CUSTOMER_REMARK,
      dataIndex: "remark",
      key: "remark",
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
      cancelhide:true
    },
    {
      title: labels.CANCEL_REQUESTED_BY,
      dataIndex: "cancelRequestedByName",
      key: "cancelRequestedByName",
      sorter: true,
      render: (text, record) => {
        return text != 0 ? (
          <React.Fragment>
            <text>{text}</text>
          </React.Fragment>
        ) : <React.Fragment>
        <text>Customer</text>
      </React.Fragment>;
      },
    },
    {
      title: labels.CANCEL_REQUESTED_REASON,
      dataIndex: "cancelRequestedReason",
      key: "cancelRequestedReason",
      sorter: true,
    },
    {
      title: labels.CANCELLED_REASON,
      dataIndex: "canceledReason",
      key: "canceledReason",
      sorter: true,
      cancelhide:true
    },

    {
      title: labels.CANCELLED_BY,
      dataIndex: "canceledByName",
      key: "canceledByName",
      sorter: true,
      cancelhide:true
    },

    {
      title: labels.ACTIONS,
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {/* {console.log("record->", record)} */}
          {this.state.userRights.length > 0 &&
          this.state.userRights[0].isView == true ? (
            <Tooltip title={labels.VIEW}>
              <Link
                to={{
                  pathname: [ROUTES.VIEW_PICKUP, record.pickupID].join("/"),
                  state: { pickupID: record.pickupID },
                }}
              >
                <FontAwesomeIcon icon={faInfo} />
              </Link>
            </Tooltip>
          ) : null}
          {record.storeID.toString() === localStorage.getItem("branchID") ? (
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isUpdate == true ? (
              record.status === 1 ||
              record.status === 2 ||
              record.status === 3 ||
              record.status === 8 ? (
                <Tooltip title={labels.EDIT}>
                  <Link
                    to={{
                      pathname: [ROUTES.EDIT_PICKUP, record.pickupID].join("/"),
                      state: { pickupID: record.pickupID },
                    }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Link>
                </Tooltip>
              ) : null
            ) : null
          ) : null}

          {record.storeID.toString() === localStorage.getItem("branchID") ? (
            record.status === 1 || record.status === 2 ? (
              <Link
                to={{
                  pathname: ROUTES.CREATE_ORDER,
                  state: { pickupID: record.pickupID },
                }}
              >
                <Tooltip title={labels.ORDER}>
                  <FontAwesomeIcon icon={faList} />
                </Tooltip>
              </Link>
            ) : null
          ) : null}

          {record.storeID.toString() === localStorage.getItem("branchID") ? (
            record.status === 2 || record.status === 1 ? ( //added condition on 23/09/2020
              <Button
                variant="link"
                type="link"
                onClick={() =>
                  this.setState({
                    isModalVisible: true,
                    pickupId: record.pickupID,
                  })
                }
              >
                <Tooltip title={labels.REASSIGN}>
                  <FontAwesomeIcon icon={faUserEdit} />
                </Tooltip>
              </Button>
            ) : null
          ) : null}
          {/* {console.log("record->", record)} */}
          {record.storeID.toString() === localStorage.getItem("branchID") ? (
            record.status === 4 || record.status === 3 ? (
              <Button
                variant="link"
                type="link"
                onClick={() =>
                  this.props.history.push({
                    pathname: [ROUTES.ADD_PICKUP, record.customerID].join("/"),
                    state: {
                      customerID: record.customerID,
                    },
                  })
                }
              >
                <Tooltip title="Recreate Pickup">
                  <FontAwesomeIcon icon={faPlusSquare} />
                </Tooltip>
              </Button>
            ) : null
          ) : null}
          {/* {console.log("record->",record)} */}
          {record.storeID.toString() === localStorage.getItem("branchID") &&
          ReAssign_Store_By_Admin.length > 0 ? (
            <Button
              variant="link"
              type="link"
              onClick={() =>
                this.setState({
                  UpdateStoreIDModalVisible: true,
                  SelectedPickupID: record.pickupID,
                })
              }
            >
              <Tooltip title="Reassign Store">
                <FontAwesomeIcon icon={faStore} />
              </Tooltip>
            </Button>
          ) : null}
        </Space>
      ),
    },
  ];
  /**
   * State Varibales to determine/toggle multiple components on screen
   *
   * @var showFilterBox : toggle this variable to show/hide the filter box on the top of the table
   */
  state = {
    showfilterBox: true,
    pickupFromDate: moment(ONE_MONTHS_AGO),
    pickupToDate: moment(),
    pickupId: 0,
    pickUps: [],
    tableLoading: true,
    userListOptions: [],
    isReassignBtnVisible: false,
    isModalVisible: false,
    UpdateStoreIDModalVisible: false,
    userID: "",
    userName: [],
    selectedBranch: localStorage.getItem("branchID"),
    status: null,
    pickuplist: [],
    pickupStatus: {
      disabled: true,
      data: [],
    },
    selectedPickupStatus: "1,2",
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
    },

    UniquetimeSlot: [],
    SelectedTimeSlot: "",
    TodayPickupList: [],
    ShowMapView: false,
    SelectedPickupData: {},
    TodaySlotList: [],
    MarkerList: [],
    SelectedStoreID: 0,
    SelectedPickupID: 0,
    CancelHide:false,
    columns : this.tempcolumns
    
  };

  mapHolder = React.createRef();

  componentWillMount() {
   
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.PICKUP
    );

    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  
 

  componentDidMount() {
    
    const { pagination } = this.state;
    this.getPickupList({
      storeID: this.state.selectedBranch,
      pickupStatusID: this.state.selectedPickupStatus,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      PickupFromDate: this.state.pickupFromDate,
      PickupToDate: this.state.pickupToDate,
      PickupSlot: this.state.SelectedTimeSlot,
      // ...filters,
    });
    this.getUserList();
    this.getPickupStatus();
    this.getSlotByStoreIDS(this.state.selectedBranch);
  }

  updatePickupStoreID = () => {
    let postData = {
      pickupID: this.state.SelectedPickupID,
      storeID: parseInt(this.state.SelectedStoreID),
    };
    // console.log("postData->",postData);
    this.pickupService.updatePickupStoreID(postData).then((response) => {
      if (response.statusCode === 200) {
        notification["success"]({
          message: labels.PICKUPS,
          description: labels.STOREID_UPDATED_SUCCESSFULLY,
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: labels.UNABLE_TO_UPDATE_STOREID,
        });
      }
      this.setState({ UpdateStoreIDModalVisible: false });
      const { pagination } = this.state;
      this.getPickupList({
        storeID: this.state.selectedBranch,
        pickupStatusID: this.state.selectedPickupStatus,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        pagination,
        PickupFromDate: this.state.pickupFromDate,
        PickupToDate: this.state.pickupToDate,
        PickupSlot: this.state.SelectedTimeSlot,
        // ...filters,
      });
    });
  };

  getPickupStatus = () => {
    this.pickupService.getPickupStatusList().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          pickupStatus: {
            ...this.state.pickupStatus,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_DATA_FOR_PICKUP_STATUS,
        });
      }
    });
  };
  getPickupList = (params = {}) => {
    this.setState({ tableLoading: true });
    this.pickupService.getPickup(params).then((pickupData) => {
      if (pickupData.statusCode === 200) {
        if (
          pickupData.length !== 0 &&
          pickupData.data.length === 0 &&
          params.pagination.current !== 1
        ) {
          params.pagination.current = 1;
          this.getPickupList({
            storeID: this.state.selectedBranch,
            pickupStatusID: this.state.selectedPickupStatus,
            pageSize: params.pageSize,
            pagination: params.pagination,
            PickupFromDate: this.state.pickupFromDate,
            PickupToDate: this.state.pickupToDate,
            PickupSlot: this.state.SelectedTimeSlot,
            // ...filters,
          });
        }
        this.setState({
          pickUps: pickupData.data,
          status: pickupData.data.status,
        });
        // console.log("data->", pickupData.data);
        // var temp = pickupData.data.filter(
        //   (item) => item.status == 1 || item.status == 2
        // );
        // console.log("temp->",temp);
        this.setState({
          pickuplist: pickupData.data,
        });

        this.setState({
          pagination: {
            ...params.pagination,
            total: pickupData.data.length ? pickupData.data[0].totalRecords : 0,
          },
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: pickupData.message
            ? this.common.Message(pickupData.message)
            : labels.UNABLE_TO_GET_LIST_OF_PICKUP_DATA,
        });
      }
      this.setState({ tableLoading: false });
    });
  };

  //function for getting the  data from server call into dropdown list
  getUserList = () => {
    var storeid = parseInt(localStorage.getItem("branchID"));
    this.pickupService.getUserListBasedOnStore(storeid).then((items) => {
      if (items.statusCode === 200) {
        this.setState({ userListOptions: items.data });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: items.message
            ? this.common.Message(items.message)
            : labels.UNABLE_TO_GET_LIST_OF_USER_DATA,
        });
      }
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.getPickupList({
      storeID: [null, undefined, ""].includes(this.state.selectedBranch)
        ? localStorage.getItem("branchID").toString()
        : this.state.selectedBranch,
      pickupStatusID: this.state.selectedPickupStatus,
      pageIndex: pagination.current,
      pageSize: pagination.pageSize,
      pagination,
      sortColumn: sorter.field,
      sortOrder:
        sorter.order === undefined
          ? null
          : sorter.order === "descend"
          ? "desc"
          : "asc",
      PickupFromDate: this.state.pickupFromDate,
      PickupToDate: this.state.pickupToDate,
      PickupSlot: this.state.SelectedTimeSlot,
      // ...filters,
    });
  };

  handleSearch = (event) => {
    this.getPickupList({
      storeID: [null, undefined, ""].includes(this.state.selectedBranch)
        ? localStorage.getItem("branchID").toString()
        : this.state.selectedBranch,
      pickupStatusID: this.state.selectedPickupStatus,
      commonSearch: event.target.value,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      PickupFromDate: this.state.pickupFromDate,
      PickupToDate: this.state.pickupToDate,
      PickupSlot: this.state.SelectedTimeSlot,
      // ...filters,
    });
  };

  RestoreDDlValue = (e) => {
    var userArray = this.state.userListOptions.filter((x) => x.value === e);
    // let c = cityArray[0].text;
    if (userArray !== undefined) {
      this.setState({
        userID: userArray[0].value,
        userName: userArray[0].text,
      });
    }
  };

  handlePopupOk = () => {
    this.setState({ isModalVisible: false });
  };
  handlePopupCancel = () => {
    this.setState({ isModalVisible: false });
    this.resetFields();
  };

  handleUpdateStoreIDPopupOk = () => {
    this.setState({ UpdateStoreIDModalVisible: false });
  };
  handleUpdateStoreIDPopupCancel = () => {
    this.setState({ UpdateStoreIDModalVisible: false });
    // this.resetFields();
  };

  // handleStatusWiseData = (Case) => {
  //   //console.log("Case->",Case , typeof Case);
  //   const pickupListItem = this.state.pickUps;
  //   var TempList = [];

  //   TempList = [];
  //   if (Case == 1) {
  //     //show all assigned & scheduled pickups
  //     // TempList = pickupListItem.filter(
  //     //   (item) => item.status == 1 || item.status == 2
  //     // );
  //     this.getPickupList({
  //       storeID: this.state.selectedBranch,
  //       pickupStatusID: this.state.selectedPickupStatus,
  //       pageIndex: pagination.current,
  //       pageSize: pagination.pageSize,
  //       pagination,
  //       PickupFromDate: this.state.pickupFromDate,
  //       PickupToDate: this.state.pickupToDate,
  //       // ...filters,
  //     });
  //   } else if (Case == 2) {
  //     //show all reschedule requested pickup
  //     TempList = pickupListItem.filter((item) => item.status == 8);
  //   } else if (Case == 3) {
  //     //show all cancel requested pickup
  //     TempList = pickupListItem.filter((item) => item.status == 3);
  //   } else if (Case == 4) {
  //     //show all cancelled pickup
  //     TempList = pickupListItem.filter((item) => item.status == 4);
  //   }
  //   console.log("TempList->", TempList);

  //   this.setState({
  //     pickuplist: TempList,
  //   });
  // };

  handleStatusWiseData = () => {
    this.setState(
      {
        pagination: {
          current: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
        },
      },
      () =>
        this.getPickupList({
          storeID: this.state.selectedBranch,
          pickupStatusID: this.state.selectedPickupStatus,
          pageIndex: 1,
          pageSize: 10,
          // pageIndex: pagination.current,
          // pageSize: pagination.pageSize,
          pagination: this.state.pagination,
          PickupFromDate: this.state.pickupFromDate,
          PickupToDate: this.state.pickupToDate,
          PickupSlot: this.state.SelectedTimeSlot,
          // ...filters,
        })
    );

    // const { pagination } = this.state;
    // this.getPickupList({
    //   storeID: this.state.selectedBranch,
    //   pickupStatusID: this.state.selectedPickupStatus,
    //   pageIndex: 1,
    //   pageSize: 10,
    //   // pageIndex: pagination.current,
    //   // pageSize: pagination.pageSize,
    //   pagination,
    //   PickupFromDate: this.state.pickupFromDate,
    //   PickupToDate: this.state.pickupToDate,
    //   // ...filters,
    // });
  };

  resetFields = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  handlePopupReassign = () => {
    var postData = {
      pickupID: parseInt(this.state.pickupId),
      assignedToUserID: parseInt(this.state.userID),
      sourceFrom: 1,
    };

    this.pickupService.getReassignPickups(postData).then((items) => {
      if (items.result === "Success") {
        notification["success"]({
          message: labels.PICKUPS,
          description: this.common.Message(items.message),
        });
        this.setState({ items: items, isModalVisible: false });
        const { pagination } = this.state;
        this.getPickupList({
          storeID: localStorage.getItem("branchID").toString(),
          pickupStatusID: this.state.selectedPickupStatus,
          pageIndex: this.state.pagination.current,
          pageSize: this.state.pagination.pageSize,
          pagination: this.state.pagination,
          pagination,
          PickupFromDate: this.state.pickupFromDate,
          PickupToDate: this.state.pickupToDate,
          PickupSlot: this.state.SelectedTimeSlot,
        });
        this.resetFields();
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: items.message
            ? this.common.Message(items.message)
            : labels.UNABLE_TO_REASSIGN_THE_PICKUP,
        });
      }
    });
  };
  // handleSelect = (event1) => {
  //
  //   let event = parseInt(event1.slice(1));
  //   let type = event1.charAt(0);
  //   const branchTypes = ![undefined, null, ""].includes(
  //     localStorage.getItem("BranchInfo")
  //   )
  //     ? JSON.parse(localStorage.getItem("BranchInfo"))
  //     : [];
  //   var branchArray = branchTypes.filter(
  //     (x) =>
  //       x.branchID === event &&
  //       ((x.branchType === "Store" && type === "s") ||
  //         (x.branchType === "Factory" && type === "f"))
  //   );
  //
  //   if (branchArray !== undefined) {
  //     this.setState({
  //       selectedBranch: branchArray[0].branchID,
  //       selectedBranchName: branchArray[0].branchName,
  //       selectedBranchType: branchArray[0].branchType,
  //     });
  //   }
  // };
  handleChangeBranch = () => {
    const { pagination } = this.state;
    this.getPickupList({
      storeID: [null, undefined, ""].includes(this.state.selectedBranch)
        ? localStorage.getItem("branchID")
        : this.state.selectedBranch,
      pickupStatusID: this.state.selectedPickupStatus,
      pageIndex: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      pagination: this.state.pagination,
      pagination,
      PickupFromDate: this.state.pickupFromDate,
      PickupToDate: this.state.pickupToDate,
      PickupSlot: this.state.SelectedTimeSlot,
    });
    this.getSlotByStoreIDS(this.state.selectedBranch);
  };

  handleDateChange = (date, name) => {
    this.setState({ [name]: date });
  };

  getSlotByStoreIDS = (IDS) => {
    //  console.log("IDS->",IDS);
    this.pickupService.getSlotListByStoreIDS(IDS).then((response) => {
      if (response.statusCode === 200) {
        // console.log("response->", response);
        this.setState({
          UniquetimeSlot: response.data,
        });
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_DATA_FOR_TIMESLOT,
        });
      }
    });
  };

  getTodayPickuplist = () => {
    let params = {
      storeID: parseInt(localStorage.getItem("branchID")),
      sourceFrom: 1,
      userID: 0,
    };
    this.pickupService.GetTodayPickipList(params).then((response) => {
      if (response.statusCode === 200) {
        // console.log("response->", response);
        this.setState({
          TodayPickupList: response.data.pickupListRes,
          TodaySlotList: response.data.slotList,
          ShowMapView: true,
        });
        if (
          response.data.pickupListRes != undefined &&
          response.data.pickupListRes.length > 0
        ) {
          this.initMap();
        }
      } else {
        notification["error"]({
          message: labels.PICKUPS,
          description: response.message
            ? this.common.Message(response.message)
            : labels.UNABLE_TO_GET_PICKUP_DATA,
        });
      }
    });
  };

  // showstoremarker = () => {
  //   var map;
  //   var bounds = new window.google.maps.LatLngBounds();
  //   var mapOptions = {
  //     mapTypeId: "roadmap",
  //   };

  //   const icon = {
  //     url: storemarkerimg,
  //     size: new window.google.maps.Size(50, 50),
  //     origin: new window.google.maps.Point(0, 0),
  //     anchor: new window.google.maps.Point(17, 34),
  //   };

  //   map = new window.google.maps.Map(
  //     document.getElementById("map"),
  //     mapOptions
  //   );
  //   map.setTilt(50);

  //   var infoWindow = new window.google.maps.InfoWindow(),
  //     storemarker,
  //     i;

  //   //for Store Marker -- Start
  //   let Storemarkers = this.state.TodayPickupList;
  //   var Storeposition = new window.google.maps.LatLng(
  //     Storemarkers[0].storeLat,
  //     Storemarkers[0].storeLong
  //   );

  //   bounds.extend(Storeposition);
  //   storemarker = new window.google.maps.Marker({
  //     position: Storeposition,
  //     map: map,
  //     icon,
  //     title: Storemarkers[0].storeAddress,
  //   });

  //   window.google.maps.event.addListener(
  //     storemarker,
  //     "click",
  //     (function (storemarker, i) {
  //       return function () {
  //         infoWindow.setContent(Storemarkers[0].storeAddress);
  //         infoWindow.open(map, storemarker);
  //       };
  //     })(storemarker, i)
  //   );
  //   map.fitBounds(bounds);
  //   // Set zoom level
  //   var boundsListener = window.google.maps.event.addListener(
  //     map,
  //     "bounds_changed",
  //     function (event) {
  //       this.setZoom(14);
  //       window.google.maps.event.removeListener(boundsListener);
  //     }
  //   );
  // };

  initMap = (CheckboxValueType) => {
    // console.log("CheckboxValueType->", CheckboxValueType);
    var map;
    var bounds = new window.google.maps.LatLngBounds();
    var mapOptions = {
      mapTypeId: "roadmap",
    };

    this.setState({
      SelectedPickupData: {},
    });

    const icon = {
      url: storemarkerimg,
      size: new window.google.maps.Size(50, 50),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(17, 34),
    };
    // Display a map on the web page
    map = new window.google.maps.Map(
      document.getElementById("map"),
      mapOptions
    );
    map.setTilt(50);

    //for pickup marker
    if (CheckboxValueType != undefined || CheckboxValueType != null) {
      let selectedmarker = CheckboxValueType.map((slot) => {
        return this.state.TodayPickupList.filter((item) => item.slotID == slot);
      });

      // console.log("selectedmarker->", selectedmarker);
      // Add multiple markers to map
      var infoWindow = new window.google.maps.InfoWindow(),
        marker,
        i;

      // Place pickup marker on the map

      selectedmarker.map((markers, index) => {
        // console.log("markers->", markers);

        for (i = 0; i < markers.length; i++) {
          var position = new window.google.maps.LatLng(
            markers[i].latitude,
            markers[i].longitude
          );
          // map.setCenter(position);
          // bounds.extend(position);

          marker = new window.google.maps.Marker({
            position: position,
            map: map,
            title: markers[i].location,
            label: {
              text: `${markers[i].pickupID}`,
              color: this.state.TodaySlotList.filter((e) => {
                return e.slotID == markers[i].slotID;
              })[0].colorName,
              // fontSize: "20px",
              // fontWeight: "bold"
            },
            icon: {
              path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
              fillColor: this.state.TodaySlotList.filter((e) => {
                return e.slotID == markers[i].slotID;
              })[0].colorName,
              fillOpacity: 1,
              strokeWeight: 1,
              // rotation: 0,
              scale: 1.2,
              anchor: new window.google.maps.Point(0, 20),
              labelOrigin: new window.google.maps.Point(0, 30),
            },
          });

          // this.setState({
          //   MarkerList: marker,
          // });

          // Add info window to marker
          var self = this;
          window.google.maps.event.addListener(
            marker,
            "click",
            (function (marker, i) {
              var tempobj = {
                pickupID: markers[i].pickupID,
                customerName: markers[i].customerName,
                mobileNo: markers[i].mobileNo,
                address: markers[i].location,
              };

              return function () {
                self.setState({
                  SelectedPickupData: tempobj,
                });
                infoWindow.setContent(markers[i].location);
                infoWindow.open(map, marker);
                navigator.geolocation.getCurrentPosition(function (position) {
                  window.open(
                    "https://www.google.com/maps/dir/?api=1&origin=" +
                      position.coords.latitude +
                      "," +
                      position.coords.longitude +
                      "&destination=" +
                      markers[i].latitude +
                      "," +
                      markers[i].longitude,
                    "_blank",
                    "noreferrer"
                  );
                });
              };
            })(marker, i)
          );

          // Center the map to fit all markers on the screen
          map.fitBounds(bounds);
        }
      });
    }

    //for Store Marker -- Start
    var infoWindow = new window.google.maps.InfoWindow(),
      storemarker,
      i;

    let Storemarkers = this.state.TodayPickupList;
    var Storeposition = new window.google.maps.LatLng(
      Storemarkers[0].storeLat,
      Storemarkers[0].storeLong
    );

    map.setCenter(Storeposition);
    bounds.extend(Storeposition);
    storemarker = new window.google.maps.Marker({
      position: Storeposition,
      map: map,
      icon,
      title: Storemarkers[0].storeAddress,
    });

    window.google.maps.event.addListener(
      storemarker,
      "click",
      (function (storemarker, i) {
        return function () {
          infoWindow.setContent(Storemarkers[0].storeAddress);
          infoWindow.open(map, storemarker);
        };
      })(storemarker, i)
    );

    map.fitBounds(bounds);
    // End for Store

    // Set zoom level
    var boundsListener = window.google.maps.event.addListener(
      map,
      "bounds_changed",
      function (event) {
        this.setZoom(13);
        window.google.maps.event.removeListener(boundsListener);
      }
    );
  };

  // showme = function (index) {
  //   if (
  //     this.state.MarkerList[index].getAnimation() !=
  //     window.google.maps.Animation.BOUNCE
  //   ) {
  //     this.state.MarkerList[index].setAnimation(
  //       window.google.maps.Animation.BOUNCE
  //     );
  //   } else {
  //     this.state.MarkerList[index].setAnimation(null);
  //   }
  // };

  render() {
    const { pagination, tableLoading, userName, isReassignBtnVisible } =
      this.state;
    const pickupList = this.state.pickUps;
    // const pickupListItem = pickupList.map((pickupList) => ({
    //   pickupID: pickupList.pickupID,
    //   customerID: pickupList.customerID,
    //   customerName: pickupList.customerName,
    //   customerAddressID: pickupList.customerAddressID,
    //   promocode: pickupList.promocode,
    //   pickupDate: pickupList.pickupDate,
    //   pickupSlotName: pickupList.pickupSlotName,
    //   deliverySlotName: pickupList.deliverySlotName,
    //   deliveryTypeName: pickupList.deliveryTypeName,
    //   statusName: pickupList.statusName,
    //   status: pickupList.status,
    //   assignedToUserName: pickupList.assignedToUserName,
    //   bgColor: pickupList.bgColor,
    //   storeName: pickupList.storeName,
    //   storeID: pickupList.storeID,
    //   platform: pickupList.platform,
    //   orderCount: pickupList.orderCount,
    //   remark: pickupList.remark,
    //   createdOn: pickupList.createdOn,
    //   custMobileNo: pickupList.custMobileNo,
    // }));

    ReAssign_Store_By_Admin = JSON.parse(
      localStorage.getItem("buttonAccess")
    ).filter((item) => {
      return item.accessName == "Reassign Store";
    });

   

    const storeBranchTypes = ![undefined, null, ""].includes(
      localStorage.getItem("BranchInfo")
    )
      ? JSON.parse(localStorage.getItem("BranchInfo")).filter(
          (x) => x.branchType === "Store"
        )
      : [];

    //const storeID = storeBranchTypes.filter(x => x)

    const allStoreBranchArray = storeBranchTypes
      .map((branchType) => {
        return branchType.branchID;
      })
      .filter((x) => x !== "-1")
      .slice()
      .sort();

    return (
      <div>
        <PageHeader
          title={labels.PICKUPS}
          extra={[
            // <div key="5" style={{ alignSelf: "center" }}>
            //   <Select
            //     showSearch
            //     mode="multiple"
            //     allowClear
            //     placeholder={labels.SELECT_STATUS}
            //     style={{ minWidth: 150, maxWidth: 250 }}
            //     className={styles.test1}
            //     // disabled={this.state.PickupStatusFilter.disabled}
            //     filterOption={(input, option) =>
            //       option.children.toLowerCase().indexOf(input.toLowerCase()) >=
            //       0
            //     }
            //     value={
            //       [null, undefined, "", []].includes(
            //         this.state.selectedPickupStatus
            //       )
            //         ? undefined
            //         : this.state.selectedPickupStatus.split(",")
            //     }
            //     onChange={(value) =>
            //       this.setState(
            //         {
            //           pickupStatus: {
            //             ...this.state.pickupStatus,
            //           },
            //           selectedPickupStatus: [null, undefined, ""].includes(
            //             value
            //           )
            //             ? undefined
            //             : value.join(","),
            //         },
            //         () => {
            //           const { pagination } = this.state;
            //           this.getPickupList({
            //             storeID: localStorage.getItem("branchID").toString(),
            //             pickupStatusID: this.state.selectedPickupStatus,
            //             pageIndex: this.state.pagination.current,
            //             pageSize: this.state.pagination.pageSize,
            //             pagination: this.state.pagination,
            //             pagination,
            //             PickupFromDate: this.state.pickupFromDate,
            //             PickupToDate: this.state.pickupToDate,
            //           });
            //         }
            //       )
            //     }
            //     // defaultValue={this.state.selectedPickupStatus}
            //   >
            //     {/* {this.state.stateListOptions.length !== 0? */}
            //     {
            //       this.state.pickupStatus.data.map((x) => {
            //         return x.value != -1 ? (
            //           <option
            //             key={x.value.toString()}
            //             value={x.value.toString()}
            //           >
            //             {x.text}
            //           </option>
            //         ) : null;
            //       })
            //       // :null
            //       // }
            //     }
            //   </Select>
            // </div>,
            <Button variant="purple" onClick={() => this.getTodayPickuplist()}>
              View On Map
            </Button>,
            <Select
              showSearch
              mode="multiple"
              allowClear
              // onChange={this.handleSelect}
              onChange={(event) => {
                this.setState(
                  {
                    selectedBranch: [null, undefined, ""].includes(event)
                      ? ""
                      : event.join(","),
                  },
                  () => {
                    this.handleChangeBranch();
                  }
                );
              }}
              value={
                [null, undefined, ""].includes(this.state.selectedBranch)
                  ? [localStorage.getItem("branchID")]
                  : this.state.selectedBranch.split(",")
              }
              placeholder={labels.SELECT_STORE}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ minWidth: 150, maxWidth: 400 }}
            >
              {storeBranchTypes.length !== 0
                ? storeBranchTypes.map((branchType) => {
                    return branchType.branchID !== "-1" ? (
                      <Option
                        value={branchType.branchID.toString()}
                        key={branchType.branchID.toString()}
                      >
                        {branchType.branchName}
                      </Option>
                    ) : null;
                  })
                : null}
            </Select>,
            <div key="2" style={{ alignSelf: "center" }}>
              <Checkbox
                checked={
                  ![null, undefined, ""].includes(this.state.selectedBranch) &&
                  this.state.selectedBranch.split(",").length ===
                    allStoreBranchArray.length &&
                  this.state.selectedBranch.split(",").slice().sort()
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    let allOpt = storeBranchTypes
                      .map((branchType) => {
                        return branchType.branchID;
                      })
                      .filter((x) => x !== "-1");
                    this.setState(
                      {
                        selectedBranch: allOpt.join(","),
                        // storeKeys: [],
                        // factoryKeys: [],
                        // // tagNos:""
                      },
                      () => {
                        if (this.state.selectedBranch !== "") {
                          this.handleChangeBranch();
                        }
                        // else {
                        //   this.setState({
                        //     storeOutTags: [],
                        //     btnMissedTagEnable: true,
                        //   });
                        // }
                      }
                    );
                  } else {
                    this.setState(
                      {
                        selectedBranch: localStorage.getItem("branchID"),
                      },
                      () => {
                        this.handleChangeBranch();
                      }
                    );
                  }
                }}
              >
                {labels.ALL}
              </Checkbox>
            </div>,
            <div key="3" style={{ alignSelf: "center" }}>
              <Search
                placeholder={labels.SEARCH}
                className={styles.SearchInput}
                onChange={this.handleSearch}
              />
            </div>,
            /**
             * Add Pickup Button redirects to add form
             */
            this.state.userRights.length > 0 &&
            this.state.userRights[0].isAdd == true ? (
              <div key="4" style={{ alignSelf: "center" }}>
                <Button
                  variant="purple"
                  htmlType="submit"
                  onClick={
                    () =>
                      this.props.history.push({
                        pathname: [ROUTES.ADD_PICKUP, 0].join("/"),
                        state: {
                          customerID: 0,
                        },
                      })
                    // this.props.history.push({
                    //   pathname: ROUTES.ADD_PICKUP,
                    //   state: {
                    //     pickupID: this.state.pickupId,
                    //     // pickupStatus: this.state.pickupStatus,
                    //   },
                    // })
                  }
                >
                  {labels.ADD_PICKUP}
                </Button>
              </div>
            ) : null,
            <Button
              type="link"
              className={styles.FilterToggle}
              onClick={() => {
                this.setState({ showfilterBox: !this.state.showfilterBox });
              }}
              key="3"
            >
              {this.state.showfilterBox ? "Hide Filters" : "Show Filters"}
              <FontAwesomeIcon
                icon={faChevronUp}
                className={
                  this.state.showfilterBox
                    ? styles.FilterToggleIcon
                    : styles.FilterToggleIconRotated
                }
              />
            </Button>,
          ]}
        />
        <Filters show={this.state.showfilterBox}>
          <Form
            onFinish={() => {
              this.getPickupList({
                storeID: this.state.selectedBranch,
                pickupStatusID: this.state.selectedPickupStatus,
                pageIndex: pagination.current,
                pageSize: pagination.pageSize,
                pagination,
                PickupFromDate: this.state.pickupFromDate,
                PickupToDate: this.state.pickupToDate,
                PickupSlot: this.state.SelectedTimeSlot,
              });
            }}
            initialValues={{
              pickupFromDate: this.state.pickupFromDate,
              pickupToDate: this.state.pickupToDate,
            }}
          >
            <Row gutter={24}>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"pickupFromDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_FROM_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.FROM_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "pickupFromDate")
                    }
                    value={this.state.pickupFromDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(moment(), "day") ? true : false
                    // }
                    // defaultValue={this.state.fromDate}
                    // selected={this.state.defaultFromDate}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={4}>
                <Form.Item
                  name={"pickupToDate"}
                  rules={[
                    { required: true, message: labels.PLEASE_SELECT_TO_DATE },
                  ]}
                >
                  <DatePicker
                    placeholder={labels.TO_DATE}
                    format={"DD-MM-YYYY"}
                    onChange={(date) =>
                      this.handleDateChange(date, "pickupToDate")
                    }
                    value={this.state.pickupToDate}
                    // disabledDate={(currentDate) =>
                    //   currentDate.isAfter(moment(), "day") ? true : false
                    // }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={6}>
                <Form.Item name="timeslot">
                  <Select
                    showSearch
                    mode="multiple"
                    allowClear
                    placeholder={labels.PICKUP_TIMESLOT}
                    className={styles.test1}
                    style={{ minWidth: 150, maxWidth: 250 }}
                    // value={
                    //   [null, undefined, "", []].includes(
                    //     this.state.OrderTypeFilter.selected
                    //   )
                    //     ? undefined
                    //     : this.state.OrderTypeFilter.selected.split(",")
                    // }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) =>
                      this.setState(
                        {
                          SelectedTimeSlot: [null, undefined, ""].includes(
                            value
                          )
                            ? undefined
                            : value.join(","),
                        },
                        () => {
                          console.log(this.state.SelectedTimeSlot);
                        }
                      )
                    }
                  >
                    {this.state.UniquetimeSlot.map((e) => {
                      return e.slot != "" ? (
                        <option key={e.slot} value={e.slot}>
                          {e.slot}
                        </option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={4} className={styles.ActionContainer}>
                <Button
                  htmlType="submit"
                  variant="whitePink"
                  shape="round"
                  size="large"
                  //   onClick={this.fetchItems}
                >
                  {labels.APPLY}
                </Button>
              </Col>
            </Row>
          </Form>
        </Filters>
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <Button
            variant="transparentGreyOutline"
            onClick={() =>{
              
              this.setState(
                {
                  columns:this.tempcolumns,
                  selectedPickupStatus: "1,2",
                },
                () => {
                  this.handleStatusWiseData();
                }
              )
            }
            }
            style={{
              marginLeft: 10,
              marginRight: 10,
              borderColor:
                this.state.selectedPickupStatus == "1,2" ? "#fe1d7f" : null,
              color:
                this.state.selectedPickupStatus == "1,2" ? "#fe1d7f" : null,
            }}
          >
            Assigned
          </Button>
          <Button
            variant="transparentGreyOutline"
            onClick={() =>
              this.setState(
                {
                  columns:this.tempcolumns,
                  selectedPickupStatus: "8",
                },
                () => {
                  this.handleStatusWiseData();
                }
              )
            }
            style={{
              marginLeft: 10,
              marginRight: 10,
              borderColor:
                this.state.selectedPickupStatus == "8" ? "#fe1d7f" : null,
              color: this.state.selectedPickupStatus == "8" ? "#fe1d7f" : null,
            }}
          >
            Reschedule Requested
          </Button>
          <Button
            variant="transparentGreyOutline"
            onClick={() =>
              {
             var tempArray = this.tempcolumns.filter(e => e.cancelhide != true);
              
              this.setState(
                {
                  columns:tempArray,
                  selectedPickupStatus: "3",
                },
                () => {
                  this.handleStatusWiseData();
                }
              )
              }
            }
            style={{
              marginLeft: 10,
              marginRight: 10,
              borderColor:
                this.state.selectedPickupStatus == "3" ? "#fe1d7f" : null,
              color: this.state.selectedPickupStatus == "3" ? "#fe1d7f" : null,
            }}
          >
            Cancel Requested
          </Button>
          <Button
            variant="transparentGreyOutline"
            onClick={() =>
              {
              var tempArray = this.tempcolumns.filter(e => e.cancelledhide != true);
              this.setState(
                {
                  columns:tempArray,
                  selectedPickupStatus: "4",
                },
                () => {
                  this.handleStatusWiseData();
                }
              )
              }
            }
            style={{
              marginLeft: 10,
              marginRight: 10,
              borderColor:
                this.state.selectedPickupStatus == "4" ? "#fe1d7f" : null,
              color: this.state.selectedPickupStatus == "4" ? "#fe1d7f" : null,
            }}
          >
            Cancelled
          </Button>
        </div>

        <Table
          size="small"
          //add condition
          onRow={(record, rowIndex) => {
            return {
              style: { background: record.bgColor },
            };
          }}
          columns={this.state.columns}
          // dataSource={pickupListItem}
          dataSource={this.state.pickuplist}
          loading={tableLoading}
          pagination={pagination}
          onChange={this.handleTableChange}
          scroll={
            this.state.columns.length < 4
              ? { x: 0 }
              : this.state.columns.length < 6
              ? { x: 1000 }
              : this.state.columns.length > 6 && this.state.columns.length <= 12
              ? { x: 1400 }
              : { x: 2000 }
          }
        />

        <Modal
          title={labels.REASSIGN_PICKUP_EXECUTIVE}
          visible={this.state.isModalVisible}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Form
            layout="vertical"
            onFinish={this.handlePopupReassign}
            initialValues={userName}
          >
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={labels.PICKUP_EXECUTIVE}
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: labels.PLEASE_SELECT_ANY_PICKUP_EXECUTIVE,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={labels.SELECT_PICKUP_EXECUTIVE}
                    onChange={this.RestoreDDlValue}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {/* {this.state.stateListOptions.length !== 0? */}
                    {
                      this.state.userListOptions.map((user) => {
                        return user.value !== "-1" ? (
                          <option key={user.value} value={user.value}>
                            {user.text}
                          </option>
                        ) : null;
                      })
                      // :null
                      // }
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} className="custom-modal-footer">
                <Space>
                  <>
                    <Button variant="purple" htmlType="submit">
                      {labels.REASSIGN}
                    </Button>
                    <Button
                      variant="transparentGreyOutline"
                      onClick={this.handlePopupCancel}
                    >
                      {labels.CANCEL}
                    </Button>
                  </>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          title={"Pickup List"}
          visible={this.state.ShowMapView}
          footer={null}
          onCancel={() => {
            this.setState({
              ShowMapView: false,
            });
          }}
          className="modal-form pickupslot"
          destroyOnClose
        >
          {this.state.TodayPickupList != undefined &&
          this.state.TodayPickupList.length > 0 ? (
            <div>
              <Row gutter={24} style={{ border: "2px solid black", margin: 0 }}>
                <Col xs={24} lg={10}>
                  <div>
                    Store Name : {localStorage.getItem("Store/FactoryName")}
                  </div>
                  <div>Date : {moment().format("DD-MM-YYYY")}</div>
                  <div>Total Pickups : {this.state.TodayPickupList.length}</div>
                </Col>
                <Col xs={24} lg={14}>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    onChange={this.initMap}
                  >
                    <Row>
                      {this.state.TodaySlotList.map((item, index) => {
                        return (
                          <Col span={12}>
                            <Checkbox
                              value={item.slotID}
                              style={{
                                color: item.colorName,
                              }}
                            >
                              {item.slotName}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </Checkbox.Group>
                </Col>
              </Row>
              <Row
                className="mapview"
                gutter={24}
                style={{
                  borderBottom: "2px solid black",
                  borderLeft: "2px solid black",
                  borderRight: "2px solid black",
                  margin: 0,
                }}
              >
                
                <Col xs={24} lg={3}>
                  <b>PickupID</b>
                  {this.state.TodayPickupList.map((e, index) => {
                    return (
                      <div>
                        <Button
                          type="link"
                          onClick={() => {
                            this.setState(
                              {
                                SelectedPickupData: {
                                  pickupID: e.pickupID,
                                  customerName: e.customerName,
                                  mobileNo: e.mobileNo,
                                  address: e.location,
                                },
                              }
                              // () => this.showme(index)
                            );
                          }}
                        >
                          <text
                            style={{
                              color: this.state.TodaySlotList.filter((item) => {
                                return item.slotID == e.slotID;
                              })[0].colorName,
                            }}
                          >
                            {e.pickupID}
                          </text>
                        </Button>
                        <br />
                      </div>
                    );
                  })}
                </Col>
                <Col xs={24} lg={21} style={{ padding: 0 }}>
                  <div className={styles.maps} id="map"></div>
                </Col>
              </Row>
              <Row
                gutter={24}
                style={{
                  borderBottom: "2px solid black",
                  borderLeft: "2px solid black",
                  borderRight: "2px solid black",
                  margin: 0,
                }}
              >
                <Col xs={24}>
                  <div>
                    <b>Pickup ID : </b>
                    {this.state.SelectedPickupData.pickupID}
                  </div>
                  <div>
                    <b> Customer Name :</b>{" "}
                    {this.state.SelectedPickupData.customerName}
                  </div>
                  <div>
                    <b>Mobile No. : </b>
                    {this.state.SelectedPickupData.mobileNo}
                  </div>
                  <div>
                    <b>Address : </b>
                    {this.state.SelectedPickupData.address}
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: 15,
                fontSize: 15,
              }}
            >
              No Pickup Available
            </div>
          )}
        </Modal>

        <Modal
          title={labels.REASSIGN_STORE}
          visible={this.state.UpdateStoreIDModalVisible}
          footer={null}
          onCancel={this.handleUpdateStoreIDPopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Form layout="vertical" onFinish={this.updatePickupStoreID}>
            <Row gutter={24}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={labels.STORE}
                  name="Store"
                  rules={[
                    {
                      required: true,
                      message: labels.PLEASE_SELECT_STORE,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={labels.SELECT_STORE}
                    onChange={(val) => this.setState({ SelectedStoreID: val })}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {storeBranchTypes.length !== 0
                      ? storeBranchTypes.map((branchType) => {
                          return branchType.branchID !== "-1" ? (
                            <Option
                              value={branchType.branchID.toString()}
                              key={branchType.branchID.toString()}
                            >
                              {branchType.branchName}
                            </Option>
                          ) : null;
                        })
                      : null}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} className="custom-modal-footer">
                <Space>
                  <>
                    <Button variant="purple" htmlType="submit">
                      {labels.REASSIGN}
                    </Button>
                    <Button
                      variant="transparentGreyOutline"
                      onClick={this.handleUpdateStoreIDPopupCancel}
                    >
                      {labels.CANCEL}
                    </Button>
                  </>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Master);
