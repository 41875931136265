import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  TimePicker,
  PageHeader,
  Typography,
  Space,
  notification,
  Spin,
} from "antd";
import Button from "../../../Common/Button/button";
import styles from "./createstore.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import SlotMapping from "./slotmapping.component";
import StoreService from "../../../../services/storeservice";
import Moment from "moment";

const { Option } = Select;
const { Title } = Typography;
const openNotificationWithIcon = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
  });
};

/**
 * A form as a part of create store - Add Slot Form
 *
 * @props backFunction: a function to modify parent state to go back to tabular view
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 3 June 2020
 *
 * @module Store
 */
export default class SlotForm extends React.Component {
  constructor(props) {
    super(props);
    this.storeservice = new StoreService();
    this.state = {
      fullLoading: false,
      slotID: 0,
      isAdd: false,
      isView: false,
      disabled: false,
      weekDayID: 0,
      weekDayName: "",
      slotFrom: "",
      slotTo: "",
      weekDaysList: [],
      slotMappingList: [],
      showComponent: false,
      currentComponent: true,
      slotFormValues: {},
    };
    this.handleSaveUpdateSlotMapping = this.handleSaveUpdateSlotMapping.bind(
      this
    );
  }

  componentDidMount() {
    this.getWeekDaysList();
    this.setState({
      slotID: this.props.slotID,
      weekDayName: this.props.weekDayName,
      weekDayID: this.props.weekDayID,
      isView: this.props.isView,
      disabled: this.props.disabled,
      slotFrom: Moment(this.props.slotFrom, "h:mm a"),
      slotTo: Moment(this.props.slotTo, "h:mm a"),
    });
    if (this.props.slotMappingList !== null) {
      this.setState({ slotMappingList: this.props.slotMappingList });
    }
  }

  getWeekDaysList = () => {
    this.storeservice.retrieveWeekDaysList().then((weekDaysList) => {
      this.setState({ weekDaysList: weekDaysList.data });
    });
  };

  handleChange = (event) => {
    this.setState({ weekDayID: parseInt(event) });
    this.setState({
      weekDayName: this.state.weekDaysList.filter((x) => x.value === event)[0]
        .text,
    });
  };

  handleTimeChange = (time, name) => {
    this.setState({ [name]: time });
  };

  handleSaveUpdateSlotMapping = () => {
    const postSave = {
      slotID: this.state.slotID,
      storeID: this.props.storeID,
      weekDayID: parseInt(this.state.slotFormValues.weekDayID),
      slotFrom: this.state.slotFormValues.slotFrom.format("HH:mm"),
      slotTo: this.state.slotFormValues.slotTo.format("HH:mm"),
      refSlotID: 0,
    };
    this.setState({ fullLoading: true });
    this.storeservice
      .saveUpdateSlotMappingDetails(postSave)
      .then((response) => {
        if (response.result === "Success") {
          openNotificationWithIcon("success", "Slot Mapping", response.message);
          this.setState({ showComponent: true });
        } else {
          openNotificationWithIcon("error", "Slot Mapping", response.message);
        }
        this.setState({ fullLoading: false });
      });
  };

  slotForm = React.createRef();
  slotFormValidated = false;

  handleSubmit = () => {
    const slotValidator = this.slotForm.validateFields();

    Promise.allSettled([slotValidator]).then((responses) => {
      if (responses[0].status === "rejected") {
      } else if (responses[0].status === "fulfilled") {
        this.setState(
          {
            slotFormValues: responses[0].value,
          },
          () => {
            this.handleSaveUpdateSlotMapping();
          }
        );
      }
    });
  };

  onFinishSlotForm = () => {
    return null;
  };

  render() {
    const { disabled } = this.state;
    return (
      <Spin spinning={this.state.fullLoading}>
        {this.state.showComponent ? (
          <SlotMapping
            backFunction={this.bringback}
            storeID={this.props.storeID}
          />
        ) : (
          <Form
            layout="vertical"
            initialValues={this.props.values}
            ref={(slotForm) => (this.slotForm = slotForm)}
            onFinish={this.onFinishSlotForm}
            className={styles.AddSlotForm}
          >
            {/**
             * Please go through below documentation befor modifying
             * https://ant.design/components/page-header/
             * https://ant.design/components/page-header/#API
             */}
            <PageHeader
              title={
                <Title level={4} className={styles.SlotMappingTitle}>
                  {this.state.slotID === 0
                    ? "Add Slot"
                    : this.state.isView
                    ? "View Slot"
                    : "Edit Slot"}
                </Title>
              }
              backIcon={<FontAwesomeIcon icon={faArrowLeft} />}
              onBack={this.props.backFunction}
              className={styles.AddSlotFormHeader}
            />
            <Row gutter={30}>
              <Col xs={8}>
                <Form.Item
                  label="Weekday"
                  name="weekDayID"
                  rules={[{ required: true, message: "Please select weekday" }]}
                >
                  {/* <Select placeholder="Select Week Days" onChange={(event) => this.handleChange(event,this.state.weekDaysList,'weekDayID','weekDayName')} value={weekDayName} disabled={disabled}> */}
                  <Select placeholder="Select Week Days" disabled={disabled}>
                    {/* {this.state.weekDaysList.map((weekDaysList) => <Select.Option key={weekDaysList.value} value={weekDaysList.value}>{weekDaysList.text}</Select.Option>)}   */}
                    {this.state.weekDaysList.map((weekDaysList) => {
                      return weekDaysList.value !== "-1" ? (
                        <Option
                          value={weekDaysList.value}
                          key={weekDaysList.value}
                        >
                          {weekDaysList.text}
                        </Option>
                      ) : null;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={30}>
              <Col xs={8}>
                <Form.Item
                  label="Slot From"
                  name="slotFrom"
                  rules={[
                    { required: true, message: "Please select Slot From" },
                  ]}
                >
                  {/* <TimePicker format={"h:mm a"} onChange={(time)=>this.handleTimeChange(time, 'slotFrom')} value={slotFrom} disabled={disabled}/> */}
                  <TimePicker format={"h:mm a"} disabled={disabled} />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  label="Slot To"
                  name="slotTo"
                  rules={[{ required: true, message: "Please select Slot To" }]}
                >
                  <TimePicker format={"h:mm a"} disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row className={styles.ActionsRow}>
              <Col xs={24} className={styles.ButtonContainer}>
                <Space size="middle">
                  <Button
                    variant="transparentGreyOutline"
                    className={styles.CancelButton}
                    shape="round"
                    size="large"
                    onClick={this.props.backFunction}
                  >
                    Cancel
                  </Button>
                  {!this.state.isView ? (
                    <Button
                      htmlType="submit"
                      variant="purple"
                      shape="round"
                      size="large"
                      //onClick={(event) => this.handleSaveUpdateSlotMapping(event,this.state.slotMappingList)}
                      onClick={this.handleSubmit}
                    >
                      Save
                    </Button>
                  ) : null}
                  {this.state.showComponent ? (
                    <SlotMapping data={this.state.slotMappingList} />
                  ) : null}
                </Space>
              </Col>
            </Row>
          </Form>
        )}
      </Spin>
    );
  }
}
