import React from "react";
import TransferIn from "../../../components/StoreModule/TransferIn/transferin.store.component";

/**
 * This file holds the view of Transfer In screen
 *
 * @author Payal Bharakhda
 *
 * @since 1.0.0
 * @created 15 July 2020
 */
export default class TransferInStore extends React.Component {
  render() {
    return (
      <div>
        <TransferIn></TransferIn>
      </div>
    );
  }
}
