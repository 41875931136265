import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class FactoryPostQC {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }
  async getLastQC(tagNo) {
    const stateListAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_LAST_POSTQC_INFO,
    });
    let states = [];
    let param = "?TagNo=" + tagNo;
    await stateListAPI.endpoints[this.endpoint.GET_LAST_POSTQC_INFO]
      .getOne(param, {})
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.getLastQC(tagNo);
      });
    }
    return Promise.resolve(states);
  }

  async getResponseList(param) {
    const myApi = new API({ url: this.config.ITEM_FACTORY_URL });
    myApi.createEntity({ name: this.endpoint.GET_POST_QC_LIST }); //var change
    let itemArray = [];
    await myApi.endpoints[this.endpoint.GET_POST_QC_LIST]
      .getAllPost(param)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getResponseList(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async GetGarmentDetailByTagNo(tagNo) {
    const stateListAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    stateListAPI.createEntity({
      name: this.endpoint.GET_GARMENT_DETAIL_BY_TAGNO,
    });
    let states = [];
    let param = "?TagNo=" + tagNo;
    await stateListAPI.endpoints[this.endpoint.GET_GARMENT_DETAIL_BY_TAGNO]
      .getOne(param, {})
      .then((response) => {
        states = response.data;
      })
      .catch((err) => {
        states = this.common.handleError(err);
      });
    if (states.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        states = this.GetGarmentDetailByTagNo(tagNo);
      });
    }
    return Promise.resolve(states);
  }

  async ManagePostQCRequest(data) {
    const addUpdateAPI = new API({ url: this.config.ITEM_FACTORY_URL });
    addUpdateAPI.createEntity({ name: this.endpoint.FACTORY_MANAGE_POST_QC });
    let returnVal = [];
    await addUpdateAPI.endpoints[this.endpoint.FACTORY_MANAGE_POST_QC]
      .getAllPost(data)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.ManagePostQCRequest(data);
      });
    }
    return Promise.resolve(returnVal);
  }

  handleError(error) {
    console.log(error.message);
  }
}
