import React from 'react';
import Inventorysummarycomponent from '../../../components/FactoryModule/Inventory/inventorysummary.component';

export default class inventorysumarryscreen extends React.Component {
  render() {
    return (
      <div>
          <Inventorysummarycomponent></Inventorysummarycomponent>
      </div>
    )
  }
}
