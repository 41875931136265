import React from "react";
import RateCardService from "../../../services/ratecardservice";
import { Row, Col, Form, Space, Tag, notification, Table } from "antd";
import styles from "../../Common/Styles/master.module.css";
import Button from "../../Common/Button/button";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

/**
 * A form as a part of rate card - General Section/Step
 *
 * @author Shailesh Balar
 *
 * @since 1.0.0
 * @created 26 June 2020
 *
 * @module RateCard
 */
export default class RateCardDetail extends React.Component {
  constructor(props) {
    super(props);
    this.ratecardservice = new RateCardService();
    this.state = {
      isEditmode: false,
      isViewmode: false,
      fileName: "",
      rateCardID: 0,
      fileBinary: "",
      uploading: false,
      fileList: [],
      binaryFile: "",
      currentURL: "",
      rateCardDetailsList: [],
    };
  }
  componentDidMount() {
    this.setState({ rateCardID: this.props.rateCardID });
    this.state.currentURL = window.location.origin + "/files/RateCard_TAT.xls";
    this.getRateCardDetails(
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ]
    );
    //   this.setState({
    //     isEditmode:this.props.isEditmode,
    //     isViewmode:this.props.isViewmode
    //   })
  }

  getRateCardDetails = (rateCardID) => {
    if (rateCardID !== 0) {
      this.props.toggleLoading(true);
      this.ratecardservice.getRateCardDetails(rateCardID).then((response) => {
        this.setState({ rateCardDetailsList: response.data });
        this.props.toggleLoading(false);
      });
    }
  };
  handleFileChange = (event) => {
    this.setState({
      files: event.target.files[0],
      fileName: event.target.files[0].name,
    });
  };
  handleSubmit = () => {
    if (this.state.fileList.length === 0) {
      notification["error"]({
        message: "Rate Card",
        description: "Please select rate card file",
      });
    } else {
      const formData = new FormData();
      formData.append("rateCardFile", this.state.fileList[0]);
      formData.append("rateCardID", this.state.rateCardID);
      this.props.toggleLoading(true);
      this.ratecardservice
        .uploadServiceRateCard(this.state.rateCardID, formData)
        .then((response) => {
          if (response.statusCode === 200) {
            notification["success"]({
              message: "Rate Card",
              description: response.message,
            });
          } else {
            notification["error"]({
              message: "Rate Card",
              description: response.message
                ? response.message
                : "Unable to upload rate card",
            });
          }
          this.props.toggleLoading(false);
        });
    }
  };

  columns = [
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "Garment Name",
      dataIndex: "garmentName",
      key: "garmentName",
    },
    {
      title: "Regular",
      dataIndex: "regularRate",
      key: "reguularRate",
      render: (text) => {
        return "₹ " + text;
      },
    },
    {
      title: "Semi Express",
      dataIndex: "semiExpressRate",
      key: "semiExpressRate",
      render: (text) => {
        return "₹ " + text;
      },
    },
    {
      title: "Express",
      dataIndex: "expressRate",
      key: "expressRate",
      render: (text) => {
        return "₹ " + text;
      },
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
    },
    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
    },
  ];

  render() {
    const disabled = this.props.mode === "view";
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 14,
          offset: 6,
        },
      },
    };
    const updateFileBinary = (bin) => {
      this.setState({ fileBinary: bin });
    };
    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };
    const uploadProps = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [file],
        }));
        return false;
      },
      fileList: this.state.fileList,
    };
    return (
      <Form
        layout="vertical"
        name="RateCardDetail"
        initialValues={this.props.values}
        ref={this.props.instance}
        onFinish={this.handleSubmit}
        style={{ display: !this.props.isActive ? "none" : "block" }}
      >
        {this.state.rateCardDetailsList.length === 0 ? (
          <Row gutter={30}>
            <Tag color="#FCC350" style={{ fontSize: 13 }}>
              <a href={this.state.currentURL} download>
                Click here to Download Sample Rate Card
              </a>
            </Tag>
          </Row>
        ) : null}
        {this.state.rateCardDetailsList.length === 0 ? (
          <Row gutter={30}>
            <Col xs={8}>
              <Form.Item
                // {...tailFormItemLayout}
                label="Upload Rate Card"
                rules={[{ required: true, message: "Please select File" }]}
                disabled={disabled}
                getValueFromEvent={normFile}
                name="rateCardFile"
              >
                <Upload {...uploadProps}>
                  <Button>
                    <UploadOutlined /> Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        {this.state.rateCardDetailsList.length === 0 ? (
          <Row className={styles.ActionsRow}>
            <Col xs={24} className={styles.ButtonContainer}>
              <Space size="middle">
                <Button
                  variant="transparentGreyOutline"
                  className={styles.CancelButton}
                  shape="round"
                  size="large"
                  onClick={this.props.backFunction}
                >
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  variant="purple"
                  shape="round"
                  size="large"
                >
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        ) : null}
        {this.state.rateCardDetailsList.length > 0 ? (
          <Table
            size="small"
            bordered
            columns={this.columns}
            dataSource={this.state.rateCardDetailsList}
            pagination={false}
          />
        ) : null}
      </Form>
    );
  }
}
