import React from "react";
import StateMaster from "../../../components/Master/State/statemaster.component"
export default class StateScreen extends React.Component {
  render() {
    return (
      <div >
        <StateMaster></StateMaster>
      </div>
    );
  }
}