import React from "react";
import {
  Row,
  Col,
  Form,
  Select,
  PageHeader,
  // TimePicker,
  Input,
  // DatePicker,
  Space,
  notification,
  // Radio,
  Descriptions,
  Upload,
  Collapse,
} from "antd";
import { BarcodeOutlined } from "@ant-design/icons";
import Common from "../../../services/common";
import GarmentProcessingService from "../../../services/garmentprocessingservice";
import Moment from "moment";
import { IsAuthenticated } from "../../../services/auth-header";
import UserRightsConfiguration from "../../../shared/userrightsconfiguration";
import { labels } from "../../../shared/language";
import Button from "../../Common/Button/button";
var jwt = require("jsonwebtoken");

export default class GarmentProcessing extends React.Component {
  constructor(props) {
    super(props);
    this.garmentprocessdetailservice = new GarmentProcessingService();
    this.UserRightsConfiguration = new UserRightsConfiguration();
    this.commonReq = new Common();
    this.state = {
      stickerLoaded: false,
      tagNumber: "",
      garmentData: {},
      garmentCount: "",
    };
  }

  componentWillMount() {
    if (!IsAuthenticated()) {
      window.location.href = "/";
    }
    var data = JSON.parse(localStorage.getItem("userrights"));
    var res = data.filter(
      (val) => val.screenName == this.UserRightsConfiguration.GARMENT_PROCESSING
    );

    if (!res[0].isView) {
      window.location.href = "/store/dashboard";
    }
    this.setState({ userRights: res });
  }

  GetTagNumberInfo = () => {
    if (this.state.tagNumber != "") {
      var TagNumber = this.state.tagNumber;
      var FactoryID = parseInt(localStorage.getItem("branchID"));
      this.garmentprocessdetailservice
        .getGarmentProcessingDetail(TagNumber, FactoryID)
        .then((items) => {
          if (items.statusCode === 200) {
            this.setState({
              garmentData: items.data,
              stickerLoaded: true,
              garmentCount: "",
            });
            if (items.data != null && items.data != undefined) {
              if (
                items.data.orderType == 2 &&
                items.data.rewashReason != undefined
              ) {
                notification["info"]({
                  message: "Rewash Order – Pls check carefully",
                  description: items.data.rewashReason,
                });
              }
            }
          } else {
            notification["error"]({
              message: labels.GARMENT_PROCESSING,
              description: labels.UNABLE_TO_GET_TAG_DATA,
            });
            this.setState({
              stickerLoaded: false,
              tagNumber: "",
              garmentCount: "",
            });
          }
        });
    } else {
      notification["error"]({
        message: labels.GARMENT_PROCESSING,
        description: labels.PLEASE_ENTER_TAG_NO,
      });
    }
  };

  onChange = (e) => {
    this.setState({ tagNumber: e.target.value });
  };

  onChangeofgarment = (e) => {
    this.setState({ garmentCount: e.target.value });
  };

  FactorySetProcessGarment = () => {
    var userId = jwt.decode(
      JSON.parse(localStorage.getItem("currentuser")).token
    ).unique_name;

    if (this.state.garmentCount != "") {
      const params = {
        SplitOrderNumber: this.state.garmentData.splitOrderNumber,
        UserID: parseInt(userId),
        ProcessGarments: parseInt(this.state.garmentCount),
      };

      this.garmentprocessdetailservice
        .FactorySetProcessGarment(params)
        .then((items) => {
          if (items.statusCode === 200) {
            notification["info"]({
              message: labels.GARMENT_PROCESSING,
              description: items.message,
            });
            this.setState({
              garmentData: {},
              stickerLoaded: false,
              garmentCount: "",
              tagNumber: "",
            });

            // this.GetTagNumberInfo();
          } else {
            this.setState({ garmentCount: "" });
            notification["error"]({
              message: labels.GARMENT_PROCESSING,
              description: items.message,
            });
          }
        });
    } else {
      notification["error"]({
        message: labels.GARMENT_PROCESSING,
        description: labels.PLEASE_ENTER_GARMENT,
      });
    }
  };

  render() {
    const { garmentData, tagNumber, garmentCount } = this.state;
    return (
      <div>
        <PageHeader title={labels.GARMENT_PROCESSING} />
        <br />
        <Form
          layout="vertical"
          initialValues={{
            tagNumber: this.state.tagNumber,
            garmentCount: this.state.garmentCount,
          }}
        >
          <Row gutter={30}>
            <Col xs={24} md={12} xl={6}>
              <Form.Item
                label={labels.SCAN_TAG}
                name="tagNumber"
                rules={[
                  {
                    required: true,
                    message: labels.PLEASE_SCAN_ADD_TAG_NUMBER,
                  },
                ]}
              >
                <div className="w-100" style={{ display: "flex" }}>
                  <Input
                    placeholder={labels.ENTER_TAG} //"Enter/Scan Sticker"
                    onChange={this.onChange}
                    value={this.state.tagNumber}
                    onPressEnter={this.GetTagNumberInfo}
                    suffix={<BarcodeOutlined />}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>

          {this.state.stickerLoaded ? (
            <div
              style={{
                padding: 2,
                background: garmentData.orderType == 2 ? "#e35b5b" : null,
                color: garmentData.orderType == 2 ? "white" : null,
              }}
            >
              <Descriptions
                title={labels.GARMENT_INFORMATION}
                bordered
                size="small"
                column={2}
              >
                <Descriptions.Item
                  label={labels.STORE_NAME}
                  style={garmentData.orderType == 2 ? styles.Rewashorder : null}
                >
                  {garmentData.storeName}
                </Descriptions.Item>
                <Descriptions.Item
                  label={labels.LOT_SIZE_FACTORY_IN}
                  style={garmentData.orderType == 2 ? styles.Rewashorder : null}
                >
                  {garmentData.noofGarment +
                    "/" +
                    garmentData.factoryInGarments}
                </Descriptions.Item>
                <Descriptions.Item
                  label={labels.DELIVERY_TYPE}
                  style={garmentData.orderType == 2 ? styles.Rewashorder : null}
                >
                  {garmentData.deliveryType}
                </Descriptions.Item>
                <Descriptions.Item
                  label={labels.FACTORY_IN_DATE}
                  style={garmentData.orderType == 2 ? styles.Rewashorder : null}
                >
                  {garmentData.factoryInTime}
                </Descriptions.Item>
                <Descriptions.Item
                  label={labels.NO_OF_GARMENT_ALLREADY_PROCEED}
                  style={
                    garmentData.orderType == 2 ? styles.Rewashorder : null
                  }
                >
                  {garmentData.noofProcessgarments}
                </Descriptions.Item>
                <Descriptions.Item
                  label={labels.LAST_PROCEED_DATETIME}
                  style={garmentData.orderType == 2 ? styles.Rewashorder : null}
                >
                  {garmentData.lastProcessTime}
                </Descriptions.Item>
                <Descriptions.Item
                  label={labels.WASH_TYPE}
                  style={
                    garmentData.orderType == 2 ? styles.Rewashorder : null
                  }
                >
                  {garmentData.orderType == 1 ? "Normal" : "Rewash"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={labels.REWASH_REASON}
                  style={garmentData.orderType == 2 ? styles.Rewashorder : null}
                >
                  {garmentData.rewashReason}
                </Descriptions.Item>
              </Descriptions>
              <Row>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    label={labels.NO_OF_GARMENTS}
                    style={
                      garmentData.orderType == 2 ? styles.Rewashorder : null
                    }
                    // name="garmentCount"
                    rules={[
                      { required: true, message: labels.ENTER_NO_OF_GARMENTS },
                    ]}
                  >
                    <Input
                      placeholder={labels.ENTER_NO_OF_GARMENTS}
                      onChange={this.onChangeofgarment}
                      value={this.state.garmentCount}
                      onPressEnter={this.FactorySetProcessGarment}
                      style={
                        garmentData.orderType == 2 ? styles.Rewashorder : null
                      }
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  md={12}
                  xl={6}
                  style={{ textAlign: "right", marginTop: 25 }}
                >
                  <Button
                    // htmlType="submit"
                    variant="purple"
                    shape="round"
                    size="large"
                    onClick={this.FactorySetProcessGarment}
                  >
                    {labels.SUBMIT}
                  </Button>
                </Col>
              </Row>
            </div>
          ) : null}
        </Form>
      </div>
    );
  }
}

const styles = {
  Rewashorder: {
    background: "#e35b5b",
    color: "white",
  },
};
