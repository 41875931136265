import Configuration from "../shared/configuration";
import EndPointEntity from "../shared/endpointentity";
import API from "../shared/API";
import Common from "./common";

export default class PickupService {
  constructor() {
    this.config = new Configuration();
    this.endpoint = new EndPointEntity();
    this.common = new Common();
  }

  async getPickupStatusList() {
    const pickupStatusListAPI = new API({
      url: this.config.DROPDOWN_URL,
    });
    pickupStatusListAPI.createEntity({
      name: this.endpoint.GET_PICKUP_STATUS_LIST,
    });
    let status = [];

    await pickupStatusListAPI.endpoints[this.endpoint.GET_PICKUP_STATUS_LIST]
      .getAll()
      .then((response) => {
        status = response.data;
      })
      .catch((err) => {
        status = this.common.handleError(err);
      });
    if (status.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        status = this.getPickupStatusList();
      });
    }
    return Promise.resolve(status);
  }

  async getOpInstructList() {
    const getOpInstructAPI = new API({
      url: this.config.DROPDOWN_URL,
    });
    getOpInstructAPI.createEntity({
      name: this.endpoint.GET_OPERATION_INSTRUCT_LIST,
    });
    let opInstruct = [];
    await getOpInstructAPI.endpoints[this.endpoint.GET_OPERATION_INSTRUCT_LIST]
      .getAll()
      .then((response) => {
        opInstruct = response.data;
      })
      .catch((err) => {
        opInstruct = this.common.handleError(err);
      });
    if (opInstruct.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        opInstruct = this.getOpInstructList();
      });
    }
    return Promise.resolve(opInstruct);
  }

  async getTimeSlotList(storeid) {
    const pickupStatusListAPI = new API({
      url: this.config.ITEM_URL,
    });
    pickupStatusListAPI.createEntity({
      name: this.endpoint.GET_STORE_SLOTS,
    });
    let timeslot = [];
    const idParam = "?StoreID=" + storeid;
    await pickupStatusListAPI.endpoints[this.endpoint.GET_STORE_SLOTS]
      .getOne(idParam)
      .then((response) => {
        timeslot = response.data;
      })
      .catch((err) => {
        timeslot = this.common.handleError(err);
      });
    if (timeslot.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        timeslot = this.getTimeSlotList(storeid);
      });
    }
    return Promise.resolve(timeslot);
  }

  async getDeliveryTypeList() {
    const deliveryTypeAPI = new API({
      url: this.config.DROPDOWN_URL,
    });
    deliveryTypeAPI.createEntity({
      name: this.endpoint.GET_DELIVERY_TYPES,
    });
    let deliveryTypes = [];
    await deliveryTypeAPI.endpoints[this.endpoint.GET_DELIVERY_TYPES]
      .getAll()
      .then((response) => {
        deliveryTypes = response.data;
      })
      .catch((err) => {
        deliveryTypes = this.common.handleError(err);
      });
    if (deliveryTypes.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        deliveryTypes = this.getDeliveryTypeList();
      });
    }
    return Promise.resolve(deliveryTypes);
  }

  async getCancelReasonsList() {
    const reasonListAPI = new API({
      url: this.config.DROPDOWN_URL,
    });
    reasonListAPI.createEntity({
      name: this.endpoint.GET_PICKUP_CANCEL_REASON_LIST,
    });
    let reasons = [];
    await reasonListAPI.endpoints[this.endpoint.GET_PICKUP_CANCEL_REASON_LIST]
      .getAll()
      .then((response) => {
        reasons = response.data;
      })
      .catch((err) => {
        reasons = this.common.handleError(err);
      });
    if (reasons.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        reasons = this.getCancelReasonsList();
      });
    }
    return Promise.resolve(reasons);
  }

  async getServices() {
    const servicesAPI = new API({
      url: this.config.DROPDOWN_URL,
    });
    servicesAPI.createEntity({
      name: this.endpoint.GET_SERVICES_OFFERED,
    });
    let services = [];
    await servicesAPI.endpoints[this.endpoint.GET_SERVICES_OFFERED]
      .getAll()
      .then((response) => {
        services = response.data;
      })
      .catch((err) => {
        services = this.common.handleError(err);
      });
    if (services.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        services = this.getServices();
      });
    }
    return Promise.resolve(services);
  }

  async getCustomerData(searchStr) {
    const myApi = new API({ url: this.config.ITEM_URL });
    myApi.createEntity({
      name: this.endpoint.GET_SEARCH_CUSTOMER,
    });
    let itemArray = [];
    let ID = "?SearchString=" + searchStr;
    await myApi.endpoints.GetSearchCustomer.getOne(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getCustomerData(searchStr);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getCustomerAddresses(id) {
    const getAddressAPI = new API({ url: this.config.ITEM_URL });
    getAddressAPI.createEntity({
      name: this.endpoint.GET_CUSTOMER_ADDRESSES,
    });
    let addresses = [];
    const idParam = "?CustomerId=" + id;
    await getAddressAPI.endpoints[this.endpoint.GET_CUSTOMER_ADDRESSES]
      .getOne(idParam)
      .then((response) => (addresses = response.data))
      .catch((err) => {
        addresses = this.common.handleError(err);
      });
    if (addresses.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        addresses = this.getCustomerAddresses(id);
      });
    }
    return Promise.resolve(addresses);
  }

  async getDaysBasedOnServices() {
    const daysApi = new API({
      url: this.config.LAUNDRETKART_AUTH,
    });
    daysApi.createEntity({
      name: this.endpoint.GET_SAMPLE_TIME_CARD,
    });
    let days = [];
    await daysApi.endpoints[this.endpoint.GET_SAMPLE_TIME_CARD]
      .getAll()
      .then((response) => {
        days = response.data;
      })
      .catch((err) => {
        days = this.common.handleError(err);
      });
    if (days.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        days = this.getDaysBasedOnServices();
      });
    }
    return Promise.resolve(days);
  }

  async getPickupRequestHistory(pickupID) {
    const historyApi = new API({
      url: this.config.ITEM_STORE_URL,
    });
    historyApi.createEntity({
      name: this.endpoint.GET_PICKUP_REQUEST_HISTORY,
    });
    let history = [];
    const id = "?PickupID=" + pickupID;
    // const id={ PickupID: pickupID }
    await historyApi.endpoints[this.endpoint.GET_PICKUP_REQUEST_HISTORY]
      .uploadFile(id, "", pickupID)
      .then((response) => {
        history = response.data;
      })
      .catch((err) => {
        history = this.common.handleError(err);
      });
    if (history.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        history = this.getPickupRequestHistory(pickupID);
      });
    }
    return Promise.resolve(history);
  }

  async getReassignPickups(params) {
    const reassignAPI = new API({
      url: this.config.ITEM_STORE_URL,
    });
    reassignAPI.createEntity({
      name: this.endpoint.REASSIGN_PICKUP_REQUEST,
    });
    let reassignedPickup = [];
    await reassignAPI.endpoints[this.endpoint.REASSIGN_PICKUP_REQUEST]
      .create(params)
      .then((response) => {
        reassignedPickup = response.data;
      })
      .catch((err) => {
        reassignedPickup = this.common.handleError(err);
      });
    if (reassignedPickup.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        reassignedPickup = this.getReassignPickups(params);
      });
    }
    return Promise.resolve(reassignedPickup);
  }

  async getUserListBasedOnStore(storeid) {
    const userAPI = new API({ url: this.config.DROPDOWN_URL });
    userAPI.createEntity({
      name: this.endpoint.GET_USER_LIST_BY_STORE_ID,
    });
    let users = [];
    const idParam = "?StoreID=" + storeid;
    await userAPI.endpoints[this.endpoint.GET_USER_LIST_BY_STORE_ID]
      .getOne(idParam)
      .then((response) => {
        users = response.data;
      })
      .catch((err) => {
        users = this.common.handleError(err);
      });
    if (users.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        users = this.getUserListBasedOnStore(storeid);
      });
    }
    return Promise.resolve(users);
  }
  
  async retriveHolidaylist(param) {
   
    const myApi = new API({ url: this.config.ITEM_URL });
    
    myApi.createEntity({ name: this.endpoint.GET_HOLIDAYLIST_BY_STOREID});
    let itemArray = [];
    let ID = "?StoreID=" + param;

    await myApi.endpoints.GetHolidaylistByStoreID.getOne(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
       
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.retriveHolidaylist(param);
      });
    }
    return Promise.resolve(itemArray);
  }

  async validateDisPromocode(params) {
    const promocodeAPI = new API({
      url: this.config.ITEM_STORE_URL,
    });
    promocodeAPI.createEntity({
      name: this.endpoint.PICKUP_VALIDATE_PROMOCODE,
    });
    let promocode = [];
    await promocodeAPI.endpoints[this.endpoint.PICKUP_VALIDATE_PROMOCODE]
      .getAllPost(params)
      .then((response) => {
        promocode = response.data;
      })
      .catch((err) => {
        promocode = this.common.handleError(err);
      });
    if (promocode.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        promocode = this.validateDisPromocode(params);
      });
    }
    return Promise.resolve(promocode);
  }

  async getPickup(param) {
    const allPickupAPI = new API({
      url: this.config.ITEM_STORE_URL,
    });
    allPickupAPI.createEntity({
      name: this.endpoint.GET_PICKUP_REQUEST,
    });
    let pickups = [];
    await allPickupAPI.endpoints[this.endpoint.GET_PICKUP_REQUEST]
      .getAllPost(param)
      .then((response) => {
        pickups = response.data;
      })
      .catch((err) => {
        pickups = this.common.handleError(err);
      });
    if (pickups.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        pickups = this.getPickup(param);
      });
    }
    return Promise.resolve(pickups);
  }

  async addUpdatePickupRequest(postData) {
    const addUpdateAPI = new API({
      url: this.config.ITEM_STORE_URL,
    });
    addUpdateAPI.createEntity({
      name: this.endpoint.SAVE_UPDATE_PICKUP_REQUEST,
    });
    let returnVal = [];
    await addUpdateAPI.endpoints[this.endpoint.SAVE_UPDATE_PICKUP_REQUEST]
      .create(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.addUpdatePickupRequest(postData);
      });
    }
    return Promise.resolve(returnVal);
  }

  async getPickUpDetailsByID(id) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.GET_PICKUP_REQUEST_BY_PICKUPID,
    });
    let itemArray = [];
    let ID = "?PickupId=" + id;
    await myApi.endpoints.GetPickupRequestByPickupID.getOne(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getPickUpDetailsByID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async requestReschedulePickup(params) {
    const reassignAPI = new API({
      url: this.config.ITEM_STORE_URL,
    });
    reassignAPI.createEntity({
      name: this.endpoint.RESCHEDULE_PICKUP_REQUEST,
    });
    let reassignedPickup = [];
    await reassignAPI.endpoints[this.endpoint.RESCHEDULE_PICKUP_REQUEST]
      .create(params)
      .then((response) => {
        reassignedPickup = response.data;
      })
      .catch((err) => {
        reassignedPickup = this.common.handleError(err);
      });
    if (reassignedPickup.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        reassignedPickup = this.requestReschedulePickup(params);
      });
    }
    return Promise.resolve(reassignedPickup);
  }

  async requestCancelPickup(postdata) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.CANCEL_PICKUP_REQUEST,
    });
    let itemArray = [];
    await myApi.endpoints[this.endpoint.CANCEL_PICKUP_REQUEST]
      .create(postdata)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.requestCancelPickup(postdata);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getStoreByCustomerAddressID(id) {
    const myApi = new API({ url: this.config.ITEM_STORE_URL });
    myApi.createEntity({
      name: this.endpoint.GET_STORE_ID_BY_CUST_ADDRESS,
    });
    let itemArray = [];
    let ID = "?CustomerAddressID=" + id;
    await myApi.endpoints[this.endpoint.GET_STORE_ID_BY_CUST_ADDRESS]
      .getOne(ID)
      .then(({ data }) => {
        itemArray = data;
      })
      .catch((err) => {
        itemArray = this.common.handleError(err);
      });
    if (itemArray.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        itemArray = this.getStoreByCustomerAddressID(id);
      });
    }
    return Promise.resolve(itemArray);
  }

  async getSlotListByStoreIDS(storeids) {
    const pickupStatusListAPI = new API({
      url: this.config.ITEM_URL,
    });
    pickupStatusListAPI.createEntity({
      name: this.endpoint.GET_STORE_SLOTS_BY_STOREIDS,
    });
    let timeslot = [];
    const idParam = "?StoreIDS=" + storeids;
    await pickupStatusListAPI.endpoints[this.endpoint.GET_STORE_SLOTS_BY_STOREIDS]
      .getOne(idParam)
      .then((response) => {
        timeslot = response.data;
      })
      .catch((err) => {
        timeslot = this.common.handleError(err);
      });
    if (timeslot.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        timeslot = this.getSlotListByStoreIDS(storeids);
      });
    }
    return Promise.resolve(timeslot);
  }

 
  async GetTodayPickipList(param) {
    const allPickupAPI = new API({
      url: this.config.ITEM_STORE_URL,
    });
    allPickupAPI.createEntity({
      name: this.endpoint.GET_TODAY_PICKUP_LIST,
    });
    let pickups = [];
    await allPickupAPI.endpoints[this.endpoint.GET_TODAY_PICKUP_LIST]
      .getAllPost(param)
      .then((response) => {
        pickups = response.data;
      })
      .catch((err) => {
        pickups = this.common.handleError(err);
      });
    if (pickups.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        pickups = this.GetTodayPickipList(param);
      });
    }
    return Promise.resolve(pickups);
  }

  async updatePickupStoreID(postData) {
    const addUpdateAPI = new API({
      url: this.config.ITEM_STORE_URL,
    });
    addUpdateAPI.createEntity({
      name: this.endpoint.UPDATE_PICKUP_STOREID,
    });
    let returnVal = [];
    await addUpdateAPI.endpoints[this.endpoint.UPDATE_PICKUP_STOREID]
      .create(postData)
      .then((response) => {
        returnVal = response.data;
      })
      .catch((err) => {
        returnVal = this.common.handleError(err);
      });
    if (returnVal.statusCode == 401) {
      await this.common.refreshToken().then((response) => {
        this.common.setToken(response);
        returnVal = this.addUpdatePickupRequest(postData);
      });
    }
    return Promise.resolve(returnVal);
  }

  handleResponseError(response) {
    throw new Error("HTTP error, status = " + response.status);
  }
}
