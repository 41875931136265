import React from "react";
import RoleMaster from "../../../components/Role/RoleMaster/rolemaster.component";

export default class RoleMasterScreen extends React.Component {
  render() {
    return (
      <div>
        <RoleMaster></RoleMaster>
      </div>
    );
  }
}
