import React from "react";
import {
  PageHeader,
  notification,
  Form,
  Modal,
  Row,
  Col,
  Select,
  Space,
  Table,
  DatePicker,
  Radio,
  Tooltip,
  Spin,
} from "antd";
import Button from "../../Common/Button/button";
import TimeCardService from "../../../services/timecardservice";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import Moment from "moment";
const { Option } = Select;

class StoreAssignment extends React.Component {
  timecardservice = new TimeCardService();
  state = {
    modalLoading: false,
    timeCardID: null,
    timeAssignID: 0,
    timeCardObj: {},
    timeCardStore: [],
    showAddPopup: false,
    store: {
      selected: null,
      data: [],
      disabled: true,
    },
    tableLoading: true,
    currentTimeCardStore: {},
    popupMode: "add",
  };
  componentDidMount() {
    if (!this.props.timeCardID) {
      this.props.history.goBack();
    } else {
      //this.getTimeCardMaster(this.props.match.params.timeCardID);
    }
    this.getStore();
    this.getStoreCardAssignment(this.props.timeCardID);
  }
  getStoreCardAssignment = (id) => {
    const params = {
      timeAssignID: 0,
      timeCardID: parseInt(id),
    };
    this.timecardservice
      .retrieveStoreCardAssignment(params)
      .then((response) => {
        if (response.statusCode === 200) {
          let modifiedData = response.data.map((singleObj) => {
            singleObj.key = singleObj.timeAssignID;
            return singleObj;
          });
          this.setState({ timeCardStore: modifiedData });
          this.setState({ tableLoading: false });
        } else {
          notification["error"]({
            message: "Time Card",
            description: "Unable to get store assignment",
            //description: "Redirecting....",
            // onClose: () => {
            //   this.props.history.goBack();
            // },
          });
        }
      });
  };
  getStore = () => {
    this.timecardservice.retrieveStore().then((response) => {
      if (response.statusCode === 200) {
        this.setState({
          store: {
            ...this.state.store,
            data: response.data,
            disabled: false,
          },
        });
      } else {
        notification["error"]({
          message: "Time Card",
          description: "Unable to get list of store",
        });
      }
    });
  };

  addUpdateTimeCardStoreAssignment(values) {
    const postData = {
      timeAssignID: this.state.currentTimeCardStore.timeAssignID
        ? this.state.currentTimeCardStore.timeAssignID
        : 0,
      timeCardID: parseInt(this.props.timeCardID),
      storeID: parseInt(values.storeID),
      effectiveFrom: values.effectiveFrom[0].format("yyyy-MM-DD"),
      effectiveTo: values.effectiveFrom[1].format("yyyy-MM-DD"),
      // closePreviousTimeCard:
      //   this.state.currentTimeCardStore.timeAssignID !== 0
      //     ? false
      //     : values.closePreviousTimeCard,
      closePreviousTimeCard: values.closePreviousTimeCard,
    };
    this.setState({ modalLoading: true });
    this.timecardservice
      .SaveUpdateAssignTimeCardToStores(postData)
      .then((response) => {
        if (response.statusCode === 200) {
          notification["success"]({
            message: "Time Card",
            description: response.message,
          });
        } else {
          notification["error"]({
            message: "Time Card",
            description: response.message
              ? response.message
              : "Unable to add store assignment",
          });
        }
        this.setState({ modalLoading: false });
        this.setState({ showAddPopup: false });
        this.getStoreCardAssignment(this.props.timeCardID);
      });
  }
  handleViewEditStoreAssignment = (storeassignment, mode) => {
    //this.getCityList(address.stateID.toString());
    const newStoreAssignment = {
      ...storeassignment,
      storeID: storeassignment.storeID.toString(),
      effectiveFrom: [
        Moment(storeassignment.effectiveFrom, "M/D/yyyy"),
        Moment(storeassignment.effectiveTo, "M/D/yyyy"),
      ],
    };
    this.setState({ currentTimeCardStore: newStoreAssignment });
    this.setState({ popupMode: mode });
    this.setState({ showAddPopup: true });
  };
  handlePopupCancel = () => {
    this.setState({ showAddPopup: false });
  };
  columns = [
    {
      title: "ID",
      dataIndex: "timeAssignID",
      key: "timeAssignID",
      width: "3%",
    },
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "Effective From",
      dataIndex: "effectiveFrom",
      key: "effectiveFrom",
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Effective To",
      dataIndex: "effectiveTo",
      key: "effectiveTo",
      render: (text) => {
        return Moment(text).format("DD-MM-YYYY");
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: "100px",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Button
              type="link"
              variant="link"
              onClick={() => this.handleViewEditStoreAssignment(record, "view")}
            >
              <FontAwesomeIcon icon={faInfo} />
            </Button>
          </Tooltip>
          {this.props.mode !== "view" &&
          Moment(record.effectiveFrom).isAfter(Moment()) ? (
            <Tooltip title="Edit">
              <Button
                type="link"
                variant="link"
                onClick={() =>
                  this.handleViewEditStoreAssignment(record, "edit")
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </Tooltip>
          ) : null}
        </Space>
      ),
    },
  ];
  render() {
    const disabledAll = this.state.popupMode === "view";
    return (
      <div>
        <PageHeader
          title="Store Assignment"
          extra={[
            this.props.mode !== "view" ? (
              <Button
                variant="purple"
                htmlType="submit"
                onClick={() =>
                  this.setState({
                    showAddPopup: true,
                    popupMode: "add",
                    currentTimeCardStore: {},
                  })
                }
                key="1"
              >
                Add Store Assignment
              </Button>
            ) : null,
          ]}
        />
        <Table
          size="small"
          columns={this.columns}
          dataSource={this.state.timeCardStore}
          loading={this.state.tableLoading}
          pagination={false}
        />
        <Modal
          title={[
            this.state.popupMode === "add"
              ? "Add"
              : this.state.popupMode === "view"
              ? "View"
              : "Edit",
            "Store Assignment",
          ].join(" ")}
          visible={this.state.showAddPopup}
          footer={null}
          onCancel={this.handlePopupCancel}
          className="modal-form"
          destroyOnClose
        >
          <Spin spinning={this.state.modalLoading}>
            <Form
              layout="vertical"
              onFinish={(values) =>
                this.addUpdateTimeCardStoreAssignment(values)
              }
              initialValues={this.state.currentTimeCardStore}
            >
              <Row gutter={24}>
                {/* <Col xs={16}></Col> */}
                <Col xs={24}>
                  <Form.Item
                    label="Store"
                    name="storeID"
                    rules={[{ required: true, message: "Please select Store" }]}
                  >
                    <Select
                      placeholder="Select Store"
                      disabled={disabledAll}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.store.data.length !== 0
                        ? this.state.store.data.map((store) => {
                            return store.value !== "-1" ? (
                              <Option value={store.value} key={store.value}>
                                {store.text}
                              </Option>
                            ) : null;
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Effective Period"
                    name="effectiveFrom"
                    rules={[
                      {
                        required: true,
                        message: "Please select Effective From",
                      },
                    ]}
                  >
                    <DatePicker.RangePicker
                      placeholder={["From", "To"]}
                      onChange={() => null}
                      format={"DD-MM-YYYY"}
                      disabled={disabledAll}
                      disabledDate={(currentDate) =>
                        currentDate.isSameOrBefore(Moment(), "day")
                          ? true
                          : false
                      }
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={12}>
                <Form.Item
                  label="Effective To"
                  name="effectiveTo"
                  rules={[
                    { required: true, message: "Please select Effective To" },
                  ]}
                >
                  <DatePicker onChange={() => null} disabled={disabledAll} />
                </Form.Item>
              </Col> */}
                {this.state.popupMode === "add" ? (
                  <Col xs={12}>
                    <Form.Item
                      label="Close Previous Time Card"
                      name="closePreviousTimeCard"
                      rules={[
                        {
                          required: true,
                          message: "Please select Close Previous Time Card",
                        },
                      ]}
                    >
                      <Radio.Group disabled={disabledAll}>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                ) : null}
                <Col xs={24} className="custom-modal-footer">
                  <Space>
                    {this.state.popupMode === "view" ? (
                      <Button
                        variant="transparentGreyOutline"
                        onClick={this.handlePopupCancel}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Button
                          variant="transparentGreyOutline"
                          onClick={this.handlePopupCancel}
                        >
                          Cancel
                        </Button>
                        <Button htmlType="submit" variant="purple">
                          {this.state.popupMode === "add" ? "Add" : "Update"}
                        </Button>
                      </React.Fragment>
                    )}
                  </Space>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    );
  }
}
export default withRouter(StoreAssignment);
